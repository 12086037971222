import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroEstoqueInterModal } from "./components/CadastroEstoqueInterModal";
import { EstoqueInterGrid } from "./components/EstoqueInterGrid";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { BotaoPesquisar, PageContainer } from "../../../../components";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { apiGetV2 } from "../../../../apiV2";
import { MovimentacoesEstoqueInterModal } from "./components/MovimentacoesEstoqueInterModal";
import { SaldoEstoqueInterModal } from "./components/SaldoEstoqueInterModal";

export const modalTitleEstoqueInter = "Estoque Intermediário";
export const routesBaseEstoqueInter = "/pronta_entrega/cadastro/estoque_inter";

export const EstoqueInter = () => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async () => {
    const [ok, ret] = await apiGetV2(`${routesBaseEstoqueInter}/listar/`);
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer
      title="Cadastro de Estoque Intermediário"
      number="0096"
      canGoBack
    >
      <Card body>
        <Row>
          <BotaoPesquisar
            padded={false}
            onClick={carregarDados}
            loading={loading}
          />
          <BotaoIncluir padded={false} toggle={toggleCadastro} />
          <CadastroEstoqueInterModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleEstoqueInter}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0096_2"
            selected={selected}
            routeBase={routesBaseEstoqueInter}
            notifyEvent={notifyEvent}
          />
          <MovimentacoesEstoqueInterModal selected={selected} />
          <SaldoEstoqueInterModal selected={selected} />
        </Row>
      </Card>
      <Card body>
        <EstoqueInterGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
