import React from "react";
import { naturalSort } from "../../../../../coreUtils";
import { Table } from "../../../../../components";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
    sortable: true,
  },
  {
    dataField: "id_rota_mob__nome",
    text: "Rota",
    align: "left",
    headerAlign: "left",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "data_abertura",
    text: "Início",
    align: "center",
    headerAlign: "center",
    sortable: true,
  },
  {
    dataField: "data_encerramento",
    text: "Fim",
    align: "center",
    headerAlign: "center",
    sortable: true,
    style: (_, row) => {
      if (row.mais_que_7_dias) {
        return {
          fontWeight: "bold",
          color: "coral",
          fontSize: "12.5px",
        };
      }

      return {};
    },
    formatter: (c, row) => (
      <>
        {c}
        {row.mais_que_7_dias && (
          <>
            <br />
            Duração maior que 7 dias
          </>
        )}
      </>
    ),
  },
  {
    dataField: "aberturas",
    text: "NF-es Abertura",
    align: "center",
    headerAlign: "center",
    sortable: true,
    formatter: (value) =>
      value.map((e) => <span style={{ display: "block" }}>{e}</span>),
  },
  {
    dataField: "fechamentos",
    text: "NF-es Fechamento",
    align: "center",
    headerAlign: "center",
    sortable: true,
    formatter: (value) =>
      value.map((e) => <span style={{ display: "block" }}>{e}</span>),
  },
];

export const DuracaoJornadaGrid = ({ data, handleSelect }) => {
  return (
    <Table
      data={data}
      columns={columns}
      onSelect={handleSelect}
      paginated={false}
    />
  );
};
