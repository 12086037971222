import React from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  FiltroPeriodoDatas,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { useState } from "react";
import { ClientesInadimplentesGrid } from "./components/ClientesInadimplentesGrid";
import { useEffect } from "react";
import { formatDateISO, formatValueFromAPI } from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";

export const ClientesInadimplentes = () => {
  const [idVendedor, setIdVendedor] = useState(null);
  const [nomeVendedor, setNomeVendedor] = useState("");
  const [considerarCredRot, setConsiderarCredRot] = useState(false);
  const [imprimirDetalhamento, setImprimirDetalhamento] = useState(false);
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const handleSetVendedor = (s) => {
    setIdVendedor(s?.value);
    setNomeVendedor(s?.nome);
  };

  const buscarDados = async (impressao = false) => {
    const params = {
      id_vendedor: idVendedor,
      considerar_cred_rot: considerarCredRot,
      data_ini: dataIni,
      data_fim: dataFim,
      imprimir_detalhes: impressao ? imprimirDetalhamento : false,
    };

    return await apiGetV2(
      `/relatorios/financeiro/clientes_inadimplentes/`,
      params
    );
  };

  const carregarDados = async () => {
    setLoading(true);

    const [ok, ret] = await buscarDados();

    if (ok) {
      setDados(ret.clientes);
      setTotais(ret.totais);
    }

    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados(true);

    if (ok) {
      await docPrintReport(
        "/relatorios/financeiro_receber/clientes_inadimplentes/",
        {
          data: ret,
          imprimir_detalhamento: imprimirDetalhamento,
          considerar_cred_rot: considerarCredRot,
          nome_vendedor: formatValueFromAPI(nomeVendedor, idVendedor),
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
        },
        "5044_1"
      );
    }
    setLoadingImprimir(false);
  };

  useEffect(() => {
    limparDados();
  }, [idVendedor, considerarCredRot]);

  return (
    <PageContainer
      title="Clientes Inadimplentes por Vendedor"
      number="5044"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            label="Vendedor"
            name="vendedor"
            onChange={handleSetVendedor}
            isSearchable
            isClearable
            isConcatField
            concatModelName="colaborador"
            disabled={loading || loadingImprimir}
          />
          <FormCheckbox
            label="Considerar Crédito Rotativo"
            name="considerar_cred_rot"
            onChange={(e) => setConsiderarCredRot(!considerarCredRot)}
            checked={considerarCredRot}
            disabled={loading || loadingImprimir}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas
            label="Período de Emissão de Venda"
            defaultOption={null}
            defaultStart={null}
            defaultEnd={null}
            onChange={handleDate}
            disabled={loading || loadingImprimir}
          />
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            disabled={
              [0, null, undefined].includes(idVendedor) || loadingImprimir
            }
            disabledHint="Selecione o Vendedor"
          />
          <FormCheckbox
            label="Imprimir Detalhamento"
            name="imprimir_detalhamento"
            onChange={(e) => setImprimirDetalhamento(!imprimirDetalhamento)}
            checked={imprimirDetalhamento}
            disabled={loading || loadingImprimir}
          />
          <BotaoImprimir
            onClick={imprimir}
            loading={loadingImprimir}
            disabled={[0, null, undefined].includes(idVendedor) || loading}
            disabledHint="Selecione o Vendedor"
          />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Títulos A Receber"
          value={totais?.tot_titulos_rec}
        />
        {considerarCredRot ? (
          <CardTotaisItem
            label="Crédito Rotativo"
            value={totais?.tot_rotativo}
          />
        ) : (
          <></>
        )}
        <CardTotaisItem
          label="Pend. Caixa Loja"
          value={totais?.tot_movs_pends_caixa}
        />
        <CardTotaisItem label="Total Geral" value={totais?.tot_geral} />
      </CardTotais>
      <Card body>
        <ClientesInadimplentesGrid
          dados={dados}
          idVendedor={idVendedor}
          dataIni={dataIni}
          dataFim={dataFim}
          considerarCredRot={considerarCredRot}
        />
      </Card>
    </PageContainer>
  );
};
