import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ModalBase,
} from "../../../../../components";
import { MaisProdutosGrid } from "./MaisProdutosGrid";
import MarketplacesIntegracoesService from "../../../../../services/vendas/MarktetplacesIntegracoesService";
import { toastr } from "react-redux-toastr";

export const MaisProdutosModal = ({ isOpen, toggle, action, notifyEvent }) => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingConf, setLoadingConf] = useState(false);
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState([]);

  //FILTROS
  const [subgrupo, setSubgrupo] = useState();
  const [fabricante, setFabricante] = useState();
  const [grupo, setGrupo] = useState();

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const handleSetSelected = (v, isSelected, row) => {
    setSelected(
      isSelected ? [...selected, v] : selected.filter((e) => e !== v)
    );
    setLastSelected(isSelected ? v : null);
  };

  const limparDados = () => {
    setSubgrupo();
    setFabricante();
    setGrupo();
  };

  const buscarDados = async () => {
    setLoading(true);

    try {
      const [ok, ret] = await MarketplacesIntegracoesService.listarEnviar({
        id_grupo: grupo,
        id_sub_grupo: subgrupo,
        id_fabricante: fabricante,
      });

      setDados(ok ? ret : []);

      if (ok) {
        notifyEvent();
      }
    } finally {
      setLoading(false);
    }
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    await carregarDados();
    setLoading(false);
  };

  const incluirDados = async () => {
    setLoadingConf(true);
    if (selected.length === 0) {
      setLoadingConf(false);
      toastr.warning("Atenção", "Nenhum produto selecionado.");
    } else {
      try {
        const payload = {
          produtos_enviar: selected.map((e) => ({ id_produto: e })),
        };

        const [ok] = await MarketplacesIntegracoesService.incluirProd(payload);
        if (ok) {
          toggle();
          notifyEvent();
          setSelected([]);
          setLastSelected([]);
        }
      } finally {
        setLoadingConf(false);
      }
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={"Selecione os Produtos a serem enviados para o Marketplace"}
      size="xl"
      onClose={limparDados}
      onConfirm={incluirDados}
      number="0124_1"
      onOpened={carregarDados}
      loadingConfirm={loadingConf}
    >
      <Row className="pb-2">
        <AsyncComboBox
          md={4}
          label="Grupo"
          name="grupo"
          isConcatField
          concatModelName="grupo"
          isSearchable
          isClearable
          onChange={(s) => setGrupo(s?.value)}
        />
        <AsyncComboBox
          md={3}
          label="Sub-Grupo"
          name="subgrupo"
          isConcatField
          concatModelName="sub_grupo"
          isSearchable
          isClearable
          onChange={(s) => setSubgrupo(s?.value)}
        />
        <AsyncComboBox
          md={3}
          label="Fabricante"
          name="fabricante"
          isConcatField
          concatModelName="fabricante"
          isSearchable
          isClearable
          onChange={(s) => setFabricante(s?.value)}
        />
        <BotaoPesquisar onClick={handleSearch} loading={loading} />
      </Row>

      <Card body>
        <MaisProdutosGrid
          dados={dados}
          selected={lastSelected}
          setSelected={handleSetSelected}
          onSelectAll={handleSelectAll}
        />
      </Card>
    </ModalBase>
  );
};
