import React, { useRef, useState } from "react";
import {
  AsyncComboBox,
  FormButton,
  IntegerFormInput,
  ModalBase,
  NumberInput,
} from "../../../../../../components";
import { roundFloat } from "../../../../../../coreUtils";
import { Row } from "reactstrap";
import { showWarning } from "../../../../../../components/AlertaModal";

export const IncluirDetalheMetaModal = ({
  movs,
  handleAdd,
  loadingIncluir,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idTipo, setIdTipo] = useState(0);
  const [nomeTipo, setNomeTipo] = useState("");
  const [precoVendaProd, setPrecoVendaProd] = useState(0);
  const [metaQtdVenda, setMetaQtdVenda] = useState(0);
  const [metaVlrFaturado, setMetaVlrFaturado] = useState(0);
  const [metaPercMaxDevolTroca, setMetaPercMaxDevolTroca] = useState(0);
  const idTipoRef = useRef();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const limparDados = () => {
    setIdTipo(0);
    setNomeTipo("");
    setPrecoVendaProd(0);
    setMetaQtdVenda(0);
    setMetaVlrFaturado(0);
    setMetaPercMaxDevolTroca(0);
    if (idTipoRef.current) {
      idTipoRef.current.clearValue();
    }
  };

  const handleAddInternal = () => {
    const descTipo = "Produto";

    if ([0, null, undefined].includes(idTipo)) {
      showWarning(`Por favor, selecione o ${descTipo}`);
      return false;
    }

    if (movs.some((e) => e.id_tipo === idTipo)) {
      showWarning(`O ${descTipo} informado já foi associado à Meta.`);
      return false;
    }

    const payload = {
      tipo: "PRODUTO",
      desc_tipo: descTipo,
      id_tipo: idTipo,
      nome_tipo: nomeTipo,
      preco_tipo: precoVendaProd,
      meta_qtd_venda: metaQtdVenda,
      meta_vlr_faturado: metaVlrFaturado,
      meta_perc_max_devol_troca: metaPercMaxDevolTroca,
    };

    handleAdd(payload);
    limparDados();
    if (idTipoRef.current) {
      idTipoRef.current.setFocus();
    }
  };

  const handleSetIdTipo = (s) => {
    setIdTipo(s?.value ?? 0);
    setNomeTipo(s?.nome ?? s?.label ?? "");

    if (s) {
      setPrecoVendaProd(s?.preco_venda ?? 0);
      setMetaVlrFaturado(roundFloat(metaQtdVenda * s?.preco_venda ?? 0, 2));
    }
  };

  const handleSetMetaQtdVenda = (v) => {
    setMetaQtdVenda(v);

    setMetaVlrFaturado(roundFloat(v * precoVendaProd, 2));
  };

  return (
    <>
      <FormButton md="auto" color="info" onClick={toggle}>
        Incluir + Produtos
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Incluir Item na Meta de Venda"
        number="0056_51"
        loading={loadingIncluir}
        onConfirm={handleAddInternal}
        onClosed={limparDados}
      >
        <Row className="mb-3">
          <AsyncComboBox
            isConcatField
            concatModelName={"produto"}
            isSearchable
            md={9}
            label={"Produto"}
            onChange={handleSetIdTipo}
            defaultValue={idTipo}
            ref={idTipoRef}
            hideShortcut
          />

          <NumberInput
            md={3}
            label="Preço de Venda"
            value={precoVendaProd}
            disabled
          />
        </Row>
        <Row>
          <IntegerFormInput
            md={6}
            label="Qtd. de Venda"
            defaultValue={metaQtdVenda}
            onChange={handleSetMetaQtdVenda}
          />
          <NumberInput
            md={6}
            label="Vlr. de Venda da Meta"
            value={metaVlrFaturado}
            onChange={setMetaVlrFaturado}
            disabled
          />
        </Row>
      </ModalBase>
    </>
  );
};
