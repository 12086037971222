import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AsyncComboBox,
  FormCheckbox,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { Row } from "reactstrap";
import {
  setCfopPad,
  setExigeSenhaAltEstoqPeloCadProd,
  setIdGrupoPad,
  setIdSubGrupoPad,
  setIdFabricantePad,
  setIdTipoProdPad,
  setUnidMedPad,
  setUtilizaAlteracaoIdProduto,
  setLangFullText,
  setUtilizaTamanhoProd,
  setUtilizaCorProd,
  setUtilizaFulltextPesquisa,
} from "../store/cadastro_produto_slice";
import { RadioItem } from "../../../../components/RadioGroup";

export const PersonCadastroProduto = () => {
  const store = useSelector((state) => state.cadastro_produto);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Solicitar Senha para Alterar Quantidade de Estoque pela Tela de Cadastro de Produto"
          checked={store.exige_senha_alt_estoq_pelo_cad_prod}
          onChange={() =>
            dispatch(
              setExigeSenhaAltEstoqPeloCadProd(
                !store.exige_senha_alt_estoq_pelo_cad_prod
              )
            )
          }
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="CFOP Padrão"
          isSearchable
          isConcatField
          concatModelName="cfop"
          defaultValue={store.cfop_pad}
          onChange={(s) => dispatch(setCfopPad(s?.value ?? null))}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Grupo Padrão"
          isSearchable
          isConcatField
          concatModelName="grupo"
          defaultValue={store.id_grupo_pad}
          onChange={(s) => dispatch(setIdGrupoPad(s?.value ?? null))}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Sub-Grupo Padrão"
          isSearchable
          isConcatField
          concatModelName="sub_grupo"
          defaultValue={store.id_sub_grupo_pad}
          onChange={(s) => dispatch(setIdSubGrupoPad(s?.value ?? null))}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Fabricante Padrão"
          isSearchable
          isConcatField
          concatModelName="fabricante"
          defaultValue={store.id_fabricante_pad}
          onChange={(s) => dispatch(setIdFabricantePad(s?.value ?? null))}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Tipo de Produto Padrão"
          isSearchable
          isConcatField
          concatModelName="tipo_prod"
          defaultValue={store.id_tipo_prod_pad}
          onChange={(s) => dispatch(setIdTipoProdPad(s?.value ?? null))}
        />
      </Row>
      <Row>
        <TextInput
          md={3}
          label="Unidade de Medida Padrão"
          value={store.unid_med_pad}
          onChange={(e, v) => dispatch(setUnidMedPad(v))}
          maxLength={3}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Linguagem da Pesquisa Full-Text"
          value={store.lang_full_text}
          onChange={(v) => dispatch(setLangFullText(v))}
        >
          <RadioItem label="english" value="english" />
          <RadioItem label="portuguese" value="portuguese" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Habilita opção que permite alteração do código / ID do Produto"
          checked={store.utiliza_alteracao_id_produto}
          onChange={() =>
            dispatch(
              setUtilizaAlteracaoIdProduto(!store.utiliza_alteracao_id_produto)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utiliza Campo Tamanho do Produto"
          checked={store.utiliza_tamanho_prod}
          onChange={() =>
            dispatch(setUtilizaTamanhoProd(!store.utiliza_tamanho_prod))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utiliza Campo de Cor do Produto"
          checked={store.utiliza_cor_prod}
          onChange={() => dispatch(setUtilizaCorProd(!store.utiliza_cor_prod))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utiliza Pesquisa FullText"
          checked={store.utiliza_fulltext_pesquisa}
          onChange={() =>
            dispatch(
              setUtilizaFulltextPesquisa(!store.utiliza_fulltext_pesquisa)
            )
          }
        />
        <span style={{ marginTop: "0.15rem" }}>
          Esta opção não possui efeito no Sistema Automotivo, que sempre
          utilizará pesquisa FullText
        </span>
      </Row>
    </>
  );
};
