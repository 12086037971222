import React, { forwardRef, useState, useImperativeHandle } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import GerarNfVendaOsService from "../../../../../services/faturamento/GerarNfVendaOsService";

export const DefineNaoGeraNfNfseModal = forwardRef(({ notifyEvent }, ref) => {
  const [idSelect, setSelect] = useState();
  const [tipoSelect, setTipoSelect] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const internalToggle = () => {
    if (isOpen) {
      // Limpa os dados
      setSelect(null);
      setTipoSelect(null);
    }

    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      tipo: tipoSelect,
      id_tipo: idSelect,
    };

    const ret = await GerarNfVendaOsService.defineNaoGeraNF(payload);

    if (ret) {
      internalToggle();
      notifyEvent();
    }
    setIsLoading(false);
  };

  useImperativeHandle(ref, () => ({
    toggle: (id, tipo) => {
      if (!isOpen) {
        setSelect(id);
        setTipoSelect(tipo);
        setIsOpen(true);
      }
    },
  }));

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={internalToggle}
      size="md"
      title="Define não gera NF-e/NFS-e"
      number="0036_3"
      onConfirm={handleSubmit}
      loadingConfirm={isLoading}
      autoFocus
    >
      <p style={{ textAlign: "center" }}>
        Tem certeza de que deseja definir que o registro NÃO gere NF (NF-e,
        NFS-e e NFC-e)?
      </p>
    </ModalBase>
  );
});
