import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  NumberInput,
  Table,
  ModalBase,
  TableDelete,
} from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import RegrasComissaoService from "../../../../../services/cadastro/RegrasComissaoService";

const ExcecoesRegraModal = ({ idMov, descItem }) => {
  const [isOpen, setIsOpen] = useState();
  const [itemAtual, setItemAtual] = useState(null);
  const [itens, setItens] = useState([]);
  const [percComissao, setPercComissao] = useState(0);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const selectRef = useRef();

  const limparDados = () => {
    setItemAtual(null);
    setItens([]);
    setPercComissao(0);
  };

  const toggle = () => setIsOpen(!isOpen);

  const listarItens = async () => {
    const [ok, ret] = await RegrasComissaoService.excecao.listar(idMov);
    setItens(ok ? ret : []);
  };

  const handleAdd = async () => {
    const payload = {
      id_mov: idMov,
      id_excecao: itemAtual,
      tipo_excecao: "CLIEN",
      perc_comissao: percComissao,
    };

    setLoadingAdd(true);
    const [ok] = await RegrasComissaoService.excecao.incluir(payload);
    if (ok) {
      setPercComissao(0);
      listarItens();
      if (selectRef.current) {
        selectRef.current.clearValue();
        selectRef.current.setFocus();
      }
    }
    setLoadingAdd(false);
  };

  const handleDelete = async (idExcecao) => {
    const [ok] = await RegrasComissaoService.excecao.excluir(idExcecao);
    if (ok) {
      listarItens();
    }
  };

  const columns = [
    {
      dataField: "id_excecao",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_mov",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "perc_comissao",
      text: "Perc. Comissão",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2) + " %",
      sortFunc: naturalSort,
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "Ações",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  return (
    <React.Fragment>
      <FormButton
        color="secondary"
        md="auto"
        onClick={toggle}
        disabled={[null, undefined, 0].includes(idMov)}
        disabledHint={"Selecione um item da lista abaixo"}
      >
        Exceção
      </FormButton>
      {isOpen && (
        <ModalBase
          isOpen={true}
          toggle={toggle}
          size="lg"
          onBeforeOpen={listarItens}
          onClosed={limparDados}
          title={`${descItem}: Exceções`}
          number="0038_31"
          hideCancelButton
        >
          <Row>
            <AsyncComboBox
              label="Cliente"
              isConcatField
              concatModelName="cliente"
              isSearchable
              md={8}
              onChange={(s) => setItemAtual(s?.value)}
              autoFocus
              ref={selectRef}
            />
            <NumberInput
              label="Perc. Comissão"
              md={2}
              onChange={setPercComissao}
              value={percComissao}
              isPercentage
            />
            <FormButton
              md="auto"
              color="info"
              onClick={handleAdd}
              disabled={[0, null, undefined].includes(itemAtual)}
              loading={loadingAdd}
              disabledHint={"Selecione um cliente no campo à esquerda"}
            >
              Incluir
            </FormButton>
          </Row>
          <Table
            columns={columns}
            data={itens}
            keyField="id_excecao"
            paginated={false}
            pageSize={10}
          />
        </ModalBase>
      )}
    </React.Fragment>
  );
};

export default ExcecoesRegraModal;
