import { apiGet } from "../../api";

const URL_BASE = "/cadastro/campo_personalizado";

const CadastroCampoPersonService = {
  urlBase: URL_BASE,
  listar: async (pista) => {
    let _pista = "";
    if (pista) {
      _pista = `?pista=${pista.replace(" ", "&")}`;
    }
    return await apiGet(`${URL_BASE}/listar/${_pista}`);
  },
  buscarCampos: async (tabela) =>
    await apiGet(`${URL_BASE}/listar_campos/${tabela}/`),
  buscar: async (id) => await apiGet(`${URL_BASE}/buscar/${id}/`),
};

export default CadastroCampoPersonService;
