import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";
import { DespesasEstruturadas } from "./DespesasEstruturadas";
import { ReceitasEstruturadas } from "./ReceitasEstruturadas";
import { apiGetV2 } from "../../../../../apiV2";
import {
  setDetalhesContasPagarOpen,
  setDetalhesJornadaDiaOpen,
  setDetalhesOrdServOpen,
  setDetalhesVendaOpen,
  setDetalhesVendaProntaEntregaOpen,
  setUtilizaOrganizacao,
} from "../store/dreSlice";
import { DetalhesTituloPagarModal } from "../../../../financeiro/contas_pagar/gerenc_contas_pagar/components/DetalhesTituloPagarModal";
import { DetalheVendaModal } from "../../../../financeiro/caixa_loja/components/DetalheVendaModal";
import { DetalheOSModal } from "../../../../financeiro/caixa_loja/components/DetalheOSModal";
import { DetalheVendaProntaEntregaModal } from "../../../../pronta_entrega/gerenciamento/gerenciamento_vendas/components/DetalheVendaProntaEntregaModal";
import { DetalhesDiaModal } from "../../../../pronta_entrega/gerenciamento/conferencia_valores/components/DetalhesDiaModal";

export const CardTabelaDados = () => {
  const store = useSelector((state) => state.dre);
  const dispatch = useDispatch();

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/inc_titulo_pagar/");
    if (ok) {
      dispatch(setUtilizaOrganizacao(ret.utiliza_organizacao));
    }
  };

  useEffect(() => {
    carregarParametros();
  }, []);

  if (store.despesas.length > 0 || store.receitas.length > 0) {
    return (
      <>
        <Card body>
          <DespesasEstruturadas />
        </Card>
        <Card body>
          <ReceitasEstruturadas />
        </Card>
        <Card body>
          <span className="text-muted">
            Para abrir os detalhes dos movimentos, clique duas vezes sobre a
            linha que deseja visualizar os dados
          </span>
        </Card>
        <DetalhesTituloPagarModal
          isOpen={store.detalhesContasPagarOpen}
          toggle={() =>
            dispatch(setDetalhesContasPagarOpen(!store.detalhesContasPagarOpen))
          }
          selected={store.idDetalhes}
          utilizaOrganizacao={store.utilizaOrganizacao}
        />
        <DetalheVendaModal
          isOpen={store.detalhesVendaOpen}
          toggle={() =>
            dispatch(setDetalhesVendaOpen(!store.detalhesVendaOpen))
          }
          idVenda={store.idDetalhes}
        />
        <DetalheOSModal
          isOpen={store.detalhesOrdServOpen}
          toggle={() =>
            dispatch(setDetalhesOrdServOpen(!store.detalhesOrdServOpen))
          }
          idOS={store.idDetalhes}
        />
        <DetalheVendaProntaEntregaModal
          isOpen={store.detalhesVendaProntaEntregaOpen}
          toggle={() =>
            dispatch(
              setDetalhesVendaProntaEntregaOpen(
                !store.detalhesVendaProntaEntregaOpen
              )
            )
          }
          idVenda={store.idDetalhes}
        />
        <DetalhesDiaModal
          isOpen={store.detalhesJornadaDiaOpen}
          toggle={() =>
            dispatch(setDetalhesJornadaDiaOpen(!store.detalhesJornadaDiaOpen))
          }
          idJornada={store.idDetalhes}
        />
      </>
    );
  } else {
    return <div></div>;
  }
};
