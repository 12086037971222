import React from "react";
import { Card } from "reactstrap";

const LegendaBaseInformacoes = () => {
  return (
    <Card body>
      <p className="m-0">
        <b>BASE DE INFORMAÇÕES</b>
        <br />
        Vendas
        <br />
        <br />
        <b>ESPECIFICAÇÕES</b>
        <br />
        Os valores de:
        <ul>
          <li>Venda Total no Mês da Empresa</li>
          <li>Venda Total no Mês Anterior da Empresa</li>
          <li>Valor de Venda do Vendedor</li>
          <li>Valor de Venda do Vendedor no Mês Anterior</li>
        </ul>
        já estão subtraindo valores de frete, devolução, serviços e clientes que
        não geram comissão.
        <br />
        Visualize os valores desconsiderados passando o mouse sobre ícone ao
        lado dos valores.
        <br />
        <br />O valor de desconto no crédito rotativo do vendedor no mês, que é
        realizado pelo caixa loja, é descontado do valor de comissão.
      </p>
    </Card>
  );
};

export default LegendaBaseInformacoes;
