import React from "react";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { Table } from "../../../../../components";

export const MetaVendaGrid = ({ data, handleSelect, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "tipo_meta",
      text: "Meta Por",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "desc_meta_por",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data_ini",
      text: "Início",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_fim",
      text: "Fim",
      align: "center",
      headerAlign: "center",
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        onSelect={handleSelect}
        paginated
        onRowDoubleClick={alterar}
      />
    </>
  );
};
