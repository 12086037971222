import React, { useState } from "react";
import { FormButton, ModalBase } from "../../../../../components";
import RemessaEntreEmpresasService from "../../../../../services/faturamento/RemessaEntreEmpresasService";
import { DetalhesRemessaForm } from "../../enviar_remessa/components/DetalhesRemessaModal";

export const ReceberRemessaModal = ({
  selected,
  licencaMatriz,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      id_remessa: selected,
      id_licenca_matriz: licencaMatriz,
    };
    const [ok] = await RemessaEntreEmpresasService.receberRemessa(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        padded={false}
        color="success"
        onClick={toggle}
        disabled={[0, null, undefined].includes(selected)}
      >
        Receber Remessa
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Receber Remessa"
        number="0099_1"
        autoFocus
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <DetalhesRemessaForm
          selected={selected}
          receber
          licencaMatriz={licencaMatriz}
        />
      </ModalBase>
    </>
  );
};
