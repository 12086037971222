import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  modalTitleProdutoIntermediario,
  routesBaseProdutoIntermediario,
} from "../ProdutoIntermediario";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  MODAL_ACTIONS,
  formatNumber,
  roundFloat,
} from "../../../../../coreUtils";
import { Row } from "reactstrap";
import {
  Divider,
  FixedField,
  FormButton,
  NumberInput,
  Table,
  TableDelete,
} from "../../../../../components";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import { showWarning } from "../../../../../components/AlertaModal";
import { v4 as uuidv4 } from "uuid";

export const CadastroProdutoIntermediarioModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [idProdutoFinal, setIdProdutoFinal] = useState(null);
  const [nomeProdutoFinal, setNomeProdutoFinal] = useState("");
  const [unidade, setUnidade] = useState("");
  const [insumos, setInsumos] = useState([]);
  const [idProdutoInsumo, setIdProdutoInsumo] = useState(null);
  const [nomeProdutoInsumo, setNomeProdutoInsumo] = useState("");
  const [unidadeInsumo, setUnidadeInsumo] = useState("");
  const [quantidadeInsumo, setQuantidadeInsumo] = useState(0);
  const [vlrCustoUnitInsumo, setVlrCustoUnitInsumo] = useState(0);
  const [vlrCustoTotalInsumo, setVlrCustoTotalInsumo] = useState(0);

  const pesqProdInsumoRef = useRef();
  const pesqProdFinalRef = useRef();
  const quantidadeInsumoRef = useRef();

  const limparDadosInsumo = () => {
    setIdProdutoInsumo(null);
    setNomeProdutoInsumo("");
    setUnidadeInsumo("");
    setQuantidadeInsumo(0);
    setVlrCustoUnitInsumo(0);
    setVlrCustoTotalInsumo(0);
  };

  const limparDados = () => {
    setAtivo(true);
    setIdProdutoFinal(null);
    setNomeProdutoFinal("");
    setUnidade("");
    setInsumos([]);
    limparDadosInsumo();
  };

  const fetchData = (data) => {
    setAtivo(data.ativo);
    setIdProdutoFinal(data.id_produto);
    setNomeProdutoFinal(data.nome_produto);
    setUnidade(data.unidade);
    setInsumos(
      data.insumos.map((e) => ({
        uuid: uuidv4(),
        id_produto: e.id_produto,
        nome_produto: e.nome_produto,
        unidade: e.unidade,
        quantidade: parseFloat(e.quantidade),
        vlr_custo_unit: parseFloat(e.vlr_custo_unit),
        vlr_custo_total: parseFloat(e.vlr_custo_total),
      }))
    );
    setTimeout(() => {
      if (pesqProdFinalRef.current) {
        pesqProdFinalRef.current.setDescricao(data.nome_produto);
        pesqProdFinalRef.current.setId(String(data.id_produto));
        pesqProdFinalRef.current.setReferencia(String(data.referencia_produto));
      }
    }, 150);
  };

  const handleSelectProdutoFinal = ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    unidade,
  }) => {
    if (idProdutoFinal !== id) {
      setIdProdutoFinal(id);
      setNomeProdutoFinal(nome);
      setUnidade(unidade);
      if (pesqProdFinalRef.current) {
        pesqProdFinalRef.current.setDescricao(nome);
        pesqProdFinalRef.current.setId(String(id));
        pesqProdFinalRef.current.setReferencia(String(referencia));
      }
    }
  };

  const selectNextFieldProdutoFinal = () =>
    pesqProdInsumoRef.current && pesqProdInsumoRef.current.focus();

  const handleSelectProdutoInsumo = ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    unidade,
    custoUe,
  }) => {
    if (idProdutoInsumo !== id) {
      setIdProdutoInsumo(id);
      setNomeProdutoInsumo(nome);
      setUnidadeInsumo(unidade);
      setVlrCustoUnitInsumo(custoUe);
      if (pesqProdInsumoRef.current) {
        pesqProdInsumoRef.current.setDescricao(nome);
        pesqProdInsumoRef.current.setId(String(id));
        pesqProdInsumoRef.current.setReferencia(String(referencia));
      }
    }
  };

  const selectNextFieldProdutoInsumo = () =>
    quantidadeInsumoRef.current && quantidadeInsumoRef.current.focus();

  useEffect(() => {
    setVlrCustoTotalInsumo(
      roundFloat(quantidadeInsumo * vlrCustoUnitInsumo, 2)
    );
  }, [quantidadeInsumo, vlrCustoUnitInsumo]);

  const handleAddInsumo = () => {
    if ([0, null, undefined].includes(idProdutoInsumo)) {
      showWarning("Por favor, selecione um Produto");
      return;
    }

    if (insumos.findIndex((e) => e.id_produto === idProdutoInsumo) > -1) {
      showWarning("O Produto informado já existe entre os insumos.");
      return;
    }

    const payload = {
      uuid: uuidv4(),
      id_produto: idProdutoInsumo,
      nome_produto: nomeProdutoInsumo,
      unidade: unidadeInsumo,
      quantidade: quantidadeInsumo,
      vlr_custo_unit: vlrCustoUnitInsumo,
      vlr_custo_total: vlrCustoTotalInsumo,
    };
    setInsumos([...insumos, payload]);
    limparDadosInsumo();
    if (pesqProdInsumoRef.current) {
      pesqProdInsumoRef.current.clear();
      pesqProdInsumoRef.current.focus();
    }
  };

  const handleDeleteInsumo = (uuid) =>
    setInsumos(insumos.filter((e) => e.uuid !== uuid));

  const handleAlterarInsumo = (coluna, novoValor, row) => {
    const casasDecTest = coluna === "quantidade" ? 3 : 2;
    const valorAntesTest = roundFloat(parseFloat(row[coluna]), casasDecTest);
    const novoValorTest = roundFloat(parseFloat(novoValor), casasDecTest);
    if (valorAntesTest === novoValorTest) {
      return;
    }

    if (coluna === "quantidade") {
      row.quantidade = parseFloat(novoValor);
    } else if (coluna === "vlr_custo_unit") {
      row.vlr_custo_unit = parseFloat(novoValor);
    }

    row.vlr_custo_total = roundFloat(
      parseFloat(row.vlr_custo_unit) * parseFloat(row.quantidade),
      2
    );

    setInsumos(insumos.map((e) => (e.uuid === row.uuid ? row : e)));
  };

  const submitPayload = (action) => {
    const payload = {
      ativo: ativo,
      id_produto_final: idProdutoFinal,
      nome_produto_final: nomeProdutoFinal,
      unidade: unidade,
      insumos: insumos.map((e) => ({
        id_produto: e.id_produto,
        nome_produto: e.nome_produto,
        unidade: e.unidade,
        quantidade: e.quantidade,
        vlr_custo_unit: e.vlr_custo_unit,
      })),
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_prod_inter: selected, ...payload };
  };

  const columns = [
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_produto", text: "Nome", align: "left" },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c) => formatNumber(c, true, 3, true),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
      editable: true,
      editorType: "number",
      decimalPlaces: 3,
      onChange: handleAlterarInsumo,
      alwaysShowEditor: true,
    },
    { dataField: "unidade", text: "UN", align: "center" },
    {
      dataField: "vlr_custo_unit",
      text: "Custo Unit.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
      editable: true,
      editorType: "number",
      onChange: handleAlterarInsumo,
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_custo_total",
      text: "Custo Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDeleteInsumo(row.uuid)} />
      ),
    },
  ];

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleProdutoIntermediario}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseProdutoIntermediario}
      number="0093_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row className="mb-2">
        <PesqProduto
          md={10}
          onConfirm={handleSelectProdutoFinal}
          ref={pesqProdFinalRef}
          selectNextField={selectNextFieldProdutoFinal}
          onChangeDescricao={setNomeProdutoFinal}
        />
        <FixedField
          label="Unidade"
          value={unidade}
          horizontal
          divClassName="pt-4"
        />
      </Row>
      <Divider>Insumos</Divider>
      <Row>
        <PesqProduto
          md={10}
          onConfirm={handleSelectProdutoInsumo}
          ref={pesqProdInsumoRef}
          selectNextField={selectNextFieldProdutoInsumo}
          onChangeDescricao={setNomeProdutoInsumo}
        />
        <FixedField
          label="Unidade"
          value={unidadeInsumo}
          horizontal
          divClassName="pt-4"
        />
      </Row>
      <Row className="mb-2">
        <NumberInput
          md={3}
          label="Quantidade"
          value={quantidadeInsumo}
          onChange={setQuantidadeInsumo}
          ref={quantidadeInsumoRef}
          decimalPlaces={3}
        />
        <NumberInput
          md={3}
          label="Custo Unitário"
          value={vlrCustoUnitInsumo}
          onChange={setVlrCustoUnitInsumo}
        />
        <NumberInput
          md={3}
          label="Custo Total"
          value={vlrCustoTotalInsumo}
          disabled
        />
        <FormButton md="auto" color="info" onClick={handleAddInsumo}>
          Incluir Insumo
        </FormButton>
      </Row>
      <Table
        data={insumos}
        columns={columns}
        paginated={false}
        pageSize={8}
        keyField="uuid"
      />
    </ModalCadastroV2>
  );
};
