import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { IoStatsChartSharp } from "react-icons/io5";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { TbMathEqualGreater } from "react-icons/tb";
import {
  relControlaDreRoute,
  relControlaFluxoCaixaRoute,
  relControlaResumoResultadoRoute,
} from "../../../routes/modules/relatorios";

export const RelControladoria = () => {
  return (
    <PageContainer title="Relatórios - Controladoria">
      <MenuGroup>
        <MenuGroupButton
          label="Resumo Resultado"
          icon={TbMathEqualGreater}
          pathname={relControlaResumoResultadoRoute.path}
          nroTela={relControlaResumoResultadoRoute.nroTela}
        />
        <MenuGroupButton
          label="DRE"
          icon={IoStatsChartSharp}
          pathname={relControlaDreRoute.path}
          nroTela={relControlaDreRoute.nroTela}
        />
        <MenuGroupButton
          label="Fluxo de Caixa"
          icon={LiaFileInvoiceDollarSolid}
          pathname={relControlaFluxoCaixaRoute.path}
          nroTela={relControlaFluxoCaixaRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
