import React, { useEffect, useState } from "react";
import {
  FixedField,
  FormButton,
  ModalBase,
  Table,
} from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import RemessaEntreEmpresasService from "../../../../../services/faturamento/RemessaEntreEmpresasService";
import { Row } from "reactstrap";

export const DetalhesRemessaForm = ({ selected, receber, licencaMatriz }) => {
  const [nDestino, setNDestino] = useState(null);
  const [nomeOrigem, setNomeOrigem] = useState(null);
  const [inclusao, setInclusao] = useState(null);
  const [nroNF, setNroNF] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [loadingImprimirSeparacao, setLoadingImprimirSeparacao] =
    useState(false);

  const buscarProdutosRemessa = async () => {
    const [ok, ret] = receber
      ? await RemessaEntreEmpresasService.buscarMatriz(selected, licencaMatriz)
      : await RemessaEntreEmpresasService.buscar(selected);

    if (ok) {
      setNDestino(ret.n_destino);
      setNomeOrigem(ret.nome_origem);
      setNroNF(ret.nro_nf);
      setInclusao(ret.inclusao);
      setProdutos(
        ret.produtos.map((e) => ({
          id_produto: e.id_produto,
          nome_prod: e.nome_prod,
          unidade: e.unidade,
          quantidade: parseFloat(e.quantidade),
          vlr_custo_unit: parseFloat(e.vlr_custo_unit),
          vlr_custo_total: parseFloat(e.vlr_custo_total),
        }))
      );
    }
  };

  const imprimirSeparacao = async () => {
    setLoadingImprimirSeparacao(true);
    await RemessaEntreEmpresasService.imprimirSeparacao(
      selected,
      receber,
      licencaMatriz
    );
    setLoadingImprimirSeparacao(false);
  };

  const columns = [
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_prod", text: "Nome", align: "left" },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
    },
    { dataField: "unidade", text: "UN", align: "center" },
    {
      dataField: "vlr_custo_unit",
      text: "Custo Unit.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
    },
    {
      dataField: "vlr_custo_total",
      text: "Custo Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
    },
  ];

  useEffect(() => {
    buscarProdutosRemessa();
  }, []);

  const labelClassName = receber ? "pt-2" : undefined;

  return (
    <>
      <Row className="mb-3">
        <FixedField
          label="ID"
          value={selected}
          divClassName={labelClassName}
          horizontal
        />
        {receber ? (
          <FixedField
            label="Origem"
            value={nomeOrigem}
            divClassName={labelClassName}
            horizontal
          />
        ) : (
          <FixedField
            label="Destino"
            value={nDestino}
            divClassName={labelClassName}
            horizontal
          />
        )}
        <FixedField
          label="Inclusão"
          value={inclusao}
          divClassName={labelClassName}
          horizontal
        />
        <FixedField
          label="Nº NF"
          value={nroNF}
          divClassName={labelClassName}
          horizontal
        />
        {receber && (
          <FormButton
            divClassName="ml-auto"
            color="primary"
            onClick={imprimirSeparacao}
            loading={loadingImprimirSeparacao}
            padded={false}
          >
            Imprimir Separação
          </FormButton>
        )}
      </Row>
      <Table
        data={produtos}
        columns={columns}
        paginated={false}
        keyField="id_produto"
        pageSize={10}
      />
    </>
  );
};

export const DetalhesRemessaModal = ({
  selected,
  isOpen,
  toggle,
  receber,
  licencaMatriz,
}) => {
  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Produtos da Remessa"
      number="0098_2"
    >
      <DetalhesRemessaForm
        selected={selected}
        receber={receber}
        licencaMatriz={licencaMatriz}
      />
    </ModalBase>
  );
};
