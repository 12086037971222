import React, { useRef, useState } from "react";
import {
  FixedField,
  FormButton,
  ModalBase,
  NumberInput,
  Table,
  TableDelete,
} from "../../../../../components";
import FracionamentoNfEntradaService from "../../../../../services/compras/FracionamentoNfEntradaService";
import { showWarning } from "../../../../../components/AlertaModal";
import { Row } from "reactstrap";
import {
  formatNumber,
  formatValueFromAPI,
  roundFloat,
  sumDataField,
} from "../../../../../coreUtils";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";

export const FracionarItemModal = ({
  isOpen,
  toggle,
  idItemFracionar,
  notifyEvent,
}) => {
  const [idProdutoNf, setIdProdutoNf] = useState(null);
  const [nomeProdutoNf, setNomeProdutoNf] = useState(null);
  const [qtdItemNf, setQtdItemNf] = useState(null);
  const [idProdutoFrac, setIdProdutoFrac] = useState(null);
  const [nomeProdutoFrac, setNomeProdutoFrac] = useState(null);
  const [unidadeFrac, setUnidadeFrac] = useState(null);
  const [quantidadeFrac, setQuantidadeFrac] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(false);

  const pesqProdRef = useRef();
  const quantidadeRef = useRef();

  const limparDados = () => {
    setIdProdutoNf(null);
    setNomeProdutoNf(null);
    setQtdItemNf(null);
    setProdutos([]);
    limparDadosProdFrac();
  };

  const limparDadosProdFrac = () => {
    setIdProdutoFrac(null);
    setNomeProdutoFrac(null);
    setUnidadeFrac(null);
    setQuantidadeFrac(null);
  };

  const carregarDados = async () => {
    const [ok, ret] = await FracionamentoNfEntradaService.buscarItemFracionar(
      idItemFracionar
    );
    if (ok) {
      if (ret.fracionado) {
        showWarning("O Item selecionado já foi fracionado");
        notifyEvent();
        return false;
      }
      setIdProdutoNf(ret.id_produto);
      setNomeProdutoNf(ret.nome_produto);
      setQtdItemNf(ret.quantidade);
      return true;
    } else {
      return false;
    }
  };

  const handleSelectProduto = async ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    unidade,
  }) => {
    if (id) {
      setIdProdutoFrac(id);
      setNomeProdutoFrac(nome);
      setUnidadeFrac(unidade);
      pesqProdRef.current.setId(String(id));
      pesqProdRef.current.setDescricao(nome);
      pesqProdRef.current.setReferencia(String(referencia));
    } else {
      setIdProdutoFrac(null);
      setNomeProdutoFrac(null);
      setUnidadeFrac(null);
      pesqProdRef.current.clear();
    }
  };

  const handleAdd = () => {
    if ([0, null, undefined].includes(idProdutoFrac)) {
      showWarning("Por favor, informe o Produto");
      return;
    }

    if ([0, null, undefined].includes(quantidadeFrac)) {
      showWarning("Por favor, informe a Quantidade");
      return;
    }

    if (produtos.some((e) => e.id_produto === idProdutoFrac)) {
      showWarning("O Produto informado já foi adicionado");
      return;
    }
    const payload = {
      id_produto: idProdutoFrac,
      nome_produto: nomeProdutoFrac,
      unidade: unidadeFrac,
      quantidade: quantidadeFrac,
    };

    setProdutos([...produtos, payload]);
    limparDadosProdFrac();
    if (pesqProdRef.current) {
      pesqProdRef.current.clear();
      pesqProdRef.current.focus();
    }
  };

  const handleDelete = (idProduto) => {
    setProdutos(produtos.filter((e) => e.id_produto !== idProduto));
  };

  const handleAlterarProduto = (coluna, novoValor, row) => {
    const valorAntesTest = roundFloat(parseFloat(row[coluna]), 6);
    const novoValorTest = roundFloat(parseFloat(novoValor), 6);
    if (valorAntesTest === novoValorTest) {
      return;
    }

    if (coluna === "quantidade") {
      row.quantidade = parseFloat(novoValor);
    }

    setProdutos(
      produtos.map((e) => (e.id_produto === row.id_produto ? row : e))
    );
  };

  const handleSubmit = async () => {
    if (produtos.length === 0) {
      showWarning(
        "Por favor, informe ao menos um Produto para o fracionamento"
      );
      return;
    }

    setLoading(true);
    const payload = {
      id_nf_entrada_item: idItemFracionar,
      produtos: produtos.map((e) => ({
        id_produto: e.id_produto,
        quantidade: e.quantidade,
      })),
    };
    const [ok] = await FracionamentoNfEntradaService.fracionarItem(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const onBeforeOpen = async () => {
    const ok = await carregarDados();
    if (!ok) toggle();
  };

  const columns = [
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_produto", text: "Nome", align: "left" },
    { dataField: "unidade", text: "UN", align: "center" },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c) => formatNumber(c, true, 6, true),
      editable: true,
      editorType: "number",
      onChange: handleAlterarProduto,
      decimalPlaces: 6,
      alwaysShowEditor: true,
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id_produto)} />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Fracionar Item da NF Entrada"
      number="0110_1"
      onClosed={limparDados}
      onBeforeOpen={onBeforeOpen}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row className="mb-2">
        <FixedField
          label="Produto da NFe"
          value={formatValueFromAPI(nomeProdutoNf, idProdutoNf)}
          horizontal
        />
        <FixedField
          label="Qtd Total da NFe"
          value={formatNumber(qtdItemNf, true, 2, true)}
          horizontal
        />
      </Row>
      <Row>
        <PesqProduto
          md={10}
          onConfirm={handleSelectProduto}
          ref={pesqProdRef}
          selectNextField={() =>
            quantidadeRef.current && quantidadeRef.current.focus()
          }
          onChangeDescricao={() => {}}
          autoFocus
        />
        <FixedField
          label="Unidade"
          value={unidadeFrac}
          horizontal
          divClassName="pt-4"
        />
      </Row>
      <Row className="mb-2">
        <NumberInput
          md={3}
          label="Quantidade"
          value={quantidadeFrac}
          onChange={setQuantidadeFrac}
          ref={quantidadeRef}
          decimalPlaces={6}
        />
        <FormButton color="success" onClick={handleAdd}>
          Incluir
        </FormButton>
      </Row>
      <Table
        data={produtos}
        columns={columns}
        paginated={false}
        pageSize={10}
        showRegisterCount={false}
      />
      <Row>
        <FixedField
          label="Produtos Lançados"
          value={formatNumber(
            sumDataField(produtos, "quantidade"),
            true,
            2,
            true
          )}
          horizontal
        />
      </Row>
    </ModalBase>
  );
};
