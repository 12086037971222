import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  idNfe: null,
  numero: null,
  serie: null,
  dataEmi: new Date(),
  origem: "MAN",
  emprOrigem: 1,
  idOrigem: 0,
  tipoNf: "",
  idTipoDoc: null,
  finalidadeOperTipoDoc: "VENDA",
  aVista: false,
  exigeInfoFatura: true,
  idCliente: null,
  nomeCliente: null,
  dadosDest: {
    cpfCnpj: null,
    inscricaoEstadual: null,
    endereco: null,
    cep: null,
    cidade: null,
    ufCidade: null,
    obrigInfoOrdemCompraNfe: null,
    fone1: null,
  },
  infoAdic: "",
  faturas: [],
  formasPag: [],
  frete: {
    idTransportadora: null,
    enderecoTransportadora: null,
    municipioTransportadora: null,
    ufTransportadora: null,
    placa: "",
    uf: "",
    rntrc: "",
    qtdeVolumes: 0,
    especie: "",
    marca: "",
    numero: "",
    porConta: "9",
    pesoBruto: 0,
    pesoLiquido: 0,
  },
  totaisNfe: {
    baseCalcIcms: 0,
    vlrTotIcms: 0,
    baseCalcIcmsSt: 0,
    vlrTotIcmsSt: 0,
    vlrTotIpi: 0,
    vlrFrete: 0,
    vlrSeguro: 0,
    vlrOutros: 0,
    vlrTotDesc: 0,
    vlrTotFatura: 0,
    vlrTotProd: 0,
    vlrTotNf: 0,
  },
};

export const cabNF = createSlice({
  name: "cab_nf",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setIdNfe: (state, action) => {
      state.idNfe = action.payload;
    },
    setDataEmi: (state, action) => {
      state.dataEmi = action.payload;
    },
    setNumero: (state, action) => {
      state.numero = action.payload;
    },
    setSerie: (state, action) => {
      state.serie = action.payload;
    },
    setOrigem: (state, action) => {
      state.origem = action.payload;
    },
    setEmprOrigem: (state, action) => {
      state.emprOrigem = action.payload;
    },
    setIdOrigem: (state, action) => {
      state.idOrigem = action.payload;
    },
    setTipoNf: (state, action) => {
      state.tipoNf = action.payload;
    },
    setIdTipoDoc: (state, action) => {
      state.idTipoDoc = action.payload;
    },
    setIdCliente: (state, action) => {
      state.idCliente = action.payload;
    },
    setNomeCliente: (state, action) => {
      state.nomeCliente = action.payload;
    },
    setInfoAdic: (state, action) => {
      state.infoAdic = action.payload;
    },
    setFaturas: (state, action) => {
      state.faturas = action.payload;
    },
    setFormasPag: (state, action) => {
      state.formasPag = action.payload;
    },
    setIdTransportadoraFrete: (state, action) => {
      state.frete.idTransportadora = action.payload;
    },
    setEnderecoTransportadoraFrete: (state, action) => {
      state.frete.enderecoTransportadora = action.payload;
    },
    setMunicipioTransportadoraFrete: (state, action) => {
      state.frete.municipioTransportadora = action.payload;
    },
    setUfTransportadoraFrete: (state, action) => {
      state.frete.ufTransportadora = action.payload;
    },
    setPlacaVeicFrete: (state, action) => {
      state.frete.placa = action.payload;
    },
    setUfVeicFrete: (state, action) => {
      state.frete.uf = action.payload;
    },
    setRntrcFrete: (state, action) => {
      state.frete.rntrc = action.payload;
    },
    setQtdeVolumesFrete: (state, action) => {
      state.frete.qtdeVolumes = action.payload;
    },
    setEspecieFrete: (state, action) => {
      state.frete.especie = action.payload;
    },
    setMarcaFrete: (state, action) => {
      state.frete.marca = action.payload;
    },
    setNumeroVolumeFrete: (state, action) => {
      state.frete.numero = action.payload;
    },
    setFretePorConta: (state, action) => {
      state.frete.porConta = action.payload;
    },
    setPesoBrutoFrete: (state, action) => {
      state.frete.pesoBruto = action.payload;
    },
    setPesoLiquidoFrete: (state, action) => {
      state.frete.pesoLiquido = action.payload;
    },
    setCpfCnpjDestinatario: (state, action) => {
      state.dadosDest.cpfCnpj = action.payload;
    },
    setInscricaoEstadualDestinatario: (state, action) => {
      state.dadosDest.inscricaoEstadual = action.payload;
    },
    setEnderecoDestinatario: (state, action) => {
      state.dadosDest.endereco = action.payload;
    },
    setCepDestinatario: (state, action) => {
      state.dadosDest.cep = action.payload;
    },
    setCidadeDestinatario: (state, action) => {
      state.dadosDest.cidade = action.payload;
    },
    setUfCidadeDestinatario: (state, action) => {
      state.dadosDest.ufCidade = action.payload;
    },
    setFone1Destinatario: (state, action) => {
      state.dadosDest.fone1 = action.payload;
    },
    setObrigInfoOrdemCompraNfeDestinatario: (state, action) => {
      state.dadosDest.obrigInfoOrdemCompraNfe = action.payload;
    },
    setFinalidadeOperTipoDoc: (state, action) => {
      state.finalidadeOperTipoDoc = action.payload;
    },
    setAVista: (state, action) => {
      state.aVista = action.payload;
    },
    setExigeInfoFatura: (state, action) => {
      state.exigeInfoFatura = action.payload;
    },
    setTotaisNfe: (state, action) => {
      state.totaisNfe = { ...state.totaisNfe, ...action.payload };
    },
  },
});

export const {
  init,
  setup,
  setNumero,
  setSerie,
  setDataEmi,
  setOrigem,
  setEmprOrigem,
  setIdOrigem,
  setTipoNf,
  setIdTipoDoc,
  setIdCliente,
  setNomeCliente,
  setInfoAdic,
  setFaturas,
  setFormasPag,
  setIdTransportadoraFrete,
  setPlacaVeicFrete,
  setUfVeicFrete,
  setRntrcFrete,
  setEspecieFrete,
  setMarcaFrete,
  setNumeroVolumeFrete,
  setFretePorConta,
  setPesoBrutoFrete,
  setPesoLiquidoFrete,
  setCepDestinatario,
  setCidadeDestinatario,
  setFone1Destinatario,
  setCpfCnpjDestinatario,
  setEnderecoDestinatario,
  setInscricaoEstadualDestinatario,
  setQtdeVolumesFrete,
  setUfCidadeDestinatario,
  setIdNfe,
  setFinalidadeOperTipoDoc,
  setObrigInfoOrdemCompraNfeDestinatario,
  setAVista,
  setExigeInfoFatura,
  setEnderecoTransportadoraFrete,
  setMunicipioTransportadoraFrete,
  setUfTransportadoraFrete,
  setTotaisNfe,
} = cabNF.actions;

export default cabNF.reducer;
