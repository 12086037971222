import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Label, Progress, Row } from "reactstrap";
import {
  FormButton,
  FiltroPeriodoDatas,
  RadioGroup,
} from "../../../../../components";
import DemonstrativoDeResultadoService from "../../../../../services/controladoria/DemonstrativoResultadoService";
import {
  setDataGrafico,
  setDespesas,
  setFimPeriodo,
  setIniPeriodo,
  setReceitas,
  setRegime,
  setResultado,
} from "../store/dreSlice";
import { RadioItem } from "../../../../../components/RadioGroup";

export const CardFiltros = () => {
  const store = useSelector((state) => state.dre);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingLabel, setLoadingLabel] = useState("");

  const limparDados = () => {
    dispatch(setDespesas([]));
    dispatch(setReceitas([]));
    dispatch(setDataGrafico([]));
    dispatch(setResultado({}));
  };

  const handleSelectDate = (_dataIni, _dataFim) => {
    dispatch(
      setIniPeriodo(
        typeof _dataIni === "string" || [null, undefined].includes(_dataIni)
          ? null
          : moment(_dataIni).format("YYYY-MM-DD")
      )
    );

    dispatch(
      setFimPeriodo(
        typeof _dataFim === "string" || [null, undefined].includes(_dataFim)
          ? null
          : moment(_dataFim).format("YYYY-MM-DD")
      )
    );
    limparDados();
  };

  const carregarDados = async () => {
    limparDados();
    setIsLoading(true);
    try {
      setLoadingProgress(0);
      setLoadingLabel("Buscando Despesas");
      dispatch(
        setDespesas(
          await DemonstrativoDeResultadoService.buscarDespesas(
            store.iniPeriodo,
            store.fimPeriodo,
            store.regime
          )
        )
      );
      setLoadingProgress(25);
      setLoadingLabel("Buscando Receitas");
      dispatch(
        setReceitas(
          await DemonstrativoDeResultadoService.buscarReceitas(
            store.iniPeriodo,
            store.fimPeriodo,
            store.regime
          )
        )
      );
      setLoadingProgress(50);
      setLoadingLabel("Gerando Gráfico");
      dispatch(
        setDataGrafico(
          await DemonstrativoDeResultadoService.buscarDadosGrafico(
            store.iniPeriodo,
            store.fimPeriodo,
            store.regime
          )
        )
      );
      setLoadingProgress(75);
      setLoadingLabel("Buscando Resultados");
      dispatch(
        setResultado(
          await DemonstrativoDeResultadoService.buscarResultado(
            store.iniPeriodo,
            store.fimPeriodo,
            store.regime
          )
        )
      );
      setLoadingProgress(100);
      setIsLoading(false);
      setLoadingProgress(0);
      setLoadingLabel("");
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <Card body>
      <Row>
        <FiltroPeriodoDatas onChange={handleSelectDate} />
        <RadioGroup
          label="Regime do Relatório"
          value={store.regime}
          onChange={(value) => {
            dispatch(setRegime(value ?? "CXA"));
            limparDados();
          }}
        >
          <RadioItem
            id="radio-regime-caixa"
            label="Regime de Caixa"
            value="CXA"
            checked={store.regime === "CXA"}
          />
          <RadioItem
            id="radio-regime-competencia"
            label="Regime de Competência"
            value="CMP"
            checked={store.regime === "CMP"}
          />
        </RadioGroup>
      </Row>
      <Row>
        <FormButton
          color="secondary"
          loading={isLoading}
          disabled={!store.iniPeriodo || !store.fimPeriodo}
          onClick={carregarDados}
          disabledHint="Informe as datas de início e fim"
        >
          Gerar DRE
        </FormButton>
        {isLoading && (
          <Col md={5}>
            <Label style={{ marginTop: "0.9rem" }}>{loadingLabel}</Label>
            <Progress
              color="success"
              value={loadingProgress}
              style={{ height: "1.1rem" }}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};
