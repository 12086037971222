import React from "react";
export const hintsTipoCarteira = (layout) => {
  switch (layout) {
    case "BAN":
      return (
        <>
          1 - Cobrança Simples
          <br />
          2 - Cobrança Vinculada
          <br />
          3 - Cobrança Caucionada
          <br />
          4 - Cobrança Descontada
          <br />
        </>
      );
    case "SIC":
      return <>A - Modalidade Simples</>;
    case "BDB":
      // 1 – para carteira 11/12 na modalidade Simples;
      // 2 ou 3 – para carteira 11/17 modalidade Vinculada/Caucionada e carteira 31;
      // 4 – para carteira 11/17 modalidade Descontada e carteira 51;
      // 7 – para carteira 17 modalidade Simples;
      // 8 – para Prêmio de Seguro.
      return <>17 - Modalidade Simples</>;
    case "SIB":
      return (
        <>
          01 - Simples Com Registro
          <br />
          03 - Garantida Caucionada
        </>
      );
    case "ITA":
      return (
        <>
          I - Direta Eletrônica Emissão Integral - Carnê
          <br />
          I - Direta Eletrônica Emissão Integral - Simples
          <br />
          I - Direta Eletrônica Emissão Parcial - Simples
          <br />
          U - Direta Eletrônica Sem Emissão - Dólar
          <br />
          I - Direta Eletrônica Sem Emissão - Simples
          <br />
          1 - Duplicatas - Transferência de Desconto
          <br />
          I - Escritural Eletrônica - Carnê
          <br />
          I - Escritural Eletronica - Cobrança Inteligente (B2B)
          <br />
          E - Escritural Eletrônica - Dólar
          <br />
          I - Escritural Eletrônica - Simples
          <br />
          I - Escritural Eletronica - Simples - Faixa Nosso Número Livre
          <br />
        </>
      );
    case "SAN":
      return (
        <>
          1 - Cobrança Simples (Sem Registro e Eletrônica com Registro)
          <br />
          3 - Cobrança Caucionada (Eletrônica com Registro e Convencional com
          Registro)
          <br />
          4 - Cobrança Descontada (Eletrônica com Registro)
          <br />
          5 - Cobrança Simples (Rápida com Registro)
          <br />
          6 - Cobrança Caucionada (Rápida com Registro)
          <br />
          7 - Transferência de Titularidade - Sem Devolução (Cobrança Simples -
          Eletrônica com Registro e Rápida com Registro)
          <br />
          8 - Cobrança Cessão (Eletrônica com Registro)
          <br />
          9 - Transferência de Titularidade - Com Devolução (Cobrança Simples -
          Eletrônica com Registro e Rápida com Registro)
          <br />
          B - Cobrança Simples (Sem Registro sem pré-impresso e com
          pré-impresso)
          <br />
        </>
      );
    case "BRA":
      return null;
    default:
      return null;
  }
};

export const hintsEspecieTitulo = (layout) => {
  switch (layout) {
    case "BAN":
      return (
        <>
          02 - Duplicata Mercantil
          <br />
          03 - Duplicata Mercantil p/ Indicação
          <br />
          04 - Duplicata de Serviço
          <br />
          05 - Duplicata de Serviço p/ Indicação
          <br />
          07 - Letra de Câmbio
          <br />
          12 - Nota Promissória
          <br />
          22 - Parcela de Consórcio
          <br />
          31 - Cartão de Crédito
          <br />
          32 - Boleto de Proposta
          <br />
          99 - Outros
        </>
      );
    case "SIC":
      return (
        <>
          A - Duplicata Mercantil por Indicação
          <br />
          B - Duplicata Rural
          <br />
          C - Nota Promissória
          <br />
          D - Nota Promissória Rural
          <br />
          E - Nota de Seguros
          <br />
          G - Recibo
          <br />
          H - Letra de Câmbio
          <br />
          I - Nota de Débito
          <br />
          J - Duplicata de Serviço por Indicação
          <br />
          K - Outros
          <br />
          O - Boleto Proposta
          <br />
        </>
      );
    case "BDB":
      return (
        <>
          02 - Duplicata mercantil
          <br />
          04 - Duplicata de Serviço
        </>
      );
    case "SIB":
      return (
        <>
          01 - Cheque
          <br />
          02 - Duplicata Mercantil
          <br />
          03 - Duplicata Mercantil p/ Indicação
          <br />
          04 - Duplicata de Serviço
          <br />
          05 - Duplicata de Serviço p/ Indicação
          <br />
          06 - Duplicata Rural
          <br />
          07 - Letra de Câmbio
          <br />
          08 - Nota de Crédito Comercial
          <br />
          09 - Nota de Crédito a Exportação
          <br />
          10 - Nota de Crédito Industrial
          <br />
          11 - Nota de Crédito Rural
          <br />
          12 - Nota Promissória
          <br />
          13 - Nota Promissória Rural
          <br />
          14 - Triplicata Mercantil
          <br />
          15 - Triplicata de Serviço
          <br />
          16 - Nota de Seguro
          <br />
          17 - Recibo
          <br />
          18 - Fatura
          <br />
          19 - Nota de Débito
          <br />
          20 - Apólice de Seguro
          <br />
          21 - Mensalidade Escolar
          <br />
          22 - Parcela de Consórcio
          <br />
          23 - Nota Fiscal
          <br />
          24 - Documento de Dívida
          <br />
          25 - Cédula de Produto Rural
          <br />
          31 - Cartão de Crédito
          <br />
          32 - Boleto de Proposta
          <br />
          99 - Outros
        </>
      );
    case "ITA":
      return (
        <>
          01 - Duplicata Mercantil
          <br />
          02 - Nota Promissória
          <br />
          03 - Nota de Seguro
          <br />
          04 - Mensalidade Escolar
          <br />
          05 - Recibo
          <br />
          06 - Contrato
          <br />
          07 - Cosseguros
          <br />
          08 - Duplicata de Serviço
          <br />
          09 - Letra de Câmbio
          <br />
          13 - Nota de Débitos
          <br />
          15 - Documento de Dívida
          <br />
          16 - Encargos Condominiais
          <br />
          17 - Conta de Prestação de Serviços
          <br />
          18 - Boleto de Proposta
          <br />
          99 - Diversos <br />
        </>
      );
    case "SAN":
      // 02 DM - DUPLICATA MERCANTIL
      // 04 DS - DUPLICATA DE SERVICO
      // 07 LC - LETRA DE CÂMBIO (SOMENTE PARA BANCO 353)
      // 30 LC - LETRA DE CÂMBIO (SOMENTE PARA BANCO 008)
      // 12 NP - NOTA PROMISSORIA
      // 13 NR - NOTA PROMISSORIA RURAL
      // 17 RC - RECIBO
      // 20 AP – APOLICE DE SEGURO
      // 31 BCC – CARTÃO DE CRÉDITO
      // 32 BDP – BOLETO DE PROPOSTA
      // 33 BDA – BOLETO DE DEPOSITO E APORTE
      // 97 CH – CHEQUE
      // 98 ND - NOTA PROMISSORIA DIRETA
      return (
        <>
          02 - Duplicata Mercantil
          <br />
          04 - Duplicata De servico
          <br />
          07 - Letra de Câmbio (somente para banco 353)
          <br />
          30 - Letra de Câmbio (somente para banco 008)
          <br />
          12 - Nota Promissoria
          <br />
          13 - Nota Promissoria Rural
          <br />
          17 - Recibo
          <br />
          20 – Apolice de Seguro
          <br />
          31 – Cartão de Crédito
          <br />
          32 – Boleto de Proposta
          <br />
          33 – Boleto de Deposito e Aporte
          <br />
          97 – Cheque
          <br />
          98 - Nota Promissoria Direta
        </>
      );
    case "SAF": // Banco Safra
      return (
        <>
          01 - Duplicata Mercantil
          <br />
          02 - Nota Promissória
          <br />
          03 - Nota de Seguro
          <br />
          05 - Recibo
          <br />
          09 - Duplicata de Serviço
        </>
      );
    case "BRA":
      return (
        <>
          01 - Duplicata
          <br />
          02 - Nota Promissória
          <br />
          03 - Nota de Seguro
          <br />
          05 - Recibo
          <br />
          10 - Letras de Câmbio
          <br />
          11 - Nota de Débito
          <br />
          12 - Duplicata de Serviço
          <br />
          31 - Cartão de Crédito
          <br />
          32 - Boleto de Proposta
          <br />
          33 - Depósito e Aporte
          <br />
          99 - Outros
          <br />
        </>
      );
    case "CAI":
      return (
        <>
          01 - Duplicata Mercantil
          <br />
          02 - Nota Promissória
          <br />
          03 - Duplicata de Prestação de Serviços
          <br />
          04 - Cheque
          <br />
          05 - Nota de Seguro
          <br />
          06 - Letra de Câmbio
          <br />
          07 - Duplicata Mercantil p/ Indicação
          <br />
          08 - Nota de Crédito Comercial
          <br />
          09 - Outros
          <br />
          10 - Nota de Crédito Industrial
          <br />
          11 - Nota de Crédito Rural
          <br />
          12 - Duplicata de Serviço
          <br />
          13 - Nota Promissória Rural
          <br />
          14 - Triplicata Mercantil
          <br />
          15 - Triplicata de Serviço
          <br />
          16 - Duplicata Rural
          <br />
          17 - Recibo
          <br />
          18 - Fatura
          <br />
          19 - Nota de Débito
          <br />
          20 - Apólice de Seguro
          <br />
          21 - Mensalidade Escolar
          <br />
          22 - Parcela de Consórcio
          <br />
          23 - Nota Fiscal
          <br />
          24 - Documento de Dívida
          <br />
          25 - Cédula de Produto Rural
          <br />
          26 - Nota de Crédito à Exportação
          <br />
          30 - Encargos Condominiais
          <br />
          31 - Cartão de Crédito
          <br />
          32 - Boleto Proposta
        </>
      );
    case "CRE":
      return (
        <>
          01 - Cheque
          <br />
          02 - Duplicata mercantil
          <br />
          04 - Duplicata de serviço
          <br />
          06 - Duplicata rural
          <br />
          07 - Letra de câmbio
          <br />
          12 - Nota promissória
          <br />
          17 - Recibo
          <br />
          19 - Nota de débito
          <br />
          26 - Warrant
          <br />
          27 - Dívida ativa de estado
          <br />
          28 - Dívida ativa de município
          <br />
          29 - Dívida ativa da união
          <br />
          30 - Encargos condominiais
          <br />
          32 - Boleto Proposta
          <br />
          99 - Outros
        </>
      );
    case "GRA":
      return (
        <>
          01 - Duplicata
          <br />
          02 - Nota promissória
          <br />
          03 - Nota de seguro
          <br />
          04 - Cobrança Seriada
          <br />
          05 - Recibo
          <br />
          10 - Letras de câmbio
          <br />
          11 - Nota de débito
          <br />
          12 - Duplicata de serviço
          <br />
          31 - Cartão de crédito
          <br />
          32 - boleto de proposta
          <br />
          99 - Outros
        </>
      );
    default:
      return null;
  }
};

export const hintsVariacaoCarteira = (layout) => {
  switch (layout) {
    case "BDB":
      return <>Variação definida pela Agência Bancária. Exemplo: 019</>;
    case "ITA":
      return (
        <>
          108 - Direta Eletrônica Emissão Integral - Carnê
          <br />
          180 - Direta Eletrônica Emissão Integral - Simples
          <br />
          121 - Direta Eletrônica Emissão Parcial - Simples
          <br />
          150 - Direta Eletrônica Sem Emissão - Dólar
          <br />
          109 - Direta Eletrônica Sem Emissão - Simples
          <br />
          191 - Duplicatas - Transferência de Desconto
          <br />
          104 - Escritural Eletrônica - Carnê
          <br />
          188 - Escritural Eletronica - Cobrança Inteligente (B2B)
          <br />
          147 - Escritural Eletrônica - Dólar
          <br />
          112 - Escritural Eletrônica - Simples
          <br />
          115 - Escritural Eletronica - Simples - Faixa Nosso Número Livre
          <br />
        </>
      );
    default:
      return null;
  }
};
