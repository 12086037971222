import { toastr } from "react-redux-toastr";
import { api } from "../api";

const FluxoDeCaixaService = {
  fetch: async (dataInicio, dataFim, considerar_saldo_ini) => {
    dataInicio = dataInicio.toISOString().split("T")[0];
    dataFim = dataFim.toISOString().split("T")[0];
    return await api
      .get(
        `/controladoria/fluxo_caixa/?data_ini=${dataInicio}&data_fim=${dataFim}&saldo_inicial=${considerar_saldo_ini}`
      )
      .then((ret) => {
        if (!ret.data.err) {
          return ret.data.res;
        } else {
          toastr.error("Erro", ret.data.msg);
          return false;
        }
      })
      .catch((err) => {
        toastr.error("Erro", err.message);
        return false;
      });
  },
};

export default FluxoDeCaixaService;
