import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  PageContainer,
  PesqMultiPlanoCtaCentroCusto,
} from "../../../../components";
import { formatDateISO } from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";
import { LinhaCentroCusto } from "./components/LinhaCentroCusto";

export const DemonstrativoDespesasPorCC = () => {
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [idCentroCusto, setIdCentroCusto] = useState([]);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [dadosRelatorio, setDadosRelatorio] = useState([]);
  const [loadingImpressao, setLoadingImpressao] = useState(false);
  const [loadingBusca, setLoadingBusca] = useState(false);

  const handleSelectDate = async (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      id_fornecedor: idFornecedor,
      id_centro_custo: idCentroCusto,
      ini_periodo: dataIni,
      fim_periodo: dataFim,
    };

    return await apiGetV2(
      "/controladoria/demonstrativo_despesas_por_centro_custo/",
      params
    );
  };

  const carregarDados = async () => {
    setLoadingBusca(true);
    const [ok, ret] = await buscarDados();
    setDadosRelatorio(ok ? ret : []);
    setLoadingBusca(false);
  };

  const gerarRelatorio = async () => {
    setLoadingImpressao(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      const payload = {
        itens: ret,
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
      };
      await docPrintReport(
        "/relatorios/financeiro_pagar/despesas_por_centro_custo/",
        payload,
        "5016"
      );
    }

    setLoadingImpressao(false);
  };

  return (
    <PageContainer
      title="Despesas Liquidadas por Centro de Custo"
      number="5016"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleSelectDate} />
          <AsyncComboBox
            md={4}
            label="Fornecedor"
            isConcatField
            concatModelName="fornecedor"
            isSearchable
            isClearable
            onChange={(s) => setIdFornecedor(s?.value)}
          />
        </Row>
        <Row>
          <PesqMultiPlanoCtaCentroCusto
            label="Centro de Custo"
            mode="centro_custo"
            onChange={(s) => setIdCentroCusto(s)}
            value={idCentroCusto}
          />
          <BotaoPesquisar
            loading={loadingBusca}
            onClick={carregarDados}
            disabled={!(dataIni instanceof Date) || !(dataFim instanceof Date)}
            disabledHint="Informe datas válidas de início e fim"
          />
          <BotaoImprimir
            loading={loadingImpressao}
            onClick={gerarRelatorio}
            disabled={!(dataIni instanceof Date) || !(dataFim instanceof Date)}
            disabledHint="Informe datas válidas de início e fim"
          />
        </Row>
      </Card>
      {dadosRelatorio.length > 0 && (
        <Card body>
          <ul className="dre-list">
            {dadosRelatorio.map((item, index) => (
              <LinhaCentroCusto key={index} row={item} />
            ))}
          </ul>
        </Card>
      )}
    </PageContainer>
  );
};
