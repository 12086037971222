import React from "react";
import {
  FixedTable,
  HintValue,
  NumberInput,
} from "../../../../../../components";
import { formatNumber } from "../../../../../../coreUtils";
import cellEditFactory from "react-bootstrap-table2-editor";
import { toastr } from "react-redux-toastr";

const columns = [
  {
    dataField: "moeda",
    text: "Forma de Recebimento",
    align: "center",
    headerAlign: "center",
    editable: false,
  },
  {
    dataField: "numero",
    text: "Número",
    align: "center",
    headerAlign: "center",
    editable: false,
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
    headerAlign: "center",
    editable: false,
  },
  {
    dataField: "vencimento",
    text: "Vencimento",
    align: "center",
    headerAlign: "center",
    editable: false,
  },
  {
    dataField: "status",
    text: "Status",
    align: "center",
    headerAlign: "center",
    editable: false,
  },
  {
    dataField: "vlr_orig",
    text: "Vlr. Original",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    editable: false,
  },
  {
    dataField: "vlr_desc",
    text: "Descontos",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    editable: false,
  },
  {
    dataField: "vlr_acre",
    text: "Acréscimos",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    editable: false,
  },
  {
    dataField: "vlr_rec",
    text: "Vlr. Receber",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    editable: false,
  },
  {
    dataField: "vlr_devolver",
    text: "Vlr. Devolver",
    align: "right",
    headerAlign: "right",
    formatter: (c, row) => (
      <HintValue
        enabled={parseFloat(row.valor) === 0}
        hint="A Forma de Recebimento já foi totalmente devolvida."
        value={formatNumber(c, true, 2)}
        tooltipPlacement="left"
      />
    ),
    headerStyle: () => ({ width: "10%" }),
    editCellStyle: () => ({ padding: 0, verticalAlign: "middle" }),
    editable: (c, row) => parseFloat(row.vlr_rec) > 0,
    editorRenderer: (editorProps, value) => (
      <NumberInput
        md={10}
        divClassName="ml-auto"
        table
        value={value}
        onBlur={(e, v) => editorProps.onUpdate(v)}
        decimalPlaces={2}
      />
    ),
    validator: (newValue, row) => {
      if (!isNaN(newValue) && newValue >= 0) {
        if (newValue > parseFloat(row.vlr_rec)) {
          toastr.warning(
            "Atenção",
            "O valor informado não pode ser superior ao valor da Forma de Recebimento."
          );
          return { valid: false, message: "" };
        }
        return true;
      } else {
        toastr.warning("Atenção", "Por favor, informe um valor válido.");
        return { valid: false, message: "" };
      }
    },
  },
];

export const DevolverFormasRecGrid = ({ formasRec, atualizarVlrRestit }) => {
  return (
    <FixedTable
      columns={columns}
      keyField="id_moeda"
      data={formasRec}
      style={{ heigh: "300px" }}
      cellEdit={cellEditFactory({
        mode: "click",
        afterSaveCell: (oldValue, newValue, row, column) => {
          atualizarVlrRestit();
        },
        blurToSave: true,
        autoSelectText: true,
        timeToCloseMessage: 0,
      })}
      hideCount
    />
  );
};
