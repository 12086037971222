import React from "react";
import { useSelector } from "react-redux";
import { Card, Col, Row } from "reactstrap";
import { formatNumber } from "../../../../../coreUtils";

export const CardOutrosValores = () => {
  const store = useSelector((state) => state.dre);
  if (parseFloat(store.resultado.outros ?? "0") > 0) {
    return (
      <Card body>
        <Row>
          <Col>
            <h6 style={{ fontWeight: "bold", marginBottom: "0" }}>
              Não Especificado | Outros: R$
              {formatNumber(store.resultado.outros, true, 2)}
            </h6>
          </Col>
        </Row>
      </Card>
    );
  } else {
    return <div></div>;
  }
};
