import { apiGetV2 } from "../../../apiV2";

const URL_BASE = "/relatorios/pedido_venda";
const URL_BASE_PENDENTE_ENTREGA = `${URL_BASE}/pendente_entrega`;

const PedidosPendentesEntregaService = {
  buscarDados: async (params) =>
    await apiGetV2(`${URL_BASE_PENDENTE_ENTREGA}/`, params),
  buscarItensPedido: async (idPedido) =>
    await apiGetV2(`${URL_BASE_PENDENTE_ENTREGA}/itens/${idPedido}/`),
  buscarAgrupadoProduto: async () =>
    await apiGetV2(`${URL_BASE}/produtos_pendentes_entrega/`),
  buscarAgrupadoCliente: async () =>
    await apiGetV2(`${URL_BASE}/produtos_pendentes_entrega_por_cli/`),
};

export default PedidosPendentesEntregaService;
