import React, { useEffect, useState } from "react";
import { Search } from "react-feather";
import { Col, Label, Spinner } from "reactstrap";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { formatValueFromAPI } from "../../../coreUtils";
import { BsX } from "react-icons/bs";
import { PesqPlanoCtaCentroCustoModal } from "./PesqPlanoCtaCentroCustoModal";
import { apiGetV2 } from "../../../apiV2";
import { toastr } from "react-redux-toastr";

export const PesqMultiPlanoCtaCentroCusto = ({
  onChange = () => {},
  md = 4,
  mode = "plano_contas",
  value,
  isClearable = true,
  label,
  divClassName,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkedLabels, setCheckedLabels] = useState([]);
  const [options, setOptions] = useState([]);
  const [loadingPesq, setLoadingPesq] = useState(false);
  const urlBaseLength = mode === "plano_contas" ? 169 : 140;
  const urlQueryParamBaseLength = mode === "plano_contas" ? 16 : 17;

  const toggle = async () => {
    if (!isOpen) {
      setLoadingPesq(true);
      const ok = await loadOptions();
      setLoadingPesq(false);
      if (!ok) return;
    } else {
      setOptions([]);
    }
    setIsOpen(!isOpen);
  };

  const updateLabels = (v) => {
    let checkedLabs = [];

    function eachRecursiveMulti(obj) {
      for (var k in obj) {
        if (typeof obj[k] == "object" && obj[k] !== null) {
          eachRecursiveMulti(obj[k]);
        } else {
          if (k === "value" && v.includes(obj["value"])) {
            checkedLabs.push(formatValueFromAPI(obj["label"], obj["value"]));
          }
        }
      }
    }

    eachRecursiveMulti(options);

    setCheckedLabels(checkedLabs);
  };

  useEffect(() => {
    if (![0, null, undefined].includes(value) && options.length > 0) {
      updateLabels(value);
    }
  }, [value, options.length]);

  const handleSelectPlanoConta = (v, s) => {
    if (v.length > 0) {
      const urlSize =
        urlBaseLength +
        v
          .map((e) => e.toString().length + urlQueryParamBaseLength)
          .reduce((a, b) => a + b);

      if (urlSize > 4080) {
        toastr.warning("Atenção", "Não é possível selecionar mais itens");
        return false;
      }
    }

    onChange(
      v.map((e) => parseInt(e)),
      s
    );
  };

  const loadOptions = async () => {
    const [ok, ret] = await apiGetV2(
      mode === "plano_contas"
        ? "/plano_contas/pesquisa/"
        : "/cadastro/centro_custo/pesquisa/"
    );
    setOptions(ok ? ret : []);
    return ok;
  };

  const clearSelection = () => {
    onChange([]);
    setCheckedLabels([]);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "F3") {
      e.preventDefault();
      toggle();
    }
  };

  return (
    <>
      <Col md={md} className={divClassName}>
        <Label>{label}</Label>
        <div
          tabIndex={0}
          className="form-control"
          style={{
            display: "flex",
            minHeight: "calc(1.8125rem + 2px)",
            maxHeight: "100px",
            height: "auto",
            backgroundColor: disabled && "#e9ecef",
            pointerEvents: disabled && "none",
            userSelect: disabled && "none",
          }}
          onKeyDown={handleOnKeyDown}
        >
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              flexWrap: "wrap",
              overflowY: "auto",
              cursor: "pointer",
            }}
            onClick={() => toggle()}
          >
            {!value || value.length === 0 ? (
              <span
                className="react-select__placeholder"
                style={{
                  color: "hsl(0,0%,50%)",
                  marginLeft: "2px",
                  marginRight: "2px",
                  boxSizing: "border-box",
                  display: "flex",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                Selecione...
              </span>
            ) : (
              checkedLabels.map((e) => (
                <span
                  style={{
                    paddingInline: "4px",
                    paddingBlock: "2px",
                    backgroundColor: "#EEE",
                    borderRadius: "3px",
                    margin: "2px",
                  }}
                >
                  {e}
                </span>
              ))
            )}
          </div>
          {!disabled && (
            <>
              {isClearable &&
                ![0, null, undefined].includes(value) &&
                value.length > 0 && (
                  <>
                    <div
                      style={{
                        paddingInlineStart: "2px",
                        paddingInlineEnd: "3px",
                        height: "auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <BsX
                        size={15}
                        style={{ marginTop: "2px", cursor: "pointer" }}
                        onClick={() => clearSelection()}
                      />
                    </div>
                  </>
                )}
              <div
                style={{
                  borderLeft: "1px solid silver",
                  marginTop: "2px",
                  height: "auto",
                }}
              />
              <div
                style={{
                  paddingLeft: "6px",
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {loadingPesq ? (
                  <Spinner size="sm" style={{ width: 15, height: 15 }} />
                ) : (
                  <Search
                    size={15}
                    style={{ marginTop: "1px", cursor: "pointer" }}
                    onClick={() => toggle()}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Col>
      <PesqPlanoCtaCentroCustoModal
        isOpen={isOpen}
        toggle={toggle}
        options={options}
        checked={value}
        onCheck={handleSelectPlanoConta}
      />
    </>
  );
};
