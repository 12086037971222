import React from "react";
import { Table } from "../../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../../coreUtils";

export const HistComprasProdutoGrid = ({ dados, usaPrecoAtacado }) => {
  const columns = [
    { dataField: "data_entrada", text: "Entrada", align: "center" },
    {
      dataField: "nome_fornecedor",
      text: "Fornecedor",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_fornecedor),
    },
    {
      dataField: "custo",
      text: "Custo",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "perc_lucro",
      text: "Margem",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true) + "%",
    },
    {
      dataField: "preco_venda",
      text: "Preço Venda",
      align: "right",
      formatter: (c) => (c ? formatNumber(c, true, 2) : "Não Disponível"),
    },
    {
      dataField: "perc_lucro_atacado",
      text: "Margem Atacado",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true) + "%",
      hidden: !usaPrecoAtacado,
    },
    {
      dataField: "preco_atacado",
      text: "Preço Atacado",
      align: "right",
      formatter: (c) => (c ? formatNumber(c, true, 2) : "Não Disponível"),
      hidden: !usaPrecoAtacado,
    },
  ];
  return (
    <Table
      data={dados}
      columns={columns}
      pageSize={3}
      paginated={false}
      showRegisterCount={false}
    />
  );
};
