import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Row } from "reactstrap";
import { AsyncComboBox, ComboBox } from "../../../../components";
import { BaseInfoCard } from "../../../../components/BaseInfoCard";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import TimeSeriesLineChart from "../../../../components/LineChart";
import { PageContainer } from "../../../../components/PageContainer";
import VendasPorPeriodoService from "../../../../services/VendasPorPeriodoService";
import { userPodeVerTotaisRelVenda } from "../../../../coreUtils";

const grouping_options = [
  { value: "per", label: "Período (datas)" },
  { value: "ytd", label: "Ano corrente (Year to Date)" },
  { value: "mtd", label: "Mês corrente (Month to Date)" },
];

const agrupar_por_options = [
  { value: "mes", label: "Mês" },
  { value: "ano", label: "Ano" },
  { value: "dia", label: "Dia" },
];

export const VendasPorVendedor = () => {
  const podeVerTotais = userPodeVerTotaisRelVenda();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [series, setSeries] = useState([]);
  const [total, setTotal] = useState(0);
  const [grouping, setGrouping] = useState(grouping_options[0].value);
  const [agruparPor, setAgruparPor] = useState(
    (!podeVerTotais ? agrupar_por_options[2] : agrupar_por_options[0]).value
  );
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [vendedor, setVendedor] = useState([]);

  const limparDados = () => {
    setData([]);
    setSeries([]);
    setTotal(0);
  };

  const handleDate = (dataIni, dataFim) => {
    setDataIni(dataIni);
    setDataFim(dataFim);
    limparDados();
  };

  const handleSetGrouping = (selected) => {
    setGrouping(selected?.value);
    limparDados();
  };

  const handleSetAgruparPor = (selected) => {
    setAgruparPor(selected?.value);
    limparDados();
  };

  const handleSetVendedor = (selected) => {
    setVendedor(selected?.map((e) => e.value));
    limparDados();
  };

  const fetchData = async () => {
    if (
      grouping === "per" &&
      (!(dataIni instanceof Date) || !(dataFim instanceof Date))
    ) {
      toastr.warning("Atenção", "Por favor, verifique os campos de data");
      return;
    }

    setIsLoading(true);

    const ret = await VendasPorPeriodoService.fetch(
      dataIni.toISOString()?.split("T")[0],
      dataFim.toISOString()?.split("T")[0],
      grouping,
      agruparPor,
      vendedor
    );

    setData(ret["data"] ?? []);
    setSeries(ret["names"] ?? []);
    setTotal(ret["total"] ?? 0);

    setIsLoading(false);
  };

  return (
    <PageContainer title="Vendas por Vendedor" number="5004" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          {grouping === "per" && (
            <ComboBox
              md={2}
              label="Agrupar Por"
              name="agrupar_por"
              options={agrupar_por_options}
              onChange={handleSetAgruparPor}
              isDisabled={!podeVerTotais}
              defaultValue={agruparPor}
            />
          )}
          <ComboBox
            md={3}
            label="Período para visualização"
            name="grouping"
            options={grouping_options}
            onChange={handleSetGrouping}
            isDisabled={!podeVerTotais}
            defaultValue={grouping_options[0].value}
          />
          <AsyncComboBox
            onChange={handleSetVendedor}
            isSearchable
            isClearable
            md={3}
            label="Vendedor"
            name="vendedor"
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            isMulti
          />
          <BotaoPesquisar onClick={fetchData} loading={isLoading} />
        </Row>
      </Card>
      {data.length > 0 && (
        <>
          <TimeSeriesLineChart
            data={data}
            title="Relatórios de Vendas por Período"
            dataKey={"Data"}
            lineDataKeys={series}
            total={total}
            hideTotal={localStorage.getItem("ver_totais_consultas") === "false"}
            isRank
          />
          <BaseInfoCard baseInfo={["Vendas: Vendas do Sistema Desktop"]} />
        </>
      )}
    </PageContainer>
  );
};
