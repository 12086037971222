import React, { useCallback, useState } from "react";
import { Card, Row } from "reactstrap";
import { FormCheckbox, SearchInput } from "../../../../components";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { PageContainer } from "../../../../components/PageContainer";
import { debounce, defaultDebounceTime } from "../../../../coreUtils";
import LiberacaoClientesService from "../../../../services/pedidos/LiberacaoClientesService";
import { LiberacaoClientesGrid } from "./LiberacaoClientesGrid";
import LiberarClienteContainer from "./LiberarClienteContainer";

export const LiberacaoDeClientes = () => {
  const [mostrarPendentesLib, setMostrarPendentesLib] = useState(true);
  const [nome, setNome] = useState("");
  const [dados, setDados] = useState([]);
  const [clienteID, setClienteID] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const handlePista = debounce(async (v) => {
    setNome(v);
    setLoadingPista(true);
    setDados(await LiberacaoClientesService.listar(mostrarPendentesLib, v));
    setLoadingPista(false);
  }, defaultDebounceTime);

  const listarDados = useCallback(async () => {
    setLoading(true);
    setDados(await LiberacaoClientesService.listar(mostrarPendentesLib, nome));
    setLoading(false);
  }, [mostrarPendentesLib, nome]);

  const notifyEvent = () => {
    listarDados();
    setClienteID(null);
    setNomeCliente("");
  };

  return (
    <PageContainer title="Liberação de Clientes" number="0035" canGoBack>
      <Card body>
        <Row>
          <SearchInput
            md={6}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome do Cliente"
          />
          <BotaoPesquisar onClick={listarDados} loading={loading} />
          <FormCheckbox
            label="Apenas clientes pendentes de liberação"
            name="pendentes_liberacao_checkbox"
            onChange={() => setMostrarPendentesLib(!mostrarPendentesLib)}
            checked={mostrarPendentesLib}
          />
          <LiberarClienteContainer
            clienteID={clienteID}
            nomeCliente={nomeCliente}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <LiberacaoClientesGrid
        data={dados}
        setClienteID={setClienteID}
        setNomeCliente={setNomeCliente}
      />
    </PageContainer>
  );
};
