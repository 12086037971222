import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, CustomInput, FormGroup, Label, Row, TabPane } from "reactstrap";
import { ComboBox } from "../../../../../../components";
import {
  setConcessionaria,
  setTensaoNominal,
  setTipoLigacao,
} from "../store/vistoria_slice";

const _concessionarias = [
  {
    label: "COPEL-DIS",
    value: "COPD",
  },
  {
    label: "CERAL-DIS",
    value: "CERD",
  },
  {
    label: "COCEL",
    value: "COCL",
  },
  {
    label: "CFLO",
    value: "CFLO",
  },
  {
    label: "FORCEL",
    value: "FORC",
  },
  {
    label: "CELESC-DIS",
    value: "CELD",
  },
  {
    label: "CERAÇÁ",
    value: "CERA",
  },
  {
    label: "IENERGIA",
    value: "IENE",
  },
  {
    label: "CEREJ",
    value: "CERE",
  },
  {
    label: "CERAL - ANITÁPOLIS",
    value: "CERA",
  },
  {
    label: "CERPALO",
    value: "CERP",
  },
  {
    label: "CERBRANORTE",
    value: "CRBN",
  },
  {
    label: "CERGAPA",
    value: "CERG",
  },
  {
    label: "COORSEL",
    value: "COOR",
  },
  {
    label: "CERGAL",
    value: "CEGL",
  },
  {
    label: "COOPERMILA",
    value: "COML",
  },
  {
    label: "EFLUL",
    value: "EFLU",
  },
  {
    label: "CERTREL",
    value: "CERT",
  },
  {
    label: "COOPERCOCAL",
    value: "COCC",
  },
  {
    label: "EFLJC",
    value: "EFLJ",
  },
  {
    label: "CERMOFUL",
    value: "CRMF",
  },
  {
    label: "COOPERALIANÇA",
    value: "CPAL",
  },
  {
    label: "COOPERA",
    value: "COPA",
  },
  {
    label: "CERSUL",
    value: "CERS",
  },
  {
    label: "CEJAMA",
    value: "CEJA",
  },
  {
    label: "CEPRAG",
    value: "CEPR",
  },
  {
    label: "RGE",
    value: "RGE",
  },
  {
    label: "CRELUZ-D",
    value: "CRLD",
  },
  {
    label: "CREAL",
    value: "CREA",
  },
  {
    label: "COPREL",
    value: "COPR",
  },
  {
    label: "MUXUENERGIA",
    value: "MUXU",
  },
  {
    label: "HIDROPAN",
    value: "HIDR",
  },
  {
    label: "ELETROCAR",
    value: "ELET",
  },
  {
    label: "CERLUZ",
    value: "CERL",
  },
  {
    label: "DEMEI",
    value: "DEME",
  },
  {
    label: "COOPERLUZ",
    value: "CPLZ",
  },
  {
    label: "CERMISSÕES",
    value: "CRMS",
  },
  {
    label: "CERTEL",
    value: "CRTL",
  },
  {
    label: "AES-SUL",
    value: "AESS",
  },
  {
    label: "CERTALA",
    value: "CRTA",
  },
  {
    label: "UHUNEPAL",
    value: "UHUN",
  },
  {
    label: "CEEE-D",
    value: "CEEE",
  },
  {
    label: "CERTAJA",
    value: "CRTJ",
  },
];

export const TabConcessionaria = () => {
  const vistoriaTecnica = useSelector((state) => state.vistoriaTecnica);
  const dispatch = useDispatch();
  return (
    <TabPane tabId="2" className="p-2">
      <Row>
        <ComboBox
          label="Concessionária"
          md={2}
          options={_concessionarias}
          defaultValue={vistoriaTecnica.concessionaria}
          onChange={(selected) => dispatch(setConcessionaria(selected?.value))}
        />
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => dispatch(setTipoLigacao(target?.value))}
          >
            <Label>Tipo de Ligação</Label>
            <div>
              <CustomInput
                type="radio"
                name="tipo_ligacao_padrao_entrada"
                id="monofasico_padrao_entrada"
                label="Monofásico"
                value="MONO"
                checked={vistoriaTecnica.tipoLigacao === "MONO"}
                inline
              />
              <CustomInput
                type="radio"
                name="tipo_ligacao_padrao_entrada"
                id="bifasico_padrao_entrada"
                label="Bifásico"
                value="BIFAS"
                checked={vistoriaTecnica.tipoLigacao === "BIFAS"}
                inline
              />
              <CustomInput
                type="radio"
                name="tipo_ligacao_padrao_entrada"
                id="trifasico_padrao_entrada"
                label="Trifásico"
                value="TRIFA"
                checked={vistoriaTecnica.tipoLigacao === "TRIFA"}
                inline
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => dispatch(setTensaoNominal(target?.value))}
          >
            <Label>Tensão Nominal</Label>
            <div>
              <CustomInput
                type="radio"
                name="tensao_nominal_padrao_entrada"
                id="tensao_220_padrao_entrada"
                label="220V"
                value="220"
                checked={vistoriaTecnica.tensaoNominal === "220"}
                inline
              />
              <CustomInput
                type="radio"
                name="tensao_nominal_padrao_entrada"
                id="tensao_110_padrao_entrada"
                label="110V"
                value="110"
                checked={vistoriaTecnica.tensaoNominal === "110"}
                inline
              />
              <CustomInput
                type="radio"
                name="tensao_nominal_padrao_entrada"
                id="tensao_380_padrao_entrada"
                label="380V"
                value="380"
                checked={vistoriaTecnica.tensaoNominal === "380"}
                inline
              />
              <CustomInput
                type="radio"
                name="tensao_nominal_padrao_entrada"
                id="tensao_15kv_padrao_entrada"
                label="15kV"
                value="15K"
                checked={vistoriaTecnica.tensaoNominal === "15K"}
                inline
              />
              <CustomInput
                type="radio"
                name="tensao_nominal_padrao_entrada"
                id="tensao_23kv_padrao_entrada"
                label="23kV"
                value="23K"
                checked={vistoriaTecnica.tensaoNominal === "23K"}
                inline
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </TabPane>
  );
};
