import React from "react";
import { Table } from "../../../../../components";

const columns = [
  {
    dataField: "id_grupo_prod",
    text: "# Grupo",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "id_grupo_prod__nome",
    text: "Nome",
    align: "left",
    headerAlign: "left",
  },
];

export const GrupoNaoVerifEstoqueGrid = ({ data, setSelected }) => {
  return (
    <Table
      keyField="id_grupo_prod"
      data={data}
      columns={columns}
      onSelect={setSelected}
    />
  );
};
