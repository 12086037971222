import React from "react";
import { ModalBase } from "../../../../../components";

export const ConfirmSemPremiadoModal = ({ isOpen, toggle, handleSubmit }) => {
  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Finalizar"
      number="0030_12"
      onConfirm={toggle}
      onCancel={handleSubmit}
      confirmButtonText="Sim"
      cancelButtonText="Não"
      onKeyDown={(e) => e.key === "Escape" && handleSubmit()}
    >
      <h5 className="mb-0" style={{ textAlign: "center" }}>
        Você não identificou o premiado.
      </h5>
      <h5 className="mb-0" style={{ textAlign: "center" }}>
        Deseja informar o premiado para esta venda?
      </h5>
    </ModalBase>
  );
};
