import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
} from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";

export const FichaTecnicaGrid = ({
  data,
  setSelected,
  alterar,
  excluir,
  duplicar,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_produto",
      text: "Produto",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
      sortable: true,
    },
    { dataField: "unidade", text: "UN", align: "center", sortable: true },
    {
      dataField: "qtd_itens",
      text: "Itens",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_custo_total",
      text: "Custo Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({
      excluir: excluir,
      outrasAcoes: (id) => (
        <>
          <DropdownItem onClick={() => duplicar(id)}>Duplicar</DropdownItem>
        </>
      ),
    }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
