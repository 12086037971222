import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
  Divider,
  FormCheckbox,
  NumberInput,
} from "../../../../../../components";
import { setAliqCredIcms, setGeraCredIcms } from "../../store/cadCfopSlice";

export const TabCredICMSCfop = () => {
  const store = useSelector((state) => state.cadCfop);
  const dispatch = useDispatch();

  return (
    <>
      <Divider className="mt-4">Crédito de ICMS</Divider>
      <Row className="mb-3">
        <FormCheckbox
          name="gera_cred_icms"
          label="Gera Crédito de ICMS"
          checked={store.geraCredIcms}
          onChange={() => dispatch(setGeraCredIcms(!store.geraCredIcms))}
          disabled={!store.camposAtivosIcms?.credito_icms_simples_ativo}
        />
        <NumberInput
          label="Alíquota de Crédito de ICMS"
          md={4}
          onChange={(v) => dispatch(setAliqCredIcms(v))}
          value={store.aliqCredIcms}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          disabled={
            !store.camposAtivosIcms?.credito_icms_simples_ativo ||
            !store.geraCredIcms
          }
          divClassName="pr-0"
          mdInput={10}
        />
      </Row>
      <span>
        Os Campos acima somente serão habilitados quando o Regime Tributário do
        Emitente for SIMPLES NACIONAL.
      </span>
    </>
  );
};
