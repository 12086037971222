import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormButton,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import {
  MODAL_ACTIONS,
  getFirstDayOfWeek,
  getLastDayOfWeek,
} from "../../../../coreUtils";
import { OrdemProducaoGrid } from "./components/OrdemProducaoGrid";
import OrdemProducaoService from "../../../../services/industria/OrdemProducaoService";
import { FinalizarOrdemProducaoModal } from "./components/FinalizarOrdemProducaoModal";
import { DetalhesOrdemProducaoModal } from "./components/DetalhesOrdemProducaoModal";
import { showError } from "../../../../components/AlertaModal";

export const OrdemProducao = () => {
  const [idProdutoFinal, setIdProdutoFinal] = useState(null);
  const [dataIni, setDataIni] = useState(getFirstDayOfWeek(new Date()));
  const [dataFim, setDataFim] = useState(getLastDayOfWeek(new Date()));
  const [mostrarFinalizadas, setMostrarFinalizadas] = useState(false);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [loadingTalao, setLoadingTalao] = useState(false);
  const [loadingSeparacao, setLoadingSeparacao] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [finalizarOpen, setFinalizarOpen] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    setLoading(true);
    try {
      const params = {
        id_produto_final: idProdutoFinal,
        data_ini: dataIni,
        data_fim: dataFim,
        mostrar_finalizadas: mostrarFinalizadas,
      };
      const [ok, ret] = await OrdemProducaoService.listar(params);
      setDados(ok ? ret : []);
    } finally {
      setLoading(false);
    }
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleFinalizar = () => setFinalizarOpen(!finalizarOpen);

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const mostrarDetalhes = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  const finalizar = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleFinalizar();
    }, 1);
  };

  const handleSelect = (id, isSelect) => {
    setSelected(
      isSelect ? [...selected, id] : selected.filter((e) => e !== id)
    );
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const imprimirTalao = async () => {
    setLoadingTalao(true);
    try {
      for (const idOrdemProducao of selected) {
        await OrdemProducaoService.imprimirTalao(idOrdemProducao);
      }
    } catch (error) {
      showError("Erro ao imprimir Talões", "ImprTalaoFront01", error.message);
    }
    setLoadingTalao(false);
  };

  const imprimirSeparacao = async () => {
    setLoadingSeparacao(true);
    try {
      await OrdemProducaoService.imprimirSeparacao(selected);
    } catch (error) {
      showError("Erro ao imprimir Separação", "ImprSepFront01", error.message);
    }
    setLoadingSeparacao(false);
  };

  return (
    <PageContainer title="Ordem de Produção" number="0095">
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            label="Produto Final"
            isSearchable
            isClearable
            concatModelName="produto"
            onChange={(s) => setIdProdutoFinal(s?.value)}
            hideShortcut
          />
          <FiltroPeriodoDatas
            onChange={handleDate}
            defaultOption={null}
            defaultStart={dataIni}
            defaultEnd={dataFim}
          />
          <FormCheckbox
            label="Mostrar Finalizadas"
            checked={mostrarFinalizadas}
            onChange={() => setMostrarFinalizadas(!mostrarFinalizadas)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <FormButton
            md="auto"
            color="primary"
            onClick={imprimirTalao}
            loading={loadingTalao}
            disabled={(selected?.length ?? 0) === 0}
            disabledHint="Selecione ao menos uma Ordem de Produção"
          >
            Imprimir Talão
          </FormButton>
          <FormButton
            md="auto"
            color="primary"
            onClick={imprimirSeparacao}
            loading={loadingSeparacao}
            disabled={(selected?.length ?? 0) === 0}
            disabledHint="Selecione ao menos uma Ordem de Produção"
          >
            Imprimir Separação
          </FormButton>
          <DetalhesOrdemProducaoModal
            isOpen={detalhesOpen}
            toggle={toggleDetalhes}
            selected={lastSelected}
          />
          <FinalizarOrdemProducaoModal
            isOpen={finalizarOpen}
            toggle={toggleFinalizar}
            selected={lastSelected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <OrdemProducaoGrid
          dados={dados}
          mostrarDetalhes={mostrarDetalhes}
          finalizar={finalizar}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
        />
      </Card>
    </PageContainer>
  );
};
