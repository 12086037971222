import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  iniPeriodo: null,
  fimPeriodo: null,
  regime: "CXA",
  despesas: [],
  receitas: [],
  dataGrafico: [],
  resultado: {},
  clear: false,

  utilizaOrganizacao: false,
  detalhesContasPagarOpen: false,
  detalhesVendaOpen: false,
  detalhesOrdServOpen: false,
  detalhesNfEntradaOpen: false,
  detalhesVendaProntaEntregaOpen: false,
  detalhesJornadaDiaOpen: false,
  idDetalhes: null,
};

export const estoqueRotaSlice = createSlice({
  name: "estoque_rota",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setIniPeriodo: (state, action) => {
      state.iniPeriodo = action.payload;
    },
    setFimPeriodo: (state, action) => {
      state.fimPeriodo = action.payload;
    },
    setRegime: (state, action) => {
      state.regime = action.payload;
    },
    setDespesas: (state, action) => {
      state.despesas = action.payload;
    },
    setReceitas: (state, action) => {
      state.receitas = action.payload;
    },
    setDataGrafico: (state, action) => {
      state.dataGrafico = action.payload;
    },
    setResultado: (state, action) => {
      state.resultado = action.payload;
    },
    setClear: (state, action) => {
      state.clear = action.payload;
    },
    setUtilizaOrganizacao: (state, action) => {
      state.utilizaOrganizacao = action.payload;
    },
    setDetalhesContasPagarOpen: (state, action) => {
      state.detalhesContasPagarOpen = action.payload;
    },
    setDetalhesVendaOpen: (state, action) => {
      state.detalhesVendaOpen = action.payload;
    },
    setDetalhesOrdServOpen: (state, action) => {
      state.detalhesOrdServOpen = action.payload;
    },
    setDetalhesNfEntradaOpen: (state, action) => {
      state.detalhesNfEntradaOpen = action.payload;
    },
    setDetalhesVendaProntaEntregaOpen: (state, action) => {
      state.detalhesVendaProntaEntregaOpen = action.payload;
    },
    setDetalhesJornadaDiaOpen: (state, action) => {
      state.detalhesJornadaDiaOpen = action.payload;
    },
    setIdDetalhes: (state, action) => {
      state.idDetalhes = action.payload;
    },
  },
});

export const {
  init,
  setup,
  setIniPeriodo,
  setFimPeriodo,
  setRegime,
  setDespesas,
  setReceitas,
  setDataGrafico,
  setResultado,
  setClear,
  setUtilizaOrganizacao,
  setDetalhesContasPagarOpen,
  setDetalhesVendaOpen,
  setDetalhesOrdServOpen,
  setDetalhesNfEntradaOpen,
  setDetalhesVendaProntaEntregaOpen,
  setDetalhesJornadaDiaOpen,
  setIdDetalhes,
} = estoqueRotaSlice.actions;

export default estoqueRotaSlice.reducer;
