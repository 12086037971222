import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AsyncComboBox,
  FormCheckbox,
  IntegerFormInput,
  NumberInput,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
} from "../../../../components";
import {
  setPermiteManipularVendaOs,
  setIdPlanoContas,
  setIdCentroCusto,
  setIdTipoDocVendaBonificada,
  setDestacarFreteNaNf,
  setVlrDespesaFinanceira,
  setDestacarLocalEntregaNaNfe,
  setUtilizarCondicoesPag,
  setIdContaBancGeracaoBoleto,
  setUnificarTitulosProdServ,
  setAdicionaNroVendaOrdServBoleto,
  setLicencaFaturarOutraEmpresa,
  setEmitirNfAutomaticamente,
  setNuncaGeraMovFinanceira,
  setDestacaVlrDespFinancNfe,
  setNfseDestacaObsFinancOsEm,
} from "../store/faturamento_slice";
import { Row } from "reactstrap";
import { RadioItem } from "../../../../components/RadioGroup";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { cadastroFormasRecClienteRoute } from "../../../../routes/modules/cadastro";

export const PersonFaturamento = () => {
  const store = useSelector((state) => state.faturamento);
  const dispatch = useDispatch();

  const contaBancPadraoRef = useRef();

  const onChangeVlrDespesaFinanceira = () => {
    if (store.vlr_despesa_financeira <= 0) {
      dispatch(setDestacaVlrDespFinancNfe(false));
    }
  };
  useEffect(onChangeVlrDespesaFinanceira, [store.vlr_despesa_financeira]);

  const onChangeNuncaGeraMovFinanceira = () => {
    if (store.nunca_gera_mov_financeira) {
      dispatch(setIdContaBancGeracaoBoleto(null));
      if (contaBancPadraoRef.current) {
        contaBancPadraoRef.current.clearValue();
      }
    }
  };
  useEffect(onChangeNuncaGeraMovFinanceira, [store.nunca_gera_mov_financeira]);

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Manipular Produtos na Geração de NFe de Vendas ou O.S."
          checked={store.permite_manipular_venda_os}
          onChange={() =>
            dispatch(
              setPermiteManipularVendaOs(!store.permite_manipular_venda_os)
            )
          }
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Plano de Contas Padrão para Faturamento"
          mode="plano_contas"
          value={store.id_plano_contas}
          onChange={(s) => dispatch(setIdPlanoContas(s ?? null))}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Centro de Custo Padrão para Faturamento"
          mode="centro_custo"
          value={store.id_centro_custo}
          onChange={(s) => dispatch(setIdCentroCusto(s ?? null))}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Tipo de Documento para Venda Bonificada na Frente de Venda [0030]"
          concatModelName="tipo_documento"
          defaultValue={store.id_tipo_doc_venda_bonificada}
          onChange={(s) =>
            dispatch(setIdTipoDocVendaBonificada(s?.value ?? null))
          }
          defaultOptions
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Deixar marcado por padrão a opção Destacar Valor de Frete da Venda na NF-e"
          checked={store.destacar_frete_na_nf}
          onChange={() =>
            dispatch(setDestacarFreteNaNf(!store.destacar_frete_na_nf))
          }
        />
      </Row>
      <Row>
        <NumberInput
          md="auto"
          label="Valor da Despesa Financeira"
          value={store.vlr_despesa_financeira}
          onChange={(s) => dispatch(setVlrDespesaFinanceira(s))}
        />
        <FormCheckbox
          label="Destacar Valor da Despesa Financeira no Valor Outros da NF-e"
          value={store.destaca_vlr_desp_financ_nfe}
          onChange={() =>
            dispatch(
              setDestacaVlrDespFinancNfe(!store.destaca_vlr_desp_financ_nfe)
            )
          }
          disabled={store.vlr_despesa_financeira <= 0}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Destacar Local de Entrega nos Dados Adicionais da NF-e"
          checked={store.destacar_local_entrega_na_nfe}
          onChange={() =>
            dispatch(
              setDestacarLocalEntregaNaNfe(!store.destacar_local_entrega_na_nfe)
            )
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label="Utilizar Condições de Pagamento"
          value={store.utilizar_condicoes_pag}
          onChange={(v) => dispatch(setUtilizarCondicoesPag(v))}
        >
          <RadioItem
            label="Permite Uso de qualquer Condição de Pagamento Independente do Cliente"
            value="GERAL"
          />
          <RadioItem
            label={
              <>
                Controlado por Cliente (configurado através do cadastro de{" "}
                <Link
                  style={{ color: "#5b7dff" }}
                  to={cadastroFormasRecClienteRoute.path}
                >
                  Formas de Recebimento Por Cliente
                </Link>
                )
              </>
            }
            value="CLIENTE"
          />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="O sistema deve unificar os títulos a receber no faturamento, quando a venda possuir NF-e de Produtos e NF-e de Serviços"
          checked={store.unificar_titulos_prod_serv}
          onChange={() =>
            dispatch(
              setUnificarTitulosProdServ(!store.unificar_titulos_prod_serv)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Destacar Número da Venda/Ordem de Serviço no Boleto"
          checked={store.adiciona_nro_venda_ord_serv_boleto}
          onChange={() =>
            dispatch(
              setAdicionaNroVendaOrdServBoleto(
                !store.adiciona_nro_venda_ord_serv_boleto
              )
            )
          }
        />
      </Row>
      <Row>
        <IntegerFormInput
          md={4}
          label="Faturamento Entre Empresas - Licença da Outra Empresa para faturamento"
          defaultValue={store.licenca_faturar_outra_empresa}
          onChange={(s) =>
            dispatch(setLicencaFaturarOutraEmpresa(s?.value ?? null))
          }
          mdInput={3}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Sistema deve apenas incluir a NF-e, sem emiti-la na SEFAZ automaticamente"
          checked={store.emitir_nf_automaticamente}
          onChange={() =>
            dispatch(
              setEmitirNfAutomaticamente(!store.emitir_nf_automaticamente)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label={
            <>
              Nunca Gerar Contas a Receber pelo Faturamento
              <br />
              <br />
              Esta opção deve ser marcada para o cliente que utiliza o
              faturamento apenas para a emissão da NF-e
              <br />
              <br />
              Se esta opção estiver desmarcada, o sistema irá incluir o contas a
              receber e fechar o movimento da origem no Caixa Loja.
            </>
          }
          checked={store.nunca_gera_mov_financeira}
          onChange={() =>
            dispatch(
              setNuncaGeraMovFinanceira(!store.nunca_gera_mov_financeira)
            )
          }
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          concatModelName="conta_banc"
          defaultValue={store.id_conta_banc_geracao_boleto}
          onChange={(s) =>
            dispatch(setIdContaBancGeracaoBoleto(s?.value ?? null))
          }
          label="Conta Bancária Padrão para Geração de Boletos no Faturamento"
          disabled={store.nunca_gera_mov_financeira}
          ref={contaBancPadraoRef}
          defaultOptions
        />
      </Row>
      <Row>
        <RadioGroup
          label="NFSE - Local para destaque da Observação Financeira da Ordem de Serviço"
          value={store.nfse_destaca_obs_financ_os_em}
          onChange={(v) => dispatch(setNfseDestacaObsFinancOsEm(v))}
        >
          <RadioItem label="Descrição do Serviço" value="DESC_SERV" />
          <RadioItem
            label="Informação Adicional (tratado apenas no provedor TECNOS)"
            value="INFO_ADIC"
          />
        </RadioGroup>
      </Row>
    </>
  );
};
