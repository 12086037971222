import React from "react";
import { Table, TableDelete } from "../../../../../components";

export const ClienteExcecaoInadBoletoGrid = ({
  dados,
  excluir,
  alterarExcecao,
  alterarInterno,
}) => {
  const columns = [
    { dataField: "id_cliente", text: "Cliente", align: "center" },
    { dataField: "nome_cliente", text: "Nome", align: "left" },
    {
      dataField: "num_dias_bloqueio",
      text: "Dias Exceção",
      align: "center",
      editable: true,
      editorType: "number",
      decimalPlaces: 0,
      alwaysShowEditor: true,
      onBlur: alterarExcecao,
      onChange: alterarInterno,
      fixedColWidth: true,
      colWidth: "130px",
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => <TableDelete onClick={() => excluir(row.id)} />,
    },
  ];
  return <Table data={dados} columns={columns} />;
};
