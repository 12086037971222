import { configureStore } from "@reduxjs/toolkit";
import vendaCab from "./vendaCabSlice";
import paramsFrenteVenda from "./paramsFrenteVendaSlice";

export const storeCab = configureStore({
  reducer: {
    paramsFrenteVenda: paramsFrenteVenda,
    vendaCab: vendaCab,
  },
});
