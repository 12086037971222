import React from "react";
import { DropdownItem, Table } from "reactstrap";
import { MoreDropdown } from "../../../../../../components";
import { formatNumber } from "../../../../../../coreUtils";

export const IncluirLayoutGrid = ({
  dados,
  excluirItem,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "Produto",
      align: "center",
    },
    {
      dataField: "descricao",
      text: "Nome do Produto",
      align: "left",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
    },
    {
      dataField: "preco_venda",
      text: "Preço",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "75px",
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => excluirItem(row.tipo, row.id)}>
            Excluir
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      paginated={true}
    />
  );
};
