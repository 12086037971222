import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Col, Row } from "reactstrap";
import { AsyncComboBox, CustomPieChart } from "../../../../components";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import { userPodeVerTotaisRelVenda, isArray } from "../../../../coreUtils";
import CumulativoVendasService from "../../../../services/CumulativoVendasService";

export const RepresGruposProd = () => {
  const podeVerTotais = userPodeVerTotaisRelVenda();
  const idUsuario = parseInt(localStorage.getItem("id_colaborador"));
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [grupo, setGrupo] = useState(null);
  const [vendedor, setVendedor] = useState(!podeVerTotais ? [idUsuario] : []);
  const [relData, setRelData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const limparDados = () => {
    setRelData([]);
  };

  const handleDate = (dataIni, dataFim) => {
    setDataIni(dataIni);
    setDataFim(dataFim);
    limparDados();
  };

  const handleSetGrupo = (selected) => {
    setGrupo(selected?.map((e) => e.value));
    limparDados();
  };

  const handleSetVendedor = (selected) => {
    setVendedor(
      isArray(selected) ? selected?.map((e) => e.value) : selected?.value
    );
    limparDados();
  };

  const fetchData = async () => {
    if (!(dataIni instanceof Date) || !(dataFim instanceof Date)) {
      toastr.warning("Atenção", "Por favor, verifique os campos de data");
      return;
    }
    setIsLoading(true);

    const ret = await CumulativoVendasService.fetch(
      dataIni.toISOString()?.split("T")[0],
      dataFim.toISOString()?.split("T")[0],
      grupo,
      vendedor
    );

    if (ret?.data?.length > 0) setRelData(ret.data);

    setIsLoading(false);
  };

  return (
    <PageContainer
      title="Representatividade de Grupo de Produtos"
      number="5006"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            onChange={handleSetGrupo}
            isSearchable
            isClearable
            isMulti
            md={4}
            label="Grupo"
            name="grupo"
            isConcatField
            concatModelName="grupo"
            defaultOptions
          />
          <AsyncComboBox
            onChange={handleSetVendedor}
            isSearchable
            isClearable
            md={3}
            label="Vendedor"
            name="vendedor"
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            isMulti={podeVerTotais}
            disabled={!podeVerTotais}
            defaultValue={!podeVerTotais ? vendedor[0] : null}
          />
          <BotaoPesquisar
            onClick={fetchData}
            loading={isLoading}
            disabled={!grupo || grupo?.length === 0}
            disabledHint="Informe ao menos um Grupo"
          />
        </Row>
      </Card>
      <Row>
        {relData.map((element, i) => {
          const { title, data, total } = element;
          let md = 12;
          if (relData.length % 2 === 0) {
            md = 6;
          } else {
            md = i === relData.length - 1 ? 12 : 6;
          }
          return (
            <Col flex md={md} key={`col-pie-${i}`}>
              <CustomPieChart
                key={`pie-chart-${i}`}
                title={title}
                data={data}
                dataKey={"value"}
                nameKey={"name"}
                percentage
                total={total}
              />
            </Col>
          );
        })}
      </Row>
      {relData.length > 0 && (
        <Card body>
          <p style={{ marginTop: 0, marginBottom: 0 }}>
            <b>BASE DE INFORMAÇÕES</b>
            <br />
            Vendas: Vendas do Sistema Desktop
            <br />
            <br />
            <b>OBJETIVO</b>
            <br />O objetivo deste demonstrativo é apresentar a
            representatividade, por percentual e valor, de cada Grupo de
            Produtos vendidos por cada Vendedor.
          </p>
        </Card>
      )}
    </PageContainer>
  );
};
