import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  PageContainer,
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormCheckbox,
} from "../../../../components";
import { VendasGeralGrid } from "./components/VendasGeralGrid";
import { userPodeVerTotaisRelVenda, isArray } from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { ImprimirExportarModal } from "./components/ImprimirExportarModal";
import { DetalheVendaModal } from "../../../financeiro/caixa_loja/components/DetalheVendaModal";
import { DetalheVendaProntaEntregaModal } from "../../../pronta_entrega/gerenciamento/gerenciamento_vendas/components/DetalheVendaProntaEntregaModal";

const ordenacoes = [
  { label: "Nº Venda", value: "NVD" },
  { label: "Data", value: "DAT" },
  { label: "Nome do Cliente", value: "NMC" },
  { label: "Nome Fantasia do Cliente", value: "NFC" },
  { label: "Valor", value: "VLR" },
];

export const VendasGeral = () => {
  const podeVerTotais = userPodeVerTotaisRelVenda();
  const idUsuario = parseInt(localStorage.getItem("id_colaborador"));
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [clientes, setClientes] = useState([]);
  const [tiposCli, setTiposCli] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [vendedores, setVendedores] = useState(
    !podeVerTotais ? [idUsuario] : []
  );
  const [ordenarPor, setOrdenarPor] = useState(ordenacoes[0].value);
  const [mostrarFormasRec, setMostrarFormasRec] = useState(false);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [idVendaDet, setIdVendaDet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [detalheVendaOpen, setDetalheVendaOpen] = useState(false);
  const [detalheVendaProntaEntregaOpen, setDetalheVendaProntaEntregaOpen] =
    useState(false);

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const buscarDados = async (formato) => {
    const params = {
      formato: formato,
      data_ini: dataIni,
      data_fim: dataFim,
      ordenar_por: ordenarPor,
      id_cliente: clientes,
      id_tipo_cliente: tiposCli,
      id_cidade: cidades,
      id_vendedor: vendedores,
      mostrar_formas_rec: mostrarFormasRec,
    };

    return await apiGetV2("/relatorios/venda/vendas_geral/", params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados("SIN");
    setDados(ok ? ret.vendas : []);
    setTotais(ok ? ret.totais : {});
    setLoading(false);
  };

  const handleSetVendedores = (selected) =>
    setVendedores(
      isArray(selected)
        ? selected?.map((e) => e.value)
        : selected
        ? [selected?.value]
        : []
    );

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const toggleDetalheVenda = () => setDetalheVendaOpen(!detalheVendaOpen);

  const toggleDetalheVendaProntaEntrega = () =>
    setDetalheVendaProntaEntregaOpen(!detalheVendaProntaEntregaOpen);

  const mostrarDetalhes = (tipoVenda, id) => {
    setIdVendaDet(id);
    setTimeout(() => {
      if (tipoVenda === "ADM") {
        toggleDetalheVenda();
      } else if (tipoVenda === "Pronta Entrega") {
        toggleDetalheVendaProntaEntrega();
      }
    }, 1);
  };

  useEffect(() => {
    limparDados();
  }, [dataIni, dataFim, clientes, tiposCli, cidades, vendedores, ordenarPor]);

  return (
    <PageContainer title="Relatório Geral de Vendas" number="5040" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            onChange={(s) => setClientes(s?.map((e) => e.value) ?? [])}
            isSearchable
            isClearable
            md={3}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isMulti
            hideShortcut
          />
          <AsyncComboBox
            onChange={(s) => setTiposCli(s?.map((e) => e.value) ?? [])}
            isSearchable
            isClearable
            md={3}
            label="Tipo de Cliente"
            isConcatField
            concatModelName="tipo_cliente"
            isMulti
            hideShortcut
          />
          <AsyncComboBox
            onChange={handleSetVendedores}
            isSearchable
            isClearable
            md={3}
            label="Vendedor"
            isConcatField
            concatModelName="colaborador"
            hideShortcut
            defaultOptions
            isMulti={podeVerTotais}
            disabled={!podeVerTotais}
            defaultValue={!podeVerTotais ? vendedores[0] : null}
          />
          <AsyncComboBox
            onChange={(s) => setCidades(s?.map((e) => e.value) ?? [])}
            isSearchable
            isClearable
            md={3}
            label="Cidade"
            isConcatField
            concatModelName="cidade"
            isMulti
            hideShortcut
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <ComboBox
            options={ordenacoes}
            md={2}
            label="Ordenar Por"
            onChange={(s) => setOrdenarPor(s?.value)}
            defaultValue={ordenarPor}
          />
          <FormCheckbox
            label="Mostrar formas de Recebimento"
            name="mostrar_forams_rec"
            checked={mostrarFormasRec}
            onChange={() => setMostrarFormasRec(!mostrarFormasRec)}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <ImprimirExportarModal
            buscarDados={buscarDados}
            dataIni={dataIni}
            dataFim={dataFim}
            mostrarFormasRec={mostrarFormasRec}
          />
          <DetalheVendaModal
            isOpen={detalheVendaOpen}
            toggle={toggleDetalheVenda}
            idVenda={idVendaDet}
            mostrarFormasRec={mostrarFormasRec}
          />
          <DetalheVendaProntaEntregaModal
            isOpen={detalheVendaProntaEntregaOpen}
            toggle={toggleDetalheVendaProntaEntrega}
            idVenda={idVendaDet}
            mostrarFormasRec={mostrarFormasRec}
          />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Qtd. Vendas"
          value={totais?.n_registros}
          checkFloat
        />
        <CardTotaisItem label="Total Desconto" value={totais?.vlr_desconto} />
        <CardTotaisItem label="Saldo Flex" value={totais?.saldo_flex} />
        <CardTotaisItem label="Produtos" value={totais?.vlr_tot_prod} />
        <CardTotaisItem label="Serviços" value={totais?.vlr_tot_serv} />
        <CardTotaisItem label="Total Geral" value={totais?.vlr_total} />
      </CardTotais>
      {mostrarFormasRec && (
        <CardTotais>
          {Object.keys(totais?.formas_rec ?? {}).map((k) => (
            <CardTotaisItem label={k} value={totais.formas_rec[k]} />
          ))}
        </CardTotais>
      )}
      <Card body>
        <VendasGeralGrid dados={dados} mostrarDetalhes={mostrarDetalhes} />
      </Card>
    </PageContainer>
  );
};
