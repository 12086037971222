import React, { cloneElement, useRef, useState } from "react";
import { Edit2 } from "react-feather";
import { IconButton } from "./IconButton";

export const UnlockToEdit = ({
  children,
  enabled = true,
  hint = "Clique para editar",
  locked,
  lockedHint,
  tooltipPlacement = "right",
}) => {
  const [inputLocked, setInputLocked] = useState(true);
  const ref = useRef();

  const toggleLock = () => {
    if (inputLocked) {
      if (ref.current.focus) {
        ref.current.focus();
      } else if (ref.current.setFocus) {
        ref.current.setFocus();
      }
    }
    setInputLocked(!inputLocked);
  };

  return enabled
    ? cloneElement(children, {
        disabled: inputLocked,
        ref: ref,
        onBlur: () => setInputLocked(true),
        additionalButton: (
          <IconButton
            icon={Edit2}
            size={18}
            onClick={toggleLock}
            style={{ marginBlock: "auto", marginLeft: "0.4rem" }}
            hint={hint}
            disabled={locked}
            disabledHint={lockedHint}
            tooltipPlacement={tooltipPlacement}
          />
        ),
      })
    : children;
};
