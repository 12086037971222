import React from "react";
import { formatNumber } from "../../../../../coreUtils";
import { Table } from "../../../../../components";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
  },
  {
    dataField: "data",
    text: "Data",
    align: "left",
    style: (c, { ultimo_dia }) => ({
      color: ultimo_dia ? "#5b7dff" : "inherit",
      fontWeight: ultimo_dia ? "bold" : "normal",
    }),
  },
  {
    dataField: "vlr_din_despesa",
    text: "Despesas",
    align: "right",
    formatter: (v) => formatNumber(v, true, 2),
  },
  {
    dataField: "vlr_din_receb_boleto",
    text: "Boletos Rec. em Mãos",
    align: "right",
    formatter: (v) => formatNumber(v, true, 2),
  },
  {
    dataField: "vlr_din_venda",
    text: "Venda Dinheiro",
    align: "right",
    formatter: (v) => formatNumber(v, true, 2),
  },
  {
    dataField: "saldo_quebra_conf",
    text: "Saldo de Quebra de Conferência",
    align: "right",
    style: (_, { saldo_quebra_conf: v }) => ({
      color:
        parseFloat(v) > 0
          ? "#5fc27e"
          : parseFloat(v) < 0
          ? "#f44456"
          : "inherit",
      fontWeight: "bold",
      fontStyle: "italic",
    }),
    formatter: (v) => formatNumber(v, true, 2),
  },
];

export const DiaAbertoGrid = ({ dados }) => {
  return (
    <Table
      columns={columns}
      data={dados}
      showRegisterCount={false}
      paginated={false}
      pageSize={dados.length > 10 ? 10 : dados.length}
    />
  );
};
