import React from "react";
import { HintLabel, Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

export const columnsNfDebitoLocacao = (exportacao) => {
  const numberFormatter = exportacao
    ? parseFloat
    : (c) => formatNumber(c, true, 2);

  return [
    {
      dataField: "cpf_cnpj_cliente",
      text: "CPF/CNPJ Cliente",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "uf_cliente",
      text: "UF",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "cidade_cliente",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "endereco_cliente",
      text: "Endereço",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "numero",
      text: "Número Doc.",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "serie",
      text: "Série",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data_emissao",
      text: "Emissão",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "situacao",
      text: exportacao ? (
        "Situação"
      ) : (
        <HintLabel label="Situação" hintText="0 - Regular; 2 - Cancelada" />
      ),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "acumulador",
      text: "Acumulador",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_desconto",
      text: "Vlr. Desconto",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_deducao",
      text: "Vlr. Dedução",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_contabil",
      text: "Vlr. Contábil",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "base_calculo",
      text: "Base Cálc.",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "aliq_iss",
      text: "Alíq. Iss",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_iss_normal",
      text: "Vlr. ISS Normal",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_iss_retido",
      text: "Vlr. ISS Retido",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_irrf",
      text: "Vlr. IRRF",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_pis",
      text: "Vlr. PIS",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_cofins",
      text: "Vlr. COFINS",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_csll",
      text: "Vlr. CSLL",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_crf",
      text: "Vlr. CRF",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
    {
      dataField: "vlr_inss",
      text: "Vlr. INSS",
      align: "right",
      headerAlign: "right",
      formatter: numberFormatter,
    },
  ];
};

export const NfDebitoLocacaoGrid = ({ dados }) => {
  return (
    <Table
      data={dados}
      columns={columnsNfDebitoLocacao(false)}
      paginated={false}
    />
  );
};
