import React, { useEffect, useState } from "react";
import {
  BotaoPesquisar,
  Divider,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../components";
import { Card, Col, Row } from "reactstrap";
import { EstornoPagamentoGrid } from "./components/EstornoPagamentoGrid";
import EstornoPagamentoService from "../../../../services/financeiro/EstornoPagamentoService";
import { RecursosLoteGrid } from "./components/RecursosLoteGrid";
import { TitulosLoteGrid } from "./components/TitulosLoteGrid";
import { ConfEstornarPagamentoModal } from "./components/ConfEstornarPagamentoModal";

export const EstornoPagamento = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [lotes, setLotes] = useState([]);
  const [recursos, setRecursos] = useState([]);
  const [titulos, setTitulos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarLotes = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
    };
    setLoading(true);
    const [ok, ret] = await EstornoPagamentoService.listarLotes(params);
    setLotes(ok ? ret : []);

    if (ok) {
      const idsRet = ret.map((e) => e.id);
      if (!idsRet.includes(selected)) {
        if (ret.length > 0) {
          setSelected(ret[0].id);
        } else {
          setSelected(null);
        }
      }
    }
    setLoading(false);
  };

  const buscarRecursosTitulosLotes = async (idLote) => {
    if (!idLote) {
      setRecursos([]);
      setTitulos([]);
      return;
    }
    const [ok, ret] = await EstornoPagamentoService.buscarRecursosTitulosLote(
      idLote
    );
    setRecursos(ok ? ret.recursos : []);
    setTitulos(ok ? ret.titulos : []);
  };

  useEffect(() => {
    buscarRecursosTitulosLotes(selected);
  }, [selected]);

  return (
    <PageContainer title="Estorno de Pagamento" number="0112">
      <Card body>
        <Row>
          <FiltroPeriodoDatas label="Pagamento" onChange={handleDate} />
          <BotaoPesquisar onClick={carregarLotes} loading={loading} />
          <ConfEstornarPagamentoModal
            selected={selected}
            notifyEvent={carregarLotes}
          />
        </Row>
      </Card>
      <Card body>
        <EstornoPagamentoGrid
          dados={lotes}
          onSelect={setSelected}
          selected={selected}
        />
      </Card>
      <Card body>
        <Row>
          <Col md={5}>
            <Divider>Recursos Utilizados para Pagamento</Divider>
            <RecursosLoteGrid dados={recursos} />
          </Col>
          <Col md={7}>
            <Divider>Títulos Pagos no Lote</Divider>
            <TitulosLoteGrid dados={titulos} />
          </Col>
        </Row>
      </Card>
    </PageContainer>
  );
};
