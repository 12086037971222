import React, { useState } from "react";
import { BsFileText } from "react-icons/bs";
import { Table } from ".";
import { IconButton } from "./IconButton";
import { ModalBase } from "./ModalBase";

export const colunaModalDetalhes = (titleField, dataField, columns, size) => {
  return {
    dataField: "det",
    isDummyField: true,
    align: "center",
    headerAlign: "center",
    formatter: (c, row, index) => (
      <ModalDetalhes
        id={index}
        title={row[titleField]}
        data={row[dataField]}
        columns={columns ?? []}
        size={size}
      />
    ),
  };
};

export const ModalDetalhes = ({
  title,
  data,
  columns,
  size = "xl",
  hint = "Ver Detalhes",
  number,
  onOpen,
  onClose,
  disabled,
  gridPageSize,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    if (!isOpen) {
      if (onOpen) onOpen();
    } else {
      if (onClose) onClose();
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <IconButton
        icon={BsFileText}
        hint={hint}
        onClick={toggle}
        disabled={disabled}
        tooltipPlacement="left"
      />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size={size}
        title={
          <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            {title}
          </h5>
        }
        number={number}
        autoFocus
        hideCancelButton
      >
        <Table
          columns={columns}
          data={data ?? []}
          keyField="id"
          paginated={false}
          pageSize={gridPageSize}
        />
      </ModalBase>
    </>
  );
};
