import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/cadastro/produto";
const URL_BASE_FOTOS = `${URL_BASE}/fotos`;

const CadastroProdutosService = {
  urlBase: URL_BASE,
  listar: async (params) =>
    await apiGetV2(`${URL_BASE}/generico/listar/`, params),
  listarAutomotivo: async (params) =>
    await apiGetV2(`${URL_BASE}/automotivo/listar/`, params),
  pesquisa: async (pista, idProduto) =>
    apiGetV2(`${URL_BASE}/pesquisa/`, { pista: pista, id_produto: idProduto }),
  exportarProd: async (somenteAtivos, somenteSped) =>
    await apiGetV2(`${URL_BASE}/exportar_prod/`, {
      somente_ativos: somenteAtivos,
      somente_sped: somenteSped,
    }),
  buscar: async (id) => await apiGetV2(`${URL_BASE}/buscar/${id}/`),
  buscarParametros: async () => await apiGetV2("/tela/cad_produto/"),
  alterarId: async (payload) =>
    await apiPutV2(`${URL_BASE}/alterar_id/`, payload, {
      successMessageType: "modal",
    }),
  incluir: async (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  alterar: async (payload) => apiPutV2(`${URL_BASE}/alterar/`, payload),
  gerarCodBarras: async (idProduto) =>
    apiGetV2(`${URL_BASE}/gerar_cod_barras/${idProduto}/`),
  buscarDadosImprEtq: async (id) =>
    await apiGetV2(`${URL_BASE}/buscar_dados_impr_etq/${id}/`),
  buscarLogs: async (id, params) =>
    await apiGetV2(`${URL_BASE}/buscar_logs/${id}/`, params),
  transformarCuringa: (payload) =>
    apiPutV2(`${URL_BASE}/transformar_curinga/`, payload),
  verifSenhaAltEstoque: (payload) =>
    apiPostV2(`${URL_BASE}/verif_senha_alt_estoque/`, payload, {
      successMessage: false,
    }),
  fotos: {
    incluir: async (payload) =>
      await apiPostV2(`${URL_BASE_FOTOS}/incluir/`, payload),
    excluir: async (idFoto) =>
      await apiDeleteV2(`${URL_BASE_FOTOS}/excluir/${idFoto}/`),
    definirFotoPrincipal: async (payload) =>
      await apiPutV2(`${URL_BASE_FOTOS}/definir_foto_principal/`, payload, {
        successMessage: false,
      }),
  },
};

export default CadastroProdutosService;
