import { sliceIntoChunks } from "../../../../../coreUtils";

export const imprimirEtqL30 = (etiquetas) => {
  try {
    let etq = etiquetas.reduce(
      (acc, e) => [...acc, ...Array(e.qtd_etiquetas).fill(e)],
      []
    );

    etq = sliceIntoChunks(etq, 3);

    const resZpl = etq.reduce((acc, row) => {
      let cmd = "^XA";
      row.forEach((col, colIndex) => {
        let posQrCode;
        let posIdProd;
        if (colIndex === 0) {
          posQrCode = 110;
          posIdProd = 50;
        } else if (colIndex === 1) {
          posQrCode = 400;
          posIdProd = 340;
        } else {
          posQrCode = 680;
          posIdProd = 620;
        }
        cmd +=
          `^FWN^LH${posQrCode},-15^BQN,2,7,M,7^FD   ` +
          `${col.id_produto}^FS^LH${posIdProd},-120^A2, 20, 10^FWB^FD` +
          `${col.id_produto}^FS^`;
      });
      cmd += "XZ";

      return [...acc, cmd];
    }, []);
    return [true, resZpl];
  } catch (err) {
    return [false, err.message];
  }
};
