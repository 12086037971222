import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import { FormButton, NumberInput, TextInput } from "../../../../../components";
import { showWarning } from "../../../../../components/AlertaModal";
import { MODAL_ACTIONS, roundFloat } from "../../../../../coreUtils";
import { incluirAlterarProdutoRoute } from "../../../../../routes/modules/cadastro";
import { connect } from "react-redux";
import { ContextMenu } from "../../../../../redux/reducers/menuReducer";
import { showConfirmation } from "../../../../../components/ConfirmationModal";

const hintNaoLiberado =
  "Seu perfil ou plano não permite acesso ao Cadastro de Produtos";

const IncluirProdutoNfEntradaForm = ({ incluirItem, menus, itens }) => {
  const [idProduto, setIdProduto] = useState(null);
  const [nomeProduto, setNomeProduto] = useState("");
  const [referencia, setReferencia] = useState("");
  const [unidade, setUnidade] = useState("");
  const [cfop, setCfop] = useState("");
  const [quantidade, setQuantidade] = useState(0);
  const [vlrUnit, setVlrUnit] = useState();
  const [percDesc, setPercDesc] = useState(0);
  const [vlrDesc, setVlrDesc] = useState(0);
  const [vlrTotBruto, setVlrTotBruto] = useState(0);
  const [percIpi, setPercIpi] = useState(0);
  const [vlrIpi, setVlrIpi] = useState(0);
  const [sitTribIcms, setSitTribIcms] = useState("");
  const [percIcms, setPercIcms] = useState(0);
  const [vlrIcms, setVlrIcms] = useState(0);
  const [percIcmsSt, setPercIcmsSt] = useState(0);
  const [vlrIcmsSt, setVlrIcmsSt] = useState(0);
  const [qtdCasasDecimaisQtd, setQtdCasasDecimaisQtd] = useState(2);
  const [qtdCasasDecimaisVlrs, setQtdCasasDecimaisVlrs] = useState(4);

  const cadastroLiberado =
    (menus ?? []).find((e) => e.nroTela === "0029") !== undefined;

  const refPesqProd = useRef();
  const refQuantidade = useRef();

  const semProduto = [0, "", null, undefined].includes(idProduto);

  const limparDados = () => {
    setIdProduto(null);
    setNomeProduto("");
    setReferencia("");
    setUnidade("");
    setCfop("");
    setQuantidade(0);
    setVlrUnit();
    setPercDesc(0);
    setVlrDesc(0);
    setVlrTotBruto(0);
    setPercIpi(0);
    setVlrIpi(0);
    setSitTribIcms("");
    setPercIcms(0);
    setVlrIcms(0);
    setPercIcmsSt(0);
    setVlrIcmsSt(0);
    setQtdCasasDecimaisQtd(2);
    setQtdCasasDecimaisVlrs(4);
    if (refPesqProd) {
      refPesqProd.current.clear();
    }
  };

  const handleSelectProduto = ({
    idProduto,
    nomeProduto,
    referencia,
    unidade,
    cfop,
    casasDecimaisQtd,
    casasDecimaisVlrs,
  }) => {
    cfop = (cfop ?? "").trim();
    setIdProduto(idProduto);
    setNomeProduto(nomeProduto);
    setReferencia(referencia);
    setQuantidade(0);
    setVlrDesc(0);
    setVlrUnit(0);
    setUnidade(unidade);
    setVlrTotBruto(0);
    setQtdCasasDecimaisQtd(casasDecimaisQtd);
    setQtdCasasDecimaisVlrs(casasDecimaisVlrs);
    refPesqProd.current.setDescricao(nomeProduto);
    refPesqProd.current.setId(String(idProduto));
    refPesqProd.current.setReferencia(String(referencia));
  };

  const incluirAlterarProduto = (action) => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(incluirAlterarProdutoRoute.path);
    cadastro.window.parameters = JSON.stringify({
      id: id,
      action: action,
      selected: idProduto,
    });

    const func = async (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      if (refPesqProd.current) {
        refPesqProd.current.setValueByID(event.data.selected);
      }

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  const calcularValores = (campo, novoValor) => {
    // 1 - Cálculos por Valor
    // 2 - Cálculos por Percentual
    const tipoCalc = [
      "perc_desc",
      "perc_ipi",
      "perc_icms",
      "perc_icms_st",
    ].includes(campo)
      ? 2
      : 1;

    let vDesc = 0;
    let vIpi = 0;
    let vBaseCalcIcms = 0;
    let vIcms = 0;
    let vIcmsSt = 0;
    let pDesc = 0;
    let pIpi = 0;
    let pIcms = 0;
    let pIcmsSt = 0;

    let qtd = campo === "quantidade" ? novoValor : quantidade;
    let unit = campo === "vlr_unit" ? novoValor : vlrUnit;
    let valor = qtd * unit;

    if (tipoCalc === 1) {
      vDesc = campo === "vlr_desc" ? novoValor : vlrDesc;
      vIpi = campo === "vlr_ipi" ? novoValor : vlrIpi;
      vIcms = campo === "vlr_icms" ? novoValor : vlrIcms;
      vIcmsSt = campo === "vlr_icms_st" ? novoValor : vlrIcmsSt;

      if (valor > 0) {
        if (vDesc !== 0) {
          pDesc = (vDesc / valor) * 100;
        }
        if (vIpi !== 0) {
          pIpi = (vIpi / valor) * 100;
        }

        vBaseCalcIcms = valor;

        if (vIcms !== 0) {
          pIcms = (vIcms / vBaseCalcIcms) * 100;
        }
        if (vIcmsSt !== 0) {
          pIcmsSt = (vIcmsSt / vBaseCalcIcms) * 100;
        }
      }

      setPercDesc(roundFloat(pDesc, 2));
      setPercIpi(roundFloat(pIpi, 2));
      setPercIcms(roundFloat(pIcms, 2));
      setPercIcmsSt(roundFloat(pIcmsSt, 2));
    } else if (tipoCalc === 2) {
      pDesc = campo === "perc_desc" ? novoValor : percDesc;
      pIpi = campo === "perc_ipi" ? novoValor : percIpi;
      pIcms = campo === "perc_icms" ? novoValor : percIcms;
      pIcmsSt = campo === "perc_icms_st" ? novoValor : percIcmsSt;

      if (pDesc !== 0) {
        vDesc = valor * (pDesc / 100);
      }

      if (pIpi !== 0) {
        vIpi = valor * (pIpi / 100);
      }

      vBaseCalcIcms = valor;

      if (pIcms !== 0) {
        vIcms = vBaseCalcIcms * (pIcms / 100);
      }
      if (pIcmsSt !== 0) {
        vIcmsSt = vBaseCalcIcms * (pIcmsSt / 100);
      }

      setVlrDesc(roundFloat(vDesc, 2));
      setVlrIpi(roundFloat(vIpi, 2));
      setVlrIcms(roundFloat(vIcms, 2));
      setVlrIcmsSt(roundFloat(vIcmsSt, 2));
    }
    let vTotBruto = roundFloat(valor + vIpi + vIcmsSt - vDesc, 2);
    if (vTotBruto < 0) {
      vTotBruto = 0;
    }
    setVlrTotBruto(vTotBruto);
  };

  const handleSetQuantidade = (v) => {
    setQuantidade(v);
    calcularValores("quantidade", v);
  };

  const handleSetVlrUnit = (v) => {
    setVlrUnit(v);
    calcularValores("vlr_unit", v);
  };

  const handleSetPercDesc = (v) => {
    setPercDesc(v);
    calcularValores("perc_desc", v);
  };

  const handleSetVlrDesc = (v) => {
    setVlrDesc(v);
    calcularValores("vlr_desc", v);
  };

  const handleSetPercIpi = (v) => {
    setPercIpi(v);
    calcularValores("perc_ipi", v);
  };

  const handleSetVlrIpi = (v) => {
    setVlrIpi(v);
    calcularValores("vlr_ipi", v);
  };

  const handleSetPercIcms = (v) => {
    setPercIcms(v);
    calcularValores("perc_icms", v);
  };

  const handleSetVlrIcms = (v) => {
    setVlrIcms(v);
    calcularValores("vlr_icms", v);
  };

  const handleSetPercIcmsSt = (v) => {
    setPercIcmsSt(v);
    calcularValores("perc_icms_st", v);
  };

  const handleSetVlrIcmsSt = (v) => {
    setVlrIcmsSt(v);
    calcularValores("vlr_icms_st", v);
  };

  const handleSubmit = (faseValid = 0) => {
    if (["", null, undefined].includes(unidade)) {
      showWarning("Por favor, informe a Unidade");
      return;
    }

    if ([0, null, undefined].includes(quantidade)) {
      showWarning("Por favor, informe a Quantidade");
      return;
    }

    const valor = roundFloat(quantidade * vlrUnit, 2);

    if (vlrDesc > valor) {
      showWarning("O Valor de Desconto está Maior que o Valor do Item");
      return;
    }

    if (vlrIpi > valor) {
      showWarning("O Valor de IPI está Maior que o Valor do Item");
      return;
    }

    if (vlrIcms > valor) {
      showWarning("O Valor de ICMS está Maior que o Valor do Item");
      return;
    }

    if (vlrIcmsSt > valor) {
      showWarning("O Valor de ICMS ST está Maior que o Valor do Item");
      return;
    }

    if (percDesc > 100) {
      showWarning("O percentual de desconto deve estar entre 0% e 100 %");
      return;
    }

    if (percIpi > 100) {
      showWarning("O percentual de IPI deve estar entre 0% e 100 %");
      return;
    }

    if (percIcms > 100) {
      showWarning("O percentual de ICMS deve estar entre 0% e 100 %");
      return;
    }

    if (percIcmsSt > 100) {
      showWarning("O percentual de ICMS ST deve estar entre 0% e 100 %");
      return;
    }

    if (faseValid < 1) {
      if (itens.some((e) => e.id_produto === idProduto)) {
        showConfirmation(
          <>
            Este produto já foi incluído entre os itens da NF
            <br />
            Deseja incluí-lo novamente?
          </>,
          () => handleSubmit(1)
        );
        return;
      }
    }

    const payload = {
      id_produto: idProduto,
      nome_produto: nomeProduto,
      referencia: referencia,
      unidade: unidade,
      cfop: cfop,
      quantidade: quantidade,
      vlr_unit: vlrUnit,
      perc_desc: percDesc,
      vlr_desc: vlrDesc,
      vlr_tot_bruto: vlrTotBruto,
      perc_ipi: percIpi,
      vlr_ipi: vlrIpi,
      sit_trib_icms: sitTribIcms,
      perc_icms: percIcms,
      vlr_icms: vlrIcms,
      perc_icms_st: percIcmsSt,
      vlr_icms_st: vlrIcmsSt,
      qtd_casas_decimais_qtd: qtdCasasDecimaisQtd,
      qtd_casas_decimais_vlrs: qtdCasasDecimaisVlrs,
    };

    const ok = incluirItem(payload);
    if (ok) {
      limparDados();
      if (refPesqProd.current) {
        refPesqProd.current.focus();
      }
    }
  };

  return (
    <>
      <Row>
        <PesqProduto
          md={8}
          onConfirm={handleSelectProduto}
          ref={refPesqProd}
          selectNextField={() =>
            refQuantidade.current && refQuantidade.current.focus()
          }
          onChangeDescricao={setNomeProduto}
          mdIdent={3}
          mdIdentAux={3}
          labelDescricao="Nome do Produto"
        />
        <FormButton
          onClick={() =>
            incluirAlterarProduto(
              [0, "", null, undefined].includes(idProduto)
                ? MODAL_ACTIONS.ADD
                : MODAL_ACTIONS.EDIT
            )
          }
          md="auto"
          color="primary"
          disabled={!cadastroLiberado}
          disabledHint={hintNaoLiberado}
          divClassName="ml-auto"
        >
          Cadastro Produto
        </FormButton>
      </Row>
      <Row>
        <NumberInput
          md={2}
          label="Quantidade"
          value={quantidade}
          onChange={handleSetQuantidade}
          decimalPlaces={qtdCasasDecimaisQtd}
          disabled={semProduto}
          ref={refQuantidade}
        />
        <TextInput
          md={1}
          label="UN"
          value={unidade}
          onChange={(e, v) => setUnidade(v)}
          disabled={semProduto}
          maxLength={10}
        />
        <NumberInput
          md={2}
          label="Vlr Unitário"
          value={vlrUnit}
          onChange={handleSetVlrUnit}
          decimalPlaces={qtdCasasDecimaisVlrs}
          disabled={semProduto}
        />
        <NumberInput
          md={1}
          label="Desconto"
          divClassName="pr-0"
          value={percDesc}
          onChange={handleSetPercDesc}
          isPercentage
          disabled={semProduto}
        />
        <NumberInput
          md={2}
          label="Vlr Desconto"
          value={vlrDesc}
          onChange={handleSetVlrDesc}
          disabled={semProduto}
        />
        <TextInput
          md={1}
          label="CST"
          value={sitTribIcms}
          onChange={(e, v) => setSitTribIcms(v)}
          disabled={semProduto}
          maxLength={5}
        />
        <TextInput
          md={1}
          label="CFOP"
          value={cfop}
          onChange={(e, v) => setCfop(v)}
          disabled={semProduto}
          maxLength={5}
        />
      </Row>
      <Row className="mb-2">
        <NumberInput
          md={1}
          label="IPI"
          divClassName="pr-0"
          value={percIpi}
          onChange={handleSetPercIpi}
          isPercentage
          disabled={semProduto}
        />
        <NumberInput
          md={2}
          label="Vlr IPI"
          value={vlrIpi}
          onChange={handleSetVlrIpi}
          disabled={semProduto}
        />
        <NumberInput
          md={1}
          label="ICMS"
          divClassName="pr-0"
          value={percIcms}
          onChange={handleSetPercIcms}
          isPercentage
          disabled={semProduto}
        />
        <NumberInput
          md={2}
          label="Vlr ICMS"
          value={vlrIcms}
          onChange={handleSetVlrIcms}
          disabled={semProduto}
        />
        <NumberInput
          md={1}
          label="ICMS ST"
          divClassName="pr-0"
          value={percIcmsSt}
          onChange={handleSetPercIcmsSt}
          isPercentage
          disabled={semProduto}
        />
        <NumberInput
          md={2}
          label="Vlr ICMS ST"
          value={vlrIcmsSt}
          onChange={handleSetVlrIcmsSt}
          disabled={semProduto}
        />
        <NumberInput md={2} label="Total" value={vlrTotBruto} disabled />
        <FormButton
          onClick={() => handleSubmit(0)}
          disabled={semProduto}
          disabledHint="Selecione um Produto"
        >
          Adicionar Produto
        </FormButton>
      </Row>
    </>
  );
};

export default connect((store) => ({ menus: store.menus }), null, null, {
  context: ContextMenu,
})(IncluirProdutoNfEntradaForm);
