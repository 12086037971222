import React from "react";
import { Card } from "reactstrap";
import { DataTable } from "../../../../../components/DataTable";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "descricao",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
];

export const GrupoRegraTribGrid = ({ data, setSelected }) => {
  return (
    <Card body>
      <DataTable
        data={data}
        columns={columns}
        onSelect={setSelected}
        paginated
      />
    </Card>
  );
};
