import { ShoppingBag } from "react-feather";
import { GerenciamentoCompras } from "../../pages/compras/gerenciamento/gerenciamento_compra/GerenciamentoCompras";
import { Gerenciamento } from "../../pages/compras/gerenciamento/Gerenciamento";
import { EntradaNfXml } from "../../pages/compras/gerenciamento/entrada_nf_xml/EntradaNfXml";
import { ProdutosEntradaNfXml } from "../../pages/compras/gerenciamento/entrada_nf_xml/produtos/ProdutosEntradaNfXml";
import { FinanceiroEntradaNfXml } from "../../pages/compras/gerenciamento/entrada_nf_xml/financeiro/FinanceiroEntradaNfXml";
import { FracionamentoNfEntrada } from "../../pages/compras/gerenciamento/fracionamento_nf_entrada/FracionamentoNfEntrada";
import { GerarSugestaoCompra } from "../../pages/compras/gerenciamento/gerenciamento_compra/gerar_sugestao/GerarSugestaoCompra";
import { IncluirOrdemCompra } from "../../pages/compras/gerenciamento/gerenciamento_compra/ordem_compra/IncluirOrdemCompra";
import { GerenciamentoNfEntrada } from "../../pages/compras/gerenciamento/gerenciamento_nf_entrada/GerenciamentoNfEntrada";
import { EntradaManualNf } from "../../pages/compras/gerenciamento/entrada_manual_nf/EntradaManualNf";

export const entradaNfXmlRoute = {
  path: "/compras/gerenciamento/entrada_nf_xml",
  name: "Entrada de Nota Fiscal via XML",
  id: "AcSkWeb0101",
  nroTela: "0101",
  component: EntradaNfXml,
};

export const financeiroEntradaNfXmlRoute = {
  path: "/compras/gerenciamento/entrada_nf_xml/financeiro",
  name: "Entrada de Nota Fiscal via XML",
  id: "AcSkWeb0101",
  nroTela: "0101",
  component: FinanceiroEntradaNfXml,
};

export const fracionamentoNfEntradaRoute = {
  path: "/compras/gerenciamento/fracionamento_nf_entrada",
  name: "Fracionamento de Produtos da Nota Fiscal de Entrada",
  id: "AcSkWeb0110",
  nroTela: "0110",
  component: FracionamentoNfEntrada,
};

export const gerarSugestaoCompraRoute = {
  path: "/compras/gerenciamento/gerenciamento_compras/sugestao_compra",
  name: "Gerar Sugestão de Compra",
  id: "AcSkWeb0016",
  nroTela: "0016",
  component: GerarSugestaoCompra,
};

export const gerenciamentoComprasRoute = {
  path: "/compras/gerenciamento/gerenciamento_compras/",
  name: "Gerenciamento de Compras",
  id: "AcSkWeb0016",
  nroTela: "0016",
  component: GerenciamentoCompras,
};

export const gerenciamentoNfEntradaRoute = {
  path: "/compras/gerenciamento/gerenciamento_nf_entrada",
  name: "Gerenciamento de Notas Fiscais de Entrada",
  nroTela: "0115",
  component: GerenciamentoNfEntrada,
};

export const incluirOrdemCompraRoute = {
  path: "/compras/gerenciamento/gerenciamento_compras/ordem_compra/incluir",
  name: "Incluir Ordem de Compra",
  id: "AcSkWeb0016",
  nroTela: "0016",
  component: IncluirOrdemCompra,
};

export const produtosEntradaNfXmlRoute = {
  path: "/compras/gerenciamento/entrada_nf_xml/produtos",
  name: "Entrada de Nota Fiscal via XML",
  id: "AcSkWeb0101",
  nroTela: "0101",
  component: ProdutosEntradaNfXml,
};

export const entradaManualNfRoute = {
  path: "/compras/gerenciamento/entrada_manual_nf",
  name: "Entrada Manual de Nota Fiscal",
  nroTela: "0123",
  component: EntradaManualNf,
};

export const comprasGerenciamentoRoute = [
  entradaNfXmlRoute,
  entradaManualNfRoute,
  fracionamentoNfEntradaRoute,
  gerenciamentoComprasRoute,
  gerenciamentoNfEntradaRoute,
];

const comprasRoutes = {
  path: "/compras",
  name: "Compras",
  icon: ShoppingBag,
  children: [
    {
      path: "/compras/gerenciamento",
      name: "Gerenciamento",
      component: Gerenciamento,
      canGoBack: false,
    },
  ],
};

export default comprasRoutes;
