import React, { useCallback, useEffect } from "react";
import { Provider } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useLocation } from "react-router";
import { PageContainer } from "../../../../../components/PageContainer";
import { CadastroVistoriaContainer } from "./components/CadastroVistoriaContainer";
import store from "./store";

export const CadastroVistoriaTecnica = () => {
  const location = useLocation();
  const history = useHistory();

  const verifDados = useCallback(async () => {
    if (location.state.action === "INC") {
      if (!location.state.idOS) {
        toastr.error(
          "Erro",
          "Não foi possível identificar a O.S. para inclusão da vistoria técnica"
        );
        history.goBack();
      }
    } else {
      if (!location.state.idVistoria) {
        toastr.error(
          "Erro",
          "Não foi possível identificar a vistoria técnica para alteração"
        );
        history.goBack();
      }
    }
  }, [location.state, history]);

  useEffect(() => {
    verifDados();
  }, [verifDados]);

  return (
    <PageContainer
      title="Cadastro de Vistoria Técnica"
      number="0027_1"
      canGoBack
    >
      <Provider store={store}>
        <CadastroVistoriaContainer />
      </Provider>
    </PageContainer>
  );
};
