import React from "react";
import { formatNumber, formatValueFromAPI } from "../../../../../../coreUtils";
import { Table } from "../../../../../../components";

export const HistProdutosInativosGrid = ({ dados }) => {
  const columns = [
    {
      dataField: "desc_tipo",
      text: "Meta Para",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_tipo",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_tipo),
    },
    {
      dataField: "qtd_vendida",
      text: "Qtd. Vendida",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_vendido",
      text: "Valor Vendido",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
  ];

  return (
    <Table
      columns={columns}
      data={dados ?? []}
      keyField="id"
      style={{
        height: "300px",
        overflowX: "hidden",
      }}
      pageSize={(dados ?? []).length}
      paginated={false}
    />
  );
};
