import React, { useEffect } from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  LinkButton,
  PageContainer,
} from "../../../../components";
import {
  MODAL_ACTIONS,
  exportDataToSheet,
  formatDateISO,
  sumDataField,
} from "../../../../coreUtils";
import GerenciamentoCartaoService from "../../../../services/financeiro/GerenciamentoCartaoService";
import { CompensarCartaoModal } from "./components/CompensarCartaoModal";
import {
  GerenciamentoCartaoGrid,
  columnsListagemCartoes,
} from "./components/GerenciamentoCartaoGrid";
import { LancCartaoModal } from "./components/LancCartaoModal";
import { TaxasCartaoModal } from "./components/TaxasCartaoModal";
import { compensacoesRealizadasRoute } from "../../../../routes/modules/financeiro";
import { RecalcularTaxasCartoesModal } from "./components/RecalcularTaxasCartoesModal";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { docPrintReport } from "../../../../pdf";
import { showError } from "../../../../components/AlertaModal";

const tipoDataOptions = [
  {
    label: "Prev. Recebimento",
    value: "PRV",
  },
  {
    label: "Emissão",
    value: "EMI",
  },
];

const tipoCartaoOptions = [
  {
    label: "Todos",
    value: null,
  },
  {
    label: "Crédito",
    value: "C",
  },
  {
    label: "Débito",
    value: "D",
  },
  {
    label: "Parcelado",
    value: "P",
  },
];

export const GerenciamentoCartao = () => {
  const [idCliente, setIdCliente] = useState(null);
  const [idAdministradora, setIdAdministradora] = useState(null);
  const [idMeioPagamento, setIdMeioPagamento] = useState(null);
  const [tipo, setTipo] = useState(tipoCartaoOptions[0].value);
  const [nroOrigem, setNroOrigem] = useState("");
  const [filtrarPor, setFiltrarPor] = useState(tipoDataOptions[0].value);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [somenteNaoCompensados, setSomenteNaoCompensados] = useState(true);
  const [dados, setDados] = useState([]);
  // Controle
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [lancOpen, setLancOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [taxasOpen, setTaxasOpen] = useState(false);

  const limparDados = () => {
    setSelected([]);
    setLastSelected(null);
    setDados([]);
  };

  const buscarDados = async () => {
    const params = {
      id_cliente: idCliente,
      id_administradora: idAdministradora,
      tipo: tipo,
      filtrar_por: filtrarPor,
      data_ini: dataIni,
      data_fim: dataFim,
      nro_origem: nroOrigem,
      id_meio_pagamento: idMeioPagamento,
      somente_nao_compensados: somenteNaoCompensados,
    };

    return await GerenciamentoCartaoService.listar(params);
  };

  const carregarDados = async () => {
    limparDados();
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      const payload = {
        dados: ret,
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
      };
      await docPrintReport(
        "/relatorios/financeiro_receber/listagem_cartoes/",
        payload,
        "0049_6"
      );
    }
    setLoadingImprimir(false);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      try {
        exportDataToSheet(ret, "Listagem de Cartões", {
          columns: columnsListagemCartoes(true),
          dataIni: dataIni,
          dataFim: dataFim,
        });
      } catch (error) {
        showError(
          "Erro ao Gerar Exportação do Relatório",
          "ExpRelFront01",
          error.message
        );
      }
    }

    setLoadingExportar(false);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const handleSelect = (id, isSelect) => {
    setLastSelected(id);

    const sel = isSelect
      ? [...selected, id]
      : selected.filter((item) => item !== id);

    setSelected(sel);
  };

  const handleSelectAll = (isSelect) => {
    setSelected(isSelect ? dados.map((e) => e.id) : []);
  };

  const toggleTaxas = () => setTaxasOpen(!taxasOpen);

  const taxasLanc = (id) => {
    setLastSelected(id);
    toggleTaxas();
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) {
      setSelected([]);
      setLastSelected(null);
    }
  };

  const toggleLanc = (action) => {
    setAction(action);
    setLancOpen(!lancOpen);
  };

  const toggleExcluirLanc = () => setExcluirOpen(!excluirOpen);

  const alterarLanc = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleLanc(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluirLanc = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleExcluirLanc();
    }, 1);
  };

  useEffect(() => {
    limparDados();
  }, [
    idCliente,
    idAdministradora,
    idMeioPagamento,
    tipo,
    nroOrigem,
    filtrarPor,
    dataIni,
    dataFim,
    somenteNaoCompensados,
  ]);

  return (
    <PageContainer title="Gerenciamento de Cartões" number="0049" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
          />
          <AsyncComboBox
            md={3}
            label="Meio de Pagamento"
            isConcatField
            concatModelName="meio_pagamento"
            isSearchable
            isClearable
            defaultOptions
            onChange={(s) => setIdMeioPagamento(s?.value)}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={3}
            label="Adm. Cartão"
            isConcatField
            concatModelName="administradora_cartao"
            isSearchable
            isClearable
            defaultOptions
            onChange={(s) => setIdAdministradora(s?.value)}
          />
          <ComboBox
            label="Tipo de Cartão"
            md={2}
            onChange={(s) => setTipo(s?.value)}
            options={tipoCartaoOptions}
            defaultValue={tipoCartaoOptions[0].value}
          />
          <IntegerFormInput md={2} label="Nº Origem" onChange={setNroOrigem} />
          <FormCheckbox
            label="Somente NÃO compensados"
            checked={somenteNaoCompensados}
            onChange={() => setSomenteNaoCompensados(!somenteNaoCompensados)}
            name="somente_nao_compensados"
          />
        </Row>
        <Row>
          <ComboBox
            label="Filtrar Por"
            md={2}
            onChange={(s) => setFiltrarPor(s?.value)}
            options={tipoDataOptions}
            defaultValue={tipoDataOptions[1].value}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleLanc} />
          <LancCartaoModal
            isOpen={lancOpen}
            toggle={toggleLanc}
            action={action}
            selected={lastSelected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title="Cartão"
            isOpen={excluirOpen}
            toggle={toggleExcluirLanc}
            number="0049_2"
            message="Tem certeza de que deseja excluir o cartão selecionado?"
            selected={lastSelected}
            routeBase={GerenciamentoCartaoService.urlBase}
            notifyEvent={notifyEvent}
          />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportar}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
          <CompensarCartaoModal
            selected={selected}
            data={dados}
            notifyEvent={carregarDados}
          />
          <TaxasCartaoModal
            isOpen={taxasOpen}
            toggle={toggleTaxas}
            selected={lastSelected}
            compensado={dados.find((e) => e.id === lastSelected)?.compensado}
          />
          <RecalcularTaxasCartoesModal
            selected={selected}
            notifyEvent={carregarDados}
          />
          <LinkButton
            md="auto"
            color="info"
            pathname={compensacoesRealizadasRoute.path}
          >
            Compensações Realizadas
          </LinkButton>
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Total a Receber"
          value={sumDataField(dados, "vlr_receber")}
          className="col-md-3 ml-auto"
        />
        <CardTotaisItem
          label="Total Recebido"
          value={sumDataField(dados, "vlr_recebido")}
          className="col-md-2"
        />
      </CardTotais>
      <GerenciamentoCartaoGrid
        data={dados}
        handleSelect={handleSelect}
        handleSelectAll={handleSelectAll}
        selected={selected}
        alterarLanc={alterarLanc}
        excluirLanc={excluirLanc}
        taxasLanc={taxasLanc}
      />
    </PageContainer>
  );
};
