import React from "react";
import { Card, ListGroup, ListGroupItem } from "reactstrap";
import { formatNumber } from "../../../../../coreUtils";
import ValorComTooltip from "./ValorComTooltip";

const CardValores = ({
  vlrVdaMesApuracao,
  vlrVdaMesAnterior,
  vlrComissaoMesAnterior,
  percParticipacao,
  ticketMedio,
  vlrDescCaixaLoja,
  vlrFreteMesApuracao,
  vlrDevolMesApuracao,
  vlrVdaCliSemComisMesApura,
  vlrFreteMesAnt,
  vlrDevolMesAnt,
  vlrVdaCliSemComisMesAnt,
  vlrEmprVdaMesApuracao,
  vlrEmprVdaMesAnterior,
  vlrEmprFreteMesApuracao,
  vlrEmprDevolMesApuracao,
  vlrVdaEmprCliSemComisMes,
  vlrEmprFreteMesAnterior,
  vlrEmprDevolMesAnterior,
  vlrVdaEmprCliSemComisMesAnt,
  vlrServicosMesApura,
  vlrServicosMesAnt,
  vlrServicosEmprMes,
  vlrServicosEmprMesAnt,
}) => {
  return (
    <Card body>
      <ListGroup horizontal>
        <ListGroupItem>
          Valor total de vendas da Empresa no mês:{" "}
          <ValorComTooltip
            valorPrincipal={vlrEmprVdaMesApuracao}
            valorFrete={vlrEmprFreteMesApuracao}
            valorDevol={vlrEmprDevolMesApuracao}
            valorCliSemComissao={vlrVdaEmprCliSemComisMes}
            valorServicos={vlrServicosEmprMes}
            idTooltip="valor-venda-empresa-mes"
          />
        </ListGroupItem>
        <ListGroupItem>
          Valor total de vendas da Empresa no mês anterior:{" "}
          <ValorComTooltip
            valorPrincipal={vlrEmprVdaMesAnterior}
            valorFrete={vlrEmprFreteMesAnterior}
            valorDevol={vlrEmprDevolMesAnterior}
            valorCliSemComissao={vlrVdaEmprCliSemComisMesAnt}
            valorServicos={vlrServicosEmprMesAnt}
            idTooltip="valor-venda-empresa-mes-anterior"
          />
        </ListGroupItem>
      </ListGroup>
      <div style={{ height: "20px" }} />
      <ListGroup horizontal>
        <ListGroupItem>
          Valor total de vendas no mês:{" "}
          <ValorComTooltip
            valorPrincipal={vlrVdaMesApuracao}
            valorFrete={vlrFreteMesApuracao}
            valorDevol={vlrDevolMesApuracao}
            valorCliSemComissao={vlrVdaCliSemComisMesApura}
            valorServicos={vlrServicosMesApura}
            idTooltip="valor-venda-mes"
          />
        </ListGroupItem>
        <ListGroupItem>
          Valor total de vendas no mês anterior:{" "}
          <ValorComTooltip
            valorPrincipal={vlrVdaMesAnterior}
            valorFrete={vlrFreteMesAnt}
            valorDevol={vlrDevolMesAnt}
            valorCliSemComissao={vlrVdaCliSemComisMesAnt}
            valorServicos={vlrServicosMesAnt}
            idTooltip="valor-venda-mes-anterior"
          />
        </ListGroupItem>
        <ListGroupItem>
          Total de comissão no mês anterior para o vendedor:{" "}
          <strong>
            <span>{formatNumber(vlrComissaoMesAnterior, true, 2)}</span>
          </strong>
        </ListGroupItem>
      </ListGroup>
      <div style={{ height: "20px" }} />
      <ListGroup horizontal>
        <ListGroupItem>
          Percentual de participação em vendas do vendedor:{" "}
          <strong>
            <span>{formatNumber(percParticipacao, true, 2)}%</span>
          </strong>
        </ListGroupItem>
        <ListGroupItem>
          Ticket médio do vendedor no mês:{" "}
          <strong>
            <span>{formatNumber(ticketMedio, true, 2)}</span>
          </strong>
        </ListGroupItem>
        <ListGroupItem>
          Valor de desconto no crédito rotativo do vendedor no mês:{" "}
          <strong>
            <span>{formatNumber(vlrDescCaixaLoja, true, 2)}</span>
          </strong>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default CardValores;
