import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ult_numero: 0,
  ult_lote: 0,
  camin_salvar_xml: null,
  tipo_contrib: "T",
  ciot: null,
  cpf_cnpj_resp_ciot: null,
  rntrc: null,
  tipo_carga_prod_predomina: 1,
  descr_prod_predomina: null,
  ean_prod_predomina: null,
  ncm_prod_predomina: null,
  unid_med_carga: "KG",
  id_tomador_padrao: null,
};

export const mdfeSlice = createSlice({
  name: "mdfe_slice",
  initialState: initialState,
  reducers: {
    setupMdfe: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setUltNumero: (state, action) => {
      state.ult_numero = action.payload;
    },
    setUltLote: (state, action) => {
      state.ult_lote = action.payload;
    },
    setCaminSalvarXml: (state, action) => {
      state.camin_salvar_xml = action.payload;
    },
    setTipoContrib: (state, action) => {
      state.tipo_contrib = action.payload;
    },
    setCiot: (state, action) => {
      state.ciot = action.payload;
    },
    setCpfCnpjRespCiot: (state, action) => {
      state.cpf_cnpj_resp_ciot = action.payload;
    },
    setRntrc: (state, action) => {
      state.rntrc = action.payload;
    },
    setTipoCargaProdPredomina: (state, action) => {
      state.tipo_carga_prod_predomina = action.payload;
    },
    setDescrProdPredomina: (state, action) => {
      state.descr_prod_predomina = action.payload;
    },
    setEanProdPredomina: (state, action) => {
      state.ean_prod_predomina = action.payload;
    },
    setNcmProdPredomina: (state, action) => {
      state.ncm_prod_predomina = action.payload;
    },
    setUnidMedCarga: (state, action) => {
      state.unid_med_carga = action.payload;
    },
    setIdTomadorPadrao: (state, action) => {
      state.id_tomador_padrao = action.payload;
    },
  },
});

export const {
  setupMdfe,
  setUltNumero,
  setUltLote,
  setCaminSalvarXml,
  setTipoContrib,
  setCiot,
  setCpfCnpjRespCiot,
  setRntrc,
  setTipoCargaProdPredomina,
  setDescrProdPredomina,
  setEanProdPredomina,
  setNcmProdPredomina,
  setUnidMedCarga,
  setIdTomadorPadrao,
} = mdfeSlice.actions;

export default mdfeSlice.reducer;
