import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { formatNumber } from "../../../../../coreUtils";
import ValorComTooltip from "./ValorComTooltip";

const CardValores = ({
  vlrVdaMesApuracao,
  vlrVdaMesAnterior,
  vlrComissaoMesAnterior,
  percParticipacao,
  ticketMedio,
  vlrFreteMesApuracao,
  vlrDevolMesApuracao,
  vlrVdaCliSemComisMesApura,
  vlrFreteMesAnt,
  vlrDevolMesAnt,
  vlrVdaCliSemComisMesAnt,
  vlrEmprVdaMesApuracao,
  vlrEmprVdaMesAnterior,
  vlrEmprFreteMesApuracao,
  vlrEmprDevolMesApuracao,
  vlrVdaEmprCliSemComisMes,
  vlrEmprFreteMesAnterior,
  vlrEmprDevolMesAnterior,
  vlrVdaEmprCliSemComisMesAnt,
}) => {
  return (
    <>
      <ListGroup horizontal>
        <ListGroupItem>
          <span>
            Valor Total de Vendas <strong>do Vendedor no Mês Anterior: </strong>
          </span>
          <ValorComTooltip
            valorPrincipal={vlrVdaMesAnterior}
            valorFrete={vlrFreteMesAnt}
            valorDevol={vlrDevolMesAnt}
            valorCliSemComissao={vlrVdaCliSemComisMesAnt}
            idTooltip="valor-venda-mes-anterior"
          />
        </ListGroupItem>
        <ListGroupItem>
          Total de Comissão no Mês Anterior para o Vendedor:{" "}
          <strong>
            <span>{formatNumber(vlrComissaoMesAnterior, true, 2)}</span>
          </strong>
        </ListGroupItem>
        <ListGroupItem>
          <span>
            Valor Total de Vendas <strong>do Vendedor no Mês: </strong>
          </span>
          <ValorComTooltip
            valorPrincipal={vlrVdaMesApuracao}
            valorFrete={vlrFreteMesApuracao}
            valorDevol={vlrDevolMesApuracao}
            valorCliSemComissao={vlrVdaCliSemComisMesApura}
            idTooltip="valor-venda-mes"
            style={{ marginLeft: "auto", marginRight: "5px" }}
          />
        </ListGroupItem>
      </ListGroup>
      <ListGroup horizontal>
        <ListGroupItem>
          Valor Total de Vendas da Empresa no mês anterior:{" "}
          <ValorComTooltip
            valorPrincipal={vlrEmprVdaMesAnterior}
            valorFrete={vlrEmprFreteMesAnterior}
            valorDevol={vlrEmprDevolMesAnterior}
            valorCliSemComissao={vlrVdaEmprCliSemComisMesAnt}
            idTooltip="valor-venda-empresa-mes-anterior"
          />
        </ListGroupItem>
        <ListGroupItem>
          Valor Total de Vendas da Empresa no mês:{" "}
          <ValorComTooltip
            valorPrincipal={vlrEmprVdaMesApuracao}
            valorFrete={vlrEmprFreteMesApuracao}
            valorDevol={vlrEmprDevolMesApuracao}
            valorCliSemComissao={vlrVdaEmprCliSemComisMes}
            idTooltip="valor-venda-empresa-mes"
          />
        </ListGroupItem>
      </ListGroup>
      <ListGroup horizontal className="mb-3">
        <ListGroupItem>
          Percentual de Participação em Vendas do Vendedor:{" "}
          <strong>
            <span>{formatNumber(percParticipacao, true, 2)}%</span>
          </strong>
        </ListGroupItem>
        <ListGroupItem>
          Ticket Médio do Vendedor no Nês:{" "}
          <strong>
            <span>{formatNumber(ticketMedio, true, 2)}</span>
          </strong>
        </ListGroupItem>
      </ListGroup>
    </>
  );
};

export default CardValores;
