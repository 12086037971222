import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormButton,
  ModalBase,
  Table,
} from "../../../../../components";
import { apiGetV2 } from "../../../../../apiV2";
import { Row } from "reactstrap";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const columns = [
  { dataField: "data_hora_mov", text: "Data/Hora", align: "center" },
  {
    dataField: "id_origem",
    text: "Origem",
    align: "center",
    formatter: (c, row) => `${row.desc_origem} ${c}`,
  },
  {
    dataField: "nome_produto",
    text: "Produto",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
  },
  { dataField: "histor", text: "Histórico", align: "left" },
  {
    dataField: "qtd_antes",
    text: "Qtd. Antes",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "qtd_depois",
    text: "Qtd. Depois",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_custo",
    text: "Custo",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const MovimentacoesEstoqueInterModal = ({ selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idProduto, setIdProduto] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      id_produto: idProduto,
      data_ini: dataIni,
      data_fim: dataFim,
    };
    setLoading(true);
    const [ok, ret] = await apiGetV2(
      `/pronta_entrega/rota_estoque_inter/listar_transacoes/${selected}/`,
      params
    );
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton
        padded={false}
        color="primary"
        md="auto"
        onClick={toggle}
        disabled={[0, null, undefined].includes(selected)}
      >
        Movimentações
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Movimentações do Estoque Intermediário"
        number="0096_3"
        onBeforeOpen={carregarDados}
      >
        <Row className="mb-2">
          <AsyncComboBox
            md={5}
            label="Produto"
            isConcatField
            concatModelName="produto"
            isSearchable
            onChange={(s) => setIdProduto(s?.value)}
            defaultValue={idProduto}
            hideShortcut
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
        <Table data={dados} columns={columns} paginated={false} pageSize={10} />
      </ModalBase>
    </>
  );
};
