import React, { useState } from "react";
import { TextInput } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import NotaFiscalServicoService from "../../../../../services/docs_eletron/NotaFiscalServicoService";

export const CancelarNFSeModal = ({ isOpen, toggle, selected, notify }) => {
  const [observ, setObserv] = useState("");

  const limparDados = () => {
    setObserv("");
  };

  const onConfirm = async () => {
    const [ok] = await NotaFiscalServicoService.cancelarNFSe(selected, observ);
    if (ok) {
      notify();
      toggle();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      onClosed={limparDados}
      title="Cancelar NFSe"
      number="0046_3"
      autoFocus
      hideCancelButton
      onConfirm={onConfirm}
    >
      <TextInput
        label="Informe o motivo do cancelamento"
        md={null}
        onChange={(_, v) => setObserv(v)}
      />
    </ModalBase>
  );
};
