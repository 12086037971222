import React from "react";
import { useState } from "react";
import { AsyncComboBox, TextInput } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import { modalTitleFabricante, routesBaseFabricante } from "../Fabricante";
import { ModalCadastroV2 } from "../../../../../components/cadastro";

export const CadastroFabricanteModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [nome, setNome] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [idCidade, setIdCidade] = useState(null);
  const [telefone, setTelefone] = useState("");
  const [site, setSite] = useState("");
  const [email, setEmail] = useState("");

  const limparDados = () => {
    setNome("");
    setEndereco("");
    setBairro("");
    setIdCidade(null);
    setTelefone("");
    setSite("");
    setEmail("");
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setEndereco(data.endereco ?? "");
    setBairro(data.bairro ?? "");
    setIdCidade(data.id_cidade);
    setTelefone(data.telefone ?? "");
    setSite(data.site ?? "");
    setEmail(data.email ?? "");
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(nome)) {
      toastr.warning("Atenção", "Por favor, informe o Nome do Fabricante");
      return false;
    }

    const payload = {
      nome: nome,
      endereco: endereco,
      bairro: bairro,
      id_cidade: idCidade,
      telefone: telefone,
      site: site,
      email: email,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleFabricante}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseFabricante}
      number="0065_1"
      selected={selected}
      notifyEvent={notifyEvent}
      concatShortcut={concatShortcut}
      concatModelName="fabricante"
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={40}
        />
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Endereço"
          value={endereco}
          onChange={(e, v) => setEndereco(v)}
          maxLength={40}
        />
        <TextInput
          md={5}
          label="Bairro"
          value={bairro}
          onChange={(e, v) => setBairro(v)}
          maxLength={25}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={7}
          isConcatField
          concatModelName="cidade"
          defaultOptions={action === MODAL_ACTIONS.EDIT}
          label="Cidade"
          isSearchable
          isClearable
          onChange={(s) => setIdCidade(s?.value ?? null)}
          defaultValue={idCidade}
        />
        <TextInput
          md={5}
          label="Telefone"
          value={telefone}
          onChange={(e, v) => setTelefone(v)}
          maxLength={18}
        />
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Site"
          value={site}
          onChange={(e, v) => setSite(v)}
          maxLength={60}
        />
        <TextInput
          md={6}
          label="E-Mail"
          value={email}
          onChange={(e, v) => setEmail(v)}
          maxLength={60}
        />
      </Row>
    </ModalCadastroV2>
  );
};
