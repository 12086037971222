import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  ComboBox,
  FormButton,
  FormCheckbox,
  ModalBase,
} from "../../../../../components";
import { dateRangeAsTitle, exportDataToSheet } from "../../../../../coreUtils";
import { columnsNfSaidaGeral } from "./NfSaidaGeralGrid";
import XLSX from "xlsx";

const ordenarPorOpts = [
  { label: "Emissão", value: "EMI" },
  { label: "Número", value: "NUM" },
];

const columnsRelatorio = columnsNfSaidaGeral(true);

const columnsItens = [
  { text: "Produto", align: "center", formatter: () => "Produto" },
  { dataField: "item", text: "IT", align: "center" },
  {
    dataField: "id_produto",
    text: "Prod",
    align: "center",
  },
  {
    dataField: "descricao",
    text: "Nome",
    align: "left",
  },
  { dataField: "ncm", text: "NCM", align: "center" },
  {
    dataField: "cfop",
    text: "CFOP",
    align: "center",
  },
  {
    dataField: "unidade",
    text: "UN",
    align: "center",
  },
  {
    dataField: "quantidade",
    text: "Qtd",
    align: "center",
    formatter: (c) => parseFloat(c),
  },
  {
    dataField: "vlr_item",
    text: "Item",
    align: "right",
    formatter: (c) => parseFloat(c),
  },
  {
    dataField: "sit_trib_icms",
    text: "CST",
    align: "center",
  },
  {
    dataField: "vlr_icms",
    text: "Vlr. ICMS",
    align: "right",
    formatter: (c) => parseFloat(c),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    formatter: (c) => parseFloat(c),
  },
];

export const ExportarNfSaidaGeralModal = ({
  buscarDados,
  dataIni,
  dataFim,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ordenarPor, setOrdenarPor] = useState(ordenarPorOpts[0].value);
  const [incluirProdutos, setIncluirProdutos] = useState(false);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setOrdenarPor(ordenarPorOpts[0].value);
    setIncluirProdutos(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const imprimir = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados("NAO_AGR", ordenarPor);
    if (ok) {
      if (incluirProdutos) {
        const data = [];

        data.push(columnsRelatorio.map((col) => col.text));
        data.push(columnsItens.map((col) => col.text));

        ret.notas.forEach((row) => {
          data.push(
            columnsRelatorio.map((col) =>
              col.formatter
                ? col.formatter(row[col.dataField])
                : row[col.dataField]
            )
          );

          const itens = row.itens ?? [];

          itens.forEach((item) =>
            data.push(
              columnsItens.map((col) =>
                col.formatter
                  ? col.formatter(item[col.dataField])
                  : item[col.dataField]
              )
            )
          );
        });

        let ws = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(ws, data);
        const wb = { Sheets: { Relatório: ws }, SheetNames: ["Relatório"] };

        XLSX.writeFile(
          wb,
          `NF Saída Geral - ${dateRangeAsTitle(dataIni, dataFim)}.xlsx`
        );
      } else {
        exportDataToSheet(ret.notas, "NF Saída Geral", {
          columns: columnsRelatorio,
          dataIni: dataIni,
          dataFim: dataFim,
        });
      }
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton md="auto" color="primary" onClick={toggle}>
        Exportar
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Exportar Relatório NF Saída Geral"
        number="5046_2"
        onClosed={limparDados}
        onConfirm={imprimir}
        loadingConfirm={loading}
      >
        <Row>
          <ComboBox
            label="Ordenar Por"
            options={ordenarPorOpts}
            md={10}
            onChange={(s) => setOrdenarPor(s?.value)}
            defaultValue={ordenarPor}
            isSearchable={false}
          />
        </Row>
        <Row>
          <FormCheckbox
            padded={false}
            label="Incluir Produtos"
            checked={incluirProdutos}
            onChange={() => setIncluirProdutos(!incluirProdutos)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
