import React, { useState } from "react";
import {
  ModalBase,
  ModalDetalhesIcone,
  Table,
} from "../../../../../components";
import { formatNumber, sumDataField } from "../../../../../coreUtils";
import GerenciamentoComissoesService from "../../../../../services/frente_venda_mobile/GerenciamentoComissaoService";

export const DetalhamentoComissaoModal = ({
  idRota,
  dataIni,
  dataFim,
  nomeRota,
}) => {
  const [dados, setDados] = useState([]);
  const [totalVendas, setTotalVendas] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const buscarDados = async () => {
    const [
      ok,
      res,
    ] = await GerenciamentoComissoesService.buscarDetalhamentoComiss(
      idRota,
      dataIni,
      dataFim
    );

    if (ok) {
      setDados(res);
      setTotalVendas(sumDataField(res, "vlr_tot_vend_prod"));
    } else {
      setDados([]);
      setTotalVendas(0);
    }
  };

  const columns = [
    {
      dataField: "id_produto",
      text: "# Produto",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_produto",
      text: "Nome",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "qtd_vend",
      text: "Qtd. Vendida",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2, true),
    },
    {
      dataField: "vlr_tot_vend_prod",
      text: "Total Vendido",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "perc_comis",
      text: "% Comissão",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_comissao",
      text: "Valor Comissão",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
  ];

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
    } else {
      setDados([]);
      setTotalVendas(0);
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalDetalhesIcone id={idRota} toggle={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title={
          <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            Detalhamento de Comissão: {nomeRota}
          </h5>
        }
        number="5020_1"
        autoFocus
        hideCancelButton
      >
        <div style={{ flexDirection: "row" }}>
          <h5>
            Total de Vendas:{" "}
            <strong>
              <span>{formatNumber(totalVendas, true, 2)}</span>
            </strong>
          </h5>
        </div>
        <Table
          columns={columns}
          data={dados}
          keyField="id_produto"
          paginated={false}
        />
      </ModalBase>
    </>
  );
};
