import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  marca_todos_doc_sangria: true,
  impr_recibo_todos_receb: false,
  permite_desc_caixa: false,
  desc_max_caixa: 0,
  layout_fech_caixa: 1,
  emite_nfe: false,
  tipo_nfe_prod: "NFCE",
  nfe_auto_rec_cartao: false,
  nfe_auto_rec_pix: false,
  obriga_observ_sangria: false,
  permite_pagto_duplic_pag: false,
  permite_receber_transf_banc: false,
  id_plano_conta_receb_transf_banc: null,
  id_plano_conta_receb_dinheiro: null,
  imprimir: "P",
  layout_sangria: 1,
  nome_impressora: "",
  id_plano_cta_desconto_cred_rotat: null,
  sistema_gerenc_abertura_gaveta: false,
  saldo_abertura_igual_ultimo_fechamento: true,
  calcula_e_mostra_saldo_no_fechamento: true,
  tem_tef: false,
  id_conta_banc_receb_transf_banc: null,
  vlr_aviso_sangria: 0,
  define_por_conta_frete: "FX0",
};

const fieldsToNull = [
  "id_plano_conta_receb_transf_banc",
  "id_conta_banc_receb_transf_banc",
];

export const caixaSlice = createSlice({
  name: "caixa_slice",
  initialState: initialState,
  reducers: {
    setupCaixa: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value === 0 && fieldsToNull.includes(key)) {
          value = null;
        }

        if (key === "tipo_nfe_prod") {
          value = (value ?? "").trim();
          if (value === "" && action.payload.emite_nfe === false) {
            value = null;
          }
        }

        if (["desc_max_caixa", "vlr_aviso_sangria"].includes(key)) {
          value = parseFloat(value);
        }
        state[key] = value;
      });
      return state;
    },
    setMarcaTodosDocSangria: (state, action) => {
      state.marca_todos_doc_sangria = action.payload;
    },
    setImprReciboTodosReceb: (state, action) => {
      state.impr_recibo_todos_receb = action.payload;
    },
    setPermiteDescCaixa: (state, action) => {
      state.permite_desc_caixa = action.payload;
    },
    setDescMaxCaixa: (state, action) => {
      state.desc_max_caixa = action.payload;
    },
    setLayoutFechCaixa: (state, action) => {
      state.layout_fech_caixa = action.payload;
    },
    setEmiteNfe: (state, action) => {
      state.emite_nfe = action.payload;
    },
    setTipoNfeProd: (state, action) => {
      state.tipo_nfe_prod = action.payload;
    },
    setNfeAutoRecCartao: (state, action) => {
      state.nfe_auto_rec_cartao = action.payload;
    },
    setNfeAutoRecPix: (state, action) => {
      state.nfe_auto_rec_pix = action.payload;
    },
    setObrigaObservSangria: (state, action) => {
      state.obriga_observ_sangria = action.payload;
    },
    setPermitePagtoDuplicPag: (state, action) => {
      state.permite_pagto_duplic_pag = action.payload;
    },
    setPermiteReceberTransfBanc: (state, action) => {
      state.permite_receber_transf_banc = action.payload;
    },
    setIdPlanoContaRecebTransfBanc: (state, action) => {
      state.id_plano_conta_receb_transf_banc = action.payload;
    },
    setIdPlanoContaRecebDinheiro: (state, action) => {
      state.id_plano_conta_receb_dinheiro = action.payload;
    },
    setImprimir: (state, action) => {
      state.imprimir = action.payload;
    },
    setLayoutSangria: (state, action) => {
      state.layout_sangria = action.payload;
    },
    setNomeImpressora: (state, action) => {
      state.nome_impressora = action.payload;
    },
    setIdPlanoCtaDescontoCredRotat: (state, action) => {
      state.id_plano_cta_desconto_cred_rotat = action.payload;
    },
    setSistemaGerencAberturaGaveta: (state, action) => {
      state.sistema_gerenc_abertura_gaveta = action.payload;
    },
    setSaldoAberturaIgualUltimoFechamento: (state, action) => {
      state.saldo_abertura_igual_ultimo_fechamento = action.payload;
    },
    setCalculaEMostraSaldoNoFechamento: (state, action) => {
      state.calcula_e_mostra_saldo_no_fechamento = action.payload;
    },
    setTemTef: (state, action) => {
      state.tem_tef = action.payload;
    },
    setIdContaBancRecebTransfBanc: (state, action) => {
      state.id_conta_banc_receb_transf_banc = action.payload;
    },
    setVlrAvisoSangria: (state, action) => {
      state.vlr_aviso_sangria = action.payload;
    },
    setDefinePorContaFrete: (state, action) => {
      state.define_por_conta_frete = action.payload;
    },
  },
});

export const {
  setupCaixa,
  setMarcaTodosDocSangria,
  setImprReciboTodosReceb,
  setPermiteDescCaixa,
  setDescMaxCaixa,
  setLayoutFechCaixa,
  setEmiteNfe,
  setTipoNfeProd,
  setNfeAutoRecCartao,
  setNfeAutoRecPix,
  setObrigaObservSangria,
  setPermitePagtoDuplicPag,
  setPermiteReceberTransfBanc,
  setIdPlanoContaRecebTransfBanc,
  setIdPlanoContaRecebDinheiro,
  setImprimir,
  setLayoutSangria,
  setNomeImpressora,
  setIdPlanoCtaDescontoCredRotat,
  setSistemaGerencAberturaGaveta,
  setSaldoAberturaIgualUltimoFechamento,
  setCalculaEMostraSaldoNoFechamento,
  setTemTef,
  setIdContaBancRecebTransfBanc,
  setVlrAvisoSangria,
  setDefinePorContaFrete,
} = caixaSlice.actions;

export default caixaSlice.reducer;
