import React from "react";
import { Card, UncontrolledTooltip } from "reactstrap";
import { Table } from "../../../../../components";
import {
  formatNumber,
  naturalSort,
  numericNaturalSort,
} from "../../../../../coreUtils";

const tituloComHint = (id, title, hint) => {
  return (
    <>
      <span id={`hint_title_${id}`}>{title}</span>
      <UncontrolledTooltip target={`hint_title_${id}`}>
        {hint}
      </UncontrolledTooltip>
    </>
  );
};

export const VisaoPorPeriodoGrid = ({ dados, selectRota }) => {
  const columns = [
    {
      dataField: "nome",
      text: "Rota",
      align: "left",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_vendedor",
      text: "Vendedor",
      align: "left",
    },
    {
      dataField: "saldo_inicial",
      text: tituloComHint(
        3,
        "Saldo Inicial",
        "Saldo do primeiro dia do mês até a data inicial"
      ),
      align: "right",
      sortable: true,
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "lancado_adm",
      text: "Lançado pela ADM",
      align: "right",
      sortable: true,
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "gerado_venda",
      text: "Gerado na Venda",
      align: "right",
      sortable: true,
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "utilizado_venda",
      text: "Utilizado na Venda",
      align: "right",
      sortable: true,

      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "saldo_final",
      text: "Saldo Final",
      align: "right",
      sortable: true,
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "saldo_atual",
      text: tituloComHint(1, "Saldo Atual", "Saldo disponível na data de hoje"),
      align: "right",
      sortable: true,
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "custo_flex",
      text: tituloComHint(
        2,
        "Resultado Flex",
        <>
          Resultado Flex é o valor de perda ou ganho sobre o processo de flex.
          <br />
          Valores negativos representam perda
          <br />
          Valores positivos representam ganho
        </>
      ),
      align: "right",
      sortable: true,
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: numericNaturalSort,
    },
  ];

  return (
    <>
      <Card body>
        <Table
          data={dados}
          columns={columns}
          keyField="id"
          paginated={false}
          onSelect={selectRota}
        />
      </Card>
    </>
  );
};
