import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  ComboBox,
  MaskedInput,
  ModalBase,
  TextInput,
  Table,
} from "../../../../../components";
import { Row } from "reactstrap";
import { optionsFretePorContaNF } from "../../../../docs_eletron/notas_fiscais/nota_fiscal/incluir_alterar/components/DadosFreteNf";
import { formatNumber, ufs } from "../../../../../coreUtils";
import RemessaEntreEmpresasService from "../../../../../services/faturamento/RemessaEntreEmpresasService";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";

const GerarRemessaModal = ({ selected, isOpen, toggle, notifyEvent }) => {
  const [idTransportadoraNfe, setIdTransportadoraNfe] = useState(null);
  const [fretePorContaNfe, setFretePorContaNfe] = useState(
    optionsFretePorContaNF[0].value
  );
  const [placaVeiculoNfe, setPlacaVeiculoNfe] = useState(null);
  const [ufVeiculoNfe, setUfVeiculoNfe] = useState(null);
  const [rntrcNfe, setRntrcNfe] = useState(null);
  const [especieNfe, setEspecieNfe] = useState(null);
  const [marcaNfe, setMarcaNfe] = useState(null);
  const [numeroVolumeNfe, setNumeroVolumeNfe] = useState(null);
  const [produtosRemessa, setProdutosRemessa] = useState([]);

  const [loading, setLoading] = useState(false);

  const buscarProdutosRemessa = async () => {
    const [ok, ret] = await RemessaEntreEmpresasService.buscar(selected);
    if (ok) {
      setProdutosRemessa(
        ret.produtos.map((e) => ({
          id_produto: e.id_produto,
          nome_prod: e.nome_prod,
          unidade: e.unidade,
          quantidade: parseFloat(e.quantidade),
          vlr_custo_unit: parseFloat(e.vlr_custo_unit),
          vlr_custo_total: parseFloat(e.vlr_custo_total),
        }))
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      buscarProdutosRemessa();
    }
  }, [isOpen]);

  const columns = [
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_prod", text: "Nome", align: "left" },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
    },
    { dataField: "unidade", text: "UN", align: "center" },
    {
      dataField: "vlr_custo_unit",
      text: "Custo Unit.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
    },
    {
      dataField: "vlr_custo_total",
      text: "Custo Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
    },
  ];

  const limparDados = () => {
    setIdTransportadoraNfe(null);
    setFretePorContaNfe(optionsFretePorContaNF[0].value);
    setPlacaVeiculoNfe(null);
    setUfVeiculoNfe(null);
    setRntrcNfe(null);
    setEspecieNfe(null);
    setMarcaNfe(null);
    setNumeroVolumeNfe(null);
    setProdutosRemessa([]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        id_remessa: selected,
        id_transportadora_nfe: idTransportadoraNfe,
        frete_por_conta_nfe: fretePorContaNfe ?? "0",
        placa_veiculo_nfe: placaVeiculoNfe ?? "",
        uf_veiculo_nfe: ufVeiculoNfe ?? "",
        rntrc_nfe: rntrcNfe ?? "",
        especie_nfe: especieNfe ?? "",
        marca_nfe: marcaNfe ?? "",
        numero_volume_nfe: numeroVolumeNfe ?? "",
      };

      const [ok, ret] = await RemessaEntreEmpresasService.gerarRemessa(payload);
      if (ok) {
        if (ret.nfe_emitida) {
          await NotaFiscalService.imprimir(ret.id_nfe);
        }

        notifyEvent();
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Gerar Remessa Entre Empresas"
      number="0098_1"
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row>
        <AsyncComboBox
          isConcatField
          concatModelName="transportadora"
          md={5}
          label="Transportadora"
          isSearchable
          isClearable
          onChange={(s) => setIdTransportadoraNfe(s?.value)}
          defaultOptions
        />
        <ComboBox
          md={2}
          label="Frete Por Conta"
          options={optionsFretePorContaNF}
          onChange={(s) => setFretePorContaNfe(s?.value)}
          defaultValue={fretePorContaNfe}
          isSearchable={false}
        />
        <MaskedInput
          mask="aaa-9*99"
          label="Placa"
          md={2}
          onChange={(e, v) => setPlacaVeiculoNfe(v)}
          value={placaVeiculoNfe}
          maskChar={null}
        />
        <ComboBox
          options={ufs}
          md={1}
          label="UF Veículo"
          onChange={(selected) => setUfVeiculoNfe(selected?.value)}
          defaultValue={ufVeiculoNfe}
        />
        <TextInput
          md={2}
          label="Espécie"
          value={especieNfe}
          onChange={(e, v) => setEspecieNfe(v)}
          maxLength={12}
        />
      </Row>
      <Row className="mb-2">
        <TextInput
          md={2}
          label="Marca"
          value={marcaNfe}
          onChange={(e, v) => setMarcaNfe(v)}
          maxLength={12}
        />
        <TextInput
          md={2}
          label="Número"
          value={numeroVolumeNfe}
          onChange={(e, v) => setNumeroVolumeNfe(v)}
          maxLength={12}
        />
        <TextInput
          md={2}
          label="RNTRC"
          value={rntrcNfe}
          onChange={(e, v) => setRntrcNfe(v)}
          maxLength={20}
        />
      </Row>
      <Table
        data={produtosRemessa}
        columns={columns}
        paginated={false}
        keyField="id_produto"
        pageSize={15}
      />
    </ModalBase>
  );
};

export default GerarRemessaModal;
