import React from "react";
import { Table } from "../../../../../components";

const columns = [
  { dataField: "nome_aplic", text: "Modelo", align: "left" },
  { dataField: "ano_ini_aplic", text: "De", align: "center" },
  { dataField: "ano_fim_aplic", text: "Até", align: "center" },
];

export const AplicacoesGrid = ({ aplicacoes }) => {
  return (
    <Table
      columns={columns}
      data={aplicacoes}
      paginated={false}
      pageSize={3}
      fixedSize
      clipText
      showRegisterCount={false}
    />
  );
};
