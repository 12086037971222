import React from "react";
import { Card, DropdownItem, Row } from "reactstrap";
import { FormButton, MoreDropdown, Table } from "../../../../../components";
import { columnsRemessa } from "./TitulosRemessaGrid";
import { formatNumber, sumDataField } from "../../../../../coreUtils";

export const TitulosAddRemessaGrid = ({
  duplicatas = [],
  handleOnSelect,
  handleOnSelectAll,
  selected,
  addDups,
  naoEnviarTitRemessa,
}) => {
  const columns = [
    ...columnsRemessa,
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => naoEnviarTitRemessa(row.id)}>
            Não Enviar à Remessa
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  const qtdTitulos = (duplicatas ?? []).length;

  return (
    <Card body>
      <Row className="mx-0 mb-2">
        <strong className="mt-2">
          TÍTULOS DISPONÍVEIS - {qtdTitulos} titulo{qtdTitulos === 1 ? "" : "s"}{" "}
          | Valor Total:{" "}
          {formatNumber(sumDataField(duplicatas, "vlr_receber"), true, 2)}
        </strong>
        <FormButton
          md="auto"
          padded={false}
          divClassName="ml-auto pr-0"
          color="primary"
          onClick={addDups}
          disabled={selected?.length === 0}
        >
          Adicionar à Remessa
        </FormButton>
      </Row>
      <Table
        data={duplicatas}
        columns={columns}
        multiselect
        onSelect={handleOnSelect}
        onSelectAll={handleOnSelectAll}
        selected={selected}
        pageSize={10}
        showRegisterCount={false}
      />
    </Card>
  );
};
