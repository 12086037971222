import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, CustomInput, FormGroup, Label, Row, TabPane } from "reactstrap";
import { AsyncComboBox, TextInput } from "../../../../../../components";
import { DatePicker } from "../../../../../../components/DatePicker";
import {
  setDataVisita,
  setDemandaContratada,
  setFoneResponsavel,
  setFoneSolicitante,
  setHoraVisita,
  setResponsavel1Vistoria,
  setResponsavel2Vistoria,
  setResponsavelCliente,
  setSolicitanteCliente,
  setTipoDemanda,
  setUC,
  setUCCompensacao,
} from "../store/vistoria_slice";

export const TabInfoCliente = () => {
  const vistoriaTecnica = useSelector((state) => state.vistoriaTecnica);
  const dispatch = useDispatch();
  return (
    <TabPane tabId="1" className="p-2">
      <Row>
        <Col md="auto">
          <Label>Endereço:</Label>
          <h6>{vistoriaTecnica.enderecoCliente}</h6>
        </Col>
        <Col md="auto">
          <Label>N˚</Label>
          <h6>{vistoriaTecnica.numEndCliente}</h6>
        </Col>
        {vistoriaTecnica.complementoCliente && (
          <Col md="auto">
            <Label>Complemento</Label>
            <h6>{vistoriaTecnica.complementoCliente}</h6>
          </Col>
        )}
        <Col md="auto">
          <Label>Bairro</Label>
          <h6>{vistoriaTecnica.bairroCliente}</h6>
        </Col>
        <Col md="auto">
          <Label>Cidade</Label>
          <h6>
            {vistoriaTecnica.cidadeCliente}-{vistoriaTecnica.cidadeUfCliente}
          </h6>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <Label>CEP</Label>
          <h6>{vistoriaTecnica.cepCliente}</h6>
        </Col>
        <Col md="auto">
          <Label>Referência:</Label>
          <h6>{vistoriaTecnica.referenciaCliente}</h6>
        </Col>
        <Col>
          <Label>Fone:</Label>
          <h6>{vistoriaTecnica.foneCliente}</h6>
        </Col>
      </Row>
      <Row>
        <TextInput
          label="UC"
          md={2}
          value={vistoriaTecnica.UC}
          onChange={({ target }) => {
            dispatch(setUC(target?.value));
          }}
          maxLength={40}
        />
        <DatePicker
          label="Data da Visita"
          md={2}
          value={vistoriaTecnica.dataVisita}
          onChange={(value) => {
            dispatch(setDataVisita(value));
          }}
        />
        <TextInput
          type="time"
          label="Hora da Visita"
          md={2}
          value={vistoriaTecnica.horaVisita}
          onChange={({ target }) => {
            dispatch(setHoraVisita(target?.value));
          }}
        />
      </Row>
      <Row>
        <TextInput
          label="Responsável"
          md={4}
          value={vistoriaTecnica.responsavelCliente}
          onChange={({ target }) => {
            dispatch(setResponsavelCliente(target?.value));
          }}
          maxLength={60}
        />
        <TextInput
          label="Telefone"
          md={3}
          value={vistoriaTecnica.foneResponsavel}
          onChange={({ target }) => {
            dispatch(setFoneResponsavel(target?.value));
          }}
          maxLength={18}
        />
      </Row>
      <Row>
        <TextInput
          label="Solicitante"
          md={4}
          value={vistoriaTecnica.solicitanteCliente}
          onChange={({ target }) => {
            dispatch(setSolicitanteCliente(target?.value));
          }}
          maxLength={60}
        />
        <TextInput
          label="Telefone"
          md={3}
          value={vistoriaTecnica.foneSolicitante}
          onChange={({ target }) => {
            dispatch(setFoneSolicitante(target?.value));
          }}
          maxLength={18}
        />
      </Row>
      <Row>
        <TextInput
          label="UC de Compensação"
          md={6}
          value={vistoriaTecnica.UCCompensacao}
          onChange={({ target }) => {
            dispatch(setUCCompensacao(target?.value));
          }}
          maxLength={40}
        />
      </Row>
      <Row>
        <Col md="auto">
          <FormGroup
            onChange={({ target }) =>
              dispatch(setDemandaContratada(target?.value))
            }
          >
            <Label>Demanda Contratada</Label>
            <div>
              <CustomInput
                type="radio"
                name="demanda_contratada"
                id="demanda_contratada_sim"
                label="Sim"
                value="SIM"
                inline
                checked={vistoriaTecnica.demandaContratada === "SIM"}
              />
              <CustomInput
                type="radio"
                name="demanda_contratada"
                id="demanda_contratada_nao"
                label="Não"
                value="NAO"
                inline
                checked={vistoriaTecnica.demandaContratada === "NAO"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => {
              dispatch(setTipoDemanda(target?.value));
            }}
          >
            <Label>Tipo de Demanda</Label>
            <div>
              <CustomInput
                type="radio"
                name="tipoDemanda"
                id="norte_tipo_demanda"
                label="O&M"
                value="OEM"
                inline
                checked={vistoriaTecnica.tipoDemanda === "OEM"}
              />
              <CustomInput
                type="radio"
                name="tipoDemanda"
                id="vistoria_tipo_demanda"
                label="Vistoria"
                value="VIS"
                inline
                checked={vistoriaTecnica.tipoDemanda === "VIS"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <AsyncComboBox
          label="Responsável 1"
          isConcatField
          concatModelName="tecnico"
          defaultOptions
          isSearchable
          isClearable
          onChange={(selected) =>
            dispatch(setResponsavel1Vistoria(selected?.value))
          }
          defaultValue={vistoriaTecnica.responsavel1Vistoria}
        />
        <AsyncComboBox
          label="Responsável 2"
          isConcatField
          concatModelName="tecnico"
          defaultOptions
          isSearchable
          isClearable
          onChange={(selected) =>
            dispatch(setResponsavel2Vistoria(selected?.value))
          }
          defaultValue={vistoriaTecnica.responsavel2Vistoria}
        />
      </Row>
    </TabPane>
  );
};
