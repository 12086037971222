import * as types from "../constants";

const initialState = {
  isLoading: true,
  isAuthenticated: false,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_AUTH_IS_LOADING:
      return { ...state, isLoading: actions.data };
    case types.SET_AUTH_IS_AUTHENTICATED:
      return { ...state, isAuthenticated: actions.data };
    default:
      return state;
  }
}
