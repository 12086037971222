import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { chartColors, formatNumber } from "../coreUtils";
import { OrderedTooltip } from "./index";

class TimeSeriesLineChart extends React.Component {
  state = { opacity: {} };

  componentWillMount() {
    let opacity = {};
    const { lineDataKeys } = this.props;
    lineDataKeys.forEach((element) => {
      if (typeof element !== "string") {
        opacity[element.name] = 1;
      } else {
        opacity[element] = 1;
      }
    });

    this.setState({ opacity: opacity });
  }

  handleMouseEnter = ({ value }) => {
    const { opacity } = this.state;
    Object.keys(opacity).forEach((element) => {
      if (element !== value) opacity[element] = 0.25;
    });

    this.setState({ opacity: opacity });
  };

  handleMouseLeave = () => {
    const { opacity } = this.state;
    Object.keys(opacity).forEach((element) => {
      opacity[element] = 1;
    });

    this.setState({ opacity: opacity });
  };

  render() {
    const {
      data,
      title,
      dataKey,
      lineDataKeys,
      total,
      isRank,
      biaxial,
      labels,
      hideTotal,
      hideValues,
      height,
      tooltipFormatter,
    } = this.props;
    var xLabel = false;
    var yLabel = false;
    var yLabelRight = false;
    if (labels) {
      if ("x" in labels) {
        xLabel = labels["x"];
      }
      if ("y" in labels) {
        yLabel = labels["y"];
      }
      if ("y2" in labels) {
        yLabelRight = labels["y2"];
      }
    }

    const { opacity } = this.state;
    return (
      <Container fluid className="p-0">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">{title}</CardTitle>
            {total !== undefined && (
              <h6 className="card-subtitle text-muted">
                Total do período:{" "}
                <span
                  style={
                    hideTotal ? { filter: "blur(5px)", userSelect: "none" } : {}
                  }
                >
                  {formatNumber(hideTotal ? 9999.99 : total)}
                </span>
              </h6>
            )}
          </CardHeader>
          <CardBody>
            <ResponsiveContainer width="100%" height={height ?? 300}>
              <LineChart data={data}>
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey={dataKey}
                  padding={{ left: 30, right: 30 }}
                  label={xLabel}
                />
                <YAxis yAxisId="left" label={yLabel} />
                {biaxial && (
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    label={yLabelRight}
                  />
                )}
                {data.length && (
                  <Tooltip
                    content={
                      <OrderedTooltip
                        isRank={isRank !== undefined}
                        hideValues={hideValues}
                        formatter={tooltipFormatter}
                      />
                    }
                  />
                )}
                <Legend
                  formatter={(value) =>
                    value.replace(/\w\S*/g, function (txt) {
                      return (
                        txt.charAt(0).toUpperCase() +
                        txt.substr(1).toLowerCase()
                      );
                    })
                  }
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                />
                {lineDataKeys.map((k, i) => {
                  var dataKey = k;
                  var yAxisId = "left";
                  if (typeof k != "string") {
                    dataKey = k.name;
                    yAxisId = k.axis;
                  }
                  return (
                    <Line
                      type="monotoneX"
                      key={i}
                      dot={true}
                      connectNulls={true}
                      dataKey={dataKey}
                      stroke={chartColors[i]}
                      strokeWidth={2}
                      yAxisId={yAxisId}
                      strokeDasharray={yAxisId === "right" ? "5 5" : "1 0"}
                      strokeOpacity={opacity[dataKey]}
                    />
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default TimeSeriesLineChart;
