import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import {
  BotaoPesquisar,
  FiltroPeriodoDatas,
  PageContainer,
  BaseInfoTelaCard,
} from "../../../../components";
import HorasVendidasTrabalhadasService from "../../../../services/ordem_servico/HorasVendidasTrabalhadasService";
import { HorasVendidasTrabalhadasGrid } from "./components/HorasVendidasTrabalhadasGrid";

export const HorasVendidasHorasTrabalhadas = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [ordensCab, setOrdensCab] = useState([]);
  const [indicadores, setIndicadores] = useState({});

  const limparDados = () => {
    setOrdensCab([]);
    setIndicadores([]);
  };

  const handleDate = (dataIni, dataFim) => {
    setDataIni(dataIni);
    setDataFim(dataFim);
    limparDados();
  };

  const buscarDados = async () => {
    if (!(dataIni instanceof Date)) {
      toastr.warning("Atenção", "Por favor, revise a data de início.");
      return;
    }

    if (!(dataFim instanceof Date)) {
      toastr.warning("Atenção", "Por favor, revise a data de fim.");
      return;
    }

    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
    };

    setIsLoading(true);

    const [
      okCab,
      retOrdensCab,
    ] = await HorasVendidasTrabalhadasService.buscarOrdensReq(params);

    if (okCab) {
      const [
        okIndic,
        retIndic,
      ] = await HorasVendidasTrabalhadasService.buscarIndicadores(params);
      if (okIndic) {
        setOrdensCab(retOrdensCab);
        setIndicadores(retIndic.totalizadores);
      } else {
        setOrdensCab([]);
        setIndicadores({});
      }
    } else {
      setOrdensCab([]);
      setIndicadores({});
    }
    setIsLoading(false);
  };

  return (
    <PageContainer
      title=" Horas Vendidas X Trabalhadas"
      number="5011"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={buscarDados} loading={isLoading} />
        </Row>
      </Card>
      {ordensCab.length > 0 && (
        <Card>
          <CardHeader className="pb-0">
            <CardTitle tag="h5">Ordens de Serviço</CardTitle>
            <h6 className="card-subtitle text-muted">
              Clique para expandir e visualizar os detalhes
            </h6>
          </CardHeader>
          <CardBody>
            <HorasVendidasTrabalhadasGrid dados={ordensCab} />
          </CardBody>
        </Card>
      )}
      <Container fluid className="p-0">
        {ordensCab.length > 0 && (
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Indicadores</CardTitle>
              <ListGroup>
                <ListGroupItem>
                  Total de horas vendidas:
                  <span className="ml-2 mr-3">
                    {indicadores?.horas_vend} horas
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  Total de horas trabalhadas:
                  <span className="ml-2 mr-3">
                    {indicadores?.horas_trab} horas
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  Indicativo:
                  <span
                    className={`ml-2 mr-3 text-${
                      indicadores?.saldo?.includes("-") ? "danger" : "success"
                    }`}
                  >
                    {indicadores?.saldo} horas
                  </span>
                </ListGroupItem>
              </ListGroup>
            </CardHeader>
          </Card>
        )}
      </Container>
      <BaseInfoTelaCard nroTela="5011" />
    </PageContainer>
  );
};
