import React from "react";
import { MoreDropdown, Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";

export const GerenciamentoOSGrid = ({
  dados,
  mecanica,
  onSelect,
  selected,
  mostrarDetalhes,
  reabrir,
  mostrarLogs,
  estornarRecebimento,
  excluirOS,
}) => {
  const columns = [
    { dataField: "id", text: "# OS", align: "center" },
    { dataField: "data_abertura", text: "Emissão", align: "center" },
    { dataField: "data_fechamento", text: "Fechamento", align: "center" },
    { dataField: "km", text: "KM", align: "center", hidden: !mecanica },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "nome_veic",
      text: "Veículo",
      align: "left",
      hidden: !mecanica,
    },
    { dataField: "placa", text: "Placa", align: "center", hidden: !mecanica },
    {
      dataField: "modelo_veic",
      text: "Modelo",
      align: "left",
      hidden: !mecanica,
    },
    { dataField: "ano_veic", text: "Ano", align: "center", hidden: !mecanica },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    { dataField: "desc_status", text: "Situação", align: "center" },
    {
      dataField: "det",
      text: "",
      align: "center",
      dummy: true,
      formatter: (c, row) => (
        <MoreDropdown>
          <DropdownItem onClick={() => mostrarDetalhes(row.id)}>
            Detalhes
          </DropdownItem>
          <DropdownItem onClick={() => mostrarLogs(row.id)}>Logs</DropdownItem>
          <DropdownItem
            style={{ color: "red" }}
            onClick={() => excluirOS(row.id, row.nome_cliente)}
          >
            Excluir O.S.
          </DropdownItem>
          {row.status === "F" && (
            <>
              <DropdownItem divider />
              <DropdownItem onClick={() => estornarRecebimento(row.id)}>
                Estornar Recebimento
              </DropdownItem>
              <DropdownItem onClick={() => reabrir(row.id)}>
                Reabrir
              </DropdownItem>
            </>
          )}
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      pageSize={8}
      paginated={false}
      onSelect={onSelect}
      selected={selected}
    />
  );
};
