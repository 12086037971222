import React from "react";
import { useState } from "react";
import {
  FormCheckbox,
  MaskedInput,
  TextInput,
} from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleMeioPagto, routesBaseMeioPagto } from "../MeioPagto";

export const CadastroMeioPagtoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [temTef, setTemTef] = useState(false);
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setNome("");
    setCnpj("");
    setTemTef(false);
    setAtivo(true);
  };

  const fetchData = (data) => {
    setNome(data.nome ?? "");
    setCnpj(data.cnpj ?? "");
    setTemTef(data.tem_tef);
    setAtivo(data.ativo);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      cnpj: cnpj,
      tem_tef: temTef,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ativo: ativo, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleMeioPagto}
      size="sm"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseMeioPagto}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
      number="0081_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={12}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={40}
        />
      </Row>
      <Row>
        <MaskedInput
          mask="99.999.999/9999-99"
          label="CNPJ"
          md={6}
          value={cnpj}
          onChange={(e, v) => setCnpj(v)}
          maskChar={null}
        />
        <FormCheckbox
          label="Tem TEF"
          checked={temTef}
          onChange={() => setTemTef(!temTef)}
        />
      </Row>
    </ModalCadastroV2>
  );
};
