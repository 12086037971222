import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sinc_cad_prod: false,
  sinc_cad_cli: false,
  id_ult_pln_cta_desp_pronta_entrega_sinc: 0,
  campo_cad_prod_control_est: "SINCRONIZA",
  sinc_cad_colaborador: false,
  sinc_dados_bi: false,
};

export const sincronismoSlice = createSlice({
  name: "sincronismo_slice",
  initialState: initialState,
  reducers: {
    setupSincronismo: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setSincCadProd: (state, action) => {
      state.sinc_cad_prod = action.payload;
    },
    setSincCadCli: (state, action) => {
      state.sinc_cad_cli = action.payload;
    },
    setIdUltPlnCtaDespProntaEntregaSinc: (state, action) => {
      state.id_ult_pln_cta_desp_pronta_entrega_sinc = action.payload;
    },
    setCampoCadProdControlEst: (state, action) => {
      state.campo_cad_prod_control_est = action.payload;
    },
    setSincCadColaborador: (state, action) => {
      state.sinc_cad_colaborador = action.payload;
    },
    setSincDadosBi: (state, action) => {
      state.sinc_dados_bi = action.payload;
    },
  },
});

export const {
  setupSincronismo,
  setSincCadProd,
  setSincCadCli,
  setIdUltPlnCtaDespProntaEntregaSinc,
  setCampoCadProdControlEst,
  setSincCadColaborador,
  setSincDadosBi,
} = sincronismoSlice.actions;

export default sincronismoSlice.reducer;
