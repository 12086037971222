import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory, useLocation } from "react-router";
import { Card, Row } from "reactstrap";
import {
  DatePicker,
  Divider,
  FixedField,
  FormButton,
  HintLabel,
  NumberInput,
  PageContainer,
  TextInput,
} from "../../../../../components";
import { BoletosRecebidosGrid } from "./components/BoletosRecebidosGrid";
import { formatNumber, objectIsEmpty } from "../../../../../coreUtils";
import ConferenciaValoresJornadaService from "../../../../../services/frente_venda_mobile/ConferenciaValoresJornadaService";

export const ConfirmacaoConferencia = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [dadosJornadaDia, setDadosJornadaDia] = useState({});
  const [vlrConferidoAdm, setVlrConferidoAdm] = useState(null);
  const [quebraConferencia, setQuebraConferencia] = useState(0);
  const [dataEntradaFinanc, setDataEntradaFinanc] = useState(null);
  const [observ, setObserv] = useState("");

  const [loading, setLoading] = useState(false);

  const carregarDados = async (idDia) => {
    const [ok, ret] = await ConferenciaValoresJornadaService.buscarDadosDia(
      idDia
    );
    if (ok) {
      setDadosJornadaDia(ret);
    }
    return ok;
  };

  useEffect(() => {
    const { idDia } = state;

    if (objectIsEmpty(state) || !idDia) {
      toastr.error(
        "Erro",
        "Não foi possível identificar o dia a ser conferido"
      );
      history.goBack();
    } else {
      carregarDados(idDia);
    }
  }, [history, state]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if ([0, null, undefined, ""].includes(vlrConferidoAdm)) {
        toastr.warning("Atenção", "Informe o valor conferido");
        return;
      }
      if ([null, undefined].includes(dataEntradaFinanc)) {
        toastr.warning("Atenção", "Informe a data da entrada no financeiro");
        return;
      }
      const { idDia } = state;
      const payload = {
        id_jorn_dia: idDia,
        data_comp: dataEntradaFinanc?.toISOString().split("T")[0],
        vlr_din_conf: vlrConferidoAdm,
        vlr_quebra_conf: quebraConferencia,
        observ: observ ?? "",
      };

      const [ok] = await ConferenciaValoresJornadaService.confirmarConferencia(
        payload
      );
      if (ok) {
        history.goBack();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleValorConferido = (valor) => {
    setQuebraConferencia(
      (valor ?? 0) - Number.parseFloat(dadosJornadaDia.vlr_din_malote)
    );

    setVlrConferidoAdm(valor);
  };

  return (
    <PageContainer
      title="Conferir Valores de Jornada Diária"
      number="0025_2"
      canGoBack
    >
      <Card body>
        <Row>
          <FixedField
            label="Rota"
            value={dadosJornadaDia.id_cab__id_rota_mob__nome}
          />
          <FixedField
            label="Data"
            value={`${dadosJornadaDia.data} - Essa data será utilizada no lançamento do plano de contas do dinheiro`}
          />
          {dadosJornadaDia.encerramento && (
            <h3
              className="mt-auto ml-auto mb-2 mr-4"
              style={{ color: "#002a57" }}
            >
              Dia de Encerramento
            </h3>
          )}
        </Row>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingInlineEnd: "2rem" }}>
            <Divider className="mt-2 mb-0">Bonificação / Troca</Divider>
            <Row>
              <NumberInput
                md={4}
                label="Bonificação"
                value={dadosJornadaDia.vlr_bonif}
                disabled
              />
              <NumberInput
                md={4}
                label="Troca Dia"
                value={dadosJornadaDia.vlr_tot_troca}
                disabled
              />
              <NumberInput
                md={4}
                label="Troca Jornada"
                value={dadosJornadaDia.vlr_tot_troca_jornada}
                disabled
              />
            </Row>
            {(dadosJornadaDia.params?.existe_redutor_vlr_troca ||
              dadosJornadaDia.params
                ?.permite_usar_saldo_flex_na_troca_paga) && (
              <Row>
                {dadosJornadaDia.params?.existe_redutor_vlr_troca && (
                  <NumberInput
                    md={4}
                    label="% Comissão sobre Troca (Redutor)"
                    value={dadosJornadaDia.perc_redu_troca_paga}
                    isPercentage
                    disabled
                  />
                )}
                {dadosJornadaDia.params
                  ?.permite_usar_saldo_flex_na_troca_paga && (
                  <NumberInput
                    md={4}
                    label="Troca Paga com Flex"
                    value={dadosJornadaDia.vlr_desc_flex_na_troca_paga}
                    disabled
                  />
                )}
                <NumberInput
                  md={4}
                  label="Troca Paga pelo Vendedor"
                  value={dadosJornadaDia.vlr_troca_paga}
                  disabled
                />
              </Row>
            )}
          </div>
          <div style={{ width: "50%" }}>
            <Divider className="mt-2 mb-0">Venda</Divider>
            <Row>
              <NumberInput
                md={4}
                label="Boleto"
                value={dadosJornadaDia.vlr_tot_boleto}
                disabled
              />
              <NumberInput
                md={4}
                label="Dinheiro"
                value={dadosJornadaDia.vlr_din_venda}
                disabled
              />
              <NumberInput
                md={4}
                label="Total"
                value={dadosJornadaDia.vlr_tot_venda}
                disabled
              />
            </Row>
            {(dadosJornadaDia.params?.permite_recebimento_pix ||
              dadosJornadaDia.params?.permite_recebimento_cred_rotativo) && (
              <Row>
                {dadosJornadaDia.params?.permite_recebimento_pix && (
                  <NumberInput
                    md={4}
                    label="PIX"
                    value={dadosJornadaDia.vlr_tot_pix}
                    disabled
                    autoFocus
                  />
                )}
                {dadosJornadaDia.params?.permite_recebimento_cred_rotativo && (
                  <NumberInput
                    md={4}
                    label="Rotativo"
                    value={dadosJornadaDia.vlr_tot_rotativo}
                    disabled
                    autoFocus
                  />
                )}
              </Row>
            )}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingInlineEnd: "2rem" }}>
            <Divider className="mt-2 mb-0">Comissão</Divider>
            <Row>
              <NumberInput
                md={4}
                label="Valor Bruto"
                value={dadosJornadaDia.vlr_comis_bruto}
                disabled
              />
              <NumberInput
                md={4}
                label="Descontado"
                value={dadosJornadaDia.vlr_comis_descontado}
                disabled
              />
              <NumberInput
                md={4}
                label={
                  <HintLabel
                    label="Sacado"
                    hintText="Valor Bruto Comissao - Descontado - Descontado do Empréstimo = Sacado"
                  />
                }
                value={dadosJornadaDia.vlr_comis_sacado}
                disabled
              />
            </Row>
          </div>
          <div style={{ width: "50%" }}>
            <Divider className="mt-2 mb-0">Despesa</Divider>
            <Row>
              <NumberInput
                md={4}
                label="Despesa"
                value={dadosJornadaDia.vlr_din_despesa}
                disabled
              />
            </Row>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", paddingInlineEnd: "2rem" }}>
            <Divider className="mt-2 mb-0">Empréstimo do Colaborador</Divider>
            <Row>
              <NumberInput
                md={4}
                label="Valor da Parcela"
                value={dadosJornadaDia.vlr_parcela_emprestimo}
                disabled
              />
              <NumberInput
                md={4}
                label="Valor Descontado da Comissão"
                value={dadosJornadaDia.vlr_pago_emprestimo}
                disabled
              />
              <NumberInput
                md={4}
                label={
                  <HintLabel
                    label="Valor Pendente (cobrança futura)"
                    hintText="Este valor será acrescido ao valor de quebra da conferência no dia atual aberto para o vendedor"
                  />
                }
                value={dadosJornadaDia.vlr_pen_parcela_emprestimo}
                disabled
              />
            </Row>
          </div>
        </div>
        <Divider className="mt-2 mb-0">Conferência</Divider>
        <div style={{ width: "50%", paddingInlineEnd: "2rem" }}>
          <Row>
            {/*
              Quando é fechamento do dia, o valor é o preenchido no app pelo
              usuário.
              Quando é encerramento, o valor é o calculado pelo sistema.
            */}
            <NumberInput
              md={4}
              label={
                dadosJornadaDia.encerramento
                  ? "Deveria ser entregue malote"
                  : "Informado no Fechamento"
              }
              value={dadosJornadaDia.vlr_din_malote}
              disabled
            />
            {dadosJornadaDia.encerramento && (
              <NumberInput
                md={4}
                label="Informou No Encerramento"
                value={dadosJornadaDia.id_cab__vlr_din_encer_jorn_contado_usu}
                disabled
              />
            )}
            <NumberInput
              md={4}
              label="Quebra do Dia"
              value={dadosJornadaDia.vlr_saldo_din_fechou_dia}
              disabled
            />
            <NumberInput
              md={4}
              label="Quebra da Semana"
              value={dadosJornadaDia.id_cab__vlr_saldo_din_encerramento}
              disabled
            />
          </Row>
          <Row>
            <NumberInput
              md={4}
              label="Valor Conferido"
              onChange={handleValorConferido}
              value={vlrConferidoAdm}
              allowNegativeValue
            />
            <NumberInput
              md={4}
              label="Quebra de Conferência"
              disabled
              value={quebraConferencia}
            />
            <DatePicker
              md={4}
              label="Data Entrada no Financeiro"
              onChange={setDataEntradaFinanc}
            />
          </Row>
        </div>
        <Row>
          <TextInput
            md={10}
            label="Observação"
            maxLength={250}
            value={observ}
            onChange={(_, v) => setObserv(v)}
            placeholder="Esta observação irá aparecer para o vendedor no aplicativo de pronta entrega"
          />
          <FormButton
            md="auto"
            color="info"
            onClick={handleSubmit}
            loading={loading}
          >
            Confirmar
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <Row style={{ justifyContent: "space-between" }} className="no-gutters">
          <span className="divider-text">Boletos Recebidos em Mãos</span>
          <span style={{ fontWeight: "bold", fontSize: 14 }}>
            Total:{" "}
            {formatNumber(dadosJornadaDia.vlr_din_receb_boleto ?? 0, true, 2)}
          </span>
        </Row>
        <BoletosRecebidosGrid dados={dadosJornadaDia.boletos} />
      </Card>
    </PageContainer>
  );
};
