import React, { useState } from "react";
import { Row } from "reactstrap";
import { MODAL_ACTIONS } from "../../coreUtils";
import { ModalBase } from "../ModalBase";
import { apiDeleteV2 } from "../../apiV2";

export const ModalExcluirV2 = ({
  isOpen,
  toggle,
  notifyEvent,
  route,
  routeBase,
  selected,
  title,
  number,
  message = "Tem certeza de que deseja excluir o registro selecionado?",
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const [ok] = await apiDeleteV2(
      route ?? `${routeBase}/excluir/${selected}/`
    );
    if (ok) {
      toggle();
      notifyEvent(MODAL_ACTIONS.DELETE);
    }
    setLoading(false);
  };

  return (
    <>
      {isOpen && (
        <ModalBase
          isOpen={isOpen}
          size="md"
          title={`Exclusão de ${title}`}
          number={number}
          confirmButtonText="Excluir"
          onConfirm={handleSubmit}
          loadingConfirm={loading}
          toggle={toggle}
          autoFocus
        >
          <Row className="justify-content-center">
            <p style={{ textAlign: "center" }}>{message}</p>
          </Row>
        </ModalBase>
      )}
    </>
  );
};
