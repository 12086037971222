const initialState = {
  idVenda: null,
  idCliente: null,
  idRota: null,
  dataIni: new Date(),
  dataFim: new Date(),
  apenasAlertas: false,
  vendas: [],
  alertas: [],
  totais: {
    num_vendas: "0",
    troca: "0",
    produtos: "0",
    icms_st: "0",
    geral: "0",
  },
};

export const SET_ID_VENDA = "SET_ID_VENDA";
export const SET_ID_CLIENTE = "SET_ID_CLIENTE";
export const SET_ID_ROTA = "SET_ID_ROTA";
export const SET_DATA_INI = "SET_DATA_INI";
export const SET_DATA_FIM = "SET_DATA_FIM";
export const SET_APENAS_ALERTAS = "SET_APENAS_ALERTAS";
export const SET_DADOS = "SET_DADOS";

// Gerenciamento de Vendas Mobile

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_APENAS_ALERTAS:
      return {
        ...state,
        apenasAlertas: !state.apenasAlertas,
      };
    case SET_DATA_FIM:
      return { ...state, dataFim: action.data };
    case SET_DATA_INI:
      return { ...state, dataIni: action.data };
    case SET_ID_ROTA:
      return { ...state, idRota: action.data };
    case SET_ID_CLIENTE:
      return { ...state, idCliente: action.data };
    case SET_ID_VENDA:
      return { ...state, idVenda: action.data };
    case SET_DADOS:
      return {
        ...state,
        alertas: action.data.alertas,
        vendas: action.data.vendas,
        totais: action.data.totais,
      };
    default:
      return state;
  }
}

export function stateAsPayload(state) {
  const res = {};
  // Remove campos nulos
  const filteredState = Object.keys(state).reduce((newObj, key) => {
    const v = state[key];
    if (v !== null && v !== undefined && v !== "") {
      if (key.startsWith("data") && v instanceof Date) {
        newObj[key] = v.toISOString().split("T")[0];
      } else {
        newObj[key] = v;
      }
    }
    return newObj;
  }, {});

  Object.keys(filteredState).forEach((key) => {
    switch (key) {
      case "idVenda":
        res["id_venda"] = filteredState[key];
        break;
      case "idRota":
        res["id_rota"] = filteredState[key];
        break;
      case "idCliente":
        res["id_cliente"] = filteredState[key];
        break;
      case "dataIni":
        res["data_ini"] = filteredState[key];
        break;
      case "dataFim":
        res["data_fim"] = filteredState[key];
        break;
      case "apenasAlertas":
        res["apenas_com_alerta"] = filteredState[key];
        break;
      default:
        break;
    }
  });

  return res;
}
