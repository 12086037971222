import React, { useState } from "react";
import { Table } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import {
  TabBody,
  TabController,
} from "../../../../../components/TabController";
import ManifEletronMDFeService from "../../../../../services/faturamento/ManifEletronMDFeService";

const alertasCol = [
  {
    dataField: "alerta",
    text: "Alerta",
    align: "left",
    headerAlign: "left",
    colWidth: "65%",
  },
  {
    dataField: "data_hora_alerta",
    text: "Data/Hora",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "id_colaborador",
    text: "Colaborador",
    align: "center",
    headerAlign: "center",
  },
];

const eventosCol = [
  {
    dataField: "nro_protocolo",
    text: "Protocolo",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "data",
    text: "Data/Hora",
    align: "center",
    headerAlign: "center",
    formatter: (c, r) => `${c} ${r.hora}`,
  },
  {
    dataField: "tipo",
    text: "Tipo",
    align: "left",
    headerAlign: "left",
  },
];

export const EventosAlertasMDFe = ({ id, isOpen, toggle }) => {
  const [eventos, setEventos] = useState([]);
  const [alertas, setAlertas] = useState([]);

  const carregarDados = async () => {
    const [ok, ret] = await ManifEletronMDFeService.buscarEventosAlertas(id);
    if (ok) {
      setEventos(ret.eventos);
      setAlertas(ret.alertas);
    } else {
      toggle();
    }
  };

  const limparDados = () => {
    setEventos([]);
    setAlertas([]);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Eventos e Alertas da MDFe"
      number="0042_3"
      autoFocus
      hideCancelButton
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <TabController>
        <TabBody title="Alertas">
          <Table
            columns={alertasCol}
            data={alertas}
            paginated={false}
            pageSize={10}
          />
        </TabBody>
        <TabBody title="Eventos">
          <Table
            columns={eventosCol}
            data={eventos}
            paginated={false}
            pageSize={10}
          />
        </TabBody>
      </TabController>
    </ModalBase>
  );
};
