import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

export const ProdutoIntermediarioGrid = ({
  data,
  setSelected,
  alterar,
  excluir,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "nome_produto",
      text: "Produto",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
    },
    { dataField: "unidade", text: "UN", align: "center" },
    {
      dataField: "qtd_itens",
      text: "Itens",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_custo_total",
      text: "Custo Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
