import React, { useState } from "react";
import { AlertTriangle, Mail, Printer } from "react-feather";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { apiGet } from "../../../../../api";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import {
  IconButton,
  ModalDetalhesIcone,
  Table,
} from "../../../../../components";
import { DetalheVendaProntaEntregaModal } from "./DetalheVendaProntaEntregaModal";

export const GerencVendasDiretasGrid = () => {
  const _state = useSelector((state) => state.gerenciamentoVendasMobile);
  const [idVendaDet, setIdVendaDet] = useState(null);
  const [detalheVendaOpen, setDetalheVendaOpen] = useState(false);

  const reimprimirNFes = async (idVenda) => {
    const ret = await apiGet(
      `jornada/vendas_rota/buscar_nfes_venda/${idVenda}/`
    );

    if (ret) {
      for (const item of ret) {
        await NotaFiscalService.imprimir(item.id_nfe);
      }
    }
  };

  const enviarNFEmail = async (idVenda) => {
    await apiGet(`jornada/vendas_rota/enviar_email_nfe/${idVenda}/`, false);
  };

  const toggleDetalheVenda = () => setDetalheVendaOpen(!detalheVendaOpen);

  const mostrarDetalhes = (id) => {
    setIdVendaDet(id);
    setTimeout(() => {
      toggleDetalheVenda();
    }, 1);
  };

  const columns = [
    {
      dataField: "id_rota_mob__nome",
      text: "Rota",
      align: "left",
      headerAlign: "left",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "id",
      text: "Nº Venda",
      align: "center",
      headerAlign: "center",
      sortable: true,
      formatter: (cell, row) =>
        row["alertas"] > 0 ? (
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              color: "#f44455",
            }}
          >
            {cell}
            <AlertTriangle size={12} className="ml-1" color="#f44455" />
          </span>
        ) : (
          cell
        ),
    },
    {
      dataField: "data",
      text: "Data",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "forma_rec",
      text: "Forma Recebimento",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_tot_flex",
      text: "Flex",
      align: "right",
      headerAlign: "right",
      formatter: (v) => (
        <span
          style={{
            color:
              parseFloat(v) > 0
                ? "#5fc27e"
                : parseFloat(v) < 0
                ? "#f44455"
                : "#495057",
            fontWeight: parseFloat(v) !== 0 ? "bold" : "normal,",
          }}
        >
          {formatNumber(v, true, 2)}
        </span>
      ),
    },
    {
      dataField: "vlr_tot_prod",
      text: "Tot. Produtos",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_tot_troca",
      text: "Troca",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_tot_icms_st",
      text: "ICMS ST",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "detalhes_act",
      isDummyField: true,
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => (
        <ModalDetalhesIcone toggle={() => mostrarDetalhes(row.id)} />
      ),
    },
    {
      dataField: "reimprimir_act",
      isDummyField: true,
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (_, row) =>
        row.gerou_nf ? (
          <IconButton
            icon={Printer}
            color="#355fff"
            size={16}
            onClick={() => reimprimirNFes(row.id)}
            hint="Reimprimir NF-e"
          />
        ) : (
          <></>
        ),
    },
    {
      dataField: "enviar_nfe_act",
      isDummyField: true,
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (_, row) =>
        row.gerou_nf ? (
          <IconButton
            icon={Mail}
            color="#355fff"
            size={16}
            onClick={() => enviarNFEmail(row.id)}
            hint="Enviar NF-e por e-mail"
          />
        ) : (
          <></>
        ),
    },
  ];

  return (
    <Card body>
      <DetalheVendaProntaEntregaModal
        isOpen={detalheVendaOpen}
        toggle={toggleDetalheVenda}
        idVenda={idVendaDet}
      />
      <Table
        columns={columns}
        data={_state.vendas}
        className="mt-2"
        paginated
      />
    </Card>
  );
};
