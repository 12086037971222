import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  IntegerFormInput,
  BotaoImprimir,
  BotaoPesquisar,
  PageContainer,
} from "../../../../components";
import { NotasExclusaoICMSSTGrid } from "./components/NotasExclusaoICMSSTGrid";
import { ProdutosExclusaoICMSSTGrid } from "./components/ProdutosExclusaoICMSSTGrid";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";

const URL_BASE = "/relatorios/tributacao";
const URL_BASE_PDF = "/relatorios/compras/exclusao_icms_st";

export const ExclusaoICMSST = () => {
  const [produtos, setProdutos] = useState([]);
  const [notas, setNotas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImprimirProdutos, setLoadingImprimirProdutos] = useState(false);
  const [loadingImprimirNotas, setLoadingImprimirNotas] = useState(false);
  const [idProduto, setIdProduto] = useState(null);
  const [versao, setVersao] = useState(1);

  const limparDados = () => {
    setProdutos([]);
    setNotas([]);
  };

  const buscarProdutos = async () =>
    await apiGetV2(
      `${URL_BASE}/produtos_enquad_lista_ncm_exc_icms_st/${versao}/`,
      { id_produto: idProduto }
    );

  const buscarNotas = async () =>
    await apiGetV2(
      `${URL_BASE}/notas_produto_enquad_lista_ncm_exc_icms_st/${versao}/`,
      { id_produto: idProduto }
    );

  const carregarDados = async () => {
    setLoading(true);
    const [ok1, ret1] = await buscarProdutos();
    setProdutos(ok1 ? ret1 : []);
    const [ok2, ret2] = await buscarNotas();
    setNotas(ok2 ? ret2 : []);
    setLoading(false);
  };

  const imprimirProdutos = async () => {
    setLoadingImprimirProdutos(true);
    const [ok, ret] = await buscarProdutos();

    if (ok) {
      await docPrintReport(
        `${URL_BASE_PDF}/produtos/`,
        { dados: ret },
        "5029_1"
      );
    }
    setLoadingImprimirProdutos(false);
  };

  const imprimirNotas = async () => {
    setLoadingImprimirNotas(true);
    const [ok, ret] = await buscarNotas();
    if (ok) {
      await docPrintReport(`${URL_BASE_PDF}/notas/`, { dados: ret }, "5029_2");
    }
    setLoadingImprimirNotas(false);
  };

  const handleSetProduto = (selected) => {
    setIdProduto(selected?.value);
    limparDados();
  };

  return (
    <PageContainer title="Exclusão de ICMS ST" number="5029">
      <Card body>
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="produto"
            isSearchable
            md={4}
            label="Produto"
            onChange={handleSetProduto}
          />
          <IntegerFormInput
            label="Versão"
            md={2}
            defaultValue={1}
            onChange={(val, _) => setVersao(val)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
      </Card>
      <Card body>
        <Row className="mb-2">
          <BotaoImprimir
            padded={false}
            onClick={imprimirProdutos}
            loading={loadingImprimirProdutos}
          />
        </Row>
        <ProdutosExclusaoICMSSTGrid data={produtos} />
      </Card>
      <Card body>
        <Row className="mb-2">
          <BotaoImprimir
            padded={false}
            onClick={imprimirNotas}
            loading={loadingImprimirNotas}
          />
        </Row>
        <NotasExclusaoICMSSTGrid data={notas} />
      </Card>
    </PageContainer>
  );
};
