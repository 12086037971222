import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { SearchInput } from "../../../components";
import { BotaoPesquisar } from "../../../components/BotaoPesquisar";
import { PageContainer } from "../../../components/PageContainer";
import {
  debounce,
  defaultDebounceTime,
  MODAL_ACTIONS,
} from "../../../coreUtils";
import CadastroCampoPersonService from "../../../services/cadastro/CadastroCampoPersonService";
import { CadastroCamposPersonModal } from "./components/CadastroCamposPersonModal";
import { CamposPersonTable } from "./components/CamposPersonTable";

export const CamposPersonalizados = ({ location }) => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [pista, setPista] = useState("");
  const tabela = location.state.tabela;

  const buscarDados = async (p = pista) => {
    setLoading(true);
    setData(await CadastroCampoPersonService.listar(p));
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    setData(await CadastroCampoPersonService.listar(v));
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    buscarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  return (
    <PageContainer title="Campos Personalizados" number="0040_4" canGoBack>
      <Card body>
        <Row>
          <SearchInput
            md={6}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome do Campo"
          />
          <BotaoPesquisar onClick={() => buscarDados()} loading={loading} />
          <CadastroCamposPersonModal
            selected={selected}
            notifyEvent={notifyEvent}
            tabela={tabela}
          />
        </Row>
        <CamposPersonTable data={data} handleOnSelect={setSelected} />
      </Card>
    </PageContainer>
  );
};
