export const formasPagNfe = [
  { label: "Dinheiro", value: "DI" },
  { label: "Cheque", value: "CQ" },
  { label: "Cartão de Crédito", value: "CC" },
  { label: "Cartão de Débito", value: "CD" },
  { label: "Crédito Loja", value: "CI" },
  { label: "Vale Alimentação", value: "VA" },
  { label: "Vale Refeição", value: "VR" },
  { label: "Vale Presente", value: "VP" },
  { label: "Vale Combustível", value: "VC" },
  { label: "Boleto Bancário", value: "BB" },
  { label: "Depósito Bancário", value: "DB" },
  { label: "PIX", value: "PX" },
  { label: "Transferência Bancária", value: "TB" },
  { label: "Cashback", value: "CA" },
  { label: "Sem Pagamento", value: "SE" },
];
