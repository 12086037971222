import React, { useState } from "react";
import {
  AsyncComboBox,
  FormButton,
  ModalBase,
  NumberInput,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { ClassifCli } from "../../../../../components/ClassifCli";
import CadastroClienteService from "../../../../../services/cadastro/CadastroClienteService";
import { RadioItem } from "../../../../../components/RadioGroup";
import CredRotativoService from "../../../../../services/financeiro/CredRotativoService";

export const AbrirContaCredRotativoModal = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idCliente, setIdCliente] = useState(null);
  const [dadosCliente, setDadosCliente] = useState({});
  const [limiteCred, setLimiteCred] = useState(0);
  const [tipoConta, setTipoConta] = useState("FIXA");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIsOpen(false);
    setIdCliente(null);
    setDadosCliente({});
    setLimiteCred(0);
    setTipoConta("FIXA");
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSetIdCliente = async (v) => {
    setIdCliente(v);

    const [ok, ret] = await CadastroClienteService.buscar(v);
    setDadosCliente(ok ? ret : {});
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      id_cliente: idCliente,
      limite_cred: limiteCred,
      tipo_conta: tipoConta,
    };

    if (await CredRotativoService.abrirConta(payload)) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton md="auto" color="info" onClick={toggle}>
        Abertura de Conta
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Abertura de Conta no Crédito Rotativo"
        number="0068_1"
        onConfirm={handleSubmit}
        onClosed={limparDados}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            md={10}
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            isSearchable
            onChange={(s) => handleSetIdCliente(s?.value)}
          />
        </Row>
        <Row>
          <TextInput label="Endereco" value={dadosCliente.endereco} disabled />
          <TextInput md={2} label="Nº" value={dadosCliente.numero} disabled />
        </Row>
        <Row>
          <TextInput
            md={6}
            label="Bairro"
            value={dadosCliente.bairro}
            disabled
          />
          <AsyncComboBox
            md={6}
            label="Cidade"
            isConcatField
            concatModelName="cidade"
            defaultValue={dadosCliente.cidade}
            disabled
          />
        </Row>
        <Row>
          <TextInput label="Fone1" value={dadosCliente.fone1} disabled />
          <TextInput label="Fone2" value={dadosCliente.fone2} disabled />
        </Row>
        <Row>
          <TextInput label="CPF/CNPJ" value={dadosCliente.cpf_cnpj} disabled />
          <ClassifCli value={dadosCliente.classif_cli} />
        </Row>
        <Row>
          <TextInput label="E-Mail" value={dadosCliente.email} disabled />
        </Row>
        <Row>
          <NumberInput
            md={4}
            label="Limite de Crédito"
            value={limiteCred}
            onChange={setLimiteCred}
          />
          <RadioGroup
            label="Tipo de Conta"
            value={tipoConta}
            onChange={setTipoConta}
          >
            <RadioItem label="Fixa" value="FIXA" />
            <RadioItem label="Temporária" value="TEMP" />
          </RadioGroup>
        </Row>
      </ModalBase>
    </>
  );
};
