import axios from "axios";

import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { Eye, EyeOff } from "react-feather";
import PasswordStrengthBar from "react-password-strength-bar";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import { kClientId, kClientSecret } from "../../api";
import { ItemRegraSenha } from "../../components/ItemRegraSenha";
import settings from "../../settings";

const ResetPassword = () => {
  const location = useLocation();
  const temAcessoNovosApps = location.state.tem_acesso_novos_apps;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordRedefinido, setPasswordRedefinido] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);

  const redefinirSenha = async (e) => {
    e.preventDefault();

    if (["", null, undefined].includes(repeatPassword)) {
      toastr.warning("Dados incorretos", "Por favor, repita a sua senha");
      return;
    }

    if (temAcessoNovosApps) {
      if (
        password.length < 6 ||
        !validarRegraLetras() ||
        !validarRegraNumeros() ||
        passwordScore < 2 ||
        password !== repeatPassword
      ) {
        toastr.warning(
          "Dados incorretos",
          "Revise os campos e tente novamente"
        );
        return;
      }
    }

    const data = {
      nickname: location.state.nickname,
      password: password,
    };

    await axios
      .put(`${settings.AUTENTICADOR}/redefinir_senha/`, data, {
        timeout: 5000,
        auth: {
          username: kClientId,
          password: kClientSecret,
        },
        validateStatus: false,
      })
      .then((response) => {
        if (response.status === 200) {
          toastr.success("Sucesso", "Senha alterada com sucesso");
          setPasswordRedefinido(true);
        } else {
          toastr.error("Erro", response.data);
        }
      })
      .catch((err) => {
        toastr.error(
          "Erro",
          "Falha ao tentar se conectar com o serviço de autenticação."
        );
      });
  };

  const validarRegraLetras = () =>
    (password.match(/[a-z]/gi) ?? []).length >= 4;

  const validarRegraNumeros = () =>
    (password.match(/[0-9]/gi) ?? []).length >= 2;

  if (passwordRedefinido) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className="text-center mt-4">
        <h1 className="h2">Redefinir sua Senha</h1>
        <p className="lead">Informe sua nova senha para liberar seu acesso.</p>
      </div>
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <h5 style={{ textAlign: "center" }}>
              Essa será a sua nova senha para acesso a TODOS os sistemas
              Skillsoft
              <br />
              (Web, Aplicativos e Sistema Legado).
            </h5>
            <Form className="mt-2">
              <FormGroup>
                <Label className="label-auth-login">Senha</Label>
                <InputGroup>
                  <Input
                    bsSize="lg"
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    placeholder="Sua nova senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="input-auth-login"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? (
                        <Eye size={15} />
                      ) : (
                        <EyeOff size={15} />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {temAcessoNovosApps && (
                  <>
                    <PasswordStrengthBar
                      style={{ marginTop: "10px" }}
                      password={password}
                      scoreWordStyle={{ display: "none" }}
                      onChangeScore={(score) => setPasswordScore(score)}
                    />
                    <ItemRegraSenha
                      label="A senha deve conter pelo menos 4 letras"
                      regraOk={validarRegraLetras()}
                    />
                    <ItemRegraSenha
                      label="A senha deve conter pelo menos 2 números"
                      regraOk={validarRegraNumeros()}
                    />
                    <ItemRegraSenha
                      label="A senha deve ser suficientemente forte (2 barras)"
                      regraOk={passwordScore >= 2}
                    />
                    <ItemRegraSenha
                      label="(Opcional) 1 caracter especial"
                      info
                    />
                  </>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-auth-login">Repita sua Senha</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="repeat-password"
                  placeholder="Repita sua senha"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  required
                  className="input-auth-login"
                />
                {repeatPassword && (
                  <ItemRegraSenha
                    label="As senhas devem ser iguais"
                    regraOk={repeatPassword === password}
                  />
                )}
              </FormGroup>
              <div className="text-center mt-3">
                <Button
                  type="submit"
                  color="primary"
                  size="lg"
                  onClick={redefinirSenha}
                >
                  Redefinir Senha
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ResetPassword;
