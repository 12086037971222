import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  idFornecedor: null,
  dataEmi: null,
  idTipoDoc: null,
  numero: "",
  serie: "",
  vlrFrete: 0,
  vlrSeguro: 0,
  vlrOutros: 0,
  idCondPag: 0,
  faturas: [],
  itens: [],
};

export const entradaManualNfSlice = createSlice({
  name: "entrada_manual_nf_slice",
  initialState: initialState,
  reducers: {
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setIdFornecedor: (state, action) => {
      state.idFornecedor = action.payload;
    },
    setDataEmi: (state, action) => {
      state.dataEmi = action.payload;
    },
    setIdTipoDoc: (state, action) => {
      state.idTipoDoc = action.payload;
    },
    setNumero: (state, action) => {
      state.numero = action.payload;
    },
    setSerie: (state, action) => {
      state.serie = action.payload;
    },
    setVlrFrete: (state, action) => {
      state.vlrFrete = action.payload;
    },
    setVlrSeguro: (state, action) => {
      state.vlrSeguro = action.payload;
    },
    setVlrOutros: (state, action) => {
      state.vlrOutros = action.payload;
    },
    setIdCondPag: (state, action) => {
      state.idCondPag = action.payload;
    },
    setFaturas: (state, action) => {
      state.faturas = action.payload;
    },
    setItens: (state, action) => {
      state.itens = action.payload;
    },
  },
});

export const {
  setup,
  setIdFornecedor,
  setDataEmi,
  setIdTipoDoc,
  setNumero,
  setSerie,
  setVlrFrete,
  setVlrSeguro,
  setVlrOutros,
  setIdCondPag,
  setFaturas,
  setItens,
} = entradaManualNfSlice.actions;

export default entradaManualNfSlice.reducer;
