import React from "react";
import { formatNumber, formatValueFromAPI } from "../../../../../../coreUtils";
import { Table } from "../../../../../../components";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
  },
  {
    dataField: "id_lote_compens_cartao",
    text: "Lote",
    align: "center",
  },
  {
    dataField: "numero_cartao",
    text: "Número",
    align: "center",
  },
  {
    dataField: "nome_meio_pagto",
    text: "Meio de Pagamento",
    align: "left",
    formatter: (c, row) =>
      row.id_meio_pagto ? formatValueFromAPI(c, row.id_meio_pagto) : "",
  },
  {
    dataField: "nome_adm",
    text: "Administradora",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_adm),
  },
  {
    dataField: "data_emissao",
    text: "Emissão",
    align: "center",
  },
  {
    dataField: "data_receb",
    text: "Recebimento",
    align: "center",
  },
  {
    dataField: "vlr_recebido",
    text: "Valor Recebido",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];
export const CompensacoesGrid = ({ data, setSelected }) => {
  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      clearSelectionOnUpdate
    />
  );
};
