import React from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoImprimir,
  BotaoPesquisar,
  PageContainer,
} from "../../../../components";
import { useState } from "react";
import FlexRepresentExtService from "../../../../services/pedidos/FlexRepresentExtService";
import { FlexRepresentExtGrid } from "./components/FlexRepresentExtGrid";
import { LancarFlexRepresentExtModal } from "./components/LancarFlexRepresentExtModal";
import { useEffect } from "react";
import { docPrintReport } from "../../../../pdf";

export const FlexRepresentExt = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const carregarDados = async () => {
    setLoading(true);

    const ret = await FlexRepresentExtService.listar();
    setDados(ret ?? []);
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const ret = await FlexRepresentExtService.listar();
    if (ret) {
      await docPrintReport(
        "/relatorios/pedido_externo/flex_represent_ext/",
        { vendedores: ret },
        "0072_1"
      );
    }
    setLoadingImprimir(false);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  return (
    <PageContainer title="Flex de Representantes Externos" number="0072">
      <Card body>
        <Row>
          <BotaoPesquisar
            padded={false}
            onClick={carregarDados}
            loading={loading}
          />
          <LancarFlexRepresentExtModal notifyEvent={carregarDados} />
          <BotaoImprimir
            padded={false}
            onClick={imprimir}
            loading={loadingImprimir}
          />
        </Row>
      </Card>
      <Card body>
        <FlexRepresentExtGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
