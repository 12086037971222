import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { HiClipboardDocumentCheck } from "react-icons/hi2";
import { FiTool } from "react-icons/fi";
import {
  centralOSRoute,
  // centralProjetosRoute,
  orcRapidoOSRoute,
  vistoriaTecnicaRoute,
} from "../../../routes/modules/ordem_servico";
import { LiaClipboardListSolid } from "react-icons/lia";
// import { VscProject } from "react-icons/vsc";

export const Atendimento = () => {
  return (
    <PageContainer title="Atendimento - Ordens de Serviço">
      <MenuGroup>
        <MenuGroupButton
          label="Central de O.S."
          icon={FiTool}
          pathname={centralOSRoute.path}
          nroTela={centralOSRoute.nroTela}
        />
        <MenuGroupButton
          label="Vistoria Técnica"
          icon={HiClipboardDocumentCheck}
          pathname={vistoriaTecnicaRoute.path}
          nroTela={vistoriaTecnicaRoute.nroTela}
        />
        <MenuGroupButton
          label="Orçamento Rápido"
          icon={LiaClipboardListSolid}
          pathname={orcRapidoOSRoute.path}
          nroTela={orcRapidoOSRoute.nroTela}
        />
        {/* <MenuGroupButton
          label="Central de Projetos"
          icon={VscProject}
          pathname={centralProjetosRoute.path}
          nroTela={centralProjetosRoute.nroTela}
        /> */}
      </MenuGroup>
    </PageContainer>
  );
};
