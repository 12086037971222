import React, { useState, useRef } from "react";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  ModalBase,
  NumberInput,
} from "../../../../../../components";
import { Row } from "reactstrap";
import { apiPost } from "../../../../../../api";
import { BsCashCoin } from "react-icons/bs";

const formasPag = [
  { label: "Dinheiro", value: "DINH" },
  { label: "Cartão de Crédito", value: "CCRE" },
  { label: "Cartão de Débito", value: "CDEB" },
  { label: "Boleto", value: "BOLE" },
  { label: "Transferência Bancária", value: "TBAN" },
  { label: "Cheque", value: "CHEQ" },
  { label: "Crédito Rotativo", value: "CROT" },
];

export const LancarAdiantamentoOSModal = ({
  idOS,
  showButtonText = true,
  padded = true,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const refIdCondPagAdiant = useRef();

  const [formaPagAdiant, setFormaPagAdiant] = useState(null);
  const [idCondPagAdiant, setIdCondPagAdiant] = useState(null);
  const [valorAdiant, setValorAdiant] = useState(0);

  const toggle = async () => {
    setIsOpen(!isOpen);
    setFormaPagAdiant(null);
    setIdCondPagAdiant(null);
    setValorAdiant(0);
  };

  const handleSubmit = async () => {
    const payload = {
      id_os: idOS,
      forma_pag: formaPagAdiant,
      id_cond_pag: idCondPagAdiant,
      valor: valorAdiant,
    };

    const ret = await apiPost("/ordem_servico/lancar_adiantamento/", payload);
    if (ret) {
      notifyEvent();
      toggle();
    }
  };

  return (
    <>
      <FormButton
        md="auto"
        color="info"
        onClick={toggle}
        tabIndex={-1}
        divClassName={!showButtonText && "pr-0"}
        padded={padded}
        hint="Lançar Adiantamento"
      >
        <BsCashCoin size={20} className={showButtonText && "mr-2"} />
        {showButtonText && "Lançar Adiantamento"}
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title={`Adiantamento de Valores da O.S. ${idOS}`}
        onConfirm={handleSubmit}
        number="0006_13"
      >
        <Row>
          <ComboBox
            md={4}
            label="Forma de Pagamento"
            options={formasPag}
            onChange={(s) => {
              setFormaPagAdiant(s?.value);
              if (s?.value === "DINH") {
                refIdCondPagAdiant.current.clearValue();
                setIdCondPagAdiant(null);
              }
            }}
            defaultValue={formaPagAdiant}
          />
          <AsyncComboBox
            md={4}
            label="Condição Pagamento"
            isConcatField
            concatModelName="cond_pag"
            isSearchable
            onChange={(s) => setIdCondPagAdiant(s?.value)}
            defaultValue={idCondPagAdiant}
            defaultOptions
            disabled={!formaPagAdiant || formaPagAdiant === "DINH"}
            ref={refIdCondPagAdiant}
          />
          <NumberInput
            md={4}
            label="Valor"
            value={valorAdiant}
            onChange={setValorAdiant}
          />
        </Row>
      </ModalBase>
    </>
  );
};
