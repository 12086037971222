import React from "react";
import { formatNumber, formatValueFromAPI } from "../../../../coreUtils";
import { Table } from "../../../../components";

export const CurvaABCGrid = ({ data, modo, formato }) => {
  const columns = [
    {
      dataField: "rank",
      text: "Rank",
      align: "center",
      headerAlign: "center",
      colWidth: "5%",
      fixedColWidth: true,
    },
    ...(formato === "fornecedor_produto"
      ? [
          {
            dataField: `nome_fornecedor`,
            text: "Fornecedor",
            align: "left",
            headerAlign: "left",
            formatter: (c, row) => formatValueFromAPI(c, row.id_fornecedor),
            colWidth: "19.5%",
            fixedColWidth: true,
          },
          {
            dataField: `nome_produto`,
            text: "Produto",
            align: "left",
            headerAlign: "left",
            formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
            colWidth: "19.5%",
            fixedColWidth: true,
          },
        ]
      : [
          {
            dataField: `nome_${formato}`,
            text:
              typeof formato === "string"
                ? formato.charAt(0).toUpperCase() + formato.slice(1)
                : "Identificador",
            align: "left",
            headerAlign: "left",
            formatter: (c, row) => formatValueFromAPI(c, row[`id_${formato}`]),
            colWidth: "39%",
            fixedColWidth: true,
          },
        ]),
    {
      dataField: "qtd",
      text: "Quantidade",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      colWidth: "7%",
      fixedColWidth: true,
    },
    ...(modo === "V" && formato === "produto"
      ? [
          {
            dataField: "estoque",
            text: "Estoque",
            align: "center",
            headerAlign: "center",
            formatter: (c) => formatNumber(c, true, 2, true),
            colWidth: "7%",
            fixedColWidth: true,
          },
        ]
      : []),
    {
      dataField: "preco_medio",
      text: "Preço Médio",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "7%",
      fixedColWidth: true,
    },
    {
      dataField: "valor",
      text: "Valor Total",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "7%",
      fixedColWidth: true,
    },
    {
      dataField: "valor_dia",
      text: "Valor/Dia",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "7%",
      fixedColWidth: true,
    },
    ...(modo === "V"
      ? [
          {
            dataField: "rentabilidade",
            text: "Rentabilidade",
            align: "right",
            headerAlign: "right",
            formatter: (c) => `${formatNumber(c, true, 2)} %`,
            colWidth: "7%",
            fixedColWidth: true,
          },
        ]
      : []),
    {
      dataField: "perc_participa",
      text: "Participação",
      align: "right",
      headerAlign: "right",
      formatter: (c) => `${formatNumber(c, true, 2, true)} %`,
      colWidth: "7%",
      fixedColWidth: true,
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      pageSize={data.length + 1}
      paginated={false}
    />
  );
};
