import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoConfirmarVoltar,
  BotaoPesquisar,
  PageContainer,
} from "../../../../components";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { ProdutoInfoCliNfGrid } from "./components/ProdutoInfoCliNfGrid";
import { CadastroProdutoInfoCliNf } from "./components/CadastroProdutoInfoCliNf";

export const modalTitleProdutoInfoCliNf = "Produto de Faturamento por Cliente";
export const routesBaseProdutoInfoCliNf = "/cadastro/produto_info_cli_nf";

export const ProdutoInfoCliNf = () => {
  const [cliente, setCliente] = useState("");
  const [produtoVenda, setProdutoVenda] = useState("");
  const [produtoNf, setProdutoNf] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (v) => {
    const [ok, ret] = await apiGetV2(`${routesBaseProdutoInfoCliNf}/listar/`, {
      id_cliente: cliente,
      id_produto_venda: produtoVenda,
      id_produto_nf: produtoNf,
    });

    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(cliente);
    setLoading(false);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer
      title="Produto de Faturamento por Cliente"
      number="0118"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            isSearchable
            onChange={(s) => setCliente(s?.value, s)}
            autoFocus
          />

          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="produto"
            label="Produto Venda"
            isSearchable
            onChange={(s) => setProdutoVenda(s?.value, s)}
            autoFocus
          />
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="produto"
            label="Produto NF"
            isSearchable
            onChange={(s) => setProdutoNf(s?.value, s)}
            autoFocus
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroProdutoInfoCliNf
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleProdutoInfoCliNf}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0118_2"
            selected={selected}
            routeBase={routesBaseProdutoInfoCliNf}
            notifyEvent={notifyEvent}
          />
          <BotaoConfirmarVoltar
            selected={selected}
            carregarDados={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <ProdutoInfoCliNfGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
