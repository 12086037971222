import React from "react";
import { Card, DropdownItem } from "reactstrap";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../../coreUtils";
import { MoreDropdown, Table } from "../../../../../components";

export const columnsListagemCartoes = (exportacao) => {
  const numberFormatter = exportacao
    ? parseFloat
    : (c) => formatNumber(c, true, 2);

  return [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "administradora",
      text: "Bandeira",
      align: "left",
      formatter: (c) => (exportacao ? c : (c ?? "").substring(0, 9)),
      sortable: true,
    },
    {
      dataField: "cliente",
      text: "Cliente",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente, 30),
      sortable: true,
    },
    {
      dataField: "origem",
      text: "Origem",
      align: "center",
    },
    {
      dataField: "id_cab__id_origem",
      text: "ID Origem",
      align: "center",
      sortable: true,
    },
    {
      dataField: "numero_nf",
      text: "Nº NF",
      align: "center",
    },
    {
      dataField: "id_cab__tipo",
      text: exportacao ? "Tipo" : "T",
      align: "center",
      sortable: true,
      formatter: (c) =>
        exportacao ? (c === "C" ? "Crédito" : c === "D" ? "Débito" : c) : c,
    },
    {
      dataField: "parcela",
      text: "Parc",
      align: "center",
      formatter: (c, row) =>
        (row.id_cab__numero ?? "").trim() !== ""
          ? `${row.id_cab__numero} | ${c}`
          : c,
    },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "previa_recebimento",
      text: "Prev. Rec.",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "recebimento",
      text: exportacao ? "Recebimento" : "Receb.",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_original",
      text: "Original",
      align: "right",
      formatter: (c) => numberFormatter(c),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_deducoes",
      text: "Deduções",
      align: "right",
      formatter: (c) => numberFormatter(c),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_receber",
      text: "Receber",
      align: "right",
      formatter: (c) => numberFormatter(c),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_recebido",
      text: "Recebido",
      align: "right",
      formatter: (c) => numberFormatter(c),
      sortable: true,
      sortFunc: naturalSort,
    },
  ];
};

export const GerenciamentoCartaoGrid = ({
  data,
  handleSelect,
  handleSelectAll,
  selected,
  alterarLanc,
  excluirLanc,
  taxasLanc,
}) => {
  const columns = [
    ...columnsListagemCartoes(false),
    {
      dataField: "act",
      isDummyField: true,
      align: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown horizontal onToggle={() => {}}>
          <DropdownItem onClick={() => taxasLanc(row.id)}>Taxas</DropdownItem>
          {!row.compensado && (
            <>
              <DropdownItem onClick={() => alterarLanc(row.id)}>
                Alterar
              </DropdownItem>
              <DropdownItem onClick={() => excluirLanc(row.id)}>
                Excluir
              </DropdownItem>
            </>
          )}
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Card body>
      <Table
        columns={columns}
        data={data}
        paginated
        multiselect
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        selected={selected}
      />
    </Card>
  );
};
