import React, { useState } from "react";
import { FixedField, ModalBase, Table } from "../../../../../components";
import FracionamentoNfEntradaService from "../../../../../services/compras/FracionamentoNfEntradaService";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { Row } from "reactstrap";

const columns = [
  { dataField: "id_produto", text: "Produto", align: "center" },
  { dataField: "nome_produto", text: "Nome", align: "left" },
  {
    dataField: "vlr_custo",
    text: "Custo Unit.",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "quantidade",
    text: "Qtd",
    align: "center",
    formatter: (c) => formatNumber(c, true, 6, true),
  },
];

export const DetalhesItemFracionadoModal = ({
  isOpen,
  toggle,
  idItemFracionar,
}) => {
  const [dados, setDados] = useState([]);

  const limparDados = () => {
    setDados([]);
  };

  const carregarDados = async () => {
    const [ok, ret] =
      await FracionamentoNfEntradaService.buscarItensFracionados(
        idItemFracionar
      );
    if (!ok) {
      toggle();
      return;
    }
    setDados(ok ? ret : {});
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Detalhes do Item Fracionado"
      number="0110_2"
      onClosed={limparDados}
      onBeforeOpen={carregarDados}
    >
      <Row className="mb-2">
        <FixedField
          label="Produto da NFe"
          value={formatValueFromAPI(dados?.nome_produto, dados?.id_produto)}
          horizontal
        />
        <FixedField
          label="Qtd Total da NFe"
          value={formatNumber(dados?.quantidade, true, 2, true)}
          horizontal
        />
      </Row>
      <Table
        data={dados.itens_frac ?? []}
        columns={columns}
        paginated={false}
        pageSize={10}
        growIntoPageSize
      />
    </ModalBase>
  );
};
