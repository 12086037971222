import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Provider } from "react-redux";
import { Card, Row } from "reactstrap";
import XLSX from "xlsx";
import {
  PageContainer,
  AsyncComboBox,
  ComboBox,
  FormButton,
  SearchInput,
  BotaoPesquisar,
  LinkButton,
} from "../../../../components";
import {
  debounce,
  defaultDebounceTime,
  MODAL_ACTIONS,
} from "../../../../coreUtils";
import { RegraTributacaoGrid } from "./components/RegraTributacaoGrid";
import store from "./store";
import RegrasTributacaoService from "../../../../services/cadastro/RegrasTributacaoService";
import { produtosRegraTribRoute } from "../../../../routes/modules/cadastro";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { CadastroRegraTributacaoModal } from "./components/modal/CadastroRegraTributacaoModal";
import { FINALIDADES_REGRA_TRIB } from "./components/modal/CamposChaves";
import {
  setFinalidade,
  setIdProduto,
  setPista,
} from "./store/listagemRegraTribSlice";

export const modalTitleRegraTrib = "Regra de Tributação";
export const routesBaseRegraTrib = RegrasTributacaoService.urlBase;

const RegraTributacaoContainer = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.listagemRegraTrib);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [descSelected, setDescSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const limparDados = () => {
    setDados([]);
  };

  const buscarDados = async (p) => {
    const params = {
      pista: p,
      finalidade: store.finalidade,
      id_produto: store.idProduto,
    };
    const [ok, ret] = await RegrasTributacaoService.listar(params);
    setDados(ok ? ret : []);
  };

  const handlePista = debounce(async (v) => {
    dispatch(setPista(v));
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const handleSelectFinalidade = (v) => {
    dispatch(setFinalidade(v ?? null));
    limparDados();
  };

  const handleSetProduto = (selected) => {
    dispatch(setIdProduto(selected?.value));
    limparDados();
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(store.pista);
    setLoading(false);
  };

  const handleSelect = (id, is, row) => {
    setSelected(id);
    setDescSelected(is ? row.descricao : "");
  };

  const notifyEvent = (action) => {
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
    carregarDados();
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const exportarDados = async () => {
    const [ok, ret] = await RegrasTributacaoService.exportarDados();

    if (!ok) return;

    const data = [];

    ret.forEach((row) => {
      data.push([
        "ID",
        "Descrição",
        "Modelo NF",
        "UF Emitente",
        "UF Destinatário",
        "CFOP",
        "Ativo",
        "Finalidade da Operação",
        "Tipo de Contribuinte",
        "Dentro do Estabelecimento",
        "CST/CSOSN ICMS",
        "Modalidade B.C. ICMS",
        "Alíq ICMS",
        "% Redutor ICMS",
        "Gera Crédito de ICMS - Simples",
        "% Credíto de ICMS - Simples",
        "% Desoneração ICMS",
        "Motivo Desoneração ICMS",
        "% Diferimento ICMS",
        "Modalidade B.C. ICMS ST",
        "MVA ICMS ST",
        "Alíq ICMS ST",
        "% Redutor ICMS ST",
        "CST IPI",
        "Alíq IPI",
        "Enquadramento IPI",
        "Soma valor de IPI na B.C. do ST",
        "CST PIS",
        "Alíq PIS",
        "% Retenção PIS",
        "CST COFINS",
        "Alíq COFINS",
        "% Retenção COFINS",
        "% Retenção CSLL",
        "% Retenção IRPJ",
        "Código de Benefício Fiscal",
        "Mensagem Adicional",
      ]);

      Object.keys(row).forEach((key) => {
        if (
          [
            "perc_icms",
            "perc_red_base_icms_em",
            "perc_desoner_icms",
            "perc_icms_difer",
            "perc_icms_st",
            "perc_redu_icms_st",
            "perc_ipi",
            "perc_pis",
            "perc_reten_pis",
            "perc_cofins",
            "perc_reten_cofins",
            "perc_reten_csll",
            "perc_reten_irpj",
            "aliq_cred_icms",
          ].includes(key)
        ) {
          row[key] = parseFloat(row[key]);
        }
      });

      data.push([
        row["id"],
        row["descricao"],
        row["modelo_nfe"],
        row["uf_emite"],
        row["uf_destino"],
        row["cfop"],
        row["ativo"],
        row["fin_operacao"],
        row["tipo_contr"],
        row["dentro_estab"],
        row["sit_trib_icms"],
        row["modalidade_bc_icms"],
        row["perc_icms"],
        row["perc_red_base_icms_em"],
        row["gera_cred_icms"],
        row["aliq_cred_icms"],
        row["perc_desoner_icms"],
        row["motivo_desoner_icms"],
        row["perc_icms_difer"],
        row["modalidade_bc_icms_st"],
        row["marg_valor_adic_icms_st"],
        row["perc_icms_st"],
        row["perc_redu_icms_st"],
        row["sit_trib_ipi"],
        row["perc_ipi"],
        row["enquad_ipi"],
        row["soma_vlr_ipi_bc_icms_st"],
        row["sit_trib_pis"],
        row["perc_pis"],
        row["perc_reten_pis"],
        row["sit_trib_cofins"],
        row["perc_cofins"],
        row["perc_reten_cofins"],
        row["perc_reten_csll"],
        row["perc_reten_irpj"],
        row["c_benef"],
        row["mensag_adic_nfe"],
      ]);

      data.push([
        "Código",
        "Nome do Produto",
        "NCM",
        "CEST",
        "Estoque",
        "Custo Unitário",
        "Origem",
        "Monofásico",
      ]);

      row["produtos"].forEach((row) => {
        data.push([
          row["id_produto"],
          row["nome_produto"],
          row["ncm_produto"],
          row["cest"],
          parseFloat(row["estoque"]),
          parseFloat(row["preco_custo"]),
          row["origem_merc"],
          row["monofasico"],
        ]);
      });

      data.push([]);
    });

    let ws = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, data);
    const wb = { Sheets: { Tributação: ws }, SheetNames: ["Tributação"] };

    XLSX.writeFile(wb, `Regras_Tributação.xlsx`);
    setLoading(false);
  };

  return (
    <>
      <Card body>
        <Row>
          <SearchInput
            md={6}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome da Regra"
            value={store.pista}
          />
          <ComboBox
            md={3}
            label="Finalidade"
            options={FINALIDADES_REGRA_TRIB}
            defaultOptions
            isClearable
            onChange={(selected) => handleSelectFinalidade(selected?.value)}
            defaultValue={store.finalidade}
          />
        </Row>
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="produto"
            isSearchable
            md={4}
            label="Produto"
            onChange={handleSetProduto}
            defaultValue={store.idProduto}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroRegraTributacaoModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleRegraTrib}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0031_2"
            selected={selected}
            routeBase={routesBaseRegraTrib}
            notifyEvent={notifyEvent}
          />
          <LinkButton
            color="primary"
            md="auto"
            disabled={[0, null, undefined].includes(selected)}
            pathname={produtosRegraTribRoute.path}
            state={{
              id_regra: selected,
              nome_regra: descSelected,
            }}
          >
            Produtos
          </LinkButton>
          <FormButton
            color="primary"
            md="auto"
            divClassName="ml-auto"
            onClick={exportarDados}
          >
            Exportar Dados
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <RegraTributacaoGrid
          dados={dados}
          onSelect={handleSelect}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </>
  );
};

export const RegraTributacao = () => {
  return (
    <PageContainer
      title="Cadastro de Regras de Tributação"
      number="0031"
      canGoBack
    >
      <Provider store={store}>
        <RegraTributacaoContainer />
      </Provider>
    </PageContainer>
  );
};
