import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormButton,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import SolicitacaoRecargaService from "../../../../services/pronta_entrega/SolicitacaoRecargaService";
import { SolicitacaoRecargaGrid } from "./components/SolicitacaoRecargaGrid";
import { CancelarSolicRecargaModal } from "./components/CancelarSolicRecargaModal";
import { DetalhesSolicRecargaModal } from "./components/DetalhesSolicRecargaModal";
import { ExpedirSolicRecargaModal } from "./components/ExpedirSolicRecargaModal";
import { apiGetV2 } from "../../../../apiV2";
import { ConferirRecargaModal } from "./components/ConferirRecargaModal";
import { showWarning } from "../../../../components/AlertaModal";

export const SolicitacaoRecarga = () => {
  // Parâmetros
  const [utilizaConferenciaRecarga, setUtilizaConferenciaRecarga] =
    useState(false);

  // Dados da Tela
  const [idRota, setIdRota] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [mostrarExpedidas, setMostrarExpedidas] = useState(false);
  const [dados, setDados] = useState([]);

  // Controle
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedExpedido, setSelectedExpedido] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [cancelarOpen, setCancelarOpen] = useState(false);
  const [conferirRecargaOpen, setConferirRecargaOpen] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarParametros = () => {
    const buscarParams = async () => {
      const [ok, ret] = await apiGetV2("/tela/solicitacao_recarga/");
      if (ok) {
        setUtilizaConferenciaRecarga(ret.utiliza_conferencia_recarga ?? false);
      }
    };
    buscarParams();
  };

  useEffect(carregarParametros, []);

  const carregarDados = async () => {
    setLoading(true);
    const params = {
      id_rota: idRota,
      data_ini: dataIni,
      data_fim: dataFim,
      mostrar_expedidas: mostrarExpedidas,
    };
    const [ok, ret] = await SolicitacaoRecargaService.listar(params);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const handleSelect = (id, is, row) => {
    setSelected(is ? id : null);
    setSelectedExpedido(is ? row.expedido_jorn : false);
  };

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleCancelar = () => setCancelarOpen(!cancelarOpen);

  const toggleConferirRecarga = () =>
    setConferirRecargaOpen(!conferirRecargaOpen);

  const mostrarDetalhes = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  const cancelar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCancelar();
    }, 1);
  };

  const verificaPodeConferir = async () => {
    const [ok, ret] = await apiGetV2(
      `/jornada/carga_prod/verifica_pode_conferir/${selected}/`
    );
    if (ok) {
      if (ret.pode_conferir === true) {
        setConferirRecargaOpen(true);
      } else {
        showWarning("Essa recarga não está no status Aguardando Conferência");
      }
    }
  };

  return (
    <PageContainer title="Solicitação de Recarga" number="0104">
      <Card body>
        <Row>
          <AsyncComboBox
            concatModelName="rota_mob"
            md={2}
            label="Rota"
            onChange={(s) => setIdRota(s?.value)}
            defaultValue={idRota}
            defaultOptions
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <FormCheckbox
            label="Mostrar Expedidas"
            checked={mostrarExpedidas}
            onChange={() => setMostrarExpedidas(!mostrarExpedidas)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <ExpedirSolicRecargaModal
            selected={selected}
            notifyEvent={carregarDados}
            selectedExpedido={selectedExpedido}
          />
          {utilizaConferenciaRecarga && (
            <FormButton
              color="primary"
              disabled={!selected}
              onClick={verificaPodeConferir}
            >
              Conferir Recarga
            </FormButton>
          )}
          <DetalhesSolicRecargaModal
            isOpen={detalhesOpen}
            toggle={toggleDetalhes}
            selected={selected}
          />
          <CancelarSolicRecargaModal
            isOpen={cancelarOpen}
            toggle={toggleCancelar}
            selected={selected}
            notifyEvent={carregarDados}
          />
          <ConferirRecargaModal
            isOpen={conferirRecargaOpen}
            toggle={toggleConferirRecarga}
            selected={selected}
            notifyEvent={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <SolicitacaoRecargaGrid
          dados={dados}
          onSelect={handleSelect}
          mostrarDetalhes={mostrarDetalhes}
          cancelar={cancelar}
          utilizaConferenciaRecarga={utilizaConferenciaRecarga}
        />
      </Card>
    </PageContainer>
  );
};
