import moment from "moment";
import React from "react";
import { useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  DatePicker,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../components";
import {
  formatDateISO,
  formatDateLocal,
  MODAL_ACTIONS,
  roundFloat,
  sumDataField,
} from "../../../../../coreUtils";
import { ParcelasGrid } from "./ParcelasGrid";
import GerenciamentoCartaoService from "../../../../../services/financeiro/GerenciamentoCartaoService";
import { isMoment } from "moment";

export const LancCartaoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [administradora, setAdministradora] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [numero, setNumero] = useState("");
  const [parcela, setParcela] = useState(0);
  const [cartaoDebito, setCartaoDebito] = useState(false);
  const [emissao, setEmissao] = useState(new Date());
  const [prevReceb, setPrevReceb] = useState(new Date());
  const [obs, setObs] = useState("");
  const [vlrTotal, setVlrTotal] = useState(0);
  const [qtdParcelas, setQtdParcelas] = useState(0);
  const [parcelasLanc, setParcelasLanc] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const limparDados = () => {
    setAdministradora(null);
    setCliente(null);
    setNumero("");
    setParcela(0);
    setCartaoDebito(false);
    setEmissao(new Date());
    setPrevReceb(new Date());
    setObs("");
    setVlrTotal(0);
    setQtdParcelas(0);
    setParcelasLanc([]);
  };

  const onOpen = async () => {
    if (action === MODAL_ACTIONS.EDIT) {
      const [ok, data] = await GerenciamentoCartaoService.buscar(selected);
      if (ok) {
        setAdministradora(data.id_cab__id_adm);
        setCliente(data.id_cab__id_cli);
        setNumero(data.id_cab__numero);
        setParcela(data.parcela);
        setEmissao(moment(data.emissao, "DD/MM/YYYY").toDate());
        setPrevReceb(moment(data.previa_recebimento, "DD/MM/YYYY").toDate());
        setObs(data.observ);
      }
    }
  };

  const calcularParcelas = () => {
    let parcelas = [];
    let dataCalc = new Date(cartaoDebito ? emissao : prevReceb);

    for (let i = 1; i <= qtdParcelas; i++) {
      parcelas.push({
        id: i,
        parcela: i,
        prev_receb: moment(dataCalc).format("DD/MM/YYYY"),
        valor: Math.round((vlrTotal / qtdParcelas) * 100) / 100,
      });
      dataCalc.setMonth(dataCalc.getMonth() + 1);
    }

    const totalParc = sumDataField(parcelas, "valor");
    if (totalParc !== vlrTotal) parcelas[0].valor += vlrTotal - totalParc;

    setParcelasLanc(parcelas);
  };

  const limparParcelas = () => setParcelasLanc([]);

  const atualizarTotal = () => setVlrTotal(sumDataField(parcelasLanc, "valor"));

  const handleSetEmissao = (v) => {
    setEmissao(moment.isMoment(v) ? v.toDate() : v);
    limparParcelas();
  };

  const handleSetPrevReceb = (v) => {
    setPrevReceb(moment.isMoment(v) ? v.toDate() : v);
    limparParcelas();
  };

  const handleSetVlrTotal = (v) => {
    if (v !== vlrTotal) {
      setVlrTotal(v);
      limparParcelas();
    }
  };

  const handleSetQtdParcelas = (v) => {
    setQtdParcelas(v);
    limparParcelas();
  };

  const handleSetCartaoDebito = () => {
    setCartaoDebito(!cartaoDebito);
    setPrevReceb(cartaoDebito ? new Date() : null);
    limparParcelas();
  };

  const alterarParcela = async (coluna, novoValor, row) => {
    if (coluna === "parcela") {
      row.parcela = parseInt(novoValor);
    } else if (coluna === "prev_receb") {
      if (isMoment(novoValor)) {
        row.prev_receb = formatDateLocal(novoValor);
      } else {
        return false;
      }
    } else if (coluna === "valor") {
      row.valor = parseFloat(novoValor);
    }

    let parcs = parcelasLanc.map((e) => (e.id === row.id ? row : e));

    if (coluna === "valor") {
      setVlrTotal(roundFloat(sumDataField(parcs, "valor"), 2));
    }

    setParcelasLanc(parcs);
  };

  const handleSubmit = async () => {
    const basePayload = {
      id_adm: administradora ?? 0,
      id_cliente: cliente ?? 0,
      numero: numero,
      observ: obs,
    };

    const payload =
      action === MODAL_ACTIONS.ADD
        ? {
            ...basePayload,
            tipo: cartaoDebito ? "D" : "C",
            emissao: formatDateISO(emissao),
            vlr_total: vlrTotal,
            parcelas: parcelasLanc.map((e) => ({
              parcela: parseInt(e.parcela),
              previsao_recebimento: formatDateISO(
                moment(e.prev_receb, "DD/MM/YYYY").toDate()
              ),
              valor: e.valor,
            })),
          }
        : {
            ...basePayload,
            id_cartao_mov: selected,
            parcela: parcela,
            previsao_recebimento: formatDateISO(prevReceb),
          };

    setLoadingSubmit(true);
    const func =
      action === MODAL_ACTIONS.ADD
        ? GerenciamentoCartaoService.incluirAvulso
        : GerenciamentoCartaoService.alterar;

    const [ok] = await func(payload);

    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoadingSubmit(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title={
        action === MODAL_ACTIONS.ADD
          ? "Lançamento Manual de Cartões"
          : "Alteração de Cartão"
      }
      size="md"
      onBeforeOpen={onOpen}
      onClosed={limparDados}
      number="0049_1"
      onConfirm={handleSubmit}
      loadingConfirm={loadingSubmit}
    >
      {action === MODAL_ACTIONS.EDIT && (
        <Row>
          <IntegerFormInput
            md={2}
            label="# ID"
            defaultValue={selected}
            disabled
          />
        </Row>
      )}
      <Row>
        <AsyncComboBox
          md={5}
          label="Administradora"
          isConcatField
          concatModelName="administradora_cartao"
          isSearchable
          defaultOptions
          onChange={(s) => setAdministradora(s?.value)}
          defaultValue={administradora}
          autoFocus
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={8}
          label="Cliente"
          isConcatField
          concatModelName="cliente"
          isSearchable
          onChange={(s) => setCliente(s?.value)}
          defaultValue={cliente}
          defaultOptions={action === MODAL_ACTIONS.EDIT ?? undefined}
        />
      </Row>
      <Row>
        <TextInput
          label="Número"
          md={4}
          onChange={(e, v) => setNumero(v)}
          value={numero}
          maxLength={30}
        />
        {action === MODAL_ACTIONS.ADD ? (
          <FormCheckbox
            label="Cartão de Débito"
            checked={cartaoDebito}
            onChange={handleSetCartaoDebito}
            name="cartao_debito"
          />
        ) : (
          <IntegerFormInput
            md={2}
            label="Parcela"
            defaultValue={parcela}
            onChange={(v) => setParcela(v)}
          />
        )}
      </Row>
      <Row>
        <DatePicker
          label="Emissão"
          md={3}
          onChange={handleSetEmissao}
          value={emissao}
        />
        {(!cartaoDebito || action === MODAL_ACTIONS.EDIT) && (
          <DatePicker
            label={`Prev. ${
              action === MODAL_ACTIONS.ADD ? "1º" : ""
            } Recebimento`}
            md={3}
            onChange={handleSetPrevReceb}
            value={prevReceb}
          />
        )}
      </Row>
      <Row className="mb-2">
        <TextInput
          label="Observação"
          md={12}
          rows={1}
          value={obs}
          onChange={(e, v) => setObs(v)}
        />
      </Row>
      {action === MODAL_ACTIONS.ADD && (
        <>
          <Row className="mb-2">
            <NumberInput
              md={3}
              label="Valor Total"
              onChange={handleSetVlrTotal}
              value={vlrTotal}
            />
            <IntegerFormInput
              md={2}
              label="Parcelas"
              defaultValue={qtdParcelas}
              onChange={handleSetQtdParcelas}
            />
            <FormButton
              md="auto"
              color="info"
              onClick={calcularParcelas}
              disabledHint={
                !cartaoDebito && !(prevReceb instanceof Date)
                  ? "Verifique a data do primeiro recebimento"
                  : cartaoDebito && !(emissao instanceof Date)
                  ? "Verifique a data de emissão"
                  : "Informe o valor total e a quantidade de parcelas"
              }
              disabled={
                (cartaoDebito
                  ? !(emissao instanceof Date)
                  : !(prevReceb instanceof Date)) ||
                !vlrTotal ||
                !qtdParcelas
              }
            >
              Calcular
            </FormButton>
          </Row>
          <ParcelasGrid
            parcelas={parcelasLanc}
            alterarParcela={alterarParcela}
            atualizarTotal={atualizarTotal}
          />
        </>
      )}
    </ModalBase>
  );
};
