import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  FormButton,
  NumberInput,
  TextInput,
  Divider,
  ModalBase,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
} from "../../../../../components";
import { formatValueFromAPI } from "../../../../../coreUtils";
import GerenciamentoCartaoService from "../../../../../services/financeiro/GerenciamentoCartaoService";
import { TaxasCartaoGrid } from "./TaxasCartaoGrid";
import { RadioItem } from "../../../../../components/RadioGroup";

const tiposCartao = [
  {
    label: "Crédito",
    value: "C",
  },
  {
    label: "Débito",
    value: "D",
  },
  {
    label: "Parcelado",
    value: "P",
  },
];

export const TaxasCartaoModal = ({ isOpen, toggle, selected, compensado }) => {
  // Controle
  const [loadingIncluir, setLoadingIncluir] = useState(false);

  // Dados do Cartão
  const [idCliente, setIdCliente] = useState(0);
  const [nomeCliente, setNomeCliente] = useState("");
  const [idAdmin, setIdAdmin] = useState(0);
  const [nomeAdmin, setNomeAdmin] = useState("");
  const [numero, setNumero] = useState("");
  const [parcela, setParcela] = useState("");
  const [emissao, setEmissao] = useState("");
  const [tipoCartao, setTipoCartao] = useState("");
  const [prevReceb, setPrevReceb] = useState("");
  const [vlrOriginal, setVlrOriginal] = useState(0);
  const [vlrDeducoes, setVlrDeducoes] = useState(0);
  const [vlrReceber, setVlrReceber] = useState(0);
  const [vlrRecebido, setVlrRecebido] = useState(0);
  const [obs, setObs] = useState("");

  // Taxas
  const [taxas, setTaxas] = useState([]);
  const [natureza, setNatureza] = useState("-");
  const [planoConta, setPlanoConta] = useState(0);
  const [historico, setHistorico] = useState("");
  const [valorTaxa, setValorTaxa] = useState(0);

  const limparDados = () => {
    setIdCliente(0);
    setNomeCliente("");
    setIdAdmin(0);
    setNomeAdmin("");
    setNumero("");
    setParcela("");
    setEmissao("");
    setTipoCartao("");
    setPrevReceb("");
    setVlrOriginal(0);
    setVlrDeducoes(0);
    setVlrReceber(0);
    setVlrRecebido(0);
    setObs("");
    setTaxas([]);
    setNatureza("-");
    setPlanoConta(0);
    setHistorico("");
    setValorTaxa(0);
  };

  const carregarDados = async () => {
    const [ok, ret] = await GerenciamentoCartaoService.buscar(selected);

    if (ok) {
      setIdCliente(ret.id_cab__id_cli);
      setNomeCliente(ret.nome_cliente);
      setIdAdmin(ret.id_cab__id_adm);
      setNomeAdmin(ret.nome_adm);
      setNumero(ret.id_cab__numero);
      setParcela(ret.parcela);
      setEmissao(ret.emissao);
      setTipoCartao(ret.id_cab__tipo);
      setPrevReceb(ret.previa_recebimento);
      setVlrOriginal(ret.vlr_original);
      setVlrDeducoes(ret.vlr_deducoes);
      setVlrReceber(ret.vlr_receber);
      setVlrRecebido(ret.vlr_recebido);
      setObs(ret.observ);
      setTaxas(ret.taxas);
    }
  };

  const incluirTaxa = async () => {
    setLoadingIncluir(true);
    const payload = {
      id_cartao: selected,
      natureza: natureza,
      id_plano_contas: planoConta ?? 0,
      historico: historico,
      valor: valorTaxa,
    };
    if (await GerenciamentoCartaoService.incluirTaxa(payload)) {
      limparDados();
      carregarDados();
    }
    setLoadingIncluir(false);
  };

  const excluirTaxa = async (idTaxa) => {
    if (await GerenciamentoCartaoService.excluirTaxa(idTaxa)) {
      limparDados();
      carregarDados();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      autoFocus
      size="lg"
      title={!compensado ? "Lançamento de Taxas de Cartão" : "Taxas de Cartão"}
      number="0049_4"
      hideCancelButton
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <Row className="mb-2">
        <TextInput
          disabled
          label="Cliente"
          value={formatValueFromAPI(nomeCliente, idCliente)}
        />
        <TextInput
          md={3}
          disabled
          label="Administradora"
          value={formatValueFromAPI(nomeAdmin, idAdmin)}
        />
        <TextInput
          md={2}
          disabled
          label="Número/Parcela"
          value={`${numero}-${parcela}`}
        />
        <TextInput md={2} disabled label="Emissão" value={emissao} />
      </Row>
      <Row className="mb-2">
        <TextInput
          disabled
          label="Tipo"
          value={
            tiposCartao.find((e) => e.value === tipoCartao)?.label ?? tipoCartao
          }
        />
        <TextInput disabled label="Previsão Receb." value={prevReceb} />
        <NumberInput disabled label="Original" value={vlrOriginal} />
        <NumberInput disabled label="Deduções" value={vlrDeducoes} />
        <NumberInput disabled label="A Receber" value={vlrReceber} />
        <NumberInput disabled label="Recebido" value={vlrRecebido} />
      </Row>
      {obs !== "" && (
        <Row className="mb-2">
          <TextInput disabled label="Observação" value={obs} />
        </Row>
      )}
      {!compensado && (
        <>
          <Divider />
          <Row className="mb-2">
            <RadioGroup
              label="Natureza"
              value={natureza}
              onChange={setNatureza}
            >
              <RadioItem label="Decréscimo" value="-" />
              <RadioItem label="Acréscimo" value="+" />
            </RadioGroup>
          </Row>
          <Row className="mb-2">
            <PesqPlanoCtaCentroCusto
              md={6}
              label="Plano de Contas"
              mode="plano_contas"
              onChange={setPlanoConta}
              value={planoConta}
            />
          </Row>
          <Row className="mb-2">
            <TextInput
              label="Histórico"
              md={12}
              value={historico}
              onChange={(e, v) => setHistorico(v)}
              maxLength={250}
            />
          </Row>
          <Row className="mb-2">
            <NumberInput
              md={3}
              label="Valor"
              onChange={setValorTaxa}
              value={valorTaxa}
            />
            <FormButton
              md="auto"
              color="info"
              onClick={incluirTaxa}
              loading={loadingIncluir}
            >
              Incluir
            </FormButton>
          </Row>
        </>
      )}
      <TaxasCartaoGrid
        taxas={taxas}
        excluirTaxa={excluirTaxa}
        compensado={compensado}
      />
    </ModalBase>
  );
};
