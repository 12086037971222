import React, { useState } from "react";
import { Provider } from "react-redux";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormCheckbox,
  TextInput,
} from "../../../../components";
import { BotaoConfirmarVoltar } from "../../../../components/BotaoConfirmarVoltar";
import { PageContainer } from "../../../../components/PageContainer";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { CadastroContaBancModal } from "./components/CadastroContaBancModal";
import { ContaBancGrid } from "./components/ContaBancGrid";
import store from "./store";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitleContaBanc = "Conta Bancária";
export const routesBaseContaBanc = "/cadastro/conta_banc";

export const ContaBanc = () => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [banco, setBanco] = useState(null);
  const [nroConta, setNroConta] = useState("");
  const [mostrarInativas, setMostrarInativas] = useState(false);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const carregarDados = async () => {
    setLoading(true);
    const params = {
      id_banco: banco,
      nro_conta: nroConta,
      mostrar_inativas: mostrarInativas,
    };
    const [ok, ret] = await apiGetV2(`${routesBaseContaBanc}/listar/`, params);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const notifyEvent = (action) => {
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
    carregarDados();
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Contas Bancárias" number="0045" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            onChange={(s) => setBanco(s?.value)}
            label="Banco"
            md={3}
            isConcatField
            concatModelName="banco"
            defaultOptions
            isSearchable
            isClearable
          />
          <TextInput
            label="Nro. Conta"
            md={3}
            onChange={(e, v) => setNroConta(v)}
          />
          <FormCheckbox
            label="Mostrar Inativas"
            checked={mostrarInativas}
            onChange={() => setMostrarInativas(!mostrarInativas)}
            name="mostrar_inativas"
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <Provider store={store}>
            <CadastroContaBancModal
              isOpen={cadastroOpen}
              toggle={toggleCadastro}
              action={action}
              selected={selected}
              notifyEvent={notifyEvent}
            />
          </Provider>
          <ModalExcluirV2
            title={modalTitleContaBanc}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0045_2"
            selected={selected}
            routeBase={routesBaseContaBanc}
            notifyEvent={notifyEvent}
          />
          <BotaoConfirmarVoltar
            selected={selected}
            carregarDados={carregarDados}
          />
        </Row>
      </Card>
      <ContaBancGrid
        data={dados}
        setSelected={setSelected}
        alterar={alterar}
        excluir={excluir}
      />
    </PageContainer>
  );
};
