import React, { useState } from "react";
import { Row } from "reactstrap";
import { NumberInput, TextInput } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  modalTitleTabFatNfDescVenda,
  routesBaseTabFatNfDescVenda,
} from "../TabFatNfDescVenda";

export const CadastroTabFatNfDescVendaModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [percFat, setPercFat] = useState(0);
  const [percDesc, setPercDesc] = useState(0);

  const limparDados = () => {
    setNome("");
    setPercFat(0);
    setPercDesc(0);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setPercFat(parseFloat(data.perc_fat));
    setPercDesc(parseFloat(data.perc_desc));
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      perc_fat: percFat,
      perc_desc: percDesc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleTabFatNfDescVenda}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseTabFatNfDescVenda}
      number="0050_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={80}
        />
      </Row>
      <Row>
        <NumberInput
          label="Perc. Faturamento"
          value={percFat}
          onChange={setPercFat}
          isPercentage
        />
        <NumberInput
          label="Percentual de redução da margem de lucro na venda"
          value={percDesc}
          onChange={setPercDesc}
          isPercentage
          md="auto"
        />
      </Row>
    </ModalCadastroV2>
  );
};
