import React from "react";
import { ModalBase } from "../../../../../../../components";

export const ConfirmarItemJaIncOrcModal = ({
  isOpen,
  toggle,
  tipo,
  incluirItem,
  adicionaItemGrid,
}) => {
  let msg = <></>;
  if (adicionaItemGrid === "S") {
    if (tipo === "P") {
      msg = (
        <p>
          Este produto já foi incluído no orçamento.
          <br />
          <br />
          Deseja substituí-lo?
        </p>
      );
    } else {
      msg = (
        <p>
          Este serviço já foi incluído no orçamento.
          <br />
          <br />
          Deseja substituí-lo?
        </p>
      );
    }
  } else {
    if (tipo === "P") {
      msg = (
        <p>
          Este produto já foi incluído no orçamento.
          <br />
          <br />
          Deseja incluí-lo novamente?
        </p>
      );
    } else {
      msg = (
        <p>
          Este serviço já foi incluído no orçamento.
          <br />
          <br />
          Deseja incluí-lo novamente?
        </p>
      );
    }
  }

  const onConfirm = () => {
    incluirItem();
    toggle();
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Incluir Item"
      onConfirm={onConfirm}
      cancelButtonText="Não"
      confirmButtonText="Sim"
      autoFocus
    >
      {msg}
    </ModalBase>
  );
};
