import { configureStore } from "@reduxjs/toolkit";
import cadastroContaBancSlice from "./cadastroContaBancSlice";
import controleCamposSlice from "./controleCamposSlice";

export default configureStore({
  reducer: {
    cadastroContaBanc: cadastroContaBancSlice,
    controleCampos: controleCamposSlice,
  },
});
