import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/cadastro/regra_desconto";
const URL_BASE_ITENS = `${URL_BASE}/itens`;

const CadastroRegraPromocaoService = {
  urlBase: URL_BASE,
  alterarPeriodo: (payload) =>
    apiPutV2(`${URL_BASE}/alterar_periodo/`, payload),
  buscarCab: (idRegra) => apiGetV2(`${URL_BASE}/buscar_cab/${idRegra}/`),
  buscarItens: (idRegra) => apiGetV2(`${URL_BASE}/buscar_itens/${idRegra}/`),
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  listarProdsSelecao: (idRegra, params) =>
    apiGetV2(`${URL_BASE}/listar_prods_selecao/${idRegra}/`, params),
  itens: {
    incluir: (payload) => apiPostV2(`${URL_BASE_ITENS}/incluir/`, payload),
    excluir: (idItem) => apiDeleteV2(`${URL_BASE_ITENS}/excluir/${idItem}/`),
  },
};

export default CadastroRegraPromocaoService;
