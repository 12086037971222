import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import InputMask from "react-input-mask";
import { Col, FormFeedback, FormGroup, Input } from "reactstrap";
import { handleFocus } from "../coreUtils";
import { HintLabel } from "./HintLabel";

const MaskedInput = forwardRef(
  (
    {
      md,
      mdInput = 12,
      label,
      type = "text",
      name,
      onChange,
      onBlur,
      value,
      className,
      inline = false,
      check = false,
      required = false,
      invalid = false,
      disabled = false,
      placeholder = "",
      mask,
      maskPlaceholder,
      maskChar,
      id,
      autoFocus,
      actions = [],
      additionalButton,
      onKeyDown,
      divClassName,
      inputStyle,
      hint,
    },
    ref
  ) => {
    const [innerValidation, setInnerValidation] = useState(true);
    const [internalValue, setInternalValue] = useState("");
    const [internalId] = useState(
      id ?? "mi-" + Math.floor(Math.random() * Date.now())
    );

    const inputRef = useRef("");

    const validateInternally = (e) => {
      if (required && e.target.value === "") {
        setInnerValidation(false);
      } else {
        setInnerValidation(true);
      }
      if (onBlur) {
        onBlur(e.target.value, e);
      }
    };

    useEffect(() => setInternalValue(value?.toUpperCase()), [value]);

    const handleOnChange = (e) => {
      e.preventDefault();
      const inputValue = e.target.value?.toUpperCase() || "";
      setInternalValue(inputValue);
      if (onChange) {
        onChange(e, inputValue);
      }
    };

    const onKeyDownInternal = (e) => {
      if (onKeyDown) {
        onKeyDown(e);
      }
      handleFocus(e);
    };

    useImperativeHandle(ref, () => ({
      isValid: () => innerValidation && !invalid,
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      value: internalValue?.toUpperCase()?.trim(),
      setValue: (val) => setInternalValue(val),
    }));

    return (
      <Col md={md} className={divClassName}>
        <FormGroup check={check} inline={inline}>
          <HintLabel
            check={check}
            for={internalId}
            label={label}
            required={required}
            hintText={hint}
          />
          <Col md={mdInput} className="no-gutters">
            <div style={{ display: "flex", width: "100%" }}>
              <InputMask
                mask={mask}
                onChange={handleOnChange}
                value={internalValue}
                onBlur={validateInternally}
                disabled={disabled}
                maskPlaceholder={maskPlaceholder}
                maskChar={maskChar}
                alwaysShowMask={false}
              >
                {() => (
                  <Input
                    type={type}
                    name={name}
                    defaultValue={value}
                    innerRef={inputRef}
                    className={className}
                    required={required}
                    invalid={!innerValidation || invalid}
                    placeholder={placeholder}
                    onKeyDown={onKeyDownInternal}
                    disabled={disabled}
                    id={internalId}
                    autoFocus={autoFocus}
                    style={inputStyle}
                  />
                )}
              </InputMask>
              {actions.length > 0 && (
                <div style={{ display: "flex" }}>{actions.map((e) => e)}</div>
              )}
              {additionalButton && additionalButton}
            </div>
          </Col>
          <FormFeedback valid={false}>Esse campo é obrigatório</FormFeedback>
        </FormGroup>
      </Col>
    );
  }
);

export default MaskedInput;
