import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = `/cadastro/cfop`;

const CadastroCfopService = {
  urlBase: URL_BASE,
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  alterar: async (payload) => await apiPutV2(`${URL_BASE}/alterar/`, payload),
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir/`, payload),
  buscar: async (id) => await apiGetV2(`${URL_BASE}/buscar/${id}/`),
};

export default CadastroCfopService;
