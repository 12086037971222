import React from "react";
import { TableDelete } from "../../../../../../components";
import { formatNumber, formatarValor } from "../../../../../../coreUtils";
import { Table } from "../../../../../../components/Table";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { BsBoxSeam } from "react-icons/bs";
import { UncontrolledTooltip } from "reactstrap";

export const ItensProjetoGrid = ({
  recalcularValoresGrade,
  atualizarDadosItem,
  removerItem,
  itens = [],
}) => {
  const columns = [
    {
      dataField: "tipo",
      text: "T",
      align: "left",
      headerAlign: "left",
      editable: false,
      colWidth: "30px",
      formatter: (c, row) => {
        const iconId = `icon-tipo-${row.id}-${row.id_item}`;
        if (c === "Serviço") {
          return (
            <>
              <MdOutlineHomeRepairService id={iconId} size={16} />
              <UncontrolledTooltip target={iconId}>{c}</UncontrolledTooltip>
            </>
          );
        } else {
          return (
            <>
              <BsBoxSeam id={iconId} size={14} />
              <UncontrolledTooltip target={iconId}>{c}</UncontrolledTooltip>
            </>
          );
        }
      },
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      headerAlign: "center",
      editable: false,
      colWidth: "75px",
    },
    {
      dataField: "id_item",
      text: "#",
      align: "center",
      headerAlign: "center",
      editable: false,
      colWidth: "60px",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      editable: (_, row) => row.curinga,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "text",
      colWidth: "120px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_custo",
      text: "Custo",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      editable: false,
      colWidth: "60px",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      headerAlign: "center",
      editable: false,
      colWidth: "30px",
    },
    {
      dataField: "vlr_unit",
      text: "V Unit.",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      editable: false,
      colWidth: "60px",
    },
    {
      dataField: "observ",
      text: "Observação",
      align: "left",
      headerAlign: "left",
      editable: true,
      colWidth: "280px",
      editorType: "textarea",
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      borderless: true,
      editorForceUpperCase: false,
    },
    {
      dataField: "quantidade",
      text: "Qtde",
      align: "center",
      headerAlign: "center",
      editable: true,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 4,
      formatter: (c) => formatarValor(c, 4, 0),
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_item",
      text: "Valor Item",
      align: "right",
      headerAlign: "right",
      editable: true,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 4,
      formatter: (c) => formatarValor(c, 4),
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "perc_desc",
      text: "% Desc.",
      align: "center",
      headerAlign: "center",
      editable: true,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 2,
      formatter: (c) => formatarValor(c, 2),
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_total",
      text: "Valor Total",
      align: "right",
      headerAlign: "right",
      editable: (c, row) => (parseFloat(row.quantidade) ?? 0) > 0,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 2,
      formatter: (c, row) => (
        <>
          <span id={`vlr_tot_${row.id}`}>{formatarValor(c, 2)}</span>
          <UncontrolledTooltip placement="left" target={`vlr_tot_${row.id}`}>
            Informe a quantidade do item para alterar este valor
          </UncontrolledTooltip>{" "}
        </>
      ),
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dummy: true,
      text: "",
      formatter: (_, row) => (
        <TableDelete onClick={() => removerItem(row.id, row.tipo)} />
      ),
    },
  ];

  return (
    <Table columns={columns} data={itens} paginated={false} growIntoPageSize />
  );
};
