import React from "react";

const NumberPage = ({ children }) => {
  return (
    <span className="text-muted text-sm" name="page-number">
      [{children}]
    </span>
  );
};

export default NumberPage;
