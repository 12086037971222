import React, { useState } from "react";
import {
  FixedField,
  ModalBase,
  ModalDetalhesIcone,
  Table,
} from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { Row } from "reactstrap";

const columnsDetalhes = [
  {
    dataField: "nome_produto",
    text: "Produto",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
  },
  {
    dataField: "qtd_antes",
    text: "Qtd. Antes",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "qtd_transac",
    text: "Qtd. Movimento",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "qtd_depois",
    text: "Qtd. Depois",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_custo",
    text: "Custo Unit.",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_tot_custo",
    text: "Custo Total",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const DetalhesLancamentoModal = ({ lancamento }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalDetalhesIcone toggle={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Detalhes do Lançamento"
        number="5059_1"
      >
        <Row className="mb-2">
          <FixedField
            label="Data/Hora"
            value={lancamento.data_hora}
            horizontal
          />
          <FixedField
            label="Descrição"
            value={lancamento.descricao}
            horizontal
          />
          <FixedField
            label="ID Origem"
            value={lancamento.id_origem}
            horizontal
          />
        </Row>
        <Row className="mb-2">
          <FixedField
            label="Colaborador"
            value={lancamento.colaborador}
            horizontal
          />
        </Row>
        <Table
          data={lancamento?.produtos ?? []}
          columns={columnsDetalhes}
          paginated={false}
          pageSize={15}
        />
      </ModalBase>
    </>
  );
};
