import React from "react";
import { useEffect } from "react";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import Loader from "./Loader";
import {
  SET_PAGE_NUMBER,
  SET_PAGE_ON_BEFORE_GOING_BACK,
  SET_PAGE_TITLE,
  SET_PAGE_TOP_OPTIONS,
} from "../redux/constants";
import { connect } from "react-redux";

const InnerPageContainer = ({
  title = "Sem Título",
  number,
  className = "p-0",
  canGoBack = false,
  onBeforeGoingBack,
  children,
  topOptions,
  onKeyDown,
  loading,
  formClassName = "pb-2",
  dispatch,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (onKeyDown) {
      const func = (e) =>
        !loading &&
        !document.getRootNode().body.className.includes("modal-open") &&
        onKeyDown(e);

      window.addEventListener("keydown", func);

      return () => {
        window.removeEventListener("keydown", func);
      };
    }
  }, [onKeyDown, loading]);

  useEffect(() => {
    if (canGoBack) {
      const exitOnEsc = (e) => {
        if (
          !document.getRootNode().body.className.includes("modal-open") &&
          e.key === "Escape"
        ) {
          onBeforeGoingBack && onBeforeGoingBack();
          history.goBack();
        }
      };

      window.addEventListener("keydown", exitOnEsc);

      return () => {
        window.removeEventListener("keydown", exitOnEsc);
      };
    }
  }, [canGoBack, history]);

  useEffect(() => {
    dispatch({ type: SET_PAGE_TITLE, data: title });
  }, [title]);

  useEffect(() => {
    dispatch({ type: SET_PAGE_NUMBER, data: number });
  }, [number]);

  useEffect(() => {
    dispatch({ type: SET_PAGE_ON_BEFORE_GOING_BACK, data: onBeforeGoingBack });
  }, [onBeforeGoingBack]);

  useEffect(() => {
    dispatch({ type: SET_PAGE_TOP_OPTIONS, data: topOptions });
  }, [topOptions]);

  return loading ? (
    <Loader />
  ) : (
    <Container fluid className={className}>
      <form className={formClassName} onSubmit={(e) => e.preventDefault()}>
        {children}{" "}
      </form>
    </Container>
  );
};

export const PageContainer = connect((store) => ({
  page: store.page,
}))(InnerPageContainer);
