import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rec_aceita_chq_sem_cpf_cnpj: false,
  rec_id_plano_contas_pad_inc_cheq: null,
  pag_id_plano_contas_pad_inc_cheq: null,
};

export const chequeSlice = createSlice({
  name: "cheque_slice",
  initialState: initialState,
  reducers: {
    setupCheque: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setRecAceitaChqSemCpfCnpj: (state, action) => {
      state.rec_aceita_chq_sem_cpf_cnpj = action.payload;
    },
    setRecIdPlanoContasPadIncCheq: (state, action) => {
      state.rec_id_plano_contas_pad_inc_cheq = action.payload;
    },
    setPagIdPlanoContasPadIncCheq: (state, action) => {
      state.pag_id_plano_contas_pad_inc_cheq = action.payload;
    },
  },
});

export const {
  setupCheque,
  setRecAceitaChqSemCpfCnpj,
  setRecIdPlanoContasPadIncCheq,
  setPagIdPlanoContasPadIncCheq,
} = chequeSlice.actions;

export default chequeSlice.reducer;
