import { DiretorioConteudo } from "../../pages/config/diretorio_conteudo/DiretorioConteudo";
import { Geral } from "../../pages/config/geral/Geral";
import { Impressao } from "../../pages/config/impressao/Impressao";
import { InformacoesEmpresa } from "../../pages/config/informacoes_empresa/InformacoesEmpresa";
import { Whatsapp } from "../../pages/config/whatsapp/Whatsapp";
import { Personalizar } from "../../pages/config/personalizar/Personalizar";
import { Senhas } from "../../pages/config/senhas/Senhas";
import { Usuarios } from "../../pages/config/usuarios/Usuarios";
import { Folder, Printer, Settings, Sliders, Users } from "react-feather";
import { BsHouseGear, BsKey, BsWhatsapp } from "react-icons/bs";

export const configGeralRoute = {
  path: "/configuracoes/geral",
  name: "Configurações",
  component: Geral,
  canGoBack: false,
  icon: Settings,
};

export const configPersonalizarRoute = {
  path: "/configuracoes/personalizar",
  name: "Personalizar",
  component: Personalizar,
  canGoBack: false,
  icon: Sliders,
};

export const configDiretorioConteudoRoute = {
  path: "/configuracoes/diretorio_conteudo",
  name: "Diretórios de Conteúdo",
  component: DiretorioConteudo,
  canGoBack: false,
  icon: Folder,
};

export const configUsuariosRoute = {
  path: "/configuracoes/usuarios",
  name: "Usuários",
  component: Usuarios,
  canGoBack: false,
  icon: Users,
};

export const configImpressaoRoute = {
  path: "/configuracoes/impressao",
  name: "Definições de Impressão",
  component: Impressao,
  canGoBack: false,
  icon: Printer,
};

export const configSenhasRoute = {
  path: "/configuracoes/senhas",
  name: "Senhas",
  component: Senhas,
  canGoBack: false,
  icon: BsKey,
};

export const configInformacoesEmpresaRoute = {
  path: "/configuracoes/informacoes_empresa",
  name: "Informações da Empresa",
  component: InformacoesEmpresa,
  canGoBack: false,
  icon: BsHouseGear,
};

export const configWhatsappRoute = {
  path: "/configuracoes/whatsapp",
  name: "Whatsapp",
  component: Whatsapp,
  canGoBack: false,
  icon: BsWhatsapp,
};

export const configuracoesRoutes = [
  configGeralRoute,
  configPersonalizarRoute,
  configDiretorioConteudoRoute,
  configUsuariosRoute,
  configImpressaoRoute,
  configSenhasRoute,
  configInformacoesEmpresaRoute,
  configWhatsappRoute,
];

export default configuracoesRoutes;
