import React from "react";
import {
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip,
} from "reactstrap";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  historicoVeiculoRoute,
  manutencaoOSRoute,
  orcamentosOSRoute,
} from "../../../../../routes/modules/ordem_servico";
import { VscTriangleLeft } from "react-icons/vsc";

const OsCard = ({
  dadosOs,
  utilizaStatusAdicConcServ,
  identificaDataHoraAgendamento,
  concluirOS,
  finalizarOS,
  imprimirOS,
  emailOS,
  enviarExec,
  statusAlias,
  utilizaModuloAvancado,
  mostrarDetalhes,
  reabrirConclusao,
  perfilPermiteFinalizarOrdemServico,
}) => {
  const history = useHistory();
  const idOs = dadosOs.id;
  const nomeCliente = formatValueFromAPI(
    dadosOs.nome_fant_cliente || dadosOs.nome_cliente,
    dadosOs.id_cliente
  );

  const aberta = statusAlias === "aberta";
  const finalizada = statusAlias === "finalizada";
  const servConcluido = dadosOs.serv_concluido;

  const emRecepcao = statusAlias === "em_recepcao";
  const emOrcamento = statusAlias === "em_orcamento";
  const emExecucao = statusAlias === "em_execucao";
  const concluida = statusAlias === "concluida";
  const tipo = dadosOs.tipo;
  const osComPlaca = tipo === "CP";

  const podeAlterar =
    aberta || emRecepcao || emOrcamento || emExecucao || servConcluido;

  const podeFinalizar = !concluida && !finalizada;

  const podeEnviarExec = utilizaModuloAvancado && podeFinalizar && !emExecucao;

  const agChefeMec = dadosOs.chefe_mec;

  return (
    <div
      body
      style={{
        cursor: podeAlterar && "pointer",
        backgroundColor: "#EEE",
        display: "flex",
        flexDirection: "row",
        borderRadius: "0.2rem",
        marginBottom: "0.5rem",
      }}
      onClick={() =>
        podeAlterar && history.push(manutencaoOSRoute.path, { selected: idOs })
      }
    >
      <div
        style={{
          width: "93%",
          paddingInline: "0.75rem",
          paddingBlock: "0.5rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5 className="mb-0" style={{ fontSize: "0.75rem" }}>
            {osComPlaca ? dadosOs.placa : nomeCliente}
          </h5>
          {osComPlaca && (
            <span
              style={{
                fontWeight: "normal",
                color: "gray",
                marginLeft: "0.8rem",
                marginTop: "-0.1rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {dadosOs.nome_veiculo}
            </span>
          )}
        </div>
        <Row className="no-gutters" style={{ justifyContent: "space-between" }}>
          {osComPlaca && <span>{nomeCliente}</span>}
        </Row>
        <Row className="no-gutters" style={{ justifyContent: "space-between" }}>
          {identificaDataHoraAgendamento &&
            (dadosOs.data_agend ?? "") !== "" && (
              <span style={{ color: "gray" }}>
                Agendado Para: {dadosOs.data_agend}
              </span>
            )}
          {utilizaStatusAdicConcServ &&
            (dadosOs.data_conclusao_serv ?? "") !== "" && (
              <span style={{ color: "gray" }}>
                Concluído em: {dadosOs.data_conclusao_serv}
              </span>
            )}
        </Row>
      </div>
      <div style={{ paddingTop: "0.5rem", paddingRight: "0.5rem" }}>
        <h5
          className="mb-0"
          style={{
            marginLeft: "auto",
            color: "black",
            fontSize: "0.8rem",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            textAlign: "right",
          }}
        >
          # {idOs}
        </h5>
        {agChefeMec && (
          <div
            style={{
              backgroundColor: "red",
              paddingInline: "0.5rem",
              borderRadius: "0.5rem",
            }}
          >
            <span style={{ color: "white" }}>Ag. Definição</span>
          </div>
        )}
        {servConcluido && (
          <div
            style={{
              backgroundColor: "green",
              paddingInline: "0.5rem",
              borderRadius: "0.5rem",
            }}
          >
            <span style={{ color: "white" }}>Concluída</span>
          </div>
        )}
      </div>
      <UncontrolledDropdown
        direction="right"
        onToggle={(e) => e.stopPropagation()}
        style={{ marginLeft: "auto" }}
      >
        <DropdownToggle
          style={{
            background: "none",
            border: "none",
            marginLeft: "auto",
            padding: 0,
            cursor: "pointer",
            height: "100%",
            backgroundColor: "#DDD",
          }}
        >
          <VscTriangleLeft
            size={18}
            color="grey"
            style={{ cursor: "pointer" }}
          />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              mostrarDetalhes(idOs);
            }}
          >
            Detalhes
          </DropdownItem>
          {podeEnviarExec && (
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                enviarExec(idOs);
              }}
            >
              Enviar para Execução
            </DropdownItem>
          )}
          {podeFinalizar && (
            <>
              {utilizaStatusAdicConcServ && !servConcluido ? (
                <DropdownItem
                  onClick={(e) => {
                    e.stopPropagation();
                    concluirOS(idOs);
                  }}
                >
                  Concluir Serviço
                </DropdownItem>
              ) : (
                <>
                  <DropdownItem
                    onClick={
                      perfilPermiteFinalizarOrdemServico
                        ? (e) => {
                            e.stopPropagation();
                            finalizarOS(idOs, nomeCliente);
                          }
                        : null
                    }
                    id={`finalizar-os-${idOs}-item`}
                    style={
                      !perfilPermiteFinalizarOrdemServico
                        ? { color: "#ccc" }
                        : {}
                    }
                  >
                    Finalizar OS
                  </DropdownItem>
                  {!perfilPermiteFinalizarOrdemServico && (
                    <UncontrolledTooltip target={`finalizar-os-${idOs}-item`}>
                      Seu perfil não tem permissão para finalizar Ordens de
                      Serviço.
                    </UncontrolledTooltip>
                  )}
                </>
              )}
            </>
          )}
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              imprimirOS(idOs);
            }}
          >
            Imprimir Fechamento
          </DropdownItem>
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              emailOS(idOs);
            }}
          >
            Enviar E-Mail
          </DropdownItem>
          <DropdownItem
            onClick={() => history.push(orcamentosOSRoute.path, { idOs: idOs })}
          >
            Orçamentos
          </DropdownItem>
          {osComPlaca && (
            <DropdownItem
              onClick={() =>
                history.push(historicoVeiculoRoute.path, {
                  placa: dadosOs.placa,
                })
              }
            >
              Histórico Veículo
            </DropdownItem>
          )}
          {(utilizaStatusAdicConcServ || utilizaModuloAvancado) &&
            servConcluido && (
              <DropdownItem onClick={() => reabrirConclusao(idOs)}>
                Reabrir Conclusão
              </DropdownItem>
            )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export const CentralOSBoard = ({
  dados,
  mecanica,
  utilizaStatusAdicConcServ,
  identificaDataHoraAgendamento,
  concluirOS,
  finalizarOS,
  imprimirOS,
  emailOS,
  enviarExec,
  utilizaModuloAvancado,
  mostrarDetalhes,
  reabrirConclusao,
  perfilPermiteFinalizarOrdemServico = true,
}) => {
  const status = utilizaModuloAvancado
    ? [
        {
          title: "Em Recepção",
          color: "purple",
          alias: "em_recepcao",
        },
        {
          title: "Em Checklist / Orçamento",
          color: "blue",
          alias: "em_orcamento",
        },
        {
          title: "Em Execução",
          color: "indigo",
          alias: "em_execucao",
        },
        {
          title: "Finalizada",
          color: "green",
          alias: "concluida",
        },
      ]
    : [
        {
          title: "Aberta",
          color: "purple",
          alias: "aberta",
        },
        {
          title: "Concluída",
          color: "blue",
          alias: "serv_concluido",
          hidden: !utilizaStatusAdicConcServ,
        },
        {
          title: "Finalizada",
          color: "green",
          alias: "finalizada",
        },
      ];

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      {status
        .filter((e) => !e.hidden)
        .map((stat) => (
          <div style={{ flexBasis: 0, flexGrow: 1, maxWidth: "100%" }}>
            <div
              style={{
                backgroundColor: stat.color,
                marginRight: "0.5rem",
                borderTopRightRadius: "0.3rem",
                borderTopLeftRadius: "0.3rem",
                height: "2.5rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h5
                style={{
                  textAlign: "Center",
                  marginBottom: 0,
                  color: "white",
                  marginBlock: "auto",
                }}
              >
                {stat.title}
              </h5>
            </div>
            <div
              style={{
                height: "50vh",
                overflow: "auto",
                paddingRight: "0.5rem",
                paddingTop: "0.3rem",
              }}
            >
              {dados instanceof Object &&
                dados[stat.alias] &&
                dados[stat.alias].map((e) => (
                  <OsCard
                    dadosOs={e}
                    mecanica={mecanica}
                    utilizaStatusAdicConcServ={utilizaStatusAdicConcServ}
                    identificaDataHoraAgendamento={
                      identificaDataHoraAgendamento
                    }
                    concluirOS={concluirOS}
                    finalizarOS={finalizarOS}
                    perfilPermiteFinalizarOrdemServico={
                      perfilPermiteFinalizarOrdemServico
                    }
                    imprimirOS={imprimirOS}
                    emailOS={emailOS}
                    statusAlias={stat.alias}
                    enviarExec={enviarExec}
                    utilizaModuloAvancado={utilizaModuloAvancado}
                    mostrarDetalhes={mostrarDetalhes}
                    reabrirConclusao={reabrirConclusao}
                  />
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};
