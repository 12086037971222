import React from "react";
import {
  AsyncComboBox,
  BaseInfoCard,
  BotaoImprimir,
  BotaoPesquisar,
  FormButton,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { useState } from "react";
import { apiGetV2 } from "../../../../apiV2";
import {
  ClientesPorVendedorGrid,
  columnsClientePorVendedor,
  columnsDetailClientePorVendedor,
} from "./components/ClientesPorVendedorGrid";
import { columnsToObject, exportDataToSheet } from "../../../../coreUtils";
import { docPrintReport } from "../../../../pdf";

export const ClientesPorVendedor = () => {
  const [idCliente, setIdCliente] = useState(null);
  const [idCidade, setIdCidade] = useState(null);
  const [idVendedor, setIdVendedor] = useState(null);
  const [imprimirDetalhamento, setImprimirDetalhamento] = useState(false);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);

  const buscarDados = async () => {
    const params = {
      id_cliente: idCliente,
      id_cidade: idCidade,
      id_vendedor: idVendedor,
    };

    return await apiGetV2(
      "/relatorios/cadastrais/clientes/clientes_por_vendedor/",
      params
    );
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        dados: ret,
        imprimir_detalhamento: imprimirDetalhamento,
      };

      await docPrintReport(
        "/relatorios/cadastrais/clientes_por_vendedor/",
        payload,
        "5053"
      );
    }
    setLoadingImprimir(false);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      let data = [];

      const formatarVendedor = (data) =>
        columnsToObject(columnsClientePorVendedor, data);

      const formatarCliente = (data) =>
        columnsToObject(columnsDetailClientePorVendedor, data);

      if (imprimirDetalhamento) {
        data = ret.reduce(
          (acc, vend) => [
            ...acc,
            ...vend.clientes.map((cli) => ({
              Vendedor: vend.nome,
              ...formatarCliente(cli),
            })),
          ],
          []
        );
      } else {
        data = ret.map((e) => formatarVendedor(e));
      }

      exportDataToSheet(data, "Clientes por Vendedor");
    }

    setLoadingExportar(false);
  };

  return (
    <PageContainer title="Clientes Por Vendedor" number="5053" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            label="Cliente"
            defaultOptions
            onChange={(s) => setIdCliente(s?.value)}
            value={idCliente}
          />
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="cidade"
            isSearchable
            isClearable
            label="Cidade"
            defaultOptions
            onChange={(s) => setIdCidade(s?.value)}
            value={idCidade}
          />
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="colaborador"
            isSearchable
            isClearable
            label="Vendedor"
            defaultOptions
            onChange={(s) => setIdVendedor(s?.value)}
            value={idVendedor}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
          <FormCheckbox
            label="Imprimir Clientes"
            checked={imprimirDetalhamento}
            onChange={() => setImprimirDetalhamento(!imprimirDetalhamento)}
          />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportar}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <ClientesPorVendedorGrid dados={dados} />
      </Card>
      <BaseInfoCard
        baseInfo={[
          "Vendedores vinculados a clientes através do campo representante externo no cadastro de cliete",
          "Vendedores vinculados a clientes através do cadastro de roteirização",
        ]}
      />
    </PageContainer>
  );
};
