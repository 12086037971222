import React from "react";
import {
  dateColumnSorter,
  formatNumber,
  formatValueFromAPI,
  numericNaturalSort,
} from "../../../../../coreUtils";
import { TableCheck, Table, MoreDropdown } from "../../../../../components";
import { SIGLA_MOSTRAR_PENDENTES } from "../GerencCobrancaBanc";
import { DropdownItem } from "reactstrap";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

export const GerencCobrancaBancGrid = ({
  dados,
  mostrar,
  handleSelect,
  handleSelectAll,
  mostrarDetalhes,
  cancelarBoleto,
}) => {
  const licenca = localStorage.getItem("licenca");

  const esconderColunaNumeroNFSe = parseInt(licenca) > 0;

  const columns = [
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      formatter: (c, row) => (
        <div style={{ display: "flex" }}>
          <span className="mr-2">{formatValueFromAPI(c, row.id_cliente)}</span>
          {row.cli_emite_nfse === true && !esconderColunaNumeroNFSe && (
            <LiaFileInvoiceDollarSolid size={14} />
          )}
        </div>
      ),
      sortable: true,
    },
    {
      dataField: "numero",
      text: "Nº / P",
      align: "center",
      formatter: (c, row) => `${c}/${row.parcela}`,
      sortable: true,
    },
    {
      dataField: "nro_boleto",
      text: "Nº Boleto",
      align: "center",
      sortable: true,
    },
    {
      dataField: "banco_boleto",
      text: "Banco Boleto",
      align: "center",
      sortable: true,
    },
    {
      dataField: "situacao_boleto",
      text: "Status Boleto",
      align: "center",
      sortable: true,
    },
    {
      dataField: "numero_nfse",
      text: "NFS-e",
      sortable: true,
      align: "center",
      hidden: esconderColunaNumeroNFSe,
      formatter: (c, row) => {
        let corCampo = "#DBDBDB";
        let corTexto = "black";

        if (row.status_nfse === "A") {
          corCampo = "#0080FF";
          corTexto = "white";
        }

        if (row.status_nfse === "E") {
          corCampo = "#00AA00";
          corTexto = "white";
        }

        if (row.status_nfse === "R") {
          corCampo = "#ff9151";
          corTexto = "white";
        }

        if (row.status_nfse === "C") {
          corCampo = "#FF0D0D";
          corTexto = "white";
        }

        return (
          <span
            style={{
              backgroundColor: corCampo,
              paddingInline: "0.3rem",
              marginRight: "0.3rem",
              borderRadius: "0.2rem",
              color: corTexto,
            }}
          >
            {c ?? "Sem NFS-e"}
          </span>
        );
      },
    },
    {
      dataField: "vencimento",
      text: "Vencimento",
      align: "center",
      sortable: true,
      sortFunc: dateColumnSorter,
    },
    {
      dataField: "data_recebimento",
      text: "Recebimento",
      align: "center",
      sortable: true,
      sortFunc: dateColumnSorter,
    },
    {
      dataField: "recebido",
      text: "Recebido",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    {
      dataField: "vlr_orig",
      text: "Original",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "vlr_acresc",
      text: "Acréscimo",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "vlr_desc",
      text: "Dedução",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    mostrar === SIGLA_MOSTRAR_PENDENTES
      ? {
          dataField: "vlr_receber",
          text: "Receber",
          align: "right",
          formatter: (c) => formatNumber(c, true, 2),
          sortable: true,
          sortFunc: numericNaturalSort,
        }
      : {
          dataField: "vlr_recebido",
          text: "Recebido",
          align: "right",
          formatter: (c) => formatNumber(c, true, 2),
          sortable: true,
          sortFunc: numericNaturalSort,
        },
    {
      dataField: "act2",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <MoreDropdown onToggle={(e) => e.stopPropagation()} horizontal>
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              mostrarDetalhes(row.id);
            }}
          >
            Detalhes
          </DropdownItem>
          {row.status_boleto !== "CAN" && row.status_boleto && (
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                cancelarBoleto(row.id, row.layout_boleto);
              }}
            >
              Cancelar Boleto
            </DropdownItem>
          )}
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={dados}
      onSelect={handleSelect}
      onSelectAll={handleSelectAll}
      pageSize={20}
      paginated={false}
      clearSelectionOnUpdate
      multiselect
    />
  );
};
