import { apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/estoque_principal/gerenciamento";
const ManutencaoEstoqueService = {
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  buscarMovimentacoes: async (idProd, params) =>
    await apiGetV2(`${URL_BASE}/buscar_movimentacoes/${idProd}/`, params),
  incluirAjuste: async (payload) =>
    await apiPostV2(`${URL_BASE}/incluir_ajuste/`, payload),
};

export default ManutencaoEstoqueService;
