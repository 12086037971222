import React from "react";
import { Label, Row } from "reactstrap";
import { FormCheckbox, RadioGroup, TextInput } from "../../../../components";
import { RadioItem } from "../../../../components/RadioGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssunto,
  setExigConexSeg,
  setMensagemPad,
  setPortaSmtp,
  setSenha,
  setServidorSmtp,
  setTipo,
  setUsuario,
} from "../store/email_slice";

export const PersonEmail = () => {
  const storeGeral = useSelector((state) => state.geral);
  const store = useSelector((state) => state.email);
  const dispatch = useDispatch();

  return (
    <>
      <Label md={12} style={{ borderBottom: "0.5px solid #ccc" }}>
        Os parâmetros de e-mail são utilizados por clientes que emitem nota pelo
        sistema Desktop ou clientes que optam por utilizar o serviço SMTP de
        envio através de uma conta de e-mail própria.
      </Label>
      {storeGeral.respons_por_envio_de_email === "SKILL" ? (
        <>
          <Label md="auto">
            O envio de e-mails está configurado para utilizar o e-mail
            naoresponder@skillsoft.com.br.
            <br />É responsabilidade da Skillsoft garantir seu bom
            funcionamento.
          </Label>
        </>
      ) : (
        <>
          <Row>
            <TextInput
              label="Servidor SMTP"
              value={store.servidor_smtp}
              onChange={(e, v) => dispatch(setServidorSmtp(v))}
              forceUppercase={false}
              maxLength={100}
              md={5}
            />
            <TextInput
              label="Porta"
              value={store.porta_smtp}
              onChange={(e, v) => dispatch(setPortaSmtp(v))}
              forceUppercase={false}
              maxLength={10}
              md={3}
            />
          </Row>
          <Row>
            <TextInput
              label="Usuario"
              value={store.usuario}
              onChange={(e, v) => dispatch(setUsuario(v))}
              forceUppercase={false}
              maxLength={100}
              md={5}
            />
            <TextInput
              label="Senha"
              value={store.senha}
              onChange={(e, v) => dispatch(setSenha(v))}
              forceUppercase={false}
              maxLength={40}
              md={4}
            />
            <FormCheckbox
              label="SMTP Exige Conexão Segura"
              checked={store.exig_conex_seg}
              onChange={() => dispatch(setExigConexSeg(!store.exig_conex_seg))}
            />
          </Row>
          <Row>
            <TextInput
              label="Assunto"
              value={store.assunto}
              onChange={(e, v) => dispatch(setAssunto(v))}
              forceUppercase={false}
              maxLength={200}
              md={8}
            />
          </Row>
          <Row>
            <TextInput
              label="Mensagem Padrão"
              value={store.mensagem_pad}
              onChange={(e, v) => dispatch(setMensagemPad(v))}
              forceUppercase={false}
            />
          </Row>
          {storeGeral.respons_por_envio_de_email === "CLIST" && (
            <Row>
              <RadioGroup
                label="Tipo"
                value={store.tipo}
                onChange={(v) => dispatch(setTipo(v))}
              >
                <RadioItem label="Financeiro" value="FINANC" />
                <RadioItem label="Orçamento" value="ORCAM" />
              </RadioGroup>
            </Row>
          )}
        </>
      )}
    </>
  );
};
