import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/meta/cadastro";

const CadastroMetaVendaService = {
  routesBase: URL_BASE,
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  listarMovs: async (id, pista) =>
    await apiGetV2(`${URL_BASE}/mov/listar/${id}/`, { pista: pista }),
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir/`, payload),
  incluirMov: async (payload) =>
    await apiPostV2(`${URL_BASE}/mov/incluir/`, payload),
  alterarMov: async (payload) =>
    await apiPutV2(`${URL_BASE}/mov/alterar/`, payload, {
      successMessage: false,
    }),
  excluirMov: async (id) => await apiDeleteV2(`${URL_BASE}/mov/excluir/${id}/`),
  buscarDadosClonar: async (id) =>
    await apiGetV2(`${URL_BASE}/buscar_dados_duplicar/${id}/`),
  clonar: async (payload) => await apiPostV2(`${URL_BASE}/duplicar/`, payload),
  buscarValoresPeriodo: async (params) =>
    await apiGetV2(`${URL_BASE}/buscar_valores_venda_periodo/`, params),
};

export default CadastroMetaVendaService;
