import { apiGet, apiPost, apiPut, urlParams } from "../../api";

const URL_BASE = "/cadastro/regra_tributacao/grupo_reg";
const GrupoRegraTributacaoService = {
  listar: async (pista) => {
    const params = urlParams({ pista: pista });

    return await apiGet(`${URL_BASE}/listar/${params}`);
  },
  buscar: async (id) => await apiGet(`${URL_BASE}/buscar/${id}/`),
  incluir: async (payload) => await apiPost(`${URL_BASE}/incluir/`, payload),
  alterar: async (payload) => await apiPut(`${URL_BASE}/alterar/`, payload),
  buscarRegrasSel: async (id, pista, finalidade) => {
    const params = urlParams({ pista: pista, finalidade: finalidade });
    return await apiGet(`${URL_BASE}/sel_regras/${id ?? 0}/${params}`);
  },
};

export default GrupoRegraTributacaoService;
