import React, { forwardRef, useState } from "react";
import { useImperativeHandle } from "react";
import { Row } from "reactstrap";
import { apiDelete } from "../api";
import { MODAL_ACTIONS } from "../coreUtils";
import FormButton from "./FormButton";
import { ModalBase } from "./ModalBase";
import { TableDelete } from "./TableDelete";

export const ModalExcluir = forwardRef(
  (
    {
      notifyEvent,
      route,
      selected,
      title,
      number,
      buttonPadded,
      table = false,
      message = "Tem certeza de que deseja excluir o registro selecionado?",
      buttonDivClassName,
      hideButton,
      canDelete = true,
      deleteHint,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    const handleSubmit = async () => {
      setLoading(true);
      if (await apiDelete(route)) {
        toggle();
        notifyEvent(MODAL_ACTIONS.DELETE);
      }
      setLoading(false);
    };

    useImperativeHandle(ref, () => ({
      toggle: () => toggle(),
    }));

    return (
      <React.Fragment>
        {table ? (
          <TableDelete onClick={() => toggle()} />
        ) : (
          !hideButton && (
            <FormButton
              color="danger"
              md="auto"
              onClick={toggle}
              disabled={[0, null, undefined].includes(selected) || !canDelete}
              padded={buttonPadded}
              divClassName={buttonDivClassName}
              id="bt-excluir"
              disabledHint={deleteHint}
            >
              Excluir
            </FormButton>
          )
        )}
        {isOpen && (
          <ModalBase
            isOpen={isOpen}
            size="md"
            title={`Exclusão de ${title}`}
            number={number}
            confirmButtonText="Excluir"
            onConfirm={handleSubmit}
            loadingConfirm={loading}
            toggle={toggle}
            autoFocus
          >
            <Row className="justify-content-center">
              <p style={{ textAlign: "center" }}>{message}</p>
            </Row>
          </ModalBase>
        )}
      </React.Fragment>
    );
  }
);
