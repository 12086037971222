import React from "react";
import { Card } from "reactstrap";
import { TableDelete } from "../../../../../../components/TableDelete";
import { Table } from "../../../../../../components";
import { naturalSort } from "../../../../../../coreUtils";

export const ProdutosRegraGrid = ({ data, handleDelete }) => {
  const columns = [
    {
      dataField: "id_produto",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_produto",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "ncm",
      text: "NCM",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "Ações",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  return (
    <Card body>
      <strong className="mt-2 mb-2">PRODUTOS ENQUADRADOS</strong>
      <Table data={data} columns={columns} pageSize={15} />
    </Card>
  );
};
