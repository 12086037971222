import "moment/locale/pt-br";
import React from "react";
import "react-day-picker/lib/style.css";
import { formatNumber } from "../../../../../coreUtils";

export const LinhaMovimentoPlanoConta = ({ level, row }) => {
  return (
    <>
      <li className={`${level ? "dre-row--item" : ""}  dre-row--last`}>
        <div
          className="dre-row"
          style={{ marginLeft: `${2 * level}%`, fontFamily: "Noto Serif" }}
        >
          <span style={{ minWidth: "35%" }} key={`mpc-${row.id}-fornec`}>
            {row.fornecedor}
          </span>
          <span
            style={{ minWidth: "7.5%", textAlign: "left" }}
            key={`pc-${row.id}-num-parc`}
          >
            Nº {row.numero}
          </span>
          <span
            style={{ minWidth: "7.5%", textAlign: "center" }}
            key={`pc-${row.id}-num-parc`}
          >
            Parc: {row.parcela}
          </span>
          <span
            style={{ minWidth: "15%", textAlign: "center" }}
            key={`pc-${row.id}-vcto`}
          >
            Vct: {row.vencimento}
          </span>
          <span
            style={{ minWidth: "15%", textAlign: "center" }}
            key={`pc-${row.id}-liq`}
          >
            Liq: {row.liquidacao}
          </span>
          <span
            style={{ minWidth: "10%", textAlign: "right" }}
            key={`mpc-${row.id}-vlr`}
          >
            {formatNumber(row.valor)}
          </span>
        </div>
      </li>
    </>
  );
};
