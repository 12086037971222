import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { BotaoImprimir } from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { docPrintReport } from "../../../../pdf";
import { apiGetV2 } from "../../../../apiV2";

export const CadastralCFOP = () => {
  const [loading, setLoading] = useState(false);

  const imprimir = async () => {
    setLoading(true);
    const [ok, ret] = await apiGetV2("/relatorios/cadastrais/cfop/");

    if (ok) {
      await docPrintReport(
        "/relatorios/cadastrais/cfop/",
        { dados: ret },
        "5036"
      );
    }
    setLoading(false);
  };

  return (
    <PageContainer title="Relatório Cadastral de CFOP" number="5036" canGoBack>
      <Card body>
        <Row>
          <BotaoImprimir padded={false} onClick={imprimir} loading={loading} />
        </Row>
      </Card>
    </PageContainer>
  );
};
