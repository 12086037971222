import React from "react";
import { FormCheckbox } from "../../../../components";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setCliObrigCnpj,
  setCliObrigCpf,
  setCliPermCuringa,
  setForObrigCpf,
  setForObrigCnpj,
  setCliPadRevendaMarcado,
} from "../store/cadastro_slice";

export const PersonCadastro = () => {
  const store = useSelector((state) => state.cadastro);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Obrigar Informação de CPF no Cadastro de Cliente"
          checked={store.cli_obrig_cpf}
          onChange={() => dispatch(setCliObrigCpf(!store.cli_obrig_cpf))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Obrigar Informação de CNPJ no Cadastro de Cliente"
          checked={store.cli_obrig_cnpj}
          onChange={() => dispatch(setCliObrigCnpj(!store.cli_obrig_cnpj))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Inclusão de Cliente Curinga"
          checked={store.cli_perm_curinga}
          onChange={() => dispatch(setCliPermCuringa(!store.cli_perm_curinga))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Campo de Revenda marcado por padrão no Cadastro de Cliente"
          checked={store.cli_pad_revenda_marcado}
          onChange={() =>
            dispatch(setCliPadRevendaMarcado(!store.cli_pad_revenda_marcado))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Obrigar Informação de CPF no Cadastro de Fornecedor"
          checked={store.for_obrig_cpf}
          onChange={() => dispatch(setForObrigCpf(!store.for_obrig_cpf))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Obrigar Informação de CNPJ no Cadastro de Fornecedor"
          checked={store.for_obrig_cnpj}
          onChange={() => dispatch(setForObrigCnpj(!store.for_obrig_cnpj))}
        />
      </Row>
    </>
  );
};
