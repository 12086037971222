import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/bancario/cobranca/remessa";

const RemessaBancariaService = {
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  buscarRemessa: async (id, impressao = false) =>
    await apiGetV2(`${URL_BASE}/buscar/${id}/?impressao=${impressao}`),
  gerarDadosRemessa: async (payload) =>
    await apiPostV2(`${URL_BASE}/gerar_dados/`, payload),
  listarTitulos: async (contaBanc, params) =>
    await apiGetV2(`${URL_BASE}/listar_titulos/${contaBanc}/`, params),
  reabrirRemessa: async (payload) =>
    await apiPutV2(`${URL_BASE}/reabrir_remessa/`, payload),
  reabrirTitulo: async (payload) =>
    await apiPutV2(`${URL_BASE}/reabrir_titulo_remessa/`, payload),
  buscarTitulosRemessa: async (id) =>
    await apiGetV2(`${URL_BASE}/buscar_titulos_remessa/${id}/`),
  naoEnviarTitRemessa: async (idTitulo) =>
    await apiPutV2(`${URL_BASE}/nao_enviar_tit_remessa/`, {
      id_titulo: idTitulo,
    }),
  salvarCopia: async (payload) =>
    await apiPostV2(`${URL_BASE}/salvar_copia/`, payload),
};

export default RemessaBancariaService;
