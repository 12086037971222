import { apiGet, apiPut, urlParams } from "../../api";

const URL_BASE = "/pedido_externo/central/liberacao_clientes";

const LiberacaoClientesService = {
  listar: async (somentePendentes, nome) => {
    const params = urlParams({
      somente_pendentes: somentePendentes,
      nome: nome,
    });

    return await apiGet(`${URL_BASE}/listar/${params}`);
  },
  liberar: async (idCliente) =>
    await apiPut(`${URL_BASE}/liberar/`, {
      id_cliente: idCliente,
    }),
};

export default LiberacaoClientesService;
