import React from "react";
import { Table, TableCheck } from "../../../../../components";
import { formatValueFromAPI } from "../../../../../coreUtils";

export const columnsVisitasNaoVenda = (exportacao) => [
  {
    dataField: "nome_vendedor",
    text: "Vendedor",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
  },
  { dataField: "nome_rota", text: "Rota", align: "left" },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  {
    dataField: "nome_fant_cliente",
    text: "Nome Fantasia",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "cidade_cliente",
    text: "Cidade",
    align: "left",
  },
  {
    dataField: "data_hora_vis",
    text: "Visitado em",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "descricao_motivo",
    text: "Motivo de Não-venda",
    align: "left",
  },
  {
    dataField: "ultima_venda",
    text: "Última Venda",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "houve_deslocamento",
    text: "Houve Deslocamento",
    align: "center",
    headerAlign: "center",
    formatter: (c) =>
      exportacao ? c ? "Sim" : "Não" : <TableCheck value={c} />,
  },
];

export const VisitasNaoVendaGrid = ({ dados }) => {
  return <Table data={dados} columns={columnsVisitasNaoVenda(false)} />;
};
