import React, { useState } from "react";
import { Card, Label, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  ComboBox,
  FormButton,
  FormCheckbox,
} from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";
import { exportDataToSheet } from "../../../../coreUtils";

const mostrarOptions = [
  { label: "Todos", value: null },
  { label: "Somente Ativos", value: "ATV" },
  { label: "Somente Inativos", value: "INA" },
];

const bloqueadosOptions = [
  { label: "Somente Bloqueados", value: "B" },
  { label: "Somente Não Bloqueados", value: "N" },
];

const cobDespFinancOptions = [
  { label: "Marcados para Cobrar", value: "S" },
  { label: "Marcados para Não Cobrar", value: "N" },
];

const ordenarOptions = [
  { label: "Nome", value: "NOC" },
  { label: "Código", value: "COD" },
  { label: "Cidade", value: "CID" },
  { label: "Tipo de Cliente", value: "TIP" },
  { label: "Plano Contratado", value: "CON" },
];

const availableFields = [
  {
    text: "Nome Fantasia",
    textImpr: "Nome Fant.",
    dataField: "nome_fant_cliente",
  },
  { text: "Telefone", dataField: "fone1" },
  { text: "Whatsapp", dataField: "whatsapp" },
  {
    text: "Endereço",
    dataField: "endereco",
    formatter: (c, row) =>
      [c, row.numero, row.complemento, row.bairro]
        .filter((e) => ![null, undefined].includes(e) && e.trim() !== "")
        .join(", "),
  },
  {
    text: "Cidade",
    dataField: "nome_cidade",
    formatter: (c) => c && c,
  },
  {
    text: "Tipo de Cliente",
    textImpr: "Tipo Cli.",
    dataField: "nome_tipo_cli",
    formatter: (c) => c && c,
  },
  {
    text: "Ramo de Atividade",
    textImpr: "Ramo Ativ.",
    dataField: "nome_ramo_atividade",
    formatter: (c) => c && c,
  },
  {
    text: "Inscrição Estadual",
    textImpr: "Insc. Est.",
    dataField: "insc_est",
    formatter: (c) => c && c,
  },
  {
    text: "Rede de Cliente",
    textImpr: "Rede Cli.",
    dataField: "nome_rede_cli",
    formatter: (c) => c && c,
  },
  {
    text: "CPF/CNPJ",
    dataField: "cpf_cnpj",
    formatter: (c) => c && c,
  },
];

export const CadastralCliente = () => {
  const [idTipoCli, setIdTipoCli] = useState(null);
  const [idCidade, setIdCidade] = useState(null);
  const [idRamoAtividade, setIdRamoAtividade] = useState(null);
  const [idRedeCli, setIdRedeCli] = useState(null);
  const [somenteBloqueados, setSomenteBloqueados] = useState(false);
  const [cobDespFinanc, setCobDespFinanc] = useState(null);
  const [mostrar, setMostrar] = useState(mostrarOptions[1].value);
  const [ordenar, setOrdenar] = useState(ordenarOptions[0].value);
  const [loading, setLoading] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);

  const buscarDados = async () => {
    const params = {
      id_cidade: idCidade,
      id_tipo_cli: idTipoCli,
      id_ramo_atividade: idRamoAtividade,
      id_rede_cli: idRedeCli,
      mostrar: mostrar,
      ordenar_por: ordenar,
      somente_bloqueados: somenteBloqueados === "B",
      cob_desp_financ: cobDespFinanc,
    };

    return await apiGetV2(
      `/relatorios/cadastrais/clientes/dados_gerais/`,
      params
    );
  };

  const imprimir = async () => {
    setLoading(true);

    const [ok, ret] = await buscarDados();

    if (ok) {
      const payload = {
        dados: ret.clientes,
        qtd_registros: ret.qtd_registros,
        selected_fields: availableFields.filter((e) =>
          selectedFields.includes(e.dataField)
        ),
        agrupar_por: ordenar,
      };

      await docPrintReport(
        "/relatorios/cadastrais/clientes_dados_gerais/",
        payload,
        "5035"
      );
    }

    setLoading(false);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      const columnsExportar = [
        { dataField: "id", text: "ID" },
        { dataField: "nome_cliente", text: "Nome" },
        ...availableFields.filter((e) => selectedFields.includes(e.dataField)),
      ];

      exportDataToSheet(ret.clientes, "Relatório Cadastral de Clientes", {
        columns: columnsExportar,
      });
    }
    setLoadingExportar(false);
  };

  const handleCheckField = (field) => {
    setSelectedFields(
      !selectedFields.includes(field)
        ? [...selectedFields, field]
        : selectedFields.filter((e) => e !== field)
    );
  };

  return (
    <PageContainer
      title="Relatório Cadastral de Cliente"
      number="5035"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            isConcatField
            concatModelName="tipo_cliente"
            defaultOptions
            label="Tipo de Cliente"
            md={3}
            onChange={(s) => setIdTipoCli(s?.value)}
            isSearchable
            isClearable
          />
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="cidade"
            label="Cidade"
            isSearchable
            isClearable
            onChange={(s) => setIdCidade(s?.value)}
          />
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="ramo_atividade"
            defaultOptions
            label="Ramo de Atividade"
            isSearchable
            isClearable
            onChange={(s) => setIdRamoAtividade(s?.value)}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="rede_cliente"
            defaultOptions
            label="Rede de Cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdRedeCli(s?.value)}
          />
          <ComboBox
            md={2}
            label="Mostrar"
            options={mostrarOptions}
            defaultValue={mostrarOptions[1].value}
            onChange={(s) => setMostrar(s?.value)}
          />
          <ComboBox
            md={2}
            label="Bloqueados"
            isClearable
            options={bloqueadosOptions}
            onChange={(s) => setSomenteBloqueados(s?.value)}
          />
          <ComboBox
            md={2}
            label="Taxas de Boleto"
            isClearable
            options={cobDespFinancOptions}
            onChange={(s) => setCobDespFinanc(s?.value)}
          />
          <ComboBox
            md={2}
            label="Ordenar Por"
            options={ordenarOptions}
            defaultValue={ordenarOptions[0].value}
            onChange={(s) => setOrdenar(s?.value)}
          />
        </Row>
        <Row className="mb-0">
          <Label md="auto" className="pb-0">
            Considerar as Colunas:{" "}
          </Label>
        </Row>
        <Row>
          {availableFields.map((e, index) => (
            <FormCheckbox
              name={`field-${index}`}
              label={e.text}
              checked={selectedFields.includes(e.dataField)}
              onChange={() => handleCheckField(e.dataField)}
              divClassName="pr-0"
              padded={false}
            />
          ))}
        </Row>
        <Row>
          <BotaoImprimir onClick={imprimir} loading={loading} />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportar}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
