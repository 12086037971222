import React, { useEffect, useState, useCallback } from "react";
import {
  AsyncComboBox,
  ComboBox,
  DatePicker,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  LinkButton,
  MaskedInput,
  NumberInput,
  PageContainer,
  TextInput,
} from "../../../../../components";
import PesqCliente from "../../../../../components/form/pesq_cliente/PesqCliente";
import { Card, Col, Row } from "reactstrap";
import { ClassifCli } from "../../../../../components/ClassifCli";
import moment from "moment";
import { MODAL_ACTIONS, formatDateISO } from "../../../../../coreUtils";
import OrdemServicoService from "../../../../../services/ordem_servico/OrdemServicoService";
import { Redirect, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { TrocarVeicCli } from "./components/TrocarVeicCliModal";
import { TrocarProprietarioVeicModal } from "./components/TrocarProprietarioVeicModal";
import { CarrosselFotosOS } from "./components/CarrosselFotosOS";
import CadastroLigacaoVeicCliService from "../../../../../services/cadastro/CadastroLigacaoVeicCliService";
import { AdiantamentoAberturaOSModal } from "./components/AdiantamentoAberturaOSModal";
import {
  centralOSRoute,
  historicoVeiculoRoute,
} from "../../../../../routes/modules/ordem_servico";
import BotaoPesq from "../../../../../components/form/BotaoPesq";
import { ligacaoVeicCliRoute } from "../../../../../routes/modules/cadastro";
import { MdOutlineDirectionsCar } from "react-icons/md";
import { apiGetV2 } from "../../../../../apiV2";

export const IncluirAlterarOS = ({ location }) => {
  const selected = location?.state?.selected;
  const action = location?.state?.action ?? MODAL_ACTIONS.ADD;

  // Controle
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  // Parâmetros
  const [tipoSist, setTipoSist] = useState("");
  const [identificaEquipamento, setIdentificaEquipamento] = useState(false);
  const [usaTaxaArmazenamento, setUsaTaxaArmazenamento] = useState(false);
  const [usaTaxaOrcamento, setUsaTaxaOrcamento] = useState(false);
  const [identificaDataHoraAgendamento, setIdentificaDataHoraAgendamento] =
    useState(false);
  const [identificaContatoServico, setIdentificaContatoServico] =
    useState(false);
  const [identificaTecnicoResp, setIdentificaTecnicoResp] = useState(false);
  const [imprEmis, setImprEmis] = useState("N");
  const sistAutomotivo = tipoSist === "MECANICA";
  // Dados do Cliente
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [cpfCnpjCliente, setCpfCnpjCliente] = useState("");
  const [enderecoCliente, setEnderecoCliente] = useState("");
  const [cidadeCliente, setCidadeCliente] = useState("");
  const [fone1Cliente, setFone1Cliente] = useState("");
  const [fone2Cliente, setFone2Cliente] = useState("");
  const [fone3Cliente, setFone3Cliente] = useState("");
  const [classifCliente, setClassifCliente] = useState(null);
  const [ultAtendCliente, setUltAtendCliente] = useState("");
  // Comum
  const [tipo, setTipo] = useState("SP");
  const [dataEmissao, setDataEmissao] = useState("");
  const [horaEmissao, setHoraEmissao] = useState("");
  const [problema, setProblema] = useState("");
  const [dataPre, setDataPre] = useState(null);
  const [horaPre, setHoraPre] = useState(null);
  const [observ, setObserv] = useState("");
  const [obsFinanc, setObsFinanc] = useState("");
  const [fotos, setFotos] = useState([]);
  // Adiantamento
  const [formaPagAdiant, setFormaPagAdiant] = useState(null);
  const [idCondPagAdiant, setIdCondPagAdiant] = useState(null);
  const [valorAdiant, setValorAdiant] = useState(0);
  // Mec
  const [km, setKm] = useState(null);
  const [placa, setPlaca] = useState("");
  const [deveBuscarClientePlaca, setDeveBuscarClientePlaca] = useState(false);
  const [idVeiculo, setIdVeiculo] = useState(null);
  const [idLigacaoVeic, setIdLigacaoVeic] = useState(null);
  const [veiculosCliente, setVeiculosCliente] = useState([]);
  const [modeloVeic, setModeloVeic] = useState("");
  const [nomeVeiculo, setNomeVeiculo] = useState("");
  const [anoVeic, setAnoVeic] = useState("");
  const [combustivelVeic, setCombustivelVeic] = useState("");
  const [corVeic, setCorVeic] = useState("");
  const [chassiVeic, setChassiVeic] = useState("");
  const [somenteCaixa, setSomenteCaixa] = useState(false);
  // Mad
  const [idEquipamento, setIdEquipamento] = useState(null);
  const [marcaEquipamento, setMarcaEquipamento] = useState("");
  const [capacidadeEquipamento, setCapacidadeEquipamento] = useState("");
  const [vlrTaxaOrcamento, setVlrTaxaOrcamento] = useState(0);
  const [vlrTaxaArmazena, setVlrTaxaArmazena] = useState(0);
  // Web
  const [contatoServ, setContatoServ] = useState("");
  const [idTecnico, setIdTecnico] = useState(null);
  const [dataAgendada, setDataAgendada] = useState(null);
  const [horaAgendada, setHoraAgendada] = useState(null);

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idCliente)) {
      toastr.warning("Atenção", "Por favor, informe o Cliente");
      return false;
    }

    if (problema === "") {
      toastr.warning("Atenção", "Por favor, informe o Problema/Defeito");
      return false;
    }

    setLoadingSubmit(true);
    let submitFunc;
    let payload;
    if (action === MODAL_ACTIONS.ADD) {
      payload = {
        tipo: tipo,
        data_emi: formatDateISO(new Date()),
        hora_emi: moment(new Date()).format("HH:mm"),
        data_pre: formatDateISO(dataPre),
        hora_pre: horaPre,
        placa: placa,
        id_cliente: idCliente,
        nome_cliente: nomeCliente,
        km: km ? String(km) : "",
        id_colaborador: parseInt(localStorage.getItem("id_colaborador")),
        id_tecnico: idTecnico,
        id_equipamento: idEquipamento,
        defeito1: problema,
        contato_serv: contatoServ,
        data_agendada: identificaDataHoraAgendamento
          ? formatDateISO(dataAgendada)
          : null,
        hora_agendada: identificaDataHoraAgendamento ? horaAgendada : null,
        obs_emi1: observ,
        obs_financ: obsFinanc,
        origem: "MAN",
        id_origem: 0,
        vlr_taxa_orcamento: vlrTaxaOrcamento,
        vlr_taxa_armazena: vlrTaxaArmazena,
        importacao: false,
        status_importacao: null,
        data_fec_importacao: null,
        hora_fec_importacao: null,
        fotos: fotos,
        somente_caixa: somenteCaixa,
        forma_pag_adiant: formaPagAdiant,
        id_cond_pag_adiant: idCondPagAdiant || null,
        valor_adiant: valorAdiant || 0,
      };

      submitFunc = OrdemServicoService.incluir;
    } else {
      payload = {
        id: selected,
        id_equipamento: idEquipamento,
        id_tecnico: idTecnico,
        contato_serv: contatoServ,
        km: km ? String(km) : "",
        chassi: chassiVeic,
        modelo: modeloVeic,
        ano: String(anoVeic),
        defeito: problema,
        observ: observ,
        obs_financ: obsFinanc,
        data_pre: dataPre ? moment(dataPre).format("YYYY-MM-DD") : dataPre,
        hora_pre: horaPre,
        somente_caixa: somenteCaixa,
      };
      submitFunc = OrdemServicoService.alterar;
    }

    const [ok, ret] = await submitFunc(payload);

    if (ok) {
      if (action === MODAL_ACTIONS.ADD) {
        if (["S", "P", "V"].includes(imprEmis)) {
          await OrdemServicoService.imprimirAbertura(ret.id);
        }
        setRedirect(true);
      } else {
        history.goBack();
      }
    }
    setLoadingSubmit(false);
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/central_os/");
    if (ok) {
      setIdentificaEquipamento(ret.abrir_os_identifica_equipamento);
      setUsaTaxaArmazenamento(ret.abrir_os_usa_taxa_armazenamento);
      setUsaTaxaOrcamento(ret.abrir_os_usa_taxa_orcamento);
      setIdentificaDataHoraAgendamento(
        ret.abrir_os_identifica_data_hora_agendamento
      );
      setTipoSist(ret.tipo_sist);
      setIdentificaContatoServico(ret.abrir_os_identifica_contato_servico);
      setIdentificaTecnicoResp(ret.abrir_os_identifica_tecnico_resp);
      setImprEmis(ret.impr_emis_os);
      setTipo(ret.tipo_sist === "MECANICA" ? "CP" : "SP");
    }
  };

  const carregarDados = async () => {
    const [ok, ret] = await OrdemServicoService.buscar(selected);

    if (ok) {
      const os = ret.os;
      setPlaca(os.placa);
      setKm(os.km);
      setIdCliente(os.id_cliente);
      setNomeCliente(os.nome_cliente);
      setCpfCnpjCliente(os.cpf_cnpj_cliente);
      setEnderecoCliente(os.endereco_cliente);
      setCidadeCliente(os.cidade_cliente);
      setClassifCliente(os.classif_cliente);
      setUltAtendCliente(os.data_ultima_compra);
      setFone1Cliente(os.fone1_cliente);
      setFone2Cliente(os.fone2_cliente);
      setFone3Cliente(os.fone3_cliente);
      // Veículo
      setModeloVeic(os.modelo_veic ?? "");
      setIdVeiculo(os.id_veiculo);
      setNomeVeiculo(os.veiculo);
      setAnoVeic(os.ano_veic ?? "");
      setCombustivelVeic(os.combustivel_veic ?? "");
      setCorVeic(os.cor_veic);
      setChassiVeic(os.chassi_veic ?? "");
      // Equipamento
      setIdEquipamento(os.id_equipamento);
      setMarcaEquipamento(os.marca_equipamento);
      setCapacidadeEquipamento(os.capacidade_equipamento);
      // OS
      setIdTecnico(os.id_tecnico);
      setContatoServ(os.contato_serv);
      setProblema(os.defeito);
      setObserv(os.observ);
      setObsFinanc(os.obs_financ ?? "");
      setDataEmissao(os.data_emissao);
      setHoraEmissao(os.hora_emissao);
      setDataPre(
        os.data_prometido
          ? moment(os.data_prometido, "DD/MM/YYYY").toDate()
          : null
      );
      setHoraPre(os.hora_prometido);
      setDataAgendada(
        os.data_agend ? moment(os.data_agend, "DD/MM/YYYY").toDate() : null
      );
      setHoraAgendada(os.hora_agend);
      setVlrTaxaOrcamento(os.vlr_taxa_orcamento);
      setVlrTaxaArmazena(os.vlr_taxa_armazena);
      setSomenteCaixa(os.somente_caixa);

      await refreshFotos();
    }
  };

  const iniciarTela = async () => {
    setLoading(true);
    await carregarParametros();

    if (action === MODAL_ACTIONS.EDIT) {
      await carregarDados();
    }
    setLoading(false);
  };

  useEffect(() => {
    iniciarTela();
  }, []);

  const buscarClientePelaPlaca = async (v) => {
    if (!["", null, undefined].includes(v) && deveBuscarClientePlaca) {
      const [ok, ret] = await CadastroLigacaoVeicCliService.buscarClientePlaca(
        placa
      );

      if (ok) {
        setIdCliente(ret.id_cliente);
        setNomeCliente(ret.nome_cliente);
        setCpfCnpjCliente(ret.cpf_cnpj_cliente);
        setEnderecoCliente(ret.endereco_cliente);
        setCidadeCliente(ret.cidade_cliente);
        setClassifCliente(ret.classif_cliente);
        setUltAtendCliente(ret.ult_compra_cliente);
        setFone1Cliente(ret.fone1_cliente);
        setFone2Cliente(ret.fone2_cliente);
        setFone3Cliente(ret.fone3_cliente);
        setModeloVeic(ret.modelo_veiculo);
        setIdVeiculo(ret.id_veiculo);
        setIdLigacaoVeic(ret.id_ligacao);
        setAnoVeic(ret.ano_veiculo);
        setCombustivelVeic(ret.combustivel_veiculo);
        setCorVeic(ret.cor_veiculo);
        setChassiVeic(ret.chassi_veiculo);
      } else {
        setIdCliente(null);
        setNomeCliente("");
        setCpfCnpjCliente("");
        setEnderecoCliente("");
        setCidadeCliente("");
        setClassifCliente("");
        setUltAtendCliente("");
        setFone1Cliente("");
        setFone2Cliente("");
        setFone3Cliente("");
        setIdVeiculo(null);
        setIdLigacaoVeic(null);
        setModeloVeic("");
        setAnoVeic("");
        setCombustivelVeic("");
        setCorVeic("");
        setChassiVeic("");
      }
      setDeveBuscarClientePlaca(false);
    }
  };

  const buscarVeiculosCliente = useCallback(async () => {
    if (idCliente) {
      const [ok1, ret1] =
        await CadastroLigacaoVeicCliService.buscarVeiculosCliente(idCliente);

      const veiculos = (ok1 ? ret1 : []).map((veic) => ({
        label: veic.nome,
        value: veic.id,
        id_veiculo: veic.id_veiculo,
        placa: veic.placa,
        modelo: veic.modelo,
        cor: veic.cor,
        ano: veic.ano,
        combustivel: veic.combustivel,
        chassi: veic.chassi,
      }));
      setVeiculosCliente(veiculos);
      if (veiculos.length > 0) {
        if (placa !== "") {
          const veicPlaca = veiculos.find((e) => e.placa === placa);
          if (veicPlaca) {
            setIdVeiculo(veicPlaca.id_veiculo);
            setIdLigacaoVeic(veicPlaca.value);
            setNomeVeiculo(veicPlaca.label);
          }
        } else {
          setIdVeiculo(veiculos[0].id_veiculo);
          setIdLigacaoVeic(veiculos[0].value);
          setNomeVeiculo(veiculos[0].label);
        }
      }
      const [ok2, ret2] = await apiGetV2(
        `cadastro/cliente/buscar_resumo/${idCliente}/`,
        {}
      );
      setCpfCnpjCliente(ret2.cpf_cnpj);
      setEnderecoCliente(ret2.endereco_cliente);
      setCidadeCliente(ret2.cidade_cliente);
      setClassifCliente(ret2.classif_cli);
      setUltAtendCliente(ret2.data_ultima_compra);
      setFone1Cliente(ret2.fone1);
      setFone2Cliente(ret2.fone2);
      setFone3Cliente(ret2.fone3);
    } else {
      setVeiculosCliente([]);
    }
  }, [idCliente]);

  const refreshFotos = async () => {
    const ret = await OrdemServicoService.buscarFotos(selected);

    if (ret) {
      setFotos(
        ret.map((e) => ({ id: e.id, base64: e.base64_foto, observ: e.obs }))
      );
    }
  };

  const addFotoEdit = async (data) => {
    const payload = { id_os: selected, ...data };
    return await OrdemServicoService.incluirFoto(payload);
  };

  const deleteFotoEdit = async (idFoto) =>
    await OrdemServicoService.excluirFoto(idFoto);

  const addFoto = async (data) => {
    if (action === MODAL_ACTIONS.EDIT) {
      if (await addFotoEdit(data)) {
        refreshFotos();
      }
    } else {
      setFotos([...fotos, data]);
    }
  };

  const deleteFoto = async (i) => {
    if (action === MODAL_ACTIONS.EDIT) {
      if (await deleteFotoEdit(fotos[i].id)) {
        refreshFotos();
      }
    } else {
      setFotos(fotos.filter((e, index) => index !== i));
    }
  };

  const notifyTrocarProprietario = () => {
    buscarClientePelaPlaca(placa);
  };

  useEffect(() => {
    buscarVeiculosCliente();
  }, [buscarVeiculosCliente]);

  useEffect(() => {
    if (!idVeiculo) {
      setNomeVeiculo("");
      setModeloVeic("");
      setAnoVeic("");
      setCombustivelVeic("");
      setCorVeic("");
      setChassiVeic("");
    }
  }, [idVeiculo]);

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9") handleSubmit();
    }
  };

  const handleSetVeiculo = (s) => {
    if (tipo === "SP") return;
    setIdLigacaoVeic(s?.value);
    setIdVeiculo(s?.id_veiculo);
    setNomeVeiculo(s?.label);
    setPlaca(s?.placa);
    setModeloVeic(s?.modelo);
    setAnoVeic(s?.ano);
    setCombustivelVeic(s?.combustivel);
    setCorVeic(s?.cor);
    setChassiVeic(s?.chassi);
  };

  const handleSetTipo = (v) => {
    setTipo(v);

    if (v === "SP") {
      setIdLigacaoVeic(null);
      setIdVeiculo(null);
      setPlaca("");
      setModeloVeic("");
      setNomeVeiculo("");
      setAnoVeic("");
      setCombustivelVeic("");
      setCorVeic("");
      setChassiVeic("");
      setKm(null);
    }
  };

  const handleSetSomenteCaixa = () => {
    setSomenteCaixa(!somenteCaixa);
    if (!somenteCaixa) {
      handleSetTipo("SP");
    } else if (sistAutomotivo) {
      handleSetTipo("CP");
    }
  };

  const handleSetEquipamento = (id, s) => {
    setIdEquipamento(id ?? null);
    setMarcaEquipamento(s?.marca ?? "");
    setCapacidadeEquipamento(s?.capacidade ?? "");
  };

  const abrirLigacaoVeicCli = () => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(ligacaoVeicCliRoute.path);

    cadastro.window.parameters = JSON.stringify({
      id: id,
      id_cliente: idCliente,
      nome: nomeCliente,
    });

    const func = (event) => {
      if (event.origin !== window.location.origin && !event.data?.id_cliente) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      if (event.data.id_cliente !== idCliente) {
        return;
      }

      buscarVeiculosCliente();

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  return redirect ? (
    <Redirect
      to={{
        pathname: centralOSRoute.path,
        state: { refresh: true },
      }}
    />
  ) : (
    <PageContainer
      title={
        action === MODAL_ACTIONS.ADD
          ? "Inclusão de Ordem de Serviço"
          : `Alteração da Ordem de Serviço Nº ${selected}`
      }
      number="0006_1"
      canGoBack
      loading={loading}
      onKeyDown={onKeyDown}
    >
      <Card body>
        <Row className="mr-0">
          <Col>
            <Row>
              {sistAutomotivo && (
                <MaskedInput
                  md={2}
                  mask="aaa-9*99"
                  label="Placa"
                  value={placa}
                  onChange={(e, v) => {
                    setDeveBuscarClientePlaca(true);
                    setPlaca(v);
                  }}
                  onBlur={buscarClientePelaPlaca}
                  disabled={action === MODAL_ACTIONS.EDIT || tipo === "SP"}
                  maskChar={null}
                  autoFocus={sistAutomotivo}
                />
              )}
              <PesqCliente
                md={8}
                onConfirm={(id, nome) => {
                  if (id !== idCliente) {
                    setIdVeiculo(null);
                    setIdLigacaoVeic(null);
                  }
                  if (action === MODAL_ACTIONS.ADD && idCliente !== id) {
                    setPlaca("");
                  }
                  setIdCliente(id);
                  setNomeCliente(nome);
                }}
                selectNextField={() => {}}
                onChangeDescricao={setNomeCliente}
                idCliente={idCliente}
                nomeCliente={nomeCliente}
                disabled={action === MODAL_ACTIONS.EDIT}
                autoFocus={!sistAutomotivo}
                sistAutomotivo={sistAutomotivo}
                showShortcut={action === MODAL_ACTIONS.ADD}
              />
              {action === MODAL_ACTIONS.EDIT && (
                <>
                  <DatePicker
                    label="Emissão"
                    md={3}
                    value={`${dataEmissao} - ${horaEmissao}`}
                    disabled
                  />
                </>
              )}
              {identificaDataHoraAgendamento && (
                <>
                  <DatePicker
                    label="Agendado Para"
                    md={2}
                    value={dataAgendada}
                    onChange={(v) =>
                      setDataAgendada(moment.isMoment(v) ? v.toDate() : v)
                    }
                  />
                  <TextInput
                    type="time"
                    label="Hora"
                    md={2}
                    value={horaAgendada}
                    onChange={(e, v) => setHoraAgendada(v)}
                  />
                </>
              )}
            </Row>
            <Row>
              <TextInput
                md={8}
                label="Endereço"
                value={enderecoCliente}
                disabled
              />
              <TextInput md={4} label="Cidade" value={cidadeCliente} disabled />
            </Row>
            <Row>
              <TextInput
                md={2}
                label="CPF/CNPJ"
                value={cpfCnpjCliente}
                disabled
              />
              <TextInput md={2} label="Fone 1" value={fone1Cliente} disabled />
              <TextInput md={2} label="Fone 2" value={fone2Cliente} disabled />
              <TextInput md={2} label="Fone 3" value={fone3Cliente} disabled />
              <ClassifCli value={classifCliente ?? 0} />
              <TextInput
                md={2}
                label="Últ. Compra/Atend."
                value={ultAtendCliente}
                disabled
              />
            </Row>
            {sistAutomotivo && (
              <>
                <Row>
                  {action === MODAL_ACTIONS.ADD && tipo === "CP" ? (
                    <ComboBox
                      md={4}
                      label="Veículo"
                      defaultValue={idLigacaoVeic}
                      options={veiculosCliente}
                      onChange={handleSetVeiculo}
                      isDisabled={
                        action === MODAL_ACTIONS.EDIT || tipo === "SP"
                      }
                      noOptionsMessage={() => "Selecione o cliente"}
                      additionalButton={
                        <BotaoPesq
                          onClick={abrirLigacaoVeicCli}
                          icon={MdOutlineDirectionsCar}
                          disabled={[0, null, undefined].includes(idCliente)}
                          disabledHint="Selecione um Cliente"
                          hint="Acesso ao Cadastro de Veículos do Cliente"
                        />
                      }
                    />
                  ) : (
                    <TextInput
                      md={4}
                      label="Veículo"
                      value={nomeVeiculo}
                      disabled
                    />
                  )}
                  <TextInput
                    md={4}
                    label="Modelo"
                    value={modeloVeic}
                    disabled
                  />
                  <IntegerFormInput
                    md={2}
                    label="KM"
                    defaultValue={km}
                    onChange={setKm}
                    disabled={tipo === "SP"}
                  />
                </Row>
              </>
            )}
            <Row>
              {identificaContatoServico && (
                <TextInput
                  md={4}
                  label="Contato"
                  value={contatoServ}
                  onChange={(e, v) => setContatoServ(v)}
                  maxLength={80}
                  forceUppercase={false}
                />
              )}
              {identificaTecnicoResp && (
                <AsyncComboBox
                  md={4}
                  label="Técnico Responsável"
                  isConcatField
                  concatModelName="tecnico"
                  isSearchable
                  defaultValue={idTecnico}
                  onChange={(s) => setIdTecnico(s?.value)}
                  defaultOptions={action === MODAL_ACTIONS.EDIT}
                />
              )}
            </Row>
            {identificaEquipamento && (
              <Row>
                <AsyncComboBox
                  md={4}
                  label="Equipamento"
                  isConcatField
                  concatModelName="equipamento"
                  isSearchable
                  defaultValue={idEquipamento}
                  onChange={(s) => handleSetEquipamento(s?.value, s)}
                />
                <TextInput
                  md={3}
                  label="Marca"
                  value={marcaEquipamento}
                  disabled
                />
                <TextInput
                  md={3}
                  label="Capacidade"
                  value={capacidadeEquipamento}
                  disabled
                />
              </Row>
            )}
            <Row>
              {usaTaxaArmazenamento && (
                <NumberInput
                  label="Taxa de Armazenamento"
                  md={2}
                  onChange={setVlrTaxaArmazena}
                  value={vlrTaxaArmazena}
                />
              )}
              {usaTaxaOrcamento && (
                <NumberInput
                  label="Taxa de Orçamento"
                  md={2}
                  onChange={setVlrTaxaOrcamento}
                  value={vlrTaxaOrcamento}
                />
              )}
            </Row>
          </Col>
          <CarrosselFotosOS
            fotos={fotos}
            onAdd={addFoto}
            onDelete={deleteFoto}
          />
        </Row>
        <Row>
          {sistAutomotivo && (
            <>
              <TextInput md={2} label="Cor" value={corVeic} disabled />
              <TextInput md={2} label="Ano" value={anoVeic} disabled />
              <TextInput md={3} label="Chassi" value={chassiVeic} disabled />
              <TextInput
                md={2}
                label="Combustível"
                value={combustivelVeic}
                disabled
              />
              <TrocarProprietarioVeicModal
                idOS={selected}
                placa={placa}
                notifyEvent={notifyTrocarProprietario}
                setDeveBuscarClientePlaca={setDeveBuscarClientePlaca}
                padded
                showButtonText
              />
            </>
          )}
          {action === MODAL_ACTIONS.EDIT && (
            <TrocarVeicCli
              idOS={selected}
              idCliente={idCliente}
              notifyEvent={carregarDados}
              mecanica={sistAutomotivo}
            />
          )}
          {sistAutomotivo && (
            <LinkButton
              md="auto"
              color="primary"
              pathname={historicoVeiculoRoute.path}
              state={{ placa: placa }}
              divClassName="pr-0"
              disabled={(placa ?? "").length < 8}
              disabledHint="Informe a placa do veículo"
            >
              Histórico do Veículo
            </LinkButton>
          )}
        </Row>
      </Card>
      <Card body>
        <Row>
          <TextInput
            type="textarea"
            label="Problema/Defeito"
            value={problema}
            onChange={(e, v) => setProblema(v)}
            rows={3}
            forceUppercase={false}
          />
        </Row>
        <Row>
          <TextInput
            type="textarea"
            label="Observação"
            value={observ}
            onChange={(e, v) => setObserv(v)}
            rows={3}
            forceUppercase={false}
          />
        </Row>
        <Row>
          <TextInput
            type="textarea"
            label="Observação Financeira - Será destacada no boleto e notas fiscais"
            value={obsFinanc}
            onChange={(e, v) => setObsFinanc(v)}
            rows={1}
            forceUppercase={false}
          />
        </Row>
        <Row>
          <DatePicker
            label="Previsão de Entrega"
            md={2}
            value={dataPre}
            onChange={(v) => setDataPre(moment.isMoment(v) ? v.toDate() : v)}
            calendarPlacement="top"
          />
          <TextInput
            type="time"
            label="Hora"
            md={2}
            value={horaPre}
            onChange={(e, v) => setHoraPre(v)}
          />
          {sistAutomotivo && (
            <FormCheckbox
              label="Não Informar Veículo"
              name="somente-caixa"
              checked={somenteCaixa}
              defaultChecked={somenteCaixa}
              onChange={handleSetSomenteCaixa}
            />
          )}
          {action !== MODAL_ACTIONS.EDIT && (
            <AdiantamentoAberturaOSModal
              setFormaPagAdiant={setFormaPagAdiant}
              formaPagAdiant={formaPagAdiant}
              setIdCondPagAdiant={setIdCondPagAdiant}
              idCondPagAdiant={idCondPagAdiant}
              setValorAdiant={setValorAdiant}
              valorAdiant={valorAdiant}
            />
          )}
          <FormButton
            divClassName="ml-auto"
            md="auto"
            color="success"
            onClick={handleSubmit}
            loading={loadingSubmit}
          >
            F9 -{" "}
            {action === MODAL_ACTIONS.ADD
              ? "Abrir O.S."
              : "Confirmar Alteração"}
          </FormButton>
          <FormButton md="auto" color="danger" onClick={() => history.goBack()}>
            Esc - Voltar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
