import React from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormCheckbox,
  IntegerFormInput,
  TextInput,
} from "../../../../components";
import { RadioGroup, RadioItem } from "../../../../components/RadioGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  setTokenCsc,
  setIdTokenCsc,
  setCaminSalvarXml,
  setNumeroUltNfce,
  setLoteUltNfce,
  setTipoImpressao,
  setFormaImpressao,
  setNomeImpressora,
  setIdTipoDocPadrao,
  setIdClientePadrao,
  setMostrarDescontoNfce,
  setDestacarValoresRepasseIcmsSt,
} from "../store/nf_eletronica_consumidor_slice";

export const PersonNfEletronicaConsumidor = () => {
  const store = useSelector((state) => state.nf_eletronica_consumidor);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <TextInput
          md={6}
          label="Token NFC-e (CSC)"
          value={store.token_csc}
          onChange={(e, v) => dispatch(setTokenCsc(v))}
          maxLength={40}
        />
        <IntegerFormInput
          md={2}
          label="ID Token CSC"
          defaultValue={store.id_token_csc}
          onChange={(v) => dispatch(setIdTokenCsc(v))}
        />
      </Row>
      <Row>
        <IntegerFormInput
          md={4}
          label="Número da Última NFC-e Emitida"
          defaultValue={store.numero_ult_nfce}
          onChange={(v) => dispatch(setNumeroUltNfce(v))}
          mdInput={5}
        />
        <IntegerFormInput
          md={4}
          label="Lote da Última NFC-e Emitida"
          defaultValue={store.lote_ult_nfce}
          onChange={(v) => dispatch(setLoteUltNfce(v))}
          mdInput={5}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Tipo de Documento Padrão"
          isSearchable
          isClearable
          isConcatField
          concatModelName="tipo_documento"
          onChange={(s) => dispatch(setIdTipoDocPadrao(s?.value ?? null))}
          defaultValue={store.id_tipo_doc_padrao}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Cliente Padrão"
          isSearchable
          isClearable
          isConcatField
          concatModelName="cliente"
          onChange={(s) => dispatch(setIdClientePadrao(s?.value ?? null))}
          defaultValue={store.id_cliente_padrao}
        />
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Salvar XML da NFC-e Em:"
          value={store.camin_salvar_xml}
          onChange={(e, v) => dispatch(setCaminSalvarXml(v))}
          maxLength={255}
          forceUppercase={false}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Tipo Impressao"
          value={store.tipo_impressao}
          onChange={(v) => dispatch(setTipoImpressao(v))}
        >
          <RadioItem label="Mini Impressora (Térmica)" value="T" />
          <RadioItem label="Impressora Normal (Folha A4)" value="A" />
        </RadioGroup>
      </Row>
      <Row>
        <RadioGroup
          label="Forma Impressao"
          value={store.forma_impressao}
          onChange={(v) => dispatch(setFormaImpressao(v))}
        >
          <RadioItem label="Sempre Imprimir" value="IMP" />
          <RadioItem label="Visualizar a NFC-e" value="VIS" />
          <RadioItem label="Perguntar se Deseja Imprmir" value="PER" />
          <RadioItem label="Não Imprimir" value="NAO" />
        </RadioGroup>
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Nome da Impressora"
          value={store.nome_impressora}
          onChange={(e, v) => dispatch(setNomeImpressora(v))}
          maxLength={30}
          forceUppercase={false}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Mostrar Desconto na Nota"
          checked={store.mostrar_desconto_nfce}
          onChange={() =>
            dispatch(setMostrarDescontoNfce(!store.mostrar_desconto_nfce))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Destacar Valores de Repasse de ICMS ST"
          checked={store.destacar_valores_repasse_icms_st}
          onChange={() =>
            dispatch(
              setDestacarValoresRepasseIcmsSt(
                !store.destacar_valores_repasse_icms_st
              )
            )
          }
        />
      </Row>
    </>
  );
};
