import classNames from "classnames";
import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Spinner, UncontrolledTooltip } from "reactstrap";

const BotaoPesq = ({
  onClick,
  loading,
  disabled,
  disabledHint,
  icon: Icon = AiOutlineSearch,
  iconSize = 20,
  hint,
  style = {},
  secondary = false,
}) => {
  const [internalId] = useState(
    "pesqprod-" + Math.floor(Math.random() * Date.now())
  );

  return (
    <>
      <div
        className={classNames(
          secondary && "react-select-shortcut-secondary",
          !secondary && "react-select-shortcut",
          disabled && "disabled"
        )}
        style={{
          padding: "0.3rem",
          width: "30px",
          minWidth: "30px",
          minHeight: "29.5938px",
          display: "flex",
          justifyContent: "center",
          ...style,
        }}
        onClick={!disabled ? onClick : () => {}}
        id={internalId}
      >
        {loading ? (
          <Spinner size="sm" color="light" />
        ) : (
          <Icon size={iconSize} color="white" />
        )}
      </div>
      {!disabled && !loading && hint && (
        <UncontrolledTooltip target={internalId}>{hint}</UncontrolledTooltip>
      )}
      {disabled && !loading && disabledHint && (
        <UncontrolledTooltip target={internalId}>
          {disabledHint}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default BotaoPesq;
