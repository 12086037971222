import { createSlice } from "@reduxjs/toolkit";

export const FORMAS_PARCELAMENTO_TEF = Object.freeze({
  PARCELADO_LOJISTA: "PARC_LOJISTA",
  PARCELADO_ADMINISTRADORA: "PARC_ADMINISTRADORA",
});

const initialState = {
  id_meio_pagto_pad_tef: null,
  integradora: "PAYER",
  id_conta_banc_receb_pix: null,
  pix_tef_habilitado: false,
  forma_parcelamento: FORMAS_PARCELAMENTO_TEF.PARCELADO_LOJISTA,
};

const fieldsToNull = ["id_meio_pagto_pad_tef", "id_conta_banc_receb_pix"];

export const tefSlice = createSlice({
  name: "tef_slice",
  initialState: initialState,
  reducers: {
    setupTef: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value === 0 && fieldsToNull.includes(key)) {
          value = null;
        }

        if (key === "integradora" && value === "") {
          value = "NAO";
        }

        state[key] = value;
      });
      return state;
    },
    setIdMeioPagtoPadTef: (state, action) => {
      state.id_meio_pagto_pad_tef = action.payload;
    },
    setIntegradora: (state, action) => {
      state.integradora = action.payload;
    },
    setIdContaBancRecebPix: (state, action) => {
      state.id_conta_banc_receb_pix = action.payload;
    },
    setPixTefHabilitado: (state, action) => {
      state.pix_tef_habilitado = action.payload;
    },
    setFormaParcelamento: (state, action) => {
      state.forma_parcelamento = action.payload;
    },
  },
});

export const {
  setupTef,
  setIdMeioPagtoPadTef,
  setIntegradora,
  setIdContaBancRecebPix,
  setPixTefHabilitado,
  setFormaParcelamento,
} = tefSlice.actions;

export default tefSlice.reducer;
