import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  id_venda: null,
  id_cliente: null,
  nome_cliente: "",
  cliente_curinga: false,
  cpf_cnpj_cliente: "",
  vlr_frete: 0,
  observ: "",
  id_orcamento: null,
  venda_bonificada: false,
  forma_pagto: null,
  id_cond_pag: null,
  vlr_tot_prod_venda: 0,
  vlr_tot_serv_venda: 0,
  vlr_tot_desc_venda: 0,
  vlr_tot_comis_venda: 0,
  vlr_tot_venda: 0,
  id_premiado: null,
  pontos_premiacao: 0,
  whatsapp_cliente: null,
  nome_local_entrega: "",
  endereco_entrega: "",
  nro_endereco_entrega: "",
  bairro_entrega: "",
  complemento_entrega: "",
  id_cidade_entrega: null,
  nome_cidade_entrega: "",
  data_entrega: null,
};

export const vendaCabSlice = createSlice({
  name: "venda_cab_slice",
  initialState: initialState,
  reducers: {
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setIdVenda: (state, action) => {
      state.id_venda = action.payload;
    },
    setIdCliente: (state, action) => {
      state.id_cliente = action.payload;
    },
    setNomeCliente: (state, action) => {
      state.nome_cliente = action.payload;
    },
    setClienteCuringa: (state, action) => {
      state.cliente_curinga = action.payload;
    },
    setCpfCnpjCliente: (state, action) => {
      state.cpf_cnpj_cliente = action.payload;
    },
    setVlrFrete: (state, action) => {
      state.vlr_frete = action.payload;
    },
    setObserv: (state, action) => {
      state.observ = action.payload;
    },
    setIdOrcamento: (state, action) => {
      state.id_orcamento = action.payload;
    },
    setVendaBonificada: (state, action) => {
      state.venda_bonificada = action.payload;
    },
    setFormaPagto: (state, action) => {
      state.forma_pagto = action.payload;
    },
    setIdCondPag: (state, action) => {
      state.id_cond_pag = action.payload;
    },
    setVlrTotProdVenda: (state, action) => {
      state.vlr_tot_prod_venda = action.payload;
    },
    setVlrTotServVenda: (state, action) => {
      state.vlr_tot_serv_venda = action.payload;
    },
    setVlrTotDescVenda: (state, action) => {
      state.vlr_tot_desc_venda = action.payload;
    },
    setVlrTotComisVenda: (state, action) => {
      state.vlr_tot_comis_venda = action.payload;
    },
    setVlrTotVenda: (state, action) => {
      state.vlr_tot_venda = action.payload;
    },
    setIdPremiado: (state, action) => {
      state.id_premiado = action.payload;
    },
    setPontosPremiacao: (state, action) => {
      state.pontos_premiacao = action.payload;
    },
    setWhatsappCliente: (state, action) => {
      state.whatsapp_cliente = action.payload;
    },
    setEnderecoEntrega: (state, action) => {
      state.endereco_entrega = action.payload;
    },
    setNomeLocalEntrega: (state, action) => {
      state.nome_local_entrega = action.payload;
    },
    setNroEnderecoEntrega: (state, action) => {
      state.nro_endereco_entrega = action.payload;
    },
    setBairroEntrega: (state, action) => {
      state.bairro_entrega = action.payload;
    },
    setComplementoEntrega: (state, action) => {
      state.complemento_entrega = action.payload;
    },
    setIdCidadeEntrega: (state, action) => {
      state.id_cidade_entrega = action.payload;
    },
    setNomeCidadeEntrega: (state, action) => {
      state.nome_cidade_entrega = action.payload;
    },
    setDataEntrega: (state, action) => {
      state.data_entrega = action.payload;
    },
  },
});
export const {
  setup,
  setIdVenda,
  setIdCliente,
  setNomeCliente,
  setClienteCuringa,
  setCpfCnpjCliente,
  setVlrFrete,
  setObserv,
  setIdOrcamento,
  setVendaBonificada,
  setFormaPagto,
  setIdCondPag,
  setVlrTotProdVenda,
  setVlrTotServVenda,
  setVlrTotDescVenda,
  setVlrTotComisVenda,
  setVlrTotVenda,
  setIdPremiado,
  setPontosPremiacao,
  setWhatsappCliente,
  setNomeLocalEntrega,
  setEnderecoEntrega,
  setNroEnderecoEntrega,
  setBairroEntrega,
  setComplementoEntrega,
  setIdCidadeEntrega,
  setNomeCidadeEntrega,
  setDataEntrega,
} = vendaCabSlice.actions;

export default vendaCabSlice.reducer;
