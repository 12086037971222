import React from "react";
import { useState } from "react";
import { TextInput } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleBanco, routesBaseBanco } from "../CadBanco";

export const CadastroBancoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut
}) => {
  const [nome, setNome] = useState("");
  const [bacen, setBacen] = useState("");
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setNome("");
    setBacen("");
    setAtivo(true);
  };

  const fetchData = (dados) => {
    setNome(dados.nome ?? "");
    setBacen(dados.cod_bc);
    setAtivo(dados.status);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      cod_bc: bacen,
      ativo: ativo,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ativo: ativo, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleBanco}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseBanco}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
      number="0121_1"
      selected={selected}
      notifyEvent={notifyEvent}
      concatShortcut={concatShortcut}
      concatModelName="banco"
    >
      <Row>
        <TextInput
          md={10}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={40}
        />
        <TextInput
          md={2}
          label="Cód. Bacen"
          value={bacen}
          onChange={(e, v) => setBacen(v)}
          maxLength={3}
        />
      </Row>
    </ModalCadastroV2>
  );
};
