import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  FiltroPeriodoDatas,
  FormButton,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { apiGetV2 } from "../../../../apiV2";
import { ClientesNovosGrid } from "./components/ClientesNovosGrid";
import { docPrintReport } from "../../../../pdf";
import { exportDataToSheet } from "../../../../coreUtils";

export const ClientesNovos = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idVendedor, setIdVendedor] = useState(null);
  const [dados, setDados] = useState([]);
  const [vlrTotPeriodo, setVlrTotPeriodo] = useState(0);
  const [vlrTotGeral, setVlrTotGeral] = useState(0);
  const [qtdClientes, setQtdClientes] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_vendedor: idVendedor,
    };

    return await apiGetV2(`/relatorios/venda/clientes_novos/`, params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      setVlrTotPeriodo(parseFloat(ret.vlr_tot_periodo));
      setVlrTotGeral(parseFloat(ret.vlr_tot_geral));
      setQtdClientes(ret.qtd_clientes);
      setDados(ret.clientes);
    } else {
      setVlrTotPeriodo(0);
      setVlrTotGeral(0);
      setQtdClientes(0);
      setDados([]);
    }
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        data_ini: dataIni,
        data_fim: dataFim,
        dados: ret,
      };
      await docPrintReport(
        "/relatorios/venda/clientes_novos/",
        payload,
        "5048"
      );
    }
    setLoadingImprimir(false);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const data = ret.clientes.map((e) => ({
        "# Cliente": e.id_cliente,
        Nome: e.nome_cliente,
        "Nome Fantasia": e.nome_fant_cliente,
        "Data de Cadastro": e.data_cadastro,
        Cidade: e.nome_cidade,
        "Vlr. Vendas no Período": parseFloat(e.vlr_vdas_periodo),
        "Total Vendas": parseFloat(e.vlr_vdas_total),
      }));

      exportDataToSheet(data, "Clientes Novos", {
        dataIni: dataIni,
        dataFim: dataFim,
      });
    }
    setLoadingExportar(false);
  };

  return (
    <PageContainer title="Clientes Novos" number="5048" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            label="Vendedor"
            isConcatField
            concatModelName="colaborador"
            onChange={(selected) => setIdVendedor(selected?.value)}
            defaultOptions
            isClearable
            isSearchable
            md={3}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportar}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem label="Clientes" value={qtdClientes} checkFloat />
        <CardTotaisItem label="Tot. Vendas no Período" value={vlrTotPeriodo} />
        <CardTotaisItem label="Tot. Vendas Clientes" value={vlrTotGeral} />
      </CardTotais>
      <Card body>
        <ClientesNovosGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
