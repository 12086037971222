import React from "react";
import { IconButton, Table } from "../../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../../coreUtils";
import { BsPrinter } from "react-icons/bs";
import { Edit } from "react-feather";
import { HiOutlineMail } from "react-icons/hi";

import OrcamentosService from "../../../../../../services/orcamentos/OrcamentosService";

export const OrcRapidoOSGrid = ({ dados, setSelected, alterar }) => {
  const columns = [
    { dataField: "id", text: "#", align: "center", headerAlign: "center" },
    {
      dataField: "placa",
      text: "Placa",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "nome_veiculo",
      text: "Veículo",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data_hora_emi",
      text: "Emissão",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_desc",
      text: "Desconto",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "situacao",
      text: "Situação",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "print",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => {
        if (row.situacao === "Aberto") {
          return (
            <>
              <IconButton
                icon={Edit}
                onClick={() => alterar(row.id)}
                hint="Alterar Orçamento"
                tooltipPlacement="left"
              />
              <IconButton
                className="ml-2"
                icon={BsPrinter}
                onClick={() => {
                  OrcamentosService.imprimir(row.id);
                }}
                hint="Imprimir Orçamento"
                tooltipPlacement="left"
              />
            </>
          );
        } else {
          return (
            <>
              <IconButton
                icon={BsPrinter}
                onClick={() => {
                  OrcamentosService.imprimir(row.id);
                }}
                hint="Imprimir Orçamento"
                tooltipPlacement="left"
              />
            </>
          );
        }
      },
    },
    {
      dataField: "mail",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <IconButton
          icon={HiOutlineMail}
          onClick={() => OrcamentosService.enviarEmail(row.id)}
          hint="Enviar E-Mail"
        />
      ),
    },
  ];

  return <Table data={dados} columns={columns} onSelect={setSelected} />;
};
