import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import {
  cadastroFabricanteRoute,
  cadastroGrupoRoute,
  cadastroProdutoRoute,
  cadastroUnidadeRoute,
  cadastroFornecedoresRoute,
  cadastroSubGrupoRoute,
  incluirAlterarProdutoRoute,
  cadastroClasseProdutoRoute,
  cadastroProdutoFaturamentoClienteRoute,
} from "../../../routes/modules/cadastro";

export const Produtos = () => {
  return (
    <PageContainer title="Cadastros - Produtos">
      <MenuGroup>
        <MenuGroupButton
          label="Produto"
          icon={incluirAlterarProdutoRoute.shortcutIcon}
          pathname={cadastroProdutoRoute.path}
          nroTela={cadastroProdutoRoute.nroTela}
        />
        <MenuGroupButton
          label="Classe de Produtos"
          icon={cadastroClasseProdutoRoute.shortcutIcon}
          pathname={cadastroClasseProdutoRoute.path}
          nroTela={cadastroClasseProdutoRoute.nroTela}
        />
        <MenuGroupButton
          label="Fabricante"
          icon={cadastroFabricanteRoute.shortcutIcon}
          pathname={cadastroFabricanteRoute.path}
          nroTela={cadastroFabricanteRoute.nroTela}
        />
        <MenuGroupButton
          label="Fornecedor"
          icon={cadastroFornecedoresRoute.shortcutIcon}
          pathname={cadastroFornecedoresRoute.path}
          nroTela={cadastroFornecedoresRoute.nroTela}
        />
        <MenuGroupButton
          label="Grupo"
          icon={cadastroGrupoRoute.shortcutIcon}
          pathname={cadastroGrupoRoute.path}
          nroTela={cadastroGrupoRoute.nroTela}
        />
        <MenuGroupButton
          label="Subgrupo"
          icon={cadastroSubGrupoRoute.shortcutIcon}
          pathname={cadastroSubGrupoRoute.path}
          nroTela={cadastroSubGrupoRoute.nroTela}
        />
        <MenuGroupButton
          label="Unidade de Medida"
          icon={cadastroUnidadeRoute.shortcutIcon}
          pathname={cadastroUnidadeRoute.path}
          nroTela={cadastroUnidadeRoute.nroTela}
        />
        <MenuGroupButton
          label="Produto de Faturamento por Cliente"
          icon={cadastroProdutoFaturamentoClienteRoute.shortcutIcon}
          pathname={cadastroProdutoFaturamentoClienteRoute.path}
          nroTela={cadastroProdutoFaturamentoClienteRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
