import React from "react";
import { formatNumber } from "../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { Table } from "../../../../../components";

export const TabFatNfDescVendaGrid = ({
  data,
  setSelected,
  alterar,
  excluir,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
    },
    {
      dataField: "perc_fat",
      text: "Perc. Faturamento",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "perc_desc",
      text: "Perc. Desconto",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2),
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];
  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
