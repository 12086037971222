import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Col, Row } from "reactstrap";
import XLSX from "xlsx";
import {
  AsyncComboBox,
  ComboBox,
  CustomPieChart,
  FormButton,
} from "../../../../components";
import { BaseInfoCard } from "../../../../components/BaseInfoCard";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import PercentualVendasPorVendedorService from "../../../../services/PercentualVendasPorVendedorService";

const grouping_options = [
  { value: "n", label: "Não agrupar" },
  { value: "g", label: "Grupo" },
  { value: "f", label: "Fabricante" },
];

export const ParticipacaoDeVendas = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [fabricante, setFabricante] = useState([]);
  const [grupo, setGrupo] = useState([]);
  const [vendedor, setVendedor] = useState([]);
  const [agruparPor, setAgruparPor] = useState(grouping_options[0]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [relData, setRelData] = useState([]);

  const limparDados = () => {
    setRelData([]);
  };

  const handleDate = (dataIni, dataFim) => {
    setDataIni(dataIni);
    setDataFim(dataFim);
    limparDados();
  };

  const handleSetFabricante = (selected) => {
    setFabricante(selected?.map((e) => e.value));
    limparDados();
  };

  const handleSetGrupo = (selected) => {
    setGrupo(selected?.map((e) => e.value));
    limparDados();
  };

  const handleSetVendedor = (selected) => {
    setVendedor(selected?.map((e) => e.value));
    limparDados();
  };

  const fetchData = async () => {
    if (!(dataIni instanceof Date) || !(dataFim instanceof Date)) {
      toastr.warning("Atenção", "Por favor, verifique os campos de data");
      return;
    }

    setIsLoadingSearch(true);

    const ret = await PercentualVendasPorVendedorService.fetch(
      dataIni.toISOString()?.split("T")[0],
      dataFim.toISOString()?.split("T")[0],
      fabricante,
      grupo,
      vendedor,
      agruparPor
    );

    if (ret?.data?.length > 0) setRelData(ret.data);

    setIsLoadingSearch(false);
  };

  const exportData = async () => {
    if (!(dataIni instanceof Date) || !(dataFim instanceof Date)) {
      toastr.warning("Atenção", "Por favor, verifique os campos de data");
      return;
    }
    setIsLoadingExport(true);

    let filename = `participacao_de_vendas_${
      dataIni.toISOString()?.split("T")[0]
    }_ate_${dataFim.toISOString()?.split("T")[0]}`;

    if (!relData.length) {
      const ret = await PercentualVendasPorVendedorService.fetch(
        dataIni.toISOString()?.split("T")[0],
        dataFim.toISOString()?.split("T")[0],
        fabricante,
        grupo,
        vendedor,
        agruparPor
      );

      if (ret === undefined) return;

      const { data } = ret;

      let wb = XLSX.utils.book_new();

      data.forEach((element) => {
        element["data"] = [
          ...element["data"],
          { name: "TOTAL", value: element["total"] },
        ];
        element["title"] = element["title"].replace("/", "_");
        let ws = XLSX.utils.json_to_sheet(element["data"]);
        XLSX.utils.book_append_sheet(wb, ws, element["title"]);
      });

      XLSX.writeFile(wb, `${filename}.xlsx`);
    } else {
      let wb = XLSX.utils.book_new();

      relData.forEach((element) => {
        element["data"] = [
          ...element["data"],
          { name: "TOTAL", value: element["total"] },
        ];
        element["title"] = element["title"].replace("/", "_");
        let ws = XLSX.utils.json_to_sheet(element["data"]);
        XLSX.utils.book_append_sheet(wb, ws, element["title"]);
      });

      XLSX.writeFile(wb, `${filename}.xlsx`);
    }
    setIsLoadingExport(false);
  };

  return (
    <PageContainer
      title="Participação de Vendas - Vendedor, Grupo e Fabricante"
      number="5005"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            onChange={handleSetFabricante}
            isSearchable
            isClearable
            isMulti
            md={3}
            label="Fabricante"
            name="fabricante"
            isConcatField
            concatModelName="fabricante"
            defaultOptions
          />
          <AsyncComboBox
            onChange={handleSetGrupo}
            isSearchable
            isClearable
            isMulti
            md={3}
            label="Grupo"
            name="grupo"
            isConcatField
            concatModelName="grupo"
            defaultOptions
          />
          <AsyncComboBox
            onChange={handleSetVendedor}
            isSearchable
            isClearable
            md={3}
            label="Vendedor"
            name="vendedor"
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            isMulti
          />
          <ComboBox
            md={2}
            label="Agrupar Por"
            name="agrupar_por"
            options={grouping_options}
            onChange={(s) => setAgruparPor(s?.value)}
            defaultValue={grouping_options[0].value}
          />
          <BotaoPesquisar onClick={fetchData} loading={isLoadingSearch} />
          <FormButton
            color="primary"
            onClick={exportData}
            loading={isLoadingExport}
            id="bt-exportar"
          >
            Exportar Dados
          </FormButton>
        </Row>
      </Card>
      <Row>
        {relData.map((element, i) => {
          const { title, data, total } = element;
          let md = 12;
          if (relData.length % 2 === 0) {
            md = 6;
          } else {
            md = i === relData.length - 1 ? 12 : 6;
          }
          return (
            <Col md={md} key={`col-pie-${i}`}>
              <CustomPieChart
                key={`pie-chart-${i}`}
                title={title}
                data={data}
                dataKey={"value"}
                nameKey={"name"}
                percentage
                total={total}
              />
            </Col>
          );
        })}
      </Row>
      {relData.length > 0 && (
        <BaseInfoCard baseInfo={["Vendas: Vendas do Sistema Desktop"]} />
      )}
    </PageContainer>
  );
};
