import React from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import { FixedTable } from "../../../../../../components";
import { formatNumber } from "../../../../../../coreUtils";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { HintLabel } from "../../../../../../components/HintLabel";

export const EntregarParcelasGrid = ({ data, loading }) => {
  const columns = [
    {
      dataField: "parcela",
      text: "Parcela",
      align: "center",
      headerAlign: "center",
      headerStyle: () => ({ width: "33%" }),
      editable: false,
    },
    {
      dataField: "data_vcto",
      text: (
        <HintLabel
          label="Vencimento"
          hintText="Clique nas Datas para Alterá-las"
        />
      ),
      align: "center",
      headerAlign: "center",
      headerStyle: () => ({ width: "33%" }),
      editorStyle: { textAlign: "center" },
      validator: (newValue) => {
        if (
          !newValue.match(/[^0-9|/]/g) &&
          moment(newValue, "DD/MM/YYYY").isValid()
        ) {
          return true;
        } else {
          toastr.warning("Atenção", "Por favor, informe uma data válida.");
          return { valid: false, message: "" };
        }
      },
      editCellStyle: () => ({ padding: 0, verticalAlign: "middle" }),
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      headerStyle: () => ({ width: "34%" }),
      formatter: (c) => formatNumber(c, true, 2),
      editable: false,
    },
  ];

  return (
    <FixedTable
      columns={columns}
      data={data}
      readOnly
      hideCount
      keyField="parcela"
      md={5}
      style={{
        maxHeight: "250px",
      }}
      loading={loading}
      cellEdit={cellEditFactory({
        mode: "click",
        beforeSaveCell: (oldValue, newValue, row, { dataField }) => {
          if (dataField === "prev_receb") {
            row.prev_receb = moment(newValue, "DD/MM/YYYY").format(
              "DD/MM/YYYY"
            );
          }
        },
        timeToCloseMessage: 100,
        blurToSave: true,
      })}
    />
  );
};
