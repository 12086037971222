import React from "react";
import { MoreDropdown, Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { iconeAlterar } from "../../../../../components/cadastro";
import { DropdownItem } from "reactstrap";
import RemessaEntreEmpresasService from "../../../../../services/faturamento/RemessaEntreEmpresasService";

export const RemessaGrid = ({
  dados,
  setSelected,
  alterar,
  mostrarDetalhes,
}) => {
  const columns = [
    { dataField: "id", text: "#", align: "center" },
    {
      dataField: "id_destino__nome",
      text: "Destino",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_destino),
    },

    { dataField: "inclusao", text: "Inclusão", align: "center" },
    {
      dataField: "nro_nf",
      text: "Nº NF",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_tot_prod",
      text: "Produtos",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "situacao",
      text: "Situação",
      align: "center",
    },
    {
      ...iconeAlterar(alterar, {
        disabled: (_, row) => row.status !== "INC",
        disabledHint: "Somente remessas não enviadas podem ser alteradas",
      }),
    },
    {
      dataField: "det",
      text: "",
      dummy: true,
      align: "center",
      cellContentTag: null,
      colWidth: "32px",
      fixedColWidth: true,
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => mostrarDetalhes(row.id)}>
            Detalhes
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              RemessaEntreEmpresasService.imprimirSeparacao(row.id, false)
            }
          >
            Imprimir Separação
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
