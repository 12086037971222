import { apiDeleteV2, apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = `/cadastro/regra_tributacao`;

const RegrasTributacaoService = {
  urlBase: URL_BASE,
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  listarProdutos: async (idRegra) =>
    await apiGetV2(`${URL_BASE}/produtos/listar/${idRegra}/`),
  listarProdutosAdd: async (idRegra, params) =>
    apiGetV2(`${URL_BASE}/produtos/sel_prods/${idRegra}/`, params),
  inserirProduto: async (payload) =>
    await apiPostV2(`${URL_BASE}/produtos/incluir/`, payload),
  excluirProduto: async (idProdRegra) =>
    await apiDeleteV2(`${URL_BASE}/produtos/excluir/${idProdRegra}/`),
  exportarDados: async () => await apiGetV2(`${URL_BASE}/exportacao_dados/`),
};

export default RegrasTributacaoService;
