import cookies from "js-cookie";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { api, buscarLogoBase64 } from "./api";
import settings from "./settings";
import { showError } from "./components/AlertaModal";
import XLSX from "xlsx";

export const versaoWeb = "3.3.0097";

export const dateFromLocaleString = (d) => {
  const dd = d.slice(0, 2);
  const mm = d.slice(3, 5);
  const yyyy = d.slice(6, 10);

  return new Date(`${mm}/${dd}/${yyyy}`);
};

export const formatDateLocal = (d) => moment(d).format("DD/MM/YYYY");

export const formatDateISO = (d) =>
  d instanceof Date
    ? new Date(d.valueOf() - d.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0]
    : d;

export const formatNumber = (
  number,
  parseVal = true,
  roundTo = 2,
  checkFloat = false
) => {
  if (parseVal) {
    number = parseFloat(number);
  }
  if (checkFloat && number % 1 === 0) {
    // Número inteiro
    roundTo = 0;
  }
  return number
    .toFixed(roundTo)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const roundNumber = (num, dec = 2) => {
  return +(Math.round(num + `e+${dec}`) + `e-${dec}`);
  // return Math.round((num + Number.EPSILON) * 10 ** dec) / 10 ** dec;
  // const formatter = new Intl.NumberFormat("pt-BR", {
  //   style: "decimal",
  //   maximumFractionDigits: dec,
  //   minimumFractionDigits: dec,
  // });
  // console.log(formatter.format(num));
  // return formatter.format(num);
};

export const formatNumberFloat = (number, parse_val = true, roundTo = 2) => {
  if (parse_val) {
    return Number(
      parseFloat(number)
        .toFixed(roundTo)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    );
  } else {
    return number.toFixed(roundTo).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }
};

export const formatarValor = (
  valor,
  maxDecimais = 2,
  minDecimais = null,
  parse = true
) => {
  if (parse) {
    valor = parseFloat(valor);
  }

  if (minDecimais == null) {
    minDecimais = maxDecimais;
  }

  if (isNaN(valor)) valor = 0;
  return valor.toLocaleString("pt-br", {
    minimumFractionDigits: minDecimais,
    maximumFractionDigits: maxDecimais,
  });
};

export const parseRound = (n, dec = 2) => {
  return Math.round(parseFloat(n) * 10 ** dec) / 10 ** dec;
};

export const grep = (pattern, x, flags = "") => {
  return new RegExp(pattern, flags).exec(x);
};

export const isValidDate = (d) => {
  return moment(d, "MM/DD/YYYY", true).isValid();
};

export const GetToSelectOptions = (getRequest, id_field, label_field) => {
  const tmpResult = [];
  getRequest.data.res.forEach((item) => {
    tmpResult.push({
      value: item[id_field],
      label: `${item[label_field]} (${item[id_field]})`,
    });
  });

  return tmpResult;
};

export const diffTime = (ini = new Date(), fim = new Date()) => {
  let diff = fim - ini;
  let hh = Math.floor(diff / 1000 / 60 / 60);
  diff -= hh * 1000 * 60 * 60;
  let mm = Math.floor(diff / 1000 / 60);
  diff -= mm * 1000 * 60;

  if (hh < 0) {
    hh = 0;
  }

  return hh + mm / 60;
};

export const dateDiffInDays = (a, b) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const stringifyDecimalTime = (val) => {
  if (val == null) {
    return "";
  }
  let frac = val - Math.floor(Math.abs(val));
  let intg = Math.floor(val);

  frac = String(Math.ceil(frac * 60)).padStart(2, "0");
  intg = String(intg).padStart(2, "0");

  return `${intg}:${frac}`;
};

export const fetchDataFromAPI = async (url) => {
  let fetchData = [];
  await api.get(url).then((data) => {
    fetchData = data;
  });
  return fetchData.data;
};

export const checkPair = (value) => {
  /* verifica se n é par um impar
    true = par
    false = impar
  */
  return value % 2 === 0;
};

export const generateRandomColor = () => {
  let letter = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) color += letter[Math.floor(Math.random() * 16)];
  return color;
};

export const isArray = (arr) => {
  return Array.isArray(arr);
};

export const getAttrName = ({ obj, pos = null, key = null } = {}) => {
  if (pos === null && key === null) {
    throw new Error("É obrigatório que a posição ou a chave sejam informados.");
  } else if (pos !== null && key !== null) {
    throw new Error("Somente a chave OU a posição devem ser informados.");
  }

  return Object.keys(obj)[pos !== null ? pos : key];
};

export const formatValueFromAPI = (value, id, maxLength) => {
  const label = maxLength
    ? value?.substring(
        0,
        // Calcula o tamanho máximo do nome considerando o (id)
        maxLength - (id.toString().length + 3)
      )
    : value;
  return `${label} (${id})`;
};

export const sleep = (milliseconds) => {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
};

export const chartColors = [
  "#5fc27e",
  "#FD5D38",
  "#5b7dff",
  "#fcc100",
  "#47BAC1",
  "#a180da",
  "#006494",
  "#DC493A",
  "#BF7D00",
  "#E15554",
  "#3BB273",
  "#7768AE",
  "#2fa7bd",
  "#716aae",
  "#b1c132",
  "#ff569d",
  "#717faf",
  "#767c3f",
  "#e79e7b",
  "#8eb0bf",
  "#ab6246",
  "#6d1f4b",
  "#055875",
  "#117964",
  "#d5d078",
  "#9797f0",
  "#d5b208",
  "#ea7c14",
  "#70e3d1",
  "#0a71a5",
];

export const toastrOptions = {
  timeOut: 3000,
  transitionIn: "fadeIn",
  transitionOut: "fadeOut",
};

export const MODAL_ACTIONS = Object.freeze({
  ADD: 1,
  EDIT: 2,
  DELETE: 3,
});

export const selectedRowColor = "#EDF5FC";

export const readOnlySelection = {
  mode: "radio",
  clickToSelect: true,
  bgColor: selectedRowColor,
};

export const downloadFileFromBlob = (blob, filename) => {
  const link = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const openFileFromBlob = (
  blob,
  filename,
  mimetype = "application/pdf"
) => {
  const file = new File([blob], filename, { type: mimetype });
  const url = window.URL.createObjectURL(file);
  const tab = window.open("about:blank", "_blank");
  if (!tab) {
    showError(
      "Não foi possível visualizar a impressão do documento.",
      "DocFrontPopup",
      [
        "Por favor, verifique, nas configurações do Navegador, " +
          "se a opção Permitir Pop-ups está habilitada. " +
          "Se ela estiver desabilitada, habilite-a.",
      ]
    );

    return false;
  }

  tab.document.write(
    `<html>
      <body>
        <iframe
          type='${mimetype}'
          style='position:absolute; left: 0; top: 0;'
          width='100%'
          height='100%'
          src='${url}'/>
      </body>
    </html>`
  );
};

export const downloadFileFromURL = (url, filename) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const isDevEnv = () => {
  const nodeEnv = process.env.NODE_ENV;
  const ambienteDev = settings.AMBIENTE_DEV ?? true;
  if (!nodeEnv || nodeEnv === "development" || ambienteDev) {
    return true;
  } else {
    return false;
  }
};

export const isTestingEnv = () => {
  const env = process.env.REACT_APP_AMBIENTE_TESTES;
  return env;
};

export const objectIsEmpty = (o) => {
  for (let _ in o) {
    return false;
  }
  return true;
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
export const debounce = (func, wait) => {
  let timeout;

  // This is the function that is returned and will be executed many times
  // We spread (...args) to capture any number of parameters we want to pass
  return function executedFunction(...args) {
    // The callback function to be executed after
    // the debounce time has elapsed
    const later = () => {
      // null timeout to indicate the debounce ended
      timeout = null;

      // Execute the callback
      func(...args);
    };
    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout);

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs Node)
    timeout = setTimeout(later, wait);
  };
};

export const timeout = (ms, func) => {
  return new Promise((resolve) =>
    setTimeout(async () => {
      await func();
      resolve();
    }, ms)
  );
};

export const rawAPIUrl = (url) => {
  return (
    `${cookies.get("api-address")}${url}?` +
    `token=${cookies.get("access-token")}`
  );
};

export const dateColumnSorter = (a, b, order) => {
  a = moment(a, "DD/MM/YYYY");
  b = moment(b, "DD/MM/YYYY");
  if (order === "asc") {
    return a.toDate() - b.toDate();
  } else if (order === "desc") {
    return b.toDate() - a.toDate();
  }
};

export const naturalSort = (a, b, order) => {
  let _a = String(a);
  let _b = String(b);
  if (order === "desc") {
    _a = String(b);
    _b = String(a);
  }
  return _a.localeCompare(_b, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

export const numericNaturalSort = (a, b, order) => {
  // Essa função foi criada pois o método localeCompare da String não trabalha
  // bem com valores numéricos negativos
  let _a = String(a).replace(".", "").replace(",", ".");
  let _b = String(b).replace(".", "").replace(",", ".");
  if (order === "desc") {
    _a = String(b);
    _b = String(a);
  }
  return parseFloat(_a) < parseFloat(_b) ? -1 : 1;
};

export const naturalGenericSort = (a, b, order) => {
  let _a = String(a);
  let _b = String(b);
  if (order === "desc") {
    _a = String(b);
    _b = String(a);
  }
  return _a.localeCompare(_b, undefined, {
    sensitivity: "base",
  });
};

export const naturalSortDate = (a, b, order) =>
  naturalSort(
    a ? moment(a, "DD/MM/YYYY").toDate().toISOString() : "",
    b ? moment(b, "DD/MM/YYYY").toDate().toISOString() : "",
    order
  );

export const defaultDebounceTime = 750;

export const ufs = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "DF", value: "DF" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MT", value: "MT" },
  { label: "MS", value: "MS" },
  { label: "MG", value: "MG" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PR", value: "PR" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RS", value: "RS" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "SC", value: "SC" },
  { label: "SP", value: "SP" },
  { label: "SE", value: "SE" },
  { label: "TO", value: "TO" },
  { label: "Exterior", value: "EX" },
];

export const pessoa = [
  {
    label: "Física",
    value: "F",
  },
  {
    label: "Jurídica",
    value: "J",
  },
];

export const sumDataField = (data, field) => {
  return data.map((a) => parseFloat(a[field])).reduce((a, b) => a + b, 0);
};

export const validateDateFields = (di, df) => {
  if (!(di instanceof Date) || !(df instanceof Date)) {
    toastr.warning("Atenção", "Por favor, verifique os campos de data.");
    return false;
  }
  return true;
};

export const meses = [
  {
    label: "Janeiro",
    value: 1,
  },
  {
    label: "Fevereiro",
    value: 2,
  },
  {
    label: "Março",
    value: 3,
  },
  {
    label: "Abril",
    value: 4,
  },
  {
    label: "Maio",
    value: 5,
  },
  {
    label: "Junho",
    value: 6,
  },
  {
    label: "Julho",
    value: 7,
  },
  {
    label: "Agosto",
    value: 8,
  },
  {
    label: "Setembro",
    value: 9,
  },
  {
    label: "Outubro",
    value: 10,
  },
  {
    label: "Novembro",
    value: 11,
  },
  {
    label: "Dezembro",
    value: 12,
  },
];

export const filterFormFocusableElements = (elements) => {
  return Array.from(elements)
    .filter((e) => {
      return (
        ([
          "text",
          "textarea",
          "time",
          "button",
          "password",
          "radio",
          "checkbox",
          "select-one",
          "tel",
        ].includes(e.type) ||
          e.hasAttribute("tabindex")) &&
        !["hidden"].includes(e.type) &&
        !e.disabled
      );
    })
    .sort((a, b) => a.tabIndex - b.tabIndex);
};

export const handleFocus = (e) => {
  if (
    (e.key === "Enter" || e.key === "ArrowUp" || e.key === "Tab") &&
    e.target.form
  ) {
    if (e.key === "Tab" || e.key === "Enter") e.preventDefault();

    const action =
      e.key === "Enter" || (!e.shiftKey && e.key === "Tab") ? 1 : -1;

    const formFilter = filterFormFocusableElements(
      e.target.form.querySelectorAll(
        'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      )
    );

    let targetIndex = formFilter.indexOf(e.target) + action;

    if (targetIndex > formFilter.length - 1) {
      targetIndex = 0;
    } else if (targetIndex < 0) {
      targetIndex = formFilter.length - 1;
    }

    const target = formFilter[targetIndex];

    if (target) {
      if (target.name && target.name.startsWith("table-sel-inpt-")) {
        target.click();
        if (target.parentNode?.parentNode) {
          target.parentNode.parentNode.focus();
        }
      } else {
        target.focus();
      }
    }
  }
};

export const handleTableFocus = (tableId, rowIndex, keyCode) => {
  const currRow = document.getElementById(`${tableId}--row-${rowIndex}`);
  if (document.activeElement === currRow) {
    if (keyCode === "ArrowDown") {
      //down
      const nextRow = document.getElementById(
        `${tableId}--row-${rowIndex + 1}`
      );
      if (nextRow) {
        nextRow.focus();
        nextRow.click();
      }
    }
    if (keyCode === "ArrowUp") {
      //up
      const prevRow = document.getElementById(
        `${tableId}--row-${rowIndex - 1}`
      );
      if (prevRow) {
        prevRow.focus();
        prevRow.click();
      }
    }
  }
};

export const viewDownloadPDF = (data, filename) => {
  const printFunction =
    localStorage.getItem("forma_geracao_pdf_web") === "VISUALIZAR"
      ? openFileFromBlob
      : downloadFileFromBlob;

  printFunction(data, `${filename}.pdf`);
};

export const limparNumero = (v) => (v ?? "").replaceAll(/\D/g, "");

export const formatarFone = (fone = "") => {
  let value = fone.replace(/\D/g, "").replace(/^0+/g, "");

  if (![8, 9, 10, 11].includes(value.length)) {
    return value;
  }

  const pattern =
    value.length === 11
      ? "(##) #.####-####"
      : value.length === 10
      ? "(##) ####-####"
      : value.length === 9
      ? "#.####-####"
      : "####-####";

  let i = 0;

  const ret = pattern.replace(/#/g, () => value[i++] ?? "");

  return ret;
};

export const formatarCep = (cep = "") => {
  let value = cep.replace(/\D/g, "");

  if (value.length !== 8) {
    return value;
  }

  const pattern = "#####-###";

  let i = 0;

  const ret = pattern.replace(/#/g, () => value[i++] ?? "");

  return ret;
};

export const formatarCnpj = (cnpj) => {
  let value = cnpj.replace(/\D/g, "");
  const pattern = "##.###.###/####-##";

  let i = 0;

  const ret = pattern.replace(/#/g, () => value[i++] ?? "");

  return ret;
};

export const formatarCpf = (cpf) => {
  let value = cpf.replace(/\D/g, "");
  const pattern = "###.###.###-##";

  let i = 0;

  const ret = pattern.replace(/#/g, () => value[i++] ?? "");

  return ret;
};

export const formatarCpfCnpj = (v) => {
  let nums = (v ?? "").replaceAll(/\D/g, "");
  if (nums.length === 14) {
    return formatarCnpj(nums);
  } else if (nums.length === 11) {
    return formatarCpf(nums);
  }
  return nums;
};

export const validarCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(1)) return false;

  return true;
};

export const validarCPF = (cpf) => {
  cpf = (cpf ?? "").replaceAll(/\D/g, "");

  var soma = 0;
  var resto;

  if (cpf === "00000000000") {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.substring(10, 11))) {
    return false;
  }
  return true;
};

export const validarCPFCNPJ = (v) => {
  const nums = (v ?? "").replaceAll(/\D/g, "");

  if (nums.length === 14) {
    return validarCNPJ(nums);
  } else if (nums.length === 11) {
    return validarCPF(nums);
  } else if (nums.length > 0) {
    return false;
  }
};

export const useStateWithRef = (initialValue) => {
  const ref = useRef(initialValue);
  const [state, setState] = useState(initialValue);

  const updateState = (newState) => {
    ref.current = typeof newState === "function" ? newState(state) : newState;
    setState(ref.current);
  };

  return [state, updateState, ref];
};

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const roundFloat = (number, decimalPlaces) =>
  Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

const getRGB = (c) => {
  return parseInt(c, 16) || c;
};

const getsRGB = (c) => {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
};

const getLuminance = (hexColor) => {
  return (
    0.2126 * getsRGB(hexColor.substr(1, 2)) +
    0.7152 * getsRGB(hexColor.substr(3, 2)) +
    0.0722 * getsRGB(hexColor.substr(-2))
  );
};

const getContrast = (f, b) => {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
};

// Define se o texto é preto ou branco conforme o fundo
export const getTextColorBg = (bgColor) => {
  const whiteContrast = getContrast(bgColor, "#ffffff");
  const blackContrast = getContrast(bgColor, "#000000");

  return whiteContrast > blackContrast ? "#ffffff" : "#000000";
};

export const isMaster = () => localStorage.getItem("master") === "true";

export const userPodeVerTotaisRelVenda = () =>
  localStorage.getItem("ver_totais_consultas") === "true" || isMaster();

export const userPodeVisHistorVendaCompra = () =>
  localStorage.getItem("vis_histor_venda_compra") === "true";

export const buscarDadosEmpresaCookies = async () => ({
  nome_empresa: localStorage.getItem("nome_empresa"),
  nome_fant_empresa: localStorage.getItem("nome_fant_empresa"),
  ender_empresa: localStorage.getItem("ender_empresa"),
  compl_empresa: localStorage.getItem("compl_empresa"),
  cnpj_empresa: localStorage.getItem("cnpj_empresa"),
  fone_empresa: localStorage.getItem("fone_empresa"),
  fone2_empresa: localStorage.getItem("fone2_empresa"),
  email_empresa: localStorage.getItem("email_empresa"),
  logo: await buscarLogoBase64(false),
});

export const corDescricaoClassifCli = (value = 0) => {
  let detClassifCli = value.toString() + " - ";
  let corClassifCli = "";
  switch (value) {
    case 1:
    case 2:
    case 3:
      corClassifCli = "#008000";
      detClassifCli += "Cliente com ótimo / bom histórico de vendas";
      break;
    case 4:
    case 5:
      corClassifCli = "#FFFF00";
      detClassifCli +=
        "Cliente com histórico de vendas razoável, requer atenção";
      break;
    case 6:
    case 7:
      corClassifCli = "#FF0000";
      detClassifCli += "Cliente com histórico de vendas ruim, evitar vendas";
      break;
    case 8:
      corClassifCli = "#000000";
      detClassifCli += "Cliente com dívidas em aberto no passado";
      break;
    default:
      corClassifCli = "#eee";
      detClassifCli += "Sem Classificação";
      break;
  }

  return { cor: corClassifCli, detalhe: detClassifCli };
};

export const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const sortAnyArray = (x, y) => {
  var pre = ["string", "number", "bool"];
  if (typeof x !== typeof y)
    return pre.indexOf(typeof y) - pre.indexOf(typeof x);

  if (x === y) return 0;
  else return x > y ? 1 : -1;
};

export const compareArraysNoOrder = (a, b) =>
  a instanceof Array &&
  b instanceof Array &&
  a.sort(sortAnyArray) === b.sort(sortAnyArray);

export const columnsToObject = (columns, data, index) =>
  columns
    .filter((e) => !e.hidden)
    .reduce(
      (acc, col) => ({
        ...acc,
        [col.text]: col.formatter
          ? col.formatter(data[col.dataField], data, index)
          : data[col.dataField],
      }),
      {}
    );

export const dateRangeAsTitle = (dataIni, dataFim) => {
  let res = "";
  if (dataIni instanceof Date && dataFim instanceof Date) {
    res = `${formatDateLocal(dataIni)} a ${formatDateLocal(dataFim)}`;
  } else if (dataIni instanceof Date && !(dataFim instanceof Date)) {
    res = `a partir de ${formatDateLocal(dataIni)}`;
  } else if (!(dataIni instanceof Date) && dataFim instanceof Date) {
    res = `até ${formatDateLocal(dataFim)}`;
  }
  return res;
};

export const formatDataToSheet = (data, columns) =>
  data.map((e, index) => columnsToObject(columns, e, index));

export const exportDataToSheet = (
  data,
  title,
  { columns, sheetTitle = "Relatório", dataIni, dataFim } = {}
) => {
  if (columns) {
    data = formatDataToSheet(data, columns);
  }

  let datasRelatorio = dateRangeAsTitle(dataIni, dataFim);

  if (datasRelatorio !== "") {
    title = `${title} - ${datasRelatorio}`;
  }

  let ws = XLSX.utils.book_new();

  XLSX.utils.sheet_add_json(ws, data);

  const wb = { Sheets: { [sheetTitle]: ws }, SheetNames: [sheetTitle] };

  XLSX.writeFile(wb, `${title}.xlsx`);
};

export const wrapText = (text, cols) =>
  text.replace(
    new RegExp(`(?![^\\n]{1,${cols}}$)([^\\n]{1,${cols}})\\s`, "g"),
    "$1\n"
  );

export const localeDateToISO = (d) =>
  moment(d, "DD/MM/YYYY").format("YYYY-MM-DD");

export const getFirstDayOfWeek = (d) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

export const getLastDayOfWeek = (d) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -1 : 6); // adjust when day is monday
  return new Date(d.setDate(diff));
};
