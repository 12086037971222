import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cfop_pad: null,
  id_grupo_pad: null,
  id_sub_grupo_pad: null,
  id_fabricante_pad: null,
  id_tipo_prod_pad: null,
  unid_med_pad: null,
  lang_full_text: "english",
  exige_senha_alt_estoq_pelo_cad_prod: false,
  utiliza_alteracao_id_produto: false,
  utiliza_tamanho_prod: false,
  utiliza_cor_prod: false,
  utiliza_fulltext_pesquisa: false,
};

export const cadastroProdutoSlice = createSlice({
  name: "cadastro_produto_slice",
  initialState: initialState,
  reducers: {
    setupCadastroProduto: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        // Trata Unidade de Medida Padrão
        if (["unid_med_pad", "cfop_pad"].includes(key)) {
          if (value !== null) {
            value = (value ?? "").trim();

            if (value === "") {
              value = null;
            }
          }
        }
        state[key] = value;
      });
      return state;
    },
    setCfopPad: (state, action) => {
      state.cfop_pad = action.payload;
    },
    setIdGrupoPad: (state, action) => {
      state.id_grupo_pad = action.payload;
    },
    setIdSubGrupoPad: (state, action) => {
      state.id_sub_grupo_pad = action.payload;
    },
    setIdFabricantePad: (state, action) => {
      state.id_fabricante_pad = action.payload;
    },
    setIdTipoProdPad: (state, action) => {
      state.id_tipo_prod_pad = action.payload;
    },
    setUnidMedPad: (state, action) => {
      state.unid_med_pad = action.payload;
    },
    setLangFullText: (state, action) => {
      state.lang_full_text = action.payload;
    },
    setExigeSenhaAltEstoqPeloCadProd: (state, action) => {
      state.exige_senha_alt_estoq_pelo_cad_prod = action.payload;
    },
    setUtilizaAlteracaoIdProduto: (state, action) => {
      state.utiliza_alteracao_id_produto = action.payload;
    },
    setUtilizaTamanhoProd: (state, action) => {
      state.utiliza_tamanho_prod = action.payload;
    },
    setUtilizaCorProd: (state, action) => {
      state.utiliza_cor_prod = action.payload;
    },
    setUtilizaFulltextPesquisa: (state, action) => {
      state.utiliza_fulltext_pesquisa = action.payload;
    },
  },
});

export const {
  setupCadastroProduto,
  setCfopPad,
  setIdGrupoPad,
  setIdSubGrupoPad,
  setIdFabricantePad,
  setIdTipoProdPad,
  setUnidMedPad,
  setLangFullText,
  setExigeSenhaAltEstoqPeloCadProd,
  setUtilizaAlteracaoIdProduto,
  setUtilizaTamanhoProd,
  setUtilizaCorProd,
  setUtilizaFulltextPesquisa,
} = cadastroProdutoSlice.actions;

export default cadastroProdutoSlice.reducer;
