import React from "react";
import { Card } from "reactstrap";
import { Table } from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const DescPorQuantidadeGrid = ({
  dados,
  setSelected,
  alterar,
  excluir,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "id_produto__nome",
      text: "Produto",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "qtd_inicial",
      text: "Quantidade Inical",
      align: "center",
      headerAlign: "center",
      sortable: true,
      formatter: (v) => formatNumber(v, true, 0),
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_final",
      text: "Quantidade Final",
      align: "center",
      headerAlign: "center",
      sortable: true,
      formatter: (v) => formatNumber(v, true, 0),
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_preco_unit",
      text: "Preço Unitário",
      align: "right",
      headerAlign: "right",
      sortable: true,
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: naturalSort,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Card body>
      <Table columns={columns} data={dados} onSelect={setSelected} />
    </Card>
  );
};
