import { apiGetV2 } from "../../../apiV2";

const URL_BASE = "/meta/consulta/pronta_entrega";

const DemonstrativoMetaService = {
  listar: (params) => apiGetV2(`${URL_BASE}/consulta_web/`, params),
  listarSupervisor: (params) =>
    apiGetV2(`${URL_BASE}/consulta_web_supervisor/`, params),
};

export default DemonstrativoMetaService;
