import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { MdForklift } from "react-icons/md";
import { FaTruckArrowRight } from "react-icons/fa6";
import {
  centralRomaneioRoute,
  romaneioRealizadosRoute,
} from "../../../routes/modules/pedidos";

export const Romaneio = () => {
  return (
    <PageContainer title="Romaneio">
      <MenuGroup>
        <MenuGroupButton
          label="Central de Romaneios"
          icon={MdForklift}
          pathname={centralRomaneioRoute.path}
          nroTela={centralRomaneioRoute.nroTela}
        />
        <MenuGroupButton
          label="Romaneios Realizados"
          icon={FaTruckArrowRight}
          pathname={romaneioRealizadosRoute.path}
          nroTela={romaneioRealizadosRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
