import React, { useEffect, useState } from "react";
import {
  BotaoImprimir,
  DatePicker,
  ModalBase,
  RadioGroup,
} from "../../../../../components";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import EstoqueRotaService from "../../../../../services/jornada/estoque_rota/EstoqueRotaService";
import { docPrintReport } from "../../../../../pdf";
import { formatDateISO } from "../../../../../coreUtils";
import { RadioItem } from "../../../../../components/RadioGroup";
import { apiGetV2 } from "../../../../../apiV2";
import { Row } from "reactstrap";

export const ImprimirResumoModal = () => {
  const [data, setData] = useState(new Date());
  const [visao, setVisao] = useState("REA");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    utilizaEstoqueRealAdmProntaEntrega,
    setUtilizaEstoqueRealAdmProntaEntrega,
  ] = useState(false);
  const [tipo, setTipo] = useState("SIM");

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const limparDados = () => {
    setData(new Date());
  };

  const buscarDados = async () => {
    if (!(data instanceof Date)) {
      toastr.warning("Atenção", "Por favor, informe uma data válida.");
      return false;
    }

    return await EstoqueRotaService.buscarResumoEstoque(data, visao);
  };

  const imprimir = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      await docPrintReport(
        "/relatorios/pronta_entrega/posicao_estoque/",
        { dados: ret, data: formatDateISO(data), visao: visao, tipo: tipo },
        "0085"
      );
    }

    setLoading(false);
  };

  const buscarParams = async () => {
    const [ok, ret] = await apiGetV2("tela/lanc_est_pronta_entrega/");
    if (ok) {
      setUtilizaEstoqueRealAdmProntaEntrega(
        ret.utiliza_estoque_real_adm_pronta_entrega
      );
    }
  };

  useEffect(() => {
    if (!utilizaEstoqueRealAdmProntaEntrega) {
      setVisao("CAD");
    }
  }, [utilizaEstoqueRealAdmProntaEntrega]);

  return (
    <>
      <BotaoImprimir onClick={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size={utilizaEstoqueRealAdmProntaEntrega ? "md" : "sm"}
        title="Imprimir Resumo de Estoque ADM"
        onConfirm={imprimir}
        loadingConfirm={loading}
        onClosed={limparDados}
        onBeforeOpen={buscarParams}
      >
        <Row>
          <RadioGroup label="Tipo" value={tipo} onChange={setTipo}>
            <RadioItem label="Analítico" value="ANA" />
            <RadioItem label="Simplificado" value="SIM" />
          </RadioGroup>
          {tipo === "ANA" && (
            <DatePicker
              label="Data"
              md={4}
              onChange={(v) => setData(moment.isMoment(v) ? v.toDate() : v)}
              value={data}
            />
          )}
          {utilizaEstoqueRealAdmProntaEntrega && (
            <RadioGroup label="Visão" value={visao} onChange={setVisao}>
              <RadioItem label="Estoque Cadastral" value="CAD" />
              <RadioItem label="Estoque Real ADM" value="REA" />
            </RadioGroup>
          )}
        </Row>
      </ModalBase>
    </>
  );
};
