import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormCheckbox,
  IntegerFormInput,
  PageContainer,
} from "../../../../components";
import { apiGetV2 } from "../../../../apiV2";
import { ImprimirNfSaidaGeralModal } from "./components/ImprimirNfSaidaGeralModal";
import { NfSaidaGeralGrid } from "./components/NfSaidaGeralGrid";
import { DetalhesNfModal } from "../../../docs_eletron/notas_fiscais/nota_fiscal/components/DetalhesNfModal";
import { DetalhesNFCeModal } from "../../../docs_eletron/notas_fiscais/nota_fiscal_consumidor/components/DetalhesNFCeModal";
import { ExportarNfSaidaGeralModal } from "./components/ExportarNfSaidaGeralModal";

const tipoContribuinteOpts = [
  { label: "Todos", value: "T" },
  { label: "Contribuinte", value: "CONTRIB" },
  { label: "Não Contribuinte", value: "NAOCONTRIB" },
  { label: "Isento", value: "ISENTO" },
];

export const NfSaidaGeral = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idTipoDoc, setIdTipoDoc] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [serie, setSerie] = useState(null);
  const [tipoContribuinte, setTipoContribuinte] = useState(
    tipoContribuinteOpts[0].value
  );
  const [incluirNfe, setIncluirNfe] = useState(true);
  const [incluirNfce, setIncluirNfce] = useState(true);
  const [incluirNfse, setIncluirNfse] = useState(true);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [detalhesNfOpen, setDetalhesNfOpen] = useState(false);
  const [detalhesNfConsumidorOpen, setDetalhesNfConsumidorOpen] =
    useState(false);

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = (agruparPor, ordenarPor) => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      ordenar_por: ordenarPor,
      id_tipo_doc: idTipoDoc,
      tipo_contribuinte: tipoContribuinte === "T" ? null : tipoContribuinte,
      agrupar_por: agruparPor,
      id_cliente: idCliente,
      serie: serie,
      incluir_nfe: incluirNfe,
      incluir_nfce: incluirNfce,
      incluir_nfse: incluirNfse,
    };

    return apiGetV2(`/relatorios/nf_saida/geral/`, params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados("NAO_AGR", "EMI");
    setDados(ok ? ret.notas : []);
    setTotais(ok ? ret.totais : {});
    setLoading(false);
  };

  const toggleDetalhesNf = () => setDetalhesNfOpen(!detalhesNfOpen);

  const toggleDetalhesNfConsumidor = () =>
    setDetalhesNfConsumidorOpen(!detalhesNfConsumidorOpen);

  const mostrarDetalhes = (modelo, id) => {
    setSelected(id);
    setTimeout(() => {
      if (modelo === "55") {
        toggleDetalhesNf();
      } else if (modelo === "65") {
        toggleDetalhesNfConsumidor();
      }
    }, 1);
  };

  useEffect(limparDados, [incluirNfe, incluirNfce, incluirNfse]);

  return (
    <PageContainer title="NF Saída Geral" number="5046" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            label="Cliente"
            isSearchable
            isClearable
            isConcatField
            concatModelName="cliente"
            onChange={(s) => setIdCliente(s?.value)}
          />
          <ComboBox
            label="Tipo de Contribuinte"
            options={tipoContribuinteOpts}
            md={2}
            onChange={(selected) => setTipoContribuinte(selected?.value)}
            isSearchable={false}
            defaultValue={tipoContribuinte}
          />
          <AsyncComboBox
            md={4}
            label="Tipo de Documento"
            isSearchable
            isClearable
            isConcatField
            concatModelName="tipo_documento"
            onChange={(s) => setIdTipoDoc(s?.value)}
            defaultOptions
          />
          <IntegerFormInput
            md={1}
            label="Série"
            defaultValue={serie}
            onChange={setSerie}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} label="Emissão" />
          <FormCheckbox
            label="NFe - Modelo 55"
            checked={incluirNfe}
            onChange={() => setIncluirNfe(!incluirNfe)}
            disabled={!incluirNfce && !incluirNfse}
          />
          <FormCheckbox
            label="NFCe - Modelo 65"
            checked={incluirNfce}
            onChange={() => setIncluirNfce(!incluirNfce)}
            disabled={!incluirNfe && !incluirNfse}
          />
          <FormCheckbox
            label="NFSe - Nota Fiscal de Serviço"
            checked={incluirNfse}
            onChange={() => setIncluirNfse(!incluirNfse)}
            disabled={!incluirNfe && !incluirNfce}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <ImprimirNfSaidaGeralModal
            buscarDados={buscarDados}
            dataIni={dataIni}
            dataFim={dataFim}
          />
          <ExportarNfSaidaGeralModal
            buscarDados={buscarDados}
            dataIni={dataIni}
            dataFim={dataFim}
          />
          <DetalhesNfModal
            isOpen={detalhesNfOpen}
            toggle={toggleDetalhesNf}
            selected={selected}
          />
          <DetalhesNFCeModal
            isOpen={detalhesNfConsumidorOpen}
            toggle={toggleDetalhesNfConsumidor}
            selected={selected}
          />
        </Row>
      </Card>
      <CardTotais>
        {incluirNfe && (
          <CardTotaisItem label="Valor Total NFe" value={totais?.nfes} />
        )}
        {incluirNfce && (
          <CardTotaisItem
            label="Valor Total NF Consumidor"
            value={totais?.nfces}
          />
        )}
        {incluirNfse && (
          <CardTotaisItem
            label="Valor Total NF Serviço"
            value={totais?.nfses}
          />
        )}
      </CardTotais>
      <Card body>
        <NfSaidaGeralGrid dados={dados} mostrarDetalhes={mostrarDetalhes} />
      </Card>
    </PageContainer>
  );
};
