import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pista: "",
  finalidade: null,
  idProduto: null,
};

export const listagemRegraTribSlice = createSlice({
  name: "listagem_regra_trib_slice",
  initialState: initialState,
  reducers: {
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setPista: (state, action) => {
      state.pista = action.payload;
    },
    setFinalidade: (state, action) => {
      state.finalidade = action.payload;
    },
    setIdProduto: (state, action) => {
      state.idProduto = action.payload;
    },
  },
});

export const { setup, setPista, setFinalidade, setIdProduto } =
  listagemRegraTribSlice.actions;

export default listagemRegraTribSlice.reducer;
