import React, { useState } from "react";
import { ModalBase, NumberInput, TextInput } from "../../../../../components";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { Row } from "reactstrap";

export const AlterarLimiteCredModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
  nomeCliente,
}) => {
  const [limiteCred, setLimiteCred] = useState(0);
  const [vlrTotPend, setVlrTotPend] = useState(0);
  const [novoLimiteCred, setNovoLimiteCred] = useState(0);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setLimiteCred(0);
    setVlrTotPend(0);
    setNovoLimiteCred(0);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setLoading(false);
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Alterar Limite de Crédito no Crédito Rotativo"
        number="0068_2"
        onClosed={limparDados}
      >
        <Row>
          <TextInput
            label="Cliente"
            value={formatValueFromAPI(nomeCliente, selected)}
            disabled
          />
        </Row>
        <Row>
          <NumberInput
            label="Limite de Crédito Atual"
            value={limiteCred}
            disabled
          />
          <NumberInput
            label="Valor Total Pendente"
            value={vlrTotPend}
            disabled
          />
          <NumberInput
            label="Novo Limite de Crédito"
            value={novoLimiteCred}
            onChange={setVlrTotPend}
          />
        </Row>
      </ModalBase>
    </>
  );
};
