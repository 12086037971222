import { Card, Row } from "reactstrap";
import {
  BotaoPesquisar,
  FormCheckbox,
  LinkButton,
  PageContainer,
  SearchInput,
} from "../../../../components";
import CadastroCfopService from "../../../../services/cadastro/CadastroCfopService";
import React, { useState } from "react";
import { ModalExcluirV2 } from "../../../../components/cadastro";
import {
  debounce,
  defaultDebounceTime,
  MODAL_ACTIONS,
} from "../../../../coreUtils";
import { CfopGrid } from "./components/CfopGrid";
import { incluirAlterarCfopRoute } from "../../../../routes/modules/cadastro";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const routesBaseCfop = CadastroCfopService.urlBase;

export const Cfop = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [somenteAtivos, setSomenteAtivos] = useState(false);

  const history = useHistory();

  const buscarDados = async (p) => {
    const params = {
      pista: p,
      somente_ativos: somenteAtivos,
    };
    const [ok, ret] = await CadastroCfopService.listar(params);

    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoading(true);
    await buscarDados(v);
    setLoading(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (codigo) => {
    history.push(incluirAlterarCfopRoute.path, {
      action: MODAL_ACTIONS.EDIT,
      selected: codigo,
    });
  };

  const excluir = (codigo) => {
    setSelected(codigo);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de CFOP" number="0119" canGoBack>
      <Card body>
        <Row>
          <SearchInput
            md={4}
            placeholder="Descrição"
            onChange={handlePista}
            loading={loading}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <LinkButton
            pathname={incluirAlterarCfopRoute.path}
            state={{ action: MODAL_ACTIONS.ADD }}
            md="auto"
            color="info"
          >
            Incluir
          </LinkButton>
          <FormCheckbox
            label="Somente Ativos"
            checked={somenteAtivos}
            onChange={() => setSomenteAtivos(!somenteAtivos)}
          />
          <ModalExcluirV2
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            selected={selected}
            number="0119_2"
            notifyEvent={notifyEvent}
            routeBase={routesBaseCfop}
          />
        </Row>
      </Card>
      <Card body>
        <CfopGrid
          dados={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
