import React from "react";
import { useState } from "react";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import {
  IntegerFormInput,
  ModalCadastro,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";

export const CadastroUnidadeModal = ({
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [codigo, setCodigo] = useState("");
  const [nome, setNome] = useState("");
  const [qtdCasasDecimaisQtd, setQtdCasasDecimaisQtd] = useState(2);
  const [qtdCasasDecimaisVlrs, setQtdCasasDecimaisVlrs] = useState(2);

  const limparDados = () => {
    setAction(MODAL_ACTIONS.ADD);
    setCodigo("");
    setNome("");
    setQtdCasasDecimaisQtd(2);
    setQtdCasasDecimaisVlrs(2);
  };

  const fetchData = (data) => {
    setCodigo(data.codigo);
    setNome(data.nome);
    setQtdCasasDecimaisQtd(data.qtd_casas_decimais_qtd);
    setQtdCasasDecimaisVlrs(data.qtd_casas_decimais_vlrs);
  };

  const submitPayload = () => {
    const payload = {
      codigo: codigo,
      nome: nome,
      qtd_casas_decimais_qtd: qtdCasasDecimaisQtd,
      qtd_casas_decimais_vlrs: qtdCasasDecimaisVlrs,
    };

    return payload;
  };

  return (
    <ModalCadastro
      title="Unidade de Medida"
      size="sm"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase="/cadastro/unidade_med"
      number="0076_1"
      deleteModalNumber="0076_2"
      selected={selected}
      notifyEvent={notifyEvent}
      onOpen={setAction}
      concatShortcut={concatShortcut}
      concatModelName="unidade"
    >
      <Row>
        <TextInput
          md={5}
          label="Código"
          value={codigo}
          onChange={(e, v) => setCodigo(v)}
          maxLength={6}
          disabled={action === MODAL_ACTIONS.EDIT}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={50}
        />
      </Row>
      <Row>
        <IntegerFormInput
          md={"auto"}
          label="Casas Decimais - Quantidades"
          mdInput={4}
          defaultValue={qtdCasasDecimaisQtd}
          onChange={setQtdCasasDecimaisQtd}
        />
        <IntegerFormInput
          md={"auto"}
          label="Casas Decimais - Valores"
          mdInput={4}
          defaultValue={qtdCasasDecimaisVlrs}
          onChange={setQtdCasasDecimaisVlrs}
        />
      </Row>
    </ModalCadastro>
  );
};
