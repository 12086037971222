import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoImprimir,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../components";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";
import { formatDateISO } from "../../../../coreUtils";

export const ResumoResultado = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const buscarDados = async () =>
    await apiGetV2(`/controladoria/resumo_resultado/`, {
      data_ini: dataIni,
      data_fim: dataFim,
    });

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
        dados: ret,
      };
      await docPrintReport(
        "/relatorios/controladoria/resumo_resultado/",
        payload,
        "5047"
      );
    }
    setLoadingImprimir(false);
  };

  return (
    <PageContainer title="Resumo Resultado" number="5047" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoImprimir
            onClick={imprimir}
            loading={loadingImprimir}
            disabled={!(dataIni instanceof Date) || !(dataFim instanceof Date)}
            disabledHint="Informe datas válidas de início e fim"
          />
        </Row>
      </Card>
    </PageContainer>
  );
};
