import { apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/jornada/conferencia_adm";

const ConferenciaValoresJornadaService = {
  listar: async (idRota) =>
    await apiGetV2(`${URL_BASE}/listar/`, { id_rota: idRota }),
  buscarDadosDia: async (idDia) =>
    await apiGetV2(`${URL_BASE}/dados_dia/${idDia}/`),
  confirmarConferencia: async (payload) =>
    await apiPostV2(`${URL_BASE}/confere_jorn_dia_adm/`, payload),
  reabrirConferencia: async (payload) =>
    await apiPostV2(`${URL_BASE}/reabrir_conferencia/`, payload),
};

export default ConferenciaValoresJornadaService;
