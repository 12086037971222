import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormButton, ModalBase, Table } from "../../../../../components";

export const Alertas = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const state = useSelector((state) => state.gerenciamentoVendasMobile);

  const toggle = () => setModalOpen(!modalOpen);

  return (
    <>
      <FormButton
        md="auto"
        color={state.alertas.length > 0 ? "danger" : "primary"}
        onClick={toggle}
      >{`Alertas (${state.alertas.length})`}</FormButton>
      {modalOpen && <AlertasModal toggle={toggle} />}
    </>
  );
};

const AlertasModal = ({ toggle }) => {
  const state = useSelector((state) => state.gerenciamentoVendasMobile);

  return (
    <ModalBase
      isOpen
      toggle={toggle}
      size="md"
      title={
        <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          Alertas
        </h5>
      }
      autoFocus
      hideCancelButton
    >
      <AlertasTable alertas={state.alertas} />
    </ModalBase>
  );
};

const AlertasTable = ({ alertas }) => {
  const _columns = [
    {
      dataField: "alerta",
      text: "Alerta",
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <Table
      columns={_columns}
      data={alertas}
      keyField="alerta"
      paginated={false}
    />
  );
};
