import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { Card, CardTitle, Col, Fade, FormGroup, Label } from "reactstrap";
import { ComboBox, ExpandableTable, Filter } from "../../../../components";
import { BotaoImprimir } from "../../../../components/BotaoImprimir";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { PageContainer } from "../../../../components/PageContainer";
import FiltersService from "../../../../services/FiltersService";
import ComissaoService from "../../../../services/relatorios/comissionamento/ComissaoService";
import columns from "./columns";
import { userPodeVerTotaisRelVenda } from "../../../../coreUtils";

class ComissaoFixaOS extends Component {
  state = {
    isLoadingSearch: false,
    isLoadingPrint: false,
    filters: [],
    type: "V",
    seller: null,
    options: [],
    data: { data: [] },
  };
  comboColabTecnicoRef = React.createRef();

  limparDados() {
    this.setState({
      data: { data: [] },
    });
  }

  handleDate = (dataIni, dataFim) => {
    this.setState((prevState) => {
      let { filters } = prevState;

      filters = filters.filter((e) => {
        const key = Object.keys(e)[0];
        return key !== "start" && key !== "end";
      });

      if (dataIni !== undefined && dataIni !== undefined) {
        // Caso a data esteja informada, o filtro é adicionado
        prevState["filters"] = [
          ...prevState["filters"],
          {
            start: dataIni?.toISOString().split("T")[0],
          },
          {
            end: dataFim?.toISOString().split("T")[0],
          },
        ];
      }

      return prevState;
    });
    this.limparDados();
  };

  handleLoad = async (value) => {
    await FiltersService.fetch(
      this.state.type === "V" ? "colaborador" : "tecnico",
      `nome__icontains=${value.toUpperCase()}`
    ).then((data) => {
      this.setState({ options: data });
    });
  };

  fetchData = async () => {
    this.setState({ isLoadingSearch: true });

    if (!this.validate()) {
      this.setState({ isLoadingSearch: false });
      return;
    }

    const ret = await ComissaoService.fetch(this.state);

    if (ret === undefined) {
      this.setState({ isLoadingSearch: false });
      return;
    }

    this.setState({
      data: ret,
    });

    this.setState({ isLoadingSearch: false });
  };

  printPDF = async () => {
    this.setState({ isLoadingPrint: true });
    if (!this.validate()) {
      this.setState({ isLoadingPrint: false });
      return;
    }

    ComissaoService.printPDF(this.state);
    this.setState({ isLoadingPrint: false });
  };

  validate = () => {
    const { filters, type } = this.state;

    let filters_keys = [];
    filters.forEach((element) => {
      filters_keys.push(...Object.keys(element));
    });
    const hasDate =
      filters_keys.includes("start") && filters_keys.includes("end");

    if (!hasDate) {
      toastr.warning(
        "Período não informado.",
        "É necessário que seja informado um período."
      );
      return false;
    }

    if (type === "V") {
      if (!filters_keys.includes("id_usuario")) {
        toastr.warning(
          "Colaborador não informado.",
          "Por favor, selecione o colaborador desejado."
        );
        return false;
      }
    } else {
      if (!filters_keys.includes("id_tecnico")) {
        toastr.warning(
          "Técnico não informado.",
          "Por favor, selecione o técnico desejado."
        );
        return false;
      }
    }

    return true;
  };

  componentDidMount() {
    FiltersService.fetch("colaborador").then((data) => {
      this.setState({ options: data });
    });
  }

  componentWillMount() {
    const podeVerTotais = userPodeVerTotaisRelVenda();

    if (podeVerTotais) {
      this.setState((prevState) => {
        prevState["filters"] = [
          ...prevState["filters"],
          {
            id_usuario: Number.parseInt(localStorage.getItem("id_colaborador")),
          },
        ];
        prevState["admin"] = podeVerTotais;

        return prevState;
      });
    }
  }

  handleSelect = (selected, target) => {
    this.setState((prevState) => {
      const { name } = target;

      prevState["filters"] = prevState["filters"].filter(
        (element) => !Object.keys(element).includes(name)
      );

      if (selected)
        prevState["filters"] = [
          ...prevState["filters"],
          { [name]: selected["value"] },
        ];

      return prevState;
    });
  };

  render() {
    const { isLoadingSearch, isLoadingPrint, data, options, type } = this.state;
    const tipoOpts = [
      { label: "Ordem de Serviço", value: "O" },
      { label: "Venda", value: "V" },
    ];
    return (
      <PageContainer title="Comissão Fixa OS" number="5009" canGoBack>
        <Filter
          title="Comissão Fixa OS"
          hasDateInput
          handleDate={this.handleDate}
        >
          <ComboBox
            md={3}
            label="Tipo"
            options={tipoOpts}
            defaultValue={type}
            hint={
              "Somente usuários master podem alterar as opções de tipo e " +
              "colaborador/técnico"
            }
            onChange={(selected) => {
              if (selected?.value !== type) {
                this.setState({ type: selected.value }, () => {
                  this.handleLoad("");
                  this.comboColabTecnicoRef.current.clearValue();
                  this.setState((prevState) => {
                    const { filters } = prevState;

                    filters["id_tecnico"] = null;
                    filters["id_usuario"] = null;

                    prevState["filters"] = filters;
                    return prevState;
                  });
                });
              }
            }}
            isDisabled={!userPodeVerTotaisRelVenda()}
          />
          {!userPodeVerTotaisRelVenda() ? (
            <Col md={4}>
              <FormGroup>
                <Label>Colaborador</Label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="id_usuario"
                  isDisabled
                  value={options.filter(
                    (element) =>
                      element["value"] ===
                      Number.parseInt(localStorage.getItem("id_colaborador"))
                  )}
                  ref={this.comboColabTecnicoRef}
                />
              </FormGroup>
            </Col>
          ) : (
            <ComboBox
              md={4}
              label={type === "V" ? "Colaborador" : "Técnico"}
              onChange={this.handleSelect}
              name={type === "V" ? "id_usuario" : "id_tecnico"}
              options={options}
              isSearchable
              isClearable
              ref={this.comboColabTecnicoRef}
            />
          )}
          <BotaoPesquisar onClick={this.fetchData} loading={isLoadingSearch} />
          <BotaoImprimir
            onClick={this.printPDF}
            loading={isLoadingPrint}
            md={1}
          />
        </Filter>
        {data.data.length > 0 && (
          <Fade in={true}>
            <Card>
              <CardTitle tag={"h5"} className="pt-3 pl-3">
                Total: <b>R$ {data.total}</b>
              </CardTitle>
              <ExpandableTable data={data} columns={columns} />
            </Card>
          </Fade>
        )}
      </PageContainer>
    );
  }
}

export default ComissaoFixaOS;
