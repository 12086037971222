import React from "react";
import { Edit } from "react-feather";
import { IconButton } from "../../../../../../components/IconButton";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  sumDataField,
} from "../../../../../../coreUtils";
import { ExcluirProdutoModal } from "./ExcluirProdutoModal";
import { Table } from "../../../../../../components";

export const PedidoItensGrid = ({
  data,
  identificaProdutoRevenda,
  handleDelete,
  toggleEdit,
}) => {
  const columns = [
    {
      dataField: "desc_tipo",
      text: "Tipo",
      align: "center",
      sortable: true,
    },
    {
      dataField: "id_produto",
      text: "# Produto",
      align: "center",
      sortable: true,
      footer: (
        <span className="table-record-count">
          {data.length} ite{data.length !== 1 ? "ns" : "m"}
        </span>
      ),
    },
    {
      dataField: "nome_produto",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "revenda",
      text: "Revenda",
      align: "center",
      sortable: true,
      formatter: (c) => (c ? "Sim" : "Não"),
      hidden: !identificaProdutoRevenda,
    },
    {
      dataField: "vlr_unit",
      text: "Vlr. Unit",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_tot_desc",
      text: "Vlr. Desc",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
      footer: formatNumber(sumDataField(data, "vlr_tot_desc"), true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_tot_prod",
      text: "Tot. Prod.",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
      footer: formatNumber(sumDataField(data, "vlr_tot_prod"), true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_tot_icms_st",
      text: "ICMS ST",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
      footer: formatNumber(sumDataField(data, "vlr_tot_icms_st"), true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_tot_ipi",
      text: "IPI",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
      footer: formatNumber(sumDataField(data, "vlr_tot_ipi"), true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_tot_troca",
      text: "Troca",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
      footer: formatNumber(sumDataField(data, "vlr_tot_troca"), true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_tot_bonif",
      text: "Bonif",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
      footer: formatNumber(sumDataField(data, "vlr_tot_bonif"), true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
      footer: formatNumber(sumDataField(data, "vlr_total"), true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "act_delete",
      isDummyField: true,
      align: "center",
      formatter: (c, row, index) => (
        <ExcluirProdutoModal
          nome={formatValueFromAPI(row.nome_produto, row.id_produto)}
          handleDelete={() => handleDelete(index)}
        />
      ),
    },
    {
      dataField: "act_edit",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <IconButton
          icon={Edit}
          onClick={() => toggleEdit(row.id_produto, row.tipo)}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={data}
      noWrap
      keyField="id_produto"
      showRegisterCount={false}
      paginated={false}
      pageSize={Math.min(15, (data ?? []).length + 1)}
    />
  );
};
