import React from "react";
import { Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const numberFormatter = (v, exportacao) =>
  exportacao ? parseFloat(v) : formatNumber(v, true, 2);

export const columnsTitulosRecebMaos = (exportacao) => [
  { dataField: "id_duplic_rec_mov", text: "ID Título", align: "center" },
  { dataField: "emissao", text: "Emissão", align: "center" },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  { dataField: "banco", text: "Banco", align: "left" },
  {
    dataField: "numero",
    text: "Número",
    align: "center",
    formatter: (c, row) => (row.parcela ? `${c}-${row.parcela}` : c),
  },
  { dataField: "nosso_numero", text: "Nosso Número", align: "center" },
  { dataField: "vencimento", text: "Vencimento", align: "center" },
  {
    dataField: "vlr_original",
    text: "Vlr. Original",
    align: "right",
    formatter: (c) => numberFormatter(c, exportacao),
  },
  { dataField: "rota", text: "Rota", align: "left" },
  { dataField: "recebimento", text: "Recebimento", align: "center" },
  {
    dataField: "vlr_recebido",
    text: "Vlr. Recebido",
    align: "right",
    formatter: (c) => numberFormatter(c, exportacao),
  },
];

export const TitulosRecebMaosGrid = ({ dados }) => {
  return (
    <Table
      data={dados}
      columns={columnsTitulosRecebMaos(false)}
      paginated={false}
    />
  );
};
