import React from "react";
import { useState } from "react";
import ProgramacaoProducaoService from "../../../../../services/industria/ProgramacaoProducaoService";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  MODAL_ACTIONS,
  dateFromLocaleString,
  formatDateISO,
} from "../../../../../coreUtils";
import { showWarning } from "../../../../../components/AlertaModal";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  DatePicker,
  NumberInput,
} from "../../../../../components";
import { routesBaseFichaTecnica } from "../../ficha_tecnica/FichaTecnica";
import { apiGetV2 } from "../../../../../apiV2";
import moment from "moment";

export const IncluirAlterarProgProducaoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [idProdutoFinal, setIdProdutoFinal] = useState(null);
  const [nomeProdutoFinal, setNomeProdutoFinal] = useState("");
  const [idFichaTecnica, setIdFichaTecnica] = useState(null);
  const [quantidadeProduzir, setQuantidadeProduzir] = useState(null);
  const [dataProd, setDataProd] = useState(new Date());
  const [fichasTecnicas, setFichasTecnicas] = useState([]);

  const limparDados = () => {
    setIdProdutoFinal(null);
    setNomeProdutoFinal("");
    setIdFichaTecnica(null);
    setQuantidadeProduzir(null);
    setDataProd(new Date());
    setFichasTecnicas([]);
  };

  const buscarFichasTecnicasProd = async (idProd, setPrimeiraFicha) => {
    const [ok, ret] = await apiGetV2(`${routesBaseFichaTecnica}/listar/`, {
      id_produto_final: idProd,
      mostrar_inativas: true,
    });
    setFichasTecnicas(
      ok ? ret.map((e) => ({ label: e.nome_produto, value: e.id })) : []
    );
    if (setPrimeiraFicha) {
      const ficha = ret.find((e) => e.ativo);
      if (ficha) {
        setIdFichaTecnica(ficha.id);
      }
    }
  };

  const fetchData = (data) => {
    setIdProdutoFinal(data.id_produto_final);
    setNomeProdutoFinal(data.nome_produto_final);
    setIdFichaTecnica(data.id_ficha_tecnica);
    buscarFichasTecnicasProd(data.id_produto_final);
    setQuantidadeProduzir(parseFloat(data.quantidade_produzir));
    setDataProd(dateFromLocaleString(data.data_producao));
  };

  const handleSetProdutoFinal = (v, s) => {
    if (v === idProdutoFinal) return;
    setIdFichaTecnica(null);
    setFichasTecnicas([]);
    if (v) {
      setIdProdutoFinal(v);
      setNomeProdutoFinal(s.nome);
      buscarFichasTecnicasProd(v, true);
    } else {
      setIdProdutoFinal(null);
      setNomeProdutoFinal("");
    }
  };

  const handleSetFichaTecnica = (v) => {
    if (v === idFichaTecnica) return;
    setIdFichaTecnica(v);
  };

  const submitPayload = (action) => {
    if ([0, null, undefined].includes(idProdutoFinal)) {
      showWarning("Por favor, informe o Produto Final");
      return false;
    }
    if ([0, null, undefined].includes(idFichaTecnica)) {
      showWarning("Por favor, informe a Ficha Técnica");
      return false;
    }
    if ([0, null, undefined].includes(quantidadeProduzir)) {
      showWarning("Por favor, informe a Quantidade a ser produzida");
      return false;
    }
    if (!(dataProd instanceof Date)) {
      showWarning("Por favor, informe a Data de Produção");
      return false;
    }

    const payload = {
      id_produto_final: idProdutoFinal,
      nome_produto_final: nomeProdutoFinal,
      id_ficha_tecnica: idFichaTecnica,
      quantidade_produzir: quantidadeProduzir,
      data_prod: formatDateISO(dataProd),
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_programacao: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title="Programação de Produção"
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={ProgramacaoProducaoService.urlBase}
      number="0094_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <AsyncComboBox
          md={12}
          label="Produto"
          concatModelName="produto"
          defaultValue={idProdutoFinal}
          onChange={(s) => handleSetProdutoFinal(s?.value, s)}
          hideShortcut
        />
      </Row>
      <Row>
        <ComboBox
          md={12}
          label="Ficha Técnica"
          options={fichasTecnicas}
          defaultValue={idFichaTecnica}
          onChange={(s) => handleSetFichaTecnica(s?.value)}
        />
      </Row>
      <Row>
        <NumberInput
          md={5}
          label="Qtd Estimada de Produção"
          value={quantidadeProduzir}
          onChange={setQuantidadeProduzir}
          decimalPlaces={3}
        />
        <DatePicker
          label="Data de Produção"
          md={5}
          value={dataProd}
          onChange={(v) => setDataProd(moment.isMoment(v) ? v.toDate() : v)}
        />
      </Row>
    </ModalCadastroV2>
  );
};
