import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { AiOutlineCar, AiOutlineFileText } from "react-icons/ai";
import { BsCashCoin } from "react-icons/bs";
import { CgToolbox } from "react-icons/cg";
import {
  cadastroGrupoNaoVerifEstoqueRoute,
  cadastroPermissoesCashbackRoute,
  cadastroTabFatNfDescVendaRoute,
  cadastroVeiculoEmpresaRoute,
  cadastroVendedorTecnicoServicoRoute,
} from "../../../routes/modules/cadastro";
import { LuPackageMinus } from "react-icons/lu";

export const Outros = () => {
  return (
    <PageContainer title="Cadastros - Outros">
      <MenuGroup>
        <MenuGroupButton
          label="Grupo Não Verif. Estoque"
          icon={LuPackageMinus}
          pathname={cadastroGrupoNaoVerifEstoqueRoute.path}
          nroTela={cadastroGrupoNaoVerifEstoqueRoute.nroTela}
        />
        <MenuGroupButton
          label="Uso de Cashback"
          icon={BsCashCoin}
          pathname={cadastroPermissoesCashbackRoute.path}
          nroTela={cadastroPermissoesCashbackRoute.nroTela}
        />
        <MenuGroupButton
          label="Veículo da Empresa"
          icon={AiOutlineCar}
          pathname={cadastroVeiculoEmpresaRoute.path}
          nroTela={cadastroVeiculoEmpresaRoute.nroTela}
        />
        <MenuGroupButton
          label="Vend. Técnico de Serviços"
          icon={CgToolbox}
          pathname={cadastroVendedorTecnicoServicoRoute.path}
          nroTela={cadastroVendedorTecnicoServicoRoute.nroTela}
        />
        <MenuGroupButton
          label="Tabela Negociação"
          icon={AiOutlineFileText}
          pathname={cadastroTabFatNfDescVendaRoute.path}
          nroTela={cadastroTabFatNfDescVendaRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
