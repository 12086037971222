import React from "react";
import { IconButton, Table } from "../../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../../coreUtils";
import { BsCheck2, BsX } from "react-icons/bs";
import { ProdServIcone } from "../../../../../../components/ProdServIcone";

export const ItensOrcamentoOSGrid = ({
  itens,
  setQtdAprovadaItem,
  orcamentoAberto,
}) => {
  const colums = [
    {
      dataField: "tipo",
      text: "T",
      align: "center",
      headerAlign: "center",
      editable: false,
      colWidth: "30px",
      formatter: (c, row) => <ProdServIcone value={c} />,
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_item),
    },
    {
      dataField: "quantidade",
      text: "Qtd Original",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_unit",
      text: "Vlr. Unitário",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "desc_status",
      text: "Status",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "qtd_aprovada",
      text: "Qtd Autorizada",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      decimalPlaces: 2,
    },
    {
      dataField: "sc",
      text: "Aprovado",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => {
        const itemAprovado = row.qtd_aprovada === row.quantidade;
        return (
          <IconButton
            icon={itemAprovado ? BsCheck2 : BsX}
            size={16}
            color={itemAprovado ? "green" : "red"}
            onClick={
              orcamentoAberto
                ? () =>
                    setQtdAprovadaItem(
                      itemAprovado ? 0 : parseFloat(row.quantidade),
                      row.tipo,
                      row.id
                    )
                : () => {}
            }
          />
        );
      },
    },
    {
      dataField: "vlr_aprovado",
      text: "Total Autorizado",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
  ];

  return (
    <Table data={itens} columns={colums} paginated={false} pageSize={10} />
  );
};
