import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import FormButton from "../../../../../../components/FormButton";
import service from "../../../../../../services/ordem_servico/VistoriaTecnicaService";
import {
  localInstAsPayload,
  reset as resetLocalInst,
} from "../store/local_instalacao_modulos_slice";
import {
  reset as resetVistoria,
  vistoriaAsPayload,
} from "../store/vistoria_slice";

const checkVazio = (obj) => {
  let invalidArray = [
    0,
    "",
    null,
    undefined,
    "NAO",
    "NAO DEFINIDO",
    false,
    "0",
  ];
  let countDados = 0;
  obj.forEach(function(i) {
    Object.keys(i).forEach(function(j) {
      if (!invalidArray.includes(i[j])) {
        countDados++;
      }
    });
  });
  if (countDados > 0) {
    return obj;
  } else {
    return [];
  }
};

export const BtnGravar = () => {
  const vistoriaTecnica = useSelector((state) => state.vistoriaTecnica);
  const localInstalacaoModulos = useSelector(
    (state) => state.localInstalacaoModulos
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async () => {
    const vistoriaJSON = vistoriaAsPayload(vistoriaTecnica);
    const localInstalaJSON = localInstAsPayload(localInstalacaoModulos);
    vistoriaJSON["local_instal"] = [localInstalaJSON];
    vistoriaJSON["local_instal"] = checkVazio(vistoriaJSON["local_instal"]);

    setLoading(true);
    let ret;
    if (!vistoriaTecnica.idVistoria) {
      ret = await service.incluirVistoria(vistoriaJSON);
    } else {
      ret = await service.alterarVistoria(
        vistoriaTecnica.idVistoria,
        vistoriaJSON
      );
    }
    setLoading(false);
    if (ret === true) {
      dispatch(resetVistoria());
      dispatch(resetLocalInst());
      history.goBack();
    }
  };

  return (
    <FormButton color="info" md="2" onClick={handleSubmit} loading={loading}>
      Gravar
    </FormButton>
  );
};
