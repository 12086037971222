import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";
import { docPrintReport } from "../../pdf";

const URL_BASE = "/remessa_entre_empr";

const RemessaEntreEmpresasService = {
  alterar: async (payload) => await apiPutV2(`${URL_BASE}/alterar/`, payload),
  buscarMatriz: async (idRemessa, licencaMatriz) =>
    await apiGetV2(`${URL_BASE}/buscar_matriz/${idRemessa}/${licencaMatriz}/`),
  buscar: async (idRemessa) =>
    await apiGetV2(`${URL_BASE}/buscar/${idRemessa}/`),
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir/`, payload),
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  listarRemessasReceber: async () =>
    await apiGetV2(`${URL_BASE}/listar_remessas_receber/`),
  gerarRemessa: async (payload) =>
    await apiPostV2(`${URL_BASE}/gerar_remessa/`, payload),
  receberRemessa: async (payload) =>
    await apiPostV2(`${URL_BASE}/receber_remessa/`, payload),

  imprimirSeparacao: async (id, receber, licencaMatriz) => {
    const [ok, ret] = receber
      ? await RemessaEntreEmpresasService.buscarMatriz(id, licencaMatriz)
      : await RemessaEntreEmpresasService.buscar(id);

    if (ok) {
      const payload = { dados: ret, receber: receber };
      await docPrintReport("/remessa_entre_empr/separacao/", payload, "0098");
    }
  },
};

export default RemessaEntreEmpresasService;
