import React from "react";
import { ModalBase, Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "titulo",
    text: "Descrição",
    align: "left",
  },
  { dataField: "data", text: "Data", align: "center" },
  {
    dataField: "vlr_total",
    text: "Valor",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const DetalhesAvisosVendasModal = ({ isOpen, toggle, dados }) => {
  return (
    <ModalBase isOpen={isOpen} toggle={toggle} size="lg" title={dados.titulo}>
      <Table
        data={dados?.avisos ?? []}
        columns={columns}
        paginated={false}
        pageSize={15}
        showRegisterCount={false}
      />
    </ModalBase>
  );
};
