import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { AsyncComboBox } from "../../../../../components";
import { BotaoImprimir } from "../../../../../components/BotaoImprimir";
import { BotaoPesquisar } from "../../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../../components/FiltroPeriodoDatas";
import { formatDateISO } from "../../../../../coreUtils";
import { apiGetV2 } from "../../../../../apiV2";
import { docPrintReport } from "../../../../../pdf";

export const FiltrosComissionamentoVTS = ({
  buscarDadosRelatorio,
  limparDados,
}) => {
  const [idVTS, setIdVTS] = useState(null);
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const handleDates = (dataIni, dataFim) => {
    setDataInicial(
      dataIni instanceof Date ? dataIni.toISOString().split("T")[0] : dataIni
    );
    setDataFinal(
      dataFim instanceof Date ? dataFim.toISOString().split("T")[0] : dataFim
    );
    limparDados();
  };

  const handlePesquisar = async () => {
    setLoading(true);
    const filters = {};
    const invalidArray = [0, null, undefined];
    if (!invalidArray.includes(idVTS)) filters["id_vts"] = idVTS;
    if (!invalidArray.includes(dataInicial)) filters["inicio"] = dataInicial;
    if (!invalidArray.includes(dataFinal)) filters["fim"] = dataFinal;

    await buscarDadosRelatorio(filters);
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);

    const params = {
      data_ini: dataInicial,
      data_fim: dataFinal,
      id_vtd: idVTS,
    };

    const [ok, ret] = await apiGetV2(
      "/relatorios/venda/comissao/vts/vendas/",
      params
    );

    if (ok) {
      await docPrintReport(
        "/relatorios/comissao/vts/",
        {
          dados: ret,
          data_ini: formatDateISO(dataInicial),
          data_fim: formatDateISO(dataFinal),
        },
        "5014_1"
      );
    }

    setLoadingImprimir(false);
  };

  return (
    <Card body>
      <Row>
        <FiltroPeriodoDatas onChange={handleDates} />
        <AsyncComboBox
          md={3}
          label="VTS"
          isSearchable
          isClearable
          isConcatField
          concatModelName="vendedor_tecn_serv"
          defaultOptions
          onChange={(sel) => {
            setIdVTS(sel?.value);
            limparDados();
          }}
        />
      </Row>
      <Row>
        <BotaoPesquisar onClick={handlePesquisar} loading={loading} />
        <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
      </Row>
    </Card>
  );
};
