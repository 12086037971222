import React from "react";
import { useState } from "react";
import { FormButton } from "../../../../../components";
import MaskedInput from "../../../../../components/MaskedInput";
import { ModalBase } from "../../../../../components/ModalBase";
import { validarCNPJ } from "../../../../../coreUtils";
import CadastroClienteService from "../../../../../services/cadastro/CadastroClienteService";
import { showWarning } from "../../../../../components/AlertaModal";

export const PreencAutoModal = ({
  carregarDadosPreencAuto,
  buttonTabIndex,
  buttonPadded = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cnpj, setCnpj] = useState("");

  const toggle = () => {
    if (isOpen) setCnpj("");
    setIsOpen(!isOpen);
  };

  const onConfirm = async () => {
    if (!validarCNPJ(cnpj)) {
      showWarning("Por favor, revise o CNPJ.");
      return;
    }

    setLoading(true);
    const ret = await CadastroClienteService.buscarDadosPreencAuto(cnpj);

    if (ret) {
      if (carregarDadosPreencAuto(ret)) {
        toggle();
      }
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        id="bt-preenc-auto"
        md="auto"
        color="secondary"
        onClick={toggle}
        padded={buttonPadded}
        tabIndex={buttonTabIndex}
      >
        F8 - Preenchimento Automático
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        title="Preenchimento Automático"
        size="sm"
        hideCancelButton
        onKeyDown={(e) => {
          if (e.key === "Enter") onConfirm();
        }}
        onConfirm={onConfirm}
        loadingConfirm={loading}
      >
        <MaskedInput
          mask="99.999.999/9999-99"
          label="CNPJ"
          md={12}
          value={cnpj}
          onChange={(e, v) => setCnpj(v)}
        />
      </ModalBase>
    </>
  );
};
