import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, TabPane } from "reactstrap";
import { ComboBox, NumberInput } from "../../../../../../../components";
import {
  setMargValorAdicIcmsSt,
  setModBcIcmsSt,
  setPercIcmsSt,
  setPercReduIcmsSt,
} from "../../../store/cadRegraTributacaoSlice";

const modalidades_bc_icms_st = [
  { label: "Preço Tabelado ou Máximo Sugerido", value: "TMS" },
  { label: "Lista Negativa (Valor)", value: "NEG" },
  { label: "Lista Positiva (Valor)", value: "POS" },
  { label: "Lista Neutra (Valor)", value: "NEU" },
  { label: "Margem de Valor Agregado (%)", value: "PVA" },
  { label: "Pauta (valor)", value: "AUT" },
];

export const TabICMSST = () => {
  const store = useSelector((state) => state.cadRegraTributacao);
  const dispatch = useDispatch();

  return (
    <TabPane tabId="2" className="p-2">
      <Row>
        <ComboBox
          label="Modalidade de Determinação da Base de Cálculo"
          isSearchable={false}
          md={12}
          options={modalidades_bc_icms_st}
          defaultOptions
          isDisabled={!store.camposAtivosIcms["mod_bc_icms_st_ativo"]}
          onChange={(selected) => dispatch(setModBcIcmsSt(selected?.value))}
          defaultValue={store.modBcIcmsSt}
        />
      </Row>
      <Row>
        <NumberInput
          label="Redutor da Base de Cálculo."
          md="auto"
          mdInput={8}
          disabled={!store.camposAtivosIcms["perc_red_bc_icms_st_ativo"]}
          onChange={(v) => dispatch(setPercReduIcmsSt(v))}
          value={store.percReduIcmsSt}
          isPercentage
          clearOnDisable
        />
        <NumberInput
          label="Margem de Valor Agregado"
          md="auto"
          mdInput={8}
          disabled={!store.camposAtivosIcms["perc_mva_bc_icms_st_ativo"]}
          onChange={(v) => dispatch(setMargValorAdicIcmsSt(v))}
          value={store.margValorAdicIcmsSt}
          isPercentage
          clearOnDisable
        />
        <NumberInput
          label="Alíquota"
          md="auto"
          mdInput={8}
          disabled={!store.camposAtivosIcms["aliq_icms_st_ativo"]}
          onChange={(v) => dispatch(setPercIcmsSt(v))}
          value={store.percIcmsSt}
          isPercentage
          clearOnDisable
        />
      </Row>
    </TabPane>
  );
};
