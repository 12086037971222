import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/cadastro/lista_preco";
const URL_BASE_DE = `${URL_BASE}/de`;
const URL_BASE_PARA = `${URL_BASE}/para`;

const CadastroListaPrecosService = {
  urlBase: URL_BASE,
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  para: {
    urlBase: URL_BASE_DE,
    excluir: (payload) => apiPostV2(`${URL_BASE_PARA}/excluir/`, payload),
    incluir: (payload) => apiPostV2(`${URL_BASE_PARA}/incluir/`, payload),
    listar: (idListaPreco) =>
      apiGetV2(`${URL_BASE_PARA}/listar/${idListaPreco}/`),
  },
  de: {
    alterar: (payload) => apiPutV2(`${URL_BASE_DE}/alterar/`, payload),
    excluir: (idRegistro) =>
      apiDeleteV2(`${URL_BASE_DE}/excluir/${idRegistro}/`),
    incluir: (payload) => apiPostV2(`${URL_BASE_DE}/incluir/`, payload),
    listar: (idListaPreco) =>
      apiGetV2(`${URL_BASE_DE}/listar/${idListaPreco}/`),
  },
};

export default CadastroListaPrecosService;
