import React, { useState } from "react";
import { debounce, defaultDebounceTime, MODAL_ACTIONS } from "../../../../coreUtils";
import {
  BotaoPesquisar,
  ComboBox,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import PlanoContasService from "../../../../services/financeiro/PlanoContasService";
import { PlanoContasModal } from "./components/PlanoContasModal";
import { PlanoContasGrid } from "./components/PlanoContasGrid";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitlePlanoContas = "Plano de Contas";
export const routesBasePlanoContas = "plano_contas";

export const PlanoContas = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mostrarInativos, setMostrarInativos] = useState("");
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExcluirOpen, setIsExcluirOpen] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [selected, setSelected] = useState([]);
  const [pista, setPista] = useState('')

  const [filtroConta, setFiltroConta] = useState();

  const tiposConta = [
    {
      label: "Receitas",
      value: "REC",
    },
    {
      label: "Despesas",
      value: "DES",
    },
    {
      label: "Investimentos",
      value: "INV",
    },
    {
      label: "Transitório",
      value: "TRA",
    },
    {
      label: "Outros",
      value: "OUT",
    },
  ];

  const buscarDados = async (p) => {
    setLoading(true);
    try {
      const [ok, ret] = await PlanoContasService.listar({
        pista: p,
        mostrar_inativos: mostrarInativos,
        tipo_conta: filtroConta,
      });
      setDados(ok ? ret : []);
    } finally {
      setLoading(false);
    }
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const toggle = (action) => {
    setAction(action);
    setIsModalOpen(!isModalOpen);
  };

  const toggleExcluir = () => setIsExcluirOpen(!isExcluirOpen);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggle(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Plano de Contas" number="0125">
      <Card body>
        <Row>
          <SearchInput
            md={4}
            onChange={handlePista}
            loading={loading}
          />
          <ComboBox
            md={2}
            options={tiposConta}
            label="Tipo de Conta"
            onChange={(s) => setFiltroConta(s?.value)}
            defaultValue={filtroConta}
            isClearable
            isSearchable={false}
          />
          <FormCheckbox
            label={"Mostrar Inativos"}
            onChange={(e) => setMostrarInativos(!mostrarInativos)}
            checked={mostrarInativos}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />

          <BotaoIncluir toggle={toggle} />

          <PlanoContasModal
            isOpen={isModalOpen}
            toggle={toggle}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitlePlanoContas}
            isOpen={isExcluirOpen}
            toggle={toggleExcluir}
            number="0125_2"
            selected={selected}
            routeBase={routesBasePlanoContas}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <PlanoContasGrid
          dados={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
