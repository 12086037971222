import { toastr } from "react-redux-toastr";
import { api } from "../../../api";

const ControleJornadaService = {
  abrirJornada: async (payload) => {
    const { data } = await api
      .post("/jornada/web_cria_jornada_primaria/", payload)
      .catch((err) => toastr.error("Erro", err.message));
    if (!data.err) {
      return data;
    } else {
      toastr.error("Erro", `${data.msg} - Erro: ${data.id_err}`);
      return {};
    }
  },
  buscarResumoJornadas: async () => {
    const { data } = await api
      .get("/jornada/web_adm_resumo_jornadas/")
      .catch((err) => toastr.error("Erro", err.message));
    if (data) {
      if (data.success) {
        return data.res;
      } else {
        toastr.error("Erro", `${data.msg} - Erro: ${data.id_err}`);
      }
    }
    return [];
  },
  buscarPrestacoesJorn: async (idJornada) => {
    const { data } = await api
      .get(`/jornada/web_adm_resumo_presta_contas_jorn/${idJornada}/`)
      .catch((err) => toastr.error("Erro", err.message));
    if (data) {
      if (data.success) {
        return { despesas: data.res };
      } else {
        toastr.error("Erro", `${data.msg} - Erro: ${data.id_err}`);
      }
    }
    return { despesas: [] };
  },
  buscarJornadasDiarias: async (idJornada) => {
    const { data } = await api
      .get(`/jornada/web_adm_resumo_jornadas_dia/${idJornada}/`)
      .catch((err) => toastr.error("Erro", err.message));
    if (data) {
      if (data.success) {
        return data.res;
      } else {
        toastr.error("Erro", `${data.msg} - Erro: ${data.id_err}`);
      }
    }
    return [];
  },
  buscarPrestacoesJornDiaria: async (idJornada) => {
    const { data } = await api
      .get(`/jornada/web_adm_resumo_presta_contas_jorn_dia/${idJornada}/`)
      .catch((err) => toastr.error("Erro", err.message));
    if (data) {
      if (data.success) {
        return data.res;
      } else {
        toastr.error("Erro", `${data.msg} - Erro: ${data.id_err}`);
      }
    }
    return [];
  },
  encerrarJornada: async (idJornada) => {
    const { data } = await api
      .put(`/jornada/encerrar/`, { id_jornada_cab: idJornada })
      .catch((err) => toastr.error("Erro", err.message));
    if (data) {
      if (data.success) {
        return data.res;
      } else {
        toastr.error("Erro", `${data.msg} (${data.id_err})`);
      }
    }
  },
};

export default ControleJornadaService;
