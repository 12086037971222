import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { TiArrowBackOutline } from "react-icons/ti";
import { TbTableImport } from "react-icons/tb";
import {
  devolucaoVendaRoute,
  gerenciamentoPremiacoesRoute,
  gerenciamentoVendasRoute,
  importacaoVendasBroker,
  marketplacesIntegracoesRoute,
} from "../../../routes/modules/vendas";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsShop, BsTrophy } from "react-icons/bs";

export const VendasGerenciamento = () => {
  return (
    <PageContainer title="Vendas - Gerenciamento">
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento de Vendas"
          icon={AiOutlineUnorderedList}
          pathname={gerenciamentoVendasRoute.path}
          nroTela={gerenciamentoVendasRoute.nroTela}
        />
        <MenuGroupButton
          label="Devolução"
          icon={TiArrowBackOutline}
          pathname={devolucaoVendaRoute.path}
          nroTela={devolucaoVendaRoute.nroTela}
        />
        <MenuGroupButton
          label="Premiações"
          icon={BsTrophy}
          pathname={gerenciamentoPremiacoesRoute.path}
          nroTela={gerenciamentoPremiacoesRoute.nroTela}
        />
        <MenuGroupButton
          label="Importação de Vendas Broker"
          icon={TbTableImport}
          pathname={importacaoVendasBroker.path}
          nroTela={importacaoVendasBroker.nroTela}
        />
        <MenuGroupButton
          label="Marketplaces Integrações"
          icon={BsShop}
          pathname={marketplacesIntegracoesRoute.path}
          nroTela={marketplacesIntegracoesRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
