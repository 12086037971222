import classNames from "classnames";
import React from "react";

export const Divider = ({ className, textClassName, children }) => (
  <div className={classNames("divider", className)}>
    {children && (
      <div className={classNames("divider-text", textClassName)}>
        {children}
      </div>
    )}
    <hr className="divider-line" />
  </div>
);
