import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Row } from "reactstrap";
import { AsyncComboBox } from "../../../../components";
import CustomBarChart from "../../../../components/BarChart";
import { BaseInfoCard } from "../../../../components/BaseInfoCard";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import VendasPorGrupoService from "../../../../services/VendasPorGrupoService";

export const VendasPorGrupo = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [fabricante, setFabricante] = useState([]);
  const [grupo, setGrupo] = useState([]);
  const [vendedor, setVendedor] = useState([]);
  const [relData, setRelData] = useState([]);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setRelData([]);
  };

  const fetchData = async () => {
    if (!(dataIni instanceof Date) || !(dataFim instanceof Date)) {
      toastr.warning("Atenção", "Por favor, verifique os campos de data");
      return;
    }

    setLoading(true);
    const res = await VendasPorGrupoService.fetchRelData(
      dataIni.toISOString()?.split("T")[0],
      dataFim.toISOString()?.split("T")[0],
      fabricante,
      grupo,
      vendedor
    );

    setRelData(res["data"] ?? []);
    setNames(res["names"] ?? []);

    setLoading(false);
  };

  const handleDate = (dataIni, dataFim) => {
    setDataIni(dataIni);
    setDataFim(dataFim);
    limparDados();
  };

  const handleSetFabricante = (selected) => {
    setFabricante(selected?.map((e) => e.value));
    limparDados();
  };

  const handleSetGrupo = (selected) => {
    setGrupo(selected?.map((e) => e.value));
    limparDados();
  };

  const handleSetVendedor = (selected) => {
    setVendedor(selected?.map((e) => e.value));
    limparDados();
  };

  return (
    <PageContainer title="Vendas por Grupo" number="5003" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            onChange={handleSetVendedor}
            isSearchable
            isClearable
            md={4}
            label="Vendedor"
            name="vendedor"
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            isMulti
          />
          <AsyncComboBox
            onChange={handleSetGrupo}
            isSearchable
            isClearable
            isMulti
            md={4}
            label="Grupo"
            name="grupo"
            isConcatField
            concatModelName="grupo"
            defaultOptions
          />
          <AsyncComboBox
            onChange={handleSetFabricante}
            isSearchable
            isClearable
            isMulti
            md={4}
            label="Fabricante"
            name="fabricante"
            isConcatField
            concatModelName="fabricante"
            defaultOptions
          />
          <BotaoPesquisar onClick={fetchData} loading={loading} />
        </Row>
      </Card>
      {relData.length > 0 && (
        <>
          <CustomBarChart data={relData} dataKey="Chave" barDataKeys={names} />
          <BaseInfoCard baseInfo={["Vendas: Vendas do Sistema Desktop"]} />
        </>
      )}
    </PageContainer>
  );
};
