import React, { useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { PiWarningDiamondFill } from "react-icons/pi";
import { BiSolidErrorAlt } from "react-icons/bi";
import { Col, Row, Spinner } from "reactstrap";
import { ModalBase } from "./ModalBase";
import { BiCopy } from "react-icons/bi";
import { IconButton } from "./IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  CLOSE_ALERT,
  SHOW_ERROR,
  SHOW_SUCCESS,
  SHOW_WARNING,
} from "../redux/constants";
import outsideStore from "../redux/store";
import { useRef } from "react";

export const STATUS_ALERTA = {
  LOADING: 0,
  SUCCESS: 1,
  ERROR: 2,
  WARNING: 3,
};

export const showError = (msg, idErr, errorDetails) => {
  if (!(errorDetails instanceof Array)) {
    if (typeof errorDetails === "string") {
      errorDetails = [errorDetails];
    } else {
      errorDetails = [];
    }
  }

  outsideStore.dispatch({
    type: SHOW_ERROR,
    msg: msg,
    idErr: idErr,
    errorDetails: errorDetails,
  });
};

export const showSuccess = (msg) =>
  outsideStore.dispatch({
    type: SHOW_SUCCESS,
    msg: msg,
  });

export const showWarning = (msg) =>
  outsideStore.dispatch({
    type: SHOW_WARNING,
    msg: msg,
  });

export const AlertaModal = () => {
  const [title, setTitle] = useState("");
  const [number, setNumber] = useState("");
  const dispatch = useDispatch();
  const store = useSelector((state) => state.alerts);
  const modalRef = useRef(null);

  const toggle = () => {
    if (store.isOpen) {
      dispatch({ type: CLOSE_ALERT });
      setTitle("");
      setNumber("");
    }
  };

  const buscarInfoTela = () => {
    const modals = Array.from(document.getElementsByClassName("modal"));
    if (modals.length > 0) {
      const lastModal = modals[modals.length - 1];

      let lastModalNumber = lastModal.querySelectorAll(`[name='modal-number']`);

      if (lastModalNumber.length > 0) {
        lastModalNumber = lastModalNumber[0].textContent;
        setNumber(
          (lastModalNumber ?? "").replaceAll("[", "").replaceAll("]", "")
        );
      }

      let lastModalTitle = lastModal.querySelectorAll(`[name='modal-title']`);

      if (lastModalTitle.length > 0) {
        lastModalTitle = lastModalTitle[0].textContent;
        setTitle(lastModalTitle);
      }
    } else {
      let pagenumber = document.getElementsByName("page-number");
      let screentitle = document.getElementsByName("screen-title");
      if (pagenumber.length > 0) {
        pagenumber = pagenumber[0].textContent;
        setNumber((pagenumber ?? "").replaceAll("[", "").replaceAll("]", ""));
      }
      if (screentitle.length > 0) {
        screentitle = screentitle[0].textContent.split(" [")[0];
        setTitle(screentitle);
      }
    }
  };

  let contentClassName = "";
  if (store.status === STATUS_ALERTA.ERROR) {
    contentClassName = "modal-erro";
  } else if (store.status === STATUS_ALERTA.LOADING) {
    contentClassName = "modal-loading";
  } else if (store.status === STATUS_ALERTA.WARNING) {
    contentClassName = "modal-aviso";
  } else if (store.status === STATUS_ALERTA.SUCCESS) {
    contentClassName = "modal-sucesso";
  }

  return (
    <ModalBase
      isOpen={store.isOpen}
      toggle={
        [
          STATUS_ALERTA.ERROR,
          STATUS_ALERTA.SUCCESS,
          STATUS_ALERTA.WARNING,
        ].includes(store.status)
          ? toggle
          : () => {}
      }
      size="md"
      title={title}
      number={number}
      hideCancelButton={
        ![STATUS_ALERTA.ERROR, STATUS_ALERTA.WARNING].includes(store.status)
      }
      footerActions
      onBeforeOpen={buscarInfoTela}
      autoFocus
      ref={modalRef}
      onConfirm={store.status === STATUS_ALERTA.SUCCESS ? toggle : null}
      confirmButtonText="Ok"
      headless
      footerStyle={{ padding: "0.5rem 1rem" }}
      contentClassName={contentClassName}
    >
      <>
        <Row>
          <Col md={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {store.status === STATUS_ALERTA.LOADING && (
                <Spinner
                  size="lg"
                  style={{
                    width: "75px",
                    height: "75px",
                    border: "0.8rem solid #ccc",
                    borderRightColor: "transparent",
                  }}
                  color="silver"
                />
              )}
              {store.status === STATUS_ALERTA.SUCCESS && (
                <BsCheckLg
                  size={30}
                  color="green"
                  style={{ width: "75px", height: "75px" }}
                />
              )}
              {store.status === STATUS_ALERTA.WARNING && (
                <PiWarningDiamondFill
                  size={5}
                  color="gold"
                  style={{ width: "75px", height: "75px" }}
                />
              )}
              {store.status === STATUS_ALERTA.ERROR && (
                <BiSolidErrorAlt
                  size={30}
                  color="#b3281f"
                  style={{ width: "75px", height: "75px" }}
                />
              )}
            </div>
          </Col>
          <Col md={9}>
            {title && <h4>{title}</h4>}
            <h5
              style={{
                marginTop: "1rem",
                marginBottom: "0.25rem",
              }}
            >
              {store.msg}
              {store.status === STATUS_ALERTA.LOADING ? "..." : ""}
              {(store.msg ?? "").length > 0 &&
                [STATUS_ALERTA.WARNING, STATUS_ALERTA.ERROR].includes(
                  store.status
                ) && (
                  <IconButton
                    size={18}
                    icon={BiCopy}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        store.msg +
                          "\n" +
                          store.errorDetails.map((e) => e + "\n").join("") +
                          `Código do Erro: ${store.idErr}    Tela: [${number}]`
                      );
                    }}
                    hint="Copiar Mensagem"
                    className="ml-2"
                  />
                )}
            </h5>

            {store.status === STATUS_ALERTA.ERROR && (
              <>
                {store.errorDetails.length > 0 && (
                  <ul style={{ paddingRight: "2rem" }}>
                    {store.errorDetails.map((e) => (
                      <li>{e}</li>
                    ))}
                  </ul>
                )}
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <label style={{ marginRight: "1.5rem" }}>
                    Código do Erro: {store.idErr}
                  </label>
                  <label>Tela: [{number}]</label>
                </div>
              </>
            )}
          </Col>
        </Row>
      </>
    </ModalBase>
  );
};
