import React, { memo, useRef } from "react";
import {
  AsyncComboBox,
  ComboBox,
  IconButton,
} from "../../../../../../components";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { MinusCircle, PlusCircle } from "react-feather";

const optionsFiltro = [
  { label: "Classe", value: "classe_prod" },
  { label: "Fabricante", value: "fabricante" },
  { label: "Fornecedor", value: "fornecedor" },
  { label: "Grupo", value: "grupo" },
  { label: "Sub Grupo", value: "sub_grupo" },
  {
    label: "Tipo de Produto",
    value: "tipo_produto",
    concatModelName: "tipo_prod",
  },
];

export const buscarLabelFiltro = (tipoFiltro) => {
  const labelFiltro = optionsFiltro.find((e) => e.value === tipoFiltro);
  if (labelFiltro === undefined) {
    return "Tipo de Filtro";
  }
  return labelFiltro.label;
};

export const buscarConcatModelName = (tipoFiltro) => {
  const labelFiltro = optionsFiltro.find((e) => e.value === tipoFiltro);
  return labelFiltro?.concatModelName ?? tipoFiltro;
};

const LinhaFiltroModal = memo(
  ({
    adicionarFiltro,
    tipoFiltro,
    uuidFiltro,
    idFiltro,
    idx,
    atualizarFiltros,
    removerFiltro,
  }) => {
    const refComboBox = useRef();

    const _setValorFiltro = (campo, valor, valorAux) => {
      const dadosFiltro = {
        uuid: uuidFiltro,
        [campo]: valor,
      };

      if (campo === "tipo") {
        dadosFiltro["id_filtro"] = null;
        dadosFiltro["desc_filtro"] = null;
        if (refComboBox.current) {
          refComboBox.current.clearValue();
        }
      } else if (campo === "id_filtro") {
        dadosFiltro["desc_filtro"] = valorAux;
      }
      atualizarFiltros(dadosFiltro);
    };

    const setTipoFiltro = (novoTipoFiltro) =>
      _setValorFiltro("tipo", novoTipoFiltro);

    const setIdFiltro = (novoIdFiltro, novaDescFiltro) =>
      _setValorFiltro("id_filtro", novoIdFiltro, novaDescFiltro);

    return (
      <Row>
        <ComboBox
          md={5}
          label="Tipo"
          options={optionsFiltro}
          onChange={(s) => {
            setTipoFiltro(s?.value);
          }}
          defaultValue={tipoFiltro}
        />
        <AsyncComboBox
          md={null}
          className={"pr-0"}
          label={`Selecione o ${buscarLabelFiltro(tipoFiltro)}`}
          concatModelName={buscarConcatModelName(tipoFiltro)}
          isConcatField
          defaultValue={idFiltro}
          disabled={[null, undefined].includes(tipoFiltro)}
          onChange={(s) => setIdFiltro(s?.value, s?.label)}
          hideShortcut
          ref={refComboBox}
        />
        <Col md="auto">
          <FormGroup>
            <Label>&#8205;</Label>
            {idx === 0 ? (
              <IconButton
                icon={PlusCircle}
                onClick={adicionarFiltro}
                style={{ display: "block", marginTop: "6.7px" }}
              />
            ) : (
              <IconButton
                icon={MinusCircle}
                onClick={() => removerFiltro(uuidFiltro)}
                style={{ display: "block", marginTop: "6.7px" }}
              />
            )}
          </FormGroup>
        </Col>
      </Row>
    );
  }
);

export default LinhaFiltroModal;
