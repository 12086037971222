import { apiGetV2, apiPostV2 } from "../../apiV2";

const ENDPOINT_BASE = "/jornada";

export const GerenciamentoFlexService = {
  buscarHistoricoFlex: async (idRota, dataIni, dataFim, somenteAdm) => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      somente_lancamentos_adm: somenteAdm,
    };

    return await apiGetV2(
      `${ENDPOINT_BASE}/flex/extrato_rota/${idRota}/`,
      params
    );
  },
  buscarRotas: async () =>
    await apiGetV2(`${ENDPOINT_BASE}/flex/saldo_flex_bonif_rotas/`),
  lancarFlex: async (payload) =>
    await apiPostV2(`${ENDPOINT_BASE}/flex/incluir_flex_avulso/`, payload),
  lancarBonif: async (payload) =>
    await apiPostV2(
      `${ENDPOINT_BASE}/bonificacao/incluir_bonifica_avulsa/`,
      payload
    ),
  listarPeriodo: async (dataIni, dataFim) => {
    const params_ = {
      data_ini: dataIni.toISOString?.().split("T")[0],
      data_fim: dataFim.toISOString?.().split("T")[0],
    };

    const searchParams = new URLSearchParams(params_).toString();
    return await apiGetV2(
      `${ENDPOINT_BASE}/flex/listar_periodo/?${searchParams}`
    );
  },
};
