import React from "react";
import { IconButton, Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import RemessaEntreEmpresasService from "../../../../../services/faturamento/RemessaEntreEmpresasService";
import { BsPrinter } from "react-icons/bs";

export const ReceberRemessaGrid = ({ dados, setSelected }) => {
  const columns = [
    { dataField: "id", text: "#", align: "center" },
    {
      dataField: "nome_origem",
      text: "Origem",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_origem),
    },
    { dataField: "inclusao", text: "Data", align: "center" },
    {
      dataField: "nro_nf",
      text: "Nº NF",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_tot_prod",
      text: "Produtos",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "imp",
      text: "",
      dummy: true,
      align: "center",
      cellContentTag: null,
      colWidth: "32px",
      fixedColWidth: true,
      selectOnClick: false,
      formatter: (c, row) => (
        <IconButton
          icon={BsPrinter}
          hint="Imprimir Separação"
          tooltipPlacement="left"
          onClick={() =>
            RemessaEntreEmpresasService.imprimirSeparacao(
              row.id,
              true,
              row.licenca_matriz
            )
          }
        />
      ),
    },
    // {
    //   dataField: "det",
    //   text: "",
    //   dummy: true,
    //   align: "center",
    //   cellContentTag: null,
    //   colWidth: "32px",
    //   fixedColWidth: true,
    //   selectOnClick: false,
    //   formatter: (c, row) => (
    //     <MoreDropdown horizontal>
    //       <DropdownItem
    //         onClick={() => mostrarDetalhes(row.id, row.licenca_matriz)}
    //       >
    //         Detalhes
    //       </DropdownItem>
    //       <DropdownItem
    //         onClick={() =>
    //           RemessaEntreEmpresasService.imprimirSeparacao(
    //             row.id,
    //             true,
    //             row.licenca_matriz
    //           )
    //         }
    //       >
    //         Imprimir Separação
    //       </DropdownItem>
    //     </MoreDropdown>
    //   ),
    // },
  ];

  return <Table data={dados} columns={columns} onSelect={setSelected} />;
};
