import { apiGetV2, apiPostV2 } from "../../apiV2";

const BASE_URL = "/bancario/cobranca/gerenciamento";
const BASE_URL_BOLETOS = "/bancario/cobranca/boleto";

export const GerenciamentoCobrancaBancariaService = {
  listar: (params) => apiGetV2(`${BASE_URL}/listar/`, params),
  buscar: (idDuplicata) => apiGetV2(`${BASE_URL}/buscar/${idDuplicata}/`),
  enviarNfseBoletoViaWhats: (idDuplicata) =>
    apiPostV2(`${BASE_URL_BOLETOS}/boleto_nfse_via_whatsapp/`, {
      id_dup_rec_mov: idDuplicata,
    }),
  gerarEmitirNfseBoleto: (idDuplicata) =>
    apiPostV2(`${BASE_URL_BOLETOS}/gerar_emitir_nfse_boleto/`, {
      id_dup_rec_mov: idDuplicata,
    }),
  verificaClienteGeraNfseBoleto: (idCliente) =>
    apiGetV2(
      `${BASE_URL_BOLETOS}/verifica_cliente_gera_nfse_boleto/${idCliente}/`
    ),
};
