import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  IntegerFormInput,
  PageContainer,
  TextInput,
} from "../../../../../components";
import GerenciamentoCartaoService from "../../../../../services/financeiro/GerenciamentoCartaoService";
import { CompensacoesGrid } from "./components/CompensacoesGrid";
import { EstornarLoteModal } from "./components/EstornarLoteModal";
import { sumDataField } from "../../../../../coreUtils";

const tiposData = [
  { label: "Recebimento", value: "REC" },
  { label: "Emissão", value: "EMI" },
];

export const CompensacoesRealizadas = () => {
  const [administradora, setAdministradora] = useState(null);
  const [meioPag, setMeioPag] = useState(null);
  const [numero, setNumero] = useState(null);
  const [loteCompensacao, setLoteCompensacao] = useState(null);
  const [tipoData, setTipoData] = useState(tiposData[0].value);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingEstornar, setLoadingEstornar] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    // setSelected(null);
    setLoading(true);
    setDados(
      await GerenciamentoCartaoService.listarCompensacoes(
        meioPag,
        administradora,
        numero,
        loteCompensacao,
        tipoData,
        dataIni,
        dataFim
      )
    );
    setLoading(false);
  };

  const estornarLote = async () => {
    setLoadingEstornar(true);
    const idLote = dados.find((e) => e.id === selected).id_lote_compens_cartao;
    if (await GerenciamentoCartaoService.estornar(idLote)) {
      carregarDados();
    }
    setLoadingEstornar(false);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  return (
    <PageContainer title="Compensações Realizadas" number="0049_5" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Meio de Pagamento"
            isConcatField
            concatModelName="meio_pagamento"
            isSearchable
            isClearable
            defaultOptions
            onChange={(s) => setMeioPag(s?.value)}
            defaultValue={meioPag}
          />
          <AsyncComboBox
            md={3}
            label="Administradora"
            isConcatField
            concatModelName="administradora_cartao"
            isSearchable
            isClearable
            defaultOptions
            onChange={(s) => setAdministradora(s?.value)}
            defaultValue={administradora}
          />
          <TextInput
            label="Número"
            md={2}
            onChange={(e, v) => setNumero(v)}
            value={numero}
            maxLength={30}
          />
          <IntegerFormInput
            md={2}
            label="Lote Compensação"
            onChange={setLoteCompensacao}
            value={loteCompensacao}
          />
        </Row>
        <Row>
          <ComboBox
            label="Filtrar Por"
            md={2}
            options={tiposData}
            onChange={(s) => setTipoData(s?.value)}
            defaultValue={tipoData}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <EstornarLoteModal
            estornarLote={estornarLote}
            loading={loadingEstornar}
            disabled={[0, null, undefined].includes(selected)}
          />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Total Recebido"
          value={sumDataField(dados, "vlr_recebido")}
          className="col-md-4 ml-auto"
        />
      </CardTotais>
      <Card body>
        <CompensacoesGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
