import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  SearchInput,
  BotaoPesquisar,
  PageContainer,
  FormButton,
  BaseInfoTelaCard,
} from "../../../../components";
import RegrasComissaoService from "../../../../services/cadastro/RegrasComissaoService";
import { CadastroRegrasComissaoModal } from "./components/CadastroRegrasComissaoModal";
import { DetalharRegraComissaoModal } from "./components/DetalharRegraComissaoModal";
import { RegrasComissaoGrid } from "./components/RegrasComissaoGrid";
import {
  debounce,
  defaultDebounceTime,
  MODAL_ACTIONS,
} from "../../../../coreUtils";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { DetalharRegraComissaoEscalonadaModal } from "./components/DetalharRegraComissaoEscalonadaModal";

export const modalTitleRegraComissao = "Regra de Comissão";
export const routesBaseRegraComissao = RegrasComissaoService.urlBase;

export const RegrasComissao = () => {
  const [selected, setSelected] = useState(null);
  const [selectedTipo, setSelectedTipo] = useState(null);
  const [dados, setDados] = useState([]);
  const [pista, setPista] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [detalhesEscalonadaOpen, setDetalhesEscalonadaOpen] = useState(false);

  const buscarDados = async (p) => {
    const params = { pista: p };
    const [ok, ret] = await RegrasComissaoService.listar(params);
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const handleSelect = (v, is, row) => {
    setSelected(v);
    setSelectedTipo(is ? row.tipo : null);
  };

  const notifyEvent = (action) => {
    if (action === MODAL_ACTIONS.DELETE) {
      setSelected(null);
      setSelectedTipo(null);
    }
    carregarDados();
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleDetalhesEscalonada = () =>
    setDetalhesEscalonadaOpen(!detalhesEscalonadaOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const mostrarDetalhes = () => {
    if (selectedTipo === "ESCAL") {
      toggleDetalhesEscalonada();
    } else {
      toggleDetalhes();
    }
  };

  return (
    <PageContainer
      title="Cadastro de Regras de Comissão"
      number="0038"
      canGoBack
    >
      <Card body>
        <Row>
          <SearchInput
            md={6}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome da Regra"
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroRegrasComissaoModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleRegraComissao}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0038_2"
            selected={selected}
            routeBase={routesBaseRegraComissao}
            notifyEvent={notifyEvent}
          />
          <FormButton
            color="primary"
            md="auto"
            onClick={mostrarDetalhes}
            disabled={[null, undefined, 0].includes(selected)}
          >
            Detalhes
          </FormButton>
          <DetalharRegraComissaoModal
            isOpen={detalhesOpen}
            toggle={toggleDetalhes}
            selected={selected}
          />
          <DetalharRegraComissaoEscalonadaModal
            isOpen={detalhesEscalonadaOpen}
            toggle={toggleDetalhesEscalonada}
            selected={selected}
          />
        </Row>
        <RegrasComissaoGrid
          dados={dados}
          onSelect={handleSelect}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
      <BaseInfoTelaCard nroTela="0038" />
    </PageContainer>
  );
};
