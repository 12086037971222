import React from "react";
import { BsClipboardCheck } from "react-icons/bs";
import { formatNumber } from "../../../../../coreUtils";
import { conferirValoresJornadaDiariaRoutes } from "../../../../../routes/modules";
import {
  IconButton,
  IconLink,
  ModalDetalhesIcone,
  Table,
} from "../../../../../components";
import { MdMoneyOff } from "react-icons/md";

export const DiasGrid = ({ dados, mostrarDetalhes, reabrirConferencia }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "data",
      text: "Data",
      align: "left",
      style: (c, { ultimo_dia }) => ({
        color: ultimo_dia ? "#5b7dff" : "inherit",
        fontWeight: ultimo_dia ? "bold" : "normal",
      }),
    },
    {
      dataField: "vlr_din_despesa",
      text: "Despesas",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_din_receb_boleto",
      text: "Boletos Rec. Mãos",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_din_venda",
      text: "Venda Dinheiro",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_din_malote",
      text: "Entregue no Malote",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_saldo_din_fechou_dia",
      text: "Quebra do Dia",
      align: "right",
      style: (v) => ({
        color:
          parseFloat(v) > 0
            ? "#5fc27e"
            : parseFloat(v) < 0
            ? "#f44456"
            : "inherit",
        fontWeight: "bold",
      }),
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "saldo_quebra",
      text: "Saldo",
      align: "right",
      style: (v) => ({
        color:
          parseFloat(v) > 0
            ? "#5fc27e"
            : parseFloat(v) < 0
            ? "#f44456"
            : "inherit",
        fontWeight: "bold",
        fontStyle: "italic",
      }),
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_comis_descontado",
      text: "Descontado V.",
      align: "right",
      style: (v) => ({
        fontWeight: parseFloat(v) !== 0 ? "bold" : "normal",
      }),
      formatter: (v) => formatNumber(v, true, 2),
    },
    // {
    //   dataField: "vlr_comis_creditado",
    //   text: "Creditado V.",
    //   align: "right",
    //   style: (v) => ({
    //     fontWeight: parseFloat(v) !== 0 ? "bold" : "normal",
    //   }),
    //   formatter: (v) => formatNumber(v, true, 2),
    // },
    {
      dataField: "vlr_saldo_quebra_confer",
      text: "Quebra Conf.",
      align: "right",
      style: (v) => ({
        color:
          parseFloat(v) > 0
            ? "#5fc27e"
            : parseFloat(v) < 0
            ? "#f44456"
            : "inherit",
        fontWeight: "bold",
      }),
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_conferido_adm",
      text: "Valor Conferido",
      align: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "det",
      text: "",
      align: "center",
      cellContentTag: null,
      formatter: (c, row) => (
        <ModalDetalhesIcone
          toggle={() => mostrarDetalhes(row.id)}
          color={row.ultimo_dia ? "#5b7dff" : "inherit"}
          hint="Acessar detalhes do dia"
        />
      ),
    },
    {
      dataField: "conf",
      text: "",
      align: "center",
      cellContentTag: null,
      formatter: (c, row) =>
        row.conferir ? (
          <IconLink
            icon={BsClipboardCheck}
            color="#355fff"
            hint="Conferir Valores"
            pathname={conferirValoresJornadaDiariaRoutes.path}
            state={{ idDia: row.id }}
            tooltipPlacement="left"
          />
        ) : row.permite_reabrir ? (
          <IconButton
            icon={MdMoneyOff}
            color="#f44456"
            hint="Reabrir Conferência"
            onClick={() => reabrirConferencia(row.id)}
          />
        ) : (
          <></>
        ),
    },
    {
      dataField: "conferido_adm",
      text: "Situação",
      align: "center",
      headerAlign: "center",
      style: (c, row) => ({ color: row.conferido_adm ? "green" : "red" }),
      formatter: (c) => (c ? "Conferido" : "Não Conferido"),
    },
  ];

  const setarEstiloLinha = (row) => {
    const style = {};

    if (row.jorn_fechada) {
      style.backgroundColor = "#e9ecf0";
    }

    return style;
  };

  return (
    <Table
      columns={columns}
      data={dados}
      rowStyle={setarEstiloLinha}
      showRegisterCount={false}
      paginated={false}
      pageSize={dados.length > 10 ? 10 : dados.length}
    />
  );
};
