import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, TabPane } from "reactstrap";
import { ComboBox, NumberInput } from "../../../../../../../components";
import {
  setMotivoDesonerIcms,
  setPercDesonerIcms,
} from "../../../store/cadRegraTributacaoSlice";

const motivo_desoner_icms = [
  { label: "1 - Táxi", value: "1" },
  { label: "3 - Produtor Agropecuário", value: "3" },
  { label: "4 - Frotista/Locadora", value: "4" },
  { label: "5 - Diplomático/Consular", value: "5" },
  {
    label:
      "6 - Utilit. e motocicletas Amazônia ocid. e áreas de livre comércio",
    value: "6",
  },
  { label: "7 - SUFRAMA", value: "7" },
  { label: "8 - Venda a Órgão Público", value: "8" },
  { label: "9 - Outros. (NT 2011/004)", value: "9" },
  { label: "10 - Deficiente Condutor (Convênio ICMS 38/12)", value: "10" },
  { label: "11 - Deficiente Não Condutor (Convênio ICMS 38/12)", value: "11" },
];

export const TabDesonerICMS = () => {
  const store = useSelector((state) => state.cadRegraTributacao);
  const dispatch = useDispatch();

  return (
    <TabPane tabId="5" className="p-2">
      <Row>
        <NumberInput
          label="Percentual"
          md={2}
          disabled={!store.camposAtivosIcms["perc_desoner_icms_ativo"]}
          onChange={(v) => dispatch(setPercDesonerIcms(v))}
          value={store.percDesonerIcms}
          isPercentage
          clearOnDisable
        />
        <ComboBox
          label="Motivo"
          isSearchable={false}
          md={10}
          options={motivo_desoner_icms}
          defaultOptions
          isDisabled={!store.camposAtivosIcms["motivo_desoner_icms_ativo"]}
          onChange={(selected) =>
            dispatch(setMotivoDesonerIcms(selected?.value))
          }
          defaultValue={store.motivoDesonerIcms}
        />
      </Row>
    </TabPane>
  );
};
