import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormCheckbox,
  IntegerFormInput,
} from "../../../../../components";
import {
  SET_APENAS_ALERTAS,
  SET_DADOS,
  SET_DATA_FIM,
  SET_DATA_INI,
  SET_ID_CLIENTE,
  SET_ID_ROTA,
  SET_ID_VENDA,
} from "../../../../../redux/reducers/frente_venda_mobile/gerenciamentoVendasReducer";
import GerenciamentoVendasMobileService from "../../../../../services/frente_venda_mobile/GerenciamentoVendasMobileService";
import { Alertas } from "./Alertas";

export const CardFiltros = () => {
  const filtros = useSelector((state) => state.gerenciamentoVendasMobile);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  const limparDados = () => {
    dispatch({
      type: SET_DADOS,
      data: {
        alertas: [],
        vendas: [],
        totais: {
          num_vendas: "0",
          troca: "0",
          produtos: "0",
          icms_st: "0",
          geral: "0",
        },
      },
    });
  };

  const limparFiltros = () => {
    dispatch({
      type: SET_ID_VENDA,
      data: null,
    });
    dispatch({
      type: SET_ID_CLIENTE,
      data: null,
    });
    dispatch({
      type: SET_ID_ROTA,
      data: null,
    });
    if (filtros.apenasAlertas) {
      dispatch({
        type: SET_APENAS_ALERTAS,
      });
    }
  };

  useEffect(() => {
    limparFiltros();
  }, []);

  const handleSelectDate = useCallback(
    (_dataIni, _dataFim) => {
      dispatch({
        type: SET_DATA_INI,
        data: _dataIni,
      });
      dispatch({
        type: SET_DATA_FIM,
        data: _dataFim,
      });
      limparDados();
    },
    [dispatch]
  );

  const handleMostrarApenasAlertas = () => {
    dispatch({
      type: SET_APENAS_ALERTAS,
    });
    limparDados();
  };

  const handleNroVenda = (value) => {
    dispatch({
      type: SET_ID_VENDA,
      data: value,
    });
    limparDados();
  };

  const handleCliente = (selected) => {
    dispatch({
      type: SET_ID_CLIENTE,
      data: selected?.value,
    });
    limparDados();
  };

  const handleRota = (selected) => {
    dispatch({
      type: SET_ID_ROTA,
      data: selected?.value,
    });
    limparDados();
  };

  const buscarDados = async () => {
    setLoading(true);
    dispatch({
      type: SET_DADOS,
      data: await GerenciamentoVendasMobileService.buscarDados(filtros),
    });
    setLoading(false);
  };

  return (
    <Card body>
      <Row>
        <IntegerFormInput
          label="Nº da Venda"
          md="2"
          onChange={handleNroVenda}
        />
        <AsyncComboBox
          label="Cliente"
          isConcatField
          concatModelName="cliente"
          isSearchable
          isClearable
          onChange={handleCliente}
          md="5"
        />
        <AsyncComboBox
          label="Rota"
          isConcatField
          concatModelName="rota_mob"
          defaultOptions
          isSearchable
          isClearable
          onChange={handleRota}
          md="3"
        />
      </Row>
      <Row>
        <FiltroPeriodoDatas onChange={handleSelectDate} />
        <FormCheckbox
          label="Mostrar somente vendas com alerta"
          checked={filtros.apenasAlertas}
          onChange={handleMostrarApenasAlertas}
          name="apenas_alertas"
        />
        <BotaoPesquisar onClick={buscarDados} loading={loading} />
      </Row>
      <Row>
        <Alertas />
      </Row>
    </Card>
  );
};
