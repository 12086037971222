import React from "react";
import { Table, TableDelete } from "../../../../../../components";
import { TrocarProprietarioVeicModal } from "../../../../../ordens_servico/atendimento/central_os/incluir_alterar/components/TrocarProprietarioVeicModal";
import { combustivelOptions } from "../LigacaoVeicCli";

export const LigacaoVeicCliGrid = ({
  dados,
  excluir,
  notifyTrocarProprietario,
  alterarVeiculo,
  atualizarVeiculo,
}) => {
  const columns = [
    { dataField: "id", text: "#", align: "center" },
    { dataField: "nome_montadora", text: "Montadora", align: "left" },
    {
      dataField: "nome_veiculo",
      text: "Nome",
      align: "left",
    },
    {
      dataField: "placa",
      text: "Placa",
      align: "center",
      editable: true,
      editorType: "masked",
      editorMask: "aaa-9*99",
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "70px",
      editorMaxLength: 10,
    },
    {
      dataField: "modelo",
      text: "Modelo",
      align: "left",
      editable: true,
      editorType: "text",
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "120px",
      editorMaxLength: 60,
    },
    {
      dataField: "cor",
      text: "Cor",
      align: "left",
      editable: true,
      editorType: "text",
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "90px",
      editorMaxLength: 30,
    },
    {
      dataField: "ano",
      text: "Ano",
      align: "center",
      editable: true,
      editorType: "text",
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "80px",
      editorMaxLength: 10,
    },
    {
      dataField: "combustivel",
      text: "Combustível",
      align: "left",
      editorType: "combobox",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "80px",
      options: combustivelOptions,
    },
    {
      dataField: "chassi",
      text: "Chassi",
      align: "center",
      editable: true,
      editorType: "text",
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "150px",
      editorMaxLength: 30,
    },
    {
      dataField: "tp",
      isDummyField: true,
      align: "center",
      formatter: (cell, row) => (
        <TrocarProprietarioVeicModal
          placa={row.placa}
          notifyEvent={notifyTrocarProprietario}
          grid
        />
      ),
    },
    {
      dataField: "del",
      isDummyField: true,
      align: "center",
      formatter: (cell, row) => <TableDelete onClick={() => excluir(row.id)} />,
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      pageSize={10}
      readOnly
      paginated={false}
    />
  );
};
