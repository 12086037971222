import React from "react";
import { Card } from "reactstrap";
import { PageContainer } from "../../../components/PageContainer";

export const DiretorioConteudo = () => {
  return (
    <PageContainer title="Diretório de Conteúdo" number="conf_03">
      <Card body></Card>
    </PageContainer>
  );
};
