import React from "react";
import { Row } from "reactstrap";
import { ComboBox } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutImpressao } from "../store/romaneio_slice";

const layoutImpressaoOptions = [
  {
    label:
      "Modelo 1 - Folha A4 - Dados do Cliente, Número NF, Forma de Pagamento",
    value: 1,
  },
  {
    label: "Modelo 2 - Folha A4 - Nº do Pedido, Nome do Cliente e Valor",
    value: 2,
  },
];

export const PersonRomaneio = () => {
  const store = useSelector((state) => state.romaneio);
  const dispatch = useDispatch();
  return (
    <>
      <Row>
        <ComboBox
          md={5}
          label="Layout de Impressão"
          options={layoutImpressaoOptions}
          defaultValue={store.layout_impressao}
          onChange={(s) => dispatch(setLayoutImpressao(s?.value))}
        />
      </Row>
    </>
  );
};
