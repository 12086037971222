import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormCheckbox,
  TextInput,
} from "../../../../../components";
import MaskedInput from "../../../../../components/MaskedInput";
import {
  setAgencia,
  setBanco,
  setCep,
  setChavePix,
  setCidade,
  setConsideraFluxoCxa,
  setFone,
  setGerente,
  setNomeBeneficiarioPix,
  setNroConta,
} from "../store/cadastroContaBancSlice";

export const TabDadosGerais = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.cadastroContaBanc);
  return (
    <>
      <Row>
        <AsyncComboBox
          onChange={(s) => dispatch(setBanco(s?.value))}
          label="Banco"
          md={6}
          isConcatField
          concatModelName="banco"
          defaultOptions
          isSearchable
          isClearable
          defaultValue={store.banco}
        />
        <TextInput
          label="Agência"
          md={2}
          onChange={(e, v) => dispatch(setAgencia(v))}
          value={store.agencia}
          maxLength={10}
        />
        <TextInput
          label="Número da Conta"
          md={3}
          onChange={(e, v) => dispatch(setNroConta(v))}
          value={store.nro_conta}
          maxLength={20}
        />
      </Row>
      <Row>
        <AsyncComboBox
          onChange={(s) => dispatch(setCidade(s?.value ?? 0))}
          label="Cidade"
          md={5}
          isConcatField
          concatModelName="cidade"
          defaultOptions
          isSearchable
          isClearable
          defaultValue={store.cidade}
        />
        <MaskedInput
          mask="99999-999"
          name="cep"
          label="CEP"
          md={3}
          onChange={(e, v) => dispatch(setCep(v))}
          value={store.cep}
        />
        <TextInput
          label="Telefone"
          md={3}
          onChange={(e, v) => dispatch(setFone(v))}
          value={store.fone}
          maxLength={15}
        />
      </Row>
      <Row>
        <TextInput
          label="Gerente"
          md={6}
          onChange={(e, v) => dispatch(setGerente(v))}
          value={store.gerente}
          maxLength={60}
        />
        <FormCheckbox
          label="Considerar no Fluxo de Caixa"
          md="auto"
          name="considera_fluxo_cxa"
          id="considera_fluxo_cxa"
          onChange={() =>
            dispatch(setConsideraFluxoCxa(!store.considera_fluxo_cxa))
          }
          checked={store.considera_fluxo_cxa}
        />
      </Row>
      <Row>
        <TextInput
          label="Chave PIX"
          md={5}
          onChange={(e, v) => dispatch(setChavePix(v))}
          value={store.chave_pix}
          maxLength={50}
        />
        <TextInput
          label="Beneficiário PIX"
          md={7}
          onChange={(e, v) => dispatch(setNomeBeneficiarioPix(v))}
          value={store.nome_beneficiario_pix}
          maxLength={100}
          hint="Se o nome do beneficiário não for definido na conta bancária, o sistema irá utilizar a razão social da empresa"
        />
      </Row>
    </>
  );
};
