import React from "react";
import { Table, TableCheck } from "../../../../../components";

const columns = [
  { dataField: "id", text: "#", align: "center", headerAlign: "center" },
  { dataField: "nome", text: "Nome", align: "left", headerAlign: "left" },
  {
    dataField: "desc_modulo",
    text: "Módulo",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "permite_interacao",
    text: "Permite Interações",
    align: "center",
    headerAlign: "center",
    formatter: (c) => <TableCheck value={c} />,
  },
];

export const AvisoVincColaboradorGrid = ({ dados, setSelected }) => {
  return <Table data={dados} columns={columns} onSelect={setSelected} />;
};
