import React, { useEffect, useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  Table,
  TableCheck,
  FormButton,
  ModalBase,
  SearchInput,
} from "../../../../../components";
import CadastroListaPrecosService from "../../../../../services/cadastro/CadastroListaPrecosService";
import { optionsListaPrecoPara } from "../ListaPrecos";

const filtrarPista = (dados, pista) => {
  if ((pista ?? "").trim() !== "") {
    const checkCampo = (campo) => (campo ?? "").toUpperCase().includes(pista);

    return dados.filter(
      (e) =>
        checkCampo(e.nome_para) ||
        checkCampo(e.nome_fant_cliente) ||
        checkCampo(e.cidade_cliente) ||
        e.id_para.toString() === pista
    );
  } else {
    return dados;
  }
};

export const DetalhesListaPara = ({
  idCab,
  tipoListaPara,
  definirTipoListaPara,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [para, setPara] = useState(null);
  const [idPara, setIdPara] = useState(null);
  const [permiteAlterarPreco, setPermiteAlterarPreco] = useState(false);
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [alterouAlgo, setAlterouAlgo] = useState(false);
  const [selectedDel, setSelectedDel] = useState([]);
  const selectRef = useRef();

  useEffect(() => {
    setPara(tipoListaPara);
  }, [tipoListaPara]);

  const columns = [
    {
      dataField: "desc_para",
      text: "Tipo",
      align: "center",
      sortable: true,
    },
    {
      dataField: "id_para",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome_para",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "nome_fant_cliente",
      text: "Nome Fantasia",
      align: "left",
      sortable: true,
      hidden: para !== "CLIENTE",
    },
    {
      dataField: "cidade_cliente",
      text: "Cidade",
      align: "left",
      sortable: true,
      hidden: para !== "CLIENTE",
      formatter: (c, row) => `${c}-${row.uf_cliente}`,
    },
    {
      dataField: "permite_alterar_preco",
      text: "Permite Alterar Preço",
      align: "center",
      sortable: true,
      formatter: (c) => <TableCheck value={c} />,
    },
  ];

  const limparDados = () => {
    setPista("");
    setDados([]);
    setSelectedDel([]);
  };

  const toggle = () => {
    if (!isOpen) {
      carregarDados();
    } else {
      setIdPara(null);
      if (alterouAlgo) {
        notifyEvent();
      }
    }
    setIsOpen(!isOpen);
    setAlterouAlgo(false);
    limparDados();
  };

  const carregarDados = async () => {
    const [ok, ret] = await CadastroListaPrecosService.para.listar(idCab);
    if (ok) {
      setDados(ret);
      if (ret?.length === 0) {
        definirTipoListaPara(null);
      }
      const idsRet = ret.map((e) => e.id);
      setSelectedDel(selectedDel.filter((e) => idsRet.includes(e)));
    } else {
      setSelectedDel([]);
    }
  };

  const handlePista = (v) => {
    setPista(v);
    const idsFilt = filtrarPista(dados, v).map((e) => e.id);
    setSelectedDel(selectedDel.filter((e) => idsFilt.includes(e)));
  };

  const handleSelectDel = (v, is) => {
    setSelectedDel(
      is ? [...selectedDel, v] : selectedDel.filter((e) => e !== v)
    );
  };

  const handleSelectAllDel = (is) => {
    setSelectedDel(is ? dados.map((e) => e.id) : []);
  };

  const handleDelete = async () => {
    const payload = { id_lista_preco_para: selectedDel };
    const [ok] = await CadastroListaPrecosService.para.excluir(payload);
    if (ok) {
      carregarDados();
      setSelectedDel([]);
      setAlterouAlgo(true);
    }
  };

  const handleAdd = async () => {
    if (idCab !== null) {
      let data = {
        id_cab: idCab,
        para: para,
        id_para: idPara,
        permite_alterar_preco: permiteAlterarPreco,
      };

      setLoadingAdd(true);

      const [ok] = await CadastroListaPrecosService.para.incluir(data);
      if (ok) {
        carregarDados();
        definirTipoListaPara(para);
        setAlterouAlgo(true);
      }

      setLoadingAdd(false);
      selectRef.current.clearValue();
      selectRef.current.setFocus();
    }
  };

  const descricaoItens =
    {
      CLIENTE: "Clientes da Lista",
      ROTA: "Rotas (APP Pronta Entrega) da Lista",
      VENDEDOR: "Vendedores (APP Pedidos) da Lista",
      LISTA_SELECT: "Lista Selecionável (APP Pedidos)",
    }[tipoListaPara] ?? "Itens";

  const labelPara =
    {
      CLIENTE: "Cliente",
      ROTA: "Rota",
      VENDEDOR: "Vendedor",
      LISTA_SELECT: "Lista Selecionável",
    }[para] ?? "Selecione o Tipo";

  const labelExcluir =
    {
      CLIENTE: "Clientes da Lista",
      ROTA: "Rotas da Lista",
      VENDEDOR: "Vendedores da Lista",
      LISTA_SELECT: "Lista Selecionável",
    }[para] ?? "";

  const concatModelNamePara = {
    LISTA_SELECT: null,
    CLIENTE: "cliente",
    ROTA: "rota_mob",
    VENDEDOR: "colaborador",
  }[para];

  return (
    <>
      <FormButton
        md="auto"
        color="info"
        disabled={[0, null, undefined].includes(idCab)}
        onClick={toggle}
      >
        {descricaoItens}
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title={descricaoItens}
        number="0013_7"
      >
        <Row>
          <ComboBox
            md={3}
            label="Tipo"
            options={optionsListaPrecoPara}
            onChange={(s) => {
              setPara(s?.value);
              setIdPara(null);
              selectRef.current.clearValue();
              if (s?.value === "CLIENTE") {
                setPermiteAlterarPreco(false);
              }
            }}
            defaultValue={para}
            isSearchable={false}
            isClearable
            isDisabled={tipoListaPara}
            divClassName="pr-0"
          />
          <AsyncComboBox
            label={labelPara}
            md={5}
            isConcatField
            concatModelName={concatModelNamePara}
            isSearchable
            isClearable
            onChange={(v) => {
              setIdPara(v?.value);
            }}
            autoFocus
            ref={selectRef}
            disabled={para === "LISTA_SELECT"}
            hideShortcut
            clearOnDisable
          />
          <FormCheckbox
            label="Permite Alteração de Preço"
            checked={permiteAlterarPreco}
            onChange={() => setPermiteAlterarPreco(!permiteAlterarPreco)}
            disabled={para === "CLIENTE"}
          />
          <FormButton
            color="success"
            loading={loadingAdd}
            onClick={handleAdd}
            disabled={[null, undefined].includes(idPara)}
            disabledHint="Selecione um item no campo à esquerda"
            divClassName="ml-auto pr-0"
          >
            Adicionar à lista
          </FormButton>
        </Row>
        <Row className="mb-2">
          <SearchInput
            label="Pesquisar na Lista Abaixo"
            md={5}
            onChange={handlePista}
          />
          <FormButton
            color="danger"
            disabled={selectedDel.length === 0}
            disabledHint="Selecione ao menos um item da lista"
            onClick={handleDelete}
            divClassName="ml-auto pr-0"
          >
            Remover {labelExcluir}
          </FormButton>
        </Row>
        <Table
          data={filtrarPista(dados, pista)}
          columns={columns}
          paginated={false}
          pageSize={15}
          multiselect
          selected={selectedDel}
          onSelect={handleSelectDel}
          onSelectAll={handleSelectAllDel}
        />
      </ModalBase>
    </>
  );
};
