import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import { PesqPlanoCtaCentroCusto, TextInput } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  modalTitleDespesaJornada,
  routesBaseDespesaJornada,
} from "../DespesasJornada";

export const CadastroDespesaJornadaModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [idPlanoContas, setIdPlanoContas] = useState(null);
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setNome("");
    setIdPlanoContas(null);
    setAtivo(true);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setIdPlanoContas(data.id_plano_contas);
    setAtivo(data.ativo);
  };

  const submitPayload = (action) => {
    if ([0, null, undefined].includes(idPlanoContas)) {
      toastr.warning("Atenção", "Selecione um plano de contas.");
      return false;
    }

    if (["", null, undefined].includes(nome)) {
      toastr.warning("Atenção", "Adicione uma descrição.");
      return false;
    }

    const payload = {
      nome: nome,
      id_plano_contas: idPlanoContas,
      ativo: ativo,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleDespesaJornada}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseDespesaJornada}
      number="0011_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={10}
          label="Descrição da despesa"
          onChange={(e, v) => setNome(v)}
          value={nome}
          maxLength={80}
          autoFocus
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={10}
          label="Plano de Contas"
          mode="plano_contas"
          onChange={(v) => setIdPlanoContas(v)}
          value={idPlanoContas}
        />
      </Row>
    </ModalCadastroV2>
  );
};
