import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroPremiadoModal } from "./components/CadastroPremiadoModal";
import { PremiadoGrid } from "./components/PremiadoGrid";
import { apiGetV2 } from "../../../../apiV2";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../../coreUtils";
import {
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../../components";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitlePremiado = "Premiado";
export const routesBasePremiado = "/premiacao/premiado";

export const Premiado = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p) => {
    const [ok, ret] = await apiGetV2(`${routesBasePremiado}/listar/`, {
      pista: p,
    });
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Premiados" number="0105" canGoBack>
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroPremiadoModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitlePremiado}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0105_2"
            selected={selected}
            routeBase={routesBasePremiado}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <PremiadoGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
