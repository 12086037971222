import React, { useRef } from "react";
import { useState } from "react";
import {
  modalTitleFichaTecnica,
  routesBaseFichaTecnica,
} from "../FichaTecnica";
import { Row } from "reactstrap";
import { FixedField, ModalBase } from "../../../../../components";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import { v4 as uuidv4 } from "uuid";
import { apiGetV2, apiPostV2 } from "../../../../../apiV2";
import { InsumosFichaTecnicaForm } from "./CadastroFichaTecnicaModal";

export const DuplicarFichaTecnicaModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [idProdutoFinal, setIdProdutoFinal] = useState(null);
  const [nomeProdutoFinal, setNomeProdutoFinal] = useState("");
  const [unidade, setUnidade] = useState("");
  const [insumos, setInsumos] = useState([]);
  const [loading, setLoading] = useState(false);

  const pesqProdInsumoRef = useRef();
  const pesqProdFinalRef = useRef();

  const limparDados = () => {
    setAtivo(true);
    setIdProdutoFinal(null);
    setNomeProdutoFinal("");
    setUnidade("");
    setInsumos([]);
  };

  const fetchData = async () => {
    const [ok, ret] = await apiGetV2(
      `${routesBaseFichaTecnica}/buscar/${selected}/`
    );
    if (ok) {
      setAtivo(ret.ativo);
      setIdProdutoFinal(ret.id_produto);
      setNomeProdutoFinal(ret.nome_produto);
      setUnidade(ret.unidade);
      setInsumos(
        ret.insumos.map((e) => ({
          uuid: uuidv4(),
          tipo: e.tipo,
          desc_tipo: e.desc_tipo,
          id_produto: e.id_produto,
          nome_produto: e.nome_produto,
          unidade: e.unidade,
          quantidade: parseFloat(e.quantidade),
          vlr_custo_unit: parseFloat(e.vlr_custo_unit),
          vlr_custo_total: parseFloat(e.vlr_custo_total),
        }))
      );
      setTimeout(() => {
        if (pesqProdFinalRef.current) {
          pesqProdFinalRef.current.setDescricao(ret.nome_produto);
          pesqProdFinalRef.current.setId(String(ret.id_produto));
          pesqProdFinalRef.current.setReferencia(
            String(ret.referencia_produto)
          );
        }
      }, 150);
    } else {
      toggle();
    }
  };

  const handleSelectProdutoFinal = ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    unidade,
  }) => {
    if (idProdutoFinal !== id) {
      setIdProdutoFinal(id);
      setNomeProdutoFinal(nome);
      setUnidade(unidade);
      if (pesqProdFinalRef.current) {
        pesqProdFinalRef.current.setDescricao(nome);
        pesqProdFinalRef.current.setId(String(id));
        pesqProdFinalRef.current.setReferencia(String(referencia));
      }
    }
  };

  const selectNextFieldProdutoFinal = () =>
    pesqProdInsumoRef.current && pesqProdInsumoRef.current.focus();

  const submitPayload = async (action) => {
    const payload = {
      ativo: ativo,
      id_produto_final: idProdutoFinal,
      nome_produto_final: nomeProdutoFinal,
      unidade: unidade,
      insumos: insumos.map((e) => ({
        tipo: e.tipo,
        id_produto: e.id_produto,
        nome_produto: e.nome_produto,
        unidade: e.unidade,
        quantidade: e.quantidade,
        vlr_custo_unit: e.vlr_custo_unit,
      })),
    };

    setLoading(true);
    const [ok] = await apiPostV2(`${routesBaseFichaTecnica}/incluir/`, payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title={`Duplicar ${modalTitleFichaTecnica}`}
      size="xl"
      onClosed={limparDados}
      onBeforeOpen={fetchData}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      number="0092_3"
      onConfirm={submitPayload}
      loadingConfirm={loading}
    >
      <Row className="mb-2">
        <PesqProduto
          label="Produto Final"
          md={10}
          mdIdent={3}
          mdDesc={7}
          onConfirm={handleSelectProdutoFinal}
          ref={pesqProdFinalRef}
          selectNextField={selectNextFieldProdutoFinal}
          onChangeDescricao={setNomeProdutoFinal}
        />
        <FixedField
          label="Unidade"
          value={unidade}
          horizontal
          divClassName="pt-4"
        />
      </Row>
      <InsumosFichaTecnicaForm
        insumos={insumos}
        setInsumos={setInsumos}
        pesqProdInsumoRef={pesqProdInsumoRef}
      />
    </ModalBase>
  );
};
