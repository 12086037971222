import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormButton,
  PageContainer,
  RadioGroup,
} from "../../../../components";
import XLSX from "xlsx";
import { Card, Row } from "reactstrap";
import { apiGetV2 } from "../../../../apiV2";
import { LancamentosEstoqueGrid } from "./components/LancamentosEstoqueGrid";
import { RadioItem } from "../../../../components/RadioGroup";
import { docPrintReport } from "../../../../pdf";
import { formatDateISO, formatDateLocal } from "../../../../coreUtils";

const visaoOptions = [
  { label: "Estoque Real ADM", value: "REAL_ADM" },
  { label: "Estoque ADM Cadastral", value: "ADM_CADASTRAL" },
  { label: "Estoque Intermediário", value: "EST_INTER" },
  { label: "Estoque das Rotas", value: "ROTA" },
];

export const LancamentosEstoque = () => {
  const [idProduto, setIdProduto] = useState(null);
  const [idEstoqueIntermediario, setIdEstoqueIntermediario] = useState(null);
  const [idRota, setIdRota] = useState(null);
  const [visao, setVisao] = useState(visaoOptions[0].value);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_produto: idProduto,
      id_estoque_intermediario: idEstoqueIntermediario,
      id_rota: idRota,
      visao: visao,
    };

    return await apiGetV2("/relatorios/estoque/lancamentos/", params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret.lancamentos : []);
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      await docPrintReport(
        "/relatorios/estoque/lancamentos/",
        {
          dados: ret.lancamentos,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
        },
        "5059"
      );
    }
    setLoadingImprimir(false);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    try {
      const [ok, ret] = await buscarDados();
      if (ok) {
        const lancamentos = ret.lancamentos;

        const data = [];

        data.push([
          "Data/Hora",
          "Natureza",
          "Descrição",
          "ID Origem",
          "Colaborador",
          "Produto",
          "Qtd. Antes",
          "Qtd. Mov.",
          "Qtd. Depois",
          "Custo Unit.",
          "Custo Total",
        ]);

        lancamentos.forEach((lancamento) =>
          lancamento.produtos.forEach((produto) =>
            data.push([
              lancamento["data_hora"],
              lancamento["natureza"],
              lancamento["descricao"],
              lancamento["id_origem"],
              lancamento["colaborador"],
              produto["nome_produto"],
              parseFloat(produto["qtd_antes"]),
              parseFloat(produto["qtd_transac"]),
              parseFloat(produto["qtd_depois"]),
              parseFloat(produto["vlr_custo"]),
              parseFloat(produto["vlr_tot_custo"]),
            ])
          )
        );

        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = { Sheets: { Relatório: ws }, SheetNames: ["Relatório"] };

        const inicio = formatDateLocal(dataIni);
        const fim = formatDateLocal(dataFim);
        const descVisao = visaoOptions.find((e) => e.value === visao).label;

        let nomeArquivo = `Lançamentos de Estoque - ${descVisao} - ${inicio} a ${fim}.xlsx`;

        XLSX.writeFile(wb, nomeArquivo);
      }
    } finally {
      setLoadingExportar(false);
    }
  };

  useEffect(() => {
    setDados([]);
  }, [idProduto, idEstoqueIntermediario, idRota, visao, dataIni, dataFim]);

  useEffect(() => {
    setIdEstoqueIntermediario(null);
    setIdRota(null);
  }, [visao]);

  const verificaPodeGerarRelatorio = () => {
    if (
      visao === "EST_INTER" &&
      [0, null, undefined].includes(idEstoqueIntermediario)
    ) {
      return [false, "Informe o Estoque Intermediário"];
    }
    if (visao === "ROTA" && [0, null, undefined].includes(idRota)) {
      return [false, "Informe a Rota"];
    }

    return [true, null];
  };

  const [podeGerar, hintNaoPodeGerar] = verificaPodeGerarRelatorio();

  return (
    <PageContainer title="Lançamentos de Estoque" number="5059">
      <Card body>
        <Row>
          <RadioGroup label="Visão" value={visao} onChange={setVisao}>
            {visaoOptions.map((e) => (
              <RadioItem label={e.label} value={e.value} />
            ))}
          </RadioGroup>
          {visao === "ROTA" && (
            <AsyncComboBox
              key="rota"
              md={3}
              label="Rota"
              isSearchable
              isClearable
              isConcatField
              defaultOptions
              concatModelName="rota_mob"
              defaultValue={idRota}
              onChange={(s) => setIdRota(s?.value)}
              hideShortcut
            />
          )}
          {visao === "EST_INTER" && (
            <AsyncComboBox
              key="estoque_inter"
              md={3}
              label="Estoque Intermediário"
              isSearchable
              isClearable
              isConcatField
              defaultOptions
              concatModelName="rota_estoque_inter"
              defaultValue={idEstoqueIntermediario}
              onChange={(s) => setIdEstoqueIntermediario(s?.value)}
              hideShortcut
            />
          )}
        </Row>
        <Row>
          <AsyncComboBox
            md={4}
            label="Produto"
            isSearchable
            isClearable
            isConcatField
            concatModelName="produto"
            defaultValue={idProduto}
            onChange={(s) => setIdProduto(s?.value)}
            hideShortcut
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            disabled={!podeGerar}
            disabledHint={hintNaoPodeGerar}
          />
          <BotaoImprimir
            onClick={imprimir}
            loading={loadingImprimir}
            disabled={!podeGerar}
            disabledHint={hintNaoPodeGerar}
          />
          <FormButton
            color="primary"
            onClick={exportar}
            loading={loadingExportar}
            disabled={!podeGerar}
            disabledHint={hintNaoPodeGerar}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <LancamentosEstoqueGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
