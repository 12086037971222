import React from "react";
import { useSelector } from "react-redux";
import { Card, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { formatNumber, objectIsEmpty } from "../../../../../coreUtils";

export const CardResultado = () => {
  const store = useSelector((state) => state.dre);
  if (!objectIsEmpty(store.resultado)) {
    return (
      <Card body>
        <Row>
          <Col>
            <ListGroup horizontal className="mb-2">
              <ListGroupItem
                style={{ justifyContent: "space-between", paddingLeft: "0" }}
              >
                <h6
                  style={{
                    marginTop: "inherit",
                    marginBottom: "inherit",
                    fontWeight: "bold",
                  }}
                >
                  Transitório:
                </h6>
                <h6
                  style={{
                    marginTop: "inherit",
                    marginBottom: "inherit",
                    fontWeight: "bold",
                  }}
                >
                  R$ {formatNumber(store.resultado.transitorio, true, 2)}
                </h6>
              </ListGroupItem>
              <ListGroupItem style={{ justifyContent: "space-between" }}>
                <h6
                  style={{
                    marginTop: "inherit",
                    marginBottom: "inherit",
                    fontWeight: "bold",
                  }}
                >
                  Investimento:
                </h6>
                <h6
                  style={{
                    marginTop: "inherit",
                    marginBottom: "inherit",
                    fontWeight: "bold",
                  }}
                >
                  R$ {formatNumber(store.resultado.investimento, true, 2)}
                </h6>
              </ListGroupItem>
              <ListGroupItem style={{ justifyContent: "space-between" }}>
                <h6
                  style={{
                    marginTop: "inherit",
                    marginBottom: "inherit",
                    fontWeight: "bold",
                  }}
                >
                  Despesa:
                </h6>
                <h6
                  style={{
                    marginTop: "inherit",
                    marginBottom: "inherit",
                    fontWeight: "bold",
                  }}
                >
                  R$ {formatNumber(store.resultado.despesa, true, 2)}
                </h6>
              </ListGroupItem>
              <ListGroupItem
                style={{ justifyContent: "space-between", paddingRight: "0" }}
              >
                <h6
                  style={{
                    marginTop: "inherit",
                    marginBottom: "inherit",
                    fontWeight: "bold",
                  }}
                >
                  Receita:
                </h6>
                <h6
                  style={{
                    marginTop: "inherit",
                    marginBottom: "inherit",
                    fontWeight: "bold",
                  }}
                >
                  R$ {formatNumber(store.resultado.receita, true, 2)}
                </h6>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 style={{ fontWeight: "bold", marginBottom: "0" }}>
              Resultado: R$ {formatNumber(store.resultado.resultado, true, 2)}
            </h6>
          </Col>
        </Row>
      </Card>
    );
  } else {
    return <div></div>;
  }
};
