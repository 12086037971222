import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  layout_impressao: 1,
};

export const romaneioSlice = createSlice({
  name: "romaneio_slice",
  initialState: initialState,
  reducers: {
    setupRomaneio: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setLayoutImpressao: (state, action) => {
      state.layout_impressao = action.payload;
    },
  },
});

export const { setupRomaneio, setLayoutImpressao } = romaneioSlice.actions;

export default romaneioSlice.reducer;
