import React from "react";
import { ModalBase } from "../../../../../../components";

export const ConfirmarGarantiaModal = ({ toggle }) => {
  return (
    <ModalBase
      isOpen={true}
      toggle={toggle}
      size="sm"
      title="Incluir Item com Garantia"
      onConfirm={() => toggle(true)}
      cancelButtonText="Não"
      confirmButtonText="Sim"
    >
      <p>
        Você está incluindo um item de garantia. Tem certeza que deseja
        continuar?
      </p>
    </ModalBase>
  );
};
