import React from "react";
import { Card } from "reactstrap";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { DetalhesCliInadRotaModal } from "./DetalhesCliInadRotaModal";
import { Table } from "../../../../../components";

export const ClientesInadRotaGrid = ({ dados }) => {
  const columns = [
    {
      dataField: "nome",
      text: "Rota",
      align: "left",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_colaborador",
      text: "Vendedor",
      align: "left",
    },
    {
      dataField: "num_titulos",
      text: "Títulos",
      align: "center",
    },
    {
      dataField: "vlr_tot_pend",
      text: "Valor Total a Receber",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortFunc: naturalSort,
      sortable: true,
    },
    {
      dataField: "det",
      align: "center",
      isDummyField: true,
      formatter: (c, row) => <DetalhesCliInadRotaModal row={row} />,
    },
  ];

  return (
    <Card body>
      <Table columns={columns} data={dados} keyField="id" paginated={false} />
    </Card>
  );
};
