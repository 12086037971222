import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  permite_manipular_venda_os: false,
  id_plano_contas: null,
  id_centro_custo: null,
  id_tipo_doc_venda_bonificada: null,
  destacar_frete_na_nf: true,
  vlr_despesa_financeira: 0,
  destacar_local_entrega_na_nfe: false,
  utilizar_condicoes_pag: "GERAL",
  id_conta_banc_geracao_boleto: 0,
  unificar_titulos_prod_serv: false,
  adiciona_nro_venda_ord_serv_boleto: true,
  licenca_faturar_outra_empresa: null,
  emitir_nf_automaticamente: true,
  nunca_gera_mov_financeira: true,
  destaca_vlr_desp_financ_nfe: false,
  nfse_destaca_obs_financ_os_em: "DESC_SERV",
};

export const faturamentoSlice = createSlice({
  name: "faturamento_slice",
  initialState: initialState,
  reducers: {
    setupFaturamento: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (["vlr_despesa_financeira"].includes(key)) {
          value = parseFloat(value);
        }

        state[key] = value;
      });
      return state;
    },
    setPermiteManipularVendaOs: (state, action) => {
      state.permite_manipular_venda_os = action.payload;
    },
    setIdPlanoContas: (state, action) => {
      state.id_plano_contas = action.payload;
    },
    setIdCentroCusto: (state, action) => {
      state.id_centro_custo = action.payload;
    },
    setIdTipoDocVendaBonificada: (state, action) => {
      state.id_tipo_doc_venda_bonificada = action.payload;
    },
    setDestacarFreteNaNf: (state, action) => {
      state.destacar_frete_na_nf = action.payload;
    },
    setVlrDespesaFinanceira: (state, action) => {
      state.vlr_despesa_financeira = action.payload;
    },
    setDestacarLocalEntregaNaNfe: (state, action) => {
      state.destacar_local_entrega_na_nfe = action.payload;
    },
    setUtilizarCondicoesPag: (state, action) => {
      state.utilizar_condicoes_pag = action.payload;
    },
    setIdContaBancGeracaoBoleto: (state, action) => {
      state.id_conta_banc_geracao_boleto = action.payload;
    },
    setUnificarTitulosProdServ: (state, action) => {
      state.unificar_titulos_prod_serv = action.payload;
    },
    setAdicionaNroVendaOrdServBoleto: (state, action) => {
      state.adiciona_nro_venda_ord_serv_boleto = action.payload;
    },
    setLicencaFaturarOutraEmpresa: (state, action) => {
      state.licenca_faturar_outra_empresa = action.payload;
    },
    setEmitirNfAutomaticamente: (state, action) => {
      state.emitir_nf_automaticamente = action.payload;
    },
    setNuncaGeraMovFinanceira: (state, action) => {
      state.nunca_gera_mov_financeira = action.payload;
    },
    setDestacaVlrDespFinancNfe: (state, action) => {
      state.destaca_vlr_desp_financ_nfe = action.payload;
    },
    setNfseDestacaObsFinancOsEm: (state, action) => {
      state.nfse_destaca_obs_financ_os_em = action.payload;
    },
  },
});

export const {
  setupFaturamento,
  setPermiteManipularVendaOs,
  setIdPlanoContas,
  setIdCentroCusto,
  setIdTipoDocVendaBonificada,
  setDestacarFreteNaNf,
  setVlrDespesaFinanceira,
  setDestacarLocalEntregaNaNfe,
  setUtilizarCondicoesPag,
  setIdContaBancGeracaoBoleto,
  setNfseDestacaObsFinancOsEm,
  setUnificarTitulosProdServ,
  setAdicionaNroVendaOrdServBoleto,
  setLicencaFaturarOutraEmpresa,
  setEmitirNfAutomaticamente,
  setNuncaGeraMovFinanceira,
  setDestacaVlrDespFinancNfe,
} = faturamentoSlice.actions;

export default faturamentoSlice.reducer;
