import React from "react";
import { Card } from "reactstrap";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { DetalhamentoComissaoModal } from "./DetalhamentoComissaoModal";
import { Table } from "../../../../../components";

export const GerenciamentoComissoesGrid = ({ data, dataIni, dataFim }) => {
  const columns = [
    {
      dataField: "id_rota_mob",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "id_rota_mob__nome",
      text: "Rota",
      align: "left",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "det",
      isDummyField: true,
      align: "center",
      formatter: (cell, row, index) => (
        <DetalhamentoComissaoModal
          idRota={row.id_rota_mob}
          nomeRota={row.id_rota_mob__nome}
          dataIni={dataIni}
          dataFim={dataFim}
        />
      ),
    },
  ];

  return (
    <Card body>
      <Table
        columns={columns}
        data={data}
        keyField="id_rota_mob"
        paginated={false}
      />
    </Card>
  );
};
