import React, { useState } from "react";
import {
  AsyncComboBox,
  FixedField,
  FormButton,
  ModalBase,
} from "../../../../../components";
import { Row } from "reactstrap";
import TransferenciaVendaService from "../../../../../services/faturamento/TransferenciaVendaService";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { showWarning } from "../../../../../components/AlertaModal";

export const TransferirVendaModal = ({
  selected,
  notifyEvent,
  empresaDestino,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idVendedor, setIdVendedor] = useState(null);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdVendedor(null);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idVendedor)) {
      showWarning("Por favor, informe o Vendedor na Empresa Destino");
      return;
    }

    setLoading(true);
    const payload = {
      id_venda: selected,
      id_vendedor: idVendedor,
    };

    const [ok] = await TransferenciaVendaService.faturarVendaOutraEmpresa(
      payload
    );
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        onClick={toggle}
        disabled={[0, null, undefined].includes(selected)}
      >
        Transferir
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Transferir Venda para Empresa"
        number="0082_1"
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row className="mb-2">
          <FixedField label="Nº Venda" value={selected} />
          <FixedField
            label="Empresa Destino"
            value={formatValueFromAPI(
              empresaDestino?.nome ?? "",
              empresaDestino?.licenca ?? ""
            )}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={12}
            label="Vendedor na Empresa Destino"
            isConcatField
            concatModelName="colaborador"
            isSearchable
            isClearable
            onChange={(s) => setIdVendedor(s?.value)}
            outraEmpresa
          />
        </Row>
      </ModalBase>
    </>
  );
};
