import React from "react";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { Table } from "../../../../../components";

const columns = [
  {
    dataField: "nome",
    text: "Nome",
    align: "left",
    headerAlign: "left",
    formatter: (c, r) => formatValueFromAPI(c, r.id),
  },
  {
    dataField: "quantidade",
    text: "Estoque",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "codigo_cfop",
    text: "CFOP Atual",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "ncm_lista",
    text: "NCM",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "custo_ue",
    text: "Vlr. Custo",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "preco_venda",
    text: "Vlr. Venda",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const ProdutosExclusaoICMSSTGrid = ({ data }) => {
  return <Table columns={columns} data={data} pageSize={15} />;
};
