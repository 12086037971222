import React from "react";
import { useState } from "react";
import { TextInput } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { modalTitleSubGrupo, routesBaseSubGrupo } from "../SubGrupo";
import { ModalCadastroV2 } from "../../../../../components/cadastro";

export const CadastroSubGrupoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [status, setStatus] = useState(true);
  const [nome, setNome] = useState("");

  const limparDados = () => {
    setStatus(true);
    setNome("");
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setStatus(data.status);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, status: status, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleSubGrupo}
      size="sm"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: status,
          toggle: () => setStatus(!status),
        }
      }
      routesBase={routesBaseSubGrupo}
      number="0108_1"
      selected={selected}
      notifyEvent={notifyEvent}
      concatShortcut={concatShortcut}
      concatModelName="sub_grupo"
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={40}
        />
      </Row>
    </ModalCadastroV2>
  );
};
