import React, { useRef, useState } from "react";
import {
  ComboBox,
  DatePicker,
  Divider,
  FormButton,
  ModalBase,
  NumberInput,
  RadioGroup,
  Table,
  TableDelete,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import { RadioItem } from "../../../../../components/RadioGroup";
import ManutencaoEstoqueService from "../../../../../services/cadastro/ManutencaoEstoqueService";
import { showWarning } from "../../../../../components/AlertaModal";
import {
  formatDateISO,
  formatNumber,
  roundFloat,
} from "../../../../../coreUtils";
import { apiGetV2 } from "../../../../../apiV2";
import moment from "moment";

export const AjusteEstoqueModal = ({ isOpen, toggle, notifyEvent }) => {
  const [natureza, setNatureza] = useState(null);
  const [historico, setHistorico] = useState("");
  const [lancamentos, setLancamentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idProduto, setIdProduto] = useState(null);
  const [nomeProduto, setNomeProduto] = useState("");
  const [qtdAtualProd, setQtdAtualProd] = useState(null);
  const [dataMov, setDataMov] = useState(new Date());
  const [idTransacao, setIdTransacao] = useState(null);
  const [custoProd, setCustoProd] = useState(null);
  const [quantidade, setQuantidade] = useState(null);
  const [permiteManipularDados, setPermiteManipularDados] = useState(false);

  const [transacoesEstoque, setTransacoesEstoque] = useState([]);

  const pesqProdRef = useRef();
  const quantidadeRef = useRef();

  const limparDadosProduto = () => {
    setIdProduto(null);
    setNomeProduto("");
    setQtdAtualProd(null);
    setQuantidade(null);
    setCustoProd(null);
  };

  const limparDados = () => {
    setNatureza(null);
    setHistorico("");
    setLancamentos([]);
    limparDadosProduto();
    setIdTransacao(null);
    setDataMov(new Date());
  };

  const buscarParams = async () => {
    const [ok, ret] = await apiGetV2("tela/manutencao_estoque/");
    if (ok) {
      if (!ret.permite_ajuste_estoque) {
        showWarning(
          "Seu perfil não está habilitado a efetuar Ajustes de Estoque."
        );
        toggle();
      }
      setPermiteManipularDados(ret.permite_manipular_dados_inc_mov_manual);
    } else {
      toggle();
    }

    const [ok2, ret2] = await apiGetV2(
      "/sistema/transacao/listar_transacoes_modulo/ET/"
    );
    if (ok2) {
      setTransacoesEstoque(
        ret2.map((e) => ({
          label: `(${e.natureza}) ${e.descricao}`,
          value: e.id,
        }))
      );
    }
  };

  const handleSelectProduto = ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    custoUe,
    quantidade,
  }) => {
    if (idProduto !== id) {
      setIdProduto(id);
      setNomeProduto(nome);
      setQtdAtualProd(quantidade);
      setCustoProd(custoUe);
      if (pesqProdRef.current) {
        pesqProdRef.current.setDescricao(nome);
        pesqProdRef.current.setId(String(id));
        pesqProdRef.current.setReferencia(String(referencia));
      }
    }
  };

  const selectNextFieldProduto = () =>
    quantidadeRef.current && quantidadeRef.current.focus();

  const handleAddProduto = () => {
    if ([0, null, undefined].includes(idProduto)) {
      showWarning("Por favor, selecione um Produto");
      if (pesqProdRef) {
        pesqProdRef.current.focus();
      }
      return;
    }

    if (lancamentos.findIndex((e) => e.id_produto === idProduto) > -1) {
      showWarning("O Produto informado já existe entre os insumos.");
      return;
    }

    if ([0, null, undefined].includes(quantidade)) {
      showWarning("Por favor, informe a quantidade do produto");
      if (quantidadeRef) {
        quantidadeRef.current.focus();
      }
      return;
    }

    const payload = {
      id_produto: idProduto,
      nome_produto: nomeProduto,
      quantidade: quantidade,
      custo_ue: custoProd,
    };
    setLancamentos([...lancamentos, payload]);
    limparDadosProduto();
    if (pesqProdRef.current) {
      pesqProdRef.current.clear();
      pesqProdRef.current.focus();
    }
  };

  const handleDeleteProduto = (id) =>
    setLancamentos(lancamentos.filter((e) => e.id_produto !== id));

  const handleAlterarProduto = (coluna, novoValor, row) => {
    const valorAntesTest = roundFloat(parseFloat(row[coluna]), 2);
    const novoValorTest = roundFloat(parseFloat(novoValor), 2);
    if (valorAntesTest === novoValorTest) {
      return;
    }

    if (coluna === "quantidade") {
      row.quantidade = parseFloat(novoValor);
    }

    setLancamentos(
      lancamentos.map((e) => (e.id_produto === row.id_produto ? row : e))
    );
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(natureza)) {
      showWarning("Por favor, informe a Natureza do Lançamento");
      return;
    }

    if (lancamentos.length === 0) {
      showWarning("Por favor, informe ao menos um Produto");
      return;
    }

    const payload = {
      natureza: natureza,
      historico: historico,
      data_mov: formatDateISO(dataMov),
      id_transacao: idTransacao,
      lancamentos: lancamentos.map((e) => ({
        id_produto: e.id_produto,
        quantidade: e.quantidade,
        custo_ue: permiteManipularDados ? e.custo_ue : null,
      })),
    };

    setLoading(true);
    const [ok] = await ManutencaoEstoqueService.incluirAjuste(payload);
    if (ok) {
      notifyEvent();
      limparDados();
    }
    setLoading(false);
  };

  const columns = [
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_produto", text: "Nome", align: "left" },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
      editable: true,
      editorType: "number",
      onChange: handleAlterarProduto,
      alwaysShowEditor: true,
    },
    {
      dataField: "custo_ue",
      text: "Custo",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDeleteProduto(row.id_produto)} />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Ajuste de Estoque"
      number="0097_1"
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onBeforeOpen={buscarParams}
    >
      <Row>
        <RadioGroup label="Natureza" value={natureza} onChange={setNatureza}>
          <RadioItem label="Adição" value="+" />
          <RadioItem label="Subtração" value="-" />
        </RadioGroup>
      </Row>
      {permiteManipularDados && (
        <Row>
          <ComboBox
            options={transacoesEstoque}
            onChange={(s) => setIdTransacao(s?.value)}
            label="Transação"
            isClearable={false}
            isSearchable={false}
            md={6}
          />
          <DatePicker
            md={2}
            label="Data do Movimento"
            value={dataMov}
            onChange={(v) => setDataMov(moment.isMoment(v) ? v.toDate() : v)}
            style={{ textAlign: "center" }}
            disabled={!permiteManipularDados}
          />
        </Row>
      )}
      <Row>
        <TextInput
          md={12}
          label="Histórico"
          value={historico}
          onChange={(e, v) => setHistorico(v)}
        />
      </Row>
      <Divider>Produtos</Divider>
      <Row>
        <PesqProduto
          mdIdent={3}
          mdDesc={7}
          md={8}
          onConfirm={handleSelectProduto}
          ref={pesqProdRef}
          selectNextField={selectNextFieldProduto}
          onChangeDescricao={setNomeProduto}
        />
        <NumberInput
          md={2}
          label="Estoque Atual"
          value={qtdAtualProd}
          disabled
        />
      </Row>
      <Row className="mb-2">
        <NumberInput
          md={2}
          label="Quantidade"
          value={quantidade}
          onChange={setQuantidade}
          ref={quantidadeRef}
        />
        <NumberInput
          md={2}
          label="Custo"
          value={custoProd}
          decimalPlaces={2}
          onChange={setCustoProd}
          disabled={!permiteManipularDados}
        />
        <FormButton md="auto" color="info" onClick={handleAddProduto}>
          Incluir
        </FormButton>
      </Row>
      <Table
        data={lancamentos}
        columns={columns}
        paginated={false}
        pageSize={8}
        keyField="id_produto"
      />
    </ModalBase>
  );
};
