import { apiDelete, apiGet, apiPost, urlParams } from "../../api";
import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/receber/cartao_cred";
const GerenciamentoCartaoService = {
  urlBase: URL_BASE,
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  buscar: async (id) => await apiGetV2(`${URL_BASE}/buscar/${id}/`),
  incluirAvulso: async (payload) =>
    await apiPostV2(`${URL_BASE}/incluir_avulso/`, payload),
  alterar: async (payload) => await apiPutV2(`${URL_BASE}/alterar/`, payload),
  excluir: async (idTaxa) =>
    await apiDeleteV2(`${URL_BASE}/excluir/${idTaxa}/`),
  incluirTaxa: async (payload) =>
    await apiPost(`${URL_BASE}/taxas/incluir/`, payload),
  excluirTaxa: async (idTaxa) =>
    await apiDelete(`${URL_BASE}/taxas/excluir/${idTaxa}/`),
  recalcularTaxas: async (payload) =>
    await apiPutV2(`${URL_BASE}/taxas/recalcular_taxas/`, payload),
  compensar: async (payload) =>
    await apiPost(`${URL_BASE}/compensacao/compensar/`, payload),
  estornar: async (idLote) =>
    await apiPost(`${URL_BASE}/compensacao/estornar/`, { lote: idLote }),
  listarCompensacoes: async (
    idMeioPagamento,
    idAdministradora,
    numero,
    idLoteCompensa,
    filtrarPor,
    dataIni,
    dataFim
  ) => {
    const params = urlParams({
      id_meio_pagamento: idMeioPagamento,
      id_administradora: idAdministradora,
      numero: numero,
      id_lote_compensa: idLoteCompensa,
      filtrar_por: filtrarPor,
      data_ini: dataIni,
      data_fim: dataFim,
    });
    return await apiGet(`${URL_BASE}/compensacao/listar/${params}`);
  },
};

export default GerenciamentoCartaoService;
