import React from "react";
import { Table, TableDelete } from "../../../../../components";
import { naturalSort } from "../../../../../coreUtils";

export const VincDespesaGrid = ({ dados, setSelected, excluirVinc }) => {
  const columns = [
    {
      dataField: "id_rota_mob",
      text: "# Rota",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "id_rota_mob__nome",
      text: "Rota",
      align: "left",
      headerAlign: "left",
      sort: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "id_fornecedor",
      text: "# Fornecedor",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "id_fornecedor__nome",
      text: "# Fornecedor",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "id_rota_mob__id_centro_custo_despesa__descricao",
      text: "Centro de Custo",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "actions",
      isDummyField: true,
      text: "Ações",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => excluirVinc(row.id)} />
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      onSelect={setSelected}
      paginated={false}
      pageSize={10}
    />
  );
};
