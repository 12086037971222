import React, { useState } from "react";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import GereciamentoVendasMobileService from "../../../../../services/frente_venda_mobile/GerenciamentoVendasMobileService";
import {
  Divider,
  FixedField,
  ModalBase,
  Table,
} from "../../../../../components";
import { Row } from "reactstrap";

const columns = [
  {
    dataField: "tipo_item",
    text: "Tipo",
    align: "center",
    headerAlign: "center",
    sort: true,
  },
  {
    dataField: "id_produto",
    text: "#",
    align: "center",
    headerAlign: "center",
    sort: true,
  },
  {
    dataField: "nome_produto",
    text: "Produto",
    align: "left",
    headerAlign: "left",
    sort: true,
  },
  {
    dataField: "quantidade",
    text: "Quantidade",
    align: "center",
    headerAlign: "center",
    formatter: (v) => formatNumber(v, true, 2, true),
  },
  {
    dataField: "vlr_item",
    text: "Vlr. Item",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_tot_icms_st",
    text: "Vlr. ICMS ST",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_tot_prod",
    text: "Vlr. Produtos",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Vlr. Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columnsFormasRec = [
  {
    dataField: "moeda",
    text: "Moeda",
    align: "left",
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const headerRowClassName = "mb-1";

export const DetalheVendaProntaEntregaModal = ({
  isOpen,
  toggle,
  idVenda,
  mostrarFormasRec,
}) => {
  const [dadosVenda, setDadosVenda] = useState({});

  const carregarDados = async () => {
    const [ok, ret] = await GereciamentoVendasMobileService.buscar(idVenda);
    if (ok) {
      setDadosVenda(ret);
    } else {
      toggle();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Detalhamento da Venda a Pronta Entrega"
      number="0024_1"
      onBeforeOpen={carregarDados}
      onClosed={() => setDadosVenda({})}
      footerActions
      autoFocus
    >
      <Row className={headerRowClassName}>
        <FixedField
          label="Nro. Venda"
          value={idVenda}
          horizontal
          boldValue
          labelClassName="mb-0"
        />

        <FixedField
          label="Emissão"
          value={dadosVenda.data_hora_emi}
          horizontal
        />
      </Row>
      <Row className={headerRowClassName}>
        <FixedField
          label="Cliente"
          md={6}
          value={formatValueFromAPI(
            dadosVenda.nome_cliente,
            dadosVenda.id_cliente
          )}
          horizontal
        />
        <FixedField
          label="Nome Fantasia"
          md={6}
          value={dadosVenda.nome_fant_cliente}
          horizontal
        />
      </Row>
      <Row className={headerRowClassName}>
        <FixedField
          label="Rota"
          md={3}
          value={dadosVenda.nome_rota_mob}
          horizontal
        />
        <FixedField
          label="Vendedor"
          md={7}
          value={formatValueFromAPI(
            dadosVenda.nome_colaborador,
            dadosVenda.id_colaborador
          )}
          horizontal
        />
      </Row>
      <Row className={headerRowClassName}>
        <FixedField
          md={4}
          label="Produtos"
          value={formatNumber(dadosVenda.vlr_tot_prod, true, 2)}
          horizontal
        />
        <FixedField
          md={4}
          label="ICMS ST"
          value={formatNumber(dadosVenda.vlr_tot_icms_st, true, 2)}
          horizontal
        />
        <FixedField
          md={4}
          label="Total"
          value={formatNumber(dadosVenda.vlr_total, true, 2)}
          horizontal
        />
      </Row>
      <Row className={headerRowClassName}>
        <FixedField label="Observação" value={dadosVenda.obs} horizontal />
      </Row>
      <Table
        data={dadosVenda?.itens ?? []}
        columns={columns}
        paginated={false}
        showRegisterCount={false}
        pageSize={12}
      />
      {mostrarFormasRec && (
        <>
          <Divider>Formas de Recebimento</Divider>
          <Table
            columns={columnsFormasRec}
            data={dadosVenda?.formas_rec ?? []}
            paginated={false}
            pageSize={5}
            growIntoPageSize
            showRegisterCount={false}
          />
        </>
      )}
    </ModalBase>
  );
};
