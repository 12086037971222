import React, { useState } from "react";
import { ModalBase, NumberInput, TextInput } from "../../../../../components";
import { Row } from "reactstrap";
import { formatValueFromAPI } from "../../../../../coreUtils";

export const IncluirCreditoModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
  nomeCliente,
}) => {
  const [observ, setObserv] = useState("");
  const [vlrCred, setVlrCred] = useState(0);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setObserv("");
    setVlrCred(0);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Inclusão de Crédito"
      number="0068_3"
      onClosed={limparDados}
      loading={loading}
    >
      <Row>
        <TextInput
          md={10}
          label="Cliente"
          value={formatValueFromAPI(nomeCliente, selected)}
          disabled
        />
      </Row>
      <Row>
        <TextInput
          label="Obervação"
          value={observ}
          onChange={(e, v) => setObserv(v)}
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Valor do Crédito"
          value={vlrCred}
          onChange={setVlrCred}
        />
      </Row>
    </ModalBase>
  );
};
