import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { formatDateISO } from "../../../../coreUtils";
import GerenciamentoComissoesService from "../../../../services/frente_venda_mobile/GerenciamentoComissaoService";
import { GerenciamentoComissoesGrid } from "./components/GerenciamentoComissoesGrid";
import { docPrintReport } from "../../../../pdf";

export const GerenciamentoComissoes = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [rotas, setRotas] = useState([]);
  const [imprimirDetalhamento, setImprimirDetalhamento] = useState(false);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingRelatorio, setLoadingRelatorio] = useState(false);

  const limparDados = () => {
    setDados([]);
  };

  const handleSelectDate = async (_dataIni, _dataFim) => {
    setDataIni(_dataIni);
    setDataFim(_dataFim);
    limparDados();
  };

  const listarDados = async () => {
    setLoadingPesquisar(true);
    let _rotas = rotas.map((rota) => rota.value);

    const [ok, ret] = await GerenciamentoComissoesService.listar(
      _rotas,
      dataIni,
      dataFim
    );
    setDados(ok ? ret : []);
    setLoadingPesquisar(false);
  };

  const gerarRelatorio = async () => {
    setLoadingRelatorio(true);
    if (imprimirDetalhamento) {
      for (var i = 0; i < rotas.length; i++) {
        const [
          ok,
          res,
        ] = await GerenciamentoComissoesService.buscarDetalhamentoComiss(
          rotas[i].value,
          dataIni,
          dataFim
        );

        if (ok) {
          await docPrintReport(
            "/relatorios/pronta_entrega/detalhamento_comissao/",
            {
              itens: res,
              nome_rota: rotas[i].label,
              data_ini: formatDateISO(dataIni),
              data_fim: formatDateISO(dataFim),
            },
            "5020_2"
          );
        }
      }
    } else {
      let _rotas = rotas.map((rota) => rota.value);

      const [ok, ret] = await GerenciamentoComissoesService.listar(
        _rotas,
        dataIni,
        dataFim
      );

      if (ok) {
        await docPrintReport(
          "/relatorios/pronta_entrega/detalhamento_comissao/",
          {
            itens: ret,
            geral: true,
            data_ini: formatDateISO(dataIni),
            data_fim: formatDateISO(dataFim),
          },
          "5020_2"
        );
      }
    }
    setLoadingRelatorio(false);
  };

  return (
    <PageContainer title="Comissão por Período" number="5020" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleSelectDate} />
        </Row>
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            isSearchable
            concatModelName="rota_mob"
            defaultOptions
            md={6}
            label="Rota(s)"
            isMulti
            onChange={(selected) => {
              setRotas(selected ?? []);
              limparDados();
            }}
          />
          <BotaoPesquisar
            onClick={listarDados}
            loading={loadingPesquisar}
            disabledHint="Selecione ao menos uma rota"
          />
          <FormCheckbox
            label="Imprimir detalhamento de produtos por rota"
            name="imprimir_detalhamento"
            onChange={() => setImprimirDetalhamento(!imprimirDetalhamento)}
            checked={imprimirDetalhamento}
            padded={true}
          />
          <BotaoImprimir
            onClick={gerarRelatorio}
            loading={loadingRelatorio}
            disabled={rotas?.length === 0 || rotas === null}
            disabledHint="Selecione ao menos uma rota"
          />
        </Row>
      </Card>
      <GerenciamentoComissoesGrid
        data={dados}
        dataIni={dataIni}
        dataFim={dataFim}
      />
    </PageContainer>
  );
};
