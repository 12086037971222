import { formatNumber } from "../../../../../coreUtils";
import React from "react";
import { Table } from "../../../../../components";

export const ParcelasGrid = ({ parcelas, alterarParcela }) => {
  const columnsParcelas = [
    {
      dataField: "parcela",
      text: "Parcela",
      align: "center",
      editable: true,
      editorType: "number",
      onChange: alterarParcela,
      decimalPlaces: 0,
      colWidth: "33%",
    },
    {
      dataField: "prev_receb",
      text: "Prev. Receb.",
      align: "center",
      editable: true,
      editorType: "date",
      onChange: alterarParcela,
      colWidth: "33%",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      colWidth: "34%",
      formatter: (c) => formatNumber(c, true, 2),
      editable: true,
      editorType: "number",
      onChange: alterarParcela,
    },
  ];

  return (
    <Table
      columns={columnsParcelas}
      data={parcelas}
      showRegisterCount={false}
      paginated={false}
      pageSize={5}
    />
  );
};
