import moment from "moment";
import {
  formatDateDDMMAA,
  formatNumberField,
  formatRow,
  removeSpecialChars,
  verificaPessoa,
} from "../GerarRemessa";

// ============================ REGISTROS E CAMPOS ============================

export const rem400headerBradesco = (dadosConta) => [
  ["0", 1, 1],
  ["1", 2, 2],
  ["REMESSA", 3, 9],
  ["01", 10, 11],
  ["COBRANCA", 12, 26],
  [formatNumberField(dadosConta.cod_cedente), 27, 46], // Cod. Empresa - Será fornecido pelo Bradesco, quando do cadastramento
  [removeSpecialChars(dadosConta.nome_empresa), 47, 76], // Nome Empresa
  ["237", 77, 79], // Nro. Bradesco
  ["BRADESCO", 80, 94],
  [formatDateDDMMAA(new Date()), 95, 100], // Data da Gravação
  ["", 101, 108], // Branco
  ["MX", 109, 110], // Identificação do Sistema
  [dadosConta.nro_remessa, 111, 117], // Número sequencial de remessa
  ["", 118, 394], // Branco
  [1, 395, 400], // Sequencial Registro
];

export const rem400T1Bradesco = (
  dadosConta,
  dup,
  seuNumero,
  enderCliente,
  temInfoAvalista,
  temDesconto,
  seq
) => [
  ["1", 1, 1], // Identificação - 1
  [0, 2, 6], // Agência do pagador para débito em conta
  ["", 7, 7], // Dígito verificador da agência do pagador
  [0, 8, 12], // Razão conta do pagador
  [0, 13, 19], // Conta corrente do pagador
  ["", 20, 20], // Dígito da corrente do pagador
  [0, 21, 21],
  [parseInt(dadosConta.tipo_carteira), 22, 24],
  [formatNumberField(dadosConta.agencia), 25, 29],
  [dadosConta.nro_conta.padStart(8, "0"), 30, 37],
  [seuNumero, 38, 62], // No Controle do Participante - Uso da Empresa
  [0, 63, 65], // No do Banco
  [parseFloat(dadosConta.perc_multa) === 0 ? 0 : 2, 66, 66], // Campo de Multa - Se = 2 considerar percentual de multa. Se = 0, sem multa.
  [formatNumberField(dadosConta.perc_multa), 67, 70], // Percentual de Multa
  [dup.nosso_numero, 71, 82], // Identificação do Título no Banco - Número Bancário para Cobrança Com Registro
  [0, 83, 92], // Desconto Bonificação por dia - Valor do desconto bonif./dia.
  [2, 93, 93], // Condição para Emissão da Papeleta de Cobrança - 1 = Banco emite e processa o registro. 2 = Cliente emite e o Banco somente processa o registro
  ["N", 94, 94], // Ident. se emite Boleto para Débito Automático - N = Não registra na cobrança. Diferente de N registra e emite Boleto.
  ["", 95, 104], // Identificação da Operação do Banco - Brancos
  ["", 105, 105], // Indicador Rateio Crédito (opcional) - 'R'
  [0, 106, 106], // Endereçamento para Aviso do Débito Automático em Conta - Corrente (opcional)
  ["", 107, 108], // Quantidade de Pagamentos
  [1, 109, 110], // Identificação da Ocorrência
  [seuNumero, 111, 120], // No do Documento
  [formatDateDDMMAA(dup.vencimento), 121, 126], // Data do Vencimento do Título - DDMMAA
  [formatNumberField(dup.vlr_titulo), 127, 139], // Valor do Título
  [0, 140, 142], // Banco Encarregado da Cobrança - Preencher com zeros
  [0, 143, 147], // Agência Depositária - Preencher com zeros
  [dadosConta.especie_tit, 148, 149], // Espécie de Título - 01-Duplicata 02-Nota Promissória 03-Nota de Seguro 05-Recibo 10-Letras de Câmbio 11-Nota de Débito 12-Duplicata de Serv. 31-Cartão de Crédito 32-Boleto de Proposta 33- Depósito e Aporte 99-Outros
  ["N", 150, 150], // Identificação - Sempre "N"
  [formatDateDDMMAA(dup.emissao), 151, 156], // Data da Emissão do Título - DDMMAA
  [dadosConta.protestar ? "06" : "07", 157, 158], // 1a Instrução
  [dadosConta.dias_protesto, 159, 160], // 2a Instrução
  [formatNumberField(dadosConta.vlr_multa), 161, 173], // Valor a ser Cobrado por Dia de Atraso
  [temDesconto ? formatDateDDMMAA(dup.data_desconto) : 0, 174, 179], // Data Limite P/Concessão de Desconto
  [formatNumberField(dup.vlr_desconto), 180, 192], // Valor do Desconto
  [0, 193, 205], // Valor do IOF
  [0, 206, 218], // Valor do Abatimento a ser Concedido ou Cancelado
  [formatNumberField(verificaPessoa(dup.cpf_cnpj_cliente)), 219, 220], // Identificação do Tipo de Inscrição do Pagador - 01-CPF 02-CNPJ
  [formatNumberField(dup.cpf_cnpj_cliente), 221, 234], // No Inscrição do Pagador - CPF/CNPJ
  [dup.nome_cliente, 235, 274], // Nome Pagador
  [enderCliente, 275, 314], // Endereço Pagador
  [dup.mensagem_1, 315, 326], // 1a Mensagem
  [formatNumberField(dup.cep_cliente.replace("-", "")), 327, 334], // CEP Pagador
  [
    temInfoAvalista
      ? dadosConta.cnpj_avalista.replace(/\D/g, "").padStart(15, "0") +
        "  " +
        dadosConta.nome_avalista
      : dup.mensagem_2,
    335,
    394,
  ], // Beneficiário Final ou 2a Mensagem - Decomposição
  [seq, 395, 400], // Seq
];

export const rem400T2Bradesco = (dadosConta, dup, seq) => [
  ["2", 1, 1], // Identificação - 2
  [dup.mensagem_1, 2, 81], // 1a Mensagem
  [dup.mensagem_2, 82, 161], // 2a Mensagem
  [dup.mensagem_3, 162, 241], // 3a Mensagem
  [dup.mensagem_4, 242, 321], // 4a Mensagem
  [0, 322, 327], // Data-Limite para Concessão de Desconto 2 - DDMMAA
  [0, 328, 340], // Valor do Desconto 2
  [0, 341, 346], // Data-Limite para Concessão de Desconto 3 - DDMMAA
  [0, 347, 359], // Valor do Desconto 3
  ["", 360, 366], // Reserva - Filler
  [parseInt(dadosConta.tipo_carteira), 367, 369], // No da Carteira
  [formatNumberField(dadosConta.agencia), 370, 374], // Agência Beneficiário
  [dadosConta.nro_conta.padStart(8, "0"), 375, 382], // Conta Corrente
  [dup.nosso_numero, 383, 394], // Nosso Número
  [seq, 395, 400], // Seq
];

export const rem400T7Bradesco = (dadosConta, dup, seq) => [
  ["7", 1, 1], // Identificação - 7
  [dadosConta.endereco_avalista, 2, 46], // Endereço Beneficiário Final
  [dadosConta.cep_avalista.replace("-", ""), 47, 54], // CEP
  [dadosConta.cidade_avalista, 55, 74], // Cidade
  [dadosConta.uf_avalista, 75, 76], // UF
  ["", 77, 366], // Reserva - Filler
  [parseInt(dadosConta.tipo_carteira), 367, 369], // No da Carteira
  [formatNumberField(dadosConta.agencia), 370, 374], // Agência Beneficiário
  [dadosConta.nro_conta.padStart(8, "0"), 375, 382], // Conta Corrente
  [dup.nosso_numero, 383, 394], // Nosso Número
  [seq, 395, 400], // Seq
];

export const rem400trailerBradesco = (seq) => [
  ["9", 1, 1], // Identificador
  ["", 2, 394], // Filler
  [seq, 395, 400], // Seq
];

// ============================== GERAÇÃO DO ARQUIVO ==============================

export const layoutBradesco = (dadosConta, duplicatas) => {
  const temInfoAvalista = ![null, undefined, ""].includes(
    dadosConta.cnpj_avalista?.trim()
  );

  const multiIndex = temInfoAvalista ? 3 : 2;

  // Header
  const header = formatRow(...rem400headerBradesco(dadosConta));

  const detalhes = "".concat(
    ...duplicatas.map((dup, index) => {
      const seuNumero = `${dup.numero}-${dup.parcela}`.padStart(10, "0");

      const enderCliente =
        dup.endereco_cliente + ", " + dup.nro_endereco_cliente;

      return (
        // Registro Tipo 1
        formatRow(
          ...rem400T1Bradesco(
            dadosConta,
            dup,
            seuNumero,
            enderCliente,
            temInfoAvalista,
            parseFloat(dup.vlr_desconto) > 0,
            index * multiIndex + 2
          )
        ) +
        // Registro Tipo 2
        formatRow(
          ...rem400T2Bradesco(dadosConta, dup, index * multiIndex + 3)
        ) +
        // Registro Tipo 7
        (temInfoAvalista
          ? formatRow(
              ...rem400T7Bradesco(dadosConta, dup, index * multiIndex + 4)
            )
          : "")
      );
    })
  );

  // Trailer
  const trailer = formatRow(
    ...rem400trailerBradesco(duplicatas.length * multiIndex + 2)
  );

  return header + detalhes + trailer;
};

export const nomeArqBradesco = (dadosConta) =>
  "CB".concat(
    moment(new Date()).format("DDMM"),
    dadosConta.nro_remessa.toString().padStart(2, "0"),
    ".REM"
  );
