import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permite_manipular_vlr_unitario: false,
  id_layout_impressao: 1,
};

export const pedidoExternoSlice = createSlice({
  name: "pedido_externo_slice",
  initialState: initialState,
  reducers: {
    setupPedidoExterno: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setPermiteManipularVlrUnitario: (state, action) => {
      state.permite_manipular_vlr_unitario = action.payload;
    },
    setIdLayoutImpressao: (state, action) => {
      state.id_layout_impressao = action.payload;
    },
  },
});

export const {
  setupPedidoExterno,
  setPermiteManipularVlrUnitario,
  setIdLayoutImpressao,
} = pedidoExternoSlice.actions;

export default pedidoExternoSlice.reducer;
