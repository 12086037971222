import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import {
  avisoVincColaboradorRoute,
  cadastroColaboradorRoute,
  cadastroPerfilRoute,
  controleAcessoRoute,
} from "../../../routes/modules/cadastro";
import { PiUserList } from "react-icons/pi";
import { TbUserCog } from "react-icons/tb";
import { BsPersonExclamation, BsPersonBadgeFill } from "react-icons/bs";

export const AcessosSistema = () => {
  return (
    <PageContainer title="Cadastros - Acessos do Sistema">
      <MenuGroup>
        <MenuGroupButton
          label="Colaborador"
          icon={BsPersonBadgeFill}
          pathname={cadastroColaboradorRoute.path}
          nroTela={cadastroColaboradorRoute.nroTela}
        />
        <MenuGroupButton
          label="Controle de Acessos"
          icon={TbUserCog}
          pathname={controleAcessoRoute.path}
          nroTela={controleAcessoRoute.nroTela}
        />
        <MenuGroupButton
          label="Perfil"
          icon={PiUserList}
          pathname={cadastroPerfilRoute.path}
          nroTela={cadastroPerfilRoute.nroTela}
        />
        <MenuGroupButton
          label="Vinc. Aviso Colaborador"
          icon={BsPersonExclamation}
          pathname={avisoVincColaboradorRoute.path}
          nroTela={avisoVincColaboradorRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
