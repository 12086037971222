import React from "react";
import { Card } from "reactstrap";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { naturalSort } from "../../../../../coreUtils";

export const ListaPrecosGrid = ({ data, onSelect, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "desc_tipo_lista",
      text: "Tipo de Lista",
      align: "left",
      sortable: true,
    },
    {
      dataField: "desc_tipo_itens",
      text: "Tipo de Itens",
      align: "left",
      sortable: true,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Card body>
      <Table
        data={data}
        columns={columns}
        onSelect={(id, is, row) => onSelect(row)}
        onRowDoubleClick={alterar}
      />
    </Card>
  );
};
