import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroColaboradorModal } from "./components/CadastroColaboradorModal";
import { ColaboradorGrid } from "./components/ColaboradorGrid";
import {
  BotaoConfirmarVoltar,
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../../components";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../../coreUtils";
import CadastroColaboradorService from "../../../../services/cadastro/CadastroColaboradorService";
import { BotaoIncluir } from "../../../../components/cadastro";

export const modalTitleColaborador = "Colaborador";
export const routesBaseColaborador = "/cadastro/colaborador";

export const Colaborador = () => {
  const [pista, setPista] = useState("");
  const [mostrarInativos, setMostrarInativos] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [loadingPista, setLoadingPista] = useState(false);
  const [cadastroOpen, setCadastroOpen] = useState(false);

  const buscarDados = async (p) => {
    const params = {
      pista: p,
      mostrar_inativos: mostrarInativos,
    };
    const [ok, ret] = await CadastroColaboradorService.listar(params);
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    try {
      await buscarDados(pista);
    } finally {
      setLoading(false);
    }
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    try {
      await buscarDados(v);
    } finally {
      setLoadingPista(false);
    }
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Colaboradores" number="0058">
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <FormCheckbox
            label="Mostrar Inativos"
            checked={mostrarInativos}
            onChange={() => setMostrarInativos(!mostrarInativos)}
            padded
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroColaboradorModal
            selected={selected}
            notifyEvent={notifyEvent}
            action={action}
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
          />
          <BotaoConfirmarVoltar
            selected={selected}
            carregarDados={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <ColaboradorGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
        />
      </Card>
    </PageContainer>
  );
};
