import React, { useState } from "react";
import {
  Divider,
  FixedField,
  ModalBase,
  Table,
} from "../../../../../components";
import { GerenciamentoCobrancaBancariaService } from "../../../../../services/financeiro/GerenciamentoCobrancaBancariaService";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { Row } from "reactstrap";

const columns = [
  {
    dataField: "data_hora_ocorrencia",
    text: "Data/Hora",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "desc_ocorrencia",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "historico",
    text: "Histórico",
    align: "left",
    headerAlign: "left",
  },
];

export const DetalhesCobrancaBancModal = ({ isOpen, toggle, selected }) => {
  const [numero, setNumero] = useState(null);
  const [parcela, setParcela] = useState(null);
  const [vlrJuro, setVlrJuro] = useState(0);
  const [vlrMulta, setVlrMulta] = useState(0);
  const [vlrDesc, setVlrDesc] = useState(0);
  const [vlrOrig, setVlrOrig] = useState(0);
  const [vlrReceber, setVlrReceber] = useState(0);
  const [vlrRecebido, setVlrRecebido] = useState(0);
  const [observ, setObserv] = useState("");
  const [recebido, setRecebido] = useState(null);
  const [emissao, setEmissao] = useState(null);
  const [vencimento, setVencimento] = useState(null);
  const [carteira, setCarteira] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState(null);
  const [idOrigem, setIdOrigem] = useState(null);
  const [origem, setOrigem] = useState(null);
  const [ocorrencias, setOcorrencias] = useState([]);

  const limparDados = () => {};

  const carregarDados = async () => {
    const [ok, ret] = await GerenciamentoCobrancaBancariaService.buscar(
      selected
    );
    if (ok) {
      setNumero(ret.numero);
      setParcela(ret.parcela);
      setVlrJuro(parseFloat(ret.vlr_juro ?? 0));
      setVlrMulta(parseFloat(ret.vlr_multa ?? 0));
      setVlrDesc(parseFloat(ret.vlr_desc ?? 0));
      setVlrOrig(parseFloat(ret.vlr_orig ?? 0));
      setVlrReceber(parseFloat(ret.vlr_receber ?? 0));
      setVlrRecebido(parseFloat(ret.vlr_recebido ?? 0));
      setObserv(ret.observ);
      setRecebido(ret.recebido);
      setEmissao(ret.emissao);
      setVencimento(ret.vencimento);
      setCarteira(ret.carteira);
      setIdCliente(ret.id_cliente);
      setNomeCliente(ret.nome_cliente);
      setIdOrigem(ret.id_origem);
      setOrigem(ret.origem);
      setOcorrencias(ret.ocorrencias);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Detalhes da Cobrança Bancária"
      number="0087_2"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <Row className="mb-2">
        <FixedField
          horizontal
          label="Cliente"
          value={formatValueFromAPI(nomeCliente, idCliente)}
        />
      </Row>
      <Row className="mb-2">
        <FixedField horizontal label="# ID" value={selected} />
        <FixedField horizontal label="Número" value={numero} />
        <FixedField horizontal label="Parcela" value={parcela} />
        <FixedField
          horizontal
          label="Origem"
          value={
            ![0, null, undefined].includes(idOrigem)
              ? `${origem} ${idOrigem}`
              : origem
          }
        />
        <FixedField
          horizontal
          label="Carteira"
          value={carteira ? "Sim" : "Não"}
        />
      </Row>
      <Row className="mb-2">
        <FixedField
          horizontal
          label="Juros"
          value={formatNumber(vlrJuro, true, 2)}
        />
        <FixedField
          horizontal
          label="Multa"
          value={formatNumber(vlrMulta, true, 2)}
        />
        <FixedField
          horizontal
          label="Desconto"
          value={formatNumber(vlrDesc, true, 2)}
        />
      </Row>
      <Row className="mb-2">
        <FixedField
          horizontal
          label="Valor Original"
          value={formatNumber(vlrOrig, true, 2)}
        />
        <FixedField
          horizontal
          label="A Receber"
          value={formatNumber(vlrReceber, true, 2)}
        />
        <FixedField
          horizontal
          label="Recebido"
          value={formatNumber(vlrRecebido, true, 2)}
        />
      </Row>
      <Row className="mb-2">
        <FixedField horizontal label="Emissão" value={emissao} />
        <FixedField horizontal label="Vencimento" value={vencimento} />
        <FixedField
          horizontal
          label="Recebido"
          value={recebido ? "Sim" : "Não"}
        />
      </Row>
      <Row className="mb-2">
        <FixedField horizontal label="Obs." value={observ} />
      </Row>
      <Divider>Ocorrências</Divider>
      <Table
        data={ocorrencias}
        columns={columns}
        pageSize={5}
        paginated={false}
      />
    </ModalBase>
  );
};
