import React from "react";
import { useState } from "react";
import { TextInput } from "../../../../../components";
import { Row } from "reactstrap";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  modalTitleRamoAtividade,
  routesBaseRamoAtividade,
} from "../RamoAtividade";

export const CadastroRamoAtividadeModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [status, setStatus] = useState(true);
  const [descricao, setDescricao] = useState("");
  const [cnae, setCnae] = useState("");

  const limparDados = () => {
    setStatus(true);
    setDescricao("");
    setCnae("");
  };

  const fetchData = (data) => {
    setStatus(data.status);
    setDescricao(data.descricao ?? "");
    setCnae(data.cnae ?? "");
  };

  const submitPayload = (action) => {
    const payload = {
      status: status,
      descricao: descricao,
      cnae: cnae,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleRamoAtividade}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: status,
        toggle: () => setStatus(!status),
      }}
      routesBase={routesBaseRamoAtividade}
      number="0080_1"
      selected={selected}
      notifyEvent={notifyEvent}
      concatShortcut={concatShortcut}
      concatModelName="ramo_atividade"
    >
      <Row>
        <TextInput
          md={9}
          label="Descrição"
          value={descricao}
          onChange={(e, v) => setDescricao(v)}
          maxLength={60}
        />
        <TextInput
          md={3}
          label="CNAE"
          value={cnae}
          onChange={(e, v) => setCnae(v)}
          maxLength={6}
        />
      </Row>
    </ModalCadastroV2>
  );
};
