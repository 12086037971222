import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BsReceipt, BsTruck } from "react-icons/bs";
import {
  prontaEntregaCadastroDespesasRoute,
  prontaEntregaCadastroEstoqueInterRoute,
  prontaEntregaCadastroRotasRoute,
} from "../../../routes/modules/pronta_entrega";
import { RiSwapBoxLine } from "react-icons/ri";

export const ProntaEntregaCadastro = () => {
  return (
    <PageContainer title="Pronta Entrega - Cadastros">
      <MenuGroup>
        <MenuGroupButton
          label="Despesas de Jornada"
          icon={BsReceipt}
          pathname={prontaEntregaCadastroDespesasRoute.path}
          nroTela={prontaEntregaCadastroDespesasRoute.nroTela}
        />
        <MenuGroupButton
          label="Estoque Intermediário"
          icon={RiSwapBoxLine}
          pathname={prontaEntregaCadastroEstoqueInterRoute.path}
          nroTela={prontaEntregaCadastroEstoqueInterRoute.nroTela}
        />
        <MenuGroupButton
          label="Rotas e Roteirizações"
          icon={BsTruck}
          pathname={prontaEntregaCadastroRotasRoute.path}
          nroTela={prontaEntregaCadastroRotasRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
