import React, { useState } from "react";
import { UncontrolledTooltip, Label, Col } from "reactstrap";
import { corDescricaoClassifCli } from "../coreUtils";

export const ClassifCli = ({ value, table, style }) => {
  const [id] = useState(`class-cli-${Math.floor(Math.random() * Date.now())}`);

  const { cor, detalhe } = corDescricaoClassifCli(value);

  return table ? (
    <>
      <div
        style={{
          width: "40px",
          height: "20px",
          borderRadius: "4px",
          ...style,
          backgroundColor: cor,
        }}
        id={id}
      />
      <UncontrolledTooltip placement="bottom" target={id}>
        {detalhe}
      </UncontrolledTooltip>
    </>
  ) : (
    <Col md="auto">
      <Label>Classificação</Label>
      <div
        style={{
          width: "50px",
          height: "32px",
          borderRadius: "4px",
          ...style,
          backgroundColor: cor,
        }}
        id={id}
      />
      <UncontrolledTooltip placement="bottom" target={id}>
        {detalhe}
      </UncontrolledTooltip>
    </Col>
  );
};
