import { apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/venda/entre_lojas";

const TransferenciaVendaService = {
  listarVendasFaturar: async (params) =>
    await apiGetV2(`${URL_BASE}/listar_vendas_faturar/`, params),
  faturarVendaOutraEmpresa: async (paylaod) =>
    await apiPostV2(`${URL_BASE}/faturar_venda_outra_empresa/`, paylaod),
};

export default TransferenciaVendaService;
