import React from "react";
import { Table, TableCheck } from "../../../../../../components";
import { formatNumber, naturalSort } from "../../../../../../coreUtils";

export const ItensAddGrid = ({
  dados,
  handleSelectItemAdd,
  onSelectAllAdd,
  tipoDescAdd,
  recalcularValoresGrade,
  selected,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "prod_vai_pro_app",
      text: "APP",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    {
      dataField: "id_grupo__nome",
      text: "Grupo",
      align: "left",
      sortable: true,
    },
    {
      dataField: "id_fabricante__nome",
      text: "Fabricante",
      align: "left",
      sortable: true,
    },
    {
      dataField: "custo_ue",
      text: "Custo",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "preco_venda",
      text: "Preço Venda",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "perc_desc",
      text: "% Desc.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2, true) + "%",
      editable: tipoDescAdd === "PER",
      editorType: "number",
      alwaysShowEditor: true,
      onChange: recalcularValoresGrade,
      colWidth: "80px",
      selectOnClick: tipoDescAdd !== "PER",
    },
    {
      dataField: "preco_venda_novo",
      text: "Preço Com Desc.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      editable: tipoDescAdd === "VAL",
      editorType: "number",
      alwaysShowEditor: true,
      onChange: recalcularValoresGrade,
      colWidth: "80px",
      selectOnClick: tipoDescAdd !== "VAL",
    },
  ];
  return (
    <Table
      data={dados}
      columns={columns}
      onSelectAll={onSelectAllAdd}
      onSelect={handleSelectItemAdd}
      pageSize={10}
      multiselect
      selected={selected}
      paginated={false}
      showRegisterCount={false}
    />
  );
};
