import React from "react";
import { Card } from "reactstrap";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { DetalhesCliNaoAtendRotaModal } from "./DetalhesCliNaoAtendRotaModal";
import { Table } from "../../../../../components";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    sortable: true,
  },
  {
    dataField: "nome",
    text: "Rota",
    align: "left",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "id_colaborador__nome",
    text: "Vendedor",
    align: "left",
    sortable: true,
  },
  {
    dataField: "num_deveria_atender",
    text: "Clientes Deveria Atender",
    align: "center",
  },
  {
    dataField: "perc_atendeu",
    text: "% Atendido",
    align: "center",
    formatter: (cell) => `${formatNumber(cell, true, 2)} %`,
  },
  {
    dataField: "num_nao_atend",
    text: "Clientes Não Vendidos",
    align: "center",
  },
  {
    dataField: "num_atend_outro_dia",
    text: "Clientes Atendidos em Dia Diferente da Roteirização",
    align: "center",
  },
  {
    dataField: "det",
    isDummyField: true,
    align: "center",
    formatter: (c, row, index) => (
      <DetalhesCliNaoAtendRotaModal index={index} data={row} />
    ),
  },
];

export const ClientesNaoAtendRotaGrid = ({ dados }) => {
  return (
    <Card body>
      <Table columns={columns} data={dados} keyField="id" />
    </Card>
  );
};
