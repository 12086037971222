import React from "react";
import {
  FixedField,
  FormButton,
  PageContainer,
} from "../../../../../components";
import { Card, Row } from "reactstrap";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { ItensExecucaoGrid } from "./components/ItensExecucaoGrid";
import { mockDataItens } from "../components/mockData";

const headerRowClassName = "mb-1";

const ValidacaoProgramacaoExecucao = ({ location }) => {
  const selected = location?.state?.selected;
  const row = location?.state?.row;

  return (
    <PageContainer
      title="Validar Programação de Execução"
      number="0027_1"
      canGoBack
    >
      <Card body>
        <Row className={headerRowClassName}>
          <FixedField
            label="Número Projeto"
            value={selected}
            horizontal
            boldValue
          />
          <FixedField
            label="Cliente"
            md={5}
            value={formatValueFromAPI(row.nome_cliente, row.id_cliente)}
            horizontal
          />
          <FixedField label="Emissão" value={row.data_abertura} horizontal />
        </Row>
        <Row className={headerRowClassName}>
          <FixedField
            md={7}
            label="Endereço"
            value={"Travessa Décio Fonseca Penteado, 1278 - Guarulhos"}
            horizontal
          />
          <FixedField
            md={3}
            label="CPF/CNPJ"
            value={"329.993.322-11"}
            horizontal
          />
        </Row>
        <Row className={headerRowClassName}>
          <FixedField
            md={4}
            label="Telefone(s)"
            value={"(11) 7855-8249"}
            horizontal
          />
        </Row>
        <Row>
          <FixedField
            md={5}
            label="Observação"
            value={"Entrada pela rua lateral"}
            horizontal
            divClassName="mt-auto mr-auto"
          />
          <FormButton
            divClassName="ml-auto"
            md="auto"
            padded={false}
            onClick={() => {}}
            color="success"
          >
            Finalizar Validação
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <ItensExecucaoGrid itens={mockDataItens[selected]} />
      </Card>
    </PageContainer>
  );
};

export default ValidacaoProgramacaoExecucao;
