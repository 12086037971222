import React, { useState } from "react";
import HorasVendidasTrabalhadasService from "../../../../../services/ordem_servico/HorasVendidasTrabalhadasService";
import { Table } from "../../../../../components";

const columnsProdServ = [
  {
    dataField: "desc",
    text: "Descrição do Serviço/Produto",
    align: "left",
  },
  {
    dataField: "tipo",
    text: "Tipo",
    align: "center",
  },
  {
    dataField: "horas_vendidas",
    text: "Horas Vendidas",
    align: "center",
  },
];

const columnsTecnico = [
  {
    dataField: "nome",
    text: "Técnico",
    align: "center",
  },
  {
    dataField: "data",
    text: "Data",
    align: "center",
  },
  {
    dataField: "horas_trabalhadas",
    text: "Horas Trabalhadas dos Técnicos",
    align: "center",
  },
];

export const DetalhesTecnico = ({ row }) => {
  const [produtosServicos, setProdutosServicos] = useState([]);
  const [tecnicos, setTecnicos] = useState([]);

  const buscarDados = async () => {
    const [ok, res] = await HorasVendidasTrabalhadasService.buscarDetalhes(
      row.id
    );

    if (ok) {
      setProdutosServicos(res.produtos_servicos);
      setTecnicos(res.tecnicos);
    }
  };

  useState(() => {
    buscarDados();
  }, []);

  return (
    <>
      <Table
        columns={columnsProdServ}
        data={produtosServicos}
        keyField="desc"
        showRegisterCount={false}
        paginated={false}
        pageSize={produtosServicos.length}
        fixedSize={false}
      />
      <div className="mb-2" />
      <Table
        columns={columnsTecnico}
        data={tecnicos}
        keyField="nome"
        showRegisterCount={false}
        paginated={false}
        pageSize={tecnicos.length}
        fixedSize={false}
      />
    </>
  );
};
