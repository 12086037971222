import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FixedTable,
  FormCheckbox,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { DatePicker } from "../../../../../components/DatePicker";
import { ModalBase } from "../../../../../components/ModalBase";
import { ModalDetalhesIcone } from "../../../../../components/ModalDetalhesIcone";
import { TableCheck } from "../../../../../components/TableCheck";
import { formatNumber } from "../../../../../coreUtils";
import EmprestimoColaboradorService from "../../../../../services/frente_venda_mobile/EmprestimoColaboradorService";

const columns = [
  {
    dataField: "vlr_orig",
    text: "Valor Original",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_pago",
    text: "Valor Pago",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "data_pagamento",
    text: "Data Pagamento",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "aberto",
    text: "Aberto",
    align: "center",
    headerAlign: "center",
    formatter: (c) => <TableCheck value={c} />,
  },
];

export const DetalheEmprestimoModal = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idColaborador, setIdColaborador] = useState(null);
  const [dataEmpr, setDataEmpr] = useState(new Date());
  const [aberto, setAberto] = useState(true);
  const [vlrTotal, setVlrTotal] = useState(0);
  const [observ, setObserv] = useState("");
  const [parcelas, setParcelas] = useState([]);

  const limparDados = () => {
    setIdColaborador(null);
    setDataEmpr(new Date());
    setAberto(true);
    setVlrTotal(0);
    setObserv("");
    setParcelas([]);
  };

  const carregarDados = async () => {
    const ret = await EmprestimoColaboradorService.buscar(id);
    if (ret?.id_colaborador) {
      setIdColaborador(ret.id_colaborador);
      setVlrTotal(ret.vlr_total);
      setAberto(ret.aberto);
      setDataEmpr(ret.data_empr);
      setParcelas(ret.parcelas);
      setObserv(ret.observ);
    }
  };

  const toggle = () => {
    if (!isOpen) {
      carregarDados();
    } else {
      limparDados();
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalDetalhesIcone id={id} toggle={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Detalhes do Empréstimo"
        autoFocus
        hideCancelButton
      >
        <Row>
          <AsyncComboBox
            label="Colaborador"
            md={7}
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            defaultValue={idColaborador}
            disabled
          />
        </Row>
        <Row className="mb-3">
          <DatePicker md={4} label="Data" value={dataEmpr} disabled />
          <NumberInput md={4} label="Valor Total" value={vlrTotal} disabled />
          <FormCheckbox
            name="aberto"
            label="Aberto"
            checked={aberto}
            disabled
          />
        </Row>
        <Row>
          <TextInput
            type="textarea"
            label="Observação"
            value={observ}
            rows={3}
            disabled
          />
        </Row>
        <FixedTable
          data={parcelas}
          columns={columns}
          style={{ height: "300px" }}
        />
      </ModalBase>
    </>
  );
};
