import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  CardTotais,
  CardTotaisItem,
  FormButton,
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormCheckbox,
  PageContainer,
  PesqMultiPlanoCtaCentroCusto,
} from "../../../../components";
import { exportDataToSheet, formatDateLocal } from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";
import { LinhaPlanoContas } from "./components/LinhaPlanoContas";

export const DemonstrativoDespesasPorPlanoConta = () => {
  const [idPlanoConta, setIdPlanoConta] = useState([]);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [mostrarSemValorLanc, setMostrarSemValorLanc] = useState(false);
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [dadosRelatorio, setDadosRelatorio] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);

  const handleDate = async (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      id_plano_conta: idPlanoConta,
      id_fornecedor: idFornecedor,
      mostrar_sem_valor_lanc: mostrarSemValorLanc,
      ini_periodo: dataIni,
      fim_periodo: dataFim,
    };

    return await apiGetV2(
      "/controladoria/demonstrativo_despesas_por_plano_conta/",
      params
    );
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      const payload = {
        data_ini: formatDateLocal(dataIni),
        data_fim: formatDateLocal(dataFim),
        movimentos: ret.movimentos,
        totais: ret.totais,
      };
      await docPrintReport(
        "/relatorios/financeiro_pagar/despesas_por_plano_conta/",
        payload,
        "5017"
      );
    }
    setLoadingImprimir(false);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      setDadosRelatorio(ret.movimentos);
      setTotais(ret.totais);
    } else {
      setDadosRelatorio([]);
      setTotais({});
    }
    setLoading(false);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      const unwrapLeafsMovimento = (movs, niveisAcima) =>
        movs.map((mv) => ({
          ...[
            ...niveisAcima,
            ...Array(Math.max(5 - niveisAcima.length, 0)).fill(""),
          ]
            .map((e, index) => ({ [`nivel${index + 1}`]: e }))
            .reduce((acc, n) => ({ ...acc, ...n }), {}),
          descricao: mv.descricao,
          fornecedor: mv.fornecedor,
          numero: mv.numero,
          parcela: mv.parcela,
          vencimento: mv.vencimento,
          liquidacao: mv.liquidacao,
          valor: parseFloat(mv.valor),
        }));

      const unwrapMovimento = (mov, niveisAcima) => {
        const movimentos = mov.movimentos;
        const nivelAbaixo = mov.nivel_abaixo;
        niveisAcima = [...niveisAcima, mov.descricao];
        if (nivelAbaixo.length > 0) {
          return nivelAbaixo.reduce(
            (acc, movAbaixo) => [
              ...acc,
              ...unwrapMovimento(movAbaixo, niveisAcima),
            ],
            [...unwrapLeafsMovimento(movimentos, niveisAcima)]
          );
        } else {
          return unwrapLeafsMovimento(movimentos, niveisAcima);
        }
      };

      const data = ret.movimentos.reduce(
        (accLista, nivel1) => [...accLista, ...unwrapMovimento(nivel1, [])],
        []
      );

      exportDataToSheet(data, "Despesas Liquidadas por Plano de Contas", {
        dataIni: dataIni,
        dataFim: dataFim,
      });
    }
    setLoadingExportar(false);
  };

  return (
    <PageContainer
      title="Despesas Liquidadas por Plano de Contas"
      number="5017"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            md={4}
            label="Fornecedor"
            isConcatField
            concatModelName="fornecedor"
            isSearchable
            isClearable
            onChange={(s) => setIdFornecedor(s?.value)}
          />
        </Row>
        <Row>
          <PesqMultiPlanoCtaCentroCusto
            label="Plano de Contas"
            mode="plano_contas"
            value={idPlanoConta}
            onChange={(s) => setIdPlanoConta(s)}
          />
          <FormCheckbox
            label="Mostrar planos de contas sem valores lançados"
            name="mostrar_sem_valor_lanc"
            onChange={() => setMostrarSemValorLanc(!mostrarSemValorLanc)}
            checked={mostrarSemValorLanc}
          />
          <BotaoPesquisar
            loading={loading}
            onClick={carregarDados}
            disabled={!(dataIni instanceof Date) || !(dataFim instanceof Date)}
            disabledHint="Informe datas válidas de início e fim"
          />
          <BotaoImprimir
            loading={loadingImprimir}
            onClick={imprimir}
            disabled={!(dataIni instanceof Date) || !(dataFim instanceof Date)}
            disabledHint="Informe datas válidas de início e fim"
          />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportar}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
      {dadosRelatorio.length > 0 && (
        <>
          <CardTotais md={12}>
            <CardTotaisItem
              label="Duplicata a Pagar"
              value={totais?.["Duplicata a Pagar"]}
            />
            <CardTotaisItem
              label="Duplicata a Receber"
              value={totais?.["Duplicata a Receber"]}
            />
            <CardTotaisItem
              label="Troca de Produtos"
              value={totais?.["Troca de Produtos"]}
            />
            <CardTotaisItem
              label="Bonificação de Produtos"
              value={totais?.["Bonificação de Produtos"]}
            />
          </CardTotais>
          <Card body>
            <ul className="dre-list">
              {dadosRelatorio.map((item, index) => (
                <LinhaPlanoContas key={index} row={item} />
              ))}
            </ul>
          </Card>
        </>
      )}
    </PageContainer>
  );
};
