import { configureStore, createSlice } from "@reduxjs/toolkit";
import React from "react";

const initialState = {
  libera_gera_estoque_inc_manual_nfe: false,
};

const controlePersonSlice = createSlice({
  name: "controle_person_slice",
  initialState: initialState,
  reducers: {
    resetControle: () => initialState,
    setLiberaGeraEstoqueIncManualNfe: (state, action) => {
      state.libera_gera_estoque_inc_manual_nfe = action.payload;
    },
  },
});

export const { resetControle, setLiberaGeraEstoqueIncManualNfe } =
  controlePersonSlice.actions;

export default configureStore({
  reducer: controlePersonSlice.reducer,
});

export const ControlePersonContext = React.createContext(null);
