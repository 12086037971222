import React from "react";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { tiposCargaMdfe } from "../../../docs_eletron/transporte/manifesto_mdfe/incluir_alterar/IncluirAlterarMDFe";
import {
  AsyncComboBox,
  ComboBox,
  IntegerFormInput,
  MaskedInput,
  TextInput,
} from "../../../../components";
import {
  setCaminSalvarXml,
  setTipoContrib,
  setCiot,
  setCpfCnpjRespCiot,
  setRntrc,
  setTipoCargaProdPredomina,
  setDescrProdPredomina,
  setEanProdPredomina,
  setNcmProdPredomina,
  setUnidMedCarga,
  setIdTomadorPadrao,
} from "../store/mdfe_slice";
import { RadioGroup, RadioItem } from "../../../../components/RadioGroup";
import { formatarCpfCnpj } from "../../../../coreUtils";

export const PersonMdfe = () => {
  const store = useSelector((state) => state.mdfe);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <IntegerFormInput
          md={2}
          mdInput={7}
          label="Últ Nº MDFe"
          defaultValue={store.ult_numero}
          disabled
        />
        <IntegerFormInput
          md={2}
          mdInput={7}
          label="Últ. Lote"
          defaultValue={store.ult_lote}
          disabled
        />
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Salvar XML Em"
          value={store.camin_salvar_xml}
          onChange={(e, v) => dispatch(setCaminSalvarXml(v))}
          maxLength={255}
          forceUppercase={false}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Tipo de Contribuinte"
          value={store.tipo_contrib}
          onChange={(v) => dispatch(setTipoContrib(v))}
        >
          <RadioItem label="Transportadora" value="T" />
          <RadioItem label="Transportador de Carga Própria" value="P" />
        </RadioGroup>
      </Row>
      <Row>
        <MaskedInput
          mask="999999999999"
          maskChar={null}
          md={2}
          label="CIOT"
          value={store.ciot}
          onChange={(e, v) => dispatch(setCiot(v))}
        />
      </Row>
      <Row>
        <TextInput
          md={3}
          mdInput={8}
          label="CPF/CNPJ do Responsável (CIOT)"
          value={store.cpf_cnpj_resp_ciot}
          onChange={(e, v) => dispatch(setCpfCnpjRespCiot(v))}
          onBlur={(e, v) => dispatch(setCpfCnpjRespCiot(formatarCpfCnpj(v)))}
          maxLength={18}
        />
      </Row>
      <Row>
        <TextInput
          md={2}
          label="RNTRC"
          value={store.rntrc}
          onChange={(e, v) => dispatch(setRntrc(v))}
          maxLength={30}
        />
      </Row>
      <Row>
        <ComboBox
          md={3}
          label="Tipo da Carga Predominante"
          options={tiposCargaMdfe}
          onChange={(s) => dispatch(setTipoCargaProdPredomina(s?.value ?? 0))}
          defaultValue={store.tipo_carga_prod_predomina}
        />
      </Row>
      <Row>
        <TextInput
          md={5}
          label="Descrição Produto Predominante"
          value={store.descr_prod_predomina}
          onChange={(e, v) => dispatch(setDescrProdPredomina(v))}
          maxLength={120}
        />
      </Row>
      <Row>
        <TextInput
          md={3}
          mdInput={8}
          label="GTIN/EAN Produto Predominante"
          value={store.ean_prod_predomina}
          onChange={(e, v) => dispatch(setEanProdPredomina(v))}
          maxLength={14}
        />
      </Row>
      <Row>
        <MaskedInput
          md={3}
          mdInput={6}
          label="NCM Produto Predominante"
          mask="99999999"
          maskChar={null}
          value={store.ncm_prod_predomina}
          onChange={(e, v) => dispatch(setNcmProdPredomina(v))}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Unidade de Medida da Carga"
          value={store.unid_med_carga}
          onChange={(v) => dispatch(setUnidMedCarga(v))}
        >
          <RadioItem label="Kg" value="KG" />
          <RadioItem label="Ton." value="TON" />
        </RadioGroup>
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Tomador Padrão"
          concatModelName="tomador_mdfe"
          defaultValue={store.id_tomador_padrao}
          onChange={(s) => dispatch(setIdTomadorPadrao(s?.value ?? null))}
        />
      </Row>
    </>
  );
};
