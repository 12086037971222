import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { FormCheckbox, TextInput } from "../../../../../../components";
import {
  setCBenef,
  setCfop,
  setCobrarNaFatura,
  setMensagAdicNfe,
} from "../../store/cadRegraTributacaoSlice";

export const BlocoDadosGerais = () => {
  const store = useSelector((state) => state.cadRegraTributacao);
  const dispatch = useDispatch();

  return (
    <>
      <Row className="mb-3">
        <TextInput
          label="CFOP"
          md={2}
          onChange={(e, v) => dispatch(setCfop(v))}
          value={store.cfop}
          maxLength={5}
        />
        <TextInput
          label="Cód. Benef. Fiscal"
          md={2}
          onChange={(e, v) => dispatch(setCBenef(v || ""))}
          value={store.cBenef}
          maxLength={15}
        />
        <FormCheckbox
          label="Cobrar, nas faturas da NF, valores dos produtos identificados nesta regra"
          md="auto"
          name="cobrar_valor_faturas_nf"
          id="cobrar_valor_faturas_nf"
          onChange={() => dispatch(setCobrarNaFatura(!store.cobrarNaFatura))}
          checked={store.cobrarNaFatura}
        />
      </Row>
      <Row className="mb-3">
        <TextInput
          label="Informações Complementares de Interesse do Contribuinte"
          type="textarea"
          md={12}
          onChange={(e, v) => dispatch(setMensagAdicNfe(v))}
          value={store.mensagAdicNfe}
        />
      </Row>
    </>
  );
};
