import React, { useCallback, useState } from "react";
import { Row } from "reactstrap";
import { FormButton } from "../../../../components";
import { ModalBase } from "../../../../components/ModalBase";
import LiberacaoClientesService from "../../../../services/pedidos/LiberacaoClientesService";

const LiberarClienteContainer = ({ clienteID, nomeCliente, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSubmit = async () => {
    setLoading(true);
    if (await LiberacaoClientesService.liberar(clienteID)) {
      toggle();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <FormButton
        color="success"
        md="auto"
        onClick={toggle}
        disabled={[null, undefined, 0].includes(clienteID)}
      >
        Liberar
      </FormButton>
      {isOpen && (
        <ModalBase
          isOpen={isOpen}
          toggle={toggle}
          size="md"
          // onOpened
          // onClosed
          title="Liberação de Cliente"
          number="0035_1"
          onConfirm={handleSubmit}
          loadingConfirm={loading}
          confirmButtonText="Liberar"
          autoFocus
        >
          <Row className="justify-content-center">
            <p style={{ textAlign: "center" }}>
              Tem certeza de que deseja liberar o cliente {nomeCliente}?
            </p>
          </Row>
        </ModalBase>
      )}
    </React.Fragment>
  );
};
export default LiberarClienteContainer;
