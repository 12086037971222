import React from "react";
import { CardFiltros } from "./components/CardFiltros";
import { CardTotais } from "./components/CardTotais";
import { GerencVendasDiretasGrid } from "./components/GerencVendasDiretasGrid";
import { PageContainer } from "../../../../components";

export const GerencVendasProntaEntrega = () => {
  return (
    <PageContainer
      title="Gerenciamento de Vendas Pronta Entrega"
      number="0024"
      canGoBack
    >
      <CardFiltros />
      <CardTotais />
      <GerencVendasDiretasGrid />
    </PageContainer>
  );
};
