import moment from "moment";
import {
  formatDateDDMMAA,
  formatNumberField,
  formatRow,
  removeSpecialChars,
  verificaPessoa,
} from "../GerarRemessa";

// ============================ REGISTROS E CAMPOS ============================

const rem400headerCaixa = (dadosConta) => [
  ["0", 1, 1],
  ["1", 2, 2],
  ["", 3, 9], // Situação da remessa - TESTE - Testes, Branco - Produção
  ["01", 10, 11],
  ["COBRANCA", 12, 26],
  [dadosConta.agencia, 27, 30], // Código da Agência
  [dadosConta.cod_cedente, 31, 36], // Código do Beneficiário
  ["", 37, 46], // Uso Exclusivo CAIXA - Brancos
  [removeSpecialChars(dadosConta.nome_empresa), 47, 76], // Nome da Empresa
  ["104", 77, 79],
  ["CAIXA", 80, 94],
  [formatDateDDMMAA(new Date()), 95, 100],
  ["007", 101, 103], // Versão: Branco - Cod. Benef. até 6 dígitos - 007 - Cod. Benef. até 7 dígitos
  ["", 104, 389], // Uso Exclusivo CAIXA - Brancos
  [dadosConta.nro_remessa, 390, 394], // Nro da Remessa
  [1, 395, 400],
];

const rem400T1Caixa = (
  dadosConta,
  dup,
  seuNumero,
  enderCliente,
  temDesconto,
  seq
) => [
  ["1", 1, 1], // Código do Registro
  [2, 2, 3], // Tipo Inscrição - 01 - Pessoa Física, 02 - Pessoa Jurídica
  [formatNumberField(dadosConta.cnpj_empresa), 4, 17], // Número Inscrição - CPF ou CNPJ
  [dadosConta.agencia, 18, 21], // Código da Agência
  [dadosConta.cod_cedente, 22, 27], // Código Beneficiário
  [2, 28, 28], // Identificação da Emissão do Boleto - 1 - Banco Emite, 2 - Cliente Emite
  [0, 29, 29], // Identificação da Entrega/Distribuição do Boleto - 0 - Postagem pelo Beneficiário, 1 - Pagador via Correio, 2 - Beneficiário via Agência CAIXA, 3 - Pagador via e-mail
  [0, 30, 31], // Taxa Permanência - Zeros
  [seuNumero, 32, 56], // Identificação do Título na Empresa
  [dup.nosso_numero, 57, 73], // Nosso Número
  ["", 74, 75], // Brancos
  [1, 76, 76], // Uso livre banco/empresa ou autorização de pagamento parcial
  ["", 77, 77], // Código do tipo de juros: 1 - Valor (dias corridos), 3 - Percentual ao mês (dias corridos), 5 - Isento, 6 - Valor (dia útil), 8 - Percentual ao mês (dias úteis)
  [0, 78, 83], // Data de Juros - DDMMAAAA. Se inválido, considerará data de vencimento + 1 dia
  [temDesconto ? 1 : 0, 84, 84], // Código do Tipo de Desconto - 0 - Sem Desconto, 1 - Valor Fixo até a data informada, 2 - Percentual até a data informada, 3 - Valor por antecipação dia corrido, 4 - Valor por antecipação dia útil, 5 - Percentual por antecipação dia corrido, 6 - Percentual por antecipação dia útil
  ["", 85, 106], // Brancos
  [1, 107, 108], // Código da Carteira - 01
  [1, 109, 110], // Código Ocorrência - 01 - Entrada de Título, 02 - Pedido de Baixa, 03 - Concessão de Abatimento, 04 - Cancelamento de Abatimento, 05 - Alteração de Vencimento, 06 - Alteração do uso da Empresa, 07 - Alteração do Prazo de Protesto, 08 - Alteração do Prazo de Devolução, 09 - Alteração de outros dados, 10 - Alt de dados c/ emissão / emissão de boleto, 11 - Alteração da opção de Protesto para Devolução, 12 - Alteração da opção de Devolução para Protesto, 13 - Alteração do valor nominal do título
  [seuNumero, 111, 120], // Número do Documento de Cobrança (Seu Número)
  [formatDateDDMMAA(dup.vencimento), 121, 126], // Vencimento do título
  [formatNumberField(dup.vlr_titulo), 127, 139], // Valor do Título
  ["104", 140, 142], // Código do Banco
  [0, 143, 147], // Agência Cobradora - 00000
  [dadosConta.especie_tit, 148, 149], // Espécie de Título
  ["N", 150, 150], // Aceite - S ou N
  [formatDateDDMMAA(dup.emissao), 151, 156], // Emissão do Título
  [dadosConta.protestar ? "01" : "02", 157, 158], // Primeira Instrução de Cobrança: 01 - Protestar Dias Corridos, 02 - Devolver (Não Protestar)
  [0, 159, 160], // Segunda Instrução de Cobrança - 0
  [formatNumberField(dup.vlr_juros_titulo), 161, 173], // Juros de Mora por dia/Valor
  [temDesconto ? formatDateDDMMAA(dup.data_desconto) : 0, 174, 179], // Data limite para concessão do desconto
  [formatNumberField(dup.vlr_desconto), 180, 192], // Valor/Percentual do Desconto
  [0, 193, 205], // Valor do IOF
  [0, 206, 218], // Abatimento
  [verificaPessoa(dup.cpf_cnpj_cliente).padStart(2, "0"), 219, 220], // Tipo de Inscrição do Pagador: 01 - Pessoa Física, 02 - Pessoa Jurídica
  [formatNumberField(dup.cpf_cnpj_cliente), 221, 234], // Número de Inscrição do Pagador
  [dup.nome_cliente, 235, 274], // Nome do Pagador
  [enderCliente, 275, 314], // Endereço do Pagador
  [dup.bairro_cliente, 315, 326], // Bairro Pagador
  [formatNumberField(dup.cep_cliente), 327, 334], // CEP Pagador
  [dup.cidade_cliente, 335, 349], // Cidade Pagador
  [dup.uf_cliente, 350, 351], // UF Pagador
  [0, 352, 357], // Data da Multa
  [formatNumberField(dup.vlr_multa_titulo), 358, 367], // Valor da Multa
  [dadosConta.nome_avalista, 368, 389], // Nome Sacador Avalista
  [0, 390, 391], // Instrução 3: 00 - Não imprime mensagem no verso do boleto, 02 - Imprime mensagem no verso do boleto
  [dadosConta.protestar ? dadosConta.dias_protesto : 30, 392, 393], // Número de dias para início do protesto/devolução
  [1, 394, 394], // Código da Moeda - 01
  [seq, 395, 400], // Sequencial
];

const rem400T2Caixa = (dadosConta, dup, seq) => [
  ["2", 1, 1], // Código do Registro
  [2, 2, 3], // Tipo Inscrição - 01 - Pessoa Física, 02 - Pessoa Jurídica
  [formatNumberField(dadosConta.cnpj_empresa), 4, 17], // Número Inscrição - CPF ou CNPJ
  [dadosConta.agencia, 18, 21], // Código da Agência
  [dadosConta.cod_cedente, 22, 27], // Cod. Beneficiário
  ["", 28, 56], // Brancos
  [dup.nosso_numero, 57, 73], // Nosso Número
  ["", 74, 106], // Brancos
  [1, 107, 108], // Código da Carteira - 01
  [1, 109, 110], // Código Ocorrência
  ["", 111, 139], // Uso Exclusivo CAIXA
  ["104", 140, 142], // Código do Banco - 104
  [dup.mensagem_1, 143, 182], // Mensagem 1
  [dup.mensagem_2, 183, 222], // Mensagem 2
  [dup.mensagem_3, 223, 262], // Mensagem 3
  [dup.mensagem_4, 263, 302], // Mensagem 4
  ["", 303, 342], // Mensagem 5
  ["", 343, 382], // Mensagem 6
  ["", 383, 394], // Brancos
  [seq, 395, 400],
];

const rem400trailerCaixa = (seq) => [
  ["9", 1, 1], // Identificador
  ["", 2, 394],
  [seq, 395, 400],
];

// ============================== GERAÇÃO DO ARQUIVO ==============================

export const layoutCaixa = (dadosConta, duplicatas) => {
  const header = formatRow(...rem400headerCaixa(dadosConta));

  const detalhes = "".concat(
    ...duplicatas.map((dup, index) => {
      const seuNumero = `${dup.numero}-${dup.parcela}`.padStart(10, "0");

      const enderCliente =
        dup.endereco_cliente +
        (!["", null, undefined].includes(dup.nro_endereco_cliente)
          ? ", " + dup.nro_endereco_cliente
          : "") +
        (!["", null, undefined].includes(dup.complemento_endereco_cliente)
          ? " - " + dup.complemento_endereco_cliente
          : "");

      return (
        // Registro Tipo 1
        formatRow(
          ...rem400T1Caixa(
            dadosConta,
            dup,
            seuNumero,
            enderCliente,
            parseFloat(dup.vlr_desconto) > 0,
            index * 2 + 2
          )
        ) +
        // Registro Tipo 2
        formatRow(...rem400T2Caixa(dadosConta, dup, index * 2 + 3))
      );
    })
  );

  // Trailer
  const trailer = formatRow(...rem400trailerCaixa(duplicatas.length * 2 + 2));

  return header + detalhes + trailer;
};

export const nomeArqCaixa = (dadosConta) => {
  return (
    moment(new Date()).format("DDMMYY") +
    dadosConta.nro_remessa.toString().padStart(3, "0") +
    ".REM"
  );
};
