import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router";
import { Card, Row } from "reactstrap";
import {
  FormButton,
  SimboloItemAtivo,
  SimboloItemInativo,
} from "../../../../../components";
import { DataTable } from "../../../../../components/DataTable";
import { default as service } from "../../../../../services/ordem_servico/VistoriaTecnicaService";
import { setOsPesquisadas } from "../store/filtros_vistorias_slice";
import { docPrintReport } from "../../../../../pdf";

export const GridVistoriaTecnica = () => {
  const history = useHistory();

  const filtrosVistorias = useSelector((state) => state.filtrosVistorias);
  const dispatch = useDispatch();

  const [idOsSelecionada, setIdOsSelecionada] = useState(null);
  const [idVistoriaSelecionada, setIdVistoriaSelecionada] = useState(null);

  const [loadingVisualizar, setLoadingVisualizar] = useState(false);
  const [loadingExcluir, setLoadingExcluir] = useState(false);

  const excluirVistoria = async () => {
    setLoadingExcluir(true);
    const ret = await service.excluirVistoria(idVistoriaSelecionada);
    if (ret) {
      dispatch(setOsPesquisadas(await service.buscarDados(filtrosVistorias)));
    }
    setLoadingExcluir(false);
  };

  const gerarRelatorio = async () => {
    setLoadingVisualizar(true);
    const ret = await service.buscarDadosVistoria(idVistoriaSelecionada);
    const ret2 = await service.buscarDadosOS(ret["id_ord_serv"]);
    const payload = {
      dados: ret,
      dados_os: ret2,
    };
    await docPrintReport("/ordem_servico/vistoria_tecnica/", payload, "5017");
    setLoadingVisualizar(false);
  };

  const handleRedirect = async (action) => {
    if (action === "INC") {
      if (await service.verifOsTemVistoria(idOsSelecionada)) {
        toastr.warning(
          "Atenção",
          "Essa O.S. já possui uma vistoria técnica vinculada a ela. " +
            "Caso deseje mudar algum valor, clique em Alterar"
        );
        return;
      }
    }

    history.push("/ordem_servico/vistoria_tecnica/cadastro/", {
      idVistoria: idVistoriaSelecionada,
      action: action,
      idOS: idOsSelecionada,
    });
  };

  const columns = [
    {
      dataField: "id_vistoria",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "id",
      text: "Nº O.S.",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "data_emi",
      text: "Emissão",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "id_cliente__nome",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      sort: false,
    },
    {
      dataField: "nome_resp",
      text: "Responsável",
      align: "left",
      headerAlign: "left",
      sort: false,
    },
    {
      dataField: "fone_resp",
      text: "Telefone",
      align: "center",
      headerAlign: "center",
      sort: false,
    },
    {
      dataField: "demanda",
      text: "Demanda",
      align: "left",
      headerAlign: "left",
      sort: false,
      formatter: (v) => (v === "OEM" ? "O&M" : "Vistoria"),
    },
    {
      dataField: "vistoria",
      isDummyField: true,
      text: "Vistoria",
      align: "center",
      headerAlign: "center",
      sort: false,
      formatter: (_, row) =>
        row.id_vistoria ? <SimboloItemAtivo /> : <SimboloItemInativo />,
    },
    {
      dataField: "id_cliente__cidade__nome",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
      sort: false,
    },
  ];

  const hintSemVistoria = !idOsSelecionada
    ? "Selecione uma O.S. na lista"
    : !idVistoriaSelecionada
    ? "A O.S. selecionada não possui uma vistoria técnica cadastrada"
    : undefined;

  return (
    <Card body>
      <DataTable
        columns={columns}
        data={filtrosVistorias.osPesquisadas}
        noWrap
        noDataIndication={
          <p className="mt-2 text-muted">Nenhuma vistoria técnica encontrada</p>
        }
        onSelect={(id, row) => {
          setIdOsSelecionada(id);
          setIdVistoriaSelecionada(row.id_vistoria);
        }}
      />
      <Row>
        <FormButton
          md="auto"
          onClick={() => handleRedirect("INC")}
          disabled={!idOsSelecionada}
          disabledHint="Selecione uma O.S. na lista"
        >
          Incluir
        </FormButton>
        <FormButton
          color="warning"
          md="auto"
          onClick={() => handleRedirect("ALT")}
          disabled={!idVistoriaSelecionada}
          disabledHint={hintSemVistoria}
        >
          Alterar
        </FormButton>
        <FormButton
          color="danger"
          md="auto"
          onClick={excluirVistoria}
          loading={loadingExcluir}
          disabled={!idVistoriaSelecionada}
          disabledHint={hintSemVistoria}
        >
          Excluir
        </FormButton>
        <FormButton
          color="info"
          md="auto"
          onClick={gerarRelatorio}
          loading={loadingVisualizar}
          disabled={!idVistoriaSelecionada}
          disabledHint={hintSemVistoria}
        >
          Visualizar
        </FormButton>
      </Row>
    </Card>
  );
};
