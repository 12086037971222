import React from "react";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setTipoDocXml,
  setPlanoContaPadXml,
  setCaminhoImpXml,
  setTipoEstruPastaArmazXml,
  setCaminhoExpXml,
  setCaminhoLixoXml,
  setPlanoContaPadNfEntrManual,
  setPermiteDifPlanoPagVlrTotNfManual,
  setSempreAtuMargemLucroProd,
  setIdentificaPlanoCtaCCustoPor,
  setCentroCustoPadXml,
  setCentroCustoPadNfEntrManual,
} from "../store/nf_entrada_slice";
import {
  AsyncComboBox,
  FormCheckbox,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { RadioItem } from "../../../../components/RadioGroup";

const labelStyle = { marginTop: "auto", lineHeight: 3.5 };

export const PersonNfEntrada = () => {
  const store = useSelector((state) => state.nf_entrada);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <AsyncComboBox
          md={6}
          label="Tipo de Documento Padrão para Entrada via XML"
          concatModelName="tipo_documento"
          defaultValue={store.tipo_doc_xml}
          onChange={(s) => dispatch(setTipoDocXml(s?.value ?? 0))}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={7}
          label="Plano de Contas Padrão para Entrada via XML"
          mode="plano_contas"
          onChange={(v) => dispatch(setPlanoContaPadXml(v ?? 0))}
          value={store.plano_conta_pad_xml}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={7}
          label="Centro de Custo Padrão para Entrada via XML"
          mode="centro_custo"
          onChange={(v) => dispatch(setCentroCustoPadXml(v))}
          value={store.centro_custo_pad_xml}
        />
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Caminho de Importação de XML"
          value={store.caminho_imp_xml}
          onChange={(e, v) => dispatch(setCaminhoImpXml(v))}
          maxLength={255}
          forceUppercase={false}
        />
        <span style={labelStyle}>Utilizado Somente no Sistema Antigo</span>
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Caminho de Armazenamento de XML"
          value={store.caminho_exp_xml}
          onChange={(e, v) => dispatch(setCaminhoExpXml(v))}
          maxLength={255}
          forceUppercase={false}
        />
        <span style={labelStyle}>Utilizado Somente no Sistema Antigo</span>
      </Row>
      <Row>
        <RadioGroup
          label="Armazenamento de XML - Separado Por"
          value={store.tipo_estru_pasta_armaz_xml}
          onChange={(v) => dispatch(setTipoEstruPastaArmazXml(v))}
        >
          <RadioItem label="Fornecedor" value="FOR" />
          <RadioItem label="Data (Mês) (Ex.: Janeiro / 2019)" value="DAT" />
        </RadioGroup>
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Caminho de Descarte de XML"
          value={store.caminho_lixo_xml}
          onChange={(e, v) => dispatch(setCaminhoLixoXml(v))}
          maxLength={255}
          forceUppercase={false}
        />
        <span style={labelStyle}>Utilizado Somente no Sistema Antigo</span>
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={7}
          label="Plano de Contas Padrão para Entrada Manual"
          mode="plano_contas"
          onChange={(v) => dispatch(setPlanoContaPadNfEntrManual(v ?? 0))}
          value={store.plano_conta_pad_nf_entr_manual}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={7}
          label="Centro de Custo Padrão para Entrada Manual"
          mode="centro_custo"
          onChange={(v) => dispatch(setCentroCustoPadNfEntrManual(v ?? null))}
          value={store.centro_custo_pad_nf_entr_manual}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permite a Entrada de NFs que tenham o Plano de Pagamento Diferente do Valor Total da Nf"
          checked={store.permite_dif_plano_pag_vlr_tot_nf_manual}
          onChange={() =>
            dispatch(
              setPermiteDifPlanoPagVlrTotNfManual(
                !store.permite_dif_plano_pag_vlr_tot_nf_manual
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Sempre Atualizar Margem de Lucro dos Produtos na Confirmação de Preço de Custo e Venda (Nas Duas Formas de Confirmação)"
          checked={store.sempre_atu_margem_lucro_prod}
          onChange={() =>
            dispatch(
              setSempreAtuMargemLucroProd(!store.sempre_atu_margem_lucro_prod)
            )
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label="Identificar Centro de Custo Por"
          value={store.identifica_plano_cta_c_custo_por}
          onChange={(v) => dispatch(setIdentificaPlanoCtaCCustoPor(v))}
        >
          <RadioItem label="Parâmetro" value="PARAM" />
          <RadioItem label="Cadastro de Fornecedor" value="CADFO" />
        </RadioGroup>
      </Row>
    </>
  );
};
