import { Clipboard } from "react-feather";
import { HorasPorCliente } from "../../pages/ordens_servico/demonstrativos/horas_por_cliente/HorasPorCliente";
import { HorasVendidasHorasTrabalhadas } from "../../pages/ordens_servico/demonstrativos/horas_vendidas_x_horas_trabalhadas/HorasVendidasHorasTrabalhadas";
import { RendimentoTecnico } from "../../pages/ordens_servico/demonstrativos/rendimento_tecnico/RendimentoTecnico";
import { CadastroVistoriaTecnica } from "../../pages/ordens_servico/atendimento/vistoria_tecnica/cadastro/CadastroVistoriaTecnica";
import { VistoriaTecnica } from "../../pages/ordens_servico/atendimento/vistoria_tecnica/VistoriaTecnica";
import { DemonstrativosOS } from "../../pages/ordens_servico/demonstrativos/DemonstrativosOS";
import { CentralOS } from "../../pages/ordens_servico/atendimento/central_os/CentralOS";
import { IncluirAlterarOS } from "../../pages/ordens_servico/atendimento/central_os/incluir_alterar/IncluirAlterarOS";
import { ManutencaoOS } from "../../pages/ordens_servico/atendimento/central_os/manutencao/ManutencaoOS";
import { OrcamentosOS } from "../../pages/ordens_servico/atendimento/central_os/orcamentos/OrcamentosOS";
import { IncluirAlterarOrcamentoOS } from "../../pages/ordens_servico/atendimento/central_os/orcamentos/incluir_alterar/IncluirAlterarOrcamentoOS";
import { OrcRapidoOS } from "../../pages/ordens_servico/atendimento/central_os/orc_rapido_os/OrcRapidoOS";
import { Atendimento } from "../../pages/ordens_servico/atendimento/Atendimento";
import { HistoricoVeiculo } from "../../pages/ordens_servico/atendimento/central_os/historico_veiculo/HistoricoVeiculo";
import { ValorHorasTecnico } from "../../pages/ordens_servico/demonstrativos/valor_horas_tecnico/ValorHorasTecnico";
import CentralProjetos from "../../pages/ordens_servico/atendimento/central_projetos/CentralProjetos";
import ProgramacaoExecucao from "../../pages/ordens_servico/atendimento/central_projetos/programacao_projeto/ProgramacaoExecucao";
import ValidacaoProgramacaoExecucao from "../../pages/ordens_servico/atendimento/central_projetos/validacao_programacao_execucao/ValidacaoProgramacaoExecucao";
import { GerenciamentoOS } from "../../pages/ordens_servico/gerenciamento/gerenciamento_os/GerenciamentoOS";
import { GerencOS } from "../../pages/ordens_servico/gerenciamento/GerencOS";
import { HistoricoAnaliticoOs } from "../../pages/ordens_servico/demonstrativos/historico_analitico_os/HistoricoAnaliticoOs";

const cadastroVistoriaTecnicaRoute = {
  path: "/ordem_servico/vistoria_tecnica/cadastro/",
  name: "Cadastro Vistoria Técnica",
  id: "AcSkWeb0027_1",
  nroTela: "0027",
  component: CadastroVistoriaTecnica,
};

export const demonstrativoOsHorasClienteRoute = {
  path: "/ordem_servico/demonstrativos/horas_cliente",
  name: "Horas Trabalhadas por Cliente",
  id: "AcSkWeb5001",
  nroTela: "5001",
  component: HorasPorCliente,
};

export const demonstrativoOsHorasVendidasTrabalhadasRoute = {
  path: "/ordem_servico/demonstrativos/horas_vendidas_x_horas_trabalhadas",
  name: "Horas Vendidas X Trabalhadas",
  id: "AcSkWeb5011",
  nroTela: "5011",
  component: HorasVendidasHorasTrabalhadas,
};

export const demonstrativoOsRendimentoTecnicoRoute = {
  path: "/ordem_servico/demonstrativos/rendimento_por_tecnico",
  name: "Rendimento por Técnico",
  id: "AcSkWeb5002",
  nroTela: "5002",
  component: RendimentoTecnico,
};

export const demonstrativoOsValorHorasTecnicoRoute = {
  path: "/ordem_servico/demonstrativos/valor_horas_tecnico",
  name: "Valor de Horas por Técnico",
  id: "AcSkWeb5057",
  nroTela: "5057",
  component: ValorHorasTecnico,
};

export const demonstrativoHistoricoAnaliticoOsRoute = {
  path: "/ordem_servico/demonstrativos/historico_analitico",
  name: "Valor de Horas por Técnico",
  id: "AcSkWeb5064",
  nroTela: "5064",
  component: HistoricoAnaliticoOs,
};

const demonstrativosOsRoutes = [
  demonstrativoOsHorasClienteRoute,
  demonstrativoOsHorasVendidasTrabalhadasRoute,
  demonstrativoOsRendimentoTecnicoRoute,
  demonstrativoOsValorHorasTecnicoRoute,
  demonstrativoHistoricoAnaliticoOsRoute
];

export const incluirAlterarOSRoute = {
  path: "/ordem_servico/central_os/abertura",
  name: "Inclusão de OS",
  id: "AcSkWeb0006_1",
  nroTela: "0006",
  component: IncluirAlterarOS,
};

export const manutencaoOSRoute = {
  path: "/ordem_servico/central_os/manutencao",
  name: "Inclusão de OS",
  id: "AcSkWeb0006_1",
  nroTela: "0006",
  component: ManutencaoOS,
};

export const orcamentosOSRoute = {
  path: "/ordem_servico/orcamentos/",
  name: "Orçamentos de Ordens de Serviço",
  id: "AcSkWeb0006",
  nroTela: "0006",
  component: OrcamentosOS,
};

export const incluirAlterarOrcamentoOSRoute = {
  path: "/ordem_servico/atendimento/orcamento",
  name: "Orçamentos de Ordens de Serviço",
  id: "AcSkWeb0077",
  nroTela: "0077",
  component: IncluirAlterarOrcamentoOS,
};

export const orcRapidoOSRoute = {
  path: "/ordem_servico/atendimento/orcamento_rapido",
  name: "Orçamento Rápido",
  id: "AcSkWeb0077",
  nroTela: "0077",
  component: OrcRapidoOS,
};

export const programacaoProjetoRoute = {
  path: "/ordem_servico/atendimento/programacao_projeto",
  name: "Programação de Projeto",
  id: "AcSkWeb0027_1",
  nroTela: "0027",
  component: ProgramacaoExecucao,
};

export const validarProgramacaoExecucaoRoute = {
  path: "/ordem_servico/atendimento/validar_programacao_execucao",
  name: "Validar Programação de Execução",
  id: "AcSkWeb0027_1",
  nroTela: "0027",
  component: ValidacaoProgramacaoExecucao,
};

export const centralProjetosRoute = {
  path: "/ordem_servico/atendimento/central_projetos",
  name: "Central de Projetos",
  id: "AcSkWeb0027",
  nroTela: "0027",
  component: CentralProjetos,
};

export const centralOSRoute = {
  path: "/ordem_servico/atendimento/central_os",
  name: "Central de O.S.",
  id: "AcSkWeb0006",
  nroTela: "0006",
  component: CentralOS,
};

export const vistoriaTecnicaRoute = {
  path: "/ordem_servico/atendimento/vistoria_tecnica",
  name: "Vistoria Técnica",
  id: "AcSkWeb0027",
  nroTela: "0027",
  component: VistoriaTecnica,
};

export const historicoVeiculoRoute = {
  path: "/ordem_servico/atendimento/central_os/historico_veiculo",
  name: "Histórico do Veículo",
  id: "AcSkWeb0006",
  nroTela: "0006",
  component: HistoricoVeiculo,
};

export const gerenciamentoOsRoute = {
  path: "/ordem_servico/gerenciamento/gerenciamento_os",
  name: "Gerenciamento de O.S.",
  id: "AcSkWeb0107",
  nroTela: "0107",
  component: GerenciamentoOS,
};

export const atendimentoOSRoute = [
  centralOSRoute,
  vistoriaTecnicaRoute,
  orcRapidoOSRoute,
  centralProjetosRoute,
  gerenciamentoOsRoute,
];

const OsRoutes = {
  path: "/ordem_servico",
  name: "Ordens de Serviço",
  icon: Clipboard,
  children: [
    {
      path: "/ordem_servico/atendimento",
      name: "Atendimento",
      component: Atendimento,
      canGoBack: false,
    },
    {
      path: "/ordem_servico/demonstrativos",
      name: "Demonstrativos",
      component: DemonstrativosOS,
      canGoBack: false,
    },
    {
      path: "/ordem_servico/gerenciamento",
      name: "Gerenciamento",
      component: GerencOS,
      canGoBack: false,
    },
  ],
};

export { OsRoutes, cadastroVistoriaTecnicaRoute, demonstrativosOsRoutes };
