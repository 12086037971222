import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id_plano_cta_ret_dinheiro: null,
  id_tipo_doc_nfe_devol: null,
  gerar_nfe_devol_default: false,
  forma_devol_default: "T",
  id_plano_cta_estorno_bancario: null,
  id_plano_cta_estorno_contas_rec: null,
  id_plano_cta_diferenca_conf_em_maos: null,
};

export const devolucaoSlice = createSlice({
  name: "devolucao_slice",
  initialState: initialState,
  reducers: {
    setupDevolucao: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setIdPlanoCtaRetDinheiro: (state, action) => {
      state.id_plano_cta_ret_dinheiro = action.payload;
    },
    setIdTipoDocNfeDevol: (state, action) => {
      state.id_tipo_doc_nfe_devol = action.payload;
    },
    setGerarNfeDevolDefault: (state, action) => {
      state.gerar_nfe_devol_default = action.payload;
    },
    setFormaDevolDefault: (state, action) => {
      state.forma_devol_default = action.payload;
    },
    setIdPlanoCtaEstornoBancario: (state, action) => {
      state.id_plano_cta_estorno_bancario = action.payload;
    },
    setIdPlanoCtaEstornoContasRec: (state, action) => {
      state.id_plano_cta_estorno_contas_rec = action.payload;
    },
    setIdPlanoCtaDiferencaConfEmMaos: (state, action) => {
      state.id_plano_cta_diferenca_conf_em_maos = action.payload;
    },
  },
});

export const {
  setupDevolucao,
  setIdPlanoCtaRetDinheiro,
  setIdTipoDocNfeDevol,
  setGerarNfeDevolDefault,
  setFormaDevolDefault,
  setIdPlanoCtaEstornoBancario,
  setIdPlanoCtaEstornoContasRec,
  setIdPlanoCtaDiferencaConfEmMaos,
} = devolucaoSlice.actions;

export default devolucaoSlice.reducer;
