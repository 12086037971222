import { toastr } from "react-redux-toastr";
import { api } from "../../api";

const BASEURL = "/ordem_servico/solar";

const VistoriaTecnicaService = Object.freeze({
  buscarDados: async (payload) => {
    const params_ = {};
    if (payload.idOS) {
      params_["id_os"] = payload.idOS;
    }
    if (payload.idCliente) {
      params_["id_cliente"] = payload.idCliente;
    }
    const searchParams = new URLSearchParams(params_).toString();
    const ret = await api.get(
      `${BASEURL}/lista_vistoria_tecnica/?${searchParams}`
    );

    if (ret.data.success) {
      return ret.data.res;
    } else {
      toastr.error("Erro", ret.data.msg);
      return [];
    }
  },
  verifOsTemVistoria: async (idOS) => {
    const ret = await api.get(`${BASEURL}/verifica_os_tem_vistoria/${idOS}/`);

    if (ret.data.success) {
      return ret.data.res.os_tem_vistoria;
    } else {
      toastr.error("Erro", ret.data.msg);
      return true;
    }
  },
  buscarDadosOS: async (idOS) => {
    const ret = await api.get(
      `${BASEURL}/buscar_dados_os_inc_vistoria/${idOS}/`
    );

    if (ret.data.success) {
      return ret.data.res;
    } else {
      toastr.error("Erro", ret.data.msg);
      return {};
    }
  },
  buscarDadosVistoria: async (idVistoria) => {
    const ret = await api.get(
      `${BASEURL}/buscar_dados_vistoria/${idVistoria}/`
    );

    if (ret.data.success) {
      return ret.data.res;
    } else {
      toastr.error("Erro", ret.data.msg);
      return {};
    }
  },
  incluirVistoria: async (payload) => {
    const ret = await api.post(`${BASEURL}/gera_vistoria_tecnica/`, payload);

    if (ret.data.success) {
      toastr.success("Sucesso", ret.data.msg);
      return true;
    } else {
      toastr.error("Erro", ret.data.msg);
      ret.data.res.forEach((msg) => {
        toastr.error("Erro", msg);
      });
      return false;
    }
  },
  alterarVistoria: async (idVistoria, payload) => {
    const ret = await api.put(
      `${BASEURL}/alterar_vistoria_tecnica/${idVistoria}/`,
      payload
    );

    if (ret.data.success) {
      toastr.success("Sucesso", ret.data.msg);
      return true;
    } else {
      toastr.error("Erro", ret.data.msg);
      ret.data.res.forEach((msg) => {
        toastr.error("Erro", msg);
      });
      return false;
    }
  },
  excluirVistoria: async (idVistoria) => {
    const ret = await api.delete(
      `${BASEURL}/excluir_vistoria_tecnica/${idVistoria}/`
    );

    if (ret.data.success) {
      toastr.success("Exclusão Realizada", ret.data.msg);
      return true;
    } else {
      toastr.error("Erro", ret.data.msg);
      return false;
    }
  },
  incluirFoto: async (payload) => {
    const ret = await api.post(`${BASEURL}/incluir_foto/`, payload);

    if (ret.data.success) {
      return ret.data.res.id_foto;
    } else {
      toastr.error("Erro", ret.data.msg);
      ret.data.res.forEach((msg) => {
        toastr.error("Erro", msg);
      });
      return null;
    }
  },
  excluirFoto: async (idFoto) => {
    const ret = await api.delete(`${BASEURL}/excluir_foto/${idFoto}/`);

    if (ret.data.success) {
      toastr.success("Exclusão Realizada", ret.data.msg);
      return true;
    } else {
      toastr.error("Erro", ret.data.msg);
      return false;
    }
  },
});

export default VistoriaTecnicaService;
