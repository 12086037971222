import React from "react";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { Table } from "../../../../../components";

export const EstoqueInterGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "nome_rota_mob",
      text: "Rota",
      align: "left",
      headerAlign: "left",
    },
    { dataField: "nome", text: "Nome", align: "left", headerAlign: "left" },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
