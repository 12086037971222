import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/receber/duplic_rec";
const URL_BASE_GERENCIAMENTO = `${URL_BASE}/gerenciamento`;

const GerencContasReceberService = {
  alterar: async (payload) =>
    await apiPutV2(`${URL_BASE_GERENCIAMENTO}/alterar/`, payload),
  buscar: async (idTitulo) =>
    await apiGetV2(`${URL_BASE_GERENCIAMENTO}/buscar/${idTitulo}/`),
  buscarImpressaoRecibo: async (idTitulo) =>
    await apiGetV2(`${URL_BASE}/recibo/dados_impressao/${idTitulo}/`),
  buscarStatus: async (idTitulo) =>
    await apiGetV2(`${URL_BASE_GERENCIAMENTO}/buscar_status/${idTitulo}/`),
  buscarLogs: async (idTitulo) =>
    await apiGetV2(`${URL_BASE_GERENCIAMENTO}/buscar_logs/${idTitulo}/`),
  excluir: async (payload) =>
    await apiPutV2(`${URL_BASE_GERENCIAMENTO}/excluir/`, payload),
  incluir: async (payload) =>
    await apiPostV2(`${URL_BASE_GERENCIAMENTO}/incluir/`, payload),
  listar: async (params) =>
    await apiGetV2(`${URL_BASE_GERENCIAMENTO}/listar/`, params),
  receber: async (payload) =>
    await apiPutV2(`${URL_BASE_GERENCIAMENTO}/receber/`, payload),
  lancamentos: {
    incluir: async (payload) =>
      await apiPostV2(`${URL_BASE_GERENCIAMENTO}/incluir_lancamento/`, payload),
    excluir: async (idLancamento) =>
      await apiDeleteV2(
        `${URL_BASE_GERENCIAMENTO}/excluir_lancamento/${idLancamento}/`
      ),
  },
};

export default GerencContasReceberService;
