import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { gerencOrcamentosRoute } from "../../../routes/modules/vendas";
import { MdOutlineRequestQuote } from "react-icons/md";

export const Orcamentos = () => {
  return (
    <PageContainer title="Orçamentos">
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento de Orçamentos"
          icon={MdOutlineRequestQuote}
          pathname={gerencOrcamentosRoute.path}
          nroTela={gerencOrcamentosRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
