import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  FormButton,
  FormCheckbox,
  PageContainer,
  TextInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { ManutencaoEstoqueGrid } from "./components/ManutencaoEstoqueGrid";
import ManutencaoEstoqueService from "../../../../services/cadastro/ManutencaoEstoqueService";
import { MovimentacoesProdutoModal } from "./components/MovimentacoesProdutoModal";
import { showWarning } from "../../../../components/AlertaModal";
import { AjusteEstoqueModal } from "./components/AjusteEstoqueModal";

const mostrarOptions = [
  { label: "Todos", value: "ALL" },
  { label: "Produtos Com Saldo Menor que Estoque Mínimo", value: "MEN" },
  { label: "Produtos Com Saldo Maior que Estoque Máximo", value: "MAI" },
  { label: "Produtos Dentro do Padrão", value: "PAD" },
];

export const ManutencaoEstoque = () => {
  const [idProduto, setIdProduto] = useState(null);
  const [idGrupo, setIdGrupo] = useState(null);
  const [idFabricante, setIdFabricante] = useState(null);
  const [idSubGrupo, setIdSubGrupo] = useState(null);
  const [referencia, setReferencia] = useState(null);
  const [mostrar, setMostrar] = useState(mostrarOptions[0].value);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [movimentacoesOpen, setMovimentacoesOpen] = useState(false);
  const [ajusteOpen, setAjusteOpen] = useState(false);
  const [mostrarSomenteAtivos, setMostrarSomenteAtivos] = useState(true);
  const [mostrarSomenteComEstoque, setMostrarSomenteComEstoque] =
    useState(false);
  const [mostrarSomenteProdutosApps, setMostrarSomenteProdutosApps] =
    useState(false);

  const carregarDados = async () => {
    const payload = {
      id_produto: idProduto,
      id_grupo: idGrupo,
      id_fabricante: idFabricante,
      id_sub_grupo: idSubGrupo,
      referencia: referencia,
      mostrar: mostrar,
      mostrar_somente_ativos: mostrarSomenteAtivos,
      mostrar_somente_com_estoque: mostrarSomenteComEstoque,
      mostrar_somente_produtos_apps: mostrarSomenteProdutosApps,
    };
    setLoading(true);
    const [ok, ret] = await ManutencaoEstoqueService.listar(payload);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const toggleMovimentacoes = () => setMovimentacoesOpen(!movimentacoesOpen);

  const toggleAjuste = () => setAjusteOpen(!ajusteOpen);

  const onKeyDown = (e) => {
    if (e.key === "F4") {
      e.preventDefault();
      if ([0, null, undefined].includes(selected)) {
        showWarning("Por favor, selecione um produto");
        return;
      }
      toggleMovimentacoes();
    }
    if (e.key === "F6") {
      e.preventDefault();
      toggleAjuste();
    }
  };

  return (
    <PageContainer
      title="Manutenção de Estoque"
      number="0097"
      onKeyDown={onKeyDown}
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            label="Produto"
            concatModelName="produto"
            defaultValue={idProduto}
            onChange={(s) => setIdProduto(s?.value)}
          />
          <AsyncComboBox
            md={4}
            label="Grupo"
            concatModelName="grupo"
            defaultValue={idGrupo}
            onChange={(s) => setIdGrupo(s?.value)}
          />
          <AsyncComboBox
            md={4}
            label="Sub Grupo"
            concatModelName="sub_grupo"
            defaultValue={idSubGrupo}
            onChange={(s) => setIdSubGrupo(s?.value)}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={4}
            label="Fabricante"
            concatModelName="fabricante"
            defaultValue={idFabricante}
            onChange={(s) => setIdFabricante(s?.value)}
          />
          <TextInput
            md={2}
            label="Referência"
            value={referencia}
            onChange={(e, v) => setReferencia(v)}
          />

          <ComboBox
            md={3}
            label="Mostrar"
            options={mostrarOptions}
            defaultValue={mostrar}
            onChange={(s) => setMostrar(s?.value)}
          />
        </Row>
        <Row>
          <FormCheckbox
            label="Somente Ativos"
            checked={mostrarSomenteAtivos}
            onChange={() => setMostrarSomenteAtivos(!mostrarSomenteAtivos)}
            name="mostrar_somente_ativos"
          />
          <FormCheckbox
            label="Somente Produtos com Estoque"
            checked={mostrarSomenteComEstoque}
            onChange={() =>
              setMostrarSomenteComEstoque(!mostrarSomenteComEstoque)
            }
            name="somente_produtos_com_estoque"
          />
          <FormCheckbox
            label="Somente Produtos Enviados para os APPs"
            checked={mostrarSomenteProdutosApps}
            onChange={() =>
              setMostrarSomenteProdutosApps(!mostrarSomenteProdutosApps)
            }
            name="mostrar_somente_produtos_apps"
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <FormButton md="auto" color="primary" onClick={toggleAjuste}>
            F6 - Ajuste Estoque
          </FormButton>
          <FormButton
            md="auto"
            color="primary"
            onClick={toggleMovimentacoes}
            disabled={[0, null, undefined].includes(selected)}
          >
            F4 - Movimentação
          </FormButton>
          <MovimentacoesProdutoModal
            isOpen={movimentacoesOpen}
            toggle={toggleMovimentacoes}
            selected={selected}
          />
          <AjusteEstoqueModal
            isOpen={ajusteOpen}
            toggle={toggleAjuste}
            notifyEvent={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <ManutencaoEstoqueGrid dados={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
