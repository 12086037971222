import React, { useState } from "react";
import {
  BotaoPesquisar,
  DatePicker,
  FixedField,
  FormButton,
  ModalBase,
  Table,
} from "../../../../../components";
import { Row } from "reactstrap";
import moment from "moment";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { toastr } from "react-redux-toastr";

const columns = [
  { dataField: "id", text: "#", align: "center", headerAlign: "center" },
  {
    dataField: "numero",
    text: "Número",
    align: "center",
    headerAlign: "center",
  },
  { dataField: "serie", text: "Série", align: "center", headerAlign: "center" },
  {
    dataField: "nome_cli",
    text: "Cliente",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  {
    dataField: "chave_nfe",
    text: "Chave de Acesso",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "vlr_tot_nf",
    text: "Valor NF",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_icms_diferido",
    text: "Vlr. ICMS Diferido",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const NfCredDiferimentoModal = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataApuracao, setDataApuracao] = useState(null);
  const [vlrTotDiferimento, setVlrTotDiferimento] = useState(0);
  const [qtdNfeDiferimento, setQtdNfeDiferimento] = useState(0);
  const [notasFiscais, setNotasFiscais] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const limparDados = () => {
    setDataApuracao(null);
    setVlrTotDiferimento(0);
    setQtdNfeDiferimento(0);
    setNotasFiscais([]);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const carregarDados = async (data) => {
    if (loading) return false;

    if (!(data instanceof Date)) {
      toastr.warning("Atenção", "Por favor, informe uma data válida.");
      return false;
    }
    setLoading(true);

    const [ok, ret] = await NotaFiscalService.credDiferimento.apurarValores(
      data
    );

    if (ok) {
      setVlrTotDiferimento(parseFloat(ret.vlr_tot_diferimento));
      setQtdNfeDiferimento(ret.qtd_nfe_diferimento);
      setNotasFiscais(ret.notas_fiscais);
    } else {
      setVlrTotDiferimento(0);
      setQtdNfeDiferimento(0);
      setNotasFiscais([]);
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    if (loading) return false;

    if (!(dataApuracao instanceof Date)) {
      toastr.warning("Atenção", "Por favor, informe uma data válida.");
      return false;
    }

    setLoadingSubmit(true);
    const [ok] = await NotaFiscalService.credDiferimento.gerarNfe(dataApuracao);
    if (ok) {
      toggle();
      notifyEvent();
    }
    setLoadingSubmit(false);
  };

  return (
    <>
      <FormButton md="auto" onClick={toggle}>
        Crédito de Diferimento
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Gerar Nota Fiscal de Crédito de Diferimento"
        number="0055_4"
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loading={loadingSubmit}
      >
        <Row className="mb-2">
          <DatePicker
            md={2}
            label="Data de Apuração"
            value={dataApuracao}
            onChange={(v) =>
              setDataApuracao(moment.isMoment(v) ? v.toDate() : v)
            }
            onBlur={(v) => carregarDados(moment.isMoment(v) ? v.toDate() : v)}
          />
          <BotaoPesquisar
            onClick={() => carregarDados(dataApuracao)}
            loading={loading}
            disabled={!(dataApuracao instanceof Date)}
            disabledHint="Informe uma data válida"
          />
        </Row>
        <Row className="mb-2">
          <FixedField
            label="Valor Total de Diferimento"
            value={formatNumber(vlrTotDiferimento, true, 2)}
            horizontal
          />
          <FixedField
            label="Quantidade de NF's"
            value={qtdNfeDiferimento}
            horizontal
          />
        </Row>
        <Table
          data={notasFiscais}
          columns={columns}
          paginated={false}
          showRegisterCount={false}
        />
      </ModalBase>
    </>
  );
};
