import React from "react";
import { Col } from "reactstrap";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import { BsCheck } from "react-icons/bs";

export const ProgressoEntradaNfXml = ({ md = "auto", activeStep = 0 }) => {
  return (
    <Col md={md} className="ml-auto">
      <Steps
        items={[
          { title: "Selecionar NF" },
          { title: "Produtos" },
          { title: "Financeiro" },
        ]}
        current={activeStep}
        labelPlacement="vertical"
        icons={{ finish: <BsCheck /> }}
        className="progresso-entrada-nfe"
      />
    </Col>
  );
};
