import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tipo_doc_xml: 0,
  plano_conta_pad_xml: 0,
  caminho_imp_xml: null,
  tipo_estru_pasta_armaz_xml: "DAT",
  caminho_exp_xml: null,
  caminho_lixo_xml: null,
  plano_conta_pad_nf_entr_manual: 0,
  permite_dif_plano_pag_vlr_tot_nf_manual: false,
  sempre_atu_margem_lucro_prod: true,
  identifica_plano_cta_c_custo_por: "PARAM",
  centro_custo_pad_xml: null,
  centro_custo_pad_nf_entr_manual: null,
};

export const nfEntradaSlice = createSlice({
  name: "nf_entrada_slice",
  initialState: initialState,
  reducers: {
    setupNfEntrada: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setTipoDocXml: (state, action) => {
      state.tipo_doc_xml = action.payload;
    },
    setPlanoContaPadXml: (state, action) => {
      state.plano_conta_pad_xml = action.payload;
    },
    setCaminhoImpXml: (state, action) => {
      state.caminho_imp_xml = action.payload;
    },
    setTipoEstruPastaArmazXml: (state, action) => {
      state.tipo_estru_pasta_armaz_xml = action.payload;
    },
    setCaminhoExpXml: (state, action) => {
      state.caminho_exp_xml = action.payload;
    },
    setCaminhoLixoXml: (state, action) => {
      state.caminho_lixo_xml = action.payload;
    },
    setPlanoContaPadNfEntrManual: (state, action) => {
      state.plano_conta_pad_nf_entr_manual = action.payload;
    },
    setPermiteDifPlanoPagVlrTotNfManual: (state, action) => {
      state.permite_dif_plano_pag_vlr_tot_nf_manual = action.payload;
    },
    setSempreAtuMargemLucroProd: (state, action) => {
      state.sempre_atu_margem_lucro_prod = action.payload;
    },
    setIdentificaPlanoCtaCCustoPor: (state, action) => {
      state.identifica_plano_cta_c_custo_por = action.payload;
    },
    setCentroCustoPadXml: (state, action) => {
      state.centro_custo_pad_xml = action.payload;
    },
    setCentroCustoPadNfEntrManual: (state, action) => {
      state.centro_custo_pad_nf_entr_manual = action.payload;
    },
  },
});

export const {
  setupNfEntrada,
  setTipoDocXml,
  setPlanoContaPadXml,
  setCaminhoImpXml,
  setTipoEstruPastaArmazXml,
  setCaminhoExpXml,
  setCaminhoLixoXml,
  setPlanoContaPadNfEntrManual,
  setPermiteDifPlanoPagVlrTotNfManual,
  setSempreAtuMargemLucroProd,
  setIdentificaPlanoCtaCCustoPor,
  setCentroCustoPadXml,
  setCentroCustoPadNfEntrManual,
} = nfEntradaSlice.actions;

export default nfEntradaSlice.reducer;
