import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pista: "",
  mostrarInativos: false,
  somenteEmEstoque: false,
};

export const listagemProdutosSlice = createSlice({
  name: "listagem_produtos_slice",
  initialState: initialState,
  reducers: {
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setPista: (state, action) => {
      state.pista = action.payload;
    },
    setMostrarInativos: (state, action) => {
      state.mostrarInativos = action.payload;
    },
    setSomenteEmEstoque: (state, action) => {
      state.somenteEmEstoque = action.payload;
    },
  },
});

export const {
  setPista,
  setMostrarInativos,
  setSomenteEmEstoque,
} = listagemProdutosSlice.actions;

export default listagemProdutosSlice.reducer;
