import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { BsCheck } from "react-icons/bs";
export const PlanoContasGrid = ({ dados, setSelected, alterar, excluir }) => {

  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "classificacao",
      text: "Classificação",
      align: "left",
      sortable: true,
    },
    {
      id: 'desc',
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      sortable: true,
      formatter: (desc, row) => row.aceita_lcto ? <span style={{fontWeight: "bold"}}>{desc}</span> : <span style={{fontWeight: 'normal'}}>{desc}</span>
    },
    { dataField: "nivel", text: "Nível", align: " ", sortable: true },
    {
      dataField: "desc_tipo_conta",
      text: "Tipo de Conta",
      align: "left",
      sortable: true,
    },
    {
      dataField: "aceita_lcto",
      text: "Aceita Lançamento",
      align: "center",
      formatter: (cell) => cell ? <BsCheck size={16} color="#5fc27e"/> : <></>,
      sortable: true,
    },
    {
      dataField: "considera_dre",
      text: "Considera DRE",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];
  return <Table data={dados} columns={columns} onSelect={setSelected} onRowDoubleClick={alterar}/>;
};