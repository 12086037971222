import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BsPersonCheck } from "react-icons/bs";
import { ecommerceLiberacaoClientesRoute } from "../../../routes/modules/pedidos";

export const PedEcommerce = () => {
  return (
    <PageContainer title="Pedidos E-Commerce">
      <MenuGroup>
        <MenuGroupButton
          label="Liberação de Clientes"
          icon={BsPersonCheck}
          pathname={ecommerceLiberacaoClientesRoute.path}
          nroTela={ecommerceLiberacaoClientesRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
