import moment from "moment";
import "moment/locale/pt-br";
import React, { useState } from "react";
import "react-day-picker/lib/style.css";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import {
  ComboBox,
  FormCheckbox,
  NumberInput,
  TextInput,
  DatePicker,
  MaskedInput,
} from "../../../../../components";
import { MODAL_ACTIONS, pessoa, ufs } from "../../../../../coreUtils";
import { modalTitleVeiculo, routesBaseVeiculo } from "../Veiculo";
import { ModalCadastroV2 } from "../../../../../components/cadastro";

const optCarroceria = [
  { label: "Não aplicável", value: "NAO" },
  { label: "Aberta", value: "ABE" },
  { label: "Fechada - Baú", value: "FEC" },
  { label: "Granelera", value: "GRA" },
  { label: "Porta contêiner", value: "CON" },
  { label: "Sider", value: "SID" },
];

const optRodado = [
  { label: "Outros", value: "OUT" },
  { label: "Truck", value: "TRU" },
  { label: "Toco", value: "TOC" },
  { label: "Cavalo mecânico", value: "CAV" },
  { label: "VAN", value: "VAN" },
  { label: "Utilitário", value: "UTI" },
];

const optTipoPropriet = [
  { label: "TAC - Agregado", value: "TAA" },
  { label: "TAC Independente", value: "TAI" },
  { label: "Outros", value: "OUT" },
];

export const CadastroVeiculoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [dataCompra, setDataCompra] = useState(new Date());
  const [placa, setPlaca] = useState("");
  const [ufVeiculo, setUfVeiculo] = useState("");
  const [tipoCarroceria, setTipoCarroceria] = useState("");
  const [tipoRodado, setTipoRodado] = useState("");
  const [pesoVeiculo, setPesoVeiculo] = useState(0);
  const [renavam, setRenavam] = useState("");
  const [rntc, setRntc] = useState("");
  const [pertenceTerceiro, setPertenceTerceiro] = useState(false);
  const [tipoPropriet, setTipoPropriet] = useState("");
  const [nomePropriet, setNomePropriet] = useState("");
  const [ufPropriet, setUfPropriet] = useState("");
  const [tipoPessoaPropriet, setTipoPessoaPropriet] = useState("F");
  const [cpfCnpjPropriet, setCpfCnpjPropriet] = useState("");
  const [inscEstPropriet, setInscEstPropriet] = useState("");
  const [tafPropriet, setTafPropriet] = useState("");
  const [regEstadualPropriet, setRegEstadualPropriet] = useState("");

  const limparDados = () => {
    setNome("");
    setDataCompra(new Date());
    setPlaca("");
    setUfVeiculo("");
    setAtivo(true);
    setTipoCarroceria("");
    setTipoRodado("");
    setPesoVeiculo(0);
    setRenavam("");
    setRntc("");
    setPertenceTerceiro(false);
    setTipoPropriet("");
    setNomePropriet("");
    setUfPropriet("");
    setTipoPessoaPropriet("F");
    setCpfCnpjPropriet("");
    setInscEstPropriet("");
    setTafPropriet("");
    setRegEstadualPropriet("");
  };

  const fetchData = async (data) => {
    setAtivo(data.ativo);
    setNome(data.nome ?? "");
    setDataCompra(moment(data.data_compra_veic, "DD/MM/YYYY").toDate());
    setPlaca(data.placa ?? "");
    setUfVeiculo(data.uf_veiculo ?? "");
    setTipoCarroceria(data.tipo_carroceria ?? "");
    setTipoRodado(data.tipo_rodado ?? "");
    setPesoVeiculo(parseFloat(data.peso_veiculo) ?? 0);
    setRenavam(data.renavam ?? "");
    setRntc(data.rntc ?? "");
    setPertenceTerceiro(data.pertence_terceiro);
    setTipoPropriet(data.tipo_propriet ?? "");
    setNomePropriet(data.nome_propriet ?? "");
    setUfPropriet(data.uf_propriet ?? "");
    setTipoPessoaPropriet(data.tipo_pessoa_propriet ?? "F");
    setCpfCnpjPropriet(data.cpf_cnpj_propriet ?? "");
    setInscEstPropriet(data.insc_est_propriet ?? "");
    setTafPropriet(data.taf_propriet ?? "");
    setRegEstadualPropriet(data.reg_estadual_propriet ?? "");
  };

  const submitPayload = (action) => {
    if (!(dataCompra instanceof Date)) {
      toastr.warning("Atenção", "Verifique a data de compra do veículo");
      return false;
    }
    if (placa?.includes("_")) {
      toastr.warning("Atenção", "Verifique a placa do veículo");
      return false;
    }

    const payload = {
      nome: nome,
      data_compra: dataCompra.toISOString().split("T")[0],
      ativo: ativo,
      placa: placa,
      uf_veiculo: ufVeiculo,
      tipo_carroceria: tipoCarroceria,
      tipo_rodado: tipoRodado,
      peso_veiculo: pesoVeiculo,
      renavam: renavam,
      rntc: rntc,
      pertence_terceiro: pertenceTerceiro,
      tipo_propriet: tipoPropriet,
      nome_propriet: nomePropriet,
      uf_propriet: ufPropriet,
      tipo_pessoa_propriet: tipoPessoaPropriet,
      cpf_cnpj_propriet: cpfCnpjPropriet,
      insc_est_propriet: inscEstPropriet,
      taf_propriet: tafPropriet,
      reg_estadual_propriet: regEstadualPropriet,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  const handlePessoa = (v) => {
    setTipoPessoaPropriet(v);
    setCpfCnpjPropriet("");
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleVeiculo}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseVeiculo}
      number="0012_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row form>
        <TextInput
          label="Nome"
          md={9}
          onChange={(e, v) => setNome(v)}
          value={nome}
          maxLength={100}
          autoFocus
        />
        <DatePicker
          label="Data de Compra"
          md={3}
          value={dataCompra}
          onChange={(v) => setDataCompra(moment.isMoment(v) ? v.toDate() : v)}
        />
      </Row>
      <Row form>
        <MaskedInput
          mask="aaa-9*99"
          label="Placa"
          md={3}
          value={placa}
          onChange={(e, v) => setPlaca(v)}
        />
        <ComboBox
          options={ufs}
          md={2}
          label="UF"
          onChange={(s) => setUfVeiculo(s?.value)}
          defaultValue={ufVeiculo}
        />
      </Row>
      <Row>
        <ComboBox
          options={optCarroceria}
          md={4}
          label="Carroceria"
          onChange={(s) => setTipoCarroceria(s?.value)}
          defaultValue={tipoCarroceria}
        />
        <ComboBox
          options={optRodado}
          md={4}
          label="Rodado"
          onChange={(s) => setTipoRodado(s?.value)}
          defaultValue={tipoRodado}
        />
        <NumberInput
          label="Peso (kg)"
          md={4}
          onChange={(v) => setPesoVeiculo(v)}
          value={pesoVeiculo}
        />
      </Row>
      <Row>
        <TextInput
          label="Renavam"
          md={6}
          onChange={(e, v) => setRenavam(v)}
          value={renavam}
          maxLength={30}
        />
        <TextInput
          label="RNTRC"
          md={6}
          onChange={(e, v) => setRntc(v)}
          value={rntc}
          maxLength={8}
        />
        <FormCheckbox
          label="Pertence a Terceiro"
          md="auto"
          padded={false}
          name="pertence_terceiro"
          onChange={() => setPertenceTerceiro(!pertenceTerceiro)}
          checked={pertenceTerceiro}
        />
      </Row>
      <hr />
      <h5>Informações do Proprietário</h5>
      <Row>
        <ComboBox
          options={optTipoPropriet}
          md={4}
          label="Tipo de Proprietário"
          onChange={(s) => setTipoPropriet(s?.value)}
          defaultValue={tipoPropriet}
        />
        <TextInput
          label="Nome do Proprietário"
          md={8}
          onChange={(e, v) => setNomePropriet(v)}
          value={nomePropriet}
          maxLength={60}
        />
      </Row>
      <Row>
        <ComboBox
          options={ufs}
          md={2}
          label="UF"
          onChange={(s) => setUfPropriet(s?.value)}
          defaultValue={ufPropriet}
        />
        <ComboBox
          options={pessoa}
          md={3}
          label="Pessoa"
          onChange={(s) => handlePessoa(s?.value)}
          defaultValue={tipoPessoaPropriet}
        />
        <MaskedInput
          mask={
            tipoPessoaPropriet === "F" ? "999.999.999-99" : "99.999.999/9999-99"
          }
          name="cpf_cnpj"
          label={tipoPessoaPropriet === "F" ? "CPF" : "CNPJ"}
          md={4}
          value={cpfCnpjPropriet}
          onChange={(e, v) => setCpfCnpjPropriet(v)}
        />
      </Row>
      <Row>
        <TextInput
          label="Inscrição Estadual"
          md={4}
          onChange={(e, v) => setInscEstPropriet(v)}
          value={inscEstPropriet}
          maxLength={15}
        />
      </Row>
      <hr />
      <h5>Informações para Fretamento</h5>
      <Row>
        <TextInput
          label="TAF"
          md={6}
          onChange={(e, v) => setTafPropriet(v)}
          value={tafPropriet}
          maxLength={12}
        />
        <TextInput
          label="Registro Estadual"
          md={6}
          onChange={(e, v) => setRegEstadualPropriet(v)}
          value={regEstadualPropriet}
          maxLength={25}
        />
      </Row>
    </ModalCadastroV2>
  );
};
