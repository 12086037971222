import { ManutencaoEstoque } from "../../pages/estoque/acoes/manutencao_estoque/ManutencaoEstoque";
import { EstoqueAcoes } from "../../pages/estoque/acoes/EstoqueAcoes";
import { Package } from "react-feather";
import { EnviarRemessa } from "../../pages/estoque/acoes/enviar_remessa/EnviarRemessa";
import { ReceberRemessa } from "../../pages/estoque/acoes/receber_remessa/ReceberRemessa";
import { IncluirAlterarRemessa } from "../../pages/estoque/acoes/enviar_remessa/incluir_alterar/IncluirAlterarRemessa";
import { ImpressaoEtiquetas } from "../../pages/estoque/acoes/impressao_etiquetas/ImpressaoEtiquetas";
import { ExportacaoBalanca } from "../../pages/estoque/acoes/exportacao_balanca/ExportacaoBalanca";

export const manutencaoEstoqueRoute = {
  path: "/estoque/acoes/manutencao_estoque",
  name: "Manutenção de Estoque",
  id: "AcSkWeb0097",
  nroTela: "0097",
  component: ManutencaoEstoque,
};

export const incluirAlterarRemessaEntreEmpresasRoute = {
  path: "/estoque/acoes/remessa_entre_empresas/remessa",
  name: "Remessa Entre Empresas",
  id: "AcSkWeb0098",
  nroTela: "0098",
  component: IncluirAlterarRemessa,
};

export const enviarRemessaEntreEmpresasRoute = {
  path: "/estoque/acoes/enviar_remessa",
  name: "Enviar Remessa Entre Empresas",
  id: "AcSkWeb0098",
  nroTela: "0098",
  component: EnviarRemessa,
};

export const receberRemessaEntreEmpresasRoute = {
  path: "/estoque/acoes/receber_remessa",
  name: "Receber Remessa Entre Empresas",
  id: "AcSkWeb0099",
  nroTela: "0099",
  component: ReceberRemessa,
};

export const impressaoEtiquetasProdutoRoute = {
  path: "/estoque/acoes/impressao_etiquetas",
  name: "Impressão de Etiquetas",
  id: "AcSkWeb0073",
  nroTela: "0073",
  component: ImpressaoEtiquetas,
};

export const exportacaoBalancaRoute = {
  path: "/estoque/acoes/exportacao_balanca",
  name: "Exportação de Produtos para Balanca",
  id: "AcSkWeb0111",
  nroTela: "0111",
  component: ExportacaoBalanca,
};

export const acoesEstoqueRoutes = [
  manutencaoEstoqueRoute,
  enviarRemessaEntreEmpresasRoute,
  receberRemessaEntreEmpresasRoute,
  impressaoEtiquetasProdutoRoute,
];

export const estoqueRoutes = {
  path: "/estoque",
  name: "Estoque",
  icon: Package,
  children: [
    {
      path: "/estoque/acoes",
      name: "Ações",
      component: EstoqueAcoes,
      canGoBack: false,
    },
  ],
};
