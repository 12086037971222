import React, { useEffect, useState } from "react";
import {
  FixedField,
  FormButton,
  FormCheckbox,
  ModalBase,
  PhoneNumberInput,
  Table,
  TableCheck,
  TableDelete,
  TextInput,
} from "../../../../../../components";
import { MODAL_ACTIONS, formatValueFromAPI } from "../../../../../../coreUtils";
import CadastroClienteService from "../../../../../../services/cadastro/CadastroClienteService";
import { Label, Row } from "reactstrap";
import { showWarning } from "../../../../../../components/AlertaModal";

export const ContatosClienteModal = ({ idCliente, nomeCliente }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [idContatoEdit, setIdContatoEdit] = useState(null);
  const [nomeContato, setNomeContato] = useState("");
  const [email, setEmail] = useState("");
  const [setor, setSetor] = useState("");
  const [cargo, setCargo] = useState("");
  const [enviarEmailNfe, setEnviarEmailNfe] = useState(false);
  const [fone1, setFone1] = useState("");
  const [fone2, setFone2] = useState("");
  const [fone3, setFone3] = useState("");
  const [dados, setDados] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const limparDadosContato = () => {
    setAction(MODAL_ACTIONS.ADD);
    setIdContatoEdit(null);
    setNomeContato("");
    setEmail("");
    setSetor("");
    setCargo("");
    setEnviarEmailNfe(false);
    setFone1("");
    setFone2("");
    setFone3("");
  };

  const limparDados = () => {
    limparDadosContato();
    setDados([]);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const carregarDados = async () => {
    const [ok, ret] = await CadastroClienteService.contato.listar(idCliente);
    setDados(ok ? ret : []);
  };

  const configAlterarContato = (data) => {
    setAction(MODAL_ACTIONS.EDIT);
    setIdContatoEdit(data.id);
    setNomeContato(data.nome_contato ?? "");
    setEmail(data.email ?? "");
    setSetor(data.setor ?? "");
    setCargo(data.cargo ?? "");
    setEnviarEmailNfe(data.enviar_email_nfe);
    setFone1(data.fone1 ?? "");
    setFone2(data.fone2 ?? "");
    setFone3(data.fone3 ?? "");
  };

  const handleSubmit = async () => {
    if (
      (nomeContato ?? "").trim() === "" &&
      (email ?? "").trim() === "" &&
      (fone1 ?? "").trim() === "" &&
      (fone2 ?? "").trim() === "" &&
      (fone3 ?? "").trim() === ""
    ) {
      showWarning(
        "Por favor, Informe o nome, o e-mail ou um telefone do contato."
      );
      return;
    }

    if (enviarEmailNfe && (email ?? "").trim() === "") {
      showWarning(
        "A opção Enviar E-Mail de NFe foi marcada, porém, o e-mail " +
          "não foi informado. Por favor, informe o e-mail do contato " +
          "e tente novamente."
      );
      return;
    }

    const commonPayload = {
      nome_contato: nomeContato,
      email: email,
      setor: setor,
      cargo: cargo,
      enviar_email_nfe: enviarEmailNfe,
      fone1: fone1,
      fone2: fone2,
      fone3: fone3,
    };

    setLoadingSubmit(true);
    let ok = false;
    if (action === MODAL_ACTIONS.ADD) {
      [ok] = await CadastroClienteService.contato.incluir({
        id_cliente: idCliente,
        ...commonPayload,
      });
    } else {
      [ok] = await CadastroClienteService.contato.alterar({
        id: idContatoEdit,
        ...commonPayload,
      });
    }
    if (ok) {
      limparDadosContato();
      carregarDados();
    }
    setLoadingSubmit(false);
  };

  const handleDelete = async (id) => {
    const [ok] = await CadastroClienteService.contato.excluir(id);
    if (ok) {
      if (action === MODAL_ACTIONS.EDIT && idContatoEdit === id) {
        setIdContatoEdit(null);
        setAction(MODAL_ACTIONS.ADD);
      }
      carregarDados();
    }
  };

  useEffect(() => {
    if (
      nomeContato === "" &&
      email === "" &&
      setor === "" &&
      cargo === "" &&
      enviarEmailNfe === false &&
      fone1 === "" &&
      fone2 === "" &&
      fone3 === ""
    ) {
      setAction(MODAL_ACTIONS.ADD);
      setIdContatoEdit(null);
    }
  }, [nomeContato, email, setor, cargo, enviarEmailNfe, fone1, fone2, fone3]);

  const columns = [
    { dataField: "nome_contato", text: "Nome", align: "left" },
    { dataField: "email", text: "E-Mail", align: "left" },
    { dataField: "fone1", text: "Fone 1", align: "center" },
    {
      dataField: "enviar_email_nfe",
      text: "Env. NFe",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  return (
    <>
      <FormButton md="auto" onClick={toggle} padded={false} color="primary">
        Contatos
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Contatos do Cliente"
        number="0040_6"
        onClosed={limparDados}
        onBeforeOpen={carregarDados}
      >
        <Row className="mb-2">
          <FixedField
            label="Cliente"
            value={formatValueFromAPI(nomeCliente, idCliente)}
            horizontal
          />
        </Row>
        <Row>
          <TextInput
            md={10}
            label="Nome do Contato"
            value={nomeContato}
            onChange={(e, v) => setNomeContato(v)}
            maxLength={80}
          />
        </Row>
        <Row>
          <TextInput
            md={6}
            label="Setor"
            value={setor}
            onChange={(e, v) => setSetor(v)}
            maxLength={60}
          />
          <TextInput
            md={6}
            label="Cargo"
            value={cargo}
            onChange={(e, v) => setCargo(v)}
            maxLength={60}
          />
        </Row>
        <Row>
          <PhoneNumberInput
            md={4}
            label="Fone 1"
            value={fone1}
            onChange={setFone1}
            maxLength={18}
          />
          <PhoneNumberInput
            md={4}
            label="Fone 2"
            value={fone2}
            onChange={setFone2}
            maxLength={18}
          />
          <PhoneNumberInput
            md={4}
            label="Fone 3"
            value={fone3}
            onChange={setFone3}
            maxLength={18}
          />
        </Row>
        <Row className="mb-2">
          <TextInput
            md={5}
            label="E-Mail"
            value={email}
            onChange={(e, v) => setEmail(v)}
            maxLength={200}
          />
          <FormCheckbox
            label="Enviar E-Mail de NFe para este contato"
            checked={enviarEmailNfe}
            onChange={() => setEnviarEmailNfe(!enviarEmailNfe)}
            divClassName="pl-0"
          />
          <FormButton
            color="success"
            onClick={handleSubmit}
            loading={loadingSubmit}
            divClassName="ml-auto"
          >
            {action === MODAL_ACTIONS.ADD
              ? "Incluir Contato"
              : "Confirmar Alteração"}
          </FormButton>
        </Row>
        <Table
          data={dados}
          columns={columns}
          pageSize={8}
          paginated={false}
          onRowDoubleClick={(id, row) => configAlterarContato(row)}
          showRegisterCount={false}
        />
        <Label>
          Clique 2x no contato na grade para visualizar todos os seus dados e
          alterá-lo
        </Label>
      </ModalBase>
    </>
  );
};
