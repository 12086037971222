import React, { useState } from "react";
import {
  ModalBase,
  NumberInput,
  PesqPlanoCtaCentroCusto,
} from "../../../../../components";
import { Row } from "reactstrap";
import { showWarning } from "../../../../../components/AlertaModal";
import GerencContasPagarService from "../../../../../services/financeiro/GerencContasPagarService";

export const AlterarPlanoCtaTituloPagarModal = ({
  isOpen,
  toggle,
  utilizaCentroCusto,
  idTitulo,
  origemTituloNaoAlteraPlanoConta,
  planoContaAlterar,
  notifyEvent,
}) => {
  const [idPlanoContas, setIdPlanoContas] = useState(null);
  const [idCentroCusto, setIdCentroCusto] = useState(null);
  const [valor, setValor] = useState(0);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdPlanoContas(null);
    setIdCentroCusto(null);
    setValor(0);
  };

  const carregarDados = () => {
    setIdPlanoContas(planoContaAlterar.id_plano_conta);
    setIdCentroCusto(
      utilizaCentroCusto ? planoContaAlterar.id_centro_custo : null
    );
    setValor(parseFloat(planoContaAlterar.valor));
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idPlanoContas)) {
      showWarning("Por favor, informe o Plano de Contas");
      return;
    }
    if (utilizaCentroCusto && [0, null, undefined].includes(idCentroCusto)) {
      showWarning("Por favor, informe o Centro de Custo");
      return;
    }
    if ([0, null, undefined].includes(valor)) {
      showWarning("Por favor, informe o Valor");
      return;
    }

    const payload = {
      id_titulo: idTitulo,
      id_mov_plano: planoContaAlterar.id,
      id_plano_contas: idPlanoContas,
      id_centro_custo: idCentroCusto,
      valor: valor,
    };

    setLoading(true);
    const [ok] = await GerencContasPagarService.alterarMovPlanoConta(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Alterar Valores do Título a Pagar"
      number="0091_5"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row>
        <PesqPlanoCtaCentroCusto
          md={12}
          label="Plano Contas"
          mode="plano_contas"
          value={idPlanoContas}
          onChange={setIdPlanoContas}
        />
      </Row>
      {utilizaCentroCusto && (
        <Row>
          <PesqPlanoCtaCentroCusto
            md={12}
            label="Centro Custo"
            mode="centro_custo"
            value={idCentroCusto}
            onChange={setIdCentroCusto}
          />
        </Row>
      )}
      <Row>
        <NumberInput
          md={5}
          label="Valor"
          value={valor}
          onChange={setValor}
          disabled={origemTituloNaoAlteraPlanoConta}
        />
      </Row>
    </ModalBase>
  );
};
