import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { gerenciamentoOsRoute } from "../../../routes/modules/ordem_servico";
import { RiListSettingsLine } from "react-icons/ri";

export const GerencOS = () => {
  return (
    <PageContainer title="Demonstrativos - Ordens de Serviço">
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento de O.S."
          icon={RiListSettingsLine}
          pathname={gerenciamentoOsRoute.path}
          nroTela={gerenciamentoOsRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
