import React, { useEffect } from "react";
import { Card, Row } from "reactstrap";
import { PageContainer } from "../../../../components/PageContainer";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  ComboBox,
  FormCheckbox,
  IntegerFormInput,
  RadioGroup,
} from "../../../../components";
import { useState } from "react";
import DemonstrativoMetaService from "../../../../services/relatorios/vendas/DemonstrativoMetaService";
import { DemontrativoMetasGrid } from "./components/DemonstrativoMetasGrid";
import { docPrintReport } from "../../../../pdf";
import { RadioItem } from "../../../../components/RadioGroup";
import { meses, userPodeVerTotaisRelVenda } from "../../../../coreUtils";

export const DemonstrativoMetas = () => {
  const podeVerTotais = userPodeVerTotaisRelVenda();
  const idUsuario = parseInt(localStorage.getItem("id_colaborador"));
  const [visao, setVisao] = useState("ROTA");
  const [detalharPor, setDetalharPor] = useState("PROD");
  const [rotas, setRotas] = useState([]);
  const [supervisor, setSupervisor] = useState(
    !podeVerTotais ? idUsuario : null
  );
  const [ordenarPorResultado, setOrdenarPorResultado] = useState(false);
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => setDados([]);

  const buscarDados = async () => {
    let _rotas = rotas.map((rota) => rota.value);

    const [ok, ret] =
      visao === "ROTA"
        ? await DemonstrativoMetaService.listar({
            id_rota: _rotas,
            ordenar_por_resultado: ordenarPorResultado,
            mes: mes,
            ano: ano,
          })
        : await DemonstrativoMetaService.listarSupervisor({
            id_supervisor: supervisor,
            ordenar_por_resultado: ordenarPorResultado,
            mes: mes,
            ano: ano,
          });
    return ok ? ret : [];
  };

  const carregarDados = async () => {
    setLoading(true);
    setDados(await buscarDados());
    setLoading(false);
  };

  const gerarImpressao = async (data) => {
    await docPrintReport(
      "/relatorios/pronta_entrega/demontrativo_metas/",
      { dados: data, visao: visao, detalhar_por: detalharPor },
      "5041_1"
    );
  };

  const imprimir = async () => {
    setLoadingImprimir(true);

    const ret = await buscarDados();

    await gerarImpressao(ret);

    setLoadingImprimir(false);
  };

  useEffect(() => {
    limparDados();
  }, [rotas, ordenarPorResultado, visao]);

  return (
    <PageContainer title="Demonstrativo de Metas" number="5041" canGoBack>
      <Card body>
        <Row>
          <RadioGroup label="Visualizar Por" onChange={setVisao} value={visao}>
            <RadioItem label="Rota" value="ROTA" />
            <RadioItem label="Supervisor" value="SUPERVISOR" />
          </RadioGroup>
          {visao === "ROTA" && (
            <>
              <AsyncComboBox
                md={3}
                label="Rota"
                isConcatField
                concatModelName="rota_mob"
                isSearchable
                isClearable
                defaultOptions
                isMulti
                onChange={(selected) => {
                  setRotas(selected ?? []);
                }}
              />
              <FormCheckbox
                label="Ordenar por Resultado"
                name="ordenar_por_resultado"
                checked={ordenarPorResultado}
                onChange={() => setOrdenarPorResultado(!ordenarPorResultado)}
              />
            </>
          )}
          {visao === "SUPERVISOR" && (
            <>
              <AsyncComboBox
                md={3}
                label="Supervisor"
                isConcatField
                concatModelName="colaborador"
                isSearchable
                isClearable
                defaultOptions
                defaultValue={supervisor}
                disabled={!podeVerTotais}
                onChange={(s) => setSupervisor(s?.value)}
              />
              <RadioGroup
                label="Detalhar Por"
                onChange={setDetalharPor}
                value={detalharPor}
              >
                <RadioItem label="Produto" value="PROD" />
                <RadioItem label="Rota" value="ROTA" />
              </RadioGroup>
            </>
          )}
          <ComboBox
            md={2}
            label="Mês"
            options={meses}
            defaultValue={mes}
            onChange={(s) => setMes(s?.value)}
            isClearable={false}
            isSearchable={false}
          />
          <IntegerFormInput
            md={1}
            label="Ano"
            defaultValue={ano}
            onChange={setAno}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      {dados?.length > 0 && (
        <>
          <Card body>
            {dados.map((meta) => (
              <DemontrativoMetasGrid
                meta={meta}
                visao={visao}
                detalharPor={visao === "ROTA" ? "PROD" : detalharPor}
                gerarImpressao={gerarImpressao}
              />
            ))}
          </Card>
          <Card body>
            <b>Legenda de Cores</b>
            <ul className="mb-0">
              <li>Vermelho - Abaixo da meta</li>
              <li>Azul - Atingindo pela projeção</li>
              <li>Amarelo - Acima de 50% da meta de troca</li>
              <li>Verde - Atingiu a meta</li>
            </ul>
          </Card>
        </>
      )}
    </PageContainer>
  );
};
