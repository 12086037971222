import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../components";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";
import { formatDateISO } from "../../../../coreUtils";

export const HorasPorCliente = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState(null);

  const handleDateInput = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const imprimir = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: cliente,
    };
    setLoading(true);
    const [ok, ret] = await apiGetV2(
      "/relatorios/ordem_servico/horas_por_cliente/",
      params
    );
    if (ok) {
      const payload = {
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
        ...ret,
      };
      await docPrintReport(
        "/relatorios/ordem_servico/horas_por_cliente/",
        payload,
        "5001"
      );
    }
    setLoading(false);
  };

  return (
    <PageContainer
      title="Horas Trabalhadas por Cliente"
      number="5001"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDateInput} />
        </Row>
        <Row>
          <AsyncComboBox
            md={6}
            isClearable
            isSearchable
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            onChange={(s) => setCliente(s?.value)}
          />
          <BotaoImprimir
            disabled={
              [0, null, undefined].includes(cliente) ||
              !(dataIni instanceof Date) ||
              !(dataFim instanceof Date)
            }
            disabledHint="Verifique o cliente e as datas de início e fim"
            onClick={imprimir}
            loading={loading}
          />
        </Row>
      </Card>
    </PageContainer>
  );
};
