import React, { Children, cloneElement } from "react";
import { useState } from "react";
import { Col, CustomInput, FormGroup } from "reactstrap";
import { handleFocus } from "../coreUtils";
import { HintLabel } from "./HintLabel";
import classNames from "classnames";

export const RadioItem = ({
  label = "",
  value,
  disabled,
  id,
  checked,
  onClick,
  style,
  className,
}) => (
  <CustomInput
    type="radio"
    label={label}
    value={value}
    id={id}
    inline
    checked={checked}
    disabled={disabled}
    onClick={onClick}
    style={style}
    onKeyDown={handleFocus}
    className={className}
  />
);

export const RadioGroup = ({
  label,
  onChange = (v) => {},
  value,
  disabled,
  children,
  divClassName,
  labelStyle,
  onKeyDown,
  optionsClassName,
  hint,
  disabledHint,
  divStyle,
}) => {
  const [id] = useState(
    Children.map(
      children,
      (e) => "radio-" + Math.floor(Math.random() * Date.now())
    )
  );

  return (
    <Col md="auto" className={divClassName} style={divStyle}>
      <FormGroup
        onChange={({ target }) => {
          onChange(target.value);
        }}
        onKeyDown={(e) => onKeyDown && onKeyDown(e, value)}
      >
        {label && (
          <HintLabel
            label={label}
            style={labelStyle}
            hintText={disabled ? disabledHint : hint}
          />
        )}
        <div>
          {Children.map(children, (e, index) =>
            cloneElement(e, {
              id: id[index],
              checked: value === e?.props.value,
              disabled: disabled || e?.props.disabled,
              className: classNames(optionsClassName, e?.props.className),
            })
          )}
        </div>
      </FormGroup>
    </Col>
  );
};
