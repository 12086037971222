import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";
import { docPrintReport } from "../../pdf";

const URL_BASE = "/mdfe";
const ManifEletronMDFeService = {
  imprimir: async (id) => {
    const [ok, dados] = await apiGetV2(`${URL_BASE}/dados_impressao/${id}/`);
    if (ok) {
      await docPrintReport(
        "/docs_eletron/transporte/mdfe/",
        { dados: dados },
        "0042_2"
      );
    }
  },
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  listarDocsInclusaoRapida: async (params) =>
    await apiGetV2(`${URL_BASE}/listar_docs_inclusao_rapida/`, params),
  buscar: async (id) => apiGetV2(`${URL_BASE}/buscar/${id}/`),
  buscarChaveDuplicidade: async (id) =>
    apiGetV2(`${URL_BASE}/duplicidade/buscar_chave_acesso/${id}/`),
  cancelar: async (id) => apiPutV2(`${URL_BASE}/cancelar/`, { id_mdfe: id }),
  corrigirDuplicidade: async (formData) =>
    apiPostV2(`${URL_BASE}/duplicidade/corrigir_duplicidade/`, formData),
  incluir: async (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  alterar: async (payload) => apiPutV2(`${URL_BASE}/alterar/`, payload),
  emitir: async (id) => await apiPutV2(`${URL_BASE}/emitir/`, { id_mdfe: id }),
  encerrar: async (id) =>
    await apiPutV2(`${URL_BASE}/encerrar/`, { id_mdfe: id }),
  buscarEventosAlertas: async (id) =>
    await apiGetV2(`${URL_BASE}/buscar_eventos_alertas/${id}/`),
  buscarDadosConemb: async (mdfes) =>
    await apiGetV2(`${URL_BASE}/buscar_dados_conemb/`, { id_mdfe: mdfes }),
  enviarEmailConemb: async (idMdfe, payload) =>
    await apiPostV2(`${URL_BASE}/enviar_email_conemb/${idMdfe}/`, payload),
};

export default ManifEletronMDFeService;
