import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/jornada/roteirizacao/cadastro";

const RoteirizacaoService = {
  listarDia: (rota, dia, params) =>
    apiGetV2(`${URL_BASE}/listar_dia/${rota}/${dia}/`, params),
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  excluir: (id) => apiDeleteV2(`${URL_BASE}/excluir/${id}/`),
  alteraOrdem: (payload) => apiPutV2(`${URL_BASE}/alterar_ordem/`, payload),
  gerarPorCidade: (payload) =>
    apiPostV2(`${URL_BASE}/gerar_por_cidade/`, payload),
};

export default RoteirizacaoService;
