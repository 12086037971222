import { Briefcase } from "react-feather";
import { ImpressaoBoletos } from "../../pages/financeiro/cobranca_banc/boleto/ImpressaoBoletos";
import { GerenciamentoRemessa } from "../../pages/financeiro/cobranca_banc/remessa/gerenciamento/GerenciamentoRemessa";
import { RemessaBancaria } from "../../pages/financeiro/cobranca_banc/remessa/RemessaBancaria";
import { RetornoBancario } from "../../pages/financeiro/cobranca_banc/retorno/RetornoBancario";
import { ContasReceber } from "../../pages/financeiro/contas_receber/ContasReceber";
import { CompensacoesRealizadas } from "../../pages/financeiro/cartao_cred/gerenciamento_cartao/compensacoes_realizadas/CompensacoesRealizadas";
import { GerenciamentoCartao } from "../../pages/financeiro/cartao_cred/gerenciamento_cartao/GerenciamentoCartao";
import { CobrancaBanc } from "../../pages/financeiro/cobranca_banc/CobrancaBanc";
import { CaixaLoja } from "../../pages/financeiro/caixa_loja/CaixaLoja";
import { CaixaLojaReceber } from "../../pages/financeiro/caixa_loja/receber/CaixaLojaReceber";
import { GerencCredRotativo } from "../../pages/financeiro/contas_receber/gerenc_cred_rotativo/GerencCredRotativo";
import { CartaoCred } from "../../pages/financeiro/cartao_cred/CartaoCred";
import { GerencCaixaLoja } from "../../pages/financeiro/caixa/gerenc_caixa_loja/GerencCaixaLoja";
import { Caixa } from "../../pages/financeiro/caixa/Caixa";
import { CartaoTaxasAdm } from "../../pages/financeiro/cartao_cred/cartao_taxas_adm/CartaoTaxasAdm";
import { GerencCobrancaBanc } from "../../pages/financeiro/cobranca_banc/gerenc_cobranca_banc/GerencCobrancaBanc";
import { ContasPagar } from "../../pages/financeiro/contas_pagar/ContasPagar";
import { GerencContasPagar } from "../../pages/financeiro/contas_pagar/gerenc_contas_pagar/GerencContasPagar";
import { EstornoPagamento } from "../../pages/financeiro/contas_pagar/estorno_pagamento/EstornoPagamento";
import { GerencContasReceber } from "../../pages/financeiro/contas_receber/gerenc_contas_receber/GerencContasReceber";

export const gerencRemessasRoute = {
  path: "/financeiro/remessa/gerenciamento_remessas/",
  id: "AcSkWeb0002",
  nroTela: "0002",
  component: GerenciamentoRemessa,
};

export const compensacoesRealizadasRoute = {
  path: "/financeiro/cartao_cred/gerenciamento/compensacoes_realizadas",
  id: "AcSkWeb0049",
  nroTela: "0049",
  component: CompensacoesRealizadas,
};

export const impressaoBoletosRoute = {
  path: "/financeiro/boleto",
  name: "Impressão de Boletos",
  id: "AcSkWeb0001",
  nroTela: "0001",
  component: ImpressaoBoletos,
};

export const remessaBancariaRoute = {
  path: "/financeiro/remessa",
  name: "Remessa Bancária",
  id: "AcSkWeb0002",
  nroTela: "0002",
  component: RemessaBancaria,
};

export const retornoCobrancaBancariaRoute = {
  path: "/financeiro/retorno",
  name: "Retorno Bancário",
  id: "AcSkWeb0019",
  nroTela: "0019",
  component: RetornoBancario,
};

export const gerenciamentoCobrancaBancRoute = {
  path: "/financeiro/gerenciamento_cobranca_bancaria",
  name: "Gerenciamento Cobrança Bancária",
  nroTela: "0087",
  component: GerencCobrancaBanc,
};

export const cobrancaBancRoutes = [
  impressaoBoletosRoute,
  remessaBancariaRoute,
  retornoCobrancaBancariaRoute,
  gerenciamentoCobrancaBancRoute,
];

export const receberCaixaLojaRoute = {
  path: "/financeiro/caixa/caixa_loja/receber",
  id: "AcSkWeb0063",
  nroTela: "0063",
  component: CaixaLojaReceber,
};

export const caixaLojaRoute = {
  path: "/financeiro/caixa/caixa_loja",
  name: "Caixa Loja",
  id: "AcSkWeb0063",
  nroTela: "0063",
  component: CaixaLoja,
};

export const gerencCaixaLojaRoute = {
  path: "/financeiro/caixa/gerenciamento_caixa_loja",
  id: "AcSkWeb0070",
  nroTela: "0070",
  component: GerencCaixaLoja,
};

export const gerenciamentoCreditoRotativoRoute = {
  path: "/financeiro/contas_receber/gerenc_credito_rotativo",
  name: "Crédito Rotativo",
  id: "AcSkWeb0068",
  nroTela: "0068",
  component: GerencCredRotativo,
};

export const gerencContasReceberRoute = {
  path: "/financeiro/contas_receber/gerenciamento",
  name: "Gerenciamento de Contas a Receber",
  nroTela: "0120",
  component: GerencContasReceber,
};

export const contasReceberRoutes = [
  gerencContasReceberRoute,
  gerenciamentoCreditoRotativoRoute,
];

export const gerenciamentoContasPagarRoute = {
  path: "/financeiro/contas_pagar/gerenc_contas_pagar",
  name: "Gerenciamento de Contas a Pagar",
  nroTela: "0091",
  component: GerencContasPagar,
};

export const estornoPagamentoRoute = {
  path: "/financeiro/contas_pagar/estorno_pagamento",
  name: "Estorno de Pagamento",
  nroTela: "0112",
  component: EstornoPagamento,
};

export const contasPagarRoutes = [
  gerenciamentoContasPagarRoute,
  estornoPagamentoRoute,
];

export const gerencCartaoCredRoute = {
  path: "/financeiro/cartao_cred/gerenciamento",
  name: "Gerenciamento de Cartões",
  id: "AcSkWeb0049",
  nroTela: "0049",
  component: GerenciamentoCartao,
};

export const cadastroCartaoTaxasAdmRoute = {
  path: "/financeiro/cartao_cred/tarifa_cartao",
  name: "Tarifas de Cartão",
  id: "AcSkWeb0003",
  nroTela: "0003",
  component: CartaoTaxasAdm,
};

export const cartaoCreditoRoutes = [
  gerencCartaoCredRoute,
  cadastroCartaoTaxasAdmRoute,
];

const financeiroRoutes = {
  path: "/financeiro",
  name: "Financeiro",
  icon: Briefcase,
  children: [
    {
      path: "/financeiro/caixa",
      name: "Controle de Caixas",
      component: Caixa,
      canGoBack: false,
    },
    {
      path: "/financeiro/cartao_cred",
      name: "Cartões de Crédito",
      component: CartaoCred,
      canGoBack: false,
    },
    {
      path: "/financeiro/cobranca",
      name: "Cobrança Bancária",
      component: CobrancaBanc,
      canGoBack: false,
    },
    {
      path: "/financeiro/contas_pagar",
      name: "Contas a Pagar",
      component: ContasPagar,
      canGoBack: false,
    },
    {
      path: "/financeiro/contas_receber",
      name: "Contas a Receber",
      component: ContasReceber,
      canGoBack: false,
    },
  ],
};

export default financeiroRoutes;
