import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Col } from "reactstrap";

class ExpandableTable extends React.Component {
  renderHandler = ({ data, keyField }, root, level) => {
    const data2 = data[0] || data;
    const { columns, striped, noWrap } = this.props;
    return (
      <Col md={12} className={root ? "p-0" : ""}>
        <BootstrapTable
          classes={`w-auto mn-100 p-2 ${!root ? "child-table" : ""} ${
            noWrap ? "nowrap" : ""
          }`}
          keyField={keyField}
          data={data}
          bordered={false}
          columns={columns[level]}
          condensed={!root}
          striped={striped}
          bootstrap4
          expandRow={
            Object.keys(data2).includes("children")
              ? {
                  className: "selected-expanded-row",
                  onlyOneExpanding: true,
                  renderer: (row) =>
                    this.renderHandler(row["children"], false, level + 1),
                }
              : undefined
          }
        />
      </Col>
    );
  };

  render() {
    return this.renderHandler(this.props.data, true, 0);
  }
}

export default ExpandableTable;
