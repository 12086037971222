import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  NumberInput,
  RadioGroup,
  TextInput,
} from "../../../../../../../components";
import { roundFloat } from "../../../../../../../coreUtils";
import { RadioItem } from "../../../../../../../components/RadioGroup";
import { useCallback } from "react";
import { useEffect } from "react";
import PesqProduto from "../../../../../../../components/form/pesq_produto/PesqProduto";
import { ConfirmarItemJaIncOrcModal } from "./ConfirmarItemJaIncOrcModal";

export const IncluirProdOrcamentoForm = ({
  handleAddItem,
  handleSubstituirItem,
  adicionaItemGrid,
  verificarItemJaIncluido,
  utilizaObservItem,
}) => {
  const [id, setId] = useState(null);
  const [tipo, setTipo] = useState("P");
  const [idItem, setIdItem] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [referencia, setReferencia] = useState("");
  const [unidade, setUnidade] = useState("");
  const [quantidade, setQuantidade] = useState(1);
  const [vlrUnit, setVlrUnit] = useState(0);
  const [vlrItem, setVlrItem] = useState(0);
  const [percDesc, setPercDesc] = useState(0);
  const [vlrTotal, setVlrTotal] = useState(0);
  const [vlrDesc, setVlrDesc] = useState(0);
  const [vlrAcre, setVlrAcre] = useState(0);
  const [observ, setObserv] = useState("");
  const [curinga, setCuringa] = useState(false);
  const [confirmarOpen, setConfirmarOpen] = useState(false);

  const refServico = useRef();

  const refPesqProd = useRef();

  const refQuantidade = useRef();

  useEffect(() => {
    if (refServico.current) {
      refServico.current.clearValue();
    }
    if (refPesqProd.current) {
      refPesqProd.current.clear();
    }
  }, [tipo]);

  const limparDados = useCallback(
    (limparTudo = true, limparTipo = true) => {
      if (limparTudo) {
        if (refServico.current) {
          refServico.current.clearValue();
        }
        if (refPesqProd.current) {
          refPesqProd.current.clear();
        }
        if (limparTipo) {
          setTipo("S");
        }
      }
      setId(null);
      setIdItem(0);
      setDescricao("");
      setUnidade("");
      setQuantidade(1);
      setVlrUnit(0);
      setVlrItem(0);
      setPercDesc(0);
      setVlrTotal(0);
      setVlrDesc(0);
      setVlrAcre(0);
      setObserv("");
      setCuringa(false);
    },
    [tipo]
  );

  useEffect(() => {
    if (tipo === "P") {
      if (refPesqProd.current && idItem) {
        refPesqProd.current.setId(String(idItem));
        refPesqProd.current.setDescricao(descricao);
        refPesqProd.current.setReferencia(String(referencia));
      }
    }
  }, [refPesqProd.current, tipo, idItem]);

  const handleSetTipo = (v) => {
    if (v !== tipo) {
      limparDados(false);
    }
    setTipo(v);
  };

  const handleSetItem = (s) => {
    if (s?.value !== idItem && ![0, null, undefined].includes(s?.value)) {
      limparDados(false);

      setIdItem(s?.value);
      setDescricao(s?.nome);
      setPercDesc(0);
      setVlrDesc(0);
      setVlrAcre(0);
      if (tipo === "P") {
        setVlrUnit(s?.preco_venda);
        setUnidade(s?.unidade);
        setVlrItem(s?.preco_venda);
        setVlrTotal(s?.preco_venda);
      } else {
        setVlrUnit(s?.valor);
        setUnidade(s?.unidade);
        setVlrItem(s?.valor);
        setVlrTotal(s?.valor);
        setCuringa(s?.pode_alt_nome);
      }
      selectNextField();
    }
  };

  const handleSelectItemPesq = ({
    idProduto,
    nomeProduto,
    referencia,
    precoVenda,
    unidade,
    curinga,
  }) => {
    if (idItem !== idProduto) {
      setIdItem(idProduto);
      setDescricao(nomeProduto);
      setVlrUnit(precoVenda);
      setUnidade(unidade);
      setVlrItem(precoVenda);
      setReferencia(referencia);
      setPercDesc(0);
      setVlrDesc(0);
      setVlrAcre(0);
      setVlrTotal(precoVenda);
      setCuringa(curinga);
    }
  };

  const handleSetQuantidade = (v) => {
    setQuantidade(v);

    const vTotBruto = vlrUnit * v;
    const vTotLiq = vlrItem * v;
    let vlrDesc = vTotBruto - vTotLiq;
    let vlrAcre = vTotLiq - vTotBruto;
    if (vlrDesc < 0) {
      vlrDesc = 0;
    }
    if (vlrAcre < 0) {
      vlrAcre = 0;
    }

    let pDesc = 0;
    if (vlrDesc > 0) {
      pDesc = 100 - (vTotLiq / vTotBruto) * 100;
    }
    setPercDesc(roundFloat(pDesc, 2));
    setVlrDesc(roundFloat(vlrDesc, 2));
    setVlrAcre(roundFloat(vlrAcre, 2));
    setVlrTotal(roundFloat(vTotLiq, 2));
  };

  const handleSetVlrItem = (v) => {
    setVlrItem(v);

    const vTotBruto = vlrUnit * quantidade;
    let pDesc = 0;
    let vDesc = 0;
    let vAcre = 0;
    if (v < vlrUnit) {
      if (vTotBruto > 0) {
        pDesc = 100 - (v / vlrUnit) * 100;
        if (pDesc > 0) {
          vDesc = (pDesc / 100) * vTotBruto;
        }
      }
    } else if (v > vlrUnit) {
      vAcre = (v - vlrUnit) * quantidade;
    }
    const vTotLiq = v * quantidade;

    setPercDesc(roundFloat(pDesc, 2));
    setVlrDesc(roundFloat(vDesc, 2));
    setVlrAcre(roundFloat(vAcre, 2));
    setVlrTotal(roundFloat(vTotLiq, 2));
  };

  const handleSetPercDesc = (v) => {
    setPercDesc(v);

    const vTotBruto = vlrUnit * quantidade;
    let vItem = vlrItem;
    let vDesc = 0;
    if (vTotBruto > 0 && v > 0) {
      vDesc = roundFloat((v / 100) * vTotBruto, 2);
      vItem = roundFloat(vlrUnit - vDesc / quantidade, 2);
    }
    const vTotLiq = roundFloat(vItem * quantidade, 2);

    if (v > 0) setVlrAcre(0);
    setVlrItem(vItem);
    setVlrDesc(vDesc);
    setVlrTotal(vTotLiq);
  };

  const handleSetVlrDesc = (v) => {
    setVlrDesc(v);

    const vTotBruto = vlrUnit * quantidade;
    let pDesc = 0;
    let vItem = vlrItem;
    if (vTotBruto > 0 && v > 0) {
      vItem = vlrUnit - v / quantidade;
      pDesc = 100 - (vItem / vlrUnit) * 100;
    }
    const vTotLiq = vItem * quantidade;

    if (v > 0) setVlrAcre(0);
    setPercDesc(roundFloat(pDesc, 2));
    setVlrItem(roundFloat(vItem, 2));
    setVlrTotal(roundFloat(vTotLiq, 2));
  };

  const incluirItem = async (confirmar) => {
    const payload = {
      id: id || null,
      tipo: tipo,
      id_item: idItem,
      descricao: descricao,
      quantidade: quantidade,
      vlr_unit: vlrUnit,
      vlr_item: vlrItem,
      perc_desc: percDesc,
      vlr_tot_desc: vlrDesc,
      vlr_tot_acre: vlrAcre,
      vlr_total: vlrTotal,
      referencia: referencia,
      unidade: unidade,
      observ: observ,
      curinga: curinga,
    };

    if (confirmar && adicionaItemGrid === "S") {
      handleSubstituirItem(payload);
    } else {
      handleAddItem(payload);
    }

    limparDados(true, false);
    if (tipo === "P" && refPesqProd.current) {
      setTimeout(() => {
        refPesqProd.current.focus();
      }, 100);
    } else if (tipo === "S" && refServico.current) {
      refServico.current.setFocus();
    }
  };

  const semItem = [0, null, undefined].includes(idItem);

  const toggleConfirmar = () => setConfirmarOpen(!confirmarOpen);

  const verificarIncluirItem = () => {
    if (semItem) {
      return toastr.warning(
        "Atenção",
        `Por favor, selecione um ${tipo === "P" ? "Produto" : "Serviço"}.`
      );
    }

    if (quantidade <= 0) {
      return toastr.warning("Atenção", "A quantidade não pode ser zero.");
    }

    if (vlrItem < 0) {
      return toastr.warning("Atenção", "Por favor, verifique o valor do item.");
    }

    const jaIncluido = verificarItemJaIncluido(tipo, idItem);

    if (jaIncluido) {
      toggleConfirmar();
    } else {
      incluirItem();
    }
  };

  const selectNextField = () => {
    refQuantidade.current.focus();
  };

  const onKeyDownTipo = (e) => {
    if (e.key === "Enter") {
      if (tipo === "S") {
        if (refServico.current) {
          refServico.current.setFocus();
        }
      } else {
        if (refPesqProd.current) {
          refPesqProd.current.focus();
        }
      }
    }
  };

  return (
    <div className="mb-3">
      <Row>
        <RadioGroup
          label="Tipo"
          value={tipo}
          onChange={handleSetTipo}
          onKeyDown={onKeyDownTipo}
        >
          <RadioItem label="Produto" value="P" />
          <RadioItem label="Serviço" value="S" />
        </RadioGroup>
        {tipo === "S" && (
          <AsyncComboBox
            style={{ zIndex: 2 }}
            md={7}
            isConcatField
            concatModelName="servico"
            label="Serviço"
            isSearchable
            onChange={handleSetItem}
            defaultValue={idItem}
            defaultOptions
            ref={refServico}
          />
        )}
        {tipo === "P" && (
          <PesqProduto
            md={7}
            onConfirm={handleSelectItemPesq}
            ref={refPesqProd}
            selectNextField={selectNextField}
            onChangeDescricao={setDescricao}
          />
        )}
        <TextInput
          md={1}
          label="Unidade"
          value={unidade}
          disabled
          className="text-center"
        />
        <NumberInput md={1} label="Unitário" value={vlrUnit} disabled />
      </Row>
      <Row>
        <NumberInput
          md={2}
          label="Quantidade"
          value={quantidade}
          onChange={handleSetQuantidade}
          disabled={semItem}
          ref={refQuantidade}
        />
        <NumberInput
          md={2}
          label="Valor Item"
          value={vlrItem}
          onChange={handleSetVlrItem}
          disabled={semItem}
        />
        <NumberInput
          md={2}
          label="Perc. Desc"
          value={percDesc}
          onChange={handleSetPercDesc}
          isPercentage
          disabled={semItem}
        />
        <NumberInput
          md={2}
          label="Desconto"
          value={vlrDesc}
          onChange={handleSetVlrDesc}
          disabled={semItem}
        />
        <NumberInput md={2} label="Valor Total" value={vlrTotal} disabled />
        <FormButton md="auto" color="primary" onClick={verificarIncluirItem}>
          Incluir
        </FormButton>
        <ConfirmarItemJaIncOrcModal
          isOpen={confirmarOpen}
          toggle={toggleConfirmar}
          adicionaItemGrid={adicionaItemGrid}
          tipo={tipo}
          incluirItem={() => incluirItem(true)}
        />
      </Row>
      <Row>
        {tipo === "P" && utilizaObservItem && (
          <TextInput
            md={9}
            rows={3}
            type="textarea"
            label="Observação"
            value={observ}
            onChange={(_, v) => setObserv(v)}
            forceUppercase={false}
            disabled={semItem}
          />
        )}
      </Row>
    </div>
  );
};
