import React, { useState } from "react";
import { ModalBase } from "../../../../../components";
import NotaFiscalConsumidorService from "../../../../../services/docs_eletron/NotaFiscalConsumidorService";

export const CancelarNFCeModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const [ok] = await NotaFiscalConsumidorService.gerenciamento.cancelar(
      selected
    );
    if (ok) {
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Cancelar Nota Fiscal Consumidor"
      number="0066_3"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <h5 style={{ textAlign: "center" }}>
        Tem certeza de que deseja cancelar a NFC-e?
      </h5>
      <label style={{ textAlign: "center" }}>
        O Prazo para cancelamento de NFCe é de <b>30 minutos</b> após a emissão.
        Caso o prazo tenha expirado, é necessário emitir uma nova Nota Fiscal de
        Estorno da NFC-e original recebida.
      </label>
    </ModalBase>
  );
};
