import React from "react";
import { useSelector } from "react-redux";
import { Card, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { formatNumber } from "../../../../../coreUtils";

export const CardTotais = () => {
  const _state = useSelector((state) => state.gerenciamentoVendasMobile);

  return (
    <Card body>
      <Row>
        <Col>
          <ListGroup horizontal>
            <ListGroupItem>
              Quantidade de Vendas:{" "}
              <strong>
                <span>{formatNumber(_state.totais.num_vendas, true, 0)}</span>
              </strong>
            </ListGroupItem>
            <ListGroupItem>
              Troca:{" "}
              <strong>
                <span>
                  {formatNumber(_state.totais?.perc_troca ?? 0, true, 2)} % - R${" "}
                  {formatNumber(_state.totais.troca, true, 2)}
                </span>
              </strong>
            </ListGroupItem>
            <ListGroupItem>
              Produtos:
              <strong>
                <span>R$ {formatNumber(_state.totais.produtos, true, 2)}</span>
              </strong>
            </ListGroupItem>
            <ListGroupItem>
              ICMS ST:
              <strong>
                <span>R$ {formatNumber(_state.totais.icms_st, true, 2)}</span>
              </strong>
            </ListGroupItem>
            <ListGroupItem>
              Total:
              <strong>
                <span>R$ {formatNumber(_state.totais.geral, true, 2)}</span>
              </strong>
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
    </Card>
  );
};
