import { apiDeleteV2, apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/cadastro/tipo_cliente";

const CadastroTipoClienteService = {
  routesBase: URL_BASE,
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir/`, payload),
  excluir: async (id) => await apiDeleteV2(`${URL_BASE}/excluir/${id}/`),
};

export default CadastroTipoClienteService;
