import React from "react";
import { useSelector } from "react-redux";
import { ExpandableRow } from "./ExpandableRow";

export const ReceitasEstruturadas = () => {
  const store = useSelector((state) => state.dre);
  return (
    <>
      <h4 style={{ fontSize: "0.9rem" }}>
        Plano de Contas Estruturado de RECEITAS
      </h4>
      <div className="pl-3 pr-1">
        <ul className="dre-list">
          {store.receitas.map((planoConta, index) => {
            return <ExpandableRow key={index} row={planoConta} />;
          })}
        </ul>
      </div>
    </>
  );
};
