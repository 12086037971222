import React from "react";
import { Table } from "../../../../../../../components";
import { naturalSort } from "../../../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../../../components/cadastro";

export const PercursoGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      sortable: true,
    },
    {
      dataField: "ufs",
      text: "UF's",
      align: "left",
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];
  return <Table columns={columns} data={data} onSelect={setSelected} />;
};
