import React from "react";
import { Col, Label } from "reactstrap";

export const FixedField = ({
  label,
  value,
  md = "auto",
  padded = false,
  divClassName,
  horizontal = false,
  boldValue,
  align,
  labelStyle,
  textStyle,
  divStyle = {},
  labelClassName,
  verticallyCentered,
}) => {
  if (padded) {
    divStyle = { ...divStyle, marginTop: "auto" };
  }

  if (verticallyCentered) {
    divStyle = {
      ...divStyle,
      display: "flex",
      alignItems: "center",
    };
  }

  return (
    <Col md={md} style={divStyle} className={divClassName}>
      {horizontal ? (
        <>
          {boldValue ? (
            <h6
              className={labelClassName}
              style={{
                fontSize: "0.8rem",
                fontWeight: 600,
                ...labelStyle,
              }}
            >
              {label}: {value}
            </h6>
          ) : (
            <div style={{ display: "flex" }}>
              <h6
                style={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                  lineHeight: "1.4",
                  marginBottom: 0,
                  ...labelStyle,
                }}
                className={labelClassName}
              >
                {label}:
              </h6>
              <Label
                className="mb-0"
                style={{
                  fontSize: "0.8rem",
                  paddingLeft: "0.25rem",
                  lineHeight: "1.4",
                  ...textStyle,
                }}
              >
                {value}
              </Label>
            </div>
          )}
        </>
      ) : (
        <>
          <Label
            style={{
              color: "rgb(70, 70, 70)",
              width: "100%",
              textAlign: align,
              ...labelStyle,
            }}
            className={labelClassName}
          >
            {label}
          </Label>
          <Label
            style={{
              textAlign: align,
              width: "100%",
              fontWeight: boldValue ? "bold" : "inherit",
              ...textStyle,
            }}
          >
            {value}
          </Label>
        </>
      )}
    </Col>
  );
};
