import { apiPostV2 } from "../../apiV2";

const URL_BASE = "/bancario/cobranca/retorno";

export const RetornoBancarioService = {
  lerArquivo: async (file, idContaBancaria) => {
    var formData = new FormData();
    formData.append("file", file);

    return await apiPostV2(
      `${URL_BASE}/ler_arquivo/${idContaBancaria}/`,
      formData,
      { successMessage: false }
    );
  },
  processarRetorno: async (payload) =>
    apiPostV2(`${URL_BASE}/processar/`, payload),
};
