import React from "react";
import {
  ModalDetalhesIcone,
  MoreDropdown,
  Table,
  TableCheck,
} from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";

export const OrdemProducaoGrid = ({
  dados,
  mostrarDetalhes,
  finalizar,
  handleSelect,
  handleSelectAll,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "data_inclusao",
      text: "Data de Produção",
      align: "center",
    },
    {
      dataField: "nome_produto_final",
      text: "Produto Final",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_produto_final),
    },
    { dataField: "nome_ficha_tecnica", text: "Ficha Técnica", align: "left" },
    {
      dataField: "quantidade_produzir",
      text: "Qtd. Produzir",
      align: "center",
      formatter: (c) => formatNumber(c, true, 3, true),
    },
    {
      dataField: "teve_perda",
      text: "Perda",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    {
      dataField: "quantidade_produzida",
      text: "Qtd. Produzida",
      align: "center",
      formatter: (c) => c && formatNumber(c, true, 3, true),
    },
    { dataField: "desc_status", text: "Status", align: "center" },
    {
      dataField: "det",
      text: "",
      align: "center",
      cellContentTag: null,
      selectOnClick: false,
      formatter: (c, row) => (
        <ModalDetalhesIcone toggle={() => mostrarDetalhes(row.id)} />
      ),
    },
    {
      dataField: "act",
      isDummyField: true,
      align: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown horizontal onToggle={() => {}}>
          <DropdownItem onClick={() => finalizar(row.id)}>
            Finalizar
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      pageSize={10}
      onSelect={handleSelect}
      onSelectAll={handleSelectAll}
      multiselect
    />
  );
};
