import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  PesqPlanoCtaCentroCusto,
} from "../../../../components";
import { Row } from "reactstrap";
import {
  setEnvioEmailAutomatico,
  setGerarBoletoAutomatico,
  setIdCentroCustoReceita,
  setIdContaBancFatBoleto,
  setLayoutImprPed,
} from "../store/entrega_pedido_ext_slice";

const layoutsPedido = [
  {
    label:
      "Modelo 1 - Folha A4 - 1 via; Considera Ambiente; Produto, Nome Produto, UN, Qtde, Valor Item, Desconto, Total",
    value: 1,
  },
  { label: "Modelo 2 - Matricial", value: 2 },
  {
    label:
      "Modelo 3 - Folha A4 - Layout Padrão - Sem informações da Empresa - 1 via",
    value: 3,
  },
  {
    label: "Modelo 4 - Folha A4 - Textos maiores e mais espaçados",
    value: 4,
  },
  {
    label:
      "Modelo 5 - Folha A4 - Observação do Item - Informações e logo da empresa",
    value: 5,
  },
  {
    label: "Modelo 6 - Folha A4 - Similar ao Modelo 5, com desconto do item",
    value: 6,
  },
  {
    label: "Modelo 7 - Folha A4 - Cabeçalho com informações de entrega",
    value: 6,
  },
];

export const PersonEntregaPedidoExt = () => {
  const store = useSelector((state) => state.entrega_pedido_ext);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Centro de Custo de Receitas"
          mode="centro_custo"
          value={store.id_centro_custo_receita}
          onChange={(s) => dispatch(setIdCentroCustoReceita(s ?? 0))}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Conta Bancária para Faturamento de Boletos"
          isSearchable
          isConcatField
          defaultOptions
          concatModelName="conta_banc"
          defaultValue={store.id_conta_banc_fat_boleto}
          onChange={(s) => dispatch(setIdContaBancFatBoleto(s?.value ?? 0))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Gerar Boleto Automaticamente"
          checked={store.gerar_boleto_automatico}
          onChange={() =>
            dispatch(setGerarBoletoAutomatico(!store.gerar_boleto_automatico))
          }
        />
      </Row>
      <Row>
        <ComboBox
          md={10}
          label="Layout de Impressão"
          options={layoutsPedido}
          defaultValue={store.layout_impr_ped}
          onChange={(s) => dispatch(setLayoutImprPed(s?.value))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Enviar E-Mail Automaticamente"
          checked={store.envio_email_automatico}
          onChange={() =>
            dispatch(setEnvioEmailAutomatico(!store.envio_email_automatico))
          }
        />
      </Row>
    </>
  );
};
