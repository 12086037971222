import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../../components";
import { GerenciamentoRemessaGrid } from "./components/GerenciamentoRemessaGrid";
import RemessaBancariaService from "../../../../../services/bancario/RemessaBancariaService";

export const GerenciamentoRemessa = () => {
  const [contaBanc, setContaBanc] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setDados([]);
  };

  const buscarDados = async () => {
    setLoading(true);
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_conta_banc: contaBanc,
    };
    const [ok, ret] = await RemessaBancariaService.listar(params);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
    limparDados();
  };

  return (
    <PageContainer
      title="Gerenciamento de Remessas Bancárias"
      number="0002_1"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            isConcatField
            concatModelName="conta_banc"
            defaultOptions
            label="Conta Bancária"
            onChange={(s) => setContaBanc(s?.value)}
            isSearchable
            isClearable
          />
          <FiltroPeriodoDatas
            label="Período de geração da remessa"
            onChange={handleDate}
          />
          <BotaoPesquisar loading={loading} onClick={buscarDados} />
        </Row>
      </Card>
      <GerenciamentoRemessaGrid data={dados} notifyEvent={buscarDados} />
      <Card body>
        <h5 className="mb-0">
          Os arquivos devem ser enviados ao Internet Banking da Conta Bancária
          conforme a ordem em que forem gerados.
        </h5>
      </Card>
    </PageContainer>
  );
};
