import React from "react";
import { formatarValor } from "../../../../../coreUtils";
import { Table } from "../../../../../components";

export const ListagemProdutosGrid = ({ dados = [], visao }) => {
  const columns = [
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_produto", text: "Nome", align: "left" },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      formatter: (c) => formatarValor(c, 2, 0, true),
      hidden: ["CAD", "ADM"].includes(visao),
    },
    {
      dataField: "qtd_estoque_cadastral",
      text: "Quantidade Estoque Cadastral",
      align: "center",
      formatter: (c) => formatarValor(c, 2, 0, true),
      hidden: visao !== "CAD",
    },
    {
      dataField: "qtd_estoque_real_adm",
      text: "Quantidade Estoque Real ADM",
      align: "center",
      formatter: (c) => formatarValor(c, 2, 0, true),
      hidden: visao !== "ADM",
    },
  ];
  return (
    <Table
      data={dados}
      columns={columns}
      paginated={false}
      keyField="id_produto"
    />
  );
};
