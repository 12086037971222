import React, { useState } from "react";
import { ModalBase } from "../../../../../../components";
import CadastroClienteService from "../../../../../../services/cadastro/CadastroClienteService";

export const TransformarClienteCuringaModal = ({
  isOpen,
  toggle,
  idCliente,
  nomeCliente,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const [ok] = await CadastroClienteService.transformarCuringa({
        id_cliente: idCliente,
      });
      if (ok) {
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Transformar Cliente em Curinga"
      number="0040_8"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      Você tem certeza que deseja transformar o cliente {nomeCliente} (
      {idCliente}) em curinga?
    </ModalBase>
  );
};
