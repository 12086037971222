import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import settings from "../settings";
import { IoIosWarning } from "react-icons/io";
import { ContextMenu } from "../redux/reducers/menuReducer";

const MenuGroupButtonInternal = ({
  label,
  pathname,
  backgroundColor,
  icon: Icon,
  divClassName,
  nroTela,
  menus,
}) => {
  const history = useHistory();
  const menu = (menus ?? []).find((e) => e.path === pathname);
  const liberado = menu !== undefined;
  const excecao = menu?.excecao;

  const onClick = () => liberado && history.push(pathname);

  const fundoIcPlano = liberado ? "#248086" : "#d7d7d7";

  return (
    <div
      className={classNames(
        "menu-group-button",
        !liberado && "disabled",
        divClassName
      )}
    >
      <div
        className="icon-button"
        style={{ backgroundcolor: backgroundColor }}
        onClick={onClick}
      >
        <div style={{ width: 30, height: 30, position: "relative" }}>
          {excecao && (
            <div
              style={{
                color: "white",
                left: "-5px",
                position: "absolute",
                top: "-5px",
                width: "15px",
                height: "15px",
                display: "flex",
                padding: "2px",
                borderRadius: "7px",
                background: "red",
              }}
            >
              <IoIosWarning
                size="15"
                color="white"
                display="flex"
                style={{ position: "relative", bottom: "1.2px" }}
              />
            </div>
          )}
          <Icon size={30} color="white" />

          <div
            style={{
              color: "white",
              left: "20px",
              position: "relative",
              bottom: "12px",
              width: "12px",
              height: "12px",
              display: "flex",
            }}
          >
            <img
              style={{
                background: fundoIcPlano,
              }}
              src={`${settings.AUTENTICADOR}/cadastro/menu/buscar_icone_tela/${nroTela}/`}
              alt=""
            />
          </div>
        </div>
      </div>
      <label onClick={onClick}>{label}</label>
    </div>
  );
};

export const MenuGroupButton = connect(
  (store) => ({ menus: store.menus }),
  null,
  null,
  {
    context: ContextMenu,
  }
)(MenuGroupButtonInternal);
