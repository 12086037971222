import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  BotaoImprimir,
  ComboBox,
  FormCheckbox,
  ModalBase,
} from "../../../../../components";
import { docPrintReport } from "../../../../../pdf";

const ordenarPorOpts = [
  { label: "Emissão", value: "EMI" },
  { label: "Número", value: "NUM" },
];

const agruparPorOpts = [
  { label: "Não Agrupar", value: "NAO_AGR" },
  { label: "Modelo e Tipo de Documento", value: "MOD_TPD" },
];

const agruparPorUrlImpressao = {
  NAO_AGR: "nao_agrupado",
  MOD_TPD: "agrupado",
};

export const ImprimirNfSaidaGeralModal = ({
  buscarDados,
  dataIni,
  dataFim,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ordenarPor, setOrdenarPor] = useState(ordenarPorOpts[0].value);
  const [agruparPor, setAgruparPor] = useState(agruparPorOpts[0].value);
  const [incluirChaveAcesso, setIncluirChaveAcesso] = useState(false);
  const [incluirProdutos, setIncluirProdutos] = useState(false);
  const [incluirVlrIcms, setIncluirVlrIcms] = useState(false);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setOrdenarPor(ordenarPorOpts[0].value);
    setAgruparPor(agruparPorOpts[0].value);
    setIncluirChaveAcesso(false);
    setIncluirProdutos(false);
    setIncluirVlrIcms(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSetAgruparPor = (v) => {
    setAgruparPor(v);
    if (v === "MOD_TPD") {
      setIncluirProdutos(false);
    }
  };

  const imprimir = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados(agruparPor, ordenarPor);
    if (ok) {
      const payload = {
        ...ret,
        data_ini: dataIni,
        data_fim: dataFim,
        incluir_produtos: incluirProdutos,
        incluir_chave_acesso: incluirChaveAcesso,
        incluir_vlr_icms: incluirVlrIcms,
      };

      const agrupamento = agruparPorUrlImpressao[agruparPor];

      const ok2 = await docPrintReport(
        `/relatorios/nf_saida_geral/`+ agrupamento,
        payload,
        "5046",
        true
      );
      if (ok2) {
        toggle();
      }
    }
    setLoading(false);
  };

  return (
    <>
      <BotaoImprimir onClick={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Imprimir Relatório NF Saída Geral"
        number="5046_1"
        onClosed={limparDados}
        onConfirm={imprimir}
        loadingConfirm={loading}
      >
        <Row>
          <ComboBox
            label="Agrupar Por"
            options={agruparPorOpts}
            md={10}
            onChange={(s) => handleSetAgruparPor(s?.value)}
            defaultValue={agruparPor}
          />
        </Row>
        <Row>
          <ComboBox
            label="Ordenar Por"
            options={ordenarPorOpts}
            md={10}
            onChange={(s) => setOrdenarPor(s?.value)}
            defaultValue={ordenarPor}
          />
        </Row>
        <Row>
          <FormCheckbox
            padded={false}
            label="Incluir Chave de Acesso"
            checked={incluirChaveAcesso}
            onChange={() => setIncluirChaveAcesso(!incluirChaveAcesso)}
          />
          <FormCheckbox
            padded={false}
            label="Incluir Produtos"
            checked={incluirProdutos}
            onChange={() => setIncluirProdutos(!incluirProdutos)}
            disabled={agruparPor === "MOD_TPD"}
          />
          <FormCheckbox
            padded={false}
            label="Incluir Valor de ICMS"
            checked={incluirVlrIcms}
            onChange={() => setIncluirVlrIcms(!incluirVlrIcms)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
