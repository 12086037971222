import React, { useState } from "react";
import { FormCheckbox, ModalBase } from "../../../../../components";
import { Row } from "reactstrap";
import OrcamentosService from "../../../../../services/orcamentos/OrcamentosService";

export const ModoImprOrcamentoModal = ({ isOpen, toggle, idOrcamento }) => {
  const [detalharValoresItens, setDetalharValoresItens] = useState(false);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setDetalharValoresItens(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const extras = {
      detalhar_valores_itens: detalharValoresItens,
    };

    const ok = await OrcamentosService.imprimirFrenteVenda(
      idOrcamento,
      false,
      extras
    );
    if (ok) {
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Impressão de Orçamento"
      number="0030_11"
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loading={loading}
    >
      <Row>
        <FormCheckbox
          padded={false}
          label="Detalhar Valores dos Itens"
          checked={detalharValoresItens}
          onChange={() => setDetalharValoresItens(!detalharValoresItens)}
        />
      </Row>
    </ModalBase>
  );
};
