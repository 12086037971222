import React, { useState } from "react";
import { Edit } from "react-feather";
import { Row } from "reactstrap";
import { NumberInput } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import CadastroListaPrecosService from "../../../../../services/cadastro/CadastroListaPrecosService";
import { RadioGroup, RadioItem } from "../../../../../components/RadioGroup";

export const AlterarItemListaDe = ({
  dadosItem,
  notifyEvent,
  tipoListaPara,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [precoVenda, setPrecoVenda] = useState(0);
  const [natureza, setNatureza] = useState(0);
  const [percDescAcre, setPercDescAcre] = useState(0);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    if (!isOpen) {
      setPrecoVenda(parseFloat(dadosItem["preco_venda"]));
      setPercDescAcre(parseFloat(dadosItem["perc_desc_acre"]));
      setNatureza(dadosItem["natureza"]);
    }
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    let data = {
      id_registro: dadosItem["id"],
      natureza: natureza,
      perc_desc_acre: percDescAcre,
      preco_venda: precoVenda,
    };
    setLoading(true);
    try {
      const [ok] = await CadastroListaPrecosService.de.alterar(data);
      if (ok) {
        toggle();
        notifyEvent();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Edit onClick={toggle} cursor="pointer" size={18} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Alteração do Item"
        number="0013_5"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row form className="ml-2">
          {dadosItem["de"] !== "PRODUTO" || tipoListaPara !== "CLIENTE" ? (
            <>
              <RadioGroup
                label="Natureza"
                value={natureza}
                onChange={setNatureza}
              >
                <RadioItem label="Desconto" value="DESCONTO" />
                <RadioItem label="Acréscimo" value="ACRESCIMO" />
              </RadioGroup>
              <NumberInput
                label="% de Desc/Acre"
                md={3}
                onChange={setPercDescAcre}
                value={percDescAcre}
                isPercentage
              />
            </>
          ) : (
            <NumberInput
              label="Preço de Venda"
              md={3}
              onChange={setPrecoVenda}
              value={precoVenda}
            />
          )}
        </Row>
      </ModalBase>
    </React.Fragment>
  );
};
