import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import { PageContainer } from "../../../../components/PageContainer";
import { DiaAbertoGrid } from "./components/DiaAbertoGrid";
import { DiasGrid } from "./components/DiasGrid";
import { AsyncComboBox, BotaoPesquisar, Divider } from "../../../../components";
import ConferenciaValoresJornadaService from "../../../../services/frente_venda_mobile/ConferenciaValoresJornadaService";
import { DetalhesDiaModal } from "./components/DetalhesDiaModal";
import { DiasAnterioresGrid } from "./components/DiasAnterioresGrid";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store";
import { setIdRota } from "./store/filtrosConferenciaSlice";
import ConfirmarReaberturaConferenciaModal from "./components/ConfirmarReaberturaConferenciaModal";

const ConferenciaValoresJornadaContainer = () => {
  const store = useSelector((state) => state.filtrosConferencia);
  const dispatch = useDispatch();
  const [dias, setDias] = useState([]);
  const [diaAberto, setDiaAberto] = useState([]);
  const [diasAnteriores, setDiasAnteriores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idDia, setIdDia] = useState(null);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [
    confirmacaoReabrirConferenciaIsOpen,
    setConfirmacaoReabrirConferenciaIsOpen,
  ] = useState(false);

  const limparDados = () => {
    setDias([]);
    setDiaAberto([]);
    setDiasAnteriores([]);
  };

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const mostrarDetalhes = (id) => {
    setIdDia(id);
    toggleDetalhes();
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await ConferenciaValoresJornadaService.listar(
      store.idRota
    );
    if (ok) {
      setDias(ret.dias);
      setDiaAberto(ret.dia_aberto);
      setDiasAnteriores(ret.dias_anteriores);
    } else {
      limparDados();
    }
    setLoading(false);
  };

  const toggleConfirmacaoReabrirConferencia = () =>
    setConfirmacaoReabrirConferenciaIsOpen(
      !confirmacaoReabrirConferenciaIsOpen
    );

  const reabrirConferencia = (idDiaReabrir) => {
    setIdDia(idDiaReabrir);
    toggleConfirmacaoReabrirConferencia();
  };

  useEffect(() => {
    if (![0, null, undefined].includes(store.idRota)) {
      carregarDados();
    }
  }, []);

  return (
    <>
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Rota"
            isConcatField
            concatModelName="rota_mob"
            defaultOptions
            isSearchable
            isClearable
            defaultValue={store.idRota}
            onChange={(s) => {
              if (s?.value !== store.idRota) {
                limparDados();
              }
              dispatch(setIdRota(s?.value));
            }}
          />
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            disabled={[0, null, undefined].includes(store.idRota)}
            disabledHint="Selecione uma rota"
          />
          <DetalhesDiaModal
            isOpen={detalhesOpen}
            toggle={toggleDetalhes}
            idJornada={idDia}
          />
          <ConfirmarReaberturaConferenciaModal
            isOpen={confirmacaoReabrirConferenciaIsOpen}
            toggle={toggleConfirmacaoReabrirConferencia}
            idJornada={idDia}
            notifyEvent={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <DiasGrid
          dados={dias}
          mostrarDetalhes={mostrarDetalhes}
          reabrirConferencia={reabrirConferencia}
        />
      </Card>
      <Card body>
        <Divider>Dia Aberto no Momento</Divider>
        <DiaAbertoGrid dados={diaAberto} />
      </Card>
      <Card body>
        <Divider>Dias Anteriores</Divider>
        <DiasAnterioresGrid
          dados={diasAnteriores}
          mostrarDetalhes={mostrarDetalhes}
          reabrirConferencia={reabrirConferencia}
        />
      </Card>
    </>
  );
};

export const ConferenciaValoresJornada = () => {
  return (
    <PageContainer
      title="Conferência de Valores da Jornada"
      number="0025"
      canGoBack
    >
      <Provider store={store}>
        <ConferenciaValoresJornadaContainer />
      </Provider>
    </PageContainer>
  );
};
