import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forma_impressao: "PER",
  layout_impr: 1,
  nome_impressora: null,
  msg_corresp_a: null,
};

export const reciboSlice = createSlice({
  name: "recibo_slice",
  initialState: initialState,
  reducers: {
    setupRecibo: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setFormaImpressao: (state, action) => {
      state.forma_impressao = action.payload;
    },
    setLayoutImpr: (state, action) => {
      state.layout_impr = action.payload;
    },
    setNomeImpressora: (state, action) => {
      state.nome_impressora = action.payload;
    },
    setMsgCorrespA: (state, action) => {
      state.msg_corresp_a = action.payload;
    },
  },
});

export const {
  setupRecibo,
  setFormaImpressao,
  setLayoutImpr,
  setNomeImpressora,
  setMsgCorrespA,
} = reciboSlice.actions;

export default reciboSlice.reducer;
