import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { MdLocalShipping, MdOutlineFormatAlignCenter } from "react-icons/md";
import {
  centralPedInternoRoute,
  pedIntPendentesEntregaRoute,
} from "../../../routes/modules/pedidos";

export const PedidosInternos = () => {
  return (
    <PageContainer title="Pedidos Internos">
      <MenuGroup>
        <MenuGroupButton
          label="Central de Pedidos Internos"
          icon={MdOutlineFormatAlignCenter}
          pathname={centralPedInternoRoute.path}
          nroTela={centralPedInternoRoute.nroTela}
        />
        <MenuGroupButton
          label="Pedidos Internos Pendentes de Entrega"
          icon={MdLocalShipping}
          pathname={pedIntPendentesEntregaRoute.path}
          nroTela={pedIntPendentesEntregaRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
