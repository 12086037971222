import React, { useState } from "react";
import { BotaoPesquisar, PageContainer } from "../../../../components";
import { Card, Row } from "reactstrap";
import { AlterarDuracaoModal } from "./components/AlterarDuracaoModal";
import { NovaDuracaoModal } from "./components/NovaDuracaoModal";
import { EncerrarDuracaoModal } from "./components/EncerrarDuracaoModal";
import DuracoesJornadaService from "../../../../services/jornada/duracoes/DuracoesJornadaService";
import { DuracaoJornadaGrid } from "./components/DuracaoJornadaGrid";
import moment from "moment";

export const DuracaoJornada = () => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [dataIniSelec, setDataIniSelec] = useState(null);
  const [dataFimSelec, setDataFimSelec] = useState(null);
  const [nomeRotaSelec, setNomeRotaSelec] = useState("");
  const [loading, setLoading] = useState(false);

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await DuracoesJornadaService.listar();
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const handleSelect = (id, is, row) => {
    setSelected(id);
    setDataIniSelec(
      is ? moment(row.data_abertura, "DD/MM/YYYY").toDate() : null
    );
    setDataFimSelec(
      is ? moment(row.data_encerramento, "DD/MM/YYYY").toDate() : null
    );
    setNomeRotaSelec(is ? row.id_rota_mob__nome : "");
  };

  return (
    <PageContainer title="Duração de Jornadas" number="0023" canGoBack>
      <Card body>
        <Row className="mb-2">
          <BotaoPesquisar
            loading={loading}
            onClick={carregarDados}
            padded={false}
          />
          <NovaDuracaoModal notifyEvent={carregarDados} />
          <AlterarDuracaoModal
            selected={selected}
            dataIniSelec={dataIniSelec}
            dataFimSelec={dataFimSelec}
            nomeRota={nomeRotaSelec}
            notifyEvent={() => {
              carregarDados();
              setSelected(null);
            }}
          />
          <EncerrarDuracaoModal
            selected={selected}
            nomeRota={nomeRotaSelec}
            notifyEncerrar={() => {
              carregarDados();
              setSelected(null);
            }}
          />
        </Row>
        <DuracaoJornadaGrid data={dados} handleSelect={handleSelect} />
      </Card>
    </PageContainer>
  );
};
