import React from "react";
import { Card } from "reactstrap";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { mockData } from "./mockData";
import { Table } from "../../../../../components";
import AcoesGridProgramacaoExecucaoProjeto from "./AcoesGridProgramacaoExecucaoProjeto";
import AcoesGridValidarProgramacaoExecucaoProjeto from "./AcoesGridValidarProgramacaoExecucaoProjeto";
import AcoesGridSeparacaoAlmoxarifadoProjeto from "./AcoesGridSeparacaoAlmoxarifadoProjeto";

const coresStatus = {
  LCP: "#8332a8",
  VLP: "#32a871",
  AMX: "#ced428",
};

const CentralProjetosGrid = () => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      dataField: "id_cliente",
      text: "Cliente",
      headerAlign: "left",
      align: "left",
      sortable: true,
      formatter: (_, row) =>
        formatValueFromAPI(row.nome_cliente, row.id_cliente),
    },
    {
      dataField: "endereco_cliente",
      text: "Endereço",
      headerAlign: "left",
      align: "left",
    },
    {
      dataField: "data_abertura",
      text: "Abertura",
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      dataField: "desc_status",
      text: "Status",
      headerAlign: "center",
      align: "center",
      sortable: true,
      formatter: (c, row) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "11.5px",
              height: "11.5px",
              borderRadius: "50%",
              marginLeft: 0,
              marginRight: "0.4rem",
              backgroundColor: coresStatus[row.status],
            }}
          />
          {c}
        </span>
      ),
    },
    {
      dataField: "vlr_orcado",
      text: "Valor Orçado",
      headerAlign: "right",
      align: "right",
      sortable: true,
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "acoes",
      text: "Ações",
      headerAlign: "center",
      align: "center",
      sortable: false,
      formatter: (_, row) => {
        if (row.status === "LCP") {
          return <AcoesGridProgramacaoExecucaoProjeto row={row} />;
        } else if (row.status === "VLP") {
          return <AcoesGridValidarProgramacaoExecucaoProjeto row={row} />;
        } else if (row.status === "AMX") {
          return <AcoesGridSeparacaoAlmoxarifadoProjeto row={row} />;
        }

        return <></>;
      },
    },
  ];

  return (
    <Card body>
      <Table
        columns={columns}
        data={mockData}
        paginated={false}
        pageSize={10}
        // onSelect={onSelect}
        // clearSelectionOnUpdate
        // onRowDoubleClick={alterar}
      />
    </Card>
  );
};

export default CentralProjetosGrid;
