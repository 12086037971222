import React, { useState, useRef } from "react";
import {
  AsyncComboBox,
  FixedField,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  ModalBase,
  NumberInput,
  Table,
} from "../../../../../components";
import { v4 as uuidv4 } from "uuid";
import { Row, Col } from "reactstrap";
import { apiGet } from "../../../../../api";
import { useDispatch } from "react-redux";
import { addRecebCartao } from "../store/moedas_rec_slice";
import { toastr } from "react-redux-toastr";
import { formatNumber, sumDataField } from "../../../../../coreUtils";

const rowStyle = { marginBottom: "0.5rem" };

export const RecCartaoMultiplosCartoesModal = ({
  vlrCartao,
  isOpen,
  toggle,
}) => {
  const [descMeioPagto, setDescMeioPagto] = useState("");
  const [idMeioPagto, setIdMeioPagto] = useState(null);
  const meioPagtoRef = useRef();
  const [meioPagtoTemTef, setMeioPagtoTemTef] = useState(false);
  const [idAdm, setIdAdm] = useState(0);
  const [descAdm, setDescAdm] = useState("");
  const admRef = useRef();
  const [qtdParcelas, setQtdParcelas] = useState(0);
  const [valor, setValor] = useState(0);
  const [cartaoDebito, setCartaoDebito] = useState(false);
  const [cartoes, setCartoes] = useState([]);
  const [uuidSelected, setUuidSelected] = useState(null);

  const [temTEF, setTemTEF] = useState(false);

  const vlrLancado = sumDataField(cartoes, "valor");

  const dispatch = useDispatch();

  const limparDados = () => {
    setDescMeioPagto("");
    setIdMeioPagto(null);
    setIdAdm(0);
    setDescAdm("");
    setQtdParcelas(0);
    setCartaoDebito(false);
    setValor(0);
    if (meioPagtoRef.current) {
      meioPagtoRef.current.clearValue();
    }
    if (admRef.current) {
      admRef.current.clearValue();
    }
  };

  const carregarParametros = async () => {
    const ret = await apiGet("/tela/caixa_loja/receb_cartao/", {});

    setTemTEF(ret?.tem_tef ?? false);
    if (ret?.tem_tef === true) {
      setIdMeioPagto(ret?.id_meio_pagto_pad_tef);
      setMeioPagtoTemTef(ret?.meio_pagto_tem_tef);
    }
  };

  const handleSetCartaoDebito = (v) => {
    if (v) {
      setQtdParcelas(1);
    } else {
      setQtdParcelas(0);
    }
    setCartaoDebito(v);
  };

  const onConfirm = () => {
    const payloadCartoes = cartoes.map((cartao) => ({
      id_meio_pagto: cartao.id_meio_pagto,
      id_adm: cartao.id_adm,
      qtd_parcelas: cartao.qtd_parcelas,
      valor: parseFloat(cartao.valor),
      tipo: cartao.tipo,
    }));

    dispatch(addRecebCartao({ valor: vlrCartao, dados_rec: payloadCartoes }));
    toggle();
  };

  const incluirCartao = () => {
    if ([0, null, undefined].includes(qtdParcelas)) {
      toastr.warning("Atenção", "Por favor, informe a quantidade de Parcelas");
      return false;
    }
    if ([0, null, undefined].includes(idMeioPagto)) {
      toastr.warning("Atenção", "Por favor, informe o meio de pagamento");
      return false;
    }
    if (!temTEF && [0, null, undefined].includes(idAdm)) {
      toastr.warning("Atenção", "Por favor, informe a administradora");
      return false;
    }
    if ([0, null, undefined].includes(valor)) {
      toastr.warning("Atenção", "Por favor, informe o valor");
      return false;
    }

    const cartoesAtuais = [...cartoes];
    cartoesAtuais.push({
      uuid: uuidv4(),
      desc_meio_pagto: descMeioPagto,
      id_meio_pagto: idMeioPagto,
      desc_adm: descAdm,
      id_adm: idAdm,
      qtd_parcelas: qtdParcelas,
      valor: valor,
      tipo: cartaoDebito ? "D" : "C",
    });
    setCartoes(cartoesAtuais);
    limparDados();
  };

  const excluirCartao = () => {
    if (!uuidSelected) {
      toastr.warning(
        "Selecite o cartão",
        "Por favor, selecione o cartão que você deseja remover"
      );
    }
    let cartoesAtuais = [...cartoes];
    cartoesAtuais = cartoesAtuais.filter(
      (cartao) => cartao.uuid !== uuidSelected
    );
    setCartoes(cartoesAtuais);
  };

  const columns = [
    {
      dataField: "desc_meio_pagto",
      text: "Meio Pag.",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "desc_adm",
      text: "Bandeira",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "qtd_parcelas",
      text: "Parcelas",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "tipo",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
      formatter: (c) => (c === "D" ? "Débito" : "Crédito"),
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Recebimento em Cartão"
      number="0063_11"
      onBeforeOpen={carregarParametros}
      onConfirm={onConfirm}
      onClosed={limparDados}
    >
      <Row style={{ ...rowStyle, marginBottom: "0rem" }}>
        <AsyncComboBox
          md={6}
          label="Meio de Pagamento"
          isConcatField
          concatModelName="meio_pagamento"
          defaultOptions
          isSearchable
          onChange={(s) => {
            setIdMeioPagto(s?.value);
            setMeioPagtoTemTef(s?.tem_tef);
            setDescMeioPagto(s?.label);
          }}
          defaultValue={idMeioPagto}
          ref={meioPagtoRef}
        />
        {(!temTEF || !meioPagtoTemTef) && (
          <AsyncComboBox
            md={6}
            label="Bandeira do Cartão"
            isConcatField
            concatModelName="administradora_cartao"
            defaultOptions
            isSearchable
            onChange={(s) => {
              setIdAdm(s?.value);
              setDescAdm(s?.label);
            }}
            defaultValue={idAdm}
            ref={admRef}
          />
        )}
      </Row>
      {!meioPagtoTemTef && (
        <Row style={rowStyle}>
          <Col md={12}>
            <span className="text-sm">Meio de pagamento não utiliza TEF</span>
          </Col>
        </Row>
      )}
      <Row style={rowStyle}>
        <NumberInput
          md={4}
          value={valor}
          label="Valor Total do Cartão"
          onChange={setValor}
        />
        <FormCheckbox
          label="Cartão de Débito"
          checked={cartaoDebito}
          onChange={() => {
            handleSetCartaoDebito(!cartaoDebito);
          }}
          name="cartao-debito"
        />
        <IntegerFormInput
          md={4}
          label="Parcelas"
          defaultValue={qtdParcelas}
          onChange={setQtdParcelas}
          disabled={cartaoDebito}
        />
        <label style={{ marginTop: "1.5rem", fontSize: "1rem" }}>X</label>
      </Row>
      <Row style={rowStyle}>
        <FormButton color="info" onClick={incluirCartao} padded={false}>
          Incluir
        </FormButton>
        <FormButton color="danger" onClick={excluirCartao} padded={false}>
          Excluir
        </FormButton>
      </Row>
      <Table
        data={cartoes}
        columns={columns}
        paginated={false}
        pageSize={10}
        keyField="uuid"
        onSelect={setUuidSelected}
        showRegisterCount={false}
      />
      <Row
        style={{
          justifyContent: "space-between",
        }}
      >
        <FixedField
          label="Valor a Cobrar"
          value={formatNumber(vlrCartao, true, 2)}
          horizontal
        />
        <FixedField
          label="Valor a Lançar"
          value={formatNumber(Math.max(vlrCartao - vlrLancado, 0), true, 2)}
          horizontal
        />
        <FixedField
          label="Valor Lançado"
          value={formatNumber(vlrLancado, true, 2)}
          horizontal
        />
      </Row>
    </ModalBase>
  );
};
