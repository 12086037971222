import React from "react";
import { useState, useRef } from "react";
import {
  MODAL_ACTIONS,
  formatDateISO,
  roundFloat,
  useStateWithRef,
} from "../../../../../coreUtils";
import moment from "moment";
import { CadastroMetaForm } from "./CadastroMetaForm";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleMetaVenda, routesBaseMetaVenda } from "../MetaVenda";
import { DetalhesMetaVendaGrid } from "./DetalhesMetaVendaGrid";
import { showWarning } from "../../../../../components/AlertaModal";
import { IncluirDetalheMetaForm } from "./IncluirDetalheMetaForm";
import { Divider } from "../../../../../components";
import { v4 as uuidv4 } from "uuid";

export const CadastroMetaVendaModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [metaPor, setMetaPor] = useState("ROTA");
  const [idMetaPor, setIdMetaPor] = useState(null);
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [tipoInc, setTipoInc] = useState(null);
  const [dados, setDados, dadosRef] = useStateWithRef([]);
  const [pista, setPista] = useState("");
  const incluirDetalheRef = useRef();

  const limparDados = () => {
    setNome("");
    setMetaPor("ROTA");
    setIdMetaPor(0);
    setMes(new Date().getMonth() + 1);
    setAno(new Date().getFullYear());
    setTipoInc(null);
    setDados([]);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setMetaPor(data.meta_por);
    setIdMetaPor(data.id_meta_por);
    setMes(moment(data.data_ini, "DD/MM/YYYY").month() + 1);
    setAno(moment(data.data_ini, "DD/MM/YYYY").year());
    setTipoInc(data.tipo_inc);

    setDados(
      data.detalhes.map((e) => ({
        ...e,
        meta_qtd_venda: parseFloat(e.meta_qtd_venda),
        meta_vlr_faturado: parseFloat(e.meta_vlr_faturado),
        meta_perc_max_devol_troca: parseFloat(e.meta_perc_max_devol_troca),
        uuid: uuidv4(),
      }))
    );
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(nome)) {
      showWarning("Por favor, informe o Nome da Meta");
      return;
    }

    if (!metaPor) {
      showWarning("Por favor, selecione Tipo da Meta");
      return false;
    }

    if (!idMetaPor) {
      if (metaPor === "ROTA") {
        showWarning("Por favor, selecione a Rota da Meta");
      }
      return false;
    }

    const payload = {
      nome: nome,
      meta_por: metaPor,
      id_meta_por: idMetaPor,
      data_inicial: formatDateISO(new Date(ano, mes - 1, 1)),
      data_final: formatDateISO(new Date(ano, mes, 0)),
      movs: dados.map((e) => ({
        tipo: e.desc_tipo === "Produto" ? "PRODUTO" : "GRUPO_PROD", // Fixo por ora
        id_tipo: e.id_tipo,
        m_qtd_vda: e.meta_qtd_venda,
        m_vlr_faturado: e.meta_vlr_faturado,
        m_perc_max_dev_troca: e.meta_perc_max_devol_troca,
        perc_info_base_venda: action === MODAL_ACTIONS.ADD ? 0 : undefined,
      })),
    };

    return action === MODAL_ACTIONS.ADD
      ? { tipo_inc: "MAN", ...payload }
      : { id: selected, ...payload };
  };

  const handlePista = (v) => setPista(v);

  const handleAddMov = async (data) => {
    const payload = {
      ...data,
      uuid: uuidv4(),
    };

    setDados([...dadosRef.current, payload]);

    incluirDetalheRef.current.limparDados();
  };

  const handleDeleteMov = (id) => {
    setDados(dadosRef.current.filter((e) => e.id !== id));
  };

  const handleEditDetalhe = async (coluna, novoValor, row) => {
    const valorAnterior = parseFloat(row[coluna]);

    if (coluna === "meta_qtd_venda") {
      row.meta_qtd_venda = parseInt(novoValor);
      row.preco_tipo = parseFloat(row.preco_tipo);

      row.meta_vlr_faturado = roundFloat(
        row.meta_qtd_venda * row.preco_tipo,
        2
      );
    }

    if (coluna === "meta_perc_max_devol_troca") {
      row.meta_perc_max_devol_troca = parseFloat(novoValor);
    }

    if (parseFloat(valorAnterior) !== parseFloat(novoValor)) {
      setDados(
        dados.map((e) => {
          if (e.uuid === row.uuid) {
            return { ...row };
          } else {
            return e;
          }
        })
      );
    }
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleMetaVenda}
      size="xl"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseMetaVenda}
      number="0056_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <CadastroMetaForm
        nome={nome}
        setNome={setNome}
        metaPor={metaPor}
        setMetaPor={setMetaPor}
        idMetaPor={idMetaPor}
        setIdMetaPor={setIdMetaPor}
        mes={mes}
        setMes={setMes}
        ano={ano}
        setAno={setAno}
      />
      <Divider>Produtos da Meta</Divider>
      <IncluirDetalheMetaForm
        ref={incluirDetalheRef}
        movs={dados}
        handleAdd={handleAddMov}
      />
      <DetalhesMetaVendaGrid
        dados={
          !["", null, undefined].includes(pista?.trim())
            ? dados.filter((e) => e.nome_tipo.includes(pista))
            : dados
        }
        handleDelete={handleDeleteMov}
        handleEditDetalhe={handleEditDetalhe}
        pista={pista}
        handlePista={handlePista}
        mostrarPercInfoBase={tipoInc === "BVD"}
      />
    </ModalCadastroV2>
  );
};
