import React from "react";
import FormButton from "../FormButton";
import { MODAL_ACTIONS } from "../../coreUtils";

export const BotaoIncluir = ({
  padded,
  divClassName,
  toggle,
  text = "Incluir",
}) => {
  return (
    <FormButton
      padded={padded}
      divClassName={divClassName}
      md="auto"
      color="info"
      onClick={() => toggle(MODAL_ACTIONS.ADD)}
    >
      {text}
    </FormButton>
  );
};
