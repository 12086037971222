import React from "react";
import { Card } from "reactstrap";
import { Table } from "../../../../../components";

export const RendimentoTecnicoGrid = ({ dados, origemDados }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Técnico",
      align: "left",
      sortable: true,
    },
    {
      dataField: "horas_vend",
      text: "Horas Vendidas",
      align: "center",
      sortable: true,
      hidden: origemDados !== "MANU",
    },
    {
      dataField: "horas_exec",
      text: "Execução",
      align: "center",
      sortable: true,
      hidden: origemDados !== "EXEC",
    },
    {
      dataField: "horas_check",
      text: "Checklist",
      align: "center",
      sortable: true,
      hidden: origemDados !== "EXEC",
    },
    {
      dataField: "total_trab",
      text: "Total Trabalhado",
      align: "center",
      sortable: true,
      hidden: origemDados !== "EXEC",
    },
    {
      dataField: "horas_ocioso",
      text: "Ocioso",
      align: "center",
      sortable: true,
      hidden: origemDados !== "EXEC",
    },
    {
      dataField: "horas_pausa",
      text: "Pausa",
      align: "center",
      sortable: true,
      hidden: origemDados !== "EXEC",
    },
  ];

  return (
    <Card body>
      <Table columns={columns} data={dados} />
    </Card>
  );
};
