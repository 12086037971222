import { apiGet, apiPut } from "../../api";

const URL_BASE = "/venda/flex";
const FlexRepresentExtService = {
  listar: async () => await apiGet(`${URL_BASE}/listar/`),
  lancamentoAdm: async (payload) =>
    await apiPut(`${URL_BASE}/lancamento_adm/`, payload),
};

export default FlexRepresentExtService;
