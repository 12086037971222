import React, { useState } from "react";
import imagem1 from "../../../../assets/img/layouts_os_emi/1.png";
import imagem2 from "../../../../assets/img/layouts_os_emi/2.png";
import imagem4 from "../../../../assets/img/layouts_os_emi/4.png";
import imagem5 from "../../../../assets/img/layouts_os_emi/5.png";
import imagem6 from "../../../../assets/img/layouts_os_emi/6.png";
import imagem7 from "../../../../assets/img/layouts_os_emi/7.png";
import imagem8 from "../../../../assets/img/layouts_os_emi/8.png";
import imagem9 from "../../../../assets/img/layouts_os_emi/9.png";
import ImageViewer from "react-simple-image-viewer";
import { Divider } from "../../../../components/Divider";

const layouts = [
  {
    id: 1,
    imagem: imagem1,
    descricao: (
      <>
        <ul>
          <li>Impressão padrão</li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    imagem: imagem2,
    descricao: (
      <>
        <ul>
          <li>Impressão padrão</li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    imagem: imagem4,
    descricao: (
      <>
        <ul>
          <li>Metade da Folha</li>
        </ul>
      </>
    ),
  },
  {
    id: 5,
    imagem: imagem5,
    descricao: (
      <>
        <ul>
          <li>Informações do Cliente</li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    imagem: imagem6,
    descricao: (
      <>
        <ul>
          <li>Código de Barras</li>
        </ul>
      </>
    ),
  },
  {
    id: 7,
    imagem: imagem7,
    descricao: (
      <>
        <ul>
          <li>2 Vias</li>
          <li>Nota de Recebimento</li>
        </ul>
      </>
    ),
  },
  {
    id: 8,
    imagem: imagem8,
    descricao: (
      <>
        <ul>
          <li>Impressão reduzida, reaproveitamento de folha</li>
        </ul>
      </>
    ),
  },
  {
    id: 9,
    imagem: imagem9,
    descricao: (
      <>
        <ul>
          <li>Ordem de Serviço Automotiva</li>
          <li>Grades para preenchimento</li>
        </ul>
      </>
    ),
  },
];

const PrintDisplay = ({ layoutsList, openImageViewer }) => (
  <div className="print-display">
    {layoutsList.map((layout) => (
      <div className="print-item">
        <img
          src={layout.imagem}
          className="print-thumb"
          onClick={() => openImageViewer(layout.id)}
          alt=""
        />
        <div style={{ height: "min-content", marginLeft: "0.75rem" }}>
          <h5 className="mb-1">Layout {layout.id}</h5>
          <span>{layout.descricao}</span>
        </div>
      </div>
    ))}
  </div>
);

export const ImpressaoEmiOS = () => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [idImagem, setIndexImagem] = useState(0);

  const openImageViewer = (index) => {
    setIndexImagem(index);
    setIsViewerOpen(true);
  };

  const onCloseImageViewer = () => {
    setIndexImagem(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Divider className="mt-2">Folha A4</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) =>
          [1, 5, 6, 7, 8, 9].includes(item.id)
        )}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Matricial - Folha A4</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [2].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      {isViewerOpen && (
        <ImageViewer
          src={[layouts.find((layout) => layout.id === idImagem)?.imagem]}
          currentIndex={0}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={onCloseImageViewer}
          backgroundStyle={{ backgroundColor: "#00000085" }}
        />
      )}
    </>
  );
};
