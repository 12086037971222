import React from "react";
import { Card } from "reactstrap";
import { Table, TableCheck } from "../../../../../components";
import { naturalSort } from "../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const FornecedorGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      sortable: true,
      formatter: (c, row) => (
        <>
          {c}
          {row.simples_nacional && (
            <span
              style={{
                marginLeft: "0.5rem",
                background: "rgb(67, 201, 144)",
                paddingInline: "0.5rem",
                borderRadius: "0.3rem",
                color: "white",
              }}
            >
              Simples Nacional
            </span>
          )}
        </>
      ),
    },
    {
      dataField: "cpf_cnpj",
      text: "CPF/CNPJ",
      align: "center",
    },
    {
      dataField: "id_cidade__nome",
      text: "Cidade",
      align: "left",
    },
    {
      dataField: "fone1",
      text: "Fone",
      align: "center",
    },
    {
      dataField: "email",
      text: "E-Mail",
      align: "left",
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Card body>
      <Table
        columns={columns}
        data={data}
        onSelect={setSelected}
        onRowDoubleClick={alterar}
      />
    </Card>
  );
};
