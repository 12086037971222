import React from "react";
import { Card, UncontrolledTooltip } from "reactstrap";
import { TableCheck } from "../../../../../components/TableCheck";
import { IconButton, Table } from "../../../../../components";
import { naturalSort } from "../../../../../coreUtils";
import { Edit } from "react-feather";

export const FormaRecClienteGrid = ({ dadosGrid, alterarFormasRecCliente }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome",
      text: "Razão Social",
      align: "left",
      sortable: true,
    },
    {
      dataField: "nome_fant",
      text: "Nome Fantasia",
      align: "left",
      sortable: true,
    },
    {
      dataField: "formas_rec",
      text: "Formas de Recebimento",
      align: "left",
      formatter: (c) =>
        c.map((e) => (
          <span
            style={{
              backgroundColor: "#dbdbdb",
              paddingInline: "0.3rem",
              marginRight: "0.3rem",
              borderRadius: "0.2rem",
            }}
          >
            {e}
          </span>
        )),
    },
    {
      dataField: "status",
      text: "Ativo",
      align: "center",
      sortable: true,
      formatter: (val) => <TableCheck value={val} />,
    },
    {
      dataField: "emite_nfe",
      text: (
        <>
          <span id="em_nfe">NFe</span>
          <UncontrolledTooltip target="em_nfe">
            Cliente emite NFe
          </UncontrolledTooltip>
        </>
      ),
      align: "center",
      sortable: true,
      formatter: (val) => <TableCheck value={val} />,
    },
    {
      dataField: "act_edit",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <IconButton
          icon={Edit}
          onClick={() => alterarFormasRecCliente(row.id, row.nome)}
        />
      ),
    },
  ];
  return (
    <Card body>
      <Table
        columns={columns}
        data={dadosGrid}
        paginated={false}
        onRowDoubleClick={(id, row) => alterarFormasRecCliente(id, row.nome)}
      />
    </Card>
  );
};
