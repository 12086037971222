import React from "react";
import { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import {
  cadastroCartoesRoutes,
  cadastroClientesRoutes,
  cadastroFinanceiroRoutes,
  cadastroOutrosRoutes,
  cadastroProdutosRoutes,
  cadastroTributacaoRoutes,
  cadastroServicosRoutes,
  incluirAlterarClienteRoute,
  incluirAlterarProdutoRoute,
} from "../routes/modules/cadastro";
import { MODAL_ACTIONS } from "../coreUtils";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import classNames from "classnames";
import { cadastroMDFeRoutes } from "../routes/modules/docsEletron";
import { connect } from "react-redux";
import { ContextMenu } from "../redux/reducers/menuReducer";

const search = (tree, concatModelName) => {
  if (tree.concatModelName === concatModelName) {
    return tree;
  } else if (!tree.children) {
    return null;
  }

  for (const child of tree.children) {
    const found = search(child, concatModelName);

    if (found) {
      return found;
    }
  }
};

const ConcatShortcutInternal = forwardRef(
  (
    { concatModelName, selected, setSelected, onClick, className, menus },
    ref
  ) => {
    const [internalId] = useState(
      "cs-" + Math.floor(Math.random() * Date.now())
    );

    const searchScope = {
      children: [
        ...cadastroFinanceiroRoutes,
        ...cadastroOutrosRoutes,
        ...cadastroProdutosRoutes,
        ...cadastroClientesRoutes,
        ...cadastroCartoesRoutes,
        ...cadastroTributacaoRoutes,
        ...cadastroServicosRoutes,
        ...cadastroMDFeRoutes,
        incluirAlterarClienteRoute,
        incluirAlterarProdutoRoute,
      ],
    };
    const [shortcut] = useState(search(searchScope, concatModelName));

    const menu = shortcut
      ? (menus ?? []).find((e) => e.nroTela === shortcut.nroTela)
      : undefined;
    const liberado = menu !== undefined;

    const onClickRedirect = () => {
      const id = Math.floor(Math.random() * Date.now());
      const cadastro = window.open(shortcut.path);

      cadastro.window.parameters = JSON.stringify({
        id: id,
        action: ![0, null, undefined, ""].includes(selected)
          ? MODAL_ACTIONS.EDIT
          : MODAL_ACTIONS.ADD,
        selected: selected,
      });

      const func = (event) => {
        if (event.origin !== window.location.origin && !event.data?.selected) {
          return;
        }

        if (event.data.id !== id) {
          return;
        }

        if (setSelected) {
          setSelected(event.data.selected);
        }

        window.removeEventListener("message", func);
      };

      window.addEventListener("message", func);
    };

    const onClickInternal = (e) => {
      if (!liberado) {
        return;
      }
      if (onClick) {
        onClick(e);
      } else {
        onClickRedirect();
      }
    };

    useImperativeHandle(ref, () => ({
      click: () => document.getElementById(internalId).click(),
    }));

    return concatModelName && shortcut ? (
      <>
        <div
          id={internalId}
          className={classNames(
            "react-select-shortcut",
            !liberado && "disabled",
            className
          )}
          onClick={onClickInternal}
        >
          <shortcut.shortcutIcon size={20} color="white" />
        </div>
        <UncontrolledTooltip target={internalId}>
          {liberado ? (
            <>F4 - Clique para acessar o Cadastro de {shortcut.name}</>
          ) : (
            <>
              Seu perfil ou plano não permite acesso ao Cadastro de{" "}
              {shortcut.name}
            </>
          )}
        </UncontrolledTooltip>
      </>
    ) : (
      <></>
    );
  }
);

export const ConcatShortcut = connect(
  (store) => ({ menus: store.menus }),
  null,
  null,
  {
    context: ContextMenu,
  }
)(ConcatShortcutInternal);
