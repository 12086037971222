import React from "react";
import { Provider } from "react-redux";
import { PageContainer } from "../../../../components/PageContainer";
import { CardFiltros } from "./components/CardFiltros";
import { GridVendasAgrupadasPorProd } from "./components/GridVendasAgrupadasPorProd";
import store from "./store";

export const gerarDadosTotaisGrupos = (obj) =>
  Object.entries(obj).map(([name, values]) => ({
    nome_grupo: name,
    quant_sem_serie: values.sem_serie.quant,
    vlr_sem_serie: values.sem_serie.vlr_prod,
    quant_com_serie: values.com_serie.quant,
    vlr_com_serie: values.com_serie.vlr_prod,
    quant_total: values.total.quant,
    vlr_total: values.total.vlr_prod,
  }));

export const VendasAgrupadasPorProduto = () => {
  return (
    <PageContainer title="Vendas Agrupadas por Produto" number="5015" canGoBack>
      <Provider store={store}>
        <CardFiltros />
        <GridVendasAgrupadasPorProd />
      </Provider>
    </PageContainer>
  );
};
