import React from "react";
import { useState } from "react";
import { DatePicker, RadioGroup } from "../../../../../components";
import { formatDateISO } from "../../../../../coreUtils";
import { RadioItem } from "../../../../../components/RadioGroup";
import moment from "moment";
import { Row } from "reactstrap";
import {
  modalTitleRegraPromocao,
  routesBaseRegraPromocao,
} from "../RegraPromocao";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { showWarning } from "../../../../../components/AlertaModal";

export const CadastroRegraPromocaoModal = ({
  isOpen,
  toggle,
  action,
  notifyEvent,
}) => {
  const [tipoRegraDe, setTipoRegraDe] = useState("PRO");
  const [tipoAplic, setTipoAplic] = useState("ALL");
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);

  const limparDados = () => {
    setTipoRegraDe("PRO");
    setTipoAplic("ALL");
    setDataIni(null);
    setDataFim(null);
  };

  const submitPayload = () => {
    if (!(dataIni instanceof Date)) {
      showWarning("Por favor, revise a data de Início");
      return false;
    }

    if (!(dataFim instanceof Date)) {
      showWarning("Por favor, revise a data de Fim");
      return false;
    }

    const payload = {
      tipo_regra_de: tipoRegraDe,
      tipo_aplic: tipoAplic,
      data_ini: formatDateISO(dataIni),
      data_fim: formatDateISO(dataFim),
    };

    return payload;
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleRegraPromocao}
      size="md"
      onClose={limparDados}
      fetchData={() => {}}
      submitPayload={submitPayload}
      routesBase={routesBaseRegraPromocao}
      number="0078_1"
      selected={null}
      notifyEvent={notifyEvent}
    >
      <Row>
        <RadioGroup
          label="Aplicar Em"
          value={tipoRegraDe}
          onChange={setTipoRegraDe}
        >
          <RadioItem label="Produto" value="PRO" />
        </RadioGroup>
      </Row>
      <Row>
        <RadioGroup
          label="Regra Para"
          value={tipoAplic}
          onChange={setTipoAplic}
        >
          <RadioItem label="Todos" value="ALL" />
        </RadioGroup>
      </Row>
      <Row>
        <DatePicker
          md={6}
          label="Início"
          value={dataIni}
          onChange={(e) => setDataIni(moment.isMoment(e) ? e.toDate() : e)}
        />
        <DatePicker
          md={6}
          label="Fim"
          value={dataFim}
          onChange={(e) => setDataFim(moment.isMoment(e) ? e.toDate() : e)}
        />
      </Row>
    </ModalCadastroV2>
  );
};
