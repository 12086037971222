import React, { useRef, useState } from "react";
import { ModalBase } from "./ModalBase";
import { PasswordInput } from "./PasswordInput";
import { showWarning } from "./AlertaModal";

export const SenhaModal = ({ isOpen, toggle, title, onConfirm, hint }) => {
  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  const limparDados = () => setSenha("");

  const onConfirmInternal = async () => {
    if (senha.length === 0) {
      showWarning(`Por favor, informe a Senha de ${title}`);
      return false;
    }
    setLoading(true);
    if (await onConfirm(senha)) {
      toggle();
    } else {
      limparDados();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title={`Informe a Senha de ${title}`}
      onConfirm={onConfirmInternal}
      loadingConfirm={loading}
      onClosed={limparDados}
    >
      {hint && <p>{hint}</p>}
      <PasswordInput value={senha} onChange={setSenha} ref={inputRef} />
    </ModalBase>
  );
};
