import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";

import imagem1 from "../../../../assets/img/layouts_orcamento/1.png";
import imagem2 from "../../../../assets/img/layouts_orcamento/2.png";
import imagem3 from "../../../../assets/img/layouts_orcamento/3.png";
import imagem4 from "../../../../assets/img/layouts_orcamento/4.png";
import imagem5 from "../../../../assets/img/layouts_orcamento/5.png";
import imagem6 from "../../../../assets/img/layouts_orcamento/6.png";
import imagem7 from "../../../../assets/img/layouts_orcamento/7.png";
import imagem8 from "../../../../assets/img/layouts_orcamento/8.png";
import imagem9 from "../../../../assets/img/layouts_orcamento/9.png";
import imagem10 from "../../../../assets/img/layouts_orcamento/10.png";
import { Divider } from "../../../../components";

const layouts = [
  {
    id: 1,
    imagem: imagem1,
    descricao: (
      <>
        <ul>
          <li>Impressão padrão</li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    imagem: imagem2,
    descricao: (
      <>
        <ul>
          <li>Impressão padrão</li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    imagem: imagem3,
    descricao: (
      <>
        <ul>
          <li>Imprime CNPJ/IE da empresa no cabeçalho</li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    imagem: imagem4,
    descricao: (
      <>
        <ul>
          <li>Imprime o valor do desconto no item</li>
        </ul>
      </>
    ),
  },
  {
    id: 5,
    imagem: imagem5,
    descricao: (
      <>
        <ul>
          <li>Imprime Proposta no cabeçalho</li>
          <li>Sem ambiente</li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    imagem: imagem6,
    descricao: (
      <>
        <ul>
          <li>Impressão padrão para impressoras térmicas de cupom</li>
        </ul>
      </>
    ),
  },
  {
    id: 7,
    imagem: imagem7,
    descricao: (
      <>
        <ul>
          <li>Cabeçalho na primeira página</li>
          <li>Subtotal do ambiente antes dos itens</li>
        </ul>
      </>
    ),
  },
  {
    id: 8,
    imagem: imagem8,
    descricao: (
      <>
        <ul>
          <li>Impressão de orçamento automotivo</li>
        </ul>
      </>
    ),
  },
  {
    id: 9,
    imagem: imagem9,
    descricao: (
      <>
        <ul>
          <li>Similar ao Layout 1, com imagens dos produtos</li>
          <li>
            Mostra o Valor Total de Serviços somente se a venda possuir serviços
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 10,
    imagem: imagem10,
    descricao: (
      <>
        <ul>
          <li>Organização de impressão feita por ambientes</li>
          <li>
            Considera nome do produto, concatenando as informações adicionais do
            item
          </li>
          <li>
            Permite escolha de impressão após fechamento do orçamento, com e sem
            preço dos itens
          </li>
          <li>
            Considera a observação geral e condição de pagamento do orçamento
          </li>
        </ul>
      </>
    ),
  },
];

const PrintDisplay = ({ layoutsList, openImageViewer }) => (
  <div className="print-display">
    {layoutsList.map((layout) => (
      <div className="print-item">
        <img
          src={layout.imagem}
          className="print-thumb"
          onClick={() => openImageViewer(layout.id)}
          alt=""
        />
        <div style={{ height: "min-content", marginLeft: "0.75rem" }}>
          <h5 className="mb-1">Layout {layout.id}</h5>
          <span>{layout.descricao}</span>
        </div>
      </div>
    ))}
  </div>
);

export const ImpressaoOrcamento = () => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [idImagem, setIndexImagem] = useState(0);

  const openImageViewer = (index) => {
    setIndexImagem(index);
    setIsViewerOpen(true);
  };

  const onCloseImageViewer = () => {
    setIndexImagem(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      <Divider className="mt-2">Folha A4</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) =>
          [1, 3, 4, 5, 7, 8, 9, 10].includes(item.id)
        )}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Bobina 80mm</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [6].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Matricial</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [2].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      {isViewerOpen && (
        <ImageViewer
          src={[layouts.find((layout) => layout.id === idImagem)?.imagem]}
          currentIndex={0}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={onCloseImageViewer}
          backgroundStyle={{ backgroundColor: "#00000085" }}
        />
      )}
    </>
  );
};
