import { apiGetV2 } from "../../apiV2";

const URL_BASE = "/pronta_entrega/gerenciamento/estoque";

const UnidadesEstoqueService = {
  listarEstoqueAdm: async (params) =>
    await apiGetV2(`${URL_BASE}/listar_estoque_adm/`, params),
  listarEstoqueCadastral: async (params) =>
    await apiGetV2(`${URL_BASE}/listar_estoque_cadastral/`, params),
  listarEstoqueInter: async (idEstInter, params) =>
    await apiGetV2(
      `${URL_BASE}/listar_estoque_intermediario/${idEstInter}/`,
      params
    ),
  listarEstoqueRotas: async (idRota, params) =>
    await apiGetV2(`${URL_BASE}/listar_estoque_rotas/${idRota}/`, params),
};

export default UnidadesEstoqueService;
