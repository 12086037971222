import moment from "moment";
import React, { useState } from "react";
import { apiGet } from "../../../../../api";
import { DataTable } from "../../../../../components/DataTable";
import { ModalBase } from "../../../../../components/ModalBase";
import { ModalDetalhesIcone } from "../../../../../components/ModalDetalhesIcone";
import {
  TabBody,
  TabController,
} from "../../../../../components/TabController";
import { formatNumber } from "../../../../../coreUtils";

const DetalhesTable = ({ consulta, dados }) => {
  const columnsCompra = [
    {
      dataField: "origem",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => {
        if (cell === "ELT") {
          return "Entrada via XML";
        } else if (cell === "NFE") {
          return "Entrada Manual";
        } else {
          return cell;
        }
      },
    },
    {
      dataField: "data_emissao",
      text: "Data",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "tipo_documento",
      text: "Tipo de Documento",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_fornecedor",
      text: "Fornecedor",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vlr_total",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
  ];

  const columnsVenda = [
    {
      dataField: "tipo_mov",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => {
        if (cell === "VDA") {
          return "Venda";
        } else if (cell === "VOS") {
          return "Ordem de Serviço";
        } else if (cell === "DIR") {
          return "Venda Direta";
        } else {
          return cell;
        }
      },
    },
    {
      dataField: "data_movimento",
      text: "Data",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nro_origem",
      text: "Número",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
  ];

  return (
    <div style={{ height: "470px" }}>
      <DataTable
        columns={consulta === "CMP" ? columnsCompra : columnsVenda}
        data={dados}
        keyField=" "
        noWrap
        readOnly
        paginated
        sizePerPage={10}
        sizePerPageList={[]}
      />
    </div>
  );
};

export const DetalhesConsultaModal = ({ consulta, dataIni, dataFim }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dados, setDados] = useState([]);

  const buscarDados = async (valor) => {
    let params =
      `data_ini=${dataIni.toISOString().split("T")[0]}` +
      `&data_fim=${dataFim.toISOString().split("T")[0]}` +
      `&tipo=${consulta}&forma_pag=${valor}`;

    setDados(
      await apiGet(
        `/relatorios/comparativos/venda_vs_compra/detalhes/?${params}`
      )
    );
  };

  const onTabChange = (t) => {
    let valor = "";

    switch (t) {
      case 1:
        valor = "AV";
        break;
      case 2:
        valor = "AP";
        break;
      case 3:
        valor = "ND";
        break;
      default:
        valor = "ND";
        break;
    }
    setDados([]);
    buscarDados(valor);
  };

  const toggle = () => {
    if (!isOpen) {
      buscarDados("AV");
    } else {
      setDados([]);
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalDetalhesIcone toggle={toggle} id={consulta} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        // onOpened
        // onClosed
        title={
          <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            {consulta === "CMP" ? "Compras" : "Vendas"}: (
            {moment(dataIni).format("DD/MM/YYYY")}
            {" a " + moment(dataFim).format("DD/MM/YYYY")})
          </h5>
        }
        autoFocus
        hideCancelButton
      >
        <TabController onTabChange={onTabChange}>
          <TabBody title="A Vista">
            <DetalhesTable consulta={consulta} dados={dados} />
          </TabBody>
          <TabBody title="A Prazo">
            <DetalhesTable consulta={consulta} dados={dados} />
          </TabBody>
          <TabBody title="Não Definido">
            <DetalhesTable consulta={consulta} dados={dados} />
          </TabBody>
        </TabController>
      </ModalBase>
    </>
  );
};
