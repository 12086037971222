import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "cadastro/gerenc_supervisor_cab";
const URL_BASE_TIPO = `${URL_BASE}/movs`;

const CadastroSupervisorService = {
  urlBase: URL_BASE,
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  buscar: (idSuperv) => apiGetV2(`${URL_BASE}/buscar/${idSuperv}/`),
  alterar: (payload) => apiPutV2(`${URL_BASE}/alterar/`, payload),
  tipo: {
    listar: (idCab) => apiGetV2(`${URL_BASE_TIPO}/listar/${idCab}/`),
    incluir: (payload) => apiPostV2(`${URL_BASE_TIPO}/incluir/`, payload),
    excluir: (idMov) =>
      apiDeleteV2(`${URL_BASE_TIPO}/excluir/${idMov}/`),
  },
};

export default CadastroSupervisorService;
