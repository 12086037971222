import React, { useState } from "react";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { modalTitleTipoCliente, routesBaseTipoCliente } from "../TipoCliente";
import { MODAL_ACTIONS } from "../../../../../coreUtils";


export const TipoClienteModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [desc, setDesc] = useState("");
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setDesc("");
    setAtivo(true);
  };

  const fetchData = (ret) => {
    setDesc(ret.descricao);
    setAtivo(ret.ativo);
  };

  const submitPayload = (action) => {
    const payload = {
      descricao: desc,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ativo: ativo, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleTipoCliente}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseTipoCliente}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
      number="0129_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Descrição"
          value={desc}
          onChange={(e, v) => setDesc(v)}
          maxLength={60}
        />
      </Row>
    </ModalCadastroV2>
  );
};
