import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { apiGetV2 } from "../../../../apiV2";
import { GerenciamentoPremiacoesGrid } from "./components/GerenciamentoPremiacoesGrid";
import { MovimentosPremiadoGrid } from "./components/MovimentosPremiadoGrid";

export const GerenciamentoPremiacoes = () => {
  const [idPremiado, setIdPremiado] = useState(null);
  const [somenteSaldo, setSomenteSaldo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedMovimentos, setSelectedMovimentos] = useState([]);

  const carregarDados = async () => {
    const params = { id_premiado: idPremiado, somente_saldo: somenteSaldo };
    setLoading(true);
    const [ok, ret] = await apiGetV2(
      "/premiacao/gerenciamento/listar/",
      params
    );
    setDados(ok ? ret : []);
    if (ok) {
      const idsRet = ret.map((e) => e.id);
      if (idsRet.includes(selected)) {
        const sel = ret.find((e) => e.id === selected);
        setSelectedMovimentos(sel.movimentos);
      } else {
        if (ret.length > 0) {
          setSelectedMovimentos(ret[0].movimentos);
        } else {
          setSelectedMovimentos([]);
        }
      }
    }
    setLoading(false);
  };

  const handleSelect = (id, is, row) => {
    setSelected(is ? id : null);
    setSelectedMovimentos(is ? row.movimentos : []);
  };

  return (
    <PageContainer title="Gerenciamento de Premiações" number="0106">
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="premiado"
            defaultOptions
            label="Premiado"
            isSearchable
            onChange={(s) => setIdPremiado(s?.value ?? null)}
          />
          <FormCheckbox
            label="Somente Movimentos com Saldo"
            checked={somenteSaldo}
            onChange={() => setSomenteSaldo(!somenteSaldo)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
      </Card>
      <Card body>
        <GerenciamentoPremiacoesGrid dados={dados} onSelect={handleSelect} />
      </Card>
      <Card body>
        <MovimentosPremiadoGrid dados={selectedMovimentos} />
      </Card>
    </PageContainer>
  );
};
