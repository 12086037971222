import React from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { OrderedTooltip } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

export const GraficoComprasVendas = ({title, data = []}) => {
  return(
    <Card>
      <CardHeader>
        <CardTitle tag="h5">{title}</CardTitle>
      </CardHeader>
      <CardBody>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey={'identificador'}
              padding={{ left: 30, right: 30 }}
            />
            <YAxis
              yAxisId="left"
              tickFormatter={(v) => formatNumber(v, true, 2)}
              tick={{fontSize: 10 }}
            />
            {data.length && (
              <Tooltip
                content={<OrderedTooltip isRank={false} />}
              />
            )}
            <Legend
              iconType="plainline" iconSize={30}
              formatter={(value) =>
                value.replace(/\w\S*/g, function(txt) {
                  return (
                    txt.charAt(0).toUpperCase() +
                    txt.substr(1).toLowerCase()
                  );
                })
              }
            />
            <Line
              type="monotoneX"
              key={1}
              dot={true}
              connectNulls={true}
              dataKey={'Venda a Vista'}
              stroke={'#5fc27e'}
              strokeWidth={2}
              yAxisId={"left"}
            />
            <Line
              type="monotoneX"
              key={2}
              dot={true}
              connectNulls={true}
              dataKey={'Venda a Prazo'}
              stroke={'#5fc27e'}
              strokeWidth={2}
              yAxisId={"left"}
              strokeDasharray="5 5"
            />
            <Line
              type="monotoneX"
              key={3}
              dot={true}
              connectNulls={true}
              dataKey={'Compra a Vista'}
              stroke={'#f44455'}
              strokeWidth={2}
              yAxisId={"left"}
            />
            <Line
              type="monotoneX"
              key={4}
              dot={true}
              connectNulls={true}
              dataKey={'Compra a Prazo'}
              stroke={'#f44455'}
              strokeWidth={2}
              yAxisId={"left"}
              strokeDasharray="5 5"
            />
          </LineChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  );
}