import React, { useState } from "react";
import {
  DragAndDropArquivos,
  FormButton,
  ModalBase,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { showWarning } from "../../../../../components/AlertaModal";
import EntradaNfXmlService from "../../../../../services/compras/EntradaNfXmlService";

export const ImportarArquivoXmlModal = ({ prosseguirEntradaNf }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [arquivo, setArquivo] = useState(null);
  const [nomeArquivo, setNomeArquivo] = useState("");
  const [loading, setLoading] = useState(null);

  const limparDados = () => {
    setArquivo(null);
    setNomeArquivo("");
  };

  const onDrop = (file) => {
    setArquivo(file);
    setNomeArquivo(file.name);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if (!arquivo) {
      showWarning("Por favor, selecione o arquivo da NF-e");
      return;
    }

    setLoading(true);
    let formData = new FormData();
    formData.append("file", arquivo, nomeArquivo);

    const [ok, ret] = await EntradaNfXmlService.importarXml(formData);
    if (ok) {
      prosseguirEntradaNf(ret.id_nf_entrada_xml);
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton md="auto" color="secondary" onClick={toggle}>
        Via Arquivo
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Importar XML de Nota Fiscal"
        number="0101_3"
        onConfirm={handleSubmit}
        loading={loading}
        onClosed={limparDados}
      >
        <Row className="mb-3">
          <DragAndDropArquivos
            accept="text/xml"
            md={12}
            text="Arraste e solte o Arquivo XML da NF-e ou clique para selecioná-lo"
            onDrop={onDrop}
          />
        </Row>
        {arquivo && (
          <Row className="mb-3">
            <TextInput
              label="Arquivo Indicado"
              value={nomeArquivo}
              disabled
              forceUppercase={false}
            />
          </Row>
        )}
      </ModalBase>
    </>
  );
};
