import React from "react";
import { Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "nome_fornecedor",
    text: "Fornecedor",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_fornecedor),
  },

  {
    dataField: "numero",
    text: "Número/Parcela",
    align: "center",
    formatter: (c, row) => `${c}-${row.sequenc}`,
  },
  { dataField: "vencimento", text: "Vencimento", align: "center" },
  {
    dataField: "valor",
    text: "Valor Pago",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const TitulosLoteGrid = ({ dados }) => {
  return (
    <Table data={dados} columns={columns} paginated={false} pageSize={8} />
  );
};
