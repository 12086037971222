import React, { useState } from "react";
import {
  FixedField,
  ModalBase,
  NumberInput,
  TextInput,
  PesqPlanoCtaCentroCusto,
} from "../../../../components";
import { Row } from "reactstrap";
import CaixaLojaService from "../../../../services/financeiro/CaixaLojaService";
import { toastr } from "react-redux-toastr";

export const RetiradaCaixaModal = ({
  isOpen,
  toggle,
  idCaixa,
  saldoDinheiro,
  nomeOperador,
  mostraSaldoFechamento,
}) => {
  const [vlrRetirada, setVlrRetirada] = useState(0);
  const [historico, setHistorico] = useState("");
  const [idPlanoConta, setIdPlanoConta] = useState(null);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setVlrRetirada(0);
    setIdPlanoConta(null);
    setHistorico("");
  };

  const _internalToggle = () => {
    if (!isOpen) {
      limparDados();
    }
    toggle();
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idPlanoConta)) {
      toastr.warning("Atenção", "Por favor, informe o Plano de Contas");
      return false;
    }
    setLoading(true);

    const payload = {
      nro_caixa: idCaixa,
      id_plano_conta: idPlanoConta,
      historico: historico,
      valor: vlrRetirada,
    };

    const [ok, ret] = await CaixaLojaService.retirada(payload);
    if (ok) {
      await CaixaLojaService.imprimirRetirada(ret.id_mov_cxa);
      _internalToggle();
    }
    setLoading(false);
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={_internalToggle}
        size="md"
        title="Retirada de Dinheiro"
        number="0063_8"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row className="mb-2">
          <FixedField horizontal label="Nº Caixa" value={idCaixa} />
          <FixedField horizontal label="Operador" value={nomeOperador} />
          {mostraSaldoFechamento && (
            <FixedField
              horizontal
              label="Saldo em Dinheiro"
              value={saldoDinheiro}
            />
          )}
        </Row>
        <Row>
          <PesqPlanoCtaCentroCusto
            md={12}
            label="Plano de Contas"
            mode="plano_contas"
            onChange={(v) => setIdPlanoConta(v ?? 0)}
            value={idPlanoConta}
          />
        </Row>
        <Row>
          <NumberInput
            md={4}
            label="Valor de Retirada"
            value={vlrRetirada}
            onChange={setVlrRetirada}
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Motivo"
            value={historico}
            onChange={(e, v) => setHistorico(v)}
            maxLength={200}
          />
        </Row>
      </ModalBase>
    </>
  );
};
