import React from "react";
import {
  IconButton,
  ModalDetalhesIcone,
  Table,
  TableCheck,
} from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { LuSplit } from "react-icons/lu";

export const ItensFracNfEntradaGrid = ({
  dados,
  fracionarItem,
  detalhesItemFracionado,
}) => {
  const columns = [
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_produto", text: "Nome", align: "left" },
    { dataField: "cfop", text: "CFOP", align: "center" },
    { dataField: "unidade", text: "UN", align: "center" },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      formatter: (c) => formatNumber(c, true, 6, true),
    },
    {
      dataField: "fator_conv",
      text: "Fator Conv",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_unit",
      text: "Unitário",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_tot_bruto",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "fracionado",
      text: "Fracionado",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) =>
        row.fracionado ? (
          <ModalDetalhesIcone toggle={() => detalhesItemFracionado(row.id)} />
        ) : (
          <IconButton
            icon={LuSplit}
            hint="Fracionar Item"
            onClick={() => fracionarItem(row.id)}
            disabled={row.fracionado}
            disabledHint="O item já foi fracionado"
          />
        ),
    },
  ];
  return (
    <Table data={dados} columns={columns} pageSize={10} paginated={false} />
  );
};
