import React from "react";
import { Star } from "react-feather";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import {
  dateColumnSorter,
  formatNumber,
  formatValueFromAPI,
  naturalSort,
} from "../../../../../coreUtils";
import { MoreDropdown, Table } from "../../../../../components";
import { DropdownItem } from "reactstrap";
export const CentralPedInternoGrid = ({
  data,
  onSelect,
  onSelectAll,
  selected,
  estornarRecebimento,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      sortable: true,
      colWidth: "175px",
      formatter: (cell, row) => (
        <>
          {formatValueFromAPI(cell, row.id_cliente, 30)}{" "}
          {row.cliente_novo && (
            <>
              <Star
                className="pl-1 pb-1"
                size={16}
                color={"#FFD700"}
                fill={"#FFD700"}
                id={`cn-${row.id}`}
              />
              <UncontrolledTooltip target={`cn-${row.id}`}>
                Cliente Novo
              </UncontrolledTooltip>
            </>
          )}
        </>
      ),
    },
    {
      dataField: "nome_fant_cliente",
      text: "Nome Fantasia",
      align: "left",
      headerAlign: "left",
      sortable: true,
      formatter: (c) => (c ?? "").substring(0, 30),
      colWidth: "180px",
    },
    {
      dataField: "nome_cidade",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
      sortable: true,
      colWidth: "100px",
      formatter: (c) => (c ?? "").substring(0, 15),
    },
    {
      dataField: "nome_vendedor",
      text: "Vendedor",
      align: "left",
      headerAlign: "left",
      sortable: true,
      colWidth: "90px",
    },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: dateColumnSorter,
      colWidth: "75px",
      formatter: (c, row) => `${c} ${row.hora_emissao}`,
    },
    {
      dataField: "previsao_entrega",
      text: "P Entr",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: dateColumnSorter,
      colWidth: "55px",
    },
    {
      dataField: "vlr_total",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "desc_status",
      text: "Stat",
      align: "center",
      headerAlign: "center",
      sortable: true,
      colWidth: "100px",
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <MoreDropdown horizontal onToggle={(e) => e.stopPropagation()}>
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              estornarRecebimento(row.id);
            }}
            disabled={row.status !== "U"}
          >
            Estornar recebimento
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={data}
      // paginated={false}
      selected={selected}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      multiselect
      pageSize={20}
      showRegisterCount={false}
    />
  );
};
