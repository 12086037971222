import React, { useState } from "react";
import {
  ChevronDown,
  ChevronRight,
  ChevronsDown,
  ChevronsRight,
} from "react-feather";
import { Label, Row, UncontrolledTooltip } from "reactstrap";
import CheckboxTree, { expandNodesToLevel } from "react-checkbox-tree";
import { ModalBase } from "../../ModalBase";
import SearchInput from "../../SearchInput";

export const PesqPlanoCtaCentroCustoModal = ({
  isOpen,
  toggle,
  mode,
  options,
  checked,
  onCheck,
}) => {
  const [expanded, setExpanded] = useState([]);
  const [queryElements, setQueryElements] = useState([]);
  const [nextPosition, setNextPosition] = useState(0);
  const [nextPositionShown, setNextPositionShown] = useState(0);
  const modalTitle =
    mode === "plano_contas" ? "Plano de Contas" : "Centro de Custo";

  const limparDados = () => {
    setExpanded([]);
    setNextPosition(0);
    setNextPositionShown(0);
    setQueryElements([]);
  };

  const onBeforeOpen = () => {
    setExpanded(expandNodesToLevel(options, Infinity));
  };

  const findSelected = () => {
    if ((checked ?? []).length === 1) {
      let query = document
        .getElementById("checkbox-tree-list")
        .querySelectorAll(`[aria-checked*='true']`);
      if (query.length > 0) {
        query[0].nextElementSibling.scrollIntoView(true);
      }
    }
  };

  const handleSearch = (v) => {
    let elements = document
      .getElementById("checkbox-tree-list")
      .querySelectorAll(`[title]`);

    [].forEach.call(elements, (e) => {
      e.style = "";
    });

    let query = document
      .getElementById("checkbox-tree-list")
      .querySelectorAll(`[title*='${v.toUpperCase().replace("'", "")}']`);

    setItemsColor(query, 0);
    setQueryElements(query);
    setNextPosition(query.length > 1 ? 1 : 0);
    setNextPositionShown(1);
  };

  const findNext = () => {
    setNextPositionShown(nextPosition + 1);
    setItemsColor(queryElements, nextPosition);
    setNextPosition(
      nextPosition === queryElements.length - 1 ? 0 : nextPosition + 1
    );
  };

  const setItemsColor = (elements, selected) => {
    [].forEach.call(elements, (e, index) => {
      if (index === selected) {
        e.scrollIntoView(true);
        e.style = "background-color: yellow;";
      } else {
        e.style = "background-color: lightyellow;";
      }
    });
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title={`Pesquisa de ${modalTitle}`}
      footerActions
      onBeforeOpen={onBeforeOpen}
      onOpened={findSelected}
      onConfirm={toggle}
      onClosed={limparDados}
    >
      <Row className="mb-2">
        <SearchInput
          onChange={handleSearch}
          hideLabel
          md={10}
          autoFocus
          onKeyDown={(e) => e.key === "Enter" && findNext()}
        />
        {queryElements.length > 0 && (
          <>
            <ChevronDown
              className="mr-2"
              onClick={queryElements.length > 0 ? findNext : undefined}
              style={{
                cursor: "pointer",
                color: "currentColor",
              }}
              id="findNextButton"
            />
            <UncontrolledTooltip target="findNextButton">
              Localizar o próximo
            </UncontrolledTooltip>

            <Label>
              {nextPositionShown} de {queryElements.length}
            </Label>
          </>
        )}
      </Row>
      <div
        style={{ height: "425px", overflow: "auto" }}
        id="checkbox-tree-list"
      >
        <CheckboxTree
          nodes={options}
          checked={checked}
          expanded={expanded}
          onCheck={onCheck}
          onExpand={setExpanded}
          showNodeIcon={false}
          nativeCheckboxes
          checkModel="all"
          showNodeTitle
          noCascade
          icons={{
            expandClose: <ChevronRight />,
            expandOpen: <ChevronDown />,
            expandAll: <ChevronsRight />,
            collapseAll: <ChevronsDown />,
          }}
        />
      </div>
    </ModalBase>
  );
};
