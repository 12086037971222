import React from "react";
import { IconButton, MoreDropdown, Table } from "../../../../../../components";
import { formatNumber } from "../../../../../../coreUtils";
import { DropdownItem } from "reactstrap";
import { Edit, Printer } from "react-feather";
import OrcamentosService from "../../../../../../services/orcamentos/OrcamentosService";

export const OrcamentosOSGrid = ({
  dados,
  onSelect,
  alterar,
  autorizarNegarTudo,
}) => {
  // Verifica se o navegador é Firefox
  const firefox = typeof InstallTrigger !== "undefined";

  const columns = [
    { dataField: "id", text: "#", align: "center", headerAlign: "center" },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "qtd_servicos",
      text: "Qtd. Serviços",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "qtd_produtos",
      text: "Qtd. Produtos",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "situacao",
      text: "Situação",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "desc_status",
      text: "Status",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              OrcamentosService.enviarEmail(row.id);
            }}
          >
            Enviar E-Mail
          </DropdownItem>
          {row.situacao === "Aberto" && (
            <>
              <hr />
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  autorizarNegarTudo(true);
                }}
              >
                Autorizar Tudo
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  autorizarNegarTudo(false);
                }}
              >
                Negar Tudo
              </DropdownItem>
              <hr />
            </>
          )}
          <DropdownItem>Log</DropdownItem>
        </MoreDropdown>
      ),
    },
    {
      dataField: "edit",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <>
          {row.situacao === "Aberto" && (
            <IconButton
              className="mr-2"
              icon={Edit}
              onClick={() => alterar(row.id)}
              hint="Alterar Orçamento"
              tooltipPlacement={firefox ? "left" : "top"}
            />
          )}
          <IconButton
            icon={Printer}
            onClick={() => {
              OrcamentosService.imprimir(row.id);
            }}
            hint="Imprimir Orçamento"
            tooltipPlacement={firefox ? "left" : "top"}
          />
        </>
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      paginated={false}
      onSelect={onSelect}
      pageSize={4}
      clearSelectionOnUpdate={true}
    />
  );
};
