import React, { useState } from "react";
import { BotaoPesquisar, PageContainer } from "../../../../components";
import AvisoVincColaboradorService from "../../../../services/aviso/AvisoVincColaboradorService";
import { Card, Row } from "reactstrap";
import { AvisoVincColaboradorGrid } from "./components/AvisoVincColaboradorGrid";
import { ColaboradoresAvisoModal } from "./components/ColaboradoresAvisoModal";

export const AvisoVincColaborador = () => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await AvisoVincColaboradorService.listar();
    setLoading(false);
    setDados(ok ? ret : []);
  };

  return (
    <PageContainer title="Vinculação de Avisos a Colaboradores" number="0090">
      <Card body>
        <Row className="mb-2">
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            padded={false}
          />
          <ColaboradoresAvisoModal selected={selected} />
        </Row>
        <AvisoVincColaboradorGrid dados={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
