import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { chartColors } from "../coreUtils";
import { OrderedTooltip } from "./index";

const CustomBarChart = ({
  data,
  title,
  barDataKeys,
  dataKey,
  height = 600,
  totalLabel = "Total",
  totalSumFunc,
}) => {
  return (
    <Container fluid className="p-0">
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{title}</CardTitle>
        </CardHeader>
        <CardBody>
          <ResponsiveContainer width="100%" height={height}>
            <BarChart data={data}>
              <CartesianGrid vertical={false} />
              <XAxis dataKey={dataKey} />
              <YAxis />
              <Tooltip
                content={
                  <OrderedTooltip
                    showTotal
                    totalLabel={totalLabel}
                    totalSumFunc={totalSumFunc}
                  />
                }
              />
              <Legend />
              {barDataKeys.map((k, i) => {
                return (
                  <Bar
                    key={i}
                    dataKey={k}
                    fill={chartColors[i]}
                    strokeWidth={0}
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        </CardBody>
      </Card>
    </Container>
  );
};

export default CustomBarChart;
