import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BsReceiptCutoff } from "react-icons/bs";
import { BiReceipt } from "react-icons/bi";
import { MdHomeRepairService } from "react-icons/md";
import {
  notaFiscalConsumidorRoute,
  notaFiscalEletronicaRoute,
  notaFiscalServicoRoute,
} from "../../../routes/modules/docsEletron";

export const NotasFiscais = () => {
  return (
    <PageContainer title="Notas Fiscais">
      <MenuGroup>
        <MenuGroupButton
          label="Nota Fiscal Eletrônica NF-e"
          icon={BiReceipt}
          pathname={notaFiscalEletronicaRoute.path}
          nroTela={notaFiscalEletronicaRoute.nroTela}
        />
        <MenuGroupButton
          label="Nota Fiscal Consumidor NFC-e"
          icon={BsReceiptCutoff}
          pathname={notaFiscalConsumidorRoute.path}
          nroTela={notaFiscalConsumidorRoute.nroTela}
        />
        <MenuGroupButton
          label="Nota Fiscal de Serviço NFS-e"
          icon={MdHomeRepairService}
          pathname={notaFiscalServicoRoute.path}
          nroTela={notaFiscalServicoRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
