import React, { useState } from "react";
import {
  FormButton,
  IconButton,
  ModalBase,
  TextInput,
} from "../../../../../../components";
import { toastr } from "react-redux-toastr";
import { formatValueFromAPI } from "../../../../../../coreUtils";
import { Row } from "reactstrap";
import CadastroLigacaoVeicCliService from "../../../../../../services/cadastro/CadastroLigacaoVeicCliService";
import { PiSteeringWheelBold, PiSteeringWheelLight } from "react-icons/pi";
import PesqCliente from "../../../../../../components/form/pesq_cliente/PesqCliente";
import classNames from "classnames";

export const TrocarProprietarioVeicModal = ({
  idOS,
  placa,
  notifyEvent,
  setDeveBuscarClientePlaca,
  padded = false,
  showButtonText,
  sistAutomotivo,
  buttonDivClassName,
  grid,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [veiculo, setVeiculo] = useState("");
  const [clienteAtual, setClienteAtual] = useState("");
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);

  const limparDados = () => {
    setIdCliente(null);
    setNomeCliente("");
    setVeiculo("");
    setClienteAtual("");
  };

  const carregarDados = async () => {
    setLoadingFetch(true);
    const [ok, ret] = await CadastroLigacaoVeicCliService.buscarClientePlaca(
      placa
    );
    if (ok) {
      setVeiculo(`${ret.nome_veiculo} - ${ret.modelo_veiculo}`);
      setClienteAtual(formatValueFromAPI(ret.nome_cliente, ret.id_cliente));
      setLoadingFetch(false);
      return true;
    } else {
      setLoadingFetch(false);
      return false;
    }
  };

  const toggle = async () => {
    if (!isOpen) {
      if (!(await carregarDados())) {
        return false;
      }
      if (setDeveBuscarClientePlaca) setDeveBuscarClientePlaca(true);
    } else {
      if (setDeveBuscarClientePlaca) setDeveBuscarClientePlaca(false);
    }
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idCliente)) {
      toastr.warning("Atenção", "Por favor, selecione o Novo Proprietário");
      return false;
    }

    setLoading(true);
    const payload = {
      id_os: idOS ?? null,
      id_cliente: idCliente,
      nome_cliente: nomeCliente,
      placa: placa,
    };

    const [ok] = await CadastroLigacaoVeicCliService.alterarProprietarioVeic(
      payload
    );
    if (ok) {
      notifyEvent();
      toggle();
    }

    setLoading(false);
  };

  return (
    <>
      {grid ? (
        <IconButton
          onClick={toggle}
          icon={PiSteeringWheelBold}
          hint="Trocar Proprietário"
          tooltipPlacement="left"
        />
      ) : (
        <FormButton
          md="auto"
          color="info"
          onClick={toggle}
          disabled={
            ["", null, undefined].includes(placa) || placa.trim().length !== 8
          }
          disabledHint="Informe a Placa"
          loading={loadingFetch}
          tabIndex={-1}
          padded={padded}
          hint="Trocar Proprietário"
          divClassName={classNames(
            !showButtonText && "pr-0",
            buttonDivClassName
          )}
        >
          <PiSteeringWheelLight
            size={20}
            className={showButtonText && "mr-2"}
          />
          {showButtonText && "Trocar Proprietário"}
        </FormButton>
      )}
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Trocar Proprietário do Veículo"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onClosed={limparDados}
        number="0006_12"
      >
        <Row>
          <TextInput md={3} label="Placa" value={placa} disabled />
          <TextInput md={9} label="Veículo" value={veiculo} disabled />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Proprietário Atual"
            value={clienteAtual}
            disabled
          />
        </Row>
        <PesqCliente
          md={12}
          onConfirm={(id, nome) => {
            setIdCliente(id);
            setNomeCliente(nome);
          }}
          selectNextField={() => {}}
          onChangeDescricao={setNomeCliente}
          idCliente={idCliente}
          nomeCliente={nomeCliente}
          sistAutomotivo={sistAutomotivo}
          label="Novo Proprietário"
          divClassName="px-0"
        />
      </ModalBase>
    </>
  );
};
