import React from "react";
import { Col, Label, Progress } from "reactstrap";

export const ProgressBar = ({ label, value = 0, md = 5, visible = true }) => {
  return (
    visible && (
      <Col md={md}>
        <Label style={{ marginTop: "0.9rem" }}>{label}</Label>
        <Progress color="success" value={value} style={{ height: "1.1rem" }} />
      </Col>
    )
  );
};
