import Axios from "axios";
import { toastr } from "react-redux-toastr";
import {
  buscarDadosEmpresaCookies,
  isDevEnv,
  isTestingEnv,
  viewDownloadPDF,
} from "./coreUtils";
import moment from "moment";

const baseURL =
  isDevEnv() && !isTestingEnv()
    ? "http://127.0.0.1:4200"
    : "https://doc-pdf.skillsoft.com.br";

const baseURLV2 =
  isDevEnv() && !isTestingEnv()
    ? "http://127.0.0.1:8000"
    : "https://ms-pdf.skillsoft.com.br";

const docService = Axios.create({
  baseURL: baseURL,
  timeout: 3000000,
});

export const docServiceV2 = Axios.create({
  baseURL: baseURLV2,
});

export const docPost = async (url, data, options, usev2 = false) =>
  await (usev2 ? docServiceV2 : docService)
    .post(url, data, { responseType: "blob" })
    .then((ret) => ret.data)
    .catch(async (err) => {
      toastr.error(
        options.errorMessage ?? "Erro",
        err.response ? await err.response.data.text() : err.message
      );
      return false;
    });

export const docPrintReport = async (url, data, number, usev2 = false) => {
  const payload = {
    dados_empresa: await buscarDadosEmpresaCookies(),
    ...data,
  };

  const arq = await docPost(
    url,
    payload,
    {
      errorMessage: "Erro ao Imprimir Relatório",
    },
    usev2
  );

  if (arq) {
    viewDownloadPDF(
      arq,
      `${number}_${moment(new Date()).format("DDMMYYYY_hhmmss")}`
    );
    return true;
  } else {
    return false;
  }
};
