import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  ativo: true,
  nome: "",
  nome_fant: "",
  endereco: "",
  numero: "",
  bairro: "",
  complemento: "",
  id_cidade: 0,
  referencia: "",
  cep: "",
  fone1: "",
  fone2: "",
  fone3: "",
  fone4: "",
  pessoa: "F",
  cpf_cnpj: "",
  insc_est: "",
  identidade: "",
  id_ramo_atividade: null,
  id_plano_conta: null,
  id_cond_pag: null,
  id_centro_custo: null,
  email: "",
  site: "",
  repres_nome: "",
  repres_fone1: "",
  repres_fone2: "",
  repres_fone3: "",
  repres_email: "",
  observ: "",
  simples_nacional: false,
};

export const cadastroFornecedores = createSlice({
  name: "cadastro_fornecedores",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setAtivo: (state, action) => {
      state.ativo = action.payload;
    },
    setNome: (state, action) => {
      state.nome = action.payload;
    },
    setNomeFant: (state, action) => {
      state.nome_fant = action.payload;
    },
    setEndereco: (state, action) => {
      state.endereco = action.payload;
    },
    setNumero: (state, action) => {
      state.numero = action.payload;
    },
    setBairro: (state, action) => {
      state.bairro = action.payload;
    },
    setComplemento: (state, action) => {
      state.complemento = action.payload;
    },
    setIdCidade: (state, action) => {
      state.id_cidade = action.payload;
    },
    setReferencia: (state, action) => {
      state.referencia = action.payload;
    },
    setCep: (state, action) => {
      state.cep = action.payload;
    },
    setFone1: (state, action) => {
      state.fone1 = action.payload;
    },
    setFone2: (state, action) => {
      state.fone2 = action.payload;
    },
    setFone3: (state, action) => {
      state.fone3 = action.payload;
    },
    setFone4: (state, action) => {
      state.fone4 = action.payload;
    },
    setPessoa: (state, action) => {
      state.pessoa = action.payload;
      state.cpf_cnpj = "";
      state.insc_est = "";
      state.identidade = "";
    },
    setCpfCnpj: (state, action) => {
      state.cpf_cnpj = action.payload;
    },
    setInscEst: (state, action) => {
      state.insc_est = action.payload;
    },
    setIdentidade: (state, action) => {
      state.identidade = action.payload;
    },
    setIdRamoAtividade: (state, action) => {
      state.id_ramo_atividade = action.payload;
    },
    setIdPlanoConta: (state, action) => {
      state.id_plano_conta = action.payload;
    },
    setIdCondPag: (state, action) => {
      state.id_cond_pag = action.payload;
    },
    setIdCentroCusto: (state, action) => {
      state.id_centro_custo = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setSite: (state, action) => {
      state.site = action.payload;
    },
    setRepresNome: (state, action) => {
      state.repres_nome = action.payload;
    },
    setRepresFone1: (state, action) => {
      state.repres_fone1 = action.payload;
    },
    setRepresFone2: (state, action) => {
      state.repres_fone2 = action.payload;
    },
    setRepresFone3: (state, action) => {
      state.repres_fone3 = action.payload;
    },
    setRepresEmail: (state, action) => {
      state.repres_email = action.payload;
    },
    setObserv: (state, action) => {
      state.observ = action.payload;
    },
    setSimplesNacional: (state, action) => {
      state.simples_nacional = action.payload;
    },
  },
});

export const {
  init,
  setup,
  setAtivo,
  setNome,
  setNomeFant,
  setEndereco,
  setNumero,
  setBairro,
  setComplemento,
  setIdCidade,
  setReferencia,
  setCep,
  setFone1,
  setFone2,
  setFone3,
  setFone4,
  setPessoa,
  setCpfCnpj,
  setInscEst,
  setIdentidade,
  setIdRamoAtividade,
  setIdPlanoConta,
  setIdCondPag,
  setIdCentroCusto,
  setEmail,
  setSite,
  setRepresNome,
  setRepresFone1,
  setRepresFone2,
  setRepresFone3,
  setRepresEmail,
  setObserv,
  setSimplesNacional,
} = cadastroFornecedores.actions;

export default cadastroFornecedores.reducer;
