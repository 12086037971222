import React from "react";
import { Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

export const DevolucaoVendaGrid = ({ dados, setSelected }) => {
  const columns = [
    {
      dataField: "id",
      text: "Venda",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "desc_origem",
      text: "Origem",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_origem",
      text: "# Origem",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_ped",
      text: "Pedido",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "nome_vendedor",
      text: "Vendedor",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_vendedor),
    },
    {
      dataField: "emissao",
      text: "Data",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_total",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
  ];

  return <Table data={dados} columns={columns} onSelect={setSelected} />;
};
