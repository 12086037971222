import React from "react";
import { Table } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const MontadoraGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    { dataField: "nome", text: "Nome", align: "left", headerAlign: "left" },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return <Table data={data} columns={columns} onSelect={setSelected} />;
};
