import React, { useState } from "react";
import RoteirizacaoService from "../../../../../services/cadastro/RoteirizacaoService";
import { showWarning } from "../../../../../components/AlertaModal";
import {
  AsyncComboBox,
  FormButton,
  FormCheckbox,
  ModalBase,
} from "../../../../../components";
import { Label, Row } from "reactstrap";

export const RoteirizacaoCidadeModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [params, setParams] = useState({});
  const [idCidade, setIdCidade] = useState(null);
  const [idRota, setIdRota] = useState(null);
  const [removerRoteirizAnterior, setRemoverRoteirizAnterior] = useState(false);
  const [loading, setLoading] = useState(null);
  const permiteIncluirClienteMaisDeUmaRota =
    params?.permite_incluir_cliente_mais_de_uma_rota ?? false;

  const limparDados = () => {
    setIdCidade(null);
    setIdRota(null);
    setRemoverRoteirizAnterior(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idCidade)) {
      showWarning("Por favor, informe a Cidade");
      return;
    }

    if ([0, null, undefined].includes(idRota)) {
      showWarning("Por favor, informe a Rota");
      return;
    }

    const payload = {
      id_cidade: idCidade,
      id_rota: idRota,
      remover_roteiriz_atual: removerRoteirizAnterior,
    };
    setLoading(true);
    const [ok] = await RoteirizacaoService.gerarPorCidade(payload);
    if (ok) {
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        divClassName="ml-auto"
        color="primary"
        onClick={toggle}
        padded={false}
      >
        Roteirização por Cidade
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Roteirização por Cidade"
        number="0010_5"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onClosed={limparDados}
        paramsName="roteirizacao_por_cidade"
        onBeforeOpen={(p) => setParams(p)}
      >
        <Label className="mb-2">
          Este processo incluirá todos os clientes da cidade informada
          {!permiteIncluirClienteMaisDeUmaRota ? (
            <>, removendo todas as suas roteirizações atuais, </>
          ) : (
            <></>
          )}{" "}
          na roteirização de Segunda-Feira da rota informada.
        </Label>
        <Row>
          <AsyncComboBox
            md={12}
            label="Cidade"
            concatModelName="cidade"
            defaultValue={idCidade}
            onChange={(s) => setIdCidade(s?.value)}
            hideShortcut
          />
        </Row>
        {permiteIncluirClienteMaisDeUmaRota && (
          <Row className="mb-2">
            <FormCheckbox
              padded={false}
              label="Excluir roteirizações atuais dos clientes"
              checked={removerRoteirizAnterior}
              onChange={() =>
                setRemoverRoteirizAnterior(!removerRoteirizAnterior)
              }
            />
          </Row>
        )}
        <Row>
          <AsyncComboBox
            md={12}
            label="Rota"
            concatModelName="rota_mob"
            defaultValue={idRota}
            onChange={(s) => setIdRota(s?.value)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
