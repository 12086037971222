import React from "react";
import { Table, TableCheck } from "../../../../../components";
import { naturalSort } from "../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const CfopGrid = ({ dados, onSelect, alterar, excluir }) => {
  const columns = [
    {
      dataField: "codigo",
      text: "Código",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "cfop_nf",
      text: "CFOP NF",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "sit_trib_icms",
      text: "Sit. Trib. Icms ",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "perc_icms",
      text: "Perc. Icms",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "sit_trib_icms_nfce",
      text: "Sit. Trib. Icms NFCe",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "perc_icms_nfce",
      text: "Perc. Icms NFCe",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    iconeAlterar(alterar, {keyField: "codigo"}),
    dropdownAcoes({ excluir: excluir, keyField: "codigo"}),
  ];

  return (
    <Table
      columns={columns}
      data={dados}
      onSelect={onSelect}
      onRowDoubleClick={alterar}
      keyField="codigo"
    />
  );
};
