import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "integracao/produto_env";

const MarketplacesIntegracoesService = {
  listarEnviados: async (params) =>
    await apiGetV2(`${URL_BASE}/listar_produtos_enviados/`, params),
  listarEnviar: async (params) =>
    await apiGetV2(`${URL_BASE}/listar_produtos_enviar/`, params),
  listarLayout: async (params) =>
    await apiGetV2(`/integracao/cad_layout/listar/`, params),
  incluirProd: async (payload) =>
    await apiPostV2(`${URL_BASE}/adicionar_produtos_enviar/`, payload),
  excluirProd: async (payload) =>
    await apiPutV2(`${URL_BASE}/remover_produtos/`, payload),
};

export default MarketplacesIntegracoesService;
