import React from "react";
import { Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

const columns = [
  { dataField: "desc_recurso", text: "Recurso", align: "left" },
  { dataField: "nro_recurso", text: "Número", align: "center" },
  { dataField: "vencimento", text: "Vencimento", align: "center" },
  {
    dataField: "valor",
    text: "Valor Pago",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const RecursosLoteGrid = ({ dados }) => {
  return (
    <Table data={dados} columns={columns} paginated={false} pageSize={8} />
  );
};
