import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  ModalBase,
  NumberInput,
  RadioGroup,
} from "../../../../../components";
import { GerenciamentoFlexService } from "../../../../../services/frente_venda_mobile/GerenciamentoFlexService";
import { RadioItem } from "../../../../../components/RadioGroup";

export const LancamentoBonifModal = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rota, setRota] = useState(null);
  const [natureza, setNatureza] = useState(null);
  const [vlrLancamento, setVlrLancamento] = useState(0);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setRota(null);
    setNatureza(null);
    setVlrLancamento(0);
  };

  const toggle = () => {
    if (isOpen) limparDados();
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if (!rota) {
      toastr.warning("Atenção", "Por favor, informe a rota para o lançamento");
      return;
    }
    if (!natureza) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a natureza para o lançamento"
      );
      return;
    }
    if (vlrLancamento <= 0) {
      toastr.warning("Atenção", "Por favor, informe o valor do lançamento");
      return;
    }

    const payload = {
      id_rota_mob: rota,
      natureza: natureza,
      valor: vlrLancamento,
    };

    setLoading(true);
    const [ok] = await GerenciamentoFlexService.lancarBonif(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };
  return (
    <>
      <FormButton color="secondary" onClick={toggle} padded={false} md="auto">
        Lançamento - Bonificação
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Lançamento de Bonificação para Rota"
        number="0028_2"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            isSearchable
            concatModelName="rota_mob"
            defaultOptions
            md={5}
            label="Rota"
            onChange={(selected) => setRota(selected?.value)}
            autoFocus
          />
          <RadioGroup label="Operação" onChange={setNatureza} value={natureza}>
            <RadioItem label="Crédito" value="+" />
            <RadioItem label="Débito" value="-" />
          </RadioGroup>
          <NumberInput
            label="Valor"
            md={3}
            value={vlrLancamento}
            onChange={(value) => setVlrLancamento(value)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
