import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Divider,
  FixedField,
  LabelButton,
  ModalBase,
  NumberInput,
  Table,
} from "../../../../../components";
import GerenciamentoVendasService from "../../../../../services/vendas/GerenciamentoVendasService";
import OrdemServicoService from "../../../../../services/ordem_servico/OrdemServicoService";
import { ProdServIcone } from "../../../../../components/ProdServIcone";
import { Col, Row } from "reactstrap";
import { formatNumber, roundFloat } from "../../../../../coreUtils";
import CaixaLojaService from "../../../../../services/financeiro/CaixaLojaService";
import UteisService from "../../../../../services/uteis/UteisService";
import { apiGetV2 } from "../../../../../apiV2";
import { SenhaModal } from "../../../../../components/SenhaModal";
import { showWarning } from "../../../../../components/AlertaModal";

const columns = [
  {
    dataField: "tipo_reg",
    text: "T",
    align: "left",
    headerAlign: "left",
    colWidth: "20px",
    formatter: (c) => <ProdServIcone value={c} />,
  },
  { dataField: "id_tipo", text: "#", align: "center", headerAlign: "center" },
  {
    dataField: "descricao",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "quantidade",
    text: "Qtd",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_unit",
    text: "Unitário",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "perc_desc",
    text: "Desc",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true) + "%",
  },
  {
    dataField: "vlr_item",
    text: "Vlr Item",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_icms_st",
    text: "ICMS ST",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_ipi",
    text: "IPI",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "perc_desc_novo",
    text: "Desc Novo",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true) + "%",
  },
  {
    dataField: "vlr_item_novo",
    text: "Vlr Item Novo",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_icms_st_novo",
    text: "ICMS ST Novo",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_ipi_novo",
    text: "IPI Novo",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total_novo",
    text: "Total Novo",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const AplicarDescontoModal = ({
  idMovCaixaLoja,
  origem,
  idOrigem,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idCliente, setIdCliente] = useState(null);
  const [itens, setItens] = useState([]);
  // Parâmetros
  const [descMaxCaixa, setDescMaxCaixa] = useState(0);
  const [regimeTribut, setRegimeTribut] = useState(null);
  // Valores originais
  const [percDescOrig, setPercDescOrig] = useState(0);
  const [vlrDescOrig, setVlrDescOrig] = useState(0);
  const [vlrTotProdServOrig, setVlrTotProdServOrig] = useState(0);
  const [vlrTotIcmsStOrig, setVlrTotIcmsStOrig] = useState(0);
  const [vlrIpiOrig, setVlrIpiOrig] = useState(0);
  const [vlrFreteOrig, setVlrFreteOrig] = useState(0);
  const [vlrTotalOrig, setVlrTotalOrig] = useState(0);
  // Novos valores
  const [percDescNovo, setPercDescNovo] = useState(0);
  const [vlrDescNovo, setVlrDescNovo] = useState(0);
  const [vlrTotProdServNovo, setVlrTotProdServNovo] = useState(0);
  const [vlrTotIcmsStNovo, setVlrTotIcmsStNovo] = useState(0);
  const [vlrIpiNovo, setVlrIpiNovo] = useState(0);
  const [vlrFreteNovo, setVlrFreteNovo] = useState(0);
  const [vlrTotalNovo, setVlrTotalNovo] = useState(0);
  // Controle
  const [loading, setLoading] = useState(false);
  const [loadingRecalc, setLoadingRecalc] = useState(false);
  const [recalcularValores, setRecalcularValores] = useState(false);
  const [senhaDescontoOpen, setSenhaDescontoOpen] = useState(false);

  const limparDados = () => {
    setItens([]);
    setPercDescOrig(0);
    setVlrDescOrig(0);
    setVlrTotProdServOrig(0);
    setVlrTotIcmsStOrig(0);
    setVlrIpiOrig(0);
    setVlrFreteOrig(0);
    setVlrTotalOrig(0);
    setPercDescNovo(0);
    setVlrDescNovo(0);
    setVlrTotProdServNovo(0);
    setVlrTotIcmsStNovo(0);
    setVlrIpiNovo(0);
    setVlrFreteNovo(0);
    setVlrTotalNovo(0);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/caixa_loja/desconto/");

    if (ok) {
      setDescMaxCaixa(parseFloat(ret.desc_max_caixa));
      setRegimeTribut(ret.regime_tribut);
    }
  };

  const carregarDados = async () => {
    if (origem === "VFX") {
      const [ok, ret] = await GerenciamentoVendasService.buscar(idOrigem);
      if (ok) {
        setIdCliente(ret.id_cliente);
        setPercDescOrig(parseFloat(ret.perc_desc_geral));
        setVlrDescOrig(parseFloat(ret.vlr_desc));
        setVlrTotProdServOrig(parseFloat(ret.vlr_tot_prod_serv));
        setVlrTotIcmsStOrig(parseFloat(ret.vlr_tot_icms_st));
        setVlrIpiOrig(parseFloat(ret.vlr_ipi));
        setVlrFreteOrig(parseFloat(ret.vlr_frete));
        setVlrTotalOrig(parseFloat(ret.vlr_total));
        setItens(
          ret.itens.map((e) => ({
            uuid: uuidv4(),
            id_item: e.id,
            tipo: e.tipo_reg,
            id_tipo: e.id_item,
            descricao: e.descricao,
            quantidade: parseFloat(e.quantidade),
            vlr_unit: parseFloat(e.vlr_unit),
            perc_desc: parseFloat(e.perc_desc),
            vlr_desc: parseFloat(e.vlr_desc),
            vlr_item: parseFloat(e.vlr_item),
            vlr_tot_prod_serv: parseFloat(e.vlr_tot_prod_serv),
            vlr_icms_st: parseFloat(e.vlr_icms_st),
            vlr_ipi: parseFloat(e.vlr_ipi),
            vlr_total: parseFloat(e.vlr_total),
            perc_desc_novo: 0,
            vlr_item_novo: parseFloat(e.vlr_item),
            vlr_desc_novo: 0,
            vlr_tot_prod_serv_novo: parseFloat(e.vlr_tot_prod_serv),
            vlr_icms_st_novo: parseFloat(e.vlr_icms_st),
            vlr_ipi_novo: parseFloat(e.vlr_ipi),
            vlr_total_novo: parseFloat(e.vlr_total),
          }))
        );
      } else {
        toggle();
      }
    } else if (origem === "VOS") {
      const [ok, ret] = await OrdemServicoService.buscar(idOrigem);
      if (ok) {
        setIdCliente(ret.os.id_cliente);

        const totais = ret.totais;
        setPercDescOrig(parseFloat(totais.perc_desc_geral));
        setVlrDescOrig(parseFloat(totais.total_desconto));
        setVlrTotProdServOrig(parseFloat(totais.total_geral));
        setVlrTotIcmsStOrig(0);
        setVlrIpiOrig(0);
        setVlrFreteOrig(0);
        setVlrTotalOrig(parseFloat(totais.total_geral));
        setItens(
          ret.itens.map((e) => ({
            uuid: uuidv4(),
            id_item: e.id,
            tipo: e.tipo.substring(0, 1),
            id_tipo: e.id_item,
            descricao: e.descricao,
            quantidade: parseFloat(e.quantidade),
            vlr_unit: parseFloat(e.vlr_unit),
            perc_desc: parseFloat(e.perc_desc),
            vlr_desc: parseFloat(e.vlr_desc),
            vlr_item: parseFloat(e.vlr_item),
            vlr_tot_prod_serv: parseFloat(e.vlr_total),
            vlr_icms_st: 0,
            vlr_ipi: 0,
            vlr_total: parseFloat(e.vlr_total),
            perc_desc_novo: 0,
            vlr_item_novo: parseFloat(e.vlr_item),
            vlr_desc_novo: 0,
            vlr_icms_st_novo: 0,
            vlr_ipi_novo: 0,
            vlr_tot_prod_serv_novo: parseFloat(e.vlr_total),
            vlr_total_novo: parseFloat(e.vlr_total),
          }))
        );
      } else {
        toggle();
      }
    }
  };

  const handleSetPercDescNovo = (v) => {
    if (v !== percDescNovo) {
      setRecalcularValores(true);
    }
    setPercDescNovo(v);
  };

  const handleSetVlrDescNovo = (v) => {
    if (v !== vlrDescNovo) {
      setRecalcularValores(true);
    }
    setVlrDescNovo(v);
  };

  const handleSetVlrTotProdServNovo = (v) => {
    if (v !== vlrTotProdServNovo) {
      setRecalcularValores(true);
    }
    setVlrTotProdServNovo(v);
  };

  const recalcularValoresDesconto = async (tipoCalculo) => {
    if (recalcularValores) {
      setLoadingRecalc(true);
      const calcularImpostos = regimeTribut !== "SIM" && origem === "VFX";

      let vlrDescPost;
      let tipoCalculoPost;
      if (tipoCalculo === "T") {
        tipoCalculoPost = "V";
        if (vlrTotProdServNovo < vlrTotProdServOrig) {
          vlrDescPost = roundFloat(vlrTotProdServOrig - vlrTotProdServNovo, 2);
        } else {
          vlrDescPost = 0;
        }
      } else {
        tipoCalculoPost = tipoCalculo;
        vlrDescPost = vlrDescNovo;
      }

      try {
        const payload = {
          calcular_por: tipoCalculoPost,
          perc_desconto: percDescNovo,
          vlr_desconto: vlrDescPost,
          vlr_tot_prod_serv: vlrTotProdServOrig,
          vlr_frete: vlrFreteOrig,
          calcular_impostos: calcularImpostos,
          id_cliente: idCliente,
          finalidade: "VENDA",
          modelo: 55,
          dentro_estab: true,
          itens: itens.map((e) => ({
            id_item: `${e.tipo}-${e.id_item}`,
            tipo: e.tipo,
            id_prod_serv: e.id_tipo,
            cfop: null,
            quantidade: parseFloat(e.quantidade ?? "0") ?? 0,
            vlr_item: parseFloat(e.vlr_item ?? "0") ?? 0,
            vlr_tot_prod_serv: parseFloat(e.vlr_tot_prod_serv ?? "0") ?? 0,
            vlr_desc: parseFloat(e.vlr_desc ?? "0") ?? 0,
            vlr_acre: 0,
            vlr_outros: 0,
          })),
        };

        const [ok, ret] = await UteisService.calcularDescontoGeral(payload);
        if (ok) {
          if (["P", "T"].includes(tipoCalculo)) {
            setVlrDescNovo(parseFloat(ret.vlr_desconto ?? "0") ?? 0);
          }
          if (["V", "T"].includes(tipoCalculo)) {
            setPercDescNovo(parseFloat(ret.perc_desconto ?? "0") ?? 0);
          }
          if (tipoCalculo !== "T") {
            setVlrTotProdServNovo(
              parseFloat(ret.vlr_tot_prod_serv ?? "0") ?? 0
            );
          }

          if (calcularImpostos) {
            setVlrTotIcmsStNovo(parseFloat(ret.vlr_tot_icms_st ?? "0") ?? 0);
            setVlrIpiNovo(parseFloat(ret.vlr_tot_ipi ?? "0") ?? 0);
          }
          setVlrTotalNovo(parseFloat(ret.vlr_total ?? "0") ?? 0);

          setVlrFreteNovo(parseFloat(ret.vlr_frete ?? "0") ?? 0);
          setItens(
            ret.itens.map((e) => {
              let [tipoItem, idItem] = e.id_item.split("-");
              idItem = parseInt(idItem);
              const itemOrig =
                itens.find(
                  (e) => e.tipo === tipoItem && e.id_item === idItem
                ) ?? {};

              let res = {
                ...itemOrig,
                vlr_item_novo: parseFloat(e.vlr_item ?? "0") ?? 0,
                perc_desc_novo: parseFloat(e.perc_desc ?? "0") ?? 0,
                vlr_desc_novo: parseFloat(e.vlr_desc ?? "0") ?? 0,
                vlr_tot_prod_serv_novo:
                  parseFloat(e.vlr_tot_prod_serv ?? "0") ?? 0,
                vlr_total_novo: parseFloat(e.vlr_total ?? "0") ?? 0,
                // vlr_acre: parseFloat(e.vlr_acre ?? "0") ?? 0,
              };

              if (calcularImpostos) {
                res.vlr_ipi_novo = parseFloat(e.vlr_ipi ?? "0") ?? 0;
                res.vlr_icms_st_novo = parseFloat(e.vlr_icms_st ?? "0") ?? 0;
              }

              return res;
            })
          );
        }

        setRecalcularValores(false);
      } finally {
        setLoadingRecalc(false);
      }
    }
  };

  const handleSubmit = async (senha) => {
    if (loadingRecalc) return false;

    const payload = {
      id_mov_cxa: idMovCaixaLoja,
      perc_desc: percDescNovo,
      vlr_desc: vlrDescNovo,
      vlr_ipi: vlrIpiNovo,
      vlr_tot_icms_st: vlrTotIcmsStNovo,
      vlr_frete: vlrFreteNovo,
      vlr_desc_frete: roundFloat(vlrFreteOrig - vlrFreteNovo, 2),
      vlr_total: vlrTotalNovo,
      senha_desconto: senha,
      itens: itens.map((e) => ({
        id_mov_item: e.id_item,
        tipo: e.tipo,
        vlr_item: e.vlr_item_novo,
        perc_desc: e.perc_desc_novo,
        vlr_desc: e.vlr_desc_novo,
        vlr_icms_st: e.vlr_icms_st_novo,
        vlr_ipi: e.vlr_ipi_novo,
        vlr_tot_prod_serv: e.vlr_tot_prod_serv_novo,
        vlr_total: e.vlr_total_novo,
      })),
    };
    setLoading(true);
    const [ok] = await CaixaLojaService.aplicarDesconto(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
    return ok;
  };

  const onConfirm = () => {
    if (loadingRecalc) return;

    console.log(vlrTotalNovo, vlrTotalOrig);
    if (vlrTotalNovo > vlrTotalOrig) {
      showWarning(
        "O novo valor total NÃO pode ser maior que o valor total atual. " +
          "Verifique os valores e tente novamente"
      );
      return;
    }

    if (vlrTotalNovo <= 0) {
      showWarning(
        "O novo valor total NÃO pode ser zero. Verifique os valores " +
          "e tente novamente"
      );
      return;
    }

    if (percDescNovo > descMaxCaixa) {
      toggleSenhaDesconto();
    } else {
      handleSubmit("");
    }
  };

  const toggleSenhaDesconto = () => setSenhaDescontoOpen(!senhaDescontoOpen);

  const checkToggle = async () => {
    const [ok] = await CaixaLojaService.verifPodeAplicarDesconto(
      idMovCaixaLoja
    );
    if (ok) toggle();
  };

  const descOrigem =
    origem === "VFX" ? "Venda" : origem === "VOS" ? "Ordem de Serviço" : origem;

  return (
    <>
      <LabelButton
        onClick={checkToggle}
        disabled={!["VFX", "VOS"].includes(origem)}
        disabledHint="Opção disponível no momento somente para Venda e O.S."
      >
        Aplicar Desconto
      </LabelButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Desconto Geral no Caixa Loja"
        number="0063_20"
        onClosed={limparDados}
        onBeforeOpen={() => {
          carregarParametros();
          carregarDados();
        }}
        onConfirm={onConfirm}
        loadingConfirm={loading}
        bodyStyle={{ paddingTop: "0.5rem" }}
      >
        <Row className="mb-2">
          <FixedField
            label="Movimento"
            value={`${descOrigem} ${idOrigem}`}
            horizontal
          />
        </Row>
        <Table
          data={itens}
          columns={columns}
          keyField="uuid"
          pageSize={12}
          paginated={false}
          showRegisterCount={false}
        />
        <Row className="pr-0">
          <Col md={6}>
            <Divider>Valores Atuais</Divider>
            <Row className="mb-2">
              <FixedField
                md={6}
                label="Desconto"
                value={
                  formatNumber(percDescOrig, true, 2, true) +
                  "% | " +
                  formatNumber(vlrDescOrig, true, 2)
                }
                horizontal
              />
              <FixedField
                md={6}
                label="Total Itens"
                value={formatNumber(vlrTotProdServOrig, true, 2)}
                horizontal
              />
            </Row>
            <Row>
              <FixedField
                md={3}
                label="ICMS ST"
                value={formatNumber(vlrTotIcmsStOrig, true, 2)}
                horizontal
              />
              <FixedField
                md={3}
                label="IPI"
                value={formatNumber(vlrIpiOrig, true, 2)}
                horizontal
              />
              <FixedField
                md={3}
                label="Frete"
                value={formatNumber(vlrFreteOrig, true, 2)}
                horizontal
              />
              <FixedField
                md={3}
                label="Total"
                value={formatNumber(vlrTotalOrig, true, 2)}
                horizontal
              />
            </Row>
          </Col>
          <Col md={6} className="pr-0">
            <Divider>Novos Valores</Divider>
            <Row className="mb-1">
              <NumberInput
                md={3}
                label="Desconto"
                value={percDescNovo}
                onChange={handleSetPercDescNovo}
                isPercentage
                onBlur={() => recalcularValoresDesconto("P")}
              />
              <NumberInput
                md={4}
                label="Vlr. Desc"
                value={vlrDescNovo}
                onChange={handleSetVlrDescNovo}
                onBlur={() => recalcularValoresDesconto("V")}
              />
              <NumberInput
                md={5}
                label="Total Itens"
                value={vlrTotProdServNovo}
                onChange={handleSetVlrTotProdServNovo}
                onBlur={() => recalcularValoresDesconto("T")}
                divClassName="pr-0"
              />
            </Row>
            <Row style={{ justifyContent: "space-between" }}>
              <FixedField
                md="auto"
                label="ICMS ST"
                value={formatNumber(vlrTotIcmsStNovo, true, 2)}
                horizontal
                divClassName="pr-0"
              />
              <FixedField
                md="auto"
                label="IPI"
                value={formatNumber(vlrIpiNovo, true, 2)}
                horizontal
                divClassName="px-0"
              />
              <FixedField
                md="auto"
                label="Frete"
                value={formatNumber(vlrFreteNovo, true, 2)}
                horizontal
                divClassName="px-0"
              />
              <FixedField
                md="auto"
                label="Total"
                value={formatNumber(vlrTotalNovo, true, 2)}
                horizontal
                divClassName="pr-0"
              />
            </Row>
          </Col>
        </Row>
      </ModalBase>
      <SenhaModal
        isOpen={senhaDescontoOpen}
        toggle={toggleSenhaDesconto}
        title="Desconto do Caixa"
        onConfirm={handleSubmit}
      />
    </>
  );
};
