import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  IconButton,
  IntegerFormInput,
  ModalBase,
  Table,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { CgChevronDoubleDownR } from "react-icons/cg";
import BotaoPesq from "../../../../../components/form/BotaoPesq";
import { apiGetV2 } from "../../../../../apiV2";
import { showWarning } from "../../../../../components/AlertaModal";

const columns = [
  { dataField: "id", text: "#", align: "center", headerAlign: "center" },
  {
    dataField: "nome_fornecedor",
    text: "Fornecedor",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_fornecedor),
  },
  {
    dataField: "numero",
    text: "Número",
    align: "center",
    headerAlign: "center",
    formatter: (c, row) => `${c}-${row.serie}`,
  },
  {
    dataField: "vlr_desconto",
    text: "Desconto",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_frete",
    text: "Frete",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columnsItens = [
  {
    dataField: "nome_produto",
    text: "Produto",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
  },
  {
    dataField: "codigo_barras",
    text: "Código de Barras",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "referencia",
    text: "Referência",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "tamanho",
    text: "Tamanho",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "cor_produto",
    text: "Cor",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "unidade_produto",
    text: "Unidade",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "quantidade",
    text: "Qtd. Etiquetas",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "preco_venda",
    text: "Preço de Venda",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const EtiquetasNfEntradaModal = ({ importarProdutos }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idNfe, setIdNfe] = useState(null);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [chaveAcesso, setChaveAcesso] = useState(null);
  const [numero, setNumero] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [itens, setItens] = useState([]);
  const [loading, setLoading] = useState(null);
  const [selected, setSelected] = useState(null);

  const limparDados = () => {
    setIdNfe(null);
    setIdFornecedor(null);
    setNumero(null);
    setChaveAcesso(null);
    setDataIni(new Date());
    setDataFim(new Date());
    setDados([]);
    setItens([]);
    setSelected(null);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const buscarDados = async () => {
    if (!isNaN(idNfe) && idNfe > 9999999999) {
      showWarning("O tamanho do ID da NFe não pode exceder 10 dígitos.");
      return false, [];
    }

    const params = {
      id_nfe: idNfe,
      id_fornecedor: idFornecedor,
      chave_acesso: chaveAcesso,
      numero: numero,
      data_ini: dataIni,
      data_fim: dataFim,
    };

    return await apiGetV2(`/nfe_entrada/listar_nfes_impr_etiqueta/`, params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret : []);
    setItens([]);
    setSelected(null);
    setLoading(false);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const importarProdutosNF = () => {
    importarProdutos(itens);
    toastr.success("Sucesso", "Produtos da Nota Fiscal adicionados.");
  };

  const onConfirm = () => {
    if ([0, null, undefined].includes(selected)) {
      showWarning("Por favor, selecione uma Nota Fiscal");
      return false;
    }
    importarProdutosNF();
  };

  const onRowDoubleClick = () => importarProdutosNF();

  const inclusaoRapida = async () => {
    const [ok, ret] = await buscarDados();
    if (ok) {
      if (ret.length > 0) {
        importarProdutos(ret[0].itens);
        setIdNfe(null);
      } else {
        toggle();
      }
    }
  };

  const handleSelect = (id, is, row) => {
    setSelected(id);
    setItens(is ? row?.itens ?? [] : []);
  };

  return (
    <>
      <IntegerFormInput
        md={3}
        label="Código NF Entrada"
        defaultValue={!isOpen ? idNfe : null}
        onChange={(v) => !isOpen && setIdNfe(v)}
        additionalButton={
          <>
            <IconButton
              icon={CgChevronDoubleDownR}
              hint="Imprimir Etiquetas de Produtos da NF Entrada"
              style={{ height: "max-content" }}
              className="ml-2"
              size={45}
              onClick={inclusaoRapida}
              disabled={[0, null, undefined, ""].includes(idNfe) || isOpen}
              disabledHint="Informe o código da NF Entrada"
            />
            <BotaoPesq onClick={toggle} />
          </>
        }
      />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Notas Fiscais de Entrada"
        number="0073_1"
        onConfirm={onConfirm}
        onBeforeClose={limparDados}
      >
        <Row>
          <TextInput
            md={6}
            label="Chave de Acesso da NFe"
            value={chaveAcesso}
            onChange={(e, v) => setChaveAcesso(v)}
            inputStyle={{ textAlign: "center" }}
          />
          <IntegerFormInput
            md={2}
            label="ID NFe"
            defaultValue={idNfe}
            onChange={setIdNfe}
            maxLength={10}
          />
          <IntegerFormInput
            md={3}
            label="Número"
            defaultValue={numero}
            onChange={setNumero}
          />
        </Row>
        <Row>
          <AsyncComboBox
            label="Fornecedor"
            md={5}
            isConcatField
            concatModelName="fornecedor"
            isSearchable
            isClearable
            defaultValue={idFornecedor}
            onChange={(sel) => setIdFornecedor(sel?.value)}
          />
          <FiltroPeriodoDatas onChange={handleDate} md={10} />
        </Row>
        <Row className="mb-3">
          <BotaoPesquisar md="auto" onClick={carregarDados} loading={loading} />
        </Row>
        <Table
          data={dados}
          columns={columns}
          paginated={false}
          onSelect={handleSelect}
          pageSize={7}
          onRowDoubleClick={onRowDoubleClick}
          clearSelectionOnUpdate
          showRegisterCount={false}
        />
        <Table
          data={itens}
          columns={columnsItens}
          paginated={false}
          pageSize={7}
          showRegisterCount={false}
        />
      </ModalBase>
    </>
  );
};
