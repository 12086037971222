import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/pagar/duplic_pag";
const URL_BASE_GERENCIAMENTO = `${URL_BASE}/gerenciamento`;
const URL_BASE_LANCAMENTOS = `${URL_BASE_GERENCIAMENTO}/lancamentos`;

const GerencContasPagarService = {
  alterar: async (payload) =>
    await apiPutV2(`${URL_BASE_GERENCIAMENTO}/alterar/`, payload),
  alterarMovPlanoConta: async (payload) =>
    await apiPutV2(
      `${URL_BASE_GERENCIAMENTO}/alterar_mov_plano_conta/`,
      payload
    ),
  buscar: async (idTitulo) =>
    await apiGetV2(`${URL_BASE_GERENCIAMENTO}/buscar/${idTitulo}/`),
  buscarStatus: async (idTitulo) =>
    await apiGetV2(`${URL_BASE_GERENCIAMENTO}/buscar_status/${idTitulo}/`),
  excluir: async (payload) =>
    await apiPutV2(`${URL_BASE_GERENCIAMENTO}/excluir/`, payload),
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir/`, payload),
  lancamentos: {
    incluir: async (payload) =>
      await apiPostV2(`${URL_BASE_LANCAMENTOS}/incluir/`, payload),
    excluir: async (idTransacao) =>
      await apiDeleteV2(`${URL_BASE_LANCAMENTOS}/excluir/${idTransacao}/`),
  },
  listar: async (params) =>
    await apiGetV2(`${URL_BASE_GERENCIAMENTO}/listar/`, params),
  liquidar: async (payload) =>
    await apiPostV2(`${URL_BASE}/liquidar/`, payload),
};

export default GerencContasPagarService;
