import React, { useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { MoreHorizontal, MoreVertical } from "react-feather";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

export const MoreDropdown = ({
  children,
  onToggle,
  disabled,
  disabledHint = "Sem ações disponíveis",
  horizontal = true,
  dropdownDirection = "left",
}) => {
  const [id] = useState("more-" + Math.floor(Math.random() * Date.now()));
  const Icon = horizontal ? MoreHorizontal : MoreVertical;
  return !disabled ? (
    <UncontrolledDropdown direction={dropdownDirection} onToggle={onToggle}>
      <DropdownToggle
        style={{
          background: "none",
          border: "none",
          marginLeft: "auto",
          padding: 0,
          cursor: "pointer",
        }}
        tabIndex="-1"
      >
        <Icon size={16} color="black" style={{ cursor: "pointer" }} />
      </DropdownToggle>
      <DropdownMenu>{children}</DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <>
      <Icon size={16} color="silver" id={id} />
      <UncontrolledTooltip target={id}>{disabledHint}</UncontrolledTooltip>
    </>
  );
};
