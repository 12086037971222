import React from "react";
import { useState } from "react";
import {
  modalTitleEstoqueInter,
  routesBaseEstoqueInter,
} from "../EstoqueInter";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { AsyncComboBox, TextInput } from "../../../../../components";
import { showWarning } from "../../../../../components/AlertaModal";

export const CadastroEstoqueInterModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [idRota, setIdRota] = useState(null);
  const [nome, setNome] = useState("");

  const limparDados = () => {
    setIdRota(null);
    setNome("");
  };

  const fetchData = (data) => {
    setIdRota(data.id_rota_mob);
    setNome(data.nome);
  };

  const submitPayload = (action) => {
    if ([0, null, undefined].includes(idRota)) {
      showWarning("Por favor, informe a Rota");
      return;
    }

    if (["", null, undefined].includes(nome)) {
      showWarning("Por favor, informe o Nome");
      return;
    }

    const payload = {
      id_rota: idRota,
      nome: nome,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_estoque_inter: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleEstoqueInter}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseEstoqueInter}
      number="0096_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <AsyncComboBox
          isConcatField
          concatModelName="rota_mob"
          isSearchable
          md={6}
          label="Rota"
          onChange={(s) => setIdRota(s?.value)}
          defaultValue={idRota}
          defaultOptions
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
        />
      </Row>
    </ModalCadastroV2>
  );
};
