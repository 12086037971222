import React from "react";
import { Card, DropdownItem } from "reactstrap";
import {
  downloadFileFromBlob,
  naturalSortDate,
} from "../../../../../../coreUtils";
import RemessaBancariaService from "../../../../../../services/bancario/RemessaBancariaService";
import { gerarRemessa } from "../../components/GerarRemessa";
import { ConfirmarReabRemessaModal } from "./ConfirmarReabRemessaModal";
import { DetalhamentoRemessaModal } from "./DetalhamentoRemessaModal";
import { docPrintReport } from "../../../../../../pdf";
import { MoreDropdown, Table } from "../../../../../../components";

const DropdownAcoes = ({ idRemessa, notifyEvent }) => {
  const imprimir = async () => {
    const [ok, ret] = await RemessaBancariaService.buscarRemessa(
      idRemessa,
      true
    );
    if (ok) {
      await docPrintReport(
        "/bancario/remessa/",
        {
          dados_conta: ret.dados_conta,
          duplicatas: ret.duplicatas,
        },
        "0002_11"
      );
    }
  };

  const gerarArquivo = async () => {
    const [ok, ret] = await RemessaBancariaService.buscarRemessa(idRemessa);
    if (ok) {
      const [content, fileName] = gerarRemessa(ret.dados_conta, ret.duplicatas);
      downloadFileFromBlob(content, fileName);
    }
  };

  return (
    <>
      <MoreDropdown horizontal>
        <DropdownItem
          onClick={() =>
            document.getElementById(`det-rem-${idRemessa}`).click()
          }
        >
          Detalhar
        </DropdownItem>
        <DropdownItem onClick={imprimir}>Imprimir</DropdownItem>
        <DropdownItem onClick={gerarArquivo}>Gerar Arquivo</DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          onClick={() =>
            document.getElementById(`reab-rem-${idRemessa}`).click()
          }
        >
          Reabrir Remessa Totalmente
        </DropdownItem>
      </MoreDropdown>
      <DetalhamentoRemessaModal id={idRemessa} />
      <ConfirmarReabRemessaModal id={idRemessa} notifyEvent={notifyEvent} />
    </>
  );
};

export const GerenciamentoRemessaGrid = ({ data, notifyEvent }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "conta_bancaria",
      text: "Conta Bancária",
      align: "left",
    },

    {
      dataField: "data_remessa",
      text: "Data da Remessa",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      sortable: true,
    },
    {
      dataField: "num_titulos",
      text: "Qtd. Títulos",
      align: "center",
    },
    {
      dataField: "status_rem",
      text: "Status",
      align: "center",
    },
    {
      dataField: "det",
      dummy: true,
      align: "center",
      cellContentTag: null,
      formatter: (c, row) => (
        <DropdownAcoes idRemessa={row.id} notifyEvent={notifyEvent} />
      ),
    },
  ];
  return (
    <Card body>
      <Table data={data} columns={columns} />
    </Card>
  );
};
