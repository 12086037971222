import React, { useState } from "react";
import {
  ModalBase,
  NumberInput,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { RadioItem } from "../../../../../components/RadioGroup";
import { Row } from "reactstrap";

export const AlterarCreditoClienteModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
  nomeCliente,
}) => {
  const [creditoAtual, setCreditoAtual] = useState(0);
  const [observ, setObserv] = useState("");
  const [operacao, setOperacao] = useState("+");
  const [valor, setValor] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {};

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Alterar Crédito do Cliente"
      number="0068_4"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row>
        <TextInput
          label="Cliente"
          value={formatValueFromAPI(nomeCliente, selected)}
          disabled
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Crédito Atual do Cliente"
          value={creditoAtual}
          disabled
        />
      </Row>
      <Row>
        <TextInput label="Observação" value={observ} onChange={setObserv} />
      </Row>
      <Row>
        <RadioGroup label="Operação" value={operacao} onChange={setOperacao}>
          <RadioItem label="Acréscimo" value="+" />
          <RadioItem label="Decréscimo" value="-" />
        </RadioGroup>
        <NumberInput md={3} label="Valor" value={valor} onChange={setValor} />
        <NumberInput
          md={3}
          label="Resultado"
          value={creditoAtual + valor * (operacao === "+" ? 1 : -1)}
          disabled
        />
      </Row>
    </ModalBase>
  );
};
