import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ult_numero: 0,
  ult_lote: 0,
  camin_salvar_xml: "",
  sigla_transportadora: "",
};

export const cteSlice = createSlice({
  name: "cte_slice",
  initialState: initialState,
  reducers: {
    setupCte: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (
          ["camin_salvar_xml", "sigla_transportadora"].includes(key) &&
          value === null
        ) {
          value = "";
        }

        if (key === "sigla_transportadora") {
          value = value.trim();
        }

        state[key] = value;
      });
      return state;
    },
    setUltNumero: (state, action) => {
      state.ult_numero = action.payload;
    },
    setUltLote: (state, action) => {
      state.ult_lote = action.payload;
    },
    setCaminSalvarXml: (state, action) => {
      state.camin_salvar_xml = action.payload;
    },
    setSiglaTransportadora: (state, action) => {
      state.sigla_transportadora = action.payload;
    },
  },
});

export const {
  setupCte,
  setUltNumero,
  setUltLote,
  setCaminSalvarXml,
  setSiglaTransportadora,
} = cteSlice.actions;

export default cteSlice.reducer;
