import React from "react";
import { Table } from "../../../../../../components";
import {
  formatarValor,
  formatNumber,
  naturalSort,
} from "../../../../../../coreUtils";

export const ProdutosSugestaoCompraGrid = ({
  dados,
  atualizarDadosItem,
  recalcularValoresGrade,
}) => {
  const columns = [
    // Essa coluna vai sinalizar se o produto foi incluído manualmente ou por
    // sugestão
    { dataField: "tipo", text: "Tipo", align: "center", sortable: true },
    {
      dataField: "id_produto",
      text: "Produto",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_produto",
      text: "Descrição",
      align: "left",
      sortable: true,
    },
    {
      dataField: "unidade_produto",
      text: "UN",
      align: "center",
      sortable: true,
    },
    {
      dataField: "custo_unit",
      text: "Custo",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_venda_periodo",
      text: "Qtd. Vendida",
      align: "center",
      formatter: (c, row) => formatNumber(c, row.qtd_casas_decimais_qtd, 0),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_media_dia",
      text: "Por Dia",
      align: "center",
      formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_dias_suprir",
      text: "Por 30 Dias",
      align: "center",
      formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "estoque_produto",
      text: "Estoque",
      align: "center",
      formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_sugerida_sis",
      text: "Qtd. Sugerida",
      align: "center",
      formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_compra",
      text: "Qtd. Compra",
      align: "center",
      formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
      sortable: true,
      sortFunc: naturalSort,
      editable: true,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: (row) => row.qtd_casas_decimais_qtd,
      colWidth: "75px",
      alwaysShowEditor: true,
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      paginated={false}
      showRegisterCount={false}
    />
  );
};
