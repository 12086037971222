import React from "react";
import { MoreDropdown, Table } from "../../../../components";
import { formatNumber } from "../../../../coreUtils";
import { DropdownItem } from "reactstrap";

export const CaixaLojaGrid = ({
  dados,
  setSelected,
  abrirDetalhes,
  solicSenhaReabrirVenda,
}) => {
  const columns = [
    {
      dataField: "act",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <MoreDropdown horizontal onToggle={() => {}}>
          {row.desc_origem === "Venda" && (
            <DropdownItem onClick={() => solicSenhaReabrirVenda(row.id)}>
              Reabrir
            </DropdownItem>
          )}
          <DropdownItem onClick={() => abrirDetalhes(row)}>
            Detalhe
          </DropdownItem>
        </MoreDropdown>
      ),
    },
    {
      dataField: "desc_origem",
      text: "Origem",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nro_origem",
      text: "Número",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_mov",
      text: "Data/Hora",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => `${c} - ${row.hora_mov}`,
    },
    {
      dataField: "id_cliente",
      text: "Cliente",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Nome",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      onSelect={setSelected}
      paginated={false}
    />
  );
};
