import axios from "axios";
import { toastr } from "react-redux-toastr";
import settings from "./settings";
import { showError, showSuccess } from "./components/AlertaModal";
import { formatDateISO, isDevEnv } from "./coreUtils";
import qs from "qs";

export const apiWhats = axios.create({
  baseURL: isDevEnv() ? settings.WHATSAPP_API_DEV : settings.WHATSAPP_API,
});

export const tratarParams = (params) =>
  Object.entries(params)
    .filter(([key, value]) => !["", null, undefined].includes(value))
    .reduce((acc, [key, value]) => {
      if (value instanceof Date) {
        value = formatDateISO(value);
      }
      return { ...acc, [key]: value };
    }, {});

const processaRetorno = ({ data }, options) => {
  if (options?.successMessage) {
    if (options?.successMessageType === "modal") {
      showSuccess(data.msg);
    } else {
      toastr.success("Sucesso", data.msg);
    }
  }

  return [true, data];
};

const processaErro = (err, errorMessage = true) => {
  let msgErro = err.message;
  let statusErro = err.response?.status ?? err.status;
  let codigoErro = `SERVER_ERROR_${statusErro}`;
  if (!statusErro) {
    msgErro =
      "Não foi possível conectar.\n" +
      "Por favor, verifique sua conexão com a Internet e tente novamente.";
    codigoErro = "SemConexao";
  }

  if (errorMessage) showError(msgErro, codigoErro);
  return [false, { msg: msgErro, id_err: codigoErro }];
};

const apiWhatsGet = async (url, params, options) =>
  await apiWhats
    .get(url, {
      params: params ? tratarParams(params) : undefined,
      paramsSerializer: (par) => qs.stringify(par, { arrayFormat: "repeat" }),
    })
    .then((ret) => processaRetorno(ret, { errorMessage: true, ...options }))
    .catch((err) => processaErro(err, options?.errorMessage ?? true));

const apiWhatsPost = async (url, payload, options) =>
  await apiWhats
    .post(url, payload)
    .then((ret) =>
      processaRetorno(ret, {
        successMessage: true,
        errorMessage: true,
        ...options,
      })
    )
    .catch((err) => processaErro(err));

const apiWhatsPut = async (url, payload, options) =>
  await apiWhats
    .put(url, payload)
    .then((ret) =>
      processaRetorno(ret, {
        successMessage: true,
        errorMessage: true,
        ...options,
      })
    )
    .catch((err) => processaErro(err));

const apiWhatsDelete = async (url) =>
  await apiWhats
    .delete(url)
    .then((ret) =>
      processaRetorno(ret, { successMessage: true, errorMessage: true })
    )
    .catch((err) => processaErro(err));

export { apiWhatsGet, apiWhatsPost, apiWhatsPut, apiWhatsDelete };
