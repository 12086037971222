import React, { useState } from "react";
import { FormButton, ModalBase, TextInput } from "../../../../../components";
import { Row } from "reactstrap";
import DownloadManifNfeService from "../../../../../services/compras/DownloadManifNfeService";
import { showWarning } from "../../../../../components/AlertaModal";

export const ConsultaChaveAcessoModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [chaveConsulta, setChaveConsulta] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setChaveConsulta("");
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(chaveConsulta)) {
      showWarning("Por favor, informe a Chave de Acesso da NFe para consulta");
      return;
    }
    setLoading(true);
    const ok = await DownloadManifNfeService.consultaChave(chaveConsulta);
    if (ok) {
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        color="secondary"
        divClassName="ml-auto"
        onClick={toggle}
      >
        Via Chave de Acesso
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Consulta de NFe por Chave de Acesso"
        number="0101_4"
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <TextInput
            colClassName="ml-auto"
            md={12}
            label="Chave de Acesso"
            onChange={(_, v) => setChaveConsulta(v)}
            inputStyle={{ textAlign: "center" }}
            value={chaveConsulta}
          />
        </Row>
      </ModalBase>
    </>
  );
};
