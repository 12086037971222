import React from "react";
import { DataTable } from "../../../../../components/DataTable";
import { TableOcorrencias } from "./TableOcorrencias";

export const TableTipos = ({ data }) => {
  const columns = [
    {
      dataField: "tipo",
      text: "Tipo",
      align: "left",
      headerAlign: "left",
      sort: false,
    },
    {
      dataField: "funcionalidade",
      text: "Funcionalidade",
      align: "left",
      headerAlign: "left",
      sort: false,
    },
    {
      dataField: "num_ocorrencias",
      text: "Nº de Ocorrências",
      align: "center",
      headerAlign: "center",
      sort: false,
    },
    {
      dataField: "valor_ocorrencias",
      text: "Valor das Ocorrências",
      align: "right",
      headerAlign: "right",
      sort: false,
    },
  ];

  return (
    <DataTable
      keyField="tipo"
      data={data}
      columns={columns}
      expandRow={{
        className: "selected-expanded-row",
        onlyOneExpanding: true,
        renderer: (row) => {
          return <TableOcorrencias data={row.ocorrencias} />;
        },
      }}
      rowStyle={{ cursor: "pointer" }}
      readOnly
      hideCount
      striped={false}
    />
  );
};
