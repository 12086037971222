import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id_tipo_doc_nfe_venda: null,
  id_plano_ctas_pad_duplic_rec: null,
  id_plano_ctas_pad_receb_dinheiro: null,
  id_tipo_doc_remessa_venda: null,
  id_tipo_doc_recarga: null,
  id_tipo_doc_retorno_remessa: null,
  id_cliente_dest_nfe_remessa: null,
  id_tipo_doc_nfe_bonificacao: null,
  id_motivo_nao_atend_encer_jornada: null,
  id_plano_ctas_pad_pag_comis: null,
  permite_abert_jorn_varios_dias_mesma_data: false,
  vlr_minimo_vda_boleto: 0,
  permite_quantidades_decimais: false,
  num_casas_decimais_quantidade: 0,
  quantidade_maxima_por_produto_solic_recarga: 750,
  mdfe_automatico_abre_fech_dia: false,
  bloquear_venda_boleto_para_cliente_inadimplente: true,
  id_plano_ctas_pad_troca: null,
  id_plano_ctas_pad_bonificacao: null,
  utiliza_bonificacao: true,
  saca_comissao_por: "DURACAO",
  transparece_desc_acre_nf: false,
  permite_recebimento_cred_rotativo: false,
  permite_recebimento_pix: false,
  permite_recarga_instantanea: true,
  habilita_cadastro_cliente_app: false,
  permite_escolha_emi_nfe_final_venda: false,
  emite_nfe_complementar_dif_valor: false,
  id_tipo_doc_nfe_complementar_dif_valor: null,
  gerar_nfe_complementar_dif_valor_por: "VENDA",
  num_dias_bloqueia_boleto: 2,
  habilita_desc_sobre_total: false,
  forma_define_preco_bonificacao: "FPVD",
  vincula_multiplos_veiculos_por_rota: false,
  permite_incluir_cliente_mais_de_uma_rota: false,
  imprimir_estoque_no_encerramento: false,
  bloq_solic_recarga_sem_estoque: false,
  ver_cadastro_produto_app: false,
  imprimir_canhoto_boleto_nf_topo: false,
  existe_redutor_vlr_troca: false,
  permite_usar_saldo_flex_na_troca_paga: false,
  zerar_flex_ao_virar_mes: true,
  utiliza_conferencia_recarga: false,
};

export const appVendaDiretaSlice = createSlice({
  name: "app_venda_direta_slice",
  initialState: initialState,
  reducers: {
    setupAppVendaDireta: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (["vlr_minimo_vda_boleto"].includes(key)) {
          value = parseFloat(value);
        }
        state[key] = value;
      });
      return state;
    },
    setIdTipoDocNfeVenda: (state, action) => {
      state.id_tipo_doc_nfe_venda = action.payload;
    },
    setIdPlanoCtasPadDuplicRec: (state, action) => {
      state.id_plano_ctas_pad_duplic_rec = action.payload;
    },
    setIdPlanoCtasPadRecebDinheiro: (state, action) => {
      state.id_plano_ctas_pad_receb_dinheiro = action.payload;
    },
    setIdTipoDocRemessaVenda: (state, action) => {
      state.id_tipo_doc_remessa_venda = action.payload;
    },
    setIdTipoDocRecarga: (state, action) => {
      state.id_tipo_doc_recarga = action.payload;
    },
    setIdTipoDocRetornoRemessa: (state, action) => {
      state.id_tipo_doc_retorno_remessa = action.payload;
    },
    setIdClienteDestNfeRemessa: (state, action) => {
      state.id_cliente_dest_nfe_remessa = action.payload;
    },
    setIdTipoDocNfeBonificacao: (state, action) => {
      state.id_tipo_doc_nfe_bonificacao = action.payload;
    },
    setIdMotivoNaoAtendEncerJornada: (state, action) => {
      state.id_motivo_nao_atend_encer_jornada = action.payload;
    },
    setIdPlanoCtasPadPagComis: (state, action) => {
      state.id_plano_ctas_pad_pag_comis = action.payload;
    },
    setPermiteAbertJornVariosDiasMesmaData: (state, action) => {
      state.permite_abert_jorn_varios_dias_mesma_data = action.payload;
    },
    setVlrMinimoVdaBoleto: (state, action) => {
      state.vlr_minimo_vda_boleto = action.payload;
    },
    setPermiteQuantidadesDecimais: (state, action) => {
      state.permite_quantidades_decimais = action.payload;
    },
    setNumCasasDecimaisQuantidade: (state, action) => {
      state.num_casas_decimais_quantidade = action.payload;
    },
    setQuantidadeMaximaPorProdutoSolicRecarga: (state, action) => {
      state.quantidade_maxima_por_produto_solic_recarga = action.payload;
    },
    setMdfeAutomaticoAbreFechDia: (state, action) => {
      state.mdfe_automatico_abre_fech_dia = action.payload;
    },
    setBloquearVendaBoletoParaClienteInadimplente: (state, action) => {
      state.bloquear_venda_boleto_para_cliente_inadimplente = action.payload;
    },
    setIdPlanoCtasPadTroca: (state, action) => {
      state.id_plano_ctas_pad_troca = action.payload;
    },
    setIdPlanoCtasPadBonificacao: (state, action) => {
      state.id_plano_ctas_pad_bonificacao = action.payload;
    },
    setUtilizaBonificacao: (state, action) => {
      state.utiliza_bonificacao = action.payload;
    },
    setSacaComissaoPor: (state, action) => {
      state.saca_comissao_por = action.payload;
    },
    setTranspareceDescAcreNf: (state, action) => {
      state.transparece_desc_acre_nf = action.payload;
    },
    setPermiteRecebimentoCredRotativo: (state, action) => {
      state.permite_recebimento_cred_rotativo = action.payload;
    },
    setPermiteRecebimentoPix: (state, action) => {
      state.permite_recebimento_pix = action.payload;
    },
    setPermiteRecargaInstantanea: (state, action) => {
      state.permite_recarga_instantanea = action.payload;
    },
    setHabilitaCadastroClienteApp: (state, action) => {
      state.habilita_cadastro_cliente_app = action.payload;
    },
    setPermiteEscolhaEmiNfeFinalVenda: (state, action) => {
      state.permite_escolha_emi_nfe_final_venda = action.payload;
    },
    setEmiteNfeComplementarDifValor: (state, action) => {
      state.emite_nfe_complementar_dif_valor = action.payload;
    },
    setIdTipoDocNfeComplementarDifValor: (state, action) => {
      state.id_tipo_doc_nfe_complementar_dif_valor = action.payload;
    },
    setGerarNfeComplementarDifValorPor: (state, action) => {
      state.gerar_nfe_complementar_dif_valor_por = action.payload;
    },
    setNumDiasBloqueiaBoleto: (state, action) => {
      state.num_dias_bloqueia_boleto = action.payload;
    },
    setHabilitaDescSobreTotal: (state, action) => {
      state.habilita_desc_sobre_total = action.payload;
    },
    setFormaDefinePrecoBonificacao: (state, action) => {
      state.forma_define_preco_bonificacao = action.payload;
    },
    setVinculaMultiplosVeiculosPorRota: (state, action) => {
      state.vincula_multiplos_veiculos_por_rota = action.payload;
    },
    setPermiteIncluirClienteMaisDeUmaRota: (state, action) => {
      state.permite_incluir_cliente_mais_de_uma_rota = action.payload;
    },
    setImprimirEstoqueNoEncerramento: (state, action) => {
      state.imprimir_estoque_no_encerramento = action.payload;
    },
    setBloqSolicRecargaSemEstoque: (state, action) => {
      state.bloq_solic_recarga_sem_estoque = action.payload;
    },
    setVerCadastroProdutoApp: (state, action) => {
      state.ver_cadastro_produto_app = action.payload;
    },
    setImprimirCanhotoBoletoNfTopo: (state, action) => {
      state.imprimir_canhoto_boleto_nf_topo = action.payload;
    },
    setExisteRedutorVlrTroca: (state, action) => {
      state.existe_redutor_vlr_troca = action.payload;
    },
    setPermiteUsarSaldoFlexNaTrocaPaga: (state, action) => {
      state.permite_usar_saldo_flex_na_troca_paga = action.payload;
    },
    setZerarFlexAoVirarMes: (state, action) => {
      state.zerar_flex_ao_virar_mes = action.payload;
    },
    setUtilizaConferenciaRecarga: (state, action) => {
      state.utiliza_conferencia_recarga = action.payload;
    },
  },
});

export const {
  setupAppVendaDireta,
  setIdTipoDocNfeVenda,
  setIdPlanoCtasPadDuplicRec,
  setIdPlanoCtasPadRecebDinheiro,
  setIdTipoDocRemessaVenda,
  setIdTipoDocRecarga,
  setIdTipoDocRetornoRemessa,
  setIdClienteDestNfeRemessa,
  setIdTipoDocNfeBonificacao,
  setIdMotivoNaoAtendEncerJornada,
  setIdPlanoCtasPadPagComis,
  setPermiteAbertJornVariosDiasMesmaData,
  setVlrMinimoVdaBoleto,
  setPermiteQuantidadesDecimais,
  setNumCasasDecimaisQuantidade,
  setQuantidadeMaximaPorProdutoSolicRecarga,
  setFormaDefinePrecoBonificacao,
  setMdfeAutomaticoAbreFechDia,
  setBloquearVendaBoletoParaClienteInadimplente,
  setIdPlanoCtasPadTroca,
  setIdPlanoCtasPadBonificacao,
  setUtilizaBonificacao,
  setSacaComissaoPor,
  setTranspareceDescAcreNf,
  setPermiteRecebimentoCredRotativo,
  setPermiteRecebimentoPix,
  setPermiteRecargaInstantanea,
  setHabilitaCadastroClienteApp,
  setPermiteEscolhaEmiNfeFinalVenda,
  setEmiteNfeComplementarDifValor,
  setIdTipoDocNfeComplementarDifValor,
  setNumDiasBloqueiaBoleto,
  setHabilitaDescSobreTotal,
  setPermiteIncluirClienteMaisDeUmaRota,
  setVinculaMultiplosVeiculosPorRota,
  setImprimirEstoqueNoEncerramento,
  setBloqSolicRecargaSemEstoque,
  setVerCadastroProdutoApp,
  setGerarNfeComplementarDifValorPor,
  setImprimirCanhotoBoletoNfTopo,
  setExisteRedutorVlrTroca,
  setPermiteUsarSaldoFlexNaTrocaPaga,
  setZerarFlexAoVirarMes,
  setUtilizaConferenciaRecarga,
} = appVendaDiretaSlice.actions;

export default appVendaDiretaSlice.reducer;
