import React from "react";
import { Table, TableCheck } from "../../../../../components";
import { iconeAlterar } from "../../../../../components/cadastro/acoesCadastro";
import { dropdownAcoes } from "../../../../../components/cadastro";

export const MeioPagtoGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    { dataField: "nome", text: "Nome", align: "left" },
    { dataField: "cnpj", text: "CNPJ", align: "center" },
    {
      dataField: "tem_tef",
      text: "Tem TEF",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return <Table data={data} columns={columns} onSelect={setSelected} />;
};
