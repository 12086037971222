import React from "react";
import { Table } from "../../../../../components/Table";
import { IconButton } from "../../../../../components";
import { AiOutlineHistory } from "react-icons/ai";
import { historicoVeiculoRoute } from "../../../../../routes/modules/ordem_servico";

const VeiculosClienteGrid = ({ veiculos = [] }) => {
  const columns = [
    {
      dataField: "placa",
      text: "Placa",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_veiculo",
      text: "Veículo",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "modelo",
      text: "Modelo",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "ano",
      text: "Ano",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "chassi",
      text: "Chassi",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "descricao_combustivel",
      text: "Combustível",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "cor",
      text: "Cor",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "act",
      text: "Histórico",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <IconButton
          icon={AiOutlineHistory}
          onClick={() => historicoVeiculo(row.placa)}
          hint="Histórico do Veículo"
          tooltipPlacement="left"
        />
      ),
    },
  ];

  const historicoVeiculo = (placa) => {
    const historico = window.open(historicoVeiculoRoute.path);

    historico.window.parameters = JSON.stringify({
      placa: placa,
    });
  };

  return (
    <Table
      columns={columns}
      data={veiculos}
      pageSize={5}
      paginated={false}
      showRegisterCount={false}
    />
  );
};

export default VeiculosClienteGrid;
