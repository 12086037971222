import React from "react";
import { Table, TableCheck } from "../../../../../components";
import { iconeAlterar } from "../../../../../components/cadastro/acoesCadastro";
import { dropdownAcoes } from "../../../../../components/cadastro";

export const CadastroBancoGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    { dataField: "nome", text: "Nome", align: "left" },
    { dataField: "cod_bc", text: "Cód. Bacen", align: "center" },
    {
      dataField: "status",
      text: "Ativo",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return <Table data={data} columns={columns} onSelect={setSelected} onRowDoubleClick={alterar}/>;
};
