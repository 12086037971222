import React from "react";
import { useState } from "react";
import {
  ComboBox,
  FormCheckbox,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitlePlanoContas, routesBasePlanoContas } from "../PlanoContas";
import { RadioItem } from "../../../../../components/RadioGroup";

export const PlanoContasModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [descricao, setDescricao] = useState("");
  const [vinculacao, setVinculacao] = useState(null);
  const [nivel, setNivel] = useState(1);
  const [aceitaLcto, setAceitaLcto] = useState(false);
  const [tipoConta, setTipoConta] = useState("OUT");
  const [consideraDre, setConsideraDre] = useState(false);
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setDescricao("");
    setVinculacao(null);
    setNivel(1);
    setAceitaLcto(false);
    setTipoConta("OUT");
    setConsideraDre(false);
    setAtivo(true);
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setVinculacao(data.cod_acima);
    setNivel(data.nivel);
    setAceitaLcto(data.aceita_lcto);
    setTipoConta(data.tipo_conta);
    setConsideraDre(data.considera_dre);
    setAtivo(data.ativo);
  };

  const submitPayload = (action) => {
    const payload = {
      descricao: descricao,
      nivel: nivel,
      cod_acima: parseInt(vinculacao ?? 0),
      aceita_lcto: aceitaLcto,
      tipo_conta: tipoConta,
      considera_dre: consideraDre,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ativo: ativo, ...payload };
  };

  const optionsTipoConta = [
    {
      label: "Receitas",
      value: "REC",
    },
    {
      label: "Despesas",
      value: "DES",
    },
    {
      label: "Investimentos",
      value: "INV",
    },
    {
      label: "Transitório",
      value: "TRA",
    },
    {
      label: "Outros",
      value: "OUT",
    },
  ];

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitlePlanoContas}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBasePlanoContas}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
      number="0125_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={8}
          label="Descrição"
          value={descricao}
          onChange={(e, v) => setDescricao(v)}
          maxLength={40}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={12}
          label="Vinculação"
          value={vinculacao}
          mode="plano_contas"
          onChange={(idSelecionado, planoSelecionado) => {
            if (idSelecionado === null){
              setNivel(1)
            }else{
              setNivel(planoSelecionado?.treeDepth + 2);
            }
            setVinculacao(idSelecionado);
          }}
        />
      </Row>
      <Row>
        <RadioGroup
          md={12}
          label="Nível"
          onChange={setNivel}
          value={nivel}
          disabled={vinculacao ? true : false}
        >
          <RadioItem label="Nível 1" value={1} />
          <RadioItem label="Nível 2" value={2} disabled={true} />
          <RadioItem label="Nível 3" value={3} disabled={true} />
          <RadioItem label="Nível 4" value={4} disabled={true} />
          <RadioItem label="Nível 5" value={5} disabled={true} />
        </RadioGroup>
        <FormCheckbox
          label={"Aceita Lançamentos de Despesas"}
          onChange={() => setAceitaLcto(!aceitaLcto)}
          checked={aceitaLcto}
        />
        <FormCheckbox
          label={"Considerar no DRE"}
          onChange={() => setConsideraDre(!consideraDre)}
          checked={consideraDre}
        />
      </Row>
      <Row>
        <ComboBox
          label={"Tipo de Conta"}
          options={optionsTipoConta}
          onChange={(s) => setTipoConta(s?.value)}
          defaultValue={tipoConta}
          isSearchable={false}
        />
      </Row>
    </ModalCadastroV2>
  );
};
