import React from "react";
import { Row, UncontrolledTooltip } from "reactstrap";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const BarraGrafico = ({ cor, id, perc, num, divStyle }) => (
  <div style={divStyle}>
    <div
      style={{
        width: "30px",
        height: `${(parseFloat(perc) / 100) * 100}px`,
        backgroundColor: cor,
        transition: "0.5s",
        borderBottom: "1px solid #ddd",
      }}
      id={id}
    />
    <UncontrolledTooltip target={id}>
      {formatNumber(perc, true, 2, true)}%
    </UncontrolledTooltip>
    <label
      style={{
        width: "100%",
        textAlign: "center",
        marginBottom: 0,
        color: "#7F7F7F",
      }}
    >
      {num}
    </label>
  </div>
);

export const ClientesNaoVendidosGraficos = ({ dados, nomeAgrup }) => {
  const gerarNomeItem = (row) =>
    nomeAgrup === "vendedores"
      ? formatValueFromAPI(row.nome, row.id)
      : nomeAgrup === "rotas"
      ? row.nome
      : nomeAgrup === "cidades"
      ? `${row.nome}-${row.uf}`
      : row.nome;

  return (
    <Row className="no-gutters">
      {dados.map((item, index) => (
        <div
          style={{
            width: "10%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
            borderRight: "1px solid #e5e9f2",
            borderBottom: "1px solid #e5e9f2",
            justifyContent: "space-between",
            paddingInline: "0.5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "end",
              width: "max-content",
            }}
          >
            <BarraGrafico
              id={`perc-atend-${index}`}
              cor="#0b9d28"
              perc={item.perc_atend}
              num={item.num_atend}
              divStyle={{ marginRight: "0.5rem" }}
            />
            <BarraGrafico
              id={`perc-nao-atend-${index}`}
              cor="#ff4317"
              perc={item.perc_nao_atend}
              num={item.num_nao_atend}
            />
          </div>
          <h5>{gerarNomeItem(item)}</h5>
        </div>
      ))}
    </Row>
  );
};
