import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BsCreditCard } from "react-icons/bs";
import { CgMathPercent } from "react-icons/cg";
import {
  cadastroCartaoTaxasAdmRoute,
  gerencCartaoCredRoute,
} from "../../../routes/modules/financeiro";

export const CartaoCred = () => {
  return (
    <PageContainer title="Cartões de Crédito">
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento"
          icon={BsCreditCard}
          pathname={gerencCartaoCredRoute.path}
          nroTela={gerencCartaoCredRoute.nroTela}
        />
        <MenuGroupButton
          label="Tarifas"
          icon={CgMathPercent}
          pathname={cadastroCartaoTaxasAdmRoute.path}
          nroTela={cadastroCartaoTaxasAdmRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
