import { toastr } from "react-redux-toastr";
import { docPost } from "../../pdf";
import { printFileFromBlob } from "../../utils/printing";
import { openFileFromBlob, viewDownloadPDF } from "../../coreUtils";
import {
  apiDeleteV2,
  apiGetV2,
  apiPostV2,
  apiPutV2,
  apiV2,
  buscarLogoBase64,
} from "../../apiV2";
import qs from "qs";
import { tratarParams } from "../../apiV2";

const URL_BASE = "/nfe_consumidor";
const URL_BASE_GERENC = `${URL_BASE}/gerenciamento`;
const URL_BASE_INCLUSAO = `${URL_BASE}/inclusao`;

const NotaFiscalConsumidorService = {
  buscar: async (id) => await apiGetV2(`${URL_BASE}/buscar/${id}/`),
  gerenciamento: {
    buscarAlertas: async (id) =>
      await apiGetV2(`${URL_BASE_GERENC}/listar_alertas/${id}/`),
    buscarChaveAcessoDuplicidade: async (id) =>
      await apiGetV2(
        `${URL_BASE_GERENC}/buscar_chave_acesso_duplicidade/${id}/`
      ),
    buscarQtdNfesPendEmissao: async (dataIni, dataFim) =>
      await apiGetV2(`${URL_BASE_GERENC}/buscar_qtd_nfs_pend_emissao/`, {
        data_ini: dataIni,
        data_fim: dataFim,
      }),
    buscarStatus: async (id) =>
      await apiGetV2(`${URL_BASE_GERENC}/buscar_status/${id}/`),
    cancelar: async (id) =>
      await apiPutV2(`${URL_BASE_GERENC}/cancelar/`, { id_nf_consumidor: id }),
    corrigirDuplicidade: async (formData) =>
      await apiPostV2(`${URL_BASE_GERENC}/corrigir_duplicidade/`, formData),
    descartarDigitacao: async (idNfce) =>
      await apiDeleteV2(`${URL_BASE_GERENC}/descartar_digitacao/${idNfce}/`),
    downloadXML: async (id) =>
      await apiV2
        .get(`${URL_BASE_GERENC}/download_xml/${id}/`, {
          responseType: "blob",
        })
        .then(async (ret) => {
          if (ret.data.type === "text/xml") {
            return ret.data;
          } else if (ret.data.type === "application/json") {
            const json_ret = JSON.parse(await ret.data.text());
            toastr.error("Erro", json_ret.msg);
            return false;
          }
        })
        .catch((err) => {
          toastr.error("Erro", err.message);
          return false;
        }),
    emitir: async (id) =>
      await apiPostV2(`${URL_BASE_GERENC}/emitir/`, { id_nfe: id }),
    exportarXmls: async (dataIni, dataFim) => {
      return await apiV2
        .get(`${URL_BASE_GERENC}/exportar_xmls/`, {
          responseType: "blob",
          params: tratarParams({ data_ini: dataIni, data_fim: dataFim }),
          paramsSerializer: (par) =>
            qs.stringify(par, { arrayFormat: "repeat" }),
        })
        .then(async (ret) => {
          if (ret.data.type === "application/zip") {
            return ret.data;
          } else if (ret.data.type === "application/json") {
            const json_ret = JSON.parse(await ret.data.text());
            toastr.error("Erro", json_ret.msg);
            return false;
          }
        })
        .catch((err) => {
          toastr.error("Erro", err.message);
          return false;
        });
    },
    listar: async (params) =>
      await apiGetV2(`${URL_BASE_GERENC}/listar/`, params),
    inutilizar: async (payload) =>
      await apiPutV2(`${URL_BASE_GERENC}/inutilizar/`, payload),
  },
  imprimir: async (id, obedecerParamImp = true) => {
    const [okImpressora, retImpressora] = await apiGetV2(
      `/tela/nf_consumidor/`
    );

    if (!okImpressora) return;

    if (obedecerParamImp) {
      if (retImpressora.forma_impressao === "NAO") return;
    }

    const [ok, ret] = await apiGetV2(`${URL_BASE}/impressao/buscar/${id}/`);

    if (!ok) return;

    const logo = await buscarLogoBase64();

    const arq = await docPost(
      "/danfce/",
      { ...ret, logo: logo, tipo_impressao: retImpressora.tipo_impressao },
      {
        errorMessage: "Erro ao Gerar DANFCe",
      }
    );

    if (!arq) return false;

    const fileName = `DANFCe_${ret.numero_nf}-${ret.serie}.pdf`;
    if (retImpressora.forma_impressao === "VIS") {
      openFileFromBlob(arq, fileName);
    } else if (retImpressora.forma_impressao === "IMP") {
      printFileFromBlob(arq, retImpressora.nome_impressora);
    } else {
      viewDownloadPDF(arq, fileName);
    }
  },

  inclusao: {
    buscarCab: async (id) =>
      await apiGetV2(`${URL_BASE_INCLUSAO}/buscar_cab/${id}/`),
    buscarItens: async (idNfe) =>
      await apiGetV2(`${URL_BASE_INCLUSAO}/buscar_itens/${idNfe}/`),
    buscarFaturas: async (idNfe) =>
      await apiGetV2(`${URL_BASE_INCLUSAO}/buscar_faturas/${idNfe}/`),
    buscarChavesRefer: async (idNfe) =>
      await apiGetV2(`${URL_BASE_INCLUSAO}/buscar_chaves_refer/${idNfe}/`),
    excluirChaveRefer: async (idChaveRefer) =>
      await apiDeleteV2(
        `${URL_BASE_INCLUSAO}/excluir_chave_refer/${idChaveRefer}/`
      ),
    excluirItem: async (idItem) =>
      await apiDeleteV2(`${URL_BASE_INCLUSAO}/excluir_item/${idItem}/`),
    incluirCab: async (payload) =>
      await apiPostV2(`${URL_BASE_INCLUSAO}/incluir_cab/`, payload, {
        successMessage: false,
      }),
    incluirChaveRefer: async (payload) =>
      await apiPostV2(`${URL_BASE_INCLUSAO}/incluir_chave_refer/`, payload, {
        successMessage: false,
      }),
    incluirFaturas: async (payload) =>
      await apiPostV2(`${URL_BASE_INCLUSAO}/incluir_faturas/`, payload, {
        successMessage: false,
      }),
    incluirItem: async (payload) =>
      await apiPostV2(`${URL_BASE_INCLUSAO}/incluir_item/`, payload),
    incluirItemIcms: async (payload) =>
      await apiPostV2(`${URL_BASE_INCLUSAO}/incluir_item_icms/`, payload),
    buscarItem: async (idItemNf) =>
      await apiGetV2(`${URL_BASE_INCLUSAO}/buscar_item/${idItemNf}/`),
    alterarItem: async (payload) =>
      await apiPutV2(`${URL_BASE_INCLUSAO}/alterar_item/`, payload),
    finalizarInclusao: async (payload) =>
      await apiPutV2(`${URL_BASE_INCLUSAO}/finalizar_inclusao/`, payload),
    buscarInfoAdic: async (idNfe) =>
      await apiGetV2(`${URL_BASE_INCLUSAO}/buscar_info_adic/${idNfe}/`),
    atualizarInfoAdic: async (payload) =>
      await apiPutV2(`${URL_BASE_INCLUSAO}/atualizar_info_adic/`, payload),
  },
};

export default NotaFiscalConsumidorService;
