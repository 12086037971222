import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cliente: null,
  numeroNF: null,
  situacao: "A",
  dataIni: null,
  dataFim: null,
};

export const nfServicoSlice = createSlice({
  name: "listagem_produtos_slice",
  initialState: initialState,
  reducers: {
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setCliente: (state, action) => {
      state.cliente = action.payload;
    },
    setNumeroNF: (state, action) => {
      state.numeroNF = action.payload;
    },
    setSituacao: (state, action) => {
      state.situacao = action.payload;
    },
    setDataIni: (state, action) => {
      state.dataIni = action.payload;
    },
    setDataFim: (state, action) => {
      state.dataFim = action.payload;
    },
  },
});

export const { setCliente, setNumeroNF, setSituacao, setDataIni, setDataFim } =
  nfServicoSlice.actions;

export default nfServicoSlice.reducer;
