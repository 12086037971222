import React, { useRef, useState } from "react";
import { FormButton } from "../../../../../components";
import CadastroMetaVendaService from "../../../../../services/cadastro/CadastroMetaVendaService";
import {
  formatDateISO,
  roundFloat,
  useStateWithRef,
} from "../../../../../coreUtils";
import { ModalBase } from "../../../../../components/ModalBase";
import { Divider } from "../../../../../components/Divider";
import moment from "moment";
import { IncluirDetalheMetaForm } from "./IncluirDetalheMetaForm";
import { CadastroMetaForm } from "./CadastroMetaForm";
import { ClonarMetaVendaGrid } from "./ClonarMetaVendaGrid";

export const ClonarMetaVendaModal = ({
  selected,
  selectedTipoInc,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [nome, setNome] = useState("");
  const [metaPor, setMetaPor] = useState("ROTA");
  const [idMetaPor, setIdMetaPor] = useState(null);
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [pista, setPista] = useState("");
  const [dados, setDados, dadosRef] = useStateWithRef([]);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const incluirDetalheRef = useRef();

  const limparDados = () => {
    setDados([]);
    setPista("");
  };

  const carregarDados = async () => {
    setLoadingFetch(true);
    const [ok, ret] = await CadastroMetaVendaService.buscarDadosClonar(
      selected
    );
    if (!ok) return false;

    ret.itens.forEach((e) => {
      e.meta_qtd_venda = parseFloat(e.meta_qtd_venda);
      e.meta_vlr_faturado = parseFloat(e.vlr_meta_faturamento);
      e.meta_perc_max_devol_troca = parseFloat(e.meta_perc_max_devol_troca);
      delete e.vlr_meta_faturamento; // Para manter os nomes dos campos padronizados
    });

    setNome(ret.nome);
    setMetaPor(ret.meta_por);
    setIdMetaPor(ret.id_meta_por);
    setMes(moment(ret.data_ini, "DD/MM/YYYY").month() + 1);
    setAno(moment(ret.data_fim, "DD/MM/YYYY").year());
    setDados(ret.itens);

    setLoadingFetch(false);
  };

  const handlePista = (v) => setPista(v);

  const handleAddMov = async (data) => {
    const payload = {
      ...data,
      id: Math.max(...dados.map((e) => e.id)) + 1,
    };

    setDados([...dadosRef.current, payload]);

    incluirDetalheRef.current.limparDados();
  };

  const handleDelete = (id) => {
    setDados(dadosRef.current.filter((e) => e.id !== id));
  };

  const toggle = async () => {
    if (!isOpen) {
      await carregarDados();
    } else {
      limparDados();
    }
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    const payload = {
      nome: nome,
      meta_por: metaPor,
      id_meta_por: idMetaPor,
      data_inicial: formatDateISO(new Date(ano, mes - 1, 1)),
      data_final: formatDateISO(new Date(ano, mes, 0)),
      movs: dados.map((e) => ({
        tipo: e.desc_tipo === "Produto" ? "PRODUTO" : "GRUPO_PROD", // Fixo por ora
        id_tipo: e.id_tipo,
        m_qtd_vda: e.meta_qtd_venda,
        m_vlr_faturado: e.meta_vlr_faturado,
        m_perc_max_dev_troca: e.meta_perc_max_devol_troca,
      })),
    };

    const [ok] = await CadastroMetaVendaService.clonar(payload);

    if (ok) {
      notifyEvent();
      toggle();
    }

    setLoadingSubmit(false);
  };

  const handleEditDetalhe = async (coluna, novoValor, row) => {
    const valorAnterior = parseFloat(row[coluna]);
    if (coluna === "meta_qtd_venda") {
      row.meta_qtd_venda = parseInt(novoValor);
      row.preco_tipo = parseFloat(row.preco_tipo);

      row.meta_vlr_faturado = roundFloat(
        row.meta_qtd_venda * row.preco_tipo,
        2
      );
    }

    if (coluna === "meta_perc_max_devol_troca") {
      row.meta_perc_max_devol_troca = parseFloat(novoValor);
    }

    if (parseFloat(valorAnterior) !== parseFloat(novoValor)) {
      setDados(
        dados.map((e) => {
          if (e.id === row.id) {
            return { ...row };
          } else {
            return e;
          }
        })
      );
    }
  };

  const aumentarPorPerc = async (percAumentar) => {
    if (percAumentar > 0) {
      const novosItens = [];
      dados.forEach((row) => {
        let novaMetaQtdVenda = parseFloat(row.meta_qtd_venda);

        novaMetaQtdVenda = Math.round(
          novaMetaQtdVenda + novaMetaQtdVenda * (percAumentar / 100)
        );

        row.meta_qtd_venda = parseInt(novaMetaQtdVenda);
        row.preco_tipo = parseFloat(row.preco_tipo);

        row.meta_vlr_faturado = roundFloat(
          row.meta_qtd_venda * row.preco_tipo,
          2
        );

        novosItens.push(row);
      });

      setDados(novosItens);
    }
  };

  return (
    <>
      <FormButton
        md="auto"
        disabled={
          [0, null, undefined].includes(selected) || selectedTipoInc === "BVD"
        }
        onClick={toggle}
        loading={loadingFetch}
        disabledHint={
          [0, null, undefined].includes(selected)
            ? "Selecione um item da lista"
            : selectedTipoInc === "BVD" &&
              "Não é possível clonar Metas Baseadas em Vendas"
        }
      >
        Clonar Meta Manual
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Clonar Meta"
        number="0056_4"
        onConfirm={handleSubmit}
        loadingConfirm={loadingSubmit}
      >
        <CadastroMetaForm
          nome={nome}
          setNome={setNome}
          metaPor={metaPor}
          setMetaPor={setMetaPor}
          idMetaPor={idMetaPor}
          setIdMetaPor={setIdMetaPor}
          mes={mes}
          setMes={setMes}
          ano={ano}
          setAno={setAno}
        />
        <Divider>Produtos da Meta</Divider>
        <IncluirDetalheMetaForm
          ref={incluirDetalheRef}
          movs={dados}
          handleAdd={handleAddMov}
        />
        <ClonarMetaVendaGrid
          dados={
            !["", null, undefined].includes(pista?.trim())
              ? dados.filter((e) => e.nome_tipo.includes(pista))
              : dados
          }
          handleDelete={handleDelete}
          handleEditDetalhe={handleEditDetalhe}
          pista={pista}
          handlePista={handlePista}
          handleAumentarValorPorPerc={aumentarPorPerc}
        />
      </ModalBase>
    </>
  );
};
