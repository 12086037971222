import React from "react";
import { useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import DevolucaoVendaService from "../../../../../services/vendas/DevolucaoVendaService";
import {
  FormButton,
  HintLabel,
  IntegerFormInput,
  Loader,
  NumberInput,
  PageContainer,
  TextInput,
} from "../../../../../components";
import { useState } from "react";
import {
  formatValueFromAPI,
  roundFloat,
  sumDataField,
} from "../../../../../coreUtils";
import { DevolverItensVendaGrid } from "./components/DevolverItensVendaGrid";
import { DevolverFormasRecGrid } from "./components/DevolverFormasRecGrid";
import { Redirect } from "react-router-dom";

export const DevolverVenda = ({ location }) => {
  const idVenda = location.state.idVenda;
  const [cliente, setCliente] = useState("");
  const [temContaRotativo, setTemContaRotativo] = useState(false);
  const [vendedor, setVendedor] = useState("");
  const [emissao, setEmissao] = useState("");
  const [vlrFrete, setVlrFrete] = useState(0);
  const [vlrTotalPed, setVlrTotalPed] = useState(0);
  const [itens, setItens] = useState([]);
  const [formasRec, setFormasRec] = useState([]);
  const [vlrTotalDevol, setVlrTotalDevol] = useState(0);
  const [vlrDespFinanc, setVlrDespFinanc] = useState(0); // Valor de custo dos boletos
  const [vlrRestit, setVlrRestit] = useState(0); // Vlr. devol. moedas + crédito
  const [vlrCredito, setVlrCredito] = useState(0);
  const [devolucaoTotal, setDevolucaoTotal] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const carregarDados = async () => {
    setLoadingFetch(true);
    const ret = await DevolucaoVendaService.buscarVenda(idVenda);

    if (ret.id) {
      setCliente(formatValueFromAPI(ret.nome_cliente, ret.id_cliente));
      setTemContaRotativo(ret.tem_conta_rotativo);
      setVendedor(formatValueFromAPI(ret.nome_vendedor, ret.id_vendedor));
      setVlrFrete(parseFloat(ret.vlr_frete));
      setVlrTotalPed(parseFloat(ret.vlr_total));
      setEmissao(ret.emissao);
      setItens(ret.itens.map((e) => ({ ...e, qtd_devolver: 0 })));
    }

    const retFormasRec = await DevolucaoVendaService.buscarFormasRecVenda(
      idVenda
    );

    setFormasRec(
      retFormasRec?.formas_rec?.map((e) => ({ ...e, vlr_devolver: 0 }))
    );
    setVlrDespFinanc(parseFloat(retFormasRec?.vlr_desp_financ ?? "0"));

    setLoadingFetch(false);
  };

  const atualizarVlrDevol = () => {
    const totalDevolProdutos = itens.reduce(
      (a, b) => (a += b.vlr_item * b.qtd_devolver),
      0
    );

    setVlrTotalDevol(roundFloat(totalDevolProdutos, 2));
  };

  const totalizarVlrMoedas = () => sumDataField(formasRec, "vlr_devolver");

  const atualizarVlrRestit = () => {
    const totalDevolMoedas = totalizarVlrMoedas();

    setVlrRestit(roundFloat(totalDevolMoedas + vlrCredito, 2));
  };

  const handleSetVlrCredito = (v) => {
    setVlrCredito(v);

    const totalDevolMoedas = totalizarVlrMoedas();

    setVlrRestit(roundFloat(totalDevolMoedas + v, 2));
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    const itensDevol = itens
      .filter((e) => e.qtd_devolver > 0)
      .map((e) => ({
        id_item: e.id,
        qtd_devolver: e.qtd_devolver,
      }));

    const formasRecDevol = formasRec
      .filter((e) => e.vlr_devolver > 0)
      .map((e) => ({
        id_moeda: e.id_moeda,
        moeda: e.sigla_moeda,
        valor_devolver: e.vlr_devolver,
      }));

    if (vlrCredito > 0) {
      formasRecDevol.push({
        id_moeda: 0,
        moeda: "CRED",
        valor_devolver: vlrCredito,
      });
    }

    const payload = {
      id_venda: idVenda,
      vlr_tot_prod_dev: vlrTotalDevol,
      vlr_frete_dev: 0,
      itens_devolver: itensDevol,
      formas_rec_devolver: formasRecDevol,
    };

    if (await DevolucaoVendaService.devolverVenda(payload)) {
      setRedirect(true);
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  useEffect(() => {
    if (vlrRestit === vlrTotalDevol + vlrDespFinanc) {
      setDevolucaoTotal(true);
    } else {
      setDevolucaoTotal(false);
    }
  }, [vlrRestit, vlrTotalDevol, vlrDespFinanc]);

  let btnConfirmarDisabled;
  if (devolucaoTotal) {
    btnConfirmarDisabled =
      vlrTotalDevol === 0 || vlrRestit !== vlrTotalDevol + vlrDespFinanc;
  } else {
    btnConfirmarDisabled = vlrTotalDevol === 0 || vlrRestit !== vlrTotalDevol;
  }

  return loadingFetch ? (
    <Loader />
  ) : (
    <PageContainer title="Devolver Venda" number="0059_1" canGoBack>
      {redirect && (
        <Redirect
          to={{
            pathname: "/vendas/devolucao/",
            state: { refresh: true },
          }}
        />
      )}
      <Card body>
        <Row>
          <IntegerFormInput
            md={2}
            label="Nº Venda"
            defaultValue={idVenda}
            disabled
          />
          <TextInput md={3} label="Cliente" value={cliente} disabled />
          <TextInput md={3} label="Vendedor" value={vendedor} disabled />
          <TextInput md={2} label="Emissão" value={emissao} disabled />
          <NumberInput
            md={2}
            label="Valor Total"
            value={vlrTotalPed}
            disableGroupSeparators={false}
            disabled
          />
        </Row>
      </Card>
      <Card body>
        <DevolverItensVendaGrid
          itens={itens}
          atualizarVlrDevol={atualizarVlrDevol}
        />
      </Card>
      <Card body>
        <Row>
          <Col md={5}>
            <Row>
              <NumberInput
                md={5}
                label={
                  <HintLabel
                    label="Gerar Crédito De"
                    hintText="Este cliente não possui Conta Corrente aberta no Crédito Rotativo, portanto, não pode gerar crédito."
                    enabled={!temContaRotativo}
                  />
                }
                value={vlrCredito}
                onChange={handleSetVlrCredito}
                disabled={!temContaRotativo}
              />
              <NumberInput md={3} label="Frete" value={vlrFrete} disabled />
              <NumberInput
                md={3}
                label="Despesa Financ"
                value={vlrDespFinanc}
                disabled
              />
            </Row>
          </Col>
          <Col md={6} className="ml-auto">
            <Row>
              <NumberInput
                md={4}
                label="Valor Restituído"
                value={vlrRestit}
                disabled
              />
              <NumberInput
                md={4}
                label="Total da Devolução"
                value={vlrTotalDevol}
                disabled
              />
              <FormButton
                md={4}
                color="success"
                onClick={handleSubmit}
                loading={loadingSubmit}
                disabled={btnConfirmarDisabled}
                disabledHint={
                  vlrTotalDevol === 0
                    ? "O Total da Devolução não pode ser zero. Informe ao menos uma quantidade para devolução."
                    : "O Valor Restituído deve ser igual ao Total da Devolução."
                }
              >
                Concluir Devolução
              </FormButton>
            </Row>
          </Col>
        </Row>
      </Card>
      <Card body>
        <DevolverFormasRecGrid
          formasRec={formasRec}
          atualizarVlrRestit={atualizarVlrRestit}
        />
      </Card>
    </PageContainer>
  );
};
