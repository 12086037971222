import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { HiDocumentDuplicate } from "react-icons/hi2";
import { gerarNfVendaOsRoute } from "../../../routes/modules/faturamento";

export const VendaOS = () => {
  return (
    <PageContainer title="Faturamento - Venda/O.S.">
      <MenuGroup>
        <MenuGroupButton
          label="Gerar Notas Fiscais de Venda/O.S."
          icon={HiDocumentDuplicate}
          pathname={gerarNfVendaOsRoute.path}
          nroTela={gerarNfVendaOsRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
