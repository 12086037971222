import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  localInstalacaoModulos: null,
  idade: "NAO DEFINIDO",
  materialEstrutura: null,
  condicao: null,
  nivelamentoSolo: "NAO",
  // dimensaoSolo: null,
  locaisInstalacao: [],
};

export const localInstalacaoModulosSlice = createSlice({
  name: "local_instalacao_modulos",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    reset: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setLocalInstalacaoModulos: (state, action) => {
      state["localInstalacaoModulos"] = action.payload;
    },
    setIdade: (state, action) => {
      state["idade"] = action.payload;
    },
    setMaterialEstrutura: (state, action) => {
      state["materialEstrutura"] = action.payload;
    },
    setCondicao: (state, action) => {
      state["condicao"] = action.payload;
    },
    setNivelamentoSolo: (state, action) => {
      state["nivelamentoSolo"] = action.payload;
    },
    addLocalInstalacao: (state, action) => {
      if (!action.payload.id) {
        const max = state.locaisInstalacao.reduce(
          (p, v) => (p.id > v.id ? p.id : v.id),
          0
        );
        action.payload.id = (max ?? 0) + 1;
      }
      state["locaisInstalacao"].push(action.payload);
    },
    removeLocalInstalacao: (state, action) => {
      state.locaisInstalacao = state.locaisInstalacao.filter((el) => {
        return el.id !== action.payload;
      });
    },
  },
});

export const {
  init,
  reset,
  setup,
  setLocalInstalacaoModulos,
  setCondicao,
  setIdade,
  setMaterialEstrutura,
  setNivelamentoSolo,
  addLocalInstalacao,
  removeLocalInstalacao,
} = localInstalacaoModulosSlice.actions;

export default localInstalacaoModulosSlice.reducer;

export const localInstAsPayload = (state) => {
  const invalidArray = [
    0,
    "",
    null,
    undefined,
    "NAO",
    "NAO DEFINIDO",
    false,
    "0",
  ];

  const checkVazio = (obj) => {
    let countDados = 0;
    Object.keys(obj).forEach(function(j) {
      if (!invalidArray.includes(obj[j])) {
        countDados++;
      }
    });
    if (countDados > 0) {
      return obj;
    } else {
      return [];
    }
  };

  // Local Instalação
  const res = {
    tipo: state.localInstalacaoModulos?.toUpperCase() ?? "",
    sigla_idade: state.idade?.toUpperCase() ?? "",
    sigla_material_estrutura: state.materialEstrutura?.toUpperCase() ?? "",
    sigla_condicao_estrutura: state.condicao?.toUpperCase() ?? "",
    sigla_nivelamento_solo: state.nivelamentoSolo?.toUpperCase() ?? "",
    instal_det: [],
  };

  state.locaisInstalacao.forEach((el) => {
    res["instal_det"].push({
      descricao: el.descricao?.toUpperCase() ?? "",
      orientacao: String(el.orientacao ?? "NOR"),
      inclinacao: String(el.inclinacao ?? 0.0),
      dim_larg: el.largura ?? 0.0,
      dim_comp: el.comprimento ?? 0.0,
      tipo_telha: el.tipoTelha?.toUpperCase() ?? "",
      dista_ripa: el.distanciaRipas ?? 0.0,
      dista_caibro: el.distanciaCaibros ?? 0.0,
      dista_terca: el.distanciaTercas ?? 0.0,
      dista_viga_metal: el.distanciaVigasMetalicas ?? 0.0,
      altura_telhado: el.alturaTelhado ?? 0.0,
      sigla_acesso: el.acesso ?? "",
      foto_croqui: el.fotoCroqui ?? "",
    });
  });

  res["instal_det"] = checkVazio(res["instal_det"]);

  let countDados = 0;
  Object.keys(res).forEach(function(j) {
    if (!invalidArray.includes(res[j])) {
      countDados++;
    }
  });
  if (res["instal_det"].length === 0) {
    countDados--;
  }
  if (countDados > 0) {
    return res;
  } else {
    return [];
  }
};
