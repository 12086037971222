import React from "react";
import { Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  FormCheckbox,
  IntegerFormInput,
  RadioGroup,
} from "../../../../components";
import { RadioItem } from "../../../../components/RadioGroup";
import {
  setCampoCadProdControlEst,
  setSincCadCli,
  setSincCadColaborador,
  setSincCadProd,
  setSincDadosBi,
} from "../store/sincronismo_slice";

export const PersonSincronismo = () => {
  const store = useSelector((state) => state.sincronismo);
  const dispatch = useDispatch();

  return (
    <>
      <Label
        md={12}
        style={{ borderBottom: "0.5px solid #ccc", fontStyle: "italic" }}
      >
        Os dados abaixo interferem apenas em sincronismos de integração de
        bancos de dados criados pela Skillsoft, não tendo qualquer relação com
        integrações de sistemas terceiros, tais como Sistemas de Ecommerce,
        Mercado Livre, etc.
      </Label>
      <Row>
        <FormCheckbox
          padded={false}
          label="Sincronizar Cadastro de Produtos"
          checked={store.sinc_cad_prod}
          onChange={() => dispatch(setSincCadProd(!store.sinc_cad_prod))}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Campo de Controle de Estoque para Banco de Dados de Destino"
          value={store.campo_cad_prod_control_est}
          onChange={(v) => dispatch(setCampoCadProdControlEst(v))}
          disabled={!store.sinc_cad_prod}
        >
          <RadioItem
            label="Sincronizar o que está registrado no Banco de Origem"
            value="SINCRONIZA"
          />
          <RadioItem
            label="Sempre Definir como Controla Estoque"
            value="FIXO_TRUE"
          />
          <RadioItem
            label="Sempre Definir como NÃO Controla Estoque"
            value="FIXO_FALSE"
          />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Sincronizar Cadastro de Clientes"
          checked={store.sinc_cad_cli}
          onChange={() => dispatch(setSincCadCli(!store.sinc_cad_cli))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Sincronizar Cadastro de Colaboradores"
          checked={store.sinc_cad_colaborador}
          onChange={() =>
            dispatch(setSincCadColaborador(!store.sinc_cad_colaborador))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Sincronizar dados de BI"
          checked={store.sinc_dados_bi}
          onChange={() => dispatch(setSincDadosBi(!store.sinc_dados_bi))}
        />
        <IntegerFormInput
          md={5}
          mdInput={4}
          label="ID Último Plano de Contas de Despesa de Pronta Entrega Sincronizado"
          defaultValue={store.id_ult_pln_cta_desp_pronta_entrega_sinc}
          disabled
        />
      </Row>
    </>
  );
};
