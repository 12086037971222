import React from "react";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { Table } from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";

const formatterPerc = (c) => formatNumber(c, true, 2, true) + "%";

const columnsPercs = Array(5)
  .fill({})
  .map((e, index) => ({
    dataField: `perc_parc_${index + 2}`,
    text: `${index + 2}x`,
    align: "right",
    headerAlign: "right",
    formatter: formatterPerc,
    sortable: true,
    sortFunc: naturalSort,
  }));

export const CartaoTaxasAdmGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "id_bandeira__nome",
      text: "Bandeira",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "id_meio_pagto__nome",
      text: "Meio Pag.",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "perc_cred_vista",
      text: "À Vista",
      align: "right",
      headerAlign: "right",
      formatter: formatterPerc,
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "perc_debito",
      text: "Débito",
      align: "right",
      headerAlign: "right",
      formatter: formatterPerc,
      sortable: true,
      sortFunc: naturalSort,
    },
    ...columnsPercs,
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return <Table data={data} columns={columns} onSelect={setSelected} />;
};
