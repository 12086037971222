import moment from "moment";
import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  DatePicker,
  FormButton,
  HintLabel,
  ModalBase,
  NumberInput,
} from "../../../../../components";
import {
  formatDateISO,
  roundFloat,
  sumDataField,
} from "../../../../../coreUtils";
import GerenciamentoCartaoService from "../../../../../services/financeiro/GerenciamentoCartaoService";

export const CompensarCartaoModal = ({ selected, data, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contaBanc, setContaBanc] = useState(0);
  const [dataReceb, setDataReceb] = useState(new Date());

  const vlrCompensar = roundFloat(
    sumDataField(
      data.filter((e) => selected.includes(e.id)),
      "vlr_receber"
    ),
    2
  );

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onConfirm = async () => {
    setLoading(true);

    const payload = {
      conta_banc: contaBanc,
      data_compensacao: formatDateISO(dataReceb),
      cartoes: selected,
    };

    if (await GerenciamentoCartaoService.compensar(payload)) {
      toggle();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        color="success"
        disabled={
          selected.length === 0 ||
          data.filter((e) => selected.includes(e.id) && e.compensado).length > 0
        }
        onClick={toggle}
        disabledHint={
          selected.length === 0
            ? "Selecione ao menos um cartão"
            : "Selecione somente cartões não compensados"
        }
      >
        Compensar
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Compensação de Valores de Cartão"
        number="0049_3"
        onConfirm={onConfirm}
        loadingConfirm={loading}
      >
        <Row className="mb-2">
          <AsyncComboBox
            md={12}
            isConcatField
            concatModelName="conta_banc"
            defaultOptions
            label="Conta Bancária"
            onChange={(s) => setContaBanc(s?.value)}
            isSearchable
            isClearable
            defaultValue={contaBanc}
          />
        </Row>
        <Row>
          <NumberInput
            md={5}
            label="Valor Total"
            value={vlrCompensar}
            disabled
          />
          <DatePicker
            label={
              <HintLabel
                label="Data de Compensação"
                hintText="Data em que o crédito é depositado na conta bancária"
              />
            }
            md={6}
            onChange={(v) => setDataReceb(moment.isMoment(v) ? v.toDate() : v)}
            value={dataReceb}
          />
        </Row>
      </ModalBase>
    </>
  );
};
