import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import ImageViewer from "react-simple-image-viewer";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  CardImg,
  CardSubtitle,
  CardText,
  Col,
  Row,
  TabPane,
} from "reactstrap";
import DragAndDropArquivos from "../../../../../../components/DragAndDropArquivos";
import TextInput from "../../../../../../components/TextInput";
import VistoriaTecnicaService from "../../../../../../services/ordem_servico/VistoriaTecnicaService";
import { addFoto, removerFoto } from "../store/vistoria_slice";

export const TabFotos = () => {
  const [observacao, setObservacao] = useState("");
  const vistoriaTecnica = useSelector((state) => state.vistoriaTecnica);
  const dispatch = useDispatch();
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [indexImagem, setIndexImagem] = useState(0);

  const incluirFoto = async (file, base64) => {
    let idFoto = null;
    if (vistoriaTecnica.idVistoria != null) {
      // Alteração
      const payload = {
        id_vistoria: vistoriaTecnica.idVistoria,
        nome_arq: file.path,
        obs: observacao.toUpperCase(),
        base64: base64,
      };
      idFoto = await VistoriaTecnicaService.incluirFoto(payload);
      if (idFoto == null) {
        toastr.error("Erro", "Não foi possível incluir a foto na vistoria.");
        return;
      }
    }
    dispatch(
      addFoto({
        id: idFoto,
        nome: file.path,
        obs: observacao.toUpperCase(),
        base64: base64,
      })
    );
    setObservacao("");
  };

  const onDrop = (file) => {
    var reader = new FileReader();

    reader.addEventListener("load", function(e) {
      if (vistoriaTecnica.fotos.find((element) => element.nome === file.path)) {
        toastr.warning("Atenção", "Foto já foi incluída na vistoria técnica");
      } else {
        incluirFoto(file, e.target.result);
      }
    });

    reader.readAsDataURL(file);
  };

  const openImageViewer = (index) => {
    setIndexImagem(index);
    setIsViewerOpen(true);
  };

  const onCloseImageViewer = () => {
    setIndexImagem(0);
    setIsViewerOpen(false);
  };

  const deleteFoto = async (id, nome) => {
    if (vistoriaTecnica.idVistoria) {
      const ret = await VistoriaTecnicaService.excluirFoto(id);
      if (!ret) {
        return;
      }
    }
    dispatch(removerFoto(nome));
  };

  return (
    <TabPane tabId="8" className="p-2">
      <Row className="pb-3">
        <TextInput
          label="Observação"
          value={observacao}
          onChange={({ target }) => setObservacao(target?.value ?? "")}
        />
      </Row>
      <Row className="pb-3">
        <DragAndDropArquivos
          multiple={true}
          accept="image/*"
          md={12}
          onDrop={onDrop}
        />
      </Row>
      {vistoriaTecnica.fotos.length > 0 && (
        <>
          <CardGroup>
            {vistoriaTecnica.fotos.map((foto, index) => (
              <Col md={3}>
                <Card>
                  <CardImg
                    alt={foto.obs}
                    style={{ cursor: "pointer", marginBottom: "7px" }}
                    src={foto.base64}
                    top
                    width="100%"
                    onClick={() => openImageViewer(index)}
                  />
                  <CardBody>
                    <CardSubtitle>{foto.obs}</CardSubtitle>
                    <CardText>{foto.nome}</CardText>
                    <Button
                      color="danger"
                      onClick={() => deleteFoto(foto.id, foto.nome)}
                    >
                      Remover Foto
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </CardGroup>

          {isViewerOpen && (
            <ImageViewer
              src={vistoriaTecnica.fotos.map((foto) => foto.base64)}
              currentIndex={indexImagem}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={onCloseImageViewer}
            />
          )}
        </>
      )}
    </TabPane>
  );
};
