import React from "react";
import { parseRound } from "../../../../coreUtils";

const columns = [
  {
    dataField: "data",
    text: "Data",
    align: "center",
    headerAlign: "center",
    footer: "",
  },
  {
    dataField: "caixa_adm",
    text: "Caixa ADM",
    align: "right",
    headerAlign: "right",
    footerAlign: "right",
  },
  {
    dataField: "conta_banc",
    text: "Bancário",
    align: "right",
    headerAlign: "right",
    footerAlign: "right",
  },
  {
    dataField: "cartao",
    text: "Cartão Crédito",
    align: "right",
    headerAlign: "right",
    footerAlign: "right",
  },
  {
    dataField: "crediario",
    text: "Contas a Receber",
    align: "right",
    headerAlign: "right",
    footerAlign: "right",
  },
  {
    dataField: "cheque_cliente",
    text: "Cheques Cliente",
    align: "right",
    headerAlign: "right",
    footerAlign: "right",
  },
  {
    dataField: "cheque_proprio",
    text: "Cheque Próprio",
    align: "right",
    headerAlign: "right",
    footerAlign: "right",
  },
  {
    dataField: "contas_pagar",
    text: "Contas a Pagar",
    align: "right",
    headerAlign: "right",
    footerAlign: "right",
  },
  {
    dataField: "resultado",
    text: "Resultado Dia",
    align: "right",
    headerAlign: "right",
    footerAlign: "right",
    formatter: (cellContent) => (
      <span
        style={{
          color: parseRound(cellContent, 2) < 0 ? "#ab303b" : "#438858",
        }}
      >
        {cellContent}
      </span>
    ),
  },
  {
    dataField: "saldo",
    text: "Saldo",
    align: "right",
    headerAlign: "right",
    footerAlign: "right",
    formatter: (cellContent) => (
      <span
        style={{
          color: parseRound(cellContent, 2) < 0 ? "#f44455" : "#5fc27e",
          fontWeight: "bold",
        }}
      >
        {cellContent}
      </span>
    ),
  },
];

export default columns;
