import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  CadastroMotivoModal,
  tipoMotivoOptions,
} from "./components/CadastroMotivoModal";
import { MotivoGrid } from "./components/MotivoGrid";
import { apiGetV2 } from "../../../../apiV2";
import {
  debounce,
  defaultDebounceTime,
  MODAL_ACTIONS,
} from "../../../../coreUtils";
import {
  BotaoPesquisar,
  ComboBox,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../../components";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitleMotivo = "Motivo";
export const routesBaseMotivo = "/cadastro/motivo";

export const Motivo = () => {
  const [pista, setPista] = useState("");
  const [tipo, setTipo] = useState(null);
  const [mostrarInativos, setMostrarInativos] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p) => {
    const params = {
      pista: p,
      tipo: tipo,
      mostrar_inativos: mostrarInativos,
    };
    const [ok, ret] = await apiGetV2(`${routesBaseMotivo}/listar/`, params);
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Motivos" number="0126" canGoBack>
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <ComboBox
            md={3}
            label="Aplicar Em"
            options={tipoMotivoOptions}
            onChange={(s) => setTipo(s?.value)}
            defaultValue={tipo}
          />
          <FormCheckbox
            label="Mostrar Inativos"
            checked={mostrarInativos}
            onChange={() => setMostrarInativos(!mostrarInativos)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroMotivoModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleMotivo}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0126_2"
            selected={selected}
            routeBase={routesBaseMotivo}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <MotivoGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
