import React from "react";
import { useState } from "react";
import { apiGet } from "../../../../../api";
import { FixedTable } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import { ModalDetalhesIcone } from "../../../../../components/ModalDetalhesIcone";
import { formatNumber } from "../../../../../coreUtils";

export const DetDespRotaModal = ({ id, nome, dataIni, dataFim, total }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [despesasAdm, setDespesasAdm] = useState([]);

  const columnsAdm = [
    {
      dataField: "tipo",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "parcela",
      text: "Parcela",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome",
      text: "Fornecedor",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data_reg",
      text: "Data",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_pagto",
      text: "Pagamento",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "obs",
      text: "Obs.",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
  ];

  const buscarDados = async () => {
    const searchParams = new URLSearchParams({
      data_ini: dataIni.toISOString().split("T")[0],
      data_fim: dataFim.toISOString().split("T")[0],
    }).toString();

    setDespesasAdm(
      await apiGet(
        `/relatorios/venda_direta/desempenho_de_rotas/detalhes_despesa/${id}/?${searchParams}`
      )
    );
  };

  const onClose = () => {
    setDespesasAdm([]);
  };

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
    } else {
      onClose();
    }
    setIsOpen(!isOpen);
  };

  const tableStyle = {
    height: "400px",
    overflowX: "hidden",
  };

  return (
    <>
      <ModalDetalhesIcone id={`det${id}`} toggle={toggle} />
      <ModalBase
        title={`${nome}: Detalhamento de Despesas`}
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        // onOpened={onOpen}
        // onClosed={onClose}
        autoFocus
        hideCancelButton
        number="5033_1"
      >
        <div style={{ flexDirection: "row" }}>
          <h5>
            Total de Despesas:{" "}
            <strong>
              <span>{formatNumber(total ?? 0, true, 2)}</span>
            </strong>
          </h5>
        </div>
        <FixedTable
          columns={columnsAdm}
          data={despesasAdm}
          keyField="numero"
          style={tableStyle}
        />
      </ModalBase>
    </>
  );
};
