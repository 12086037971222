import moment from "moment";
import {
  formatDateDDMMAAAA,
  formatNumberField,
  formatRow,
  removeSpecialChars,
  verificaPessoa,
} from "../GerarRemessa";

const rem240HeaderBancoDoBrasil = (dadosConta, codConvenio) => [
  ["001", 1, 3], // 001 para Banco do Brasil S.A.
  [0, 4, 7], // Lote de Serviço: "0000"
  [0, 8, 8], // Tipo de Registro: "0"
  ["", 9, 17], // Uso Exclusivo FEBRABAN / CNAB: Brancos
  [2, 18, 18], // Tipo de Inscrição da Empresa: '1'  =  CPF '2'  =  CGC / CNPJ
  [formatNumberField(dadosConta.cnpj_empresa), 19, 32], // Número de Inscrição da Empresa
  [codConvenio, 33, 52], // Código do Convênio no Banco
  [dadosConta.agencia, 53, 58], // Agência
  [dadosConta.nro_conta, 59, 71], // Conta Corrente
  ["", 72, 72], // Dígito Verificador da Ag/Conta: Preencher com Brancos
  [removeSpecialChars(dadosConta.nome_empresa), 73, 102], // Nome da Empresa
  ["BANCO DO BRASIL S.A.", 103, 132], // Nome do Banco: BANCO DO BRASIL S.A.
  ["", 133, 142], // Uso Exclusivo FEBRABAN / CNAB: Brancos
  [1, 143, 143], // Código Remessa / Retorno: "1"
  [formatDateDDMMAAAA(new Date()), 144, 151], // Data de Geração do Arquivo
  [moment(new Date()).format("HHmmss"), 152, 157], // Hora de Geração do Arquivo
  [0, 158, 163], // Número Seqüencial do Arquivo - Opcional
  [0, 164, 166], // No da Versão do Layout do Arquivo - Opcional, versões disnponíveis 084, 083, 082, 080, 050, 040, ou 030
  [0, 167, 171], // Densidade de Gravação do Arquivo: "00000"
  ["", 172, 191], // Para Uso Reservado do Banco: Brancos
  ["", 192, 211], // Para Uso Reservado da Empresa: Brancos
  ["", 212, 240], // Uso Exclusivo FEBRABAN / CNAB: Brancos
];

const rem240HeaderLoteBancoDoBrasil = (dadosConta, codConvenio) => [
  ["001", 1, 3], // 001 para Banco do Brasil S.A.
  [1, 4, 7], // Número Sequencial do Lote
  [1, 8, 8], // Tipo de Registro: "1"
  ["R", 9, 9], // Tipo de Operação: "R"
  [1, 10, 11], // Tipo de Serviço: "01"
  ["", 12, 13], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [0, 14, 16], // Nº da Versão do Layout do Lote: Opcional, versões disponíveis 043, 042, 041, 040, 030 e 020
  ["", 17, 17], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [2, 18, 18], // Tipo de Inscrição da Empresa: '1'  =  CPF '2'  =  CGC / CNPJ
  [formatNumberField(dadosConta.cnpj_empresa), 19, 33], // Nº de Inscrição da Empresa
  [codConvenio, 34, 53], // Código do Convênio no Banco
  [dadosConta.agencia, 54, 59], // Agência
  [dadosConta.nro_conta, 60, 72], // Conta Corrente
  ["", 73, 73], // Dígito Verificador da Ag/Conta: Brancos
  [removeSpecialChars(dadosConta.nome_empresa), 74, 103], // Nome da Empresa
  ["", 104, 143], // Mensagem 1 - Brancos
  ["", 144, 183], // Mensagem 2 - Brancos
  [dadosConta.nro_remessa, 184, 191], // Número Remessa/Retorno
  [formatDateDDMMAAAA(new Date()), 192, 199], // Data de Gravação Remessa/Retorno
  ["", 200, 207], // Data do Crédito: Brancos
  ["", 208, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240SegPBancoDoBrasil = (
  dadosConta,
  dup,
  seuNumero,
  vlrPercJuros,
  seq
) => [
  ["001", 1, 3], // 001 para Banco do Brasil S.A.
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["P", 14, 14], // Cód. Segmento do Registro Detalhe: "P"
  ["", 15, 15], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 16, 17], // Código de Movimento Remessa:  01 – Entrada de títulos, 02 – Pedido de baixa, 04 – Concessão de Abatimento, 05 – Cancelamento de Abatimento, 06 – Alteração de Vencimento, 07 – Concessão de Desconto, 08 – Cancelamento de Desconto, 09 – Protestar, 10 – Cancela/Sustação da Instrução de protesto, 12 – Alterar Juros de Mora, 13 – Dispensar Juros de Mora, 14 – Cobrar Multa, 15 – Dispensar Multa, 16 – Ratificar dados da Concessão de Desconto, 19 – Altera Prazo Limite de Recebimento, 20 – Dispensar Prazo Limite de Recebimento, 21 – Altera do Número do Título dado pelo Beneficiário, 22 – Alteração do Número de Controle do Participante, 23 – Alteração de Nome e Endereço do Pagador, 30 – Recusa da Alegação do Sacado, 31 – Alteração de Outros Dados, 34 – Altera Data Para Concessão de Desconto, 40 – Alteração de modalidade, 45 – Inclusão de Negativação sem protesto (campo “Seu número” diferencia a negativação para o mesmo pagador), 46 – Exclusão de Negativação sem protesto e 47 – Alteração do Valor Nominal do Boleto.
  [dadosConta.agencia, 18, 23], // Agência
  [dadosConta.nro_conta, 24, 36], // Conta Corrente
  [0, 37, 37], // Dígito Verificador da Ag/Conta: Branco ou Zero
  [dup.nosso_numero, 38, 57], // Nosso Número
  [7, 58, 58], // Código da Carteira
  [0, 59, 59], // Forma de Cadastr. do Título no Banco: "0"
  ["", 60, 60], // Tipo de Documento: Brancos
  [2, 61, 61], // Identificação da Emissão do Boleto: Opcional, 1 – com cadastramento (Cobrança registrada) ou 2 – sem cadastramento (Cobrança sem registro).
  [2, 62, 62], // Identificação da Distribuição do Boleto: Opcional, No caso de carteira 11/12/31/51, utilizar código 1 – Banco emite, OU códigos 4 – Banco reemite e 5 – Banco não reemite, porém nestes dois últimos casos, o código de Movimento Remessa (posições 16 a 17) deve ser código '31' Alteração de outros dados (para títulos que já estão registrados no Banco do Brasil). No caso de carteira 17, podem ser usados os códigos: 1 – Banco emite, 2 – Cliente emite, 3 – Banco pre-emite e cliente complementa, 6 – Cobrança sem papel. Permite ainda, códigos 4 – Banco reemite e 5 – Banco não reemite, porém o código de Movimento Remessa (posições 16 a 17) deve ser código '31' Alteração de outros dados (para títulos que já estão registrados no Banco do Brasil). Obs.: Quando utilizar código, informar de acordo com o que foi cadastrado para a carteira junto ao Banco do Brasil, consulte seu gerente de relacionamento.
  [seuNumero, 63, 77], // Número do Documento de Cobrança
  [formatDateDDMMAAAA(dup.vencimento), 78, 85], // Data de Vencimento do Título
  [formatNumberField(dup.vlr_titulo), 86, 100], // Valor Nominal do Título
  [0, 101, 105], // Agência Encarregada da Cobrança: "00000"
  ["", 106, 106], // Dígito Verificador da Agência: Brancos
  [dadosConta.especie_tit, 107, 108], // Para carteira 11 e 17 modalidade Simples, pode ser usado: 01 – Cheque, 02 – Duplicata Mercantil, 04 – Duplicata de Serviço, 06 – Duplicata Rural, 07 – Letra de Câmbio, 12 – Nota Promissória, 17 - Recibo, 19 – Nota de Debito, 26 – Warrant, 27 – Dívida Ativa de Estado, 28 – Divida Ativa de Município, 29 – Dívida Ativa União, para carteira 17 modalidade simples, também aceita 31 – Cartão de Crédito, 32 – Boleto de Proposta e 33 Boleto de Aporte
  ["N", 109, 109], // Identific. de Título Aceito/Não Aceito: Código adotado pela FEBRABAN para identificar se o título de cobrança foi aceito (reconhecimento da dívida pelo Pagador). 'A'  =  Aceite 'N'  =  Não Aceite
  [formatDateDDMMAAAA(dup.emissao), 110, 117], // Data da Emissão do Título
  [dup.tipo_juros_titulo === "V" ? 1 : 2, 118, 118], // Código do Juros de Mora: '0'  =  Isento '1'  =  Valor por Dia '2'  =  Taxa Mensal
  [formatDateDDMMAAAA(dup.data_juros_titulo), 119, 126], // Data do Juros de Mora - Não há carência de juros.
  [vlrPercJuros, 127, 141], // Juros de Mora por Dia/Taxa ao Mês
  [parseFloat(dup.vlr_desconto) > 0 ? 1 : 0, 142, 142], // O Banco do Brasil só trata os códigos '1', '2' e '3'. No caso em que não há desconto, informar '0' (zero). Para os códigos '1' e '2' é obrigatório a informação dos demais campos referentes ao desconto, caso contrário, o registro do título será recusado.
  [
    parseFloat(dup.vlr_desconto) > 0
      ? formatDateDDMMAAAA(dup.data_desconto)
      : 0,
    143,
    150,
  ], // Data do Desconto 1
  [
    parseFloat(dup.vlr_desconto) > 0 ? formatNumberField(dup.vlr_desconto) : 0,
    151,
    165,
  ], // Valor/Percentual a ser Concedido
  [0, 166, 180], // Valor do IOF a ser Recolhido
  [0, 181, 195], // Valor do Abatimento
  [dup.numero, 196, 220], // Identificação do Título na Empresa: Campo destinado para uso do Beneficiário para identificação do Título.
  [dadosConta.protestar ? 1 : 3, 221, 221], // '1' = Protestar dias corridos '3' = Não Protestar
  [dadosConta.dias_protesto, 222, 223], // Informar prazo de inicio do protesto a partir do vencimento '0' - Não protestar
  [0, 224, 224], // Código para Baixa/Devolução: "0"
  [0, 225, 227], // Número de Dias para Baixa/Devolução: Prazo máximo 365 dias. Caso seja preenchido com zeros. O sistema acata a informação que foi cadastrada na sua carteira junto ao Banco do Brasil.
  [9, 228, 229], // Código da Moeda: '00' - Real
  [0, 230, 239], // Nº do Contrato da Operação de Crédito: Não tratado, informar 'zeros' ou o número do contrato de cobrança.
  [1, 240, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240SegQBancoDoBrasil = (
  dadosConta,
  dup,
  enderCliente,
  temInfoAvalista,
  seq
) => [
  ["001", 1, 3], // 001 para Banco do Brasil S.A.
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["Q", 14, 14], // Cód. Segmento do Registro Detalhe: "Q"
  ["", 15, 15], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 16, 17], // Código de Movimento Remessa:  '01' = Entrada de Títulos '02' = Solicitação de Baixa '04' = Concessão de Abatimento '05' = Cancelamento de Abatimento '06' = Prorrogação de Vencimento '09' = Protestar '10' = Desistência do Protesto e Baixar Título '11' = Desistência do Protesto e manter em carteira '12' = Alteração de Juros de Mora '13' = Dispensar Cobrança de Juros de Mora '14' = Alteração de Valor/Percentual de Multa '15' = Dispensar Cobrança de Multa '19' = Prazo limite de recebimento - alterar '20' = Prazo limite de recebimento - dispensar '23' = Alterar dados do pagador '31' = Alterações de outros dados
  [verificaPessoa(dup.cpf_cnpj_cliente), 18, 18], // Pagador - Tipo de Inscrição: '1'  =  CPF '2'  =  CGC / CNPJ
  [formatNumberField(dup.cpf_cnpj_cliente), 19, 33], // Pagador - Número de Inscrição
  [removeSpecialChars(dup.nome_cliente), 34, 73], // Pagador - Nome
  [removeSpecialChars(enderCliente), 74, 113], // Pagador - Endereço
  [removeSpecialChars(dup.bairro_cliente), 114, 128], // Pagador - Bairro
  [formatNumberField(dup.cep_cliente), 129, 136], // Pagador - CEP
  [removeSpecialChars(dup.cidade_cliente), 137, 151], // Pagador - Cidade
  [dup.uf_cliente, 152, 153], // Pagador - UF
  [temInfoAvalista ? 2 : 0, 154, 154], // Sacador/Avalista - Tipo de Inscrição: '0' = Isento / Não Informado '1'  =  CPF '2'  =  CGC / CNPJ
  [formatNumberField(dadosConta.cnpj_avalista), 155, 169], // Sacador/Avalista - Número de Inscrição
  [removeSpecialChars(dadosConta.nome_avalista), 170, 209], // Sacador/Avalista - Nome
  [0, 210, 212], // Cód. Bco. Corresp. na Compensação
  ["", 213, 232], // Nosso Nro. Banco Correspondente - O campo NN deve ser preenchido com "000", somente nos casos em que o campo anterior tenha indicado o uso do Banco Correspondente. Obs.: O preenchimento deste campo será alinha à esquerda a partir da posição 213 indo até 219.
  ["", 233, 240], // Uso Exclusivo FEBRABAN/CNAB
];

const rem240SegRBancoDoBrasil = (dadosConta, dup, vlrPercMulta, seq) => [
  ["001", 1, 3], // 001 para Banco do Brasil S.A.
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["R", 14, 14], // Cód. Segmento do Registro Detalhe: "R"
  ["", 15, 15], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 16, 17], // Código de Movimento Remessa:  '01' = Entrada de Títulos
  [0, 18, 18], // Código do Desconto 2 '0'  =  Não Conceder desconto '1'  =  Valor Fixo Até a Data Informada '2'  =  Percentual Até a Data Informada
  [0, 19, 26], // Data do Desconto 2
  [0, 27, 41], // Valor/Percentual a ser Concedido
  [0, 42, 42], // Código do Desconto 3 '0'  =  Não Conceder desconto '1'  =  Valor Fixo Até a Data Informada '2'  =  Percentual Até a Data Informada
  [0, 43, 50], // Data do Desconto 3
  [0, 51, 65], // Valor/Percentual a ser Concedido
  [dadosConta.tipo_multa === "PER" ? 2 : 1, 66, 66], // Código da Multa: '0'  =  Isento '1'  =  Valor Fixo '2'  =  Percentual
  [formatDateDDMMAAAA(dup.data_multa_titulo), 67, 74], // Data de inicio da cobrança de multa do título
  [vlrPercMulta, 75, 89], // Valor/Percentual a Ser Aplicado
  ["", 90, 99], // Informação ao Pagador: Brancos
  ["", 100, 139], // Mensagem 3. Brancos
  ["", 140, 179], // Mensagem 4. Brancos
  [0, 180, 199], // Uso Exclusivo FEBRABAN/CNAB: Brancos ou Zeros
  [0, 200, 207], // Data limite de pagamento: "DDMMAAAA"
  [0, 208, 210], // Cód. do Banco na Conta do Débito: "000"
  [0, 211, 215], // Código da Agência do Débito: "00000"
  [0, 216, 216], // Dígito Verificador da Agência: Brancos
  [0, 217, 228], // Conta Corrente para Débito: "000000000000"
  [0, 229, 229], // Dígito Verificador da Conta: Brancos ou Zeros
  [0, 230, 230], // Dígito Verificador Ag/Conta: Brancos
  [0, 231, 231], // Aviso para Débito Automático: "0"
  ["", 232, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240TrailerLoteBancoDoBrasil = (registrosLote) => [
  ["001", 1, 3], // 001 para Banco do Brasil S.A.
  [1, 4, 7], // Número Sequencial do Lote
  [5, 8, 8], // Tipo de Registro: "5"
  ["", 9, 17], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [registrosLote, 18, 23], // Total de linhas do Lote
  ["", 24, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240TrailerBancoDoBrasil = (totalRegistros) => [
  ["001", 1, 3], // 001 para Banco do Brasil S.A.
  [9999, 4, 7], // Preencher com '9999'.
  [9, 8, 8], // Tipo de Registro: "9"
  ["", 9, 17], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 18, 23], // Quantidade de Lotes do Arquivo
  [totalRegistros, 24, 29], // Quantidade de Registros do Arquivo
  ["", 30, 35], // Qtde de Contas p/ Conc. (Lotes): Zeros ou Brancos
  ["", 36, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

export const nomeArq240BancoDoBrasil = (dadosConta) =>
  "E" +
  moment(new Date())
    .format("DD")
    .padEnd(4, "0") +
  dadosConta.nro_remessa +
  ".REM";

export const layout240BancoDoBrasil = (dadosConta, duplicatas) => {
  const temInfoAvalista = ![null, undefined, ""].includes(
    dadosConta.cnpj_avalista?.trim()
  );

  const vlrPercMulta = formatNumberField(
    dadosConta.tipo_multa === "PER"
      ? dadosConta.perc_multa
      : dadosConta.vlr_multa
  );

  const codConvenio =
    removeSpecialChars(dadosConta.cod_cedente).padStart(9, "0") +
    "0014" +
    dadosConta.tipo_carteira.toString().padStart(2, "0") +
    dadosConta.variacao_carteira.trim().padStart(3, "0");

  dadosConta.agencia = removeSpecialChars(dadosConta.agencia)
    .padEnd(5, "0")
    .padStart(6, "0");
  dadosConta.nro_conta = removeSpecialChars(dadosConta.nro_conta).padStart(
    13,
    "0"
  );

  const header = "".concat(
    formatRow(...rem240HeaderBancoDoBrasil(dadosConta, codConvenio)),
    formatRow(...rem240HeaderLoteBancoDoBrasil(dadosConta, codConvenio))
  );

  const detalhes = "".concat(
    ...duplicatas.map((dup, index) => {
      const seuNumero = `${dup.numero}/${dup.parcela}`;

      const enderCliente =
        dup.endereco_cliente +
        " " +
        dup.nro_endereco_cliente +
        " " +
        dup.complemento_endereco_cliente;

      const vlrPercJuros = formatNumberField(
        dup.tipo_juros_titulo === "V"
          ? dup.vlr_juros_titulo
          : dup.perc_juros_titulo
      );

      return (
        formatRow(
          ...rem240SegPBancoDoBrasil(
            dadosConta,
            dup,
            seuNumero,
            vlrPercJuros,
            index * 3 + 1
          )
        ) +
        formatRow(
          ...rem240SegQBancoDoBrasil(
            dadosConta,
            dup,
            enderCliente,
            temInfoAvalista,
            index * 3 + 2
          )
        ) +
        formatRow(
          ...rem240SegRBancoDoBrasil(
            dadosConta,
            dup,
            vlrPercMulta,
            index * 3 + 3
          )
        )
      );
    })
  );

  const trailer = "".concat(
    formatRow(...rem240TrailerLoteBancoDoBrasil(duplicatas.length * 3 + 2)),
    formatRow(...rem240TrailerBancoDoBrasil(duplicatas.length * 3 + 4))
  );

  return header + detalhes + trailer;
};
