import React, { useState, useRef } from "react";
import { BsCashCoin } from "react-icons/bs";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  ModalBase,
  NumberInput,
} from "../../../../../../components";
import { Row } from "reactstrap";

const formasPag = [
  { label: "Dinheiro", value: "DINH" },
  { label: "Cartão de Crédito", value: "CCRE" },
  { label: "Cartão de Débito", value: "CDEB" },
  { label: "Boleto", value: "BOLE" },
  { label: "Transferência Bancária", value: "TBAN" },
  { label: "Cheque", value: "CHEQ" },
  { label: "Crédito Rotativo", value: "CROT" },
];

export const AdiantamentoAberturaOSModal = ({
  setFormaPagAdiant,
  formaPagAdiant,
  setIdCondPagAdiant,
  idCondPagAdiant,
  setValorAdiant,
  valorAdiant,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const refIdCondPagAdiant = useRef();

  const toggle = async () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton md="auto" color="info" onClick={toggle} tabIndex={-1}>
        <BsCashCoin size={20} className="mr-2" />
        Adiantamento
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Adiantamento de Valores da O.S."
        onConfirm={toggle}
        number="0006_13"
      >
        <Row>
          <ComboBox
            md={4}
            label="Forma de Pagamento"
            options={formasPag}
            onChange={(s) => {
              setFormaPagAdiant(s?.value);
              if (s?.value === "DINH") {
                setIdCondPagAdiant(null);
                refIdCondPagAdiant.current.clearValue();
              }
            }}
            defaultValue={formaPagAdiant}
          />
          <AsyncComboBox
            md={4}
            label="Condição Pagamento"
            isConcatField
            concatModelName="cond_pag"
            isSearchable
            onChange={(s) => setIdCondPagAdiant(s?.value)}
            defaultValue={idCondPagAdiant}
            defaultOptions
            disabled={!formaPagAdiant || formaPagAdiant === "DINH"}
            ref={refIdCondPagAdiant}
          />
          <NumberInput
            md={4}
            label="Valor"
            value={valorAdiant}
            onChange={setValorAdiant}
          />
        </Row>
      </ModalBase>
    </>
  );
};
