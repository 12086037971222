import React from "react";
import { ModalBase, Table, TableCheck } from "../../../../../components";
import { formatarValor } from "../../../../../coreUtils";

const DetalhesAdiantamentosModal = ({
  isOpen,
  toggle,
  idOS,
  adiantamentos,
}) => {
  const columns = [
    {
      dataField: "desc_forma_pag",
      text: "Forma de Pagamento",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "desc_cond_pag",
      text: "Condição de Pagamento",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data_adiantamento",
      text: "Data",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "hora_adiantamento",
      text: "Hora",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "recebido",
      text: "Recebido",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title={`Adiantamentos da O.S. Nº ${idOS}`}
      number="0006_8"
    >
      <Table
        columns={columns}
        data={adiantamentos ?? []}
        paginated={false}
        pageSize={6}
        growIntoPageSize
        keyField="id"
      />
    </ModalBase>
  );
};

export default DetalhesAdiantamentosModal;
