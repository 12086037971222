import React, { useState } from "react";
import { ModalBase } from "../../../../../components";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";

export const CancelarNfModal = ({ isOpen, toggle, selected, notifyEvent }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const [ok] = await NotaFiscalService.gerenciamento.cancelarNFe(selected);
    if (ok) {
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Cancelar Nota Fiscal"
      number="0055_6"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <h5 style={{ textAlign: "center" }}>
        Tem certeza de que deseja cancelar a NFe?
      </h5>
      <label style={{ textAlign: "center" }}>
        O Prazo de cancelamento para NFe's é de 7 dias corridos. Caso o prazo
        tenha expirado, é necessário emitir uma nova Nota Fiscal de Estorno da
        NFe original recebida.
      </label>
    </ModalBase>
  );
};
