import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import { AsyncComboBox, BotaoPesquisar } from "../../../../../components";
import { PageContainer } from "../../../../../components/PageContainer";
import RegrasTributacaoService from "../../../../../services/cadastro/RegrasTributacaoService";
import { ProdutosAddGrid } from "./components/ProdutosAddGrid";
import { ProdutosRegraGrid } from "./components/ProdutosRegraGrid";
import { IntegerFormInput } from "../../../../../components";

export const ProdutosRegraTributacao = ({ location }) => {
  const idRegra = location.state.id_regra;
  const nomeRegra = location.state.nome_regra;
  const [produto, setProduto] = useState(null);
  const [grupo, setGrupo] = useState(null);
  const [fabricante, setFabricante] = useState(null);
  const [ncm, setNcm] = useState(null);
  const [produtosAdd, setProdutosAdd] = useState([]);
  const [produtosRegra, setProdutosRegra] = useState([]);
  const [selectedAdd, setSelectedAdd] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const limparProdutosAdd = () => {
    setSelectedAdd([]);
  };

  const carregarProdutosAdd = async () => {
    limparProdutosAdd();
    setLoading(true);
    const params = {
      id_produto: produto,
      id_grupo: grupo,
      id_fabricante: fabricante,
      ncm: ncm,
    };
    const [ok, ret] = await RegrasTributacaoService.listarProdutosAdd(
      idRegra,
      params
    );
    setProdutosAdd(ok ? ret : []);
    setLoading(false);
  };

  const carregarProdutos = async () => {
    const [ok, ret] = await RegrasTributacaoService.listarProdutos(idRegra);
    setProdutosRegra(ok ? ret : []);
  };

  const handleDelete = async (idProdRegra) => {
    const [ok] = await RegrasTributacaoService.excluirProduto(idProdRegra);
    if (ok) {
      carregarProdutos();
    }
  };

  const handleSelectAdd = (id, isSelect) => {
    if (!loading) {
      setSelectedAdd(
        isSelect
          ? [...selectedAdd, id]
          : selectedAdd.filter((item) => {
              return item !== id;
            })
      );
    }
  };

  const handleOnSelectAll = (isSelect) =>
    setSelectedAdd(isSelect ? produtosAdd.map((e) => e.id) : []);

  const addProdutos = async () => {
    setLoadingAdd(true);
    let listProds = [];

    produtosAdd.forEach(
      (e) => selectedAdd.includes(e.id) && listProds.push({ id_prod: e.id })
    );

    const payload = {
      id_regra: idRegra,
      list_prods: listProds,
    };

    const [ok] = await RegrasTributacaoService.inserirProduto(payload);

    setLoadingAdd(false);

    carregarProdutos();

    // Atualiza listagem de produtos para adicionar.
    // Se só havia um produto a ser adicionado, apenas
    // limpa a grid para evitar a mensagem de erro.
    if (ok && produtosAdd.length > 0) {
      if (
        listProds.length === 1 &&
        produtosAdd.length === 1 &&
        produtosAdd[0].id === listProds[0].id_prod
      ) {
        limparProdutosAdd();
      } else {
        carregarProdutosAdd();
      }
    }
  };

  useEffect(() => {
    carregarProdutos();
  }, []);

  return (
    <PageContainer
      title={`Regra ${idRegra} - ${nomeRegra}`}
      canGoBack
      number="0031_3"
    >
      <Card body>
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="produto"
            isSearchable
            md={4}
            label="Produto"
            defaultOptions
            onChange={(s) => setProduto(s?.value)}
          />
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="grupo"
            isSearchable
            md={3}
            label="Grupo"
            defaultOptions
            onChange={(s) => setGrupo(s?.value)}
          />
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="fabricante"
            isSearchable
            md={3}
            label="Fabricante"
            defaultOptions
            onChange={(s) => setFabricante(s?.value)}
          />
          <IntegerFormInput md={1} label="NCM" onChange={setNcm} />
          <BotaoPesquisar loading={loading} onClick={carregarProdutosAdd} />
        </Row>
      </Card>
      <ProdutosAddGrid
        data={produtosAdd}
        selected={selectedAdd}
        addProdutos={addProdutos}
        onSelect={handleSelectAdd}
        onSelectAll={handleOnSelectAll}
        loadingAdd={loadingAdd}
      />
      <ProdutosRegraGrid data={produtosRegra} handleDelete={handleDelete} />
    </PageContainer>
  );
};
