import React, { useState } from "react";
import { ModalBase } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  setClienteCuringa,
  setCpfCnpjCliente,
  setIdCliente,
  setNomeCliente,
} from "../store/vendaCabSlice";

export const ConfirmarAlterarClienteModal = ({
  isOpen,
  toggle,
  dadosClienteAlterar,
  cancelarAlteracao,
  recalcularValoresItens,
}) => {
  const params = useSelector((state) => state.paramsFrenteVenda);
  const dispatch = useDispatch();
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const confirmarAlteracao = async () => {
    setLoadingConfirm(true);
    try {
      dispatch(setIdCliente(dadosClienteAlterar.id));
      dispatch(setNomeCliente(dadosClienteAlterar.nome));
      dispatch(setClienteCuringa(dadosClienteAlterar.curinga));
      dispatch(setCpfCnpjCliente(dadosClienteAlterar.cpfCnpj));

      await recalcularValoresItens(dadosClienteAlterar.id);

      toggle();
    } finally {
      setLoadingConfirm(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Confirmar Alteração do Cliente"
      number="0030_1"
      onConfirm={confirmarAlteracao}
      loadingConfirm={loadingConfirm}
      onCancel={cancelarAlteracao}
      confirmButtonText="Sim"
      cancelButtonText="Não"
    >
      <p style={{ marginBottom: 0 }}>
        {params.tem_lista_preco === true
          ? "Foi detectado que sua empresa utiliza Listas de Preços."
          : "Foi detectado que sua empresa utiliza Regras de Tributação."}
        <br />
        Por isso, ao alterar o cliente, é necessário recalcular os valores dos
        itens.
        <br />
        <b className="text-danger">
          Atenção: o sistema irá alterar o valores dos itens, se necessário.
        </b>
        <br />
        Você deseja continuar?
      </p>
    </ModalBase>
  );
};
