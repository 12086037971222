import React, { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import CadastroCampoPersonService from "../services/cadastro/CadastroCampoPersonService";
import { FormCheckbox } from "./FormCheckbox";

export const CamposPersonToggle = ({ tableName, onChange }) => {
  const [campos, setCampos] = useState([]);
  const [values, setValues] = useState([]);

  const handleChange = (id, v) => {
    let _state = [...values];
    _state[_state.findIndex((e) => e.id === id)].enabled = v;
    setValues(_state);
    onChange(_state);
  };

  const carregarCamposPerson = async () => {
    const ret = await CadastroCampoPersonService.buscarCampos(tableName);
    setCampos(ret);
    const _values = ret.map((e) => {
      return {
        id: e.id,
        nome: e.nome_campo,
        tipo: e.tipo_campo,
        enabled: true,
      };
    });
    setValues(_values);
    onChange(_values);
  };

  useEffect(() => {
    carregarCamposPerson();
  }, []);

  return (
    <Col style={{ marginTop: "auto" }}>
      <Label>Selecionar Campos Personalizados:</Label>
      <Row className="mb-3">
        {campos.map((e) => {
          let label = e.nome_campo
            .toLowerCase()
            .replace(/^\w/, (c) => c.toUpperCase());
          return (
            <FormCheckbox
              label={label}
              name={e.nome_campo}
              padded={false}
              checked={values.find((item) => item.id === e.id)?.enabled}
              onChange={(event) => handleChange(e.id, event.target.checked)}
            />
          );
        })}
      </Row>
    </Col>
  );
};
