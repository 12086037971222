import React, { useState } from "react";
import {
  formatValueFromAPI,
  naturalSortDate,
} from "../../../../../coreUtils";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import { ModalBase, Table } from "../../../../../components";

const columns = [
  {
    dataField: "data_hora_log",
    text: "Data/Hora",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  { dataField: "log", text: "Descrição", align: "left", sortable: true },
  {
    dataField: "nome_colaborador",
    text: "Responsável",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
    sortable: true,
  },
];

export const LogNfModal = ({ isOpen, toggle, lastSelected }) => {
  const [dados, setDados] = useState([]);

  const limparDados = () => {
    setDados([]);
  };

  const buscarDados = async () => {
    const [ok, ret] =  await NotaFiscalService.gerenciamento.buscarLogs(lastSelected);
    setDados(ok ? ret : []);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      onOpened={buscarDados}
      toggle={toggle}
      size="lg"
      title="Logs de NFEs"
      number="0055_4"
      onClosed={limparDados}
    >
      <Table data={dados} columns={columns} paginated={false} pageSize={15} />
    </ModalBase>
  );
};
