import React from "react";
import { Card, Row } from "reactstrap";
import { Table, TableCheck } from "../../../../../components";

export const GridClientesCamposPerson = ({
  camposPerson,
  dados,
  onSelect,
  action,
  onSelectAll,
  paginated,
  selected,
}) => {
  const enabledCustomFields = camposPerson.filter((e) => e.enabled);

  const columns = [
    {
      text: "#",
      align: "center",
      headerAlign: "cente",
      formatter: (c, r, index) => index + 1,
    },
    {
      text: "Nome",
      dataField: "nome",
      align: "left",
      headerAlign: "left",
      formatter: (cell) => cell.substr(0, 39),
    },
    ...enabledCustomFields.map((field) => ({
      text: field.nome,
      dataField: field.nome,
      align: field.tipo === "TEXT" ? "left" : "center",
      headerAlign: field.tipo === "TEXT" ? "left" : "center",
      formatter: (c) =>
        field.tipo === "CHECK" ? <TableCheck value={c === "true"} /> : c,
    })),
  ];

  return (
    <Card body>
      <Row className="no-gutters mb-2">{action}</Row>
      <Table
        columns={columns}
        data={dados}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        multiselect
        paginated={paginated}
        selected={selected}
      />
    </Card>
  );
};
