import axios from "axios";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
import settings from "./settings";
import { showError, showSuccess } from "./components/AlertaModal";
import { formatDateISO, isDevEnv } from "./coreUtils";
import qs from "qs";
import Axios from "axios";

const kClientId = "xc3BYHQIbFFQL00nhwuSBL10ULggkYOK6vfivM1X";
const kClientSecret =
  "MUi14fA8i8pYiDhk3oymVgzL4MRGkWBCT5c0TFCzqaLy0xnsb5MvY5K8KlGCdfmIfC" +
  "EEmPjJc3Gmz8mqXAKF71v1AtzyeSCGD2R9Jco2JVMFe4hP1IrndHTuzrz5ZPBG";

let api = axios.create({
  baseURL: Cookies.get("api-address"),
  headers: {
    ...(isDevEnv() ? { "database-name": settings.DB_NAME_DEV } : {}),
    Authorization: `Bearer ${Cookies.get("access-token")}`,
  },
});

export const updateAPIV2 = (apiAddress, accessToken) => {
  api = axios.create({
    baseURL: apiAddress,
    headers: {
      ...(isDevEnv() ? { "database-name": settings.DB_NAME_DEV } : {}),
      Authorization: `Bearer ${accessToken}`,
    },
  });
  setupInterceptors(api);
};

// Atualiza token caso necessário
const refreshTokenV2 = async (error) => {
  try {
    const data = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: Cookies.get("refresh-token"),
    });
    await axios
      .post(`${settings.AUTENTICADOR}/o/token/`, data, {
        timeout: 5000,
        auth: { username: kClientId, password: kClientSecret },
      })
      .then(async (res) => {
        Cookies.set("access-token", res.data.access_token);
        Cookies.set("refresh-token", res.data.refresh_token);
        return res;
      })
      .catch((_) => {
        return error;
      });
  } catch (err) {
    return err;
  }
};

const setupInterceptors = (apiClient) => {
  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const access_token = Cookies.get("access-token");
      if ([403, 401].includes(error.response?.status) && access_token) {
        await refreshTokenV2(error);
        const new_access_token = Cookies.get("access-token");
        error.config.headers["Authorization"] = `Bearer ${new_access_token}`;
        error.config.baseURL = Cookies.get("api-address");
        return Axios.request(error.config);
      }
      return Promise.reject(error);
    }
  );
};

setupInterceptors(api);

export { api as apiV2, kClientId, kClientSecret };

export const tratarParams = (params) =>
  Object.entries(params)
    .filter(([key, value]) => !["", null, undefined].includes(value))
    .reduce((acc, [key, value]) => {
      if (value instanceof Date) {
        value = formatDateISO(value);
      }
      return { ...acc, [key]: value };
    }, {});

export const revokeTokenV2 = async () => {
  try {
    const data = new URLSearchParams({
      token: Cookies.get("access-token"),
      client_id: kClientId,
      client_secret: kClientSecret,
    });
    await axios
      .post(`${settings.AUTENTICADOR}/o/revoke-token/`, data, {
        timeout: 5000,
      })
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  } catch (err) {
    return err;
  }
};

const processaRetorno = ({ data }, options) => {
  const success = data.success || (data.hasOwnProperty("err") && !data.err);

  if (success) {
    if (options?.successMessage) {
      if (options?.successMessageType === "modal") {
        showSuccess(data.msg);
      } else {
        toastr.success("Sucesso", data.msg);
      }
    }
  } else {
    if (options?.errorMessage) {
      if (options?.errorMessageType === "toastr") {
        toastr.error("Erro", data.msg);
      } else {
        showError(data.msg, data.id_err, data.res);
      }
    }
  }
  return [success, success ? data.res : data];
};

const processaErro = (err, errorMessage = true) => {
  let msgErro = err.message;
  let statusErro = err.response?.status ?? err.status;
  let codigoErro = `SERVER_ERROR_${statusErro}`;
  if (!statusErro) {
    msgErro =
      "Não foi possível conectar.\n" +
      "Por favor, verifique sua conexão com a Internet e tente novamente.";
    codigoErro = "SemConexao";
  }

  if (errorMessage) showError(msgErro, codigoErro);
  return [false, { msg: msgErro, id_err: codigoErro }];
};

const apiGetV2 = async (url, params, options) =>
  await api
    .get(url, {
      params: params ? tratarParams(params) : undefined,
      paramsSerializer: (par) => qs.stringify(par, { arrayFormat: "repeat" }),
    })
    .then((ret) => processaRetorno(ret, { errorMessage: true, ...options }))
    .catch((err) => processaErro(err, options?.errorMessage ?? true));

const apiPostV2 = async (url, payload, options) =>
  await api
    .post(url, payload)
    .then((ret) =>
      processaRetorno(ret, {
        successMessage: true,
        errorMessage: true,
        ...options,
      })
    )
    .catch((err) => processaErro(err));

const apiPutV2 = async (url, payload, options) =>
  await api
    .put(url, payload)
    .then((ret) =>
      processaRetorno(ret, {
        successMessage: true,
        errorMessage: true,
        ...options,
      })
    )
    .catch((err) => processaErro(err));

const apiDeleteV2 = async (url) =>
  await api
    .delete(url)
    .then((ret) =>
      processaRetorno(ret, { successMessage: true, errorMessage: true })
    )
    .catch((err) => processaErro(err));

export { apiGetV2, apiPostV2, apiPutV2, apiDeleteV2 };

export const buscarLogoBase64 = async (noDefault = true) =>
  await api
    .get("/cadastro/empresa/logo/", {
      responseType: "blob",
      headers: { "Cache-Control": "no-cache" },
    })
    .then(
      (res) =>
        new Promise((resolve, reject) => {
          if (noDefault && res.headers["default-logo"] === "1") {
            reject(null);
          }
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(res.data);
        })
    )
    .catch(() => null);
