import React from "react";
import { Card } from "reactstrap";
import { formatNumber } from "../../../../../../coreUtils";
import { Table } from "../../../../../../components";

export const ExtratoClienteGrid = ({ data }) => {
  const styles = {
    vendaLinha: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  };

  const renderRecebimentos = (c) => (
    <div>
      {c.length > 0 ? (
        c.map((e) => {
          switch (e.descricao_moeda) {
            case "Dinheiro":
              return (
                <p>
                  <strong>Recebido em Dinheiro</strong>
                  <br />
                  <b></b>
                  <b>
                    {e.data_rec} - R$ {formatNumber(e.valor, true, 2)}
                  </b>
                </p>
              );
            case "Inclusão de Crédito de Cliente via Troco":
            case "Crédito Cliente":
              return (
                <p>
                  <strong>{e.descricao_moeda}</strong>
                  <br />
                  <b></b>
                  <b>
                    {e.data_rec} - R$ {formatNumber(e.valor, true, 2)}
                  </b>
                </p>
              );
            case "Cartão de Débito":
            case "Cartão de Crédito":
            case "Cartão de Débito Incluído Manualmente":
            case "Cartão de Crédito Incluído Manualmente":
              return (
                <p>
                  <strong>
                    {e.descricao_moeda}
                    {e.descricao_moeda.includes("Incluído Manualmente") &&
                      ` - ${e.data_mov}`}
                  </strong>
                  <br />
                  {e.parcelas.map((p) => (
                    <div>
                      <b>
                        {p.parcela} - {p.previsao_recebimento} - R${" "}
                        {formatNumber(p.vlr_receber, true, 2)} -{" "}
                        {p.compensado
                          ? `Compensado: R$ ${formatNumber(
                              p.vlr_compensado,
                              true,
                              2
                            )}`
                          : "Não Compensado"}
                      </b>
                      <br />
                    </div>
                  ))}
                </p>
              );
            case "Contas a Receber/Crediário":
            case "Contas a Receber Manual":
              return (
                <p>
                  <strong>
                    {e.descricao_moeda}
                    {e.descricao_moeda === "Contas a Receber Manual" &&
                      ` - ${e.data_emi}`}
                  </strong>
                  <br />
                  {e.parcelas?.map((p) => (
                    <div>
                      <b>
                        {p.parcela} - {p.vencimento} - Receber: R${" "}
                        {formatNumber(p.vlr_receber, true, 2)} - Recebido: R${" "}
                        {formatNumber(p.vlr_recebido, true, 2)}
                      </b>
                      <br />
                    </div>
                  ))}
                </p>
              );
            case "Transf. Bancária":
              return (
                <p>
                  <strong>{e.descricao_moeda}</strong>
                  <br />
                  <b></b>
                  <b>
                    {e.data_rec} - R$ {formatNumber(e.valor, true, 2)} - Tipo:{" "}
                    {e.tipo_transferencia}
                  </b>
                </p>
              );
            case "Cheque":
            case "Cheque Incluído Manualmente":
              return (
                <p>
                  <strong>
                    {e.descricao_moeda}
                    {e.descricao_moeda.includes("Incluído Manualmente") &&
                      ` - ${e.data_mov}`}
                  </strong>
                  <br />
                  <b>
                    Nro: {e.nro_cheque} - Vencimento: {e.vencimento} - R${" "}
                    {formatNumber(e.valor, true, 2)}
                  </b>
                </p>
              );
            case "Crédito Rotativo":
              return (
                <p>
                  <strong>Registrado em Crédito Rotativo</strong>
                  <br />
                  <span>
                    Recebido: R$ {formatNumber(e.vlr_recebido, true, 2)} -
                    Pendente: R$ {formatNumber(e.vlr_pendente, true, 2)}
                  </span>
                  <b>
                    {e.faturas_rotativo?.map((f) => (
                      <div>
                        <b>Lote {f.id_lote}</b>
                        <br />
                        {f.recebimentos.map((r) => (
                          <>
                            <b>
                              Recebimento em {r.descricao_moeda} - {r.data_rec}{" "}
                              - {formatNumber(r.valor, true, 2)}
                              {r.descricao_moeda === "Transf. Bancária" &&
                                ` - Tipo: ${r.tipo_transferencia}`}
                            </b>
                            <br />
                          </>
                        ))}
                      </div>
                    ))}
                  </b>
                </p>
              );
            case "Pendente Crédito Rotativo":
              return (
                <p>
                  Pendente em Crédito Rotativo - R${" "}
                  {formatNumber(e.valor, true, 2)}
                </p>
              );
            case undefined:
              return (
                <p>
                  <strong>{e.historico}</strong>
                  <br />
                  <b></b>
                  <b>
                    {e.data_mov} - R$ {formatNumber(e.valor, true, 2)}
                  </b>
                </p>
              );
            default:
              return <p>{JSON.stringify(e)}</p>;
          }
        })
      ) : (
        <strong>Em Aberto</strong>
      )}
    </div>
  );

  const renderVendaOS = (row) =>
    row.id &&
    (row.tipo_mov === "Venda" ? (
      <>
        <div style={styles.vendaLinha}>
          <b>
            Nº {row.tipo_mov}: {row.id}
          </b>
          <b>Emissão: {row.data_emi}</b>
          <b>Vlr. Tot. Prod.: {formatNumber(row.vlr_tot_prod, true, 2)}</b>
        </div>
        <div style={styles.vendaLinha}>
          <b>Vlr. Frete: {formatNumber(row.vlr_frete, true, 2)}</b>
          <b>Vlr. Desconto: {formatNumber(row.vlr_desc, true, 2)} </b>
          <b>Vlr. Total: {formatNumber(row.vlr_total, true, 2)} </b>
        </div>
      </>
    ) : (
      <>
        <div style={styles.vendaLinha}>
          <b>Nº O.S.: {row.id}</b>
          <b>Data: {row.data_mov}</b>
        </div>
        <div style={styles.vendaLinha}>
          <b>Vlr. Desconto: {formatNumber(row.vlr_desc, true, 2)} </b>
          <b>Vlr. Total: {formatNumber(row.vlr_total, true, 2)} </b>
        </div>
      </>
    ));

  const columns = [
    {
      dataField: "venda",
      text: "Venda/O.S.",
      fixedColWidth: true,
      colWidth: "41%",
      formatter: (c, row) => renderVendaOS(row),
    },
    {
      dataField: "recebimentos",
      text: "Recebimentos",
      fixedColWidth: true,
      colWidth: "59%",
      formatter: (c, row) =>
        c ? renderRecebimentos(c) : renderRecebimentos([row]),
    },
  ];

  return (
    <Card body>
      <Table
        data={data}
        columns={columns}
        fixedSize={false}
        paginated={false}
        showRegisterCount={false}
      />
    </Card>
  );
};
