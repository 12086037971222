import Axios from "axios";
import { toastr } from "react-redux-toastr";
import { limparNumero } from "../../coreUtils";
import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/cadastro/cliente";
const URL_BASE_CONTATO = `${URL_BASE}/contato`;
const URL_BASE_COMPRADOR = `${URL_BASE}/comprador`;

const CadastroClienteService = {
  urlBase: URL_BASE,

  alterar: async (payload) => await apiPutV2(`${URL_BASE}/alterar/`, payload),
  buscar: async (id) => await apiGetV2(`${URL_BASE}/buscar/${id}/`),
  buscarCep: async (cep) =>
    await Axios.get(`https://viacep.com.br/ws/${limparNumero(cep)}/json/`)
      .then((ret) => {
        if (ret.data.erro) {
          toastr.error(
            "Erro",
            "Houve um erro ao buscar o CEP informado. " +
              "Por favor, revise-o e tente novamente."
          );
          return false;
        }
        return ret.data;
      })
      .catch((err) => {
        toastr.error("Erro", "Erro ao consultar CEP: " + err.message);
        return false;
      }),
  buscarDadosPreencAuto: async (cnpj) =>
    await Axios.get(`https://publica.cnpj.ws/cnpj/${cnpj.replace(/\D/g, "")}`)
      .then((ret) => ret.data)
      .catch((err) => {
        toastr.error(
          "Erro",
          err.response ? err.response.data.detalhes : err.message
        );
        return false;
      }),
  buscarResumo: async (id) =>
    await apiGetV2(`${URL_BASE}/buscar_resumo/${id}/`),
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir/`, payload),
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  listarAutomotivo: async (params) =>
    await apiGetV2(`${URL_BASE}/automotivo/listar/`, params),
  pesquisa: async (pista) =>
    await apiGetV2(`${URL_BASE}/pesquisa/`, { pista: pista }),
  transformarCuringa: (payload) =>
    apiPutV2(`${URL_BASE}/transformar_curinga/`, payload),
  contato: {
    alterar: async (payload) =>
      await apiPutV2(`${URL_BASE_CONTATO}/alterar/`, payload),
    excluir: async (idContato) =>
      await apiDeleteV2(`${URL_BASE_CONTATO}/excluir/${idContato}/`),
    incluir: async (payload) =>
      await apiPostV2(`${URL_BASE_CONTATO}/incluir/`, payload),
    listar: async (idCliente) =>
      await apiGetV2(`${URL_BASE_CONTATO}/listar/${idCliente}/`),
  },
  comprador: {
    buscar: async (idCliente) =>
      await apiGetV2(`${URL_BASE_COMPRADOR}/buscar/${idCliente}/`),
    alterar: async (payload) =>
      await apiPutV2(`${URL_BASE_COMPRADOR}/alterar/`, payload),
  },
};

export default CadastroClienteService;
