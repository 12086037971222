import React, { useState } from "react";
import { AsyncComboBox, ModalBase, TextInput } from "../../../../../components";
import { Row } from "reactstrap";
import { toastr } from "react-redux-toastr";
import OrcamentosService from "../../../../../services/orcamentos/OrcamentosService";

export const RejeitarOrcamentoModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [idMotivo, setIdMotivo] = useState(0);
  const [observacao, setObservacao] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdMotivo(0);
    setObservacao("");
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idMotivo)) {
      toastr.warning("Atenção", "Por favor, informe o Motivo.");
      return false;
    }

    const payload = {
      id_orcamento: selected,
      id_motivo: idMotivo,
      observacao: observacao,
    };

    setLoading(true);
    const [ok] = await OrcamentosService.gerenciamento.rejeitar(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title={`Rejeitar Orçamento Nº ${selected}`}
      number="0083_3"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onClosed={limparDados}
    >
      <Row>
        <AsyncComboBox
          md={8}
          isConcatField
          concatModelName="motivo"
          defaultOptions
          label="Motivo"
          isSearchable
          onChange={(s) => setIdMotivo(s?.value)}
          defaultValue={idMotivo}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          rows={3}
          type="textarea"
          label="Observação"
          value={observacao}
          onChange={(_, v) => setObservacao(v)}
          forceUppercase={false}
        />
      </Row>
    </ModalBase>
  );
};
