import React from "react";
import { apiGet, urlParams } from "../../../../api";
import { useState } from "react";
import {
  BaseInfoCard,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  FiltroPeriodoDatas,
  FormButton,
  FormCheckbox,
  PageContainer,
  RadioGroup,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { useEffect } from "react";
import { ClientesNaoVendidosGrid } from "./components/ClientesNaoVendidosGrid";
import { docPrintReport } from "../../../../pdf";
import {
  exportDataToSheet,
  formatNumber,
  formatValueFromAPI,
} from "../../../../coreUtils";
import { RadioItem } from "../../../../components/RadioGroup";
import { ClientesNaoVendidosGraficos } from "./components/ClientesNaoVendidosGraficos";

export const ClientesNaoVendidos = () => {
  const [modo, setModo] = useState("V");
  const [dataFim, setDataFim] = useState(new Date());
  const [dataIni, setDataIni] = useState(new Date());
  const [order, setOrder] = useState("M");
  const [imprimirDetalhamento, setImprimirDetalhamento] = useState(false);
  const [vendedores, setVendedores] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);

  const limparDados = () => {
    setVendedores([]);
    setCidades([]);
    setTotais({});
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      order: order,
    };

    const visao = modo === "V" ? "visao_vendedor" : "visao_cidade";

    return await apiGet(
      `/relatorios/venda/clientes_nao_vendidos/${visao}/${urlParams(params)}`
    );
  };

  const carregarDados = async () => {
    setLoading(true);
    const ret = await buscarDados();

    if (Object.keys(ret).length > 0) {
      if (modo === "V") {
        setVendedores(ret.vendedores);
      } else {
        setCidades(ret.cidades);
      }
      setTotais(ret.totais);
    }
    setLoading(false);
  };

  const imprimirRelatorio = async (dados, imprimirDetalhes) => {
    const payload = {
      ...dados,
      modo: modo,
      data_ini: dataIni,
      data_fim: dataFim,
      imprimir_detalhes: imprimirDetalhes,
    };

    await docPrintReport(
      "/relatorios/venda/clientes_nao_vendidos/",
      payload,
      "5045"
    );
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const ret = await buscarDados();

    if (Object.keys(ret).length > 0) {
      await imprimirRelatorio(ret, imprimirDetalhamento);
    }

    setLoadingImprimir(false);
  };

  const imprimirDet = async (nomeAgrup, row) => {
    let dados = {
      vendedores: [],
      rotas: [],
      cidades: [],
      totais: {
        num_clientes: row.num_clientes,
        num_atend: row.num_atend,
        perc_atend: row.perc_atend,
        num_nao_atend: row.num_nao_atend,
        perc_nao_atend: row.perc_nao_atend,
      },
    };

    dados[nomeAgrup].push(row);

    return await imprimirRelatorio(dados, true);
  };

  const exportarRelatorio = async () => {
    setLoadingExportar(true);
    const ret = await buscarDados();

    if (Object.keys(ret).length > 0) {
      const gerarNomeItem = (row) =>
        modo === "V" ? row.nome : `${row.nome}-${row.uf}`;

      const formatarAgrup = (ag, nomeAgrup) => ({
        [nomeAgrup]: gerarNomeItem(ag),
        "Total Clientes": parseFloat(ag.num_clientes),
        Atendidos: parseFloat(ag.num_atend),
        "Perc. Atendido": parseInt(ag.perc_atend),
        "Não Atendidos": parseFloat(ag.num_nao_atend),
        "Perc. Não Atendido": parseInt(ag.perc_nao_atend),
      });

      const formatarCliente = (cli) =>
        modo === "V"
          ? {
              Cliente: formatValueFromAPI(cli.nome, cli.id),
              "Nome Fantasia": cli.nome_fant,
              "Ult. Venda": cli.ultima_venda,
              Bairro: cli.bairro,
              Cidade: cli.cidade__nome,
              Fone: cli.fone1,
            }
          : {
              Cliente: formatValueFromAPI(cli.nome, cli.id),
              "Nome Fantasia": cli.nome_fant,
              Representante: cli.vendedor,
              "Ult. Venda": cli.ultima_venda,
              Bairro: cli.bairro,
              Cidade: cli.cidade__nome,
              Fone: cli.fone1,
            };

      const formatarDados = (dados, nomeAgrup) =>
        imprimirDetalhamento
          ? dados.reduce(
              (acc, ag) => [
                ...acc,
                ...ag.clientes_nao_atend.map((cli) => ({
                  [nomeAgrup]: gerarNomeItem(ag),
                  ...formatarCliente(cli),
                })),
              ],
              []
            )
          : dados.map((ag) => formatarAgrup(ag, nomeAgrup));

      let data = [];
      if (modo === "V") {
        data = [...formatarDados(ret.vendedores, "Vendedor/Rota")];
      } else {
        data = formatarDados(ret.cidades, "Cidade");
      }

      exportDataToSheet(data, "Clientes Não Atendidos", {
        dataIni: dataIni,
        dataFim: dataFim,
      });
    }
    setLoadingExportar(false);
  };

  useEffect(() => {
    limparDados();
  }, [dataFim, dataIni, modo, order]);

  return (
    <PageContainer title="Clientes Não Vendidos" number="5045" canGoBack>
      <Card body>
        <Row>
          <RadioGroup
            label="Agrupar Por"
            value={modo}
            onChange={setModo}
            disabled={loading || loadingImprimir}
          >
            <RadioItem label="Vendedor" value="V" />
            <RadioItem label="Cidade" value="C" />
          </RadioGroup>
          <FiltroPeriodoDatas
            onChange={handleDate}
            disabled={loading || loadingImprimir}
          />
          <RadioGroup
            label="Ordenar Por"
            value={order}
            onChange={setOrder}
            disabled={loading || loadingImprimir}
          >
            <RadioItem label="Melhor Desempenho" value="M" />
            <RadioItem label="Pior Desempenho" value="P" />
          </RadioGroup>
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
          <FormCheckbox
            label="Considerar clientes na impressão"
            checked={imprimirDetalhamento}
            onChange={() => setImprimirDetalhamento(!imprimirDetalhamento)}
            disabled={loadingImprimir || loadingExportar}
          />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportarRelatorio}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Total de Clientes"
          value={totais?.num_clientes}
          checkFloat
        />
        <CardTotaisItem
          label="Atendidos"
          value={
            formatNumber(totais?.num_atend ?? 0, true, 2, true) +
            " | " +
            formatNumber(totais?.perc_atend ?? 0, true, 2, true) +
            "%"
          }
          format={false}
        />
        <CardTotaisItem
          label="Não Atendidos"
          value={
            formatNumber(totais?.num_nao_atend ?? 0, true, 2, true) +
            " | " +
            formatNumber(totais?.perc_nao_atend ?? 0, true, 2, true) +
            "%"
          }
          format={false}
        />
      </CardTotais>
      {(vendedores.length > 0 || cidades.length > 0) && (
        <>
          <Card body>
            {vendedores.length > 0 && (
              <ClientesNaoVendidosGraficos
                dados={vendedores}
                nomeAgrup="vendedores"
              />
            )}
            {cidades.length > 0 && (
              <ClientesNaoVendidosGraficos
                dados={cidades}
                nomeAgrup="cidades"
              />
            )}
          </Card>
          <Card body>
            {vendedores.length > 0 && (
              <ClientesNaoVendidosGrid
                dados={vendedores}
                nomeAgrup="vendedores"
                imprimirDet={imprimirDet}
              />
            )}
            {cidades.length > 0 && (
              <ClientesNaoVendidosGrid
                dados={cidades}
                nomeAgrup="cidades"
                imprimirDet={imprimirDet}
              />
            )}
          </Card>
        </>
      )}
      <BaseInfoCard
        baseInfo={[
          "Vendas: Vendas realizadas pelos aplicativos de Pedido de Venda e Pronta Entrega",
        ]}
      />
    </PageContainer>
  );
};
