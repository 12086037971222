import React from "react";
import { Table } from "../../../../../../components";

const columns = [
  {
    dataField: "ordem",
    text: "Ordem",
    align: "center",
    headerAlign: "center",
    sort: true,
  },
  {
    dataField: "id_cliente",
    text: "Cliente",
    align: "center",
    headerAlign: "center",
    sort: true,
  },
  {
    dataField: "id_cliente__nome",
    text: "Nome",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) =>
      row.bloqueado ? (
        <>
          {c} <span style={{ color: "red" }}>Cliente bloqueado!</span>
        </>
      ) : (
        c
      ),
  },
  {
    dataField: "id_cliente__nome_fant",
    text: "Nome Fant.",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "id_cliente__cidade__nome",
    text: "Cidade",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "id_cliente__endereco",
    text: "Endereço",
    align: "left",
    headerAlign: "left",
    formatter: (_, row) =>
      `${row.id_cliente__endereco}, ${row.id_cliente__numero}`,
  },
  {
    dataField: "id_cliente__bairro",
    text: "Bairro",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "id_cliente__fone1",
    text: "Telefone",
    align: "center",
    headerAlign: "center",
  },
];

export const RoteirizacaoGrid = ({ data, selected, setSelected }) => {
  return (
    <Table
      data={data}
      columns={columns}
      selected={selected}
      onSelect={setSelected}
      paginated={false}
      clearSelectionOnUpdate
    />
  );
};
