import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormButton,
  PageContainer,
} from "../../../../components";
import { apiGetV2 } from "../../../../apiV2";
import {
  NfDebitoLocacaoGrid,
  columnsNfDebitoLocacao,
} from "./components/NfDebitoLocacaoGrid";
import { exportDataToSheet } from "../../../../coreUtils";

const columnsRelatorio = columnsNfDebitoLocacao(true);

export const NfDebitoLocacao = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
    };
    return await apiGetV2("/relatorios/locacao/resumo_nf_debito_loc/", params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      exportDataToSheet(ret, "NF Débito Locação", {
        columns: columnsRelatorio,
        dataIni: dataIni,
        dataFim: dataFim,
      });
    }
    setLoadingExportar(false);
  };

  return (
    <PageContainer
      title="Notas Fiscais de Débito de Locação"
      number="5050"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            disabled={!(dataIni instanceof Date) || !(dataFim instanceof Date)}
            disabledHint="Informe datas válidas de Início e Fim"
          />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportar}
            loading={loadingExportar}
            disabled={!(dataIni instanceof Date) || !(dataFim instanceof Date)}
            disabledHint="Informe datas válidas de Início e Fim"
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <NfDebitoLocacaoGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
