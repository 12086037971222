import React from "react";
import { Card } from "reactstrap";
import { DataTable } from "../../../../components/DataTable";

export const PermissoesTable = ({ items, handleSelect }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      align: "center",
      editable: false,
      headerClasses: "text-center",
    },
    {
      dataField: "tipo_uso",
      text: "Tipo",
      sort: true,
      editable: false,
    },
    {
      dataField: "id_uso",
      text: "Identificador",
      sort: true,
      editable: false,
    },
  ];

  return (
    <Card body>
      <DataTable data={items} columns={columns} onSelect={handleSelect} />
    </Card>
  );
};

export default PermissoesTable;
