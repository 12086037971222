import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoPesquisar, PageContainer } from "../../../../components";
import { ProdutosBalancaGrid } from "./components/ProdutosBalancaGrid";

export const urlRelatorioProdutosBalanca =
  "/relatorios/cadastrais/produtos/produtos_balanca/";

export const ProdutosBalanca = () => {
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await apiGetV2(urlRelatorioProdutosBalanca);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  return (
    <PageContainer title="Produtos de Balança" number="5052" canGoBack>
      <Card body>
        <Row>
          <BotaoPesquisar
            padded={false}
            onClick={carregarDados}
            loading={loading}
          />
        </Row>
        <ProdutosBalancaGrid dados={dados} />

        <Row>
          <Col>
            <h5>Legenda</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <span style={{ fontWeight: "bold" }}>
              Produtos identificados pela cor AZUL serão pesados pela balança no
              PDV.
              <br />
              Para um produto ser identificado como de balança ele precisar ter
              as seguintes características:
              <ul>
                <li>A unidade cadastrada como KG (Kilogramas)</li>
                <li>O código de balança informado no cadastro</li>
              </ul>
            </span>
          </Col>
        </Row>
      </Card>
    </PageContainer>
  );
};
