import React from "react";
import { DetalhesTecnico } from "./DetalhesTecnico";
import { Label } from "reactstrap";
import { formatNumber, naturalSortDate } from "../../../../../coreUtils";
import { Table } from "../../../../../components";

const getColorResultado = (resultado) => {
  var label;

  if (
    resultado === "-00:00" ||
    resultado === "+00:00" ||
    resultado === "00:00"
  ) {
    label = <Label className="mb-0">{resultado.replace("-", "")}</Label>;
  } else if (resultado.includes("-")) {
    label = <Label className="text-danger mb-0">{resultado}</Label>;
  } else if (resultado.includes("+")) {
    label = <Label className="text-success mb-0">{resultado}</Label>;
  }

  return label;
};

const columns = [
  {
    dataField: "id",
    text: "#",
    sortable: true,
    align: "left",
  },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    sortable: true,
    align: "left",
  },
  {
    dataField: "nome_veic",
    text: "Veículo",
    align: "center",
  },
  {
    dataField: "placa",
    text: "Placa",
    align: "center",
  },
  {
    dataField: "data_fechamento",
    text: "Fechamento",
    sortable: true,
    align: "center",
    sortFunc: naturalSortDate,
  },
  {
    dataField: "vlr_tot_serv",
    text: "Serviços (R$)",
    align: "center",
    formatter: (c) => (c ? formatNumber(c) : " - "),
  },
  {
    dataField: "vlr_total",
    text: "Total (R$)",
    align: "center",
    formatter: (c) => formatNumber(c),
  },
  {
    dataField: "horas_vendidas",
    text: "Horas Vendidas",
    align: "center",
  },
  {
    dataField: "horas_trabalhadas",
    text: "Horas Trabalhadas",
    align: "center",
  },
  {
    dataField: "resultado",
    text: "Resultado",
    sortable: true,
    align: "center",
    formatter: (c) => getColorResultado(c),
  },
];

export const HorasVendidasTrabalhadasGrid = ({ dados }) => {
  return (
    <Table
      data={dados}
      columns={columns}
      canExpand
      expandedAreaClassName="p-0"
      fixedSize={false}
      expandedAreaStyle={{
        backgroundColor: "none",
        boderRadius: "none",
      }}
      expandedAreaBuilder={(row) => <DetalhesTecnico row={row} />}
    />
  );
};
