import React, { useState } from "react";
import XLSX from "xlsx";
import {
  BotaoImprimir,
  FormButton,
  ModalBase,
  RadioGroup,
} from "../../../../../components";
import { RadioItem } from "../../../../../components/RadioGroup";
import { docPrintReport } from "../../../../../pdf";
import {
  formatDateISO,
  formatDateLocal,
  formatNumber,
} from "../../../../../coreUtils";
import { showError } from "../../../../../components/AlertaModal";

export const ImprimirExportarModal = ({
  buscarDados,
  dataIni,
  dataFim,
  mostrarFormasRec,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formato, setFormato] = useState("SIN");
  const [modo, setModo] = useState("IMPR");
  const [loading, setLoading] = useState(false);

  const limparDados = () => setFormato("SIN");

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleImprimir = () => {
    setModo("IMP");
    toggle();
  };

  const toggleExportar = () => {
    setModo("EXP");
    toggle();
  };

  const imprimir = async () => {
    const [ok, ret] = await buscarDados(formato);
    if (ok) {
      return await docPrintReport(
        "/relatorios/venda/vendas_geral/",
        {
          dados: ret.vendas,
          totais: ret.totais,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
          formato: formato,
          mostrar_formas_rec: mostrarFormasRec,
        },
        "5040_1"
      );
    } else {
      return false;
    }
  };

  const exportar = async () => {
    const [ok, ret] = await buscarDados(formato);

    if (ok) {
      try {
        const rows = [];

        // Gera os cabeçalhos
        let row = [
          "Nº Venda",
          "Nome do Cliente",
          "Nome Fantasia",
          "Vendedor",
          "Data / Hora",
          "Origem",
          "Nº Pedido",
          "Vlr. Bruto",
          "Vlr. Desconto",
          "Vlr. Flex",
          "Produtos",
          "Serviços",
          "Total",
        ];
        if (mostrarFormasRec) {
          row.push("Formas de Recebimento");
        }
        rows.push(row);

        ret.vendas.forEach((row) => {
          let rowInc = [
            row.id,
            row.n_cli,
            row.n_f_cli,
            row.n_vend,
            `${row.d_emi} ${row.h_emi}`,
            row.tp_vda,
            row.id_ped,
            parseFloat(row.v_bruto),
            parseFloat(row.v_desc),
            parseFloat(row.v_flex),
            parseFloat(row.vlr_tot_prod),
            parseFloat(row.vlr_tot_serv),
            parseFloat(row.v_tot),
          ];
          if (mostrarFormasRec) {
            rowInc.push(
              (row.formas_rec ?? [])
                .map((e) => `${e.moeda}: ${formatNumber(e.valor, true, 2)}`)
                .join(" | ")
            );
          }
          rows.push(rowInc);

          if (formato === "ANA") {
            if (row.itens?.length > 0) {
              rows.push([
                "",
                "Item",
                "Tipo",
                "Descrição",
                "Quant.",
                "Vlr. Unit",
                "Vlr. Item",
                "% Desc.",
                "Vlr. Desc",
                "Vlr. Flex",
                "ICMS ST",
                "IPI",
                "Total",
              ]);

              row.itens.forEach((itemRow) =>
                rows.push([
                  "",
                  itemRow.item,
                  itemRow.tipo_item,
                  itemRow.descricao,
                  parseFloat(itemRow.quantidade),
                  parseFloat(itemRow.vlr_unit),
                  parseFloat(itemRow.vlr_item),
                  parseFloat(itemRow.perc_desc),
                  parseFloat(itemRow.vlr_desc),
                  parseFloat(itemRow.vlr_flex),
                  parseFloat(itemRow.vlr_icms_st),
                  parseFloat(itemRow.vlr_ipi),
                  parseFloat(itemRow.vlr_total),
                ])
              );
              rows.push([]); // Pula uma linha para melhor leitura
            }
          }
        }); // ret.vendas.forEach

        rows.push([]);
        rows.push([]);
        rows.push(["Totais"]);

        rows.push(["Qtd. Vendas", parseFloat(ret.totais?.n_registros)]);
        rows.push(["Total Desconto", parseFloat(ret.totais?.vlr_desconto)]);
        rows.push(["Saldo Flex", parseFloat(ret.totais?.saldo_flex)]);
        rows.push(["Produtos", parseFloat(ret.totais?.vlr_tot_prod)]);
        rows.push(["Serviços", parseFloat(ret.totais?.vlr_tot_serv)]);
        rows.push(["Total Geral", parseFloat(ret.totais?.vlr_total)]);
        if (mostrarFormasRec) {
          rows.push([]);
          rows.push(["Formas de Recebimento"]);
          Object.keys(ret.totais?.formas_rec ?? []).forEach((k) =>
            rows.push([k, parseFloat(ret.totais.formas_rec[k])])
          );
        }

        const ws = XLSX.utils.aoa_to_sheet(rows);
        const wb = { Sheets: { Relatório: ws }, SheetNames: ["Relatório"] };

        const modo = formato === "SIN" ? "Sintético" : "Analítico";
        const inicio = formatDateLocal(dataIni);
        const fim = formatDateLocal(dataFim);
        let nomeArquivo = `Vendas Geral ${modo} - ${inicio} a ${fim}.xlsx`;

        XLSX.writeFile(wb, nomeArquivo);
        return true;
      } catch (error) {
        showError(
          "Erro ao Gerar Exportação do Relatório",
          "ExpRelFront01",
          error.message
        );
        return false;
      }
    } else {
      return false;
    }
  };

  const onConfirm = async () => {
    const func = modo === "IMP" ? imprimir : exportar;
    setLoading(true);
    if (await func()) {
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <BotaoImprimir onClick={toggleImprimir} />
      <FormButton md="auto" color="primary" onClick={toggleExportar}>
        Exportar
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title={
          (modo === "IMP" ? "Imprimir" : "Exportar") +
          " Relatório Geral de Vendas"
        }
        number="5040_1"
        onConfirm={onConfirm}
        loadingConfirm={loading}
        onClosed={limparDados}
      >
        <RadioGroup label="Formato" value={formato} onChange={setFormato}>
          <RadioItem label="Sintético" value="SIN" />
          <RadioItem label="Analítico (Com Produtos)" value="ANA" />
        </RadioGroup>
      </ModalBase>
    </>
  );
};
