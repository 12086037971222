import { PiFactory } from "react-icons/pi";
import { FichaTecnica } from "../../pages/industria/processos/ficha_tecnica/FichaTecnica";
import { IndustriaProcessos } from "../../pages/industria/processos/IndustriaProcessos";
import { ProdutoIntermediario } from "../../pages/industria/processos/produto_intermediario/ProdutoIntermediario";
import { ProgramacaoProducao } from "../../pages/industria/processos/programacao_producao/ProgramacaoProducao";
import { OrdemProducao } from "../../pages/industria/processos/ordem_producao/OrdemProducao";

export const fichaTecnicaRoute = {
  path: "/industria/processos/ficha_tecnica",
  name: "Ficha Técnica",
  id: "AcSkWeb0092",
  nroTela: "0092",
  component: FichaTecnica,
};

export const produtoIntermediarioRoute = {
  path: "/industria/processos/produto_intermediario",
  name: "Produto Intermediário",
  id: "AcSkWeb0093",
  nroTela: "0093",
  component: ProdutoIntermediario,
};

export const programacaoProducaoRoute = {
  path: "/industria/processos/programacao_producao",
  name: "Programação de Produção",
  id: "AcSkWeb0094",
  nroTela: "0094",
  component: ProgramacaoProducao,
};

export const ordemProducaoRoute = {
  path: "/industria/processos/ordem_producao",
  name: "Ordem de Produção",
  id: "AcSkWeb0095",
  nroTela: "0095",
  component: OrdemProducao,
};

export const industriaProcessosRoutes = [
  fichaTecnicaRoute,
  produtoIntermediarioRoute,
  programacaoProducaoRoute,
  ordemProducaoRoute,
];

export const industriaRoutes = {
  path: "/industria",
  name: "Indústria",
  icon: PiFactory,
  children: [
    {
      path: "/industria/processos",
      name: "Processos",
      component: IndustriaProcessos,
      canGoBack: false,
    },
  ],
};
