import { toastr } from "react-redux-toastr";
import { api } from "../../api";

const ENDPOINT = "controladoria/dre/estrutura_plano_contas";

export default {
  buscarDespesas: async (iniPeriodo, fimPeriodo, regime) => {
    const urlParams = new URLSearchParams({
      ini_periodo: iniPeriodo,
      fim_periodo: fimPeriodo,
    }).toString();
    let url;
    if (regime === "CMP") {
      url = `${ENDPOINT}/regime_competencia/despesas/?${urlParams}`;
    } else {
      url = `${ENDPOINT}/regime_caixa/despesas/?${urlParams}`;
    }
    const ret = await api
      .get(url)
      .catch((err) => toastr.error("Erro", err.message));
    if (ret.data.success) {
      return ret.data.res;
    } else {
      toastr.error("Erro", ret.data.msg);
      return [];
    }
  },
  buscarReceitas: async (iniPeriodo, fimPeriodo, regime) => {
    const urlParams = new URLSearchParams({
      ini_periodo: iniPeriodo,
      fim_periodo: fimPeriodo,
    }).toString();
    let url;
    if (regime === "CMP") {
      url = `${ENDPOINT}/regime_competencia/receitas/?${urlParams}`;
    } else {
      url = `${ENDPOINT}/regime_caixa/receitas/?${urlParams}`;
    }
    const ret = await api
      .get(url)
      .catch((err) => toastr.error("Erro", err.message));
    if (ret.data.success) {
      return ret.data.res;
    } else {
      toastr.error("Erro", ret.data.msg);
      return [];
    }
  },
  buscarDadosGrafico: async (iniPeriodo, fimPeriodo, regime) => {
    const urlParams = new URLSearchParams({
      ini_periodo: iniPeriodo,
      fim_periodo: fimPeriodo,
      regime: regime,
    }).toString();
    const url = `controladoria/dre/grafico/?${urlParams}`;
    const ret = await api
      .get(url)
      .catch((err) => toastr.error("Erro", err.message));
    if (ret.data.success) {
      return ret.data.res;
    } else {
      toastr.error("Erro", ret.data.msg);
      return [];
    }
  },
  buscarResultado: async (iniPeriodo, fimPeriodo, regime) => {
    const urlParams = new URLSearchParams({
      ini_periodo: iniPeriodo,
      fim_periodo: fimPeriodo,
      regime: regime,
    }).toString();
    const url = `controladoria/dre/resultado/?${urlParams}`;
    const ret = await api
      .get(url)
      .catch((err) => toastr.error("Erro", err.message));
    if (ret.data.success) {
      return ret.data.res;
    } else {
      toastr.error("Erro", ret.data.msg);
      return {};
    }
  },
  buscarDadosMovimento: async (idMovimento) => {
    const url = `${ENDPOINT}/movs_desc_acre_vinculados_inc/${idMovimento}/`;
    const ret = await api
      .get(url)
      .catch((err) => toastr.error("Erro", err.message));
    if (ret.data.success) {
      return ret.data.res;
    } else {
      toastr.error("Erro", ret.data.msg);
      return [];
    }
  },
};
