import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BsTruck } from "react-icons/bs";
import { AiOutlineLineChart } from "react-icons/ai";
import { BiTransfer, BiUserMinus, BiUserX } from "react-icons/bi";
import { RiCoinsLine, RiInboxArchiveLine } from "react-icons/ri";
import { FaHandHoldingUsd } from "react-icons/fa";
import {
  prontaEntregaClientesInadimplentesRotaRoute,
  prontaEntregaClientesNaoAtendRotaRoute,
  prontaEntregaDemonstrativoMetasRoute,
  prontaEntregaDesempenhoRotaRoute,
  prontaEntregaLancamentosEstoqueRoute,
  prontaEntregaTitulosRecebMaosRoute,
  prontaEntregaTrocaDeProdutosRoute,
  prontaEntregaVendasFormaRecebimentoRoute,
  prontaEntregaVendasPorRotaRoute,
} from "../../../routes/modules/pronta_entrega";

export const ProntaEntregaRel = () => {
  return (
    <PageContainer title="Pronta Entrega - Demonstrativos">
      <MenuGroup>
        <MenuGroupButton
          label="Clientes Não Atendidos"
          icon={BiUserX}
          pathname={prontaEntregaClientesNaoAtendRotaRoute.path}
          nroTela={prontaEntregaClientesNaoAtendRotaRoute.nroTela}
        />
        <MenuGroupButton
          label="Desempenho por Rota"
          icon={AiOutlineLineChart}
          pathname={prontaEntregaDesempenhoRotaRoute.path}
          nroTela={prontaEntregaDesempenhoRotaRoute.nroTela}
        />
        <MenuGroupButton
          label="Inadimplência por Rota"
          icon={BiUserMinus}
          pathname={prontaEntregaClientesInadimplentesRotaRoute.path}
          nroTela={prontaEntregaClientesInadimplentesRotaRoute.nroTela}
        />
        <MenuGroupButton
          label="Lançamentos de Estoque"
          icon={RiInboxArchiveLine}
          pathname={prontaEntregaLancamentosEstoqueRoute.path}
          nroTela={prontaEntregaLancamentosEstoqueRoute.nroTela}
        />
        <MenuGroupButton
          label="Metas"
          icon={AiOutlineLineChart}
          pathname={prontaEntregaDemonstrativoMetasRoute.path}
          nroTela={prontaEntregaDemonstrativoMetasRoute.nroTela}
        />
        <MenuGroupButton
          label="Títulos Recebidos em Mãos"
          icon={FaHandHoldingUsd}
          pathname={prontaEntregaTitulosRecebMaosRoute.path}
          nroTela={prontaEntregaTitulosRecebMaosRoute.nroTela}
        />
        <MenuGroupButton
          label="Troca de Produtos"
          icon={BiTransfer}
          pathname={prontaEntregaTrocaDeProdutosRoute.path}
          nroTela={prontaEntregaTrocaDeProdutosRoute.nroTela}
        />
        <MenuGroupButton
          label="Vendas Agrupadas por Rota"
          icon={BsTruck}
          pathname={prontaEntregaVendasPorRotaRoute.path}
          nroTela={prontaEntregaVendasPorRotaRoute.nroTela}
        />
        <MenuGroupButton
          label="Vendas por Forma de Recebimento"
          icon={RiCoinsLine}
          pathname={prontaEntregaVendasFormaRecebimentoRoute.path}
          nroTela={prontaEntregaVendasFormaRecebimentoRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
