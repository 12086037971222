import React, { useState } from "react";
import { Row } from "reactstrap";
import { apiGet, urlParams } from "../../../../../api";
import { BotaoImprimir, FormButton, Table } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import {
  formatDateISO,
  formatDateLocal,
  formatNumber,
  formatValueFromAPI,
} from "../../../../../coreUtils";
import { docPrintReport } from "../../../../../pdf";

export const DetalhamentoVendasModal = ({
  idColab,
  nomeColab,
  dataIni,
  dataFim,
  tipo,
  percComis,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dados, setDados] = useState([]);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const buscarDados = async () => {
    const params = urlParams({
      tipo_beneficiado: "VEND_EXT",
      id_beneficiado: idColab,
      data_ini: dataIni,
      data_fim: dataFim,
      tipo: tipo,
      perc_comissao: percComis,
    });

    return await apiGet(`/venda/comissao/detalhar_vendas/${params}`);
  };

  const carregarDados = async () => {
    const ret = await buscarDados();

    setDados(ret.vendas);
  };

  const limparDados = () => setDados([]);

  const imprimir = async () => {
    setLoadingImprimir(true);
    const ret = await buscarDados();

    await docPrintReport(
      "/relatorios/comissao/vend_ext/",
      {
        dados: ret.vendas,
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
        vendedor: nomeColab,
        totais: ret.totais,
        tipo: tipo,
      },
      "5032_1"
    );

    setLoadingImprimir(false);
  };

  const toggle = () => setIsOpen(!isOpen);

  const columns = [
    {
      dataField: "id",
      text: "Venda",
      align: "center",
    },
    {
      dataField: "id_ped",
      text: "Pedido",
      align: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      formatter: (c, row) =>
        formatValueFromAPI(c, row.id_cliente, tipo === "LIQ" ? 35 : 60),
    },
    {
      dataField: "data_emi",
      text: "Data/Hora",
      align: "center",
      formatter: (c, row) => `${c} ${row.hora_emi}`,
    },
    {
      dataField: "vlr_tot_prod",
      text: "Vlr. Prod.",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_frete",
      text: "Frete",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_devol",
      text: "Vlr. Devol.",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Vlr. Total",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    ...(tipo === "LIQ"
      ? [
          {
            dataField: "formas_rec",
            text: "Forma Rec.",
            align: "left",
            formatter: (c) =>
              c.map((e) => (
                <>
                  <span>
                    {e.forma_rec} - {formatNumber(e.valor, true, 2)}
                  </span>
                  <br />
                </>
              )),
          },
          {
            dataField: "formas_rec",
            text: "Compensado",
            align: "left",
            formatter: (c) =>
              c.map((e) => (
                <>
                  <span>{e.data_rec}</span>
                  <br />
                </>
              )),
          },
        ]
      : []),
    {
      dataField: "base_calc_comis",
      text: "Base Comis.",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_comissao",
      text: "Vlr. Comis.",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
  ];

  return (
    <>
      <FormButton
        md="auto"
        color="info"
        onClick={toggle}
        disabled={percComis <= 0}
        disabledHint="Informe o Percentual de Comissão"
      >
        Detalhar
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        onBeforeOpen={carregarDados}
        onClosed={limparDados}
        title={
          <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            Detalhamento de Vendas: {formatDateLocal(dataIni)} até{" "}
            {formatDateLocal(dataFim)}
          </h5>
        }
        autoFocus
        hideCancelButton
        size="xl"
      >
        <Row className="mb-2">
          <BotaoImprimir
            padded={false}
            onClick={imprimir}
            loading={loadingImprimir}
          />
        </Row>
        <Table
          columns={columns}
          data={dados ?? []}
          pageSize={15}
          paginated={false}
        />
      </ModalBase>
    </>
  );
};
