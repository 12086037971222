import React, { useState } from "react";
import { FormButton, ModalBase } from "../../../../../components";
import EstornoPagamentoService from "../../../../../services/financeiro/EstornoPagamentoService";

export const ConfEstornarPagamentoModal = ({ selected, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const [ok] = await EstornoPagamentoService.estornarLote(selected);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        onClick={toggle}
        disabled={[0, null, undefined].includes(selected)}
      >
        Estornar
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Estorno de Pagamento"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <h5 style={{ textAlign: "center" }}>
          Tem certeza de que deseja estornar o Lote de Pagamento selecionado?
        </h5>
      </ModalBase>
    </>
  );
};
