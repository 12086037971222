import moment from "moment";
import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const VeiculoGrid = ({ dados, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "data_compra",
      text: "Data de compra",
      align: "left",
      sortable: true,
      formatter: (c) => moment(c).format("DD/MM/YYYY"),
    },
    {
      dataField: "placa",
      text: "Placa",
      align: "left",
      sortable: true,
    },
    {
      dataField: "uf_veiculo",
      text: "UF",
      align: "center",
      sortable: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      sortable: true,
      formatter: (cell) => <TableCheck value={cell} />,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table data={dados} columns={columns} onSelect={setSelected} paginated />
  );
};
