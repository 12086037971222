import React from "react";
import { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

export const HintValue = ({
  enabled = true,
  hint,
  value,
  tooltipPlacement,
}) => {
  const [id] = useState(`hv-${Math.floor(Math.random() * Date.now())}`);

  return !enabled ? (
    value
  ) : (
    <>
      <span id={id}>{value}</span>
      <UncontrolledTooltip target={id} placement={tooltipPlacement}>
        {hint}
      </UncontrolledTooltip>
    </>
  );
};
