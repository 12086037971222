import React, { useState } from "react";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { Row } from "reactstrap";
import {
  FormCheckbox,
  IntegerFormInput,
  TextInput,
} from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { routesBaseCadastroClasseProd } from "../Classe";

export const CadastroClasseModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [descricao, setDescricao] = useState();
  const [diferenciarPesqProd, setDiferenciarPesqProd] = useState(false);
  const [prioridadeImprPesq, setPrioridadeImprPesq] = useState(0);
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setDescricao("");
    setDiferenciarPesqProd(false);
    setPrioridadeImprPesq(0);
    setAtivo(true);
  };

  const buscarDados = (data) => {
    setDescricao(data.descricao);
    setDiferenciarPesqProd(data.diferenciar_pesq_prod);
    setPrioridadeImprPesq(data.prioridade_pesq_impr);
    setAtivo(data.ativo);
  };

  const handleSubmit = () => {
    const payLoad = {
      descricao: descricao,
      diferenciar_pesq_prod: diferenciarPesqProd,
      prioridade_pesq_impr: prioridadeImprPesq,
    };
    if (action === MODAL_ACTIONS.EDIT) {
      payLoad.id_classe = selected;
      payLoad.ativo = ativo;
    }
    return payLoad;
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title="Cadastro De Classe De Produtos"
      size="md"
      onClose={limparDados}
      fetchData={buscarDados}
      submitPayload={handleSubmit}
      selected={selected}
      number="0114_1"
      routesBase={routesBaseCadastroClasseProd}
      notifyEvent={notifyEvent}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
    >
      <Row>
        <TextInput
          label="Descrição"
          value={descricao}
          onChange={(e, v) => {
            setDescricao(v);
          }}
        />
      </Row>
      <Row>
        <IntegerFormInput
          md={4}
          label="Prioridade"
          hint="Prioridade para impressão e pesquisa. Quanto maior o número, maior a prioridade"
          defaultValue={prioridadeImprPesq}
          onChange={setPrioridadeImprPesq}
        />
        <FormCheckbox
          label="Diferenciar na Pesquisa de Produtos"
          checked={diferenciarPesqProd}
          onChange={(e) => {
            setDiferenciarPesqProd(!diferenciarPesqProd);
          }}
        />
      </Row>
    </ModalCadastroV2>
  );
};
