import React from "react";
import * as types from "../constants";
import { createStore } from "redux";

const initialState = {
  menus: [],
  menuAllowedRoutes: [], // Todas as rotas permitidas
  menuCurrentRoutes: [], // Somente as rotas a serem exibidas no menu lateral
};

const reducer = (state = initialState, actions) => {
  switch (actions.type) {
    case types.SET_MENUS:
      return {
        ...state,
        menus: actions.data,
      };
    case types.SET_MENU_CURRENT_ROUTES:
      return {
        ...state,
        menuCurrentRoutes: actions.data,
      };
    case types.SET_MENU_ALLOWED_ROUTES:
      return {
        ...state,
        menuAllowedRoutes: actions.data,
      };
    default:
      return state;
  }
};

export default createStore(reducer);

export const ContextMenu = React.createContext(null);
