import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  itens: [],
};

export const itensOsSlice = createSlice({
  name: "itens_os_slice",
  initialState: initialState,
  reducers: {
    setItens: (state, action) => {
      state.itens = [...action.payload];
    },
  },
});

export const { setItens } = itensOsSlice.actions;

export default itensOsSlice.reducer;
