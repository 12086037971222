import { toastr } from "react-redux-toastr";
import { apiGetV2, apiPostV2 } from "../../apiV2";
import ComboBoxService from "../components/ComboBoxService";
import { routesBaseCidade } from "../../pages/cadastro/clientes/cidade/Cidade";

const URL_BASE = routesBaseCidade;

const CadastroCidadeService = {
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir/`, payload),
  verificarInserirCidade: async (cidade, uf, codIbge) => {
    if (
      ["", null, undefined, 0].includes(cidade) ||
      ["", null, undefined, 0].includes(uf)
    ) {
      return [false, null];
    }

    cidade = cidade?.trim().toUpperCase();
    uf = uf?.trim();
    const ret = await ComboBoxService.fetchOptions("cidade", {
      nome: cidade,
      uf: uf,
    });
    if (ret.length > 0) {
      return [true, ret[0].value];
    } else {
      codIbge = parseInt(codIbge ?? 0);

      if (isNaN(codIbge)) codIbge = 0;

      if (codIbge === 0) {
        toastr.warning(
          "Atenção",
          "Não foi possível cadastrar a cidade " +
            "pois não foi possível identificar o código IBGE."
        );
        return [false, null];
      }

      const payload = {
        ativo: true,
        nome: cidade,
        regiao: "",
        uf: uf,
        cod_ibge: codIbge ?? 0,
        id_pais: 1,
      };
      const [ok, ret] = await CadastroCidadeService.incluir(payload);
      return [ok, ok ? ret.id_cadastrado : null];
    }
  },
};


export default CadastroCidadeService;
