import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { FormCheckbox, PesqPlanoCtaCentroCusto } from "../../../../components";
import {
  setBloqProcessamentoRetornoMovNaoIdentif,
  setIdPlanoContasTarifaBoleto,
  setSolicConfirmDataCompConfRecBanc,
} from "../store/bancario_slice";

export const PersonBancario = () => {
  const store = useSelector((state) => state.bancario);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Solicitar Confirmação da Data de Compensação de Movimentos Bancários (PIX, TED, DOC) na Conferência de Recebimentos em Conta Bancária"
          checked={store.solic_confirm_data_comp_conf_rec_banc}
          onChange={() =>
            dispatch(
              setSolicConfirmDataCompConfRecBanc(
                !store.solic_confirm_data_comp_conf_rec_banc
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear Processamento de Movimentos Não Identificados no Retorno Bancário"
          checked={store.bloq_processamento_retorno_mov_nao_identif}
          onChange={() =>
            dispatch(
              setBloqProcessamentoRetornoMovNaoIdentif(
                !store.bloq_processamento_retorno_mov_nao_identif
              )
            )
          }
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={7}
          label="Plano de Contas para lançamento de Acréscimos Não Identificados no Retorno Bancário tais como Juros e Multa"
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoContasTarifaBoleto(v))}
          value={store.id_plano_contas_tarifa_boleto}
        />
      </Row>
    </>
  );
};
