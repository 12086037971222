import { FileText } from "react-feather";
import { Percurso } from "../../pages/docs_eletron/transporte/manifesto_mdfe/cadastro/percurso/Percurso";
import { Tomador } from "../../pages/docs_eletron/transporte/manifesto_mdfe/cadastro/tomador/Tomador";
import { ManifestoEletronicoMDFe } from "../../pages/docs_eletron/transporte/manifesto_mdfe/ManifestoEletronicoMDFe";
import { NotaFiscalServico } from "../../pages/docs_eletron/notas_fiscais/nota_fiscal_servico/NotaFiscalServico";
import { NotaFiscalEletronica } from "../../pages/docs_eletron/notas_fiscais/nota_fiscal/NotaFiscalEletronica";
import { Condutor } from "../../pages/docs_eletron/transporte/manifesto_mdfe/cadastro/condutor/Condutor";
import { IncluirAlterarNF } from "../../pages/docs_eletron/notas_fiscais/nota_fiscal/incluir_alterar/IncluirAlterarNF";
import { NotaFiscalConsumidor } from "../../pages/docs_eletron/notas_fiscais/nota_fiscal_consumidor/NotaFiscalConsumidor";
import { IncluirAlterarNFServico } from "../../pages/docs_eletron/notas_fiscais/nota_fiscal_servico/incluir_alterar/IncluirAlterarNFServico";
import { NotasFiscais } from "../../pages/docs_eletron/notas_fiscais/NotasFiscais";
import { Transporte } from "../../pages/docs_eletron/transporte/Transporte";
import { IncluirAlterarMDFe } from "../../pages/docs_eletron/transporte/manifesto_mdfe/incluir_alterar/IncluirAlterarMDFe";
import { TbRoad, TbSteeringWheel } from "react-icons/tb";
import { CgUserList } from "react-icons/cg";
import { IncluirAlterarNFCons } from "../../pages/docs_eletron/notas_fiscais/nota_fiscal_consumidor/incluir_alterar/IncluirAlterarNFCons";

export const incluirAlterarMDFeRoute = {
  path: "/docs_eletron/transporte/manifesto_eletronico/mdfe",
  name: "Manifesto Eletrônico - MDFe",
  component: IncluirAlterarMDFe,
};

export const incluirAlterarNFRoute = {
  path: "/docs_eletron/nota_fiscal/nota_fiscal",
  name: "Incluir/Alterar NFe",
  id: "AcSkWeb0055",
  nroTela: "0055",
  component: IncluirAlterarNF,
};

export const incluirAlterarNFServicoRoute = {
  path: "/docs_eletron/nota_fiscal_servico/nota_fiscal",
  name: "",
  id: "AcSkWeb0046",
  nroTela: "0046",
  component: IncluirAlterarNFServico,
};

export const cadastroCondutorMDFeRoute = {
  path: "/docs_eletron/transporte/manifesto_eletronico/condutor",
  name: "Condutor",
  id: "AcSkWeb0042",
  nroTela: "0042",
  component: Condutor,
  concatModelName: "condutor_mdfe",
  shortcutIcon: TbSteeringWheel,
};

export const cadastroTomadorMDFeRoute = {
  path: "/docs_eletron/transporte/manifesto_eletronico/tomador",
  name: "Tomador",
  id: "AcSkWeb0042",
  nroTela: "0042",
  component: Tomador,
  concatModelName: "tomador_mdfe",
  shortcutIcon: CgUserList,
};

export const cadastroPercursoMDFeRoute = {
  path: "/docs_eletron/transporte/manifesto_eletronico/percurso",
  name: "Percurso",
  id: "AcSkWeb0042",
  nroTela: "0042",
  component: Percurso,
  concatModelName: "percurso_mdfe",
  shortcutIcon: TbRoad,
};

export const cadastroMDFeRoutes = [
  cadastroPercursoMDFeRoute,
  cadastroTomadorMDFeRoute,
  cadastroCondutorMDFeRoute,
];

export const notaFiscalServicoRoute = {
  path: "/docs_eletron/notas_fiscais/nota_fiscal_servico",
  name: "Nota Fiscal de Serviço",
  id: "AcSkWeb0046",
  nroTela: "0046",
  component: NotaFiscalServico,
};

export const notaFiscalEletronicaRoute = {
  path: "/docs_eletron/notas_fiscais/nota_fiscal_eletronica",
  name: "Nota Fiscal Eletrônica",
  id: "AcSkWeb0055",
  nroTela: "0055",
  component: NotaFiscalEletronica,
};

export const incluirAlterarNFConsRoute = {
  path: "/docs_eletron/notas_fiscais/nota_fiscal_consumidor/nfc",
  name: "Incluir/Alterar NFCe",
  id: "AcSkWeb0066",
  nroTela: "0066",
  component: IncluirAlterarNFCons,
};

export const notaFiscalConsumidorRoute = {
  path: "/docs_eletron/notas_fiscais/nota_fiscal_consumidor",
  name: "Nota Fiscal Consumidor",
  id: "AcSkWeb0066",
  nroTela: "0066",
  component: NotaFiscalConsumidor,
};

export const docsEletronNotasFiscaisRoutes = [
  notaFiscalEletronicaRoute,
  notaFiscalConsumidorRoute,
  notaFiscalServicoRoute,
];

export const manifestoEletronicoMDFeRoute = {
  path: "/docs_eletron/transporte/manifesto_eletronico",
  name: "Manifesto Eletrônico - MDFe",
  id: "AcSkWeb0042",
  nroTela: "0042",
  component: ManifestoEletronicoMDFe,
};

export const docsEletronTransporteRoutes = [manifestoEletronicoMDFeRoute];

export const docsEletronRoutes = {
  path: "/docs_eletron",
  name: "Documentos Eletrônicos",
  icon: FileText,
  children: [
    {
      path: "/docs_eletron/notas_fiscais",
      name: "Notas Fiscais",
      component: NotasFiscais,
      canGoBack: false,
    },
    {
      path: "/docs_eletron/transporte",
      name: "Transporte",
      component: Transporte,
      canGoBack: false,
    },
  ],
};
