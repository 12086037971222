import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  servidor_smtp: "",
  porta_smtp: "",
  usuario: "",
  senha: "",
  assunto: "",
  exig_conex_seg: false,
  mensagem_pad: "",
  tipo: "",
};

export const emailSlice = createSlice({
  name: "email_slice",
  initialState: initialState,
  reducers: {
    setupEmail: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setServidorSmtp: (state, action) => {
      state.servidor_smtp = action.payload;
    },
    setPortaSmtp: (state, action) => {
      state.porta_smtp = action.payload;
    },
    setUsuario: (state, action) => {
      state.usuario = action.payload;
    },
    setSenha: (state, action) => {
      state.senha = action.payload;
    },
    setAssunto: (state, action) => {
      state.assunto = action.payload;
    },
    setExigConexSeg: (state, action) => {
      state.exig_conex_seg = action.payload;
    },
    setMensagemPad: (state, action) => {
      state.mensagem_pad = action.payload;
    },
    setTipo: (state, action) => {
      state.tipo = action.payload;
    },
  },
});

export const {
  setupEmail,
  setServidorSmtp,
  setPortaSmtp,
  setUsuario,
  setSenha,
  setAssunto,
  setExigConexSeg,
  setMensagemPad,
  setTipo,
} = emailSlice.actions;

export default emailSlice.reducer;
