import React from "react";
import { MoreDropdown, Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";

export const GerencCredRotativoGrid = ({
  dados,
  onSelect,
  alterarLimiteCred,
  incluirCredito,
  alterarCredito,
  abrirDetalhesConta,
}) => {
  const columns = [
    {
      dataField: "id_cliente",
      text: "Cliente",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Nome",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "telefone_cliente",
      text: "Telefone",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_credito",
      text: "Crédito",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "limite_cred",
      text: "Limite de Crédito",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_tot_pend",
      text: "Total em Aberto",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_saldo_disp",
      text: "Saldo Disponível",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => abrirDetalhesConta(row.id_cliente)}>
            Detalhes
          </DropdownItem>
          <DropdownItem onClick={() => alterarLimiteCred(row.id_cliente)}>
            Alterar Limite de Crédito
          </DropdownItem>
          <DropdownItem onClick={() => incluirCredito(row.id_cliente)}>
            Incluir Crédito
          </DropdownItem>
          <DropdownItem onClick={() => alterarCredito(row.id_cliente)}>
            Alterar Crédito
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      keyField="id_cliente"
      columns={columns}
      data={dados}
      onSelect={onSelect}
    />
  );
};
