import React from "react";

const NumberPageModal = ({ children, parametrizado, style = {} }) => {
  return (
    <span
      className="text-muted"
      style={{ marginRight: "auto", ...style }}
      name="modal-number"
    >
      [{children}] {parametrizado && " - P*"}
    </span>
  );
};

export default NumberPageModal;
