import React from "react";
import { formatNumber } from "../../../../../coreUtils";
import { Table } from "../../../../../components";

export const TrocaProdutosGrid = ({ data }) => {
  const columns = [
    {
      dataField: "id_produto",
      text: "#",
      align: "center",
    },
    {
      dataField: "nome_produto",
      text: "Produto",
      align: "left",
    },
    {
      dataField: "qtd_troca",
      text: "Quantidade Troca",
      align: "center",
      formatter: (cell, row) => formatNumber(cell, true, 2, true),
    },
    {
      dataField: "qtd_perc_sobre_total",
      text: "Perc. por Quantidade",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2) + " %",
    },
    {
      dataField: "vlr_troca",
      text: "Valor Troca",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_perc_sobre_total",
      text: "Perc. por Valor",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2) + " %",
    },
  ];

  return <Table data={data} columns={columns} />;
};
