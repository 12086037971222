import React, { useState } from "react";
import {
  BotaoImprimir,
  ComboBox,
  FormCheckbox,
  ModalBase,
} from "../../../../../components";
import { Row } from "reactstrap";

const agrupadores = [
  { label: "Nenhum", value: "N" },
  { label: "Data de Entrada", value: "D" },
  { label: "Fornecedor", value: "F" },
];

export const ImprimirDetEntradasModal = ({ imprimir }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [agrupamento, setAgrupamento] = useState(agrupadores[0].value);
  const [imprimirItens, setImprimirItens] = useState(false);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setAgrupamento(agrupadores[0].value);
    setImprimirItens(false);
  };

  const onConfirm = async () => {
    setLoading(true);
    const res = await imprimir(agrupamento, imprimirItens);
    setLoading(false);
    if (res) toggle();
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <BotaoImprimir onClick={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Imprimir"
        number="0115_2"
        onConfirm={onConfirm}
        loadingConfirm={loading}
        onClosed={limparDados}
      >
        <Row>
          <ComboBox
            md={4}
            label="Agrupar por"
            isClearable={false}
            isSearchable={false}
            options={agrupadores}
            defaultValue={agrupamento}
            onChange={(s) => setAgrupamento(s?.value)}
          />
          <FormCheckbox
            name="imprimir_itens"
            onChange={() => setImprimirItens(!imprimirItens)}
            label="Imprimir itens da NF-e"
          />
        </Row>
      </ModalBase>
    </>
  );
};
