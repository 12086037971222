import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  ComboBox,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";
import { formatDateISO } from "../../../../coreUtils";

const periodoOptions = [
  { label: "Vencimento", value: "VCTO" },
  { label: "Emissão", value: "EMI" },
];

const carteiraOptions = [
  { label: "Todos", value: "T" },
  { label: "Somente Carteira", value: "C" },
  { label: "Somente Não Carteira", value: "N" },
];

const ordenarOptions = [
  { label: "Data de Vencimento", value: "VCTO" },
  { label: "Cliente + Data de Vencimento", value: "CLI_VCTO" },
  { label: "Valor", value: "VLR_REC" },
  { label: "Cliente + Valor", value: "CLI_VLR_REC" },
  { label: "Data de Emissão", value: "EMI" },
  { label: "Cliente", value: "CLI" },
  { label: "Tipo de Cliente", value: "TIPO_CLI" },
];

export const ContasReceberPendentes = () => {
  const [idCliente, setIdCliente] = useState(null);
  const [idTipoCli, setIdTipoCli] = useState(null);
  const [idReprInterno, setIdReprInterno] = useState(null);
  const [idReprExterno, setIdReprExterno] = useState(null);
  const [idRede, setIdRede] = useState(null);
  const [idCidade, setIdCidade] = useState(null);
  const [idOrganizacao, setIdOrganizacao] = useState(null);
  const [carteira, setCarteira] = useState(carteiraOptions[0].value);
  const [periodo, setPeriodo] = useState(periodoOptions[0].value);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [ordenar, setOrdenar] = useState(ordenarOptions[0].value);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = () => {
    const params = {
      id_cliente: idCliente,
      id_tipo_cli: idTipoCli,
      id_repr_interno: idReprInterno,
      id_repr_externo: idReprExterno,
      id_rede: idRede,
      id_cidade: idCidade,
      id_organizacao: idOrganizacao,
      carteira: carteira,
      periodo: periodo,
      data_ini: dataIni,
      data_fim: dataFim,
      ordenar: ordenar,
    };
    return apiGetV2(
      "/relatorios/contas_receber/contas_receber_pendentes/",
      params
    );
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
        dados: ret,
        ordenar: ordenar,
      };
      await docPrintReport(
        "/relatorios/financeiro_receber/contas_receber_pendentes/",
        payload,
        "5060"
      );
    }
    setLoadingImprimir(false);
  };

  return (
    <PageContainer
      title="Relatório de Contas a Receber Pendentes"
      number="5060"
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Organização"
            concatModelName="organizacao"
            defaultValue={idOrganizacao}
            onChange={(s) => setIdOrganizacao(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={5}
            label="Cliente"
            concatModelName="cliente"
            defaultValue={idCliente}
            onChange={(s) => setIdCliente(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={3}
            label="Tipo de Cliente"
            concatModelName="tipo_cliente"
            defaultValue={idTipoCli}
            onChange={(s) => setIdTipoCli(s?.value)}
            hideShortcut
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={3}
            label="Representante Interno"
            concatModelName="colaborador"
            defaultValue={idReprInterno}
            onChange={(s) => setIdReprInterno(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={3}
            label="Representante Externo"
            concatModelName="colaborador"
            defaultValue={idReprExterno}
            onChange={(s) => setIdReprExterno(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={2}
            label="Rede"
            concatModelName="rede_cliente"
            defaultValue={idRede}
            onChange={(s) => setIdRede(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={4}
            label="Cidade"
            concatModelName="cidade"
            defaultValue={idCidade}
            onChange={(s) => setIdCidade(s?.value)}
            hideShortcut
          />
        </Row>
        <Row>
          <ComboBox
            options={carteiraOptions}
            md={2}
            label="Mostrar"
            onChange={(s) => setCarteira(s?.value)}
            defaultValue={carteira}
          />
          <ComboBox
            options={periodoOptions}
            md={2}
            label="Período"
            onChange={(s) => setPeriodo(s?.value)}
            defaultValue={periodo}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <ComboBox
            options={ordenarOptions}
            md={2}
            label="Ordenar Por"
            onChange={(s) => setOrdenar(s?.value)}
            defaultValue={ordenar}
          />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
    </PageContainer>
  );
};
