import React from "react";
import { Table } from "../../../../../components";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../../coreUtils";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    headerAlign: "left",
    sortable: true,
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
    headerAlign: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "nome_vendedor",
    text: "Vendedor",
    align: "left",
    headerAlign: "left",
    sortable: true,
    formatter: (c, row) => formatValueFromAPI(c, row.id_colab_vendedor),
  },

  {
    dataField: "vlr_desc",
    text: "Desconto",
    align: "right",
    headerAlign: "right",
    sortable: true,
    sortFunc: naturalSort,
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_tot_prod",
    text: "Produtos",
    align: "right",
    headerAlign: "right",
    sortable: true,
    sortFunc: naturalSort,
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_frete",
    text: "Frete",
    align: "right",
    headerAlign: "right",
    sortable: true,
    sortFunc: naturalSort,
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    headerAlign: "right",
    sortable: true,
    sortFunc: naturalSort,
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const TransferenciaVendaGrid = ({ dados, onSelect }) => {
  return (
    <Table
      data={dados}
      columns={columns}
      onSelect={onSelect}
      paginated={false}
      pageSize={8}
      clearSelectionOnUpdate
    />
  );
};
