import React, { useEffect } from "react";
import { useState } from "react";
import { Card, Row, Label } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormCheckbox,
  IconButton,
  PageContainer,
} from "../../../../components";
import { apiGet, urlParams } from "../../../../api";
import { GerencCaixaLojaGrid } from "./components/GerencCaixaLojaGrid";
import { DetalhesCaixaLojaGrid } from "./components/DetalhesCaixaLojaGrid";
import { AiOutlineCheckSquare } from "react-icons/ai";

const mostrarOptions = [
  { label: "Vendas", value: "VFX" },
  { label: "Pedidos Internos", value: "FPE" },
  { label: "Ordens de Serviço", value: "VOS" },
  { label: "Faturas de Créd. Rotativo", value: "FRO" },
  { label: "Adiantamentos", value: "AOS" },
  { label: "Devoluções", value: "DEV" },
];

const moedasRecOptions = [
  { label: "Dinheiro", value: "DI" },
  { label: "Cartões Créd./Déb.", value: "CC" },
  { label: "Cheques", value: "CQ" },
  { label: "Crediário/Boletos", value: "CI" },
  { label: "Crédito Rotativo", value: "CR" },
  { label: "Crédito de Cliente", value: "CL" },
  { label: "Transf. Bancárias", value: "TB" },
];

const outrasOperOptions = [
  { label: "Sangrias", value: "SAN" },
  { label: "Retiradas", value: "RET" },
  { label: "Reforços", value: "REF" },
];

const MostrarDetalhesOptions = ({ label, options, selected, onChange }) => {
  const onSelectOption = (v) => {
    if (!selected.includes(v)) {
      onChange([...selected, v]);
    } else {
      onChange(selected.filter((e) => e !== v));
    }
  };

  const onSelectGroup = () => {
    const opts = options.map((e) => e.value);
    if (!opts.every((e) => selected.includes(e))) {
      const sel = [...selected, ...opts.filter((v) => !selected.includes(v))];
      onChange(sel);
    } else {
      const sel = selected.filter((v) => !opts.includes(v));
      onChange(sel);
    }
  };

  return (
    <Row>
      <Label md={2} style={{ textAlign: "right", paddingRight: 0 }}>
        {label}
      </Label>
      {options.map((e) => (
        <FormCheckbox
          padded={false}
          label={e.label}
          checked={selected.includes(e.value)}
          onChange={() => onSelectOption(e.value)}
        />
      ))}
      <IconButton
        icon={AiOutlineCheckSquare}
        onClick={onSelectGroup}
        hint="Marcar/Desmarcar Tudo"
        className="mt-2"
      />
    </Row>
  );
};

export const GerencCaixaLoja = () => {
  const [idColaborador, setIdColaborador] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [detalhes, setDetalhes] = useState([]);
  const [mostrar, setMostrar] = useState([
    ...mostrarOptions.map((e) => e.value),
    ...outrasOperOptions.map((e) => e.value),
  ]);
  const [mostrarMoedasRec, setMostrarMoedasRec] = useState(
    moedasRecOptions.map((e) => e.value)
  );

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      id_colaborador: idColaborador,
      data_ini: dataIni,
      data_fim: dataFim,
    };
    setLoading(true);
    const ret = await apiGet(
      `/caixa_loja/gerenciamento/listar_caixas/${urlParams(params)}`
    );
    setDados(ret ?? []);
    setLoading(false);
  };

  const carregarDetalhes = async (idCaixa) => {
    const paramsMostrar = mostrar.map((e) => `mostrar=${e}`);
    const paramsMoedasRec = mostrarMoedasRec.map((e) => `moedas_rec=${e}`);
    const ret = await apiGet(
      `/caixa_loja/gerenciamento/listar_movimentos/${idCaixa}/?${[
        ...paramsMostrar,
        ...paramsMoedasRec,
      ].join(`&`)}`
    );
    setDetalhes(ret ?? []);
  };

  const handleSelect = (v) => {
    setSelected(v);
  };

  useEffect(() => {
    if (![0, null, undefined].includes(selected)) {
      carregarDetalhes(selected);
    }
  }, [mostrar, mostrarMoedasRec, selected]);

  return (
    <PageContainer title="Gerenciamento de Caixas Loja" number="0070" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            label="Operador"
            onChange={(s) => setIdColaborador(s?.value)}
            isSearchable
            isClearable
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
      </Card>
      <Card body>
        <GerencCaixaLojaGrid dados={dados} onSelect={handleSelect} />
      </Card>
      <Card body>
        <MostrarDetalhesOptions
          label="Mostrar"
          options={mostrarOptions}
          selected={mostrar}
          onChange={setMostrar}
        />
        <MostrarDetalhesOptions
          label="Moedas de Recebimento"
          options={moedasRecOptions}
          selected={mostrarMoedasRec}
          onChange={setMostrarMoedasRec}
        />
        <MostrarDetalhesOptions
          label="Outras Operações"
          options={outrasOperOptions}
          selected={mostrar}
          onChange={setMostrar}
        />
      </Card>
      <Card body>
        <DetalhesCaixaLojaGrid detalhes={detalhes} />
      </Card>
    </PageContainer>
  );
};
