import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, CustomInput, FormGroup, Label, Row, TabPane } from "reactstrap";
import {
  setCondicao,
  setIdade,
  setLocalInstalacaoModulos,
  setMaterialEstrutura,
  setNivelamentoSolo,
} from "../store/local_instalacao_modulos_slice";

export const TabLocalInstalacao = () => {
  const localInstalacao = useSelector((state) => state.localInstalacaoModulos);
  const dispatch = useDispatch();

  return (
    <TabPane tabId="4" className="p-2">
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) =>
              dispatch(setLocalInstalacaoModulos(target?.value))
            }
          >
            <Label>Local de Instalação dos Módulos</Label>
            <div>
              <CustomInput
                type="radio"
                name="local_instalacao_modulos"
                id="solo_instalacao_modulos"
                label="Solo"
                value="SOLO"
                inline
                checked={localInstalacao.localInstalacaoModulos === "SOLO"}
              />
              <CustomInput
                type="radio"
                name="local_instalacao_modulos"
                id="telhado_instalacao_modulos"
                label="Telhado"
                value="TELH"
                inline
                checked={localInstalacao.localInstalacaoModulos === "TELH"}
              />
              <CustomInput
                type="radio"
                name="local_instalacao_modulos"
                id="laje_instalacao_modulos"
                label="Laje"
                value="LAJE"
                inline
                checked={localInstalacao.localInstalacaoModulos === "LAJE"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => dispatch(setIdade(target?.value))}
          >
            <Label>Idade do Telhado</Label>
            <div>
              <CustomInput
                type="radio"
                name="idade_telhado"
                id="nao_def_idade_telhado"
                label="Não Definido"
                value="NAO DEFINIDO"
                inline
                checked={localInstalacao.idade === "NAO DEFINIDO"}
              />
              <CustomInput
                type="radio"
                name="idade_telhado"
                id="ate5_idade_telhado"
                label="Até 5 anos"
                value="MENOS_5"
                inline
                checked={localInstalacao.idade === "MENOS_5"}
              />
              <CustomInput
                type="radio"
                name="idade_telhado"
                id="5_a_10_idade_telhado"
                label="5 a 10 anos"
                value="5_10"
                inline
                checked={localInstalacao.idade === "5_10"}
              />
              <CustomInput
                type="radio"
                name="idade_telhado"
                id="10_a_20_idade_telhado"
                label="10 a 20 anos"
                value="10_20"
                inline
                checked={localInstalacao.idade === "10_20"}
              />
              <CustomInput
                type="radio"
                name="idade_telhado"
                id="mais_20_idade_telhado"
                label="Mais de 20 anos"
                value="MAIS_20"
                inline
                checked={localInstalacao.idade === "MAIS_20"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => dispatch(setCondicao(target?.value))}
          >
            <Label>Condições do Telhado</Label>
            <div>
              <CustomInput
                type="radio"
                name="condicoes_telhado"
                id="boa_condicoes_telhado"
                label="Boa"
                value="BOA"
                inline
                checked={localInstalacao.condicao === "BOA"}
              />
              <CustomInput
                type="radio"
                name="condicoes_telhado"
                id="ruim_condicoes_telhado"
                label="Ruim"
                value="RUIM"
                inline
                checked={localInstalacao.condicao === "RUIM"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) =>
              dispatch(setMaterialEstrutura(target?.value))
            }
          >
            <Label>Material de Estrutura do Telhado</Label>
            <div>
              <CustomInput
                type="radio"
                name="material_estrutura_telhado"
                id="madeira_material_telhado"
                label="Madeira"
                value="MADEI"
                inline
                checked={localInstalacao.materialEstrutura === "MADEI"}
              />
              <CustomInput
                type="radio"
                name="material_estrutura_telhado"
                id="metalico_material_telhado"
                label="Estrutura Metálica"
                value="METAL"
                inline
                checked={localInstalacao.materialEstrutura === "METAL"}
              />
              <CustomInput
                type="radio"
                name="material_estrutura_telhado"
                id="concreto_material_telhado"
                label="Perfil de Concreto"
                value="CONCR"
                inline
                checked={localInstalacao.materialEstrutura === "CONCR"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) =>
              dispatch(setNivelamentoSolo(target?.value))
            }
          >
            <Label>Nivelamento do Solo</Label>
            <div>
              <CustomInput
                type="radio"
                name="nivelamento_solo"
                id="nao_def_nivelamento_solo"
                label="Não Definido"
                value="NAO"
                inline
                checked={localInstalacao.nivelamentoSolo === "NAO"}
              />
              <CustomInput
                type="radio"
                name="nivelamento_solo"
                id="plano_nivelamento_solo"
                label="Plano"
                value="PLANO"
                inline
                checked={localInstalacao.nivelamentoSolo === "PLANO"}
              />
              <CustomInput
                type="radio"
                name="nivelamento_solo"
                id="inclinado_nivelamento_solo"
                label="Inclinado"
                value="INCLINADO"
                inline
                checked={localInstalacao.nivelamentoSolo === "INCLINADO"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </TabPane>
  );
};
