import React, { useState } from "react";
import { Row } from "reactstrap";
import { ModalBase } from "../../../../../../components/ModalBase";
import NotaFiscalService from "../../../../../../services/docs_eletron/NotaFiscalService";

export const ExcluirItemNfModal = ({
  isOpen,
  toggle,
  idItem,
  nomeItem,
  notifyEvent,
}) => {
  const [loading, setLoading] = useState(false);
  const onConfirm = async () => {
    setLoading(true);
    const [ok] = await NotaFiscalService.inclusao.excluirItem(idItem);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Exclusão de Item do Pedido"
      number="0034_42"
      autoFocus
      confirmButtonText="Excluir"
      onConfirm={onConfirm}
      loadingConfirm={loading}
    >
      <Row className="mx-0">
        <p style={{ textAlign: "center" }}>
          Tem certeza de que deseja excluir o produto {nomeItem}?
        </p>
      </Row>
    </ModalBase>
  );
};
