import React from "react";
import { ArrowLeft } from "react-feather";
import { useHistory } from "react-router-dom";

const ScreenTitle = ({ children, pop = false, onBeforeGoingBack }) => {
  const history = useHistory();

  return (
    <h5
      className="h5 mb-0 mr-2"
      style={{ fontWeight: "bold", fontSize: "0.95rem" }}
      name="screen-title"
    >
      {pop && (
        <>
          <ArrowLeft
            size={15}
            style={{ marginBottom: "3.55px", cursor: "pointer" }}
            onClick={() => {
              onBeforeGoingBack && onBeforeGoingBack();
              history.goBack();
            }}
          />{" "}
        </>
      )}
      {children}
    </h5>
  );
};

export default ScreenTitle;
