import React from "react";
import { Card } from "reactstrap";
import { Table } from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { DetComissaoFixaModal } from "./DetComissaoFixaModal";

export const ComissaoFixaGrid = ({ data, dataIni, dataFim }) => {
  const columns = [
    {
      dataField: "id_cab__id_colab_vendedor",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_colab",
      text: "Vendedor",
      align: "left",
      sortable: true,
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "det",
      align: "center",
      formatter: (c, row, index) => (
        <DetComissaoFixaModal
          index={index}
          data={row}
          dataIni={dataIni}
          dataFim={dataFim}
        />
      ),
    },
  ];

  return (
    <>
      <Card body>
        <Table
          columns={columns}
          data={data}
          keyField="id_cab__id_colab_vendedor"
        />
      </Card>
    </>
  );
};
