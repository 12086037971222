import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  NumberInput,
  PageContainer,
  TextInput,
} from "../../../../../components";
import { DatePicker } from "../../../../../components/DatePicker";
import { Divider } from "../../../../../components/Divider";
import { FixedField } from "../../../../../components/FixedField";
import {
  MODAL_ACTIONS,
  formatDateISO,
  roundFloat,
} from "../../../../../coreUtils";
import NotaFiscalServicoService from "../../../../../services/docs_eletron/NotaFiscalServicoService";
import { toastr } from "react-redux-toastr";
import { useRef } from "react";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import moment from "moment";
import { notaFiscalServicoRoute } from "../../../../../routes/modules/docsEletron";
import { apiGetV2 } from "../../../../../apiV2";

export const IncluirAlterarNFServico = ({ location }) => {
  const selected = location?.state?.selected;
  const action = location?.state?.action ?? MODAL_ACTIONS.ADD;

  // Controle
  const history = useHistory();
  const tomadorRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // Parâmetros
  const [localPrestaServPadrao, setLocalPrestaServPadrao] = useState("");

  // NF
  const [natOps, setNatOps] = useState([]);
  const [idTipoDoc, setIdTipoDoc] = useState(null);
  const [dataEmi, setDataEmi] = useState(new Date());
  const [numero, setNumero] = useState(0);
  const [numeroRps, setNumeroRps] = useState(0);
  const [semTomador, setSemTomador] = useState(false);
  const [idNaturOperacao, setIdNaturOperacao] = useState(null);
  const [infoAdic, setInfoAdic] = useState("");
  const [idCidadeLocalPrestaServ, setIdCidadeLocalPrestaServ] = useState(null);
  const [codigoObra, setCodigoObra] = useState(null);
  const [codigoArtObra, setCodigoArtObra] = useState(null);

  // Cliente
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [cpfCnpjCliente, setCpfCnpjCliente] = useState("");
  const [enderCliente, setEnderCliente] = useState("");
  const [bairroCliente, setBairroCliente] = useState("");
  const [cepCliente, setCepCliente] = useState("");
  const [cidadeCliente, setCidadeCliente] = useState("");

  // Intermediário
  const [idClienteIntermed, setIdClienteIntermed] = useState(null);
  const [cpfCnpjIntermed, setCpfCnpjIntermed] = useState("");

  // Serviço
  const [idServico, setIdServico] = useState(null);
  const [descServico, setDescServico] = useState("");
  const [codCnae, setCodCnae] = useState("");
  const [unidade, setUnidade] = useState("");
  const [codSped, setCodSped] = useState("");
  const [vlrUnit, setVlrUnit] = useState(0);
  const [vlrItem, setVlrItem] = useState(0);
  const [quantidade, setQuantidade] = useState(0);
  const [vlrDesc, setVlrDesc] = useState(0);
  const [percDescServico, setPercDescServico] = useState(0);
  const [vlrTotServ, setVlrTotServ] = useState(0);
  const [vlrTotal, setVlrTotal] = useState(0);

  // Impostos
  const [sitTribIssqn, setSitTribIssqn] = useState("");
  const [baseCalcIssqn, setBaseCalcIssqn] = useState(0);
  const [vlrIssqn, setVlrIssqn] = useState(0);
  const [aliqIssqn, setAliqIssqn] = useState(0);
  const [baseCalcPis, setBaseCalcPis] = useState(0);
  const [vlrPis, setVlrPis] = useState(0);
  const [aliqPis, setAliqPis] = useState(0);
  const [baseCalcCofins, setBaseCalcCofins] = useState(0);
  const [vlrCofins, setVlrCofins] = useState(0);
  const [aliqCofins, setAliqCofins] = useState(0);
  const [aliqInss, setAliqInss] = useState(0);
  const [baseCalcInss, setBaseCalcInss] = useState(0);
  const [vlrTotInss, setVlrTotInss] = useState(0);

  const carregarNaturezasOperacao = async () => {
    const [ok, ret] = await NotaFiscalServicoService.naturezaOperacao.listar();

    setNatOps(ok ? ret.map((e) => ({ label: e.descricao, value: e.id })) : []);
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/inc_alt_nfse/", {});

    if (!ok) return false;

    if (!ret.cidade_homologada) {
      toastr.warning(
        "Atenção",
        "A cidade da empresa não está homologada. Por favor, entre em contato com a Skillsoft para maiores informações."
      );
      return false;
    }
    setLocalPrestaServPadrao(ret.local_presta_serv_padrao);
    if (ret.id_tipo_doc_padrao) {
      setIdTipoDoc(ret.id_tipo_doc_padrao);
    }
    if (ret.id_natureza_oper_padrao) {
      setIdNaturOperacao(ret.id_natureza_oper_padrao);
    }
    return true;
  };

  const iniciarTela = async () => {
    setLoading(true);
    if (!(await carregarParametros())) {
      history.goBack();
    }
    await carregarNaturezasOperacao();

    if (action === MODAL_ACTIONS.EDIT) {
      await carregarDados();
    }
    setLoading(false);
  };

  const carregarDados = async () => {
    const [ok, ret] = await NotaFiscalServicoService.buscar(selected);

    if (ok) {
      setNumero(ret.numero);
      setNumeroRps(ret.numero_rps);
      setIdTipoDoc(ret.id_tipo_doc);
      setIdCliente(ret.id_cliente);
      setNomeCliente(ret.nome_cli);
      if (ret.id_cliente === 0) {
        setSemTomador(true);
      }
      setIdClienteIntermed(ret.id_cliente_intermed);
      setInfoAdic(ret.info_adicional);
      setCpfCnpjCliente(ret.cpf_cnpj_cli);
      setIdNaturOperacao(ret.natur_operacao);
      setDataEmi(moment(ret.data_emissao, "DD/MM/YYYY", true).toDate());
      setCodigoObra(ret.codigo_obra);
      setCodigoArtObra(ret.codigo_art_obra);

      const serv = ret.servico;

      if (serv) {
        setIdServico(serv.id_servico);
        setDescServico(serv.descricao);
        setCodCnae(serv.cod_cnae);
        setCodSped(serv.cod_sped);
        setUnidade(serv.unidade);
        setQuantidade(parseFloat(serv.quantidade));
        setVlrUnit(parseFloat(serv.vlr_unit));
        setVlrItem(parseFloat(serv.vlr_item));
        setPercDescServico(parseFloat(serv.perc_desc));
        setVlrDesc(parseFloat(serv.vlr_desc));
        setVlrTotServ(parseFloat(serv.vlr_tot_serv));
        setSitTribIssqn(serv.sit_trib_issqn);
        setBaseCalcIssqn(parseFloat(serv.base_calc_issqn));
        setVlrIssqn(parseFloat(serv.vlr_issqn));
        setAliqIssqn(parseFloat(serv.aliq_issqn));
        setBaseCalcPis(parseFloat(serv.base_calc_pis));
        setVlrPis(parseFloat(serv.vlr_pis));
        setAliqPis(parseFloat(serv.aliq_pis));
        setBaseCalcCofins(parseFloat(serv.base_calc_cofins));
        setVlrCofins(parseFloat(serv.vlr_cofins));
        setAliqCofins(parseFloat(serv.aliq_cofins));
        setAliqInss(parseFloat(serv.aliq_inss));
        setBaseCalcInss(parseFloat(serv.base_calc_inss));
        setVlrTotInss(parseFloat(serv.vlr_inss));
        setVlrTotal(parseFloat(serv.vlr_total));
      }
    }
  };

  const handleSetTomador = async (v) => {
    if (v) {
      const [ok, ret] = await NotaFiscalServicoService.buscarDadosCliNfse(v);

      if (ok) {
        const enderCli =
          ret.endereco +
          ", " +
          ret.numero +
          (!["", null, undefined].includes(ret.complemento)
            ? ", " + ret.complemento
            : "");

        setNomeCliente(ret.nome);
        setCpfCnpjCliente(ret.cpf_cnpj);
        setEnderCliente(enderCli);
        setBairroCliente(ret.bairro);
        setCepCliente(ret.cep);
        setCidadeCliente(ret.cidade__nome + "-" + ret.cidade__uf);
      } else {
        return;
      }
    } else {
      setNomeCliente("");
      setCpfCnpjCliente("");
      setEnderCliente("");
      setBairroCliente("");
      setCepCliente("");
      setCidadeCliente("");
    }

    setIdCliente(v);
  };

  const handleSetSemTomador = () => {
    if (!semTomador) {
      handleSetTomador(null);
      tomadorRef.current.clearValue();
    }

    setSemTomador(!semTomador);
  };

  const calcularImpostos = async (vlrTotalServico, naturezaOperacao) => {
    const [ok, trib] = await NotaFiscalServicoService.calcularImpostos(
      vlrTotalServico,
      naturezaOperacao
    );
    if (ok) {
      setSitTribIssqn(trib.sit_trib_iss);
      setAliqIssqn(parseFloat(trib.aliq_iss));
      setBaseCalcIssqn(parseFloat(trib.base_calc_iss));
      setVlrIssqn(parseFloat(trib.vlr_iss));
      setAliqPis(parseFloat(trib.aliq_pis));
      setBaseCalcPis(parseFloat(trib.base_calc_pis));
      setVlrPis(parseFloat(trib.vlr_pis));
      setAliqCofins(parseFloat(trib.aliq_cofins));
      setBaseCalcCofins(parseFloat(trib.base_calc_cofins));
      setVlrCofins(parseFloat(trib.vlr_cofins));
      setAliqInss(parseFloat(trib.aliq_inss));
      setBaseCalcInss(parseFloat(trib.base_calc_inss));
      setVlrTotInss(parseFloat(trib.vlr_inss));
      setVlrTotal(parseFloat(trib.vlr_total));
      return true;
    } else {
      return false;
    }
  };

  const handleSetServico = (s) => {
    if (s?.value === idServico) return;
    setIdServico(s?.value);
    setUnidade(s?.unidade);
    if ((descServico || "") === "") {
      setDescServico(s?.nome);
    }
    setQuantidade(1);
    setVlrUnit(s?.valor ?? 0);
    setVlrItem(s?.valor ?? 0);
    setCodCnae(s?.cod_cnae ?? "");
    setCodSped(s?.cod_sped ?? "");
    setPercDescServico(0);
    setVlrDesc(0);
    setVlrTotServ(s?.valor);
    calcularImpostos(s?.valor ?? 0, idNaturOperacao);
  };

  const handleSetVlrItem = (v) => {
    setVlrItem(v);

    const vDescUnit = v < vlrUnit ? vlrUnit - v : 0;
    const vTotDesc = vDescUnit * quantidade;
    const vTotServ = v * quantidade;
    const pDesc = v < vlrUnit ? 100 * ((vlrUnit - v) / vlrUnit) : 0;
    setPercDescServico(roundFloat(pDesc, 2));
    setVlrDesc(roundFloat(vTotDesc, 2));
    setVlrTotServ(roundFloat(vTotServ, 2));

    calcularImpostos(vTotServ, idNaturOperacao);
  };

  const handleSetQuantidade = (v) => {
    setQuantidade(v);

    const vDescUnit = vlrItem < vlrUnit ? vlrUnit - vlrItem : 0;
    const vTotDesc = vDescUnit * v;
    const vTotServ = vlrItem * v;

    setVlrDesc(roundFloat(vTotDesc, 2));
    setVlrTotServ(roundFloat(vTotServ, 2));
    calcularImpostos(vTotServ, idNaturOperacao);
  };

  const handleSetVlrTotServ = (v) => {
    setVlrTotServ(v);

    const vItem = quantidade > 0 ? v / quantidade : 0;
    const vDescUnit = vItem < vlrUnit ? vlrUnit - vItem : 0;
    const vTotDesc = vDescUnit * quantidade;
    setVlrItem(roundFloat(vItem, 2));
    setVlrDesc(roundFloat(vTotDesc, 2));

    calcularImpostos(v, idNaturOperacao);
  };

  const handleSetIntermediario = async (v) => {
    if (v) {
      const [ok, ret] = await NotaFiscalServicoService.buscarDadosCliNfse(v);
      if (ok) {
        setCpfCnpjIntermed(ret.cpf_cnpj);
      } else {
        return;
      }
    } else {
      setCpfCnpjIntermed("");
    }
    setIdClienteIntermed(v);
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idTipoDoc)) {
      toastr.warning("Atenção", "Por favor, informe o Tipo de Documento");
      return false;
    }

    if ([0, null, undefined].includes(idNaturOperacao)) {
      toastr.warning("Atenção", "Por favor, informe a Natureza da Operação");
      return false;
    }

    if (!semTomador) {
      if ([0, null, undefined].includes(idCliente)) {
        toastr.warning("Atenção", "Por favor, informe o Tomador");
        return false;
      }
    }

    if ([0, null, undefined].includes(idServico)) {
      toastr.warning("Atenção", "Por favor, informe o Serviço");
      return false;
    }

    const data = {
      dt_emissao: formatDateISO(dataEmi),
      id_tipo_doc: idTipoDoc,
      id_cliente: idCliente ?? 0,
      nome_cliente: nomeCliente,
      cpf_cnpj_cliente: cpfCnpjCliente,
      nfse_sem_tomador: semTomador,
      id_intermediario: idClienteIntermed ?? 0,
      vlr_desc: vlrDesc,
      base_calc_issqn: baseCalcIssqn,
      vlr_tot_issqn: vlrIssqn,
      base_calc_pis: baseCalcPis,
      vlr_tot_pis: vlrPis,
      base_calc_cofins: baseCalcCofins,
      vlr_tot_cofins: vlrCofins,
      vlr_tot_serv: vlrTotServ,
      vlr_total_nf: vlrTotal,
      info_adic: infoAdic,
      id_cidade_local_presta_serv:
        localPrestaServPadrao === "SSL" ? idCidadeLocalPrestaServ : null,
      codigo_obra: codigoObra,
      codigo_art_obra: codigoArtObra,
      id_natur_operacao: idNaturOperacao,
      servicos: [
        {
          item: 1,
          id_servico: idServico ?? 0,
          descricao: descServico ?? "",
          cod_cnae: codCnae,
          cod_sped: codSped,
          unidade: unidade,
          quantidade: quantidade,
          vlr_unit: vlrUnit,
          vlr_item: vlrItem,
          sit_trib_issqn: sitTribIssqn,
          aliq_issqn: aliqIssqn,
          base_calc_issqn: baseCalcIssqn,
          vlr_issqn: vlrIssqn,
          aliq_pis: aliqPis,
          base_calc_pis: baseCalcPis,
          vlr_pis: vlrPis,
          aliq_cofins: aliqCofins,
          base_calc_cofins: baseCalcCofins,
          vlr_cofins: vlrCofins,
          aliq_inss: aliqInss,
          base_calc_inss: baseCalcInss,
          vlr_tot_inss: vlrTotInss,
          vlr_tot_servico: vlrTotServ,
          vlr_total: vlrTotal,
        },
      ],
    };

    setLoadingSubmit(true);

    const submitFunc =
      action === MODAL_ACTIONS.ADD
        ? NotaFiscalServicoService.incluir
        : NotaFiscalServicoService.alterar;

    const payload =
      action === MODAL_ACTIONS.ADD
        ? { ...data, origem: "MAN", id_origem: 0 }
        : { id_nfse: selected, ...data };

    const [ok] = await submitFunc(payload);

    if (ok) {
      setRedirect(true);
    }

    setLoadingSubmit(false);
  };

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9") handleSubmit();
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: notaFiscalServicoRoute.path,
          state: { refresh: true },
        }}
      />
    );
  }

  return (
    <PageContainer
      title="Nota Fiscal de Serviço"
      number="0046_1"
      loading={loading}
      canGoBack
      onKeyDown={onKeyDown}
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={6}
            isConcatField
            concatModelName="tipo_documento"
            defaultOptions
            label="Tipo de Documento"
            isSearchable
            onChange={(s) => setIdTipoDoc(s?.value)}
            defaultValue={idTipoDoc}
          />
          <DatePicker
            label="Data"
            md={2}
            value={dataEmi}
            onChange={(v) => setDataEmi(moment.isMoment(v) ? v.toDate() : v)}
          />
          {action === MODAL_ACTIONS.EDIT && (
            <>
              <IntegerFormInput
                md={2}
                label="Número NFS-e"
                defaultValue={numero}
                disabled
              />
              <IntegerFormInput
                md={1}
                label="Número RPS"
                defaultValue={numeroRps}
                disabled
              />
            </>
          )}
        </Row>
        <Row>
          <ComboBox
            md={7}
            label="Natureza"
            options={natOps}
            onChange={(s) => setIdNaturOperacao(s?.value)}
            defaultValue={idNaturOperacao}
          />
          {localPrestaServPadrao === "SSL" && (
            <AsyncComboBox
              md={5}
              className="mb-3"
              isConcatField
              concatModelName="cidade"
              label="Local de Prestação de Serviço"
              isSearchable
              isClearable
              onChange={(s) => setIdCidadeLocalPrestaServ(s?.value)}
              defaultValue={idCidadeLocalPrestaServ}
              defaultOptions={action === MODAL_ACTIONS.EDIT}
            />
          )}
        </Row>
      </Card>
      <Card body>
        <Divider className="mt-0">Informações do Tomador</Divider>
        <Row className="mb-2">
          <FormCheckbox
            mb="auto"
            padded={false}
            label="Sem Tomador"
            checked={semTomador}
            onChange={() => handleSetSemTomador()}
            name="sem_tomador"
          />
        </Row>
        {!semTomador && (
          <>
            <Row>
              <AsyncComboBox
                md={6}
                className="mb-3"
                isConcatField
                concatModelName="cliente"
                label="Tomador"
                isSearchable
                isClearable
                onChange={(s) => handleSetTomador(s?.value)}
                defaultValue={idCliente}
                disabled={semTomador}
                ref={tomadorRef}
              />
              <FixedField label="CPF/CNPJ" value={cpfCnpjCliente} />
            </Row>
            <Row>
              <FixedField label="Endereço" value={enderCliente} />
              <FixedField label="Bairro" value={bairroCliente} />
              <FixedField label="CEP" value={cepCliente} />
              <FixedField label="Cidade" value={cidadeCliente} />
            </Row>
          </>
        )}
      </Card>
      <Card body>
        <Divider className="mt-0">Informações do Serviço</Divider>
        <Row>
          <AsyncComboBox
            md={6}
            isConcatField
            concatModelName="servico"
            defaultOptions
            label="Serviço Realizado"
            isSearchable
            onChange={handleSetServico}
            defaultValue={idServico}
            isClearable
            disabled={[0, null, undefined].includes(idNaturOperacao)}
          />
          <FixedField label="Unidade" value={unidade} />
          <FixedField label="CNAE" value={codCnae} />
          <FixedField label="SPED" value={codSped} />
        </Row>
        <Row>
          <TextInput
            type="textarea"
            label="Discriminação"
            value={descServico}
            onChange={(e, v) => setDescServico(v)}
          />
        </Row>
        <Row>
          <NumberInput
            md={2}
            label="Valor do Serviço"
            onChange={handleSetVlrItem}
            value={vlrItem}
            disabled={[0, null, undefined].includes(idServico)}
          />
          <NumberInput
            md={2}
            label="Quantidade"
            onChange={handleSetQuantidade}
            value={quantidade}
            disabled={[0, null, undefined].includes(idServico)}
          />
          <NumberInput md={2} label="Vlr. Desc." value={vlrDesc} disabled />
          <NumberInput
            md={2}
            label="Perc. Desc."
            value={percDescServico}
            isPercentage
            disabled
          />
          <NumberInput
            md={2}
            label="Total Serviços"
            onChange={handleSetVlrTotServ}
            value={vlrTotServ}
            disabled={[0, null, undefined].includes(idServico)}
          />
          <NumberInput md={2} label="Valor Total" value={vlrTotal} disabled />
        </Row>
        <Row>
          <NumberInput
            md={2}
            label="Base Cálc. ISSQN"
            value={baseCalcIssqn}
            disabled
          />
          <NumberInput md={2} label="Valor ISSQN" value={vlrIssqn} disabled />
          <NumberInput md={2} label="Alíq ISSQN" value={aliqIssqn} disabled />
          <NumberInput
            md={2}
            label="Base Cálc. PIS"
            value={baseCalcPis}
            disabled
          />
          <NumberInput md={2} label="Valor PIS" value={vlrPis} disabled />
          <NumberInput md={2} label="Alíq PIS" value={aliqPis} disabled />
        </Row>
        <Row>
          <NumberInput
            md={2}
            label="Base Cálc. COFINS"
            value={baseCalcCofins}
            disabled
          />
          <NumberInput md={2} label="Valor COFINS" value={vlrCofins} disabled />
          <NumberInput md={2} label="Alíq COFINS" value={aliqCofins} disabled />
          {/* <NumberInput md={2} label="ISS Retido" value={vlrISSRet} disabled /> */}
          <NumberInput
            md={2}
            label="Base Cálc. INSS"
            value={baseCalcInss}
            disabled
          />
          <NumberInput md={2} label="Alíq INSS" value={aliqInss} disabled />
          <NumberInput md={2} label="Valor INSS" value={vlrTotInss} disabled />
        </Row>
      </Card>
      <Card body>
        <Divider className="mt-0">Informações do Intermediário</Divider>
        <Row>
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="cliente"
            label="Intermediário"
            isSearchable
            isClearable
            onChange={(s) => handleSetIntermediario(s?.value)}
            defaultValue={idClienteIntermed}
            defaultOptions={action === MODAL_ACTIONS.EDIT}
          />
          <TextInput
            md={2}
            maxLength={15}
            label="Código Obra"
            value={codigoObra}
            onChange={(e, v) => setCodigoObra(v)}
          />
          <TextInput
            md={2}
            maxLength={15}
            label="Código ART"
            value={codigoArtObra}
            onChange={(e, v) => setCodigoArtObra(v)}
          />
          {![0, null, undefined].includes(idClienteIntermed) && (
            <FixedField label="CPF/CNPJ" value={cpfCnpjIntermed} />
          )}
          <FormButton
            divClassName="ml-auto"
            md="auto"
            color="success"
            onClick={handleSubmit}
            loading={loadingSubmit}
          >
            Confirmar
          </FormButton>
          <FormButton md="auto" color="danger" onClick={() => history.goBack()}>
            Esc - Sair
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
