import React, { useState } from "react";
import {
  Divider,
  FixedField,
  FormButton,
  ModalBase,
  Table,
} from "../../../../../components";
import { Row } from "reactstrap";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import GerencContasReceberService from "../../../../../services/financeiro/GerencContasReceberService";
import { LogsTituloReceberModal } from "./LogsTituloReceberModal";

const columnsLancamentos = [
  { dataField: "id", text: "Seq", align: "center" },
  {
    dataField: "transacao",
    text: "Cod",
    align: "center",
  },
  {
    dataField: "desc_transac",
    text: "Transação",
    align: "left",
  },
  {
    dataField: "histor",
    text: "Histórico",
    align: "left",
  },
  {
    dataField: "usuario",
    text: "Usuário",
    align: "center",
  },
  {
    dataField: "data_hora_lanc",
    text: "Data/Hora",
    align: "center",
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columnsPlanosContas = [
  { dataField: "desc_operacao", text: "Tipo", align: "left" },
  { dataField: "id", text: "Seq", align: "center" },
  { dataField: "id_plano_conta", text: "Plano", align: "center" },
  {
    dataField: "nome_plano_conta",
    text: "Descrição Plano de Conta",
    align: "left",
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
  },
  {
    dataField: "pagamento",
    text: "Recebimento",
    align: "center",
  },
  { dataField: "id_lote", text: "Lote", align: "center" },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columnsFormasRec = [
  { dataField: "desc_recurso", text: "Recebido com", align: "left" },
  { dataField: "numero_recurso", text: "Número", align: "center" },
  { dataField: "obs_recurso", text: "Observação", align: "left" },
  { dataField: "vencimento", text: "Vencimento", align: "center" },
  {
    dataField: "vlr_receb",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const headerRowStyle = {
  justifyContent: "space-between",
  marginBottom: "0.5rem",
  marginRight: "-15px",
};

export const DetalhesTituloReceberModal = ({
  isOpen,
  toggle,
  selected,
  utilizaOrganizacao,
}) => {
  const [dadosTitulo, setDadosTitulo] = useState({});
  const [logsOpen, setLogsOpen] = useState(false);
  const [idSelected, setIdSelected] = useState(null);

  const limparDados = () => setDadosTitulo({});

  const carregarDados = async () => {
    const [ok, ret] = await GerencContasReceberService.buscar(selected);
    if (ok) {
      setDadosTitulo(ret);
    } else {
      toggle();
    }
  };

  const toggleLogs = () => setLogsOpen(!logsOpen);
  const mostrarLogs = (id) => {
    setIdSelected(id);
    setTimeout(() => {
      toggleLogs();
    }, 1);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Detalhes do Título a Receber"
      number="0120_2"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      footerActions={
        <FormButton padded={false} onClick={mostrarLogs}>
          Logs
        </FormButton>
      }
    >
      <Row style={headerRowStyle}>
        {utilizaOrganizacao && (
          <FixedField
            label="Organização"
            value={
              dadosTitulo?.id_organizacao
                ? formatValueFromAPI(
                    dadosTitulo?.nome_organizacao,
                    dadosTitulo?.id_organizacao
                  )
                : "Não Informada"
            }
            horizontal
          />
        )}
        <FixedField label="Sequência" value={selected} horizontal />
        <FixedField
          label="Carteira"
          value={dadosTitulo?.carteira ? "Sim" : "Não"}
          horizontal
        />
        <FixedField
          label="Status"
          value={dadosTitulo?.recebido ? "Liquidado" : "Em Aberto"}
          textStyle={{ color: dadosTitulo?.recebido ? "green" : "red" }}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField
          label="Cliente"
          value={formatValueFromAPI(
            dadosTitulo?.nome_cliente,
            dadosTitulo?.id_cliente
          )}
          horizontal
        />
        <FixedField
          label="Número/Parcela"
          value={`${dadosTitulo?.numero}-${dadosTitulo?.parcela}`}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField
          label="Origem"
          value={dadosTitulo?.desc_origem}
          horizontal
        />
        <FixedField
          label="Nro. Origem"
          value={dadosTitulo?.nro_origem}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField label="Emissão" value={dadosTitulo?.emissao} horizontal />
        <FixedField
          label="Vencimento"
          value={dadosTitulo?.vencimento}
          horizontal
        />
        {dadosTitulo?.recebido && (
          <FixedField
            label="Recebimento"
            value={dadosTitulo?.recebimento}
            horizontal
          />
        )}
      </Row>
      <Row style={headerRowStyle}>
        <FixedField
          label="Juros (+)"
          value={formatNumber(dadosTitulo?.vlr_juro, true, 2)}
          horizontal
        />
        <FixedField
          label="Multa (+)"
          value={formatNumber(dadosTitulo?.vlr_multa, true, 2)}
          horizontal
        />
        <FixedField
          label="Desconto (-)"
          value={formatNumber(dadosTitulo?.vlr_desc, true, 2)}
          horizontal
        />
        <FixedField
          label="Acréscimos (+)"
          value={formatNumber(dadosTitulo?.vlr_acre, true, 2)}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField
          label="Valor Original"
          value={formatNumber(dadosTitulo?.vlr_orig, true, 2)}
          horizontal
        />
        <FixedField
          label="Valor a Receber"
          value={formatNumber(dadosTitulo?.vlr_receber, true, 2)}
          horizontal
        />
        <FixedField
          label="Valor Recebido"
          value={formatNumber(dadosTitulo?.vlr_recebido, true, 2)}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField label="Observação" value={dadosTitulo?.observ} horizontal />
      </Row>

      <Divider>Lançamentos Realizados</Divider>
      <Table
        data={dadosTitulo?.lancamentos ?? []}
        columns={columnsLancamentos}
        showRegisterCount={false}
        pageSize={5}
        paginated={false}
      />
      <Divider>Informações de Plano de Contas e Centro de Custo</Divider>
      <Table
        data={dadosTitulo?.planos_contas ?? []}
        columns={columnsPlanosContas}
        showRegisterCount={false}
        pageSize={5}
        paginated={false}
      />
      <Divider>Formas de Recebimento</Divider>
      <Table
        data={dadosTitulo?.recebimentos ?? []}
        columns={columnsFormasRec}
        showRegisterCount={false}
        pageSize={4}
        paginated={false}
      />
      <LogsTituloReceberModal
        isOpen={logsOpen}
        toggle={toggleLogs}
        selected={selected}
      />
    </ModalBase>
  );
};
