import React from "react";
import { DataTable } from "../../../../components/DataTable";
import { TableCheck } from "../../../../components/TableCheck";
import { tiposCampo } from "./CadastroCamposPersonModal";

export const CamposPersonTable = ({ data, handleOnSelect }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "tabela",
      text: "Tabela",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "nome_campo",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "tipo_campo",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
      formatter: (cell) =>
        tiposCampo.find((e) => e.value === cell)?.label ?? cell,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => <TableCheck value={cell} />,
    },
  ];

  return <DataTable onSelect={handleOnSelect} data={data} columns={columns} />;
};
