import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BiCustomize } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import { MdOutlineLocationOn } from "react-icons/md";
import {
  cadastroClienteRoute,
  cadastroCidadeRoute,
  cadastroRelatorioCamposPersonRoute,
  cadastroRamoAtividadeRoute,
  cadastroTransportadoraRoute,
  cadastroTipoClienteRoute,
} from "../../../routes/modules/cadastro";
import { LiaStoreAltSolid, LiaTruckMovingSolid } from "react-icons/lia";
import { RiFileUserLine } from "react-icons/ri";

export const Clientes = () => {
  return (
    <PageContainer title="Cadastros - Clientes">
      <MenuGroup>
        <MenuGroupButton
          label="Cliente"
          icon={BsPerson}
          pathname={cadastroClienteRoute.path}
          nroTela={cadastroClienteRoute.nroTela}
        />
        <MenuGroupButton
          label="Cidade"
          icon={MdOutlineLocationOn}
          pathname={cadastroCidadeRoute.path}
          nroTela={cadastroCidadeRoute.nroTela}
        />
        <MenuGroupButton
          label="Campos Personalizados"
          icon={BiCustomize}
          pathname={cadastroRelatorioCamposPersonRoute.path}
          nroTela={cadastroRelatorioCamposPersonRoute.nroTela}
        />
        <MenuGroupButton
          label="Ramo de Atividade"
          icon={LiaStoreAltSolid}
          pathname={cadastroRamoAtividadeRoute.path}
          nroTela={cadastroRamoAtividadeRoute.nroTela}
        />
      </MenuGroup>
      <MenuGroup>
        <MenuGroupButton
          label="Transportadora"
          icon={LiaTruckMovingSolid}
          pathname={cadastroTransportadoraRoute.path}
          nroTela={cadastroTransportadoraRoute.nroTela}
        />
        <MenuGroupButton
          label="Tipo de Cliente"
          icon={RiFileUserLine}
          pathname={cadastroTipoClienteRoute.path}
          nroTela={cadastroTipoClienteRoute.nroTela}
        /> 
      </MenuGroup>
    </PageContainer>
  );
};
