import moment from "moment";
import React from "react";
import { useState } from "react";
import { Card, ListGroup, Row } from "reactstrap";
import { apiGet } from "../../../../api";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotaisItem,
  FiltroPeriodoDatas,
  TimeSeriesLineChart,
} from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { ProgressBar } from "../../../../components/ProgressBar";
import { naturalSort } from "../../../../coreUtils";
import { DesempenhoRotaGrid } from "./components/DesempenhoRotaGrid";

const URL_BASE = `/relatorios/venda_direta/desempenho_de_rotas`;

export const DesempenhoRota = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [rotas, setRotas] = useState([]);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [dadosGrafico, setDadosGrafico] = useState([]);
  const [seriesGrafico, setSeriesGrafico] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);

  moment.locale("pt-BR");

  const limparDados = () => {
    setDados([]);
    setTotais({});
    setDadosGrafico([]);
    setSeriesGrafico([]);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
    limparDados();
  };

  const buscarDados = async () => {
    const formatNomeRota = (nome) => {
      const nomeSplit = nome.split(" ");

      if (isNaN(parseInt(nomeSplit[nomeSplit.length - 1]))) {
        nomeSplit[nomeSplit.length - 1] = nomeSplit[
          nomeSplit.length - 1
        ].padStart(2, 0);
      }
      return nomeSplit.join(" ");
    };

    setLoading(true);
    setLoadingProgress(0);
    setLoadingLabel("Buscando Dados");

    let searchParams =
      new URLSearchParams({
        data_ini: dataIni.toISOString().split("T")[0],
        data_fim: dataFim.toISOString().split("T")[0],
      }).toString() + rotas.map((e) => `&id_rota=${e.value}`).join("");

    // Grid
    const dadosGrid = await apiGet(`${URL_BASE}/listar/?${searchParams}`);
    setDados(dadosGrid.dados);
    setTotais(dadosGrid.totais);

    // Gráfico
    setLoadingProgress(50);
    setLoadingLabel("Gerando Gráfico");
    const retGrafico = await apiGet(`${URL_BASE}/grafico/?${searchParams}`);

    setSeriesGrafico(
      retGrafico.names.map((e) => formatNomeRota(e)).sort(naturalSort)
    );

    setDadosGrafico(
      retGrafico.data.map((e) => {
        e = e[0];

        let ret = {
          Data: moment(e.data).format("DD/MM/YYYY (ddd)"),
        };
        for (let key in e) {
          if (key !== "data") ret[formatNomeRota(key)] = parseFloat(e[key]);
        }

        return ret;
      })
    );
    setLoadingProgress(100);
    setLoading(false);
    setLoadingLabel("");
  };
  return (
    <PageContainer title="Desempenho por Rota" number="5033" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            isClearable
            isConcatField
            isSearchable
            concatModelName="rota_mob"
            defaultOptions
            md={5}
            label="Rota(s)"
            isMulti
            onChange={(selected) => {
              setRotas(selected ?? []);
              limparDados();
            }}
          />
          <BotaoPesquisar
            onClick={buscarDados}
            loading={loading}
            disabled={!(dataIni instanceof Date) || !(dataFim instanceof Date)}
            disabledHint="Informe datas válidas de início e fim"
          />
          <ProgressBar
            label={loadingLabel}
            value={loadingProgress}
            md={3}
            visible={loading}
          />
        </Row>
      </Card>
      {dados.length > 0 && dadosGrafico.length > 0 && (
        <>
          <TimeSeriesLineChart
            data={dadosGrafico}
            title="Lucro por Rota no Período"
            dataKey={"Data"}
            lineDataKeys={seriesGrafico}
            isRank={true}
            height={600}
          />
          <Card body>
            <ListGroup horizontal>
              <CardTotaisItem label="Despesas" value={totais.despesas} />
              <CardTotaisItem label="Troca" value={totais.troca} />
              <CardTotaisItem label="Custo Prod" value={totais.custo_venda} />
              <CardTotaisItem label="Venda" value={totais.venda} />
            </ListGroup>
            <ListGroup horizontal>
              <CardTotaisItem label="Comissão" value={totais.comissao} />
              <CardTotaisItem label="Bonificação" value={totais.bonificacao} />
              <CardTotaisItem label="Custo Total" value={totais.custo_total} />
              <CardTotaisItem label="Lucro" value={totais.lucro} redGreen />
            </ListGroup>
          </Card>
          <DesempenhoRotaGrid
            data={dados}
            dataIni={dataIni}
            dataFim={dataFim}
          />
        </>
      )}
    </PageContainer>
  );
};
