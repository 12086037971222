import React from "react";
import { Table } from "../../../../../components";
import {
  formatNumber,
  naturalSort,
  naturalSortDate,
} from "../../../../../coreUtils";

const columns = [
  {
    dataField: "id",
    text: "Lote",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "pagamento",
    text: "Pagamento",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "qtd_dpl",
    text: "Qtd. Títulos Pagos",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "valor",
    text: "Valor Pago",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const EstornoPagamentoGrid = ({ dados, onSelect, selected }) => {
  return (
    <Table
      data={dados}
      columns={columns}
      onSelect={onSelect}
      paginated={false}
      pageSize={8}
      selected={selected}
    />
  );
};
