import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Select from "react-select";
import { Col, FormGroup, Label } from "reactstrap";
import { handleFocus } from "../coreUtils";
import { HintLabel } from "./HintLabel";

const ComboBox = forwardRef(
  (
    {
      md = 4,
      mdInput = 12,
      label,
      name,
      onChange,
      options = [],
      isSearchable,
      isClearable,
      isMulti,
      defaultValue,
      isDisabled,
      hint,
      clearOnDisable,
      autoFocus,
      tabOrder,
      noOptionsMessage,
      id,
      placeholder = "Selecione...",
      additionalButton,
      onBlur,
      divClassName,
      menuPlacement,
      openMenuOnClick,
      openMenuOnFocus,
      components,
      divStyle = {},
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const [internalId] = useState(id ?? Math.floor(Math.random() * Date.now()));
    const selectRef = useRef();

    const clearValue = () => {
      selectRef.current.select.clearValue();
    };

    const setFocus = () => {
      selectRef.current.select.focus();
    };

    const setValue = (value) => {
      selectRef.current.select.selectOption(value);
    };

    useImperativeHandle(ref, () => ({
      clearValue: () => clearValue(),
      setFocus: () => setFocus(),
      setValue: (v) => setValue(v),
    }));

    useEffect(() => {
      if (options.length > 0) {
        if (defaultValue) {
          const opt = options.find((v) => v.value === defaultValue);
          if (opt) {
            setValue(opt);
          } else {
            clearValue();
          }
        } else {
          clearValue();
        }
      }
    }, [defaultValue, options]);

    useEffect(() => {
      if (isDisabled && clearOnDisable) {
        clearValue();
      }
    }, [isDisabled, clearOnDisable]);

    const handleChange = (v, e) => {
      if (!isMulti) {
        if ((v?.value ?? null) !== selectedValue) {
          setSelectedValue(v?.value ?? null);
          if (onChange) onChange(v, e);
        }
      } else {
        setSelectedValue(v);
        if (onChange) {
          onChange(v, e);
        }
      }
    };

    const labelFor = `react-select-${internalId}-input`;

    return (
      <Col
        md={md}
        onBlur={onBlur}
        className={divClassName}
        style={{ ...divStyle }}
      >
        <FormGroup onKeyDown={handleFocus}>
          {hint ? (
            <HintLabel label={label} hintText={hint} labelFor={labelFor} />
          ) : (
            <Label for={labelFor}>{label}</Label>
          )}
          <Col md={mdInput} className="no-gutters">
            <div style={{ display: "flex", width: "100%" }}>
              <Select
                key={JSON.stringify(defaultValue)}
                className="react-select-container"
                classNamePrefix="react-select"
                name={name}
                onChange={handleChange}
                options={options}
                defaultValue={defaultValue}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isMulti={isMulti}
                isDisabled={isDisabled}
                ref={selectRef}
                placeholder={placeholder}
                autoFocus={autoFocus}
                tabOrder={tabOrder}
                instanceId={internalId}
                noOptionsMessage={noOptionsMessage}
                menuPlacement={menuPlacement}
                openMenuOnClick={openMenuOnClick}
                openMenuOnFocus={openMenuOnFocus}
                components={components}
              />
              {additionalButton && additionalButton}
            </div>
          </Col>
        </FormGroup>
      </Col>
    );
  }
);

export default ComboBox;
