import { apiGetV2 } from "../../../apiV2";

const URL_BASE = "/relatorios/comissao/por_vendedor";

const ComissaoFixaService = {
  listar: async (vendedores, dataIni, dataFim) => {
    const params = {
      id_vendedor: vendedores,
      data_ini: dataIni,
      data_fim: dataFim,
    };

    return await apiGetV2(`${URL_BASE}/listar/`, params);
  },
  buscarDetalhamentoComiss: async (idVendedor, dataIni, dataFim) => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
    };

    return await apiGetV2(`${URL_BASE}/detalhamento/${idVendedor}/`, params);
  },
};

export default ComissaoFixaService;
