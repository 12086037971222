import React from "react";
import { Row } from "reactstrap";
import {
  IntegerFormInput,
  MaskedInput,
  TextInput,
} from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  setTaf,
  setRegistroEstadual,
  setUltNroCteOs,
  setCaminSalvarXml,
} from "../store/cte_os_slice";

export const PersonCteOs = () => {
  const store = useSelector((state) => state.cte_os);
  const dispatch = useDispatch();
  return (
    <>
      <Row>
        <IntegerFormInput
          md={3}
          label="Último Número de CT-e OS Emitido"
          mdInput={7}
          defaultValue={store.ult_nro_cte_os}
          onChange={(v) => dispatch(setUltNroCteOs(v ?? 0))}
        />
        <IntegerFormInput
          md={3}
          label="Último Número de Lote"
          mdInput={7}
          defaultValue={store.ult_lote}
          disabled
        />
      </Row>
      <Row>
        <MaskedInput
          md={3}
          label="TAF"
          mask="999999999999"
          maskChar={null}
          value={store.taf}
          onChange={(e, v) => dispatch(setTaf(v))}
        />
      </Row>
      <Row>
        <MaskedInput
          md={3}
          label="Registro Estadual"
          mask="9999999999999999999999999"
          maskChar={null}
          value={store.registro_estadual}
          onChange={(e, v) => dispatch(setRegistroEstadual(v))}
        />
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Salvar XML Em"
          value={store.camin_salvar_xml}
          onChange={(e, v) => dispatch(setCaminSalvarXml(v))}
          forceUppercase={false}
          maxLength={255}
        />
      </Row>
    </>
  );
};
