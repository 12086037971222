import React from "react";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Cell,
} from "recharts";
import { Card, CardBody, CardHeader, CardTitle, Row, Button } from "reactstrap";
import { chartColors, formatNumber } from "../coreUtils";
import { OrderedTooltip } from "./index";
import XLSX from "xlsx";

const RADIAN = Math.PI / 180;

const PercentageLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  fillOpacity,
}) => {
  let radius = innerRadius + (outerRadius - innerRadius) + 5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill={chartColors[index]}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      opacity={fillOpacity || 1}
    >{`${(percent * 100).toFixed(2)}%`}</text>
  );
};

class CustomPieChart extends React.Component {
  state = { opacity: {} };
  componentWillReceiveProps() {
    let opacity = {};
    const { nameKey, data } = this.props;
    data.forEach((entry) => {
      if (!Object.keys(opacity).includes(entry[nameKey])) {
        opacity[entry[nameKey]] = 1;
      }
    });

    this.setState({ opacity: opacity });
  }

  handleMouseEnter = ({ value }) => {
    const { opacity } = this.state;
    Object.keys(opacity).forEach((element) => {
      if (element !== value) opacity[element] = 0.2;
    });
    this.setState({ opacity: opacity });
  };

  handleMouseLeave = () => {
    const { opacity } = this.state;
    Object.keys(opacity).forEach((element) => {
      opacity[element] = 1;
    });

    this.setState({ opacity: opacity });
  };

  handleExport = () => {
    const { data, title } = this.props;
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, title);

    XLSX.writeFile(wb, `${title}.xlsx`);
  };

  render() {
    const { data, title, nameKey, dataKey, percentage, total } = this.props;
    const { opacity } = this.state;
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{title}</CardTitle>
          {total !== undefined && (
            <h6 className="card-subtitle text-muted">
              Total: {formatNumber(total)}
            </h6>
          )}
        </CardHeader>
        <CardBody>
          <Row>
            <ResponsiveContainer width={"100%"} height={400}>
              <PieChart>
                <Tooltip content={<OrderedTooltip />} />
                <Legend
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                />
                <Pie
                  data={data}
                  nameKey={nameKey}
                  dataKey={dataKey}
                  label={percentage ? <PercentageLabel /> : true}
                  labelLine={false}
                  isAnimationActive={false}
                >
                  {data.map((entry, index) => {
                    const { name } = entry;
                    return (
                      <Cell
                        key={`cell-${index}`}
                        fillOpacity={opacity[name]}
                        fill={chartColors[index]}
                      />
                    );
                  })}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Row>
          <div className="w-100 clearfix">
            <Button
              className="float-right"
              color="link"
              onClick={this.handleExport}
            >
              Exportar Dados
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default CustomPieChart;
