import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  marca_modelo: "URANO",
  controle_fluxo: "NENHUM",
  paridade: "NENHUMA",
  bits_parada: "s1",
  bits_dados: 5,
  velocidade_porta: 110,
  porta: "COM1",
  modulo_balanca_ativado: false,
  tem_buffet_livre: true,
};

export const balancaSlice = createSlice({
  name: "balanca_slice",
  initialState: initialState,
  reducers: {
    setupBalanca: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setMarcaModelo: (state, action) => {
      state.marca_modelo = action.payload;
    },
    setControleFluxo: (state, action) => {
      state.controle_fluxo = action.payload;
    },
    setParidade: (state, action) => {
      state.paridade = action.payload;
    },
    setBitsParada: (state, action) => {
      state.bits_parada = action.payload;
    },
    setBitsDados: (state, action) => {
      state.bits_dados = action.payload;
    },
    setVelocidadePorta: (state, action) => {
      state.velocidade_porta = action.payload;
    },
    setPorta: (state, action) => {
      state.porta = action.payload;
    },
    setModuloBalancaAtivado: (state, action) => {
      state.modulo_balanca_ativado = action.payload;
    },
    setTemBuffetLivre: (state, action) => {
      state.tem_buffet_livre = action.payload;
    },
  },
});

export const {
  setupBalanca,
  setMarcaModelo,
  setControleFluxo,
  setParidade,
  setBitsParada,
  setBitsDados,
  setVelocidadePorta,
  setPorta,
  setModuloBalancaAtivado,
  setTemBuffetLivre,
} = balancaSlice.actions;

export default balancaSlice.reducer;
