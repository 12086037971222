import React from "react";
import { Card } from "reactstrap";
import { formatNumber, naturalSort } from "../../../../../../coreUtils";
import { IconButton, Table } from "../../../../../../components";
import { colunaModalDetalhes } from "../../../../../../components/ModalDetalhes";
import { BsPrinter } from "react-icons/bs";
import { docPrintReport } from "../../../../../../pdf";

const imprimirEstoqueRota = async (row) => {
  const payload = {
    nome_rota: row.nome,
    custo_carga: row.custo_carga,
    venda_carga: row.venda_carga,
    quantidade: row.quantidade,
    estoque: row.estoque,
  };

  await docPrintReport(
    "/relatorios/pronta_entrega/estoque_rota/",
    payload,
    "0085_1"
  );
};

const columnsProduto = [
  {
    dataField: "id_produto",
    text: "#",
    sortable: true,
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "id_produto__nome",
    text: "Produto",
    sortable: true,
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "quantidade",
    text: "Quantidade",
    sortable: true,
    align: "center",
    headerAlign: "center",
    formatter: (v) => formatNumber(v, true, 2, true),
    sortFunc: naturalSort,
  },
  {
    dataField: "custo_carga",
    text: "Custo da Carga",
    sortable: true,
    align: "right",
    headerAlign: "right",
    formatter: (v) => formatNumber(v, true, 2),
    sortFunc: naturalSort,
  },
  {
    dataField: "venda_carga",
    text: "Valor de Venda da Carga",
    sortable: true,
    align: "right",
    headerAlign: "right",
    formatter: (v) => formatNumber(v, true, 2),
    sortFunc: naturalSort,
  },
];

const columnsRota = [
  {
    dataField: "id",
    text: "#",
    sortable: false,
    align: "center",
    headerAlign: "center",
    headerStyle: { width: "3%" },
  },
  {
    dataField: "nome",
    text: "Rota",
    sortable: true,
    align: "left",
    headerAlign: "left",
    sortFunc: naturalSort,
  },
  {
    dataField: "id_colaborador__nome",
    text: "Vendedor",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "quantidade",
    text: "Quantidade de Produtos",
    sortable: true,
    align: "center",
    headerAlign: "center",
    formatter: (v) => formatNumber(v, true, 2, true),
    sortFunc: naturalSort,
  },
  {
    dataField: "custo_carga",
    text: "Custo da Carga",
    sortable: true,
    align: "right",
    headerAlign: "right",
    formatter: (v) => formatNumber(v, true, 2),
    sortFunc: naturalSort,
  },
  {
    dataField: "venda_carga",
    text: "Valor de Venda da Carga",
    sortable: true,
    align: "right",
    headerAlign: "right",
    formatter: (v) => formatNumber(v, true, 2),
    sortFunc: naturalSort,
  },
  colunaModalDetalhes("nome", "estoque", columnsProduto),
  {
    dataField: "print",
    text: "",
    align: "center",
    formatter: (c, row) => (
      <IconButton icon={BsPrinter} onClick={() => imprimirEstoqueRota(row)} />
    ),
  },
];

export const TableRotaProdutos = ({ data }) => {
  return (
    <Card body>
      <Table data={data} columns={columnsRota} paginated={false} />
    </Card>
  );
};
