import React, { useState } from "react";
import {
  FormCheckbox,
  LabelButton,
  ModalBase,
} from "../../../../../components";
import { columnsContasReceber } from "./GerencContasReceberGrid";
import { exportDataToSheet } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { periodoContasReceberOptions } from "../GerencContasReceber";

export const ExportarContasReceberModal = ({
  buscarDados,
  utilizaOrganizacao,
  dataIni,
  dataFim,
  periodo,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fone1, setFone1] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setFone1(false);
    setWhatsapp(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const exportar = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const columns = columnsContasReceber(
        utilizaOrganizacao,
        true,
        fone1,
        whatsapp
      );

      const per = periodoContasReceberOptions.find(
        (e) => e.value === periodo
      )?.label;
      const titulo = `Títulos a Receber - ${per}`;

      exportDataToSheet(ret.titulos, titulo, {
        columns: columns,
        dataIni: dataIni,
        dataFim: dataFim,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <LabelButton divClassName="ml-auto" onClick={toggle}>
        Exportar
      </LabelButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Exportar Listagem de Títulos a Receber"
        number="0120_8"
        onConfirm={exportar}
        loadingConfirm={loading}
        onClosed={limparDados}
      >
        <Row>
          <FormCheckbox
            padded={false}
            label="Incluir Fone 1"
            checked={fone1}
            onChange={() => setFone1(!fone1)}
          />
          <FormCheckbox
            padded={false}
            label="Incluir WhatsApp"
            checked={whatsapp}
            onChange={() => setWhatsapp(!whatsapp)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
