import React, { useEffect, useRef, useState } from "react";
import {
  AsyncComboBox,
  ComboBox,
  FixedField,
  FormButton,
  MaskedInput,
  PageContainer,
  TextInput,
} from "../../../../../components";
import { useHistory } from "react-router-dom";
import CadastroLigacaoVeicCliService from "../../../../../services/cadastro/CadastroLigacaoVeicCliService";
import { Card, Row } from "reactstrap";
import { LigacaoVeicCliGrid } from "./components/LigacaoVeicCliGrid";
import { formatValueFromAPI, limparNumero } from "../../../../../coreUtils";
import { BsLightningFill } from "react-icons/bs";
import ComboBoxService from "../../../../../services/components/ComboBoxService";
import { apiPostV2 } from "../../../../../apiV2";
import { routesBaseMontadora } from "../../../servicos/montadora/Montadora";
import { routesBaseVeicCliente } from "../../../servicos/veic_cliente/VeicCliente";
import BotaoPesq from "../../../../../components/form/BotaoPesq";
import { TrocarProprietarioVeicModal } from "../../../../ordens_servico/atendimento/central_os/incluir_alterar/components/TrocarProprietarioVeicModal";
import { ligacaoVeicCliRoute } from "../../../../../routes/modules/cadastro";
import { showWarning } from "../../../../../components/AlertaModal";

const verificarInserirMontadora = async (nome) => {
  if (["", null, undefined, 0].includes(nome)) {
    return null;
  }

  nome = nome?.trim().toUpperCase();
  const ret = await ComboBoxService.fetchOptions("montadora", {
    nome: nome,
  });
  if (ret.length > 0) {
    return ret[0].value;
  } else {
    const payload = {
      nome: nome,
    };
    const [ok, ret] = await apiPostV2(
      `${routesBaseMontadora}/incluir/`,
      payload,
      { successMessage: false }
    );
    return ok ? ret.id_cadastrado : null;
  }
};

const verificarInserirVeicCliente = async (montadora, nome) => {
  if (
    ["", null, undefined, 0].includes(montadora) ||
    ["", null, undefined, 0].includes(nome)
  ) {
    return null;
  }

  nome = nome?.trim().toUpperCase();
  const ret = await ComboBoxService.fetchOptions("veiculo", {
    nome: nome,
  });
  if (ret.length > 0) {
    return ret[0].value;
  } else {
    const idMont = await verificarInserirMontadora(montadora);
    if (!idMont) return null;

    const payload = {
      nome: nome,
      id_montadora: idMont,
      importacao: false,
      id_cad_imp: 0,
    };
    const [ok, ret] = await apiPostV2(
      `${routesBaseVeicCliente}/incluir/`,
      payload,
      { successMessage: false }
    );
    return ok ? ret.id_cadastrado : null;
  }
};

export const combustivelOptions = [
  { label: "Flex", value: "F" },
  { label: "Gasolina", value: "G" },
  { label: "Álcool", value: "A" },
  { label: "Diesel", value: "D" },
];

export const LigacaoVeicCli = ({ location }) => {
  const opener = window.opener;
  const windowParameters = window?.parameters
    ? JSON.parse(window?.parameters)
    : {};
  const idCliente = location?.state?.id_cliente ?? windowParameters.id_cliente;
  const nomeCliente = location?.state?.nome ?? windowParameters.nome;
  const history = useHistory();
  const podeConfirmar = opener !== null && history.length === 1;
  const [idVeiculo, setIdVeiculo] = useState(0);
  const [modelo, setModelo] = useState("");
  const [placa, setPlaca] = useState("");
  const [chassi, setChassi] = useState("");
  const [ano, setAno] = useState("");
  const [combustivel, setCombustivel] = useState("");
  const [cor, setCor] = useState("");
  const [nomeMontadora, setNomeMontadora] = useState("");
  const [dados, setDados] = useState([]);
  const [loadingIncluir, setLoadingIncluir] = useState(false);
  const [loadingConsultaPlaca, setLoadingConsultaPlaca] = useState(false);
  const [dadosVeicPreencAuto, setDadosVeicPreencAuto] = useState({});
  const placaRef = useRef();
  const veiculoRef = useRef();

  const limparDados = () => {
    setIdVeiculo(0);
    setModelo("");
    setPlaca("");
    setChassi("");
    setAno("");
    setCombustivel("");
    setCor("");
    setNomeMontadora("");
  };

  const carregarDados = async () => {
    const [ok, ret] = await CadastroLigacaoVeicCliService.buscarVeiculosCliente(
      idCliente
    );
    setDados(ok ? ret : []);
  };

  const incluirVeiculo = async () => {
    if (["", null, undefined].includes(placa)) {
      showWarning("Por favor, informe a Placa do veículo.");
      return false;
    }

    if ([0, null, undefined].includes(idVeiculo)) {
      showWarning("Por favor, informe o Veículo.");
      return false;
    }

    setLoadingIncluir(true);
    const payload = {
      id_cliente: idCliente,
      id_veiculo: idVeiculo,
      modelo: modelo,
      placa: placa,
      chassi: chassi,
      ano: ano,
      combustivel: combustivel,
      cor: cor,
    };

    const [ok] = await CadastroLigacaoVeicCliService.incluir(payload);
    if (ok) {
      limparDados();
      carregarDados();
      veiculoRef.current.clearValue();
      placaRef.current.focus();
    }
    setLoadingIncluir(false);
  };

  const alterarVeiculo = (coluna, novoValor, row) => {
    if (novoValor === row[coluna]) return false;

    if (["placa", "modelo", "cor", "ano", "chassi"].includes(coluna)) {
      row[coluna] = (novoValor ?? "").toUpperCase();
    } else if (coluna === "combustivel") {
      row[coluna] = novoValor;
      atualizarVeiculo(row);
      return;
    }
    setDados(dados.map((e) => (e.id === row.id ? row : e)));
  };

  const atualizarVeiculo = async (row) => {
    const payload = {
      id_ligacao: row.id,
      id_veiculo: row.id_veiculo,
      modelo: row.modelo,
      placa: row.placa,
      chassi: row.chassi,
      ano: row.ano,
      combustivel: row.combustivel,
      cor: row.cor,
    };

    await CadastroLigacaoVeicCliService.alterar(payload);
    carregarDados();
  };

  const excluir = async (id) => {
    const [ok] = await CadastroLigacaoVeicCliService.excluir(id);
    if (ok) {
      carregarDados();
    }
  };

  const handleSelectVeiculo = (s) => {
    setIdVeiculo(s?.value);
    setNomeMontadora(s?.nome_montadora ?? "");
  };

  const consultaPlaca = async () => {
    if (loadingConsultaPlaca) return;
    if (["", null, undefined].includes(placa) || placa.length !== 8) {
      showWarning("Por favor, informe a Placa do veículo");
      return;
    }

    setLoadingConsultaPlaca(true);
    const cnpj = limparNumero(localStorage.getItem("cnpj_empresa"));
    const [ok, ret] = await CadastroLigacaoVeicCliService.consultaPlaca(
      placa,
      cnpj
    );
    if (ok) {
      const idVeic = await verificarInserirVeicCliente(
        ret.nome_montadora,
        ret.nome_veiculo
      );

      setAno(ret.ano?.trim());
      setChassi(ret.chassi?.trim());
      setCombustivel(ret.combustivel?.trim());
      setCor(ret.cor?.trim());
      setModelo(ret.modelo?.trim());
      setIdVeiculo(idVeic);
      setDadosVeicPreencAuto(ret);
    }
    setLoadingConsultaPlaca(false);
  };

  const handleSetPlaca = (v) => {
    setPlaca(v);
    setDadosVeicPreencAuto({});
  };

  const confirmarVoltar = () => {
    if (!podeConfirmar || loadingConsultaPlaca || loadingIncluir) return;
    if (opener) {
      opener.postMessage(
        { id: JSON.parse(window.parameters).id, id_cliente: idCliente },
        window.location.origin
      );
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "F8") {
      consultaPlaca();
    }

    if (e.key === "F9") {
      incluirVeiculo();
    }
  };

  useEffect(() => {
    carregarDados();
  }, []);

  window.onbeforeunload = function (e) {
    if (window.location.pathname !== ligacaoVeicCliRoute.path) {
      return;
    }

    if (podeConfirmar) {
      confirmarVoltar();
    }
  };

  return (
    <PageContainer
      title="Veículos do Cliente"
      number="0040_3"
      onKeyDown={onKeyDown}
      onBeforeGoingBack={podeConfirmar && confirmarVoltar}
      canGoBack
    >
      <Card body>
        <Row className="mb-2">
          <FixedField
            label="Cliente"
            value={formatValueFromAPI(nomeCliente, idCliente)}
            horizontal
          />
        </Row>
        <Row>
          <MaskedInput
            md={2}
            mask="aaa-9*99"
            label="Placa"
            value={placa}
            onChange={(e, v) => handleSetPlaca(v)}
            ref={placaRef}
            autoFocus
            maskChar={null}
            additionalButton={
              <BotaoPesq
                onClick={consultaPlaca}
                loading={loadingConsultaPlaca}
                disabled={placa.length !== 8}
                disabledHint="Informe a Placa do veículo"
                hint="F8 - Preenchimento Automático"
                icon={BsLightningFill}
                iconSize={18}
              />
            }
            inputStyle={{ textAlign: "center" }}
          />
          <AsyncComboBox
            md={5}
            label="Veículo"
            onChange={(s) => handleSelectVeiculo(s)}
            isSearchable
            isClearable
            isConcatField
            concatModelName="veiculo"
            defaultValue={idVeiculo}
            ref={veiculoRef}
          />
          <FixedField
            label="Montadora"
            value={nomeMontadora}
            horizontal
            divClassName="pt-4"
          />
          {Object.keys(dadosVeicPreencAuto ?? {}).length > 0 && (
            <>
              <FixedField
                label="Cidade"
                value={`${dadosVeicPreencAuto.cidade}-${dadosVeicPreencAuto.uf}`}
                horizontal
                divClassName="pt-4"
              />
            </>
          )}
        </Row>
        <Row>
          <TextInput
            md={3}
            label="Modelo/Motorização"
            value={modelo}
            onChange={(e, v) => setModelo(v)}
          />
          <TextInput
            md={3}
            label="Chassi"
            value={chassi}
            onChange={(e, v) => setChassi(v)}
            maxLength={30}
            inputStyle={{ textAlign: "center" }}
          />
          <TextInput
            md={2}
            label="Ano"
            value={ano}
            onChange={(e, v) => setAno(v)}
            maxLength={10}
            inputStyle={{ textAlign: "center" }}
          />
        </Row>
        <Row>
          <ComboBox
            options={combustivelOptions}
            md={3}
            label="Combustível"
            onChange={(s) => setCombustivel(s?.value)}
            defaultValue={combustivel}
          />
          <TextInput
            md={2}
            label="Cor"
            value={cor}
            onChange={(e, v) => setCor(v)}
            maxLength={30}
          />
          <FormButton
            md="auto"
            color="info"
            onClick={incluirVeiculo}
            loading={loadingIncluir}
          >
            F9 - Incluir
          </FormButton>
          <TrocarProprietarioVeicModal
            placa={placa}
            notifyEvent={carregarDados}
            padded
            showButtonText
            buttonDivClassName="ml-auto"
          />
        </Row>
      </Card>
      <Card body>
        <LigacaoVeicCliGrid
          dados={dados}
          excluir={excluir}
          notifyTrocarProprietario={carregarDados}
          alterarVeiculo={alterarVeiculo}
          atualizarVeiculo={atualizarVeiculo}
        />
        {podeConfirmar && (
          <Row>
            <FormButton
              color="success"
              md="auto"
              onClick={() => window.close()}
              divClassName="ml-auto"
            >
              Confirmar e Sair
            </FormButton>
          </Row>
        )}
      </Card>
    </PageContainer>
  );
};
