import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  NumberInput,
  Table,
  ModalBase,
  TableDelete,
} from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import RegrasComissaoService from "../../../../../services/cadastro/RegrasComissaoService";
import ExcecoesRegraModal from "./ExcecoesRegraModal";

export const DetalharRegraComissaoModal = ({ isOpen, toggle, selected }) => {
  const [nome, setNome] = useState("");
  const [regraPara, setRegraPara] = useState("");
  const [itemAtual, setItemAtual] = useState(null);
  const [itens, setItens] = useState([]);
  const [percComissao, setPercComissao] = useState(0);
  const [idItem, setIdItem] = useState(null);
  const [descItem, setDescItem] = useState("");
  const [loadingAdd, setLoadingAdd] = useState(false);
  const selectRef = useRef();

  const limparDados = () => {
    setNome("");
    setRegraPara("");
    setItemAtual(null);
    setItens([]);
    setPercComissao(0);
    setIdItem(null);
    setDescItem("");
  };

  const onOpen = async () => {
    await buscarDados();
    await carregarItens();
  };

  const carregarItens = async () => {
    const [ok, ret] = await RegrasComissaoService.item.listar(selected);
    setItens(ok ? ret : []);
  };

  const buscarDados = async () => {
    const [ok, ret] = await RegrasComissaoService.buscar(selected);
    if (!ok) return;
    setNome(ret.nome);
    switch (ret.tipo) {
      case "PRODU":
        setRegraPara("produto");
        break;
      case "GRUPO":
        setRegraPara("grupo");
        break;
      case "FABRI":
        setRegraPara("fabricante");
        break;
      case "VENDE":
        setRegraPara("colaborador");
        break;
      default:
        break;
    }
  };

  const handleAdd = async () => {
    const payload = {
      id_cab: selected,
      id_mov: itemAtual.value,
      perc_comissao: percComissao,
    };

    setLoadingAdd(true);
    const [ok] = await RegrasComissaoService.item.incluir(payload);

    if (ok) {
      setPercComissao(0);
      await carregarItens();
      if (selectRef.current) {
        selectRef.current.clearValue();
        selectRef.current.setFocus();
      }
    }
    setLoadingAdd(false);
  };

  const handleDelete = async (idProdRegra) => {
    const [ok] = await RegrasComissaoService.item.excluir(idProdRegra);
    if (ok) {
      setIdItem(null);
      carregarItens();
    }
  };

  const handleSelectItem = (id, checked, row) => {
    setIdItem(id);
    setDescItem(
      checked ? row?.nome_mov + ` (Padrão ${row?.perc_comissao}%)` : ""
    );
  };

  const columns = [
    {
      dataField: "id_mov",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_mov",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "perc_comissao",
      text: "Perc. Comissão",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2) + " %",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "Ações",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={`${nome}: Detalhes`}
      number="0038_3"
      onBeforeOpen={onOpen}
      onClosed={limparDados}
      hideCancelButton
    >
      <Row className="mb-2">
        {regraPara !== "" && (
          <AsyncComboBox
            label={regraPara.charAt(0).toUpperCase() + regraPara.slice(1)}
            isConcatField
            concatModelName={regraPara}
            defaultOptions
            isSearchable
            md={6}
            onChange={(selected) => setItemAtual(selected)}
            autoFocus
            ref={selectRef}
          />
        )}
        <NumberInput
          label="Perc. Comissão"
          md={2}
          onChange={setPercComissao}
          value={percComissao}
          isPercentage
        />
        <FormButton
          md="auto"
          color="info"
          onClick={handleAdd}
          disabled={[0, null, undefined].includes(itemAtual)}
          disabledHint="Selecione um produto no campo à esquerda"
          loading={loadingAdd}
        >
          Incluir
        </FormButton>
        <ExcecoesRegraModal idMov={idItem} descItem={descItem} />
      </Row>
      <Table
        columns={columns}
        data={itens}
        paginated={false}
        pageSize={10}
        onSelect={handleSelectItem}
      />
    </ModalBase>
  );
};
