import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  id_ordem_compra: null,
  data_emi: new Date(),
  id_fornecedor: null,
  nome_fornecedor: null,
  qtd_produtos: 0,
  vlr_total: 0,
  id_cond_pag: null,
  itens: [],
  data_entrega: null,
};

export const ordemCompraSlice = createSlice({
  name: "ordem_compra_slice",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      const newState = { ...initialState };
      Object.entries(action.payload).forEach(([key, value]) => {
        newState[key] = value;
      });
      return newState;
    },
    setIdOrdemCompra: (state, action) => {
      state.id_ordem_compra = action.payload;
    },
    setDataEmi: (state, action) => {
      state.data_emi = action.payload;
    },
    setIdFornecedor: (state, action) => {
      state.id_fornecedor = action.payload;
    },
    setNomeFornecedor: (state, action) => {
      state.nome_fornecedor = action.payload;
    },
    setQtdProdutos: (state, action) => {
      state.qtd_produtos = action.payload;
    },
    setVlrTotal: (state, action) => {
      state.vlr_total = action.payload;
    },
    setIdCondPag: (state, action) => {
      state.id_cond_pag = action.payload;
    },
    setItens: (state, action) => {
      state.itens = action.payload;
    },
    setDataEntrega: (state, action) => {
      state.data_entrega = action.payload;
    },
  },
});

export const {
  init,
  setup,
  setIdOrdemCompra,
  setDataEmi,
  setDataEntrega,
  setIdFornecedor,
  setNomeFornecedor,
  setQtdProdutos,
  setVlrTotal,
  setIdCondPag,
  setItens,
} = ordemCompraSlice.actions;

export default ordemCompraSlice.reducer;
