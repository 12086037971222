import React, { useState } from "react";
import { ModalBase, Table } from "../../../../../components";
import OrdemServicoService from "../../../../../services/ordem_servico/OrdemServicoService";
import { formatValueFromAPI } from "../../../../../coreUtils";

const columns = [
  { dataField: "data_hora_log", text: "Data/Hora", align: "center" },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
  },
  { dataField: "log", text: "Descrição", align: "left" },
];

export const LogsOSModal = ({ isOpen, toggle, selected }) => {
  const [dados, setDados] = useState([]);

  const carregarDados = async () => {
    const [ok, ret] = await OrdemServicoService.buscarLogs(selected);
    if (!ok) {
      toggle();
      return;
    }
    setDados(ret);
  };

  const limparDados = () => {
    setDados([]);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={`Logs da OS Nº ${selected}`}
      number="0107_2"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <Table data={dados} columns={columns} pageSize={10} paginated={false} />
    </ModalBase>
  );
};
