import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import {
  BsBarChartSteps,
  BsFillPersonLinesFill,
  BsPersonExclamation,
} from "react-icons/bs";
import {
  relFinancReceberClienteInadimplentesRoute,
  relFinancReceberContasReceberPendentesRoute,
  relFinancReceberExtratoCreditoRotativoRoute,
  relFinancReceberReceitasLiquidadasCentroCustoRoute,
  relFinancReceberReceitasLiquidadasPlanoContasRoute,
} from "../../../routes/modules/relatorios";
import { LiaSearchDollarSolid } from "react-icons/lia";
import { AiOutlinePieChart } from "react-icons/ai";

export const FinanceiroReceber = () => {
  return (
    <PageContainer title="Relatórios Financeiros - Contas a Receber">
      <MenuGroup>
        <MenuGroupButton
          label="Clientes Inadimplentes"
          icon={BsPersonExclamation}
          pathname={relFinancReceberClienteInadimplentesRoute.path}
          nroTela={relFinancReceberClienteInadimplentesRoute.nroTela}
        />
        <MenuGroupButton
          label="Contas a Receber Pendentes"
          icon={LiaSearchDollarSolid}
          pathname={relFinancReceberContasReceberPendentesRoute.path}
          nroTela={relFinancReceberContasReceberPendentesRoute.nroTela}
        />
        <MenuGroupButton
          label="Crédito Rotativo por Cliente"
          icon={BsFillPersonLinesFill}
          pathname={relFinancReceberExtratoCreditoRotativoRoute.path}
          nroTela={relFinancReceberExtratoCreditoRotativoRoute.nroTela}
        />
        <MenuGroupButton
          label="Receitas Liquidadas por Centro de Custo"
          icon={AiOutlinePieChart}
          pathname={relFinancReceberReceitasLiquidadasCentroCustoRoute.path}
          nroTela={relFinancReceberReceitasLiquidadasCentroCustoRoute.nroTela}
        />
        <MenuGroupButton
          label="Receitas Liquidadas por Plano de Contas"
          icon={BsBarChartSteps}
          pathname={relFinancReceberReceitasLiquidadasPlanoContasRoute.path}
          nroTela={relFinancReceberReceitasLiquidadasPlanoContasRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
