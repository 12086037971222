import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/venda/frente_venda";
const URL_BASE_VENDAS_SALVAS = `${URL_BASE}/vendas_salvas`;

const FrenteVendaService = {
  alterarItem: async (payload) =>
    await apiPutV2(`${URL_BASE}/alterar_item/`, payload, {
      successMessage: false,
    }),
  buscarItens: async (idVenda) =>
    await apiGetV2(`${URL_BASE}/buscar_itens/${idVenda}/`),
  buscarLocalEntrega: async (idVenda) =>
    await apiGetV2(`${URL_BASE}/buscar_local_entrega/${idVenda}/`),
  buscarPrecoListaPreco: async (idProduto, idCliente) =>
    await apiGetV2(
      `/cadastro/produto/buscar_preco_lista_preco/${idProduto}/${idCliente}/`
    ),
  excluirItem: async (tipo, idItem) =>
    await apiDeleteV2(`${URL_BASE}/excluir_item/${tipo}/${idItem}/`),
  salvarCab: async (payload, successMessage) =>
    await apiPutV2(`${URL_BASE}/salvar_cab/`, payload, {
      successMessage: successMessage,
    }),
  incluirCab: async (payload) =>
    await apiPostV2(`${URL_BASE}/incluir_cab/`, payload, {
      successMessage: false,
    }),
  incluirItem: async (payload, successMessage) =>
    await apiPostV2(`${URL_BASE}/incluir_item/`, payload, {
      successMessage: successMessage,
    }),
  finalizar: async (payload) =>
    await apiPutV2(`${URL_BASE}/finalizar/`, payload),
  listarOrcamentos: async (params) =>
    await apiGetV2(`${URL_BASE}/listar_orcamentos/`, params),

  vendasSalvas: {
    listar: async (params) =>
      await apiGetV2(`${URL_BASE_VENDAS_SALVAS}/listar/`, params),
    buscar: async (idVendaSalva) =>
      await apiGetV2(`${URL_BASE_VENDAS_SALVAS}/buscar/${idVendaSalva}/`),
  },
};

export default FrenteVendaService;
