import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { BaseInfoTelaCard, ComboBox } from "../../../../components";
import { BotaoImprimir } from "../../../../components/BotaoImprimir";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import { formatDateISO } from "../../../../coreUtils";
import { docPrintReport } from "../../../../pdf";
import { RendimentoTecnicoGrid } from "./components/RendimentoTecnicoGrid";
import { apiGetV2 } from "../../../../apiV2";

const origemDadosOptions = [
  { label: "Painel de Execução", value: "EXEC" },
  { label: "Manutenção de O.S.", value: "MANU" },
];

export const RendimentoTecnico = () => {
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [origemDados, setOrigemDados] = useState(origemDadosOptions[0].value);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      origem_dados: origemDados,
    };
    return await apiGetV2(
      "/relatorios/ordem_servico/rendimento_tecnico/",
      params
    );
  };

  const carregarDados = async () => {
    limparDados();
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const gerarPdf = async () => {
    setLoadingImprimir(true);

    const [ok, ret] = await buscarDados();
    if (ok) {
      await docPrintReport(
        "/relatorios/ordem_servico/rendimento_tecnico/",
        {
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
          dados: ret,
          origem_dados: origemDados,
        },
        "5002_1"
      );
    }

    setLoadingImprimir(false);
  };

  const handleDateInputs = (dataIni, dataFim) => {
    limparDados();
    setDataIni(dataIni);
    setDataFim(dataFim);
  };

  const handleSelectOrigemDados = (s) => {
    limparDados();
    setOrigemDados(s?.value);
  };

  const limparDados = () => {
    setDados([]);
    setLoading(false);
  };

  const datasInformadas = dataIni instanceof Date && dataFim instanceof Date;

  return (
    <PageContainer
      title="Rendimento de Trabalho por Técnico"
      number="5002"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDateInputs} />
          <ComboBox
            md={3}
            label="Origem dos Dados"
            options={origemDadosOptions}
            onChange={handleSelectOrigemDados}
          />
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            disabled={!datasInformadas}
            disabledHint="Informe datas válidas de início e fim"
          />
          <BotaoImprimir
            loading={loadingImprimir}
            onClick={gerarPdf}
            disabled={!datasInformadas}
            disabledHint="Informe datas válidas de início e fim"
          />
        </Row>
      </Card>
      {dados.length > 0 && (
        <RendimentoTecnicoGrid origemDados={origemDados} dados={dados} />
      )}
      <BaseInfoTelaCard nroTela="5002" />
    </PageContainer>
  );
};
