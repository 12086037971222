import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { OrderedTooltip } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

export const ComissaoMetaGrafico = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey={"mesano"} padding={{ left: 30, right: 30 }} />
        <YAxis
          yAxisId="left"
          tickFormatter={(v) => formatNumber(v, true, 2)}
          tick={{ fontSize: 10 }}
        />
        {data.length && <Tooltip content={<OrderedTooltip isRank={false} />} />}
        <Legend
          iconType="plainline"
          iconSize={30}
          formatter={(value) =>
            value.replace(/\w\S*/g, function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
          }
        />
        <Line
          type="monotoneX"
          key={1}
          dot={true}
          connectNulls={true}
          dataKey={"valor"}
          stroke={"#5fc27e"}
          strokeWidth={2}
          yAxisId={"left"}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
