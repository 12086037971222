import React from "react";
import { Table } from "../../../../../components";

export const ParcelasCondPagGrid = ({ data, alterarParcela }) => {
  const columns = [
    {
      dataField: "prazo",
      text: "Dias de Prazo",
      align: "center",
      headerAlign: "center",
      editable: true,
      editorType: "number",
      decimalPlaces: 0,
      onChange: alterarParcela,
      alwaysShowEditor: true,
    },
    {
      dataField: "perc",
      text: "Percentual",
      align: "right",
      editable: true,
      editorType: "number",
      onChange: alterarParcela,
      alwaysShowEditor: true,
    },
  ];
  return (
    <Table data={data} columns={columns} pageSize={10} paginated={false} />
  );
};
