import "moment/locale/pt-br";
import React from "react";
import "react-day-picker/lib/style.css";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { FiltroPeriodoDatas } from "./FiltroPeriodoDatas";

const Filter = ({
  children,
  title,
  description,
  hasDateInput,
  handleDate,
  infoIcon,
  tooltipOpen,
  toggleTooltip,
  infoText,
}) => {
  return (
    <Card>
      {title && description && infoIcon && (
        <CardHeader>
          <CardTitle tag="h5">
            {title}
            {infoIcon && (
              <BsFillQuestionCircleFill
                size={12}
                className="ml-1"
                id="infoTooltip"
                color="#47bac1"
              />
            )}
          </CardTitle>
          {description !== undefined && (
            <h6 className="card-subtitle text-muted">{description}</h6>
          )}
          {infoIcon && (
            <UncontrolledTooltip placement="right" target="infoTooltip">
              {infoText}
            </UncontrolledTooltip>
          )}
        </CardHeader>
      )}
      <CardBody>
        <Row form>
          {hasDateInput && (
            <FiltroPeriodoDatas onChange={handleDate}/>
          )}
          {children}
        </Row>
      </CardBody>
    </Card>
  );
};

export default Filter;
