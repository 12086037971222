import React from "react";
import { IconLink } from "../../../../../components";
import { FaBoxesPacking } from "react-icons/fa6";
import { programacaoProjetoRoute } from "../../../../../routes/modules/ordem_servico";

const AcoesGridProgramacaoExecucaoProjeto = ({ row }) => {
  return (
    <IconLink
      pathname={programacaoProjetoRoute.path}
      state={{ selected: row.id, row: row }}
      icon={FaBoxesPacking}
      hint={"Lançar Programação da Execução do Projeto"}
      size={16}
      tooltipPlacement="left"
    />
  );
};

export default AcoesGridProgramacaoExecucaoProjeto;
