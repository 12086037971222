import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

export const ProgramacaoProducaoGrid = ({
  dados,
  alterar,
  excluir,
  handleSelect,
  handleSelectAll,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "nome_produto_final",
      text: "Produto Final",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_produto_final),
    },
    { dataField: "id_ficha_tecnica", text: "Ficha Técnica", align: "center" },
    {
      dataField: "quantidade_produzir",
      text: "Qtd. Produzir",
      align: "center",
      formatter: (c) => formatNumber(c, true, 3, true),
    },
    { dataField: "data_prev_producao", text: "Data", align: "center" },
    {
      dataField: "ordem_producao_gerada",
      text: "Ordem de Produção Gerada",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      onRowDoubleClick={alterar}
      pageSize={10}
      onSelect={handleSelect}
      onSelectAll={handleSelectAll}
      multiselect
    />
  );
};
