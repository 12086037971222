import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
import { api } from "../../../api";

class ComissaoService {
  static async fetch({ filters, type }) {
    return new Promise(async (resolve, reject) => {
      let url;
      if (type === "V") {
        url = new URL(
          `${Cookies.get("api-address")}/relatorios/venda/comissao/vendedor/`
        );
      } else {
        let id_tecnico;
        filters.forEach((element) => {
          let key = Object.keys(element)[0];
          if (key === "id_tecnico") {
            id_tecnico = element[key];
          }
        });
        url = new URL(
          `${Cookies.get(
            "api-address"
          )}/relatorios/ordem_servico/comissao/${id_tecnico}/`
        );
      }
      filters.forEach((element) => {
        let key = Object.keys(element)[0];
        if (element[key] !== null) url.searchParams.set(key, element[key]);
      });

      await api
        .get(`${url.toString()}`)
        .then((fetchData) => {
          const checkErr = fetchData.data;
          if (!checkErr.err) {
            resolve(fetchData.data.res);
          } else {
            const { data } = fetchData;
            toastr.error("Erro", `${data.msg} - Erro: ${data.id_err}`);
            resolve(undefined);
          }
        })
        .catch((err) => toastr.error("Erro", err.message));
    });
  }

  static printPDF({ filters, type }) {
    let url;
    if (type === "V") {
      url = new URL(
        `${Cookies.get(
          "api-address"
        )}/relatorios/venda/comissao/vendedor/impressao/`
      );
    } else {
      let id_tecnico;
      filters.forEach((element) => {
        let key = Object.keys(element)[0];
        if (key === "id_tecnico") {
          id_tecnico = element[key];
        }
      });
      url = new URL(
        `${Cookies.get(
          "api-address"
        )}/relatorios/ordem_servico/comissao/impressao/${id_tecnico}/`
      );
    }
    filters.forEach((element) => {
      let key = Object.keys(element)[0];
      if (element[key] !== null) url.searchParams.set(key, element[key]);
    });
    const token_query = `&token=${Cookies.get("access-token")}`;

    window.open(`${url.toString()}${token_query}`, "_blank");
  }
}

export default ComissaoService;
