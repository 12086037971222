import React, { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { sumDataField } from "../../../../coreUtils";
import { BoletoService } from "../../../../services/bancario/BoletoService";
import { ImpressaoBoletosGrid } from "./components/ImpressaoBoletosGrid";
import {
  AsyncComboBox,
  ComboBox,
  FiltroPeriodoDatas,
  FormCheckbox,
  IntegerFormInput,
  TextInput,
  BotaoPesquisar,
  PageContainer,
  CardTotais,
  CardTotaisItem,
} from "../../../../components";

const filtrarPorOptions = [
  { label: "Vencimento", value: "data_vcto" },
  { label: "Emissão", value: "data_emi" },
  { label: "Impressão", value: "data_impr" },
];

const carteiraOptions = [
  { label: "Todos", value: "T" },
  { label: "Carteira", value: "C" },
  { label: "Não-Carteira", value: "NC" },
];

export const ImpressaoBoletos = () => {
  const [idCliente, setIdCliente] = useState(null);
  const [tipoCliente, setTipoCliente] = useState(null);
  const [numero, setNumero] = useState(null);
  const [carteira, setCarteira] = useState(carteiraOptions[0].value);
  const [idOrigem, setIdOrigem] = useState(null);
  const [boletoImpresso, setBoletoImpresso] = useState(false);
  const [tipoData, setTipoData] = useState(filtrarPorOptions[1].value);
  const [dataIni, setDataIni] = useState(
    new Date(new Date().setDate(new Date().getDate() - 3))
  );
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDuplics, setSelectedDuplics] = useState([]);
  const [hints, setHints] = useState({ value: 0, nSelected: 0 });

  const carregarDados = async () => {
    setSelectedDuplics([]);
    setHints({ value: 0, nSelected: 0 });
    setLoading(true);

    const filtroDataIni = tipoData + "_ini";
    const filtroDataFim = tipoData + "_fim";

    const params = {
      id_cliente: idCliente,
      tipo_cliente: tipoCliente,
      numero: numero,
      carteira: carteira === "C" ? true : carteira === "NC" ? false : null,
      id_origem: idOrigem,
      boleto_impresso: boletoImpresso,
      [filtroDataIni]: dataIni,
      [filtroDataFim]: dataFim,
    };
    const [ok, ret] = await BoletoService.listarTituloImprimir(params);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const handleOnSelect = (id, isSelect, row) => {
    const sel = isSelect
      ? [
          ...selectedDuplics,
          {
            id: id,
            id_conta: row.conta_banc,
            id_conta_banc_padrao_cli: row.id_conta_banc_padrao_cli,
          },
        ]
      : selectedDuplics.filter((item) => item.id !== id);

    const val = sumDataField(
      dados.filter((e) => sel.map((s) => s.id).includes(e.id)),
      "vlr_receber"
    );

    setHints({
      value: val,
      nSelected: sel.length,
    });
    setSelectedDuplics(sel);
  };

  const handleOnSelectAll = (isSelect) => {
    const sel = isSelect
      ? dados.map((r) => ({
          id: r.id,
          id_conta: r.conta_banc,
          id_conta_banc_padrao_cli: r.id_conta_banc_padrao_cli,
        }))
      : [];

    const val = isSelect ? sumDataField(dados, "vlr_receber") : 0;

    setHints({
      value: val,
      nSelected: sel.length,
    });
    setSelectedDuplics(sel);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const notifyAction = (action) => {
    switch (action) {
      case "print":
        carregarDados();
        handleOnSelectAll(false, null);
        break;
      case "email":
        handleOnSelectAll(false, null);
        break;
      default:
        break;
    }
  };

  return (
    <PageContainer title="Impressão de Boletos" number="0001" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            label="Cliente"
            name="cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
            defaultValue={idCliente}
          />
          <AsyncComboBox
            md={3}
            label="Tipo Cliente"
            name="tipo_cliente"
            isConcatField
            concatModelName="tipo_cliente"
            isSearchable
            isClearable
            onChange={(s) => setTipoCliente(s?.value)}
            defaultOptions
            defaultValue={tipoCliente}
          />
          <TextInput
            md={2}
            label="Número"
            name="numero"
            onChange={(e, v) => setNumero(v)}
            value={numero}
          />
          <IntegerFormInput
            md={2}
            label="Nº Origem"
            name="id_origem"
            onChange={setIdOrigem}
            defaultValue={idOrigem}
          />
        </Row>
        <Row>
          <ComboBox
            label="Filtrar Por"
            md={2}
            options={filtrarPorOptions}
            defaultValue={tipoData}
            onChange={(s) => setTipoData(s?.value)}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <ComboBox
            label="Mostrar"
            md={2}
            onChange={(s) => setCarteira(s?.value)}
            options={carteiraOptions}
            defaultValue={carteira}
          />
          <FormCheckbox
            label="Ver impressos"
            md={2}
            defaultChecked={boletoImpresso}
            onChange={() => setBoletoImpresso(!boletoImpresso)}
            name="mostrar_impressos"
          />
        </Row>
      </Card>
      <Row>
        <Col md={10}>
          <CardTotais>
            <CardTotaisItem label="Total Selecionado" value={hints.value} />
            <CardTotaisItem
              label="Títulos Selecionados"
              value={hints.nSelected}
              checkFloat
            />
          </CardTotais>
        </Col>
        <Col md={2}>
          <BotaoPesquisar
            style={{ marginTop: "5px" }}
            onClick={carregarDados}
            loading={loading}
            padded={false}
          />
        </Col>
      </Row>
      <ImpressaoBoletosGrid
        dados={dados}
        selectedIDs={selectedDuplics.map((item) => item.id)}
        handleOnSelect={handleOnSelect}
        handleOnSelectAll={handleOnSelectAll}
        selectedDuplics={selectedDuplics}
        notifyAction={notifyAction}
        disabledButtons={hints.nSelected > 0 ? false : true}
      />
    </PageContainer>
  );
};
