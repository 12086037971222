import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import {
  cadastroMontadoraRoute,
  cadastroServicoRoute,
  cadastroTerceirizacaoRoute,
  cadastroVeicClienteRoute,
} from "../../../routes/modules/cadastro";
import { IoIosSwap } from "react-icons/io";

export const ServicosCadastro = () => {
  return (
    <PageContainer title="Cadastros - Serviços">
      <MenuGroup>
        <MenuGroupButton
          label="Serviço"
          icon={cadastroServicoRoute.shortcutIcon}
          pathname={cadastroServicoRoute.path}
          nroTela={cadastroServicoRoute.nroTela}
        />
        <MenuGroupButton
          label="Montadora"
          icon={cadastroMontadoraRoute.shortcutIcon}
          pathname={cadastroMontadoraRoute.path}
          nroTela={cadastroMontadoraRoute.nroTela}
        />
        <MenuGroupButton
          label="Terceirização"
          icon={IoIosSwap}
          pathname={cadastroTerceirizacaoRoute.path}
          nroTela={cadastroTerceirizacaoRoute.nroTela}
        />
        <MenuGroupButton
          label="Veículo de Cliente"
          icon={cadastroVeicClienteRoute.shortcutIcon}
          pathname={cadastroVeicClienteRoute.path}
          nroTela={cadastroVeicClienteRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
