import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";
import CamposPesq from "./CamposPesq";
import ModalPesqProduto from "./ModalPesqProduto";
import CadastroProdutosService from "../../../services/cadastro/CadastroProdutosService";
import { apiGetV2 } from "../../../apiV2";

const PesqProduto = forwardRef(
  (
    {
      onConfirm,
      selectNextField,
      md = 6,
      onChangeDescricao,
      autoFocus,
      mdIdent = 4,
      mdIdentAux = 2,
      showShortcut,
      selected,
      disabled,
      disabledHint,
      auxAsLabel,
      label,
      divClassName,
      mostrarAux = true,
      habilitaPesqReferencia = true,
      podeAlterarNomeProdCuringa = true,
      idCliente,
      labelDescricao,
    },
    ref
  ) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pesqPorReferencia, setPesqPorReferencia] = useState(false);
    const [sistAutomotivo, setSistAutomotivo] = useState(false);
    const [utilizaCorProd, setUtilizaCorProd] = useState(false);
    const [utilizaTamanhoProd, setUtilizaTamanhoProd] = useState(false);
    const [utilizaPrecoAtacado, setUtilizaPrecoAtacado] = useState(false);
    const [tipoVendaCliente, setTipoVendaCliente] = useState(null);
    const [pistaPesq, setPistaPesq] = useState("");
    const [prodCuringa, setProdCuringa] = useState(false);
    const refDescricao = useRef();
    const refIdentificador = useRef();
    const refReferencia = useRef();
    const refIdentificadorAux = useRef();
    const refReferenciaAux = useRef();

    const buscarParametros = async () => {
      const params = { id_cliente: idCliente };
      const [ok, ret] = await apiGetV2("/tela/pesq_produto/", params);
      if (ok) {
        setPesqPorReferencia(
          habilitaPesqReferencia && ret.priorizar_pesq_prod === "REF"
        );
        setSistAutomotivo(ret.tipo_sistema === "MECANICA");
        setUtilizaCorProd(ret.utiliza_cor_prod === true);
        setUtilizaTamanhoProd(ret.utiliza_tamanho_prod === true);
        setUtilizaPrecoAtacado(ret.usa_preco_atacado === true);
        setTipoVendaCliente(ret.tipo_venda_cliente);
      }
    };

    const buscarDadosProduto = async (pista, idProduto) => {
      const searchById = ![0, null, undefined].includes(idProduto);
      if (pista || searchById) {
        setLoading(true);
        const [ok, ret] = await CadastroProdutosService.pesquisa(
          pista,
          idProduto
        );

        if (ok) {
          if (ret.length === 0) {
            onConfirm({
              idProduto: "",
              nomeProduto: "Não Encontrado",
              referencia: "",
              precoVenda: 0,
              unidade: "",
              custoUe: 0,
              cfop: "",
              ncm: "",
              curinga: false,
              quantidade: 0,
              codBarras: "",
              casasDecimaisQtd: 2,
              casasDecimaisVlrs: 2,
              tamanho: null,
              cor: null,
              precoMinimo: null,
            });
            setProdCuringa(false);
          } else if (ret.length > 1) {
            setPistaPesq(pista);
            setModalOpen(true);
          } else {
            const prod = ret[0];
            setProdCuringa(prod.curinga);
            onConfirm({
              idProduto: prod.id,
              nomeProduto: prod.nome,
              referencia: prod.referencia,
              precoVenda: parseFloat(prod.preco_venda),
              unidade: prod.unidade,
              custoUe: parseFloat(prod.custo_ue),
              cfop: prod.id_cfop,
              ncm: prod.clas_fiscal,
              curinga: prod.curinga,
              quantidade: parseFloat(prod.quantidade),
              codBarras: prod.cod_bar,
              casasDecimaisQtd: prod.qtd_casas_decimais_qtd,
              casasDecimaisVlrs: prod.qtd_casas_decimais_vlrs,
              tamanho: prod.tamanho,
              cor: prod.cor,
              precoMinimo: parseFloat(prod.preco_min_permitido),
            });
            if (!prod.curinga && selectNextField) {
              selectNextField();
            }
            setLoading(false);
            return true;
          }
        } else {
          onConfirm({
            idProduto: "",
            nomeProduto: "Não Encontrado",
            referencia: "",
            precoVenda: 0,
            unidade: "",
            custoUe: 0,
            cfop: "",
            ncm: "",
            curinga: false,
            quantidade: 0,
            codBarras: "",
            casasDecimaisQtd: 2,
            casasDecimaisVlrs: 2,
            tamanho: null,
            cor: null,
            precoMinimo: null,
          });
          setProdCuringa(false);
        }
        setLoading(false);
      } else {
        onConfirm({
          idProduto: "",
          nomeProduto: "Não Encontrado",
          referencia: "",
          precoVenda: 0,
          unidade: "",
          custoUe: 0,
          cfop: "",
          ncm: "",
          curinga: false,
          quantidade: 0,
          codBarras: "",
          casasDecimaisQtd: 2,
          casasDecimaisVlrs: 2,
          tamanho: null,
          cor: null,
          precoMinimo: null,
        });
        setProdCuringa(false);
      }
      return false;
    };

    const focusIdentif = () => {
      if (prodCuringa) {
        if (refDescricao.current) {
          refDescricao.current.focus();
        }
      } else {
        if (pesqPorReferencia) {
          if (refReferencia.current) {
            refReferencia.current.focus();
          }
        } else {
          if (refIdentificador.current) {
            refIdentificador.current.focus();
          }
        }
      }
    };

    const setDescricao = (v) => refDescricao.current.setValue(v);

    const setId = (v) => {
      if (refIdentificador.current) {
        refIdentificador.current.setValue(v);
      }
      if (refIdentificadorAux.current) {
        refIdentificadorAux.current.setValue(v);
      }
    };

    const setReferencia = (v) => {
      if (refReferencia.current) {
        refReferencia.current.setValue(v);
      }
      if (refReferenciaAux.current) {
        refReferenciaAux.current.setValue(v);
      }
    };

    const limparCampos = () => {
      setDescricao("");
      setId("");
      setReferencia("");
      setProdCuringa(false);
    };

    useImperativeHandle(ref, () => ({
      setDescricao: setDescricao,
      setId: setId,
      setReferencia: setReferencia,
      setValueByID: (v) => buscarDadosProduto(null, v),
      focus: focusIdentif,
      clear: limparCampos,
    }));

    useEffect(() => {
      if (podeAlterarNomeProdCuringa && prodCuringa) {
        refDescricao.current.focus();
      }
    }, [podeAlterarNomeProdCuringa, prodCuringa]);

    const configModalAberto = () => {
      if (modalOpen) {
        buscarParametros();
      }
    };

    useEffect(configModalAberto, [modalOpen]);

    const buscarConfigs = () => {
      buscarParametros();
    };
    useEffect(buscarConfigs, []);

    const handleOpenModal = (pista) => {
      setPistaPesq(pista);
      setModalOpen(true);
    };

    return (
      <>
        <CamposPesq
          md={md}
          onClickPesq={() => setModalOpen(true)}
          pesqPorReferencia={pesqPorReferencia}
          onExitIdentificador={(_, pista) => buscarDadosProduto(pista)}
          refIdentificador={refIdentificador}
          refDescricao={refDescricao}
          refReferencia={refReferencia}
          refIdentificadorAux={refIdentificadorAux}
          refReferenciaAux={refReferenciaAux}
          labelDescricao={labelDescricao}
          labelIdentificador={
            label ? label : pesqPorReferencia ? "Referência" : "Código"
          }
          loadingPesq={loading}
          disabledDescricao={!(podeAlterarNomeProdCuringa && prodCuringa)}
          openModal={handleOpenModal}
          onChangeDescricao={onChangeDescricao}
          autoFocus={autoFocus}
          mdIdent={mdIdent}
          mdDesc={null}
          mdIdentAux={mdIdentAux}
          showShortcut={showShortcut}
          selected={selected}
          disabled={disabled}
          disabledHint={disabledHint}
          auxAsLabel={auxAsLabel}
          pistaPesq={pistaPesq}
          setPistaPesq={setPistaPesq}
          divClassName={divClassName}
          mostrarAux={mostrarAux}
        />
        <ModalPesqProduto
          pistaPadrao={pistaPesq}
          isOpen={modalOpen}
          toggle={(selecionouProd) => {
            setModalOpen(!modalOpen);
            if (!modalOpen) {
              setPistaPesq("");
            } else {
              if (selecionouProd) {
                if (selectNextField) selectNextField();
              } else {
                focusIdentif();
              }
            }
          }}
          selectProd={(
            id,
            nome,
            refer,
            precoVda,
            unidade,
            curinga,
            custoUe,
            cfop,
            clasFiscal,
            quantidade,
            codBar,
            qtdCasasDecimaisQtd,
            qtdCasasDecimaisVlrs,
            tamanhoProd,
            corProd,
            precoMinimo
          ) => {
            setProdCuringa(curinga);
            onConfirm({
              idProduto: id,
              nomeProduto: nome,
              referencia: refer,
              precoVenda: precoVda,
              unidade: unidade,
              custoUe: custoUe,
              cfop: cfop,
              ncm: clasFiscal,
              curinga: curinga,
              quantidade: quantidade,
              codBarras: codBar,
              casasDecimaisQtd: qtdCasasDecimaisQtd,
              casasDecimaisVlrs: qtdCasasDecimaisVlrs,
              tamanho: tamanhoProd,
              cor: corProd,
              precoMinimo: precoMinimo,
            });
          }}
          sistAutomotivo={sistAutomotivo}
          utilizaCorProd={utilizaCorProd}
          utilizaTamanhoProd={utilizaTamanhoProd}
          utilizaPrecoAtacado={utilizaPrecoAtacado}
          tipoVendaCliente={tipoVendaCliente}
        />
      </>
    );
  }
);

export default PesqProduto;
