import React, { useState, forwardRef, useImperativeHandle } from "react";
import { ModalBase, RadioGroup } from "../../../../../components";
import { RadioItem } from "../../../../../components/RadioGroup";
import { Row, Spinner } from "reactstrap";
import { LuXOctagon } from "react-icons/lu";
import { BsCheckLg } from "react-icons/bs";
import NotaFiscalConsumidorService from "../../../../../services/docs_eletron/NotaFiscalConsumidorService";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import CaixaLojaService from "../../../../../services/financeiro/CaixaLojaService";
import { toastr } from "react-redux-toastr";

const STATUS = {
  LOADING: 1,
  ERROR: 2,
  SUCCESS: 3,
};

export const FaturarNfModal = forwardRef(
  ({ notifyEvent, idMovCxaLoja }, ref) => {
    // Parâmetros
    const [defineFretePorConta, setDefineFretePorConta] = useState("FX0");
    // Controle
    const [isOpen, setIsOpen] = useState(false);
    const [modeloNFe, setModeloNFe] = useState(null);
    const [fretePorConta, setFretePorConta] = useState(null);
    const [status, setStatus] = useState(STATUS.LOADING);
    const [msgStatus, setMsgStatus] = useState("");
    const [modeloNfeCli, setModeloNfeCli] = useState(null);

    // 1 - Perguntar se deseja emitir NF-e
    // 2 - Perguntar modelo NF-e
    // 3 - Definir frete por conta (opcional, conforme parâmetro)
    // 4 - Imprimir/Concluído
    const [estagio, setEstagio] = useState(1);

    const buscarParametros = (params) => {
      if (params) {
        setDefineFretePorConta(params.define_por_conta_frete);
      }
    };

    const toggleInternal = (
      emiteAuto,
      modelo,
      fretePorConta,
      usarModNfeCadCli,
      modeloNfeCadCli
    ) => {
      if (!isOpen) {
        if (emiteAuto) {
          setDefineFretePorConta(fretePorConta);
          if (modelo !== "PERG" && modelo) {
            if (fretePorConta === "PEG" && modelo === "NFE") {
              setModeloNFe(modelo);
              setEstagio(3);
            } else {
              faturar(modelo);
              setEstagio(4);
            }
          } else {
            setEstagio(2);
            if (usarModNfeCadCli) {
              setModeloNfeCli(
                modeloNfeCadCli === "NFE" ? modeloNfeCadCli : "NFCE"
              );
            }
          }
        } else {
          setEstagio(1);
          if (modelo !== "PERG" && modelo) {
            setModeloNFe(modelo);
          } else if (usarModNfeCadCli) {
            setModeloNfeCli(
              modeloNfeCadCli === "NFE" ? modeloNfeCadCli : "NFCE"
            );
          }
        }
      }
      setIsOpen(!isOpen);
    };

    const faturar = async (modelo) => {
      setStatus(STATUS.LOADING);
      const payload = {
        id_mov_cxa: idMovCxaLoja,
        modelo: modelo,
        frete_por_conta: fretePorConta,
      };

      const [ok, ret] = await CaixaLojaService.faturar(payload);
      if (ok) {
        if (ret.res.nf_emitida) {
          // Imprimir NFC-e
          setMsgStatus("Gerando Impressão");
          if (modelo === "NFCE") {
            await NotaFiscalConsumidorService.imprimir(ret.res.id_nfe);
          } else {
            await NotaFiscalService.imprimir(ret.res.id_nfe);
          }
          setStatus(STATUS.SUCCESS);
          setMsgStatus("NF Faturada com Sucesso!");
          setTimeout(notifyEvent, 2500);
        } else {
          setStatus(STATUS.ERROR);
          setMsgStatus(
            "Nota Fiscal faturada, mas ocorreu um erro durante a emissão. " +
              "Por favor, faça a emissão manualmente. Erro: " +
              ret.res.msg_erro_emissao
          );
        }
      } else {
        setMsgStatus(
          "Ocorreu um erro durante o faturamento da Nota Fiscal. " +
            "Por favor, realize o faturamento manualmente. Erro: " +
            ret
        );
        setStatus(STATUS.ERROR);
      }
    };

    useImperativeHandle(ref, () => ({
      toggle: toggleInternal,
    }));

    const handleSubmit = async () => {
      if (estagio === 1) {
        if (modeloNFe === null) {
          if (modeloNfeCli !== null) {
            // Pré seleciona modelo da NF-e com modelo definido no cadastro do
            // cliente
            setModeloNFe(modeloNfeCli);
          }
          setEstagio(2);
        } else {
          if (defineFretePorConta === "PEG" && modeloNFe === "NFE") {
            setEstagio(3);
          } else {
            setEstagio(4);
            await faturar(modeloNFe);
          }
        }
      } else if (estagio === 2) {
        if (!["NFE", "NFCE"].includes(modeloNFe)) {
          toastr.warning(
            "Atenção",
            "Por favor, selecione o modelo da Nota Fiscal"
          );
          return;
        }

        if (defineFretePorConta === "PEG" && modeloNFe === "NFE") {
          setEstagio(3);
        } else {
          setEstagio(4);
          await faturar(modeloNFe);
        }
      } else if (estagio === 3) {
        setEstagio(4);
        await faturar(modeloNFe);
      }
    };

    return (
      <ModalBase
        isOpen={isOpen}
        toggle={
          estagio === 3 && status === STATUS.ERROR ? toggleInternal : null
        }
        size="md"
        title="Recebimento do Caixa Loja"
        number="0063_18"
        cancelButtonText={
          estagio === 1
            ? "Não"
            : [2, 3].includes(estagio)
            ? "Cancelar"
            : "Fechar"
        }
        paramsName="caixa_loja/fatura_nfe"
        onBeforeOpen={buscarParametros}
        hideCancelButton={estagio === 4 && status !== STATUS.ERROR}
        confirmButtonText={estagio === 1 ? "Sim" : "Confirmar"}
        onConfirm={estagio !== 4 ? handleSubmit : null}
        onCancel={notifyEvent}
      >
        {estagio === 1 && (
          <>
            <h5 style={{ textAlign: "center" }}>
              Você deseja gerar a Nota Fiscal do recebimento agora?
            </h5>
          </>
        )}
        {estagio === 2 && (
          <>
            <Row>
              <RadioGroup
                label="Selecione o modelo da NF que você deseja gerar"
                value={modeloNFe}
                onChange={setModeloNFe}
                divClassName="m-auto"
              >
                <RadioItem label="NF-e" value="NFE" />
                <RadioItem label="NFC-e" value="NFCE" />
              </RadioGroup>
            </Row>
          </>
        )}
        {estagio === 3 && (
          <>
            <Row>
              <RadioGroup
                label="Selecione por conta de quem é o frete"
                value={fretePorConta}
                onChange={setFretePorConta}
                divClassName="m-auto"
              >
                <RadioItem label="0 - Remetente" value="0" />
                <RadioItem label="1 - Destinatário" value="1" />
                <RadioItem label="2 - Terceiros" value="2" />
                <RadioItem label="9 - Sem Frete" value="9" />
              </RadioGroup>
            </Row>
          </>
        )}
        {estagio === 4 && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              {status === STATUS.LOADING && (
                <Spinner
                  size="lg"
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "0.8rem solid #ccc",
                    borderRightColor: "transparent",
                  }}
                  color="silver"
                />
              )}
              {status === STATUS.SUCCESS && (
                <BsCheckLg
                  size={30}
                  color="green"
                  style={{ width: "150px", height: "150px" }}
                />
              )}
              {status === STATUS.ERROR && (
                <LuXOctagon
                  size={30}
                  color="red"
                  style={{ width: "150px", height: "150px" }}
                />
              )}
            </div>

            <h4 style={{ textAlign: "center" }}>
              {msgStatus}
              {status === STATUS.LOADING ? "..." : ""}
            </h4>
          </>
        )}
      </ModalBase>
    );
  }
);
