import React from "react";
import { useState } from "react";
import { modalTitleAmbiente, routesBaseAmbiente } from "../Ambiente";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { TextInput } from "../../../../../components";

export const CadastroAmbienteModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [abreviacao, setAbreviacao] = useState("");
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setAbreviacao("");
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setAbreviacao(data.abreviacao);
    setAtivo(data.ativo);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      abreviacao: abreviacao ?? "",
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ativo: ativo, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleAmbiente}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseAmbiente}
      number="0103_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={40}
          md={10}
        />
        <TextInput
          label="Abreviação"
          value={abreviacao}
          onChange={(e, v) => setAbreviacao(v)}
          maxLength={5}
          md={2}
        />
      </Row>
    </ModalCadastroV2>
  );
};
