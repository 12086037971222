import React from "react";
import { useState } from "react";
import { TextInput } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { modalTitleMontadora, routesBaseMontadora } from "../Montadora";
import { ModalCadastroV2 } from "../../../../../components/cadastro";

export const CadastroMontadoraModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [nome, setNome] = useState("");

  const limparDados = () => {
    setNome("");
  };

  const fetchData = (data) => {
    setNome(data.nome);
  };

  const submitPayload = (action) => {
    const payload = { nome: nome };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleMontadora}
      size="sm"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseMontadora}
      number="0069_1"
      selected={selected}
      notifyEvent={notifyEvent}
      concatShortcut={concatShortcut}
      concatModelName="montadora"
    >
      <TextInput label="Nome" value={nome} onChange={(e, v) => setNome(v)} />
    </ModalCadastroV2>
  );
};
