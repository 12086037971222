import React, { useState } from "react";
import { apiGetV2 } from "../../../../../apiV2";
import { routesBaseEstoqueInter } from "../EstoqueInter";
import {
  BotaoImprimir,
  FixedField,
  FormButton,
  ModalBase,
  Table,
} from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { docPrintReport } from "../../../../../pdf";
import { Row } from "reactstrap";

const columns = [
  {
    dataField: "nome_produto",
    text: "Produto",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
  },
  { dataField: "unidade_produto", text: "UN", align: "center" },
  {
    dataField: "quantidade",
    text: "Qtd.",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
];
export const SaldoEstoqueInterModal = ({ selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dados, setDados] = useState({});
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => setDados({});

  const buscarDados = async () =>
    await apiGetV2(`${routesBaseEstoqueInter}/buscar_saldo/${selected}/`);

  const carregarDados = async () => {
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret : {});
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      await docPrintReport("/relatorios/pronta_entrega/estoque_inter/", {
        dados: ret,
      });
    }
    setLoadingImprimir(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton
        md="auto"
        padded={false}
        color="primary"
        onClick={toggle}
        disabled={[0, null, undefined].includes(selected)}
      >
        Saldo e Produtos
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Saldo e Produtos do Estoque Intermediario"
        number="0096_4"
        onBeforeOpen={carregarDados}
        onClosed={limparDados}
        footerActions={
          <>
            <BotaoImprimir
              padded={false}
              onClick={imprimir}
              loading={loadingImprimir}
            />
          </>
        }
      >
        <Row className="mb-2">
          <FixedField label="Rota" value={dados.nome_rota} horizontal />
          <FixedField label="Nome" value={dados.nome_estoque} horizontal />
        </Row>
        <Table
          data={dados?.produtos ?? []}
          columns={columns}
          paginated={false}
          pageSize={10}
        />
      </ModalBase>
    </>
  );
};
