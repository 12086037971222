import React from "react";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { BsBoxSeam } from "react-icons/bs";
import { UncontrolledTooltip } from "reactstrap";
import { formatarValor } from "../../../../../../coreUtils";
import { Table } from "../../../../../../components";

const coresStatus = {
  NAO: "#9c9c9c",
  CMP: "#dead0b",
  AGM: "#0453d1",
  IOK: "#4dae4f",
};
const statusOptions = [
  { label: "Não Definido", value: "NAO" },
  { label: "Comprar", value: "CMP" },
  { label: "Ag. Mercadoria", value: "AGM" },
  { label: "Ok", value: "IOK" },
];

export const ItensExecucaoGrid = ({ itens }) => {
  const columns = [
    {
      dataField: "tipo",
      text: "T",
      align: "left",
      headerAlign: "left",
      editable: false,
      colWidth: "30px",
      formatter: (c, row) => {
        const iconId = `icon-tipo-${row.id}-${row.id_item}`;
        if (c === "Serviço") {
          return (
            <>
              <MdOutlineHomeRepairService id={iconId} size={16} />
              <UncontrolledTooltip target={iconId}>{c}</UncontrolledTooltip>
            </>
          );
        } else {
          return (
            <>
              <BsBoxSeam id={iconId} size={14} />
              <UncontrolledTooltip target={iconId}>{c}</UncontrolledTooltip>
            </>
          );
        }
      },
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      headerAlign: "center",
      colWidth: "75px",
    },
    {
      dataField: "id_item",
      text: "#",
      align: "center",
      headerAlign: "center",
      colWidth: "60px",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      colWidth: "350px",
    },
    {
      dataField: "vlr_custo",
      text: "Custo",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2),
      colWidth: "60px",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      headerAlign: "center",
      colWidth: "30px",
    },
    {
      dataField: "vlr_unit",
      text: "V Unit.",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2),
      colWidth: "60px",
    },
    {
      dataField: "qtd_estoque",
      text: "Estoque",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatarValor(c, 4, 0),
      colWidth: "75px",
    },
    {
      dataField: "quantidade",
      text: "Qtde",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <span
          style={
            parseFloat(row.qtd_estoque ?? "0") < parseFloat(c ?? "0") &&
            row.tipo === "Produto"
              ? { color: "red", fontWeight: "bold" }
              : {}
          }
        >
          {formatarValor(c, 4, 0)}
        </span>
      ),
      colWidth: "75px",
    },
    {
      dataField: "vlr_item",
      text: "Valor Item",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 4),
      colWidth: "75px",
    },
    {
      dataField: "perc_desc",
      text: "% Desc.",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatarValor(c, 2),
      colWidth: "75px",
    },
    {
      dataField: "vlr_total",
      text: "Valor Total",
      align: "right",
      headerAlign: "right",
      formatter: (c, row) => formatarValor(c, 2),
      colWidth: "75px",
    },
    {
      dataField: "status",
      text: "Status",
      align: "left",
      formatter: (c, row) => row.desc_status,
      editable: true,
      alwaysShowEditor: true,
      editorType: "combobox",
      optionsRenderer: (opt) => (
        <span style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginLeft: 0,
              marginRight: "0.4rem",
              backgroundColor: coresStatus[opt.value],
            }}
          />
          {opt.label}
        </span>
      ),
      options: statusOptions,
      onChange: console.log,
      fixedColWidth: true,
      colWidth: "180px",
      sortable: true,
    },
  ];

  return (
    <Table columns={columns} data={itens} paginated={false} pageSize={20} />
  );
};
