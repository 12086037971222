import React, { useRef, useState } from "react";
import {
  AsyncComboBox,
  LabelButton,
  ModalBase,
  NumberInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { roundFloat } from "../../../../../coreUtils";

export const IncluirServicoModal = ({ incluirItem }) => {
  // Controle
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // Dados do Serviço
  const [idItem, setIdItem] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [unidade, setUnidade] = useState("");
  const [quantidade, setQuantidade] = useState(1);
  const [vlrUnit, setVlrUnit] = useState(0);
  const [vlrItem, setVlrItem] = useState(0);
  const [percDesc, setPercDesc] = useState(0);
  const [vlrTotal, setVlrTotal] = useState(0);
  const [vlrDesc, setVlrDesc] = useState(0);
  const [vlrAcre, setVlrAcre] = useState(0);

  const refServico = useRef();
  const refQuantidade = useRef();

  const limparDados = () => {
    if (refServico.current) {
      refServico.current.clearValue();
    }
    setIdItem(0);
    setDescricao("");
    setUnidade("");
    setQuantidade(1);
    setVlrUnit(0);
    setVlrItem(0);
    setPercDesc(0);
    setVlrTotal(0);
    setVlrDesc(0);
    setVlrAcre(0);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const semItem = [0, null, undefined].includes(idItem);

  const selectNextField = () => {
    setTimeout(() => {
      refQuantidade.current.focus();
    }, 35);
  };

  const handleSetItem = (s) => {
    if (s?.value !== idItem && ![0, null, undefined].includes(s?.value)) {
      limparDados(false);

      setIdItem(s?.value);
      setDescricao(s?.nome);
      setVlrDesc(0);
      setPercDesc(0);
      setVlrAcre(0);
      setVlrUnit(s?.valor);
      setUnidade(s?.unidade);
      setVlrItem(s?.valor);
      setVlrTotal(s?.valor);
      selectNextField();
    }
  };

  const handleSetQuantidade = (v) => {
    setQuantidade(v);

    const vlrTotBruto = vlrUnit * v;
    const vlrTotal = vlrItem * v;

    let novoVlrDesc;
    let novoPercDesc;
    let novoVlrAcre;
    if (vlrUnit > vlrItem) {
      novoVlrDesc = v * (vlrUnit - vlrItem);
      novoPercDesc = roundFloat((novoVlrDesc / vlrTotBruto) * 100, 2);
      novoVlrAcre = 0;
    } else {
      novoVlrDesc = 0;
      novoPercDesc = 0;
      if (vlrItem > vlrUnit) {
        novoVlrAcre = v * (vlrItem - vlrUnit);
      } else {
        novoVlrAcre = 0;
      }
    }

    setPercDesc(roundFloat(novoPercDesc, 2));
    setVlrDesc(roundFloat(novoVlrDesc, 2));
    setVlrAcre(roundFloat(novoVlrAcre, 2));
    setVlrTotal(roundFloat(vlrTotal, 2));
  };

  const handleSetPercDesc = (v) => {
    setPercDesc(v);

    const vlrTotBruto = vlrUnit * quantidade;

    const vlrItem = ((100 - percDesc) / 100) * vlrUnit;
    const vlrTotal = vlrItem * quantidade;
    const vlrDesc = (percDesc / 100) * vlrTotBruto;

    setVlrDesc(roundFloat(vlrDesc, 2));
    setVlrItem(roundFloat(vlrItem, 2));
    setVlrTotal(roundFloat(vlrTotal, 2));
  };

  const handleSetVlrItem = (v) => {
    setVlrItem(v);

    const vlrTotBruto = vlrUnit * quantidade;
    const vlrTotal = v * quantidade;

    let novoVlrDesc;
    let novoPercDesc;
    let novoVlrAcre;
    if (vlrUnit > v) {
      novoVlrDesc = quantidade * (vlrUnit - v);
      novoPercDesc = roundFloat((novoVlrDesc / vlrTotBruto) * 100, 2);
      novoVlrAcre = 0;
    } else {
      novoVlrDesc = 0;
      novoPercDesc = 0;
      if (v > vlrUnit) {
        novoVlrAcre = quantidade * (v - vlrUnit);
      } else {
        novoVlrAcre = 0;
      }
    }

    setPercDesc(roundFloat(novoPercDesc, 2));
    setVlrDesc(roundFloat(novoVlrDesc, 2));
    setVlrAcre(roundFloat(novoVlrAcre, 2));
    setVlrTotal(roundFloat(vlrTotal, 2));
  };

  const handleSetVlrTotal = (v) => {
    setVlrTotal(v);

    const vlrTotBruto = vlrUnit * quantidade;

    const novoVlrItem = roundFloat(v / quantidade, 4);
    let novoVlrDesc;
    let novoPercDesc;
    let novoVlrAcre;
    if (vlrUnit > novoVlrItem) {
      novoVlrDesc = quantidade * (vlrUnit - novoVlrItem);
      novoPercDesc = roundFloat((novoVlrDesc / vlrTotBruto) * 100, 2);
      novoVlrAcre = 0;
    } else {
      novoVlrDesc = 0;
      novoPercDesc = 0;
      if (novoVlrItem > vlrUnit) {
        novoVlrAcre = quantidade * (novoVlrItem - vlrUnit);
      } else {
        novoVlrAcre = 0;
      }
    }

    setPercDesc(roundFloat(novoPercDesc, 2));
    setVlrDesc(roundFloat(novoVlrDesc, 2));
    setVlrAcre(roundFloat(novoVlrAcre, 2));
    setVlrItem(roundFloat(novoVlrItem, 2));
  };

  const handleSubmit = async () => {
    const payload = {
      tipo: "S",
      id_prod_serv: idItem,
      descricao: descricao,
      unidade: unidade,
      id_ambiente: 0,
      quantidade: quantidade,
      vlr_unit: vlrUnit,
      vlr_item: vlrItem,
      perc_desc: percDesc,
      vlr_tot_prod_serv: vlrTotal,
      vlr_total: vlrTotal,
      vlr_desc: vlrDesc,
      vlr_acre: vlrAcre,
      perc_ipi: 0,
      vlr_ipi: 0,
      vlr_icms_st: 0,
      vlr_icms_st_unit: 0,
      perc_comis: 0,
      vlr_tot_comis: 0,
      observ: "",
    };
    setLoading(true);
    if (await incluirItem(payload)) {
      limparDados();
      if (refServico.current) {
        refServico.current.setFocus();
      }
    }
    setLoading(false);
  };

  return (
    <>
      <LabelButton onClick={toggle} divClassName="mt-2 mb-0">
        + Serviço
      </LabelButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Incluir Serviço"
        number="0079_3"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onClosed={limparDados}
      >
        <Row>
          <AsyncComboBox
            md={9}
            isConcatField
            concatModelName="servico"
            label="Serviço"
            isSearchable
            onChange={handleSetItem}
            defaultValue={idItem}
            defaultOptions
            ref={refServico}
            autoFocus
          />
        </Row>
        <Row>
          <NumberInput
            md={3}
            label="Quantidade"
            value={quantidade}
            onChange={handleSetQuantidade}
            disabled={semItem}
            ref={refQuantidade}
          />
          <NumberInput
            md={3}
            label="Desconto (%)"
            value={percDesc}
            onChange={handleSetPercDesc}
            isPercentage
            disabled={semItem}
          />
          <NumberInput
            md={3}
            label="Valor Item"
            value={vlrItem}
            onChange={handleSetVlrItem}
            disabled={semItem}
          />
          <NumberInput
            md={3}
            label="Valor Total"
            value={vlrTotal}
            onChange={handleSetVlrTotal}
            disabled={semItem}
          />
        </Row>
      </ModalBase>
    </>
  );
};
