import React from "react";
import { HelpCircle } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { formatNumber } from "../../../../coreUtils";

const columns = [
  [
    {
      dataField: "regra_origem",
      text: "Regra",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vlr_usa_calc",
      text: "Valor para Cálculo",
      align: "right",
      headerAlign: "right",
      headerFormatter: (column) => {
        return (
          <React.Fragment>
            {column.text}
            {"  "}
            <HelpCircle size={15} id="HelpCircleCalculo" />
            <UncontrolledTooltip placement="right" target="HelpCircleCalculo">
              Esse campo considera os valores dos itens que se encaixam na
              regra, subtraindo o valor devolvido dos mesmos.
            </UncontrolledTooltip>
          </React.Fragment>
        );
      },
      formatter: (value) => formatNumber(value),
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão (R$)",
      align: "right",
      headerAlign: "right",
      formatter: (value) => formatNumber(value),
    },
  ],
  [
    {
      dataField: "tipo",
      text: "Tipo",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vlr_devol",
      text: "Valor Devolvido",
      align: "right",
      headerAlign: "right",
      formatter: (value) => formatNumber(value),
    },
    {
      dataField: "vlr_usa_calc",
      text: "Valor para Cálculo",
      align: "right",
      headerAlign: "right",
      formatter: (value) => formatNumber(value),
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão (R$)",
      align: "right",
      headerAlign: "right",
      formatter: (value) => formatNumber(value),
    },
  ],
  [
    {
      dataField: "tipo",
      text: "Tipo",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "nome",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_item",
      text: "Valor Un. Item",
      align: "right",
      headerAlign: "right",
      formatter: (value) => formatNumber(value),
    },
    {
      dataField: "vlr_usa_calc",
      text: "Valor para Cálculo",
      align: "right",
      headerAlign: "right",
      formatter: (value) => formatNumber(value),
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão (R$)",
      align: "right",
      headerAlign: "right",
      formatter: (value) => formatNumber(value),
    },
  ],
];

export default columns;
