import React, { useState } from "react";
import {
  Divider,
  ModalBase,
  ModalDetalhesIcone,
  Table,
} from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

const movsColumns = [
  // id_cred_rot_mov 29,
  {
    dataField: "origem",
    text: "Origem",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "id_origem",
    text: "Nº Origem",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "historico",
    text: "Histórico",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "data_hora_mov",
    text: "Data/Hora",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "valor_mov",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "status",
    text: "Status",
    align: "center",
    headerAlign: "center",
  },
];

const moedasColumns = [
  {
    dataField: "desc_moeda",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "historico",
    text: "Histórico",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "data_rec",
    text: "Data/Hora Rec.",
    align: "center",
    headerAlign: "center",
    formatter: (c, row) => `${c} ${row.hora_rec}`,
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const DetalhesLoteCredRotativoModal = ({ lote }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalDetalhesIcone toggle={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Detalhes do Lote do Crédito Rotativo"
        number="0068_51"
      >
        <Divider className="mt-0">Movimentos</Divider>
        <Table
          data={lote?.movs ?? []}
          columns={movsColumns}
          paginated={false}
          pageSize={Math.min(lote?.movs?.length ?? 0, 10)}
        />
        <Divider>Formas de Pagamento</Divider>
        <Table
          data={lote?.moedas_rec}
          columns={moedasColumns}
          paginated={false}
          pageSize={Math.min(lote?.moedas_rec?.length ?? 0, 5)}
        />
      </ModalBase>
    </>
  );
};
