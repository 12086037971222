import React, { useCallback, useEffect, useState } from "react";
import { Table } from "../../../../../components/Table";
import { formatValueFromAPI, formatarValor } from "../../../../../coreUtils";
import { apiGet } from "../../../../../api";

const TableExecucoesCentralOS = ({ idOs }) => {
  const [dados, setDados] = useState([]);

  const carregarDados = useCallback(async () => {
    setDados(
      await apiGet(
        `/ordem_servico/controle_horas/listar_execucoes/${idOs}/`,
        []
      )
    );
  }, [idOs]);

  useEffect(() => {
    carregarDados();
  }, [carregarDados]);

  const columns = [
    {
      dataField: "tipo",
      text: "Tipo",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "tecnico",
      text: "Técnico",
      align: "left",
      headerAlign: "left",
      formatter: (_, row) =>
        formatValueFromAPI(row.nome_tecnico, row.id_tecnico),
    },
    {
      dataField: "data_hora_ini_exec",
      text: "Início",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_hora_fim_exec",
      text: "Fim",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "hora",
      text: "Tempo",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatarValor(c, 4, 0),
    },
    {
      dataField: "hora_formatada",
      text: "Horas/Minutos",
      align: "center",
      headerAlign: "center",
    },
  ];
  return (
    <>
      {dados.length > 0 && (
        <div className="mt-2">
          <Table
            data={dados}
            columns={columns}
            paginated={false}
            pageSize={dados.length}
            fixedSize={false}
            bgStyle={{ borderRadius: "none" }}
          />
        </div>
      )}
    </>
  );
};

export default TableExecucoesCentralOS;
