import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
  FormCheckbox,
  IntegerFormInput,
  NumberInput,
  PesqPlanoCtaCentroCusto,
} from "../../../../components";
import {
  setCobJuros,
  setCobMulta,
  setCodPlanoCtaJuros,
  setCodPlanoCtaMulta,
  setDiasJuros,
  setDiasMulta,
  setIdPlanoContaPadDescFinanc,
  setIdPlanoContasCustoBoleto,
  setIdPlanoContasPadIncTit,
  setIdPlnCtaOutrAcrescimoCobBanc,
  setIdentifPlanoContasIncManual,
  setPermiteLiquidacaoTransacao,
  setTaxaJuros,
  setTaxaMulta,
} from "../store/contas_receber_slice";

export const PersonContasReceber = () => {
  const store = useSelector((state) => state.contas_receber);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          label="Cobrar Multa"
          checked={store.cob_multa}
          onChange={() => dispatch(setCobMulta(!store.cob_multa))}
        />
        <NumberInput
          md={2}
          label="Taxa de Multa"
          value={store.taxa_multa}
          onChange={(v) => dispatch(setTaxaMulta(v))}
        />
        <IntegerFormInput
          md={3}
          label="Dias para Cobrança de Multa"
          defaultValue={store.dias_multa}
          onChange={(v) => dispatch(setDiasMulta(v))}
        />
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de Contas para Recebimento de Multa"
          value={store.cod_plano_cta_multa}
          mode="plano_contas"
          onChange={(v) => dispatch(setCodPlanoCtaMulta(v))}
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Cobrar Juros"
          checked={store.cob_juros}
          onChange={() => dispatch(setCobJuros(!store.cob_juros))}
        />
        <NumberInput
          md={2}
          label="Taxa de Juros"
          value={store.taxa_juros}
          onChange={(v) => dispatch(setTaxaJuros(v))}
        />
        <IntegerFormInput
          md={3}
          label="Dias para Cobrança de Juros"
          defaultValue={store.dias_juros}
          onChange={(v) => dispatch(setDiasJuros(v))}
        />
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de Contas para Recebimento de Juros"
          value={store.cod_plano_cta_juros}
          mode="plano_contas"
          onChange={(v) => dispatch(setCodPlanoCtaJuros(v))}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          label="Plano de Contas Padrão para Inclusão de Títulos a Receber"
          value={store.id_plano_contas_pad_inc_tit}
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoContasPadIncTit(v))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permite Liquidação de Título via Transação"
          checked={store.permite_liquidacao_transacao}
          onChange={() =>
            dispatch(
              setPermiteLiquidacaoTransacao(!store.permite_liquidacao_transacao)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Identificar Plano de Contas na Inclusão Manual de Título"
          checked={store.identif_plano_contas_inc_manual}
          onChange={() =>
            dispatch(
              setIdentifPlanoContasIncManual(
                !store.identif_plano_contas_inc_manual
              )
            )
          }
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          label="Plano de Contas Padrão para Desconto Financeiro"
          value={store.id_plano_conta_pad_desc_financ}
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoContaPadDescFinanc(v))}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          label="Plano de Contas Padrão para Custos de Boleto"
          value={store.id_plano_contas_custo_boleto}
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlanoContasCustoBoleto(v))}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          label="Plano de Contas Padrão para Outros Acréscimos de Cobrança Bancária"
          value={store.id_pln_cta_outr_acrescimo_cob_banc}
          mode="plano_contas"
          onChange={(v) => dispatch(setIdPlnCtaOutrAcrescimoCobBanc(v))}
        />
      </Row>
    </>
  );
};
