import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  zera_qtde_prod_balanco: false,
  usa_estoque_virtual: false,
  camin_impressora_etiqueta: null,
  layout_etiqueta: 1,
  linguagem_impressora_etiqueta: null,
  utiliza_estoque_real_adm_pronta_entrega: false,
  bloqueia_lcto_result_negativo_est_real_adm: false,
  permite_manipular_dados_inc_mov_manual: false,
};

export const estoqueSlice = createSlice({
  name: "estoque_slice",
  initialState: initialState,
  reducers: {
    setupEstoque: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setZeraQtdeProdBalanco: (state, action) => {
      state.zera_qtde_prod_balanco = action.payload;
    },
    setUsaEstoqueVirtual: (state, action) => {
      state.usa_estoque_virtual = action.payload;
    },
    setCaminImpressoraEtiqueta: (state, action) => {
      state.camin_impressora_etiqueta = action.payload;
    },
    setLayoutEtiqueta: (state, action) => {
      state.layout_etiqueta = action.payload;
    },
    setLinguagemImpressoraEtiqueta: (state, action) => {
      state.linguagem_impressora_etiqueta = action.payload;
    },
    setUtilizaEstoqueRealAdmProntaEntrega: (state, action) => {
      state.utiliza_estoque_real_adm_pronta_entrega = action.payload;
    },
    setBloqueiaLctoResultNegativoEstRealAdm: (state, action) => {
      state.bloqueia_lcto_result_negativo_est_real_adm = action.payload;
    },
    setPermiteManipularDadosIncMovManual: (state, action) => {
      state.permite_manipular_dados_inc_mov_manual = action.payload;
    },
  },
});

export const {
  setupEstoque,
  setZeraQtdeProdBalanco,
  setUsaEstoqueVirtual,
  setCaminImpressoraEtiqueta,
  setLayoutEtiqueta,
  setLinguagemImpressoraEtiqueta,
  setUtilizaEstoqueRealAdmProntaEntrega,
  setBloqueiaLctoResultNegativoEstRealAdm,
  setPermiteManipularDadosIncMovManual,
} = estoqueSlice.actions;

export default estoqueSlice.reducer;
