import React, { useState } from "react";
import { Row } from "reactstrap";
import { FormButton, ModalBase } from "../../../../../components";
import DuracoesJornadaService from "../../../../../services/jornada/duracoes/DuracoesJornadaService";

export const EncerrarDuracaoModal = ({
  notifyEncerrar,
  selected,
  nomeRota,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const payload = { id_jornada: selected };

    setLoading(true);
    const [ok] = await DuracoesJornadaService.encerrar(payload);
    if (ok) {
      await notifyEncerrar();
      toggle();
    }
    setLoading(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton
        color="danger"
        md="auto"
        divStyle={{ marginLeft: "auto" }}
        onClick={() => toggle()}
        padded={false}
        disabled={[0, null, undefined].includes(selected)}
      >
        Encerrar Duração
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title={`Encerramento de Duração - ${nomeRota}`}
        number="0023_3"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row className="justify-content-center">
          <p style={{ textAlign: "center" }}>
            Você tem certeza que deseja encerrar a duração nº {selected} da rota{" "}
            <strong>{nomeRota}</strong>?
            <br />
            <span className="text-danger">
              Atenção: esse processo não pode ser desfeito sem a intervenção do
              suporte Skillsoft!
            </span>
          </p>
        </Row>
      </ModalBase>
    </>
  );
};
