import { apiPutV2 } from "../../apiV2";
import { apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/compra/sugestao_compra";

const SugestaoCompraService = {
  abrirSugestao: (payload) =>
    apiPostV2(`${URL_BASE}/abrir_sugestao/`, payload, {
      successMessage: false,
    }),
  alterarQtdCompraItem: (payload) =>
    apiPutV2(`${URL_BASE}/alterar_qtd_compra_item/`, payload, {
      successMessage: false,
    }),
  buscarCab: (idSugestao) => apiGetV2(`${URL_BASE}/buscar_cab/${idSugestao}/`),
  buscarQtdProdutosEstMin: (params) =>
    apiGetV2(`${URL_BASE}/buscar_qtd_produtos_est_min/`, params, {
      errorMessageType: "toastr",
    }),
  gerarItensSugestao: (payload) =>
    apiPostV2(`${URL_BASE}/gerar_itens_sugestao/`, payload, {
      successMessage: false,
    }),
  gerarOrdemCompra: (payload) =>
    apiPostV2(`${URL_BASE}/gerar_ordem_compra/`, payload),
  incluirItem: (payload) => apiPostV2(`${URL_BASE}/incluir_item/`, payload),
  incluirMultiplosItem: (payload) =>
    apiPostV2(`${URL_BASE}/incluir_multiplos_item/`, payload),
  listarProdutosEstMin: (params) =>
    apiGetV2(`${URL_BASE}/listar_produtos_est_min/`, params),
  listarProdutos: (idSugestao, params) =>
    apiGetV2(`${URL_BASE}/listar_produtos/${idSugestao}/`, params),
};

export default SugestaoCompraService;
