import React, { useState } from "react";
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import { LabelButton } from "../../../../../components";
import { DetalhesAvisosVendasModal } from "./DetalhesAvisosVendasModal";
import { MdOutlinePointOfSale } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  gerencContasReceberRoute,
  gerenciamentoContasPagarRoute,
} from "../../../../../routes/modules/financeiro";
import { ContextMenu } from "../../../../../redux/reducers/menuReducer";
import { showWarning } from "../../../../../components/AlertaModal";

const GrupoAvisosInternal = ({ dados, menus }) => {
  const history = useHistory();
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const idAviso = dados?.id_aviso;

  const toggleDetalhes = () => {
    setDetalhesOpen(!detalhesOpen);
  };

  let iconeAviso;
  if (idAviso === 1) {
    iconeAviso = (
      <FaMoneyCheckAlt
        size={55}
        color="white"
        style={{
          marginRight: "0.7rem",
          backgroundColor: "#DD5144",
          padding: "0.1rem 0.3rem",
          borderRadius: "5px",
        }}
      />
    );
  } else if (idAviso === 2) {
    iconeAviso = (
      <FaMoneyCheckDollar
        size={55}
        color="white"
        style={{
          marginRight: "0.7rem",
          backgroundColor: "#39A43B",
          padding: "0.3rem",
          borderRadius: "5px",
        }}
      />
    );
  } else if (idAviso === 3) {
    iconeAviso = (
      <MdOutlinePointOfSale
        size={55}
        color="white"
        style={{
          marginRight: "0.7rem",
          backgroundColor: "indigo",
          padding: "0.3rem",
          borderRadius: "5px",
        }}
      />
    );
  } else if (idAviso === 4) {
    iconeAviso = (
      <FaMoneyCheckAlt
        size={55}
        color="white"
        style={{
          marginRight: "0.7rem",
          backgroundColor: "#ff897e",
          padding: "0.1rem 0.3rem",
          borderRadius: "5px",
        }}
      />
    );
  } else if (idAviso === 5) {
    iconeAviso = (
      <FaMoneyCheckDollar
        size={55}
        color="white"
        style={{
          marginRight: "0.7rem",
          backgroundColor: "#71DF74",
          padding: "0.3rem",
          borderRadius: "5px",
        }}
      />
    );
  }

  const abrirTelaSeLiberado = (route, state) => {
    const menu = menus.find((e) => e.path === route.path);
    const liberado = menu !== undefined;

    if (liberado) {
      history.push(route.path, state);
    } else {
      showWarning(`Seu perfil não permite acesso ao ${route.name}`);
    }
  };

  const onClick = () => {
    if (idAviso === 1) {
      const filtros = {
        periodo: "VCT",
        status: "PD",
        data_ini: null,
        data_fim: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 1
        ),
      };
      abrirTelaSeLiberado(gerenciamentoContasPagarRoute, filtros);
    } else if (idAviso === 2) {
      const filtros = {
        periodo: "VCT",
        mostrar_status: "PD",
        data_ini: null,
        data_fim: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 1
        ),
      };
      abrirTelaSeLiberado(gerencContasReceberRoute, filtros);
    } else if (idAviso === 4) {
      const filtros = {
        periodo: "VCT",
        status: "PD",
        data_ini: new Date(),
        data_fim: new Date(),
      };
      abrirTelaSeLiberado(gerenciamentoContasPagarRoute, filtros);
    } else if (idAviso === 5) {
      const filtros = {
        periodo: "VCT",
        mostrar_status: "PD",
        data_ini: new Date(),
        data_fim: new Date(),
      };
      abrirTelaSeLiberado(gerencContasReceberRoute, filtros);
    }
  };

  return (
    <>
      <ListGroupItem
        style={{
          display: "flex",
          height: "auto",
          transition: "none",
          boxShadow: "#0000001c 0px 5px 5px",
          borderRadius: "5px",
        }}
        onClick={onClick}
      >
        {iconeAviso && iconeAviso}
        <div>
          <ListGroupItemHeading>{dados.titulo}</ListGroupItemHeading>
          <ListGroupItemText style={{ marginBottom: 0 }}>
            {Array.isArray(dados.descricao)
              ? dados.descricao.map((e) => (
                  <>
                    {e}
                    <br />
                  </>
                ))
              : dados.descricao}{" "}
            {dados.permite_interacao && (
              <LabelButton divClassName="px-0 mt-1" onClick={toggleDetalhes}>
                Ver Detalhes
              </LabelButton>
            )}
          </ListGroupItemText>
        </div>
      </ListGroupItem>
      {dados.permite_interacao && (
        <DetalhesAvisosVendasModal
          isOpen={detalhesOpen}
          toggle={toggleDetalhes}
          dados={dados}
        />
      )}
    </>
  );
};

export const GrupoAvisos = connect(
  (store) => ({ menus: store.menus }),
  null,
  null,
  {
    context: ContextMenu,
  }
)(GrupoAvisosInternal);
