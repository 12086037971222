import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FixedTable,
  FormButton,
  NumberInput,
} from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import { TableDelete } from "../../../../../components/TableDelete";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import VendedorTecnicoServicoService from "../../../../../services/cadastro/VendedorTecnicoServicoService";

export const ModalItensVTS = ({ notify, idVTS }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [itensVTS, setItensVTS] = useState([]);
  const [tipoItemSelecionado, setTipoItemSelecionado] = useState("PROD");
  const [idItemSelecionado, setIdItemSelecionado] = useState(null);
  const [percComissao, setPercComissao] = useState(0.0);
  const itemSelectRef = useRef();
  const percComissaoInputRef = useRef();
  const tiposItens = [{ label: "Produto", value: "PROD" }];
  const [loadingAdd, setLoadingAdd] = useState(false);

  const columns = [
    {
      dataField: "tipo",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
      sort: true,
      formatter: (v) => {
        return { PROD: "Produto" }[v];
      },
    },
    {
      dataField: "id_tipo",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "perc_comissao",
      text: "% Comissão",
      align: "right",
      headerAlign: "right",
      sort: true,
      formatter: (v) => formatNumber(v),
      sortFunc: naturalSort,
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "Ações",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => (
        <TableDelete onClick={() => removerItem(row.id)} />
      ),
    },
  ];

  const buscarDados = async () => {
    const ret = await VendedorTecnicoServicoService.buscarItens(idVTS);
    setItensVTS(ret);
  };

  const handleItemSelect = (sel) => setIdItemSelecionado(sel?.value);

  const handleTipoSelect = (sel) => setTipoItemSelecionado(sel?.value);

  const removerItem = async (idRegistro) => {
    const ret = await VendedorTecnicoServicoService.excluirItem(idRegistro);
    if (ret === true) buscarDados();
  };

  const handleSubmit = async () => {
    const payload = {
      id_vts: idVTS,
      tipo: tipoItemSelecionado,
      id_tipo: idItemSelecionado,
      perc_comis: percComissao,
    };
    let ret;
    setLoadingAdd(true);
    ret = await VendedorTecnicoServicoService.adicionarItem(payload);
    if (ret === true) {
      itemSelectRef.current.clearValue();
      itemSelectRef.current.setFocus();
      setPercComissao(0);
      buscarDados();
    }
    setLoadingAdd(false);
  };

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
    } else {
      setItensVTS([]);
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton
        padded={true}
        divClassName="pr-0"
        md="auto"
        disabled={[0, null, undefined].includes(idVTS)}
        color="secondary"
        onClick={toggle}
      >
        Itens
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        // onOpened
        // onClosed
        title="Inclusão de Items - Comissão de VTS"
        number="0020_4"
      >
        <Row>
          <ComboBox
            md={3}
            label="Tipo"
            options={tiposItens}
            defaultValue={tiposItens[0].value}
            onChange={handleTipoSelect}
            autoFocus
          />
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="produto"
            isSearchable
            md={9}
            label="Item"
            onChange={handleItemSelect}
            ref={itemSelectRef}
          />
        </Row>
        <Row>
          <NumberInput
            label="% Comissão"
            md={2}
            required
            value={percComissao}
            onChange={setPercComissao}
            ref={percComissaoInputRef}
          />
          <FormButton
            color="info"
            onClick={handleSubmit}
            loading={loadingAdd}
            disabled={
              [0, null, undefined].includes(idItemSelecionado) ||
              [0, null, undefined].includes(percComissao)
            }
            disabledHint={
              "Selecione um item no campo acima e informe um percentual de comissão maior que zero"
            }
          >
            Adicionar
          </FormButton>
        </Row>
        <Row>
          <FixedTable
            noWrap
            columns={columns}
            data={itensVTS}
            keyField="id_produto"
          />
        </Row>
      </ModalBase>
    </>
  );
};
