import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormButton,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { apiGetV2 } from "../../../../apiV2";
import {
  VisitasNaoVendaGrid,
  columnsVisitasNaoVenda,
} from "./components/VisitasNaoVendaGrid";
import { exportDataToSheet } from "../../../../coreUtils";

const columnsRelatorio = columnsVisitasNaoVenda(true);

export const VisitasNaoVenda = () => {
  const [idRota, setIdVendedor] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);

  const buscarDados = async () => {
    const params = {
      id_vendedor: idRota,
      data_ini: dataIni,
      data_fim: dataFim,
    };
    return await apiGetV2("relatorios/venda/visitas/", params);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret.visitas : []);
    setLoading(false);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      exportDataToSheet(ret.visitas, "Visitas (Não-vendas)", {
        columns: columnsRelatorio,
        dataIni: dataIni,
        dataFim: dataFim,
      });
    }
    setLoadingExportar(false);
  };

  return (
    <PageContainer title="Visitas (Não-vendas)" number="5051" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Vendedor"
            isConcatField
            concatModelName="colaborador"
            isSearchable
            isClearable
            onChange={(s) => setIdVendedor(s?.value)}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            disabled={!(dataIni instanceof Date) || !(dataFim instanceof Date)}
            disabledHint="Informe datas válidas de início e fim"
          />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportar}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <VisitasNaoVendaGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
