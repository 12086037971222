import React from "react";
import { BsFileText } from "react-icons/bs";
import { IconButton } from "./IconButton";

export const ModalDetalhesIcone = ({
  toggle,
  hint = "Ver Detalhes",
  tooltipPlacement = "left",
  color,
}) => (
  <IconButton
    icon={BsFileText}
    onClick={toggle}
    hint={hint}
    tooltipPlacement={tooltipPlacement}
    color={color}
  />
);
