import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FixedField,
  FormCheckbox,
  NumberInput,
  RadioGroup,
  Table,
  FormButton,
  ModalBase,
  ModalExcluir,
} from "../../../../../components";
import { formatarValor, formatNumber } from "../../../../../coreUtils";
import CadastroListaPrecosService from "../../../../../services/cadastro/CadastroListaPrecosService";
import { AlterarItemListaDe } from "./AlterarItemListaDe";
import { optionsListaPrecoDe } from "../ListaPrecos";
import { RadioItem } from "../../../../../components/RadioGroup";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";

export const DetalhesListaDe = ({
  idCab,
  nomeLista,
  tipoListaDe,
  tipoListaPara,
  definirTipoListaDe,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [de, setDe] = useState(tipoListaDe);
  const [idDe, setIdDe] = useState(null);
  const [nomeProduto, setNomeProduto] = useState(null);
  const [referenciaProd, setReferenciaProd] = useState(null);
  const [natureza, setNatureza] = useState(null);
  const [percDescAcre, setPercDescAcre] = useState(null);
  const [custoUe, setCustoUe] = useState(null);
  const [precoUnit, setPrecoUnit] = useState(null);
  const [precoVenda, setPrecoVenda] = useState(0);
  const [precoMinimo, setPrecoMinimo] = useState(0);
  const [itensDeLista, setItensListaDe] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [alterouAlgo, setAlterouAlgo] = useState(false);
  const [usaDescMaxEspec, setUsaDescMaxEspec] = useState(false);
  const [descMaxEspec, setDescMaxEspec] = useState(null);
  const [percDescMax, setPercDescMax] = useState(null);
  const selectRef = useRef();
  const refPesqProd = useRef();

  useEffect(() => {
    setDe(tipoListaDe);
  }, [tipoListaDe]);

  const descricaoItens =
    {
      PRODUTO: "Produtos",
      GRUPO: "Grupos",
      FABRICANTE: "Fabricantes",
      ALL: "Ajuste de Percentual",
    }[tipoListaDe] ?? "Itens";

  const columns = [
    {
      dataField: "id_de",
      text: "#",
      align: "center",
      sortable: true,
      hidden: tipoListaDe === "ALL",
    },
    {
      dataField: "desc_de",
      text: "Tipo",
      align: "left",
    },
    {
      dataField: "nome_de",
      text: "Nome",
      align: "left",
      sortable: true,
      hidden: tipoListaDe === "ALL",
    },
    {
      dataField: "desc_natureza",
      text: "Natureza",
      align: "left",
      sortable: true,
      hidden: tipoListaDe === "PRODUTO" && tipoListaPara === "CLIENTE",
    },
    {
      dataField: "perc_desc_acre",
      text: "% Desc/Acre",
      align: "right",
      formatter: (c) => `${formatNumber(c, true, 2)}%`,
      sortable: true,
      hidden: tipoListaDe === "PRODUTO" && tipoListaPara === "CLIENTE",
    },
    {
      dataField: "preco_venda",
      text: "Preço de Venda (R$)",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      hidden: tipoListaDe !== "PRODUTO" || tipoListaPara !== "CLIENTE",
    },
    {
      dataField: "desc_tipo_desc_max_espec",
      text: "Desc. Máx. Especial",
      align: "left",
      hidden: tipoListaDe !== "PRODUTO",
    },
    {
      dataField: "perc_desc_max_espec",
      text: "% Desc. Máx. Especial",
      align: "center",
      formatter: (c) => (c ? formatNumber(c, true, 2) : ""),
      hidden: tipoListaDe !== "PRODUTO",
    },
    {
      dataField: "act_edit",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <AlterarItemListaDe
          notifyEvent={buscarDados}
          tipoListaPara={tipoListaPara}
          dadosItem={row}
        />
      ),
    },
    {
      dataField: "act_delete",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <ModalExcluir
          notifyEvent={() => {
            setAlterouAlgo(true);
            buscarDados();
          }}
          route={`/cadastro/lista_preco/de/excluir/${row.id}/`}
          selected={row.id}
          title={`${descricaoItens} da Lista de Preços`}
          number="0013_41"
          table
          message={`Tem certeza de que deseja excluir o registro da lista ${nomeLista}?`}
        />
      ),
    },
  ];

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
    }
    setIsOpen(!isOpen);
    setAlterouAlgo(false);
    setItensListaDe([]);
    if (isOpen) {
      setIdDe(null);
      setPrecoUnit(0);
      setPrecoVenda(0);
      setPercDescAcre(0);
      setCustoUe(0);
      setNomeProduto(null);
      setReferenciaProd(null);
      buscarDados();
      definirTipoListaDe(de);
      setUsaDescMaxEspec(false);
      setDescMaxEspec(null);
      setPercDescMax(null);
      if (alterouAlgo) {
        notifyEvent();
      }
    }
  };

  const handleSubmit = async () => {
    let data = {
      id_cab: idCab,
      de: de,
      id_de: idDe,
      natureza: natureza,
      perc_desc_acre: percDescAcre,
      preco_venda: precoVenda,
      usa_desc_max_espec: usaDescMaxEspec,
      tipo_desc_max_espec: descMaxEspec,
      perc_desc_max_espec: percDescMax,
    };

    setLoadingAdd(true);
    try {
      const [ok] = await CadastroListaPrecosService.de.incluir(data);
      if (ok) {
        setIdDe(null);
        setPrecoUnit(0);
        setPrecoVenda(0);
        setPercDescAcre(0);
        setCustoUe(0);
        setNomeProduto(null);
        setReferenciaProd(null);
        buscarDados();
        definirTipoListaDe(de);
        setUsaDescMaxEspec(false);
        setDescMaxEspec(null);
        setPercDescMax(null);
        setAlterouAlgo(true);
      }
    } finally {
      setLoadingAdd(false);
    }
    if (selectRef.current) {
      selectRef.current.clearValue();
      selectRef.current.setFocus();
    }

    if (refPesqProd.current) {
      refPesqProd.current.clear();
      refPesqProd.current.focus();
    }
  };

  const buscarDados = async () => {
    const [ok, ret] = await CadastroListaPrecosService.de.listar(idCab);
    if (ok) {
      setItensListaDe(ret);
      if (ret?.length === 0) {
        definirTipoListaDe(null);
      }
    }
  };

  const labelDe =
    {
      ALL: "Todos os Produtos",
      PRODUTO: "Produto",
      GRUPO: "Grupo",
      FABRICANTE: "Fabricante",
    }[de] ?? "Selecione o Tipo";

  const concatModelNameDe = {
    ALL: null,
    PRODUTO: "produto",
    GRUPO: "grupo",
    FABRICANTE: "fabricante",
  }[de];

  const calcularPrecoVenda = () => {
    if (de === "PRODUTO") {
      let vlrAjuste = (precoUnit ?? 0) * ((percDescAcre ?? 0) / 100);
      if (natureza === "DESCONTO") {
        vlrAjuste *= -1;
      }
      setPrecoVenda(precoUnit + vlrAjuste);
    }
  };

  useEffect(calcularPrecoVenda, [percDescAcre, de, idDe, natureza]);

  const handleSelectProduto = async ({
    idProduto,
    nomeProduto,
    referencia,
    precoVenda,
    precoMinimo: precoMin,
    custoUe,
  }) => {
    setIdDe(idProduto);
    setNomeProduto(nomeProduto);
    setReferenciaProd(referencia);
    setPrecoUnit(precoVenda);
    setPrecoMinimo(precoMin);
    setCustoUe(custoUe);
  };

  const carregarDadosProduto = () => {
    if (refPesqProd.current) {
      if (idDe) {
        refPesqProd.current.setId(String(idDe));
        refPesqProd.current.setDescricao(nomeProduto);
        refPesqProd.current.setReferencia(String(referenciaProd));
      } else {
        refPesqProd.current.clear();
      }
    }
  };

  useEffect(carregarDadosProduto, [refPesqProd.current, idDe]);

  return (
    <>
      <FormButton
        md="auto"
        color="primary"
        onClick={toggle}
        disabled={
          [0, null, undefined].includes(idCab) ||
          [null, undefined, ""].includes(tipoListaPara)
        }
      >
        {descricaoItens} da Lista
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title={<>{descricaoItens} da Lista de Preços</>}
        number="0013_4"
      >
        <Row className="mb-1">
          <ComboBox
            md={4}
            label="Definição de Lista de Preço Por"
            options={optionsListaPrecoDe}
            onChange={(s) => {
              setDe(s?.value);
              setIdDe(null);
              if (s?.value !== "PRODUTO") {
                setPrecoVenda(null);
                setPrecoMinimo(null);
                setPrecoUnit(null);
              } else {
                setNatureza(null);
                setPercDescAcre(null);
              }
            }}
            defaultValue={de}
            isSearchable={false}
            isClearable
            isDisabled={tipoListaDe}
          />
        </Row>
        <Row>
          {de !== "PRODUTO" ? (
            <AsyncComboBox
              label={labelDe}
              isConcatField
              concatModelName={concatModelNameDe}
              isClearable
              isSearchable
              md={8}
              onChange={(s) => {
                setIdDe(s?.value);
                if (de === "PRODUTO") {
                  setPrecoMinimo(s?.preco_min_permitido);
                  setPrecoUnit(s?.preco_venda);
                }
              }}
              autoFocus
              defaultOptions={de !== "PRODUTO"}
              ref={selectRef}
              disabled={!de || de === "ALL"}
            />
          ) : (
            <>
              <PesqProduto
                md={8}
                onConfirm={handleSelectProduto}
                ref={refPesqProd}
                mdIdent={3}
                mdDesc={7}
                mdIdentAux={2}
                auxAsLabel
                podeAlterarNomeProdCuringa={false}
              />
              <FixedField
                label="Custo"
                value={formatarValor(custoUe, 2, 2, true)}
                horizontal
                padded
                divStyle={{ paddingBottom: "10px" }}
              />
              <FixedField
                label="Preço Cadastro"
                value={formatarValor(precoUnit, 2, 2, true)}
                horizontal
                padded
                divStyle={{ paddingBottom: "10px" }}
              />
            </>
          )}
        </Row>
        <Row className="mb-2">
          {(de !== "PRODUTO" || tipoListaPara !== "CLIENTE") && (
            <>
              <RadioGroup
                label="Operação"
                value={natureza}
                onChange={setNatureza}
                disabled={!de}
              >
                <RadioItem label="Diminuir" value="DESCONTO" />
                <RadioItem label="Aumentar" value="ACRESCIMO" />
              </RadioGroup>
              <NumberInput
                label={`% de ${
                  natureza === "DESCONTO" ? "Redução" : "Aumento"
                }`}
                md={2}
                onChange={setPercDescAcre}
                value={percDescAcre}
                isPercentage
                disabled={!de || !natureza}
              />
            </>
          )}
          {de === "PRODUTO" && (
            <>
              <NumberInput
                label="Preço de Venda Aplicado"
                md={3}
                mdInput={7}
                onChange={setPrecoVenda}
                value={precoVenda}
                disabled={tipoListaPara !== "CLIENTE"}
              />
            </>
          )}
        </Row>
        <Row className="mb-2" style={{ justifyContent: "space-between" }}>
          {de === "PRODUTO" && tipoListaPara !== "CLIENTE" && (
            <FormCheckbox
              label="Definir desconto máximo especial"
              checked={usaDescMaxEspec}
              padded={false}
              onChange={() => setUsaDescMaxEspec(!usaDescMaxEspec)}
            />
          )}
          <FormButton
            md="auto"
            color="success"
            onClick={handleSubmit}
            loading={loadingAdd}
            padded={false}
          >
            Adicionar
          </FormButton>
        </Row>
        {usaDescMaxEspec && (
          <>
            <Row className="mb-2">
              <Col md="auto" style={{ paddingTop: "21px" }}>
                <RadioItem
                  id="desc_max_espec_preco_min"
                  label={`Por Preço Mínimo R$ ${formatarValor(
                    precoMinimo,
                    2,
                    2,
                    true
                  )}`}
                  checked={descMaxEspec === "PRECO_MIN"}
                  onClick={() => setDescMaxEspec("PRECO_MIN")}
                />
              </Col>
              <Col md="auto" style={{ paddingTop: "21px" }}>
                <RadioItem
                  id="desc_max_espec_perc_desc_max"
                  label={`Por % de Desconto Máximo`}
                  checked={descMaxEspec === "PERC_DESC_MAX"}
                  onClick={() => setDescMaxEspec("PERC_DESC_MAX")}
                />
              </Col>
              {descMaxEspec === "PERC_DESC_MAX" && (
                <NumberInput
                  label={`% de Desconto`}
                  md={2}
                  onChange={setPercDescMax}
                  value={percDescMax}
                  isPercentage
                />
              )}
            </Row>
          </>
        )}
        <Table data={itensDeLista} columns={columns} paginated={false} />
      </ModalBase>
    </>
  );
};
