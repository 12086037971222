import { apiGetV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/pagar/duplic_pag/estorno";
const EstornoPagamentoService = {
  buscarRecursosTitulosLote: (idLote) =>
    apiGetV2(`${URL_BASE}/buscar_recursos_titulos_lote/${idLote}/`),
  estornarLote: (idLote) => apiPutV2(`${URL_BASE}/estornar_lote/${idLote}/`),
  listarLotes: (params) => apiGetV2(`${URL_BASE}/listar_lotes/`, params),
};

export default EstornoPagamentoService;
