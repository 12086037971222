import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import "react-day-picker/lib/style.css";
import { Info } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "reactstrap";
import { checkPair } from "../../../../../coreUtils";
import { MovimentosDescAcreModal } from "./MovimentosDescAcreModal";
import {
  setDetalhesContasPagarOpen,
  setDetalhesJornadaDiaOpen,
  setDetalhesNfEntradaOpen,
  setDetalhesOrdServOpen,
  setDetalhesVendaOpen,
  setDetalhesVendaProntaEntregaOpen,
  setIdDetalhes,
} from "../store/dreSlice";

export const ExpandableRow = ({ level, row, index, despesas }) => {
  const store = useSelector((state) => state.dre);
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [idMovimento, setIdMovimento] = useState(null);
  const [childrenVisible, setChildrenVisible] = useState(false);

  const toggle = () => {
    setChildrenVisible(!childrenVisible);
  };

  useEffect(() => {
    if (store.clear) {
      setChildrenVisible(false);
    }
  }, [store.clear]);

  const toggleModal = (_idMovimento) => {
    if (modalOpen) {
      setIdMovimento(null);
    } else {
      setIdMovimento(_idMovimento);
    }
    setModalOpen(!modalOpen);
  };

  const abrirDetalhes = (siglaOrigem, idOrigem) => {
    dispatch(setIdDetalhes(idOrigem));
    if (["IMT", "COJ", "DJO"].includes(siglaOrigem)) {
      dispatch(setDetalhesContasPagarOpen(true));
    } else if (siglaOrigem === "VFX") {
      dispatch(setDetalhesVendaOpen(true));
    } else if (siglaOrigem === "VOS") {
      dispatch(setDetalhesOrdServOpen(true));
    } else if (siglaOrigem === "NFE") {
      dispatch(setDetalhesNfEntradaOpen(true));
    } else if (["VMO", "VDB", "VDT"].includes(siglaOrigem)) {
      dispatch(setDetalhesVendaProntaEntregaOpen(true));
    } else if (siglaOrigem === "CVJ") {
      dispatch(setDetalhesJornadaDiaOpen(true));
    }
  };

  return (
    <>
      <li className={`${level ? "dre-row--item" : ""}`}>
        <div
          className={`dre-row ${checkPair(index || 0) ? "dre-row--even" : ""} ${
            despesas && checkPair(index || 0) ? "dre-row--despesa" : ""
          }`}
          onClick={toggle}
          onDoubleClick={() => {
            if (row.data.desc_origem_mov) {
              abrirDetalhes(row.data.origem_mov, row.data.id_origem_mov);
            }
          }}
          style={{
            marginLeft: `${2 * level}%`,
          }}
        >
          <div style={{ width: "66.6666%" }}>
            <span style={!row.children ? { textTransform: "capitalize" } : {}}>
              {row.data.historico || "Sem Histórico"}
              {!row.children && (
                <span style={{ marginLeft: "15px" }}>
                  <b>Incluído:</b> {row.data.data}
                </span>
              )}
              {row.data.desc_origem_mov && (
                <>
                  <span style={{ marginLeft: "15px" }}>
                    <b>Origem:</b> {row.data.desc_origem_mov}
                  </span>
                  <span style={{ marginLeft: "15px" }}>
                    <b>Nº:</b> {row.data.id_origem_mov}
                  </span>
                </>
              )}
              {row.possui_dec_acr === true ? (
                <Info
                  size={14}
                  className="ml-1"
                  onClick={() => toggleModal(row.id_mov)}
                />
              ) : null}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="no-gutters"
              style={{
                textAlign: "center",
                width: "100px",
              }}
            >
              <span>
                {row.data.perc_sob_total && (
                  <span style={{ marginRight: "15px", fontSize: "0.75rem" }}>
                    {row.data.perc_sob_total}
                  </span>
                )}
              </span>
            </div>
            <div
              className="no-gutters"
              style={{
                textAlign: "right",
                width: "100px",
              }}
            >
              <span>{row.data.valor}</span>
            </div>
          </div>
        </div>
      </li>
      {childrenVisible && row.children && (
        <Fade>
          <ul className="dre-list">
            {row.children.map((element, index) => {
              return (
                <ExpandableRow
                  despesas={despesas}
                  key={index}
                  row={element}
                  level={(level || 0) + 1}
                  index={index}
                />
              );
            })}
          </ul>
        </Fade>
      )}
      {modalOpen && (
        <MovimentosDescAcreModal
          isOpen={modalOpen}
          toggle={toggleModal}
          idMovimento={idMovimento}
        />
      )}
    </>
  );
};
