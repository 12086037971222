import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imprimir: "P",
  layout_impressao: 1,
  gerar_mov_caixa_loja_sobre: "PED",
  layout_etiqueta: 1,
};

export const pedidoVendaSlice = createSlice({
  name: "pedido_venda_slice",
  initialState: initialState,
  reducers: {
    setupPedidoVenda: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setImprimir: (state, action) => {
      state.imprimir = action.payload;
    },
    setLayoutImpressao: (state, action) => {
      state.layout_impressao = action.payload;
    },
    setGerarMovCaixaLojaSobre: (state, action) => {
      state.gerar_mov_caixa_loja_sobre = action.payload;
    },
    setLayoutEtiqueta: (state, action) => {
      state.layout_etiqueta = action.payload;
    },
  },
});

export const {
  setupPedidoVenda,
  setImprimir,
  setLayoutImpressao,
  setGerarMovCaixaLojaSobre,
  setLayoutEtiqueta,
} = pedidoVendaSlice.actions;

export default pedidoVendaSlice.reducer;
