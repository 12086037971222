import { apiGetV2 } from "../../apiV2";

const URL_BASE = "/tributacao";

const TributacaoService = {
  urlBase: URL_BASE,
  listarCsts: () => apiGetV2(`${URL_BASE}/buscar_csts_icms/`),
  camposAtivosIcms: (cst) =>
    apiGetV2(`${URL_BASE}/buscar_campos_ativos_icms/${cst}/`),
  camposAtivosIcmsNfce: (cst) =>
    apiGetV2(`${URL_BASE}/buscar_campos_ativos_icms_nfce/${cst}/`),
};

export default TributacaoService;
