import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { AiOutlineDollarCircle, AiOutlineUnorderedList } from "react-icons/ai";
import { BsBoxes, BsCalendar3, BsCashStack, BsListCheck } from "react-icons/bs";
import { TbFileDollar, TbReload } from "react-icons/tb";
import {
  duracaoJorandasRoute,
  estoqueAdmProntaEntregaRoute,
  gerenciamentoFlexRoute,
  gerenciamentoVendasProntaEntregaRoute,
  prontaEntregaConferenciaValoresRoute,
  prontaEntregaEmprestimoColabRoute,
  prontaEntregaGerencComissoesRoute,
  solicitacaoRecargaRoute,
} from "../../../routes/modules/pronta_entrega";

export const ProntaEntregaGerenc = () => {
  return (
    <PageContainer title="Pronta Entrega - Gerenciamento">
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento de Vendas"
          icon={AiOutlineUnorderedList}
          pathname={gerenciamentoVendasProntaEntregaRoute.path}
          nroTela={gerenciamentoVendasProntaEntregaRoute.nroTela}
        />
        <MenuGroupButton
          label="Conferência de Valores"
          icon={BsListCheck}
          pathname={prontaEntregaConferenciaValoresRoute.path}
          nroTela={prontaEntregaConferenciaValoresRoute.nroTela}
        />
        <MenuGroupButton
          label="Duração de Jornadas"
          icon={BsCalendar3}
          pathname={duracaoJorandasRoute.path}
          nroTela={duracaoJorandasRoute.nroTela}
        />
        <br />
        <MenuGroupButton
          label="Flex/Bonificação das Rotas"
          icon={TbFileDollar}
          pathname={gerenciamentoFlexRoute.path}
          nroTela={gerenciamentoFlexRoute.nroTela}
        />
        <MenuGroupButton
          label="Comissões"
          icon={AiOutlineDollarCircle}
          pathname={prontaEntregaGerencComissoesRoute.path}
          nroTela={prontaEntregaGerencComissoesRoute.nroTela}
        />
        <MenuGroupButton
          label="Empréstimos"
          icon={BsCashStack}
          pathname={prontaEntregaEmprestimoColabRoute.path}
          nroTela={prontaEntregaEmprestimoColabRoute.nroTela}
        />
        <MenuGroupButton
          label="Unidades de Estoque"
          icon={BsBoxes}
          pathname={estoqueAdmProntaEntregaRoute.path}
          nroTela={estoqueAdmProntaEntregaRoute.nroTela}
        />
        <MenuGroupButton
          label="Solicitação de Recarga"
          icon={TbReload}
          pathname={solicitacaoRecargaRoute.path}
          nroTela={solicitacaoRecargaRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
