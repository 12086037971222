import moment from "moment";
import { sumDataField } from "../../../../../../coreUtils";
import {
  formatDateDDMMAAAA,
  formatNumberField,
  formatRow,
  removeSpecialChars,
  verificaPessoa,
} from "../GerarRemessa";

const rem240HeaderBanrisul = (dadosConta) => [
  ["041", 1, 3], // Código do Banco na Compensação: "041"
  [0, 4, 7], // Lote de Serviço: "0000"
  [0, 8, 8], // Tipo de Registro: "0"
  ["", 9, 17], // Uso Exclusivo FEBRABAN / CNAB: Brancos
  [2, 18, 18], // Tipo de Inscrição da Empresa: '1'  =  CPF '2'  =  CGC / CNPJ
  [formatNumberField(dadosConta.cnpj_empresa), 19, 32], // Número de Inscrição da Empresa
  [removeSpecialChars(dadosConta.cod_cedente).padEnd(20, "0"), 33, 52], // Código do Beneficiário (número fornecido pelo Banco)
  [dadosConta.agencia, 53, 58], // Agência
  [dadosConta.nro_conta, 59, 71], // Conta Corrente
  ["", 72, 72], // Dígito Verificador da Ag/Conta: Brancos
  [removeSpecialChars(dadosConta.nome_empresa), 73, 102], // Nome da Empresa
  ["BANRISUL", 103, 132], // Nome do Banco: BANRISUL
  ["", 133, 142], // Uso Exclusivo FEBRABAN / CNAB: Brancos
  [1, 143, 143], // Código Remessa / Retorno: "1"
  [formatDateDDMMAAAA(new Date()), 144, 151], // Data de Geração do Arquivo
  [moment(new Date()).format("HHmmss"), 152, 157], // Hora de Geração do Arquivo
  [dadosConta.nro_remessa, 158, 163], // Número Seqüencial do Arquivo
  [103, 164, 166], // No da Versão do Layout do Arquivo: "081"
  [0, 167, 171], // Densidade de Gravação do Arquivo: "00000"
  ["", 172, 191], // Para Uso Reservado do Banco: Brancos
  ["", 192, 211], // Para Uso Reservado da Empresa: Brancos
  ["", 212, 240], // Uso Exclusivo FEBRABAN / CNAB: Brancos
];

const rem240HeaderLoteBanrisul = (dadosConta) => [
  ["041", 1, 3], // Código do Banco na Compensação: "041"
  [1, 4, 7], // Número Sequencial do Lote
  [1, 8, 8], // Tipo de Registro: "1"
  ["R", 9, 9], // Tipo de Operação: "R"
  [1, 10, 11], // Tipo de Serviço: "01"
  [1, 12, 13], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [60, 14, 16], // Nº da Versão do Layout do Lote: "060"
  ["", 17, 17], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [2, 18, 18], // Tipo de Inscrição da Empresa: '1'  =  CPF '2'  =  CGC / CNPJ
  [formatNumberField(dadosConta.cnpj_empresa), 19, 33], // Nº de Inscrição da Empresa
  [removeSpecialChars(dadosConta.cod_cedente).padEnd(20, "0"), 34, 53], // Código do Convênio no Banco: Brancos
  [dadosConta.agencia, 54, 59], // Agência
  [dadosConta.nro_conta, 60, 72], // Conta Corrente
  ["", 73, 73], // Dígito Verificador da Ag/Conta: Brancos
  [removeSpecialChars(dadosConta.nome_empresa), 74, 103], // Nome da Empresa
  ["", 104, 143], // Mensagem 1. Brancos
  ["", 144, 183], // Mensagem 2. Brancos
  [0, 184, 191], // Número Remessa/Retorno
  [formatDateDDMMAAAA(new Date()), 192, 199], // Data de Gravação Remessa/Retorno
  [0, 200, 207], // Data do Crédito: "00000000"
  ["", 208, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240SegPBanrisul = (
  dadosConta,
  dup,
  seuNumero,
  vlrPercJuros,
  temDesconto,
  seq
) => [
  ["041", 1, 3], // Código do Banco na Compensação: "041"
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["P", 14, 14], // Cód. Segmento do Registro Detalhe: "P"
  ["", 15, 15], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 16, 17], // Código de Movimento Remessa:  '01' = Entrada de Títulos '02' = Solicitação de Baixa '04' = Concessão de Abatimento '05' = Cancelamento de Abatimento '06' = Prorrogação de Vencimento '09' = Protestar '10' = Desistência do Protesto e Baixar Título '11' = Desistência do Protesto e manter em carteira '12' = Alteração de Juros de Mora '13' = Dispensar Cobrança de Juros de Mora '14' = Alteração de Valor/Percentual de Multa '15' = Dispensar Cobrança de Multa '19' = Prazo limite de recebimento - alterar '20' = Prazo limite de recebimento - dispensar '23' = Alterar dados do pagador '31' = Alterações de outros dados
  [0, 18, 22], // Número da Agência
  ["", 23, 23], // Dígito Verificador da Agência
  [0, 24, 35], // Conta Corrente
  ["", 36, 36], // Dígito Verificador da Conta Corrente
  ["", 37, 37], // Dígito Verificador da Ag/Conta: Brancos
  [dup.nosso_numero.padEnd(20, "0"), 38, 57], // Tipo de cobrança
  [parseInt(dadosConta.tipo_carteira), 58, 58], // Código da Carteira
  [1, 59, 59], // Forma de Cadastr. do Título no Banco: '1' = Com Cadastramento (Cobrança Registrada), '2' = Sem Cadastramento (Cobrança sem Registro) - Obs.: destina-se somente para emissão de Boleto de Pagamento pelo banco (não tratado), '3' = Com Cadastramento / Recusa do Débito Automático (não tratado)
  [1, 60, 60], // Tipo de Documento: '1' = Tradicional; '2' = Escritural
  [2, 61, 61], // Identificação da Emissão do Boleto: '1'  =  Banco Emite '2'  =  Beneficiário Emite
  [2, 62, 62], // Identificação da Distribuição do Boleto: '1'  =  Banco Distribui '2'  =  Beneficiário Distribui
  [seuNumero, 63, 77], // Número do Documento de Cobrança
  [formatDateDDMMAAAA(dup.vencimento), 78, 85], // Data de Vencimento do Título
  [formatNumberField(dup.vlr_titulo), 86, 100], // Valor Nominal do Título
  [0, 101, 105], // Agência Encarregada da Cobrança: "00000"
  ["", 106, 106], // Dígito Verificador da Agência: Brancos
  [dadosConta.especie_tit, 107, 108], // Espécie do Título: '01'  =  CH Cheque '02'  =  DM Duplicata Mercantil '03'  =  DMI Duplicata Mercantil p/ Indicação '04'  =  DS Duplicata de Serviço '05'  =  DSI Duplicata de Serviço p/ Indicação '06'  =  DR Duplicata Rural '07'  =  LC Letra de Câmbio '08'  =  NCC Nota de Crédito Comercial '09'  =  NCE Nota de Crédito a Exportação '10'  =  NCI Nota de Crédito Industrial '11'  =  NCR Nota de Crédito Rural '12'  =  NP Nota Promissória '13'  =  NPR Nota Promissória Rural '14'  =  TM Triplicata Mercantil '15'  =  TS Triplicata de Serviço '16'  =  NS Nota de Seguro '17'  =  RC Recibo '18'  =  FAT Fatura '19'  =  ND Nota de Débito '20'  =  AP Apólice de Seguro '21'  =  ME Mensalidade Escolar '22'  =  PC Parcela de Consórcio '23'  =  NF Nota Fiscal '24'  =  DD Documento de Dívida ‘25’ = Cédula de Produto Rural '31' = Cartão de Crédito '32' = BDP Boleto de Proposta '99'  =  Outros
  ["N", 109, 109], // Identific. de Título Aceito/Não Aceito: Código adotado pela FEBRABAN para identificar se o título de cobrança foi aceito (reconhecimento da dívida pelo Pagador). 'A'  =  Aceite 'N'  =  Não Aceite
  [formatDateDDMMAAAA(dup.emissao), 110, 117], // Data da Emissão do Título
  [dup.tipo_juros_titulo === "V" ? 1 : 2, 118, 118], // Código do Juros de Mora: '0'  =  Isento '1'  =  Valor por Dia '2'  =  Taxa Mensal
  [formatDateDDMMAAAA(dup.data_juros_titulo), 119, 126], // Data indicativa do inicio da cobrança do juros de mora do título. A data informada deverá ser maior que a data de vencimento do título de cobrança. Caso seja inválida, igual a data de vencimento ou não informada será considerada a data do vencimento acrescida de um dia. Utilizar o formato (DD/MM/AAAA), onde: 'DD = Ano' 'MM = Mês'  'AAAA = Ano'.
  [vlrPercJuros, 127, 141], // Juros de Mora por Dia/Taxa ao Mês
  [temDesconto ? 1 : 0, 142, 142], // Código do Desconto 1 '0'  =  Não Conceder desconto '1'  =  Valor Fixo Até a Data Informada '2'  =  Percentual Até a Data Informada
  [temDesconto ? formatDateDDMMAAAA(dup.data_desconto) : 0, 143, 150], // Data do Desconto 1
  [formatNumberField(dup.vlr_desconto), 151, 165], // Valor/Percentual a ser Concedido
  [0, 166, 180], // Valor do IOF a ser Recolhido
  [0, 181, 195], // Valor do Abatimento
  [dup.numero, 196, 220], // Identificação do Título na Empresa: Campo destinado para uso do Beneficiário para identificação do Título.
  [dadosConta.protestar ? 1 : 3, 221, 221], // '1' = Protestar dias corridos '3' = Não Protestar '9' = Cancelar Instrução de Protesto  O código '9' deverá ser utilizado para cancelar um agendamento futuro de protesto e deverá estar atrelado obrigatóriamente ao código de entrada '31'.
  [dadosConta.dias_protesto, 222, 223], // Informar prazo de inicio do protesto a partir do vencimento '0' - Não protestar
  [0, 224, 224], // Código para Baixa/Devolução: "0"
  [0, 225, 227], // Número de Dias para Baixa/Devolução: Brancos
  [9, 228, 229], // Código da Moeda: '09'  = Real
  [805076, 230, 239], // Nº do Contrato da Operação de Crédito. Para Código de carteira = 1: 805076=Cobrança simples com registro, 805157=Cobrança simples seguradoras, 805238=Cobrança simples financeiras, 15470=Cobrança Partilhada, 825468=Cobrança simples dólar; Para Código de carteira = 4: 603015=Desconto de títulos
  [1, 240, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240SegQBanrisul = (dup, enderCliente, seq) => [
  ["041", 1, 3], // Código do Banco na Compensação: "041"
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["Q", 14, 14], // Cód. Segmento do Registro Detalhe: "Q"
  ["", 15, 15], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 16, 17], // Código de Movimento Remessa:  '01' = Entrada de Títulos '02' = Solicitação de Baixa '04' = Concessão de Abatimento '05' = Cancelamento de Abatimento '06' = Prorrogação de Vencimento '09' = Protestar '10' = Desistência do Protesto e Baixar Título '11' = Desistência do Protesto e manter em carteira '12' = Alteração de Juros de Mora '13' = Dispensar Cobrança de Juros de Mora '14' = Alteração de Valor/Percentual de Multa '15' = Dispensar Cobrança de Multa '19' = Prazo limite de recebimento - alterar '20' = Prazo limite de recebimento - dispensar '23' = Alterar dados do pagador '31' = Alterações de outros dados
  [verificaPessoa(dup.cpf_cnpj_cliente), 18, 18], // Pagador - Tipo de Inscrição: '1'  =  CPF '2'  =  CGC / CNPJ
  [formatNumberField(dup.cpf_cnpj_cliente), 19, 33], // Pagador - Número de Inscrição
  [removeSpecialChars(dup.nome_cliente), 34, 73], // Pagador - Nome
  [removeSpecialChars(enderCliente), 74, 113], // Pagador - Endereço
  [removeSpecialChars(dup.bairro_cliente), 114, 128], // Pagador - Bairro
  [formatNumberField(dup.cep_cliente), 129, 136], // Pagador - CEP
  [removeSpecialChars(dup.cidade_cliente), 137, 151], // Pagador - Cidade
  [dup.uf_cliente, 152, 153], // Pagador - UF
  [0, 154, 154], // Sacador/Avalista - Tipo de Inscrição Sacador Avalista: '0' = Isento / Não Informado '1'  =  CPF '2'  =  CGC / CNPJ
  [0, 155, 169], // Sacador/Avalist - Número de Inscrição
  ["", 170, 209], // Sacador/Avalist - Nome do Sacador/Avalista
  [0, 210, 212], // Cód. Bco. Corresp. na Compensação
  ["", 213, 232], // Nosso Nro. Banco Correspondente - O campo NN deve ser preenchido com "000", somente nos casos em que o campo anterior tenha indicado o uso do Banco Correspondente. Obs.: O preenchimento deste campo será alinha à esquerda a partir da posição 213 indo até 219.
  ["", 233, 240], // Uso Exclusivo FEBRABAN/CNAB
];

const rem240SegRBanrisul = (dadosConta, dup, vlrPercMulta, seq) => [
  ["041", 1, 3], // Código do Banco na Compensação: "041"
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["R", 14, 14], // Cód. Segmento do Registro Detalhe: "R"
  ["", 15, 15], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 16, 17], // Código de Movimento Remessa:  '01' = Entrada de Títulos '02' = Solicitação de Baixa '04' = Concessão de Abatimento '05' = Cancelamento de Abatimento '06' = Prorrogação de Vencimento '09' = Protestar '10' = Desistência do Protesto e Baixar Título '11' = Desistência do Protesto e manter em carteira '12' = Alteração de Juros de Mora '13' = Dispensar Cobrança de Juros de Mora '14' = Alteração de Valor/Percentual de Multa '15' = Dispensar Cobrança de Multa '19' = Prazo limite de recebimento - alterar '20' = Prazo limite de recebimento - dispensar '23' = Alterar dados do pagador '31' = Alterações de outros dados
  [0, 18, 18], // Código do Desconto 2 '0'  =  Não Conceder desconto '1'  =  Valor Fixo Até a Data Informada '2'  =  Percentual Até a Data Informada
  [0, 19, 26], // Data do Desconto 2
  [0, 27, 41], // Valor/Percentual a ser Concedido
  [0, 42, 42], // Código do Desconto 3 '0'  =  Não Conceder desconto '1'  =  Valor Fixo Até a Data Informada '2'  =  Percentual Até a Data Informada
  [0, 43, 50], // Data do Desconto 3
  [0, 51, 65], // Valor/Percentual a ser Concedido
  [dadosConta.tipo_multa === "PER" ? 2 : 1, 66, 66], // Código da Multa: '0'  =  Isento '1'  =  Valor Fixo '2'  =  Percentual
  [formatDateDDMMAAAA(dup.data_multa_titulo), 67, 74], // Data indicativa do inicio da cobrança de multa do título. A data informada deverá ser maior que a data de vencimento do título de cobrança. Caso seja inválida, igual a data de vencimento ou não informada será considerada a data do vencimento acrescida de um dia. Utilizar o formato (DD/MM/AAAA), onde: 'DD = Ano' 'MM = Mês'  'AAAA = Ano'.
  [vlrPercMulta, 75, 89], // Valor/Percentual da Multa
  ["", 90, 99], // Informação ao Pagador: Brancos
  ["", 100, 139], // Mensagem 3. Brancos
  ["", 140, 179], // Mensagem 4. Brancos
  ["", 180, 199], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [0, 200, 207], // Data limite de pagamento: "DDMMAAAA"
  [0, 208, 210], // Cód. do Banco na Conta do Débito: "000"
  [0, 211, 215], // Código da Agência do Débito: "00000"
  [0, 216, 216], // Dígito Verificador da Agência: Brancos
  [0, 217, 228], // Conta Corrente para Débito: "000000000000"
  ["", 229, 229], // Dígito Verificador da Conta: Brancos
  ["", 230, 230], // Dígito Verificador Ag/Conta: Brancos
  [0, 231, 231], // Aviso para Débito Automático: "0"
  ["", 232, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240TrailerLoteBanrisul = (
  dadosConta,
  registrosLote,
  qtdTitulos,
  totalTitulos
) => [
  ["041", 1, 3], // Código do Banco na Compensação: "041"
  [1, 4, 7], // Número Sequencial do Lote
  [5, 8, 8], // Tipo de Registro: "5"
  ["", 9, 17], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [registrosLote, 18, 23], // Quantidade de Registros no Lote
  [dadosConta.tipo_carteira === "1" ? qtdTitulos : 0, 24, 29], // Cobrança Simples - Quantidade de Títulos em Cobrança
  [dadosConta.tipo_carteira === "1" ? totalTitulos : 0, 30, 46], // Cobrança Simples - Valor Total dos Títulos em Carteiras
  [dadosConta.tipo_carteira === "2" ? qtdTitulos : 0, 47, 52], // Cobrança Vinculada - Quantidade de Títulos em Cobrança
  [dadosConta.tipo_carteira === "2" ? totalTitulos : 0, 53, 69], // Cobrança Vinculada - Valor Total dos Títulos em Carteiras
  [dadosConta.tipo_carteira === "3" ? qtdTitulos : 0, 70, 75], // Cobrança Caucionada - Quantidade de Títulos em Cobrança
  [dadosConta.tipo_carteira === "3" ? qtdTitulos : 0, 76, 92], // Cobrança Caucionada - Quantidade de Títulos em Carteiras
  [dadosConta.tipo_carteira === "4" ? qtdTitulos : 0, 93, 98], // Cobrança Descontada - Quantidade de Títulos em Cobrança
  [dadosConta.tipo_carteira === "4" ? totalTitulos : 0, 99, 115], // Cobrança Descontada - Valor Total dosTítulos em Carteiras
  ["", 116, 123], // Número do Aviso de Lançamento: Brancos
  ["", 124, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240TrailerBanrisul = (totalRegistros) => [
  ["041", 1, 3], // Código do Banco na Compensação: "041"
  [9999, 4, 7], // Preencher com '9999'.
  [9, 8, 8], // Tipo de Registro: "9"
  ["", 9, 17], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 18, 23], // Quantidade de Lotes do Arquivo
  [totalRegistros, 24, 29], // Quantidade de Registros do Arquivo
  [0, 30, 35], // Qtde de Contas p/ Conc. (Lotes): "000000"
  ["", 36, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

export const nomeArq240Banrisul = (dadosConta) =>
  "E00" + moment(new Date()).format("DD") + dadosConta.nro_remessa + ".240";

export const layout240Banrisul = (dadosConta, duplicatas) => {
  const vlrPercMulta = formatNumberField(
    dadosConta.tipo_multa === "PER"
      ? dadosConta.perc_multa
      : dadosConta.vlr_multa
  );

  dadosConta.nro_conta = removeSpecialChars(dadosConta.nro_conta).padStart(
    16,
    "0"
  );

  const header = "".concat(
    formatRow(...rem240HeaderBanrisul(dadosConta)),
    formatRow(...rem240HeaderLoteBanrisul(dadosConta))
  );

  const detalhes = "".concat(
    ...duplicatas.map((dup, index) => {
      const seuNumero = `${dup.numero}${dup.parcela}`;

      const enderCliente =
        dup.endereco_cliente + " " + dup.nro_endereco_cliente;

      const vlrPercJuros = formatNumberField(
        dup.tipo_juros_titulo === "V"
          ? dup.vlr_juros_titulo
          : dup.perc_juros_titulo
      );

      return (
        formatRow(
          ...rem240SegPBanrisul(
            dadosConta,
            dup,
            seuNumero,
            vlrPercJuros,
            parseFloat(dup.vlr_desconto) > 0,
            index * 3 + 1
          )
        ) +
        formatRow(...rem240SegQBanrisul(dup, enderCliente, index * 3 + 2)) +
        formatRow(
          ...rem240SegRBanrisul(dadosConta, dup, vlrPercMulta, index * 3 + 3)
        )
      );
    })
  );

  const trailer = "".concat(
    formatRow(
      ...rem240TrailerLoteBanrisul(
        dadosConta,
        duplicatas.length * 3 + 2,
        duplicatas.length,
        formatNumberField(
          sumDataField(duplicatas, "vlr_titulo")
            .toFixed(2)
            .toString()
        )
      )
    ),
    formatRow(...rem240TrailerBanrisul(duplicatas.length * 3 + 4))
  );

  return header + detalhes + trailer;
};
