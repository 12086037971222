import React from "react";
import { formatNumber } from "../../../../../coreUtils";
import { Table } from "../../../../../components";

const columns = [
  {
    dataField: "data_presta",
    text: "Data",
    align: "center",
  },
  {
    dataField: "id_despesa__nome",
    text: "Despesa",
    align: "left",
  },
  {
    dataField: "nome_fornecedor",
    text: "Fornecedor",
    align: "left",
  },
  {
    dataField: "numero",
    text: "Número",
    align: "center",
  },
  {
    dataField: "forma_pagamento",
    text: "Forma de Pagamento",
    align: "center",
  },
  {
    dataField: "valor",
    text: "Valor (R$)",
    align: "right",
    formatter: (value) => formatNumber(value),
  },
];

export const PrestacaoContasGrid = ({ dados }) => {
  return (
    <Table
      columns={columns}
      data={dados}
      paginated={false}
      pageSize={dados.length > 5 ? 5 : dados.length}
      showRegisterCount={false}
    />
  );
};
