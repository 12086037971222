import React, { useState } from "react";
import {
  DatePicker,
  FixedField,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  LabelButton,
  ModalBase,
  Table,
  TextInput,
  UnlockToEdit,
} from "../../../../../components";
import { Col, Row } from "reactstrap";
import moment from "moment";
import {
  formatDateISO,
  formatDateLocal,
  formatNumber,
  sumDataField,
} from "../../../../../coreUtils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addRecebCrediario } from "../store/moedas_rec_slice";
import { apiGet } from "../../../../../api";
import CaixaLojaService from "../../../../../services/financeiro/CaixaLojaService";
import { cadastroClienteRoute } from "../../../../../routes/modules/cadastro";
import { showWarning } from "../../../../../components/AlertaModal";
import UteisService from "../../../../../services/uteis/UteisService";

const rowStyle = { justifyContent: "center" };

export const RecCrediarioModal = ({
  idMovCaixaLoja,
  vlrCrediario,
  isOpen,
  toggle,
}) => {
  const [qtdParcelas, setQtdParcelas] = useState(0);
  const [dataBase, setDataBase] = useState(new Date());
  const [diaFixo, setDiaFixo] = useState(false);
  const [numDias, setNumDias] = useState(30);
  const [carteira, setCarteira] = useState(false);
  const [parcelas, setParcelas] = useState([]);
  const [numero, setNumero] = useState("");

  const [nomeCliente, setNomeCliente] = useState("");
  const [idCliente, setIdCliente] = useState(0);
  const [enderecoCliente, setEnderecoCliente] = useState("");
  const [foneCliente, setFoneCliente] = useState("");
  const [cpfCnpjCli, setCpfCnpjCli] = useState("");

  const [aceitaCredSemCpfCnpj, setAceitaCredSemCpfCnpj] = useState(false);
  const [aplicaDiferencaParcela, setAplicaDiferencaParcela] = useState("P");
  const [loadingParcelas, setLoadingParcelas] = useState(false);

  const carregarParametros = async () => {
    const ret = await apiGet("/tela/caixa_loja/receb_crediario/", {});

    setAceitaCredSemCpfCnpj(ret?.aceita_cred_sem_cpf_cnpj ?? false);
    setAplicaDiferencaParcela(ret?.aplica_diferenca_parcela ?? "P");
  };

  const carregarDados = async () => {
    const [ok, ret] = await CaixaLojaService.buscarDadosMovReceber(
      idMovCaixaLoja
    );

    if (ok) {
      setNomeCliente(ret.nome_cli);
      setIdCliente(ret.id_cli);
      let enderecoCli = `${ret.endereco_cli}, ${ret.numero_cli}`;
      if (ret.complemento_cli) {
        enderecoCli = `${enderecoCli}, ${ret.complemento_cli}`;
      }
      if (ret.bairro_cli) {
        enderecoCli = `${enderecoCli}, ${ret.bairro_cli}`;
      }
      if (ret.cidade_cli) {
        enderecoCli = `${enderecoCli} - ${ret.cidade_cli}/${ret.uf_cli}`;
      }
      setEnderecoCliente(enderecoCli);
      setFoneCliente(ret.telefone_cli);
      setCpfCnpjCli(ret.cpf_cnpj_cli);
    }
  };

  const dispatch = useDispatch();

  const calcularParcelas = async () => {
    if ([0, null, undefined].includes(parcelas)) {
      showWarning("Por favor, informe o número de Parcelas");
      return false;
    }

    if ([0, null, undefined].includes(numDias)) {
      showWarning(
        `Por favor, informe o ${diaFixo ? "Dia Fixo" : "Intervalo de Dias"}`
      );
      return false;
    }

    if (!(dataBase instanceof Date)) {
      showWarning("Por favor, informe a Data Base");
      return false;
    }

    setLoadingParcelas(true);

    const payload = {
      qtd_parcelas: qtdParcelas,
      num_dias: numDias,
      dia_fixo: diaFixo,
      valor: vlrCrediario,
      data_base: formatDateISO(dataBase),
      aplicar_diferenca: aplicaDiferencaParcela,
    };

    const [ok, ret] = await UteisService.calcularParcelasPorQtdParcNumDias(
      payload
    );
    if (ok) {
      const faturasDepois = ret.map((e, index) => ({
        id: index,
        parcela: index + 1,
        vencimento: formatDateLocal(e.data_vcto),
        valor: parseFloat(e.valor),
      }));

      setParcelas(faturasDepois);
    }
    setLoadingParcelas(false);
  };

  const handleEditValorParc = (coluna, novoValor, row) => {
    row.valor = novoValor;
    setParcelas(parcelas.map((e) => (e.parcela === row.parcela ? row : e)));
  };

  const handleEditDataParc = (_, newValue, row) => {
    const value = moment(newValue, "DD/MM/YYYY", true);
    if (value.isValid()) {
      setParcelas(
        parcelas.map((e) =>
          e.parcela === row.parcela
            ? { ...row, vencimento: value.format("DD/MM/YYYY") }
            : e
        )
      );
    }
  };

  const onConfirm = () => {
    if (parcelas.length === 0) {
      showWarning(
        "Por favor, clique no botão Calcular Sugestão para gerar as parcelas do crediário e tente novamente"
      );
      return;
    }

    const payload = {
      id_cliente: idCliente,
      carteira: carteira,
      numero: numero,
      parcelas: parcelas.map((e) => ({
        parcela: e.parcela,
        numero: numero,
        data_vcto: formatDateISO(
          moment(e.vencimento, "DD/MM/YYYY", true).toDate()
        ),
        valor: e.valor,
      })),
    };
    dispatch(addRecebCrediario({ valor: vlrCrediario, dados_rec: payload }));
    toggle();
  };

  const columns = [
    {
      dataField: "parcela",
      text: "Parcela",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vencimento",
      text: "Vencimento",
      align: "center",
      headerAlign: "center",
      editable: true,
      editorType: "date",
      onChange: handleEditDataParc,
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      editable: true,
      editorType: "number",
      decimalPlaces: 2,
      onChange: handleEditValorParc,
    },
  ];

  const onKeyDown = (e) => {
    if (e.key === "F4") {
      calcularParcelas();
    }
  };

  useEffect(() => {
    setParcelas([]);
  }, [qtdParcelas, dataBase, diaFixo, numDias]);

  const limparTela = () => {
    setParcelas([]);
    setQtdParcelas(0);
    setDataBase(new Date());
    setDiaFixo(false);
    setCarteira(false);
    setNumDias(30);
    setNumero("");
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Recebimento em Crediário"
      number="0063_13"
      onConfirm={onConfirm}
      onClosed={limparTela}
      bodyStyle={{ paddingTop: "0.3rem" }}
      onKeyDown={onKeyDown}
      onBeforeOpen={() => {
        carregarParametros();
        carregarDados();
      }}
    >
      <Row>
        <Col md="auto">
          <Row className="mb-2">
            <FixedField
              label="Cliente"
              value={`${nomeCliente} (${idCliente})`}
              horizontal
            />
          </Row>
          <Row className="mb-2">
            <FixedField label="Endereço" value={enderecoCliente} horizontal />
          </Row>
          <Row className="mb-2">
            <FixedField
              divStyle={{
                backgroundColor:
                  !aceitaCredSemCpfCnpj && !cpfCnpjCli
                    ? "yellow"
                    : "transparent",
              }}
              label="CPF/CNPJ"
              value={cpfCnpjCli}
              horizontal
              md={!aceitaCredSemCpfCnpj && !cpfCnpjCli ? "4" : "auto"}
            />
            <FixedField label="Telefone" value={foneCliente} horizontal />
          </Row>
        </Col>
        <LabelButton
          md="auto"
          divClassName="ml-auto"
          onClick={() => window.open(cadastroClienteRoute.path)}
          tabIndex={7}
          style={{ alignSelf: "flex-start" }}
        >
          Cadastro de Cliente
        </LabelButton>
      </Row>
      <Row>
        <Col md={6}>
          <Row style={rowStyle}>
            <TextInput
              md={4}
              label="Número"
              value={numero}
              onChange={(e, v) => setNumero(v)}
              maxLength={10}
              inputStyle={{ textAlign: "center" }}
            />
          </Row>
          <Row style={rowStyle}>
            <IntegerFormInput
              md={4}
              label="Parcelas"
              defaultValue={qtdParcelas}
              onChange={setQtdParcelas}
            />
          </Row>
          <Row style={rowStyle}>
            <IntegerFormInput
              md={4}
              label={diaFixo ? "Dia Fixo" : "Intervalo de Dias"}
              defaultValue={numDias}
              onChange={setNumDias}
            />
          </Row>
          <Row style={rowStyle}>
            <UnlockToEdit>
              <DatePicker
                label="Data Base"
                md={4}
                value={dataBase}
                onChange={(v) =>
                  setDataBase(moment.isMoment(v) ? v.toDate() : v)
                }
              />
            </UnlockToEdit>
          </Row>
          <Row style={rowStyle}>
            <FormCheckbox
              md={4}
              divClassName="mt-1 mb-3"
              padded={false}
              label="Dia Fixo"
              checked={diaFixo}
              onChange={() => setDiaFixo(!diaFixo)}
              name="dia-fixo"
            />
          </Row>
          <Row style={rowStyle}>
            <FormCheckbox
              md={4}
              divClassName="mt-1 mb-3"
              padded={false}
              label="Carteira"
              checked={carteira}
              onChange={() => setCarteira(!carteira)}
              name="carteira"
            />
          </Row>
          <Row style={rowStyle}>
            <FormButton
              md="auto"
              padded={false}
              color="primary"
              disabled={
                [0, null, undefined].includes(parcelas) ||
                [0, null, undefined].includes(numDias) ||
                !(dataBase instanceof Date)
              }
              disabledHint={
                [0, null, undefined].includes(parcelas)
                  ? "Informe a quantidade de Parcelas"
                  : [0, null, undefined].includes(numDias)
                  ? `Informe o ${diaFixo ? "Dia Fixo" : "Intervalo de Dias"}`
                  : !(dataBase instanceof Date) &&
                    "Informe a data do Primeiro Vencimento"
              }
              loading={loadingParcelas}
              onClick={calcularParcelas}
            >
              F4 - Calcular Sugestão
            </FormButton>
          </Row>
        </Col>
        <Col md={6}>
          <Table
            data={parcelas}
            columns={columns}
            paginated={false}
            pageSize={10}
            showRegisterCount={false}
          />
          <Row style={{ justifyContent: "space-between" }}>
            <FixedField
              label="Valor a Cobrar"
              value={formatNumber(vlrCrediario, true, 2)}
              horizontal
            />
            <FixedField
              label="Valor Lançado"
              value={formatNumber(sumDataField(parcelas, "valor"), true, 2)}
              horizontal
            />
          </Row>
        </Col>
      </Row>
    </ModalBase>
  );
};
