import React from "react";
import { TableCheck } from "../../../../../components/TableCheck";
import { Table } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const DespesaJornadaGrid = ({
  dados,
  handleSelect,
  alterar,
  excluir,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "nome",
      text: "Despesa",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "id_plano_contas",
      text: "ID Plano de Contas",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_plano_contas__descricao",
      text: "Plano de Contas",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      onSelect={handleSelect}
      data={dados}
      columns={columns}
      paginated={false}
      pageSize={10}
      onRowDoubleClick={alterar}
    />
  );
};
