import React from "react";
import { DataTable } from "../../../../../components/DataTable";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { TableCheck } from "../../../../../components/TableCheck";
import { DetalheEmprestimoModal } from "./DetalheEmprestimoModal";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
  },
  {
    dataField: "vlr_total",
    text: "Valor Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "aberto",
    text: "Aberto",
    align: "center",
    headerAlign: "center",
    formatter: (c) => <TableCheck value={c} />,
  },
  {
    dataField: "act",
    text: "",
    isDummyField: true,
    align: "center",
    headerAlign: "center",
    formatter: (c, row) => <DetalheEmprestimoModal id={row.id} />,
  },
];

export const EmprestimoColaboradorGrid = ({ data, setSelected }) => {
  return (
    <DataTable data={data} columns={columns} onSelect={setSelected} paginated />
  );
};
