import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import { ComboBox, TextInput } from "../../../../components";
import { ModalCadastro } from "../../../../components/ModalCadastro";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import CadastroCampoPersonService from "../../../../services/cadastro/CadastroCampoPersonService";

export const tiposCampo = [
  {
    label: "Texto",
    value: "TEXT",
  },
  {
    label: "Checkbox",
    value: "CHECK",
  },
];

export const CadastroCamposPersonModal = ({
  selected,
  notifyEvent,
  tabela,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [nomeTabela, setNomeTabela] = useState("");
  const [tipo, setTipo] = useState("TEXT");

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setNomeTabela("");
    setTipo("TEXT");
  };

  const buscarDados = async () => {
    const ret = await CadastroCampoPersonService.buscar(selected);
    setNome(ret["nome_campo"]);
    setNomeTabela(ret["tabela"]);
    setTipo(ret["tipo_campo"]);
    setAtivo(ret["ativo"]);
  };

  const submitPayload = (action) => {
    if (!nome && nome.length <= 0) {
      toastr.warning("Atenção", "Por favor, informe um nome válido.");
      return false;
    }
    const data = {
      tabela: nomeTabela,
      nome_campo: nome,
      tipo_campo: tipo,
      ativo: ativo,
    };

    return action === MODAL_ACTIONS.ADD ? data : { ...data, id: selected };
  };

  const onOpen = (action) =>
    action === MODAL_ACTIONS.EDIT ? buscarDados() : setNomeTabela(tabela);

  const onClose = () => limparDados();

  return (
    <ModalCadastro
      title="Campos Personalizados"
      onOpen={onOpen}
      onClose={onClose}
      size="md"
      submitPayload={(a) => submitPayload(a)}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routes={{
        post: `${CadastroCampoPersonService.urlBase}/incluir/`,
        put: `${CadastroCampoPersonService.urlBase}/alterar/`,
        delete: `${CadastroCampoPersonService.urlBase}/excluir/${selected}/`,
      }}
      number="0041_1"
      deleteModalNumber="0041_2"
      deleteMessage="Tem certeza de que deseja excluir o campo selecionado?"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          md={9}
          onChange={(e, v) => setNome(v)}
          value={nome}
          maxLength={100}
          autoFocus
        />
        <ComboBox
          md={3}
          label="Tipo"
          options={tiposCampo}
          onChange={(s) => setTipo(s?.value)}
          defaultValue={tipo}
        />
      </Row>
    </ModalCadastro>
  );
};
