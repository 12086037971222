import React from "react";
import { useState } from "react";
import moment from "moment";
import {
  AsyncComboBox,
  DatePicker,
  MaskedInput,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { RadioItem } from "../../../../../components/RadioGroup";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleColaborador, routesBaseColaborador } from "../Colaborador";

export const CadastroColaboradorModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  // const [nickname, setNickname] = useState("");
  const [perfil, setPerfil] = useState(null);
  const [cpf, setCpf] = useState("");
  const [idCidade, setIdCidade] = useState(null);
  const [endereco, setEndereco] = useState("");
  const [nroEndereco, setNroEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [fone, setFone] = useState("");
  const [sexo, setSexo] = useState("M");
  const [dataNasc, setDataNasc] = useState(null);
  const [email, setEmail] = useState("");
  const [idTerminal, setIdTerminal] = useState(null);
  // const [temAcessoWeb, setTemAcessoWeb] = useState(false);
  // const [temAcessoAppVendaDireta, setTemAcessoAppVendaDireta] = useState(false);
  // const [temAcessoAppPedidoVda, setTemAcessoAppPedidoVda] = useState(false);
  // const [temAcessoAppGerencial, setTemAcessoAppGerencial] = useState(false);
  // const [siglaEmpresa, setSiglaEmpresa] = useState(null);
  // const [appVendaDiretaContratado, setAppVendaDiretaContratado] =
  //   useState(false);
  // const [appPedidoVdaContratado, setAppPedidoVdaContratado] = useState(false);
  // const [appGerencialContratado, setAppGerencialContratado] = useState(false);

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    // setNickname("");
    setPerfil(null);
    setCpf("");
    setIdCidade(null);
    setEndereco("");
    setNroEndereco("");
    setBairro("");
    setFone("");
    setSexo("M");
    setDataNasc(null);
    setEmail("");
    setIdTerminal(null);
    // setTemAcessoWeb(false);
    // setTemAcessoAppVendaDireta(false);
    // setTemAcessoAppPedidoVda(false);
    // setTemAcessoAppGerencial(false);
    // setSiglaEmpresa(null);
    // setAppVendaDiretaContratado(false);
    // setAppPedidoVdaContratado(false);
    // setAppGerencialContratado(false);
  };

  const fetchData = (data) => {
    setAtivo(data?.ativo);
    setNome(data?.nome);
    setPerfil(data?.perfil);
    setCpf(data?.cpf);
    setIdCidade(data?.id_cidade);
    setEndereco(data?.endereco);
    setNroEndereco(data?.nro_endereco);
    setBairro(data?.bairro);
    setFone(data?.fone);
    setSexo(data?.sexo);
    setDataNasc(moment(data?.data_nasc));
    setEmail(data?.email);
    setIdTerminal(data?.id_terminal);
  };

  const submitPayload = (action) => {
    debugger;
    const payload = {
      nome: nome,
      // nickname: nickname,
      id_perfil: perfil,
      fone: fone ?? "",
      cpf: cpf ?? "",
      sexo: sexo,
      email: email ?? "",
      // Esse inline-or está aqui para limpar as cidades que estão registradas
      // como 0 (zero) no banco de dados. Resquício do Delphi
      id_cidade: idCidade || null,
      endereco: endereco ?? "",
      nro_endereco: nroEndereco ?? "",
      bairro: bairro ?? "",
      data_nasc: moment(dataNasc).isValid()
        ? moment(dataNasc).format("YYYY-MM-DD")
        : null,
      id_terminal: idTerminal, // Revisar
      // tem_acesso_app_venda_direta: temAcessoAppVendaDireta,
      // tem_acesso_app_pedido_vda: temAcessoAppPedidoVda,
      // tem_acesso_web: temAcessoWeb,
      // tem_acesso_app_gerencial: temAcessoAppGerencial,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_colaborador: selected, ...payload, ativo: ativo };
  };

  const onOpen = (_, params) => {
    // setSiglaEmpresa(params.sigla_empresa ?? "");
    // setAppVendaDiretaContratado(params.app_venda_dir_contratado);
    // setAppPedidoVdaContratado(params.app_ped_vda_contratado);
    // setAppGerencialContratado(params.app_gerencial_contratado);
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleColaborador}
      size="lg"
      onOpen={onOpen}
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseColaborador}
      number="0058_1"
      selected={selected}
      notifyEvent={notifyEvent}
      paramsName="cad_colaborador"
    >
      <Row>
        <TextInput
          md={8}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={40}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Perfil"
          isConcatField
          concatModelName="perfil"
          isSearchable
          isClearable={false}
          onChange={(s) => setPerfil(s?.value)}
          defaultValue={perfil}
          defaultOptions
        />
        {/* <TextInput
          md={4}
          label="Nickname"
          value={nickname}
          onChange={(e, v) => setNickname(v)}
          disabled={action === MODAL_ACTIONS.EDIT}
          addonText={action === MODAL_ACTIONS.ADD ? siglaEmpresa : ""}
          addonType="append"
          forceUppercase={false}
        /> */}
        {/* {action === MODAL_ACTIONS.EDIT && (
          <FormButton md="2" color="secondary">
            Alterar Senha
          </FormButton>
        )} */}
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Endereco"
          value={endereco}
          onChange={(e, v) => setEndereco(v)}
          maxLength={40}
        />
        <TextInput
          md={2}
          label="Número"
          value={nroEndereco}
          onChange={(e, v) => setNroEndereco(v)}
          maxLength={10}
        />
        <TextInput
          md={4}
          label="Bairro"
          value={bairro}
          onChange={(e, v) => setBairro(v)}
          maxLength={25}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={4}
          label="Cidade"
          isConcatField
          concatModelName="cidade"
          isSearchable
          isClearable
          onChange={(s) => setIdCidade(s?.value ?? 0)}
          defaultValue={idCidade}
        />
        <TextInput
          md={3}
          label="Fone"
          value={fone}
          onChange={(e, v) => setFone(v)}
          maxLength={18}
        />
        <TextInput
          md={5}
          label="E-Mail"
          value={email}
          onChange={(e, v) => setEmail(v)}
          maxLength={150}
        />
      </Row>
      <Row>
        <MaskedInput
          md={3}
          label="CPF"
          value={cpf}
          onChange={(e, v) => setCpf(v)}
          mask="999.999.999-99"
        />
        <RadioGroup label="Sexo" value={sexo} onChange={setSexo}>
          <RadioItem label="Masculino" value="M" />
          <RadioItem label="Feminino" value="F" />
        </RadioGroup>
        <DatePicker
          md={3}
          label="Data de Nascimento"
          value={dataNasc}
          onChange={(v) => setDataNasc(moment.isMoment(v) ? v.toDate() : v)}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={5}
          label="Terminal"
          isConcatField
          concatModelName="terminal"
          isSearchable
          onChange={(s) => setIdTerminal(s?.value)}
          defaultValue={idTerminal}
          defaultOptions
        />
      </Row>
      {/* <Row>
        <FormCheckbox
          label="Usuário tem Acesso ao Sistema Web"
          checked={temAcessoWeb}
          onChange={() => setTemAcessoWeb(!temAcessoWeb)}
        />
        <FormCheckbox
          label="Usuário tem Acesso ao APP de Pronta Entrega"
          checked={temAcessoAppVendaDireta}
          onChange={() => setTemAcessoAppVendaDireta(!temAcessoAppVendaDireta)}
          disabled={!appVendaDiretaContratado}
          disabledHint="Módulo não contratado"
        />
        <FormCheckbox
          label="Usuário tem Acesso ao APP de Pedidos de Venda"
          checked={temAcessoAppPedidoVda}
          onChange={() => setTemAcessoAppPedidoVda(!temAcessoAppPedidoVda)}
          disabled={!appPedidoVdaContratado}
          disabledHint="Módulo não contratado"
        />
        <FormCheckbox
          label="Usuário tem Acesso ao APP Gerencial"
          checked={temAcessoAppGerencial}
          onChange={() => setTemAcessoAppGerencial(!temAcessoAppGerencial)}
          disabled={!appGerencialContratado}
          disabledHint="Módulo não contratado"
        />
      </Row> */}
    </ModalCadastroV2>
  );
};
