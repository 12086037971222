import { apiGetV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "plano_contas";
const PlanoContasService = {
  listar: (params) =>
    apiGetV2(
      `${URL_BASE}/listar/`, params, 
    ),
  pesquisar: (params) =>
    apiGetV2(
      `${URL_BASE}/pesquisa/`, params, 
    ),
  buscar: async (id) =>
    await apiGetV2(
      `${URL_BASE}/buscar/${id}/`
    ),
  alterar: (payload) =>
    apiPutV2(`${URL_BASE}/alterar/`, payload),
  excluir: async (id) =>
    await apiPutV2(`${URL_BASE}/excluir/${id}/`),
  incluir: (payload) =>
    apiPutV2(`${URL_BASE}/incluir/`, payload),
};

export default PlanoContasService;
