import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ident_representante: false,
  info_dados_adic_1: null,
  info_dados_adic_2: null,
  info_dados_adic_3: null,
  info_dados_adic_4: null,
  info_dados_adic_5: null,
  ident_fatura_por: "QTP",
  copia_email_emitente: false,
  email_copia_emitente: null,
  focar_descricao_prod: false,
  perm_alt_unid_med: false,
  camin_salvar_xml: null,
  ult_nro_lote: null,
  define_data_hora_saida: false,
  visualizar_danfe_dep_emi: "P",
  impr_cod_prod: "COD",
  inc_prod_suframa: false,
  id_tipo_doc_padrao: null,
  ident_codigo_destinatario_nfe: true,
  identifica_cor: false,
  num_casas_decimais_quantidade: 2,
  num_casas_decimais_valor: 4,
  emite_nfe_credito_diferimento: false,
  id_produto_nfe_credito_diferimento: null,
  id_cliente_nfe_credito_diferimento: null,
  id_tipo_doc_nfe_credito_diferimento: null,
  sugerir_auto_qtde_volumes: false,
};

export const nfEletronicaSlice = createSlice({
  name: "nf_eletronica",
  initialState: initialState,
  reducers: {
    setupNfEletronica: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (
          [null, undefined].includes(value) &&
          ["focar_descricao_prod", "define_data_hora_saida"].includes(key)
        ) {
          value = false;
        }

        state[key] = value;
      });
      return state;
    },
    setIdentRepresentante: (state, action) => {
      state.ident_representante = action.payload;
    },
    setInfoDadosAdic1: (state, action) => {
      state.info_dados_adic_1 = action.payload;
    },
    setInfoDadosAdic2: (state, action) => {
      state.info_dados_adic_2 = action.payload;
    },
    setInfoDadosAdic3: (state, action) => {
      state.info_dados_adic_3 = action.payload;
    },
    setInfoDadosAdic4: (state, action) => {
      state.info_dados_adic_4 = action.payload;
    },
    setInfoDadosAdic5: (state, action) => {
      state.info_dados_adic_5 = action.payload;
    },
    setIdentFaturaPor: (state, action) => {
      state.ident_fatura_por = action.payload;
    },
    setCopiaEmailEmitente: (state, action) => {
      state.copia_email_emitente = action.payload;
    },
    setEmailCopiaEmitente: (state, action) => {
      state.email_copia_emitente = action.payload;
    },
    setFocarDescricaoProd: (state, action) => {
      state.focar_descricao_prod = action.payload;
    },
    setPermAltUnidMed: (state, action) => {
      state.perm_alt_unid_med = action.payload;
    },
    setCaminSalvarXml: (state, action) => {
      state.camin_salvar_xml = action.payload;
    },
    setUltNroLote: (state, action) => {
      state.ult_nro_lote = action.payload;
    },
    setDefineDataHoraSaida: (state, action) => {
      state.define_data_hora_saida = action.payload;
    },
    setVisualizarDanfeDepEmi: (state, action) => {
      state.visualizar_danfe_dep_emi = action.payload;
    },
    setImprCodProd: (state, action) => {
      state.impr_cod_prod = action.payload;
    },
    setIncProdSuframa: (state, action) => {
      state.inc_prod_suframa = action.payload;
    },
    setIdTipoDocPadrao: (state, action) => {
      state.id_tipo_doc_padrao = action.payload;
    },
    setIdentCodigoDestinatarioNfe: (state, action) => {
      state.ident_codigo_destinatario_nfe = action.payload;
    },
    setIdentificaCor: (state, action) => {
      state.identifica_cor = action.payload;
    },
    setNumCasasDecimaisQuantidade: (state, action) => {
      state.num_casas_decimais_quantidade = action.payload;
    },
    setNumCasasDecimaisValor: (state, action) => {
      state.num_casas_decimais_valor = action.payload;
    },
    setEmiteNfeCreditoDiferimento: (state, action) => {
      state.emite_nfe_credito_diferimento = action.payload;
    },
    setIdProdutoNfeCreditoDiferimento: (state, action) => {
      state.id_produto_nfe_credito_diferimento = action.payload;
    },
    setIdClienteNfeCreditoDiferimento: (state, action) => {
      state.id_cliente_nfe_credito_diferimento = action.payload;
    },
    setIdTipoDocNfeCreditoDiferimento: (state, action) => {
      state.id_tipo_doc_nfe_credito_diferimento = action.payload;
    },
    setSugerirAutoQtdeVolumes: (state, action) => {
      state.sugerir_auto_qtde_volumes = action.payload;
    },
  },
});

export const {
  setupNfEletronica,
  setIdentRepresentante,
  setInfoDadosAdic1,
  setInfoDadosAdic2,
  setInfoDadosAdic3,
  setInfoDadosAdic4,
  setInfoDadosAdic5,
  setIdentFaturaPor,
  setCopiaEmailEmitente,
  setEmailCopiaEmitente,
  setFocarDescricaoProd,
  setPermAltUnidMed,
  setCaminSalvarXml,
  setUltNroLote,
  setDefineDataHoraSaida,
  setVisualizarDanfeDepEmi,
  setImprCodProd,
  setIncProdSuframa,
  setIdTipoDocPadrao,
  setIdentCodigoDestinatarioNfe,
  setIdentificaCor,
  setNumCasasDecimaisQuantidade,
  setNumCasasDecimaisValor,
  setEmiteNfeCreditoDiferimento,
  setIdProdutoNfeCreditoDiferimento,
  setIdClienteNfeCreditoDiferimento,
  setIdTipoDocNfeCreditoDiferimento,
  setSugerirAutoQtdeVolumes,
} = nfEletronicaSlice.actions;

export default nfEletronicaSlice.reducer;
