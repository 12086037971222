import moment from "moment";
import React, { useRef, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  PageContainer,
} from "../../../../components";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import GerarNfVendaOsService from "../../../../services/faturamento/GerarNfVendaOsService";
import { GerarNfVendaOsGrid } from "./components/GerarNfVendaOsGrid";
import { ReabrirGerarNfModal } from "./components/ReabrirGerarNfModal";
import { DefineNaoGeraNfNfseModal } from "./components/DefineNaoGeraNfModal";
import { infoNfFaturadaRoute } from "../../../../routes/modules/faturamento";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GerarNFServicoModal } from "./components/GerarNFServicoModal";

export const GerarNfVendaOs = () => {
  const [cliente, setCliente] = useState(null);
  const [sequencia, setSequencia] = useState(null);
  const [mostrarFaturadas, setMostrarFaturadas] = useState(false);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const reabrirFatRef = useRef();
  const definirNaoGeraNfRet = useRef();

  const handleSelectDate = async (_dataIni, _dataFim) => {
    setDataIni(_dataIni);
    setDataFim(_dataFim);
  };

  const listarDados = async () => {
    const ret = await GerarNfVendaOsService.listar(
      mostrarFaturadas,
      moment(dataIni).format("YYYY-MM-DD"),
      moment(dataFim).format("YYYY-MM-DD"),
      sequencia,
      cliente
    );
    setDados(ret);
  };

  const handleSearch = async () => {
    setLoading(true);
    await listarDados();
    setLoading(false);
  };

  const notifyEvent = () => {
    setSelected([]);
    listarDados();
  };

  const reabrirFat = (idSelect, tipoSelect) => {
    reabrirFatRef.current.toggle(idSelect, tipoSelect);
  };

  const defineNaoGeraNf = (idSelect, tipoSelect) => {
    definirNaoGeraNfRet.current.toggle(idSelect, tipoSelect);
  };

  const handleSelect = (v, isSelected, row) => {
    setSelected(
      isSelected
        ? [...selected, { tipo: row.tipo_mov, id_tipo: row.id }]
        : selected.filter((e) => {
            if (e.id_tipo === row.id) {
              return e.tipo !== row.tipo_mov;
            }
            return true;
          })
    );
  };

  const handleSelectAll = (isSelected) => {
    setSelected(
      isSelected
        ? dados.map((row) => ({ tipo: row.tipo_mov, id_tipo: row.id }))
        : []
    );
  };

  const gerarNfProduto = () => {
    history.push(infoNfFaturadaRoute.path, { selected: selected });
  };

  return (
    <PageContainer title="Gerar NF de Venda ou O.S." number="0036" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            md={5}
            onChange={(selected) => setCliente(selected?.value)}
            isSearchable
            isClearable
          />
          <IntegerFormInput
            label="Nº da Venda"
            md="2"
            onChange={setSequencia}
          />
          <FormCheckbox
            label="Mostrar vendas já faturadas"
            name="vendas_faturadas_checkbox"
            onChange={() => setMostrarFaturadas(!mostrarFaturadas)}
            checked={mostrarFaturadas}
            padded={true}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleSelectDate} />
          <BotaoPesquisar onClick={handleSearch} loading={loading} />
        </Row>
        <Row>
          <FormButton
            onClick={gerarNfProduto}
            md="auto"
            color="success"
            disabled={selected?.length === 0}
          >
            Gerar NF Produto
          </FormButton>
          <GerarNFServicoModal selected={selected} notifyEvent={notifyEvent} />
        </Row>
      </Card>
      <GerarNfVendaOsGrid
        data={dados}
        handleSelect={handleSelect}
        handleSelectAll={handleSelectAll}
        reabrirFat={reabrirFat}
        defineNaoGeraNf={defineNaoGeraNf}
      />
      <DefineNaoGeraNfNfseModal
        ref={definirNaoGeraNfRet}
        notifyEvent={notifyEvent}
      />
      <ReabrirGerarNfModal ref={reabrirFatRef} notifyEvent={notifyEvent} />
    </PageContainer>
  );
};
