import { toastr } from "react-redux-toastr";
import { api } from "../../../api";

const processaRetorno = (ret, returnType) => {
  if (ret.data.success || (ret.data.hasOwnProperty("err") && !ret.data.err)) {
    if (returnType) {
      return ret.data.res;
    } else {
      toastr.success("Sucesso", ret.data.msg);
      return true;
    }
  } else {
    toastr.info("", ret.data.msg);
    return returnType ?? false;
  }
};

const processaErro = (err, returnType) => {
  toastr.error("Erro", err.message);
  return returnType ?? false;
};

const ComissaoVTSService = {
  listar: async (url) => {
    return await api
      .get(url)
      .then((ret) => processaRetorno(ret, []))
      .catch((err) => processaErro(err, []));
  },
  buscarDadosNivelVTS: async (filters) => {
    const searchParams = new URLSearchParams(filters).toString();
    return await api
      .get(`/relatorios/venda/comissao/vts/?${searchParams}`)
      .then((ret) => processaRetorno(ret, []))
      .catch((err) => processaErro(err, []));
  },
};

export default ComissaoVTSService;
