import { apiGet } from "../api";

const VendasPorGrupoService = {
  fetchRelData: async (dataIni, dataFim, fabricante, grupo, vendedor) => {
    const params_ = {
      data_ini: dataIni,
      data_fim: dataFim,
    };

    if (fabricante?.length > 0) params_["fabricante"] = `[${fabricante}]`;
    if (grupo?.length > 0) params_["grupo"] = `[${grupo}]`;
    if (vendedor?.length > 0) params_["vendedor"] = `[${vendedor}]`;

    const searchParams = new URLSearchParams(params_).toString();

    return await apiGet(
      `/relatorios/venda/total_agrupado_por_grup_fabr/?${searchParams}`
    );
  },
};

export default VendasPorGrupoService;
