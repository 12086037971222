import React, { useState } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import CentralPedidosService from "../../../../../services/pedidos/CentralPedidosService";

export const NaoEntregarRestantePedidoModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const payload = { id_pedido: selected };

    if (await CentralPedidosService.naoEntregarRestante(payload)) {
      notifyEvent();
      toggle();
    }

    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Não entregar restante dos itens do pedido"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      confirmButtonText="Sim"
      cancelButtonText="Não"
      number="0034_6"
    >
      <h4 className="ml-3">
        Você tem certeza que deseja não entregar o restante dos itens do Pedido
        Nº {selected}
      </h4>
    </ModalBase>
  );
};
