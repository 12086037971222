import { apiGetV2 } from "../../apiV2";

const ComboBoxService = {
  fetchOptions: async (modelName, filters = {}) => {
    const [ok, ret] = await apiGetV2(
      `/cadastro/concat/${modelName}/`,
      filters,
      {
        errorMessage: false,
        successMessage: false,
      }
    );
    return ok ? ret : [];
  },
  fetchOptionsOutraEmpresa: async (modelName, params) => {
    const [ok, ret] = await apiGetV2(
      `/cadastro/concat/outra_empresa/${modelName}/`,
      params,
      {
        errorMessage: false,
        successMessage: false,
      }
    );
    return ok ? ret : [];
  },
};

export default ComboBoxService;
