import React from "react";
import { Row } from "reactstrap";
import { ComboBox, RadioGroup, TextInput } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormaImpressao,
  setLayoutImpr,
  setNomeImpressora,
  setMsgCorrespA,
} from "../store/recibo_slice";
import { RadioItem } from "../../../../components/RadioGroup";

const layoutImprOptions = [
  { label: "Modelo 1 - Impressão Matricial", value: 1 },
  { label: "Modelo 2 - Impressão Jato de Tinta", value: 2 },
  { label: "Modelo 3 - Impressão em Bobina - Mini Impressora", value: 3 },
];

export const PersonRecibo = () => {
  const store = useSelector((state) => state.recibo);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <RadioGroup
          label="Imprimir Recibo"
          value={store.forma_impressao}
          onChange={(v) => dispatch(setFormaImpressao(v))}
        >
          <RadioItem label="Sempre Imprimir" value="IMP" />
          <RadioItem label="Visualizar" value="VIS" />
          <RadioItem label="Perguntar se Deseja Imprimir" value="PER" />
          <RadioItem label="Não Imprimir" value="NAO" />
        </RadioGroup>
      </Row>
      <Row>
        <ComboBox
          md={5}
          label="Layout de Impressão"
          options={layoutImprOptions}
          defaultValue={store.layout_impr}
          onChange={(s) => dispatch(setLayoutImpr(s?.value))}
        />
      </Row>
      <Row>
        <TextInput
          md={5}
          label="Nome da Impressora"
          value={store.nome_impressora}
          onChange={(e, v) => dispatch(setNomeImpressora(v))}
          maxLength={255}
          forceUppercase={false}
        />
      </Row>
      <Row>
        <TextInput
          md={10}
          label="Correspondente A"
          value={store.msg_corresp_a}
          onChange={(e, v) => dispatch(setMsgCorrespA(v))}
          forceUppercase={false}
        />
      </Row>
    </>
  );
};
