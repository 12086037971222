import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { frenteVendaRoute } from "../../../routes/modules/vendas";
import { MdOutlinePointOfSale } from "react-icons/md";

export const FrenteVendaMenu = () => {
  return (
    <PageContainer title="Vendas - Frente de Venda">
      <MenuGroup>
        <MenuGroupButton
          label="Frente de Venda"
          icon={MdOutlinePointOfSale}
          pathname={frenteVendaRoute.path}
          nroTela={frenteVendaRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
