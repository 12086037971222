import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Row } from "reactstrap";
import { api } from "../../../../api";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import { GraficoComprasVendas } from "./components/GraficoComprasVendas";
import { GridConsultas } from "./components/GridConsultas";

export const VendaVsCompra = () => {
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [loading, setLoading] = useState(false);
  const [compras, setCompras] = useState([]);
  const [vendas, setVendas] = useState([]);
  const [totalVendas, setTotalVendas] = useState(0);
  const [totalCompras, setTotalCompras] = useState(0);
  const [grafico, setGrafico] = useState([]);
  const [mostrarDados, setMostrarDados] = useState(false);

  const limparDados = () => {
    setTotalCompras(0);
    setTotalVendas(0);
    setCompras([]);
    setVendas([]);
    setGrafico([]);
  };

  const handleSelectDate = async (_dataIni, _dataFim) => {
    setDataIni(_dataIni);
    setDataFim(_dataFim);
    limparDados();
  };

  const buscarDados = async () => {
    const invalidOpts = [null, undefined, 0];
    if (invalidOpts.includes(dataIni)) {
      toastr.error(
        "Data de início inválida",
        "Verifique a data e tente novamente"
      );
      return false;
    }
    if (invalidOpts.includes(dataFim)) {
      toastr.error(
        "Data de fim inválida",
        "Verifique a data e tente novamente"
      );
      return false;
    }
    const urlParams = new URLSearchParams({
      data_ini: dataIni.toISOString().split("T")[0],
      data_fim: dataFim.toISOString().split("T")[0],
    }).toString();
    const ret = await api.get(
      `relatorios/comparativos/venda_vs_compra/?${urlParams}`
    );
    if (ret.data["success"]) {
      if (ret.data["res"]) {
        return ret.data["res"];
      } else {
        toastr.info(
          "Sem Dados",
          "Não existem dados para o período selecionado."
        );
        return [];
      }
    } else {
      toastr.error("Erro", ret.data["msg"]);
      return [];
    }
  };

  const listarDados = async () => {
    setLoading(true);
    let res = await buscarDados();

    if (res["consulta"]) {
      setCompras([res["consulta"]["compra"]]);
      setVendas([res["consulta"]["venda"]]);
      setTotalCompras(res["totais"]["compra"]);
      setTotalVendas(res["totais"]["venda"]);
      setGrafico(
        res["grafico"].map((e) => {
          return {
            identificador: e.identificador,
            "Venda a Vista": parseFloat(e["Venda a Vista"]),
            "Venda a Prazo": parseFloat(e["Venda a Prazo"]),
            "Compra a Vista": parseFloat(e["Compra a Vista"]),
            "Compra a Prazo": parseFloat(e["Compra a Prazo"]),
          };
        })
      );
    } else {
      setCompras([]);
      setVendas([]);
      setGrafico([]);
    }
    setMostrarDados(true);
    setLoading(false);
  };

  return (
    <PageContainer
      title="Venda x Compra por forma de pagamento"
      number="5019"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleSelectDate} />
          <BotaoPesquisar onClick={listarDados} loading={loading} />
        </Row>
      </Card>
      {mostrarDados && (
        <div>
          <Row>
            <GridConsultas
              dados={compras}
              titulo="Compras"
              dataIni={dataIni}
              dataFim={dataFim}
              total={totalCompras}
            />
            <GridConsultas
              dados={vendas}
              titulo="Vendas"
              dataIni={dataIni}
              dataFim={dataFim}
              total={totalVendas}
            />
          </Row>
          <GraficoComprasVendas data={grafico} />
          <Card body>
            <p>
              <b>BASE DE INFORMAÇÕES</b>
              <br />
              Vendas: Ordens de Serviço, Vendas, Vendas Diretas.
              <br />
              Compras: Entrada de notas fiscais.
              <br />
              <br />
              <b>ESPECIFICAÇÕES</b>
              <br />
              <b>Movimentos não identificados:</b>
              <br />
              Vendas: movimentos que não foram recebidos em caixa. Isso ocorre
              com origens de ordem de serviço e vendas no estabelecimento.
              <br />
              Compras: notas fiscais que não tiveram suas faturas identificadas
              no processo de entrada.
            </p>
          </Card>
        </div>
      )}
      <br />
    </PageContainer>
  );
};
