import React from "react";
import { useState } from "react";
import { AsyncComboBox } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  modalTitleProdutoInfoCliNf,
  routesBaseProdutoInfoCliNf,
} from "../ProdutoInfoCliNf";

export const CadastroProdutoInfoCliNf = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [cliente, setCliente] = useState();
  const [produtoVenda, setProdutoVenda] = useState();
  const [produtoNf, setProdutoNf] = useState();

  const limparDados = () => {
    setCliente();
    setProdutoVenda();
    setProdutoNf();
  };

  const fetchData = (dados) => {
    setCliente(dados.id_cliente);
    setProdutoVenda(dados.id_produto_venda);
    setProdutoNf(dados.id_produto_nf);
  };

  const submitPayload = (action) => {
    const payload = {
      id_cliente: cliente,
      id_produto_venda: produtoVenda,
      id_produto_nf: produtoNf,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_prod_info_cli_nf: selected, ...payload };
  };
  
  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleProdutoInfoCliNf}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseProdutoInfoCliNf}
      number="0118_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <AsyncComboBox
          isConcatField
          concatModelName="cliente"
          isSearchable
          label="Cliente"
          required
          onChange={(s) => {
            setCliente(s?.value ?? 0);
          }}
          defaultValue = {cliente}
        />
      </Row>
      <Row>
        <AsyncComboBox
          isConcatField
          concatModelName="produto"
          isSearchable
          label="Produto Venda"
          required
          onChange={(s) => {
            setProdutoVenda(s?.value ?? 0);
          }}
          defaultValue={produtoVenda}
        />
      </Row>
      <Row>
        <AsyncComboBox
          isConcatField
          concatModelName="produto"
          isSearchable
          label="Produto NF"
          required
          onChange={(s) => {
            setProdutoNf(s?.value ?? 0);
          }}
          defaultValue = {produtoNf}
        />
      </Row>
    </ModalCadastroV2>
  );
};
