import React from "react";
import { ModalBase } from "../../../../components";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useState } from "react";
import settings from "../../../../settings";
import { isDevEnv } from "../../../../coreUtils";
import { useEffect } from "react";
import { Spinner } from "reactstrap";
import QRCode from "react-qr-code";
import { apiWhatsPost } from "../../../../apiWhats";
import { showSuccess } from "../../../../components/AlertaModal";

export const ConfigurarWhatsappModal = ({ isOpen, toggle, notifyEvent }) => {
  const licenca = localStorage.getItem("licenca");

  const [codigoQr, setCodigoQr] = useState(null);
  const [socketUrl, setSocketUrl] = useState(null);
  const { lastMessage, readyState } = useWebSocket(socketUrl);

  const iniciarConexaoWS = () => {
    if (!isOpen) {
      setSocketUrl(null);
    } else {
      const endpointWebsocket = isDevEnv()
        ? settings.WS_WHATSAPP_API_DEV
        : settings.WS_WHATSAPP_API;
      setSocketUrl(`${endpointWebsocket}/qrcode/?licenca=${licenca}`);
    }
  };

  useEffect(iniciarConexaoWS, [isOpen]);

  const iniciarProcessoConexaoWhatsapp = async () => {
    if (readyState === ReadyState.OPEN) {
      const [ok] = await apiWhatsPost(`connect/${licenca}`, null, {
        successMessage: false,
      });
      if (!ok) {
        // Desconecta o socket do WS
        setSocketUrl(null);
      }
    }
  };

  const procConnWhats = () => {
    iniciarProcessoConexaoWhatsapp();
  };

  useEffect(procConnWhats, [readyState]);

  const tratarMensagemRecebidaWS = () => {
    if (lastMessage?.data === "CONN_OK") {
      showSuccess("WhatsApp configurado com sucesso");
      notifyEvent();
      toggle();
    } else if (lastMessage?.data === "CONN_CONFIG_READY") {
      showSuccess("WhatsApp já está configurado e aceitando conexões");
      notifyEvent();
      toggle();
    } else {
      setCodigoQr(lastMessage?.data);
    }
  };

  useEffect(tratarMensagemRecebidaWS, [lastMessage]);

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title="Configurar Whatsapp"
      size="md"
    >
      <div
        style={{
          height: "450px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {!codigoQr ? (
          <Spinner />
        ) : (
          <QRCode
            size={128}
            style={{ height: "auto", maxWidth: "80%", width: "80%" }}
            value={codigoQr}
          />
        )}
      </div>
    </ModalBase>
  );
};
