import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Col, Spinner } from "reactstrap";

const activeStyle = {
  backgroundColor: "#2196f3",
};

const acceptStyle = {
  backgroundColor: "#2196f3",
};

const rejectStyle = {
  backgroundColor: "#ff1744",
};

const DragAndDropArquivos = ({
  md = 3,
  accept = "*",
  onDrop,
  multiple = false,
  maxFiles = 0,
  text = "Arraste e solte um arquivo aqui ou clique para selecioná-lo",
  style,
  textStyle = {},
  loading,
  loadingMessage,
}) => {
  const dropzoneOptions = { multiple, maxFiles };

  if (accept !== "*") {
    dropzoneOptions["accept"] = accept;
  }

  const _onDrop = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        acceptedFiles.forEach((file) => onDrop(file));
      }
    },
    [onDrop]
  );

  dropzoneOptions["onDrop"] = _onDrop;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone(dropzoneOptions);

  const innerStyle = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Col md={md}>
      <div
        {...getRootProps({
          className: "dropzone",
          style: {
            cursor: "pointer",
            border: "none",
            backgroundColor: "#5b7dff",
            color: "white",
            justifyContent: "center",
            padding: "12px",
            fontSize: "14px",
            ...style,
            ...innerStyle,
          },
        })}
      >
        {loading ? (
          <>
            <Spinner size="md" color="light" style={{ marginBottom: "4px" }} />
            {loadingMessage && <>{loadingMessage}...</>}
          </>
        ) : (
          <>
            <input {...getInputProps({ multiple: false })} />
            <p style={{ marginTop: "revert", ...textStyle }}>{text}</p>
          </>
        )}
      </div>
    </Col>
  );
};

export default DragAndDropArquivos;
