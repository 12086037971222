import React from "react";
import { Table, TableDelete } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

export const ImpressaoEtiquetasGrid = ({
  etiquetas,
  deleteProduto,
  setQtdEtiqProd,
}) => {
  const columns = [
    {
      dataField: "nome",
      text: "Produto",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
    },
    {
      dataField: "codigo_barras",
      text: "Código de Barras",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "tamanho",
      text: "Tamanho",
      align: "center",
      headerAlign: "center",
    },
    { dataField: "cor", text: "Cor", align: "center", headerAlign: "center" },
    {
      dataField: "unidade",
      text: "Unidade",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "preco_venda",
      text: "Preço de Venda",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "qtd_etiquetas",
      text: "Qtd. Etiquetas",
      align: "center",
      headerAlign: "center",
      editable: true,
      editorType: "number",
      decimalPlaces: 0,
      onBlur: setQtdEtiqProd,
      colWidth: "50px",
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row, index) => (
        <TableDelete onClick={() => deleteProduto(index)} />
      ),
    },
  ];

  return (
    <Table
      data={etiquetas}
      columns={columns}
      paginated={false}
      keyField="id_produto"
    />
  );
};
