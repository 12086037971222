import React, { Component } from "react";
import { Col, FormGroup, Label, Input } from "reactstrap";
import { handleFocus } from "../coreUtils";

const isValid = (value, min, max) =>
  value !== "" &&
  value !== "-" &&
  (min === undefined || value >= min) &&
  (max === undefined || value <= max);

class IntegerInput extends Component {
  constructor(props) {
    super(props);
    this.state = { internalValue: this.props.value || 1 };

    if (this.props.onChange !== undefined)
      this.props.onChange(this.state.internalValue);
  }

  clear = (v) => {
    this.setState({ internalValue: v });
  };

  update = (v) => {
    if (v !== undefined) this.setState({ internalValue: v });
  };

  render() {
    const regex = new RegExp(`^-?[0-9]*$`);
    const { internalValue } = this.state;
    const { md, label, name, min, max, onChange, autoFocus } = this.props;
    return (
      <Col md={md}>
        <FormGroup>
          <Label>{label}</Label>
          <Input
            type={"number"}
            name={name}
            value={internalValue}
            autoFocus={autoFocus}
            onChange={(evt) => {
              const newValue = evt.target.value;
              if (regex.test(newValue)) {
                this.setState({ internalValue: newValue });
                if (isValid(newValue)) {
                  onChange(newValue);
                }
              } else {
                if (min !== undefined) {
                  this.setState({ internalValue: min });
                } else {
                  this.setState(undefined);
                }
              }
            }}
            min={min}
            max={max}
            onBlur={() => {
              if (internalValue < min) {
                this.setState({ internalValue: min });
              } else if (internalValue > max) {
                this.setState({ internalValue: max });
              } else {
                this.setState({ internalValue: this.props.value });
              }
            }}
            onKeyDown={handleFocus}
          />
        </FormGroup>
      </Col>
    );
  }
}

export default IntegerInput;
