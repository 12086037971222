import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { handleFocus } from "../coreUtils";
import classNames from "classnames";
import { PiCaretDownBold } from "react-icons/pi";

export const TableCombobox = forwardRef(
  (
    {
      id,
      name,
      autoFocus,
      tabOrder,
      className,
      options = [],
      onBlur,
      value,
      onChange,
      align,
      style,
      optionsRenderer,
    },
    ref
  ) => {
    const [internalId] = useState(
      id ?? "table-combobox-" + Math.floor(Math.random() * Date.now())
    );
    const [mouseOver, setMouseOver] = useState(false);
    const [callOnBlur, setCallOnBlur] = useState(false);
    const [listOpen, setListOpen] = useState(false);
    const inputRef = useRef("");

    // const onKeyDown = (e) => {
    //   handleFocus(e);
    // };

    // const handleOnChange = (e) => {
    //   e.preventDefault();
    //   if (onChange) {
    //     const selValue = e.target.value || "";
    //     setCallOnBlur(true);
    //     onChange(selValue, e);
    //   }
    // };

    // const onBlurInternal = (e) => {
    //   if (onBlur) {
    //     const selValue = e.target.value || "";
    //     if (callOnBlur) onBlur(selValue, e);
    //   }
    //   setCallOnBlur(false);
    // };

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
    }));

    const optionsRenderers = options.map((opt) => {
      let renderer = <span className="value-span">{opt.label}</span>;

      if (optionsRenderer) {
        renderer = optionsRenderer(opt);
      }
      return { ...opt, renderer: renderer };
    });

    return (
      <div
        id={internalId}
        className={classNames("table-combobox", className)}
        style={{ textAlign: align, ...style }}
        onClick={() => {
          inputRef.current.focus();
        }}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <div className="value-container">
          <input
            name={name}
            autoFocus={autoFocus}
            tabIndex={tabOrder}
            className="ghost-input"
            readOnly={true}
            ref={inputRef}
            onFocus={() => setListOpen(true)}
            onBlur={() => {
              if (!mouseOver) {
                setListOpen(false);
              }
            }}
            onKeyDown={(e) => {
              if (["Enter", "Tab", "ArrowUp"].includes(e.key)) {
                setListOpen(false);
              }
              handleFocus(e);
            }}
          />
          <span className="value-span">
            {optionsRenderers.find((e) => e.value === value)?.renderer}
          </span>
          <PiCaretDownBold className="caret" />
        </div>
        {listOpen && (
          <div className="options-list">
            {optionsRenderers
              .filter((opt) => opt.value !== value)
              .map((opt) => (
                <div
                  className="option"
                  onClick={(e) => {
                    setListOpen(false);
                    if (onChange) {
                      onChange(opt.value, opt, e);
                    }
                  }}
                >
                  {opt.renderer}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
);
