import React, { useRef, useState } from "react";
import {
  AsyncComboBox,
  FixedField,
  MaskedInput,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../../components";
import { Row } from "reactstrap";
import { toastr } from "react-redux-toastr";
import NotaFiscalService from "../../../../../../services/docs_eletron/NotaFiscalService";

export const IncluirItemIcmsNFModal = ({
  isOpen,
  toggle,
  idNfe,
  notifyEvent,
}) => {
  // Controle
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const refCfop = useRef();
  // Parâmetros
  const [cfop, setCfop] = useState("");
  const [sitTribIcms, setSitTribIcms] = useState("");
  const [descricao, setDescricao] = useState("");
  const [ncm, setNcm] = useState("00000000");
  const [baseCalcIcms, setBaseCalcIcms] = useState(0);
  const [percIcms, setPercIcms] = useState(0);
  const [vlrIcms, setVlrIcms] = useState(0);
  const [baseCalcIcmsSt, setBaseCalcIcmsSt] = useState(0);
  const [percIcmsSt, setPercIcmsSt] = useState(0);
  const [vlrIcmsSt, setVlrIcmsSt] = useState(0);
  const [mvaIcmsSt, setMvaIcmsSt] = useState(0);
  const [vlrIcmsOper, setVlrIcmsOper] = useState(0);
  const [percDiferIcms, setPercDiferIcms] = useState(0);
  const [vlrDiferIcms, setVlrDiferIcms] = useState(0);

  const handleSetCfop = (s) => {
    setCfop(s?.value);
    setSitTribIcms(s?.sit_trib_icms);
    if (["", null, undefined].includes(descricao)) {
      setDescricao(s?.descricao);
    }
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(cfop)) {
      toastr.warning("Atenção", "Por Favor, informe a CFOP do Item.");
      return false;
    }

    if (["", null, undefined].includes(descricao)) {
      toastr.warning("Atenção", "Por Favor, informe a Descrição do Item.");
      return false;
    }

    setLoadingSubmit(true);

    try {
      const payload = {
        id_cab: idNfe,
        descricao: descricao.trim(),
        ncm: ncm,
        cfop: cfop,
        base_calc_icms: baseCalcIcms,
        perc_icms: percIcms,
        vlr_icms: vlrIcms,
        vlr_icms_oper: vlrIcmsOper,
        vlr_difer_icms: vlrDiferIcms,
        perc_difer_icms: percDiferIcms,
        base_calc_icms_st: baseCalcIcmsSt,
        marg_vlr_adic_icms_st: mvaIcmsSt,
        perc_icms_st: percIcmsSt,
        vlr_icms_st: vlrIcmsSt,
      };

      const [ok] = await NotaFiscalService.inclusao.incluirItemIcms(payload);
      if (ok) {
        limparDados();
        toggle();
        notifyEvent();
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const limparDados = () => {
    setCfop("");
    setSitTribIcms("");
    setDescricao("");
    setNcm("00000000");
    setBaseCalcIcms(0);
    setPercIcms(0);
    setVlrIcms(0);
    setVlrIcmsOper(0);
    setPercDiferIcms(0);
    setVlrDiferIcms(0);
    setBaseCalcIcmsSt(0);
    setPercIcmsSt(0);
    setVlrIcmsSt(0);
    setMvaIcmsSt(0);
    if (refCfop.current) refCfop.current.clearValue();
  };

  const semCfop = ["", null, undefined].includes(cfop);

  return (
    <ModalBase
      title="Inclusão de Item de ICMS em NFe"
      number="0055_12"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loadingSubmit}
      confirmButtonText="Confirmar Item"
    >
      <Row>
        <AsyncComboBox
          isConcatField
          concatModelName="cfop"
          isSearchable
          md={8}
          label="CFOP"
          onChange={handleSetCfop}
          defaultValue={cfop}
          defaultOptions
          ref={refCfop}
          clearOnDisable={false}
        />
        {sitTribIcms && (
          <FixedField label="CST / CSOSN ICMS" value={sitTribIcms} />
        )}
      </Row>
      <Row>
        <TextInput
          md={8}
          label="Descrição"
          value={descricao}
          onChange={(e, v) => setDescricao(v)}
          disabled={semCfop}
        />
        <MaskedInput
          md={2}
          label="NCM"
          value={ncm}
          onChange={(e, v) => setNcm(v)}
          mask="99999999"
          maskChar={null}
          disabled={semCfop}
          selectTextOnFocus
        />
      </Row>
      <Row>
        <NumberInput
          md={2}
          label="Base Cálculo ICMS"
          value={baseCalcIcms}
          onChange={setBaseCalcIcms}
          decimalPlaces={2}
          disabled={semCfop}
        />
        <NumberInput
          md={2}
          label="% ICMS"
          value={percIcms}
          onChange={setPercIcms}
          disabled={semCfop}
          decimalPlaces={2}
          isPercentage
        />
        <NumberInput
          md={2}
          label="Valor ICMS"
          value={vlrIcms}
          onChange={setVlrIcms}
          decimalPlaces={2}
          disabled={semCfop}
        />
        <NumberInput
          md={2}
          label="Valor ICMS Operação"
          value={vlrIcmsOper}
          onChange={setVlrIcmsOper}
          disabled={semCfop}
          decimalPlaces={2}
        />
        <NumberInput
          divClassName="ml-auto"
          md={2}
          label="% Diferimento ICMS"
          value={percDiferIcms}
          onChange={setPercDiferIcms}
          disabled={semCfop}
          decimalPlaces={4}
          isPercentage
        />
        <NumberInput
          md={2}
          label="Valor ICMS Diferido"
          value={vlrDiferIcms}
          onChange={setVlrDiferIcms}
          disabled={semCfop}
          decimalPlaces={2}
        />
      </Row>
      <Row>
        <NumberInput
          md={2}
          label="Base Cálc. ICMS ST"
          value={baseCalcIcmsSt}
          onChange={setBaseCalcIcmsSt}
          decimalPlaces={2}
          disabled={semCfop}
        />
        <NumberInput
          md={2}
          label="% ICMS ST"
          value={percIcmsSt}
          onChange={setPercIcmsSt}
          disabled={semCfop}
          decimalPlaces={2}
          isPercentage
        />
        <NumberInput
          md={2}
          label="Valor ICMS ST"
          value={vlrIcmsSt}
          onChange={setVlrIcmsSt}
          decimalPlaces={2}
          disabled={semCfop}
        />
        <NumberInput
          md={2}
          label="% MVA ST"
          value={mvaIcmsSt}
          onChange={setMvaIcmsSt}
          disabled={semCfop}
          decimalPlaces={2}
          isPercentage
        />
      </Row>
    </ModalBase>
  );
};
