import React from "react";
import { Table } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const ProdutoInfoCliNfGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "id_cliente",
      text: "ID Cliente",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "id_produto_venda",
      text: "ID Produto Venda",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
        dataField: "nome_produto_venda",
        text: "Nome Produto Venda",
        align: "left",
        headerAlign: "center",
        sortable: true,
      },
      {
        dataField: "unidade_produto_venda",
        text: "UN",
        align: "center",
        headerAlign: "center",
        sortable: true,
      },
    {
      dataField: "id_produto_nf",
      text: "ID Produto NF",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "nome_produto_nf",
      text: "Produto NF",
      align: "left",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "unidade_produto_nf",
      text: "UN",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      clearSelectionOnUpdate
      onRowDoubleClick={alterar}
    />
  );
};
