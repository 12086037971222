import React from "react";
import { Card } from "reactstrap";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { DetalhesConsultaModal } from "./DetalhesConsultaModal";
import { Table } from "../../../../../components";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    sortable: true,
  },
  {
    dataField: "id_cliente",
    text: "Cliente",
    align: "center",
    sortable: true,
  },
  {
    dataField: "id_cliente__nome",
    text: "Nome",
    align: "left",
    sortable: true,
  },
  {
    dataField: "id_cliente__cidade__nome",
    text: "Cidade",
    align: "left",
    sortable: true,
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
    sortable: true,
  },
  {
    dataField: "qtd_itens",
    text: "Qtd. Produtos",
    align: "center",
    sortable: true,
    formatter: (v) => formatNumber(v, true, 2, true),
    sortFunc: naturalSort,
  },
  {
    dataField: "situacao",
    text: "Situação",
    align: "center",
    sortable: true,
    formatter: (c) => (c === "NAO" ? "Não Entregue" : "Entrega Parcial"),
  },
  {
    dataField: "vlr_total",
    text: "Valor Total",
    align: "right",
    sortable: true,
    formatter: (v) => formatNumber(v, true, 2),
    sortFunc: naturalSort,
  },
  {
    dataField: "actions",
    text: "",
    align: "center",
    isDummyField: true,
    formatter: (c, row) => <DetalhesConsultaModal idPedido={row.id} />,
  },
];

export const PedidosPendentesEntregaGrid = ({ data }) => {
  return (
    <Card body>
      <Table columns={columns} data={data} />
    </Card>
  );
};
