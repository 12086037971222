import { api } from "../api";

class FiltersService {
  static fetch(table, filters) {
    return new Promise(async (resolve, reject) => {
      await api
        .get(
          `/cadastro/concat/${table}/${
            filters !== undefined ? `?${filters}` : ""
          }`
        )
        .then((fetchData) => {
          const checkErr = fetchData.data;
          if (checkErr.success) {
            resolve(fetchData.data.res);
          } else {
            const { data } = fetchData;
            reject(`${data.msg} - Erro: ${data.id_err}`);
          }
        })
        .catch(() => {
          reject("Erro inesperado!");
        });
    });
  }
}

export default FiltersService;
