import { sliceIntoChunks } from "../../../../../coreUtils";
import {
  formatRow,
  formatNumberField,
} from "../../../../financeiro/cobranca_banc/remessa/components/GerarRemessa";
import moment from "moment";

const conembHeader = (nomeEmpresa, nomeCliente) => [
  ["000", 1, 3], // IDENTIFICAÇÃO DO REGISTRO
  [nomeEmpresa, 4, 38], // IDENTIFICAÇÃO DO REMETENTE
  [nomeCliente, 39, 73], // IDENTIFICAÇÃO DO DESTINATÁRIO
  [moment(new Date()).format("DDMMYY"), 74, 79], // DATA
  [moment(new Date()).format("HHmm"), 80, 83], // HORA
  ["CON" + moment(new Date()).format("DDMMHHmm") + "0", 84, 95], // IDENTIFICAÇÃO DO INTERCÂMBIO - CONDDMMHHMMS
  ["", 96, 680], // FILLER
];

const conembCabDoc = (seq) => [
  ["320", 1, 3], // IDENTIFICAÇÃO DO REGISTRO
  [
    "CONHE" +
      moment(new Date()).format("DDMM") +
      seq.toString().padStart(5, "0"),
    4,
    17,
  ], // IDENTIFICAÇÃO DO DOCUMENTO
  ["", 18, 680], // FILLER
];

const conembDadosTransp = (cnpjEmpresa, nomeEmpresa) => [
  ["321", 1, 3],
  [formatNumberField(cnpjEmpresa), 4, 17],
  [nomeEmpresa, 18, 57],
  ["", 58, 680],
];

const conembDadosConhec = (conhec, cnpjEmpresa, cnpjCliente, nfes, cont) => [
  ["322", 1, 3], // IDENTIFICADOR DO REGISTRO
  ["MATRIZ", 4, 13], // FILIAL EMISSORA DO CONHECIMENTO
  ["1", 14, 18], // SÉRIE
  [conhec.numero_cte.toString().padStart(12, "0"), 19, 30], // NUMERO DO CONHECIMENTO
  [moment(conhec.data_emi_cte).format("DDMMYYYY"), 31, 38], // DATA DE EMISSÃO
  ["C", 39, 39], // CONDIÇÃO DE FRETE: C - CIF, F - FOB
  [formatNumberField(conhec.peso_transp), 40, 46], // PESO TRANSPORTADO
  [formatNumberField(conhec.vlr_frete_total), 47, 61], // VALOR TOTAL DO FRETE
  [formatNumberField(conhec.base_calc_icms), 62, 76], // BASE DE CÁLCULO PARA APURAÇÃO ICMS
  [formatNumberField(conhec.perc_icms), 77, 80], // PERC. ICMS
  [formatNumberField(conhec.vlr_icms), 81, 95], // VALOR ICMS
  [formatNumberField(conhec.vlr_frete_por_peso), 96, 110], // VALOR DO FRETE POR PESO/VOLUME
  [formatNumberField(conhec.vlr_frete), 111, 125], // VALOR DO FRETE
  [0, 126, 140], // VALOR SEC - CAT
  [0, 141, 155], // VALOR ITR
  [formatNumberField(conhec.vlr_carga), 156, 170], // VALOR DO DESPACHO
  [0, 171, 185], // VALOR DO PEDÁGIO
  [0, 186, 200], // VALOR ADEME
  ["2", 201, 201], // SUBSTITUIÇÃO TRIBUTÁRIA: 1 - SIM, 2 - NÃO
  [0, 202, 204], // FILLER (ANTIGO CAMPO DE CFOP DE 3 POSIÇÕES)
  [formatNumberField(cnpjEmpresa), 205, 218], // C.G.C. DO EMISSOR DO CONHECIMENTO
  [formatNumberField(cnpjCliente), 219, 232], // C.G.C. DA EMBARCADORA / REMETENTE
  [
    [
      ...nfes.map(
        (nf) =>
          `${parseInt(nf.serie)
            .toString()
            .padEnd(3, " ")}${parseInt(nf.numero)
            .toString()
            .padStart(8, "0")}`
      ),
      ...Array(Math.max(40 - nfes.length, 0)).fill("   00000000"),
    ]
      // .map((c) => c.padStart(8, "0") + "   ")
      .join(""),
    233,
    672,
  ], // Chaves de acesso das NF's
  ["I", 673, 673], // AÇÃO DO DOCUMENTO: I = INCLUIR, C = COMPLEMENTAR, E = EXCLUIR
  [conhec.tipo_servico.substring(0, 1), 674, 674], // TIPO DO CONHECIMENTO: N = NORMAL, C = COMPLEMENTAR, D = CONHECIMENTO DE DEVOLUÇÃO, E = NORMAL DE ENTRADA, O = NORNAL DE RETORNO, R = CONHECIMENTO DE REENTREGA, S = NORMAL DE SAÍDA, T = NORMAL DE TRANSFERÊNCIA INTERNA, W = COMPLEMENTAR DE RETORNO, X = COMPLEMENTAR DE ENTRADA, Y = COMPLEMENTAR DE SAÍDA, Z = COMPLEMENTAR DE TRANSF. INTERNA
  [cont ? "C" : "U", 675, 675], // INDICAÇÃO DE CONTINUIDADE/REPETIÇÃO DOS DADOS DO CONHECIMENTO, QUANDO HOUVER MAIS DE 40 NFs: U OU ESPAÇO: CONHECIMENTO COM 40 OU MENOS NFs, C: CONTINUAÇÃO/REPETIÇÃO DOS DADOS DOS CAMPOS 1 A 22 PELO FATO DESTE CONTER MAIS DE 40 NFs.
  [conhec.cfop, 676, 680], // CÓDIGO FISCAL DA NATUREZA DE OPERAÇÃO
  [conhec.chave_acesso_cte, 681, 724], // Número da Chave de acesso CTe
  [moment(conhec.data_emi_sefaz_cte).format("DDMMYYYYHHmmss"), 725, 738], // Data Recebimento SEFAZ
  [conhec.nro_prot_emi_cte, 739, 754], // Número do Protocolo SEFAZ
];

const conembFooter = (qtdConhecimentos, vlrTotCte) => [
  ["323", 1, 3], // IDENTIFICADOR DO REGISTRO
  [qtdConhecimentos, 4, 7], // QUANTIDADE TOTAL DE CONHECIMENTOS
  [formatNumberField(vlrTotCte), 8, 22], // VALOR TOTAL DE CONHECIMENTOS
  ["", 23, 680], // FILLER
];

export const conemb = (data) => {
  const dadosPrim = data[0];
  const header = formatRow(
    ...conembHeader(dadosPrim.nome_empresa, dadosPrim.nome_cliente)
  );

  const documentos = data
    .map((mdfe, index) => {
      const cabDoc = formatRow(...conembCabDoc(index + 1));
      const dadosTransp = formatRow(
        ...conembDadosTransp(mdfe.cpf_cnpj_empresa, mdfe.nome_empresa)
      );

      const dadosConhec = mdfe.conhecimentos
        // Gera múltiplos registros 322 para cada conhecimento a depender do número de NFE's
        .reduce(
          (acc, conhec) => [
            ...acc,
            // quebra NFe's do conhecimento em conjunto com 40 elementos
            sliceIntoChunks(conhec.chaves_nfes, 40)
              .map(
                (chunkNfes) =>
                  // gera um registro 322 para cada conjunto de 40 NFe's
                  formatRow(
                    ...conembDadosConhec(
                      conhec,
                      mdfe.cpf_cnpj_empresa,
                      mdfe.cpf_cnpj_cliente,
                      chunkNfes,
                      conhec.chaves_nfes.length > 40
                    )
                  )
                // Junta os registros de cada grupo
              )
              .join(""),
          ],
          []
        )
        .join("");

      const footer = formatRow(
        ...conembFooter(mdfe.conhecimentos.length, mdfe.vlr_tot_cte)
      );

      return cabDoc + dadosTransp + dadosConhec + footer;
    })
    .join("");

  const content = header + documentos;

  const fileName =
    (data.sigla_transportadora ?? "").trim() +
    moment(new Date()).format("DD") +
    moment(new Date()).format("DDMMYY") +
    ".CON";

  return [new Blob([content], { type: "text/plain" }), fileName];
};
