import React, { useState } from "react";
import { ModalBase } from "../../../../../components";
import SolicitacaoRecargaService from "../../../../../services/pronta_entrega/SolicitacaoRecargaService";

export const CancelarSolicRecargaModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const [ok] = await SolicitacaoRecargaService.cancelarSolicitacao(selected);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Cancelar Solicitação de Recarga"
      number="0104_3"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <h5 style={{ textAlign: "center" }}>
        Tem certeza de que deseja CANCELAR a Solicitação de Recarga Nº{" "}
        {selected}?
      </h5>
    </ModalBase>
  );
};
