import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "reactstrap";
import { PageContainer } from "../../../components/PageContainer";
import { FormButton } from "../../../components";
import { apiWhatsGet } from "../../../apiWhats";
import { BsCheck, BsX } from "react-icons/bs";
import { ConfigurarWhatsappModal } from "./components/ConfigurarWhatsappModal";

export const Whatsapp = () => {
  const licenca = localStorage.getItem("licenca");
  // Controle
  const [whatsappConfigurado, setWhatsappConfigurado] = useState(false);
  const [modalWhatsappIsOpen, setModalWhatsappIsOpen] = useState(false);

  const verificarWhatsConfigurado = async () => {
    const [ok, ret] = await apiWhatsGet(`/check_status/${licenca}`);

    if (ok) {
      setWhatsappConfigurado(ret.connected);
    }
  };

  const iniciarTela = () => {
    verificarWhatsConfigurado();
  };

  useEffect(iniciarTela, []);

  const toggleModalWhatsapp = () =>
    setModalWhatsappIsOpen(!modalWhatsappIsOpen);

  return (
    <PageContainer title="Whatsapp" number="conf_06">
      <Card body>
        <ConfigurarWhatsappModal
          isOpen={modalWhatsappIsOpen}
          toggle={toggleModalWhatsapp}
          notifyEvent={verificarWhatsConfigurado}
        />
        <Row>
          <FormButton
            color="secondary"
            onClick={toggleModalWhatsapp}
            padded={false}
          >
            Configurar Conexão
          </FormButton>
          {whatsappConfigurado ? (
            <Col md="auto" className="mt-2">
              <Row>
                <BsCheck size={18} color="green" />
                Configurado
              </Row>
            </Col>
          ) : (
            <Col md="auto" className="mt-2">
              <Row>
                <BsX size={18} color="red" />
                Ainda não configurado
              </Row>
            </Col>
          )}
        </Row>
      </Card>
    </PageContainer>
  );
};
