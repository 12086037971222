import React, { useState } from "react";
import {
  Divider,
  FixedField,
  ModalBase,
  MoreDropdown,
  Table,
} from "../../../../../components";
import CredRotativoService from "../../../../../services/financeiro/CredRotativoService";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";
import { DetalhesLoteCredRotativoModal } from "./DetalhesLoteCredRotativoModal";
import { SenhaModal } from "../../../../../components/SenhaModal";

export const DetalhesContaModal = ({
  isOpen,
  toggle,
  selected,
  nomeCliente,
}) => {
  const [lotes, setLotes] = useState([]);
  const [idEstornar, setIdEstornar] = useState(null);
  const [senhaEstornarOpen, setSenhaEstornarOpen] = useState(false);

  const carregarDados = async () => {
    const ret = await CredRotativoService.listarDetalhesContas(selected);

    if (ret.lotes) {
      setLotes(ret.lotes);
    }
  };

  const estornarRecebimento = async (senha) => {
    const payload = {
      id_lote: idEstornar,
      senha_estorno: senha,
    };
    const [ok] = await CredRotativoService.estornarRecebimento(payload);
    if (ok) {
      carregarDados();
      setIdEstornar(null);
    }
    return ok;
  };

  const toggleSenhaEstornar = () => setSenhaEstornarOpen(!senhaEstornarOpen);

  const solicSenhaEstornarRecebimento = (id) => {
    setIdEstornar(id);
    setTimeout(() => {
      toggleSenhaEstornar();
    }, 1);
  };

  const lotesColumns = [
    { dataField: "id_lote", text: "#", align: "center", headerAlign: "center" },
    {
      dataField: "nro_movs_lote",
      text: "Movimentos",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_hora_lote",
      text: "Data/Hora",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_desc_lote",
      text: "Desconto",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "valor_lote",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "recebido_caixa",
      text: "Rec. Caixa",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "det",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => <DetalhesLoteCredRotativoModal lote={row} />,
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem
            onClick={() => solicSenhaEstornarRecebimento(row.id_lote)}
          >
            Estornar Fatura
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Detalhes da Conta de Crédito Rotativo"
        number="0068_5"
        onBeforeOpen={carregarDados}
      >
        <FixedField
          horizontal
          divClassName="no-gutters"
          label="Cliente"
          value={formatValueFromAPI(nomeCliente, selected)}
        />
        <Divider className="mt-1">Lotes</Divider>
        <Table data={lotes} columns={lotesColumns} paginated={false} />
        <SenhaModal
          isOpen={senhaEstornarOpen}
          toggle={toggleSenhaEstornar}
          title="Estorno de Recebimento"
          onConfirm={estornarRecebimento}
        />
      </ModalBase>
    </>
  );
};
