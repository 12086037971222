import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import { AsyncComboBox, FormButton } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import DespesasJornadaService from "../../../../../services/jornada/despesa/DespesasJornadaService";

export const IncluirVincModal = ({ selected, nomeDespesa, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idFornecedor, setIdFornecedor] = useState(0);
  const [idRotaMob, setIdRotaMob] = useState(0);
  const [loading, setLoading] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const handleSubmit = async (e) => {
    if ([0, null, undefined].includes(idFornecedor)) {
      toastr.warning("Atenção", "Informe um Fornecedor válido");
      return false;
    }

    if ([0, null, undefined].includes(idRotaMob)) {
      toastr.warning("Atenção", "Informe uma Rota válida");
      return false;
    }

    setLoading(true);
    const payload = {
      id_despesa: selected,
      id_fornecedor: idFornecedor,
      id_rota_mob: idRotaMob,
    };

    const [ok] = await DespesasJornadaService.incluirVinculacao(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        padded={false}
        divClassName="ml-auto"
        md="auto"
        color="info"
        onClick={toggle}
        disabled={[0, null, undefined].includes(selected)}
        disabledHint="Selecione uma Despesa na lista acima"
      >
        Incluir nova vinculação
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title={`Incluir Vinculação - ${nomeDespesa}`}
        number="0011_3"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            label="Fornecedor"
            md={6}
            isConcatField
            concatModelName="fornecedor"
            defaultOptions
            isSearchable
            isClearable
            onChange={(sel) => setIdFornecedor(sel?.value ?? 0)}
            autoFocus
          />
          <AsyncComboBox
            label="Rota"
            md={6}
            isConcatField
            concatModelName="rota_mob"
            defaultOptions
            isSearchable
            isClearable
            onChange={(sel) => setIdRotaMob(sel?.value ?? 0)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
