import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  ComboBox,
  FiltroPeriodoDatas,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { docPrintReport } from "../../../../pdf";
import { formatDateISO } from "../../../../coreUtils";
import HistoricoAnaliticoOsService from "../../../../services/ordem_servico/HistoricoAnaliticoOsService";

const optionsPeriodos = [
  {
    label: "Emissão",
    value: "EMI",
  },
  {
    label: "Fechamento",
    value: "FEC",
  },
];

const ordenarOptions = [
  { label: "Número O.S", value: "ID" },
  { label: "Data de Fechamento", value: "FEC" },
];

export const HistoricoAnaliticoOs = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [itensGarantia, setItensGarantia] = useState(false);
  const [periodo, setPeriodo] = useState();
  const [ordenar, setOrdenar] = useState();

  const handleDateInput = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const imprimir = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: cliente,
      periodo: periodo,
      ordenar_por: ordenar,
    };

    const [ok, ret] = await HistoricoAnaliticoOsService.imprimir(params);

    setLoading(true);

    if (ok) {

      const payload = {
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
        garantia: itensGarantia,
        ...ret
      }
  
      await docPrintReport(
        "/relatorios/ordem_servico/historico_analitico_os",
        payload,
        "5064",
        true
      );
    }
    setLoading(false);
  };

  return (
    <PageContainer title="Histórico Analítico de O.S." number="5064" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            isClearable
            isSearchable
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            onChange={(s) => setCliente(s?.value)}
          />
          <ComboBox
            md={2}
            label={"Filtrar por"}
            options={optionsPeriodos}
            onChange={(s) => setPeriodo(s?.value)}
            defaultValue={"FEC"}
          />
          <ComboBox
            md={2}
            label={"Ordenar Por"}
            options={ordenarOptions}
            defaultValue={ordenarOptions[0].value}
            onChange={(s) => setOrdenar(s?.value)}
          />
          <FiltroPeriodoDatas onChange={handleDateInput} />
        </Row>
        <Row>
          <FormCheckbox
            label={"Itens Garantia"}
            onChange={() => setItensGarantia(!itensGarantia)}
            value={itensGarantia}
          />
          <BotaoImprimir
            disabled={
              [0, null, undefined].includes(cliente) ||
              !(dataIni instanceof Date) ||
              !(dataFim instanceof Date)
            }
            disabledHint="Verifique o cliente e as datas de início e fim"
            onClick={imprimir}
            loading={loading}
          />
        </Row>
      </Card>
    </PageContainer>
  );
};
