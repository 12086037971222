import React, { useState } from "react";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  AsyncComboBox,
  FormCheckbox,
  MaskedInput,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import {
  modalTitleTransportadora,
  routesBaseTransportadora,
} from "../Transportadora";
import BotaoPesq from "../../../../../components/form/BotaoPesq";
import { limparNumero, MODAL_ACTIONS } from "../../../../../coreUtils";
import CadastroClienteService from "../../../../../services/cadastro/CadastroClienteService";
import CadastroCidadeService from "../../../../../services/cadastro/CadastroCidadeService";

export const CadastroTransportadoraModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [ativo, setAtivo] = useState(true);
  const [endereco, setEndereco] = useState("");
  const [nroEnder, setNroEnder] = useState("");
  const [bairro, setBairro] = useState("");
  const [cep, setCep] = useState("");
  const [idCidade, setIdCidade] = useState(null);
  const [cnpj, setCnpj] = useState("");
  const [ie, setIe] = useState("");
  const [fone, setFone] = useState("");
  const [email, setEmail] = useState("");
  const [envMailNfe, setEnvMailNfe] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);

  const limparDados = () => {
    setNome("");
    setAtivo(true);
    setEndereco("");
    setNroEnder("");
    setBairro("");
    setCep("");
    setIdCidade(null);
    setCnpj("");
    setIe("");
    setFone("");
    setEmail("");
    setEnvMailNfe(false);
  };

  const fetchData = (ret) => {
    setNome(ret.nome);
    setAtivo(ret.ativo);
    setEndereco(ret.endereco);
    setNroEnder(ret.numero);
    setBairro(ret.bairro);
    setCep(ret.cep);
    setIdCidade(ret.id_cidade);
    setCnpj(ret.cnpj);
    setIe(ret.insc_est);
    setFone(ret.telefone);
    setEmail(ret.email);
    setEnvMailNfe(ret.enviar_email_nfe);
  };

  const buscarCep = async () => {
    const stateCep = limparNumero(cep);

    if (cep.length < 8) return false;

    setLoadingCep(true);
    const ret = await CadastroClienteService.buscarCep(stateCep);

    if (Object.keys(ret).length > 0) {
      if (ret.logradouro) setEndereco(ret.logradouro);

      if (ret.bairro) setBairro(ret.bairro);

      const [ok, cidade] = await CadastroCidadeService.verificarInserirCidade(
        ret.localidade,
        ret.uf,
        ret.ibge
      );

      if (ok) {
        if (cidade) setIdCidade(cidade);
      }
    }
    setLoadingCep(false);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      endereco: endereco,
      nro_ender: nroEnder,
      bairro: bairro,
      cep: cep,
      id_cidade: idCidade,
      cnpj: cnpj,
      ie: ie,
      fone: fone,
      email: email ?? "",
      env_mail_nfe: envMailNfe,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ativo: ativo, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleTransportadora}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseTransportadora}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
      number="0117_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={11}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={60}
        />
      </Row>
      <Row>
        <TextInput
          md={9}
          label="Endereço"
          value={endereco}
          onChange={(e, v) => setEndereco(v)}
          maxLength={80}
        />
        <TextInput
          md={3}
          label="Número"
          value={nroEnder}
          onChange={(e, v) => setNroEnder(v)}
          maxLength={10}
        />
      </Row>
      <Row>
        <TextInput
          md={4}
          label="Bairro"
          value={bairro}
          onChange={(e, v) => setBairro(v)}
          maxLength={40}
        />
        <AsyncComboBox
          md={5}
          concatModelName="cidade"
          label="Cidade"
          onChange={(s) => setIdCidade(s?.value ?? null)}
          defaultValue={idCidade}
        />
        <MaskedInput
          mask="99999-999"
          name="cep"
          label="CEP"
          md={3}
          onChange={(e, v) => setCep(v)}
          value={cep}
          additionalButton={
            <BotaoPesq
              onClick={buscarCep}
              loading={loadingCep}
              disabled={limparNumero(cep ?? "").length < 8}
              secondary
            />
          }
        />
      </Row>
      <Row>
        <MaskedInput
          mask={"99.999.999/9999-99"}
          label="CNPJ"
          md={4}
          value={cnpj}
          onChange={(e, v) => setCnpj(v)}
        />
        <TextInput
          md={4}
          label="Inscrição Estadual"
          value={ie}
          onChange={(e, v) => setIe(v)}
          maxLength={15}
        />
        <TextInput
          md={4}
          label="Fone"
          value={fone}
          onChange={(e, v) => setFone(v)}
          maxLength={18}
        />
      </Row>
      <Row>
        <TextInput
          md={8}
          label="E-mail"
          value={email}
          onChange={(e, v) => setEmail(v ?? "")}
          maxLength={200}
          forceUppercase={false}
        />
        <FormCheckbox
          label="Enviar Email de NFe"
          checked={envMailNfe}
          onChange={(v) => setEnvMailNfe(!envMailNfe)}
        />
      </Row>
    </ModalCadastroV2>
  );
};
