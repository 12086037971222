import React, { useState, useEffect } from "react";
import {
  BotaoPesquisar,
  FiltroPeriodoDatas,
  PageContainer,
  RadioGroup,
} from "../../../../components";
import { VisaoJornadasAbertas } from "./components/VisaoJornadasAbertasGrid";
import { CardTotais, CardTotaisItem } from "../../../../components";
import { HistoricoFlexModal } from "./components/HistoricoFlexModal";
import { LancamentoBonifModal } from "./components/LancamentoBonifModal";
import { LancamentoFlexModal } from "./components/LancamentoFlexModal";
import { Card, Row } from "reactstrap";
import { GerenciamentoFlexService } from "../../../../services/frente_venda_mobile/GerenciamentoFlexService";
import { RadioItem } from "../../../../components/RadioGroup";
import { VisaoPorPeriodoGrid } from "./components/VisaoPorPeriodoGrid";
import { showWarning } from "../../../../components/AlertaModal";

const VISOES = {
  JORNADA_ABERTA: "J",
  POR_PERIODO: "P",
};

export const FlexBonificacao = () => {
  const [visao, setVisao] = useState(VISOES.JORNADA_ABERTA);
  const [dadosGrid, setDadosGrid] = useState([]);
  const [totais, setTotais] = useState({});
  const [rota, setRota] = useState(null);

  // Visão por período
  const [dataIni, setDataIni] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [dataFim, setDataFim] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );
  const [loading, setLoading] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    setDadosGrid([]);
    setTotais({});
    setRota(null);

    try {
      if (visao === VISOES.JORNADA_ABERTA) {
        const [ok, ret] = await GerenciamentoFlexService.buscarRotas();
        setDadosGrid(ok ? ret.rotas : []);
        setTotais(ok ? ret.totais?.flex : {});
      } else {
        if (!(dataIni instanceof Date)) {
          showWarning("Por favor, revise a data de início");
          return false;
        }

        if (!(dataFim instanceof Date)) {
          showWarning("Por favor, revise a data de fim");
          return false;
        }

        setLoading(true);
        const [ok, ret] = await GerenciamentoFlexService.listarPeriodo(
          dataIni,
          dataFim
        );
        setDadosGrid(ok ? ret.rotas : []);
        setTotais(ok ? ret.totais : {});
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    carregarDados();
  }, []);

  useEffect(() => {
    carregarDados();
    if (visao === VISOES.JORNADA_ABERTA) {
      setDataIni(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
      setDataFim(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      );
    }
  }, [visao]);

  return (
    <PageContainer title="Flex/Bonificação das Rotas" number="0028" canGoBack>
      <Card body>
        <Row className="mb-2">
          <RadioGroup
            label="Visualizar"
            onChange={(v) => setVisao(v)}
            value={visao}
          >
            <RadioItem label="Jornadas Abertas" value={VISOES.JORNADA_ABERTA} />
            <RadioItem label="Por Período" value={VISOES.POR_PERIODO} />
          </RadioGroup>
          {visao === VISOES.POR_PERIODO && (
            <>
              <FiltroPeriodoDatas
                onChange={handleDate}
                defaultOption="MESATU"
              />
              <BotaoPesquisar onClick={carregarDados} loading={loading} />
            </>
          )}
        </Row>
        <Row>
          <LancamentoFlexModal notifyEvent={carregarDados} />
          <HistoricoFlexModal
            rotaSelec={rota}
            dataIniDefault={dataIni}
            dataFimDefault={dataFim}
          />
          <LancamentoBonifModal notifyEvent={carregarDados} />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Saldo Inicial"
          value={totais?.saldo_inicial}
          hidden={visao !== VISOES.POR_PERIODO}
          hintText="Saldo do primeiro dia do mês até a data inicial"
        />
        <CardTotaisItem label="Lançado ADM" value={totais?.gerado_adm} />
        <CardTotaisItem label="Gerado Venda" value={totais?.gerado_venda} />
        <CardTotaisItem
          label="Utilizado Venda"
          value={totais?.utilizado_venda}
        />
        <CardTotaisItem
          label="Saldo Final"
          value={totais?.saldo_final}
          hidden={visao !== VISOES.POR_PERIODO}
        />
        <CardTotaisItem
          label="Saldo Atual"
          value={totais?.saldo_atual}
          hidden={visao !== VISOES.POR_PERIODO}
        />
        <CardTotaisItem
          label="Resultado Flex"
          value={totais?.custo_flex}
          hidden={visao !== VISOES.POR_PERIODO}
          hintText={
            <>
              Resultado Flex é o valor de perda ou ganho sobre o processo de
              flex.
              <br />
              Valores negativos representam perda
              <br />
              Valores positivos representam ganho
            </>
          }
        />
      </CardTotais>
      {visao === VISOES.JORNADA_ABERTA ? (
        <VisaoJornadasAbertas dados={dadosGrid} selectRota={setRota} />
      ) : loading === true ? (
        <></>
      ) : (
        <VisaoPorPeriodoGrid dados={dadosGrid} selectRota={setRota} />
      )}
    </PageContainer>
  );
};
