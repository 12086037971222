import React from "react";
import { Card, CardTitle, Col } from "reactstrap";
import { DataTable } from "../../../../../components/DataTable";
import { formatNumber } from "../../../../../coreUtils";
import { DetalhesConsultaModal } from "./DetalhesConsultaModal";

export const GridConsultas = ({ dados, titulo, dataIni, dataFim, total }) => {
  const _columns = [
    {
      dataField: "a_vista",
      text: "A Vista",
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "a_prazo",
      text: "A Prazo",
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "nao_definido",
      text: "Não Definido",
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "det",
      isDummyField: true,
      align: "center",
      headerAlign: "center",
      formatter: () => (
        <DetalhesConsultaModal
          consulta={titulo === "Compras" ? "CMP" : "VDA"}
          dataIni={dataIni}
          dataFim={dataFim}
        />
      ),
    },
  ];

  return (
    <Col md="6">
      <Card body>
        <CardTitle>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div align="left">{titulo}</div>
            <div align="right">
              Total:
              <strong>
                <span>{" " + formatNumber(total)}</span>
              </strong>
            </div>
          </div>
        </CardTitle>
        <DataTable
          columns={_columns}
          data={dados}
          keyField=" "
          noWrap
          striped={false}
          readOnly
          hideCount
        />
      </Card>
    </Col>
  );
};
