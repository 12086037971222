import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AlertCircle, Bell, Home, Settings, UserPlus } from "react-feather";
import { connect, useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import {
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { logoff } from "../auth/session";
import { isDevEnv, isTestingEnv, rawAPIUrl } from "../coreUtils";
import { hideSidebar, toggleSidebar } from "../redux/actions/sidebarActions";
import { dashboard } from "../routes/index";
import ScreenTitle from "./ScreenTitle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SET_MENU_CURRENT_ROUTES } from "../redux/constants";
import { configuracoesRoutes } from "../routes/modules";
import { configGeralRoute } from "../routes/modules/configuracoes";
import NumberPage from "./NumberPage";
import { compose } from "redux";
import { ContextMenu } from "../redux/reducers/menuReducer";

const listScreens = () => {
  const unwrapRoute = (node, level) => {
    if (node.children) {
      return node.children.reduce(
        (acc, child) => [...acc, ...unwrapRoute(child, level + 1)],
        []
      );
    } else {
      return [
        {
          ...node,
          level: level,
          number: node.id?.replace("AcSkWeb", ""),
        },
      ];
    }
  };

  return [...dashboard, ...configuracoesRoutes].reduce(
    (acc, node) => [...acc, ...unwrapRoute(node, 1)],
    []
  );
};

const screensList = listScreens();

const NavbarDropdown = ({
  children,
  count,
  showBadge,
  header,
  footer,
  icon: Icon,
}) => (
  <UncontrolledDropdown nav inNavbar className="mr-2">
    <DropdownToggle nav className="nav-icon dropdown-toggle">
      <div className="position-relative">
        <Icon className="align-middle" size={18} />
        {showBadge ? <span className="indicator">{count}</span> : null}
      </div>
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted">{footer}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const NavbarDropdownItem = ({
  icon,
  title,
  description,
  time,
  spacing,
  href,
  uuid,
}) => (
  <ListGroup>
    <ListGroupItem className="notify-list">
      <Row className="align-items-center">
        <Col xs={2}>{icon}</Col>
        <Col xs={10} className={spacing ? "pl-4 pb-2 pt-2" : null}>
          <a href={href} className="pb-2">
            <div className="text-dark">{title}</div>
            <div className="text-muted small mt-1">{description}</div>
            <div className="text-muted small mt-1">{time}</div>
          </a>
          <div className="text-muted small mt-2">
            <label style={{ cursor: "pointer", textDecoration: "underline" }}>
              Marcar como lida
            </label>
          </div>
        </Col>
      </Row>
    </ListGroupItem>
  </ListGroup>
);

const NavbarComponent = ({ dispatch, menuDispatch }) => {
  const [notifications] = useState([]);
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [redirectHome, setRedirectHome] = useState(false);
  const [rotaTela, setRotaTela] = useState(null);
  const pageState = useSelector((state) => state.page);
  const location = useLocation();
  const history = useHistory();

  const buscarNomeTela = (path) => {
    const route = screensList.find((e) => e.path === path);
    setRotaTela(route ?? null);
  };

  useEffect(() => {
    buscarNomeTela(location.pathname);
  }, [location.pathname]);

  const onLogout = async () => {
    await logoff();
    window.location = "/acesso/login/";
  };

  const getNickname = () => {
    let nickname = Cookies.get("nickname");
    if (nickname) {
      return nickname;
    } else {
      return "Não autenticado";
    }
  };

  const getHref = () => {
    var href;
    notifications.forEach((item) => {
      if (item.tipo === "MDR") {
        href = "/venda_direta/cadastros/rotas";
      } else if (item.tipo === "RDP") {
        href = "/venda_direta/jornada";
      }
    });
    return href;
  };

  const abrirParametros = () => {
    menuDispatch({ type: SET_MENU_CURRENT_ROUTES, data: configuracoesRoutes });
    history.push(configGeralRoute.path);
  };

  useEffect(() => {
    const handleToggle = (event) => {
      if (
        event.keyCode === 81 &&
        document.activeElement.tagName === "BODY" &&
        !window.location.pathname.startsWith("/configuracoes")
      ) {
        dispatch(toggleSidebar());
      }
    };

    document.addEventListener("keydown", handleToggle);
    setNomeEmpresa(localStorage.getItem("nome_fant_empresa"));

    if (window.location.pathname === "/configuracoes") {
      dispatch(hideSidebar());
    }
  }, []);

  return (
    <Navbar color="white" light expand>
      {redirectHome && <Redirect to="/" />}
      {rotaTela && (
        <ScreenTitle
          pop={rotaTela.canGoBack !== false && history.length > 1}
          onBeforeGoingBack={pageState.onBeforeGoingBack}
        >
          {pageState.title}{" "}
          {pageState.number && <NumberPage>{pageState.number}</NumberPage>}
        </ScreenTitle>
      )}
      {pageState.topOptions && pageState.topOptions}
      <Collapse navbar>
        <img
          src={rawAPIUrl("/cadastro/empresa/logo/")}
          className="ml-auto"
          style={{
            width: "40px",
          }}
          alt="Logo da Empresa"
        />

        <span
          className="ml-2 mr-4"
          style={{ color: isDevEnv() ? "red" : "black" }}
        >
          {nomeEmpresa}
          <br />
          {isDevEnv() && (
            <div style={{ fontSize: "0.7rem" }}>
              Ambiente de Desenvolvimento
            </div>
          )}
        </span>

        <Nav className="ml-1" navbar>
          <NavbarDropdown
            header="novas notificações"
            footer={<Link to="">Limpar notificações</Link>}
            icon={Bell}
            showBadge={notifications.length > 0}
            count={notifications.length}
          >
            {notifications.map((item, key) => {
              let icon = <Bell size={18} className="text-warning" />;

              if (item.type === "important") {
                icon = <AlertCircle size={18} className="text-danger" />;
              }

              if (item.type === "login") {
                icon = <Home size={18} className="text-primary" />;
              }

              if (item.type === "request") {
                icon = <UserPlus size={18} className="text-success" />;
              }

              return (
                <NavbarDropdownItem
                  key={key}
                  icon={icon}
                  title={item.titulo}
                  description={item.descricao}
                  time={item.data_hora}
                  spacing
                  href={getHref()}
                  uuid={item.uuid}
                />
              );
            })}
          </NavbarDropdown>
          <img
            src={
              "https://static.vecteezy.com/system/resources/previews/000/420/940/original/avatar-icon-vector-illustration.jpg"
            }
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>

            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-dark">{getNickname()}</span>
              </DropdownToggle>
            </span>

            <DropdownMenu right>
              <DropdownItem onClick={abrirParametros}>
                Configurações
              </DropdownItem>
              <DropdownItem>Suporte</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={onLogout}>Sair</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default compose(
  connect((store) => ({ app: store.app })),
  connect(
    (store) => ({ menuCurrentRoutes: store.menuCurrentRoutes }),
    (dispatch) => ({ menuDispatch: dispatch }),
    null,
    {
      context: ContextMenu,
    }
  )
)(NavbarComponent);
