import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";

import imagem1 from "../../../../assets/img/layouts_recibo/1.png";
import imagem2 from "../../../../assets/img/layouts_recibo/2.png";
import imagem3 from "../../../../assets/img/layouts_recibo/3.png";

import { Divider } from "../../../../components";

const layouts = [
  {
    id: 1,
    imagem: imagem1,
    descricao: (
      <>
        <ul>
          <li>Layout Matricial</li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    imagem: imagem2,
    descricao: (
      <>
        <ul>
          <li>Layout Jato de tinta.</li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    imagem: imagem3,
    descricao: (
      <>
        <ul>
          <li>Layout de bobina.</li>
        </ul>
      </>
    ),
  },
];

const PrintDisplay = ({ layoutsList, openImageViewer }) => (
  <div className="print-display">
    {layoutsList.map((layout) => (
      <div className="print-item">
        <img
          src={layout.imagem}
          className="print-thumb"
          onClick={() => openImageViewer(layout.id)}
          alt=""
        />
        <div style={{ height: "min-content", marginLeft: "0.75rem" }}>
          <h5 className="mb-1">Layout {layout.id}</h5>
          <span>{layout.descricao}</span>
        </div>
      </div>
    ))}
  </div>
);

export const ImpressaoRecibo = () => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [idImagem, setIndexImagem] = useState(0);

  const openImageViewer = (index) => {
    setIndexImagem(index);
    setIsViewerOpen(true);
  };

  const onCloseImageViewer = () => {
    setIndexImagem(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      <Divider className="mt-2">FOLHA A4</Divider>
      <PrintDisplay layoutsList={layouts} openImageViewer={openImageViewer} />
      {isViewerOpen && (
        <ImageViewer
          src={[layouts.find((layout) => layout.id === idImagem)?.imagem]}
          currentIndex={0}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={onCloseImageViewer}
          backgroundStyle={{ backgroundColor: "#00000085" }}
        />
      )}
    </>
  );
};
