import React from "react";
import { ModalBase } from "../../../../../../components";
import { useSelector } from "react-redux";

export const ConfirmarItemJaIncluidoModal = ({ toggle, tipo }) => {
  const params = useSelector((state) => state.paramsOS);

  let msg = <></>;
  if (params.modoAdicaoItemGrid === "S") {
    if (tipo === "P") {
      msg = (
        <p>
          Este produto já foi incluído nesta ordem de serviço.
          <br />
          <br />
          Deseja substituí-lo?
        </p>
      );
    } else {
      msg = (
        <p>
          Este serviço já foi incluído nesta ordem de serviço.
          <br />
          <br />
          Deseja substituí-lo?
        </p>
      );
    }
  } else {
    if (tipo === "P") {
      msg = (
        <p>
          Este produto já foi incluído nesta ordem de serviço.
          <br />
          <br />
          Deseja incluí-lo novamente?
        </p>
      );
    } else {
      msg = (
        <p>
          Este serviço já foi incluído nesta ordem de serviço.
          <br />
          <br />
          Deseja incluí-lo novamente?
        </p>
      );
    }
  }

  return (
    <ModalBase
      isOpen={true}
      toggle={toggle}
      size="sm"
      title="Incluir Item com Garantia"
      onConfirm={() => toggle(true)}
      cancelButtonText="Não"
      confirmButtonText="Sim"
    >
      {msg}
    </ModalBase>
  );
};
