import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  idCliente: null,
  idOS: null,
  osPesquisadas: [],
};

export const filtrosVistoriasSlice = createSlice({
  name: "filtros_vistorias_slice",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setIdCliente: (state, action) => {
      state.idCliente = action.payload;
    },
    setIdOS: (state, action) => {
      state.idOS = action.payload;
    },
    setOsPesquisadas: (state, action) => {
      state.osPesquisadas = action.payload;
    },
  },
});

export const {
  init,
  setup,
  setIdCliente,
  setIdOS,
  setOsPesquisadas,
} = filtrosVistoriasSlice.actions;

export default filtrosVistoriasSlice.reducer;
