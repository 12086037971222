import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adiciona_item_grid: "R",
  operacao_padrao: "V",
  forma_pag_padrao: "DIN",
  utiliza_ambiente: false,
  utiliza_veiculo: false,
  oper_padrao_entrega: "SEM",
  identif_tipo_impr_final_proc: false,
  oper_visivel_venda: true,
  oper_visivel_pedido: true,
  oper_visivel_orcamento: true,
  oper_visivel_ordem_serv: true,
  identifica_premiado: false,
  gerar_pontos_por: "V",
  utiliza_regra_premiacao: false,
  avisa_identif_premiado: false,
  bloq_inc_prod_qtd_est_negativo: false,
  identifica_forma_rec: false,
  oper_visivel_condicional: false,
  mostrar_col_vlr_liq_grid_frente_venda: false,
  cheque_cliente_define_limite_por: "SITU",
  obriga_autoriz_uso_prod_curinga: false,
  bloq_inc_prod_por: "DESC_MAX",
  identifica_vendedor_final: false,
  utiliza_negociacao_financ: false,
  perc_min_negociacao_financ: 0,
  forma_sistema_control_desc: "SIM",
  mostrar_valor_lucro: false,
  bloq_por_preco_minimo_control_desc_lucro: false,
  mostrar_vlr_lucro_total: true,
  bloq_venda_cliente_classif_8: false,
  permite_faturamento_frente_venda: false,
  informar_perc_comis_frente_venda: false,
};

const fieldsToFalse = [
  "utiliza_regra_premiacao",
  "bloq_inc_prod_qtd_est_negativo",
];

export const frenteVendaSlice = createSlice({
  name: "frente_caixa_slice",
  initialState: initialState,
  reducers: {
    setupFrenteCaixa: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (["perc_min_negociacao_financ"].includes(key)) {
          value = parseFloat(value);
        }

        if (fieldsToFalse.includes(key) && value === null) {
          value = false;
        }
        state[key] = value;
      });
      return state;
    },
    setAdicionaItemGrid: (state, action) => {
      state.adiciona_item_grid = action.payload;
    },
    setOperacaoPadrao: (state, action) => {
      state.operacao_padrao = action.payload;
    },
    setFormaPagPadrao: (state, action) => {
      state.forma_pag_padrao = action.payload;
    },
    setUtilizaAmbiente: (state, action) => {
      state.utiliza_ambiente = action.payload;
    },
    setUtilizaVeiculo: (state, action) => {
      state.utiliza_veiculo = action.payload;
    },
    setOperPadraoEntrega: (state, action) => {
      state.oper_padrao_entrega = action.payload;
    },
    setIdentifTipoImprFinalProc: (state, action) => {
      state.identif_tipo_impr_final_proc = action.payload;
    },
    setOperVisivelVenda: (state, action) => {
      state.oper_visivel_venda = action.payload;
    },
    setOperVisivelPedido: (state, action) => {
      state.oper_visivel_pedido = action.payload;
    },
    setOperVisivelOrcamento: (state, action) => {
      state.oper_visivel_orcamento = action.payload;
    },
    setOperVisivelOrdemServ: (state, action) => {
      state.oper_visivel_ordem_serv = action.payload;
    },
    setIdentificaPremiado: (state, action) => {
      state.identifica_premiado = action.payload;
    },
    setGerarPontosPor: (state, action) => {
      state.gerar_pontos_por = action.payload;
    },
    setUtilizaRegraPremiacao: (state, action) => {
      state.utiliza_regra_premiacao = action.payload;
    },
    setAvisaIdentifPremiado: (state, action) => {
      state.avisa_identif_premiado = action.payload;
    },
    setBloqIncProdQtdEstNegativo: (state, action) => {
      state.bloq_inc_prod_qtd_est_negativo = action.payload;
    },
    setIdentificaFormaRec: (state, action) => {
      state.identifica_forma_rec = action.payload;
    },
    setOperVisivelCondicional: (state, action) => {
      state.oper_visivel_condicional = action.payload;
    },
    setMostrarColVlrLiqGridFrenteVenda: (state, action) => {
      state.mostrar_col_vlr_liq_grid_frente_venda = action.payload;
    },
    setChequeClienteDefineLimitePor: (state, action) => {
      state.cheque_cliente_define_limite_por = action.payload;
    },
    setObrigaAutorizUsoProdCuringa: (state, action) => {
      state.obriga_autoriz_uso_prod_curinga = action.payload;
    },
    setBloqIncProdPor: (state, action) => {
      state.bloq_inc_prod_por = action.payload;
    },
    setIdentificaVendedorFinal: (state, action) => {
      state.identifica_vendedor_final = action.payload;
    },
    setUtilizaNegociacaoFinanc: (state, action) => {
      state.utiliza_negociacao_financ = action.payload;
    },
    setPercMinNegociacaoFinanc: (state, action) => {
      state.perc_min_negociacao_financ = action.payload;
    },
    setFormaSistemaControlDesc: (state, action) => {
      state.forma_sistema_control_desc = action.payload;
    },
    setMostrarValorLucro: (state, action) => {
      state.mostrar_valor_lucro = action.payload;
    },
    setBloqPorPrecoMinimoControlDescLucro: (state, action) => {
      state.bloq_por_preco_minimo_control_desc_lucro = action.payload;
    },
    setMostrarVlrLucroTotal: (state, action) => {
      state.mostrar_vlr_lucro_total = action.payload;
    },
    setBloqVendaClienteClassif8: (state, action) => {
      state.bloq_venda_cliente_classif_8 = action.payload;
    },
    setPermiteFaturamentoFrenteVenda: (state, action) => {
      state.permite_faturamento_frente_venda = action.payload;
    },
    setInformarPercComisFrenteVenda: (state, action) => {
      state.informar_perc_comis_frente_venda = action.payload;
    },
  },
});

export const {
  setupFrenteCaixa,
  setAdicionaItemGrid,
  setOperacaoPadrao,
  setFormaPagPadrao,
  setUtilizaAmbiente,
  setUtilizaVeiculo,
  setOperPadraoEntrega,
  setIdentifTipoImprFinalProc,
  setOperVisivelVenda,
  setOperVisivelPedido,
  setOperVisivelOrcamento,
  setOperVisivelOrdemServ,
  setGerarPontosPor,
  setUtilizaRegraPremiacao,
  setAvisaIdentifPremiado,
  setBloqIncProdQtdEstNegativo,
  setIdentificaFormaRec,
  setOperVisivelCondicional,
  setMostrarColVlrLiqGridFrenteVenda,
  setChequeClienteDefineLimitePor,
  setObrigaAutorizUsoProdCuringa,
  setBloqIncProdPor,
  setIdentificaVendedorFinal,
  setUtilizaNegociacaoFinanc,
  setPercMinNegociacaoFinanc,
  setFormaSistemaControlDesc,
  setMostrarValorLucro,
  setBloqPorPrecoMinimoControlDescLucro,
  setMostrarVlrLucroTotal,
  setBloqVendaClienteClassif8,
  setIdentificaPremiado,
  setPermiteFaturamentoFrenteVenda,
  setInformarPercComisFrenteVenda,
} = frenteVendaSlice.actions;

export default frenteVendaSlice.reducer;
