import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BsPersonLinesFill, BsPersonFillGear, BsClipboardData } from "react-icons/bs";
import {
  demonstrativoHistoricoAnaliticoOsRoute,
  demonstrativoOsHorasClienteRoute,
  demonstrativoOsHorasVendidasTrabalhadasRoute,
  demonstrativoOsRendimentoTecnicoRoute,
  demonstrativoOsValorHorasTecnicoRoute,
} from "../../../routes/modules/ordem_servico";

export const DemonstrativosOS = () => {
  return (
    <PageContainer title="Demonstrativos - Ordens de Serviço">
      <MenuGroup>
        <MenuGroupButton
          label="Horas por Cliente"
          icon={BsPersonLinesFill}
          pathname={demonstrativoOsHorasClienteRoute.path}
          nroTela={demonstrativoOsHorasClienteRoute.nroTela}
        />
        <MenuGroupButton
          label="Horas Vendidas X Trabalhadas"
          icon={AiOutlineFieldTime}
          pathname={demonstrativoOsHorasVendidasTrabalhadasRoute.path}
          nroTela={demonstrativoOsHorasVendidasTrabalhadasRoute.nroTela}
        />
        <MenuGroupButton
          label="Rendimento de Trabalho por Técnico"
          icon={BsPersonFillGear}
          pathname={demonstrativoOsRendimentoTecnicoRoute.path}
          nroTela={demonstrativoOsRendimentoTecnicoRoute.nroTela}
        />
        <MenuGroupButton
          label="Valor de Horas por Técnico"
          icon={BsPersonFillGear}
          pathname={demonstrativoOsValorHorasTecnicoRoute.path}
          nroTela={demonstrativoOsValorHorasTecnicoRoute.nroTela}
        />
      </MenuGroup>
      <MenuGroup>
        <MenuGroupButton
          label="Histórico Analítico de O.S."
          icon={BsClipboardData}
          pathname={demonstrativoHistoricoAnaliticoOsRoute.path}
          nroTela={demonstrativoHistoricoAnaliticoOsRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
