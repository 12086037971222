import { apiGetV2 } from "../../apiV2";

const URL_BASE = "/relatorios/ordem_servico/horas_trabalhadas_x_vendidas";

const HorasVendidasTrabalhadasService = {
  buscarOrdensReq: async (params) => await apiGetV2(`${URL_BASE}/`, params),
  buscarIndicadores: async (params) =>
    await apiGetV2(`${URL_BASE}/indicadores/`, params),
  buscarDetalhes: async (id) => await apiGetV2(`${URL_BASE}/detalhes/${id}/`),
};

export default HorasVendidasTrabalhadasService;
