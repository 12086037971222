import React from "react";
import { Table } from "../../../../../components";
import { iconeAlterar } from "../../../../../components/cadastro";
import { naturalSort } from "../../../../../coreUtils";

export const PerfilGrid = ({ data, setSelected, alterar }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      sortable: true,
    },
    iconeAlterar(alterar),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
