import React, { useEffect, useState } from "react";
import {
  IconButton,
  ModalBase,
  RadioGroup,
} from "../../../../../../components";
import {
  TIPO_ITEM_BONIFICACAO,
  TIPO_ITEM_TROCA,
  TIPO_ITEM_VENDA,
} from "./IncluirAlterarProdutoModal";
import { Edit } from "react-feather";
import { RadioItem } from "../../../../../../components/RadioGroup";

const ModalAlterarTipoItem = ({ tipo, setTipo }) => {
  const [tipoSelecionado, setTipoSelecionado] = useState(tipo);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const ajustarTipoAoAbrir = () => {
    if (isOpen) {
      setTipoSelecionado(tipo);
    }
  };

  useEffect(ajustarTipoAoAbrir, [isOpen]);

  return (
    <>
      <IconButton
        icon={Edit}
        onClick={toggle}
        hint="Alterar tipo de item"
        className="mt-4"
      />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Cancelamento do Pedido"
        onConfirm={() => {
          setTipo(tipoSelecionado);
          toggle();
        }}
      >
        <RadioGroup
          divClassName="d-flex"
          label="Tipo"
          value={tipoSelecionado}
          onChange={setTipoSelecionado}
          divStyle={{ justifyContent: "center" }}
        >
          <RadioItem label="Venda" value={TIPO_ITEM_VENDA} />
          <RadioItem label="Troca" value={TIPO_ITEM_TROCA} />
          <RadioItem label="Bonificação" value={TIPO_ITEM_BONIFICACAO} />
        </RadioGroup>
      </ModalBase>
    </>
  );
};

export default ModalAlterarTipoItem;
