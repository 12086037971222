import React, { useCallback } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Col } from "reactstrap";
import ImgsViewer from "react-images-viewer";
import { useEffect } from "react";
import { usePrevious } from "../../../../../../coreUtils";
import { GerencFotosModal } from "./GerencFotosModal";

export const resizeImage = (base64Str, maxWidth = 700, maxHeight = 700) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

const DragDropFotos = ({ onDrop, md = 4 }) => {
  const dropzoneOptions = { multiple: false, maxFiles: 1, accept: "image/*" };

  const _onDrop = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        acceptedFiles.forEach((file) => onDrop(file));
      }
    },
    [onDrop]
  );

  dropzoneOptions["onDrop"] = _onDrop;

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  return (
    <Col className="pr-0 pb-1" md={md}>
      <div
        {...getRootProps({
          className: "dropzone",
          style: {
            cursor: "pointer",
            justifyContent: "center",
            height: "100%",
          },
        })}
      >
        <input {...getInputProps({ multiple: false })} />
        <p style={{ marginTop: "revert" }}>Adicionar Foto</p>
      </div>
    </Col>
  );
};

export const CarrosselFotosProd = ({
  fotos = [],
  onAdd,
  onDelete,
  md = 4,
  definirPrincipal,
}) => {
  const [index, setIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const qtdFotosAntes = usePrevious(fotos?.length);

  const onAddInternal = async (file) => {
    var reader = new FileReader();

    reader.addEventListener("load", async function(e) {
      const imgResize = await resizeImage(e.target.result);
      onAdd(imgResize);
    });

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (
      fotos.length > 0 &&
      qtdFotosAntes > 0 &&
      (index > fotos.length - 1 || fotos?.length > qtdFotosAntes)
    ) {
      setIndex(fotos.length - 1);
    }
  }, [fotos]);

  return fotos.length === 0 ? (
    <DragDropFotos onDrop={onAddInternal} md={md} />
  ) : (
    <Col className="pr-0" style={{ height: "279px" }} md={md}>
      <div
        style={{
          marginLeft: "auto",
          height: "88%",
          width: "95%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#eee",
          border: "1px solid #dee2e6",
          marginBottom: "0.5rem",
        }}
      >
        <img
          src={fotos[index]?.base64_foto}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            cursor: "zoom-in",
          }}
          alt=""
          onClick={() => setIsViewerOpen(true)}
        />
      </div>
      <div
        className="ml-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <ChevronLeft
            size={20}
            onClick={() => index > 0 && setIndex(index - 1)}
            style={index > 0 ? { cursor: "pointer" } : { color: "#DDD" }}
          />
          <label style={{ marginInline: "1rem" }}>
            {index + 1} / {fotos.length}
          </label>
          <ChevronRight
            size={20}
            onClick={() => index < fotos.length - 1 && setIndex(index + 1)}
            style={
              index < fotos.length - 1
                ? { cursor: "pointer" }
                : { color: "#DDD" }
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <GerencFotosModal
            fotos={fotos}
            onAdd={onAdd}
            onDelete={onDelete}
            definirPrincipal={definirPrincipal}
          />
        </div>
      </div>
      <ImgsViewer
        imgs={fotos.map((e) => ({
          src: e.base64_foto,
        }))}
        isOpen={isViewerOpen}
        currImg={index}
        backdropCloseable={true}
        onClose={() => setIsViewerOpen(false)}
        onClickNext={() => setIndex(index + 1)}
        onClickPrev={() => setIndex(index - 1)}
        spinnerDisabled
        width={700}
        backgroundStyle={{ backgroundColor: "#00000085" }}
      />
    </Col>
  );
};
