import React, { useState } from "react";
import { Card, Row, Label, Col } from "reactstrap";
import {
  FormButton,
  TableDelete,
  BotaoImprimir,
  BotaoPesquisar,
  CamposPersonToggle,
  MaskedInput,
  PageContainer,
} from "../../../../components";
import { naturalSort } from "../../../../coreUtils";
import { GridClientesCamposPerson } from "./components/GridClientesCamposPerson";
import { docPrintReport } from "../../../../pdf";
import { apiGetV2 } from "../../../../apiV2";

export const ClientesCamposPerson = () => {
  const [letra, setLetra] = useState("");
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [camposPerson, setCamposPerson] = useState([]);
  const [dados, setDados] = useState([]);
  const [clientesSelec, setClientesSelec] = useState([]);
  const [cliSelectAdd, setCliSelectAdd] = useState([]);
  const [cliSelectDel, setCliSelectDel] = useState([]);

  const changeCamposPerson = (v) => {
    setCamposPerson(v);
  };

  const listarDados = async () => {
    const params = { pista: (letra ?? "").trim() };

    setLoadingPesquisar(true);

    const [ok, ret] = await apiGetV2(
      "/relatorios/cadastrais/cliente_livro_pagto/",
      params
    );

    if (ok) {
      setDados(
        ret.map((item) => ({
          id: item.id,
          nome: item.nome,
          ...camposPerson.reduce(
            (acc, campo) => ({
              ...acc,
              [campo.nome]:
                item.campos_personalizados.find(
                  (c) => c.nome_campo === campo.nome
                )?.valor ?? null,
            }),
            {}
          ),
        }))
      );
    } else {
      setDados([]);
    }
    setCliSelectAdd([]);
    setCliSelectDel([]);

    setLoadingPesquisar(false);
  };

  const gerarRelatorio = async () => {
    setLoadingImprimir(true);
    await docPrintReport(
      "/relatorios/cadastrais/clientes_campos_person/",
      { dados: clientesSelec, campos_person: camposPerson },
      "5025_1"
    );
    setLoadingImprimir(false);
  };

  const handleSelectAdd = (c, isSelected) => {
    setCliSelectAdd(
      isSelected ? [...cliSelectAdd, c] : cliSelectAdd.filter((e) => e !== c)
    );
  };

  const handleSelectDel = (c, isSelected) => {
    setCliSelectDel(
      isSelected ? [...cliSelectDel, c] : cliSelectDel.filter((e) => e !== c)
    );
  };

  const handleAdd = () => {
    setClientesSelec(
      [
        ...clientesSelec,
        ...dados.filter((e) => cliSelectAdd.includes(e.id)),
      ].sort((a, b) => naturalSort(a.nome, b.nome, "asc"))
    );
    setCliSelectAdd([]);
    setCliSelectDel([]);
  };

  const handleDelete = () => {
    setClientesSelec(clientesSelec.filter((e) => !cliSelectDel.includes(e.id)));
    setCliSelectDel([]);
    setCliSelectAdd([]);
  };

  const handleSelectAllAdd = (isSelected) =>
    setCliSelectAdd(
      isSelected
        ? dados
            .filter((e) => !clientesSelec.map((c) => c.id).includes(e.id))
            .map((e) => e.id)
        : []
    );

  const handleSelectAllDel = (isSelected) =>
    setCliSelectDel(isSelected ? clientesSelec.map((c) => c.id) : []);

  return (
    <PageContainer
      title="Relatório com Campos Personalizados"
      number="5025"
      canGoBack
    >
      <Card body>
        <Row style={{ marginTop: "-10px" }}>
          <Col md="auto">
            <Label style={{ marginTop: "23px" }}>
              Considerar somente clientes com o nome que inicia com a letra:
            </Label>
          </Col>
          <MaskedInput
            mask="a"
            name="inicial"
            md={1}
            onChange={(e, v) => setLetra(v)}
            maskChar={null}
          />
        </Row>
        <Row>
          <CamposPersonToggle
            tableName="cliente"
            onChange={changeCamposPerson}
          />
        </Row>
        <Row>
          <BotaoPesquisar
            onClick={listarDados}
            loading={loadingPesquisar}
            padded={false}
          />
        </Row>
      </Card>
      <GridClientesCamposPerson
        camposPerson={camposPerson}
        dados={dados.filter(
          (e) => !clientesSelec.map((c) => c.id).includes(e.id)
        )}
        onSelect={handleSelectAdd}
        onSelectAll={handleSelectAllAdd}
        selected={cliSelectAdd}
        action={
          <FormButton
            divClassName="ml-auto"
            md="auto"
            padded={false}
            color="success"
            onClick={handleAdd}
            disabled={cliSelectAdd.length === 0}
          >
            Incluir na Listagem
          </FormButton>
        }
      />
      <GridClientesCamposPerson
        camposPerson={camposPerson}
        dados={clientesSelec}
        onSelect={handleSelectDel}
        onSelectAll={handleSelectAllDel}
        paginated={false}
        selected={cliSelectDel}
        action={
          <>
            <BotaoImprimir
              divClassName="ml-auto"
              loading={loadingImprimir}
              onClick={gerarRelatorio}
              disabled={clientesSelec.length === 0}
              disabledHint="Adicione ao menos um cliente da lista acima"
              padded={false}
            />
            <TableDelete
              className="ml-3"
              size={25}
              onClick={handleDelete}
              disabled={cliSelectDel.length === 0}
            />
          </>
        }
      />
    </PageContainer>
  );
};
