import React from "react";
import { DetCustoVsPrecoVendaModal } from "./DetCustoVsPrecoVendaModal";
import {
  formatNumber,
  formatValueFromAPI,
  numericNaturalSort,
} from "../../../../../coreUtils";
import { Table } from "../../../../../components";
import { UncontrolledTooltip } from "reactstrap";

const tituloComHint = (id, title, hint) => {
  return (
    <>
      <span id={`hint_title_${id}`}>{title}</span>
      <UncontrolledTooltip target={`hint_title_${id}`}>
        {hint}
      </UncontrolledTooltip>
    </>
  );
};

export const CustoVsPrecoVendaGrid = ({ dados }) => {
  const columns = [
    {
      dataField: "id",
      text: "Nº Venda",
      align: "center",
    },
    {
      dataField: "n_f_cli",
      text: "Nome Fantasia",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c ? c : row.n_cli, row.id_cli),
      sortable: true,
    },
    {
      dataField: "n_vend",
      text: "Vendedor",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_vend),
      sortable: true,
    },
    {
      dataField: "d_emi",
      text: "Data / Hora",
      align: "center",
      formatter: (c, row) => `${c} ${row.h_emi}`,
      sortable: true,
    },
    {
      dataField: "vlr_tot_custo",
      text: "Vlr. Custo",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "v_bruto",
      text: "Vlr. Bruto",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "v_desc",
      text: "Vlr. Desconto",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "p_desc_finc",
      text: "% Desc. Fin.",
      align: "center",
      formatter: (c) => `${formatNumber(c, true, 2)} %`,
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "v_desc_fin",
      text: "Desc. Fin.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "v_bonif",
      text: "Vlr. Bonif.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "v_troca_devol",
      text: "Troca/Devol",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "v_flex",
      text: tituloComHint(
        1,
        "Vlr. Flex",
        "Valores negativos representam descontos concedidos, enquanto " +
          "valores positivos indicam vendas realizadas acima do preço de " +
          "tabela"
      ),
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "v_tot",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "resultado",
      text: "Resultado",
      align: "right",
      formatter: (c) => (
        <span
          style={parseFloat(c) < 0 ? { color: "red", fontWeight: "bold" } : {}}
        >
          {formatNumber(c, true, 2)}
        </span>
      ),
      sortable: true,
      sortFunc: numericNaturalSort,
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      isDummyField: true,
      formatter: (c, row) => (
        <DetCustoVsPrecoVendaModal id={row.id} itens={row.itens} />
      ),
    },
  ];

  return <Table columns={columns} data={dados} sizePerPage={20} paginated />;
};
