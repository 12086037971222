import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { FixedTable, SearchInput } from "../../../../../components";
import { BotaoPesquisar } from "../../../../../components/BotaoPesquisar";
import { TableCheck } from "../../../../../components/TableCheck";
import {
  debounce,
  defaultDebounceTime,
  MODAL_ACTIONS,
  selectedRowColor,
} from "../../../../../coreUtils";
import VendedorTecnicoServicoService from "../../../../../services/cadastro/VendedorTecnicoServicoService";
import ModalCadastroVTS from "./ModalCadastroVTS";
import { ModalCidadesVTS } from "./ModalCidadesVTS";
import { ModalItensVTS } from "./ModalItensVTS";

export const TableVTS = () => {
  const [dadosGrid, setDadosGrid] = useState([]);
  const [idSelecionado, setIdSelecionado] = useState(0);
  const [pista, setPista] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      sort: true,
      formatter: (cell) => <TableCheck value={cell} />,
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    setDadosGrid(await VendedorTecnicoServicoService.listar(pista));
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    setDadosGrid(await VendedorTecnicoServicoService.listar(v));
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notify = (action) => {
    switch (action) {
      case MODAL_ACTIONS.ADD:
        fetchData();
        break;
      case MODAL_ACTIONS.DELETE:
      case MODAL_ACTIONS.EDIT:
        setIdSelecionado(0);
        fetchData();
        break;
      default:
        break;
    }
  };

  return (
    <Card body>
      <Row>
        <SearchInput
          md={6}
          onChange={handlePista}
          loading={loadingPista}
          placeholder="Nome"
        />
        <BotaoPesquisar
          divClassName="pr-0"
          onClick={fetchData}
          loading={loading}
        />
        <ModalCadastroVTS idRegistro={idSelecionado} notify={notify} />
        <ModalCidadesVTS idVTS={idSelecionado} notify={notify} />
        <ModalItensVTS idVTS={idSelecionado} notify={notify} />
      </Row>
      <FixedTable
        columns={columns}
        data={dadosGrid}
        keyField="id"
        noWrap
        selectRow={{
          mode: "radio",
          clickToSelect: true,
          bgColor: selectedRowColor,
          selected: [idSelecionado],
          onSelect: (row) => setIdSelecionado(row?.id),
        }}
      />
    </Card>
  );
};
