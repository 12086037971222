import React from "react";
import { ListGroup } from "reactstrap";
import { CardTotaisItem } from "../../../../../components";

export const TotalizadoresHistoricoFlex = ({ saldoAtual, totais }) => {
  return (
    <ListGroup horizontal className="mb-2">
      <CardTotaisItem label="Lançado pela ADM" value={totais?.gerado_adm} />
      <CardTotaisItem label="Gerado na Venda" value={totais?.gerado_venda} />
      <CardTotaisItem
        label="Utlizado na Venda"
        value={totais?.utilizado_venda}
      />
      <CardTotaisItem
        label="Resultado Flex"
        value={totais?.saldo_periodo}
        emphasisNegative
        hintText={
          <>
            Resultado Flex é o valor de perda ou ganho sobre o processo de flex.
            <br />
            Valores negativos representam perda
            <br />
            Valores positivos representam ganho
          </>
        }
      />
      <CardTotaisItem label="Saldo Atual" value={saldoAtual} />
    </ListGroup>
  );
};
