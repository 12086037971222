import React, { useState } from "react";
import { PageContainer } from "../../../../components/PageContainer";
import ComissaoVTSService from "../../../../services/relatorios/comissionamento/ComissaoVTSService";
import { FiltrosComissionamentoVTS } from "./components/FiltrosComissionamentoVTS";
import { TabelasComissionamentoVTS } from "./components/TabelasComisionamentoVTS";

export const ComissionamentoVTS = () => {
  const [dadosRelatorio, setDadosRelatorio] = useState([]);
  const buscarDadosRelatorio = async (filters) => {
    setDadosRelatorio(await ComissaoVTSService.buscarDadosNivelVTS(filters));
  };

  const limparDados = () => setDadosRelatorio([]);

  return (
    <PageContainer title="Comissonamento de VTS" number="5014" canGoBack>
      <FiltrosComissionamentoVTS
        buscarDadosRelatorio={buscarDadosRelatorio}
        limparDados={limparDados}
      />
      {dadosRelatorio.length > 0 && (
        <TabelasComissionamentoVTS dados={dadosRelatorio} />
      )}
    </PageContainer>
  );
};
