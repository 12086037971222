import { apiDeleteV2, apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/comissao/regra_comis";
const URL_BASE_ITEM = `${URL_BASE}/item`;
const URL_BASE_MOV_ESCALONADA = `${URL_BASE}/mov_escalonada`;
const URL_BASE_EXCECAO = `${URL_BASE}/excecao`;

const RegrasComissaoService = {
  urlBase: URL_BASE,
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  buscar: (id) => apiGetV2(`${URL_BASE}/buscar/${id}/`),
  excecao: {
    listar: (idMov) => apiGetV2(`${URL_BASE_EXCECAO}/listar/${idMov}/`),
    incluir: (payload) => apiPostV2(`${URL_BASE_EXCECAO}/incluir/`, payload),
    excluir: (idExcecao) =>
      apiDeleteV2(`${URL_BASE_EXCECAO}/excluir/${idExcecao}/`),
  },
  item: {
    listar: (idRegra) => apiGetV2(`${URL_BASE_ITEM}/listar/${idRegra}/`),
    incluir: (payload) => apiPostV2(`${URL_BASE_ITEM}/incluir/`, payload),
    excluir: (idProdRegra) =>
      apiDeleteV2(`${URL_BASE_ITEM}/excluir/${idProdRegra}/`),
  },
  movEscalonada: {
    listar: (idRegra, params) =>
      apiGetV2(`${URL_BASE_MOV_ESCALONADA}/listar/${idRegra}/`, params),
    incluir: (payload) =>
      apiPostV2(`${URL_BASE_MOV_ESCALONADA}/incluir/`, payload),
    excluir: (idMov) =>
      apiDeleteV2(`${URL_BASE_MOV_ESCALONADA}/excluir/${idMov}/`),
  },
};

export default RegrasComissaoService;
