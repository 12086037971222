import { apiGet } from "../api";

const PercentualVendasPorVendedorService = {
  fetch: async (dataIni, dataFim, fabricante, grupo, vendedor, agruparPor) => {
    const params_ = {
      data_ini: dataIni,
      data_fim: dataFim,
      agrupar_por: agruparPor,
    };

    if (fabricante?.length > 0) params_["fabricante"] = `[${fabricante}]`;
    if (grupo?.length > 0) params_["grupo"] = `[${grupo}]`;
    if (vendedor?.length > 0) params_["vendedor"] = `[${vendedor}]`;

    const searchParams = new URLSearchParams(params_).toString();

    return await apiGet(
      `/relatorios/venda/participacao_vendas/?${searchParams}`
    );
  },
};

export default PercentualVendasPorVendedorService;
