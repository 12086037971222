import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

export const IconLink = ({
  icon: Icon,
  size = 16,
  color,
  disabled,
  hint,
  id,
  className,
  pathname,
  state,
  disabledHint,
  tooltipPlacement,
}) => {
  const [internalId] = useState(
    id ?? "ib-" + Math.floor(Math.random() * Date.now())
  );
  const history = useHistory();
  return (
    <>
      <Icon
        size={size}
        onClick={!disabled ? (e) => history.push(pathname, state) : () => {}}
        color={!disabled ? color : "#a6a6a6"}
        id={internalId}
        style={{ cursor: !disabled ? "pointer" : "default" }}
        className={className}
      />
      {hint && (
        <UncontrolledTooltip target={internalId} placement={tooltipPlacement}>
          {!disabled ? hint : disabledHint ?? hint}
        </UncontrolledTooltip>
      )}
    </>
  );
};
