import React, { useEffect, useRef, useState } from "react";
import {
  AsyncComboBox,
  Divider,
  FixedField,
  FormButton,
  NumberInput,
  PageContainer,
  Table,
  TableDelete,
} from "../../../../../components";
import RemessaEntreEmpresasService from "../../../../../services/faturamento/RemessaEntreEmpresasService";
import {
  MODAL_ACTIONS,
  formatNumber,
  roundFloat,
} from "../../../../../coreUtils";
import { Card, Row } from "reactstrap";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import { showWarning } from "../../../../../components/AlertaModal";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { enviarRemessaEntreEmpresasRoute } from "../../../../../routes/modules/estoque";

export const IncluirAlterarRemessa = ({ location }) => {
  const selected = location?.state?.selected;
  const action = location?.state?.action ?? MODAL_ACTIONS.ADD;
  const [idDestino, setIdDestino] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [idProduto, setIdProduto] = useState(null);
  const [nomeProduto, setNomeProduto] = useState("");
  const [unidade, setUnidade] = useState("");
  const [quantidade, setQuantidade] = useState(0);
  const [vlrCustoUnit, setVlrCustoUnit] = useState(0);
  const [vlrCustoTotal, setVlrCustoTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const pesqProdRef = useRef();
  const quantidadeRef = useRef();

  const carregarDados = async () => {
    const [ok, ret] = await RemessaEntreEmpresasService.buscar(selected);
    if (ok) {
      setIdDestino(ret.id_destino);
      setProdutos(
        ret.produtos.map((e) => ({
          id_produto: e.id_produto,
          nome_prod: e.nome_prod,
          unidade: e.unidade,
          quantidade: parseFloat(e.quantidade),
          vlr_custo_unit: parseFloat(e.vlr_custo_unit),
          vlr_custo_total: parseFloat(e.vlr_custo_total),
        }))
      );
    }
  };

  const limparDadosProduto = () => {
    setIdProduto(null);
    setNomeProduto("");
    setUnidade("");
    setQuantidade(0);
    setVlrCustoUnit(0);
    setVlrCustoTotal(0);
  };

  const handleSelectProduto = ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    unidade,
    custoUe,
  }) => {
    if (idProduto !== id) {
      setIdProduto(id);
      setNomeProduto(nome);
      setUnidade(unidade);
      setVlrCustoUnit(custoUe);
      if (pesqProdRef.current) {
        pesqProdRef.current.setDescricao(nome);
        pesqProdRef.current.setId(String(id));
        pesqProdRef.current.setReferencia(String(referencia));
      }
    }
  };

  const selectNextFieldProduto = () =>
    quantidadeRef.current && quantidadeRef.current.focus();

  useEffect(() => {
    setVlrCustoTotal(roundFloat(quantidade * vlrCustoUnit, 2));
  }, [quantidade, vlrCustoUnit]);

  const handleAddProduto = () => {
    if ([0, null, undefined].includes(idProduto)) {
      showWarning("Por favor, selecione um Produto");
      return;
    }

    if (produtos.findIndex((e) => e.id_produto === idProduto) > -1) {
      showWarning("O Produto informado já foi lançado.");
      return;
    }

    const payload = {
      id_produto: idProduto,
      nome_prod: nomeProduto,
      unidade: unidade,
      quantidade: quantidade,
      vlr_custo_unit: vlrCustoUnit,
      vlr_custo_total: vlrCustoTotal,
    };
    setProdutos([...produtos, payload]);
    limparDadosProduto();
    if (pesqProdRef.current) {
      pesqProdRef.current.clear();
      pesqProdRef.current.focus();
    }
  };

  const handleDeleteProduto = (id_produto) =>
    setProdutos(produtos.filter((e) => e.id_produto !== id_produto));

  const handleAlterarProduto = (coluna, novoValor, row) => {
    const valorAntesTest = roundFloat(parseFloat(row[coluna]), 2);
    const novoValorTest = roundFloat(parseFloat(novoValor), 2);
    if (valorAntesTest === novoValorTest) {
      return;
    }

    if (coluna === "quantidade") {
      row.quantidade = parseFloat(novoValor);
    } else if (coluna === "vlr_custo_unit") {
      row.vlr_custo_unit = parseFloat(novoValor);
    }

    row.vlr_custo_total = roundFloat(
      parseFloat(row.vlr_custo_unit) * parseFloat(row.quantidade),
      2
    );

    setProdutos(
      produtos.map((e) => (e.id_produto === row.id_produto ? row : e))
    );
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idDestino)) {
      showWarning("Por favor, infrome o Destino da Remessa");
      return;
    }

    if (produtos.length === 0) {
      showWarning("Por favor, informe ao menos um produto");
      return;
    }

    const commonPayload = {
      id_destino: idDestino,
      produtos: produtos.map((e) => ({
        id_produto: e.id_produto,
        nome_prod: e.nome_prod,
        unidade: e.unidade,
        quantidade: e.quantidade,
        vlr_custo_unit: e.vlr_custo_unit,
      })),
    };

    const submitFunc =
      action === MODAL_ACTIONS.ADD
        ? RemessaEntreEmpresasService.incluir
        : RemessaEntreEmpresasService.alterar;

    const payload =
      action === MODAL_ACTIONS.ADD
        ? commonPayload
        : { ...commonPayload, id_remessa: selected };

    setLoadingSubmit(true);
    const [ok] = await submitFunc(payload);
    if (ok) {
      setRedirect(true);
    }
    setLoadingSubmit(false);
  };

  const iniciarTela = async () => {
    if (action === MODAL_ACTIONS.EDIT) {
      await carregarDados();
    }

    setLoading(false);
  };

  useEffect(() => {
    iniciarTela();
  }, []);

  const columns = [
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_prod", text: "Nome", align: "left" },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
      editable: true,
      editorType: "number",
      onChange: handleAlterarProduto,
      alwaysShowEditor: true,
    },
    { dataField: "unidade", text: "UN", align: "center" },
    {
      dataField: "vlr_custo_unit",
      text: "Custo Unit.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
      editable: true,
      editorType: "number",
      onChange: handleAlterarProduto,
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_custo_total",
      text: "Custo Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDeleteProduto(row.id_produto)} />
      ),
    },
  ];

  if (redirect) {
    return (
      <Redirect
        to={enviarRemessaEntreEmpresasRoute.path}
        state={{ refresh: true }}
      />
    );
  }

  return (
    <PageContainer
      title="Remessa Entre Empresas"
      number="0098_1"
      loading={loading}
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            label="Destino"
            isConcatField
            concatModelName="organizacao"
            defaultValue={idDestino}
            onChange={(s) => setIdDestino(s?.value)}
            defaultOptions
          />
        </Row>
        <Divider>Produtos</Divider>
        <Row>
          <PesqProduto
            md={7}
            onConfirm={handleSelectProduto}
            ref={pesqProdRef}
            selectNextField={selectNextFieldProduto}
            onChangeDescricao={setNomeProduto}
          />
          <FixedField
            label="Unidade"
            value={unidade}
            horizontal
            divClassName="pt-4"
          />
        </Row>
        <Row className="mb-2">
          <NumberInput
            md={2}
            label="Quantidade"
            value={quantidade}
            onChange={setQuantidade}
            ref={quantidadeRef}
          />
          <NumberInput
            md={2}
            label="Custo Unitário"
            value={vlrCustoUnit}
            onChange={setVlrCustoUnit}
          />
          <NumberInput
            md={2}
            label="Custo Total"
            value={vlrCustoTotal}
            disabled
          />
          <FormButton md="auto" color="info" onClick={handleAddProduto}>
            Incluir
          </FormButton>
        </Row>
        <Table
          data={produtos}
          columns={columns}
          paginated={false}
          keyField="id_produto"
          pageSize={15}
        />
        <Row>
          <FormButton
            divClassName="ml-auto"
            md="auto"
            color="success"
            onClick={handleSubmit}
            loading={loadingSubmit}
            padded={false}
          >
            F9 - Confirmar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
