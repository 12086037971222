import React from "react";
import { formatNumber } from "../coreUtils";
import { Star } from "react-feather";

const OrderedTooltip = ({
  active,
  payload,
  label,
  showTotal,
  totalLabel = "Total",
  totalSumFunc,
  isRank,
  hideValues,
  formatter,
}) => {
  let total = 0;
  if (payload) {
    payload.sort((a, b) => {
      if (a.value < b.value) {
        return 1;
      } else if (a.value > b.value) {
        return -1;
      }
      return 0;
    });

    if (showTotal) {
      if (totalSumFunc) {
        total = totalSumFunc(payload);
      } else {
        payload.forEach((element) => {
          total += element.value;
        });
      }
    }
  }
  if (payload) {
    return (
      <div
        className={"recharts-default-tooltip"}
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          whiteSpace: "nowrap",
        }}
      >
        <p className={"recharts-tooltip-label"} style={{ margin: 0 }}>
          {label}
        </p>
        <ul
          className={"recharts-tooltip-item-list"}
          style={{ padding: 0, margin: 0 }}
        >
          {payload.map((element, i) => {
            const { name, value, unit, color } = element;
            return (
              <li
                className="recharts-tooltip-item"
                key={`tooltip-item-${i}`}
                style={{
                  display: "block",
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: color || "#2b2b2b",
                }}
              >
                <span className="recharts-tooltip-item-name">
                  {name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()}
                </span>
                <span className="recharts-tooltip-item-separator">: </span>
                <span
                  className="recharts-tooltip-item-value"
                  style={
                    hideValues
                      ? { filter: "blur(5px)", userSelection: "none" }
                      : {}
                  }
                >
                  {formatter
                    ? formatter(hideValues ? 9999.99 : value, element)
                    : formatNumber(hideValues ? 9999.99 : value)}
                </span>
                <span className="recharts-tooltip-item-unit">{unit || ""}</span>
                {isRank && i === 0 && (
                  <Star
                    className="pl-1 pb-1"
                    size={23}
                    color={"#FFD700"}
                    fill={"#FFD700"}
                  />
                )}
              </li>
            );
          })}
        </ul>
        {showTotal && (
          <p
            className="recharts-tooltip-label"
            style={{ margin: 0, fontWeight: "bold" }}
          >
            {totalLabel}: {formatNumber(total)}
          </p>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default OrderedTooltip;
