import React, { useState } from "react";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import {
  FormButton,
  NumberInput,
  SearchInput,
  Table,
} from "../../../../../components";
import { TableDelete } from "../../../../../components/TableDelete";
import { Row } from "reactstrap";

export const DetalhesMetaVendaGrid = ({
  dados,
  handleDelete,
  handleEditDetalhe,
  atualizarValoresItens,
  pista,
  handlePista,
  loadingPista,
  handleAumentarValorPorPerc,
  mostrarPercInfoBase,
}) => {
  const columns = [
    {
      dataField: "desc_tipo",
      text: "Meta Para",
      align: "center",
    },
    {
      dataField: "nome_tipo",
      text: "Descrição",
      align: "left",
      formatter: (c, row) => (
        <span style={row.meta_qtd_venda === 0 ? { color: "red" } : {}}>
          {formatValueFromAPI(c, row.id_tipo)}
        </span>
      ),
    },
    {
      dataField: "preco_tipo",
      text: "Preço de Venda",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "meta_qtd_venda",
      text: "Meta de Quantidade de Venda",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      onChange: handleEditDetalhe,
      onBlur: atualizarValoresItens,
      editable: true,
      editorType: "number",
      decimalPlaces: 0,
      alwaysShowEditor: true,
    },
    {
      dataField: "meta_vlr_faturado",
      text: "Meta de Valor de Vendas",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "perc_info_base_venda",
      text: "Aumento na Inclusão",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true) + " %",
      hidden: !mostrarPercInfoBase,
    },
    {
      dataField: "meta_perc_max_devol_troca",
      text: "% Máx. Devol. Troca",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true) + " %",
      onChange: handleEditDetalhe,
      onBlur: atualizarValoresItens,
      editable: true,
      editorType: "number",
      alwaysShowEditor: true,
    },
    {
      dataField: "act",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  const [percAumentarValorMeta, setPercAumentarValorMeta] = useState(0);

  const handleAumentarValorInterno = () => {
    handleAumentarValorPorPerc(percAumentarValorMeta);
    setPercAumentarValorMeta(null);
  };

  return (
    <>
      <Row className="mb-3">
        <SearchInput
          value={pista}
          md={6}
          onChange={handlePista}
          loading={loadingPista}
          placeholder="Nome do Produto"
        />
        {handleAumentarValorPorPerc && (
          <>
            <NumberInput
              isPercentage
              label={
                <>
                  Aumentar <b>toda</b> meta em %
                </>
              }
              md={2}
              divClassName="ml-auto"
              value={percAumentarValorMeta}
              onChange={setPercAumentarValorMeta}
            />
            <FormButton
              onClick={() => handleAumentarValorInterno()}
              disabled={[0, null, undefined].includes(percAumentarValorMeta)}
              disabledHint="Informe um percentual para aumento da Meta"
            >
              Calcular
            </FormButton>
          </>
        )}
      </Row>
      <Table
        columns={columns}
        data={dados ?? []}
        keyField="uuid"
        pageSize={10}
        paginated={false}
      />
    </>
  );
};
