import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useLocation } from "react-router";
import { Card, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import {
  PageContainer,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotaisItem,
  FiltroPeriodoDatas,
} from "../../../../../components";
import {
  formatDateISO,
  formatNumber,
  formatValueFromAPI,
} from "../../../../../coreUtils";
import { ExtratoClienteGrid } from "./components/ExtratoClienteGrid";
import { apiGetV2 } from "../../../../../apiV2";
import { docPrintReport } from "../../../../../pdf";

export const ExtratoCliente = () => {
  const location = useLocation();
  const [dataIni, setDataIni] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );
  const [dataFim, setDataFim] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [totais, setTotais] = useState({});

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const cliente = formatValueFromAPI(
    location.state.nome,
    location.state.id_cliente
  );

  const buscarDados = async () => {
    if (!(dataIni instanceof Date)) {
      toastr.warning("Atenção", "Por favor, revise a data inicial.");
      return [false, null];
    }

    if (!(dataFim instanceof Date)) {
      toastr.warning("Atenção", "Por favor, revise a data final.");
      return [false, null];
    }

    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
    };

    return await apiGetV2(
      `/relatorios/venda/extrato_cliente/${location.state.id_cliente}/`,
      params
    );
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret.movimentos : []);
    setTotais(ok ? ret.totais : {});
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        dados: ret.movimentos,
        totais: ret.totais,
        cliente: cliente,
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
      };

      await docPrintReport(
        "/relatorios/venda/extrato_cliente/",
        payload,
        "5030"
      );
    }
    setLoadingImprimir(false);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
    limparDados();
  };

  useEffect(() => {
    carregarDados();
  }, []);

  return (
    <PageContainer
      title={`Histórico Financeiro - ${cliente}`}
      number="0040_5"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas
            label="Emissão da Venda"
            onChange={handleDate}
            defaultOption="MESATUANT"
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <Card body>
        <Row>
          <Col md={5}>
            <ListGroup horizontal>
              <CardTotaisItem label="Total Vendas" value={totais?.venda} />
              <CardTotaisItem label="Total O.S's" value={totais?.os} />
            </ListGroup>
          </Col>
          <Col md={7} style={{ borderLeft: "1px solid #dee2e6" }}>
            <ListGroup horizontal className="mb-1">
              <CardTotaisItem
                label="Cartão"
                value={totais?.moedas?.cartao}
                style={{ maxWidth: "36%" }}
              />
              <CardTotaisItem label="Cheque" value={totais?.moedas?.cheque} />
            </ListGroup>
            <ListGroup horizontal className="mb-1">
              <CardTotaisItem
                label="Crédito Cliente"
                value={totais?.moedas?.credito_cliente}
                style={{ maxWidth: "36%" }}
              />
              <CardTotaisItem
                label="Crédito Rotativo"
                value={totais?.moedas?.credito_rotativo}
              />
            </ListGroup>
            <ListGroup horizontal className="mb-1">
              <CardTotaisItem
                label="Dinheiro"
                value={totais?.moedas?.dinheiro}
                style={{ maxWidth: "36%" }}
              />
              <ListGroupItem>
                <div>
                  <span>Contas a Receber - Recebido: </span>
                  <strong>
                    <span>
                      {formatNumber(
                        totais?.moedas?.duplicata_receber_recebido ?? 0,
                        true,
                        2
                      )}
                    </span>
                  </strong>
                </div>
                <div>
                  Receber:{" "}
                  <strong>
                    <span>
                      {formatNumber(
                        totais?.moedas?.duplicata_receber_em_aberto ?? 0,
                        true,
                        2
                      )}
                    </span>
                  </strong>
                </div>
              </ListGroupItem>
            </ListGroup>
            <ListGroup horizontal>
              <CardTotaisItem
                label="Transf. Bancária"
                value={totais?.moedas?.transferencia_bancaria}
                style={{ maxWidth: "36%" }}
              />
              <CardTotaisItem
                label="Em Aberto no Caixa Loja"
                value={totais?.moedas?.em_aberto}
              />
            </ListGroup>
          </Col>
        </Row>
      </Card>
      <Card body>
        <Row>
          <span style={{ marginLeft: "20px", marginTop: "3px" }}>
            Movtos MANUAIS -
          </span>
          <Col className="pl-0">
            <ListGroup horizontal>
              <CardTotaisItem
                label="Cartão de Crédito"
                value={totais?.movs_manuais?.cartao}
                className="pl-1"
              />
              <CardTotaisItem
                label="Cheque"
                value={totais?.movs_manuais?.cheque}
              />
              <CardTotaisItem
                label="Crédito Cliente"
                value={totais?.movs_manuais?.credito_cliente}
              />
              <CardTotaisItem
                label="Contas a Receber"
                value={totais?.movs_manuais?.duplicata_receber}
              />
            </ListGroup>
          </Col>
        </Row>
      </Card>
      <ExtratoClienteGrid data={dados} />
    </PageContainer>
  );
};
