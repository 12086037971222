import React, { useState } from "react";
import { ModalBase, TextInput } from "../../../../../components";
import { Row } from "reactstrap";
import { OrdemCompraService } from "../../../../../services/compras/OrdemCompraService";

export const EmailOrdemCompraModal = ({
  isOpen,
  toggle,
  ordemCompraSelected,
  emailEnviarOrdemCompra,
  setEmailEnviarOrdemCompra,
}) => {
  const [loading, setLoading] = useState(false);
  const limparDados = () => {
    setEmailEnviarOrdemCompra("");
  };

  const handleSubmit = async () => {
    const payload = {
      id_ordem_compra: ordemCompraSelected,
      email_enviar: emailEnviarOrdemCompra,
    };
    setLoading(true);
    const [ok] = await OrdemCompraService.enviarEmail(payload);
    if (ok) {
      toggle();
    }
    setLoading(false);
  };
  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Enviar E-Mail de Ordem de Compra"
      number="0016_7"
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row>
        <TextInput
          md={12}
          label="Enviar Para Email"
          value={emailEnviarOrdemCompra}
          onChange={(e, v) => setEmailEnviarOrdemCompra(v)}
          forceUppercase={false}
        />
      </Row>
    </ModalBase>
  );
};
