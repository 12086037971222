import React, { useState } from "react";
import { Row } from "reactstrap";
import { ModalBase } from "../../../../../../components/ModalBase";
import RemessaBancariaService from "../../../../../../services/bancario/RemessaBancariaService";
import { FormCheckbox } from "../../../../../../components";

export const ConfirmarReabRemessaModal = ({ id, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reabrirTitulo, setReabrirTitulo] = useState(false);

  const toggle = () => {
    setReabrirTitulo(false);
    setIsOpen(!isOpen);
  };

  const reabrirRemessa = async () => {
    setLoading(true);
    const payload = { id_remessa: id, reabrir_boletos: reabrirTitulo };
    const [ok] = await RemessaBancariaService.reabrirRemessa(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <div id={`reab-rem-${id}`} onClick={() => toggle()} />
      {isOpen && (
        <ModalBase
          isOpen={isOpen}
          size="md"
          title="Reabertura de Remessa"
          onConfirm={reabrirRemessa}
          loadingConfirm={loading}
          toggle={toggle}
          autoFocus
        >
          <Row className="justify-content-center px-2">
            <p
              style={{ textAlign: "left", paddingInline: "5px" }}
              className="mb-2"
            >
              Advertência!
              <br />
              Caso o boleto tenha sido enviado e registrado no banco, é
              necessário que você entre no Internet Banking e cancele o boleto
              no sistema do banco.
              <br />
              Atenção: o cliente pode ter recebido a cobrança do boleto que você
              está reabrindo.
              <br />
              <br />
              Tem certeza de que deseja reabrir a remessa {id}?
            </p>
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Reabrir boleto totalmente quando o boleto for retirado de uma remessa"
              checked={reabrirTitulo}
              onChange={() => setReabrirTitulo(!reabrirTitulo)}
              hint={
                "A opção marcada irá resetar as informações do título, ou seja, zerar nosso número, linha digitável. " +
                "O que obrigatoriamente obriga uma nova geração e envio do boleto pela empresa para o cliente. " +
                "A opção desmarcada irá simplesmente reabrir o boleto para que seja reincluído em outra remessa."
              }
            />
          </Row>
        </ModalBase>
      )}
    </>
  );
};
