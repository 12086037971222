import { apiGet, apiPost, urlParams } from "../../api";

const URL_BASE = "/pedido_externo/central/conferencia_dinheiro";
const ConferenciaDinheiroService = {
  listar: async (params) =>
    await apiGet(`${URL_BASE}/listar/${urlParams(params)}`),
  conferir: async (payload) => await apiPost(`${URL_BASE}/conferir/`, payload),
};

export default ConferenciaDinheiroService;
