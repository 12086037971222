import React from "react";
import { Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

const columns = [
  { dataField: "id", text: "#", align: "center", headerAlign: "center" },
  {
    dataField: "desc_origem",
    text: "Origem",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nro_origem",
    text: "Nº Origem",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "data_mov",
    text: "Data",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "hora_mov",
    text: "Hora",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "historico",
    text: "Histórico",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const DetalhesCaixaLojaGrid = ({ detalhes }) => {
  return (
    <Table
      data={detalhes}
      columns={columns}
      paginated={false}
      showRegisterCount={false}
      pageSize={10}
    />
  );
};
