import React from "react";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { Table, TableDelete } from "../../../../../components";

export const TaxasCartaoGrid = ({ taxas, excluirTaxa, compensado }) => {
  const columns = [
    {
      dataField: "natureza",
      text: "Natureza",
      align: "center",
    },
    {
      dataField: "transacao",
      text: "Trans",
      align: "center",
    },
    {
      dataField: "usuario",
      text: "Usuário",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.nome_usuario),
    },
    {
      dataField: "data_hora",
      text: "Data/Hora",
      align: "center",
    },
    {
      dataField: "histor",
      text: "Histórico",
      align: "left",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c, row) => formatNumber(c, true, 2),
    },
    {
      dataField: "act",
      text: "",
      align: "right",
      hidden: compensado,
      formatter: (c, row) => (
        <TableDelete onClick={() => excluirTaxa(row.id)} />
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      data={taxas}
      paginated={false}
      pageSize={5}
      showRegisterCount={false}
    />
  );
};
