import React, { useState } from "react";
import {
  FixedField,
  ModalBase,
  NumberInput,
  TextInput,
  PesqPlanoCtaCentroCusto,
} from "../../../../components";
import { Row } from "reactstrap";
import CaixaLojaService from "../../../../services/financeiro/CaixaLojaService";

export const ReforcoCaixaModal = ({
  isOpen,
  toggle,
  idCaixa,
  saldoDinheiro,
  nomeOperador,
  mostraSaldoFechamento,
}) => {
  const [vlrReforco, setVlrReforco] = useState(0);
  const [historico, setHistorico] = useState("");
  const [idPlanoConta, setIdPlanoConta] = useState(0);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setVlrReforco(0);
    setHistorico("");
  };

  const _internalToggle = () => {
    if (!isOpen) {
      limparDados();
    }
    toggle();
  };

  const handleSubmit = async () => {
    setLoading(true);

    const payload = {
      nro_caixa: idCaixa,
      id_plano_conta: idPlanoConta,
      historico: historico,
      valor: vlrReforco,
    };

    const ret = await CaixaLojaService.reforcoCaixa(payload);
    if (ret) {
      _internalToggle();
    }
    setLoading(false);
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={_internalToggle}
        size="md"
        title="Reforco de Caixa"
        number="0063_9"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row className="mb-2">
          <FixedField horizontal label="Nº Caixa" value={idCaixa} />
          <FixedField horizontal label="Operador" value={nomeOperador} />
          {mostraSaldoFechamento && (
            <FixedField
              horizontal
              label="Saldo em Dinheiro"
              value={saldoDinheiro}
            />
          )}
        </Row>
        <Row>
          <PesqPlanoCtaCentroCusto
            md={12}
            label="Plano de Contas"
            mode="plano_contas"
            onChange={(v) => setIdPlanoConta(v ?? 0)}
            value={idPlanoConta}
          />
        </Row>
        <Row>
          <NumberInput
            md={4}
            label="Valor de Reforco"
            value={vlrReforco}
            onChange={setVlrReforco}
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Motivo"
            value={historico}
            onChange={(e, v) => setHistorico(v)}
            maxLength={200}
          />
        </Row>
      </ModalBase>
    </>
  );
};
