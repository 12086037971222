import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  ComboBox,
  FormButton,
  Table,
  TableDelete,
  TextInput,
} from "../../../../../../../components";
import {
  MODAL_ACTIONS,
  ufs,
  useStateWithRef,
} from "../../../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../../../components/cadastro";
import { modalTitlePercursoMDFe, routesBasePercursoMDFe } from "../Percurso";

export const CadastroPercursoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [descricao, setDescricao] = useState("");
  const [ufsPerc, setUfsPerc, ufsPercRef] = useStateWithRef([]);
  const [ufAtu, setUfAtu] = useState(null);
  const selectRef = useRef();

  const limparDados = () => {
    setDescricao("");
    setUfsPerc([]);
    setUfAtu(null);
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setUfsPerc(data.ufs);
  };

  const submitPayload = (action) => {
    let data = {
      descricao: descricao,
      ufs: ufsPerc,
    };

    return action === MODAL_ACTIONS.EDIT ? { ...data, id: selected } : data;
  };

  const addUf = () => {
    setUfsPerc([...ufsPercRef.current, { uf: ufAtu }]);
    setUfAtu(null);
    selectRef.current.clearValue();
    selectRef.current.setFocus();
  };

  const deleteUf = (i) => {
    setUfsPerc(ufsPercRef.current.filter((e, index) => index !== i));
  };

  const columns = [
    {
      dataField: "uf",
      text: "UF",
      align: "center",
      sortable: true,
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      align: "center",
      formatter: (c, row, index) => (
        <TableDelete onClick={() => deleteUf(index)} />
      ),
    },
  ];

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitlePercursoMDFe}
      size="md"
      fetchData={fetchData}
      onClose={limparDados}
      submitPayload={submitPayload}
      routesBase={routesBasePercursoMDFe}
      selected={selected}
      notifyEvent={notifyEvent}
      number="0042_71"
      concatShortcut={concatShortcut}
      concatModelName="percurso_mdfe"
    >
      <Row>
        <TextInput
          label="Nome"
          md={8}
          onChange={(e, v) => setDescricao(v)}
          value={descricao}
          maxLength={60}
          autoFocus
        />
      </Row>
      <Row>
        <ComboBox
          options={ufs}
          md={2}
          label="UF"
          onChange={(s) => setUfAtu(s?.value)}
          ref={selectRef}
        />
        <FormButton
          onClick={addUf}
          color="success"
          md="auto"
          disabled={!ufAtu}
          disabledHint="Selecione uma UF no campo à esquerda"
        >
          Adicionar
        </FormButton>
      </Row>
      <Table
        columns={columns}
        data={ufsPerc}
        keyField="uf"
        paginated={false}
        pageSize={7}
      />
    </ModalCadastroV2>
  );
};
