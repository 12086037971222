import React from "react";
import { BsCheck } from "react-icons/bs";
import { Table, TableCheck } from "../../../../../components";
import {
  iconeAlterar,
} from "../../../../../components/cadastro";

export const CentroCustoGrid = ({ dados, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "classificacao",
      text: "Classificação",
      align: "left",
      sortable: true,
    },
    {
      id: "desc",
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      sortable: true,
      formatter: (desc, row) =>
        row.aceita_lcto ? (
          <span style={{ fontWeight: "bold" }}>{desc}</span>
        ) : (
          <span style={{ fontWeight: "normal" }}>{desc}</span>
        ),
    },
    { dataField: "nivel", text: "Nível", align: " ", sortable: true },
    {
      dataField: "natureza",
      text: "Natureza",
      align: "center",
      sortable: true,
    },
    {
      dataField: "aceita_lcto",
      text: "Aceita Lançamento",
      align: "center",
      formatter: (cell) =>
        cell ? <BsCheck size={16} color="#5fc27e" /> : <></>,
      sortable: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sortable: true,
    },
    iconeAlterar(alterar),
  ];
  return (
    <Table
      data={dados}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
