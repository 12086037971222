import React, { useState } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import { FiltroPeriodoDatas, RadioGroup } from "../../../../../components";
import { RadioItem } from "../../../../../components/RadioGroup";
import { downloadFileFromBlob, formatDateISO } from "../../../../../coreUtils";
import { Col, Row } from "reactstrap";
import { useCallback } from "react";
import { useEffect } from "react";

export const ExportarModal = ({ isOpen, toggle }) => {
  const [tipoExportacao, setTipoExportacao] = useState("XML");
  const [dataIni, setDataIni] = useState();
  const [dataFim, setDataFim] = useState();
  const [qtdNfesPendEmissao, setQtdNfesPendEmissao] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarQtdNfesPendEmissao = useCallback(async () => {
    setIsLoading(true);
    try {
      if (dataIni instanceof Date && dataFim instanceof Date && isOpen) {
        const [ok, ret] =
          await NotaFiscalService.gerenciamento.buscarQtdNfesPendEmissao(
            dataIni,
            dataFim
          );
        if (ok) {
          setQtdNfesPendEmissao(ret.qtd_notas_pend);
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [dataIni, dataFim, isOpen]);

  useEffect(() => {
    buscarQtdNfesPendEmissao();
  }, [buscarQtdNfesPendEmissao]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (tipoExportacao === "XML") {
        const ret = await NotaFiscalService.gerenciamento.exportarXmls(
          dataIni,
          dataFim
        );

        if (ret) {
          const dIni = formatDateISO(dataIni).replaceAll("-", "");
          const dFim = formatDateISO(dataFim).replaceAll("-", "");
          const filename = `xmls_nfe_modelo55_${dIni}_ate_${dFim}`;
          downloadFileFromBlob(ret, filename);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Exportação de NF-e"
      number="0055_10"
      autoFocus
      hideCancelButton
      onConfirm={handleSubmit}
      loadingConfirm={isLoading}
    >
      <Row>
        <RadioGroup
          label="Tipo de Exportação"
          onChange={setTipoExportacao}
          value={tipoExportacao}
        >
          <RadioItem label="XML" value="XML" />
        </RadioGroup>
        {tipoExportacao === "XML" && (
          <FiltroPeriodoDatas onChange={handleDate} />
        )}
      </Row>
      {qtdNfesPendEmissao > 0 && (
        <Row
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            marginRight: "-15px",
          }}
        >
          <Col
            md={12}
            className="py-2"
            style={{
              background: "#F44455",
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {qtdNfesPendEmissao > 1
              ? `Existem ${qtdNfesPendEmissao} NF-es pendentes`
              : `Existe uma NF-e pendente`}{" "}
            de emissão no período informado acima
          </Col>
        </Row>
      )}
    </ModalBase>
  );
};
