import React from "react";
import { Card, UncontrolledTooltip } from "reactstrap";
import { Table } from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";

const tituloComHint = (id, title) => {
  return (
    <>
      <span id={`hint_title_${id}`}>{title}</span>
      <UncontrolledTooltip target={`hint_title_${id}`}>
        Valores referentes ao mês atual
      </UncontrolledTooltip>
    </>
  );
};

export const VisaoJornadasAbertas = ({ dados, selectRota }) => {
  const columns = [
    {
      dataField: "id_rota_mob__nome",
      text: "Rota",
      align: "left",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "id_rota_mob__id_colaborador__nome",
      text: "Vendedor",
      align: "left",
    },
    {
      dataField: "gerado_adm",
      text: tituloComHint(3, "Lançado pela ADM"),
      align: "right",
      colHeaderStyle: { backgroundColor: "rgb(187, 201, 255)" },
      style: { backgroundColor: "rgb(187, 201, 255)" },
      sortable: true,
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: naturalSort,
    },
    {
      dataField: "gerado_venda",
      text: tituloComHint(4, "Gerado na Venda"),
      align: "right",
      sortable: true,
      colHeaderStyle: { backgroundColor: "rgb(187, 201, 255)" },
      style: { backgroundColor: "rgb(187, 201, 255)" },
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: naturalSort,
    },
    {
      dataField: "utilizado_venda",
      text: tituloComHint(5, "Utilizado na Venda"),
      align: "right",
      sortable: true,
      colHeaderStyle: { backgroundColor: "rgb(187, 201, 255)" },
      style: { backgroundColor: "rgb(187, 201, 255)" },
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_saldo_flex",
      text: tituloComHint(6, "Saldo de Flex"),
      align: "right",
      sortable: true,
      colHeaderStyle: { backgroundColor: "rgb(187, 201, 255)" },
      style: { backgroundColor: "rgb(187, 201, 255)" },
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_saldo_bonifica",
      text: tituloComHint(7, "Saldo de Bonificação"),
      align: "right",
      sortable: true,
      colHeaderStyle: { backgroundColor: "rgb(218, 196, 255)" },
      style: { backgroundColor: "rgb(218, 196, 255)" },
      formatter: (v) => formatNumber(v, true, 2),
      sortFunc: naturalSort,
    },
  ];

  return (
    <>
      <Card body>
        <Table
          data={dados}
          columns={columns}
          keyField="id_rota_mob"
          paginated={false}
          onSelect={selectRota}
        />
      </Card>
    </>
  );
};
