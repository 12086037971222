import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  FiltroPeriodoDatas,
  PageContainer,
  TextInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import FracionamentoNfEntradaService from "../../../../services/compras/FracionamentoNfEntradaService";
import { FracionamentoNfEntradaGrid } from "./components/FracionamentoNfEntradaGrid";
import { ItensFracNfEntradaGrid } from "./components/ItensFracNfEntradaGrid";
import { FracionarItemModal } from "./components/FracionarItemModal";
import { DetalhesItemFracionadoModal } from "./components/DetalhesItemFracionadoModal";

const filtrarPorOptions = [
  { label: "Entrada", value: "ENT" },
  { label: "Emissão", value: "EMI" },
];

export const FracionamentoNfEntrada = () => {
  const [numero, setNumero] = useState(null);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [chaveAcesso, setChaveAcesso] = useState(null);
  const [filtrarPor, setFiltrarPor] = useState(filtrarPorOptions[0].value);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedItens, setSelectedItens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idItemFracionar, setIdItemFracionar] = useState(null);
  const [fracionarOpen, setFracionarOpen] = useState(null);
  const [detFracionadoOpen, setDetFracionadoOpen] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      numero: numero,
      id_fornecedor: idFornecedor,
      chave_acesso: chaveAcesso,
      filtrar_por: filtrarPor,
      data_ini: dataIni,
      data_fim: dataFim,
    };
    setLoading(true);
    const [ok, ret] = await FracionamentoNfEntradaService.listarNfs(params);
    setDados(ok ? ret : []);
    if (ok) {
      const idsRet = ret.map((e) => e.id);
      if (idsRet.includes(selected)) {
        const sel = ret.find((e) => e.id === selected);
        setSelectedItens(sel.itens);
      } else {
        if (ret.length > 0) {
          setSelectedItens(ret[0].itens);
        } else {
          setSelectedItens([]);
        }
      }
    }
    setLoading(false);
  };

  const handleSelect = (id, is, row) => {
    setSelected(is ? id : null);
    setSelectedItens(is ? row.itens : []);
  };

  const toggleFracionar = () => setFracionarOpen(!fracionarOpen);

  const toggleDetFracionado = () => setDetFracionadoOpen(!detFracionadoOpen);

  const fracionarItem = (idItem) => {
    setIdItemFracionar(idItem);
    setTimeout(() => {
      toggleFracionar();
    }, 1);
  };

  const detalhesItemFracionado = (idItem) => {
    setIdItemFracionar(idItem);
    setTimeout(() => {
      toggleDetFracionado();
    }, 1);
  };

  return (
    <PageContainer
      title="Fracionamento de Produtos da Nota Fiscal de Entrada"
      number="0110"
    >
      <Card body>
        <Row>
          <TextInput
            md={2}
            label="Número"
            value={numero}
            onChange={(e, v) => setNumero(v)}
          />
          <AsyncComboBox
            md={4}
            label="Fornecedor"
            name="fornecedor"
            isConcatField
            concatModelName="fornecedor"
            isSearchable
            isClearable
            onChange={(s) => setIdFornecedor(s?.value)}
          />
          <TextInput
            md={4}
            label="Chave de Acesso"
            value={chaveAcesso}
            onChange={(e, v) => setChaveAcesso(v)}
            maxLength={44}
            inputStyle={{ textAlign: "center" }}
          />
        </Row>
        <Row>
          <ComboBox
            label="Filtrar Por"
            md={2}
            onChange={(s) => setFiltrarPor(s?.value)}
            options={filtrarPorOptions}
            defaultValue={filtrarPor}
          />
          <FiltroPeriodoDatas onChange={handleDate} defaultOption="3D" />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <FracionarItemModal
            isOpen={fracionarOpen}
            toggle={toggleFracionar}
            idItemFracionar={idItemFracionar}
            notifyEvent={carregarDados}
          />
          <DetalhesItemFracionadoModal
            isOpen={detFracionadoOpen}
            toggle={toggleDetFracionado}
            idItemFracionar={idItemFracionar}
          />
        </Row>
      </Card>
      <Card body>
        <FracionamentoNfEntradaGrid dados={dados} onSelect={handleSelect} />
      </Card>
      <Card body>
        <ItensFracNfEntradaGrid
          dados={selectedItens}
          fracionarItem={fracionarItem}
          detalhesItemFracionado={detalhesItemFracionado}
        />
      </Card>
    </PageContainer>
  );
};
