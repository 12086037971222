import moment from "moment";
import {
  formatDateDDMMAA,
  formatNumberField,
  formatRow,
  removeSpecialChars,
  verificaPessoa,
} from "../GerarRemessa";

// ============================ REGISTROS E CAMPOS ============================

const rem400headerItau = (dadosConta) => [
  ["0", 1, 1],
  ["1", 2, 2],
  ["REMESSA", 3, 9], // Situação da remessa - TESTE - Testes, Branco - Produção
  ["01", 10, 11],
  ["COBRANCA", 12, 26],
  [dadosConta.agencia, 27, 30], // Código da Agência
  [0, 31, 32], // Complemento de Registro
  [formatNumberField(dadosConta.nro_conta), 33, 38], // Número da Conta
  ["", 39, 46], // Uso Exclusivo do Banco - Brancos
  [removeSpecialChars(dadosConta.nome_empresa), 47, 76], // Nome da Empresa
  ["341", 77, 79],
  ["BANCO ITAU SA", 80, 94],
  [formatDateDDMMAA(new Date()), 95, 100],
  ["", 101, 394], // Brancos
  [1, 395, 400],
];

const rem400T1Itau = (
  dadosConta,
  dup,
  seuNumero,
  enderCliente,
  instrucao2,
  temDesconto,
  temInfoAvalista,
  seq
) => [
  ["1", 1, 1], // Código do Registro
  [2, 2, 3], // Tipo Inscrição - 01 - Pessoa Física, 02 - Pessoa Jurídica
  [formatNumberField(dadosConta.cnpj_empresa), 4, 17], // Número Inscrição - CPF ou CNPJ
  [dadosConta.agencia, 18, 21], // Código da Agência
  [0, 22, 23], // Complemento de Registro - Zeros
  [dadosConta.nro_conta, 24, 29], // Número da Conta
  ["", 30, 33], // Complemento de Registro - Brancos
  [0, 34, 37], // Instrução/Alegação a ser cancelada
  ["", 38, 62], // Identificação do Título na Empresa
  [dup.nosso_numero, 63, 70], // Nosso Número
  [0, 71, 83], // Quantidade de Moeda Variável
  [dadosConta.variacao_carteira, 84, 86], // Número da Carteira no Banco: 108 - DIRETA ELETRÔNICA EMISSÃO INTEGRAL - CARNÊ, 180 - DIRETA ELETRÔNICA EMISSÃO INTEGRAL - SIMPLES, 121 - DIRETA ELETRÔNICA EMISSÃO PARCIAL - SIMPLES, 150 - DIRETA ELETRÔNICA SEM EMISSÃO -DÓLAR, 109 - DIRETA ELETRÔNICA SEM EMISSÃO - SIMPLES, 191 - DUPLICATAS - TRANSFERÊNCIA DE DESCONTO, 104 - ESCRITURAL ELETRÔNICA - CARNÊ, 188 - ESCRITURAL ELETRONICA - COBRANÇA INTELIGENTE (B2B), 147 - ESCRITURAL ELETRÔNICA -DÓLAR, 112 - ESCRITURAL ELETRÔNICA - SIMPLES, 115 - ESCRITURAL ELETRONICA - SIMPLES - FAIXA NOSSO NUMERO LIVRE
  ["", 87, 107], // Uso do Banco - Brancos
  [dadosConta.tipo_carteira, 108, 108], // Código da Carteira: I - DIRETA ELETRÔNICA EMISSÃO INTEGRAL - CARNÊ, I - DIRETA ELETRÔNICA EMISSÃO INTEGRAL - SIMPLES, I - DIRETA ELETRÔNICA EMISSÃO PARCIAL - SIMPLES, U - DIRETA ELETRÔNICA SEM EMISSÃO -DÓLAR, I - DIRETA ELETRÔNICA SEM EMISSÃO - SIMPLES, 1 - DUPLICATAS - TRANSFERÊNCIA DE DESCONTO, I - ESCRITURAL ELETRÔNICA - CARNÊ, I - ESCRITURAL ELETRONICA - COBRANÇA INTELIGENTE (B2B), E - ESCRITURAL ELETRÔNICA -DÓLAR, I - ESCRITURAL ELETRÔNICA - SIMPLES, I - ESCRITURAL ELETRONICA - SIMPLES - FAIXA NOSSO NUMERO LIVRE
  [1, 109, 110], // Código Ocorrência - 01 - Entrada de Título, 02 - Pedido de Baixa, 03 - Concessão de Abatimento, 04 - Cancelamento de Abatimento, 05 - Alteração de Vencimento, 06 - Alteração do uso da Empresa, 07 - Alteração do Prazo de Protesto, 08 - Alteração do Prazo de Devolução, 09 - Alteração de outros dados, 10 - Alt de dados c/ emissão / emissão de boleto, 11 - Alteração da opção de Protesto para Devolução, 12 - Alteração da opção de Devolução para Protesto, 13 - Alteração do valor nominal do título
  [seuNumero, 111, 120], // Número do Documento de Cobrança (Seu Número)
  [formatDateDDMMAA(dup.vencimento), 121, 126], // Vencimento do título
  [formatNumberField(dup.vlr_titulo), 127, 139], // Valor do Título
  ["341", 140, 142], // Código do Banco
  [0, 143, 147], // Agência Cobradora - 00000
  [dadosConta.especie_tit, 148, 149], // Espécie de Título
  ["N", 150, 150], // Aceite - S ou N
  [formatDateDDMMAA(dup.emissao), 151, 156], // Emissão do Título
  [5, 157, 158], // Primeira Instrução de Cobrança
  [instrucao2, 159, 160], // Segunda Instrução de Cobrança
  [formatNumberField(dup.vlr_juros_titulo), 161, 173], // Juros de um dia - Valor de Mora por dia de atraso
  [temDesconto ? formatDateDDMMAA(dup.data_desconto) : 0, 174, 179], // Data limite para concessão do desconto
  [formatNumberField(dup.vlr_desconto), 180, 192], // Valor/Percentual do Desconto
  [0, 193, 205], // Valor do IOF
  [0, 206, 218], // Abatimento
  [verificaPessoa(dup.cpf_cnpj_cliente).padStart(2, "0"), 219, 220], // Tipo de Inscrição do Pagador: 01 - Pessoa Física, 02 - Pessoa Jurídica
  [formatNumberField(dup.cpf_cnpj_cliente), 221, 234], // Número de Inscrição do Pagador
  [dup.nome_cliente, 235, 264], // Nome do Pagador
  ["", 265, 274], // Complemento de Registro - Brancos
  [enderCliente, 275, 314], // Endereço do Pagador
  [dup.bairro_cliente, 315, 326], // Bairro Pagador
  [formatNumberField(dup.cep_cliente), 327, 334], // CEP Pagador
  [dup.cidade_cliente, 335, 349], // Cidade Pagador
  [dup.uf_cliente, 350, 351], // UF Pagador
  [temInfoAvalista ? dadosConta.nome_avalista : "", 352, 381], // Nome Sacador Avalista
  ["", 382, 385], // Complemento de Registro - Brancos
  [formatDateDDMMAA(dup.data_juros_titulo), 386, 391], // Data de Mora
  [dadosConta.dias_protesto, 392, 393], // Número de dias para início do protesto/devolução
  ["", 394, 394], // Complemento de Registro - Brancos
  [seq, 395, 400], // Sequencial
];

export const rem400T5Itau = (dadosConta, seq) => [
  ["5", 1, 1], // Código do Registro
  ["", 2, 121], // Email Cliente
  [2, 122, 123], // Tipo de inscrição do Sacador/Avalista
  [formatNumberField(dadosConta.cnpj_avalista), 124, 137], // Inscrição do Sacador/Avalista
  [dadosConta.endereco_avalista, 138, 177], // Endereço
  ["", 178, 189], // Bairro
  [dadosConta.cep_avalista.replace("-", ""), 190, 197], // CEP
  [dadosConta.cidade_avalista, 198, 212], // Cidade
  [dadosConta.uf_avalista, 213, 214], // UF
  ["", 215, 394], // Brancos
  [seq, 395, 400], // Seq
];

const rem400trailerItau = (seq) => [
  ["9", 1, 1], // Identificador
  ["", 2, 394],
  [seq, 395, 400],
];

// ============================== GERAÇÃO DO ARQUIVO ==============================

export const layoutItau = (dadosConta, duplicatas) => {
  const temInfoAvalista = ![null, undefined, ""].includes(
    dadosConta.cnpj_avalista?.trim()
  );

  const multiIndex = temInfoAvalista ? 2 : 1;

  const instrucao2 =
    dadosConta.forma_inadimplencia === "NEG"
      ? 66 // ENTRADA EM NEGATIVAÇÃO EXPRESSA (IMPRIME: SUJEITO A NEGATIVAÇÃO APÓS O VENCIMENTO)
      : dadosConta.protestar
      ? 34 // PROTESTAR APÓS XX DIAS CORRIDOS DO VENCIMENTO
      : 10; // NÃO PROTESTAR
  const header = formatRow(...rem400headerItau(dadosConta));

  const detalhes = "".concat(
    ...duplicatas.map((dup, index) => {
      const seuNumero = `${dup.numero}${dup.parcela}`;

      const enderCliente =
        removeSpecialChars(dup.endereco_cliente) +
        (!["", null, undefined].includes(dup.nro_endereco_cliente)
          ? ", " + dup.nro_endereco_cliente
          : "") +
        (!["", null, undefined].includes(dup.complemento_endereco_cliente)
          ? " " + dup.complemento_endereco_cliente
          : "");

      return (
        // Registro Tipo 1
        formatRow(
          ...rem400T1Itau(
            dadosConta,
            dup,
            seuNumero,
            enderCliente,
            instrucao2,
            parseFloat(dup.vlr_desconto) > 0,
            temInfoAvalista,
            index * multiIndex + 2
          )
        ) +
        (temInfoAvalista
          ? formatRow(...rem400T5Itau(dadosConta, index * multiIndex + 3))
          : "")
      );
    })
  );

  // Trailer
  const trailer = formatRow(
    ...rem400trailerItau(duplicatas.length * multiIndex + 2)
  );

  return header + detalhes + trailer;
};

export const nomeArqItau = (dadosConta) =>
  "E00" + moment(new Date()).format("DD") + dadosConta.nro_remessa + ".REM";
