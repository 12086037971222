import React from "react";
import { ModalBase } from "../../../../../../components";

export const ConfSemAlterarPrecosModal = ({ isOpen, toggle, onConfirm }) => {
  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Entrada de Nota Fiscal via XML"
      onConfirm={onConfirm}
      confirmButtonText="Sim"
      cancelButtonText="Não"
    >
      <h5 style={{ textAlign: "center" }}>
        Nenhum produto foi marcado para atualizar preços de custo e venda.
      </h5>
      <h5 style={{ textAlign: "center" }}>
        Deseja continuar sem atualizar os preços?
      </h5>
    </ModalBase>
  );
};
