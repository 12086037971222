import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  FiltroPeriodoDatas,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { apiGetV2 } from "../../../../apiV2";
import { VendasExcetoGruposGrid } from "./components/VendasExcetoGruposGrid";
import { formatDateISO, formatNumber } from "../../../../coreUtils";
import { docPrintReport } from "../../../../pdf";

export const VendasExcetoGrupos = () => {
  const [idGrupo, setIdGrupo] = useState([]);
  const [idVendedor, setIdVendedor] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [imprimirDetalhamento, setImprimirDetalhamento] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      id_grupo: idGrupo,
      id_vendedor: idVendedor,
      data_ini: dataIni,
      data_fim: dataFim,
    };
    return await apiGetV2("/relatorios/outros/vendas_exceto_grupos/", params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret.vendas : []);
    setTotais(ok ? ret.totais : {});
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
        vendas: ret.vendas,
        totais: ret.totais,
        imprimir_detalhamento: imprimirDetalhamento,
      };
      await docPrintReport(
        "/relatorios/outros/vendas_exceto_grupos/",
        payload,
        "5054"
      );
    }
    setLoadingImprimir(false);
  };

  const disabledButtons =
    (idGrupo ?? []).length === 0 ||
    !(dataIni instanceof Date) ||
    !(dataFim instanceof Date);

  const disabledHint =
    (idGrupo ?? []).length === 0
      ? "Selecione ao menos um grupo"
      : "Informe datas válidas de início e fim";

  return (
    <PageContainer title="Vendas Exceto Grupos" number="5054" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={8}
            isSearchable
            isClearable
            isConcatField
            label="Relatório de Vendas em que não há incidência de produtos vendidos dos seguintes grupos:"
            concatModelName="grupo"
            isMulti
            hideShortcut
            onChange={(s) => setIdGrupo(s?.map((e) => e.value) ?? [])}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={4}
            isSearchable
            isClearable
            isConcatField
            label="Vendedor"
            concatModelName="colaborador"
            hideShortcut
            onChange={(s) => setIdVendedor(s?.value)}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
        </Row>
        <Row>
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            disabled={disabledButtons}
            disabledHint={disabledHint}
          />
          <BotaoImprimir
            onClick={imprimir}
            loading={loadingImprimir}
            disabled={disabledButtons}
            disabledHint={disabledHint}
          />
          <FormCheckbox
            label="Imprimir Detalhamento"
            checked={imprimirDetalhamento}
            onChange={() => setImprimirDetalhamento(!imprimirDetalhamento)}
          />
        </Row>
      </Card>
      <CardTotais md={7}>
        <CardTotaisItem
          label="Vendas do Período"
          value={
            formatNumber(totais?.qtd_vendas_geral ?? 0, true, 2, true) +
            " | " +
            formatNumber(totais?.vlr_vendas_geral ?? 0, true, 2)
          }
          format={false}
        />
        <CardTotaisItem
          label="Vendas Exceto Grupos"
          value={
            formatNumber(totais?.qtd_vendas ?? 0, true, 2, true) +
            " | " +
            formatNumber(totais?.vlr_vendas ?? 0, true, 2)
          }
          format={false}
        />
      </CardTotais>
      <Card body>
        <VendasExcetoGruposGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
