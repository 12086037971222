import React from "react";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { Table } from "../../../../../components";

const columns = [
  {
    dataField: "nome_produto",
    text: "Produto",
    align: "left",
    formatter: (c, r) => formatValueFromAPI(c, r.id_produto),
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
  },
  {
    dataField: "entrada",
    text: "Entrada",
    align: "center",
  },
  {
    dataField: "fornecedor",
    text: "Fornecedor",
    align: "left",
  },
  {
    dataField: "numero",
    text: "Número",
    align: "center",
  },
  {
    dataField: "sit_trib_icms",
    text: "CST",
    align: "center",
  },
  {
    dataField: "aliq_icms",
    text: "Aliq. ICMS",
    align: "right",
    formatter: (c) => `${formatNumber(c, true, 2)}%`,
  },
  {
    dataField: "base_calc_icms",
    text: "Base Calc. ICMS",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "base_calc_icms_st",
    text: "Base Calc. ICMS ST",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "quantidade",
    text: "Quantidade",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_unit",
    text: "Vlr. Unitário",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const NotasExclusaoICMSSTGrid = ({ data }) => {
  return <Table columns={columns} data={data} pageSize={15} />;
};
