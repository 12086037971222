import "moment/locale/pt-br";
import React, { useState } from "react";
import "react-day-picker/lib/style.css";
import { Fade } from "reactstrap";
import { checkPair, formatNumber } from "../../../../../coreUtils";
import { LinhaPlanoContas } from "../../demonstrativo_despesas_por_plano_conta/components/LinhaPlanoContas";

export const LinhaCentroCusto = ({ level, row }) => {
  const [childrenVisible, setChildrenVisible] = useState(false);
  const toggle = () => {
    setChildrenVisible(!childrenVisible);
  };

  return (
    <>
      <li
        className={`${level ? "dre-row--item" : ""} ${
          !checkPair(level || 0) ? "dre-row--odd" : ""
        }`}
      >
        <div
          className="dre-row"
          onClick={toggle}
          style={{ marginLeft: `${2 * level}%`, fontWeight: "bold" }}
        >
          <span key={`cc-${row.id}-desc`}>
            Centro de Custo: {row.descricao}
          </span>
          <span key={`cc-${row.id}-vlr`}>{formatNumber(row.valor)}</span>
        </div>
      </li>
      {childrenVisible && row.nivel_abaixo && (
        <Fade>
          <ul className="dre-list">
            {row.planos_contas?.map((element, index) => (
              <LinhaPlanoContas
                key={index}
                row={element}
                level={(level || 0) + 1}
              />
            ))}
            {row.nivel_abaixo.map((element, index) => {
              return (
                <LinhaCentroCusto
                  key={index}
                  row={element}
                  level={(level || 0) + 1}
                />
              );
            })}
          </ul>
        </Fade>
      )}
    </>
  );
};
