import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  aplica_diferenca_parcela: "U",
  impr_nota_promissoria: "P",
  impr_carne: "P",
  layout_impr_carne: 1,
  aceita_cred_sem_cpf_cnpj: false,
  plano_contas_padrao: 1,
  msg_carne: "",
  lancar_parcelas_por: "QTD",
  nro_vias_carne: 1,
  layout_impr_promissoria: 1,
};

export const crediarioSlice = createSlice({
  name: "crediario_slice",
  initialState: initialState,
  reducers: {
    setupCrediario: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (key === "msg_carne" && value === null) {
          value = "";
        }

        if (key === "layout_impr_promissoria" && value === null) {
          value = 1;
        }

        state[key] = value;
      });
      return state;
    },
    setAplicaDiferencaParcela: (state, action) => {
      state.aplica_diferenca_parcela = action.payload;
    },
    setImprNotaPromissoria: (state, action) => {
      state.impr_nota_promissoria = action.payload;
    },
    setImprCarne: (state, action) => {
      state.impr_carne = action.payload;
    },
    setLayoutImprCarne: (state, action) => {
      state.layout_impr_carne = action.payload;
    },
    setAceitaCredSemCpfCnpj: (state, action) => {
      state.aceita_cred_sem_cpf_cnpj = action.payload;
    },
    setPlanoContasPadrao: (state, action) => {
      state.plano_contas_padrao = action.payload;
    },
    setMsgCarne: (state, action) => {
      state.msg_carne = action.payload;
    },
    setLancarParcelasPor: (state, action) => {
      state.lancar_parcelas_por = action.payload;
    },
    setNroViasCarne: (state, action) => {
      state.nro_vias_carne = action.payload;
    },
    setLayoutImprPromissoria: (state, action) => {
      state.layout_impr_promissoria = action.payload;
    },
  },
});

export const {
  setupCrediario,
  setAplicaDiferencaParcela,
  setImprNotaPromissoria,
  setImprCarne,
  setLayoutImprCarne,
  setAceitaCredSemCpfCnpj,
  setPlanoContasPadrao,
  setMsgCarne,
  setLancarParcelasPor,
  setNroViasCarne,
  setLayoutImprPromissoria,
} = crediarioSlice.actions;

export default crediarioSlice.reducer;
