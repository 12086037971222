import React from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoConfirmarVoltar,
  BotaoPesquisar,
  PageContainer,
} from "../../../../components";
import { CadastroVeiculoModal } from "./components/CadastroVeiculoModal";
import { VeiculoGrid } from "./components/VeiculoGrid";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitleVeiculo = "Veículo";
export const routesBaseVeiculo = "/cadastro/veic_empr";

export const Veiculo = () => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async () => {
    const [ok, ret] = await apiGetV2(`${routesBaseVeiculo}/listar/`);

    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer
      title="Cadastro de Veículos da Empresa"
      number="0012"
      canGoBack
    >
      <Card body>
        <Row className="mb-3">
          <BotaoPesquisar
            loading={loading}
            onClick={carregarDados}
            padded={false}
            divClassName="pr-0"
          />
          <BotaoIncluir toggle={toggleCadastro} padded={false} />
          <CadastroVeiculoModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleVeiculo}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0012_2"
            selected={selected}
            routeBase={routesBaseVeiculo}
            notifyEvent={notifyEvent}
          />
          <BotaoConfirmarVoltar
            padded={false}
            selected={selected}
            carregarDados={carregarDados}
          />
        </Row>
        <VeiculoGrid
          dados={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
