import { apiGetV2, apiDeleteV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/aviso/vinc_colaborador";

const AvisoVincColaboradorService = {
  listar: async () => await apiGetV2(`${URL_BASE}/listar/`),
  buscar: async (idAviso) => await apiGetV2(`${URL_BASE}/buscar/${idAviso}/`),
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir/`, payload),
  excluir: async (idVinc) =>
    await apiDeleteV2(`${URL_BASE}/excluir/${idVinc}/`),
};

export default AvisoVincColaboradorService;
