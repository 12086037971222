import React from "react";
import { Info } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { formatNumber } from "../../../../../coreUtils";

const ValorComTooltip = ({
  valorPrincipal,
  valorFrete,
  valorDevol,
  valorCliSemComissao,
  valorServicos,
  idTooltip,
}) => {
  return (
    <>
      <strong>
        <Info
          style={{ marginBottom: "2px", marginRight: "3px" }}
          size={14}
          id={idTooltip}
        />
        <span>{formatNumber(valorPrincipal, true, 2)}</span>
      </strong>
      <UncontrolledTooltip target={idTooltip}>
        <p style={{ textAlign: "justify", marginBottom: "0" }}>
          Valores desconsiderados:
          <br />
          Frete: <strong>{formatNumber(valorFrete, true, 2)}</strong>
          <br />
          Devoluções: <strong>{formatNumber(valorDevol, true, 2)}</strong>
          <br />
          Clientes que não geram comissão:{" "}
          <strong>{formatNumber(valorCliSemComissao, true, 2)}</strong>
          <br />
          Serviços: <strong>{formatNumber(valorServicos, true, 2)}</strong>
        </p>
      </UncontrolledTooltip>
    </>
  );
};

export default ValorComTooltip;
