import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import {
  cadastroBancoRoute,
  cadastroCentroCustoRoute,
  cadastroCondPagRoute,
  cadastroContaBancRoute,
  cadastroMeioPagtoRoute,
  cadastroMotivoRoute,
  cadastroPlanoContasRoute,
} from "../../../routes/modules/cadastro";

export const Financeiro = () => {
  return (
    <PageContainer title="Cadastros - Financeiro">
      <MenuGroup>
        <MenuGroupButton
          label="Condição de Pagamento"
          icon={cadastroCondPagRoute.shortcutIcon}
          pathname={cadastroCondPagRoute.path}
          nroTela={cadastroCondPagRoute.nroTela}
        />
        <MenuGroupButton
          label="Conta Bancária"
          icon={cadastroContaBancRoute.shortcutIcon}
          pathname={cadastroContaBancRoute.path}
          nroTela={cadastroContaBancRoute.nroTela}
        />
        <MenuGroupButton
          label="Meio de Pagamento"
          icon={cadastroMeioPagtoRoute.shortcutIcon}
          pathname={cadastroMeioPagtoRoute.path}
          nroTela={cadastroMeioPagtoRoute.nroTela}
        />
        <MenuGroupButton
          label="Motivo"
          icon={cadastroMotivoRoute.shortcutIcon}
          pathname={cadastroMotivoRoute.path}
          nroTela={cadastroMotivoRoute.nroTela}
        />
        <MenuGroupButton
          label="Banco"
          icon={cadastroBancoRoute.shortcutIcon}
          pathname={cadastroBancoRoute.path}
          nroTela={cadastroBancoRoute.nroTela}
        />
        <MenuGroupButton
          label="Plano de Contas"
          icon={cadastroPlanoContasRoute.shortcutIcon}
          pathname={cadastroPlanoContasRoute.path}
          nroTela={cadastroPlanoContasRoute.nroTela}
        />
        <MenuGroupButton
          label="Centro de Custos"
          icon={cadastroCentroCustoRoute.shortcutIcon}
          pathname={cadastroCentroCustoRoute.path}
          nroTela={cadastroCentroCustoRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
