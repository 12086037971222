import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import CadastroCampoPersonService from "../services/cadastro/CadastroCampoPersonService";
import { FormCheckbox } from "./FormCheckbox";
import TextInput from "./TextInput";

export const CamposPerson = ({ tableName, values, onChange }) => {
  const [fields, setFields] = useState([]);

  const handleChange = (id, v) => {
    let _state = values.filter((item) => item.id_campo !== id);
    _state.push({ id_campo: id, value: v.toString() });
    onChange(_state);
  };

  const loadFields = async () => {
    const ret = await CadastroCampoPersonService.buscarCampos(tableName);
    setFields(ret);
  };

  useEffect(() => {
    loadFields();
  }, []);

  return (
    <Row>
      {fields.map((e) => {
        let label = e.nome_campo
          .toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase());
        switch (e.tipo_campo) {
          case "TEXT":
            return (
              <TextInput
                label={label}
                name={e.nome_campo}
                onChange={(event, v) => handleChange(e.id, v)}
                value={values.find((item) => item.id_campo === e.id)?.value}
              />
            );
          case "CHECK":
            return (
              <FormCheckbox
                label={label}
                name={e.nome_campo}
                checked={
                  values.find((item) => item.id_campo === e.id)?.value ===
                  "true"
                }
                onChange={(event) => handleChange(e.id, event.target.checked)}
              />
            );
          default:
            return null;
        }
      })}
    </Row>
  );
};
