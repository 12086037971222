import React from "react";
import { Card } from "reactstrap";
import { Table } from "../../../../../components";
import {
  formatarValor,
  naturalSort,
} from "../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const FlexRegraColaboradorGrid = ({
  dados,
  setSelected,
  alterar,
  excluir,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "nome_colaborador",
      text: "Colaborador",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "perc_limite_acresc",
      text: "Limite de Acréscimo",
      align: "center",
      headerAlign: "center",
      sortable: true,
      formatter: (v) => formatarValor(v, 2),
      sortFunc: naturalSort,
    },
    {
      dataField: "perc_limite_deduc",
      text: "Limite de Dedução",
      align: "center",
      headerAlign: "center",
      sortable: true,
      formatter: (v) => formatarValor(v, 2),
      sortFunc: naturalSort,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Card body>
      <Table columns={columns} data={dados} onSelect={setSelected} onRowDoubleClick={alterar}/>
    </Card>
  );
};
