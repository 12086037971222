import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroGrupoNaoVerifEstoqueModal } from "./components/CadastroGrupoNaoVerifEstoqueModal";
import { GrupoNaoVerifEstoqueGrid } from "./components/GrupoNaoVerifEstoqueGrid";
import {
  BaseInfoTelaCard,
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../../components";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";

export const GrupoNaoVerifEstoque = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const buscarDados = async (p) => {
    const [ok, ret] = await apiGetV2(
      `/cadastro/grupo_prod_exclusao_verif_estoque/listar/`,
      {
        pista: p,
      }
    );
    return ok ? ret : [];
  };

  const carregarDados = async () => {
    setLoading(true);
    setDados(await buscarDados(pista));
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    setDados(await buscarDados(v));
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  return (
    <PageContainer
      title="Cadastro de Grupos de Não Verificação de Estoque"
      number="0074"
      canGoBack
    >
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroGrupoNaoVerifEstoqueModal
            selected={selected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <GrupoNaoVerifEstoqueGrid data={dados} setSelected={setSelected} />
      </Card>
      <BaseInfoTelaCard nroTela="0074" />
    </PageContainer>
  );
};
