import React from "react";
import { IconButton, Table, TableDelete } from "../../../../../../components";
import { formatNumber } from "../../../../../../coreUtils";
import { Edit, Info } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";

export const InfoNfFaturadaGrid = ({
  itens,
  handleEdit,
  handleDelete,
  podeAlterar,
  setSelected,
  handleEditCfopProduto,
}) => {
  const columns = [
    {
      dataField: "id_produto",
      text: "Código",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_produto",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => (
        <>
          {c}
          {row.prod_convertido === true && (
            <>
              <Info
                size={12}
                id={`item-${row.uuid}`}
                style={{ marginTop: "-2px", marginLeft: "7px" }}
              />

              <UncontrolledTooltip placement="top" target={`item-${row.uuid}`}>
                Produto convertido. Originalmente era {row.nome_prod_orig} (
                {row.id_prod_orig}) - {row.un_prod_orig}
              </UncontrolledTooltip>
            </>
          )}
        </>
      ),
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "clas_fiscal",
      text: "NCM",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <span style={!row.ncm_valido ? { color: "red" } : {}}>{c}</span>
      ),
    },
    {
      dataField: "cfop",
      text: "CFOP",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <>
          {c}{" "}
          <IconButton
            icon={Edit}
            size={15}
            onClick={() => handleEditCfopProduto(row.uuid)}
          />
        </>
      ),
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "edit",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <IconButton icon={Edit} onClick={() => handleEdit(row.uuid)} />
      ),
      hidden: !podeAlterar,
    },
    {
      dataField: "edit",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.uuid)} />
      ),
      hidden: !podeAlterar,
    },
  ];

  return (
    <Table
      keyField="uuid"
      data={itens}
      columns={columns}
      paginated={false}
      pageSize={10}
      rowStyle={(row) =>
        (row.clas_fiscal ?? "").trim() === "" || (row.cfop ?? "").trim() === ""
          ? { color: "red" }
          : undefined
      }
      clearSelectionOnUpdate
      onSelect={setSelected}
    />
  );
};
