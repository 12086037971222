import React from "react";
import {
  Divider,
  MenuGroup,
  MenuGroupButton,
  PageContainer,
} from "../../../components";
import { LiaPalletSolid } from "react-icons/lia";
import {
  impressaoEtiquetasProdutoRoute,
  manutencaoEstoqueRoute,
  receberRemessaEntreEmpresasRoute,
  enviarRemessaEntreEmpresasRoute,
  exportacaoBalancaRoute,
} from "../../../routes/modules/estoque";
import { BsBoxArrowInRight, BsBoxArrowRight } from "react-icons/bs";
import { AiOutlineTags } from "react-icons/ai";
import { MdOutlineScale } from "react-icons/md";

export const EstoqueAcoes = () => {
  return (
    <PageContainer title="Estoque - Ações">
      <MenuGroup>
        <MenuGroupButton
          label="Manutenção de Estoque"
          icon={LiaPalletSolid}
          pathname={manutencaoEstoqueRoute.path}
          nroTela={manutencaoEstoqueRoute.nroTela}
        />
        <MenuGroupButton
          label="Impressão de Etiquetas"
          icon={AiOutlineTags}
          pathname={impressaoEtiquetasProdutoRoute.path}
          nroTela={impressaoEtiquetasProdutoRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">Remessa Entre Empresas</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Enviar Remessa"
          icon={BsBoxArrowRight}
          pathname={enviarRemessaEntreEmpresasRoute.path}
          nroTela={enviarRemessaEntreEmpresasRoute.nroTela}
        />
        <MenuGroupButton
          label="Receber Remessa"
          icon={BsBoxArrowInRight}
          pathname={receberRemessaEntreEmpresasRoute.path}
          nroTela={receberRemessaEntreEmpresasRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">Integração PDV</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Exportação para Balança"
          icon={MdOutlineScale}
          pathname={exportacaoBalancaRoute.path}
          nroTela={exportacaoBalancaRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
