import React, { useState } from "react";
import { FormButton, ModalBase } from "../../../../../../components";

export const EstornarLoteModal = ({ disabled, estornarLote, loading }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onConfirm = () => {
    toggle();
    estornarLote();
  };

  return (
    <>
      <FormButton
        md="auto"
        color="info"
        onClick={toggle}
        loading={loading}
        disabled={disabled}
      >
        Estornar Lote
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Estorno de Lote de Compensação de Cartões"
        number="0049_51"
        autoFocus
        onConfirm={onConfirm}
      >
        <div style={{ textAlign: "center" }}>
          O sistema irá estornar TODO o LOTE de compensação do cartão
          selecionado.
          <br />
          Se houverem cartões além do selecionado, estes também serão reabertos.
          <br />
          <strong>Tem certeza de que deseja realizar esta operação?</strong>
        </div>
      </ModalBase>
    </>
  );
};
