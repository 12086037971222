import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import FormButton from "./FormButton";

export const BotaoConfirmarVoltar = ({
  selected,
  carregarDados,
  padded = true,
}) => {
  const opener = window.opener;
  const history = useHistory();

  useEffect(() => {
    if (opener !== null && history.length === 1 && carregarDados) {
      carregarDados();
    }
  }, [opener]);

  const onClick = () => {
    opener.postMessage(
      { id: JSON.parse(window.parameters).id, selected: selected },
      window.location.origin
    );
    window.close();
  };

  return (
    opener !== null &&
    history.length === 1 && (
      <FormButton
        md="auto"
        color="success"
        padded={padded}
        disabled={[0, null, undefined].includes(selected)}
        onClick={onClick}
      >
        Confirmar e Voltar
      </FormButton>
    )
  );
};
