import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroPercursoModal } from "./components/CadastroPercursoModal";
import { PercursoGrid } from "./components/PercursoGrid";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../../../../coreUtils";
import {
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../../../../components";
import { apiGetV2 } from "../../../../../../apiV2";
import {
  BotaoIncluir,
  ModalExcluirV2,
} from "../../../../../../components/cadastro";

export const modalTitlePercursoMDFe = "Percurso";
export const routesBasePercursoMDFe = "/cadastro/rota_percurso";

export const Percurso = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p = pista) => {
    const params = {
      pista: p,
    };

    const [ok, ret] = await apiGetV2(
      `${routesBasePercursoMDFe}/listar/`,
      params
    );

    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (p) => {
    setPista(p);
    setLoadingPista(true);
    await buscarDados(p);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) {
      setSelected(null);
    }
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Percurso" number="0042_5" canGoBack>
      <Card body>
        <Row className="mb-2">
          <SearchInput
            md={5}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Descrição"
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroPercursoModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            notifyEvent={notifyEvent}
            selected={selected}
          />
          <ModalExcluirV2
            title={modalTitlePercursoMDFe}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0042_7"
            selected={selected}
            routeBase={routesBasePercursoMDFe}
            notifyEvent={notifyEvent}
          />
        </Row>
        <PercursoGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
