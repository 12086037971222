import React from "react";
import { Row } from "reactstrap";
import { formatarValor, naturalSort } from "../../../../../coreUtils";
import { Table } from "../../../../../components/Table";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "referencia",
    text: "Referência",
    align: "center",
    sortable: true,
  },
  {
    dataField: "nome",
    text: "Nome",
    align: "left",
    sortable: true,
  },
  {
    dataField: "nome_grupo",
    text: "Grupo",
    align: "left",
  },
  {
    dataField: "nome_fabricante",
    text: "Fabricante",
    align: "left",
  },
  {
    dataField: "custo_ue",
    text: "Custo",
    align: "right",
    formatter: (v, row) => formatarValor(v, row.qtd_casas_decimais_vlrs),
  },
  {
    dataField: "quantidade",
    text: "Quantidade",
    align: "center",
    formatter: (v, row) => formatarValor(v, row.qtd_casas_decimais_qtd),
  },
  {
    dataField: "qtd_reserva",
    text: "Reserva",
    align: "center",
    formatter: (v, row) => formatarValor(v, row.qtd_casas_decimais_qtd),
  },
  {
    dataField: "preco_venda",
    text: "Preço de Venda",
    align: "right",
    formatter: (v, row) => formatarValor(v, row.qtd_casas_decimais_vlrs),
  },
];

export const ProdutosSimilaresGrid = ({ data }) => {
  return (
    <>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <span
          style={{
            color: "#2b2b2b",
            paddingBottom: "0.5rem",
            fontWeight: "bold",
          }}
        >
          Produtos SIMILARES ao produto selecionado acima
        </span>
      </Row>
      <Table columns={columns} data={data} paginated={false} pageSize={3} />
    </>
  );
};
