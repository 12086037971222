import React, { useState } from "react";
import { Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import ImageViewer from "react-simple-image-viewer";
import { DataTable } from "../../../../../../components/DataTable";
import { formatNumber } from "../../../../../../coreUtils";
import { removeLocalInstalacao } from "../store/local_instalacao_modulos_slice";

export const GridLocaisInstalacao = () => {
  const localInstalacaoModulos = useSelector(
    (state) => state.localInstalacaoModulos
  );
  const dispatch = useDispatch();
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const columns = [
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      sort: false,
    },
    {
      dataField: "orientacao",
      text: "Orientação",
      align: "center",
      headerAlign: "center",
      sort: false,
      formatter: (v) => {
        switch (v) {
          default:
            return "Não Definido";
          case "N":
            return "Norte";
          case "NE":
            return "Nordeste";
          case "L":
            return "Leste";
          case "SE":
            return "Sudeste";
          case "S":
            return "Sul";
          case "SO":
            return "Sudoeste";
          case "O":
            return "Oeste";
          case "NO":
            return "Noroeste";
        }
      },
    },
    {
      dataField: "inclinacao",
      text: "Inclinação",
      align: "center",
      headerAlign: "center",
      sort: false,
      formatter: (v) => formatNumber(String(v), true, 2),
    },
    {
      dataField: "",
      isDummyField: true,
      text: "Larg. x Comp.",
      align: "center",
      headerAlign: "center",
      sort: false,
      formatter: (_, row) => {
        const largura = formatNumber(String(row.largura), true, 2);
        const comprimento = formatNumber(String(row.comprimento), true, 2);
        return `${largura} x ${comprimento}`;
      },
    },
    {
      dataField: "tipoTelha",
      text: "Telha",
      align: "left",
      headerAlign: "left",
      sort: false,
      formatter: (v) => {
        switch (v) {
          default:
            return "Não Definido";
          case "FICI":
            return "Fibrocimento";
          case "COLO":
            return "Colônial";
          case "ALUZ":
            return "Aluzinco";
          case "CONC":
            return "Concreto";
        }
      },
    },
    {
      dataField: "distanciaRipas",
      text: "Ripas",
      align: "center",
      headerAlign: "center",
      sort: false,
      formatter: (v) => formatNumber(String(v), true, 2),
    },
    {
      dataField: "distanciaCaibros",
      text: "Caibros",
      align: "center",
      headerAlign: "center",
      sort: false,
      formatter: (v) => formatNumber(String(v), true, 2),
    },
    {
      dataField: "distanciaTercas",
      text: "Terças",
      align: "center",
      headerAlign: "center",
      sort: false,
      formatter: (v) => formatNumber(String(v), true, 2),
    },
    {
      dataField: "distanciaVigasMetalicas",
      text: "Vigas Metal.",
      align: "center",
      headerAlign: "center",
      sort: false,
      formatter: (v) => formatNumber(String(v), true, 2),
    },
    {
      dataField: "alturaTelhado",
      text: "Altura",
      align: "center",
      headerAlign: "center",
      sort: false,
      formatter: (v) => formatNumber(String(v), true, 2),
    },
    {
      dataField: "acesso",
      text: "Acesso",
      align: "center",
      headerAlign: "center",
      sort: false,
      formatter: (v) => {
        switch (v) {
          case "NAO":
          default:
            return "Não Definido";
          case "ESCA":
            return "Escada";
          case "ANDA":
            return "Andaime";
          case "PLAT":
            return "Plataforma";
        }
      },
    },
    {
      dataField: "foto_croqui",
      isDummyField: true,
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (_, row) => {
        if (row.fotoCroqui != null) {
          return (
            <img
              src={row.fotoCroqui}
              style={{ maxHeight: "50px", cursor: "pointer" }}
              onClick={() => setIsViewerOpen(true)}
              alt=""
            />
          );
        } else {
          return <></>;
        }
      },
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (_, row) => (
        <Trash2
          size={18}
          color="#db3d3d"
          cursor="pointer"
          onClick={() => {
            dispatch(removeLocalInstalacao(row.id));
          }}
        />
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={localInstalacaoModulos.locaisInstalacao}
        noDataIndication={
          <p className="mt-2 text-muted">Nenhum local informado</p>
        }
        readOnly
        hideCount
      />
      {isViewerOpen && (
        <ImageViewer
          src={localInstalacaoModulos.locaisInstalacao
            .map((el) => el.fotoCroqui)
            .filter((n) => n != null)}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setIsViewerOpen(false)}
        />
      )}
    </>
  );
};
