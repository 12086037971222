import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormCheckbox, PesqPlanoCtaCentroCusto } from "../../../../components";
import { Row } from "reactstrap";
import {
  setRecAceitaChqSemCpfCnpj,
  setRecIdPlanoContasPadIncCheq,
  setPagIdPlanoContasPadIncCheq,
} from "../store/cheque_slice";

export const PersonCheque = () => {
  const store = useSelector((state) => state.cheque);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Aceitar Cheques Terceiros Sem CPF/CNPJ"
          checked={store.rec_aceita_chq_sem_cpf_cnpj}
          onChange={() =>
            dispatch(
              setRecAceitaChqSemCpfCnpj(!store.rec_aceita_chq_sem_cpf_cnpj)
            )
          }
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de Contas Padrão para Inclusão de Cheques Terceiros"
          mode="plano_contas"
          value={store.rec_id_plano_contas_pad_inc_cheq}
          onChange={(v) => dispatch(setRecIdPlanoContasPadIncCheq(v ?? null))}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={5}
          label="Plano de Contas Padrão para Inclusão de Cheques Próprios"
          mode="plano_contas"
          value={store.pag_id_plano_contas_pad_inc_cheq}
          onChange={(v) => dispatch(setPagIdPlanoContasPadIncCheq(v ?? null))}
        />
      </Row>
    </>
  );
};
