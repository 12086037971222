import { apiGet, apiPost, urlParams } from "../../../api";

const ComissaoVendExtService = {
  listar: async (params) => {
    const searchParams = urlParams(params);
    return await apiGet(`/venda/comissao/listar/${searchParams}`);
  },
  confirmar: async (payload) =>
    await apiPost("/venda/comissao/confirmar/", payload, false),
  previa: async (payload) =>
    await apiPost("/venda/comissao/previa/", payload, {}),
};

export default ComissaoVendExtService;
