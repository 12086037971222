import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormCheckbox,
  ModalBase,
  Table,
} from "../../../../../components";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import FrenteVendaService from "../../../../../services/vendas/FrenteVendaService";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const columns = [
  { dataField: "id", text: "#", align: "center" },
  {
    dataField: "nome_cli",
    text: "Cliente",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  {
    dataField: "data_hora_reg",
    text: "Data/Hora",
    align: "center",
  },
  {
    dataField: "id_colaborador_oper__nome",
    text: "Vendedor",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador_oper),
  },
  {
    dataField: "vlr_total",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const VendasSalvasModal = ({ isOpen, toggle, importarVendaSalva }) => {
  const [idCliente, setIdCliente] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [somenteMeusRegs, setSomenteMeusRegs] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdCliente(null);
    setDataIni(new Date());
    setDataFim(new Date());
    setSomenteMeusRegs(false);
    setDados([]);
    setSelected(null);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      id_cliente: idCliente,
      somente_meus_regs: somenteMeusRegs,
      data_ini: dataIni,
      data_fim: dataFim,
    };
    setLoadingFetch(true);
    const [ok, ret] = await FrenteVendaService.vendasSalvas.listar(params);
    setDados(
      ok
        ? ret.map((e) =>
            e.aberto === false && e.status !== "N" ? { ...e, status: "TV" } : e
          )
        : []
    );
    setLoadingFetch(false);
  };

  const onConfirm = async () => {
    try {
      if ([0, null, undefined].includes(selected)) {
        toastr.warning("Atenção", "Por favor, selecione uma Venda.");
        return false;
      }
      setLoading(true);
      if (await importarVendaSalva(selected)) {
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Pesquisa de Vendas Salvas"
        number="0079_5"
        onConfirm={onConfirm}
        loadingConfirm={loading}
        onClosed={limparDados}
      >
        <Row>
          <AsyncComboBox
            md={5}
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
            defaultValue={idCliente}
            hideShortcut
          />
          <FormCheckbox
            label="Somente as Minhas Vendas Salvas"
            md="auto"
            onChange={(e) => setSomenteMeusRegs(!somenteMeusRegs)}
            checked={somenteMeusRegs}
          />
        </Row>
        <Row className="mb-2">
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loadingFetch} />
        </Row>
        <Table
          data={dados}
          columns={columns}
          paginated={false}
          onSelect={setSelected}
          onRowDoubleClick={onConfirm}
          pageSize={15}
        />
      </ModalBase>
    </>
  );
};
