import React, { useState } from "react";
import { ModalBase, TextInput } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { apiPostV2 } from "../../../../../apiV2";
import { setApiAuthorizationCode } from "../store/cadastroContaBancSlice";

export const ModalGerarAuthCode = ({ toggle, isOpen }) => {
  const dispatch = useDispatch();
  const dadosCobranca = useSelector(
    (state) => state.cadastroContaBanc.cobranca
  );
  const [urlAutenticar, setUrlAutenticar] = useState(null);

  const gerarUrlAutenticar = async () => {
    const payload = {
      layout: dadosCobranca.layout,
    };

    const [
      ok,
      ret,
    ] = await apiPostV2(
      "/bancario/cobranca/integracao_api/gerar_url_autenticacao/",
      payload,
      { successMessage: false }
    );
    if (ok) {
      setUrlAutenticar(ret.url_autenticacao);
    } else {
      toggle();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      size="md"
      title="Gerar Authorization Code"
      toggle={toggle}
      onBeforeOpen={gerarUrlAutenticar}
      onConfirm={toggle}
    >
      {urlAutenticar && (
        <div style={{ fontSize: "14px", textAlign: "center" }} className="mb-2">
          Acesse o{" "}
          <a href={urlAutenticar} target="_blank" rel="noopener noreferrer">
            link para autenticação
          </a>{" "}
          e siga os passos indicados pelo banco.
          <br />
          <br />
          Depois, copie o código de autorização gerado e cole no campo abaixo
        </div>
      )}
      <TextInput
        label="Cole o código gerado aqui"
        inputStyle={{ textAlign: "center" }}
        md={12}
        onChange={(_, v) => dispatch(setApiAuthorizationCode(v))}
        forceUppercase={false}
      />
    </ModalBase>
  );
};
