import moment from "moment";
import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import {
  DatePicker,
  FixedField,
  FormButton,
  ModalBase,
} from "../../../../../components";
import DuracoesJornadaService from "../../../../../services/jornada/duracoes/DuracoesJornadaService";
import { dateDiffInDays } from "../../../../../coreUtils";
import { showConfirmation } from "../../../../../components/ConfirmationModal";

export const AlterarDuracaoModal = ({
  notifyEvent,
  selected,
  dataIniSelec,
  dataFimSelec,
  nomeRota,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataFim, setDataFim] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!(dataFim instanceof Date)) {
      toastr.warning("Atenção", "Verifique a data informada.");
      return false;
    }

    const diasDiff = dateDiffInDays(dataIniSelec, dataFim);

    if (diasDiff <= 7) {
      await gravarDados();
      return;
    }

    showConfirmation(
      "A duração informada é maior que 7 dias. Deseja continuar?",
      gravarDados,
      () => {},
      "sm"
    );
  };

  const gravarDados = async () => {
    setLoading(true);
    try {
      const payload = {
        id_jornada_cab: selected,
        nova_data_encerra: dataFim.toISOString().split("T")[0],
      };

      const [ok] = await DuracoesJornadaService.alterarDataEncerramento(
        payload
      );
      if (ok) {
        await notifyEvent();
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  const toggle = () => {
    if (!isOpen) {
      setDataFim(dataFimSelec);
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton
        color="warning"
        md="auto"
        onClick={() => toggle()}
        padded={false}
        disabled={[0, null, undefined].includes(selected)}
      >
        Alterar Encerramento
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title={`Alterar Encerramento de Jornada - ${nomeRota}`}
        number="0023_2"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <FixedField
            label="Início Atual"
            value={moment(dataIniSelec).format("DD/MM/YYYY")}
          />
          <FixedField
            label="Encerramento Atual"
            value={moment(dataFimSelec).format("DD/MM/YYYY")}
          />
          <DatePicker
            label="Novo Encerramento"
            md={4}
            value={dataFim}
            onChange={(v) => setDataFim(moment.isMoment(v) ? v.toDate() : v)}
            autoFocus
          />
        </Row>
      </ModalBase>
    </>
  );
};
