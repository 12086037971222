import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import {
  caixaLojaRoute,
  gerencCaixaLojaRoute,
} from "../../../routes/modules/financeiro";
import { BiMoneyWithdraw } from "react-icons/bi";
import { TbCash } from "react-icons/tb";

export const Caixa = () => {
  return (
    <PageContainer title="Caixa">
      <MenuGroup>
        <MenuGroupButton
          label="Caixa Loja"
          icon={BiMoneyWithdraw}
          pathname={caixaLojaRoute.path}
          nroTela={caixaLojaRoute.nroTela}
        />
        <MenuGroupButton
          label="Gerenc. Caixa Loja"
          icon={TbCash}
          pathname={gerencCaixaLojaRoute.path}
          nroTela={gerencCaixaLojaRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
