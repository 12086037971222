import unidecode from "unidecode";
import { apiGet, apiPost, urlParams, apiPut, apiDelete } from "../../api";
import { viewDownloadPDF } from "../../coreUtils";
import { docPost } from "../../pdf";
import moment from "moment";
import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/ordem_servico";
const URL_BASE_ITENS = `${URL_BASE}/itens`;

const OrdemServicoService = {
  alterar: async (payload) => await apiPutV2(`${URL_BASE}/alterar/`, payload),
  alterarCliente: async (payload) =>
    await apiPutV2(`${URL_BASE}/alterar_cliente/`, payload),
  alterarTecnicoHorasItem: async (payload) =>
    await apiPutV2(`${URL_BASE}/itens/alterar_tecnico_horas/`, payload),
  buscar: async (idOS) => await apiGetV2(`${URL_BASE}/buscar/${idOS}/`),
  buscarDadosItem: async (idItem, tipo) =>
    await apiGet(`${URL_BASE}/itens/buscar/?tipo=${tipo}&id_item=${idItem}`),
  buscarFotos: async (idOs) =>
    await apiGet(`${URL_BASE}/fotos/buscar/${idOs}/`),
  buscarParametros: async () => await apiGet("/tela/central_os/"),
  concluirServico: async (payload) =>
    await apiPut(`${URL_BASE}/concluir_servico/`, payload),
  enviarEmail: async (idOS) =>
    await apiPost(`${URL_BASE}/enviar_email/`, { id_os: idOS }),
  enviarParaExecucao: async (idOS) =>
    await apiPut(`${URL_BASE}/enviar_para_execucao/`, { id: idOS }),
  excluirFoto: async (idFoto) =>
    await apiDelete(`${URL_BASE}/fotos/excluir/${idFoto}/`),
  excluirOS: async (idOS) => await apiDeleteV2(`${URL_BASE}/excluir/${idOS}/`),
  finalizar: async (payload) => await apiPut(`${URL_BASE}/finalizar/`, payload),
  listar: async (params) =>
    await apiGet(`${URL_BASE}/listar/${urlParams(params)}`),
  listarVisaoKanban: async (params) =>
    await apiGet(`${URL_BASE}/listar_visao_kanban/${urlParams(params)}`),
  incluir: async (payload) =>
    await apiPostV2(`${URL_BASE}/incluir/`, payload, {}, true),
  incluirFoto: async (payload) =>
    await apiPost(`${URL_BASE}/fotos/incluir/`, payload),
  incluirItem: async (payload) =>
    await apiPost(`${URL_BASE}/itens/incluir/`, payload),
  imprimirAbertura: async (idOS) => {
    const [ok, dados] = await apiGetV2(`${URL_BASE}/impressao/buscar/${idOS}/`);

    if (!ok) {
      return false;
    }

    const arq = await docPost("/ordem_servico/abertura/", dados, {
      errorMessage: "Erro ao Imprimir Abertura da O.S.",
    });

    if (!arq) return false;

    const fileName = `OS_${idOS}_${unidecode(
      dados.os.nome_cliente.replaceAll(" ", "_")
    )}_${moment(new Date()).format("YYYYMMDD")}`;

    viewDownloadPDF(arq, fileName);
  },
  imprimirFechamento: async (idOS) => {
    const [ok, dados] = await apiGetV2(`${URL_BASE}/impressao/buscar/${idOS}/`);

    if (!ok) {
      return false;
    }

    const arq = await docPost("/ordem_servico/fechamento/", dados, {
      errorMessage: "Erro ao Imprimir Fechamento da O.S.",
    });

    if (!arq) return false;

    const fileName = `OS_${idOS}_${unidecode(
      dados.os.nome_cliente.replaceAll(" ", "_")
    )}_${moment(new Date()).format("YYYYMMDD")}`;

    viewDownloadPDF(arq, fileName);
  },
  historicoVeiculo: async (placa) =>
    await apiGetV2(`${URL_BASE}/historico_veiculo/${placa}/`),
  reabrirOsConcluida: async (idOs) =>
    await apiPut(`${URL_BASE}/reabrir_os_concluida/`, { id_os: idOs }),
  estornarRecCaixa: async (payload) =>
    await apiPutV2(`${URL_BASE}/estornar_recebimento/`, payload),
  buscarFormasRec: async (idOs) =>
    await apiGetV2(`${URL_BASE}/buscar_formas_rec/${idOs}/`),
  buscarLogs: async (idOs) =>
    await apiGetV2(`${URL_BASE}/buscar_logs/${idOs}/`),
  verificarPodeReabrir: async (idOs) =>
    await apiGetV2(`${URL_BASE}/verificar_pode_reabrir/${idOs}/`, null, {
      successMessage: false,
    }),
  reabrir: async (idOs) =>
    await apiPutV2(`${URL_BASE}/reabrir/`, { id_os: idOs }),
  itens: {
    resumo: (idOs) => apiGetV2(`${URL_BASE_ITENS}/resumo/${idOs}/`),
  },
};

export default OrdemServicoService;
