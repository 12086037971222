import moment from "moment";
import {
  formatDateDDMMAA,
  formatNumberField,
  formatRow,
  removeSpecialChars,
  verificaPessoa,
} from "../GerarRemessa";

// ============================ REGISTROS E CAMPOS ============================

export const rem400headerGrafeno = (dadosConta) => [
  ["0", 1, 1],
  ["1", 2, 2],
  ["REMESSA", 3, 9],
  ["01", 10, 11],
  ["COBRANCA", 12, 26],
  [formatNumberField(dadosConta.cod_cedente), 27, 46], // Número da conta Grafeno
  [removeSpecialChars(dadosConta.nome_empresa), 47, 76], // Nome Empresa
  ["274", 77, 79], // Número do Banco emissor na câmara de compensação
  ["BMPMONEYPLUS", 80, 94],
  [formatDateDDMMAA(new Date()), 95, 100], // Data da Gravação
  ["", 101, 108], // Branco
  ["MX", 109, 110], // Identificação do Sistema
  [dadosConta.nro_remessa, 111, 117], // Número sequencial de remessa
  ["", 118, 394], // Branco
  [1, 395, 400], // Sequencial Registro
];

export const rem400T1Grafeno = (
  dadosConta,
  dup,
  seuNumero,
  enderCliente,
  temInfoAvalista,
  temDesconto,
  seq
) => [
  ["1", 1, 1], // Identificação - 1
  [0, 2, 6], // Não utilizado
  ["", 7, 7], // Não utilizado
  [0, 8, 12], // Não utilizado
  [0, 13, 19], // Não utilizado
  ["", 20, 20], // Não utilizado
  [0, 21, 21], // Zero
  [parseInt(dadosConta.tipo_carteira), 22, 24], // Carteira
  [formatNumberField(dadosConta.agencia), 25, 29], // Agência
  [dadosConta.nro_conta.padStart(8, "0"), 30, 37], // Conta Corrente
  [seuNumero, 38, 62], // No Controle do Participante - Uso da Empresa
  [274, 63, 65], // Código do banco a ser debitado na câmara de compensação
  [parseFloat(dadosConta.perc_multa) === 0 ? 0 : 2, 66, 66], // Campo de Multa - 0: Sem multa, 2: Considerar multa
  [formatNumberField(dadosConta.perc_multa), 67, 70], // Percentual de Multa
  [dup.nosso_numero, 71, 82], // Identificação do Título no Banco - Nosso número
  [0, 83, 92], // Desconto - Valor do desconto por dia.
  [0, 93, 93], // Não utilizado
  ["", 94, 94], // Não utilizado
  ["", 95, 104], // Não utilizado
  ["", 105, 105], // Não utilizado
  ["", 106, 106], // Não utilizado
  ["01", 107, 108], // Quantidade de Pagamentos possíveis - por ora, deverá ser fixado em 01
  [1, 109, 110], // Identificação da Ocorrência - 01 - Remessa, 02 - Pedido de baixa, 04 - Concessão de abatimento, 06 - Alteração de vencimento, 07 - Atualização/Inclusão do Número de Controle do Participante, 08 - Atualização Número de Documento (Seu Número), 09 - Instrução para protesto, 10 - Atualização/Inclusão da Chave da NF, 19 - Desistência/Cancelamento de protesto, 23 - Instrução para Grafeno Titularidades
  [seuNumero, 111, 120], // No do Documento
  [formatDateDDMMAA(dup.vencimento), 121, 126], // Data do Vencimento do Título - DDMMAA
  [formatNumberField(dup.vlr_titulo), 127, 139], // Valor do Título
  [0, 140, 142], // Banco Encarregado da Cobrança - Preencher com zeros
  [0, 143, 147], // Agência Depositária - Preencher com zeros
  [dadosConta.especie_tit, 148, 149], // Espécie de Título - 01 - Duplicata, 02 - Nota promissória, 03 - Nota de seguro, 04 - Cobrança Seriada, 05 - Recibo, 10 - Letras de câmbio, 11 - Nota de débito, 12 - Duplicata de serviço, 31 - Cartão de crédito, 32 - boleto de proposta, 99 - Outros
  ["N", 150, 150], // Identificação - Sempre "N"
  [formatDateDDMMAA(dup.emissao), 151, 156], // Data da Emissão do Título - DDMMAA
  [0, 157, 158], // Não utilizado
  [0, 159, 160], // Não utilizado
  [formatNumberField(dadosConta.vlr_multa), 161, 173], // Valor a ser Cobrado por Dia de Atraso
  [temDesconto ? formatDateDDMMAA(dup.data_desconto) : 0, 174, 179], // Data Limite P/Concessão de Desconto
  [formatNumberField(dup.vlr_desconto), 180, 192], // Valor do Desconto
  [0, 193, 205], // Valor do IOF
  [0, 206, 218], // Valor do Abatimento a ser Concedido ou Cancelado
  [formatNumberField(verificaPessoa(dup.cpf_cnpj_cliente)), 219, 220], // Identificação do Tipo de Inscrição do Pagador - 01-CPF 02-CNPJ
  [formatNumberField(dup.cpf_cnpj_cliente), 221, 234], // No Inscrição do Pagador - CPF/CNPJ
  [dup.nome_cliente, 235, 274], // Nome Pagador
  [enderCliente, 275, 314], // Endereço Pagador
  [dup.mensagem_1, 315, 326], // 1a Mensagem
  [formatNumberField(dup.cep_cliente.replace("-", "")), 327, 334], // CEP Pagador
  [
    temInfoAvalista
      ? dadosConta.cnpj_avalista.replace(/\D/g, "").padStart(15, "0") +
        "  " +
        dadosConta.nome_avalista
      : dup.mensagem_2,
    335,
    394,
  ], // Sacador/Avalista ou 2a Mensagem - Decomposição
  [seq, 395, 400], // Seq
];

export const rem400T7Grafeno = (dadosConta, dup, seq) => [
  ["7", 1, 1], // Identificação - 7
  [dadosConta.endereco_avalista, 2, 46], // Endereço do Sacador/Avalista Final
  [dadosConta.cep_avalista.replace("-", ""), 47, 54], // CEP
  [dadosConta.cidade_avalista, 55, 74], // Cidade
  [dadosConta.uf_avalista, 75, 76], // UF
  ["", 77, 366], // Reserva - Branco
  [parseInt(dadosConta.tipo_carteira), 367, 369], // No da Carteira
  [formatNumberField(dadosConta.agencia), 370, 374], // Agência Beneficiário
  [dadosConta.nro_conta.padStart(8, "0"), 375, 382], // Conta Corrente
  [dup.nosso_numero, 383, 394], // Nosso Número
  [seq, 395, 400], // Seq
];

export const rem400trailerGrafeno = (seq) => [
  ["9", 1, 1], // Identificador
  ["", 2, 394], // Filler
  [seq, 395, 400], // Seq
];

// ============================== GERAÇÃO DO ARQUIVO ==============================

export const layoutGrafeno = (dadosConta, duplicatas) => {
  const temInfoAvalista = ![null, undefined, ""].includes(
    dadosConta.cnpj_avalista?.trim()
  );

  const multiIndex = temInfoAvalista ? 2 : 1;

  // Header
  const header = formatRow(...rem400headerGrafeno(dadosConta));

  const detalhes = "".concat(
    ...duplicatas.map((dup, index) => {
      const seuNumero = `${dup.numero}-${dup.parcela}`.padStart(10, "0");

      const enderCliente =
        dup.endereco_cliente + ", " + dup.nro_endereco_cliente;

      return (
        // Registro Tipo 1
        formatRow(
          ...rem400T1Grafeno(
            dadosConta,
            dup,
            seuNumero,
            enderCliente,
            temInfoAvalista,
            parseFloat(dup.vlr_desconto) > 0,
            index * multiIndex + 2
          )
        ) +
        // Registro Tipo 7
        (temInfoAvalista
          ? formatRow(
              ...rem400T7Grafeno(dadosConta, dup, index * multiIndex + 3)
            )
          : "")
      );
    })
  );

  // Trailer
  const trailer = formatRow(
    ...rem400trailerGrafeno(duplicatas.length * multiIndex + 2)
  );

  return header + detalhes + trailer;
};

export const nomeArqGrafeno = (dadosConta) =>
  "CG".concat(
    moment(new Date()).format("DDMMYYYY"),
    removeSpecialChars(dadosConta.nome_empresa)
      .replace(/ /g, "")
      .substring(0, 10),
    ".REM"
  );
