import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cli_obrig_cpf: false,
  cli_obrig_cnpj: false,
  cli_perm_curinga: true,
  for_obrig_cpf: false,
  for_obrig_cnpj: false,
  cli_pad_revenda_marcado: false,
};

export const cadastroSlice = createSlice({
  name: "cadastro_slice",
  initialState: initialState,
  reducers: {
    setupCadastro: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setCliObrigCpf: (state, action) => {
      state.cli_obrig_cpf = action.payload;
    },
    setCliObrigCnpj: (state, action) => {
      state.cli_obrig_cnpj = action.payload;
    },
    setCliPermCuringa: (state, action) => {
      state.cli_perm_curinga = action.payload;
    },
    setCliPadRevendaMarcado: (state, action) => {
      state.cli_pad_revenda_marcado = action.payload;
    },
    setForObrigCpf: (state, action) => {
      state.for_obrig_cpf = action.payload;
    },
    setForObrigCnpj: (state, action) => {
      state.for_obrig_cnpj = action.payload;
    },
  },
});

export const {
  setupCadastro,
  setCliObrigCpf,
  setCliObrigCnpj,
  setCliPermCuringa,
  setForObrigCpf,
  setForObrigCnpj,
  setCliPadRevendaMarcado,
} = cadastroSlice.actions;

export default cadastroSlice.reducer;
