import React from "react";
import { Row } from "reactstrap";
import { ComboBox, FormCheckbox } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  setPermiteManipularVlrUnitario,
  setIdLayoutImpressao,
} from "../store/pedido_externo_slice";

const layoutImpressaoOptions = [
  {
    label: "1 - Layout Padrão, Sem informações da Empresa",
    value: 1,
  },
  {
    label: "2 - Textos maiores e espaçados",
    value: 2,
  },
  {
    label: "3 - Similar ao Layout 1, com informações da Empresa",
    value: 3,
  },
  {
    label: "4 - Valor unitário e desconto total do item",
    value: 4,
  },
  {
    label: "5 - Similar ao Layout 1, cabeçalho com informações de entrega",
    value: 5,
  },
];

export const PersonPedidoExterno = () => {
  const store = useSelector((state) => state.pedido_externo);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Manipular Valor Unitário do Produto"
          checked={store.permite_manipular_vlr_unitario}
          onChange={() =>
            dispatch(
              setPermiteManipularVlrUnitario(
                !store.permite_manipular_vlr_unitario
              )
            )
          }
        />
      </Row>
      <Row>
        <ComboBox
          md={8}
          label="Layout de Impressão"
          options={layoutImpressaoOptions}
          defaultValue={store.id_layout_impressao}
          onChange={(s) => dispatch(setIdLayoutImpressao(s?.value))}
        />
      </Row>
    </>
  );
};
