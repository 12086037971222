import { formatNumber } from "../../../../coreUtils";

const columns = (hideTotals) => [
  [
    {
      dataField: "desc",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão (R$)",
      align: "right",
      headerAlign: "right",
      formatter: (cell, row) => formatNumber(hideTotals ? 9999.99 : cell),
      style: hideTotals ? { filter: "blur(5px)", userSelect: "none" } : {},
    },
  ],
  [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data",
      text: "Data",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "valor",
      text: "Total (R$)",
      align: "right",
      headerAlign: "right",
      formatter: (cell, row) => formatNumber(cell),
    },
    {
      dataField: "comissao",
      text: "Comissão (R$)",
      align: "right",
      headerAlign: "right",
      formatter: (cell, row) => formatNumber(cell),
    },
  ],
  [
    {
      dataField: "nome",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => formatNumber(cell, true, 0),
    },
    {
      dataField: "valor_total",
      text: "Valor total",
      align: "right",
      headerAlign: "right",
      formatter: (cell, row) => formatNumber(cell),
    },
    {
      dataField: "comissao",
      text: "Comissão (R$)",
      align: "right",
      headerAlign: "right",
      formatter: (cell, row) => formatNumber(cell),
    },
  ],
];

export default columns;
