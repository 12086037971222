import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import TextInput from "../../TextInput";
import BotaoPesq from "../BotaoPesq";
import IntegerFormInput from "../../IntegerFormInput";
import { ConcatShortcut } from "../../ConcatShortcut";
import { useRef } from "react";
import { useEffect } from "react";
import { filterFormFocusableElements } from "../../../coreUtils";

const CamposPesqCliente = ({
  md = 5,
  mdIdent,
  openModal,
  onExitIdentificador,
  onClickPesq,
  refNome,
  refId,
  loadingPesq,
  disabledDescricao = true,
  onChangeDescricao,
  autoFocus,
  idCliente,
  nomeCliente,
  disabledIdPesq,
  showShortcut,
  label,
  divClassName,
  pistaPesq,
  setPistaPesq,
}) => {
  const [triggerOnExit, setTriggerOnExit] = useState(false);
  const shortcutRef = useRef();

  const handleChangeId = (v) => {
    if (pistaPesq !== v) {
      setTriggerOnExit(true);
      setPistaPesq(v);
    }
  };

  const handleOnExit = async (_, v) => {
    if (triggerOnExit) {
      const ok = await onExitIdentificador(_, v);
      if (ok) {
        setTriggerOnExit(false);
      }
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "F3") {
      e.preventDefault();
      openModal(e.target.value);
    }
    if (e.key === "F4" && shortcutRef.current) shortcutRef.current.click();
    if (e.key === "Enter") {
      const formElements = filterFormFocusableElements(
        e.target.form.elements
      ).filter((el) => el !== e.target);

      if (
        formElements.length === 0 &&
        !["", null, undefined].includes(pistaPesq)
      ) {
        onExitIdentificador(null, pistaPesq);
      }
    }
  };

  useEffect(() => {
    if (idCliente !== pistaPesq) {
      setPistaPesq(idCliente);
      onExitIdentificador(null, idCliente);
    }
  }, [idCliente]);

  return (
    <Col md={md} className={divClassName}>
      <Row>
        <Col
          md={mdIdent ?? (showShortcut ? 5 : 3)}
          style={{ paddingRight: "0.53rem" }}
        >
          <Row>
            <IntegerFormInput
              label={label}
              onChange={handleChangeId}
              onBlur={handleOnExit}
              ref={refId}
              colClassName="pr-0"
              additionalButton={
                <>
                  {!disabledIdPesq && (
                    <BotaoPesq onClick={onClickPesq} loading={loadingPesq} />
                  )}
                  {showShortcut && (
                    <ConcatShortcut
                      selected={idCliente}
                      concatModelName="cliente"
                      setSelected={(v) => onExitIdentificador(null, v)}
                      ref={shortcutRef}
                    />
                  )}
                </>
              }
              onKeyDown={handleOnKeyDown}
              autoFocus={autoFocus}
              defaultValue={idCliente}
              disabled={disabledIdPesq}
            />
          </Row>
        </Col>
        <TextInput
          md={null}
          label="Nome"
          disabled={disabledDescricao}
          ref={refNome}
          colClassName="px-0"
          onChange={(_, v) => onChangeDescricao(v)}
          value={nomeCliente}
          selectTextOnFocus
        />
      </Row>
    </Col>
  );
};

export default CamposPesqCliente;
