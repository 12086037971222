import React from "react";
import { useState } from "react";
import {
  modalTitleNumeracaoNfe,
  routesBaseNumeracaoNfe,
} from "../NumeracaoNfe";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { IntegerFormInput } from "../../../../../components";
import { toastr } from "react-redux-toastr";

export const CadastroNumeracaoNfeModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [numeroUltNfe, setNumeroUltNfe] = useState(null);
  const [serie, setSerie] = useState(null);

  const limparDados = () => {
    setNumeroUltNfe(null);
    setSerie(null);
  };

  const fetchData = (data) => {
    setNumeroUltNfe(data.numero_ult_nfe);
    setSerie(data.serie);
  };

  const submitPayload = (action) => {
    if ([null, undefined].includes(serie)) {
      toastr.warning("Atenção", "Por favor, informe a Série");
      return false;
    }

    if ([null, undefined].includes(numeroUltNfe)) {
      toastr.warning("Atenção", "Por favor, o Último Número de NFe");
      return false;
    }

    return action === MODAL_ACTIONS.ADD
      ? {
          nro_ult_nfe_emitida: numeroUltNfe,
          serie: serie,
        }
      : { id: selected, numero_alt: numeroUltNfe, serie_alt: serie };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleNumeracaoNfe}
      size="sm"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseNumeracaoNfe}
      number="0089_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <IntegerFormInput
          md={4}
          label="Série"
          defaultValue={serie}
          onChange={setSerie}
        />
        <IntegerFormInput
          md={6}
          label="Último Número de NFe"
          defaultValue={numeroUltNfe}
          onChange={setNumeroUltNfe}
        />
      </Row>
    </ModalCadastroV2>
  );
};
