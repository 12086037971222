import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  FormCheckbox,
  PageContainer,
  TextInput,
} from "../../../../../components";
import MarketplacesIntegracoesService from "../../../../../services/vendas/MarktetplacesIntegracoesService";
import {IncluirLayoutGrid} from "./components/IncluirLayoutGrid"

export const IncluirLayout = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pista, setPista] = useState();

  const [layout, setLayout] = useState();
  const [token, setToken] = useState();
  const [impressora, setImpressora] = useState();
  const [envProd, setEnvProd] = useState(false);
  const [recebCli, setRecebCli] = useState(false);
  const [recebPed, setRecebPed] = useState(false);
  const [authKey, setAuthKey] = useState();
  const [authSecret, setAuthSecret] = useState();
  const [urlBase, setUrlBase] = useState();

  const buscarDados = async (p) => {
    const [ok, ret] = await MarketplacesIntegracoesService.listarLayout();
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setDados();
    setLoading(false);
  };

  return (
    <PageContainer title="Incluir Layout" number="0124_1">
      <Card body>
        <Row>
          <AsyncComboBox
            md={6}
            concatModelName="layouts"
            label="Layout"
            onChange={(s) => setLayout(s?.value ?? null)}
          />
          <TextInput
            md={6}
            label="Token"
            value={token}
            onChange={(e, v) => setToken(v)}
            maxLength={80}
          />
        </Row>
        <Row>
          <TextInput
            md={6}
            label="Impressora"
            value={impressora}
            onChange={(e, v) => setImpressora(v)}
            maxLength={10}
          />
          <FormCheckbox
            label={"Envia Produtos"}
            onChange={setEnvProd}
            padded={true}
          />
          <FormCheckbox
            label={"Recebe Clientes"}
            onChange={setRecebCli}
            padded={true}
          />
          <FormCheckbox
            label={"Recebe Pedidos"}
            onChange={setRecebPed}
            padded={true}
          />
        </Row>
        <Row>
          <TextInput
            md={4}
            label="Chave de Autenticação"
            value={authKey}
            onChange={(e, v) => setAuthKey(v)}
            maxLength={40}
          />
          <TextInput
            md={4}
            label="Segredo de autenticação"
            value={authSecret}
            onChange={(e, v) => setAuthSecret(v)}
            maxLength={40}
          />
          <TextInput
            md={4}
            label="URL Base"
            value={urlBase}
            onChange={(e, v) => setUrlBase(v)}
            maxLength={40}
          />
        </Row>
      </Card>
      <Card body>
        <IncluirLayoutGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
