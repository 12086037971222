import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroNaturezaOperNfseModal } from "./components/CadastroNaturezaOperNfseModal";
import { NaturezaOperNfseGrid } from "./components/NaturezaOperNfseGrid";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { BotaoPesquisar, PageContainer } from "../../../../components";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import NotaFiscalServicoService from "../../../../services/docs_eletron/NotaFiscalServicoService";
import { LogsNaturezaOperNfseModal } from "./components/LogsNaturezaOperNfseModal";

export const modalTitleNaturezaOperNfse = "Natureza de Operação de NFS-e";

export const NaturezaOperNfse = () => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);

  const buscarDados = async () => {
    const [ok, ret] = await NotaFiscalServicoService.naturezaOperacao.listar();
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const toggleLogs = () => setLogsOpen(!logsOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const mostrarLogs = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleLogs();
    }, 1);
  };

  const onActivate = () => {
    carregarDados();
  };

  useEffect(onActivate, []);

  return (
    <PageContainer
      title="Cadastro de Naturezas de Operação de NFS-e"
      number="0127"
      canGoBack
    >
      <Card body>
        <Row>
          <BotaoPesquisar
            padded={false}
            onClick={carregarDados}
            loading={loading}
          />
          <BotaoIncluir padded={false} toggle={toggleCadastro} />
          <CadastroNaturezaOperNfseModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleNaturezaOperNfse}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0127_2"
            selected={selected}
            routeBase={NotaFiscalServicoService.naturezaOperacao.urlBase}
            notifyEvent={notifyEvent}
          />
          <LogsNaturezaOperNfseModal
            isOpen={logsOpen}
            toggle={toggleLogs}
            selected={selected}
          />
        </Row>
      </Card>
      <Card body>
        <NaturezaOperNfseGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
          mostrarLogs={mostrarLogs}
        />
      </Card>
    </PageContainer>
  );
};
