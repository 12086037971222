import React from "react";
import { useState } from "react";
import { BsBoxSeam, BsWrench } from "react-icons/bs";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { UncontrolledTooltip } from "reactstrap";

export const ProdServIcone = ({ value = "P" }) => {
  const [iconId] = useState("ips-" + Math.floor(Math.random() * Date.now()));

  const tipo = (value ?? "").substring(0, 1);

  let Icon;

  let size;

  if (tipo === "P") {
    Icon = BsBoxSeam;
    size = 14;
  } else if (tipo === "S") {
    Icon = MdOutlineHomeRepairService;
    size = 16;
  } else if (tipo === "T") {
    Icon = BsWrench;
    size = 14;
  }

  return (
    <>
      <Icon
        id={iconId}
        size={size}
        style={{ display: "flex", margin: "auto" }}
      />
      <UncontrolledTooltip target={iconId}>
        {{ S: "Serviço", P: "Produto", T: "Terceirização" }[tipo]}
      </UncontrolledTooltip>
    </>
  );
};
