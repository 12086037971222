import React from "react";
import { Table, TableCheck } from "../../../../../components";
import { iconeAlterar } from "../../../../../components/cadastro";

export const ColaboradorGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "nickname",
      text: "Nickname",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "nome_perfil",
      text: "Perfil",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      sortable: true,
      formatter: (cell) => <TableCheck value={cell} />,
    },
    iconeAlterar(alterar),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
