import { apiGet, apiPost, urlParams } from "../../api";
import { apiGetV2 } from "../../apiV2";
import { docPrintReport } from "../../pdf";

const URL_BASE = "/romaneio";
const RomaneioService = {
  listarPendentes: async (
    idVendedor,
    idCliente,
    dataIni,
    dataFim,
    idVenda,
    idPedido
  ) => {
    const params = urlParams({
      id_vendedor: idVendedor,
      id_cliente: idCliente,
      data_ini: dataIni,
      data_fim: dataFim,
      id_venda: idVenda,
      id_pedido: idPedido,
    });
    return await apiGet(`${URL_BASE}/listar_pendentes/${params}`);
  },
  listarRealizados: async (dataIni, dataFim) => {
    const params = urlParams({ data_ini: dataIni, data_fim: dataFim });
    return await apiGet(`${URL_BASE}/listar_realizados/${params}`);
  },
  confirmarRomaneio: async (payload) =>
    await apiPost(`${URL_BASE}/confirmar_romaneio/`, payload, {}),
  detalheRomaneioRealizado: async (id) =>
    await apiGet(`${URL_BASE}/detalhe_romaneio_realizado/${id}/`),
  buscarDadosCarga: async (id) =>
    await apiGetV2(`${URL_BASE}/buscar_dados_carga/${id}/`),
  buscarDadosFinanceiro: async (id) =>
    await apiGetV2(`${URL_BASE}/buscar_dados_impressao_financeiro/${id}/`),
  imprimirCarga: async (id) => {
    const [ok, ret] = await RomaneioService.buscarDadosCarga(id);

    if (ok) {
      await docPrintReport(
        "/pedido/romaneio/carga/",
        { data: ret, id: id },
        "0048_3"
      );
    }
  },
  imprimirFinanceiro: async (id) => {
    const [ok, ret] = await RomaneioService.buscarDadosFinanceiro(id);

    if (ok) {
      await docPrintReport(
        "/pedido/romaneio/financeiro/",
        { data: ret.dados_romaneio, id: id, layout: ret.layout_impressao },
        "0048_3"
      );
    }
  },
};

export default RomaneioService;
