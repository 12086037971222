import React, { useState } from "react";
import { AsyncComboBox, ModalBase } from "../../../../../components";
import { BoletoService } from "../../../../../services/bancario/BoletoService";
import { Row } from "reactstrap";
import { showWarning } from "../../../../../components/AlertaModal";

export const ConfirmaCancelarBoletoModal = ({
  isOpen,
  toggle,
  selected,
  selectedLayout,
  notifyAction,
}) => {
  const [loading, setLoading] = useState(false);
  const [motivo, setMotivo] = useState(null);

  const onConfirm = async () => {
    setLoading(true);
    try {
      const payload = {
        id_duplicata: selected,
      };
      if (selectedLayout === "INT") {
        if (["", null, undefined].includes(motivo)) {
          showWarning("Por favor, informe o motivo do cancelamento");
          return;
        }
        payload["motivo"] = motivo;
      }

      const [ok] = await BoletoService.cancelarBoleto(payload);
      if (ok) {
        toggle();
        notifyAction();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Confirmação Cancelamento de Boleto"
      number="0087_3"
      loadingConfirm={loading}
      onConfirm={onConfirm}
    >
      <div style={{ textAlign: "center" }}>
        Você tem certeza que deseja cancelar o boleto?
        <br />
        <span style={{ color: "red", fontWeight: "bolder" }}>
          Essa ação NÃO pode ser desfeita!
        </span>
      </div>
      {selectedLayout === "INT" && (
        <Row>
          <AsyncComboBox
            label="Motivo para Cancelamento"
            md={12}
            isConcatField
            concatModelName="motivo"
            defaultOptions
            isSearchable
            onChange={(s) => setMotivo(s?.label)}
          />
        </Row>
      )}
    </ModalBase>
  );
};
