import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, TabPane } from "reactstrap";
import {
  ComboBox,
  FormCheckbox,
  NumberInput,
} from "../../../../../../../components";
import {
  setEnquadIpi,
  setPercIpi,
  setSitTribIpi,
  setSomaVlrFreteBcIpi,
  setSomaVlrIpiBcIcms,
  setSomaVlrIpiBcIcmsSt,
} from "../../../store/cadRegraTributacaoSlice";

const csts_ipi = [
  { label: "00 - Entrada com recuperação de crédito", value: "00" },
  { label: "01 - Entrada tributada com alíquota zero", value: "01" },
  { label: "02 - Entrada isenta", value: "02" },
  { label: "03 - Entrada não-tributada", value: "03" },
  { label: "04 - Entrada imune", value: "04" },
  { label: "05 - Entrada com suspensão", value: "05" },
  { label: "49 - Outras Entradas", value: "49" },
  { label: "50 - Saída tributada", value: "50" },
  { label: "51 - Saída tributada com alíquota zero", value: "51" },
  { label: "52 - Saída isenta", value: "52" },
  { label: "53 - Saída não-tributada", value: "53" },
  { label: "54 - Saída imune", value: "54" },
  { label: "55 - Saída com suspensão", value: "55" },
  { label: "99 - Outras saídas", value: "99" },
];
const enquadramentos_ipi = [
  { label: "999", value: "999" },
  { label: "108", value: "108" },
  { label: "109", value: "109" },
  { label: "304", value: "304" },
  { label: "102", value: "102" },
];

export const TabIPI = () => {
  const store = useSelector((state) => state.cadRegraTributacao);
  const dispatch = useDispatch();

  return (
    <TabPane tabId="3" className="p-2">
      <Row>
        <ComboBox
          label="Situação Tributária"
          isSearchable={false}
          md={10}
          options={csts_ipi}
          defaultOptions
          onChange={(selected) => dispatch(setSitTribIpi(selected?.value))}
          defaultValue={store.sitTribIpi}
        />
        <NumberInput
          label="Alíquota"
          md={2}
          onChange={(v) => dispatch(setPercIpi(v))}
          value={store.percIpi}
          isPercentage
        />
      </Row>
      <Row>
        <ComboBox
          label="Enquadramento"
          isSearchable={false}
          md={10}
          options={enquadramentos_ipi}
          defaultOptions
          onChange={(selected) => dispatch(setEnquadIpi(selected?.value))}
          defaultValue={store.enquadIpi}
        />
      </Row>
      <Row>
        <FormCheckbox
          name="soma_ipi_bc_vlr_icms_st"
          label="Soma valor de IPI na B.C. do ICMS ST (Substituição Tributária)"
          checked={store.somaVlrIpiBcIcmsSt}
          onChange={() =>
            dispatch(setSomaVlrIpiBcIcmsSt(!store.somaVlrIpiBcIcmsSt))
          }
        />
        <FormCheckbox
          name="soma_ipi_bc_vlr_icms"
          label="Soma valor de IPI na B.C. do ICMS (Web)"
          checked={store.somaVlrIpiBcIcms}
          onChange={() =>
            dispatch(setSomaVlrIpiBcIcms(!store.somaVlrIpiBcIcms))
          }
        />
        <FormCheckbox
          name="soma_vlr_frete_bc_ipi"
          label="Soma valor de Frete na B.C. do IPI (Web)"
          checked={store.somaVlrFreteBcIpi}
          onChange={() =>
            dispatch(setSomaVlrFreteBcIpi(!store.somaVlrFreteBcIpi))
          }
        />
      </Row>
    </TabPane>
  );
};
