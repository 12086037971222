import { toastr } from "react-redux-toastr";
import { api } from "../../api";

const URL_BASE = "/cadastro/vend_tec_serv";

const _processaRetorno = (res) => {
  if (res?.data) {
    if (res.data.err) {
      toastr.error("Erro", res.data.msg);
    } else {
      toastr.success("Sucesso", res.data.msg);
      return true;
    }
  }
  return false;
};

const VendedorTecnicoServicoService = {
  urlBase: URL_BASE,
  listar: async (filtro) => {
    let url = `${URL_BASE}/listar/`;
    if (!["", null, undefined].includes(filtro)) {
      url = `${url}?nome=${filtro}`;
    }
    const ret = await api
      .get(url)
      .catch((err) => toastr.error("Erro", err.message));
    if (ret) {
      if (ret.data) {
        if (!ret.data.err) {
          return ret.data.res;
        } else {
          toastr.info("", ret.data.msg);
        }
      }
    }
    return [];
  },
  buscar: async (idRegistro) => {
    const ret = await api
      .get(`${URL_BASE}/buscar/${idRegistro}/`)
      .catch((_err) => toastr.error("Erro", _err.message));
    if (ret?.data) {
      if (!ret.data.err) {
        return ret.data.res;
      } else {
        toastr.info("", ret.data.msg);
      }
    }
    return {};
  },
  adicionarCidade: async (payload) => {
    const ret = await api
      .post(`/comissao/regra_vts/cadastrar/`, payload)
      .catch((_err) => toastr.error("Erro", _err.message));
    return _processaRetorno(ret);
  },
  excluirCidade: async (idRegistro) => {
    const ret = await api
      .delete(`/comissao/regra_vts/excluir/${idRegistro}/`)
      .catch((_err) => toastr.error("Erro", _err.message));
    return _processaRetorno(ret);
  },
  buscarCidades: async (idVTS) => {
    const ret = await api
      .get(`/comissao/regra_vts/listar_cidades/?id_vts=${idVTS}`)
      .catch((_err) => toastr.error("Erro", _err.message));
    if (ret?.data) {
      if (!ret.data.err) {
        return ret.data.res;
      } else {
        toastr.info("", ret.data.msg);
      }
    }
    return [];
  },
  buscarItens: async (idVTS) => {
    const ret = await api
      .get(`/comissao/regra_vts/item/listar/?id_vts=${idVTS}`)
      .catch((_err) => toastr.error("Erro", _err.message));
    if (ret?.data) {
      if (!ret.data.err) {
        return ret.data.res;
      } else {
        toastr.info("", ret.data.msg);
      }
    }
    return [];
  },
  adicionarItem: async (payload) => {
    const ret = await api
      .post("/comissao/regra_vts/item/cadastrar/", payload)
      .catch((_err) => toastr.error("Erro", _err.message));
    return _processaRetorno(ret);
  },
  excluirItem: async (idRegistro) => {
    const ret = await api
      .delete(`/comissao/regra_vts/item/excluir/${idRegistro}/`)
      .catch((_err) => toastr.error("Erro", _err.message));
    return _processaRetorno(ret);
  },
};

export default VendedorTecnicoServicoService;
