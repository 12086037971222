import React from "react";
import { ModalDetalhesIcone, Table } from "../../../../../components";
import {
  formatNumber,
  naturalSort,
  naturalSortDate,
} from "../../../../../coreUtils";

export const columnsNfSaidaGeral = (exportacao = false) => {
  const numberFormatter = exportacao
    ? parseFloat
    : (c) => formatNumber(c, true, 2);

  return [
    {
      dataField: "modelo",
      text: "Mod.",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
      fixedColWidth: true,
      colWidth: "55px",
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
      fixedColWidth: true,
      colWidth: "90px",
    },
    {
      dataField: "serie",
      text: "Série",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
      fixedColWidth: true,
      colWidth: "55px",
    },
    {
      dataField: "chave_acesso",
      text: "Chave de Acesso",
      align: "center",
      hidden: !exportacao,
    },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
      fixedColWidth: true,
      colWidth: "90px",
    },
    {
      dataField: "desc_tipo_doc",
      text: "Tipo de Documento",
      align: "left",
      sortable: true,
      fixedColWidth: true,
      colWidth: "270px",
    },
    {
      dataField: "nome_cli",
      text: "Nome Cliente",
      align: "left",
      sortable: true,
      fixedColWidth: true,
      colWidth: "280px",
      formatter: (c) => (exportacao ? c : (c ?? "").substring(0, 38)),
    },
    {
      dataField: "tipo_contribuinte_cliente",
      text: "Contribuinte",
      align: "center",
      fixedColWidth: true,
      colWidth: "120px",
    },
    {
      dataField: "vlr_tot_nf",
      text: "Total",
      align: "right",
      formatter: (c) => numberFormatter(c),
      sortable: true,
      sortFunc: naturalSort,
      fixedColWidth: true,
      colWidth: "80px",
    },
  ];
};

export const NfSaidaGeralGrid = ({ dados, mostrarDetalhes }) => {
  const columns = [
    ...columnsNfSaidaGeral(false),
    {
      dataField: "act",
      text: "",
      align: "center",
      dummy: true,
      fixedColWidth: true,
      colWidth: "32px",
      formatter: (c, row) =>
        row.modelo !== "NFS-e" ? (
          <ModalDetalhesIcone
            toggle={() => mostrarDetalhes(row.modelo, row.id)}
          />
        ) : (
          <></>
        ),
    },
  ];

  return <Table data={dados} columns={columns} />;
};
