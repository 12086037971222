import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { AiOutlinePieChart } from "react-icons/ai";
import { BsBarChartSteps } from "react-icons/bs";
import {
  relFinancPagarDespesasLiquidadasCentroCustoRoute,
  relFinancPagarDespesasLiquidadasPlanoContasRoute,
} from "../../../routes/modules/relatorios";

export const FinanceiroPagar = () => {
  return (
    <PageContainer title="Relatórios Financeiros - Contas a Pagar">
      <MenuGroup>
        <MenuGroupButton
          label="Despesas Liquidadas por Centro de Custo"
          icon={AiOutlinePieChart}
          pathname={relFinancPagarDespesasLiquidadasCentroCustoRoute.path}
          nroTela={relFinancPagarDespesasLiquidadasCentroCustoRoute.nroTela}
        />
        <MenuGroupButton
          label="Despesas Liquidadas por Plano de Contas"
          icon={BsBarChartSteps}
          pathname={relFinancPagarDespesasLiquidadasPlanoContasRoute.path}
          nroTela={relFinancPagarDespesasLiquidadasPlanoContasRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
