import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ComboBox,
  FormCheckbox,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { RadioItem } from "../../../../components/RadioGroup";
import { Row } from "reactstrap";
import {
  setImprEmisOs,
  setLayoutImprEmisOs,
  setImprFechOs,
  setLayoutImprFechOs,
  setIdentificaTecnico,
  setAdicionaItemGrid,
  setMensagemFechOs,
  setMecUtilizaControlPlenoOs,
  setImprimeValorOsPrevia,
  setAddVlrTotBrutoImprFechOs,
  setAbrirOsIdentificaEquipamento,
  setAbrirOsUsaTaxaArmazenamento,
  setAbrirOsUsaTaxaOrcamento,
  setAbrirOsIdentificaDataHoraAgendamento,
  setUtilizaStatusAdicConcServ,
  setAbrirOsIdentificaContatoServico,
  setAbrirOsIdentificaTecnicoResp,
  setUtilizaObservItem,
  setImprimeReferFech,
  setMensagemFechOsSomenteCaixa,
  setBloqIncServicoSemTecnico,
} from "../store/os_slice";

const layoutsImprEmisOs = [
  { label: "1 - Folha A4", value: 1 },
  { label: "2 - Folha A4", value: 2 },
  { label: "3 - Matricial - Bobina 80mm largura", value: 3 },
  { label: "4 - Metade Folha A4", value: 4 },
  { label: "5 - Folha A4 - Informações do Cliente", value: 5 },
  { label: "6 - Folha A4 - Código de Barras", value: 6 },
  { label: "7 - Folha A4 - 2 Vias - Nota de Recebimento", value: 7 },
  {
    label: "8 - Folha A4 - Impressão reduzida, reaproveitamento de folha",
    value: 8,
  },
  {
    label: "9 - Folha A4 - Abertura de O.S. Automotiva",
    value: 9,
  },
  {
    label:
      "10 - Folha A4 - Abertura de O.S. - Processo de Produção de Máquinas",
    value: 10,
  },
];

const layoutsImprFechOs = [
  { label: "1 - Folha A4", value: 1 },
  { label: "2 - Matricial", value: 2 },
  { label: "3 - Matricial - Bobina 80mm largura", value: 3 },
  {
    label: "4 - Folha A4 (Web) - Fechamento de O.S. Automotiva",
    value: 4,
  },
  {
    label: "5 - Folha A4 (Web) - Destaca descontos",
    value: 5,
  },
];

export const PersonOS = () => {
  const store = useSelector((state) => state.os);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <RadioGroup
          label="Imprimir Emissão de O.S."
          value={store.impr_emis_os}
          onChange={(v) => dispatch(setImprEmisOs(v))}
        >
          <RadioItem label="Sempre Imprimir" value="S" />
          <RadioItem label="Visualizar" value="V" />
          <RadioItem label="Perguntar se Deseja Imprimir" value="P" />
          <RadioItem label="Não Imprimir" value="N" />
        </RadioGroup>
      </Row>
      <ComboBox
        md={10}
        label="Layout de Impressão da Emissão de O.S."
        options={layoutsImprEmisOs}
        defaultValue={store.layout_impr_emis_os}
        onChange={(s) => dispatch(setLayoutImprEmisOs(s?.value))}
      />
      <Row>
        <RadioGroup
          label="Imprimir Fechamento de O.S."
          value={store.impr_fech_os}
          onChange={(v) => dispatch(setImprFechOs(v))}
        >
          <RadioItem label="Sempre Imprimir" value="S" />
          <RadioItem label="Visualizar" value="V" />
          <RadioItem label="Perguntar se Deseja Imprimir" value="P" />
          <RadioItem label="Não Imprimir" value="N" />
        </RadioGroup>
      </Row>
      <Row>
        <ComboBox
          md={10}
          label="Layout de Impressão de Fechamento de O.S."
          options={layoutsImprFechOs}
          defaultValue={store.layout_impr_fech_os}
          onChange={(s) => dispatch(setLayoutImprFechOs(s?.value))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Identificar Técnico da O.S."
          checked={store.identifica_tecnico}
          onChange={() =>
            dispatch(setIdentificaTecnico(!store.identifica_tecnico))
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label="Incluir Itens na Grade de Manutenção de O.S."
          value={store.adiciona_item_grid}
          onChange={(v) => dispatch(setAdicionaItemGrid(v))}
        >
          <RadioItem label="Repetir" value="R" />
          <RadioItem label="Substituir" value="S" />
        </RadioGroup>
      </Row>
      <Row>
        <TextInput
          md={11}
          label="Mensagem de Fechamento"
          value={store.mensagem_fech_os}
          onChange={(e, v) => dispatch(setMensagemFechOs(v))}
          type="textarea"
          forceUppercase={false}
          rows={3}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="[Mec] Utiliza Controle Pleno de O.S."
          checked={store.mec_utiliza_control_pleno_os}
          onChange={() =>
            dispatch(
              setMecUtilizaControlPlenoOs(!store.mec_utiliza_control_pleno_os)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Imprimir Valor na Prévia da O.S."
          checked={store.imprime_valor_os_previa}
          onChange={() =>
            dispatch(setImprimeValorOsPrevia(!store.imprime_valor_os_previa))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Imprimir valor bruto no fechamento da O.S."
          checked={store.add_vlr_tot_bruto_impr_fech_os}
          onChange={() =>
            dispatch(
              setAddVlrTotBrutoImprFechOs(!store.add_vlr_tot_bruto_impr_fech_os)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Abertura - Identificar Equipamento"
          checked={store.abrir_os_identifica_equipamento}
          onChange={() =>
            dispatch(
              setAbrirOsIdentificaEquipamento(
                !store.abrir_os_identifica_equipamento
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Abertura - Utilizar Taxa de Armazenamento"
          checked={store.abrir_os_usa_taxa_armazenamento}
          onChange={() =>
            dispatch(
              setAbrirOsUsaTaxaArmazenamento(
                !store.abrir_os_usa_taxa_armazenamento
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Abertura - Utilizar Taxa de Orçamento"
          checked={store.abrir_os_usa_taxa_orcamento}
          onChange={() =>
            dispatch(
              setAbrirOsUsaTaxaOrcamento(!store.abrir_os_usa_taxa_orcamento)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Abertura - Identificar Data e Hora de Agendamento"
          checked={store.abrir_os_identifica_data_hora_agendamento}
          onChange={() =>
            dispatch(
              setAbrirOsIdentificaDataHoraAgendamento(
                !store.abrir_os_identifica_data_hora_agendamento
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Abertura - Identificar Contato do Serviço"
          checked={store.abrir_os_identifica_contato_servico}
          onChange={() =>
            dispatch(
              setAbrirOsIdentificaContatoServico(
                !store.abrir_os_identifica_contato_servico
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Abertura - Identificar Técnico Responsável"
          checked={store.abrir_os_identifica_tecnico_resp}
          onChange={() =>
            dispatch(
              setAbrirOsIdentificaTecnicoResp(
                !store.abrir_os_identifica_tecnico_resp
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar Status de Conclusão de Serviço"
          checked={store.utiliza_status_adic_conc_serv}
          onChange={() =>
            dispatch(
              setUtilizaStatusAdicConcServ(!store.utiliza_status_adic_conc_serv)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar Observação do Item"
          checked={store.utiliza_observ_item}
          onChange={() =>
            dispatch(setUtilizaObservItem(!store.utiliza_observ_item))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Imprimir Referência dos Itens no Fechamento"
          checked={store.imprime_refer_fech}
          onChange={() =>
            dispatch(setImprimeReferFech(!store.imprime_refer_fech))
          }
        />
      </Row>
      <Row>
        <TextInput
          md={11}
          label="Mensagem de Fechamento para O.S's Somente Caixa"
          value={store.mensagem_fech_os_somente_caixa}
          onChange={(e, v) => dispatch(setMensagemFechOsSomenteCaixa(v))}
          type="textarea"
          rows={3}
          forceUppercase={false}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear Inclusão de Serviços Sem Técnico"
          checked={store.bloq_inc_servico_sem_tecnico}
          onChange={() =>
            dispatch(
              setBloqIncServicoSemTecnico(!store.bloq_inc_servico_sem_tecnico)
            )
          }
        />
      </Row>
    </>
  );
};
