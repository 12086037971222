export const mockData = [
  {
    id: 3,
    id_cliente: 3,
    nome_cliente: "ANNA SANTOS ALVES",
    endereco_cliente: "Travessa Décio Fonseca Penteado, 1278 - Guarulhos",
    data_abertura: "11/04/2024",
    status: "LCP",
    desc_status: "Programação para Execução",
    vlr_orcado: "7550.00",
  },
  {
    id: 4,
    id_cliente: 4,
    nome_cliente: "RICARDO GOMES CARDOSO",
    endereco_cliente: "Travessa Décio Fonseca Penteado, 1278 - Guarulhos",
    data_abertura: "14/04/2024",
    status: "LCP",
    desc_status: "Programação para Execução",
    vlr_orcado: "7550.00",
  },
  {
    id: 5,
    id_cliente: 5,
    nome_cliente: "THIAGO COSTA GONCALVES",
    endereco_cliente: "Travessa Décio Fonseca Penteado, 1278 - Guarulhos",
    data_abertura: "14/04/2024",
    status: "LCP",
    desc_status: "Programação para Execução",
    vlr_orcado: "12353.00",
  },
  {
    id: 2,
    id_cliente: 2,
    nome_cliente: "ROBERTO GUEDES",
    endereco_cliente: "Travessa Décio Fonseca Penteado, 1278 - Guarulhos",
    data_abertura: "11/04/2024",
    status: "VLP",
    desc_status: "Validação de Programação de Execução",
    vlr_orcado: "4800.00",
  },
  {
    id: 1,
    id_cliente: 1,
    nome_cliente: "JOÃO DA SILVA",
    endereco_cliente: "Travessa Décio Fonseca Penteado, 1278 - Guarulhos",
    data_abertura: "09/04/2024",
    status: "AMX",
    desc_status: "Separação/Almoxarifado",
    vlr_orcado: "25355.00",
  },
];

export const mockDataItens = {
  1: [
    {
      tipo: "Produto",
      referencia: "",
      id_item: 335,
      descricao: "CD 18/12 DISJ NEMA INTERNO FUME",
      vlr_custo: "60.00",
      unidade: "UN",
      vlr_unit: "95.00",
      observ: "",
      qtd_estoque: 1,
      quantidade: 2,
      vlr_item: "95.00",
      perc_desc: "0.0",
      vlr_total: "190.00",
      status: "CMP",
    },
    {
      tipo: "Serviço",
      referencia: "",
      id_item: 1,
      descricao: "MÃO DE OBRA INSTALAÇÃO - EQUIPE 1",
      vlr_custo: "1870.00",
      unidade: "UN",
      vlr_unit: "1870.00",
      observ: "",
      qtd_estoque: 10,
      quantidade: 1,
      vlr_item: "1870.00",
      perc_desc: "0.0",
      vlr_total: "1870.00",
      status: "IOK",
    },
  ],
  2: [
    {
      tipo: "Produto",
      referencia: "",
      id_item: 7,
      descricao: "DISJUNTOR TERM. C 3 X 100A - 5KA/ 3KA - SCHNEIDER",
      vlr_custo: "298,75",
      unidade: "UN",
      vlr_unit: "298,75",
      observ: "",
      qtd_estoque: 2,
      quantidade: 4,
      vlr_item: "298,75",
      perc_desc: "0.0",
      vlr_total: "1195.00",
      status: "AGM",
    },
    {
      tipo: "Produto",
      referencia: "",
      id_item: 335,
      descricao: "DHM-72X10-555W-PAINEL SOLAR -",
      vlr_custo: "715",
      unidade: "UN",
      vlr_unit: "715",
      observ: "",
      qtd_estoque: 10,
      quantidade: 32,
      vlr_item: "715",
      perc_desc: "0.0",
      vlr_total: "22880.00",
      status: "CMP",
    },
    {
      tipo: "Serviço",
      referencia: "",
      id_item: 1,
      descricao: "MÃO DE OBRA INSTALAÇÃO - EQUIPE 2",
      vlr_custo: "3000.00",
      unidade: "UN",
      vlr_unit: "3000.00",
      observ: "",
      qtd_estoque: 0,
      quantidade: 1,
      vlr_item: "3000.00",
      perc_desc: "0.0",
      vlr_total: "3000.00",
      status: "IOK",
    },
  ],
  3: [
    {
      tipo: "Produto",
      referencia: "",
      id_item: 335,
      descricao: "DHM-72X10-555W-PAINEL SOLAR -",
      vlr_custo: "715",
      unidade: "UN",
      vlr_unit: "715",
      observ: "",
      qtd_estoque: 2,
      quantidade: 8,
      vlr_item: "715",
      perc_desc: "0.0",
      vlr_total: "5720.0",
      status: "CMP",
    },
    {
      tipo: "Serviço",
      referencia: "",
      id_item: 1,
      descricao: "MÃO DE OBRA INSTALAÇÃO - EQUIPE 3",
      vlr_custo: "860.00",
      unidade: "UN",
      vlr_unit: "860.00",
      observ: "",
      qtd_estoque: 6,
      quantidade: 1,
      vlr_item: "860.00",
      perc_desc: "0.0",
      vlr_total: "860.00",
      status: "IOK",
    },
  ],
  4: [
    {
      tipo: "Produto",
      referencia: "",
      id_item: 335,
      descricao: "CD 18/12 DISJ NEMA INTERNO FUME",
      vlr_custo: "60.00",
      unidade: "UN",
      vlr_unit: "95.00",
      observ: "",
      qtd_estoque: 1,
      quantidade: 2,
      vlr_item: "95.00",
      perc_desc: "0.0",
      vlr_total: "190.00",
      status: "AGM",
    },
    {
      tipo: "Serviço",
      referencia: "",
      id_item: 1,
      descricao: "MÃO DE OBRA INSTALAÇÃO - EQUIPE 4",
      vlr_custo: "860.00",
      unidade: "UN",
      vlr_unit: "860.00",
      observ: "",
      qtd_estoque: 4,
      quantidade: 1,
      vlr_item: "860.00",
      perc_desc: "0.0",
      vlr_total: "860.00",
      status: "IOK",
    },
  ],
  5: [
    {
      tipo: "Produto",
      referencia: "",
      id_item: 335,
      descricao: "CD 18/12 DISJ NEMA INTERNO FUME",
      vlr_custo: "60.00",
      unidade: "UN",
      vlr_unit: "95.00",
      observ: "",
      qtd_estoque: 0,
      quantidade: 2,
      vlr_item: "95.00",
      perc_desc: "0.0",
      vlr_total: "190.00",
      status: "CMP",
    },
  ],
};
