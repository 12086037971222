import React, { useState } from "react";
import { Row } from "reactstrap";
import { MODAL_ACTIONS, validarCPF } from "../../../../../../../coreUtils";
import { MaskedInput, TextInput } from "../../../../../../../components";
import { ModalCadastroV2 } from "../../../../../../../components/cadastro";
import { modalTitleCondutorMDFe, routesBaseCondutorMDFe } from "../Condutor";
import { toastr } from "react-redux-toastr";

export const CadastroCondutorModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [status, setStatus] = useState(true);

  const fetchData = (data) => {
    setNome(data.nome);
    setCpf(data.cpf);
    setStatus(data.status);
  };

  const limparDados = () => {
    setNome("");
    setCpf("");
    setStatus(true);
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(nome)) {
      toastr.warning("Atenção", "Por favor, informe o Nome do Condutor.");
      return false;
    }

    if (["", null, undefined].includes(cpf)) {
      toastr.warning("Atenção", "Por favor, informe o CPF do Condutor.");
      return false;
    }

    if (!validarCPF(cpf)) {
      toastr.warning("Atenção", "O CPF informado é inválido.");
      return false;
    }

    let data = {
      nome: nome,
      cpf: cpf,
      status: status,
    };

    return action === MODAL_ACTIONS.EDIT ? { ...data, id: selected } : data;
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleCondutorMDFe}
      size="md"
      fetchData={fetchData}
      onClose={limparDados}
      headerCheck={{
        toggle: () => setStatus(!status),
        value: status,
      }}
      routesBase={routesBaseCondutorMDFe}
      submitPayload={submitPayload}
      selected={selected}
      notifyEvent={notifyEvent}
      number="0042_61"
      concatShortcut={concatShortcut}
      concatModelName="condutor_mdfe"
    >
      <Row>
        <TextInput
          label="Nome"
          md={8}
          onChange={(e, v) => setNome(v)}
          value={nome}
          maxLength={60}
          autoFocus
        />
        <MaskedInput
          mask="999.999.999-99"
          label="CPF"
          md={4}
          value={cpf}
          onChange={(e, v) => setCpf(v)}
        />
      </Row>
    </ModalCadastroV2>
  );
};
