import React from "react";
import { Card, DropdownItem } from "reactstrap";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { ColorField, MoreDropdown, Table } from "../../../../../components";

const coresStatusMDFe = [
  { value: "S", color: "#7af440", hint: "MDFe não finalizado - Salvo" },
  { value: "A", color: "#1b7ff6", hint: "MDFe finalizado aguardando emissão" },
  { value: "E", color: "#d7d844", hint: "MDFe enviado ao SEFAZ com sucesso" },
  { value: "R", color: "#4ea929", hint: "MDFe encerrado" },
  { value: "C", color: "#ed3833", hint: "MDFe cancelado" },
  { value: "U", color: "#f39562", hint: "MDFe inutilizado" },
];

export const ManifEletronMDFeGrid = ({
  dados,
  setSelected,
  handleSelectAll,
  encerrar,
  eventosAlertas,
  imprimir,
  alterar,
  cancelar,
  corrigirDuplicidade,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "descricao_origem",
      text: "Origem",
      align: "center",
    },
    {
      dataField: "documentos",
      text: "Documento(s)",
      align: "center",
    },
    {
      dataField: "data_inicio_viagem",
      text: "Início Viagem",
      align: "center",
      formatter: (c, r) => `${c} ${r.hora_inicio_viagem}`,
    },
    {
      dataField: "cidade_carrega",
      text: "Local Carreg.",
      align: "left",
    },
    {
      dataField: "nome_veiculo",
      text: "Veículo",
      align: "left",
      formatter: (cell, row) => `${cell} - ${row.placa_veiculo}`,
    },
    {
      dataField: "nome_condutor",
      text: "Condutor",
      align: "left",
    },
    {
      dataField: "vlr_total_carga",
      text: "Vlr. Carga",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "status",
      text: "Sit",
      align: "center",
      formatter: (c) => <ColorField scheme={coresStatusMDFe} value={c} />,
      style: { verticalAlign: "middle", padding: 0 },
      colWidth: "35px",
      fixedColWidth: true,
      sortable: true,
      hideSortIcon: true,
    },
    {
      dataField: "act_imprimir",
      isDummyField: true,
      text: "",
      align: "center",
      style: { verticalAlign: "middle" },
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => imprimir(row.id)}>Imprimir</DropdownItem>
          <DropdownItem
            onClick={() => alterar(row.id, row.status, row.web_pode_alterar)}
          >
            Alterar
          </DropdownItem>
          <hr />
          <DropdownItem onClick={() => encerrar(row.id)}>Encerrar</DropdownItem>
          <DropdownItem onClick={() => cancelar(row.id)}>Cancelar</DropdownItem>
          <DropdownItem onClick={() => {}}>Descartar Digitação</DropdownItem>
          <DropdownItem onClick={() => eventosAlertas(row.id)}>
            Eventos e Alertas
          </DropdownItem>
          {row.status === "A" && (
            <DropdownItem onClick={() => corrigirDuplicidade(row.id)}>
              Corrigir Duplicidade
            </DropdownItem>
          )}
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Card body>
      <Table
        data={dados}
        columns={columns}
        multiselect
        onSelect={setSelected}
        onSelectAll={handleSelectAll}
        onRowDoubleClick={(id, row) =>
          alterar(id, row.status, row.web_pode_alterar)
        }
      />
    </Card>
  );
};
