import React, { useState } from "react";
import {
  AsyncComboBox,
  DatePicker,
  IconButton,
  IntegerFormInput,
  ModalBase,
  TextInput,
} from "../../../../../../components";
import { Edit } from "react-feather";
import moment from "moment";
import { Row } from "reactstrap";
import UteisService from "../../../../../../services/uteis/UteisService";
import { formatDateISO } from "../../../../../../coreUtils";

export const AlterarInfoCabModal = ({
  idCliente,
  idTipoDoc,
  emissao,
  itens,
  modeloNfe,
  handleAlterarInfoCab,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idClienteInterno, setIdClienteInterno] = useState(null);
  const [nomeClienteInterno, setNomeClienteInterno] = useState("");
  const [idTipoDocInterno, setIdTipoDocInterno] = useState(null);
  const [descTipoDocInterno, setDescTipoDocInterno] = useState("");
  const [serieTipoDocInterno, setSerieTipoDocInterno] = useState("");
  const [finalidadeTipoDocInterno, setFinalidadeTipoDocInterno] = useState("");
  const [emissaoInterno, setEmissaoInterno] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSetCliente = (s) => {
    setIdClienteInterno(s?.value);
    setNomeClienteInterno(s?.nome);
  };

  const handleSetTipoDoc = (s) => {
    setIdTipoDocInterno(s?.value);
    setDescTipoDocInterno(s?.label);
    setSerieTipoDocInterno(s?.serie ?? "");
    setFinalidadeTipoDocInterno(s?.finalidade ?? "");
  };

  const onOpen = () => {
    setIdClienteInterno(idCliente);
    setIdTipoDocInterno(idTipoDoc);
    setEmissaoInterno(emissao);
  };

  const onConfirm = async () => {
    const payload = {
      id_cliente: idClienteInterno,
      id_tipo_doc: idTipoDocInterno,
      modelo: modeloNfe === "NFCE" ? 65 : 55,
      dentro_estab: true,
      produtos: itens.map((e) => ({
        uuid: e.uuid,
        id_produto: e.id_produto,
        cfop: (e.cfop ?? "").trim(),
        quantidade: e.quantidade,
        vlr_tot_prod: e.vlr_tot_prod,
        vlr_frete: 0,
      })),
    };

    setLoading(true);
    const ret = await UteisService.calcularImpostosVariosProdutos(payload);
    if (ret?.length > 0) {
      const payloadRet = {
        id_cliente: idClienteInterno,
        nome_cliente: nomeClienteInterno,
        id_tipo_doc: idTipoDocInterno,
        desc_tipo_doc: descTipoDocInterno,
        serie_tipo_doc: serieTipoDocInterno,
        emissao: formatDateISO(emissaoInterno),
        itens: ret,
      };

      handleAlterarInfoCab(payloadRet);
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <IconButton icon={Edit} onClick={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Alterar Informações"
        number="0036_12"
        onBeforeOpen={onOpen}
        onConfirm={onConfirm}
        loadingConfirm={loading}
      >
        <Row className="mb-2">
          <AsyncComboBox
            md={9}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            defaultValue={idClienteInterno}
            onChange={handleSetCliente}
          />

          <DatePicker
            md={2}
            label="Emissão"
            value={emissaoInterno}
            onChange={(v) =>
              setEmissaoInterno(moment.isMoment(v) ? v.toDate() : v)
            }
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={4}
            label="Tipo de Documento"
            isConcatField
            concatModelName="tipo_documento"
            isSearchable
            defaultValue={idTipoDocInterno}
            onChange={handleSetTipoDoc}
          />
          <IntegerFormInput
            md={1}
            label="Série"
            defaultValue={serieTipoDocInterno}
            disabled
          />
          <TextInput
            md={4}
            label="Finalidade"
            value={finalidadeTipoDocInterno}
            disabled
          />
        </Row>
      </ModalBase>
    </>
  );
};
