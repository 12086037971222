import React from "react";
import { ChevronRight, ChevronUp } from "react-feather";
import { Button, Col, Collapse, Row } from "reactstrap";

class Spoiler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hidden: this.props.hidden ? true : false };
  }

  turnVisibility = () => this.setState({ hidden: !this.state.hidden });

  componentWillReceiveProps() {
    this.setState({ hidden: this.props.hidden ? true : false });
  }

  render() {
    return (
      <React.Fragment>
        {/* controladores */}
        <Row>
          <Col md={"auto"} className="d-flex justify-content-start">
            <Button color="link" onClick={this.turnVisibility}>
              {this.state.hidden ? (
                <React.Fragment>
                  <span>
                    {this.props.label ? this.props.label : "Mostrar"}{" "}
                    <ChevronRight size={14} />
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span>
                    {this.props.labelHide ? this.props.labelHide : "Esconder"}{" "}
                    <ChevronUp size={14} />
                  </span>
                </React.Fragment>
              )}
            </Button>
          </Col>
          {!this.props.noDivision && (
            <Col>
              <hr />
            </Col>
          )}
        </Row>

        {/* conteudo do spoiler */}
        <Collapse isOpen={!this.state.hidden}>{this.props.children}</Collapse>
      </React.Fragment>
    );
  }
}

export default Spoiler;
