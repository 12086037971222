import React, { useEffect, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Fade, Row } from "reactstrap";
import {
  ComboBox,
  ExpandableTable,
  TimeSeriesLineChart,
} from "../../../../components";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { CardTotais, CardTotaisItem } from "../../../../components/CardTotais";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import { formatNumber, userPodeVerTotaisRelVenda } from "../../../../coreUtils";
import ComissaoGerenciaService from "../../../../services/relatorios/comissionamento/ComissaoGerenciaService";
import columns from "./columns";

const agruparOptions = [
  { value: "SEM", label: "Semana" },
  { value: "MES", label: "Mês" },
];

export const ComissaoGerencia = () => {
  const [filters, setFilters] = useState([{ agrupar: "SEM" }]);
  const [isLoading, setIsLoading] = useState(false);
  const [gerentes, setGerentes] = useState([]);
  const [data, setData] = useState({ data: [] });
  const [gerenteSel, setGerenteSel] = useState(null);
  const agruparPorRef = useRef();

  const podeVerTotais = userPodeVerTotaisRelVenda();
  const hideTotals = localStorage.getItem("ver_totais_consultas") === "false";

  const limparDados = () => {
    setData({ data: [] });
  };

  const handleDate = (dataIni, dataFim) => {
    let _filters = filters.filter((e) => {
      const key = Object.keys(e)[0];
      return key !== "start" && key !== "end";
    });

    if (dataIni && dataFim) {
      // Caso a data esteja informada, o filtro é adicionado
      _filters.push({
        start:
          dataIni instanceof Date
            ? dataIni.toISOString().split("T")[0]
            : dataIni,
      });
      _filters.push({
        end:
          dataFim instanceof Date
            ? dataFim.toISOString().split("T")[0]
            : dataFim,
      });
    }
    setFilters(_filters);
    limparDados();
  };

  const fetchData = async () => {
    let filters_keys = [];
    filters.forEach((element) => {
      filters_keys.push(...Object.keys(element));
    });
    const hasDate =
      filters_keys.includes("start") && filters_keys.includes("end");

    if (!hasDate) {
      toastr.warning(
        "Período não informado.",
        "É necessário que seja informado um período."
      );
      return;
    }

    if (!filters_keys.includes("agrupar")) {
      toastr.warning(
        "Agrupamento não informado.",
        "Por favor, selecione o tipo de agrupamento dos dados."
      );
      return;
    }
    if (podeVerTotais) {
      if (!filters_keys.includes("gerente")) {
        toastr.warning(
          "Gerente não informado.",
          "Por favor, selecione o gerente que terá a comissão calculada."
        );
        return;
      }
    } else {
      const countGere = gerentes.filter((element) => {
        return (
          element["value"] ===
          Number.parseInt(localStorage.getItem("id_colaborador"))
        );
      }).length;
      if (countGere === 0) {
        toastr.warning(
          "Gerente não informado.",
          "Por favor, selecione o gerente que terá a comissão calculada."
        );
        return;
      }
      filters.push({
        gerente: gerentes.filter((element) => {
          return (
            element["value"] ===
            Number.parseInt(localStorage.getItem("id_colaborador"))
          );
        })[0]["value"],
      });
    }
    setIsLoading(true);

    const ret = await ComissaoGerenciaService.buscarComissao(filters);
    if (ret) setData(ret);

    setIsLoading(false);
  };

  const handleSelect = (selected, target) => {
    const { name } = target;
    if (name === "gerente") {
      setGerenteSel(selected);
    }

    let _filters = filters.filter(
      (element) => !Object.keys(element).includes(name)
    );

    if (selected !== null) _filters.push({ [name]: selected.value });

    setFilters(_filters);
    limparDados();
  };

  const buscarGerentes = async () => {
    setGerentes(await ComissaoGerenciaService.buscarGerentes());
  };

  useEffect(() => {
    buscarGerentes();
  }, []);

  return (
    <PageContainer title="Gerência" number="5012" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <ComboBox
            md={2}
            label="Agrupar Por"
            name="agrupar"
            options={agruparOptions}
            ref={agruparPorRef}
            onChange={handleSelect}
            defaultValue={agruparOptions[0].value}
          />
          <ComboBox
            md={3}
            label="Gerente"
            name="gerente"
            options={gerentes}
            onChange={handleSelect}
            isDisabled={!podeVerTotais}
            defaultValue={
              podeVerTotais
                ? gerenteSel
                : Number.parseInt(localStorage.getItem("id_colaborador"))
            }
          />
          <BotaoPesquisar onClick={fetchData} loading={isLoading} />
        </Row>
      </Card>
      {data["data"]?.length > 0 && (
        <Fade in={true}>
          <CardTotais>
            <CardTotaisItem
              label="Total Comissão"
              value={formatNumber(data["Total Comissão"])}
            />
            <CardTotaisItem
              label="Total Vendido"
              value={formatNumber(data["Total Venda"])}
              hideValues={hideTotals}
            />
            <CardTotaisItem
              label="Valor Médio de Venda do Período"
              value={formatNumber(data["Valor Médio de Venda"])}
              hideValues={hideTotals}
            />
            <CardTotaisItem
              label="Quantidade de vendas"
              value={formatNumber(data["Quantidade Vendas"])}
            />
          </CardTotais>
          <TimeSeriesLineChart
            data={data["data"]}
            title="Relatórios de comissão de gerência"
            dataKey="data_graph"
            lineDataKeys={[
              {
                name: "Total Vendido",
                axis: "left",
              },
              {
                name: "Valor Médio de Venda",
                axis: "right",
              },
            ]}
            biaxial
            hideValues={hideTotals}
          />
          <Card body>
            <ExpandableTable data={data} columns={columns(hideTotals)} />
          </Card>
        </Fade>
      )}
    </PageContainer>
  );
};
