import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tipo_vlr_aprox_tributos: "IBPT",
  perc_aprox_tributos: 0,
  ambiente: "H",
  utiliza_lib_win_crypt: true,
  aliq_int_icms_uf_emit_simples_nac: 0,
  permite_contingencia: false,
  cad_cli_permite_identif_mod_nfe: false,
};

export const nfEletronicaGeralSlice = createSlice({
  name: "nf_eletronica_geral",
  initialState: initialState,
  reducers: {
    setupNfEletronicaGeral: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (
          ["perc_aprox_tributos", "aliq_int_icms_uf_emit_simples_nac"].includes(
            key
          )
        ) {
          value = parseFloat(value);
        }

        state[key] = value;
      });
      return state;
    },
    setTipoVlrAproxTributos: (state, action) => {
      state.tipo_vlr_aprox_tributos = action.payload;
    },
    setPercAproxTributos: (state, action) => {
      state.perc_aprox_tributos = action.payload;
    },
    setAmbiente: (state, action) => {
      state.ambiente = action.payload;
    },
    setUtilizaLibWinCrypt: (state, action) => {
      state.utiliza_lib_win_crypt = action.payload;
    },
    setAliqIntIcmsUfEmitSimplesNac: (state, action) => {
      state.aliq_int_icms_uf_emit_simples_nac = action.payload;
    },
    setPermiteContingencia: (state, action) => {
      state.permite_contingencia = action.payload;
    },
    setCadCliPermiteIdentifModNfe: (state, action) => {
      state.cad_cli_permite_identif_mod_nfe = action.payload;
    },
  },
});

export const {
  setupNfEletronicaGeral,
  setTipoVlrAproxTributos,
  setPercAproxTributos,
  setAmbiente,
  setUtilizaLibWinCrypt,
  setAliqIntIcmsUfEmitSimplesNac,
  setPermiteContingencia,
  setCadCliPermiteIdentifModNfe,
} = nfEletronicaGeralSlice.actions;

export default nfEletronicaGeralSlice.reducer;
