import {
  Label,
  PrintDensity,
  PrintDensityName,
  Text,
  FontFamily,
  FontFamilyName,
  Spacing,
  Alignment,
  Grid,
  Size,
  SizeType,
  Barcode,
  BarcodeType,
  BarcodeTypeName,
} from "jszpl";
import {
  formatNumber,
  sliceIntoChunks,
  wrapText,
} from "../../../../../coreUtils";
import unidecode from "unidecode";
import alignmentValue from "jszpl/src/enums/alignment-value";

export const imprimirEtqL19 = (etiquetas) => {
  try {
    let etq = etiquetas.reduce(
      (acc, e) => [...acc, ...Array(e.qtd_etiquetas).fill(e)],
      []
    );

    etq = sliceIntoChunks(etq, 2);

    const resZpl = etq.reduce((acc, row) => {
      const label = new Label();
      label.printDensity = new PrintDensity(PrintDensityName["8dpmm"]);
      label.width = 100;
      label.height = 75;
      // label.padding = new Spacing(10);

      const grid = new Grid();
      label.content.push(grid);
      grid.columns.push(new Size(1, SizeType.Relative));
      grid.columns.push(new Size(1, SizeType.Relative));
      grid.rows.push(new Size(1, SizeType.Relative));
      grid.columnSpacing = 2;
      grid.rowSpacing = 2;
      grid.padding = new Spacing(30);

      row.forEach((col, colIndex) => {
        const nomeWrap = wrapText(unidecode(col.nome), 18).split("\n");
        if (nomeWrap?.length > 0) {
          const nomeProd = new Text();
          grid.content.push(nomeProd);
          nomeProd.text = nomeWrap[0];
          nomeProd.fontFamily = new FontFamily(FontFamilyName.A);
          nomeProd.characterHeight = new Size(30, SizeType.Absolute);
          nomeProd.grid.column = colIndex;
          nomeProd.top = new Size(42, SizeType.Absolute);
        }

        if (nomeWrap?.length > 1) {
          const nomeProd2 = new Text();
          grid.content.push(nomeProd2);
          nomeProd2.text = nomeWrap.slice(1).join(" ").substring(0, 18);
          nomeProd2.fontFamily = new FontFamily(FontFamilyName.A);
          nomeProd2.characterHeight = new Size(30, SizeType.Absolute);
          nomeProd2.grid.column = colIndex;
          nomeProd2.top = new Size(75, SizeType.Absolute);
        }

        const idProd = new Text();
        grid.content.push(idProd);
        idProd.text = `# ${col.id_produto}`;
        idProd.fontFamily = new FontFamily(FontFamilyName.A);
        idProd.characterHeight = new Size(22, SizeType.Absolute);
        idProd.grid.column = colIndex;
        idProd.horizontalAlignment = new Alignment(alignmentValue.End);
        idProd.top = new Size(114, SizeType.Absolute);

        const tamanho = new Text();
        grid.content.push(tamanho);
        tamanho.text = `TAM: ${col.tamanho ?? ""}`;
        tamanho.fontFamily = new FontFamily(FontFamilyName.A);
        tamanho.characterHeight = new Size(22, SizeType.Absolute);
        tamanho.grid.column = colIndex;
        tamanho.horizontalAlignment = new Alignment(alignmentValue.End);
        tamanho.top = new Size(150, SizeType.Absolute);

        const grupo = new Text();
        grid.content.push(grupo);
        grupo.text = unidecode((col.grupo ?? "").substring(0, 18));
        grupo.fontFamily = new FontFamily(FontFamilyName.A);
        grupo.characterHeight = new Size(22, SizeType.Absolute);
        grupo.grid.column = colIndex;
        grupo.top = new Size(114, SizeType.Absolute);

        const classe = new Text();
        grid.content.push(classe);
        classe.text = unidecode((col.classe_prod ?? "").substring(0, 18));
        classe.fontFamily = new FontFamily(FontFamilyName.A);
        classe.characterHeight = new Size(22, SizeType.Absolute);
        classe.grid.column = colIndex;
        classe.top = new Size(150, SizeType.Absolute);

        if (!["", "SEM GTIN"].includes(col.codigo_barras ?? "")) {
          const codBar = new Barcode();
          grid.content.push(codBar);
          codBar.data = col.codigo_barras;
          codBar.type = new BarcodeType(BarcodeTypeName.EAN13);
          codBar.left = new Size(70, SizeType.Absolute);
          codBar.height = new Size(60, SizeType.Absolute);
          codBar.grid.column = colIndex;
          codBar.top = new Size(190, SizeType.Absolute);
        }

        const nomeEmpresa = new Text();
        grid.content.push(nomeEmpresa);
        nomeEmpresa.text = localStorage.getItem("nome_fant_empresa");
        nomeEmpresa.fontFamily = new FontFamily(FontFamilyName.B);
        nomeEmpresa.characterHeight = new Size(27, SizeType.Absolute);
        nomeEmpresa.grid.column = colIndex;
        nomeEmpresa.top = new Size(293, SizeType.Absolute);

        const nomeProdComp = new Text();
        grid.content.push(nomeProdComp);
        nomeProdComp.text = unidecode(col.nome.substring(0, 33));
        nomeProdComp.fontFamily = new FontFamily(FontFamilyName.A);
        nomeProdComp.characterHeight = new Size(22, SizeType.Absolute);
        nomeProdComp.grid.column = colIndex;
        nomeProdComp.top = new Size(370, SizeType.Absolute);

        const precoVenda = new Text();
        grid.content.push(precoVenda);
        precoVenda.text = `R$ ${formatNumber(col.preco_venda, true, 2)}`;
        precoVenda.fontFamily = new FontFamily(FontFamilyName.B);
        precoVenda.characterHeight = new Size(30, SizeType.Absolute);
        precoVenda.grid.column = colIndex;
        precoVenda.horizontalAlignment = new Alignment(alignmentValue.End);
        precoVenda.top = new Size(405, SizeType.Absolute);
      });

      return [...acc, ...label.generateZPL().split("\n")];
    }, []);

    return [true, resZpl];
  } catch (err) {
    return [false, err.message];
  }
};
