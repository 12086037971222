import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  CardTotais,
  CardTotaisItem,
} from "../../../../components";
import { BotaoImprimir } from "../../../../components/BotaoImprimir";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import { formatDateISO, validateDateFields } from "../../../../coreUtils";
import { TrocaProdutosGrid } from "./components/TrocaProdutosGrid";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";

export const TrocaDeProdutos = () => {
  const [idProduto, setIdProduto] = useState(null);
  const [idGrupo, setIdGrupo] = useState(null);
  const [idRota, setIdRota] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [idRedeCliente, setIdRedeCliente] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const buscarDados = async () => {
    const params = {
      id_produto: idProduto,
      id_grupo: idGrupo,
      id_rota: idRota,
      id_cliente: idCliente,
      id_rede_cliente: idRedeCliente,
      data_ini: dataIni,
      data_fim: dataFim,
    };

    return await apiGetV2(
      `/relatorios/venda_direta/trocas_por_produto/`,
      params
    );
  };

  const carregarDados = async () => {
    if (!validateDateFields(dataIni, dataFim)) return;
    setLoading(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      setDados(ret.produtos);
      setTotais(ret.totais);
    } else {
      setDados([]);
      setTotais({});
    }
    setLoading(false);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
    limparDados();
  };

  const imprimir = async () => {
    if (!validateDateFields(dataIni, dataFim)) return;
    setLoadingImprimir(true);

    const [ok, res] = await buscarDados();

    if (ok) {
      await docPrintReport(
        "/relatorios/pronta_entrega/troca_produtos/",
        {
          dados: res.produtos,
          totais: res.totais,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
        },
        "5031"
      );
    }

    setLoadingImprimir(false);
  };

  return (
    <PageContainer title="Troca de Produtos" number="5031" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="rota_mob"
            isSearchable
            md={2}
            label="Rota"
            onChange={(s) => setIdRota(s?.value)}
            defaultOptions
          />
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="cliente"
            isSearchable
            md={4}
            label="Cliente"
            onChange={(s) => setIdCliente(s?.value)}
          />
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="rede_cliente"
            isSearchable
            md={3}
            label="Rede de Clientes"
            onChange={(s) => setIdRedeCliente(s?.value)}
            defaultOptions
          />
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="grupo"
            isSearchable
            md={3}
            label="Grupo"
            onChange={(s) => setIdGrupo(s?.value)}
            defaultOptions
          />
        </Row>
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="produto"
            isSearchable
            md={4}
            label="Produto"
            onChange={(s) => setIdProduto(s?.value)}
          />
          <FiltroPeriodoDatas onChange={handleDate} defaultOption="MESATU" />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Quantidade Troca"
          value={totais.qtd_troca}
          checkFloat
        />
        <CardTotaisItem
          label="Perc. sobre Troca"
          value={totais.qtd_perc_sobre_total}
          isPercentage
        />
        <CardTotaisItem label="Valor Troca" value={totais.vlr_troca} />
        <CardTotaisItem
          label="Perc por Valor"
          value={totais.vlr_perc_sobre_total}
          isPercentage
        />
      </CardTotais>
      <Card body>
        <TrocaProdutosGrid data={dados} />
      </Card>
    </PageContainer>
  );
};
