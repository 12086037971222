import React from "react";
import { PageContainer } from "../../../../components/PageContainer";
import { TableVTS } from "./components/TableVTS";

export const VendedorTecnicoServicos = () => {
  return (
    <PageContainer
      title="Cadastro de Vendedores Técnicos de Serviços"
      number="0020"
      canGoBack
    >
      <TableVTS />
    </PageContainer>
  );
};
