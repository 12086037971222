import React from "react";
import {
  IconButton,
  IconLink,
  Table,
  TableCheck,
} from "../../../../../components";
import { itensRegraPromocaoRoute } from "../../../../../routes/modules/cadastro";
import { Edit } from "react-feather";
import { BsPrinter } from "react-icons/bs";
import { dropdownAcoes } from "../../../../../components/cadastro";
import { DropdownItem } from "reactstrap";
import { naturalSort, naturalSortDate } from "../../../../../coreUtils";

export const RegraPromocaoGrid = ({
  dados,
  setSelected,
  alterarPeriodo,
  excluir,
  imprimirRegra,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "regra_de",
      text: "Aplicar Em",
      align: "center",
      sortable: true,
    },
    {
      dataField: "aplic",
      text: "Regra Para",
      align: "center",
      sortable: true,
    },
    {
      dataField: "data_inicio",
      text: "Início",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "data_final",
      text: "Fim",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "ativo",
      text: "Ativa",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    dropdownAcoes({
      excluir: excluir,
      outrasAcoes: (id) => (
        <DropdownItem onClick={() => alterarPeriodo(id)}>
          Alterar Período
        </DropdownItem>
      ),
    }),
    {
      dataField: "it",
      text: "",
      align: "center",
      colWidth: "32px",
      fixedColWidth: true,
      formatter: (c, row) => (
        <IconLink
          pathname={itensRegraPromocaoRoute.path}
          state={{ selected: row.id }}
          icon={Edit}
          hint="Itens da Regra"
          tooltipPlacement="left"
        />
      ),
    },
    {
      dataField: "print",
      text: "",
      align: "center",
      colWidth: "32px",
      fixedColWidth: true,
      formatter: (c, row) => (
        <IconButton
          icon={BsPrinter}
          onClick={() => imprimirRegra(row.id)}
          hint="Imprimir"
          tooltipPlacement="left"
        />
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      data={dados}
      onSelect={setSelected}
      pageSize={10}
      paginated={false}
    />
  );
};
