import React, { useState } from "react";
import {
  AsyncComboBox,
  FormButton,
  ModalBase,
  NumberInput,
  RadioGroup,
} from "../../../../../components";
import { Row } from "reactstrap";
import { toastr } from "react-redux-toastr";
import FlexRepresentExtService from "../../../../../services/pedidos/FlexRepresentExtService";
import { RadioItem } from "../../../../../components/RadioGroup";

export const LancarFlexRepresentExtModal = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idVendedor, setRota] = useState(null);
  const [natureza, setNatureza] = useState(null);
  const [vlrLancamento, setVlrLancamento] = useState(0);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setRota(null);
    setNatureza(null);
    setVlrLancamento(0);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if (!idVendedor) {
      toastr.warning("Atenção", "Por favor, informe o Vendedor");
      return;
    }
    if (!natureza) {
      toastr.warning("Atenção", "Por favor, informe a Operação");
      return;
    }
    if (vlrLancamento <= 0) {
      toastr.warning("Atenção", "Por favor, informe o Valor do Lançamento");
      return;
    }

    const payload = {
      id_vendedor: idVendedor,
      natureza: natureza,
      valor: vlrLancamento,
    };

    setLoading(true);
    const ret = await FlexRepresentExtService.lancamentoAdm(payload);
    if (ret) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton md="auto" padded={false} onClick={toggle}>
        Lançar Flex
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Lançar Flex para Representante"
        number="0072_1"
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            isSearchable
            concatModelName="colaborador"
            defaultOptions
            md={6}
            label="Vendedor"
            onChange={(s) => setRota(s?.value)}
            autoFocus
          />
          <RadioGroup label="Operação" onChange={setNatureza} value={natureza}>
            <RadioItem label="Crédito" value="+" />
            <RadioItem label="Débito" value="-" />
          </RadioGroup>
          <NumberInput
            label="Valor"
            md={3}
            onChange={(value) => setVlrLancamento(value)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
