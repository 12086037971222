import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cob_multa: false,
  taxa_multa: 0,
  dias_multa: 0,
  cob_juros: false,
  taxa_juros: 0,
  dias_juros: 0,
  cod_plano_cta_multa: 0,
  cod_plano_cta_juros: 0,
  id_plano_contas_pad_inc_tit: null,
  permite_liquidacao_transacao: false,
  identif_plano_contas_inc_manual: false,
  id_plano_conta_pad_desc_financ: null,
  id_plano_contas_custo_boleto: null,
  id_pln_cta_outr_acrescimo_cob_banc: null,
};

const fieldsToNull = ["cod_plano_cta_multa", "cod_plano_cta_juros"];

export const contasReceberSlice = createSlice({
  name: "contas_receber_slice",
  initialState: initialState,
  reducers: {
    setupContasReceber: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value === 0 && fieldsToNull.includes(key)) {
          value = null;
        }

        if (["taxa_juros", "taxa_multa"].includes(key)) {
          value = parseFloat(value);
        }

        state[key] = value;
      });
      return state;
    },
    setCobMulta: (state, action) => {
      state.cob_multa = action.payload;
    },
    setTaxaMulta: (state, action) => {
      state.taxa_multa = action.payload;
    },
    setDiasMulta: (state, action) => {
      state.dias_multa = action.payload;
    },
    setCobJuros: (state, action) => {
      state.cob_juros = action.payload;
    },
    setTaxaJuros: (state, action) => {
      state.taxa_juros = action.payload;
    },
    setDiasJuros: (state, action) => {
      state.dias_juros = action.payload;
    },
    setCodPlanoCtaMulta: (state, action) => {
      state.cod_plano_cta_multa = action.payload;
    },
    setCodPlanoCtaJuros: (state, action) => {
      state.cod_plano_cta_juros = action.payload;
    },
    setIdPlanoContasPadIncTit: (state, action) => {
      state.id_plano_contas_pad_inc_tit = action.payload;
    },
    setPermiteLiquidacaoTransacao: (state, action) => {
      state.permite_liquidacao_transacao = action.payload;
    },
    setIdentifPlanoContasIncManual: (state, action) => {
      state.identif_plano_contas_inc_manual = action.payload;
    },
    setIdPlanoContaPadDescFinanc: (state, action) => {
      state.id_plano_conta_pad_desc_financ = action.payload;
    },
    setIdPlanoContasCustoBoleto: (state, action) => {
      state.id_plano_contas_custo_boleto = action.payload;
    },
    setIdPlnCtaOutrAcrescimoCobBanc: (state, action) => {
      state.id_pln_cta_outr_acrescimo_cob_banc = action.payload;
    },
  },
});

export const {
  setupContasReceber,
  setCobMulta,
  setTaxaMulta,
  setDiasMulta,
  setCobJuros,
  setTaxaJuros,
  setDiasJuros,
  setCodPlanoCtaMulta,
  setCodPlanoCtaJuros,
  setIdPlanoContasPadIncTit,
  setPermiteLiquidacaoTransacao,
  setIdentifPlanoContasIncManual,
  setIdPlanoContaPadDescFinanc,
  setIdPlanoContasCustoBoleto,
  setIdPlnCtaOutrAcrescimoCobBanc,
} = contasReceberSlice.actions;

export default contasReceberSlice.reducer;
