import React, { useState } from "react";
import { ModalBase } from "../../../../../components";
import OrdemServicoService from "../../../../../services/ordem_servico/OrdemServicoService";

export const ConfReabrirOSModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const [ok] = await OrdemServicoService.reabrir(selected);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title={`Reabrir Ordem de Serviço Nº ${selected}`}
      number="0107_1"
      onConfirm={handleSubmit}
      loadingConfimr={loading}
    >
      <h5 style={{ textAlign: "center" }}>ATENÇÃO</h5>
      <span style={{ textAlign: "center", display: "block" }}>
        Este Processo Irá:
      </span>
      <ol>
        <li>Reabrir a Ordem de Serviço;</li>
        <li>Ajustar o Saldo dos Produtos;</li>
        <li>Estornar a Ordem de Serviço do Caixa;</li>
        <li>Estornar movimentos em crédito rotativo (se houverem).</li>
      </ol>
      <h5 style={{ textAlign: "center" }}>
        Tem certeza de que deseja continuar?
      </h5>
    </ModalBase>
  );
};
