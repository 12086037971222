import React, { useState } from "react";
import { FormButton, ModalBase, TextInput } from "../../../../../components";
import { Label, Row } from "reactstrap";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import { toastr } from "react-redux-toastr";

export const EmitirCartaCorrecaoModal = ({ idNf, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [texto, setTexto] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setTexto("");
  };

  const handleSubmit = async () => {
    if (texto.length < 15) {
      toastr.warning(
        "Atenção",
        "O Tamanho mínimo do texto da carta de correção é de 15 caracteres."
      );
      return false;
    }
    setLoading(true);
    const payload = { id_nfe: idNf, texto: texto };
    const [ok] = await NotaFiscalService.gerenciamento.emitirCartaCorrecao(
      payload
    );
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton md="auto" onClick={toggle} padded={false}>
        Incluir Carta de Correção
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Emitir Carta de Correção de NF"
        number="0055_51"
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        confirmButtonText="Emitir"
      >
        <Row>
          <TextInput
            md={12}
            label="Descrição da Solicitação"
            type="textarea"
            value={texto}
            onChange={(e, v) => setTexto(v)}
            rows={5}
          />
        </Row>

        <Label>
          Obs.: Correção a ser considerada, texto livre. A correção mais recente
          substitui as anteriores.
        </Label>
        {texto.length < 15 && (
          <Label>
            Tamanho mínimo de 15 caracteres. Faltam {15 - texto.length}
          </Label>
        )}
      </ModalBase>
    </>
  );
};
