import { createSlice } from "@reduxjs/toolkit";

export const initialFieldsState = {
  cod_cedente: true,
  tipo_carteira: true,
  variacao_carteira: true,
  cod_transmissao: true,
  posto: true,
  padrao_remessa: null,
};

export const controleCampos = createSlice({
  name: "controle_campos",
  initialState: initialFieldsState,
  reducers: {
    initFields: () => initialFieldsState,
    setupFields: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
  },
});

export const { initFields, setupFields } = controleCampos.actions;

export default controleCampos.reducer;
