import React, { useEffect, useState } from "react";
import { Card, CardTitle, Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  NumberInput,
} from "../../../../components";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { PageContainer } from "../../../../components/PageContainer";
import { formatNumber } from "../../../../coreUtils";
import ComissaoMetaService from "../../../../services/relatorios/comissionamento/ComissaoMetaService";
import CardValores from "./components/CardValores";
import { ComissaoMetaGrafico } from "./components/ComissaoMetaGrafico";
import LegendaBaseInformacoes from "./components/LegendaBaseInformacoes";

const _meses = [
  {
    label: "Janeiro",
    value: 1,
  },
  {
    label: "Fevereiro",
    value: 2,
  },
  {
    label: "Março",
    value: 3,
  },
  {
    label: "Abril",
    value: 4,
  },
  {
    label: "Maio",
    value: 5,
  },
  {
    label: "Junho",
    value: 6,
  },
  {
    label: "Julho",
    value: 7,
  },
  {
    label: "Agosto",
    value: 8,
  },
  {
    label: "Setembro",
    value: 9,
  },
  {
    label: "Outubro",
    value: 10,
  },
  {
    label: "Novembro",
    value: 11,
  },
  {
    label: "Dezembro",
    value: 12,
  },
];

export const ComissaoPorMeta = () => {
  // Filtros
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [idColab, setIdColab] = useState(null);
  const [ano, setAno] = useState(new Date().getFullYear());
  // Controle
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingCalcular, setLoadingCalcular] = useState(false);
  const [loadingConfirmar, setLoadingConfirmar] = useState(false);
  const [mostrarDados, setMostrarDados] = useState(false);
  const [deduzirValorDescCredRot, setDeduzirValorDescCredRot] = useState(false);
  // Valores da Previa
  const [percComissaoPrevia, setPercComissaoPrevia] = useState(0);
  const [previaResultado, setPreviaResultado] = useState(null);
  // Valores do Vendedor - Mês da Apuração
  const [vlrVdaMesApuracao, setVlrVdaMesApuracao] = useState();
  const [percParticipacao, setPercParticipacao] = useState();
  const [ticketMedio, setTicketMedio] = useState();
  const [vlrDescCaixaLoja, setVlrDescCaixaLoja] = useState();
  const [vlrFreteMesApuracao, setVlrFreteMesApuracao] = useState();
  const [vlrDevolMesApuracao, setVlrDevolMesApuracao] = useState();
  const [vlrVdaCliSemComisMesApura, setVlrVdaCliSemComisMesApura] = useState();
  const [vlrServicosMesApura, setVlrServicosMesApura] = useState();
  // Valores do Vendedor - Mês Anterior
  const [vlrVdaMesAnterior, setVlrVdaMesAnterior] = useState();
  const [vlrComissaoMesAnterior, setVlrComissaoMesAnterior] = useState();
  const [vlrFreteMesAnt, setVlrFreteMesAnt] = useState();
  const [vlrDevolMesAnt, setVlrDevolMesAnt] = useState();
  const [vlrVdaCliSemComisMesAnt, setVlrVdaCliSemComisMesAnt] = useState();
  const [vlrServicosMesAnt, setVlrServicosMesAnt] = useState();
  // Valores da Empresa - Mês da Apuração
  const [vlrEmprVdaMesApuracao, setVlrEmprVdaMesApuracao] = useState();
  const [vlrEmprFreteMesApuracao, setVlrEmprFreteMesApuracao] = useState();
  const [vlrEmprDevolMesApuracao, setVlrEmprDevolMesApuracao] = useState();
  const [vlrVdaEmprCliSemComisMes, setVlrEmprVdaCliSemComisMes] = useState();
  const [vlrServicosEmprMes, setVlrEmprServicosMes] = useState();
  // Valores da Empresa - Mês Anterior
  const [vlrEmprVdaMesAnterior, setVlrEmprVdaMesAnterior] = useState();
  const [vlrEmprFreteMesAnterior, setVlrEmprFreteMesAnterior] = useState();
  const [vlrEmprDevolMesAnterior, setVlrEmprDevolMesAnterior] = useState();
  const [vlrServicosEmprMesAnt, setVlrEmprServicosMesAnt] = useState();
  const [
    vlrVdaEmprCliSemComisMesAnt,
    setVlrEmprVdaCliSemComisMesAnt,
  ] = useState();
  // Gráfico
  const [vlrUltSemestre, setVlrUltSemestre] = useState([]);

  const buscarDados = async () => {
    setLoadingPesquisar(true);
    const [ok, ret] = await ComissaoMetaService.buscarDadosPainel(
      idColab,
      mes,
      ano
    );

    if (ok) {
      // Valores do Vendedor - Mês da Apuração
      setPercParticipacao(ret.perc_participacao);
      setTicketMedio(ret.ticket_medio);
      setVlrDescCaixaLoja(ret.vlr_desc_caixa_loja);
      setVlrVdaMesApuracao(ret.vlr_vda_mes_apuracao);
      setVlrFreteMesApuracao(ret.vlr_frete_mes);
      setVlrDevolMesApuracao(ret.vlr_devol_mes);
      setVlrVdaCliSemComisMesApura(ret.vlr_venda_cli_nao_gera_comis_mes);
      setVlrServicosMesApura(ret.vlr_serv_mes);
      // Valores do Vendedor - Mês Anterior
      setVlrVdaMesAnterior(ret.vlr_vda_mes_anterior);
      setVlrComissaoMesAnterior(ret.vlr_comissao_mes_anterior);
      setVlrFreteMesAnt(ret.vlr_frete_mes_ant);
      setVlrDevolMesAnt(ret.vlr_devol_mes_ant);
      setVlrVdaCliSemComisMesAnt(ret.vlr_venda_cli_nao_gera_comis_mes_ant);
      setVlrServicosMesAnt(ret.vlr_serv_mes_ant);
      // Valores da Empresa - Mês da Apuração
      setVlrEmprVdaMesApuracao(ret.venda_mes_empresa);
      setVlrEmprFreteMesApuracao(ret.frete_mes_empresa);
      setVlrEmprDevolMesApuracao(ret.devol_mes_empresa);
      setVlrEmprVdaCliSemComisMes(ret.vlr_mes_empr_cli_nao_gera_comis);
      setVlrEmprServicosMes(ret.vlr_serv_mes_empresa);
      // Valores da Empresa - Mês Anterior
      setVlrEmprVdaMesAnterior(ret.venda_mes_ant_empresa);
      setVlrEmprFreteMesAnterior(ret.frete_mes_ant_empresa);
      setVlrEmprDevolMesAnterior(ret.devol_mes_ant_empresa);
      setVlrEmprVdaCliSemComisMesAnt(ret.vlr_mes_ant_empr_cli_nao_gera_comis);
      setVlrEmprServicosMesAnt(ret.vlr_serv_mes_ant_empresa);
      // Gráfico
      setVlrUltSemestre(
        ret.valor_ult_semestre.map((e) => ({
          mesano: e.mesano,
          valor: parseFloat(e["valor"]),
        }))
      );
      setPreviaResultado(null);
      setMostrarDados(true);
    } else {
      limparDados();
    }

    setLoadingPesquisar(false);
  };

  const handleCalcPrevia = async () => {
    setLoadingCalcular(true);
    const [ok, ret] = await ComissaoMetaService.calcularPrevia({
      id_colab: idColab,
      mes: mes,
      ano: ano,
      perc_comissao_previa: percComissaoPrevia,
      deduzir_valor_desc_cred_rot: deduzirValorDescCredRot,
    });
    setPreviaResultado(formatNumber(ok ? ret.vlr_previa : 0, true, 2));
    setLoadingCalcular(false);
  };

  const handleConfirmarPercComiss = async () => {
    setLoadingConfirmar(true);
    await ComissaoMetaService.confirmarPercComissao({
      id_colab: idColab,
      mes: mes,
      ano: ano,
      perc_comissao: percComissaoPrevia,
      deduzir_valor_desc_cred_rot: deduzirValorDescCredRot,
    });
    setLoadingConfirmar(false);
  };

  const limparDados = () => {
    // Valores do Vendedor - Mês da Apuração
    setPercParticipacao(0);
    setTicketMedio(0);
    setVlrDescCaixaLoja(0);
    setVlrVdaMesApuracao(0);
    setVlrFreteMesApuracao(0);
    setVlrDevolMesApuracao(0);
    setVlrVdaCliSemComisMesApura(0);
    setVlrServicosMesApura(0);
    // Valores do Vendedor - Mês Anterior
    setVlrVdaMesAnterior(0);
    setVlrComissaoMesAnterior(0);
    setVlrFreteMesAnt(0);
    setVlrDevolMesAnt(0);
    setVlrVdaCliSemComisMesAnt(0);
    setVlrServicosMesAnt(0);
    // Valores da Empresa - Mês da Apuração
    setVlrEmprVdaMesApuracao(0);
    setVlrEmprFreteMesApuracao(0);
    setVlrEmprDevolMesApuracao(0);
    setVlrEmprVdaCliSemComisMes(0);
    setVlrEmprServicosMes(0);
    // Valores da Empresa - Mês Anterior
    setVlrEmprVdaMesAnterior(0);
    setVlrEmprFreteMesAnterior(0);
    setVlrEmprDevolMesAnterior(0);
    setVlrEmprVdaCliSemComisMesAnt(0);
    setVlrEmprServicosMesAnt(0);
    setVlrUltSemestre([]);
  };

  useEffect(() => {
    limparDados();
  }, [mes, ano, idColab]);

  return (
    <PageContainer title="Comissão por Meta" number="5022" canGoBack>
      <Card body>
        <Row>
          <ComboBox
            md={2}
            label="Mês"
            options={_meses}
            onChange={(s) => setMes(s?.value)}
            name="mes"
            defaultValue={mes}
          />
          <IntegerFormInput
            label="Ano"
            md="2"
            defaultValue={ano}
            onChange={setAno}
          />
          <AsyncComboBox
            md={5}
            label="Colaborador"
            name="colaborador"
            onChange={(s) => setIdColab(s?.value)}
            isSearchable
            isConcatField
            defaultOptions
            concatModelName="colaborador"
          />
          <BotaoPesquisar
            onClick={buscarDados}
            loading={loadingPesquisar}
            disabled={!idColab}
            disabledHint="Selecione um vendedor"
          />
        </Row>
      </Card>
      {mostrarDados && (
        <>
          <CardValores
            vlrVdaMesApuracao={vlrVdaMesApuracao}
            vlrVdaMesAnterior={vlrVdaMesAnterior}
            vlrComissaoMesAnterior={vlrComissaoMesAnterior}
            percParticipacao={percParticipacao}
            ticketMedio={ticketMedio}
            vlrDescCaixaLoja={vlrDescCaixaLoja}
            vlrFreteMesApuracao={vlrFreteMesApuracao}
            vlrDevolMesApuracao={vlrDevolMesApuracao}
            vlrVdaCliSemComisMesApura={vlrVdaCliSemComisMesApura}
            vlrFreteMesAnt={vlrFreteMesAnt}
            vlrDevolMesAnt={vlrDevolMesAnt}
            vlrVdaCliSemComisMesAnt={vlrVdaCliSemComisMesAnt}
            vlrEmprVdaMesApuracao={vlrEmprVdaMesApuracao}
            vlrEmprVdaMesAnterior={vlrEmprVdaMesAnterior}
            vlrEmprFreteMesApuracao={vlrEmprFreteMesApuracao}
            vlrEmprDevolMesApuracao={vlrEmprDevolMesApuracao}
            vlrVdaEmprCliSemComisMes={vlrVdaEmprCliSemComisMes}
            vlrEmprFreteMesAnterior={vlrEmprFreteMesAnterior}
            vlrEmprDevolMesAnterior={vlrEmprDevolMesAnterior}
            vlrVdaEmprCliSemComisMesAnt={vlrVdaEmprCliSemComisMesAnt}
            vlrServicosMesApura={vlrServicosMesApura}
            vlrServicosMesAnt={vlrServicosMesAnt}
            vlrServicosEmprMes={vlrServicosEmprMes}
            vlrServicosEmprMesAnt={vlrServicosEmprMesAnt}
          />
          <Card body>
            <CardTitle>Valor total vendido pelo vendedor</CardTitle>
            <ComissaoMetaGrafico data={vlrUltSemestre} />
          </Card>
          <Card body>
            <Row>
              <NumberInput
                label="Perc. Comissão"
                md={2}
                onChange={setPercComissaoPrevia}
                value={percComissaoPrevia}
                isPercentage
              />
              <FormButton
                md={2}
                color="info"
                loading={loadingCalcular}
                onClick={handleCalcPrevia}
              >
                Calcular
              </FormButton>
              <FormCheckbox
                label="Deduzir descontos em faturas do rotativo"
                name="deduc-valor-desc-rotativo"
                checked={deduzirValorDescCredRot}
                onChange={() =>
                  setDeduzirValorDescCredRot(!deduzirValorDescCredRot)
                }
              />
              <NumberInput
                label="Resultado"
                md={2}
                disabled={true}
                value={previaResultado}
              />
              <FormButton
                md={2}
                color="success"
                onClick={handleConfirmarPercComiss}
                loading={loadingConfirmar}
              >
                Confirmar
              </FormButton>
            </Row>
          </Card>
          <LegendaBaseInformacoes />
        </>
      )}
    </PageContainer>
  );
};
