import React from "react";
import { HintLabel, Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { DetalhesCliInadModal } from "./DetalhesCliInadModal";

export const ClientesInadimplentesGrid = ({
  dados,
  idVendedor,
  dataIni,
  dataFim,
  considerarCredRot,
}) => {
  const columns = [
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "nome_fant_cliente",
      text: "Nome Fantasia",
      align: "left",
    },
    {
      dataField: "devedor_titulos_rec",
      text: "Títulos a Receber",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },

    ...(considerarCredRot
      ? [
          {
            dataField: "devedor_rotativo",
            text: "Rotativo",
            align: "right",
            formatter: (c) => formatNumber(c, true, 2),
          },
        ]
      : []),
    {
      dataField: "movs_pend_caixa",
      text: (
        <HintLabel
          label="Pend. Caixa Loja"
          hintText="Vendas pendentes de recebimento no Caixa Loja"
        />
      ),
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "total_devedor",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "det",
      align: "center",
      isDummyField: true,
      formatter: (cell, row, index) => (
        <DetalhesCliInadModal
          idVendedor={idVendedor}
          idCliente={row.id_cliente}
          nomeCliente={row.nome_cliente}
          dataIni={dataIni}
          dataFim={dataFim}
          considerarCredRot={considerarCredRot}
        />
      ),
    },
  ];

  return <Table data={dados} columns={columns} />;
};
