import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCamposAtivosIcms,
  setGeraCredIcms,
  setModBcIcms,
  setPercIcms,
  setPercRedBaseIcmsEm,
  setPercRedBaseIcmsPara,
  setSitTribIcms,
  setSomaVlrFreteBcIcms,
  setSomaVlrOutrosBcIcms,
  setTipoRedBaseIcms,
} from "../../store/cadCfopSlice";
import TributacaoService from "../../../../../../services/cadastro/TributacaoService";
import { Row } from "reactstrap";
import {
  ComboBox,
  Divider,
  FormCheckbox,
  NumberInput,
} from "../../../../../../components";

const modalidades_icms = [
  { label: "Margem Valor Agregado", value: "MVA" },
  { label: "Pauta (Valor)", value: "PAV" },
  { label: "Preço Tabelado Máx. (valor)", value: "PTM" },
  { label: "Valor da Operação", value: "VOP" },
];
const tipos_redutor_bc_icms = [
  { label: "Reduzir B.C. ICMS", value: "EM" },
  { label: "Reduzir B.C. ICMS para percentual", value: "PA" },
];

export const TabICMSCfop = () => {
  const store = useSelector((state) => state.cadCfop);
  const dispatch = useDispatch();

  const handleSelectCsts = async (selected) => {
    if (selected) {
      const [ok, ret] = await TributacaoService.camposAtivosIcms(
        selected?.value
      );
      if (!ok) return;
      dispatch(setCamposAtivosIcms(ret));

      if (!ret.credito_icms_simples_ativo) {
        dispatch(setGeraCredIcms(false));
      }

      if (!ret.mod_bc_icms_ativo) {
        dispatch(setModBcIcms(""));
      }

      if (!ret.tipo_red_bc_icms_ativo) {
        dispatch(setTipoRedBaseIcms(""));
      }

      dispatch(setSitTribIcms(selected?.value));
    }
  };

  return (
    <>
      <Divider>ICMS</Divider>
      <Row>
        <ComboBox
          label="Situação Tributária"
          isSearchable={false}
          md={12}
          options={store.cstsIcms}
          defaultOptions
          defaultValue={store.sitTribIcms}
          onChange={handleSelectCsts}
          divClassName="pr-0"
        />
      </Row>
      <Row>
        <ComboBox
          label="Modalidade de Determinação da Base de Cálculo"
          isSearchable={false}
          md={9}
          options={modalidades_icms}
          defaultOptions
          isDisabled={!store.camposAtivosIcms?.mod_bc_icms_ativo}
          onChange={(selected) => dispatch(setModBcIcms(selected?.value ?? ""))}
          defaultValue={store.modBcIcms}
          divClassName="pr-0"
        />
        <NumberInput
          label="Alíquota"
          md={3}
          disabled={!store.camposAtivosIcms?.aliq_icms_ativo}
          onChange={(v) => dispatch(setPercIcms(v))}
          value={store.percIcms}
          isPercentage
          clearOnDisable
          divClassName="pr-0"
        />
      </Row>
      <Row>
        <ComboBox
          label="Tipo de Redutor do ICMS"
          isSearchable={false}
          md={6}
          options={tipos_redutor_bc_icms}
          defaultOptions
          isDisabled={!store.camposAtivosIcms?.tipo_red_bc_icms_ativo}
          onChange={(selected) =>
            dispatch(setTipoRedBaseIcms(selected?.value ?? ""))
          }
          defaultValue={store.tipoRedBaseIcms}
          divClassName="pr-0"
        />
        <NumberInput
          label="Reduzir Em"
          md={3}
          disabled={
            !store.camposAtivosIcms?.perc_red_em_bc_icms_ativo ||
            store.tipoRedBaseIcms !== "EM"
          }
          onChange={(v) => dispatch(setPercRedBaseIcmsEm(v))}
          value={store.percRedBaseIcmsEm}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          divClassName="pr-0"
        />
        <NumberInput
          label="Reduzir Para"
          md={3}
          disabled={
            !store.camposAtivosIcms?.perc_red_para_bc_icms_ativo ||
            store.tipoRedBaseIcms !== "PA"
          }
          onChange={(v) => dispatch(setPercRedBaseIcmsPara(v))}
          value={store.percRedBaseIcmsPara}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          divClassName="pr-0"
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Soma valor de Frete na B.C. do ICMS (Web)"
          checked={store.somaVlrFreteBcIcms}
          onChange={() =>
            dispatch(setSomaVlrFreteBcIcms(!store.somaVlrFreteBcIcms))
          }
          padded={false}
        />
        <FormCheckbox
          label="Soma Outras Despesas na B.C. do ICMS (Web)"
          checked={store.somaVlrOutrosBcIcms}
          onChange={() =>
            dispatch(setSomaVlrOutrosBcIcms(!store.somaVlrOutrosBcIcms))
          }
          padded={false}
        />
      </Row>
    </>
  );
};
