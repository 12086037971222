import { stateAsPayload } from "../../redux/reducers/frente_venda_mobile/gerenciamentoVendasReducer";
import { api } from "../../api";
import { toastr } from "react-redux-toastr";
import { apiGetV2 } from "../../apiV2";

const processaRetorno = (ret, returnType) => {
  if (ret.data.success || (ret.data.hasOwnProperty("err") && !ret.data.err)) {
    if (returnType) {
      return ret.data.res;
    } else {
      toastr.success("Sucesso", ret.data.msg);
      return true;
    }
  } else {
    toastr.error("Erro", ret.data.msg);
    return returnType ?? false;
  }
};

const processaErro = (err, returnType) => {
  toastr.error("Erro", err.message);
  return returnType ?? false;
};

const GereciamentoVendasMobileService = {
  buscar: async (idVendaMob) =>
    await apiGetV2(`/venda_mob/buscar/${idVendaMob}/`),
  buscarDados: async (filtros) => {
    const urlParams = new URLSearchParams(stateAsPayload(filtros)).toString();
    const url = `venda_mob/gerenciamento/?${urlParams}`;
    const ret = await api
      .get(url)
      .catch((err) => toastr.error("Erro", err.message));
    if (ret.data.success) {
      return ret.data.res;
    } else {
      toastr.error("Erro", ret.data.msg);
      return {
        alertas: [],
        vendas: [],
        totais: {
          num_vendas: "0",
          troca: "0",
          produtos: "0",
          icms_st: "0",
          geral: "0",
        },
      };
    }
  },
  buscarDetalhes: async (idVenda) => {
    return await api
      .get(`/venda_mob/gerenciamento/itens/${idVenda}/`)
      .then((ret) => processaRetorno(ret, []))
      .catch((err) => processaErro(err, []));
  },
};

export default GereciamentoVendasMobileService;
