import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  PageContainer,
  CardTotais,
  CardTotaisItem,
} from "../../../../components";
import { ClientesInadRotaGrid } from "./components/ClientesInadRotaGrid";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";

export const ClientesInadimplentesRota = () => {
  const [idRota, setIdRota] = useState(null);
  const [idCidade, setIdCidade] = useState(null);
  const [dados, setDados] = useState([]);
  const [totalGeralPend, setTotalGeralPend] = useState(0);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => {
    setDados([]);
    setTotalGeralPend(0);
  };

  const buscarDados = async () => {
    const params = {
      id_rota: idRota,
      id_cidade: idCidade,
    };
    return await apiGetV2(
      "/relatorios/venda_direta/inadimplencia_por_rota/",
      params
    );
  };

  const listarDados = async () => {
    setLoadingPesquisar(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      setDados(ret.rotas);
      setTotalGeralPend(ret.total_geral_pend);
    } else {
      setDados([]);
      setTotalGeralPend(0);
    }
    setLoadingPesquisar(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      await docPrintReport(
        "/relatorios/pronta_entrega/clientes_inad_rota/",
        { dados: ret },
        "5028_2"
      );
    }
    setLoadingImprimir(false);
  };

  useEffect(() => {
    limparDados();
  }, [idRota, idCidade]);

  return (
    <PageContainer
      title="Clientes Inadimplentes por Rota"
      number="5028"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={2}
            label="Rota"
            concatModelName="rota_mob"
            defaultOptions
            onChange={(s) => setIdRota(s?.value)}
          />
          <AsyncComboBox
            md={3}
            label="Cidade"
            concatModelName="cidade"
            defaultOptions
            onChange={(s) => setIdCidade(s?.value)}
            hideShortcut
          />
          <BotaoPesquisar onClick={listarDados} loading={loadingPesquisar} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <CardTotais md={3}>
        <CardTotaisItem label="Total Geral a Receber" value={totalGeralPend} />
      </CardTotais>
      <ClientesInadRotaGrid dados={dados} />
    </PageContainer>
  );
};
