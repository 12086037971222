import React from "react";
import { IconButton } from "../../../../../components";
import { BsPrinter } from "react-icons/bs";

const AcoesGridSeparacaoAlmoxarifadoProjeto = () => {
  return (
    <>
      <IconButton
        icon={BsPrinter}
        size={16}
        tooltipPlacement="left"
        hint={"Imprimir Separação dos Itens"}
      />
    </>
  );
};

export default AcoesGridSeparacaoAlmoxarifadoProjeto;
