import { apiGetV2, apiPostV2 } from "../../../apiV2";

const URL_BASE = "/relatorios/comissao/por_meta";

const ComissaoMetaService = {
  buscarDadosPainel: async (idColab, mes, ano) =>
    await apiGetV2(`${URL_BASE}/dados_painel/${idColab}/`, {
      mes: mes,
      ano: ano,
    }),
  calcularPrevia: async (payload) =>
    await apiPostV2(`${URL_BASE}/calcular_previa/`, payload, {
      successMessage: false,
    }),
  confirmarPercComissao: async (payload) =>
    await apiPostV2(`${URL_BASE}/confirmar/`, payload),
};

export default ComissaoMetaService;
