import React from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  IntegerFormInput,
  LinkButton,
  PageContainer,
} from "../../../../components";
import { DevolucaoVendaGrid } from "./components/DevolucaoVendaGrid";
import DevolucaoVendaService from "../../../../services/vendas/DevolucaoVendaService";
import { useEffect } from "react";
import { devolverVendaRoute } from "../../../../routes/modules/vendas";

export const DevolucaoVenda = ({ location }) => {
  const [idVenda, setIdVenda] = useState(null);
  const [idPedido, setIdPedido] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    setLoading(true);

    const params = {
      id_venda: idVenda,
      id_ped: idPedido,
      id_cliente: idCliente,
      data_ini: dataIni,
      data_fim: dataFim,
    };

    setDados(await DevolucaoVendaService.listarVendas(params));

    setLoading(false);
  };

  const onActivate = () => {
    if (location.state?.refresh) {
      carregarDados();
    }
  };

  useEffect(onActivate, []);

  return (
    <PageContainer title="Devolução de Venda" number="0059" canGoBack>
      <Card body>
        <Row>
          <IntegerFormInput
            md={2}
            label="Nº Venda"
            defaultValue={idVenda}
            onChange={setIdVenda}
          />
          <IntegerFormInput
            md={2}
            label="Nº Pedido"
            defaultValue={idPedido}
            onChange={setIdPedido}
          />
          <AsyncComboBox
            md={4}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
            hideShortcut
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <LinkButton
            md="auto"
            color="primary"
            padded
            pathname={devolverVendaRoute.path}
            state={{ idVenda: selected }}
            disabled={[0, null, undefined].includes(selected)}
          >
            Devolver Venda
          </LinkButton>
        </Row>
      </Card>
      <Card body>
        <DevolucaoVendaGrid dados={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
