import React from "react";
import { DataTable } from "../../../../../components/DataTable";
import { formatValueFromAPI } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome_transportadora",
    text: "Transportadora",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) =>
      row.id_transportadora ? formatValueFromAPI(c, row.id_transportadora) : "",
  },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_usuario),
  },
  {
    dataField: "data_hora_realizado",
    text: "Realizado",
    align: "center",
    headerAlign: "center",
  },
];

export const RomaneiosRealizadosGrid = ({
  data,
  selected,
  handleSelect,
  handleSelectAll,
}) => {
  return (
    <DataTable
      data={data}
      columns={columns}
      isMulti
      selected={selected}
      onSelect={handleSelect}
      onSelectAll={handleSelectAll}
      paginated
    />
  );
};
