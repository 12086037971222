import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

export const IconButton = ({
  icon: Icon,
  size = 16,
  color,
  onClick,
  disabled,
  disabledHint,
  hint,
  id,
  className,
  tooltipPlacement,
  style,
  centered = false,
}) => {
  const [internalId] = useState(
    id ?? "ib-" + Math.floor(Math.random() * Date.now())
  );

  // Verifica se o navegador é Firefox
  const firefox = typeof InstallTrigger !== "undefined";

  if (!tooltipPlacement && firefox) {
    tooltipPlacement = "left";
  }

  if (centered) {
    style = {
      display: "flex",
      margin: "auto",
      ...style,
    };
  }

  return (
    <>
      <Icon
        size={size}
        onClick={!disabled && onClick ? (e) => onClick(e) : () => {}}
        color={!disabled ? color : "#999"}
        id={internalId}
        style={{
          cursor: !disabled ? "pointer" : "default",
          ...style,
        }}
        className={className}
      />
      {(hint || (disabled && disabledHint)) && (
        <UncontrolledTooltip target={internalId} placement={tooltipPlacement}>
          {!disabled ? hint : disabledHint}
        </UncontrolledTooltip>
      )}
    </>
  );
};
