import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  DragAndDropArquivos,
  FormButton,
} from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { RetornoBancarioService } from "../../../../services/bancario/RetornoBancarioService";
import { TableTipos } from "./components/TableTipos";
import moment from "moment";
import { docPrintReport } from "../../../../pdf";

export const RetornoBancario = () => {
  const [idContaBancaria, setIdContaBancaria] = useState(0);
  const [ocorrencias, setOcorrencias] = useState(null);
  const [dadosArquivo, setDadosArquivo] = useState(null);
  const [loadingProcessar, setLoadingProcessar] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingLeitura, setLoadingLeitura] = useState(false);
  const [arquivo, setArquivo] = useState(null);

  const limparDados = () => {
    setOcorrencias(null);
    setArquivo(null);
  };

  const onDrop = async (file) => {
    setLoadingLeitura(true);
    try {
      const [ok, ret] = await RetornoBancarioService.lerArquivo(
        file,
        idContaBancaria
      );
      if (ok) {
        setOcorrencias(ret?.ocorrencias);
        setDadosArquivo(ret);
        setArquivo(file);
      } else {
        setOcorrencias(null);
        setDadosArquivo(null);
        setArquivo(null);
      }
    } finally {
      setLoadingLeitura(false);
    }
  };

  const __formatOcorrencias = (tarifasDict = false) => {
    const result = {
      id_conta_banc: idContaBancaria,
      nome_arquivo: dadosArquivo.nome_arquivo,
      data_arquivo: dadosArquivo.data_arquivo,
      nro_identif_proc: dadosArquivo.nro_identif_proc,
    };

    ocorrencias.forEach((element) => {
      if (element.chave === "tarifas" && tarifasDict) {
        result[element.chave] = {
          valor: element.valor_ocorrencias,
        };
        if (element.ocorrencias.length > 0) {
          result[element.chave].data_registro =
            element.ocorrencias[0].data_ocorrencia;
        } else {
          result[element.chave].data_registro = moment(new Date()).format(
            "DD/MM/YYYY"
          );
        }
      } else {
        result[element.chave] = element.ocorrencias;
      }
    });

    return result;
  };

  const onPrint = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await RetornoBancarioService.lerArquivo(
      arquivo,
      idContaBancaria
    );

    if (ok) {
      await docPrintReport("/bancario/retorno/", { dados: ret }, "0019_1");
    }
    setLoadingImprimir(false);
  };

  const onProcessar = async () => {
    const data = __formatOcorrencias(true);
    setLoadingProcessar(true);
    const [ok] = await RetornoBancarioService.processarRetorno(data);
    if (ok) {
      setOcorrencias(null);
    }
    setLoadingProcessar(false);
  };

  return (
    <PageContainer title="Retorno Bancário" number="0019" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            isConcatField
            concatModelName="conta_banc"
            defaultOptions
            label="Conta Bancária"
            isSearchable
            isClearable
            onChange={(sel) => {
              if ((sel?.value ?? 0) !== idContaBancaria) {
                setOcorrencias(null);
              }
              setIdContaBancaria(sel?.value ?? 0);
            }}
            md={5}
          />
          {ocorrencias !== null && (
            <FormButton md="auto" color="warning" onClick={limparDados}>
              Importar Outro Arquivo
            </FormButton>
          )}
        </Row>
      </Card>
      {idContaBancaria > 0 && (
        <Card body>
          {ocorrencias == null ? (
            <Row>
              <DragAndDropArquivos
                md={12}
                onDrop={onDrop}
                loading={loadingLeitura}
                loadingMessage="Lendo o arquivo. Aguarde um momento"
              />
            </Row>
          ) : (
            <>
              <Row className="mb-2">
                <FormButton
                  padded={false}
                  md="auto"
                  divClassName="pr-0"
                  color="info"
                  onClick={onProcessar}
                  loading={loadingProcessar}
                >
                  Processar Arquivo
                </FormButton>
                <FormButton
                  padded={false}
                  md="auto"
                  divClassName="pr-0"
                  color="primary"
                  className="ml-2"
                  onClick={onPrint}
                  loading={loadingImprimir}
                >
                  Imprimir Relatório
                </FormButton>
              </Row>
              <TableTipos data={ocorrencias} />
            </>
          )}
        </Card>
      )}
    </PageContainer>
  );
};
