import React, { useCallback } from "react";
import { Card } from "reactstrap";
import { PageContainer } from "../../../../../../components/PageContainer";

import { Row } from "reactstrap";
import {
  AsyncComboBox,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FixedField,
  FormButton,
  IconButton,
  MaskedInput,
  TextInput,
} from "../../../../../../components";
import { Divider } from "../../../../../../components/Divider";
import { v4 as uuidv4 } from "uuid";
import {
  formatValueFromAPI,
  MODAL_ACTIONS,
  roundFloat,
  sumDataField,
  useStateWithRef,
} from "../../../../../../coreUtils";
import OrcamentosService from "../../../../../../services/orcamentos/OrcamentosService";
import { OrcamentoItensGrid } from "./components/OrcamentoItensGrid";
import { useState } from "react";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { IncluirProdOrcamentoForm } from "./components/IncluirProdOrcamentoForm";
import {
  orcRapidoOSRoute,
  orcamentosOSRoute,
} from "../../../../../../routes/modules/ordem_servico";
import { toastr } from "react-redux-toastr";
import { BiCopy } from "react-icons/bi";
import CadastroLigacaoVeicCliService from "../../../../../../services/cadastro/CadastroLigacaoVeicCliService";
import PesqCliente from "../../../../../../components/form/pesq_cliente/PesqCliente";
import { apiGetV2 } from "../../../../../../apiV2";

const formasPag = [
  { label: "Dinheiro", value: "DIN" },
  { label: "Cartão de Crédito", value: "CCR" },
  { label: "Cartão de Débito", value: "CDE" },
  { label: "Boleto", value: "BOL" },
  { label: "Transferência Bancária", value: "TBA" },
  { label: "Cheque", value: "CHE" },
  { label: "Crédito Rotativo", value: "CRO" },
];

export const IncluirAlterarOrcamentoOS = ({ location }) => {
  const idOs = location.state?.idOs ?? null;
  const idOrcamento = location.state?.idOrcamento;
  const action = location.state?.action ?? MODAL_ACTIONS.ADD;

  // Dados da OS
  const [placa, setPlaca] = useState("");
  const [idCliente, setIdCliente] = useState(0);
  const [nomeCliente, setNomeCliente] = useState("");
  const [fone1Cliente, setFone1Cliente] = useState("");
  const [whatsCliente, setWhatsCliente] = useState("");
  const [status, setStatus] = useState("");
  const [emissao, setEmissao] = useState("");
  const [idVeiculo, setIdVeiculo] = useState(null);
  const [idLigacaoVeic, setIdLigacaoVeic] = useState(0);
  const [modeloVeic, setModeloVeic] = useState("");
  const [nomeVeiculo, setNomeVeiculo] = useState("");
  const [anoVeic, setAnoVeic] = useState("");
  const [chassiVeic, setChassiVeic] = useState("");
  const [veiculosCliente, setVeiculosCliente] = useState([]);

  // Dados do Orçamento
  const [vlrTotal, setVlrTotal] = useState(null);
  const [vlrTotDesconto, setVlrTotDesconto] = useState(null);
  const [obs, setObs] = useState(null);
  const [formaPagto, setFormaPagto] = useState(null);
  const [idCondPag, setIdCondPag] = useState(null);
  const [itens, setItens, itensRef] = useStateWithRef([]);

  // Variáveis de Controle
  const [orcRapido, setOrcRapido] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [deveBuscarClientePlaca, setDeveBuscarClientePlaca] = useState(false);

  // Parâmetros
  const [sistAutomotivo, setSistAutomotivo] = useState(false);
  const [adicionaItemGrid, setAdicionaItemGrid] = useState(false);
  const [utilizaObservItem, setUtilizaObservItem] = useState(false);

  const carregarParametros = async () => {
    const [ok, ret] = await OrcamentosService.buscarParametros();

    if (ok) {
      setSistAutomotivo(ret.tipo_sist === "MECANICA");
      setAdicionaItemGrid(ret.adiciona_item_grid);
      setUtilizaObservItem(ret.utiliza_observ_item);
      if (
        action === MODAL_ACTIONS.ADD &&
        ![0, null, undefined].includes(ret.id_cliente_curinga)
      ) {
        setIdCliente(ret.id_cliente_curinga);
      }
    }
  };

  const carregarDadosOS = async () => {
    const [ok, ret] = await OrcamentosService.buscarOrcamentosOS(idOs, true);

    if (ok) {
      const os = ret.os;
      setPlaca(os.placa);
      setIdCliente(os.id_cliente);
      setNomeCliente(os.nome_cliente);
      setFone1Cliente(os.id_cliente__fone1);
      setWhatsCliente(os.id_cliente__whatsapp);
      setStatus(os.desc_status);
      setEmissao(os.emissao);
      setIdVeiculo(os.id_veiculo);
      setIdLigacaoVeic(os.id_ligacao_veic);
      setModeloVeic(os.modelo_veic);
      setNomeVeiculo(os.veiculo);
      setAnoVeic(os.ano_veic);
      setChassiVeic(os.chassi_veic);
    }
  };

  const carregarDados = async () => {
    let [ok, ret] = await OrcamentosService.buscar(idOrcamento);
    if (ok) {
      setPlaca(ret.placa);
      setIdCliente(ret.id_cliente);
      setNomeCliente(ret.id_cliente__nome);
      setFone1Cliente(ret.id_cliente__fone1);
      setWhatsCliente(ret.id_cliente__whatsapp);
      setIdVeiculo(ret.id_veiculo);
      setIdLigacaoVeic(ret.id_ligacao_veic);
      setModeloVeic(ret.modelo_veic);
      setNomeVeiculo(ret.veiculo);
      setAnoVeic(ret.ano_veic);
      setChassiVeic(ret.chassi_veic);
      setVlrTotDesconto(parseFloat(ret.vlr_desc ?? 0));
      setVlrTotal(parseFloat(ret.vlr_total));
      setFormaPagto(ret.forma_pagto);
      setIdCondPag(ret.id_cond_pag);
      setObs(ret.observ);
      setItens(
        ret.itens.map((el) => ({
          ...el,
          quantidade: parseFloat(el.quantidade),
          vlr_unit: parseFloat(el.vlr_unit),
          vlr_item: parseFloat(el.vlr_item),
          perc_desc: parseFloat(el.perc_desc),
          vlr_tot_desc: parseFloat(el.vlr_tot_desc),
          vlr_tot_acre: parseFloat(el.vlr_tot_acre),
          vlr_total: parseFloat(el.vlr_total),
        }))
      );
      atualizarValoresCab();
    } else {
      setItens([]);
    }
  };

  const iniciarInclusao = () => {
    setVlrTotal(0);
    setVlrTotDesconto(0);
    setObs("");
    setFormaPagto(formasPag[0].value);
    setIdCondPag(0);
  };

  const atualizarValoresCab = () => {
    setVlrTotal(roundFloat(sumDataField(itensRef.current, "vlr_total"), 2));
    setVlrTotDesconto(
      roundFloat(sumDataField(itensRef.current, "vlr_tot_desc"), 2)
    );
  };

  const handleDeleteItem = (index) => {
    setItens(itensRef.current.filter((_, i) => i !== index));
    atualizarValoresCab();
  };

  const verificarItemJaIncluido = (tipo, idItem) =>
    itensRef.current.findIndex((e) => e.tipo === tipo && e.id_item === idItem) >
    -1;

  const handleAddItem = (item) => {
    setItens([...itensRef.current, { ...item, id: uuidv4() }]);
    atualizarValoresCab();
  };

  const handleSubstituirItem = (item) => {
    setItens(
      itensRef.current.map((e) =>
        e.tipo === item.tipo && e.id_item === item.id_item ? item : e
      )
    );
    atualizarValoresCab();
  };

  const handleSetFormaPagto = (v) => {
    setFormaPagto(v);
    if (!["BOLE", "CHEQ"].includes(v)) {
      setIdCondPag(null);
    }
  };

  const handleSubmit = async () => {
    if (loading) return;

    if ([0, null, undefined].includes(idCliente)) {
      toastr.warning("Atenção", "Por favor, informe o Cliente.");
      return false;
    }

    setLoading(true);

    const prods = itens
      .filter((e) => e.tipo === "P")
      .map((e) => ({
        ...(action === MODAL_ACTIONS.EDIT
          ? { id_item: typeof e.id == "string" ? null : e.id }
          : {}),
        id_produto: e.id_item,
        nome_produto: e.descricao,
        quantidade: e.quantidade,
        vlr_unit: e.vlr_unit,
        vlr_item: e.vlr_item,
        perc_desc: e.perc_desc,
        vlr_tot_desc: e.vlr_tot_desc,
        vlr_tot_acre: e.vlr_tot_acre,
        vlr_total: e.vlr_total,
        observ: e.observ ?? "",
      }));

    const servs = itens
      .filter((e) => e.tipo === "S")
      .map((e) => ({
        ...(action === MODAL_ACTIONS.EDIT
          ? { id_item: typeof e.id == "string" ? null : e.id }
          : {}),
        id_servico: e.id_item,
        nome_servico: e.descricao,
        quantidade: e.quantidade,
        vlr_unit: e.vlr_unit,
        vlr_item: e.vlr_item,
        perc_desc: e.perc_desc,
        vlr_tot_desc: e.vlr_tot_desc,
        vlr_tot_acre: e.vlr_tot_acre,
        vlr_total: e.vlr_total,
      }));

    const payload = {
      ...(action === MODAL_ACTIONS.EDIT ? { id_orcamento: idOrcamento } : {}),
      id_cliente: idCliente,
      nome_cliente: nomeCliente,
      placa: placa,
      id_veiculo: idVeiculo ?? null,
      ...(action === MODAL_ACTIONS.ADD
        ? { id_vendedor: parseInt(localStorage.getItem("id_colaborador")) }
        : {}),
      vlr_tot_desconto: vlrTotDesconto,
      vlr_total: vlrTotal,
      observ: obs,
      origem: orcRapido ? "MAN" : "VOS",
      id_origem: orcRapido ? 0 : idOs,
      forma_pag: formaPagto,
      id_cond_pag: idCondPag,
      itens: prods,
      servicos: servs,
    };

    const submitFunc =
      action === MODAL_ACTIONS.ADD
        ? OrcamentosService.incluir
        : OrcamentosService.alterar;

    const [ok, ret] = await submitFunc(payload);

    if (ok) {
      if (action === MODAL_ACTIONS.ADD) {
        await OrcamentosService.imprimir(ret.id_orcamento, true);
      }
      setRedirect(true);
    }

    setLoading(false);
  };

  const iniciarTela = async () => {
    await carregarParametros();
    if (action === MODAL_ACTIONS.EDIT) {
      if ([0, null, undefined].includes(idOs)) {
        setOrcRapido(true);
      }
      await carregarDados();
    } else {
      if (![0, null, undefined].includes(idOs)) {
        await carregarDadosOS();
      } else {
        setOrcRapido(true);
      }
      iniciarInclusao();
    }
    setLoadingFetch(false);
  };

  const buscarClientePelaPlaca = async (v) => {
    if (!["", null, undefined].includes(v) && deveBuscarClientePlaca) {
      const [ok, ret] = await CadastroLigacaoVeicCliService.buscarClientePlaca(
        placa
      );

      if (ok) {
        setIdCliente(ret.id_cliente);
        setNomeCliente(ret.nome_cliente);
        setFone1Cliente(ret.fone1_cliente);
        setModeloVeic(ret.modelo_veiculo);
        setIdVeiculo(ret.id_veiculo);
        setIdLigacaoVeic(ret.id_ligacao);
        setAnoVeic(ret.ano_veiculo);
        setChassiVeic(ret.chassi_veiculo);
      } else {
        setIdCliente(null);
        setNomeCliente("");
        setFone1Cliente("");
        setIdVeiculo(null);
        setIdLigacaoVeic(null);
        setModeloVeic("");
        setAnoVeic("");
        setChassiVeic("");
      }
      setDeveBuscarClientePlaca(false);
    }
  };

  const buscarVeiculosCliente = useCallback(async () => {
    if (idCliente) {
      const [ok1, ret1] =
        await CadastroLigacaoVeicCliService.buscarVeiculosCliente(idCliente);
      const veiculos = (ok1 ? ret1 : []).map((veic) => ({
        label: veic.nome,
        value: veic.id,
        id_veiculo: veic.id_veiculo,
        placa: veic.placa,
        modelo: veic.modelo,
        cor: veic.cor,
        ano: veic.ano,
        combustivel: veic.combustivel,
        chassi: veic.chassi,
      }));
      setVeiculosCliente(veiculos);
      if (veiculos.length > 0) {
        if (placa !== "") {
          const veicPlaca = veiculos.find((e) => e.placa === placa);
          if (veicPlaca) {
            setIdVeiculo(veicPlaca.id_veiculo);
            setIdLigacaoVeic(veicPlaca.value);
            setNomeVeiculo(veicPlaca.label);
          }
        } else {
          setIdVeiculo(veiculos[0].id_veiculo);
          setIdLigacaoVeic(veiculos[0].value);
          setNomeVeiculo(veiculos[0].label);
        }
      }
      const [ok2, ret2] = await apiGetV2(
        `cadastro/cliente/buscar_resumo/${idCliente}/`,
        {}
      );
      setFone1Cliente(ret2.fone1);
    } else {
      setVeiculosCliente([]);
    }
  }, [idCliente]);

  const recalcularValoresGrade = (coluna, novoValor, row) => {
    // Se o valor não for alterado, não recalcula nada
    const alterouValorNumerico = !["descricao", "observ"].includes(coluna);
    let novoValorTest;
    let valorAntesTest;
    if (alterouValorNumerico) {
      novoValorTest = roundFloat(parseFloat(novoValor), 2);
      valorAntesTest = roundFloat(parseFloat(row[coluna]), 2);
    } else {
      novoValorTest = novoValor;
      valorAntesTest = row[coluna];
    }

    if (novoValorTest === valorAntesTest) {
      return false;
    }

    if (coluna === "descricao") {
      row.descricao = novoValor;
    } else if (coluna === "observ") {
      row.observ = novoValor;
    } else if (coluna === "quantidade") {
      if (novoValor === undefined || novoValor === null) {
        novoValor = "0";
      }
      const quantidade = parseFloat(novoValor);
      const vlrItem = parseFloat(row.vlr_item);
      const vlrUnit = parseFloat(row.vlr_unit);

      let novoVlrDesc;
      let novoPercDesc;
      let novoVlrAcre;
      if (vlrUnit > vlrItem) {
        novoVlrDesc = quantidade * (vlrUnit - vlrItem);
        let vlrDescUnit = vlrUnit - vlrItem;
        if (vlrUnit > 0) {
          novoPercDesc = roundFloat((vlrDescUnit / vlrUnit) * 100, 2);
        } else {
          novoPercDesc = 0;
        }
        novoVlrAcre = 0;
      } else {
        novoVlrDesc = 0;
        novoPercDesc = 0;
        if (vlrItem > vlrUnit) {
          novoVlrAcre = quantidade * (vlrItem - vlrUnit);
        } else {
          novoVlrAcre = 0;
        }
      }
      const vlrTotal = vlrItem * quantidade;
      row.quantidade = quantidade;
      row.perc_desc = roundFloat(novoPercDesc, 2);
      row.vlr_tot_desc = roundFloat(novoVlrDesc, 2);
      row.vlr_tot_acre = roundFloat(novoVlrAcre, 2);
      row.vlr_total = roundFloat(vlrTotal, 2);
    } else if (coluna === "vlr_item") {
      if (novoValor === undefined || novoValor === null) {
        novoValor = "0";
      }
      const quantidade = parseFloat(row.quantidade);
      const vlrItem = parseFloat(novoValor);
      const vlrUnit = parseFloat(row.vlr_unit);

      let novoVlrDesc;
      let novoPercDesc;
      let novoVlrAcre;
      if (vlrUnit > vlrItem) {
        novoVlrDesc = quantidade * (vlrUnit - vlrItem);
        let vlrDescUnit = vlrUnit - vlrItem;
        if (vlrUnit > 0) {
          novoPercDesc = roundFloat((vlrDescUnit / vlrUnit) * 100, 2);
        } else {
          novoPercDesc = 0;
        }
        novoVlrAcre = 0;
      } else {
        novoVlrDesc = 0;
        novoPercDesc = 0;
        if (vlrItem > vlrUnit) {
          novoVlrAcre = quantidade * (vlrItem - vlrUnit);
        } else {
          novoVlrAcre = 0;
        }
      }
      const vlrTotal = vlrItem * quantidade;
      row.perc_desc = roundFloat(novoPercDesc, 2);
      row.vlr_tot_desc = roundFloat(novoVlrDesc, 2);
      row.vlr_tot_acre = roundFloat(novoVlrAcre, 2);
      row.vlr_item = roundFloat(vlrItem, 2);
      row.vlr_total = roundFloat(vlrTotal, 2);
    } else if (coluna === "perc_desc") {
      if (novoValor === undefined || novoValor === null) {
        novoValor = "0";
      }
      const percDesc = parseFloat(novoValor);
      const quantidade = parseFloat(row.quantidade);
      const vlrUnit = parseFloat(row.vlr_unit);
      const vlrTotBruto = vlrUnit * quantidade;

      const vlrItem = ((100 - percDesc) / 100) * vlrUnit;
      const vlrTotal = vlrItem * quantidade;
      const vlrDesc = (percDesc / 100) * vlrTotBruto;
      row.perc_desc = roundFloat(percDesc, 2);
      row.vlr_tot_desc = roundFloat(vlrDesc, 2);
      row.vlr_tot_acre = 0;
      row.vlr_item = roundFloat(vlrItem, 2);
      row.vlr_total = roundFloat(vlrTotal, 2);
    } else if (coluna === "vlr_total") {
      if (novoValor === undefined || novoValor === null) {
        novoValor = "0";
      }
      const vlrTotal = parseFloat(novoValor);
      const quantidade = parseFloat(row.quantidade);
      const vlrUnit = parseFloat(row.vlr_unit);
      const vlrTotBruto = vlrUnit * quantidade;

      const vlrItem = roundFloat(vlrTotal / quantidade, 4);
      let novoVlrDesc;
      let novoPercDesc;
      let novoVlrAcre;
      if (vlrUnit > vlrItem) {
        novoVlrDesc = quantidade * (vlrUnit - vlrItem);
        novoPercDesc = roundFloat((novoVlrDesc / vlrTotBruto) * 100, 2);
        novoVlrAcre = 0;
      } else {
        novoVlrDesc = 0;
        novoPercDesc = 0;
        if (vlrItem > vlrUnit) {
          novoVlrAcre = quantidade * (vlrItem - vlrUnit);
        } else {
          novoVlrAcre = 0;
        }
      }
      row.perc_desc = roundFloat(novoPercDesc, 2);
      row.vlr_tot_desc = roundFloat(novoVlrDesc, 2);
      row.vlr_tot_acre = roundFloat(novoVlrAcre, 2);
      row.vlr_item = roundFloat(vlrItem, 2);
      row.vlr_total = roundFloat(vlrTotal, 2);
    }

    setItens(
      itens.map((element) => {
        if (element.id === row.id) {
          return { ...row };
        } else {
          return element;
        }
      })
    );
    atualizarValoresCab();
  };

  useEffect(() => {
    if (orcRapido) {
      buscarVeiculosCliente();
    }
  }, [buscarVeiculosCliente, orcRapido]);

  useEffect(() => {
    if (!idLigacaoVeic && orcRapido) {
      setModeloVeic("");
      setNomeVeiculo("");
      setAnoVeic("");
      setChassiVeic("");
    }
  }, [idLigacaoVeic, orcRapido]);

  useEffect(() => {
    iniciarTela();
  }, []);

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9") handleSubmit();
    }
  };

  return (
    <PageContainer
      title={
        action === MODAL_ACTIONS.ADD
          ? `Inclusão de Orçamento ${
              !orcRapido ? "Vinculado À O.S." : "Rápido"
            }`
          : `Alteração do Orçamento Nº ${idOrcamento}`
      }
      number="0077_1"
      canGoBack
      onKeyDown={onKeyDown}
      loading={loadingFetch}
    >
      {redirect && (
        <Redirect
          to={
            orcRapido
              ? {
                  pathname: orcRapidoOSRoute.path,
                  state: { refresh: true },
                }
              : {
                  pathname: orcamentosOSRoute.path,
                  state: { idOs: idOs },
                }
          }
        />
      )}
      {orcRapido ? (
        <Card body>
          <Row>
            <PesqCliente
              onConfirm={(id, nome) => {
                if (id !== idCliente) {
                  setIdVeiculo(null);
                  setIdLigacaoVeic(null);
                }
                if (action === MODAL_ACTIONS.ADD && idCliente !== id) {
                  setPlaca("");
                }
                setIdCliente(id);
                setNomeCliente(nome);
              }}
              selectNextField={() => {}}
              onChangeDescricao={setNomeCliente}
              idCliente={idCliente}
              nomeCliente={nomeCliente}
              disabled={action === MODAL_ACTIONS.EDIT}
              autoFocus={sistAutomotivo}
              sistAutomotivo={sistAutomotivo}
            />
            {sistAutomotivo && (
              <MaskedInput
                md={2}
                mask="aaa-9*99"
                label="Placa"
                value={placa}
                onChange={(e, v) => {
                  setDeveBuscarClientePlaca(true);
                  setPlaca(v);
                }}
                onBlur={buscarClientePelaPlaca}
                disabled={action === MODAL_ACTIONS.EDIT}
                maskChar={null}
                placeholder="Opcional"
              />
            )}
          </Row>
          {sistAutomotivo && (
            <>
              <Row>
                {action === MODAL_ACTIONS.ADD ? (
                  <ComboBox
                    md={4}
                    label="Veículo"
                    defaultValue={idLigacaoVeic}
                    options={veiculosCliente}
                    onChange={(s) => {
                      setIdLigacaoVeic(s?.value);
                      setIdVeiculo(s?.id_veiculo);
                      setNomeVeiculo(s?.label);
                      setPlaca(s?.placa);
                      setModeloVeic(s?.modelo);
                      setAnoVeic(s?.ano);
                      setChassiVeic(s?.chassi);
                    }}
                    isDisabled={action === MODAL_ACTIONS.EDIT}
                    noOptionsMessage={() => "Selecione o cliente"}
                    placeholder="Opcional"
                  />
                ) : (
                  <TextInput
                    md={4}
                    label="Veículo"
                    value={nomeVeiculo}
                    disabled
                  />
                )}
                <FixedField label="Modelo" value={modeloVeic} />
                <FixedField label="Ano" value={anoVeic} />
                <FixedField label="Chassi" value={chassiVeic} />
              </Row>
            </>
          )}
        </Card>
      ) : (
        <Card body>
          <Row>
            <FixedField horizontal label="Nº O.S." value={idOs} />
            {sistAutomotivo && (
              <FixedField horizontal label="Placa" value={placa} />
            )}
            {action === MODAL_ACTIONS.ADD && (
              <>
                <FixedField horizontal label="Emissão" value={emissao} />
                <FixedField horizontal label="Status" value={status} />
              </>
            )}
          </Row>
          <Row>
            <FixedField
              horizontal
              label="Cliente"
              value={formatValueFromAPI(nomeCliente, idCliente)}
            />
            <FixedField horizontal label="Telefone" value={fone1Cliente} />
            <FixedField horizontal label="WhatsApp" value={whatsCliente} />
          </Row>
          {sistAutomotivo && (
            <Row>
              {/* <FixedField horizontal label="Montadora" value="CHEVROLET" /> */}
              <FixedField horizontal label="Veículo" value={nomeVeiculo} />
              <FixedField horizontal label="Modelo" value={modeloVeic} />
              <FixedField horizontal label="Ano" value={anoVeic} />
              <FixedField
                horizontal
                label="Chassi"
                value={
                  <>
                    {chassiVeic}{" "}
                    {(chassiVeic ?? "").length > 0 && (
                      <IconButton
                        size={12}
                        icon={BiCopy}
                        onClick={() => {
                          navigator.clipboard.writeText(chassiVeic);
                          toastr.info("", "Chassi copiado.");
                        }}
                      />
                    )}
                  </>
                }
              />
            </Row>
          )}
        </Card>
      )}
      <Card body>
        <IncluirProdOrcamentoForm
          handleAddItem={handleAddItem}
          handleSubstituirItem={handleSubstituirItem}
          adicionaItemGrid={adicionaItemGrid}
          verificarItemJaIncluido={verificarItemJaIncluido}
          utilizaObservItem={utilizaObservItem}
        />
        <OrcamentoItensGrid
          data={itens}
          handleDelete={handleDeleteItem}
          recalcularValoresGrade={recalcularValoresGrade}
          utilizaObservItem={utilizaObservItem}
        />
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Total Produtos"
          value={sumDataField(
            itens.filter((e) => e.tipo === "P"),
            "vlr_total"
          )}
        />
        <CardTotaisItem
          label="Servicos"
          value={sumDataField(
            itens.filter((e) => e.tipo === "S"),
            "vlr_total"
          )}
        />
        <CardTotaisItem
          label="Desconto"
          value={sumDataField(itens, "vlr_tot_desc")}
        />
        <CardTotaisItem
          label="Geral"
          value={sumDataField(itens, "vlr_total")}
        />
      </CardTotais>
      <Card body>
        <Divider className="mt-1">Pagamento</Divider>
        <Row>
          <ComboBox
            md={3}
            label="Forma de Pagamento"
            options={formasPag}
            defaultValue={formaPagto}
            onChange={(s) => handleSetFormaPagto(s?.value)}
          />
          {!["DIN"].includes(formaPagto) && formaPagto && (
            <AsyncComboBox
              md={3}
              label="Condição de Pagamento"
              isConcatField
              concatModelName="cond_pag"
              defaultOptions
              isSearchable
              defaultValue={idCondPag}
              onChange={(s) => setIdCondPag(s?.value ?? null)}
            />
          )}
        </Row>
      </Card>
      <Card body>
        <Row>
          <TextInput
            name="obs"
            label="Observação"
            type="textarea"
            inputStyle={{ resize: "none" }}
            rows={4}
            md={8}
            value={obs}
            onChange={(e, v) => setObs(v)}
          />
          <FormButton
            md="auto"
            color="success"
            divClassName="ml-auto mt-auto"
            onClick={handleSubmit}
            loading={loading}
          >
            F9 - Confirmar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
