import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { Card, Col, FormGroup, Label, Row } from "reactstrap";
import { AsyncComboBox, FormButton } from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import CashbackService from "../../../../services/cashback/CashbackService";
import FiltersServiceV2 from "../../../../services/FiltersServiceV2";
import ModalCadastro from "./ModalCadastro";
import PermissoesTable from "./PermissoesTable";

class CadastroUsoCashbackPage extends Component {
  state = {
    permissoes: [],
    isExcluding: false,
    tipo_uso: "GRU",
    id_uso: null,
  };
  select = React.createRef();

  notify = () => {
    CashbackService.fetchPermissoes().then((data) => {
      this.setState({ permissoes: data });
    });
  };

  handleLoad = (value) => {
    const { tipo_uso } = this.state;
    if (value?.length > 1 && tipo_uso !== null) {
      return new Promise((resolve) => {
        if (tipo_uso === "GRU") {
          FiltersServiceV2.fetch(
            "grupo",
            `nome__icontains=${value.toUpperCase()}`
          ).then((data) => {
            resolve(data);
          });
        } else if (tipo_uso === "FAB") {
          FiltersServiceV2.fetch(
            "fabricante",
            `nome__icontains=${value.toUpperCase()}`
          ).then((data) => {
            resolve(data);
          });
        } else {
          FiltersServiceV2.fetch(
            "produto",
            `nome__icontains=${value.toUpperCase()}`
          ).then((data) => {
            resolve(data);
          });
        }
      });
    } else {
      return [];
    }
  };

  componentDidMount() {
    CashbackService.fetchPermissoes().then((data) => {
      this.setState({ permissoes: data });
    });
  }

  handleSelect = (selected, target) => {
    if (target.name === "tipo_uso") selected = selected.value;
    this.setState({ [target.name]: selected });

    if (target.name === "tipo_uso") {
      this.select.current.clearValue();
    }
  };

  handleTableSelect = (id) => {
    this.setState({ id_uso: id });
  };

  toggle = () => {
    this.modalCadastro.toggle();
  };

  excludeItem = async () => {
    const { id_uso } = this.state;
    this.setState({ isExcluding: true });
    await CashbackService.removePermission(id_uso)
      .then(() => {
        toastr.info("Exclusão", "O registro foi removido com sucesso.");
        this.notify();
      })
      .catch((err) => {
        toastr.error("Ops!", err);
      });
    this.setState({ isExcluding: false, id_uso: null });
  };

  render() {
    const { permissoes, isExcluding, id_uso } = this.state;
    const OPTIONS = [
      { label: "GRUPO", value: "GRU" },
      { label: "FABRICANTE", value: "FAB" },
      { label: "PRODUTO", value: "PRO" },
    ];

    return (
      <PageContainer
        title="Cadastro de Permissões de Uso de Cashback"
        number="0008"
        canGoBack
      >
        <ModalCadastro
          ref={(ref) => {
            this.modalCadastro = ref;
          }}
          notify={this.notify}
        />
        <Card body>
          <Row form>
            <Col md={2}>
              <FormGroup>
                <Label>Tipo</Label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="tipo_uso"
                  onChange={this.handleSelect}
                  options={OPTIONS}
                  defaultValue={OPTIONS[0]}
                />
              </FormGroup>
            </Col>
            <AsyncComboBox
              md={4}
              label="Identificador"
              loadOptions={this.handleLoad}
              onChange={this.handleSelect}
              name="id_uso"
              isSearchable
              isClearable
              ref={this.select}
            />
            <FormButton onClick={this.toggle} color="success">
              Adicionar
            </FormButton>
            <FormButton
              onClick={this.excludeItem}
              color="danger"
              loading={isExcluding}
              disabled={!id_uso}
            >
              Excluir
            </FormButton>
          </Row>
        </Card>
        <PermissoesTable
          items={permissoes}
          handleSelect={this.handleTableSelect}
          selectedItem={id_uso}
        />
      </PageContainer>
    );
  }
}

export default CadastroUsoCashbackPage;
