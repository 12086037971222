import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  LinkButton,
  PageContainer,
  RadioGroup,
} from "../../../../components";
import { estoqueRotaRoutes } from "../../../../routes/modules/pronta_entrega";
import { LancAjusteEstoqueModal } from "./components/LancAjusteEstoqueModal";
import { ImprimirResumoModal } from "./components/ImprimirResumoModal";
import { ListagemProdutosGrid } from "./components/ListagemProdutosGrid";
import { apiGetV2 } from "../../../../apiV2";
import { RadioItem } from "../../../../components/RadioGroup";
import UnidadesEstoqueService from "../../../../services/pronta_entrega/UnidadesEstoqueService";
import { toastr } from "react-redux-toastr";

const visaoOptions = [
  { label: "Estoque ADM", value: "ADM" },
  { label: "Estoque Cadastral", value: "CAD" },
  { label: "Estoque Intermediário", value: "INTER" },
  { label: "Estoque das Rotas", value: "ROTA" },
];

export const UnidadesEstoque = () => {
  const [visao, setVisao] = useState(visaoOptions[0].value);
  const [dados, setDados] = useState([]);
  const [idProduto, setIdProduto] = useState(null);
  const [idRota, setIdRota] = useState(null);
  const [idEstoqueIntermediario, setIdEstoqueIntermediario] = useState(null);
  const [loading, setLoading] = useState(false);
  const [
    utilizaEstoqueRealAdmProntaEntrega,
    setUtilizaEstoqueRealAdmProntaEntrega,
  ] = useState(false);

  const limparDados = () => {
    setDados([]);
  };

  const carregarDados = async () => {
    setLoading(true);

    try {
      const params = { id_produto: idProduto };

      if (["ADM", "CAD"].includes(visao)) {
        let funcao;
        if (visao === "CAD") {
          funcao = UnidadesEstoqueService.listarEstoqueCadastral;
        } else {
          funcao = UnidadesEstoqueService.listarEstoqueAdm;
        }

        const [ok, ret] = await funcao(params);
        setDados(ok ? ret : []);
      }
      if (visao === "INTER") {
        if ([0, null, undefined].includes(idEstoqueIntermediario)) {
          toastr.warning(
            "Atenção",
            "Por favor, selecione o estoque intermediário e tente novamente"
          );
          return;
        }

        const [ok, ret] = await UnidadesEstoqueService.listarEstoqueInter(
          idEstoqueIntermediario,
          params
        );
        setDados(ok ? ret : []);
      }
      if (visao === "ROTA") {
        if ([0, null, undefined].includes(idRota)) {
          toastr.warning(
            "Atenção",
            "Por favor, selecione a rota e tente novamente"
          );
          return;
        }

        const [ok, ret] = await UnidadesEstoqueService.listarEstoqueRotas(
          idRota,
          params
        );
        setDados(ok ? ret : []);
      }
    } finally {
      setLoading(false);
    }
  };

  const buscarParams = async () => {
    const [ok, ret] = await apiGetV2("tela/lanc_est_pronta_entrega/");
    if (ok) {
      setUtilizaEstoqueRealAdmProntaEntrega(
        ret.utiliza_estoque_real_adm_pronta_entrega
      );
    }
  };

  useEffect(() => {
    buscarParams();
  }, []);

  useEffect(() => {
    limparDados();
  }, [visao]);

  useEffect(() => {
    if (utilizaEstoqueRealAdmProntaEntrega) {
      setVisao("ADM");
    } else {
      setVisao("CAD");
    }
  }, [utilizaEstoqueRealAdmProntaEntrega]);

  const visoesDisp = utilizaEstoqueRealAdmProntaEntrega
    ? visaoOptions
    : visaoOptions.filter((e) => e.value !== "ADM");

  return (
    <PageContainer title="Unidades de Estoque" number="0085" canGoBack>
      <Card body>
        <Row>
          <RadioGroup label="Visão" value={visao} onChange={setVisao}>
            {visoesDisp.map((e) => (
              <RadioItem label={e.label} value={e.value} />
            ))}
          </RadioGroup>
          {visao === "ROTA" && (
            <AsyncComboBox
              isClearable
              isConcatField
              isSearchable
              concatModelName="rota_mob"
              md={4}
              label="Rota"
              onChange={(selected) => setIdRota(selected?.value)}
              defaultOptions
            />
          )}
          {visao === "INTER" && (
            <AsyncComboBox
              key="estoque_inter"
              md={3}
              label="Estoque Intermediário"
              isSearchable
              isClearable
              isConcatField
              defaultOptions
              concatModelName="rota_estoque_inter"
              onChange={(s) => setIdEstoqueIntermediario(s?.value)}
              hideShortcut
            />
          )}
        </Row>
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            isSearchable
            concatModelName="produto"
            md={4}
            label="Produto"
            onChange={(selected) => setIdProduto(selected?.value)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          {["CAD", "ADM"].includes(visao) && <ImprimirResumoModal />}
          <LancAjusteEstoqueModal />
          <LinkButton pathname={estoqueRotaRoutes.path} md="auto" color="info">
            Estoque das Rotas
          </LinkButton>
        </Row>
      </Card>
      <Card body>
        <ListagemProdutosGrid dados={dados} visao={visao} />
      </Card>
    </PageContainer>
  );
};
