import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  idNfe: null,
  finalidadeOperTipoDoc: "VENDA",
  formaPag: null,
  descFormaPag: "",
  idCliente: null,
  nomeCliente: null,
  dadosDest: {
    curinga: false,
    cpfCnpj: null,
  },
  infoAdic: "",
  totaisNfe: {
    baseCalcIcms: 0,
    vlrTotIcms: 0,
    vlrTotProd: 0,
    vlrTotNf: 0,
  },
};

export const cabNFCons = createSlice({
  name: "cab_nf_cons",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setIdNfe: (state, action) => {
      state.idNfe = action.payload;
    },
    setIdCliente: (state, action) => {
      state.idCliente = action.payload;
    },
    setNomeCliente: (state, action) => {
      state.nomeCliente = action.payload;
    },
    setInfoAdic: (state, action) => {
      state.infoAdic = action.payload;
    },
    setClienteCuringa: (state, action) => {
      state.dadosDest.curinga = action.payload;
    },
    setCpfCnpjDestinatario: (state, action) => {
      state.dadosDest.cpfCnpj = action.payload;
    },
    setFinalidadeOperTipoDoc: (state, action) => {
      state.finalidadeOperTipoDoc = action.payload;
    },
    setFormaPag: (state, action) => {
      state.formaPag = action.payload;
    },
    setDescFormaPag: (state, action) => {
      state.descFormaPag = action.payload;
    },
    setTotaisNfe: (state, action) => {
      state.totaisNfe = { ...state.totaisNfe, ...action.payload };
    },
  },
});

export const {
  init,
  setup,
  setIdCliente,
  setNomeCliente,
  setInfoAdic,
  setClienteCuringa,
  setCpfCnpjDestinatario,
  setIdNfe,
  setFinalidadeOperTipoDoc,
  setObrigInfoOrdemCompraNfeDestinatario,
  setFormaPag,
  setDescFormaPag,
  setTotaisNfe,
} = cabNFCons.actions;

export default cabNFCons.reducer;
