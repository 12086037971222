import React from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  IntegerFormInput,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { useState } from "react";
import { TransferenciaVendaGrid } from "./components/TransferenciaVendaGrid";
import { TransfVendaItensGrid } from "./components/TransfVendaItensGrid";
import { TransferirVendaModal } from "./components/TransferirVendaModal";
import TransferenciaVendaService from "../../../../services/faturamento/TransferenciaVendaService";

export const TransferenciaVenda = () => {
  const [idCliente, setIdCliente] = useState(null);
  const [numVenda, setNumVenda] = useState(null);
  const [idVendedor, setIdVendedor] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [itens, setItens] = useState([]);
  const [empresaDestino, setEmpresaDestino] = useState({});
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      id_cliente: idCliente,
      data_ini: dataIni,
      data_fim: dataFim,
      num_venda: numVenda,
      id_vendedor: idVendedor,
    };
    setLoading(true);
    const [ok, ret] = await TransferenciaVendaService.listarVendasFaturar(
      params
    );
    if (ok) {
      const vendas = ret.vendas;
      setDados(vendas);
      setItens(vendas.length > 0 ? vendas[0].itens : []);
      setEmpresaDestino(ret.empresa_destino);
    } else {
      setDados([]);
      setItens([]);
      setEmpresaDestino({});
    }
    setLoading(false);
  };

  const handleSelect = (v, is, row) => {
    setSelected(v ?? null);
    setItens(is ? row.itens : []);
  };

  return (
    <PageContainer
      title="Transferência de Venda Entre Empresas"
      number="0082"
      canGoBack
    >
      <Card body>
        <Row>
          <IntegerFormInput
            md={2}
            label="Nº Venda"
            defaultValue={numVenda}
            onChange={setNumVenda}
          />
          <AsyncComboBox
            md={4}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
          />
          <AsyncComboBox
            md={3}
            label="Vendedor"
            isConcatField
            concatModelName="colaborador"
            isSearchable
            isClearable
            onChange={(s) => setIdVendedor(s?.value)}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <TransferirVendaModal
            selected={selected}
            notifyEvent={carregarDados}
            empresaDestino={empresaDestino}
          />
        </Row>
      </Card>
      <Card body>
        <TransferenciaVendaGrid dados={dados} onSelect={handleSelect} />
      </Card>
      <Card body>
        <TransfVendaItensGrid itens={itens} />
      </Card>
    </PageContainer>
  );
};
