import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { formatNumber } from "../../../../../coreUtils";

const _columns = [
  {
    dataField: "descricao",
    text: "Plano de Contas",
    align: "left",
    headerAlign: "left",
    sort: true,
  },
  {
    dataField: "operacao",
    text: "Tipo",
    align: "center",
    headerAlign: "center",
    sort: false,
    formatter: (v) => (v === "D" ? "Decréscimo" : "Acréscimo"),
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    sort: false,
    formatter: (v) => formatNumber(v, true, 2),
  },
];

export const GridMovimentosDescAcre = ({ movimentos = [] }) => {
  return (
    <BootstrapTable
      columns={_columns}
      data={movimentos ?? []}
      keyField="id_venda"
      noWrap
      bordered={false}
      classes="w-auto p-0 mn-100 nowrap mt-2"
      bootstrap4
    />
  );
};
