import React, { useState } from "react";
import { Table } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import { ModalDetalhesIcone } from "../../../../../components/ModalDetalhesIcone";
import { formatNumber } from "../../../../../coreUtils";
import ComissaoFixaService from "../../../../../services/relatorios/comissionamento/ComissaoFixaService";
import { Row, Col } from "reactstrap";

const columns = [
  {
    dataField: "produto",
    text: "# Produto",
    align: "center",
  },
  {
    dataField: "descricao",
    text: "Nome",
    align: "left",
  },
  {
    dataField: "qtd_vend",
    text: "Qtd. Vendida",
    align: "right",
    formatter: (v) => formatNumber(v, true, 2, true),
  },
  {
    dataField: "vlr_tot_vend_prod",
    text: "Total Vendido",
    align: "right",
    formatter: (v) => formatNumber(v, true, 2),
  },
  {
    dataField: "perc_comis",
    text: "% Comissão",
    align: "right",
    formatter: (v) => formatNumber(v, true, 2),
  },
  {
    dataField: "vlr_comissao",
    text: "Valor Comissão",
    align: "right",
    formatter: (v) => formatNumber(v, true, 2),
  },
];

export const DetComissaoFixaModal = ({ dataIni, dataFim, index, data }) => {
  const [dados, setDados] = useState([]);
  const [totalVendas, setTotalVendas] = useState(0);
  const [totalFrete, setTotalFrete] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const buscarDados = async () => {
    const [ok, res] = await ComissaoFixaService.buscarDetalhamentoComiss(
      data.id_cab__id_colab_vendedor,
      dataIni,
      dataFim
    );
    if (ok) {
      setDados(res.itens);
      setTotalVendas(parseFloat(res.total_venda));
      setTotalFrete(parseFloat(res.total_frete));
    }
  };

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
    } else {
      setDados([]);
      setTotalVendas(0);
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalDetalhesIcone id={index} toggle={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title={
          <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            Detalhamento de Comissão: {data.nome_colab}
          </h5>
        }
        number="5021_1"
        autoFocus
        hideCancelButton
      >
        <Row style={{ justifyContent: "space-between" }}>
          <Col>
            <h5>
              Total de Vendas:{" "}
              <strong>
                <span>{formatNumber(totalVendas, true, 2)}</span>
              </strong>
            </h5>
          </Col>
          <Col>
            <h5 style={{ textAlign: "right" }}>
              Valor de Frete (não é comissionado):{" "}
              <strong>
                <span>{formatNumber(totalFrete, true, 2)}</span>
              </strong>
            </h5>
          </Col>
        </Row>
        <Table
          columns={columns}
          data={dados}
          keyField="produto"
          paginated={false}
          pageSize={15}
        />
      </ModalBase>
    </>
  );
};
