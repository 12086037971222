import React, { useState } from "react";
import { formatNumber, formatValueFromAPI } from "../../../../../../coreUtils";
import {
  FormButton,
  NumberInput,
  SearchInput,
  Table,
  TableDelete,
} from "../../../../../../components";
import { Row, UncontrolledTooltip } from "reactstrap";
import { IncluirDetalheMetaModal } from "./IncluirDetalheMetaModal";

export const DetalhesMetaBaseadoVendaGrid = ({
  dados,
  handleDelete,
  handleEditDetalhe,
  atualizarValoresItens,
  pista,
  handlePista,
  loadingPista,
  handleAumentarValorPorPerc,
  handleAddMov,
}) => {
  const columns = [
    {
      dataField: "desc_tipo",
      text: "Meta Para",
      align: "center",
    },
    {
      dataField: "nome_tipo",
      text: "Descrição",
      align: "left",
      cellContentTag: null,
      formatter: (c, row) => {
        if (row.qtd_venda_periodo_base === 0) {
          const id = `sv-${row.id}`;
          return (
            <>
              <span style={{ color: "red" }} id={id}>
                {formatValueFromAPI(c, row.id_tipo)}
              </span>
              <UncontrolledTooltip target={id}>
                Produto sem vendas no período base
              </UncontrolledTooltip>
            </>
          );
        } else {
          return <span>{formatValueFromAPI(c, row.id_tipo)}</span>;
        }
      },
    },
    {
      dataField: "preco_tipo",
      text: "Preço de Venda",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "qtd_venda_periodo_base",
      text: "Qtd. Venda na Apuração",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_faturado_periodo_base",
      text: "Vlr. Venda na Apuração",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "meta_qtd_venda",
      text: "Meta de Quantidade de Venda",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      onChange: handleEditDetalhe,
      onBlur: atualizarValoresItens,
      editable: true,
      editorType: "number",
      decimalPlaces: 0,
      alwaysShowEditor: true,
    },
    {
      dataField: "perc_diferenca",
      text: "% da Meta",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true) + " %",
      onChange: handleEditDetalhe,
      onBlur: atualizarValoresItens,
      editable: (c, row) => parseFloat(row.qtd_venda_periodo_base) > 0,
      editorType: "number",
      alwaysShowEditor: true,
    },
    {
      dataField: "meta_vlr_faturado",
      text: "Meta de Valor de Vendas",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "act",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  const [percAumentarValorMeta, setPercAumentarValorMeta] = useState(0);

  return (
    <>
      <Row className="mb-3">
        <SearchInput
          value={pista}
          md={6}
          onChange={handlePista}
          loading={loadingPista}
          placeholder="Nome do Produto"
        />
        {handleAumentarValorPorPerc && (
          <>
            <NumberInput
              isPercentage
              label={
                <>
                  Aumentar <b>toda</b> meta em %
                </>
              }
              md={2}
              divClassName="ml-auto"
              onChange={setPercAumentarValorMeta}
              value={percAumentarValorMeta}
            />
            <FormButton
              md="auto"
              color="primary"
              onClick={() => handleAumentarValorPorPerc(percAumentarValorMeta)}
              divClassName="mr-auto"
            >
              Calcular
            </FormButton>
          </>
        )}
        <IncluirDetalheMetaModal movs={dados} handleAdd={handleAddMov} />
      </Row>
      <Table
        columns={columns}
        data={dados ?? []}
        keyField="id"
        style={{
          height: "300px",
          overflowX: "hidden",
        }}
        paginated={false}
      />
    </>
  );
};
