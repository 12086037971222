import React from "react";
import { Table, TableCheck } from "../../../../../components";
import { Card } from "reactstrap";

export const ControleAcessoGrid = ({ dados, liberarBloquearAcesso }) => {
  const columns = [
    { dataField: "nome_perfil", text: "Perfil", align: "left" },
    { dataField: "modulo", text: "Módulo", align: "left" },
    { dataField: "descricao", text: "Nome", align: "left" },
    { dataField: "nro_tela", text: "Nº Tela", align: "center" },
    {
      dataField: "liberado",
      text: "Liberado",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableCheck value={c} onClick={() => liberarBloquearAcesso(row)} />
      ),
    },
  ];

  return (
    <Card body>
      <Table data={dados} columns={columns} />
    </Card>
  );
};
