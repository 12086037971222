import { api } from "../../api";

class CashbackService {
  static async fetchPermissoes() {
    return new Promise(async (resolve, reject) => {
      await api.get("venda/cashback/permissoes/resumo/").then(({ data }) => {
        if (!data.err) {
          resolve(data.res);
        } else {
          reject(`${data.msg} - Erro: ${data.id_err}`);
        }
      });
    });
  }

  static async addNewPermission(permission) {
    return new Promise(async (resolve, reject) => {
      await api
        .post("venda/cashback/permissoes/adicionar/", permission)
        .then(({ data }) => {
          if (!data.err) {
            resolve();
          } else {
            reject(`${data.msg} - Erro: ${data.id_err}`);
          }
        });
    });
  }

  static async removePermission(id) {
    return new Promise(async (resolve, reject) => {
      await api
        .delete(`venda/cashback/permissoes/remover/${id}/`)
        .then(({ data }) => {
          if (!data.err) {
            resolve();
          } else {
            reject(`${data.msg} - Erro: ${data.id_err}`);
          }
        });
    });
  }
}

export default CashbackService;
