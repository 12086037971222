import { apiGet } from "../api";
import { isArray } from "../coreUtils";

const CumulativoVendasService = {
  fetch: async (dataIni, dataFim, grupo, vendedor) => {
    const params_ = {
      data_ini: dataIni,
      data_fim: dataFim,
    };

    if (
      isArray(vendedor)
        ? vendedor.length > 0
        : ![null, undefined].includes(vendedor)
    ) {
      params_["vendedor"] = `[${vendedor}]`;
    }

    if (grupo?.length > 0) params_["grupo"] = `[${grupo}]`;

    const searchParams = new URLSearchParams(params_).toString();

    return await apiGet(
      `/relatorios/venda/cumulativo_vendas_por_vendedor/?${searchParams}`
    );
  },
};

export default CumulativoVendasService;
