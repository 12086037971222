import React from "react";
import { ModalDetalhes } from "../../../../../components/ModalDetalhes";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "item",
    text: "item",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "tipo_item",
    text: "Tipo",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "descricao",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_tipo),
  },
  {
    dataField: "quantidade",
    text: "Quant.",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_unit",
    text: "Vlr. Unit",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_custo",
    text: "Custo Unit.",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_item",
    text: "Vlr. Item",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "perc_desc",
    text: "% Desc.",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_desc",
    text: "Vlr. Desc.",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_flex",
    text: "Vlr. Flex",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_icms_st",
    text: "ICMS ST",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_ipi",
    text: "IPI",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const DetCustoVsPrecoVendaModal = ({ id, itens }) => {
  return (
    <ModalDetalhes
      title={`Detalhamento da Venda ${id}`}
      data={itens}
      columns={columns}
    />
  );
};
