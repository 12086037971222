import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  FiltroPeriodoDatas,
  IntegerFormInput,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { GerencOrcamentosGrid } from "./components/GerencOrcamentosGrid";
import { RejeitarOrcamentoModal } from "./components/RejeitarOrcamentoModal";
import OrcamentosService from "../../../../services/orcamentos/OrcamentosService";
import { DetalhesOrcamentoModal } from "./components/DetalhesOrcamentoModal";
import FrenteVendaService from "../../../../services/vendas/FrenteVendaService";
import { roundFloat } from "../../../../coreUtils";
import UteisService from "../../../../services/uteis/UteisService";
import { apiGetV2 } from "../../../../apiV2";

const mostrarOptions = [
  { label: "Todos", value: "TOD" },
  { label: "Já transformados em venda", value: "TEV" },
  { label: "Não transformados em venda", value: "ABE" },
  { label: "Rejeitados/Não Aprovados", value: "NAO" },
];

export const GerencOrcamentos = () => {
  const [idOrcamento, setIdOrcamento] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [idVendedor, setIdVendedor] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [mostrar, setMostrar] = useState(mostrarOptions[2].value);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [rejeitarOpen, setRejeitarOpen] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      id_orcamento: idOrcamento,
      id_cliente: idCliente,
      id_vendedor: idVendedor,
      data_ini: dataIni,
      data_fim: dataFim,
      mostrar: mostrar,
    };

    setLoading(true);
    const [ok, ret] = await OrcamentosService.gerenciamento.listar(params);
    setDados(
      ok
        ? ret.map((e) =>
            e.aberto === false && e.status !== "N" ? { ...e, status: "TV" } : e
          )
        : []
    );
    setLoading(false);
  };

  const toggleRejeitar = () => setRejeitarOpen(!rejeitarOpen);

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const rejeitar = async (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleRejeitar();
    }, 1);
  };

  const detalhes = async (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  const importOrcamentoNaFrenteVenda = async (idOrcamento) => {
    let simplesNacional;
    const [ok, ret] = await apiGetV2("/tela/frente_venda/");
    if (ok) {
      simplesNacional = ret.regime_tribut === "Simples Nacional";
    } else {
      return false;
    }
    const [ok1, ret1] = await OrcamentosService.buscar(idOrcamento);
    if (ok1) {
      const idCli = ret1.id_cliente;
      const payloadCab = {
        id_cliente: idCli,
        nome_cliente: ret1.id_cliente__nome ?? "",
        vlr_frete: parseFloat(ret1.vlr_frete ?? "0") ?? 0,
        id_orcamento: idOrcamento,
        cpf_cnpj_cliente: ret1.id_cliente__cpf_cnpj,
        id_premiado: ret1.id_premiado,
      };

      let idVenda;

      const [ok2, ret2] = await FrenteVendaService.incluirCab(
        payloadCab,
        false
      );
      if (ok2) {
        idVenda = ret2.id_frente_venda_salva_cab;
      } else {
        return false;
      }

      const payloadFrenteVda = {
        ...payloadCab,
        idVenda: idVenda,
        observ: ret1.observ ?? "",
      };

      for (const item of ret1.itens) {
        const idProdServ = item.id_item;
        const qtd = parseFloat(item.quantidade ?? 0);
        const vTotProd = parseFloat(item.vlr_total);

        const vlrUnit = parseFloat(item.vlr_unit);

        const vTotBruto = vlrUnit * qtd;
        let vAcre = parseFloat(item.vlr_tot_acre ?? 0);

        if (vAcre === 0 && roundFloat(vTotProd, 2) > roundFloat(vTotBruto, 2)) {
          vAcre = roundFloat(vTotProd - vTotBruto, 2);
        }

        let payload = {
          id_cab: idVenda,
          tipo: item.tipo,
          id_prod_serv: idProdServ,
          descricao: item.descricao,
          unidade: item.unidade,
          id_ambiente: item.id_ambiente,
          quantidade: qtd,
          vlr_unit: parseFloat(item.vlr_unit ?? 0),
          vlr_item: parseFloat(item.vlr_item ?? 0),
          perc_desc: parseFloat(item.perc_desc ?? 0),
          vlr_tot_prod_serv: vTotProd,
          vlr_total: vTotProd,
          vlr_desc: parseFloat(item.vlr_tot_desc ?? 0),
          vlr_acre: vAcre,
          perc_ipi: 0,
          vlr_ipi: 0,
          vlr_icms_st: 0,
          vlr_icms_st_unit: 0,
          observ: item.observ ?? "",
        };

        if (item.tipo === "P" && !simplesNacional) {
          const trib = await UteisService.calcularImpostosProduto(
            idProdServ,
            idCli,
            "VENDA",
            55,
            true,
            "", // cfop?
            qtd,
            vTotProd,
            0
          );

          if (trib && Object.keys(trib).length > 0) {
            const vIcmsSt = parseFloat(trib.vlr_icms_st);
            const vIpi = parseFloat(trib.vlr_ipi);
            const pIpi = parseFloat(trib.perc_ipi);
            const vIcmsStUnit = roundFloat(vIcmsSt / qtd, 2);
            const vTotal = parseFloat(trib.vlr_total_com_impostos);

            payload = {
              ...payload,

              perc_ipi: pIpi,
              vlr_ipi: vIpi,
              vlr_icms_st: vIcmsSt,
              vlr_icms_st_unit: vIcmsStUnit,
              vlr_total: vTotal,
            };
          } else {
            break;
          }
        }

        const [ok3] = await FrenteVendaService.incluirItem(payload, false);
        if (!ok3) {
          break;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <PageContainer title="Gerenciamento de Orçamentos" number="0083">
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
            hideShortcut
          />
          <IntegerFormInput
            md={2}
            label="Nº Orçamento"
            value={idOrcamento}
            onChange={setIdOrcamento}
          />
          <ComboBox
            md={3}
            label="Mostrar"
            options={mostrarOptions}
            onChange={(s) => setMostrar(s?.value)}
            defaultValue={mostrar}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={4}
            isSearchable
            isClearable
            isConcatField
            label="Vendedor"
            concatModelName="colaborador"
            hideShortcut
            onChange={(s) => setIdVendedor(s?.value)}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <DetalhesOrcamentoModal
            isOpen={detalhesOpen}
            toggle={toggleDetalhes}
            selected={selected}
          />
          <RejeitarOrcamentoModal
            isOpen={rejeitarOpen}
            toggle={toggleRejeitar}
            selected={selected}
            notifyEvent={() => {
              carregarDados();
              setSelected(null);
            }}
          />
        </Row>
      </Card>
      <Card body>
        <GerencOrcamentosGrid
          dados={dados}
          setSelected={setSelected}
          rejeitar={rejeitar}
          detalhes={detalhes}
        />
      </Card>
    </PageContainer>
  );
};
