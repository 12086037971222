import React, { useState } from "react";
import {
  AsyncComboBox,
  FormButton,
  LabelButton,
  PageContainer,
  TextInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { EntradaNfXmlGrid } from "./components/EntradaNfXmlGrid";
import EntradaNfXmlService from "../../../../services/compras/EntradaNfXmlService";
import { useHistory } from "react-router-dom";
import { produtosEntradaNfXmlRoute } from "../../../../routes/modules/compras";
import { ProgressoEntradaNfXml } from "./components/ProgressoEntradaNfXml";
import { ConsultaChaveAcessoModal } from "./components/ConsultaChaveAcessoModal";
import { ImportarArquivoXmlModal } from "./components/ImportarArquivoXmlModal";

export const EntradaNfXml = () => {
  const idUsuario = parseInt(localStorage.getItem("id_colaborador"));
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numeroNf, setNumeroNf] = useState(null);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [chaveAcesso, setChaveAcesso] = useState(null);

  const history = useHistory();

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await EntradaNfXmlService.listarNfs(
      numeroNf,
      idFornecedor,
      chaveAcesso
    );
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const prosseguirEntradaNf = async (id) => {
    const [ok] = await EntradaNfXmlService.gerarDadosItens(id);
    if (ok) {
      history.push(produtosEntradaNfXmlRoute.path, { selected: id });
    }
  };

  const naoImportar = async (idNf) => {
    const [ok] = await EntradaNfXmlService.naoImportar(idNf);
    if (ok) {
      carregarDados();
    }
  };

  const atualizarNotasJaImportadas = async () => {
    const [ok] = await EntradaNfXmlService.atualizarNotasJaImportadas();
    if (ok) {
      carregarDados();
    }
  };

  return (
    <PageContainer title="Entrada de Nota Fiscal via XML" number="0101">
      <Card body>
        <Row>
          <TextInput
            md={2}
            label="Número"
            value={numeroNf}
            onChange={(e, v) => setNumeroNf(v)}
          />
          <AsyncComboBox
            md={4}
            label="Fornecedor"
            name="fornecedor"
            isConcatField
            concatModelName="fornecedor"
            isSearchable
            isClearable
            onChange={(s) => setIdFornecedor(s?.value)}
          />
          <ProgressoEntradaNfXml md={4} />
        </Row>
        <Row>
          <TextInput
            md={4}
            label="Chave de Acesso"
            value={chaveAcesso}
            onChange={(e, v) => setChaveAcesso(v)}
            maxLength={44}
            inputStyle={{ textAlign: "center" }}
          />
          <FormButton
            md="auto"
            padded={true}
            color="primary"
            onClick={carregarDados}
            loading={loading}
          >
            Pesquisar
          </FormButton>
          <ConsultaChaveAcessoModal />
          <ImportarArquivoXmlModal prosseguirEntradaNf={prosseguirEntradaNf} />
        </Row>
      </Card>
      <Card body>
        <EntradaNfXmlGrid
          dados={dados}
          prosseguirEntradaNf={prosseguirEntradaNf}
          naoImportar={naoImportar}
        />
      </Card>
      {/* Esse botão abaixo deve aparecer SOMENTE para o usuário 0 - Skillsoft */}
      {idUsuario === 0 && (
        <LabelButton
          divClassName="mt-2"
          style={{ color: "#0e4826" }}
          showIcon={false}
          onClick={atualizarNotasJaImportadas}
        >
          Atualizar NF-es já importadas
        </LabelButton>
      )}
    </PageContainer>
  );
};
