import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

// Campo scheme aguarda lista de objetos seguindo o esquema:
// {value: "valor", hint: "descrição", color: "cor",}
export const ColorField = ({
  scheme,
  value,
  style,
  tooltipPlacement = "left",
  showHint = true,
}) => {
  const [id] = useState(Math.floor(Math.random() * Date.now()));
  return (
    <>
      <div
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "4px",
          marginLeft: "auto",
          marginRight: "auto",
          ...style,
          backgroundColor:
            scheme.find((e) => e.value === value)?.color ?? "white",
        }}
        id={`color-field-${id}`}
      />
      {showHint && (
        <UncontrolledTooltip
          placement={tooltipPlacement}
          target={`color-field-${id}`}
        >
          {value} - {scheme.find((e) => e.value === value)?.hint}
        </UncontrolledTooltip>
      )}
    </>
  );
};
