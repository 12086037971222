import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  DatePicker,
  Divider,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  ModalBase,
  NumberInput,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
  Table,
  TableDelete,
  TextInput,
  UnlockToEdit,
} from "../../../../../components";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { Row } from "reactstrap";
import {
  formatDateISO,
  formatDateLocal,
  formatNumber,
  formatValueFromAPI,
  localeDateToISO,
  sumDataField,
} from "../../../../../coreUtils";
import { showWarning } from "../../../../../components/AlertaModal";
import UteisService from "../../../../../services/uteis/UteisService";
import GerencContasPagarService from "../../../../../services/financeiro/GerencContasPagarService";
import { RadioItem } from "../../../../../components/RadioGroup";

export const IncluirTituloPagarModal = ({ notifyEvent }) => {
  const idUsuario = parseInt(localStorage.getItem("id_colaborador"));
  const idEmpresaUsu = parseInt(localStorage.getItem("id_empresa"));
  const [isOpen, setIsOpen] = useState(false);
  const [params, setParams] = useState({});
  const [idOrganizacao, setIdOrganizacao] = useState(null);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [competencia, setCompetencia] = useState(new Date());
  const [carteira, setCarteira] = useState(false);
  const [numero, setNumero] = useState("");
  const [qtdParcelas, setQtdParcelas] = useState(0);
  const [dataBase, setDataBase] = useState(new Date());
  const [diaFixo, setDiaFixo] = useState(true);
  const [numDias, setNumDias] = useState(30);
  const [observ, setObserv] = useState("");
  const [idPlanoContas, setIdPlanoContas] = useState(null);
  const [nomePlanoContas, setNomePlanoContas] = useState("");
  const [idCentroCusto, setIdCentroCusto] = useState(null);
  const [nomeCentroCusto, setNomeCentroCusto] = useState("");
  const [vlrPlanoContas, setVlrPlanoContas] = useState(0);
  const [planosContas, setPlanosContas] = useState([]);
  const [parcelas, setParcelas] = useState([]);
  const [loadingParcelas, setLoadingParcelas] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const utilizaCentroCusto = params?.utiliza_centro_custo;
  const utilizaOrganizacao = params?.utiliza_organizacao;

  const limparDados = () => {
    setParams({});
    setIdOrganizacao(null);
    setIdFornecedor(null);
    setCompetencia(new Date());
    setCarteira(false);
    setNumero("");
    setQtdParcelas(0);
    setDataBase(new Date());
    setDiaFixo(true);
    setNumDias(30);
    setObserv("");
    setIdPlanoContas(null);
    setNomePlanoContas("");
    setIdCentroCusto(null);
    setNomeCentroCusto("");
    setVlrPlanoContas(0);
    setPlanosContas([]);
    setParcelas([]);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSetPlanoContas = (v, s) => {
    setIdPlanoContas(v ?? null);
    setNomePlanoContas(s?.label ?? "");
  };

  const handleSetCentroCusto = (v, s) => {
    setIdCentroCusto(v ?? null);
    setNomeCentroCusto(s?.label ?? "");
  };

  const validarDadosParcelas = () => {
    let msg = "";
    if (!(competencia instanceof Date)) {
      msg = "Por favor, revise a Data da Primeira Competência.";
      return [false, msg];
    }
    if (["", null, undefined].includes(numero)) {
      msg = "Por favor, informe o Número do Título.";
      return [false, msg];
    }
    if ([0, null, undefined].includes(qtdParcelas)) {
      msg = "Por favor, informe a Quantidade de Parcelas.";
      return [false, msg];
    }
    if (!(dataBase instanceof Date)) {
      msg = "Por favor, revise a Data Base do cálculo das parcelas.";
      return [false, msg];
    }
    if (diaFixo) {
      if ([0, null, undefined].includes(numDias)) {
        msg = "Por favor, informe o Dia Fixo de vencimento das parcelas.";
        return [false, msg];
      }
    } else {
      if (
        qtdParcelas <= 1
          ? [null, undefined].includes(numDias)
          : [0, null, undefined].includes(numDias)
      ) {
        msg =
          "Por favor, informe o Intervalo de Dias entre os vencimentos das parcelas.";
        return [false, msg];
      }
    }

    return [true, msg];
  };

  const validarDadosTitulo = () => {
    if ([0, null, undefined].includes(idFornecedor)) {
      showWarning("Por favor, informe o Fornecedor.");
      return false;
    }
    const [ok, msg] = validarDadosParcelas();
    if (!ok) {
      showWarning(msg);
      return false;
    }
    return true;
  };

  const handleAddPlanoConta = () => {
    if (!validarDadosTitulo()) {
      return false;
    }
    if ([0, null, undefined].includes(idPlanoContas)) {
      showWarning("Por favor, informe o Plano de Contas");
      return false;
    }
    if (["", null, undefined].includes(nomePlanoContas)) {
      showWarning("Por favor, verifique o Plano de Contas");
      return false;
    }
    if (utilizaCentroCusto && [0, null, undefined].includes(idCentroCusto)) {
      showWarning("Por favor, informe o Centro de Custo");
      return false;
    }
    if (utilizaCentroCusto && ["", null, undefined].includes(nomeCentroCusto)) {
      showWarning("Por favor, verifique o Centro de Custo");
      return false;
    }
    if ([0, null, undefined].includes(vlrPlanoContas)) {
      showWarning("Por favor, informe o Valor a Pagar");
      return false;
    }

    const payload = {
      uuid: uuidv4(),
      id_plano_contas: idPlanoContas,
      nome_plano_contas: nomePlanoContas,
      id_centro_custo: idCentroCusto,
      nome_centro_custo: nomeCentroCusto,
      valor: vlrPlanoContas,
    };

    const verificarCorresp = (e) =>
      e.id_plano_contas === idPlanoContas &&
      e.id_centro_custo === idCentroCusto;

    const planoContaJaExiste = planosContas.findIndex(verificarCorresp) > -1;

    setPlanosContas(
      !planoContaJaExiste
        ? [...planosContas, payload]
        : planosContas.map((e) => (verificarCorresp(e) ? payload : e))
    );

    setIdPlanoContas(null);
    setNomePlanoContas("");
    setIdCentroCusto(null);
    setNomeCentroCusto("");
    setVlrPlanoContas(0);
  };

  const handleDeletePlanoConta = (uuid) =>
    setPlanosContas(planosContas.filter((e) => e.uuid !== uuid));

  const gerarParcelas = async (alterarTudo = false) => {
    const [okVal] = validarDadosParcelas();
    if (!okVal) {
      return;
    }

    const valorTotal = sumDataField(planosContas, "valor");
    if (valorTotal === 0) {
      setParcelas([]);
      return;
    }
    setLoadingParcelas(true);

    const payload = {
      qtd_parcelas: qtdParcelas,
      num_dias: numDias,
      dia_fixo: diaFixo,
      valor: valorTotal,
      data_base: formatDateISO(dataBase),
      data_competencia: formatDateISO(competencia),
    };

    const [ok, ret] = await UteisService.calcularParcelasPorQtdParcNumDias(
      payload
    );

    if (ok) {
      const parcelasAntes = [...parcelas];
      const parcelasDepois = ret.map((e, index) => ({
        sequenc: index + 1,
        numero: numero,
        data_competencia: formatDateLocal(e.data_comp),
        data_vcto: formatDateLocal(e.data_vcto),
        ...(!alterarTudo ? parcelasAntes[index] ?? {} : {}),
        valor: parseFloat(e.valor),
      }));

      setParcelas(parcelasDepois);
    }
    setLoadingParcelas(false);
  };

  useEffect(() => {
    gerarParcelas(true);
  }, [qtdParcelas, dataBase, competencia, diaFixo, numDias, planosContas]);

  const handleSetNumero = (v) => {
    setNumero(v);
    setParcelas(parcelas.map((e) => ({ ...e, numero: v })));
  };

  const alterarParcela = (coluna, novoValor, row) => {
    if (coluna === "numero") {
      row.numero = novoValor;
    }
    if (["data_vcto", "data_competencia"].includes(coluna)) {
      if (moment.isMoment(novoValor)) {
        row[coluna] = formatDateLocal(novoValor);
      } else {
        return false;
      }
    }
    if (coluna === "valor") {
      row.valor = parseFloat(novoValor);
    }

    setParcelas(parcelas.map((e) => (e.sequenc === row.sequenc ? row : e)));
    return true;
  };

  const handleSubmit = async () => {
    if (loadingParcelas) {
      return false;
    }

    if (utilizaOrganizacao && [0, null, undefined].includes(idOrganizacao)) {
      showWarning("Por favor, informe a Organização.");
      return false;
    }

    if (!validarDadosTitulo()) return false;

    if (planosContas.length === 0) {
      showWarning(
        "Não foram identificados valores para os Planos de Contas. " +
          "Por favor, revise as informações e tente novamente."
      );
      return false;
    }

    if (parcelas.length === 0) {
      showWarning(
        "As Parcelas do(s) título(s) não foram informadas. " +
          "Por favor, revise as informações e tente novamente."
      );
      return false;
    }

    const payload = {
      id_organizacao: idOrganizacao,
      id_colab: idUsuario,
      id_fornec: idFornecedor,
      origem: "IMT",
      id_origem: 0,
      empr_origem: idEmpresaUsu,
      data_emi: formatDateISO(new Date()),
      movs: parcelas.map((e) => ({
        numero: e.numero,
        sequenc: e.sequenc.toString(),
        data_vcto: localeDateToISO(e.data_vcto),
        data_competencia: localeDateToISO(e.data_competencia),
        vlr_orig: e.valor,
        vlr_desc: 0,
        vlr_outr_ent: 0,
        vlr_juro: 0,
        vlr_multa: 0,
        vlr_outr_sai: 0,
        vlr_pagar: e.valor,
        vlr_pago: 0,
        observ: observ,
        aberto: true,
        id_transacao: 61,
      })),
      pln_cc: planosContas.map((e) => ({
        id_plano_contas: e.id_plano_contas,
        id_centro_custo: e.id_centro_custo ?? 0,
        valor: e.valor,
      })),
    };
    setLoadingSubmit(true);
    const [ok] = await GerencContasPagarService.incluir(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoadingSubmit(false);
  };

  const onKeyDown = (e) => {
    if (e.key === "F8") {
      handleAddPlanoConta();
    }
  };

  const columnsPlanosContas = [
    {
      dataField: "nome_plano_contas",
      text: "Plano de Contas",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_plano_contas),
    },
    {
      dataField: "nome_centro_custo",
      text: "Centro de Custo",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_centro_custo),
      hidden: !utilizaCentroCusto,
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      dummy: true,
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDeletePlanoConta(row.uuid)} />
      ),
    },
  ];

  const columnsParcelas = [
    {
      dataField: "sequenc",
      text: "Parcela",
      align: "center",
      fixedColWidth: true,
      colWidth: "20%",
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarParcela,
      editorType: "text",
      fixedColWidth: true,
      colWidth: "20%",
    },
    {
      dataField: "data_competencia",
      text: "Competência",
      align: "center",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarParcela,
      editorType: "date",
      fixedColWidth: true,
      colWidth: "20%",
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarParcela,
      editorType: "date",
      fixedColWidth: true,
      colWidth: "20%",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarParcela,
      editorType: "number",
      fixedColWidth: true,
      colWidth: "20%",
    },
  ];

  return (
    <>
      <FormButton md="auto" color="info" onClick={toggle} padded={false}>
        Incluir Despesa
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Inclusão Manual de Títulos a Pagar"
        number="0091_2"
        onConfirm={handleSubmit}
        loadingConfirm={loadingSubmit}
        onKeyDown={onKeyDown}
        paramsName="inc_titulo_pagar"
        onBeforeOpen={(p) => setParams(p)}
        onClosed={limparDados}
      >
        {utilizaOrganizacao && (
          <Row>
            <AsyncComboBox
              md={6}
              label="Organização"
              isConcatField
              concatModelName="organizacao"
              defaultValue={idOrganizacao}
              onChange={(s) => setIdOrganizacao(s?.value)}
              defaultOptions
            />
          </Row>
        )}
        <Row>
          <AsyncComboBox
            md={6}
            label="Fornecedor"
            isConcatField
            concatModelName="fornecedor"
            defaultValue={idFornecedor}
            onChange={(s) => setIdFornecedor(s?.value)}
          />
          <DatePicker
            md={2}
            label={diaFixo ? "Data Base Competência" : "Competência 1ª Parcela"}
            value={competencia}
            onChange={(v) =>
              setCompetencia(moment.isMoment(v) ? v.toDate() : v)
            }
            tabIndex={100}
            hint="Data do Fato Gerador da Despesa"
          />
          <FormCheckbox
            label="Em Carteira"
            checked={carteira}
            onChange={() => setCarteira(!carteira)}
            tabIndex={101}
          />
        </Row>
        <Row>
          <TextInput
            md={2}
            label="Número"
            value={numero}
            onChange={(e, v) => handleSetNumero(v)}
            maxLength={12}
          />
          <IntegerFormInput
            md={2}
            label="Qtd. Parcelas"
            defaultValue={qtdParcelas}
            onChange={setQtdParcelas}
          />
          <RadioGroup
            label=" "
            value={diaFixo}
            onChange={(v) => setDiaFixo(v.toString() === "true")}
          >
            <RadioItem label="Dia Fixo" value={true} />
            <RadioItem label="Intervalo de Dias" value={false} />
          </RadioGroup>
          <IntegerFormInput
            md={2}
            label={diaFixo ? "Dia Fixo" : "Intervalo de Dias"}
            defaultValue={numDias}
            onChange={setNumDias}
          />
          <UnlockToEdit>
            <DatePicker
              md={2}
              label="Data Base"
              value={dataBase}
              onChange={(v) => setDataBase(moment.isMoment(v) ? v.toDate() : v)}
            />
          </UnlockToEdit>
        </Row>
        <Row className="mb-1">
          <PesqPlanoCtaCentroCusto
            md={6}
            label="Plano Contas"
            mode="plano_contas"
            value={idPlanoContas}
            onChange={handleSetPlanoContas}
          />
          {utilizaCentroCusto && (
            <PesqPlanoCtaCentroCusto
              md={6}
              label="Centro Custo"
              mode="centro_custo"
              value={idCentroCusto}
              onChange={handleSetCentroCusto}
            />
          )}
        </Row>
        <Row>
          <NumberInput
            md={2}
            label="Valor"
            value={vlrPlanoContas}
            onChange={setVlrPlanoContas}
          />
          <FormButton md="auto" color="info" onClick={handleAddPlanoConta}>
            F8 - Inserir
          </FormButton>
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Observação"
            value={observ}
            onChange={(e, v) => setObserv(v)}
            maxLength={100}
          />
        </Row>
        <Divider>Planos de Contas</Divider>
        <Table
          data={planosContas}
          columns={columnsPlanosContas}
          keyField="uuid"
          paginated={false}
          showRegisterCount={false}
          pageSize={planosContas.length > 5 ? 5 : planosContas.length || 2}
        />
        <Divider>Parcelas</Divider>
        <Table
          data={parcelas}
          columns={columnsParcelas}
          keyField="sequenc"
          paginated={false}
          showRegisterCount={false}
          pageSize={parcelas.length > 5 ? 5 : parcelas.length || 2}
        />
      </ModalBase>
    </>
  );
};
