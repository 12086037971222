import React from "react";
import { useState } from "react";
import {
  ComboBox,
  FormCheckbox,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleCentroCustos, routesBaseCentroCustos } from "../CentroCusto";
import { RadioItem } from "../../../../../components/RadioGroup";


export const CentroCustosModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [descricao, setDescricao] = useState("");
  const [vinculacao, setVinculacao] = useState(null);
  const [nivel, setNivel] = useState(1);
  const [aceitaLcto, setAceitaLcto] = useState(false);
  const [natureza, setNatureza] = useState('');
  const [ativo, setAtivo] = useState(true);

  const optionsNatureza = [
    {
      label: "Receitas",
      value: "+",
    },
    {
      label: "Despesas",
      value: "-",
    }
  ];

  const limparDados = () => {
    setDescricao("");
    setVinculacao(null);
    setNivel(1);
    setAceitaLcto(false);
    setNatureza('');
    setAtivo(true);
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setVinculacao(data.id_acima === 0 ? null : data.id_acima);
    setNivel(data.nivel === null ? 1 : data.nivel);
    setAceitaLcto(data.aceita_lcto);
    setNatureza(data.natureza);
    setAtivo(data.ativo);
  };
 
  const submitPayload = (action) => {
    const payload = {
      descricao: descricao,
      nivel: nivel,
      id_acima: parseInt(vinculacao ?? 0),
      aceita_lcto: aceitaLcto,
      natureza: natureza,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_centro_custo: selected, ativo: ativo, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleCentroCustos}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseCentroCustos}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
      number="0128_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={8}
          label="Descrição"
          value={descricao}
          onChange={(e, v) => setDescricao(v)}
          maxLength={40}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={12}
          label="Vinculação"
          value={vinculacao}
          mode="centro_custo"
          onChange={(idSelecionado, planoSelecionado) => {
            if (idSelecionado === null){
              setNivel(1)
            }else{
              setNivel(planoSelecionado?.treeDepth + 2);
            }
            setVinculacao(idSelecionado);
          }}
        />
      </Row>
      <Row>
        <RadioGroup
          md={12}
          label="Nível"
          onChange={(s) => vinculacao === null ? setNivel(1) : setNivel(s?.value)}
          value={nivel}
          disabled={vinculacao ? true : false}
        >
          <RadioItem label="Nível 1" value={1} />
          <RadioItem label="Nível 2" value={2} disabled={true} />
          <RadioItem label="Nível 3" value={3} disabled={true} />
          <RadioItem label="Nível 4" value={4} disabled={true} />
          <RadioItem label="Nível 5" value={5} disabled={true} />
        </RadioGroup>
        <FormCheckbox
          label={"Aceita Lançamentos de Despesas"}
          onChange={() => setAceitaLcto(!aceitaLcto)}
          checked={aceitaLcto}
        />
        <ComboBox
          label={"Natureza"}
          options={optionsNatureza}
          onChange={(s) => setNatureza(s?.value)}
          defaultValue={natureza}
          isSearchable={false}
        />
      </Row>
    </ModalCadastroV2>
  );
};
