import { apiDeleteV2, apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/cadastro/cliente_forma_rec";
const FormaRecClienteService = {
  listarClientes: (params) => apiGetV2(`${URL_BASE}/listar_clientes/`, params),
  listar: (idCliente) => apiGetV2(`${URL_BASE}/listar/${idCliente}/`),
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  excluir: (idRegistro) => apiDeleteV2(`${URL_BASE}/excluir/${idRegistro}/`),
};

export default FormaRecClienteService;
