import React from "react";
import { Table } from "../../../../../components";

const columns = [
  {
    dataField: "codigo",
    text: "Código",
    align: "center",
    headerAlign: "center",
  },
  { dataField: "nome", text: "Nome", align: "left", headerAlign: "left" },
];

export const UnidadeGrid = ({ data, setSelected }) => {
  return (
    <Table
      keyField="codigo"
      data={data}
      columns={columns}
      onSelect={setSelected}
      paginated={false}
    />
  );
};
