import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
  Divider,
  IconButton,
  ModalBase,
  NumberInput,
} from "../../../../../../components";
import { setPercIcmsDifer } from "../../store/cadCfopSlice";
import { AiOutlineInfoCircle } from "react-icons/ai";

export const TabDiferICMSCfop = () => {
  const store = useSelector((state) => state.cadCfop);
  const dispatch = useDispatch();

  return (
    <>
      <Divider className="mt-4">Diferimento</Divider>
      <Row className="mb-3">
        <NumberInput
          label="Alíquota"
          md={3}
          onChange={(v) => dispatch(setPercIcmsDifer(v))}
          value={store.percIcmsDifer}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          divClassName="pr-2"
        />
        <HintDiferIcmsCfopModal />
      </Row>
    </>
  );
};

const HintDiferIcmsCfopModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <IconButton
        icon={AiOutlineInfoCircle}
        onClick={toggle}
        className="mt-4"
        size={20}
      />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Diferimento"
        number="0119_11"
      >
        <span>
          Para a aplicação do Diferimento, é necessário que a operação se
          enquadre nas seguintes condições:
        </span>
        <li className="ml-3">
          1. O Regime Tributário do Emitente deve ser Lucro Real ou Presumido;
        </li>
        <li className="ml-3">
          2. O Destinatário da NF deve ser Contribuinte, portanto, deve possuir
          uma Inscrição Estadual;
        </li>
        <li className="ml-3 mb-2">
          3. No Cadastro do Cliente, a opção Revenda deve estar marcada.
        </li>
        <span>
          Quando o Sistema enquadrar o Processo nas três condições acima,
          alterará automaticamente a CST do ICMS do Produto na NF para 51 -
          Diferimento, portanto, não indicamos o Cadastro de uma Regra
          Tributária específica para Diferimento, pois este controle será feito
          pelo próprio sistema.
        </span>
      </ModalBase>
    </>
  );
};
