import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";
import { docPrintReport } from "../../pdf";

const URL_BASE = "/compra/ordem_compra";
const URL_BASE_PRODUTOS = `${URL_BASE}/produtos`;

export const OrdemCompraService = {
  buscar: (idOrdemCompra) => apiGetV2(`${URL_BASE}/buscar/${idOrdemCompra}/`),
  buscarDadosImpressao: (idOrdemCompra) =>
    apiGetV2(`${URL_BASE}/buscar_dados_impressao/${idOrdemCompra}/`),
  enviarEmail: (payload) => apiPostV2(`${URL_BASE}/enviar_email/`, payload),
  fecharManualmenteOrdemCompra: (payload) =>
    apiPutV2(`${URL_BASE}/fechar_manualmente/`, payload),
  finalizar: (payload) => apiPutV2(`${URL_BASE}/finalizar_inclusao/`, payload),
  imprimir: async (idOrdemCompra) => {
    const [ok, ret] = await OrdemCompraService.buscarDadosImpressao(
      idOrdemCompra
    );
    if (!ok) return;

    await docPrintReport("/compras/ordem_compra/", { dados: ret }, "");
  },
  incluirCab: (payload) =>
    apiPostV2(`${URL_BASE}/incluir_cab/`, payload, {
      successMessage: false,
    }),
  salvar: (payload) => apiPutV2(`${URL_BASE}/salvar/`, payload),
  produtos: {
    alterar: (payload) =>
      apiPutV2(`${URL_BASE_PRODUTOS}/alterar/`, payload, {
        successMessage: false,
      }),
    excluir: (idItem) => apiDeleteV2(`${URL_BASE_PRODUTOS}/excluir/${idItem}/`),
    incluir: (payload) => apiPostV2(`${URL_BASE_PRODUTOS}/incluir/`, payload),
    incluirMultiplos: (payload) =>
      apiPostV2(`${URL_BASE_PRODUTOS}/incluir_multiplos/`, payload),
  },
};
