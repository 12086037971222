import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataIni: new Date(),
  dataFim: new Date(),
  dadosRelatorio: [],
  totaisRelatorio: {},
  totaisGrupos: {},
  loading: false,
  loadingImprimir: false,
  somarBonif: false,
  somarTroca: false,
  dividirDados: true,
  rota: null,
  adicSubtotalGrupos: false,
  idCliente: null,
  idProduto: null,
  idCidade: null,
  idFabricante: null,
  imprimirValores: true,
};

export const vendasAgrupadasPorProdutoSlice = createSlice({
  name: "vendas_agrupadas_por_produto_slice",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setDataIni: (state, action) => {
      state.dataIni = action.payload;
    },
    setDataFim: (state, action) => {
      state.dataFim = action.payload;
    },
    setDadosRelatorio: (state, action) => {
      state.dadosRelatorio = action.payload.registros ?? [];
      state.totaisRelatorio = action.payload.totais ?? {};
      state.totaisGrupos = action.payload.grupos ?? {};
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingImprimir: (state, action) => {
      state.loadingImprimir = action.payload;
    },
    setSomarTroca: (state, action) => {
      state.somarTroca = action.payload;
    },
    setSomarBonif: (state, action) => {
      state.somarBonif = action.payload;
    },
    setDividirDados: (state, action) => {
      state.dividirDados = action.payload;
    },
    setRota: (state, action) => {
      state.rota = action.payload;
    },
    setAdicSubtotalGrupos: (state, action) => {
      state.adicSubtotalGrupos = action.payload;
    },
    setIdProduto: (state, action) => {
      state.idProduto = action.payload;
    },
    setIdCliente: (state, action) => {
      state.idCliente = action.payload;
    },
    setIdCidade: (state, action) => {
      state.idCidade = action.payload;
    },
    setIdFabricante: (state, action) => {
      state.idFabricante = action.payload;
    },
    setImprimirValores: (state, action) => {
      state.imprimirValores = action.payload;
    },
  },
});

export const {
  init,
  setup,
  setDataIni,
  setDataFim,
  setDadosRelatorio,
  setLoading,
  setLoadingImprimir,
  setSomarTroca,
  setSomarBonif,
  setDividirDados,
  setRota,
  setAdicSubtotalGrupos,
  setIdProduto,
  setIdCliente,
  setIdCidade,
  setIdFabricante,
  setImprimirValores,
} = vendasAgrupadasPorProdutoSlice.actions;

export default vendasAgrupadasPorProdutoSlice.reducer;
