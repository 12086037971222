import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token_csc: "",
  id_token_csc: 1,
  camin_salvar_xml: "",
  numero_ult_nfce: 0,
  lote_ult_nfce: 0,
  tipo_impressao: "A",
  forma_impressao: "NAO",
  nome_impressora: "",
  id_tipo_doc_padrao: null,
  id_cliente_padrao: null,
  mostrar_desconto_nfce: false,
  destacar_valores_repasse_icms_st: true,
};

export const nfEletronicaConsumidorSlice = createSlice({
  name: "nf_eletronica_consumidor_slice",
  initialState: initialState,
  reducers: {
    setupNfEletronicaConsumidor: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setTokenCsc: (state, action) => {
      state.token_csc = action.payload;
    },
    setIdTokenCsc: (state, action) => {
      state.id_token_csc = action.payload;
    },
    setCaminSalvarXml: (state, action) => {
      state.camin_salvar_xml = action.payload;
    },
    setNumeroUltNfce: (state, action) => {
      state.numero_ult_nfce = action.payload;
    },
    setLoteUltNfce: (state, action) => {
      state.lote_ult_nfce = action.payload;
    },
    setTipoImpressao: (state, action) => {
      state.tipo_impressao = action.payload;
    },
    setFormaImpressao: (state, action) => {
      state.forma_impressao = action.payload;
    },
    setNomeImpressora: (state, action) => {
      state.nome_impressora = action.payload;
    },
    setIdTipoDocPadrao: (state, action) => {
      state.id_tipo_doc_padrao = action.payload;
    },
    setIdClientePadrao: (state, action) => {
      state.id_cliente_padrao = action.payload;
    },
    setMostrarDescontoNfce: (state, action) => {
      state.mostrar_desconto_nfce = action.payload;
    },
    setDestacarValoresRepasseIcmsSt: (state, action) => {
      state.destacar_valores_repasse_icms_st = action.payload;
    },
  },
});

export const {
  setupNfEletronicaConsumidor,
  setTokenCsc,
  setIdTokenCsc,
  setCaminSalvarXml,
  setNumeroUltNfce,
  setLoteUltNfce,
  setTipoImpressao,
  setFormaImpressao,
  setNomeImpressora,
  setIdTipoDocPadrao,
  setIdClientePadrao,
  setMostrarDescontoNfce,
  setDestacarValoresRepasseIcmsSt,
} = nfEletronicaConsumidorSlice.actions;

export default nfEletronicaConsumidorSlice.reducer;
