import React from "react";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";

class Dashboard extends React.Component {
  constructor(props) {
    super();

    this.props = props;
  }

  render() {
    return (
      <React.Fragment>
        <Wrapper>
          <Sidebar username={this.props.username} />
          <Main>
            <Navbar />
            <Content>{this.props.children}</Content>
          </Main>
        </Wrapper>
      </React.Fragment>
    );
  }
}

export default Dashboard;
