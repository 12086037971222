import { Smartphone } from "react-feather";
import { ClientesInadimplentesRota } from "../../pages/pronta_entrega/relatorios/clientes_inadimplentes_rota/ClientesInadimplentesRota";
import { ClientesNaoAtendRota } from "../../pages/pronta_entrega/relatorios/clientes_nao_atend_rota/ClientesNaoAtendRota";
import { DesempenhoRota } from "../../pages/pronta_entrega/relatorios/desempenho_rota/DesempenhoRota";
import { TrocaDeProdutos } from "../../pages/pronta_entrega/relatorios/troca_de_produtos/TrocaDeProdutos";
import { VendasPorRota } from "../../pages/pronta_entrega/relatorios/vendas_por_rota/VendasPorRota";
import { DespesasJornada } from "../../pages/pronta_entrega/cadastros/despesas_jornada/DespesasJornada";
import { RotasRoteirizacao } from "../../pages/pronta_entrega/cadastros/rotas_roteirizacao/RotasRoteirizacao";
import { Roteirizacao } from "../../pages/pronta_entrega/cadastros/rotas_roteirizacao/roteirizacao/Roteirizacao";
import { ConferenciaValoresJornada } from "../../pages/pronta_entrega/gerenciamento/conferencia_valores/ConferenciaValoresJornada";
import { ConfirmacaoConferencia } from "../../pages/pronta_entrega/gerenciamento/conferencia_valores/confirmacao_conferencia/ConfirmacaoConferencia";
import { DuracaoJornada } from "../../pages/pronta_entrega/gerenciamento/duracao_jornada/DuracaoJornada";
import { EstoqueRota } from "../../pages/pronta_entrega/gerenciamento/unidades_estoque/estoque_rota/EstoqueRota";
import { GerenciamentoComissoes } from "../../pages/pronta_entrega/gerenciamento/gerenciamento_comissoes/GerenciamentoComissoes";
import { FlexBonificacao } from "../../pages/pronta_entrega/gerenciamento/gerenciamento_flex/FlexBonificacao";
import { GerencVendasProntaEntrega } from "../../pages/pronta_entrega/gerenciamento/gerenciamento_vendas/GerencVendasProntaEntrega";
import Recarga from "../../pages/pronta_entrega/jornada/Recarga";
import { EmprestimoColaborador } from "../../pages/pronta_entrega/gerenciamento/emprestimo_colaborador/EmprestimoColaborador";
import { VendasFormaRec } from "../../pages/pronta_entrega/relatorios/vendas_forma_rec/VendasFormaRec";
import { ProntaEntregaCadastro } from "../../pages/pronta_entrega/cadastros/ProntaEntregaCadastro";
import { ProntaEntregaRel } from "../../pages/pronta_entrega/relatorios/ProntaEntregaRel";
import { ProntaEntregaGerenc } from "../../pages/pronta_entrega/gerenciamento/ProntaEntregaGerenc";
import { DemonstrativoMetas } from "../../pages/pronta_entrega/relatorios/demontrativo_metas/DemonstrativoMetas";
import { TitulosRecebMaos } from "../../pages/pronta_entrega/relatorios/titulos_receb_maos/TitulosRecebMaos";
import { EstoqueInter } from "../../pages/pronta_entrega/cadastros/estoque_inter/EstoqueInter";
import { SolicitacaoRecarga } from "../../pages/pronta_entrega/gerenciamento/solicitacao_recarga/SolicitacaoRecarga";
import { LancamentosEstoque } from "../../pages/pronta_entrega/relatorios/lancamentos_estoque/LancamentosEstoque";
import { UnidadesEstoque } from "../../pages/pronta_entrega/gerenciamento/unidades_estoque/UnidadesEstoque";

export const inclusaoRotaRoute = {
  path: "/pronta_entrega/cadastros/rotas/roteirizacao",
  name: "Roteirização",
  id: "AcSkWeb0010_1",
  nroTela: "0010",
  component: Roteirizacao,
};

const recargaProdutosRoutes = {
  path: "/pronta_entrega/jornada/recarga",
  name: "Jornada Principal",
  id: "AcSkWeb0015",
  nroTela: "0015",
  component: Recarga,
};

export const gerenciamentoFlexRoute = {
  path: "/pronta_entrega/gerenciamento/flex_bonificacao",
  name: "Gerenciamento de Flex/Bonificação",
  id: "AcSkWeb0028",
  nroTela: "0028",
  component: FlexBonificacao,
};

export const conferirValoresJornadaDiariaRoutes = {
  path: "/pronta_entrega/conferencia_adm/conferencia_valores/dia/",
  name: "",
  id: "AcSkWeb0025",
  nroTela: "0025",
  component: ConfirmacaoConferencia,
};

export const estoqueRotaRoutes = {
  path: "/pronta_entrega/gerenciamento/posicao_estoque/estoque_rota/",
  name: "",
  id: "AcSkWeb0085",
  nroTela: "0085",
  component: EstoqueRota,
};

export const prontaEntregaCadastroDespesasRoute = {
  path: "/pronta_entrega/cadastros/despesas_jornada",
  name: "Despesas de Jornada",
  id: "AcSkWeb0011",
  nroTela: "0011",
  component: DespesasJornada,
};

export const prontaEntregaCadastroEstoqueInterRoute = {
  path: "/pronta_entrega/cadastros/estoque_inter",
  name: "Despesas de Jornada",
  id: "AcSkWeb0096",
  nroTela: "0096",
  component: EstoqueInter,
};

export const prontaEntregaCadastroRotasRoute = {
  path: "/pronta_entrega/cadastros/rotas",
  name: "Rotas e Roteirizações",
  id: "AcSkWeb0010",
  nroTela: "0010",
  component: RotasRoteirizacao,
};

export const prontaEntregaCadastroRoutes = [
  prontaEntregaCadastroDespesasRoute,
  prontaEntregaCadastroEstoqueInterRoute,
  prontaEntregaCadastroRotasRoute,
];

export const prontaEntregaDesempenhoRotaRoute = {
  path: "/pronta_entrega/demonstrativos/desempenho_rota",
  name: "Desempenho por Rota",
  id: "AcSkWeb5033",
  nroTela: "5033",
  component: DesempenhoRota,
};

export const prontaEntregaClientesInadimplentesRotaRoute = {
  path: "/pronta_entrega/demonstrativos/clientes_inadimplentes_rota",
  name: "Clientes Inadimplentes por Rota",
  id: "AcSkWeb5028",
  nroTela: "5028",
  component: ClientesInadimplentesRota,
};

export const prontaEntregaClientesNaoAtendRotaRoute = {
  path: "/pronta_entrega/demonstrativos/clientes_nao_atend_rota",
  name: "Clientes Não Atendidos por Rota",
  id: "AcSkWeb5026",
  nroTela: "5026",
  component: ClientesNaoAtendRota,
};

export const prontaEntregaDemonstrativoMetasRoute = {
  path: "/pronta_entrega/demonstrativos/demonstrativo_metas",
  name: "Demonstrativo de Metas",
  id: "AcSkWeb5041",
  nroTela: "5041",
  component: DemonstrativoMetas,
};

export const prontaEntregaTrocaDeProdutosRoute = {
  path: "/pronta_entrega/demonstrativos/troca_de_produtos",
  name: "Troca de Produtos",
  id: "AcSkWeb5031",
  nroTela: "5031",
  component: TrocaDeProdutos,
};

export const prontaEntregaVendasPorRotaRoute = {
  path: "/pronta_entrega/demonstrativos/vendas_por_rota",
  name: "Vendas Agrupadas por Rota",
  id: "AcSkWeb5027",
  nroTela: "5027",
  component: VendasPorRota,
};

export const prontaEntregaVendasFormaRecebimentoRoute = {
  path: "/pronta_entrega/demonstrativos/vendas_forma_recebimento",
  name: "Vendas por Forma de Recebimento",
  id: "AcSkWeb5039",
  nroTela: "5039",
  component: VendasFormaRec,
};

export const prontaEntregaTitulosRecebMaosRoute = {
  path: "/pronta_entrega/demonstrativos/titulos_recebidos_em_maos",
  name: "Títulos Recebidos em Mãos",
  id: "AcSkWeb5055",
  nroTela: "5055",
  component: TitulosRecebMaos,
};

export const prontaEntregaLancamentosEstoqueRoute = {
  path: "/pronta_entrega/demonstrativos/lancamentos_estoque",
  name: "Lançamentos de Estoque",
  id: "AcSkWeb5059",
  nroTela: "5059",
  component: LancamentosEstoque,
};

export const prontaEntregaRelatoriosRoutes = [
  prontaEntregaDesempenhoRotaRoute,
  prontaEntregaClientesInadimplentesRotaRoute,
  prontaEntregaClientesNaoAtendRotaRoute,
  prontaEntregaDemonstrativoMetasRoute,
  prontaEntregaTrocaDeProdutosRoute,
  prontaEntregaVendasPorRotaRoute,
  prontaEntregaVendasFormaRecebimentoRoute,
  prontaEntregaTitulosRecebMaosRoute,
  prontaEntregaLancamentosEstoqueRoute,
];

export const gerenciamentoVendasProntaEntregaRoute = {
  path: "/pronta_entrega/gerenciamento_vendas",
  name: "Gerenciamento de Vendas",
  id: "AcSkWeb0024",
  nroTela: "0024",
  component: GerencVendasProntaEntrega,
};

export const duracaoJorandasRoute = {
  path: "/pronta_entrega/gerenciamento/duracao_jornadas",
  name: "Duração de Jornada",
  id: "AcSkWeb0023",
  nroTela: "0023",
  component: DuracaoJornada,
};

export const estoqueAdmProntaEntregaRoute = {
  path: "/pronta_entrega/gerenciamento/unidades_estoque",
  name: "Estoque ADM",
  id: "AcSkWeb0085",
  nroTela: "0085",
  component: UnidadesEstoque,
};

export const prontaEntregaEmprestimoColabRoute = {
  path: "/pronta_entrega/gerenciamento/emprestimo_colab",
  name: "Empréstimos de Colaboradores",
  id: "AcSkWeb0052",
  nroTela: "0052",
  component: EmprestimoColaborador,
};

export const prontaEntregaConferenciaValoresRoute = {
  path: "/pronta_entrega/gerenciamento/conferencia_valores",
  name: "Conferência de Valores",
  id: "AcSkWeb0025",
  nroTela: "0025",
  component: ConferenciaValoresJornada,
};

export const prontaEntregaGerencComissoesRoute = {
  path: "/pronta_entrega/gerenciamento/comissoes",
  name: "Comissões",
  id: "AcSkWeb5020",
  nroTela: "5020",
  component: GerenciamentoComissoes,
};

export const solicitacaoRecargaRoute = {
  path: "/pronta_entrega/gerenciamento/solicitacao_recarga",
  name: "Solicitação de Recarga",
  id: "AcSkWeb0104",
  nroTela: "0104",
  component: SolicitacaoRecarga,
};

export const prontaEntregaGerenciamentoRoutes = [
  gerenciamentoVendasProntaEntregaRoute,
  prontaEntregaGerencComissoesRoute,
  prontaEntregaConferenciaValoresRoute,
  duracaoJorandasRoute,
  prontaEntregaEmprestimoColabRoute,
  estoqueAdmProntaEntregaRoute,
  gerenciamentoFlexRoute,
  solicitacaoRecargaRoute,
];

const prontaEntregaRoutes = {
  path: "/pronta_entrega",
  name: "Pronta Entrega",
  icon: Smartphone,
  children: [
    {
      path: "/pronta_entrega/cadastros",
      name: "Cadastro",
      component: ProntaEntregaCadastro,
      canGoBack: false,
    },
    {
      path: "/pronta_entrega/demonstrativos",
      name: "Demonstrativos",
      component: ProntaEntregaRel,
      canGoBack: false,
    },
    {
      path: "/pronta_entrega/gerenciamento",
      name: "Gerenciamento",
      component: ProntaEntregaGerenc,
      canGoBack: false,
    },
  ],
};

export { recargaProdutosRoutes, prontaEntregaRoutes };
