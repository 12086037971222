import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BsBriefcase } from "react-icons/bs";
import { AiOutlineDollarCircle, AiOutlineLineChart } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
import { CgToolbox } from "react-icons/cg";
import { VscTools } from "react-icons/vsc";
import { RiUserStarLine } from "react-icons/ri";
import {
  relComissaoFixaOsRoute,
  relComissaoFixaRoute,
  relComissaoGerenciaRoute,
  relComissaoLucroRoute,
  relComissaoMetaRoute,
  relComissaoVendedorExternoRoute,
  relComissaoVtsRoute,
} from "../../../routes/modules/relatorios";

export const Comissionamento = () => {
  return (
    <PageContainer title="Relatórios de Comissionamento">
      <MenuGroup>
        <MenuGroupButton
          label="Comissão Fixa"
          icon={AiOutlineDollarCircle}
          pathname={relComissaoFixaRoute.path}
          nroTela={relComissaoFixaRoute.nroTela}
        />
        <MenuGroupButton
          label="Por Lucro"
          icon={MdAttachMoney}
          pathname={relComissaoLucroRoute.path}
          nroTela={relComissaoLucroRoute.nroTela}
        />
        <MenuGroupButton
          label="Por Meta"
          icon={AiOutlineLineChart}
          pathname={relComissaoMetaRoute.path}
          nroTela={relComissaoMetaRoute.nroTela}
        />
        <MenuGroupButton
          label="Fixa OS"
          icon={VscTools}
          pathname={relComissaoFixaOsRoute.path}
          nroTela={relComissaoFixaOsRoute.nroTela}
        />
        <MenuGroupButton
          label="Vendedor Externo"
          icon={BsBriefcase}
          pathname={relComissaoVendedorExternoRoute.path}
          nroTela={relComissaoVendedorExternoRoute.nroTela}
        />
        <MenuGroupButton
          label="Gerência"
          icon={RiUserStarLine}
          pathname={relComissaoGerenciaRoute.path}
          nroTela={relComissaoGerenciaRoute.nroTela}
        />
        <MenuGroupButton
          label="Vend. Técnico de Serviços"
          icon={CgToolbox}
          pathname={relComissaoVtsRoute.path}
          nroTela={relComissaoVtsRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
