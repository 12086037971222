import { apiGetV2, apiPostV2, apiPutV2 } from "../../../apiV2";

const URL_BASE = "/jornada/duracoes";

const DuracoesJornadaService = {
  listar: async () => await apiGetV2(`${URL_BASE}/listar/`),
  adicionar: async (payload) =>
    await apiPostV2(`${URL_BASE}/adicionar/`, payload),
  alterarDataEncerramento: async (payload) =>
    await apiPutV2(`${URL_BASE}/alterar_data_encerramento/`, payload),
  encerrar: async (payload) => await apiPutV2(`${URL_BASE}/encerrar/`, payload),
};

export default DuracoesJornadaService;
