import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { TbTruckDelivery } from "react-icons/tb";
import { manifestoEletronicoMDFeRoute } from "../../../routes/modules/docsEletron";

export const Transporte = () => {
  return (
    <PageContainer title="Documentos Eletrônicos de Transporte">
      <MenuGroup>
        <MenuGroupButton
          label="Manifesto Eletrônico"
          icon={TbTruckDelivery}
          pathname={manifestoEletronicoMDFeRoute.path}
          nroTela={manifestoEletronicoMDFeRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
