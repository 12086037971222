import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  AsyncComboBox,
  DatePicker,
  FormButton,
  FormCheckbox,
  NumberInput,
  RadioGroup,
  TextInput,
} from "../../../../../../components";
import { roundFloat } from "../../../../../../coreUtils";
import { RadioItem } from "../../../../../../components/RadioGroup";
import { Row, Col } from "reactstrap";
import OrdemServicoService from "../../../../../../services/ordem_servico/OrdemServicoService";
import moment from "moment";
import PesqProduto from "../../../../../../components/form/pesq_produto/PesqProduto";
import { useSelector } from "react-redux";
import { ConfirmarGarantiaModal } from "./ConfirmarGarantiaModal";
import { ConfirmarItemJaIncluidoModal } from "./ConfirmarItemJaIncluidoModal";

export const IncluirItemOSForm = ({ idOS, notifyEvent }) => {
  const itens = useSelector((state) => state.itensOS);
  const params = useSelector((state) => state.paramsOS);
  const [loading, setLoading] = useState(false);

  const mecanica = params.tipoSist === "MECANICA";

  // Produto / Serviço
  const [tipo, setTipo] = useState("S");
  const [idItem, setIdItem] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [unidade, setUnidade] = useState("");
  const [idTecnico, setIdTecnico] = useState(null);
  const [quantidade, setQuantidade] = useState(1);
  const [vlrUnit, setVlrUnit] = useState(0);
  const [vlrItem, setVlrItem] = useState(0);
  const [percDesc, setPercDesc] = useState(0);
  const [vlrTotal, setVlrTotal] = useState(0);
  const [vlrDesc, setVlrDesc] = useState(0);
  const [vlrAcre, setVlrAcre] = useState(0);
  const [garantia, setGarantia] = useState(false);
  const [observ, setObserv] = useState("");
  const [vlrCusto, setVlrCusto] = useState(0);

  // Serviço
  const [data, setData] = useState(new Date());
  const [horaIni, setHoraIni] = useState("");
  const [horaFim, setHoraFim] = useState("");
  const [horasServico, setHorasServico] = useState(0);
  const [retrabalho, setRetrabalho] = useState(false);
  const [horasVendidas, setHorasVendidas] = useState(0);
  const [confirmarGarantia, setConfirmarGarantia] = useState(false);
  const [confirmarItemJaIncluido, setConfirmarItemJaIncluido] = useState(false);

  // Terceirização
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [vlrTotCustoTerc, setVlrTotCustoTerc] = useState(null);

  const [itemCuringa, setItemCuringa] = useState(false);

  const refServico = useRef();
  const refTecnico = useRef();
  const refFornecTerceiriz = useRef();

  const refTerceirizacao = useRef();
  const refVlrTotCustoTerc = useRef();

  const refPesqProd = useRef();

  const refQuantidade = useRef();

  useEffect(() => {
    if (refServico.current) {
      refServico.current.clearValue();
    }
    if (refPesqProd.current) {
      refPesqProd.current.clear();
    }
    if (refTerceirizacao.current) {
      refTerceirizacao.current.clearValue();
    }
  }, [tipo]);

  const limparDados = useCallback(
    (limparTudo = true) => {
      if (limparTudo) {
        if (refServico.current) {
          refServico.current.clearValue();
        }
        if (refTecnico.current) {
          refTecnico.current.clearValue();
        }
        if (refPesqProd.current) {
          refPesqProd.current.clear();
        }
        if (refTerceirizacao.current) {
          refTerceirizacao.current.clearValue();
        }
      }
      if (tipo === "S" || limparTudo) {
        setIdTecnico(null);
        setData(new Date());
        setHoraIni("");
        setHoraFim("");
        setHorasServico(0);
        setRetrabalho(false);
        setHorasVendidas(0);

        if (refTecnico.current) {
          refTecnico.current.clearValue();
        }
      }
      if (tipo === "T" || limparTudo) {
        setIdFornecedor(null);
        setVlrTotCustoTerc(null);

        if (refFornecTerceiriz.current) {
          refFornecTerceiriz.current.clearValue();
        }
      }
      setIdItem(0);
      setDescricao("");
      setUnidade("");
      setQuantidade(1);
      setVlrUnit(0);
      setVlrItem(0);
      setPercDesc(0);
      setVlrTotal(0);
      setVlrDesc(0);
      setItemCuringa(false);
      setVlrAcre(0);
      setGarantia(false);
      setObserv("");
      setVlrCusto(0);
    },
    [tipo]
  );

  useEffect(() => {
    limparDados(false);
  }, [limparDados]);

  const handleSetTipo = (v) => {
    if (v !== tipo) {
      limparDados(false);
    }
    setTipo(v);
  };

  const handleSetItem = (s) => {
    if (s?.value !== idItem && ![0, null, undefined].includes(s?.value)) {
      limparDados(false);

      setIdItem(s?.value);
      if (tipo === "T") {
        setDescricao(s?.descricao);
        setIdFornecedor(s?.id_fornecedor);
      } else {
        setDescricao(s?.nome);
      }
      setVlrDesc(0);
      setPercDesc(0);
      setVlrAcre(0);
      setVlrCusto(0);
      if (tipo === "P") {
        setVlrUnit(s?.preco_venda);
        setUnidade(s?.unidade);
        setVlrItem(s?.preco_venda);
        setVlrTotal(s?.preco_venda);
      } else {
        setVlrUnit(s?.valor);
        setUnidade(s?.unidade);
        setVlrItem(s?.valor);
        setVlrTotal(s?.valor);
      }
      selectNextField();
    } else if ([0, null, undefined].includes(s?.value)) {
      setIdItem(0);
    }
  };

  const handleSetQuantidade = (v) => {
    setQuantidade(v);

    const vTotBruto = vlrUnit * v;
    const vTotLiq = vlrItem * v;
    let vlrDesc = vTotBruto - vTotLiq;
    let vlrAcre = vTotLiq - vTotBruto;
    if (vlrDesc < 0) {
      vlrDesc = 0;
    }
    if (vlrAcre < 0) {
      vlrAcre = 0;
    }

    let pDesc = 0;
    if (vlrDesc > 0) {
      pDesc = 100 - (vTotLiq / vTotBruto) * 100;
    }
    setPercDesc(roundFloat(pDesc, 2));
    setVlrDesc(roundFloat(vlrDesc, 2));
    setVlrAcre(roundFloat(vlrAcre, 2));
    setVlrTotal(roundFloat(vTotLiq, 2));
  };

  const handleSetPercDesc = (v) => {
    setPercDesc(v);

    const vTotBruto = vlrUnit * quantidade;
    let vItem = vlrItem;
    let vDesc = 0;
    if (vTotBruto > 0 && v > 0) {
      vDesc = roundFloat((v / 100) * vTotBruto, 2);
      vItem = roundFloat(vlrUnit - vDesc / quantidade, 2);
    }
    const vTotLiq = roundFloat(vItem * quantidade, 2);

    if (v > 0) {
      setVlrAcre(0);
    }

    setVlrItem(vItem);
    setVlrDesc(vDesc);
    setVlrTotal(vTotLiq);
  };

  const handleSetVlrItem = (v) => {
    setVlrItem(v);

    const vTotBruto = vlrUnit * quantidade;
    let pDesc = 0;
    let vDesc = 0;
    let vAcre = 0;
    if (v < vlrUnit) {
      if (vTotBruto > 0) {
        pDesc = 100 - (v / vlrUnit) * 100;
        if (pDesc > 0) {
          vDesc = (pDesc / 100) * vTotBruto;
        }
      }
    } else if (v > vlrUnit) {
      vAcre = (v - vlrUnit) * quantidade;
    }
    const vTotLiq = v * quantidade;

    if (itemCuringa) {
      setVlrUnit(v);
    }
    setPercDesc(roundFloat(pDesc, 2));
    setVlrDesc(roundFloat(vDesc, 2));
    setVlrAcre(roundFloat(vAcre, 2));
    setVlrTotal(roundFloat(vTotLiq, 2));
  };

  const ajustarValorItemGarantia = () => {
    if (garantia) {
      handleSetVlrItem(0);
    }
  };

  useEffect(ajustarValorItemGarantia, [garantia]);

  const handleSetVlrTotal = (v) => {
    setVlrTotal(v);

    const vTotBruto = vlrUnit * quantidade;
    const vItem = v / quantidade;
    let vDesc = 0;
    let pDesc = 0;
    let vAcre = 0;
    if (vItem < vlrUnit) {
      if (vTotBruto > 0) {
        pDesc = 100 - (vItem / vlrUnit) * 100;
        if (pDesc > 0) {
          vDesc = (pDesc / 100) * vTotBruto;
        }
      }
    } else if (vItem > vlrUnit) {
      vAcre = (vItem - vlrUnit) * quantidade;
    }

    if (itemCuringa) {
      setVlrUnit(roundFloat(vItem, 2));
    }
    setPercDesc(roundFloat(pDesc, 2));
    setVlrDesc(roundFloat(vDesc, 2));
    setVlrAcre(roundFloat(vAcre, 2));
    setVlrItem(roundFloat(vItem, 2));
  };

  const calcularTempoServiço = (hi, hf) => {
    const hIni = moment(hi, "HH:mm", true);
    const hFim = moment(hf, "HH:mm", true);

    if (hIni.isValid() && hFim.isValid()) {
      const diff = hFim.diff(hIni, "minutes") / 60;
      setHorasServico(roundFloat(diff, 2));
    } else {
      setHorasServico(0);
    }
  };

  const handleSetHoraIni = (v) => {
    setHoraIni(v);
    calcularTempoServiço(v, horaFim);
  };

  const handleSetHoraFim = (v) => {
    setHoraFim(v);
    calcularTempoServiço(horaIni, v);
  };

  const verificarItemJaIncluido = () => {
    const indexItem = itens.itens.findIndex((element) => {
      return element.id_item === idItem && element.tipo[0] === tipo;
    });

    return indexItem > -1;
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (garantia) {
      setConfirmarGarantia(true);
    } else {
      if (verificarItemJaIncluido()) {
        setConfirmarItemJaIncluido(true);
      } else {
        await incluirItem();
      }
    }

    setLoading(false);
  };

  const incluirItem = async () => {
    const payload = {
      importacao: false,
      id_cab: idOS,
      tipo: tipo,
      id_prod_serv_terc: idItem,
      descricao: descricao,
      id_tecnico: idTecnico,
      quantidade: quantidade,
      vlr_unit: vlrUnit,
      vlr_item: vlrItem,
      perc_desc: percDesc,
      vlr_total: vlrTotal,
      vlr_desc: vlrDesc,
      vlr_acre: vlrAcre,
      garantia: garantia,
      observ: observ,
      data_do_serv: data ? moment(data).format("YYYY-MM-DD") : data,
      hora_ini: horaIni !== "" ? horaIni : null,
      hora_fim: horaIni !== "" ? horaFim : null,
      horas_servico: horasServico,
      retrabalho: retrabalho,
      horas_vendidas: horasVendidas,
      id_fornecedor: idFornecedor,
      data_conc_terc: data ? moment(data).format("YYYY-MM-DD") : data,
      vlr_tot_custo_terc: vlrTotCustoTerc,
    };
    if (await OrdemServicoService.incluirItem(payload)) {
      if (tipo === "S") {
        refServico.current.setFocus();
      } else if (tipo === "T") {
        refTerceirizacao.current.setFocus();
      } else {
        refPesqProd.current.focus();
      }
      limparDados();
      notifyEvent();
    }
  };

  const semItem = [0, null, undefined].includes(idItem);

  const selectNextField = () => {
    if (params.identificaTecnico && tipo === "S") {
      refTecnico.current.setFocus();
    } else if (tipo === "T") {
      refVlrTotCustoTerc.current.focus();
    } else {
      refQuantidade.current.focus();
    }
  };

  const handleSelectItemPesq = ({
    idProduto,
    nomeProduto,
    referencia,
    precoVenda,
    unidade,
    custoUe,
    curinga,
  }) => {
    if (idItem !== idProduto) {
      setIdItem(idProduto);
      setDescricao(nomeProduto);
      setVlrUnit(precoVenda);
      setUnidade(unidade);
      setVlrItem(precoVenda);
      setPercDesc(0);
      setVlrDesc(0);
      setVlrAcre(0);
      setVlrTotal(precoVenda);
      setVlrCusto(custoUe);
      setItemCuringa(curinga);
      refPesqProd.current.setDescricao(nomeProduto);
      refPesqProd.current.setId(String(idProduto));
      refPesqProd.current.setReferencia(String(referencia));
    }
  };

  const btnIncluir = (
    <FormButton
      divClassName="mt-auto"
      onClick={handleSubmit}
      md="auto"
      color="info"
      loading={loading}
      disabled={semItem}
      disabledHint={
        semItem &&
        "Selecione " +
          (tipo === "P"
            ? "o Produto"
            : tipo === "S"
            ? "o Serviço"
            : "a Terceirização")
      }
    >
      Incluir
    </FormButton>
  );

  return (
    <>
      <Row>
        <RadioGroup label="Tipo" value={tipo} onChange={handleSetTipo}>
          <RadioItem label="Serviço" value="S" />
          <RadioItem label="Produto" value="P" />
          <RadioItem label="Terceirização" value="T" />
        </RadioGroup>
        {tipo === "S" && (
          <AsyncComboBox
            style={{ zIndex: 2 }}
            md={6}
            isConcatField
            concatModelName="servico"
            label="Serviço"
            isSearchable
            onChange={handleSetItem}
            defaultValue={idItem}
            defaultOptions
            ref={refServico}
            autoFocus
          />
        )}
        {tipo === "P" && (
          <PesqProduto
            onConfirm={handleSelectItemPesq}
            ref={refPesqProd}
            selectNextField={selectNextField}
            onChangeDescricao={setDescricao}
            autoFocus
          />
        )}
        {tipo === "T" && (
          <AsyncComboBox
            style={{ zIndex: 2 }}
            md={6}
            isConcatField
            concatModelName="terceirizacao"
            label="Terceirização"
            isSearchable
            onChange={handleSetItem}
            defaultValue={idItem}
            defaultOptions
            ref={refTerceirizacao}
            autoFocus
          />
        )}
      </Row>
      <Row>
        <TextInput
          md={1}
          label="Unidade"
          value={unidade}
          disabled
          className="text-center"
        />
        <NumberInput md={2} label="Unitário" value={vlrUnit} disabled />
        {tipo === "P" && (
          <NumberInput md={2} label="Custo" value={vlrCusto} disabled />
        )}
        <FormCheckbox
          label="Garantia"
          name="garantia"
          checked={garantia}
          onChange={() => setGarantia(!garantia)}
          disabled={semItem}
        />
        {tipo === "S" && (
          <FormCheckbox
            label="Retrabalho"
            name="retrabalho"
            checked={retrabalho}
            onChange={() => setRetrabalho(!retrabalho)}
            disabled={semItem}
          />
        )}
      </Row>
      <Row>
        {tipo === "S" && params.identificaTecnico && (
          <>
            <AsyncComboBox
              md={3}
              isConcatField
              concatModelName="tecnico"
              label="Técnico"
              isSearchable
              onChange={(s) => setIdTecnico(s?.value ?? null)}
              defaultValue={idTecnico}
              defaultOptions
              ref={refTecnico}
            />
          </>
        )}
        {tipo === "T" && (
          <>
            <AsyncComboBox
              md={4}
              isConcatField
              concatModelName="fornecedor"
              label="Fornecedor"
              isSearchable
              onChange={(s) => setIdFornecedor(s?.value ?? null)}
              defaultValue={idFornecedor}
              ref={refFornecTerceiriz}
            />
          </>
        )}
        <DatePicker
          md={2}
          label={tipo === "T" ? "Data Conc. Terc." : "Data do Trabalho"}
          value={data}
          onChange={setData}
          disabled={tipo === "P" || semItem}
        />

        {tipo === "S" && (
          <>
            {!mecanica ? (
              <Col md={3}>
                <Row>
                  <TextInput
                    type="time"
                    md={6}
                    label="Hora Início"
                    value={horaIni}
                    onChange={(_, v) => handleSetHoraIni(v)}
                    disabled={tipo !== "S"}
                  />
                  <TextInput
                    type="time"
                    md={6}
                    label="Hora Fim"
                    value={horaFim}
                    onChange={(_, v) => handleSetHoraFim(v)}
                    disabled={tipo !== "S"}
                  />
                </Row>
              </Col>
            ) : (
              <NumberInput
                md={2}
                label="Horas Serviço"
                value={percDesc}
                onChange={handleSetPercDesc}
                disabled
              />
            )}
            <NumberInput
              md={2}
              label="Horas Vendidas"
              value={horasVendidas}
              onChange={setHorasVendidas}
              disabled={tipo !== "S" || semItem}
            />
          </>
        )}
        {tipo === "T" && (
          <NumberInput
            md={2}
            label="Valor Custo Terc."
            value={vlrTotCustoTerc}
            onChange={setVlrTotCustoTerc}
            ref={refVlrTotCustoTerc}
          />
        )}
      </Row>
      <Row>
        <NumberInput
          md={2}
          label="Quantidade"
          value={quantidade}
          onChange={handleSetQuantidade}
          disabled={semItem}
          ref={refQuantidade}
        />
        <NumberInput
          md={2}
          label="Desconto (%)"
          value={percDesc}
          onChange={handleSetPercDesc}
          isPercentage
          disabled
        />
        <NumberInput
          md={2}
          label="Valor Item"
          value={vlrItem}
          onChange={handleSetVlrItem}
          disabled={semItem || garantia}
        />
        <NumberInput
          md={2}
          label="Valor Total"
          value={vlrTotal}
          onChange={handleSetVlrTotal}
          disabled
        />
        {!params.utilizaObservItem && btnIncluir}
      </Row>
      {params.utilizaObservItem && tipo !== "T" && (
        <Row>
          <TextInput
            md={9}
            rows={3}
            type="textarea"
            label="Observação"
            value={observ}
            onChange={(_, v) => setObserv(v)}
            forceUppercase={false}
            disabled={semItem}
          />
          {btnIncluir}
        </Row>
      )}
      {confirmarGarantia && (
        <ConfirmarGarantiaModal
          toggle={(v) => {
            if (v === true && confirmarItemJaIncluido === false) {
              incluirItem();
            }
            setConfirmarGarantia(false);
          }}
        />
      )}
      {confirmarItemJaIncluido && !confirmarGarantia && (
        <ConfirmarItemJaIncluidoModal
          tipo={tipo}
          toggle={(v) => {
            if (v === true) {
              incluirItem();
            }
            setConfirmarItemJaIncluido(false);
          }}
        />
      )}
    </>
  );
};
