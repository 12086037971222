import React from "react";
import { useState } from "react";
import { AsyncComboBox, ModalCadastro } from "../../../../../components";
import { Row } from "reactstrap";

export const CadastroGrupoNaoVerifEstoqueModal = ({
  selected,
  notifyEvent,
}) => {
  const [idGrupo, setIdGrupo] = useState(null);

  const limparDados = () => {
    setIdGrupo(null);
  };

  const submitPayload = () => {
    return { id_grupo: idGrupo };
  };

  return (
    <ModalCadastro
      title="Grupo de Não Verificação de Estoque"
      size="sm"
      onClose={limparDados}
      submitPayload={submitPayload}
      routesBase="/cadastro/grupo_prod_exclusao_verif_estoque"
      number="0074_1"
      deleteModalNumber="0074_2"
      selected={selected}
      notifyEvent={notifyEvent}
      hideEditButton
    >
      <Row>
        <AsyncComboBox
          isConcatField
          concatModelName="grupo"
          isSearchable
          isClearable
          md={12}
          label="Grupo"
          defaultOptions
          onChange={(s) => setIdGrupo(s?.value ?? 0)}
          defaultValue={idGrupo}
        />
      </Row>
    </ModalCadastro>
  );
};
