import { apiGet, apiPost, urlParams } from "../../api";

const URL_BASE = "/emprestimo_colaborador";
const EmprestimoColaboradorService = {
  listar: async (params) =>
    await apiGet(`${URL_BASE}/listar/${urlParams(params)}`),
  buscar: async (idEmprestimo) =>
    await apiGet(`${URL_BASE}/buscar/${idEmprestimo}/`),
  incluir: async (payload) => await apiPost(`${URL_BASE}/incluir/`, payload),
};

export default EmprestimoColaboradorService;
