import React from "react";
import { ModalDetalhes } from "../../../../../components/ModalDetalhes";
import { formatNumber, naturalSort } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "id_produto",
    text: "#",
    align: "center",
    sortable: true,
  },
  {
    dataField: "nome_produto",
    text: "Produto",
    align: "left",
    sortable: true,
  },
  {
    dataField: "qtd_pendente",
    text: "Qtd. Pendente",
    align: "center",
    sortable: true,
    formatter: (v) => formatNumber(v, true, 2, true),
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_pendente",
    text: "Valor Pendente",
    align: "right",
    sortable: true,
    formatter: (cell) => formatNumber(cell, true, 2),
    sortFunc: naturalSort,
  },
];

export const DetalhesProdPendEntregaCliModal = ({ index, row }) => (
  <ModalDetalhes
    id={index}
    title={`${row.id_cliente__nome}`}
    data={row.itens}
    columns={columns}
  />
);
