import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Divider, FormButton, ModalBase } from "../../../../../../components";
import { v4 as uuidv4 } from "uuid";
import LinhaFiltroModal, { buscarLabelFiltro } from "./LinhaFiltroModal";

const emptyFilter = () => ({
  uuid: uuidv4(),
  tipo: null,
  id_filtro: null,
  desc_filtro: null,
});

const FiltrosModal = forwardRef(({ onFilterUpdate, divClassName }, ref) => {
  const [filtros, setFiltros] = useState([emptyFilter()]);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const adicionarFiltro = () => setFiltros([emptyFilter(), ...filtros]);

  const atualizarFiltros = (novosDadosFiltro) => {
    setFiltros([
      ...filtros.map((e) => {
        if (e.uuid === novosDadosFiltro.uuid) {
          return { ...e, ...novosDadosFiltro };
        }
        return e;
      }),
    ]);
  };

  useEffect(() => {
    if (onFilterUpdate) {
      onFilterUpdate(filtros);
    }
  }, [filtros]);

  useImperativeHandle(ref, () => ({
    // Retorna somente os filtros completamente informados
    buscarFiltros: () =>
      filtros
        .filter(
          (e) =>
            ![0, null, undefined].includes(e.id_filtro) &&
            ![0, null, undefined].includes(e.tipo)
        )
        .map((e) => ({ tipo: e.tipo, id_filtro: e.id_filtro })),
  }));

  const removerFiltro = (uuidRemover) =>
    setFiltros([...filtros.filter((e) => e.uuid !== uuidRemover)]);

  const filtrosSelecionados = filtros.filter(
    (e) =>
      ![0, null, undefined].includes(e.id_filtro) &&
      ![0, null, undefined].includes(e.tipo)
  );

  return (
    <div style={{ display: "flex" }} className={divClassName}>
      {filtrosSelecionados.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "right",
            justifyContent: "center",
          }}
        >
          {filtrosSelecionados.map((e) => (
            <span>
              {buscarLabelFiltro(e.tipo)} - {e.desc_filtro}
            </span>
          ))}
        </div>
      )}
      <FormButton
        color="primary"
        onClick={toggle}
        padded={false}
        divClassName="pr-0"
      >
        Definir Filtros
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Filtros"
        number="0016_3"
        onConfirm={toggle}
      >
        {filtros.map((filtro, idx) => {
          const component = (
            <LinhaFiltroModal
              tipoFiltro={filtro.tipo}
              uuidFiltro={filtro.uuid}
              idFiltro={filtro.id_filtro}
              idx={idx}
              adicionarFiltro={adicionarFiltro}
              atualizarFiltros={atualizarFiltros}
              removerFiltro={removerFiltro}
            />
          );
          if (idx === 0) {
            return component;
          }
          return (
            <>
              <Divider />
              {component}
            </>
          );
        })}
      </ModalBase>
    </div>
  );
});

export default FiltrosModal;
