import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  idRota: null,
};

export const filtrosConferenciaSlice = createSlice({
  name: "filtros_conferencia_slice",
  initialState: initialState,
  reducers: {
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setIdRota: (state, action) => {
      state.idRota = action.payload;
    },
  },
});

export const { setIdRota } = filtrosConferenciaSlice.actions;

export default filtrosConferenciaSlice.reducer;
