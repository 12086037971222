import React, { useState } from "react";
import { Table } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import { ModalDetalhesIcone } from "../../../../../components/ModalDetalhesIcone";
import {
  TabBody,
  TabController,
} from "../../../../../components/TabController";
import { naturalSort } from "../../../../../coreUtils";

export const DetalhesCliNaoAtendRotaModal = ({ index, data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <ModalDetalhesIcone id={index} toggle={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title={
          <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            {data["nome"]}
          </h5>
        }
        number="5026_1"
        autoFocus
        hideCancelButton
      >
        <TabController>
          <TabBody title="Clientes Não Atendidos">
            <DetatalhesCliNaoAtendGrid
              data={data["clientes_nao_atend"] ?? []}
              tabId={1}
            />
          </TabBody>
          <TabBody title="Clientes Atendidos Fora de Roteirização">
            <p>Informações baseadas no cadastro da roteirização</p>
            <DetatalhesCliNaoAtendGrid
              data={data["clientes_atend_outro_dia"] ?? []}
              tabId={2}
            />
          </TabBody>
        </TabController>
      </ModalBase>
    </>
  );
};

const DetatalhesCliNaoAtendGrid = ({ data, tabId }) => {
  const columns = [
    {
      dataField: "id_cliente",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "id_cliente__nome_fant",
      text: "Nome Fantasia",
      align: "left",
      sortable: true,
      formatter: (c, row) => (c === "" ? row.id_cliente : c),
    },
    {
      dataField: "id_cliente__cidade__nome",
      text: "Cidade",
      align: "left",
    },
    {
      dataField: "status",
      text: "Situação",
      align: "left",
    },
    {
      dataField: "data_deveria_atend",
      text: "Deveria Atender Em",
      align: "center",
      formatter: (_, row) =>
        `${row.data_deveria_atend} - ${row.dia_sem_deveria_atend}`,
    },
    {
      dataField: "data_atendeu",
      text: "Atendido Em",
      align: "center",
      formatter: (_, row) => `${row.data_atendeu} - ${row.dia_sem_atendido}`,
    },
    {
      dataField: "motivo_nao_vend",
      text: "Motivo",
      align: "left",
    },
    {
      dataField: "observ_nao_vend",
      text: "Obs.",
      align: "left",
    },
    {
      dataField: "houve_deslocamento",
      text: "Houve Desloca.",
      align: "center",
      formatter: (cell) => (cell ? "Sim" : "Não"),
    },
  ];

  const filtraColunas = () => {
    let cols = columns;

    if (tabId === 1) {
      cols = cols.filter(
        (e) => !["data_atendeu", "dia_sem_atendido"].includes(e.dataField)
      );
    }

    if (tabId !== 1) {
      cols = cols.filter(
        (e) =>
          ![
            "houve_deslocamento",
            "motivo_nao_venda",
            "observ_nao_vend",
          ].includes(e.dataField)
      );
    }

    return cols;
  };

  return (
    <Table
      columns={filtraColunas()}
      data={data}
      keyField="id_cliente"
      pageSize={15}
      paginated={false}
    />
  );
};
