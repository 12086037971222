import React, { useState } from "react";
import { BotaoPesquisar, PageContainer } from "../../../../components";
import RemessaEntreEmpresasService from "../../../../services/faturamento/RemessaEntreEmpresasService";
import { Card, Row } from "reactstrap";
import { ReceberRemessaGrid } from "./components/ReceberRemessaGrid";
import { ReceberRemessaModal } from "./components/ReceberRemessaModal";

export const ReceberRemessa = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [licencaMatrizSelected, setLicencaMatrizSelected] = useState(null);

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await RemessaEntreEmpresasService.listarRemessasReceber();
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const notifyEvent = () => {
    setSelected(null);
    setLicencaMatrizSelected(null);
    carregarDados();
  };

  return (
    <PageContainer title="Receber Remessa Entre Empresas" number="0099">
      <Card body>
        <Row>
          <BotaoPesquisar
            padded={false}
            onClick={carregarDados}
            loading={loading}
          />
          <ReceberRemessaModal
            selected={selected}
            licencaMatriz={licencaMatrizSelected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <ReceberRemessaGrid
          dados={dados}
          setSelected={(selected, _, row) => {
            setSelected(selected);
            setLicencaMatrizSelected(row?.licenca_matriz ?? null);
          }}
        />
      </Card>
    </PageContainer>
  );
};
