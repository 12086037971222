import React from "react";
import { Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

export const ProgramacaoProducaoInsumosGrid = ({ dados }) => {
  const columns = [
    {
      dataField: "nome_produto_insumo",
      text: "Insumo",
      align: "left",
    },
    {
      dataField: "unidade_produto_insumo",
      text: "Unidade",
      align: "center",
    },
    {
      dataField: "estoque",
      text: "Estoque",
      align: "center",
      formatter: (c) => formatNumber(c, true, 3, true),
    },
    {
      dataField: "qtd_demanda",
      text: "Demanda Programação",
      align: "center",
      formatter: (c) => formatNumber(c, true, 3, true),
    },
    {
      dataField: "qtd_demanda_selecionados",
      text: "Demanda dos Selecionados",
      align: "center",
      formatter: (c) => formatNumber(c, true, 3, true),
    },
  ];

  return <Table data={dados} columns={columns} pageSize={10} />;
};
