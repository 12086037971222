import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCamposAtivosIcmsNfce,
  setModBcIcmsNfce,
  setPercIcmsNfce,
  setPercRedBaseIcmsNfce,
  setSitTribIcmsNfce,
} from "../../store/cadCfopSlice";
import TributacaoService from "../../../../../../services/cadastro/TributacaoService";
import { Row } from "reactstrap";
import { ComboBox, Divider, NumberInput } from "../../../../../../components";

const modalidades_icms = [
  { label: "Margem Valor Agregado", value: "MVA" },
  { label: "Pauta (Valor)", value: "PAV" },
  { label: "Preço Tabelado Máx. (valor)", value: "PTM" },
  { label: "Valor da Operação", value: "VOP" },
];

export const TabICMSNFCeCfop = () => {
  const store = useSelector((state) => state.cadCfop);
  const dispatch = useDispatch();

  const handleSelectCstsNfce = async (selected) => {
    if (selected) {
      const [ok, ret] = await TributacaoService.camposAtivosIcmsNfce(
        selected?.value
      );
      if (!ok) return;
      dispatch(setCamposAtivosIcmsNfce(ret));
      dispatch(setSitTribIcmsNfce(selected?.value));
      if (!ret.mod_bc_icms_nfce_ativo) {
        dispatch(setModBcIcmsNfce(""));
      }
    }
  };

  return (
    <>
      <Divider>ICMS - Modelo 65 (NFCe)</Divider>
      <Row>
        <ComboBox
          label="Situação Tributária"
          isSearchable={false}
          md={12}
          options={store.cstsIcms}
          defaultOptions
          defaultValue={store.sitTribIcmsNfce}
          onChange={handleSelectCstsNfce}
          divClassName="pr-0"
        />
      </Row>
      <Row>
        <ComboBox
          label="Modalidade de Determinação da Base de Cálculo"
          isSearchable={false}
          md={10}
          options={modalidades_icms}
          defaultOptions
          isDisabled={!store.camposAtivosIcmsNfce?.mod_bc_icms_nfce_ativo}
          onChange={(selected) =>
            dispatch(setModBcIcmsNfce(selected?.value ?? ""))
          }
          defaultValue={store.modBcIcmsNfce}
          divClassName="pr-0"
        />
      </Row>
      <Row>
        <NumberInput
          label="Alíquota"
          md={3}
          disabled={!store.camposAtivosIcmsNfce?.aliq_icms_nfce_ativo}
          onChange={(v) => dispatch(setPercIcmsNfce(v))}
          value={store.percIcmsNfce}
          isPercentage
          clearOnDisable
          divClassName="pr-0"
        />
        <NumberInput
          label="Redutor Base ICMS"
          md={3}
          disabled={!store.camposAtivosIcmsNfce?.perc_red_base_icms_nfce_ativo}
          onChange={(v) => dispatch(setPercRedBaseIcmsNfce(v))}
          value={store.percRedBaseIcmsNfce}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          divClassName="pr-0"
        />
      </Row>
    </>
  );
};
