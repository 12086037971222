import React from "react";
import { ModalDetalhes, Table, TableCheck } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const columnsDetalhes = [
  {
    dataField: "id_os",
    text: "Nº O.S.",
    align: "center",
  },
  {
    dataField: "descricao",
    text: "Serviço",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_servico),
  },
  {
    dataField: "data_fec_os",
    text: "Fech. O.S.",
    align: "center",
  },
  {
    dataField: "quantidade",
    text: "Qtd",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_unit",
    text: "Unitário",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_item",
    text: "Vlr. Item",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_desc",
    text: "Vlr. Desconto",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "garantia",
    text: "Garantia",
    align: "center",
    formatter: (c) => <TableCheck value={c} />,
  },
  {
    dataField: "retrabalho",
    text: "Retrabalho",
    align: "center",
    formatter: (c) => <TableCheck value={c} />,
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columns = [
  {
    dataField: "nome_tecnico",
    text: "Técnico",
    align: "left",
    formatter: (c, row) =>
      row.id_tecnico ? formatValueFromAPI(c, row.id_tecnico) : c,
  },
  {
    dataField: "ctn_servs",
    text: "Lançamentos",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "qtd_servs",
    text: "Qtd. Serviços",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_servs",
    text: "Vlr. Serviços",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "det",
    text: "",
    align: "center",
    formatter: (c, row) => (
      <ModalDetalhes
        data={row.servicos}
        columns={columnsDetalhes}
        title={`${row.nome_tecnico} - Serviços`}
      />
    ),
  },
];

export const ValorHorasTecnicoGrid = ({ dados }) => {
  return <Table data={dados} columns={columns} />;
};
