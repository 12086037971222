import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  solic_confirm_data_comp_conf_rec_banc: false,
  bloq_processamento_retorno_mov_nao_identif: false,
  id_plano_contas_tarifa_boleto: null,
};

const fieldsToNull = ["id_plano_contas_tarifa_boleto"];

export const bancarioSlice = createSlice({
  name: "bancario_slice",
  initialState: initialState,
  reducers: {
    setupBancario: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value === 0 && fieldsToNull.includes(key)) {
          value = null;
        }

        state[key] = value;
      });
      return state;
    },
    setSolicConfirmDataCompConfRecBanc: (state, action) => {
      state.solic_confirm_data_comp_conf_rec_banc = action.payload;
    },
    setBloqProcessamentoRetornoMovNaoIdentif: (state, action) => {
      state.bloq_processamento_retorno_mov_nao_identif = action.payload;
    },
    setIdPlanoContasTarifaBoleto: (state, action) => {
      state.id_plano_contas_tarifa_boleto = action.payload;
    },
  },
});

export const {
  setupBancario,
  setSolicConfirmDataCompConfRecBanc,
  setBloqProcessamentoRetornoMovNaoIdentif,
  setIdPlanoContasTarifaBoleto,
} = bancarioSlice.actions;

export default bancarioSlice.reducer;
