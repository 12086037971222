import React, { useState } from "react";
import {
  AsyncComboBox,
  FixedField,
  ModalBase,
} from "../../../../../../components";
import { Row } from "reactstrap";
import { formatValueFromAPI } from "../../../../../../coreUtils";
import UteisService from "../../../../../../services/uteis/UteisService";

export const AlterarCfopModal = ({
  isOpen,
  toggle,
  uuidItemEdit,
  idCliente,
  itens,
  editCfopProduto,
}) => {
  const [nomeProduto, setNomeProduto] = useState("");
  const [cfop, setCfop] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setNomeProduto("");
    setCfop("");
  };

  const carregarDados = () => {
    const item = itens.find((e) => e.uuid === uuidItemEdit);
    setCfop(item.cfop);
    setNomeProduto(formatValueFromAPI(item.nome_produto, item.id_produto));
  };

  const handleSubmit = async () => {
    const item = itens.find((e) => e.uuid === uuidItemEdit);
    setLoading(true);
    const trib = await UteisService.calcularImpostosProduto(
      item.id_produto,
      idCliente,
      "VENDA",
      55,
      true,
      cfop,
      item.quantidade,
      item.vlr_tot_prod,
      0
    );
    if (Object.keys(trib).length > 0) {
      editCfopProduto(trib);
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Alterar CFOP de item da NF"
      number="0036_13"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      onConfirm={handleSubmit}
    >
      <Row className="mb-2">
        <FixedField
          label="Produto"
          value={nomeProduto}
          labelStyle={{ color: "black" }}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={10}
          label="CFOP"
          isSearchable
          isConcatField
          concatModelName="cfop"
          defaultValue={cfop}
          onChange={(s) => setCfop(s?.value)}
          defaultOptions
        />
      </Row>
    </ModalBase>
  );
};
