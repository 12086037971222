import React from "react";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSortDate,
} from "../../../../../../coreUtils";
import { Table } from "../../../../../../components";
import { ProdServIcone } from "../../../../../../components/ProdServIcone";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
    sortable: true,
  },
  {
    dataField: "nome_cliente",
    text: "Proprietário",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    sortable: true,
  },
  {
    dataField: "km",
    text: "KM",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "abertura",
    text: "Data",
    align: "center",
    headerAlign: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "vlr_total",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columnsDetail = [
  {
    dataField: "tipo",
    text: "T",
    align: "center",
    formatter: (c) => <ProdServIcone value={c} />,
  },
  {
    dataField: "id_item",
    text: "#",
    align: "center",
    formatter: (c, row) => {
      if (row.tipo === "Produto") {
        return row.id_produto;
      } else if (row.tipo === "Serviço") {
        return row.id_servico;
      } else {
        return row.id_terceirizacao;
      }
    },
  },
  {
    dataField: "referencia",
    text: "Referência",
    align: "center",
  },
  {
    dataField: "descricao",
    text: "Descrição",
    align: "left",
  },
  {
    dataField: "quantidade",
    text: "Qtd",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_item",
    text: "Vlr Item",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const HistoricoVeiculoGrid = ({ dados }) => {
  return (
    <Table
      data={dados instanceof Array ? dados : []}
      columns={columns}
      paginated={false}
      canExpand
      multiExpand
      expandedAreaStyle={{
        backgroundColor: "rgb(233, 247, 255)",
        boderRadius: "none",
      }}
      fixedSize={false}
      expandedAreaBuilder={detalhesOSHistoricoVeiculo}
    />
  );
};

const concatenarObserv = (lista) =>
  lista.filter((e) => (e ?? "").trim() !== "").join("\n");

const detalhesOSHistoricoVeiculo = (row) => {
  const obsEmi = concatenarObserv([row.obs_emi1, row.obs_emi2, row.obs_emi3]);
  const obsFec = concatenarObserv([row.obs_fec1, row.obs_fec2, row.obs_fec3]);
  const defeito = concatenarObserv([
    row.defeito1,
    row.defeito2,
    row.defeito3,
    row.defeito4,
    row.defeito5,
  ]);

  return (
    <div className="m-0 p-0">
      <div
        style={{
          width: "100%",
        }}
      >
        <p className="mb-1">
          <b>Descrição do Problema: </b>
          {defeito}
        </p>
        {obsEmi && (
          <p className="mb-1">
            <b>Obs. Abertura: </b>
            {obsEmi}
          </p>
        )}
        {obsFec && (
          <p className="mb-1">
            <b>Obs. Fechamento: </b>
            {obsFec}
          </p>
        )}
      </div>
      <Table
        data={row.itens}
        columns={columnsDetail}
        paginated={false}
        pageSize={row.itens.length}
        showRegisterCount={false}
        growIntoPageSize
      />
    </div>
  );
};
