import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id_tipo_doc: null,
  ultimo_rps: 0,
  ultimo_lote_rps: 0,
  camin_logo_prefeit: "",
  camin_salvar_xml: "",
  natur_oper_padrao: 1,
  regime_espec: 0,
  incentiv_cultural: false,
  id_servico_padrao_fatura: null,
  usuario_acesso_provedor_nfse: null,
  senha_acesso_provedor_nfse: null,
  provedor: "NAO",
  local_presta_serv_padrao: "EMI",
};

const fieldsToNull = ["id_tipo_doc"];

export const nfServicoSlice = createSlice({
  name: "nf_servico_slice",
  initialState: initialState,
  reducers: {
    setupNfServico: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value === 0 && fieldsToNull.includes(key)) {
          value = null;
        }

        state[key] = value;
      });
      return state;
    },
    setIdTipoDoc: (state, action) => {
      state.id_tipo_doc = action.payload;
    },
    setUltimoRps: (state, action) => {
      state.ultimo_rps = action.payload;
    },
    setUltimoLoteRps: (state, action) => {
      state.ultimo_lote_rps = action.payload;
    },
    setCaminLogoPrefeit: (state, action) => {
      state.camin_logo_prefeit = action.payload;
    },
    setCaminSalvarXml: (state, action) => {
      state.camin_salvar_xml = action.payload;
    },
    setNaturOperPadrao: (state, action) => {
      state.natur_oper_padrao = action.payload;
    },
    setRegimeEspec: (state, action) => {
      state.regime_espec = action.payload;
    },
    setIncentivCultural: (state, action) => {
      state.incentiv_cultural = action.payload;
    },
    setIdServicoPadraoFatura: (state, action) => {
      state.id_servico_padrao_fatura = action.payload;
    },
    setUsuarioAcessoProvedorNfse: (state, action) => {
      state.usuario_acesso_provedor_nfse = action.payload;
    },
    setSenhaAcessoProvedorNfse: (state, action) => {
      state.senha_acesso_provedor_nfse = action.payload;
    },
    setProvedor: (state, action) => {
      state.provedor = action.payload;
    },
    setLocalPrestaServPadrao: (state, action) => {
      state.local_presta_serv_padrao = action.payload;
    },
  },
});

export const {
  setupNfServico,
  setIdTipoDoc,
  setUltimoRps,
  setUltimoLoteRps,
  setCaminLogoPrefeit,
  setCaminSalvarXml,
  setNaturOperPadrao,
  setRegimeEspec,
  setIncentivCultural,
  setIdServicoPadraoFatura,
  setUsuarioAcessoProvedorNfse,
  setSenhaAcessoProvedorNfse,
  setProvedor,
  setLocalPrestaServPadrao,
} = nfServicoSlice.actions;

export default nfServicoSlice.reducer;
