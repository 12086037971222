import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  ativo: true,
  nome: "",
  referencia: "",
  refer_aux_1: "",
  refer_aux_2: "",
  refer_aux_3: "",
  refer_aux_4: "",
  cod_bar: "",
  id_grupo: 0,
  id_sub_grupo: 0,
  id_fabricante: 0,
  id_anp: null,
  id_classe: null,
  id_similar: 0,
  clas_fiscal: "",
  id_cfop: "",
  quantidade: 0,
  unidade: "",
  id_tipo_prod: null,
  preco_med: 0,
  preco_venda: 0,
  preco_atacado: 0,
  custo_ue: 0,
  custo_med: 0,
  custo_max: 0,
  perc_comis: 0,
  perc_desc_fixo: 0,
  perc_desc_max_vend: 0,
  perc_lucro: 0,
  perc_lucro_atacado: 0,
  control_est: true,
  origem_merc: "0",
  local: "",
  observ: "",
  ultima_entrada: null,
  enviar_mobile_forca_venda: false,
  est_min: 0,
  preco_min_permitido: 0,
  cest: "",
  peso_unit_bruto: 0,
  peso_unit_liquido: 0,
  fotos: [],
  id_grupo_trib: null,
  gera_comissao: true,
  entra_calc_marg_lucro_venda: true,
  cod_balanca: "",
  permite_alt_preco: false,
  usa_caixa_fec: false,
  qtd_caixa_fec: 0,
  tamanho: null,
  cor: null,
  pronta_entr_solic_rec_usa_caixa_fec: false,
  pronta_entr_solic_rec_qtd_caixa_fec: 0,
  dias_validade: 0,
};

export const cadastroProduto = createSlice({
  name: "cadastro_produto",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setAtivo: (state, action) => {
      state.ativo = action.payload;
    },
    setNome: (state, action) => {
      state.nome = action.payload;
    },
    setReferencia: (state, action) => {
      state.referencia = action.payload;
    },
    setReferAux1: (state, action) => {
      state.refer_aux_1 = action.payload;
    },
    setReferAux2: (state, action) => {
      state.refer_aux_2 = action.payload;
    },
    setReferAux3: (state, action) => {
      state.refer_aux_3 = action.payload;
    },
    setReferAux4: (state, action) => {
      state.refer_aux_4 = action.payload;
    },
    setCodBar: (state, action) => {
      state.cod_bar = action.payload;
    },
    setIdGrupo: (state, action) => {
      state.id_grupo = action.payload;
    },
    setIdSubGrupo: (state, action) => {
      state.id_sub_grupo = action.payload;
    },
    setIdFabricante: (state, action) => {
      state.id_fabricante = action.payload;
    },
    setIdAnp: (state, action) => {
      state.id_anp = action.payload;
    },
    setIdClasse: (state, action) => {
      state.id_classe = action.payload;
    },
    setIdSimilar: (state, action) => {
      state.id_similar = action.payload;
    },
    setClasFiscal: (state, action) => {
      state.clas_fiscal = action.payload;
    },
    setIdCfop: (state, action) => {
      state.id_cfop = action.payload;
    },
    setQuantidade: (state, action) => {
      state.quantidade = action.payload;
    },
    setUnidade: (state, action) => {
      state.unidade = action.payload;
    },
    setIdTipoProd: (state, action) => {
      state.id_tipo_prod = action.payload;
    },
    setPrecoMed: (state, action) => {
      state.preco_med = action.payload;
    },
    setPrecoVenda: (state, action) => {
      state.preco_venda = action.payload;
    },
    setPrecoAtacado: (state, action) => {
      state.preco_atacado = action.payload;
    },
    setCustoUe: (state, action) => {
      state.custo_ue = action.payload;
    },
    setCustoMed: (state, action) => {
      state.custo_med = action.payload;
    },
    setCustoMax: (state, action) => {
      state.custo_max = action.payload;
    },
    setPercComis: (state, action) => {
      state.perc_comis = action.payload;
    },
    setPercDescFixo: (state, action) => {
      state.perc_desc_fixo = action.payload;
    },
    setPercDescMaxVend: (state, action) => {
      state.perc_desc_max_vend = action.payload;
    },
    setPercLucro: (state, action) => {
      state.perc_lucro = action.payload;
    },
    setPercLucroAtacado: (state, action) => {
      state.perc_lucro_atacado = action.payload;
    },
    setControlEst: (state, action) => {
      state.control_est = action.payload;
    },
    setOrigemMerc: (state, action) => {
      state.origem_merc = action.payload;
    },
    setLocal: (state, action) => {
      state.local = action.payload;
    },
    setObserv: (state, action) => {
      state.observ = action.payload;
    },
    setUltimaEntrada: (state, action) => {
      state.ultima_entrada = action.payload;
    },
    setEnviarMobileForcaVenda: (state, action) => {
      state.enviar_mobile_forca_venda = action.payload;
    },
    setEstMin: (state, action) => {
      state.est_min = action.payload;
    },
    setPrecoMinPermitido: (state, action) => {
      state.preco_min_permitido = action.payload;
    },
    setCest: (state, action) => {
      state.cest = action.payload;
    },
    setPesoUnitBruto: (state, action) => {
      state.peso_unit_bruto = action.payload;
    },
    setPesoUnitLiquido: (state, action) => {
      state.peso_unit_liquido = action.payload;
    },
    setFotos: (state, action) => {
      state.fotos = action.payload;
    },
    setIdGrupoTrib: (state, action) => {
      state.id_grupo_trib = action.payload;
    },
    setGeraComissao: (state, action) => {
      state.gera_comissao = action.payload;
    },
    setEntraCalcMargLucroVenda: (state, action) => {
      state.entra_calc_marg_lucro_venda = action.payload;
    },
    setCodBalanca: (state, action) => {
      state.cod_balanca = action.payload;
    },
    setPermiteAltPreco: (state, action) => {
      state.permite_alt_preco = action.payload;
    },
    setUsaCaixaFec: (state, action) => {
      state.usa_caixa_fec = action.payload;
    },
    setQtdCaixaFec: (state, action) => {
      state.qtd_caixa_fec = action.payload;
    },
    setTamanho: (state, action) => {
      state.tamanho = action.payload;
    },
    setCor: (state, action) => {
      state.cor = action.payload;
    },
    setProntaEntrSolicRecUsaCaixaFec: (state, action) => {
      state.pronta_entr_solic_rec_usa_caixa_fec = action.payload;
    },
    setProntaEntrSolicRecQtdCaixaFec: (state, action) => {
      state.pronta_entr_solic_rec_qtd_caixa_fec = action.payload;
    },
    setDiasValidade: (state, action) => {
      state.dias_validade = action.payload;
    },
  },
});

export const {
  init,
  setup,
  setAtivo,
  setNome,
  setReferencia,
  setReferAux1,
  setReferAux2,
  setReferAux3,
  setReferAux4,
  setCodBar,
  setIdGrupo,
  setIdSubGrupo,
  setIdFabricante,
  setIdAnp,
  setIdClasse,
  setIdSimilar,
  setClasFiscal,
  setIdCfop,
  setQuantidade,
  setUnidade,
  setIdTipoProd,
  setPrecoMed,
  setPrecoVenda,
  setPrecoAtacado,
  setCustoUe,
  setCustoMed,
  setCustoMax,
  setPercComis,
  setPercDescFixo,
  setPercDescMaxVend,
  setPercLucro,
  setPercLucroAtacado,
  setControlEst,
  setOrigemMerc,
  setLocal,
  setObserv,
  setUltimaEntrada,
  setEnviarMobileForcaVenda,
  setEstMin,
  setPrecoMinPermitido,
  setCest,
  setPesoUnitBruto,
  setPesoUnitLiquido,
  setFotos,
  setIdGrupoTrib,
  setGeraComissao,
  setEntraCalcMargLucroVenda,
  setCodBalanca,
  setPermiteAltPreco,
  setUsaCaixaFec,
  setQtdCaixaFec,
  setTamanho,
  setCor,
  setProntaEntrSolicRecUsaCaixaFec,
  setProntaEntrSolicRecQtdCaixaFec,
  setDiasValidade,
} = cadastroProduto.actions;

export default cadastroProduto.reducer;
