import React, { useState } from "react";
import {
  Divider,
  FixedField,
  ModalBase,
  Table,
} from "../../../../../components";
import OrdemProducaoService from "../../../../../services/industria/OrdemProducaoService";
import { Row } from "reactstrap";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const headerRowStyle = {
  justifyContent: "space-between",
  marginBottom: "0.5rem",
  marginRight: "-15px",
};

const columnsInsumos = [
  { dataField: "desc_tipo_insumo", text: "Tipo", align: "center" },
  {
    dataField: "nome_produto_insumo",
    text: "Insumo",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto_insumo),
  },
  {
    dataField: "qtd_ficha_tecnica",
    text: "Qtd. Ficha Técnica",
    align: "center",
    formatter: (c) => formatNumber(c, true, 3),
  },
  {
    dataField: "quantidade_projetada",
    text: "Qtd. Projetada",
    align: "center",
    formatter: (c) => formatNumber(c, true, 3),
  },
  {
    dataField: "quantidade_utilizada",
    text: "Qtd. Utilizada",
    align: "center",
    formatter: (c) => c && formatNumber(c, true, 3),
  },
];

const columnsPerdas = [
  { dataField: "desc_tipo_insumo", text: "Tipo", align: "center" },
  {
    dataField: "nome_produto_insumo",
    text: "Insumo",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto_insumo),
  },
  {
    dataField: "quantidade_perda",
    text: "Qtd. Perda",
    align: "center",
    formatter: (c) => formatNumber(c, true, 3),
  },
];

export const DetalhesOrdemProducaoModal = ({ isOpen, toggle, selected }) => {
  const [dados, setDados] = useState({});
  const limparDados = () => {
    setDados({});
  };

  const carregarDados = async () => {
    const [ok, ret] = await OrdemProducaoService.buscar(selected);
    if (ok) {
      setDados(ret);
    } else {
      toggle();
    }
  };

  const insumos = dados?.insumos ?? [];
  const perdas = dados?.perdas ?? [];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Detalhes da Ordem de Produção"
      number="0095_2"
      onClosed={limparDados}
      onBeforeOpen={carregarDados}
    >
      <Row style={headerRowStyle}>
        <FixedField
          label="Produto Final"
          value={formatValueFromAPI(
            dados?.nome_produto_final,
            dados?.id_produto_final
          )}
          horizontal
        />
        <FixedField label="Status" value={dados?.desc_status} horizontal />
        <FixedField label="ID" value={selected} horizontal />
      </Row>
      <Row style={headerRowStyle}>
        {dados?.status === "FIN" && (
          <FixedField
            label="Finalizada em"
            value={dados?.data_hora_conclusao ?? ""}
            horizontal
          />
        )}
        <FixedField
          label="Quantidade Projetada"
          value={formatNumber(dados?.quantidade_produzir, true, 3, true)}
          horizontal
        />
        <FixedField
          label="Quantidade Produzida"
          value={
            dados?.quantidade_produzida
              ? formatNumber(dados?.quantidade_produzida, true, 3, true)
              : ""
          }
          horizontal
        />
      </Row>
      <Divider>Insumos</Divider>
      <Table
        data={insumos}
        columns={columnsInsumos}
        showRegisterCount={false}
        pageSize={insumos.length < 10 ? insumos.length : 10}
        paginated={false}
      />
      <Divider>Perdas</Divider>
      <Table
        data={perdas}
        columns={columnsPerdas}
        showRegisterCount={false}
        pageSize={perdas.length < 10 ? perdas.length : 4}
        paginated={false}
      />
    </ModalBase>
  );
};
