import React, { forwardRef, useImperativeHandle, useState } from "react";
import FormButton from "./FormButton";
import { MODAL_ACTIONS } from "../coreUtils";
import { apiPost, apiPut } from "../api";
import { ModalExcluir } from "./ModalExcluir";
import { ModalBase } from "./ModalBase";
import { useRef } from "react";
import { ConcatShortcut } from "./ConcatShortcut";
import { apiGetV2 } from "../apiV2";

export const ModalCadastro = forwardRef(
  (
    {
      title = "Sem Título",
      size = "lg",
      onOpen,
      onClose,
      fetchData,
      submitPayload,
      headerCheck,
      routes,
      routesBase,
      number = "Sem Número",
      deleteModalNumber = "Sem Número",
      deleteMessage,
      canDelete = true,
      deleteHint,
      selected,
      buttonsPadded = true,
      buttonsDivClassName = "",
      notifyEvent,
      addButtonText = "Incluir",
      loadingFetch,
      noDelete = false,
      autoTitle = true,
      hideEditButton,
      hideDeleteButton,
      onKeyDown,
      paramsName,
      footerActions,
      concatShortcut,
      concatModelName,
      bodyStyle,
      footerStyle,
      headerStyle,
      children,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [action, setAction] = useState(MODAL_ACTIONS.ADD);
    const [loading, setLoading] = useState(false);
    const [loadingFetchInternal, setLoadingFetchInternal] = useState(false);
    const deleteModalRef = useRef(null);

    const innerFetchData = async () => {
      const [ok, data] = await apiGetV2(
        routesBase ? `${routesBase}/buscar/${selected}/` : routes.get,
        null,
        { errorMessage: concatModelName !== "unidade" }
      );
      if (ok) {
        fetchData(data);
        return true;
      } else {
        return false;
      }
    };

    const buscarParametros = async () => {
      if (paramsName) {
        setLoadingFetchInternal(true);
        return await apiGetV2(`/tela/${paramsName}/`);
      } else {
        return [true, undefined];
      }
    };

    const toggle = async (action) => {
      if (!isOpen) {
        const [paramOk, paramRet] = await buscarParametros();

        if (action === MODAL_ACTIONS.EDIT && fetchData) {
          setLoadingFetchInternal(true);
          if (!(await innerFetchData())) {
            if (concatShortcut) {
              // Exceção no comportamento para o cadastro de unidade
              if (concatModelName === "unidade") {
                action = MODAL_ACTIONS.ADD;
              }
            } else {
              setLoadingFetchInternal(false);
              return false;
            }
          }
        }
        setLoadingFetchInternal(false);
        if (onOpen) onOpen(action, paramOk ? paramRet : undefined);
      } else {
        if (onClose) onClose(action);
      }
      setAction(typeof action === "number" ? action : MODAL_ACTIONS.ADD);
      setIsOpen(!isOpen);
    };

    const handleSubmit = async () => {
      const payload = submitPayload(action);

      if (payload) {
        setLoading(true);

        let ret =
          action === MODAL_ACTIONS.ADD
            ? await apiPost(
                routesBase ? `${routesBase}/incluir/` : routes.post,
                payload,
                undefined,
                concatShortcut
              )
            : await apiPut(
                routesBase ? `${routesBase}/alterar/` : routes.put,
                payload,
                undefined,
                concatShortcut
              );

        if (ret) {
          if (notifyEvent) {
            if (concatShortcut) {
              if (concatModelName === "unidade") {
                ret = payload.codigo;
              } else {
                if (ret.id_cadastrado) {
                  ret = ret.id_cadastrado;
                }
              }
              notifyEvent(action === MODAL_ACTIONS.ADD ? ret : selected);
            } else {
              notifyEvent(action);
            }
          }

          toggle(action);
        }
        setLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      toggle: (action) => {
        if (action === MODAL_ACTIONS.DELETE && !noDelete) {
          deleteModalRef.current.toggle();
        } else {
          toggle(action);
        }
      },
      setAction: (v) => setAction(v ?? MODAL_ACTIONS.ADD),
    }));

    return (
      <>
        {concatShortcut && concatModelName ? (
          <ConcatShortcut
            concatModelName={concatModelName}
            onClick={() =>
              toggle(
                [0, "", null, undefined].includes(selected)
                  ? MODAL_ACTIONS.ADD
                  : MODAL_ACTIONS.EDIT
              )
            }
          />
        ) : (
          <>
            <FormButton
              padded={buttonsPadded}
              divClassName={buttonsDivClassName}
              md="auto"
              color="info"
              onClick={() => toggle(MODAL_ACTIONS.ADD)}
            >
              {addButtonText}
            </FormButton>
            {!hideEditButton && (
              <FormButton
                o
                padded={buttonsPadded}
                divClassName={buttonsDivClassName}
                md="auto"
                color="warning"
                disabled={[0, null, undefined].includes(selected)}
                onClick={() => toggle(MODAL_ACTIONS.EDIT)}
                loading={loadingFetch || loadingFetchInternal}
                id="bt-alterar"
              >
                Alterar
              </FormButton>
            )}
            {!noDelete && (
              <ModalExcluir
                title={title}
                number={deleteModalNumber}
                selected={selected}
                route={
                  routesBase
                    ? `${routesBase}/excluir/${selected}/`
                    : routes.delete
                }
                notifyEvent={notifyEvent}
                buttonPadded={buttonsPadded}
                message={deleteMessage}
                buttonDivClassName={buttonsDivClassName}
                hideButton={hideDeleteButton}
                canDelete={canDelete}
                deleteHint={deleteHint}
                ref={deleteModalRef}
              />
            )}
          </>
        )}
        <ModalBase
          isOpen={isOpen}
          size={size}
          title={
            <>
              {autoTitle &&
                (action === MODAL_ACTIONS.ADD
                  ? "Inclusão de "
                  : "Alteração de ") + " "}
              {title}
            </>
          }
          headerCheck={headerCheck}
          number={number}
          onConfirm={handleSubmit}
          loadingConfirm={loading}
          toggle={toggle}
          onKeyDown={onKeyDown}
          paramsName={paramsName}
          footerActions={footerActions}
          bodyStyle={bodyStyle}
          headerStyle={headerStyle}
          footerStyle={footerStyle}
        >
          {children}
        </ModalBase>
      </>
    );
  }
);
