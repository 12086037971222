import React from "react";
import { Table } from "../../../../../components";
import { DetalhesLancamentoModal } from "./DetalhesLancamentoModal";

const columns = [
  { dataField: "data_hora", text: "Data/Hora", align: "center" },
  { dataField: "natureza", text: "Natureza", align: "center" },
  { dataField: "descricao", text: "Descrição", align: "left" },
  { dataField: "id_origem", text: "ID Origem", align: "center" },
  { dataField: "colaborador", text: "Colaborador", align: "left" },
  {
    dataField: "det",
    text: "",
    align: "center",
    formatter: (c, row) => <DetalhesLancamentoModal lancamento={row} />,
  },
];

export const LancamentosEstoqueGrid = ({ dados }) => {
  return <Table data={dados} columns={columns} />;
};
