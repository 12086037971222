import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { relListagemValorEstoque } from "../../../routes/modules/relatorios";
import { MdOutlineInventory2 } from "react-icons/md";

export const Estoque = () => {
  return (
    <PageContainer title="Relatórios de Estoque">
      <MenuGroup>
        <MenuGroupButton
          label="Listagem de Valor em Estoque"
          icon={MdOutlineInventory2}
          pathname={relListagemValorEstoque.path}
          nroTela={relListagemValorEstoque.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
