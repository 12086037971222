import { apiDelete, apiGet, apiPost, apiPut, urlParams } from "../../api";
import { apiPutV2 } from "../../apiV2";

const URL_BASE = "/cred_rotativo";
const CredRotativoService = {
  listarContas: async (params) =>
    await apiGet(
      `${URL_BASE}/gerenciamento/listar_contas/${urlParams(params)}`
    ),
  listarDetalhesContas: async (idCliente) =>
    await apiGet(
      `${URL_BASE}/gerenciamento/listar_detalhes_contas/${idCliente}/`
    ),
  estornarRecFatura: async (payload) =>
    await apiPut(`${URL_BASE}/gerenciamento/estornar_rec_fatura/`, payload),
  abrirConta: async (payload) =>
    await apiPost(`${URL_BASE}/gerenciamento/abrir_conta/`, payload),
  listarMovsPendentes: async (idCliente) =>
    await apiGet(`${URL_BASE}/listar_movs_pendentes/${idCliente}/`),
  gerarFatura: async (payload) =>
    await apiPost(`${URL_BASE}/gerar_fatura/`, payload),
  estornarFaturaRotativo: async (idLote) =>
    await apiDelete(`${URL_BASE}/estornar_fatura_rotativo/${idLote}/`),
  estornarRecebimento: async (payload) =>
    await apiPutV2(`${URL_BASE}/estornar_rec_fatura/`, payload),
};

export default CredRotativoService;
