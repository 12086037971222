import axios from "axios";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card } from "reactstrap";
import { kClientId, kClientSecret } from "../api";
import settings from "../settings";

export const BaseInfoTelaCard = ({ nroTela }) => {
  const [baseInfo, setBaseInfo] = useState("");
  const [objetivo, setObjetivo] = useState("");

  const buscarInfo = async () => {
    await axios
      .get(
        `${settings.AUTENTICADOR}/cadastro/menu/buscar_base_informacao/${nroTela}/`,
        {
          timeout: 5000,
          auth: {
            username: kClientId,
            password: kClientSecret,
          },
          validateStatus: false,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          if (data.success) {
            setBaseInfo(data.res.base_informacao);
            setObjetivo(data.res.objetivo);
          }
        } else {
          toastr.error("Erro", res.data);
        }
      })
      .catch((err) => {
        toastr.error(
          "Erro",
          "Falha ao tentar buscar a Base de Informação da Tela."
        );
      });
  };

  useEffect(() => {
    buscarInfo();
  }, [nroTela]);

  return (
    <Card body>
      <b>BASE DE INFORMAÇÕES</b>
      <span>{baseInfo}</span>
      {(objetivo ?? "") !== "" && (
        <>
          <b className="mt-2">OBJETIVO</b>
          <span>{objetivo}</span>
        </>
      )}
    </Card>
  );
};
