import { configureStore } from "@reduxjs/toolkit";
import cadRegraTributacaoSlice from "./cadRegraTributacaoSlice";
import listagemRegraTribSlice from "./listagemRegraTribSlice";

export default configureStore({
  reducer: {
    cadRegraTributacao: cadRegraTributacaoSlice,
    listagemRegraTrib: listagemRegraTribSlice,
  },
});
