import React, { useEffect, useState } from "react";
import {
  ComboBox,
  FormButton,
  IntegerFormInput,
  MaskedInput,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../components";
import { Card, Row } from "reactstrap";
import { PesqModal } from "../../../components/PesqModal";
import { apiGetV2, apiPostV2, apiPutV2 } from "../../../apiV2";
import { SenhaModal } from "../../../components/SenhaModal";
import { RadioItem } from "../../../components/RadioGroup";
import { limparNumero } from "../../../coreUtils";
import { showWarning } from "../../../components/AlertaModal";

const regimeTributOptions = [
  { label: "MEI", value: "MEI" },
  { label: "Simples Nacional", value: "SIM" },
  { label: "Lucro Real", value: "REA" },
  { label: "Lucro Presumido", value: "PRE" },
];
const URL_BASE = "/cadastro/empresa";

const INSC_EST_NAO_CONTRIB = "NAOCONTRIB";

const INSC_EST_ISENTO = "ISENTO";

export const InformacoesEmpresa = () => {
  const idEmpresaUsu = parseInt(localStorage.getItem("id_empresa"));
  const [regimeTribut, setRegimeTribut] = useState(
    regimeTributOptions[0].value
  );
  const [licenca, setLicenca] = useState(null);
  const [nome, setNome] = useState("");
  const [nomeRes, setNomeRes] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [site, setSite] = useState("");
  const [codIbge, setCodIbge] = useState(0);
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [cep, setCep] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [insEst, setInsEst] = useState("");
  const [insMunic, setInsMunic] = useState("");
  const [email, setEmail] = useState("");
  const [fone2, setFone2] = useState("");
  const [ieIsentoNaoCont, setIeIsentoNaoCont] = useState("NOR");
  const [locked, setLocked] = useState(true);
  const [loadingAlterar, setLoadingAlterar] = useState(false);
  const [senhaOpen, setSenhaOpen] = useState(false);

  const carregarDados = async () => {
    const [ok, ret] = await apiGetV2(`${URL_BASE}/buscar/${idEmpresaUsu}/`);
    if (ok) {
      let inscEst = (ret.ins_est ?? "").trim();

      if (![INSC_EST_NAO_CONTRIB, INSC_EST_ISENTO].includes(inscEst)) {
        inscEst = limparNumero(inscEst);
      }

      setLicenca(ret.licenca);
      setRegimeTribut(ret.regime_tribut);
      setNome(ret.nome);
      setNomeRes(ret.nome_res);
      setEndereco(ret.endereco);
      setNumero(ret.numero);
      setComplemento(ret.complemento ?? "");
      setBairro(ret.bairro);
      setSite(ret.site);
      setCodIbge(ret.cod_ibge ?? 0);
      setCidade(ret.cidade);
      setUf(ret.uf);
      setCep(ret.cep);
      setTelefone(ret.telefone ?? "");
      setCnpj(ret.cnpj);
      setInsEst(inscEst);
      setInsMunic(ret.ins_munic ?? "");
      setEmail(ret.email ?? "");
      setFone2(ret.fone_2 ?? "");

      setIeIsentoNaoCont(
        inscEst === INSC_EST_ISENTO
          ? "ISE"
          : inscEst === INSC_EST_NAO_CONTRIB
          ? "NAO"
          : "NOR"
      );
    }
  };

  const verificarSenha = async (senha) => {
    const payload = { senha: senha };
    const [ok] = await apiPostV2("/sistema/verificar_senha_sys/", payload, {
      successMessage: false,
      errorMessageType: "toastr",
    });
    if (ok) {
      setLocked(false);
    }
    return ok;
  };

  const alterar = async () => {
    if (["", null, undefined].includes(nome)) {
      showWarning(
        "O Nome da Empresa não foi informado. " +
          "Por favor, revise os dados e tente novamente"
      );
      return;
    }

    if (["", null, undefined].includes(nomeRes)) {
      showWarning(
        "O Nome Fantasia não foi informado. " +
          "Por favor, revise os dados e tente novamente"
      );
      return;
    }

    if (["", null, undefined].includes(endereco)) {
      showWarning(
        "O Endereço não foi informado. " +
          "Por favor, revise os dados e tente novamente"
      );
      return;
    }

    if (["", null, undefined].includes(numero)) {
      showWarning(
        "O Número do Endereço não foi informado. " +
          "Por favor, revise os dados e tente novamente"
      );
      return;
    }

    if (["", null, undefined].includes(bairro)) {
      showWarning(
        "O Bairro não foi informado. " +
          "Por favor, revise os dados e tente novamente"
      );
      return;
    }

    if (["", null, undefined].includes(cidade)) {
      showWarning(
        "A Cidade não foi informada. " +
          "Por favor, revise os dados e tente novamente"
      );
      return;
    }
    if (["", null, undefined].includes(uf)) {
      showWarning(
        "A UF não foi informada. " +
          "Por favor, revise os dados e tente novamente"
      );
      return;
    }

    if ([0, null, undefined].includes(codIbge)) {
      showWarning(
        "O Código IBGE não foi informado. " +
          "Por favor, revise os dados e tente novamente"
      );
      return;
    }

    if (["", null, undefined].includes(cep)) {
      showWarning(
        "O CEP não foi informado. " +
          "Por favor, revise os dados e tente novamente"
      );
      return;
    }

    if (ieIsentoNaoCont === "NOR" && ["", null, undefined].includes(insEst)) {
      showWarning(
        "A Inscrição Estadual não foi informada. " +
          "Se a empresa for Isenta ou Não Contribuinte, marque " +
          "a opção correspondente."
      );
      return;
    }

    const payload = {
      id: idEmpresaUsu,
      regime_tribut: regimeTribut,
      nome: nome,
      nome_res: nomeRes,
      endereco: endereco,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      site: site,
      cod_ibge: codIbge,
      cidade: cidade,
      uf: uf,
      cep: cep,
      telefone: telefone,
      ins_est: insEst,
      ins_munic: insMunic,
      email: email,
      fone_2: fone2,
    };

    setLoadingAlterar(true);
    const [ok] = await apiPutV2(`${URL_BASE}/alterar/`, payload);
    if (ok) {
      carregarDados();
      setLocked(true);
    }
    setLoadingAlterar(false);
  };

  const handleSetIsentoNaoCont = (v) => {
    setIeIsentoNaoCont(v);
    setInsEst(
      v === "ISE" ? INSC_EST_ISENTO : v === "NAO" ? INSC_EST_NAO_CONTRIB : ""
    );
  };

  const toggleSenha = () => setSenhaOpen(!senhaOpen);

  const onActivate = () => {
    carregarDados();
  };

  useEffect(onActivate, []);

  const onKeyDown = (e) => {
    if (e.key === "F9") {
      if (locked) return;
      alterar();
    }
  };

  return (
    <PageContainer
      title="Informações da Empresa"
      number="conf_07"
      onKeyDown={onKeyDown}
    >
      <Card body>
        <SenhaModal
          isOpen={senhaOpen}
          toggle={toggleSenha}
          title="Gerenciamento do Sistema"
          onConfirm={verificarSenha}
        />
        <Row>
          <IntegerFormInput
            md={2}
            label="Código da Empresa"
            defaultValue={idEmpresaUsu}
            divStyle={{ marginBottom: "11px" }}
            disabled
          />
          <IntegerFormInput
            md={2}
            label="Licença"
            defaultValue={licenca}
            divStyle={{ marginBottom: "11px" }}
            disabled
            colClassName="ml-auto"
          />
        </Row>
        <Row>
          <TextInput
            label="Nome"
            md={8}
            onChange={(e, v) => setNome(v)}
            value={nome}
            maxLength={100}
            autoFocus
            disabled={locked}
          />
        </Row>
        <Row>
          <TextInput
            label="Nome Fantasia"
            md={8}
            onChange={(e, v) => setNomeRes(v)}
            value={nomeRes}
            maxLength={100}
            disabled={locked}
          />
        </Row>
        <Row>
          <TextInput
            label="Endereço"
            md={6}
            onChange={(e, v) => setEndereco(v)}
            value={endereco}
            maxLength={60}
            disabled={locked}
          />
          <TextInput
            label="Número"
            md={1}
            onChange={(e, v) => setNumero(v)}
            value={numero}
            maxLength={10}
            disabled={locked}
          />
          <TextInput
            label="Complemento"
            md={2}
            onChange={(e, v) => setComplemento(v)}
            value={complemento}
            maxLength={40}
            disabled={locked}
          />
          <TextInput
            label="Bairro"
            md={3}
            onChange={(e, v) => setBairro(v)}
            value={bairro}
            maxLength={40}
            disabled={locked}
          />
        </Row>
        <Row>
          <TextInput
            label="Cidade"
            md={5}
            onChange={(e, v) => setCidade(v)}
            value={cidade}
            maxLength={50}
            disabled={locked}
          />
          <TextInput
            label="UF"
            md={1}
            onChange={(e, v) => setUf(v)}
            value={uf}
            maxLength={2}
            disabled={locked}
          />
          <MaskedInput
            mask="99999-999"
            name="cep"
            label="CEP"
            md={2}
            onChange={(e, v) => setCep(v)}
            value={cep}
            disabled={locked}
          />
          <IntegerFormInput
            md={2}
            label="Cod. IBGE"
            defaultValue={codIbge}
            onChange={setCodIbge}
            maxLength={7}
            additionalButton={
              <PesqModal
                route="/cadastro/ibge/listar/"
                keyField="codigo"
                labelField="nome_cid"
                title="Código IBGE"
                value={codIbge}
                onChange={(v) => setCodIbge(v)}
                terc="uf_cid"
                tercText="UF"
                disabled={locked}
              />
            }
            disabled={locked}
          />
        </Row>
        <Row>
          <TextInput
            label="Fone 1"
            md={3}
            onChange={(e, v) => setTelefone(v)}
            value={telefone}
            maxLength={18}
            disabled={locked}
          />
          <TextInput
            label="Fone 2"
            md={3}
            onChange={(e, v) => setFone2(v)}
            value={fone2}
            maxLength={18}
            disabled={locked}
          />
        </Row>
        <Row>
          <ComboBox
            options={regimeTributOptions}
            md={3}
            label="Regime Tributário"
            onChange={(s) => setRegimeTribut(s?.value)}
            defaultValue={regimeTribut}
            isDisabled={locked}
          />
          <MaskedInput
            mask="99.999.999/9999-99"
            label="CNPJ"
            md={3}
            value={cnpj}
            onChange={(e, v) => setCnpj(v)}
            disabled
          />
          <MaskedInput
            label="Inscrição Municipal"
            maskChar={null}
            mask="999999999999999"
            md={3}
            onChange={(e, v) => setInsMunic(v)}
            value={insMunic}
            disabled={locked}
          />
        </Row>
        <Row>
          <RadioGroup
            label="Inscrição Estadual"
            value={ieIsentoNaoCont}
            onChange={handleSetIsentoNaoCont}
            divClassName="mb-2"
            disabled={locked}
          >
            <RadioItem label="Contribuinte" value="NOR" />
            <RadioItem label="Não Contribuinte" value="NAO" />
            <RadioItem label="Isento" value="ISE" />
          </RadioGroup>
          <MaskedInput
            maskChar={null}
            mask="999999999999999999"
            md={3}
            onChange={(e, v) => setInsEst(v)}
            value={ieIsentoNaoCont === "NOR" ? insEst : ""}
            disabled={ieIsentoNaoCont !== "NOR" || locked}
          />
        </Row>
        <Row>
          <TextInput
            label="E-Mail"
            md={5}
            onChange={(e, v) => setEmail(v)}
            value={email}
            maxLength={80}
            forceUppercase={false}
            disabled={locked}
          />
          <TextInput
            label="Site"
            md={5}
            onChange={(e, v) => setSite(v)}
            value={site}
            maxLength={60}
            forceUppercase={false}
            disabled={locked}
          />
        </Row>
        <Row>
          {locked ? (
            <FormButton
              padded={false}
              color="primary"
              onClick={toggleSenha}
              divClassName="ml-auto"
            >
              Desbloquear
            </FormButton>
          ) : (
            <FormButton
              padded={false}
              color="success"
              onClick={alterar}
              loading={loadingAlterar}
              divClassName="ml-auto"
            >
              F9 - Alterar Dados
            </FormButton>
          )}
        </Row>
      </Card>
    </PageContainer>
  );
};
