import React, { useState } from "react";
import { FormButton, ModalBase } from "../../../../../components";
import SolicitacaoRecargaService from "../../../../../services/pronta_entrega/SolicitacaoRecargaService";

export const ExpedirSolicRecargaModal = ({
  selected,
  notifyEvent,
  selectedExpedido,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const handleSubmit = async () => {
    setLoading(true);
    const [ok] = await SolicitacaoRecargaService.expedirRecargaInstantaneaAdm(
      selected
    );
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        color="success"
        onClick={toggle}
        disabled={[0, null, undefined].includes(selected) || selectedExpedido}
        disabledHint={
          [0, null, undefined].includes(selected)
            ? "Selecione uma Solicitação da Lista"
            : "A Solicitação selecionada já foi carregada"
        }
      >
        Carregar Agora
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Carregar Solicitação de Recarga Agora"
        number="0104_1"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <h5 style={{ textAlign: "center" }}>
          Tem certeza de que deseja carregar agora a Solicitação de Recarga Nº{" "}
          {selected}?
        </h5>
      </ModalBase>
    </>
  );
};
