import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  ColorField,
  ComboBox,
  FiltroPeriodoDatas,
  FormCheckbox,
  IntegerFormInput,
  ModalBase,
  Table,
} from "../../../../../components";
import { Row } from "reactstrap";
import {
  formatNumber,
  formatValueFromAPI,
  roundFloat,
} from "../../../../../coreUtils";
import OrcamentosService from "../../../../../services/orcamentos/OrcamentosService";
import { toastr } from "react-redux-toastr";
import FrenteVendaService from "../../../../../services/vendas/FrenteVendaService";
import { coresStatus } from "../../../orcamentos/gerenc_orcamentos/components/GerencOrcamentosGrid";
import { useDispatch } from "react-redux";
import { setIdVenda, setup } from "../store/vendaCabSlice";
import UteisService from "../../../../../services/uteis/UteisService";
import { ModoImprOrcamentoModal } from "./ModoImprOrcamentoModal";
import { apiGetV2 } from "../../../../../apiV2";

const columns = [
  { dataField: "id", text: "#", align: "center" },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  { dataField: "emissao", text: "Emissão", align: "center" },
  {
    dataField: "vlr_total",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "situacao",
    text: "Situação",
    align: "center",
    headerAlign: "center",
    style: { verticalAlign: "middle", padding: 0 },
    formatter: (c, row) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <ColorField
          scheme={coresStatus}
          value={row.status}
          showHint={false}
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
          }}
        />
        {c}
      </div>
    ),
  },
];

const mostrarOptions = [
  { label: "Todos", value: "TOD" },
  { label: "Já transformados em venda", value: "TEV" },
  { label: "Não transformados em venda", value: "ABE" },
  { label: "Rejeitados/Não Aprovados", value: "NAO" },
];

export const OrcamentosModal = ({
  isOpen,
  toggle,
  buscarItens,
  simplesNacional,
  setItens,
}) => {
  const dispatch = useDispatch();
  const [layoutImpressao, setLayoutImpressao] = useState(null);
  const [idOrcamento, setIdOrcamento] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [somenteMeusOrc, setSomenteMeusOrc] = useState(true);
  const [mostrar, setMostrar] = useState(mostrarOptions[2].value);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [modoImprOrcamentoOpen, setModoImprOrcamentoOpen] = useState(false);

  const limparDados = () => {
    setIdOrcamento(null);
    setIdCliente(null);
    setDataIni(new Date());
    setDataFim(new Date());
    setSomenteMeusOrc(true);
    setMostrar(mostrarOptions[2].value);
    setDados([]);
    setSelected(null);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarParams = async () => {
    const [ok, ret] = await apiGetV2("/tela/pesq_orcamento/");
    if (ok) {
      setLayoutImpressao(ret.layout_impressao);
    }
    return ok;
  };

  const onBeforeOpen = async () => {
    if (!(await buscarParams())) {
      toggle();
    }
  };

  const carregarDados = async () => {
    const params = {
      id_orcamento: idOrcamento,
      id_cliente: idCliente,
      somente_meus_orc: somenteMeusOrc,
      mostrar: mostrar,
      data_ini: dataIni,
      data_fim: dataFim,
    };
    setLoadingFetch(true);
    const [ok, ret] = await FrenteVendaService.listarOrcamentos(params);
    setDados(
      ok
        ? ret.map((e) =>
            e.aberto === false && e.status !== "N" ? { ...e, status: "TV" } : e
          )
        : []
    );
    setLoadingFetch(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    if (layoutImpressao === 10) {
      toggleModoImprOrcamento();
    } else {
      await OrcamentosService.imprimirFrenteVenda(selected, false);
    }
    setLoadingImprimir(false);
  };

  const toggleModoImprOrcamento = () =>
    setModoImprOrcamentoOpen(!modoImprOrcamentoOpen);

  const importarOrcamento = async (idOrcamento) => {
    const [ok1, ret1] = await OrcamentosService.buscar(idOrcamento);
    if (ok1) {
      const idCli = ret1.id_cliente;
      const payloadCab = {
        id_cliente: idCli,
        nome_cliente: ret1.id_cliente__nome ?? "", // DEVERIA DEIXAR NULL
        vlr_frete: parseFloat(ret1.vlr_frete ?? "0") ?? 0,
        id_orcamento: idOrcamento,
        cpf_cnpj_cliente: ret1.id_cliente__cpf_cnpj,
        id_premiado: ret1.id_premiado,
        // Esse campo não existe no orçamento
        vda_bonificada: false,
        forma_pag: (ret1.forma_pagto ?? "")?.trim() || null,
        id_cond_pag: ret1.id_cond_pag || null,
      };

      let idVenda;

      const [ok2, ret2] = await FrenteVendaService.incluirCab(
        payloadCab,
        false
      );
      if (ok2) {
        idVenda = ret2.id_frente_venda_salva_cab;
        dispatch(setIdVenda(idVenda));
      } else {
        return false;
      }

      dispatch(
        setup({
          ...payloadCab,
          observ: ret1.observ ?? "",
          forma_pagto: (ret1.forma_pagto ?? "")?.trim(),
        })
      );
      setItens([]);
      for (const item of ret1.itens) {
        const idProdServ = item.id_item;
        const qtd = parseFloat(item.quantidade ?? 0);
        const vItem = parseFloat(item.vlr_item ?? 0);
        const vTotProd = qtd * vItem;

        const vlrUnit = parseFloat(item.vlr_unit);

        const vTotBruto = vlrUnit * qtd;
        let vAcre = parseFloat(item.vlr_tot_acre ?? 0);

        if (vAcre === 0 && roundFloat(vTotProd, 2) > roundFloat(vTotBruto, 2)) {
          vAcre = roundFloat(vTotProd - vTotBruto, 2);
        }

        let payload = {
          id_cab: idVenda,
          tipo: item.tipo,
          id_prod_serv: idProdServ,
          descricao: item.descricao ?? "",
          unidade: item.unidade ?? "",
          id_ambiente: item.id_ambiente ?? 0,
          quantidade: qtd,
          vlr_unit: parseFloat(item.vlr_unit ?? 0),
          vlr_item: vItem,
          perc_desc: parseFloat(item.perc_desc ?? 0),
          vlr_tot_prod_serv: vTotProd,
          vlr_total: vTotProd,
          vlr_desc: parseFloat(item.vlr_tot_desc ?? 0),
          vlr_acre: vAcre,
          perc_ipi: 0,
          vlr_ipi: 0,
          vlr_icms_st: 0,
          vlr_icms_st_unit: 0,
          perc_comis: 0,
          vlr_tot_comis: 0,
          observ: item.observ ?? "",
        };

        if (item.tipo === "P" && !simplesNacional) {
          const trib = await UteisService.calcularImpostosProduto(
            idProdServ,
            idCli,
            "VENDA",
            55,
            true,
            "", // cfop?
            qtd,
            vTotProd,
            0
          );

          if (trib && Object.keys(trib).length > 0) {
            const vIcmsSt = parseFloat(trib.vlr_icms_st);
            const vIpi = parseFloat(trib.vlr_ipi);
            const pIpi = parseFloat(trib.perc_ipi);
            const vIcmsStUnit = roundFloat(vIcmsSt / qtd, 2);
            const vTotal = parseFloat(trib.vlr_total_com_impostos);

            payload = {
              ...payload,

              perc_ipi: pIpi,
              vlr_ipi: vIpi,
              vlr_icms_st: vIcmsSt,
              vlr_icms_st_unit: vIcmsStUnit,
              vlr_total: vTotal,
            };
          } else {
            break;
          }
        }

        const [ok3] = await FrenteVendaService.incluirItem(payload, false);
        if (!ok3) {
          break;
        }
      }
      buscarItens(idVenda);
      return true;
    } else {
      return false;
    }
  };

  const onConfirm = async () => {
    try {
      if ([0, null, undefined].includes(selected)) {
        toastr.warning("Atenção", "Por favor, selecione um orçamento.");
        return false;
      }
      setLoading(true);
      if (await importarOrcamento(selected)) {
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Pesquisa de Orçamentos"
        number="0079_2"
        onConfirm={onConfirm}
        loadingConfirm={loading}
        footerActions={
          <>
            <BotaoImprimir
              padded={false}
              onClick={imprimir}
              loading={loadingImprimir}
              disabled={[0, null, undefined].includes(selected)}
            />
          </>
        }
        onBeforeOpen={onBeforeOpen}
        onClosed={limparDados}
      >
        <Row>
          <IntegerFormInput
            md={2}
            label="Nº Orçamento"
            defaultValue={idOrcamento}
            onChange={(v) => setIdOrcamento(v)}
          />
          <AsyncComboBox
            md={5}
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
            defaultValue={idCliente}
            hideShortcut
          />
          <FormCheckbox
            label="Somente os Meus Orçamentos"
            md="auto"
            onChange={(e) => setSomenteMeusOrc(!somenteMeusOrc)}
            checked={somenteMeusOrc}
          />
        </Row>
        <Row className="mb-2">
          <FiltroPeriodoDatas onChange={handleDate} />
          <ComboBox
            md={3}
            label="Mostrar"
            options={mostrarOptions}
            onChange={(s) => setMostrar(s?.value)}
            defaultValue={mostrar}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loadingFetch} />
        </Row>
        <Table
          data={dados}
          columns={columns}
          paginated={false}
          onSelect={setSelected}
          onRowDoubleClick={onConfirm}
          pageSize={15}
        />
      </ModalBase>
      {isOpen && (
        <ModoImprOrcamentoModal
          isOpen={modoImprOrcamentoOpen}
          toggle={toggleModoImprOrcamento}
          idOrcamento={selected}
        />
      )}
    </>
  );
};
