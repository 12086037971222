import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  ModalBase,
} from "../../../../../components";
import { showWarning } from "../../../../../components/AlertaModal";
import GerencContasPagarService from "../../../../../services/financeiro/GerencContasPagarService";

const ExcluirTitulosModal = ({ titulosSelected, notifyEvent }) => {
  const [idMotivo, setIdMotivo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selecionouTituloPago = titulosSelected.some((e) => e.aberto === false);

  const toggle = () => {
    if (isOpen) {
      setIdMotivo(null);
    }
    setIsOpen(!isOpen);
  };

  const onConfirmInternal = async () => {
    setLoading(true);
    try {
      if ([null, undefined, 0].includes(idMotivo)) {
        showWarning(
          "Por favor, selecione o motivo para exclusão e tente novamente"
        );
        return;
      }

      const payload = {
        id_motivo: idMotivo,
        titulos_excluir: titulosSelected.map((e) => ({ id_titulo: e.id })),
      };
      const [ok, _] = await GerencContasPagarService.excluir(payload);
      if (ok) {
        notifyEvent();
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormButton
        md="auto"
        color="danger"
        onClick={toggle}
        disabled={selecionouTituloPago || titulosSelected.length === 0}
        disabledHint={
          selecionouTituloPago
            ? "Selecione somete títulos abertos"
            : "Selecione ao menos um título da lista"
        }
        padded={false}
        hint="Os títulos selecionados serão excluídos"
      >
        Excluir
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Exclusão de Títulos"
        number="0091_7"
        onConfirm={onConfirmInternal}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            label="Motivo para Exclusão"
            md={12}
            isConcatField
            concatModelName="motivo"
            defaultOptions
            isSearchable
            onChange={(s) => setIdMotivo(s?.value)}
            defaultValue={idMotivo}
          />
        </Row>
      </ModalBase>
    </>
  );
};

export default ExcluirTitulosModal;
