import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  Divider,
  FiltroPeriodoDatas,
  FormButton,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import ProgramacaoProducaoService from "../../../../services/industria/ProgramacaoProducaoService";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
  getFirstDayOfWeek,
  getLastDayOfWeek,
} from "../../../../coreUtils";
import { IncluirAlterarProgProducaoModal } from "./components/IncluirAlterarProgProducaoModal";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { ProgramacaoProducaoGrid } from "./components/ProgramacaoProducaoGrid";
import { ProgramacaoProducaoInsumosGrid } from "./components/ProgramacaoProducaoInsumosGrid";
import OrdemProducaoService from "../../../../services/industria/OrdemProducaoService";

export const ProgramacaoProducao = () => {
  const [idProdutoFinal, setIdProdutoFinal] = useState(null);
  const [dataIni, setDataIni] = useState(getFirstDayOfWeek(new Date()));
  const [dataFim, setDataFim] = useState(
    getLastDayOfWeek(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 7
      )
    )
  );
  const [mostrarGeradas, setMostrarGeradas] = useState(false);
  const [dados, setDados] = useState([]);
  const [insumos, setInsumos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingGerarOrdem, setLoadingGerarOrdem] = useState(false);
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [incluirAlterarOpen, setIncluirAlterarOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    setLoading(true);
    try {
      const params = {
        id_produto_final: idProdutoFinal,
        data_ini: dataIni,
        data_fim: dataFim,
        mostrar_geradas: mostrarGeradas,
      };
      const [ok, ret] = await ProgramacaoProducaoService.listar(params);
      setDados(ok ? ret : []);
      carregarInsumos();
    } finally {
      setLoading(false);
    }
  };

  const carregarInsumos = debounce(async () => {
    const params = {
      id_produto_final: idProdutoFinal,
      data_ini: dataIni,
      data_fim: dataFim,
      mostrar_geradas: mostrarGeradas,
      id_programacao: selected,
    };
    const [ok, ret] = await ProgramacaoProducaoService.listarInsumos(params);
    setInsumos(ok ? ret : []);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) {
      setSelected(selected.filter((e) => e !== lastSelected));
      setLastSelected(null);
    }
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setIncluirAlterarOpen(!incluirAlterarOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const handleSelect = (id, isSelect) => {
    setSelected(
      isSelect ? [...selected, id] : selected.filter((e) => e !== id)
    );
    setLastSelected(isSelect ? id : null);
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  useEffect(() => {
    if (dados.length > 0) {
      carregarInsumos();
    }
  }, [selected]);

  const gerarOrdemProducao = async () => {
    setLoadingGerarOrdem(true);
    try {
      const payload = {
        programacoes: selected.map((e) => ({ id_programacao: e })),
      };
      const [ok, ret] = await OrdemProducaoService.gerarOrdemProducao(payload);
      if (ok) {
        notifyEvent();
        await OrdemProducaoService.imprimirSeparacao(ret.ordens_producao);
      }
    } finally {
      setLoadingGerarOrdem(false);
    }
  };

  return (
    <PageContainer title="Programação de Produção" number="0094">
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            label="Produto Final"
            isSearchable
            isClearable
            concatModelName="produto"
            onChange={(s) => setIdProdutoFinal(s?.value)}
            hideShortcut
          />
          <FiltroPeriodoDatas
            onChange={handleDate}
            defaultOption={null}
            defaultStart={dataIni}
            defaultEnd={dataFim}
          />
          <FormCheckbox
            label="Mostrar Geradas"
            checked={mostrarGeradas}
            onChange={() => setMostrarGeradas(!mostrarGeradas)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <FormButton
            loading={loadingGerarOrdem}
            onClick={gerarOrdemProducao}
            disabled={selected.length === 0}
            disabledHint="Por favor, selecione uma programação na grade"
            color="success"
            md="auto"
          >
            Gerar Ordem de Produção
          </FormButton>
          <IncluirAlterarProgProducaoModal
            isOpen={incluirAlterarOpen}
            toggle={toggleCadastro}
            action={action}
            selected={lastSelected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title="Programação de Produção"
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0094_2"
            selected={lastSelected}
            routeBase={ProgramacaoProducaoService.urlBase}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <ProgramacaoProducaoGrid
          dados={dados}
          alterar={alterar}
          excluir={excluir}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
        />
      </Card>
      <Card body>
        <Divider>Insumos das Programações</Divider>
        <ProgramacaoProducaoInsumosGrid dados={insumos} />
      </Card>
    </PageContainer>
  );
};
