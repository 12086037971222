import React, { useState } from "react";
import { Table, TableDelete } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import FormaRecClienteService from "../../../../../services/cadastro/FormaRecClienteService";
import { IncluirFormaRecForm } from "./FormasRecPadraoModal";

export const FormaRecClienteModal = ({
  isOpen,
  toggle,
  idCliente,
  nomeCliente,
}) => {
  const [dados, setDados] = useState([]);

  const limparDados = () => {
    setDados([]);
  };

  const carregarDados = async () => {
    const [ok, ret] = await FormaRecClienteService.listar(idCliente);
    setDados(ok ? ret : []);
  };

  const handleDelete = async (idReg) => {
    const [ok] = await FormaRecClienteService.excluir(idReg);
    if (ok) {
      carregarDados();
    }
  };

  const handleAdd = async (dados) => {
    const payload = {
      id_cliente: idCliente,
      moeda: dados.moeda,
      id_cond_pag: dados.id_cond_pag,
    };

    const [ok] = await FormaRecClienteService.incluir(payload);
    if (ok) {
      carregarDados();
    }
    return ok;
  };

  const columns = [
    {
      dataField: "moeda",
      text: "Moeda",
      align: "center",
      sortable: true,
    },
    {
      dataField: "id_cond_pag__descricao",
      text: "Condição de Pagamento",
      align: "left",
      sortable: true,
    },
    {
      dataField: "id_cond_pag__parcelas",
      text: "Parcelas",
      align: "center",
      sortable: true,
    },
    {
      dataField: "del",
      isDummyField: true,
      text: "Ações",
      align: "",
      formatter: (c, row) => (
        <TableDelete
          onClick={() => handleDelete(row.id)}
          disabled={row.padrao}
          disabledHint="Formas de recebimento padrão não podem ser excluídas"
        />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={
        <>
          <div> FORMAS DE RECEBIMENTO</div>
          <div style={{ fontSize: "0.75rem" }}> Cliente: {nomeCliente}</div>
        </>
      }
      number="0022_1"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <IncluirFormaRecForm handleSubmit={handleAdd} />
      <Table columns={columns} data={dados} paginated={false} pageSize={10} />
    </ModalBase>
  );
};
