import React, { useRef } from "react";
import {
  CardTotais,
  CardTotaisItem,
  FixedField,
  FormButton,
  IconButton,
  LinkButton,
  Loader,
  PageContainer,
} from "../../../../../components";
import { useSelector, useDispatch } from "react-redux";
import { setItens } from "./store/itens_os_slice";
import { Card, Row } from "reactstrap";
import { useEffect } from "react";
import OrdemServicoService from "../../../../../services/ordem_servico/OrdemServicoService";
import { useState } from "react";
import { IncluirItemOSForm } from "./components/IncluirItemOSForm";
import { ItensOSGrid } from "./components/ItensOSGrid";
import { Provider } from "react-redux";
import {
  MODAL_ACTIONS,
  formatValueFromAPI,
  roundFloat,
} from "../../../../../coreUtils";
import store from "./store";
import {
  FinalizarOSButton,
  FinalizarOSModal,
} from "../components/FinalizarOSModal";
import {
  centralOSRoute,
  historicoVeiculoRoute,
  incluirAlterarOSRoute,
} from "../../../../../routes/modules/ordem_servico";
import { Redirect } from "react-router-dom";
import { TrocarProprietarioVeicModal } from "../incluir_alterar/components/TrocarProprietarioVeicModal";
import { TrocarVeicCli } from "../incluir_alterar/components/TrocarVeicCliModal";
import { loadParams } from "./store/params_os_slice";
import { BsFileText, BsPrinter } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import {
  ConcluirServOSButton,
  ConcluirServOSModal,
} from "../components/ConcluirServOSModal";
import { LancarAdiantamentoOSModal } from "./components/LancarAdiantamentoOSModal";
import AlterarTecnicoHoraItemModal from "./components/AlterarTecnicoHoraItemModal";
import { apiDeleteV2, apiPutV2 } from "../../../../../apiV2";
import DetalhesAdiantamentosModal from "../components/DetalhesAdiantamentosModal";

const headerRowClassName = "mb-1";

const ManutencaoOSContainer = ({ location }) => {
  const selected = location?.state?.selected;
  const store = useSelector((state) => state.itensOS);
  const params = useSelector((state) => state.paramsOS);
  const dispatch = useDispatch();

  const [atualizarValoresItens, setAtualizarValoresItens] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [loadingImprimirAbert, setLoadingImprimirAbert] = useState(false);
  const [concluirServOpen, setConcluirServOpen] = useState(false);
  const [finalizarOpen, setFinalizarOpen] = useState(false);
  const [detalhesAdiantamentosOpen, setDetalhesAdiantamentosOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);

  // Comum
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [enderecoCliente, setEnderecoCliente] = useState("");
  const [cpfCnpjCliente, setCpfCnpjCliente] = useState("");
  const [cidadeCliente, setCidadeCliente] = useState("");
  const [fone1Cliente, setFone1Cliente] = useState("");
  const [fone2Cliente, setFone2Cliente] = useState("");
  const [fone3Cliente, setFone3Cliente] = useState("");
  const [dataEmissao, setDataEmissao] = useState("");
  const [horaEmissao, setHoraEmissao] = useState("");
  const [problema, setProblema] = useState("");
  const [dataPre, setDataPre] = useState(null);
  const [horaPre, setHoraPre] = useState(null);
  const [observ, setObserv] = useState("");
  const [totais, setTotais] = useState({});
  const [status, setStatus] = useState("");
  const [adiantamentosOs, setAdiantamentosOs] = useState([]);
  // Mec
  const [km, setKm] = useState(null);
  const [placa, setPlaca] = useState("");
  const [veiculo, setVeiculo] = useState("");
  const [modeloVeic, setModeloVeic] = useState("");
  const [anoVeic, setAnoVeic] = useState("");
  const [combustivelVeic, setCombustivelVeic] = useState("");
  const [corVeic, setCorVeic] = useState("");
  const [chassiVeic, setChassiVeic] = useState("");
  // Mad
  const [nomeEquipamento, setNomeEquipamento] = useState(null);
  const [marcaEquipamento, setMarcaEquipamento] = useState(null);
  const [capacidadeEquipamento, setCapacidadeEquipamento] = useState(null);
  // Web
  const [contatoServ, setContatoServ] = useState("");
  const [nomeTecnico, setNomeTecnico] = useState("");
  const [dataAgendada, setDataAgendada] = useState(null);
  const [horaAgendada, setHoraAgendada] = useState(null);

  const alterarItemOsModalRef = useRef();

  const toggleDetalhesAdiantamentos = () =>
    setDetalhesAdiantamentosOpen(!detalhesAdiantamentosOpen);

  const carregarParametros = async () => {
    const ret = await OrdemServicoService.buscarParametros();
    if (ret) {
      dispatch(
        loadParams({
          tipoSist: ret.tipo_sist,
          identificaEquipamento: ret.abrir_os_identifica_equipamento,
          identificaDataHoraAgendamento:
            ret.abrir_os_identifica_data_hora_agendamento,
          identificaContatoServico: ret.abrir_os_identifica_contato_servico,
          identificaTecnicoResp: ret.abrir_os_identifica_tecnico_resp,
          imprFech: ret.impr_fech_os,
          modoAdicaoItemGrid: ret.adiciona_item_grid,
          priorizaPesqProd: ret.priorizar_pesq_prod,
          identificaTecnico: ret.identifica_tecnico,
          utilizaObservItem: ret.utiliza_observ_item,
          imprimeReferFech: ret.imprime_refer_fech,
          utilizaStatusAdicConcServ: ret.utiliza_status_adic_conc_serv,
          layoutImprEmis: ret.layout_impr_emis_os,
          layoutImprFech: ret.layout_impr_fech_os,
        })
      );
    }
  };

  const sistAutomotivo = params.tipoSist === "MECANICA";

  const recalcularValoresGrade = (coluna, novoValor, row) => {
    // Se o valor não for alterado, não recalcula nada
    const alterouValorNumerico = !["descricao", "observ"].includes(coluna);
    let novoValorTest;
    let valorAntesTest;
    if (alterouValorNumerico) {
      const casasDecTeste = ["quantidade", "vlr_item"].includes(coluna) ? 4 : 2;
      novoValorTest = roundFloat(parseFloat(novoValor), casasDecTeste);
      valorAntesTest = roundFloat(parseFloat(row[coluna]), casasDecTeste);
    } else {
      novoValorTest = novoValor;
      valorAntesTest = row[coluna];
    }

    if (novoValorTest === valorAntesTest) {
      return false;
    }

    if (coluna === "descricao") {
      row.descricao = novoValor;
    } else if (coluna === "observ") {
      row.observ = novoValor;
    } else if (coluna === "quantidade") {
      if (novoValor === undefined || novoValor === null) {
        novoValor = "0";
      }
      const quantidade = parseFloat(novoValor);
      const vlrItem = parseFloat(row.vlr_item);
      const vlrUnit = parseFloat(row.vlr_unit);

      let novoVlrDesc;
      let novoPercDesc;
      let novoVlrAcre;
      if (vlrUnit > vlrItem) {
        novoVlrDesc = quantidade * (vlrUnit - vlrItem);
        let vlrDescUnit = vlrUnit - vlrItem;
        if (vlrUnit > 0) {
          novoPercDesc = roundFloat((vlrDescUnit / vlrUnit) * 100, 2);
        } else {
          novoPercDesc = 0;
        }
        novoVlrAcre = 0;
      } else {
        novoVlrDesc = 0;
        novoPercDesc = 0;
        if (vlrItem > vlrUnit) {
          novoVlrAcre = quantidade * (vlrItem - vlrUnit);
        } else {
          novoVlrAcre = 0;
        }
      }
      const vlrTotal = vlrItem * quantidade;
      row.quantidade = quantidade;
      row.perc_desc = roundFloat(novoPercDesc, 2);
      row.vlr_desc = roundFloat(novoVlrDesc, 2);
      row.vlr_acre = roundFloat(novoVlrAcre, 2);
      row.vlr_total = roundFloat(vlrTotal, 2);
    } else if (coluna === "vlr_item") {
      if (novoValor === undefined || novoValor === null) {
        novoValor = "0";
      }
      const quantidade = parseFloat(row.quantidade);
      const vlrItem = parseFloat(novoValor);
      const vlrUnit = parseFloat(row.vlr_unit);

      let novoVlrDesc;
      let novoPercDesc;
      let novoVlrAcre;
      if (vlrUnit > vlrItem) {
        novoVlrDesc = quantidade * (vlrUnit - vlrItem);
        let vlrDescUnit = vlrUnit - vlrItem;
        if (vlrUnit > 0) {
          novoPercDesc = roundFloat((vlrDescUnit / vlrUnit) * 100, 2);
        } else {
          novoPercDesc = 0;
        }
        novoVlrAcre = 0;
      } else {
        novoVlrDesc = 0;
        novoPercDesc = 0;
        if (vlrItem > vlrUnit) {
          novoVlrAcre = quantidade * (vlrItem - vlrUnit);
        } else {
          novoVlrAcre = 0;
        }
      }
      const vlrTotal = vlrItem * quantidade;
      row.perc_desc = roundFloat(novoPercDesc, 2);
      row.vlr_desc = roundFloat(novoVlrDesc, 2);
      row.vlr_acre = roundFloat(novoVlrAcre, 2);
      row.vlr_item = roundFloat(vlrItem, 4);
      row.vlr_total = roundFloat(vlrTotal, 2);
    } else if (coluna === "perc_desc") {
      if (novoValor === undefined || novoValor === null) {
        novoValor = "0";
      }
      const percDesc = parseFloat(novoValor);
      const quantidade = parseFloat(row.quantidade);
      const vlrUnit = parseFloat(row.vlr_unit);
      const vlrTotBruto = vlrUnit * quantidade;

      const vlrItem = ((100 - percDesc) / 100) * vlrUnit;
      const vlrTotal = vlrItem * quantidade;
      const vlrDesc = (percDesc / 100) * vlrTotBruto;
      row.perc_desc = roundFloat(percDesc, 2);
      row.vlr_desc = roundFloat(vlrDesc, 2);
      row.vlr_acre = 0;
      row.vlr_item = roundFloat(vlrItem, 4);
      row.vlr_total = roundFloat(vlrTotal, 2);
    } else if (coluna === "vlr_total") {
      if (novoValor === undefined || novoValor === null) {
        novoValor = "0";
      }
      const vlrTotal = parseFloat(novoValor);
      const quantidade = parseFloat(row.quantidade);
      const vlrUnit = parseFloat(row.vlr_unit);
      const vlrTotBruto = vlrUnit * quantidade;

      const vlrItem = roundFloat(vlrTotal / quantidade, 4);
      let novoVlrDesc;
      let novoPercDesc;
      let novoVlrAcre;
      if (vlrUnit > vlrItem) {
        novoVlrDesc = quantidade * (vlrUnit - vlrItem);
        novoPercDesc = roundFloat((novoVlrDesc / vlrTotBruto) * 100, 2);
        novoVlrAcre = 0;
      } else {
        novoVlrDesc = 0;
        novoPercDesc = 0;
        if (vlrItem > vlrUnit) {
          novoVlrAcre = quantidade * (vlrItem - vlrUnit);
        } else {
          novoVlrAcre = 0;
        }
      }
      row.perc_desc = roundFloat(novoPercDesc, 2);
      row.vlr_desc = roundFloat(novoVlrDesc, 2);
      row.vlr_acre = roundFloat(novoVlrAcre, 2);
      row.vlr_item = roundFloat(vlrItem, 4);
      row.vlr_total = roundFloat(vlrTotal, 2);
    }

    setAtualizarValoresItens(true);
    dispatch(
      setItens(
        store.itens.map((element) => {
          if (element.id === row.id && element.tipo === row.tipo) {
            return { ...row };
          } else {
            return element;
          }
        })
      )
    );
  };

  const atualizarDadosItem = async (row) => {
    if (atualizarValoresItens) {
      const payload = {
        id_item: row.id,
        tipo: row.tipo === "Produto" ? "P" : "S",
        descricao: row.descricao,
        quantidade: parseFloat(row.quantidade ?? "0") ?? 0,
        vlr_unit: parseFloat(row.vlr_unit ?? "0") ?? 0,
        vlr_item: parseFloat(row.vlr_item ?? "0") ?? 0,
        perc_desc: parseFloat(row.perc_desc ?? "0") ?? 0,
        vlr_total: parseFloat(row.vlr_total ?? "0") ?? 0,
        vlr_desc: parseFloat(row.vlr_desc ?? "0") ?? 0,
        vlr_acre: parseFloat(row.vlr_acre ?? "0") ?? 0,
        observ: row.observ ?? "",
      };

      await apiPutV2("ordem_servico/itens/alterar/", payload, {
        successMessage: false,
      });

      carregarDados();
    }
  };

  const handleEditTecnicoHorasItemOs = (idItem, tipoItem) => {
    alterarItemOsModalRef.current.open(
      idItem,
      tipoItem === "Produto" ? "P" : "S"
    );
  };

  const carregarDados = async () => {
    const [ok, ret] = await OrdemServicoService.buscar(selected);

    if (ok) {
      const os = ret.os;
      const totaisOS = ret.totais;
      const itensOS = ret.itens;
      setPlaca(os.placa);
      setKm(os.km);
      setIdCliente(os.id_cliente);
      setNomeCliente(os.nome_cliente);
      setCpfCnpjCliente(os.cpf_cnpj_cliente);
      setEnderecoCliente(os.endereco_cliente);
      setCidadeCliente(os.cidade_cliente);
      setFone1Cliente(os.fone1_cliente);
      setFone2Cliente(os.fone2_cliente);
      setFone3Cliente(os.fone3_cliente);
      // Veículo
      setModeloVeic(os.modelo_veic);
      setVeiculo(os.veiculo);
      setAnoVeic(os.ano_veic);
      setCombustivelVeic(os.combustivel_veic);
      setCorVeic(os.cor_veic);
      setChassiVeic(os.chassi_veic);
      // Equipamento
      setNomeEquipamento(os.nome_equipamento);
      setMarcaEquipamento(os.marca_equipamento);
      setCapacidadeEquipamento(os.capacidade_equipamento);
      // OS
      setNomeTecnico(os.nome_tecnico);
      setContatoServ(os.contato_serv);
      setProblema(os.defeito);
      setObserv(os.observ);
      setDataEmissao(os.data_emissao);
      setHoraEmissao(os.hora_emissao);
      setDataPre(os.data_prometido);
      setHoraPre(os.hora_prometido);
      setDataAgendada(os.data_agend);
      setHoraAgendada(os.hora_agend);
      dispatch(setItens(itensOS));
      setTotais(totaisOS);
      setStatus(os.status);
      setAdiantamentosOs(os.adiantamentos);
    }
  };

  const iniciarTela = async () => {
    await carregarParametros();

    await carregarDados();
    setLoading(false);
  };

  const notifyFinalizar = async () => {
    if (["S", "P", "V"].includes(params.imprFech)) {
      await OrdemServicoService.imprimirFechamento(selected);
    }
    setRedirect(true);
  };

  const notifyConcluirServ = () => {
    setRedirect(true);
  };

  const imprimirAbertura = async () => {
    setLoadingImprimirAbert(true);
    await OrdemServicoService.imprimirAbertura(selected);
    setLoadingImprimirAbert(false);
  };

  useEffect(() => {
    iniciarTela();
  }, []);

  const notifyAlteracao = () => {
    carregarDados();
  };

  const removerItem = async (id, tipo) => {
    let _tipo;
    if (tipo === "Serviço") {
      _tipo = "S";
    } else if (tipo === "Terceirização") {
      _tipo = "T";
    } else {
      _tipo = "P";
    }
    const [ok] = await apiDeleteV2(
      `/ordem_servico/itens/excluir/${_tipo}/${id}/`
    );
    if (ok) {
      carregarDados();
    }
  };

  const toggleConcluirServ = () => setConcluirServOpen(!concluirServOpen);

  const toggleFinalizar = () => setFinalizarOpen(!finalizarOpen);

  return loading ? (
    <Loader />
  ) : redirect ? (
    <Redirect
      to={{
        pathname: centralOSRoute.path,
        state: { refresh: true },
      }}
    />
  ) : (
    <>
      <Card body>
        <Row className={headerRowClassName}>
          <FixedField label="Nro. O.S." value={selected} horizontal boldValue />
          {sistAutomotivo && (
            <FixedField label="Placa" value={placa} horizontal />
          )}
          <FixedField
            label="Cliente"
            md={5}
            value={formatValueFromAPI(nomeCliente, idCliente)}
            horizontal
          />
          <FixedField
            label="Emissão"
            value={`${dataEmissao} - ${horaEmissao}`}
            horizontal
          />
          {params.identificaDataHoraAgendamento && (
            <>
              <FixedField
                label="Agendado Para"
                value={
                  dataAgendada
                    ? `${dataAgendada}` +
                      (horaAgendada ? ` - ${horaAgendada}` : "")
                    : "Não Especificado"
                }
                horizontal
              />
            </>
          )}
          <FixedField
            label="Previsão de Entrega"
            value={
              dataPre
                ? `${dataPre}` + (horaPre ? ` - ${horaPre}` : "")
                : "Não Especificado"
            }
            horizontal
          />
        </Row>
        <Row className={headerRowClassName}>
          <FixedField
            md={7}
            label="Endereço"
            value={`${enderecoCliente} - ${cidadeCliente}`}
            horizontal
          />
          <FixedField
            md={3}
            label="CPF/CNPJ"
            value={cpfCnpjCliente}
            horizontal
          />
        </Row>
        <Row className={headerRowClassName}>
          {params.identificaContatoServico && (
            <FixedField md={3} label="Contato" value={contatoServ} horizontal />
          )}
          <FixedField
            md={4}
            label="Telefone(s)"
            value={[fone1Cliente, fone2Cliente, fone3Cliente]
              .filter((e) => !["", null, undefined].includes(e))
              .join(" - ")}
            horizontal
          />
          {params.identificaTecnicoResp && (
            <FixedField
              md={4}
              label="Téc. Responsável"
              value={nomeTecnico}
              horizontal
            />
          )}
        </Row>
        {sistAutomotivo ? (
          <>
            <Row className={headerRowClassName}>
              <FixedField label="Veículo" value={veiculo} horizontal />
              <FixedField label="Modelo" value={modeloVeic} horizontal />
              <FixedField label="Cor" value={corVeic} horizontal />
              <FixedField label="Ano" value={anoVeic} horizontal />
              <FixedField label="Chassi" value={chassiVeic} horizontal />
              <FixedField
                label="Combustível"
                value={combustivelVeic}
                horizontal
              />
              <FixedField label="KM" value={km} horizontal />
            </Row>
          </>
        ) : (
          <></>
        )}
        {params.identificaEquipamento && (
          <Row className={headerRowClassName}>
            <FixedField
              md={4}
              label="Equipamento"
              value={nomeEquipamento}
              horizontal
            />
            <FixedField
              md={3}
              label="Marca"
              value={marcaEquipamento}
              horizontal
            />
            <FixedField
              md={3}
              label="Capacidade"
              value={capacidadeEquipamento}
              horizontal
            />
          </Row>
        )}
        <Row>
          <FixedField md={6} label="Problema" value={problema} horizontal />
        </Row>
        <Row>
          <FixedField
            md={5}
            label="Observação"
            value={observ}
            horizontal
            divClassName="mt-auto mr-auto"
          />
          {sistAutomotivo && (
            <LinkButton
              padded={false}
              md="auto"
              color="primary"
              pathname={historicoVeiculoRoute.path}
              state={{ placa: placa }}
              divClassName="pr-0"
            >
              Histórico do Veículo
            </LinkButton>
          )}
          <LancarAdiantamentoOSModal
            idOS={selected}
            showButtonText={false}
            padded={false}
            notifyEvent={carregarDados}
          />
          {sistAutomotivo && (
            <TrocarProprietarioVeicModal
              idOS={selected}
              notifyEvent={notifyAlteracao}
              placa={placa}
              padded={false}
            />
          )}
          <TrocarVeicCli
            idOS={selected}
            notifyEvent={notifyAlteracao}
            sistAutomotivo={sistAutomotivo}
            padded={false}
          />
          <FormButton
            md="auto"
            padded={false}
            color="primary"
            divClassName="pr-0"
            onClick={imprimirAbertura}
            loading={loadingImprimirAbert}
          >
            <BsPrinter size={20} className="mr-2" />
            Abertura
          </FormButton>
          <LinkButton
            padded={false}
            pathname={incluirAlterarOSRoute.path}
            state={{ action: MODAL_ACTIONS.EDIT, selected: selected }}
            md="auto"
            color="magenta"
            divClassName="pr-0"
          >
            <CiEdit size={20} className="mr-2" />
            Abertura
          </LinkButton>
          {params.utilizaStatusAdicConcServ && status !== "T" ? (
            <ConcluirServOSButton onClick={toggleConcluirServ} padded={false} />
          ) : (
            <FinalizarOSButton onClick={toggleFinalizar} padded={false} />
          )}
          {params.utilizaStatusAdicConcServ && (
            <ConcluirServOSModal
              isOpen={concluirServOpen}
              toggle={toggleConcluirServ}
              idOs={selected}
              notifyEvent={notifyConcluirServ}
            />
          )}
          <FinalizarOSModal
            isOpen={finalizarOpen}
            toggle={toggleFinalizar}
            selected={selected}
            nomeClienteOS={nomeCliente}
            notifyEvent={notifyFinalizar}
          />
        </Row>
      </Card>
      <CardTotais cardClassName="py-1">
        <CardTotaisItem label="Produtos" value={totais?.total_produtos} />
        <CardTotaisItem label="Servicos" value={totais?.total_servicos} />
        <CardTotaisItem
          label="Terceirização"
          value={totais?.total_terceirizacao}
        />
        <CardTotaisItem
          label="Adiantamento"
          value={totais?.total_adiantamento}
          trailing={
            parseFloat(totais?.total_adiantamento ?? "0") > 0 ? (
              <IconButton
                icon={BsFileText}
                className="ml-1"
                hint="Detalhar Adiantamentos"
                style={{
                  transform: "translateY(-1px)",
                }}
                onClick={toggleDetalhesAdiantamentos}
              />
            ) : (
              <></>
            )
          }
        />
        {params.identificaTecnico ? (
          <CardTotaisItem
            label="Horas"
            value={totais?.total_horas}
            format={false}
          />
        ) : (
          <></>
        )}
        <CardTotaisItem label="Desconto" value={totais?.total_desconto} />
        <CardTotaisItem label="Geral" value={totais?.total_geral} />
      </CardTotais>
      <Card body>
        <IncluirItemOSForm idOS={selected} notifyEvent={carregarDados} />
      </Card>
      <Card body>
        <ItensOSGrid
          totais={totais}
          recalcularValoresGrade={recalcularValoresGrade}
          atualizarDadosItem={atualizarDadosItem}
          removerItem={removerItem}
          handleEditItemOs={handleEditTecnicoHorasItemOs}
        />
      </Card>
      <AlterarTecnicoHoraItemModal
        ref={alterarItemOsModalRef}
        automotivo={sistAutomotivo}
        identificaTecnico={params.identificaTecnico}
        notifyEvent={carregarDados}
      />
      <DetalhesAdiantamentosModal
        idOS={selected}
        isOpen={detalhesAdiantamentosOpen}
        toggle={toggleDetalhesAdiantamentos}
        adiantamentos={adiantamentosOs}
      />
    </>
  );
};

export const ManutencaoOS = ({ location }) => {
  return (
    <PageContainer
      title="Manutenção da Ordem de Serviço"
      number="0006_2"
      canGoBack
    >
      <Provider store={store}>
        <ManutencaoOSContainer location={location} />
      </Provider>
    </PageContainer>
  );
};
