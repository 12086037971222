import React, { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { PageContainer, BotaoPesquisar } from "../../../../components";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { CadastroDespesaJornadaModal } from "./components/CadastroDespesaJornadaModal";
import { DespesaJornadaGrid } from "./components/DespesaJornadaGrid";
import { IncluirVincModal } from "./components/IncluirVincModal";
import { VincDespesaGrid } from "./components/VincDespesaGrid";
import DespesasJornadaService from "../../../../services/jornada/despesa/DespesasJornadaService";

export const modalTitleDespesaJornada = "Despesa de Jornada";
export const routesBaseDespesaJornada = DespesasJornadaService.urlBase;

export const DespesasJornada = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [vinculacoes, setVinculacoes] = useState([]);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [excluirVincOpen, setExcluirVincOpen] = useState(false);
  const [vincSelected, setVincSelected] = useState(null);
  const [nomeSelected, setNomeSelected] = useState("");

  const buscarVinculacoesDespesa = async (id) => {
    setVincSelected(null);
    if ([0, null, undefined].includes(id)) {
      setVinculacoes([]);
      return false;
    }
    const [ok, ret] = await DespesasJornadaService.buscarVinculacoesDespesa(id);
    setVinculacoes(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await DespesasJornadaService.listar();
    setDados(ok ? ret : []);
    if (ok) {
      const idDesp =
        ![0, null, undefined].includes(selected) &&
        ret.map((e) => e.id).includes(selected)
          ? selected
          : ret.length > 0
          ? ret[0].id
          : null;
      buscarVinculacoesDespesa(idDesp);
    }
    setLoading(false);
  };

  const handleSelect = (id, is, row) => {
    setSelected(is ? id : null);
    setNomeSelected(is ? row.nome : "");
    buscarVinculacoesDespesa(is ? id : null);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const toggleExcluirVinc = () => setExcluirVincOpen(!excluirVincOpen);

  const excluirVinc = (id) => {
    setVincSelected(id);
    setTimeout(() => {
      toggleExcluirVinc();
    }, 1);
  };

  return (
    <PageContainer
      title="Cadastro de Despesas de Jornada"
      number="0011"
      canGoBack
    >
      <Card body>
        <Row className="mb-2">
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            padded={false}
            divClassName="pr-0"
          />
          <BotaoIncluir toggle={toggleCadastro} padded={false} />
          <CadastroDespesaJornadaModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleDespesaJornada}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0011_2"
            selected={selected}
            routeBase={routesBaseDespesaJornada}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title="Vinculação de Despesa"
            isOpen={excluirVincOpen}
            toggle={toggleExcluirVinc}
            number="0011_4"
            selected={vincSelected}
            routeBase={DespesasJornadaService.vincUrlBase}
            notifyEvent={() => carregarDados()}
            message="Tem certeza de que deseja excluir a vinculação selecionada?"
          />
        </Row>
        <DespesaJornadaGrid
          dados={dados}
          handleSelect={handleSelect}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
      <Card body>
        <Row className="mb-2">
          <Col md={10}>
            <h5
              className="divider-text"
              style={{ marginTop: "0.6rem", marginBottom: 0 }}
            >
              Vinculações da Despesa
            </h5>
          </Col>
          <IncluirVincModal
            selected={selected}
            notifyEvent={notifyEvent}
            nomeDespesa={nomeSelected}
          />
        </Row>
        <VincDespesaGrid
          dados={vinculacoes}
          setSelected={setVincSelected}
          excluirVinc={excluirVinc}
        />
      </Card>
    </PageContainer>
  );
};
