import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BsFillSignpost2Fill } from "react-icons/bs";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { TbFileDollar } from "react-icons/tb";
import {
  centralPedidosRoute,
  pedidosConferenciaValoresRecebidosEmMaosRoute,
  pedidosFlexRepresentantesRoute,
} from "../../../routes/modules/pedidos";

export const PedidosExternos = () => {
  return (
    <PageContainer title="Pedidos Externos">
      <MenuGroup>
        <MenuGroupButton
          label="Central de Pedidos Externos"
          icon={BsFillSignpost2Fill}
          pathname={centralPedidosRoute.path}
          nroTela={centralPedidosRoute.nroTela}
        />
        <MenuGroupButton
          label="Conferência de Valores Recebidos em Mãos"
          icon={FaHandHoldingDollar}
          pathname={pedidosConferenciaValoresRecebidosEmMaosRoute.path}
          nroTela={pedidosConferenciaValoresRecebidosEmMaosRoute.nroTela}
        />
        <MenuGroupButton
          label="Flex de Representantes"
          icon={TbFileDollar}
          pathname={pedidosFlexRepresentantesRoute.path}
          nroTela={pedidosFlexRepresentantesRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
