import React from "react";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  AsyncComboBox,
  FormCheckbox,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
} from "../../../../components";
import { RadioItem } from "../../../../components/RadioGroup";
import {
  setIdPlanoCtaRetDinheiro,
  setIdTipoDocNfeDevol,
  setGerarNfeDevolDefault,
  setFormaDevolDefault,
  setIdPlanoCtaEstornoBancario,
  setIdPlanoCtaEstornoContasRec,
  setIdPlanoCtaDiferencaConfEmMaos,
} from "../store/devolucao_slice";

export const PersonDevolucao = () => {
  const store = useSelector((state) => state.devolucao);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Retirada de Caixa Loja - Plano de Contas Padrão"
          mode="plano_contas"
          value={store.id_plano_cta_ret_dinheiro}
          onChange={(v) => dispatch(setIdPlanoCtaRetDinheiro(v ?? null))}
        />
        <span style={{ marginTop: "auto", lineHeight: 3.5 }}>
          Estorno com Devolução de Dinheiro em forma de retirada no caixa da
          loja
        </span>
      </Row>
      <Row>
        <AsyncComboBox
          md={5}
          label="Tipo de Documento Padrão para NFe de Devolução"
          concatModelName="tipo_documento"
          defaultValue={store.id_tipo_doc_nfe_devol}
          onChange={(s) => dispatch(setIdTipoDocNfeDevol(s?.value ?? null))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Gera Nota Fiscal de Devolução"
          checked={store.gerar_nfe_devol_default}
          onChange={() =>
            dispatch(setGerarNfeDevolDefault(!store.gerar_nfe_devol_default))
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label="Forma de Devolução Padrão"
          value={store.forma_devol_default}
          onChange={(v) => dispatch(setFormaDevolDefault(v))}
        >
          <RadioItem label="Estorno" value="E" />
          <RadioItem label="Troca por Crédito" value="T" />
        </RadioGroup>
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Plano de Contas Padrão Para Estorno de Recebimento Bancário"
          mode="plano_contas"
          value={store.id_plano_cta_estorno_bancario}
          onChange={(v) => dispatch(setIdPlanoCtaEstornoBancario(v ?? null))}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Plano de Contas Padrão Para Estorno de Recebimento - Contas a Receber"
          mode="plano_contas"
          value={store.id_plano_cta_estorno_contas_rec}
          onChange={(v) => dispatch(setIdPlanoCtaEstornoContasRec(v ?? null))}
        />
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Plano de Contas para Diferença de Valores Recebidos em Mãos Mãos"
          mode="plano_contas"
          value={store.id_plano_cta_diferenca_conf_em_maos}
          onChange={(v) =>
            dispatch(setIdPlanoCtaDiferencaConfEmMaos(v ?? null))
          }
        />
      </Row>
    </>
  );
};
