import React, { useState } from "react";
import {
  AsyncComboBox,
  DatePicker,
  FixedField,
  ModalBase,
  Table,
  UnlockToEdit,
} from "../../../../../../components";
import SugestaoCompraService from "../../../../../../services/compras/SugestaoCompraService";
import {
  formatarValor,
  formatDateISO,
  formatNumber,
  naturalSort,
} from "../../../../../../coreUtils";
import { Row } from "reactstrap";
import moment from "moment";
import { showWarning } from "../../../../../../components/AlertaModal";
import { OrdemCompraService } from "../../../../../../services/compras/OrdemCompraService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const columns = [
  { dataField: "tipo", text: "Tipo", align: "center", sortable: true },
  {
    dataField: "id_produto",
    text: "Produto",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "nome_produto",
    text: "Descrição",
    align: "left",
    sortable: true,
  },
  {
    dataField: "unidade_produto",
    text: "UN",
    align: "center",
    sortable: true,
  },
  {
    dataField: "custo_unit",
    text: "Custo",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "estoque_produto",
    text: "Estoque",
    align: "center",
    formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "qtd_sugerida_sis",
    text: "Qtd. Sugerida",
    align: "center",
    formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "qtd_compra",
    text: "Qtd. Compra",
    align: "center",
    formatter: (c, row) => formatarValor(c, row.qtd_casas_decimais_qtd, 0),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_total_compra",
    text: "Vlr. Compra",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const GerarOrdemCompraModal = ({ isOpen, toggle, idSugestao }) => {
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [nomeFornecedor, setNomeFornecedor] = useState("");
  const [idCondPag, setIdCondPag] = useState(null);
  const [dataEmi, setDataEmi] = useState(new Date());
  const [dataEntrega, setDataEntrega] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [qtdProdutos, setQtdProdutos] = useState(0);
  const [vlrTotalCompra, setVlrTotalCompra] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const limparDados = () => {
    setIdFornecedor(null);
    setNomeFornecedor("");
    setIdCondPag(null);
    setDataEmi(new Date());
    setProdutos([]);
    setQtdProdutos(0);
    setVlrTotalCompra(0);
    setDataEntrega(null);
  };

  const carregarDados = async () => {
    const params = { somente_qtd_compra: true };
    const [ok, ret] = await SugestaoCompraService.listarProdutos(
      idSugestao,
      params
    );
    if (ok) {
      if (ret.itens.length === 0) {
        showWarning(
          "Nenhum item da Sugestão teve a quantidade " +
            "de compra informada. Por favor, revise os itens " +
            "da Sugestão de Compra e tente novamente"
        );
        toggle();
        return;
      }
      const totais = ret.totais;
      setQtdProdutos(totais.qtd_produtos);
      setVlrTotalCompra(totais.vlr_total_compra);
      setProdutos(ret.itens);
    } else {
      toggle();
    }
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idFornecedor)) {
      showWarning("Por favor, informe o Fornecedor");
      return;
    }

    if (!(dataEmi instanceof Date)) {
      showWarning("Por favor, verifique a Data de Emissão");
      return;
    }

    const payload = {
      id_sugestao: idSugestao,
      id_fornecedor: idFornecedor,
      nome_fornecedor: nomeFornecedor,
      id_cond_pag: idCondPag,
      data_emi: formatDateISO(dataEmi),
      data_entrega: dataEntrega ? formatDateISO(dataEntrega) : null,
    };
    setLoading(true);
    const [ok, ret] = await SugestaoCompraService.gerarOrdemCompra(payload);
    if (ok) {
      OrdemCompraService.imprimir(ret.id_ordem_compra);
      history.goBack();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Gerar Ordem de Compra via Sugestão"
      number="0016_6"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row>
        <AsyncComboBox
          label="Fornecedor"
          md={6}
          concatModelName="fornecedor"
          onChange={(s) => {
            setIdFornecedor(s?.value);
            setNomeFornecedor(s?.label);
            setIdCondPag(s?.id_cond_pag ?? idCondPag);
          }}
          defaultValue={idFornecedor}
        />
        <UnlockToEdit>
          <DatePicker
            md={2}
            label="Emissão"
            value={dataEmi}
            onChange={(v) => setDataEmi(moment.isMoment(v) ? v.toDate() : v)}
            style={{ textAlign: "center" }}
          />
        </UnlockToEdit>
        <UnlockToEdit>
          <DatePicker
            md={2}
            label="Entrega"
            value={dataEntrega}
            onChange={(v) =>
              setDataEntrega(moment.isMoment(v) ? v.toDate() : v)
            }
            style={{ textAlign: "center" }}
          />
        </UnlockToEdit>
      </Row>
      <Row className="mb-2">
        <AsyncComboBox
          label="Condição de Pagamento"
          md={4}
          concatModelName="cond_pag"
          onChange={(s) => setIdCondPag(s?.value ?? null)}
          defaultValue={idCondPag}
          defaultOptions
        />
      </Row>
      <Table
        data={produtos}
        columns={columns}
        paginated={false}
        pageSize={10}
        showRegisterCount={false}
      />
      <Row>
        <FixedField
          divClassName="ml-auto"
          label="Qtd. Produtos"
          value={qtdProdutos}
          horizontal
        />
        <FixedField
          label="Valor Total de Compra"
          value={formatNumber(vlrTotalCompra, true, 2)}
          horizontal
        />
      </Row>
    </ModalBase>
  );
};
