import React, { useState, useEffect } from "react";
import { Card, Row } from "reactstrap/lib";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  NumberInput,
  PageContainer,
  RadioGroup,
} from "../../../../components";
import { formatDateISO } from "../../../../coreUtils";
import { DesempenhoVendasMetaLucroGrid } from "./components/DesempenhoVendasMetaLucroGrid";
import { docPrintReport } from "../../../../pdf";
import { apiGetV2 } from "../../../../apiV2";
import { RadioItem } from "../../../../components/RadioGroup";

export const DesempenhoVendasMetaLucro = () => {
  const [gerarPor, setGerarPor] = useState("ABX");
  const [margemGerarAcima, setMargemGerarAcima] = useState(0);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [cliente, setCliente] = useState(null);
  const [vendedor, setVendedor] = useState(null);
  const [dados, setDados] = useState([]);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => setDados([]);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const ajustarMargem = () => {
    if (gerarPor === "ABX") setMargemGerarAcima(0);
  };

  useEffect(ajustarMargem, [gerarPor]);

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: cliente,
      id_vendedor: vendedor,
      gerar_por: gerarPor,
      margem_lucro_acima: margemGerarAcima,
    };

    return await apiGetV2(
      `/relatorios/venda_desconto/desempenho_venda_meta_lucro/`,
      params
    );
  };

  const carregarDados = async () => {
    setLoadingPesquisar(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret : []);
    setLoadingPesquisar(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        dados: ret,
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
      };
      await docPrintReport(
        "/relatorios/venda/vendas_abaixo_marg_lucro/",
        payload,
        "5038_1"
      );
    }
    setLoadingImprimir(false);
  };

  useEffect(() => {
    limparDados();
  }, [dataIni, dataFim, cliente, vendedor]);

  return (
    <PageContainer
      title="Desempenho de Vendas por Meta de Lucro"
      number="5038"
      canGoBack
    >
      <Card body>
        <Row>
          <RadioGroup
            label="Gerar relatórios com produtos:"
            value={gerarPor}
            onChange={(v) => setGerarPor(v)}
            divStyle={{ fontSize: "14px" }}
          >
            <RadioItem label="ABAIXO do lucro mínimo" value="ABX" />
            <RadioItem label="ACIMA da margem de lucro" value="ACM" />
          </RadioGroup>
          {gerarPor === "ACM" && (
            <NumberInput
              label="Margem de Lucro"
              md={1}
              value={margemGerarAcima}
              onChange={(v) => setMargemGerarAcima(v)}
            />
          )}
        </Row>
        <Row>
          <AsyncComboBox
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            md={4}
            onChange={(selected) => setCliente(selected?.value)}
            isSearchable
            isClearable
          />
          <AsyncComboBox
            label="Vendedor"
            md={3}
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            isSearchable
            isClearable
            onChange={(s) => setVendedor(s?.value)}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loadingPesquisar} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <Card body>
        <DesempenhoVendasMetaLucroGrid data={dados} />
      </Card>
    </PageContainer>
  );
};
