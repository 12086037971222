import React, { useState } from "react";
import { Table } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import NotaFiscalConsumidorService from "../../../../../services/docs_eletron/NotaFiscalConsumidorService";

const alertasCol = [
  {
    dataField: "alerta",
    text: "Alerta",
    align: "left",
    headerAlign: "left",
    colWidth: "65%",
  },
  {
    dataField: "data_alerta",
    text: "Data",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "hora_alerta",
    text: "Hora",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome_usuario",
    text: "Usuário",
    align: "center",
    headerAlign: "center",
  },
];

export const AlertasNFCeModal = ({ selected, isOpen, toggle }) => {
  const [alertas, setAlertas] = useState([]);

  const carregarDados = async () => {
    const [ok, ret] =
      await NotaFiscalConsumidorService.gerenciamento.buscarAlertas(selected);
    if (ok) {
      setAlertas(ret);
    }
  };

  const limparDados = () => {
    setAlertas([]);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Alertas da NFC-e"
      number="0066_5"
      autoFocus
      hideCancelButton
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <Table
        columns={alertasCol}
        data={alertas}
        paginated={false}
        pageSize={10}
      />
    </ModalBase>
  );
};
