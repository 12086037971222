import React, { useCallback, useEffect, useState } from "react";
import { Card, Spinner } from "reactstrap";
import ComissaoVTSService from "../../../../../services/relatorios/comissionamento/ComissaoVTSService";
import { TabelaBaseVTS } from "./TabelaBase";

const RenderizarTabela = ({ nivel, row }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dados, setDados] = useState([]);

  const buscarDados = useCallback(async () => {
    setIsLoading(true);
    setDados(await ComissaoVTSService.listar(row.url_abaixo));
    setIsLoading(false);
  }, [row.url_abaixo]);

  useEffect(() => {
    buscarDados();
  }, [buscarDados]);

  return isLoading ? (
    <div
      className="d-flex w-100"
      style={{ height: "150px", justifyContent: "center" }}
    >
      <Spinner type="grow" color="primary" className="align-self-center" />
    </div>
  ) : nivel === "cidade" ? (
    <TabelaNivelCidade dados={dados} />
  ) : (
    <TabelaNivelVenda dados={dados} />
  );
};

const TabelaNivelVTS = ({ dados }) => {
  const columns = [
    {
      dataField: "id_vts",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "nome_vts",
      text: "Vendedor Técnico de Serviços",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "num_vendas",
      text: "Nº de Vendas",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão (R$)",
      align: "right",
      headerAlign: "right",
    },
  ];

  return (
    <TabelaBaseVTS
      data={dados}
      columns={columns}
      keyField="id_vts"
      renderer={(row) => <RenderizarTabela nivel="cidade" row={row} />}
    />
  );
};

const TabelaNivelCidade = ({ dados }) => {
  const columns = [
    {
      dataField: "id_cidade",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "nome_cidade",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão (R$)",
      align: "right",
      headerAlign: "right",
    },
  ];

  return (
    <TabelaBaseVTS
      data={dados}
      columns={columns}
      keyField="id_cidade"
      renderer={(row) => <RenderizarTabela nivel="venda" row={row} />}
    />
  );
};

const TabelaNivelVenda = ({ dados }) => {
  const columns = [
    {
      dataField: "id_venda",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "id_cliente",
      text: "# Cliente",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "nome_cliente",
      text: "Nome",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data",
      text: "Data",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "hora",
      text: "Hora",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão (R$)",
      align: "right",
      headerAlign: "right",
    },
  ];

  return (
    <TabelaBaseVTS
      data={dados}
      columns={columns}
      keyField="id_cidade"
      renderer={null}
    />
  );
};

export const TabelasComissionamentoVTS = ({ dados }) => {
  return (
    <Card body>
      <TabelaNivelVTS dados={dados} />
    </Card>
  );
};
