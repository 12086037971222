import React from "react";
import { Card, UncontrolledTooltip } from "reactstrap";
import { DataTable } from "../../../../../components/DataTable";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { DetDespRotaModal } from "./DetDespRotaModal";

export const DesempenhoRotaGrid = ({ data, dataIni, dataFim }) => {
  const columns = [
    {
      dataField: "nome",
      text: "Rota",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vendedor",
      text: "Vendedor",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vlr_despesas",
      text: "Despesas",
      align: "right",
      headerAlign: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_troca",
      text: "Troca",
      align: "right",
      headerAlign: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_bonificacao",
      text: "Bonificação",
      align: "right",
      headerAlign: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão",
      align: "right",
      headerAlign: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_custo_venda",
      text: "Custo Prod.",
      align: "right",
      headerAlign: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "custo_total",
      text: "Custo Total",
      align: "right",
      headerAlign: "right",
      style: { backgroundColor: "#ffcece" },
      headerStyle: { backgroundColor: "#ffcece" },
      formatter: (cell) => formatNumber(cell, true, 2),
      sort: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_venda",
      text: (
        <>
          <span id="tituloVenda">Venda</span>
          <UncontrolledTooltip target="tituloVenda">
            Sem ICMS ST
          </UncontrolledTooltip>
        </>
      ),
      align: "right",
      headerAlign: "right",
      style: { backgroundColor: "#c1e8c1" },
      headerStyle: { backgroundColor: "#c1e8c1" },
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "ticket_medio",
      text: "Tkt. Médio",
      align: "right",
      headerAlign: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sort: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "perc_lucro",
      text: "Perc. Lucro",
      align: "right",
      headerAlign: "right",
      formatter: (cell) => formatNumber(cell, true, 2) + "%",
      sort: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_lucro",
      text: "Lucro",
      align: "right",
      headerAlign: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sort: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "det",
      isDummyField: true,
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <DetDespRotaModal
          id={row.id}
          nome={row.nome}
          dataIni={dataIni}
          dataFim={dataFim}
          total={parseFloat(row.vlr_despesas)}
        />
      ),
    },
  ];

  return (
    <Card body>
      <DataTable data={data} columns={columns} hideSelectColumn paginated />
    </Card>
  );
};
