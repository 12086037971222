import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import logo from "../assets/img/logo/SkillSoft_web.png";
import { isDevEnv, versaoWeb } from "../coreUtils";
import routes from "../routes/index";
import { IconButton } from "./IconButton";
import { CgMenuLeft } from "react-icons/cg";
import { SET_MENU_CURRENT_ROUTES } from "../redux/constants";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { compose } from "redux";
import { ContextMenu } from "../redux/reducers/menuReducer";

const devEnvClass = isDevEnv() ? " dev-env-sidebar" : "";

class Sidebar extends Component {
  componentWillMount() {
    const pathName = this.props.location.pathname;

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome,
      }));
    });
  }

  handleClick = (item) => {
    this.setState((prevState) => ({
      [item]: !prevState[item],
    }));
  };

  getClassIsOpen = (index) => {
    return this.state[index];
  };

  handleRedirectHome = () => {
    if (window.location.pathname !== "/") {
      this.props.menuDispatch({ type: SET_MENU_CURRENT_ROUTES, data: routes });
      this.setState((prevState) => ({
        redirectHome: true,
      }));
      setTimeout(() => {
        this.setState((prevState) => ({
          redirectHome: false,
        }));
      }, 1);
    }
  };

  toggleMenu = () => {
    if (window.location.pathname.startsWith("/configuracoes")) {
      this.handleRedirectHome();
    } else {
      this.props.dispatch(toggleSidebar());
    }
  };

  renderingHandler = (renderRoutes, level) => {
    const { location } = this.props;

    return renderRoutes.map((route, index) => {
      const {
        name,
        badgeColor,
        badgeText,
        icon: Icon,
        path,
        children,
        id,
      } = route;
      if (children) {
        if (children.length > 0) {
          const isOpen = this.getClassIsOpen(path);
          return (
            <li
              className={`sidebar-item ${
                location.pathname.indexOf(path) ||
                (location.pathname === "/" && path === "/dashboard"
                  ? "active"
                  : "")
              }${devEnvClass}`}
              key={index}
            >
              <span
                data-toggle="collapse"
                className={`sidebar-link ${
                  !isOpen ? "collapsed" : ""
                }${devEnvClass}`}
                onClick={() => this.handleClick(path)}
                aria-expanded={isOpen ? "true" : "false"}
              >
                {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
                <span className="align-middle">{name}</span>
                {badgeColor && badgeText ? (
                  <Badge color={badgeColor} size={18} className="sidebar-badge">
                    {badgeText}
                  </Badge>
                ) : null}
              </span>
              <Collapse isOpen={isOpen}>
                <ul
                  id="item"
                  className={`sidebar-dropdown list-unstyled${devEnvClass}`}
                >
                  {this.renderingHandler(children, level + 1)}
                </ul>
              </Collapse>
            </li>
          );
        } else {
          return null;
        }
      } else {
        return (
          <li
            className={`sidebar-item ${
              location.pathname === path ? "active" : ""
            }${devEnvClass}`}
            key={id !== undefined ? id : index}
          >
            <NavLink
              to={path}
              className={`sidebar-link${level > 1 ? level : ""}${devEnvClass}`}
              activeClassName="active"
            >
              {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
              {name}
              {badgeColor && badgeText ? (
                <Badge color={badgeColor} size={18} className="sidebar-badge">
                  {badgeText}
                </Badge>
              ) : null}
            </NavLink>
          </li>
        );
      }
    });
  };

  render() {
    if (this.state.redirectHome) {
      return <Redirect to="/" />;
    }
    const { sidebar, menuCurrentRoutes } = this.props;
    return (
      <nav
        className={`sidebar sidebar-sticky ${
          sidebar.isOpen ? "" : "toggled"
        }${devEnvClass}`}
      >
        <div
          style={{
            marginTop: "calc(100vh - 1.25rem)",
            position: "fixed",
            paddingLeft: "1rem",
            paddingRight: "2px",
            paddingBottom: "2px",
            zIndex: 1,
            backgroundColor: "inherit",
          }}
        >
          <span
            style={{
              opacity: "70%",
              color: isDevEnv() ? "inherit" : "#ced4da",
            }}
          >
            v{versaoWeb}
          </span>
        </div>
        <PerfectScrollbar>
          <div style={{ display: "flex" }}>
            <div className="sidebar-brand">
              <img
                className="ml-auto mr-auto"
                onClick={() => this.handleRedirectHome()}
                src={logo}
                alt="Skillsoft Sistemas"
                style={{ height: "40px" }}
              />
            </div>
            <IconButton
              icon={CgMenuLeft}
              className="sidebar-toggle"
              color="white"
              onClick={this.toggleMenu}
              size={30}
              tooltipPlacement="right"
              hint={
                window.location.pathname.startsWith("/configuracoes")
                  ? "Voltar à Tela Inicial"
                  : "Ocultar/Mostrar Menu"
              }
            />
          </div>
          <ul className={`sidebar-nav${devEnvClass}`}>
            {this.renderingHandler(menuCurrentRoutes, 0)}
          </ul>
        </PerfectScrollbar>
      </nav>
    );
  }
}

export default withRouter(
  compose(
    connect((store) => ({
      sidebar: store.sidebar,
      layout: store.layout,
    })),
    connect(
      (store) => ({ menuCurrentRoutes: store.menuCurrentRoutes }),
      (dispatch) => ({ menuDispatch: dispatch }),
      null,
      {
        context: ContextMenu,
      }
    )
  )(Sidebar)
);
