import React from "react";
import { Table } from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { DetalhesClientesNaoVendidosModal } from "./DetalhesClientesNaoVendidosModal";

export const ClientesNaoVendidosGrid = ({ dados, nomeAgrup, imprimirDet }) => {
  const gerarNomeAgrup = () =>
    nomeAgrup === "vendedores"
      ? "Vendedor/Rota"
      : nomeAgrup === "cidades"
      ? "Cidade"
      : nomeAgrup;

  const gerarNomeItem = (row) =>
    nomeAgrup === "vendedores"
      ? row.nome
      : nomeAgrup === "cidades"
      ? `${row.nome}-${row.uf}`
      : row.nome;

  const columns = [
    {
      dataField: "nome",
      text: gerarNomeAgrup(),
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => gerarNomeItem(row),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "num_clientes",
      text: "Qtd. Total Clientes",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2, true),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "perc_atend",
      text: "Atendidos",
      align: "right",
      headerAlign: "right",
      formatter: (c, row) =>
        `${formatNumber(row.num_atend, true, 2, true)} | ${formatNumber(
          row.perc_atend,
          true,
          2,
          true
        )}%`,
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "perc_nao_atend",
      text: "Não Atendidos",
      align: "right",
      headerAlign: "right",
      formatter: (c, row) =>
        `${formatNumber(row.num_nao_atend, true, 2, true)} | ${formatNumber(
          row.perc_nao_atend,
          true,
          2,
          true
        )}%`,
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "det",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <DetalhesClientesNaoVendidosModal
          nomeItem={gerarNomeItem(row)}
          data={row.clientes_nao_atend}
          imprimir={() => imprimirDet(nomeAgrup, row)}
        />
      ),
    },
  ];

  return <Table data={dados} columns={columns} paginated={false} />;
};
