import React, { useState } from "react";
import SolicitacaoRecargaService from "../../../../../services/pronta_entrega/SolicitacaoRecargaService";
import {
  FixedField,
  LabelButton,
  ModalBase,
  Table,
} from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { showWarning } from "../../../../../components/AlertaModal";
import { apiPutV2 } from "../../../../../apiV2";

export const ConferirRecargaModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [nomeRotaMob, setNomeRotaMob] = useState(null);
  const [dataHoraSolicitacao, setDataHoraSolicitacao] = useState(null);
  const [itens, setItens] = useState([]);

  const [loadingConferir, setLoadingConferir] = useState(false);

  const limparDados = () => {
    setNomeRotaMob(null);
    setDataHoraSolicitacao(null);
    setItens([]);
  };

  const carregarDados = async () => {
    const [ok, ret] = await SolicitacaoRecargaService.buscar(selected);
    if (ok) {
      setDataHoraSolicitacao(ret.data_hora_solicitacao);
      setNomeRotaMob(ret.nome_rota_mob);
      setItens(ret.itens.map((e) => ({ ...e, conferido: false })));
    } else {
      toggle();
    }
  };

  const handleSelect = (idRow, isSelected) => {
    const idxItemAlterado = itens.findIndex((e) => e.id === idRow);

    const itensNovos = [...itens];

    itensNovos[idxItemAlterado].conferido = isSelected;

    setItens(itensNovos);
  };

  const conferirRecarga = async () => {
    setLoadingConferir(true);
    try {
      if (itens.some((e) => !e.conferido)) {
        showWarning(
          "Todos os itens devem ser conferidos para liberar a recarga para carregamento!"
        );
        return;
      }

      const [ok] = await apiPutV2("/jornada/carga_prod/conferir/", {
        id_solicitacao: selected,
      });
      if (ok) {
        notifyEvent();
        toggle();
      }
    } finally {
      setLoadingConferir(false);
    }
  };

  const columns = [
    {
      dataField: "id_produto",
      text: "Produto",
      align: "center",
    },
    {
      dataField: "nome_produto",
      text: "nome",
      align: "left",
    },
    {
      dataField: "unidade_produto",
      text: "Unidade",
      align: "Center",
    },
    {
      dataField: "qtd_solic",
      text: "Quantidade",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "act",
      dummyField: true,
      text: "",
      align: "center",
      formatter: (_, row) => (
        <input
          type={"checkbox"}
          name={`table-sel-inpt-${row.id}`}
          id={`table-sel-inpt-${row.id}`}
          checked={row.conferido}
          onChange={({ target: { checked } }) => handleSelect(row.id, checked)}
          htmlFor={`table-sel-inpt-${row.id}`}
          style={{
            display: "flex",
            margin: "auto",
            width: "16px",
            height: "16px",
          }}
        />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={`Conferir Solicitação de Recarga Nº ${selected}`}
      number="0104_2"
      onClosed={limparDados}
      onBeforeOpen={carregarDados}
      onConfirm={conferirRecarga}
      loadingConfirm={loadingConferir}
      numberStyle={{ marginRight: "10px" }}
      footerActions={
        <div className="mr-auto">
          <LabelButton
            disabled={itens.every((e) => e.conferido)}
            onClick={() =>
              setItens(itens.map((e) => ({ ...e, conferido: true })))
            }
            disabledHint="Todos os itens já estão marcados"
          >
            Marcar Todos
          </LabelButton>
          <LabelButton
            disabled={itens.every((e) => !e.conferido)}
            onClick={() =>
              setItens(itens.map((e) => ({ ...e, conferido: false })))
            }
            disabledHint="Nenhum item está marcado"
          >
            Desmarcar Todos
          </LabelButton>
        </div>
      }
    >
      <Row className="mb-2" style={{ justifyContent: "space-between" }}>
        <FixedField label="Rota" value={nomeRotaMob} horizontal />
        <FixedField label="Solicitado" value={dataHoraSolicitacao} horizontal />
      </Row>
      <Table
        data={itens}
        columns={columns}
        paginated={false}
        pageSize={10}
        multiselect
        hideSelectAll
      />
    </ModalBase>
  );
};
