import React from "react";
import { Table, TableCheck, TableDelete } from "../../../../../../components";
import { formatNumber, naturalSort } from "../../../../../../coreUtils";

export const ItensRegraGrid = ({ dados, handleDeleteItem }) => {
  const columns = [
    {
      dataField: "id_item_regra_de",
      text: "#",
      align: "center",
    },
    {
      dataField: "desc_item_regra_de",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "prod_vai_pro_app",
      text: "APP",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    {
      dataField: "tipo_desconto",
      text: "Tipo de Desconto",
      align: "center",
      formatter: (c) => (c === "PER" ? "Percentual" : "Valor"),
      sortable: true,
    },
    {
      dataField: "custo_prod",
      text: "Custo",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "quantidade_prod",
      text: "Quantidade",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "preco_venda_prod",
      text: "Preço Venda",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "perc_desconto",
      text: "% Desconto",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2, true) + "%",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "preco_venda",
      text: "Preço Venda",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDeleteItem(row.id)} />
      ),
    },
  ];

  return (
    <Table data={dados} columns={columns} paginated={false} pageSize={10} />
  );
};
