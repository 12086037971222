import React, { useEffect, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormButton,
  FormCheckbox,
  ModalBase,
  ModalDetalhesIcone,
  Table,
} from "../../../../../components";
import {
  formatDateISO,
  formatNumber,
  formatValueFromAPI,
} from "../../../../../coreUtils";
import { GerenciamentoFlexService } from "../../../../../services/frente_venda_mobile/GerenciamentoFlexService";
import { TotalizadoresHistoricoFlex } from "./TotalizadoresHistoricoFlex";
import { DetalheVendaProntaEntregaModal } from "../../gerenciamento_vendas/components/DetalheVendaProntaEntregaModal";
import { docPrintReport } from "../../../../../pdf";

export const HistoricoFlexModal = ({
  rotaSelec,
  dataIniDefault,
  dataFimDefault,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rota, setRota] = useState(null);
  const [rotaNome, setRotaNome] = useState("");
  const [dados, setDados] = useState([]);
  const [saldo, setSaldo] = useState(0);
  const [totais, setTotais] = useState({});
  const [dataIni, setDataIni] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [dataFim, setDataFim] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );
  const [somenteAdm, setSomenteAdm] = useState(false);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [idVendaDet, setIdVendaDet] = useState(null);
  const [detalheVendaOpen, setDetalheVendaOpen] = useState(false);

  const refFiltrosData = useRef();

  const limparDados = () => {
    setDados([]);
    setTotais({});
    setSaldo(0);
  };

  const buscarDados = async (idRota, _dataIni, _dataFim) => {
    if (!((_dataIni ?? dataIni) instanceof Date)) {
      toastr.warning("Atenção", "Por favor, revise a data de início.");
      return [false, null];
    }

    if (!((_dataFim ?? dataFim) instanceof Date)) {
      toastr.warning("Atenção", "Por favor, revise a data de fim.");
      return [false, null];
    }

    return await GerenciamentoFlexService.buscarHistoricoFlex(
      idRota ?? rota,
      _dataIni ?? dataIni,
      _dataFim ?? dataFim,
      somenteAdm
    );
  };

  const carregarDados = async (idRota, _dataIni, _dataFim) => {
    setLoadingPesquisar(true);
    const [ok, ret] = await buscarDados(idRota, _dataIni, _dataFim);
    if (ok) {
      setDados(ret.extrato ?? []);
      setSaldo(ret.saldo_atual ?? 0);
      setTotais(ret.totais ?? {});
    } else {
      setDados([]);
      setSaldo(0);
      setTotais({});
    }
    setLoadingPesquisar(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      await docPrintReport(
        "/relatorios/pronta_entrega/extrato_flex/",
        {
          dados: ret.extrato,
          saldo: ret.saldo_atual,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
          rota: rotaNome,
        },
        "0028_11"
      );
    }
    setLoadingImprimir(false);
  };

  const toggle = () => {
    if (!isOpen) {
      setRota(rotaSelec);
      setDataIni(dataIniDefault);
      setDataFim(dataFimDefault);

      carregarDados(rotaSelec, dataIniDefault, dataFimDefault);
    } else {
      limparDados();
      setDataIni(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
      setDataFim(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      );
      setRota(null);
      setRotaNome("");
      setSomenteAdm(false);
    }

    setIsOpen(!isOpen);
  };

  const handleSetRota = (selected) => {
    setRota(selected.value);
    setRotaNome(selected.label);
    if (selected.value !== rota) limparDados();
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const toggleDetalheVenda = () => setDetalheVendaOpen(!detalheVendaOpen);

  const mostrarDetalhes = (id) => {
    setIdVendaDet(id);
    setTimeout(() => {
      toggleDetalheVenda();
    }, 1);
  };

  useEffect(() => {
    if (refFiltrosData.current) {
      refFiltrosData.current.updateDataIni(dataIniDefault);
      refFiltrosData.current.updateDataFim(dataFimDefault);
    }
  }, [refFiltrosData.current]);

  const columns = [
    {
      dataField: "id_origem",
      text: "Venda",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data_hora",
      text: "Data/Hora",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_colaborador__nome",
      text: "Colaborador",
      align: "left",
      headerAlign: "left",
      formatter: (v, row) => formatValueFromAPI(v, row.id_colaborador),
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (v, row) => (
        <span style={{ color: row.natureza === "+" ? "green" : "red" }}>
          {formatNumber(v, true, 2)}
        </span>
      ),
    },
    {
      dataField: "detalhes_act",
      isDummyField: true,
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) =>
        row.id_origem !== 0 ? (
          <ModalDetalhesIcone toggle={() => mostrarDetalhes(row.id_origem)} />
        ) : (
          <></>
        ),
    },
  ];

  return (
    <>
      <FormButton
        color="info"
        onClick={toggle}
        padded={false}
        disabled={[0, null, undefined].includes(rotaSelec)}
        loading={loadingPesquisar}
      >
        Extrato Flex
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Extrato Flex"
        number="0028_3"
        autoFocus
        hideCancelButton
        unmountOnClose={false}
      >
        <Row className="mb-2">
          <AsyncComboBox
            isConcatField
            isSearchable
            concatModelName="rota_mob"
            defaultOptions
            md={2}
            label="Rota"
            onChange={handleSetRota}
            defaultValue={rota}
          />
          <FiltroPeriodoDatas
            onChange={handleDate}
            defaultOption="MESATU"
            ref={refFiltrosData}
          />
          <FormCheckbox
            label="Somente lançamentos feitos pela ADM"
            checked={somenteAdm}
            onChange={() => setSomenteAdm(!somenteAdm)}
            name="somente_adm"
          />
          <BotaoPesquisar
            onClick={() => carregarDados()}
            loading={loadingPesquisar}
          />
          <BotaoImprimir onClick={() => imprimir()} loading={loadingImprimir} />
        </Row>
        <TotalizadoresHistoricoFlex saldoAtual={saldo} totais={totais} />
        <Table columns={columns} data={dados} paginated={false} pageSize={10} />
      </ModalBase>
      <DetalheVendaProntaEntregaModal
        isOpen={detalheVendaOpen}
        toggle={toggleDetalheVenda}
        idVenda={idVendaDet}
      />
    </>
  );
};
