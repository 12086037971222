import React from "react";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { Table } from "../../../../../components";

export const CondPagGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "tipo_cond_pag",
      text: "Tipo",
      align: "center",
      sortable: true,
    },
    {
      dataField: "multiplicador",
      text: "Multiplicador",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "parcelas",
      text: "Qtd Parcelas",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "entrada",
      text: "Entrada",
      align: "right",
      formatter: (c) => `${formatNumber(c, true, 2)} %`,
      sortable: true,
      sortFunc: naturalSort,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
