import React from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import { BotaoPesquisar, LinkButton } from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { CadastroRotaModal } from "./components/CadastroRotaModal";
import { CadastroRotaGrid } from "./components/CadastroRotaGrid";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { inclusaoRotaRoute } from "../../../../routes/modules";
import { RoteirizacaoCidadeModal } from "./components/RoteirizacaoCidadeModal";

export const modalTitleRota = "Rota";
export const routesBaseRota = "/cadastro/rota_colab";

export const RotasRoteirizacao = () => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await apiGetV2(`${routesBaseRota}/listar/`);

    setDados(ok ? ret : []);
    setLoading(false);
  };

  const notifyEvent = () => {
    carregarDados();
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Rotas e Roteirização" number="0010" canGoBack>
      <Card body>
        <Row className="mb-2">
          <BotaoPesquisar
            loading={loading}
            onClick={carregarDados}
            padded={false}
          />
          <BotaoIncluir toggle={toggleCadastro} padded={false} />
          <LinkButton
            pathname={inclusaoRotaRoute.path}
            state={{
              id: selected,
            }}
            md="auto"
            color="indigo"
            disabled={[0, null, undefined].includes(selected)}
            padded={false}
          >
            Roteirização - Clientes
          </LinkButton>
          <RoteirizacaoCidadeModal />
          {cadastroOpen && (
            <CadastroRotaModal
              isOpen={cadastroOpen}
              toggle={toggleCadastro}
              action={action}
              selected={selected}
              notifyEvent={notifyEvent}
              nomeRotaSelec={dados.find((e) => e.id === selected)?.nome}
            />
          )}
          <ModalExcluirV2
            title={modalTitleRota}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0010_2"
            selected={selected}
            routeBase={routesBaseRota}
            notifyEvent={notifyEvent}
          />
        </Row>
        <CadastroRotaGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
