import moment from "moment";
import {
  formatDateDDMMAAAA,
  formatNumberField,
  formatRow,
  verificaPessoa,
} from "../GerarRemessa";

const rem240HeaderCresol = (dadosConta) => [
  ["133", 1, 3], // Código do Banco na Compensação: "133"
  [0, 4, 7], // Lote de Serviço: 0000
  [0, 8, 8], // Tipo de Registro: "0"
  ["", 9, 17], // Brancos
  [2, 18, 18], // Tipo de Inscrição da Empresa: 2
  [formatNumberField(dadosConta.cnpj_empresa), 19, 32], // Número de Inscrição da Empresa
  [formatNumberField(dadosConta.cod_cedente), 33, 52], // Número do convênio de cobrança CRESOL: Número da conta do cooperado
  [formatNumberField(dadosConta.agencia), 53, 57], // AG Mantedora da Conta
  [0, 58, 58], // DV Agência
  [formatNumberField(dadosConta.nro_conta), 59, 71], // Conta Corrente
  ["", 72, 72], // Dígito Verificador da Ag/Conta: Brancos
  [dadosConta.nome_empresa, 73, 102], // Nome da Empresa
  ["CRESOL CONFEDERAÇÃO", 103, 132], // Nome do Banco: CRESOL CONFEDERAÇÃO
  ["", 133, 142], // Uso Exclusivo FEBRABAN / CNAB: Brancos
  [1, 143, 143], // Código Remessa / Retorno: "1"
  [formatDateDDMMAAAA(new Date()), 144, 151], // Data de Geração do Arquivo
  [moment(new Date()).format("HHmmss"), 152, 157], // Hora de Geração do Arquivo
  [dadosConta.nro_remessa, 158, 163], // Número Seqüencial do Arquivo
  [84, 164, 166], // No da Versão do Layout do Arquivo: "084"
  [0, 167, 171], // Densidade de Gravação do Arquivo: "00000"
  ["", 172, 191], // Para Uso Reservado do Banco: Brancos
  ["", 192, 211], // Para Uso Reservado da Empresa: Brancos
  ["", 212, 240], // Uso Exclusivo FEBRABAN / CNAB: Brancos
];

const rem240HeaderLoteCresol = (dadosConta) => [
  ["133", 1, 3], // Código do Banco na Compensação: "133"
  [1, 4, 7], // Número Sequencial do Lote
  [1, 8, 8], // Tipo de Registro: "1"
  ["R", 9, 9], // Tipo de Operação: "R"
  [1, 10, 11], // Tipo de Serviço: "01"
  ["", 12, 13], // Brancos
  [42, 14, 16], // Nº da Versão do Layout do Lote: "042"
  ["", 17, 17], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [2, 18, 18], // Tipo de Inscrição da Empresa: 2
  [formatNumberField(dadosConta.cnpj_empresa), 19, 33], // Nº de Inscrição da Empresa
  [formatNumberField(dadosConta.cod_cedente), 34, 53], // Código do Convênio no Banco: Número da conta do cooperado
  [formatNumberField(dadosConta.agencia), 54, 58], // AG Mantedora da Conta
  [0, 59, 59], // DV Agência
  [formatNumberField(dadosConta.nro_conta), 60, 72], // Conta Corrente
  ["", 73, 73], // Dígito Verificador da Ag/Conta: Brancos
  [dadosConta.nome_empresa, 74, 103], // Nome da Empresa
  ["", 104, 143], // Mensagem 1. Brancos
  ["", 144, 183], // Mensagem 2. Brancos
  [0, 184, 191], // Número Remessa/Retorno: Zeros
  [0, 192, 199], // Data de Gravação Remessa/Retorno: Zeros
  [0, 200, 207], // Data do Crédito: Zeros
  ["", 208, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240SegPCresol = (
  dadosConta,
  dup,
  seuNumero,
  vlrPercJuros,
  temDesconto,
  seq
) => [
  ["133", 1, 3], // Código do Banco na Compensação: "133"
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["P", 14, 14], // Cód. Segmento do Registro Detalhe: "P"
  ["", 15, 15], // Branco
  [1, 16, 17], // Código de Movimento Remessa: 01 = Remessa, 02 = Pedido de Baixa, 04 = Concessão de Abatimento, 05 = Cancelamento de Abatimento, 06 = Alteração de Vencimento, 09 = Protestar, 10 = Cancela/Sustação da instrução de protesto, 11 = Sustar Protesto e Manter em Carteira
  [formatNumberField(dadosConta.agencia), 18, 23], // AG Mantedora da Conta
  [formatNumberField(dadosConta.nro_conta), 24, 36], // Conta Corrente
  ["", 37, 37], // Dígito Verificador da Ag/Conta: Brancos
  [0, 38, 40], // Identific. do Produto: Zeros
  [0, 41, 45], // Zeros
  [dup.nosso_numero, 46, 57], // Nosso Número
  [0, 58, 58], // Código da Carteira: Zero
  [0, 59, 59], // Forma de Cadastr. do Título no Banco: 0
  ["", 60, 60], // Tipo de Documento: Brancos
  [2, 61, 61], // Identificação da Emissão do Bloqueto - ???
  [2, 62, 62], // Identific. da Distribuição - ???
  [seuNumero.padStart(15, "0"), 63, 77], // Número do Documento de Cobrança
  [formatDateDDMMAAAA(dup.vencimento), 78, 85], // Data de Vencimento do Título
  [formatNumberField(dup.vlr_titulo), 86, 100], // Valor Nominal do Título
  [0, 101, 105], // Agência Encarregada da Cobrança: Zeros
  ["", 106, 106], // Dígito Verificador da Agência: Brancos
  [dadosConta.especie_tit, 107, 108], // Espécie do Título: 01 - Cheque, 02 - Duplicata mercantil, 04 - Duplicata de serviço, 06 - Duplicata rural, 07 - Letra de câmbio, 12 - Nota promissória, 17 - Recibo, 19 - Nota de débito, 26 - Warrant, 27 - Dívida ativa de estado, 28 - Dívida ativa de município, 29 - Dívida ativa da união, 30 - Encargos condominiais, 32 - Boleto Proposta, 99 - Outros
  ["N", 109, 109], // Identific. de Título Aceito/Não Aceito
  [formatDateDDMMAAAA(dup.emissao), 110, 117], // Data da Emissão do Título
  [dup.tipo_juros_titulo === "V" ? 1 : 2, 118, 118], // Código do Juros de Mora: 1 - Real ao dia, 2 - Percentual ao mês
  [formatDateDDMMAAAA(dup.data_juros_titulo), 119, 126], // Data do Juros de Mora
  [vlrPercJuros, 127, 141], // Juros de Mora por Dia/Taxa
  [temDesconto ? 1 : 0, 142, 142], // Código do Desconto: 0 - Sem desconto, 1 - Valor fixo até a data informada
  [temDesconto ? formatDateDDMMAAAA(dup.data_desconto) : 0, 143, 150], // Data do Desconto
  [formatNumberField(dup.vlr_desconto), 151, 165], // Valor do Desconto
  [0, 166, 180], // Valor do IOF a ser Recolhido
  [0, 181, 195], // Valor do Abatimento - Somente relevante com ocorrência = 4
  [`duplicata: ${dup.id}`, 196, 220], // Identificação do Título na Empresa: Somente relevante com ocorrência = 1
  [dadosConta.protestar ? 1 : 3, 221, 221], // 1 - Protestar dias corridos, 3 - Não Protestar
  [dadosConta.dias_protesto, 222, 223], // Nº de Dias para Protesto
  [0, 224, 224], // Código para Baixa/Devolução: ???
  ["", 225, 227], // Número de Dias para Baixa/Devolução: ???
  [9, 228, 229], // Código da Moeda: 09
  [0, 230, 240], // Zeros
];

const rem240SegQCresol = (dup, enderCliente, seq) => [
  ["133", 1, 3], // Código do Banco na Compensação: "133"
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: 3
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["Q", 14, 14], // Cód. Segmento do Registro Detalhe: "Q"
  ["", 15, 15], // Branco
  [1, 16, 17], // Código de Movimento Remessa
  [verificaPessoa(dup.cpf_cnpj_cliente), 18, 18], // Pagador - Tipo de Inscrição: 1 - CPF, 2 - CNPJ
  [formatNumberField(dup.cpf_cnpj_cliente), 19, 33], // Pagador - Número de Inscrição
  [dup.nome_cliente, 34, 73], // Pagador - Nome
  [enderCliente, 74, 113], // Pagador - Endereço
  [dup.bairro_cliente, 114, 128], // Pagador - Bairro
  [formatNumberField(dup.cep_cliente), 129, 136], // Pagador - CEP
  [dup.cidade_cliente, 137, 151], // Pagador - Cidade
  [dup.uf_cliente, 152, 153], // Pagador - UF
  [0, 154, 154], // Sacador/Avalist - Tipo de Inscrição Sacador Avalista: 0
  [0, 155, 169], // Sacador/Avalist - Número de Inscrição
  ["", 170, 209], // Sacador/Avalist - Nome do Sacador/Avalista
  [0, 210, 212], // Cód. Bco. Corresp. na Compensação: Zeros
  ["", 213, 232], // Nosso Nro. Banco Correspondente: Brancos
  ["", 233, 240], // Uso Exclusivo FEBRABAN/CNAB
];

const rem240SegRCresol = (dadosConta, dup, vlrPercMulta, seq) => [
  ["133", 1, 3], // Código do Banco na Compensação: "133"
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["R", 14, 14], // Cód. Segmento do Registro Detalhe: "R"
  ["", 15, 15], // Branco
  [1, 16, 17], // Código de Movimento Remessa:  ???
  [0, 18, 18], // Código do Desconto 2 0 - Não Conceder desconto, 1 - Valor Fixo Até a Data Informada
  [0, 19, 26], // Data do Desconto 2
  [0, 27, 41], // Valor a ser Concedido
  [0, 42, 42], // Código do Desconto 3 0 - Não Conceder desconto, 1 - Valor Fixo Até a Data Informada
  [0, 43, 50], // Data do Desconto 3
  [0, 51, 65], // Valor a ser Concedido
  [dadosConta.tipo_multa === "PER" ? 2 : 1, 66, 66], // Código da Multa: 1 - Valor Fixo, 2 - Percentual
  [formatDateDDMMAAAA(dup.data_multa_titulo), 67, 74], // Data da Multa
  [vlrPercMulta, 75, 89], // Valor/Percentual da Multa
  ["", 90, 99], // Informação ao Pagador: Brancos
  ["", 100, 139], // Mensagem 3. Brancos
  ["", 140, 179], // Mensagem 4. Brancos
  ["", 180, 199], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [0, 200, 207], // Cód. Ocor. do Pagador: Zeros
  [0, 208, 210], // Cód. do Banco na Conta do Débito: Zeros
  [0, 211, 215], // Código da Agência do Débito: Zeros
  ["", 216, 216], // Dígito Verificador da Agência: Brancos
  [0, 217, 228], // Conta Corrente para Débito: Zeros
  ["", 229, 229], // Dígito Verificador da Conta: Brancos
  ["", 230, 230], // Dígito Verificador Ag/Conta: Brancos
  [0, 231, 231], // Aviso para Débito Automático: Zero
  ["", 232, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240TrailerLoteCresol = (registrosLote) => [
  ["133", 1, 3], // Código do Banco na Compensação: "133"
  [1, 4, 7], // Número Sequencial do Lote
  [5, 8, 8], // Tipo de Registro: "5"
  ["", 9, 17], // Branco
  [registrosLote, 18, 23], // Quantidade de Registros no Lote
  [0, 24, 115], // Zeros
  ["", 116, 240], // Brancos
];

const rem240TrailerCresol = (totalRegistros) => [
  ["133", 1, 3], // Código do Banco na Compensação: "133"
  [9999, 4, 7], // Preencher com '9999'.
  [9, 8, 8], // Tipo de Registro: "9"
  ["", 9, 17], // Brancos
  [1, 18, 23], // Quantidade de Lotes do Arquivo
  [totalRegistros, 24, 29], // Quantidade de Registros do Arquivo
  [0, 30, 35], // Zeros
  ["", 36, 240], // Brancos
];

export const nomeArq240Cresol = (dadosConta) =>
  "E00" + moment(new Date()).format("DD") + dadosConta.nro_remessa + ".rem"; // NOME_ARQUIVO_DATA]”.rem”

export const layout240Cresol = (dadosConta, duplicatas) => {
  const vlrPercMulta = formatNumberField(
    dadosConta.tipo_multa === "PER"
      ? dadosConta.perc_multa
      : dadosConta.vlr_multa
  );

  const header = "".concat(
    formatRow(...rem240HeaderCresol(dadosConta)),
    formatRow(...rem240HeaderLoteCresol(dadosConta))
  );

  const detalhes = "".concat(
    ...duplicatas.map((dup, index) => {
      const seuNumero = `${dup.numero}-${dup.parcela}`;

      const enderCliente =
        dup.endereco_cliente + " " + dup.nro_endereco_cliente;

      const vlrPercJuros = formatNumberField(
        dup.tipo_juros_titulo === "V"
          ? dup.vlr_juros_titulo
          : dup.perc_juros_titulo
      );

      return (
        formatRow(
          ...rem240SegPCresol(
            dadosConta,
            dup,
            seuNumero,
            vlrPercJuros,
            parseFloat(dup.vlr_desconto) > 0,
            index * 3 + 1
          )
        ) +
        formatRow(...rem240SegQCresol(dup, enderCliente, index * 3 + 2)) +
        formatRow(
          ...rem240SegRCresol(dadosConta, dup, vlrPercMulta, index * 3 + 3)
        )
      );
    })
  );

  const trailer = "".concat(
    formatRow(...rem240TrailerLoteCresol(duplicatas.length * 3 + 2)),
    formatRow(...rem240TrailerCresol(duplicatas.length * 3 + 4))
  );

  return header + detalhes + trailer;
};
