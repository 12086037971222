import React, { useEffect, useState } from "react";
import { PageContainer } from "../../../../../components";
import EstoqueRotaService from "../../../../../services/jornada/estoque_rota/EstoqueRotaService";
import { TableRotaProdutos } from "./components/TableRotaProdutos";

export const EstoqueRota = () => {
  const [loading, setLoading] = useState(true);
  const [dados, setDados] = useState([]);

  const carregarDados = async () => {
    setDados(await EstoqueRotaService.buscarDados());
    setLoading(false);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  return (
    <PageContainer
      title="Estoque Atual das Rotas"
      number="0085_1"
      canGoBack
      loading={loading}
    >
      <TableRotaProdutos data={dados} />
    </PageContainer>
  );
};
