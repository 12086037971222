import React, { useState } from "react";
import { TextInput } from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import CentralPedidosService from "../../../../../services/pedidos/CentralPedidosService";

export const CancelarPedidoModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [obs, setObs] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => setObs("");

  const handleSubmit = async () => {
    setLoading(true);

    const payload = {
      id_pedido: selected,
      observ: obs,
    };

    const [ok] = await CentralPedidosService.cancelar(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }

    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      onClosed={limparDados}
      title="Cancelamento do Pedido"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      number="0034_6"
    >
      <h3 className="ml-3">Pedido Nº {selected}</h3>
      <TextInput
        md={12}
        label="Observação"
        value={obs}
        onChange={(e, v) => setObs(v)}
      />
    </ModalBase>
  );
};
