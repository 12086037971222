import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { formatNumber, objectIsEmpty } from "../../../../../coreUtils";
import DemonstrativoResultadosService from "../../../../../services/controladoria/DemonstrativoResultadoService";
import { GridMovimentosDescAcre } from "./GridMovimentosDescAcre";
import { ModalBase } from "../../../../../components";

export const MovimentosDescAcreModal = ({ isOpen, toggle, idMovimento }) => {
  const [dadosMovimento, setDadosMovimento] = useState({
    id_cab: 0,
    historico: "",
    valor: "0.0",
    valor_final: "0.0",
    movimentos: [],
  });

  const carregarDados = useCallback(async () => {
    if (idMovimento !== null) {
      const ret = await DemonstrativoResultadosService.buscarDadosMovimento(
        idMovimento
      );
      if (!objectIsEmpty(ret)) {
        setDadosMovimento(ret);
      }
    }
  }, [idMovimento]);

  useEffect(() => {
    if (isOpen) {
      carregarDados();
    }
  }, [isOpen, carregarDados]);

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={
        <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          Movimentos de Desconto e Acréscimo vinculado a Inclusão
        </h5>
      }
      autoFocus
      hideCancelButton
      number="5007_1"
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Col md={12}>
          <h6>{dadosMovimento.historico}</h6>
          <h6>
            Valor Original: R$
            {formatNumber(dadosMovimento.valor, true, 2)}
          </h6>
        </Col>
      </Row>
      <GridMovimentosDescAcre movimentos={dadosMovimento.movimentos} />
      <Row style={{ justifyContent: "end" }} className="pt-2">
        <Col md="auto">
          <h6>
            Valor Final: R$
            {formatNumber(dadosMovimento.valor_final, true, 2)}
          </h6>
        </Col>
      </Row>
    </ModalBase>
  );
};
