import React from "react";
import { DataTable } from "../../../../../components/DataTable";
import { formatValueFromAPI } from "../../../../../coreUtils";

export const TableOcorrencias = ({ data }) => {
  const columns = [
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      headerAlign: "center",
      sort: false,
    },
    {
      dataField: "nosso_nro",
      text: "Nosso Número",
      align: "center",
      headerAlign: "center",
      sort: false,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      sort: false,
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "data_ocorrencia",
      text: "Data da Ocorrência",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "descricao",
      text: "Descrição da Ocorrência",
      align: "left",
      headerAlign: "left",
      sort: false,
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      sort: true,
    },
  ];
  return (
    <DataTable
      keyField="tipo"
      data={data}
      columns={columns}
      striped
      noDataIndication={<p className="mt-2 text-muted">Sem ocorrências</p>}
      readOnly
    />
  );
};
