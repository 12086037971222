import React from "react";
import { formatarValor } from "../../../../../../coreUtils";
import { Table, TableDelete } from "../../../../../../components";
import NotaFiscalConsumidorService from "../../../../../../services/docs_eletron/NotaFiscalConsumidorService";
import { UncontrolledTooltip } from "reactstrap";

export const ItensNFConsGrid = ({ dados, notifyExclusao, regimeTribut }) => {
  const removerItem = async (idItem) => {
    const [ok] = await NotaFiscalConsumidorService.inclusao.excluirItem(idItem);
    if (ok) {
      notifyExclusao && notifyExclusao();
    }
  };

  const columns = [
    {
      dataField: "item",
      text: "#",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "50px",
    },
    {
      dataField: "cod_prod",
      text: "Produto",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "70px",
    },
    {
      dataField: "nome_prod",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "ncm",
      text: "NCM",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "80px",
      formatter: (c, row) =>
        row.ncm_valido ? (
          c
        ) : (
          <>
            <span style={{ color: "red" }} id={`ncm-inv-${row.id}`}>
              {c}
            </span>
            <UncontrolledTooltip target={`ncm-inv-${row.id}`} placement="left">
              NCM Inválida
            </UncontrolledTooltip>
          </>
        ),
    },
    {
      dataField: "sit_trib_icms",
      text: "CST",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "50px",
      hidden: regimeTribut === "SIM",
    },
    {
      dataField: "cfop",
      text: "CFOP",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "55px",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "55px",
    },
    {
      dataField: "quantidade",
      text: "Qtd.",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "70px",
      formatter: (c) => formatarValor(c, 2, 0, true),
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      headerAlign: "right",
      fixedColWidth: true,
      colWidth: "80px",
      formatter: (c) => formatarValor(c, 4, 4, true),
    },
    {
      dataField: "vlr_icms",
      text: "ICMS",
      align: "right",
      headerAlign: "right",
      fixedColWidth: true,
      colWidth: "80px",
      formatter: (c) => formatarValor(c, 2, 2, true),
      hidden: regimeTribut === "SIM",
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      fixedColWidth: true,
      colWidth: "90px",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      colHeaderStyle: { fontWeight: "normal" },
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) => (
        <TableDelete onClick={() => removerItem(row.id)} />
      ),
    },
  ];

  return (
    <Table data={dados} columns={columns} pageSize={7} paginated={false} />
  );
};
