import React from "react";
import { Row } from "reactstrap";
import { IntegerFormInput, TextInput } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  setUltNumero,
  setCaminSalvarXml,
  setSiglaTransportadora,
} from "../store/cte_slice";

export const PersonCte = () => {
  const store = useSelector((state) => state.cte);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <IntegerFormInput
          md={3}
          label="Último Número de CT-e Emitido"
          mdInput={7}
          defaultValue={store.ult_numero}
          onChange={(v) => dispatch(setUltNumero(v ?? 0))}
        />
        <IntegerFormInput
          md={3}
          label="Último Número de Lote"
          mdInput={7}
          defaultValue={store.ult_lote}
          disabled
        />
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Salvar XML Em"
          value={store.camin_salvar_xml}
          onChange={(e, v) => dispatch(setCaminSalvarXml(v))}
          forceUppercase={false}
          maxLength={255}
        />
      </Row>
      <Row>
        <TextInput
          md={3}
          mdInput={4}
          label="Sigla da transportadora - EDI"
          value={store.sigla_transportadora}
          onChange={(e, v) => dispatch(setSiglaTransportadora(v))}
          maxLength={3}
        />
      </Row>
    </>
  );
};
