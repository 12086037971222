import React, { useState } from "react";
import { Col, CustomInput, Label, UncontrolledTooltip } from "reactstrap";
import { handleFocus } from "../coreUtils";

export const FormCheckbox = ({
  label,
  md = "auto",
  name,
  onChange,
  checked,
  defaultChecked,
  padded = true,
  disabled,
  className,
  divClassName,
  tabIndex,
  divStyle,
  hint,
  tooltipPlacement = "top-start",
  disabledHint,
}) => {
  const [internalId] = useState(
    name ?? "ch-" + Math.floor(Math.random() * Date.now())
  );

  return (
    <Col
      md={md}
      className={divClassName}
      style={divStyle}
      id={`${internalId}-div`}
    >
      {padded && <Label>&#8205;</Label>}
      <CustomInput
        type="checkbox"
        name={name}
        id={internalId}
        checked={checked}
        onChange={onChange}
        htmlFor={name}
        label={label}
        disabled={disabled}
        defaultChecked={defaultChecked}
        className={className}
        onKeyDown={handleFocus}
        tabIndex={tabIndex}
      />
      {(hint || (disabled && disabledHint)) && (
        <UncontrolledTooltip
          target={`${internalId}-div`}
          placement={tooltipPlacement}
        >
          <div style={{ textAlign: "left" }}>
            {disabled ? disabledHint : hint}
          </div>
        </UncontrolledTooltip>
      )}
    </Col>
  );
};
