import React from "react";
import { Table } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const RegrasComissaoGrid = ({ dados, onSelect, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "data_ini",
      text: "Início",
      align: "center",
    },
    {
      dataField: "data_final",
      text: "Fim",
      align: "center",
    },
    {
      dataField: "desc_tipo",
      text: "Tipo",
      align: "center",
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      onSelect={onSelect}
      data={dados}
      columns={columns}
      onRowDoubleClick={alterar}
    />
  );
};
