import {
  dataHojeYYYYMMDD,
  formatDateDDMMAA,
  formatNumberField,
  formatRow,
  verificaPessoa,
} from "../GerarRemessa";

// ============================ REGISTROS E CAMPOS ============================

export const rem400headerSicredi = (dadosConta) => [
  ["0", 1, 1],
  ["1", 2, 2],
  ["REMESSA", 3, 9],
  ["01", 10, 11],
  ["COBRANCA", 12, 26],
  [dadosConta.cod_cedente, 27, 31], // COD BENEFICIARIO
  [dadosConta.cnpj_empresa.padStart(14, "0"), 32, 45], // CPF/CNPJ BENEFICIARIO
  ["", 46, 76],
  ["748", 77, 79],
  ["SICREDI", 80, 94],
  [dataHojeYYYYMMDD, 95, 102],
  ["", 103, 110],
  [dadosConta.nro_remessa, 111, 117],
  ["", 118, 390],
  ["2.00", 391, 394],
  [1, 395, 400],
];

export const rem400detalheSicredi = (
  dadosConta,
  dup,
  seuNumero,
  vlrPercJuros,
  enderCliente,
  seq,
  temDesconto
) => [
  ["1", 1, 1], // IDENTIFICACAO - 1
  ["A", 2, 2], // TIPO COBRANÇA - A - SICREDI COM REGISTRO
  [dadosConta.tipo_carteira, 3, 3], // TIPO CARTEIRA - A - SIMPLES
  ["A", 4, 4], // TIPO IMPRESSÃO - A - NORMAL, B - CARTEIRA
  ["", 5, 16], // FILLER
  ["A", 17, 17], // TIPO MOEDA - A - REAL
  ["A", 18, 18], // TIPO DESCONTO - A - VALOR, B - PERCENTUAL
  [dup.tipo_juros_titulo === "V" ? "A" : "B", 19, 19], // TIPO JUROS - A - VALOR, B - PERCENTUAL
  ["", 20, 47], // FILLER
  [dup.nosso_numero, 48, 56], // NOSSO NUMERO
  ["", 57, 62], // FILLER
  [dataHojeYYYYMMDD, 63, 70], // DATA DE INSTRUÇÃO - AAAAMMDD
  ["", 71, 71], // INSTRUCOES - USAR SOMENTE QUANDO INSTRUÇÃO [109-110] FOR 31
  ["N", 72, 72], // POSTAGEM DO TITULO
  ["", 73, 73], // FILLER
  ["B", 74, 74], // EMISSAO DO BOLETO [A - SICREDI, B - BENEFICIARIO]
  ["", 75, 76], // NUMERO PARCELA DO CARNE [SE POS 004 = B]
  ["", 77, 78], // TOTAL DE PARCELAS DO CARNE [SE POS 004 = B]
  ["", 79, 82], // FILLER
  [0, 83, 92], // DESCONTO POR DIA DE ANTECIPACAO
  [formatNumberField(dadosConta.perc_multa), 93, 96], // PERC MULTA POR PAGAMENTO EM ATRASO - Alinhado à direita com zeros à esquerda, sem separador decimal ou preencher com zeros.
  ["", 97, 108], // FILLER
  ["01", 109, 110], // INSTRUÇÃO - 01 - Cadastro de título
  [seuNumero, 111, 120], // SEU NUMERO - NUMERO DO TITULO
  [formatDateDDMMAA(dup.vencimento), 121, 126], // DATA VENCIMENTO - SETE DIAS MAIOR QUE DATA EMISSAO [151 A 156] - DDMMAA
  [formatNumberField(dup.vlr_titulo), 127, 139], //VALOR DO TITULO
  ["", 140, 148], // FILLER
  [dadosConta.especie_tit, 149, 149], // ESPECIE DOCUMENTO - A - DUPLICATA MERCANTIL
  ["N", 150, 150], // ACEITE - S - SIM, N - NAO
  [formatDateDDMMAA(dup.emissao), 151, 156], // DATA EMISSAO - DDMMAA
  [dadosConta.protestar ? "06" : "00", 157, 158], // PROTESTO AUTOMATICO - 00 NAO 06 SIM
  [dadosConta.dias_protesto, 159, 160], // DIAS PROTESTO AUTOMATICO
  [vlrPercJuros, 161, 173], // VALOR/% JUROS DIA OU 0
  [temDesconto ? formatDateDDMMAA(dup.data_desconto) : 0, 174, 179], // DATA LIMITE PARA CONCESSAO DE DESCONTO DDMMAA OU 0
  [formatNumberField(dup.vlr_desconto), 180, 192], // VALOR/% DESCONTO OU 0
  [0, 193, 205], // FILLER
  [0, 206, 218], // VALOR ABATIMENTO OU 0
  [verificaPessoa(dup.cpf_cnpj_cliente), 219, 219], // PESSOA PAGADOR - 1 FISICA, 2 - JURIDICA
  [0, 220, 220], // FILLER
  [formatNumberField(dup.cpf_cnpj_cliente), 221, 234], // CPF/CNPJ PAGADOR - DIREITA COM ZEROS A ESQUERDA
  [dup.nome_cliente, 235, 274], // NOME PAGADOR - SEM ACENTUACAO
  [enderCliente, 275, 314], // ENDERECO PAGADOR - SEM ACENTUACAO
  [0, 315, 319], // COD PAGADOR COOPERATIVA BENEFICIARIO OU 0
  [0, 320, 325], // FILLER
  ["", 326, 326], // FILLER
  [dup.cep_cliente.replace("-", ""), 327, 334], // CEP PAGADOR
  [0, 335, 339], // COD PAGADOR JUNTO AO CLIENTE
  [formatNumberField(dadosConta.cnpj_avalista), 340, 353], // CPF/CNPJ SACADOR AVALISTA
  [dadosConta.nome_avalista, 354, 394], // NOME SACADOR AVALISTA
  [seq, 395, 400],
];

export const rem400mensagemSicredi = (dup, seuNumero, seq) => [
  ["2", 1, 1], // IDENTIFICACAO - 2
  ["", 2, 12], // FILLER
  [dup.nosso_numero, 13, 21], // NOSSO NUMERO
  [dup.mensagem_1, 22, 101], // 1a INSTRUCAO
  [dup.mensagem_2, 102, 181], // 2a INSTRUCAO
  [dup.mensagem_3, 182, 261], // 3a INSTRUCAO
  [dup.mensagem_4, 262, 341], // 4a INSTRUCAO
  [seuNumero, 342, 351], // SEU NUMERO
  ["", 352, 394], // FILLER
  [seq, 395, 400],
];

export const rem400avalistaSicredi = (dadosConta, dup, seuNumero, seq) => [
  ["6", 1, 1], // IDENTIFICACAO - 6
  [formatNumberField(dup.nosso_numero), 2, 16], // NOSSO NUMERO
  [seuNumero, 17, 26], // SEU NUMERO
  [0, 27, 31], // CODIGO DO PAGADOR JUNTO AO CLIENTE
  [formatNumberField(dadosConta.cnpj_avalista), 32, 45], // CPF/CNPJ SACADOR AVALISTA
  [dadosConta.nome_avalista, 46, 86], // NOME SACADOR AVALISTA
  [dadosConta.endereco_avalista, 87, 131], // ENDERECO
  [dadosConta.cidade_avalista, 132, 151], // CIDADE
  [dadosConta.cep_avalista.replace("-", ""), 152, 159], // CEP
  [dadosConta.uf_avalista, 160, 161], // ESTADO
  ["", 162, 394], // FILLER
  [seq, 395, 400],
];

export const rem400trailerSicredi = (dadosConta, seq) => [
  ["9", 1, 1], // IDENTIFICADOR
  ["1", 2, 2], // IDENTIFICADOR ARQUIVO REMESSA
  ["748", 3, 5], // NUMERO SICREDI
  [dadosConta.cod_cedente, 6, 10], // COD BENEFICIARIO [CONTA CORRENTE SEM O DV]
  ["", 11, 394],
  [seq, 395, 400],
];

// ============================== GERAÇÃO DO ARQUIVO ==============================

export const layoutSicredi = (dadosConta, duplicatas) => {
  const temInfoAvalista = ![null, undefined, ""].includes(
    dadosConta.cnpj_avalista?.trim()
  );

  const multiIndex = temInfoAvalista ? 3 : 2;

  const header = formatRow(...rem400headerSicredi(dadosConta));

  const detalhes = "".concat(
    ...duplicatas.map((dup, index) => {
      const seuNumero = `${dup.numero}-${dup.parcela}`.padStart(10, "0");

      const enderCliente =
        dup.endereco_cliente +
        (!["", null, undefined].includes(dup.nro_endereco_cliente)
          ? ", " + dup.nro_endereco_cliente
          : "") +
        (!["", null, undefined].includes(dup.complemento_endereco_cliente)
          ? " - " + dup.complemento_endereco_cliente
          : "");

      const vlrPercJuros = formatNumberField(
        dup.tipo_juros_titulo === "V"
          ? dup.vlr_juros_titulo
          : dup.perc_juros_titulo
      );

      return (
        // LINHA DETALHE
        formatRow(
          ...rem400detalheSicredi(
            dadosConta,
            dup,
            seuNumero,
            vlrPercJuros,
            enderCliente,
            index * multiIndex + 2,
            parseFloat(dup.vlr_desconto) > 0
          )
        ) +
        // LINHA MENSAGEM
        formatRow(
          ...rem400mensagemSicredi(dup, seuNumero, index * multiIndex + 3)
        ) +
        // LINHA AVALISTA
        (temInfoAvalista
          ? formatRow(
              ...rem400avalistaSicredi(
                dadosConta,
                dup,
                seuNumero,
                index * multiIndex + 4
              )
            )
          : "")
      );
    })
  );

  // LINHA TRAILER
  const trailer = formatRow(
    ...rem400trailerSicredi(dadosConta, duplicatas.length * multiIndex + 2)
  );

  return header + detalhes + trailer;
};

export const nomeArqSicredi = (dadosConta) => {
  let mes = new Date().getMonth() + 1;

  // Extensão do arquivo
  let remessaAtual = dadosConta.nro_remessas_dia;
  if (remessaAtual === 10) remessaAtual = 0;

  let extensaoArq = remessaAtual > 1 ? `RM${remessaAtual}` : "CRM";

  // Código mês Sicredi
  switch (mes) {
    case 10:
      mes = "O";
      break;
    case 11:
      mes = "N";
      break;
    case 12:
      mes = "D";
      break;
    default:
      mes = mes.toString();
      break;
  }

  // Código do cendente + código do mês + dia to mês . extensão
  return (
    dadosConta.cod_cedente +
    mes +
    new Date().getDate().toString().padStart(2, "0") +
    "." +
    extensaoArq
  );
};
