import React, { useEffect, useState } from "react";
import {
  Divider,
  FormButton,
  PageContainer,
  PasswordInput,
} from "../../../components";
import { Card, Label, Row } from "reactstrap";
import classNames from "classnames";
import CadastroSenhaService from "../../../services/config/CadastroSenhaService";

const SenhaForm = ({
  label,
  observ,
  dividerClassName,
  temSenha,
  nomeUrl,
  notifyEvent,
}) => {
  const [senhaAtual, setSenhaAtual] = useState("");
  const [senhaNovaInterna, setSenhaNovaInterna] = useState("");
  const [senhaNova, setSenhaNova] = useState("");
  const [senhasOk, setSenhasOk] = useState(false);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setSenhaAtual("");
    setSenhaNovaInterna("");
    setSenhaNova("");
  };

  useEffect(() => {
    setSenhasOk(senhaNovaInterna === senhaNova);
  }, [senhaNovaInterna, senhaNova]);

  const handleSubmit = async () => {
    const payload = {
      senha_anterior: temSenha ? senhaAtual : null,
      nova_senha: senhaNova,
    };

    setLoading(true);
    const [ok] = await CadastroSenhaService.alterar(nomeUrl, payload);
    if (ok) {
      limparDados();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <>
      <Divider className={classNames(dividerClassName, "mb-1")}>
        {label}
      </Divider>
      <Row>
        <PasswordInput
          md={3}
          label="Senha Atual"
          value={senhaAtual}
          onChange={setSenhaAtual}
          disabled={!temSenha}
        />
        <PasswordInput
          md={3}
          label="Nova Senha"
          value={senhaNovaInterna}
          onChange={setSenhaNovaInterna}
        />
        <PasswordInput
          md={3}
          label="Digite a Nova Senha Novamente"
          value={senhaNova}
          onChange={setSenhaNova}
        />
        <FormButton
          md="auto"
          color="success"
          onClick={handleSubmit}
          loading={loading}
          disabled={
            (temSenha && senhaAtual.length === 0) ||
            senhaNova.length === 0 ||
            !senhasOk
          }
          disabledHint={
            temSenha && senhaAtual.length === 0
              ? "Informe a Senha Atual"
              : senhaNova.length === 0
              ? "Informe a Nova Senha nos dois campos"
              : !senhasOk && "As senhas devem corresponder nos dois campos"
          }
        >
          Confirmar
        </FormButton>
      </Row>
      <Row>
        {!temSenha && (
          <>
            <Label className="py-0" md={3}>
              Não há Senha Cadastrada
            </Label>
          </>
        )}
        {senhaNova.length > 0 &&
          (!senhasOk || (temSenha && senhaAtual.length === 0)) && (
            <Label style={{ color: "red" }} className="py-0 ml-auto" md={3}>
              {!senhasOk
                ? "As Senhas Não Correspondem"
                : "Informe a Senha Atual"}
            </Label>
          )}
      </Row>
      <Row>
        <Label className="mb-0 py-0" md="auto">
          {observ}
        </Label>
      </Row>
    </>
  );
};

export const Senhas = () => {
  const [loading, setLoading] = useState(true);
  const [senhaDescProd, setSenhaDescProd] = useState(false);
  const [senhaReabreVenda, setSenhaReabreVenda] = useState(false);
  const [senhaRetiradaCaixa, setSenhaRetiradaCaixa] = useState(false);
  const [senhaDescCaixaLoja, setSenhaDescCaixaLoja] = useState(false);
  const [senhaPesquisaCaixaLoja, setSenhaPesquisaCaixaLoja] = useState(false);
  const [senhaInclusaoCreditoCli, setSenhaInclusaoCreditoCli] = useState(false);
  const [senhaExclusaoVenda, setSenhaExclusaoVenda] = useState(false);
  const [senhaPesqCaixa, setSenhaPesqCaixa] = useState(false);
  const [
    senhaInclusaoProdSemEstoque,
    setSenhaInclusaoProdSemEstoque,
  ] = useState(false);
  const [senhaAltEstoqPeloCadProd, setSenhaAltEstoqPeloCadProd] = useState(
    false
  );
  const [senhaEstornoRecCaixaLoja, setSenhaEstornoRecCaixaLoja] = useState(
    false
  );
  const [
    senhaImpressaoResumoCaixaLoja,
    setSenhaImpressaoResumoCaixaLoja,
  ] = useState(false);
  const [senhaExcluirItemPdv, setSenhaExcluirItemPdv] = useState(false);

  const carregarDados = async () => {
    const [ok, ret] = await CadastroSenhaService.buscar();
    if (ok) {
      setSenhaDescProd(ret.senha_desc_prod);
      setSenhaReabreVenda(ret.senha_reabre_venda);
      setSenhaRetiradaCaixa(ret.senha_retirada_caixa);
      setSenhaDescCaixaLoja(ret.senha_desc_caixa_loja);
      setSenhaPesquisaCaixaLoja(ret.senha_pesquisa_caixa_loja);
      setSenhaInclusaoCreditoCli(ret.senha_inclusao_credito_cli);
      setSenhaExclusaoVenda(ret.senha_exclusao_venda);
      setSenhaPesqCaixa(ret.senha_pesq_caixa);
      setSenhaInclusaoProdSemEstoque(ret.senha_inclusao_prod_sem_estoque);
      setSenhaAltEstoqPeloCadProd(ret.senha_alt_estoq_pelo_cad_prod);
      setSenhaEstornoRecCaixaLoja(ret.senha_estorno_rec_caixa_loja);
      setSenhaExcluirItemPdv(ret.senha_excluir_item_pdv);
      setSenhaImpressaoResumoCaixaLoja(ret.senha_impressao_resumo_caixa_loja);
    }
    setLoading(false);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  return (
    <PageContainer title="Senhas" number="conf_06" canGoBack loading={loading}>
      <Card body>
        <SenhaForm
          dividerClassName="mt-0"
          label="Senha de Descontos de Produtos"
          observ="Esta senha permite descontos maiores que o desconto máximo/teto do produto."
          temSenha={senhaDescProd}
          nomeUrl="senha_desc_prod"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha de Reabertura de Vendas"
          temSenha={senhaReabreVenda}
          nomeUrl="senha_reabre_venda"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha de Retiradas do Caixa Loja"
          temSenha={senhaRetiradaCaixa}
          nomeUrl="senha_retirada_caixa"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha de Descontos no Caixa Loja"
          observ="Esta senha permite descontos maiores que o desconto máximo/teto do produto."
          temSenha={senhaDescCaixaLoja}
          nomeUrl="senha_desc_caixa_loja"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha de Pesquisa de Caixa Loja"
          observ="Para habilitar esta senha, será necessário ativar o controle desta funcionalidade no cadastro de perfil de colaborador."
          temSenha={senhaPesqCaixa}
          nomeUrl="senha_pesq_caixa"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha de Inclusão de Créditos para o Cliente"
          observ="Para habilitar esta senha, será necessário ativar o controle desta funcionalidade no cadastro de perfil de colaborador."
          temSenha={senhaInclusaoCreditoCli}
          nomeUrl="senha_inclusao_credito_cli"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha de Exclusão de Venda"
          observ="Para habilitar esta senha, será necessário ativar o controle desta funcionalidade no cadastro de perfil de colaborador."
          temSenha={senhaExclusaoVenda}
          nomeUrl="senha_exclusao_venda"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha de Estorno de Recebimento do Caixa Loja"
          observ=""
          temSenha={senhaEstornoRecCaixaLoja}
          nomeUrl="senha_estorno_rec_caixa_loja"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha de Inclusão de Produto sem Estoque"
          observ="Para habilitar esta senha, será necessário ativar o controle desta funcionalidade no cadastro de perfil de colaborador."
          temSenha={senhaInclusaoProdSemEstoque}
          nomeUrl="senha_inclusao_prod_sem_estoque"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha de Alteração de Estoque pelo Cadastro de Produtos"
          observ="Para habilitar esta senha, será necessário ativar o controle desta funcionalidade no cadastro de perfil de colaborador."
          temSenha={senhaAltEstoqPeloCadProd}
          nomeUrl="senha_alt_estoq_pelo_cad_prod"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha para Impressão do Resumo do Caixa Loja - PDV"
          observ=""
          temSenha={senhaImpressaoResumoCaixaLoja}
          nomeUrl="senha_impressao_resumo_caixa_loja"
          notifyEvent={carregarDados}
        />
        <SenhaForm
          label="Senha para Exclusão de Itens no PDV"
          observ="Para habilitar esta senha, será necessário ativar o controle desta funcionalidade nos parâmetros do PDV."
          temSenha={senhaExcluirItemPdv}
          nomeUrl="senha_excluir_item_pdv"
          notifyEvent={carregarDados}
        />
      </Card>
    </PageContainer>
  );
};
