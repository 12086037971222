import React from "react";
import { Card } from "reactstrap";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { DetalhesProdPendEntregaCliModal } from "./DetalhesProdPendEntregaCliModal";
import { Table } from "../../../../../components";

export const ProdutosPendentesAgrupadoGrid = ({ dados, agruparPor }) => {
  const columns = [
    {
      dataField: agruparPor === "C" ? "id_cliente" : "id_produto",
      text: agruparPor === "C" ? "Cliente" : "Produto",
      align: "center",
      sortable: true,
    },
    {
      dataField: agruparPor === "C" ? "id_cliente__nome" : "nome_produto",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "qtd_pendente",
      text: "Qtd. Pendente",
      align: "center",
      sortable: true,
      formatter: (v) => formatNumber(v, true, 2, true),
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_pendente",
      text: "Valor Pendente",
      align: "right",
      sortable: true,
      formatter: (cell) => formatNumber(cell, true, 2),
      sortFunc: naturalSort,
    },
    {
      dataField: "det",
      isDummyField: true,
      align: "center",
      hidden: agruparPor !== "C",
      formatter: (cell, row, index) => (
        <DetalhesProdPendEntregaCliModal index={index} row={row} />
      ),
    },
  ];

  return (
    <Card body>
      <Table
        columns={columns}
        data={dados}
        keyField={agruparPor === "C" ? "id_cliente" : "id_produto"}
      />
    </Card>
  );
};
