import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col } from "reactstrap";
import Spinner from "reactstrap/lib/Spinner";

const FixedTable = ({
  columns,
  data = [],
  keyField = "id",
  style = {},
  noWrap,
  selectRow,
  striped = true,
  paginated = false,
  rowStyle = {},
  hideCount = false,
  cellEdit,
  md = 12,
  loading,
  remote,
  onTableChange,
}) => {
  const totalRegistros = (v = 0, className = "") =>
    !hideCount && (
      <span className={`table-record-count ${className}`.trim()}>
        {v} registro{v !== 1 ? "s" : ""}
      </span>
    );

  return (
    <Col md={md} className="p-0" style={{ position: "relative" }}>
      <div className="scrollable-table-wrapper mb-1" style={style}>
        <BootstrapTable
          selectRow={selectRow}
          remote={remote}
          classes={`w-auto p-0 mn-100 ${
            noWrap ? "nowrap" : ""
          } scrollable-table`}
          keyField={keyField}
          data={data}
          pagination={
            paginated
              ? paginationFactory({
                  sizePerPage: 30,
                  sizePerPageList: [],
                  showTotal: true,
                  paginationTotalRenderer: (from, to, size) =>
                    totalRegistros(size, "ml-2"),
                })
              : undefined
          }
          cellEdit={cellEdit}
          striped={striped}
          bordered={false}
          columns={columns}
          rowStyle={rowStyle}
          noDataIndication={
            loading ? (
              <Spinner size="sm" color="dark" />
            ) : (
              <p className="mt-2 text-muted">Sem dados</p>
            )
          }
          bootstrap4
          onTableChange={onTableChange}
        />
      </div>
      {!paginated && totalRegistros(data.length ?? 0)}
    </Col>
  );
};

export default FixedTable;
