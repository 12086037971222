import React from "react";
import { useSelector } from "react-redux";
import { CustomBarChart } from "../../../../../components";

export const GraficoDRE = () => {
  const store = useSelector((state) => state.dre);
  if (store.dataGrafico.length > 0) {
    return (
      <CustomBarChart
        title="Resumo do Demonstrativo de Resultado Agrupado por Mês"
        data={store.dataGrafico}
        dataKey="data"
        barDataKeys={[
          "receita",
          "despesa",
          "transitorio",
          "investimento",
          "outros",
        ]}
        totalLabel="Resultado"
        totalSumFunc={(payload) => {
          let resultado = 0;
          payload.forEach((e) => {
            if (e.dataKey === "receita") {
              resultado += e.value;
            } else if (e.dataKey === "despesa") {
              resultado -= e.value;
            }
          });
          return resultado;
        }}
        height={300}
      />
    );
  } else {
    return <div></div>;
  }
};
