import React, { useState } from "react";
import {
  formatNumber,
  formatValueFromAPI,
  getTextColorBg,
} from "../../../../../coreUtils";
import { FixedField } from "../../../../../components";
import { Collapse } from "reactstrap";

const border = "2px solid #c7c7c7";

const columns = [
  {
    dataField: "tipo_item",
    text: "Tipo",
    align: "center",
  },
  {
    dataField: "descricao_item",
    text: "Descrição",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_item),
  },
  {
    dataField: "meta_qtd_venda",
    text: "Meta",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
    footerDataField: "qtd_meta_volume",
    colStyle: { borderLeft: border },
  },
  {
    dataField: "qtd_ate_hoje",
    text: "Até Hoje",
    align: "right",
    formatter: (c, row) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "3px",
          paddingRight: "3px",
        }}
      >
        <span>{formatNumber(c, true, 2, true)}</span>
        {row && (
          <span>
            {formatNumber(row?.qtd_perc_ate_agora, true, 2, true) + "%"}
          </span>
        )}
      </div>
    ),
    cellColor: "cor_volume",
  },
  {
    dataField: "qtd_projetada",
    text: "Projeção",
    align: "right",
    formatter: (c, row) => (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingLeft: "3px",
          paddingRight: "3px",
        }}
      >
        <span>
          {formatNumber(c, true, 2, true)}{" "}
          {row && (
            <>
              (
              <small>
                {formatNumber(row?.qtd_perc_atingido_meta, true, 2, true) + "%"}
              </small>
              )
            </>
          )}
        </span>
      </div>
    ),
    footerDataField: "qtd_volume_projetado",
  },
  {
    dataField: "meta_vlr_faturado",
    text: "Meta",
    align: "right",
    formatter: (c) => (
      <>
        <small>R$</small> {formatNumber(c, true, 2)}
      </>
    ),
    footerDataField: "vlr_meta_faturamento",
    colStyle: { borderLeft: border },
  },
  {
    dataField: "vlr_ate_hoje",
    text: "Até Hoje",
    align: "right",
    formatter: (c, row) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "3px",
          paddingRight: "3px",
        }}
      >
        <span>
          <small>R$</small> {formatNumber(c, true, 2)}
        </span>
        {row && (
          <span>
            {formatNumber(row?.vlr_perc_ate_agora, true, 2, true) + "%"}
          </span>
        )}
      </div>
    ),
    cellColor: "cor_faturamento",
  },
  {
    dataField: "vlr_projetado",
    text: "Projeção",
    align: "right",
    formatter: (c, row) => (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingLeft: "3px",
          paddingRight: "3px",
        }}
      >
        <span>
          <small>R$</small> {formatNumber(c, true, 2)}{" "}
          {row && (
            <>
              (
              <small>
                {formatNumber(row?.vlr_perc_atingido_meta, true, 2, true) + "%"}
              </small>
              )
            </>
          )}
        </span>
      </div>
    ),
    footerDataField: "vlr_faturamento_projetado",
  },
  {
    dataField: "meta_perc_max_devol_troca",
    text: "Meta",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true) + "%",
    colStyle: { borderLeft: border },
  },
  {
    dataField: "perc_troca",
    text: "Até Hoje",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true) + "%",
    cellColor: "cor_troca",
  },
  {
    dataField: "qtd_clientes",
    text: "Qtd.",
    align: "center",
    colStyle: { borderLeft: border },
  },
  {
    dataField: "perc_clientes",
    text: "% Clientes",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true) + "%",
  },
];

const columnGroups = [
  { text: "", colSpan: 2 },
  {
    text: "QUANTIDADE",
    colSpan: 3,
    style: { borderInline: border },
  },
  {
    text: "VENDA",
    colSpan: 3,
    style: { borderInline: border },
  },
  { text: "TROCA", colSpan: 2, style: { borderInline: border } },
  { text: "Clientes que Compraram", colSpan: 2 },
];

export const DemontrativoMetasGrid = ({
  meta,
  gerarImpressao,
  visao,
  detalharPor,
}) => {
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const itens = meta.itens;
  const rotas = meta.rotas ?? [];
  const totais = meta.totais;

  const imprimir = async () => {
    if (loadingImprimir) return;
    setLoadingImprimir(true);
    await gerarImpressao([meta]);
    setLoadingImprimir(false);
  };

  return (
    <div>
      <div
        className="mb-1 px-2 py-2"
        style={{
          display: "flex",
          cursor: "pointer",
          backgroundColor: meta.cor,
        }}
        onClick={() => setCollapseOpen(!collapseOpen)}
      >
        <h6
          className="mb-0 mr-2"
          style={{ fontWeight: "bold", width: "300px" }}
        >
          {visao === "ROTA" ? (
            <>
              {formatValueFromAPI(meta.nome, meta.id)} - {meta.desc_meta_por}
            </>
          ) : (
            <>
              {meta.nome} - {meta.nome_supervisor}
            </>
          )}
          <br />
          <small>{meta.desc_status_meta}</small>
        </h6>
        <div style={{ display: "flex", flexDirection: "row" }} className="ml-3">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderColor: "#ccc",
              borderRight: "1px solid",
            }}
          >
            <span style={{ fontWeight: "bold", margin: "auto" }}>
              QUANTIDADE
            </span>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FixedField
                divClassName="pl-0"
                label="Meta"
                value={formatNumber(totais.qtd_meta_volume, true, 2, true)}
              />
              <FixedField
                label="Até Hoje"
                value={
                  <>
                    {formatNumber(totais.qtd_volume_ate_agora, true, 2, true)} |{" "}
                    {formatNumber(totais.perc_volume_ate_agora, true, 2, true)}%
                  </>
                }
              />
              <FixedField
                divClassName="pr-0"
                label="Projeção"
                value={
                  <>
                    {formatNumber(totais.qtd_volume_projetado, true, 2, true)} (
                    <small>
                      {formatNumber(totais.perc_volume_atingido, true, 2, true)}
                      %
                    </small>
                    )
                  </>
                }
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontWeight: "bold", margin: "auto" }}>VENDA</span>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FixedField
                  label="Meta"
                  value={
                    <>
                      <small>R$</small>{" "}
                      {formatNumber(totais.vlr_meta_faturamento, true, 2, true)}
                    </>
                  }
                />
                <FixedField
                  label="Até Hoje"
                  value={
                    <>
                      <small>R$</small>{" "}
                      {formatNumber(
                        totais.tot_faturamento_ate_agora,
                        true,
                        2,
                        true
                      )}{" "}
                      |{" "}
                      {formatNumber(
                        totais.perc_faturamento_ate_agora,
                        true,
                        2,
                        true
                      )}
                      %
                    </>
                  }
                />
                <FixedField
                  label="Projeção"
                  value={
                    <>
                      <small>R$</small>{" "}
                      {formatNumber(
                        totais.vlr_faturamento_projetado,
                        true,
                        2,
                        true
                      )}{" "}
                      (
                      <small>
                        {formatNumber(
                          totais.perc_faturamento_atingido,
                          true,
                          2,
                          true
                        )}
                        %
                      </small>
                      )
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <span
          className="ml-auto"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={imprimir}
        >
          {!loadingImprimir ? "Gerar PDF" : "Gerando PDF..."}
        </span>
      </div>
      <Collapse isOpen={collapseOpen}>
        <div style={{ marginBottom: "15px" }}>
          <table
            className="table table-striped"
            style={{ marginBottom: "1rem" }}
          >
            <thead>
              <tr>
                {columnGroups.map((group) => (
                  <th
                    className="p-1"
                    colSpan={group.colSpan}
                    style={{
                      ...group.style,
                      textAlign: "center",
                      borderBottom: "none",
                    }}
                  >
                    <b>{group.text}</b>
                  </th>
                ))}
              </tr>
              <tr>
                {columns.map((col) => (
                  <th
                    className="p-1"
                    style={{
                      ...col.colStyle,
                      textAlign: "center",
                      borderTop: "none",
                    }}
                  >
                    <b>{col.text}</b>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(detalharPor === "PROD" ? itens : rotas).map((row) => (
                <tr>
                  {columns.map((col) => (
                    <td
                      className="p-1"
                      style={{
                        ...col.colStyle,
                        ...(col.cellColor && {
                          backgroundColor: row[col.cellColor],
                          color: getTextColorBg(row[col.cellColor]),
                        }),
                        textAlign: col.align,
                      }}
                    >
                      {col.formatter
                        ? col.formatter(row[col.dataField], row)
                        : row[col.dataField]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Collapse>
    </div>
  );
};
