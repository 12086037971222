import React from "react";
import { useState } from "react";
import {
  ComboBox,
  Divider,
  FormCheckbox,
  IntegerFormInput,
  MaskedInput,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleTerminal, routesBaseTerminal } from "../Terminal";

export const marcaImpressoraConecGavetaOptions = [
  { label: "Não Identificada", value: "NAO_IDENT" },
  { label: "Daruma", value: "DARUMA" },
  { label: "EPSON/Elgin", value: "EPSON" },
  { label: "EPSON 2", value: "EPSON2" },
];

export const portaImpressoraOptions = [
  { label: "Não Identificada", value: "NAO_IDENT" },
  { label: "COM1", value: "COM1" },
  { label: "COM2", value: "COM2" },
  { label: "COM3", value: "COM3" },
  { label: "COM4", value: "COM4" },
  { label: "COM5", value: "COM5" },
  { label: "COM6", value: "COM6" },
  { label: "COM7", value: "COM7" },
  { label: "COM8", value: "COM8" },
  { label: "COM9", value: "COM9" },
  { label: "COM10", value: "COM10" },
  { label: "COM11", value: "COM11" },
  { label: "COM12", value: "COM12" },
  { label: "COM13", value: "COM13" },
  { label: "USB", value: "USB" },
  { label: "SERIAL", value: "SERIAL" },
  { label: "Outra", value: "OUTRA" },
];

const codTerminalIntegradoraMask = Array(100).fill("9").join("");

const marcaModeloBalancaOptions = [
  { label: "Urano", value: "URANO" },
  { label: "Urano POP-S", value: "URANO-POP" },
  { label: "Toledo Prix 8217", value: "PRIX-8217" },
];

const controleFluxoBalancaOptions = [
  { label: "Nenhum", value: "NENHUM" },
  { label: "XON-XOFF", value: "XON-XOFF" },
  { label: "RTS-CTS", value: "RTS-CTS" },
  { label: "DTR-DSR", value: "DTR-DSR" },
];

const paridadeBalancaOptions = [
  { label: "Nenhuma", value: "NENHUMA" },
  { label: "ODD", value: "ODD" },
  { label: "EVEN", value: "EVEN" },
  { label: "MARK", value: "MARK" },
  { label: "SPACE", value: "SPACE" },
];

const bitsParadaBalancaOptions = [
  { label: "1 Segundo", value: "s1" },
  { label: "1 Segundo e Meio", value: "s1.5" },
  { label: "2 Segundos", value: "s2" },
];

const bitsDadosBalancaOptions = [
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
];

const velocidadePortaBalancaOptions = [
  { label: "110", value: 110 },
  { label: "300", value: 300 },
  { label: "600", value: 600 },
  { label: "1200", value: 1200 },
  { label: "2400", value: 2400 },
  { label: "4800", value: 4800 },
  { label: "9600", value: 9600 },
  { label: "14400", value: 14400 },
  { label: "19200", value: 19200 },
  { label: "38400", value: 38400 },
  { label: "56000", value: 56000 },
  { label: "57600", value: 57600 },
];

export const CadastroTerminalModal = ({
  selected,
  notifyEvent,
  isOpen,
  toggle,
  action,
}) => {
  const [descricao, setDescricao] = useState("");
  const [codTerminalIntegradora, setCodTerminalIntegradora] = useState("");
  const [marcaImpressoraConecGaveta, setMarcaImpressoraConecGaveta] = useState(
    marcaImpressoraConecGavetaOptions[0].value
  );
  const [portaImpressoraConecGaveta, setPortaImpressoraConecGaveta] = useState(
    portaImpressoraOptions[0].value
  );
  const [outraPortaImpressoraConecGaveta, setOutraPortaImpressoraConecGaveta] =
    useState(null);
  const [portaBalanca, setPortaBalanca] = useState("");
  const [marcaModeloBalanca, setMarcaModeloBalanca] = useState(null);
  const [controleFluxoBalanca, setControleFluxoBalanca] = useState(null);
  const [paridadeBalanca, setParidadeBalanca] = useState(null);
  const [bitsParadaBalanca, setBitsParadaBalanca] = useState(null);
  const [bitsDadosBalanca, setBitsDadosBalanca] = useState(null);
  const [velocidadePortaBalanca, setVelocidadePortaBalanca] = useState(null);
  const [nomeImpressoraVenda, setNomeImpressoraVenda] = useState(null);
  const [temBalancaNoPDV, setTemBalancaNoPDV] = useState(false);
  const [tempoTimerBalanca, setTempoTimerBalanca] = useState(600);
  const [tempoTimeoutLeituraBalanca, setTempoTimeoutLeituraBalanca] =
    useState(500);

  const limparDados = () => {
    setDescricao("");
    setCodTerminalIntegradora("");
    setMarcaImpressoraConecGaveta(marcaImpressoraConecGavetaOptions[0].value);
    setPortaImpressoraConecGaveta(null);
    setOutraPortaImpressoraConecGaveta(null);
    setPortaBalanca("");
    setMarcaModeloBalanca(null);
    setControleFluxoBalanca(null);
    setParidadeBalanca(null);
    setBitsParadaBalanca(null);
    setBitsDadosBalanca(null);
    setVelocidadePortaBalanca(null);
    setNomeImpressoraVenda(null);
    setTemBalancaNoPDV(false);
    setTempoTimerBalanca(600);
    setTempoTimeoutLeituraBalanca(500);
  };

  const fetchData = (data) => {
    setDescricao(data.descricao ?? "");
    setCodTerminalIntegradora(data.cod_terminal_integradora ?? "");
    setMarcaImpressoraConecGaveta(
      marcaImpressoraConecGavetaOptions.findIndex(
        (e) => e.value === data.marca_impressora_conec_gaveta
      ) >= 0
        ? data.marca_impressora_conec_gaveta
        : "NAO_IDENT"
    );
    if (
      data.porta_impressora_conec_gaveta &&
      portaImpressoraOptions.findIndex(
        (e) => e.value === data.porta_impressora_conec_gaveta
      ) < 0
    ) {
      setPortaImpressoraConecGaveta("OUTRA");
      setOutraPortaImpressoraConecGaveta(data.porta_impressora_conec_gaveta);
    } else {
      setPortaImpressoraConecGaveta(data.porta_impressora_conec_gaveta);
    }
    setMarcaModeloBalanca(data.marca_modelo_balanca);
    setControleFluxoBalanca(data.controle_fluxo_balanca);
    setParidadeBalanca(data.paridade_balanca);
    setBitsParadaBalanca(data.bits_parada_balanca);
    setBitsDadosBalanca(data.bits_dados_balanca);
    setVelocidadePortaBalanca(data.velocidade_porta_balanca);
    setPortaBalanca(data.porta_balanca);
    setTemBalancaNoPDV(data.tem_balanca_no_pdv);
    setNomeImpressoraVenda(data.nome_impressora_venda);
    setTempoTimerBalanca(parseFloat(data.tempo_timer_balanca));
    setTempoTimeoutLeituraBalanca(
      parseFloat(data.tempo_timeout_leitura_balanca)
    );
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(descricao)) {
      toastr.warning("Atenção", "Por favor, informe a Descrição do Terminal");
      return false;
    }

    const payload = {
      descricao: descricao,
      cod_terminal_integradora: codTerminalIntegradora,
      marca_impressora_conec_gaveta: marcaImpressoraConecGaveta,
      porta_impressora_conec_gaveta:
        portaImpressoraConecGaveta !== "OUTRA"
          ? portaImpressoraConecGaveta
          : outraPortaImpressoraConecGaveta,
      porta_balanca: portaBalanca,
      marca_modelo_balanca: marcaModeloBalanca,
      controle_fluxo_balanca: controleFluxoBalanca,
      paridade_balanca: paridadeBalanca,
      bits_parada_balanca: bitsParadaBalanca,
      bits_dados_balanca: bitsDadosBalanca,
      velocidade_porta_balanca: velocidadePortaBalanca,
      nome_impressora_venda: nomeImpressoraVenda,
      tem_balanca_no_pdv: temBalancaNoPDV,
      tempo_timer_balanca: tempoTimerBalanca ?? 0,
      tempo_timeout_leitura_balanca: tempoTimeoutLeituraBalanca ?? 0,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      title={modalTitleTerminal}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseTerminal}
      number="0075_1"
      selected={selected}
      notifyEvent={notifyEvent}
      isOpen={isOpen}
      toggle={toggle}
      action={action}
    >
      <Row>
        <TextInput
          md={12}
          label="Descrição"
          value={descricao}
          onChange={(e, v) => setDescricao(v)}
          maxLength={80}
        />
      </Row>
      <Divider>TEF</Divider>
      <Row>
        <MaskedInput
          md={12}
          label="Código Terminal na Integradora TEF"
          value={codTerminalIntegradora}
          onChange={(e, v) => setCodTerminalIntegradora(v)}
          maxLength={100}
          forceUppercase={false}
          mask={codTerminalIntegradoraMask}
          maskChar={null}
        />
      </Row>
      <Divider>Gaveta</Divider>
      <Row>
        <ComboBox
          md={4}
          label="Marca da Impressora"
          options={marcaImpressoraConecGavetaOptions}
          defaultValue={marcaImpressoraConecGaveta}
          onChange={(s) => setMarcaImpressoraConecGaveta(s?.value)}
        />
        <ComboBox
          md={4}
          label="Porta da Impressora"
          options={portaImpressoraOptions}
          defaultValue={portaImpressoraConecGaveta}
          onChange={(s) => setPortaImpressoraConecGaveta(s?.value)}
        />
        {portaImpressoraConecGaveta === "OUTRA" && (
          <TextInput
            md={8}
            label="Nome da Porta"
            value={outraPortaImpressoraConecGaveta}
            onChange={(e, v) => setOutraPortaImpressoraConecGaveta(v)}
            maxLength={40}
            forceUppercase={false}
          />
        )}
      </Row>
      <Divider>Balança</Divider>
      <Row>
        <ComboBox
          md={6}
          label="Marca/Modelo"
          options={marcaModeloBalancaOptions}
          isClearable
          defaultValue={marcaModeloBalanca}
          onChange={(s) => setMarcaModeloBalanca(s?.value ?? null)}
        />
        <ComboBox
          md={6}
          label="Controle de Fluxo"
          options={controleFluxoBalancaOptions}
          isClearable
          defaultValue={controleFluxoBalanca}
          onChange={(s) => setControleFluxoBalanca(s?.value ?? null)}
        />
      </Row>
      <Row>
        <ComboBox
          md={6}
          label="Paridade"
          options={paridadeBalancaOptions}
          isClearable
          defaultValue={paridadeBalanca}
          onChange={(s) => setParidadeBalanca(s?.value ?? null)}
        />
        <ComboBox
          md={6}
          label="Bits de Parada"
          options={bitsParadaBalancaOptions}
          isClearable
          defaultValue={bitsParadaBalanca}
          onChange={(s) => setBitsParadaBalanca(s?.value ?? null)}
        />
      </Row>
      <Row>
        <ComboBox
          md={6}
          label="Bits de Dados"
          options={bitsDadosBalancaOptions}
          isClearable
          defaultValue={bitsDadosBalanca}
          onChange={(s) => setBitsDadosBalanca(s?.value ?? null)}
        />
        <ComboBox
          md={6}
          label="Velocidade da Porta"
          options={velocidadePortaBalancaOptions}
          isClearable
          defaultValue={velocidadePortaBalanca}
          onChange={(s) => setVelocidadePortaBalanca(s?.value ?? null)}
        />
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Porta da Balança"
          value={portaBalanca}
          onChange={(e, v) => setPortaBalanca(v)}
          maxLength={15}
          forceUppercase={false}
        />
        <IntegerFormInput
          label="Tempo Leitura Balança (ms)"
          md={6}
          defaultValue={tempoTimerBalanca}
          onChange={setTempoTimerBalanca}
        />
      </Row>
      <Row>
        <IntegerFormInput
          label="Timeout Leitura Balança (ms)"
          md={6}
          defaultValue={tempoTimeoutLeituraBalanca}
          onChange={setTempoTimeoutLeituraBalanca}
        />
        <FormCheckbox
          label="Tem Balança no PDV"
          name="tem_balanca_no_pdv"
          checked={temBalancaNoPDV}
          onChange={() => setTemBalancaNoPDV(!temBalancaNoPDV)}
        />
      </Row>
      <Divider>Impressora de Vendas</Divider>
      <Row>
        <TextInput
          md={6}
          label="Nome da Impressora de Vendas"
          value={nomeImpressoraVenda}
          onChange={(e, v) => setNomeImpressoraVenda(v)}
          maxLength={30}
          forceUppercase={false}
        />
      </Row>
    </ModalCadastroV2>
  );
};
