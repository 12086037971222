import { docPost } from "../../../../../pdf";

export const imprimirEtqL13 = async (etiquetas) => {
  const payload = {
    layout: 13,
    etiquetas: etiquetas,
  };
  const res = await docPost("/produto/etiqueta/", payload, {
    errorMessage: "Falha ao gerar impressão de etiquetas",
  });
  if (res) {
    return [true, res];
  } else {
    return [false, null];
  }
};
