import React from "react";
import { Table } from "../../../../../components";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSortDate,
} from "../../../../../coreUtils";

const columns = [
  { dataField: "id", text: "#", align: "center" },
  { dataField: "numero", text: "Número", align: "center" },
  {
    dataField: "nome_fornecedor",
    text: "Fornecedor",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_fornecedor),
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
    sortFunc: naturalSortDate,
  },
  {
    dataField: "entrada",
    text: "Entrada",
    align: "center",
    sortFunc: naturalSortDate,
  },
  {
    dataField: "qtd_itens",
    text: "Qtd. Itens",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_tot_prod",
    text: "Produtos",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const FracionamentoNfEntradaGrid = ({ dados, onSelect }) => {
  return (
    <Table
      data={dados}
      columns={columns}
      pageSize={10}
      paginated={false}
      onSelect={onSelect}
    />
  );
};
