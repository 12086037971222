import { apiGetV2 } from "../../apiV2";

const GerenciamentoComissoesService = {
  listar: async (rotas, dataIni, dataFim) => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      rotas: (rotas ?? []).join(","),
    };

    return await apiGetV2(`relatorios/venda_direta/comissao/listar/`, params);
  },
  buscarDetalhamentoComiss: async (idRota, dataIni, dataFim) => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
    };

    return await apiGetV2(
      `relatorios/venda_direta/comissao/detalhamento/${idRota}/`,
      params
    );
  },
};

export default GerenciamentoComissoesService;
