import React, { useRef, useState } from "react";
import {
  FixedField,
  FormButton,
  ModalBase,
  NumberInput,
} from "../../../../../../components";
import PesqProduto from "../../../../../../components/form/pesq_produto/PesqProduto";
import { Row } from "reactstrap";
import { useEffect } from "react";
import { showWarning } from "../../../../../../components/AlertaModal";
import { formatNumber } from "../../../../../../coreUtils";
import { ConfirmarItemJaIncluidoSugestaoModal } from "./ConfirmarItemJaIncluidoModal";

export const IncluirProdutoSugestaoCompraModal = ({
  incluirItem,
  produtos,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // Dados do Produto
  const [idProduto, setIdProduto] = useState(null);
  const [descricao, setDescricao] = useState("");
  const [referencia, setReferencia] = useState("");
  const [unidade, setUnidade] = useState("");
  const [quantidade, setQuantidade] = useState(1);
  const [custoUe, setCustoUe] = useState(0);
  //Controle
  const [loading, setLoading] = useState(false);
  const [qtdCasasDecimaisQtd, setQtdCasasDecimaisQtd] = useState(2);
  const [confirmarJaIncluidoOpen, setConfirmarJaIncluidoOpen] = useState(false);
  const quantidadeRef = useRef();
  const refPesqProd = useRef();
  const semProduto = [0, null, undefined].includes(idProduto);

  const limparDadosProduto = () => {
    setIdProduto(0);
    setDescricao("");
    setReferencia("");
    setUnidade("");
    setQuantidade(1);
    setCustoUe(0);
    setQtdCasasDecimaisQtd(2);
    if (refPesqProd.current) {
      refPesqProd.current.clear();
    }
  };

  const selectNextField = () => {
    setTimeout(() => {
      if (quantidadeRef.current) {
        quantidadeRef.current.focus();
      }
    }, 35);
  };

  const handleSelectProduto = ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    unidade,
    custoUe,
    casasDecimaisQtd = 2,
  }) => {
    if (idProduto !== id) {
      if (["", 0, null, undefined].includes(id)) {
        limparDadosProduto();
        return;
      }

      setIdProduto(id);
      setDescricao(nome);
      setQuantidade(1);
      setUnidade(unidade);
      setReferencia(referencia);
      setCustoUe(custoUe);
      setQtdCasasDecimaisQtd(casasDecimaisQtd);

      selectNextField();
    }
  };

  useEffect(() => {
    if (refPesqProd.current && idProduto) {
      refPesqProd.current.setId(String(idProduto));
      refPesqProd.current.setDescricao(descricao);
      refPesqProd.current.setReferencia(String(referencia));
    }
  }, [refPesqProd.current, idProduto]);

  const toggleConfirmarJaIncluido = () =>
    setConfirmarJaIncluidoOpen(!confirmarJaIncluidoOpen);

  const onConfirm = () => {
    if (semProduto) {
      showWarning("Por favor, informe o Produto");
      if (refPesqProd.current) {
        refPesqProd.current.focus();
      }
      return;
    }

    if ([0, null, undefined].includes(quantidade)) {
      showWarning("Por favor, informe a Quantidade de Compra");
      if (quantidadeRef.current) {
        quantidadeRef.current.focus();
      }
      return;
    }

    if (produtos.some((e) => e.id_produto === idProduto)) {
      toggleConfirmarJaIncluido();
      return;
    }

    handleSubmit();
  };

  const handleSubmit = async () => {
    const payload = {
      id_produto: idProduto,
      quantidade: quantidade,
    };

    setLoading(true);
    if (await incluirItem(payload)) {
      limparDadosProduto();
      if (refPesqProd.current) {
        refPesqProd.current.focus();
      }
    }
    setLoading(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton
        padded={false}
        color="info"
        onClick={toggle}
        hint={"Adicionar produtos que não estejam aparecendo na sugestão"}
      >
        + Produto
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Adicionar Produto à Sugestão de Compra"
        number="0016_5"
        onClosed={limparDadosProduto}
        onConfirm={onConfirm}
        loadingConfirm={loading}
      >
        <Row>
          <PesqProduto
            md={12}
            onConfirm={handleSelectProduto}
            ref={refPesqProd}
            selectNextField={selectNextField}
            onChangeDescricao={setDescricao}
            mdIdent={3}
            mdDesc={6}
            mdIdentAux={3}
            divClassName="pr-0"
            auxAsLabel
          />
        </Row>
        <Row>
          <NumberInput
            md={2}
            label="Quantidade"
            value={quantidade}
            onChange={setQuantidade}
            disabled={semProduto}
            decimalPlaces={qtdCasasDecimaisQtd}
            ref={quantidadeRef}
          />
          <FixedField
            md="auto"
            divClassName="pt-4"
            label="Unidade"
            value={unidade}
            horizontal
          />
          <FixedField
            md="auto"
            divClassName="pt-4"
            label="Custo Unitário"
            value={formatNumber(custoUe, true, 2)}
            horizontal
          />
          <FixedField
            md="auto"
            divClassName="pt-4"
            label="Custo Total"
            value={formatNumber(custoUe * (quantidade ?? 0), true, 2)}
            horizontal
          />
        </Row>
      </ModalBase>
      <ConfirmarItemJaIncluidoSugestaoModal
        isOpen={confirmarJaIncluidoOpen}
        toggle={toggleConfirmarJaIncluido}
        onConfirm={handleSubmit}
      />
    </>
  );
};
