import { apiGetV2 } from "../../../apiV2";

const URL_BASE = "/relatorios/estoque";

const ListagemValorEstoqueService = {
  listar: async (params) =>
    await apiGetV2(`${URL_BASE}/listagem_valor_em_estoque/`, params),
};

export default ListagemValorEstoqueService;
