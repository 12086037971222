import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import { AsyncComboBox, ComboBox } from "../../../../components";
import { ModalBase } from "../../../../components/ModalBase";
import CashbackService from "../../../../services/cashback/CashbackService";
import FiltersServiceV2 from "../../../../services/FiltersServiceV2";

class ModalCadastro extends Component {
  state = {
    isOpen: false,
    tipo_uso: "GRU",
    id_uso: undefined,
    loading: false,
  };
  select = React.createRef();

  toggle = () => {
    this.setState((prevState) => {
      prevState["isOpen"] = !prevState["isOpen"];
      prevState["tipo_uso"] = "GRU";
      if (prevState["id_uso"] !== undefined && this.select.current)
        this.select.current.clearValue();
      prevState["id_uso"] = undefined;
      return prevState;
    });
  };

  handleLoad = (value) => {
    const { tipo_uso } = this.state;
    if (value?.length > 1 && tipo_uso !== null) {
      return new Promise((resolve) => {
        if (tipo_uso === "GRU") {
          FiltersServiceV2.fetch(
            "grupo",
            `nome__icontains=${value.toUpperCase()}`
          ).then((data) => {
            resolve(data);
          });
        } else if (tipo_uso === "FAB") {
          FiltersServiceV2.fetch(
            "fabricante",
            `nome__icontains=${value.toUpperCase()}`
          ).then((data) => {
            resolve(data);
          });
        } else {
          FiltersServiceV2.fetch(
            "produto",
            `nome__icontains=${value.toUpperCase()}`
          ).then((data) => {
            resolve(data);
          });
        }
      });
    } else {
      return [];
    }
  };

  addItem = async () => {
    const { tipo_uso, id_uso } = this.state;

    if (!tipo_uso.match(/GRU|FAB|PRO/)) {
      toastr.error("Ops!", "O tipo do identificador é inválido.");
      return;
    }

    if (!id_uso) {
      toastr.error("Ops!", "O identificador é inválido.");
      return;
    }

    this.setState({ loading: true });

    await CashbackService.addNewPermission({ tipo_uso, id_uso })
      .then(() => {
        toastr.success("Sucesso!", "A inclusão foi realizada sem erros.");
        this.toggle();
        if (this.props.notify) this.props.notify();
      })
      .catch((err) => {
        toastr.error("Ops!", err);
      });
    this.setState({ loading: false });
  };

  handleSelect = (selected, target) => {
    if (["tipo_uso", "id_uso"].includes(target.name) && selected !== null)
      selected = selected.value;
    if (target.name === "tipo_uso" && this.select.current)
      this.select.current.clearValue();
    this.setState({ [target.name]: selected });
  };

  render() {
    const { isOpen, loading } = this.state;
    const OPTIONS = [
      { label: "GRUPO", value: "GRU" },
      { label: "FABRICANTE", value: "FAB" },
      { label: "PRODUTO", value: "PRO" },
    ];

    return (
      <ModalBase
        isOpen={isOpen}
        toggle={this.toggle}
        size="lg"
        title="Cadastro de permissões de uso de cashback"
        number="0008_1"
        onConfirm={this.addItem}
        loadingConfirm={loading}
      >
        <Row form>
          <ComboBox
            md={3}
            label="Tipo"
            name="tipo_uso"
            options={OPTIONS}
            onChange={this.handleSelect}
            defaultValue={this.state.tipo_uso}
          />
          <AsyncComboBox
            md={9}
            label="Identificador"
            loadOptions={this.handleLoad}
            onChange={this.handleSelect}
            name="id_uso"
            isSearchable
            isClearable
            ref={this.select}
          />
        </Row>
      </ModalBase>
    );
  }
}

export default ModalCadastro;
