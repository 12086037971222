import React from "react";
import { Card, UncontrolledTooltip } from "reactstrap";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { Table } from "../../../../../components";

export const TableVendasPorRota = ({ dados }) => {
  const _columns = [
    {
      dataField: "rota",
      text: "Rota",
      align: "left",
      headerAlign: "left",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "quantidade_vendas",
      text: "Qtd. de Vendas",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "ticket_medio",
      text: (
        <>
          <span id="ticket_medio">Ticket Médio</span>
          <UncontrolledTooltip target="ticket_medio">
            Valor calculado considerando o total de vendas sem ICMS ST
          </UncontrolledTooltip>
        </>
      ),
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "perc_troca",
      text: "% Troca",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "total_troca",
      text: "Total Troca",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "total_bonif",
      text: "Total Bonificação",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "total_venda_com_st",
      text: "Total Vendas com ST",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "total_venda",
      text: "Total Vendas sem ST",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
  ];

  return (
    <Card body>
      <Table columns={_columns} data={dados} keyField="rota" />
    </Card>
  );
};
