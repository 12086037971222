import React from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { BaseInfoCard } from "../../../../../components/BaseInfoCard";
import { DataTable } from "../../../../../components/DataTable";
import { formatNumber } from "../../../../../coreUtils";
import { gerarDadosTotaisGrupos } from "../VendasAgrupadasPorProduto";

export const GridVendasAgrupadasPorProd = () => {
  const _state = useSelector((state) => state.vendasAgrupadasPorProduto);

  const columnsDivisao = () =>
    _state.dividirDados
      ? [
          {
            isDummyField: true,
            dataField: "sem_serie",
            text: "Sem Série",
            align: "center",
            headerAlign: "center",
            sort: false,
            classes: "min spacer",
            headerClasses: "min spacer",
            footerClasses: "min",
            formatter: (_, row) => (
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  WebkitJustifyContent: "space-between",
                }}
              >
                <span class="px-2">
                  {formatNumber(row["quant_sem_serie"], true, 0)}
                </span>
                <span class="px-2">
                  R$ {formatNumber(row["vlr_sem_serie"])}
                </span>
              </div>
            ),
          },
          {
            isDummyField: true,
            dataField: "com_serie",
            text: "Com Série",
            align: "center",
            headerAlign: "center",
            sort: false,
            classes: "min spacer",
            headerClasses: "min spacer",
            footerClasses: "min",
            formatter: (_, row) => (
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  WebkitJustifyContent: "space-between",
                }}
              >
                <span class="px-2">
                  {formatNumber(row["quant_com_serie"], true, 0)}
                </span>
                <span class="px-2">
                  R$ {formatNumber(row["vlr_com_serie"])}
                </span>
              </div>
            ),
          },
        ]
      : [];

  const columnTotal = {
    isDummyField: true,
    dataField: "total",
    text: "Total",
    align: "center",
    headerAlign: "center",
    classes: "min spacer",
    headerClasses: "min spacer",
    footerClasses: "min",
    sort: false,
    formatter: (_, row) => (
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          WebkitJustifyContent: "space-between",
        }}
      >
        <span class="px-2">{formatNumber(row["quant_total"], true, 0)}</span>
        <span class="px-2">R$ {formatNumber(row["vlr_total"])}</span>
      </div>
    ),
  };

  const _columns = [
    {
      dataField: "id_produto",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
      footer: "",
    },
    {
      dataField: "nome_produto",
      text: "Nome Produto",
      align: "left",
      headerAlign: "left",
      sort: true,
      footer: "",
    },
    ...columnsDivisao(),
    { ...columnTotal },
  ];

  const _columnsGrupos = [
    {
      dataField: "nome_grupo",
      text: "Grupo",
      align: "left",
      headerAlign: "left",
    },
    ...columnsDivisao(),
    { ...columnTotal },
  ];

  if (_state.dadosRelatorio?.length > 0) {
    _columns.forEach((element) => {
      switch (element.dataField) {
        case "total":
          element.footer = "";
          element.footerFormatter = () => (
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                WebkitJustifyContent: "space-between",
              }}
            >
              <span class="px-2">
                {formatNumber(
                  _state.totaisRelatorio?.total?.quant ?? "0",
                  true,
                  0
                )}
              </span>
              <span class="px-2">
                R${" "}
                {formatNumber(_state.totaisRelatorio?.total?.vlr_prod ?? "0")}
              </span>
            </div>
          );
          break;
        case "sem_serie":
          element.footer = "";
          element.footerFormatter = () => (
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                WebkitJustifyContent: "space-between",
              }}
            >
              <span class="px-2">
                {formatNumber(
                  _state.totaisRelatorio?.sem_serie?.quant ?? "0",
                  true,
                  0
                )}
              </span>
              <span class="px-2">
                R${" "}
                {formatNumber(
                  _state.totaisRelatorio?.sem_serie?.vlr_prod ?? "0"
                )}
              </span>
            </div>
          );
          break;
        case "com_serie":
          element.footer = "";
          element.footerFormatter = () => (
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                WebkitJustifyContent: "space-between",
              }}
            >
              <span class="px-2">
                {formatNumber(
                  _state.totaisRelatorio?.com_serie?.quant ?? "0",
                  true,
                  0
                )}
              </span>
              <span class="px-2">
                R${" "}
                {formatNumber(
                  _state.totaisRelatorio?.com_serie?.vlr_prod ?? "0"
                )}
              </span>
            </div>
          );
          break;
        default:
          element.footer = "";
          break;
      }
    });
  }

  return _state.dadosRelatorio?.length > 0 ? (
    <>
      {_state.adicSubtotalGrupos && (
        <Card body>
          <DataTable
            columns={_columnsGrupos}
            data={gerarDadosTotaisGrupos(_state.totaisGrupos)}
            keyField="nome_grupo"
            noWrap
            className="mt-2"
            paginated
            defaultSorted={[{ dataField: "nome_grupo", order: "asc" }]}
          />
        </Card>
      )}
      <Card body>
        <DataTable
          columns={_columns}
          data={_state.dadosRelatorio}
          keyField="id_produto"
          noWrap
          className="mt-2"
          paginated
          defaultSorted={[{ dataField: "nome_produto", order: "asc" }]}
        />
      </Card>
      <BaseInfoCard
        baseInfo={[
          "Vendas: Vendas realizadas por Aplicativos Mobile e Desktop",
        ]}
      />
    </>
  ) : (
    <div></div>
  );
};
