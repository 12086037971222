import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FixedTable,
  FormButton,
} from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import { TableDelete } from "../../../../../components/TableDelete";
import VendedorTecnicoServicoService from "../../../../../services/cadastro/VendedorTecnicoServicoService";

export const ModalCidadesVTS = ({ notify, idVTS }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cidadesVTS, setCidadesVTS] = useState([]);
  const [idCidadeSelecionada, setIdCidadeSelecionada] = useState(null);
  const cidadeSelectRef = useRef();
  const [loadingAdd, setLoadingAdd] = useState(false);

  const columns = [
    {
      dataField: "id_cidade",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "id_cidade__nome",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "Ações",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => (
        <TableDelete onClick={() => removerCidade(row.id)} />
      ),
    },
  ];

  const buscarDados = async () => {
    const ret = await VendedorTecnicoServicoService.buscarCidades(idVTS);
    setCidadesVTS(ret);
  };

  const handleCidadeSelect = (sel) => setIdCidadeSelecionada(sel?.value);

  const removerCidade = async (idRegistro) => {
    const ret = await VendedorTecnicoServicoService.excluirCidade(idRegistro);
    if (ret === true) buscarDados();
  };

  const handleSubmit = async () => {
    const payload = {
      id_vts: idVTS,
      id_cid: idCidadeSelecionada,
    };
    let ret;
    setLoadingAdd(true);
    ret = await VendedorTecnicoServicoService.adicionarCidade(payload);
    if (ret === true) {
      cidadeSelectRef.current.clearValue();
      cidadeSelectRef.current.setFocus();
      buscarDados();
    }
    setLoadingAdd(false);
  };

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
    } else {
      setCidadesVTS([]);
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton
        padded={true}
        divClassName="pr-0"
        md="auto"
        disabled={[0, null, undefined].includes(idVTS)}
        color="primary"
        onClick={toggle}
      >
        Cidades
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        // onOpened
        // onClosed
        title="Inclusão de Cidades - Comissão VTS"
        number="0020_3"
      >
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="cidade"
            isSearchable
            defaultOptions
            md={9}
            label="Cidade"
            onChange={handleCidadeSelect}
            ref={cidadeSelectRef}
            autoFocus
          />
          <FormButton
            md={3}
            color="info"
            onClick={handleSubmit}
            loading={loadingAdd}
            disabled={[0, null, undefined].includes(idCidadeSelecionada)}
            disabledHint={"Selecione uma cidade no campo à esquerda"}
          >
            Adicionar
          </FormButton>
        </Row>
        <Row>
          <FixedTable
            noWrap
            columns={columns}
            data={cidadesVTS}
            keyField="id_cidade"
          />
        </Row>
      </ModalBase>
    </>
  );
};
