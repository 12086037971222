import React, { useEffect, useState } from "react";
import { handleFocus, limparNumero } from "../coreUtils";
import { Col, FormGroup, Label } from "reactstrap";
import classNames from "classnames";
import "react-international-phone/build/index.css";
import {
  PhoneInput as InternationalPhoneInput,
  buildCountryData,
  defaultCountries,
  parseCountry,
} from "react-international-phone";

const brasil = parseCountry(
  defaultCountries.find((e) => parseCountry(e).iso2 === "br")
);

const countries = [
  buildCountryData({
    ...brasil,
    format: {
      default: "...................",
      "/^[0-9]{2}9[0-9]{8}/": "(..) . ....-....",
      "/^9[0-9]{8}$/": ". ....-....",
      "/^[34][0-9]{7}$/": "....-....",
      "/^[0-9]{2}[0-9]/": "(..) ....-....",
    },
  }),
];

export const PhoneNumberInput = ({
  md,
  label,
  value,
  onChange,
  divClassName,
  className,
  formGroupClassName,
  hiddenLabel,
  mdInput = 12,
  maxLength = 18,
  id,
}) => {
  const [internalValue, setInternalValue] = useState("");
  const [internalId] = useState(
    id ?? "pi-" + Math.floor(Math.random() * Date.now())
  );

  const handleOnChange = (phone, { inputValue }) => {
    setInternalValue(inputValue);

    if (onChange) {
      onChange(inputValue);
    }
  };

  useEffect(() => {
    const numeros = limparNumero(value);
    if (numeros !== limparNumero(internalValue)) {
      setInternalValue(value.replaceAll(/(\d)\./g, / $1 /));
    }
  }, [value]);

  const onKeyDownInternal = (e) => {
    handleFocus(e);
  };

  return (
    <Col md={md} className={divClassName}>
      <FormGroup className={formGroupClassName}>
        <Label for={internalId} hidden={hiddenLabel}>
          {label}
        </Label>
        <Col md={mdInput} className="no-gutters">
          <InternationalPhoneInput
            defaultCountry="br"
            disableCountryGuess
            hideDropdown
            // flags={{ show: false }}
            dialCodePreviewStyleProps={{}}
            value={internalValue}
            onChange={(p, meta) => handleOnChange(p, meta)}
            maxLength={maxLength}
            inputProps={{
              className: classNames("form-control", className),
              onKeyDown: onKeyDownInternal,
            }}
            disableDialCodeAndPrefix
            disableDialCodePrefill
            countrySelectorStyleProps={{ style: { display: "none" } }}
            countries={countries}
          />
          {/* <TelefoneBrasileiroInput
            value={internalValue}
            onChange={handleOnChange}
            onKeyPress={onKeyPress}
            maxLength={maxLength}
            onKeyDown={onKeyDownInternal}
            temDDD={temDDD}
            separaDDD
            className={classNames("form-control", className)}
          /> */}
        </Col>
      </FormGroup>
    </Col>
  );
};
