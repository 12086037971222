import { apiGetV2 } from "../apiV2";

const URL_BASE = "/aviso";

const AvisoService = {
  buscarAvisosColab: async () =>
    await apiGetV2(`${URL_BASE}/buscar_avisos_colab/`),
  buscarTiposAvisosColab: async () =>
    await apiGetV2(`${URL_BASE}/buscar_tipos_avisos_colab/`),
  avisosTitulosPagar: async (filtro) =>
    await apiGetV2(`${URL_BASE}/avisos_titulos_pagar/${filtro}/`),
  avisosTitulosReceber: async (filtro) =>
    await apiGetV2(`${URL_BASE}/avisos_titulos_receber/${filtro}/`),
  avisosVendas: async (filtro) =>
    await apiGetV2(`${URL_BASE}/avisos_vendas/${filtro}/`),
  listarTiposAvisosLiberados: async () =>
    await apiGetV2(`${URL_BASE}/listar_tipos_avisos_liberados/`),
};

export default AvisoService;
