import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/cadastro/cliente_excecao_inadimpl_boleto";
const ClienteExcecaoInadBoletoService = {
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  excluir: (idExcecao) => apiDeleteV2(`${URL_BASE}/excluir/${idExcecao}/`),
  alterar: (payload) => apiPutV2(`${URL_BASE}/alterar/`, payload),
};
export default ClienteExcecaoInadBoletoService;
