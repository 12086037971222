import { configureStore } from "@reduxjs/toolkit";
import itensOsSlice from "./itens_os_slice";
import paramsOsSlice from "./params_os_slice";

export default configureStore({
  reducer: {
    itensOS: itensOsSlice,
    paramsOS: paramsOsSlice,
  },
});
