import React from "react";
import { Row } from "reactstrap";
import {
  IntegerFormInput,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { RadioItem } from "../../../../components/RadioGroup";
import {
  setFormaImpressao,
  setMsgFixa,
  setNomeImpressora,
  setNroVias,
} from "../store/condicional_slice";

export const PersonCondicional = () => {
  const store = useSelector((state) => state.condicional);
  const dispatch = useDispatch();
  return (
    <>
      <Row>
        <RadioGroup
          label="Imprimir Condicional"
          value={store.forma_impressao}
          onChange={(v) => dispatch(setFormaImpressao(v))}
        >
          <RadioItem label="Sempre Imprimir" value="IMP" />
          <RadioItem label="Visualizar" value="VIS" />
          <RadioItem label="Perguntar se Deseja Imprimir" value="PER" />
          <RadioItem label="Não Imprimir" value="NAO" />
        </RadioGroup>
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Nome da Impressora"
          value={store.nome_impressora}
          onChange={(e, v) => dispatch(setNomeImpressora(v))}
          forceUppercase={false}
          maxLength={50}
        />
      </Row>
      <Row>
        <IntegerFormInput
          md={2}
          mdInput={8}
          label="Nº de Vias"
          defaultValue={store.nro_vias}
          onChange={(v) => dispatch(setNroVias(v))}
        />
      </Row>
      <Row>
        <TextInput
          md={10}
          label="Mensagem Fixa"
          value={store.msg_fixa}
          onChange={(e, v) => dispatch(setMsgFixa(v))}
        />
      </Row>
    </>
  );
};
