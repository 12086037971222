import { apiGet, urlParams } from "../../../api";

const CurvaABCService = {
  buscar: async (
    dataIni,
    dataFim,
    modo,
    formato,
    indicador,
    maxItens,
    idTipoCliente,
    idRamoAtividade,
    idGrupo,
    idSubGrupo,
    idFabricante,
    idVendedor,
    idCliente
  ) => {
    const params = urlParams({
      data_ini: dataIni,
      data_fim: dataFim,
      indicador: indicador,
      max_itens: maxItens,
      id_tipo_cliente: idTipoCliente,
      id_ramo_atividade: idRamoAtividade,
      id_grupo: idGrupo,
      id_sub_grupo: idSubGrupo,
      id_fabricante: idFabricante,
      id_vendedor: idVendedor,
      id_cliente: idCliente,
    });

    const _modo = modo === "C" ? "compras" : "venda";

    return await apiGet(`/relatorios/${_modo}/curva_abc/${formato}/${params}`);
  },
};

export default CurvaABCService;
