import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, TabPane } from "reactstrap";
import { NumberInput } from "../../../../../../../components";
import { setPercIcmsDifer } from "../../../store/cadRegraTributacaoSlice";

export const TabDiferICMS = () => {
  const store = useSelector((state) => state.cadRegraTributacao);
  const dispatch = useDispatch();

  return (
    <TabPane tabId="6" className="p-2">
      <Row className="mb-3">
        <NumberInput
          label="Alíquota"
          md={2}
          onChange={(v) => dispatch(setPercIcmsDifer(v))}
          value={store.percIcmsDifer}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
        />
      </Row>
      <span>
        Para a aplicação do Diferimento, é necessário que a operação se enquadre
        nas seguintes condições:
      </span>
      <li className="ml-3">
        1. O Regime Tributário do Emitente deve ser Lucro Real ou Presumido;
      </li>
      <li className="ml-3">
        2. O Destinatário da NF deve ser Contribuinte, portanto, deve possuir
        uma Inscrição Estadual;
      </li>
      <li className="ml-3 mb-2">
        3. No Cadastro do Cliente, a opção Revenda deve estar marcada.
      </li>
      <span>
        Quando o Sistema enquadrar o Processo nas três condições acima, alterará
        automaticamente a CST do ICMS do Produto na NF para 51 - Diferimento,
        portanto, não indicamos o Cadastro de uma Regra Tributária específica
        para Diferimento, pois este controle será feito pelo próprio sistema.
      </span>
    </TabPane>
  );
};
