import React from "react";
import { Card } from "reactstrap";

export const BaseInfoCard = ({ baseInfo = [], especif = [] }) => {
  return (
    <Card body>
      <p style={{ marginTop: 0, marginBottom: 0 }}>
        {baseInfo?.length > 0 && <b>BASE DE INFORMAÇÕES</b>}
        {baseInfo.map((e) => (
          <>
            <br />
            {e}
          </>
        ))}
        {especif?.length > 0 && (
          <>
            <br />
            <br />
            <b>ESPECIFICAÇÕES</b>
            {especif.map((e) => (
              <>
                <br />
                {e}
              </>
            ))}
          </>
        )}
      </p>
    </Card>
  );
};
