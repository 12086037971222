import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { ComboBox, Divider, NumberInput } from "../../../../../../components";
import {
  setMotivoDesonerIcms,
  setPercDesonerIcms,
} from "../../store/cadCfopSlice";

const motivo_desoner_icms = [
  { label: "1 - Táxi", value: "1" },
  { label: "3 - Produtor Agropecuário", value: "3" },
  { label: "4 - Frotista/Locadora", value: "4" },
  { label: "5 - Diplomático/Consular", value: "5" },
  {
    label:
      "6 - Utilit. e motocicletas Amazônia ocid. e áreas de livre comércio",
    value: "6",
  },
  { label: "7 - SUFRAMA", value: "7" },
  { label: "8 - Venda a Órgão Público", value: "8" },
  { label: "9 - Outros. (NT 2011/004)", value: "9" },
  { label: "10 - Deficiente Condutor (Convênio ICMS 38/12)", value: "10" },
  { label: "11 - Deficiente Não Condutor (Convênio ICMS 38/12)", value: "11" },
];

export const TabDesonerICMSCfop = () => {
  const store = useSelector((state) => state.cadCfop);
  const dispatch = useDispatch();

  return (
    <>
      <Divider className="mt-4">Desoneração ICMS</Divider>
      <Row>
        <NumberInput
          label="Percentual"
          md={3}
          disabled={!store.camposAtivosIcms["perc_desoner_icms_ativo"]}
          onChange={(v) => dispatch(setPercDesonerIcms(v))}
          value={store.percDesonerIcms}
          isPercentage
          clearOnDisable
          divClassName="pr-0"
        />
        <ComboBox
          label="Motivo"
          isSearchable={false}
          md={9}
          options={motivo_desoner_icms}
          defaultOptions
          isDisabled={!store.camposAtivosIcms["motivo_desoner_icms_ativo"]}
          onChange={(selected) =>
            dispatch(setMotivoDesonerIcms(selected?.value))
          }
          defaultValue={store.motivoDesonerIcms}
          divClassName="pr-0"
        />
      </Row>
    </>
  );
};
