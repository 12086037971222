import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forma_impressao: "VIS",
  nome_impressora: "",
  nro_vias: 1,
  msg_fixa: "",
};

export const condicionalSlice = createSlice({
  name: "condicional_slice",
  initialState: initialState,
  reducers: {
    setupCondicional: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (["nome_impressora", "msg_fixa"].includes(key) && value === null) {
          value = "";
        }

        state[key] = value;
      });
      return state;
    },
    setFormaImpressao: (state, action) => {
      state.forma_impressao = action.payload;
    },
    setNomeImpressora: (state, action) => {
      state.nome_impressora = action.payload;
    },
    setNroVias: (state, action) => {
      state.nro_vias = action.payload;
    },
    setMsgFixa: (state, action) => {
      state.msg_fixa = action.payload;
    },
  },
});

export const {
  setupCondicional,
  setFormaImpressao,
  setNomeImpressora,
  setNroVias,
  setMsgFixa,
} = condicionalSlice.actions;

export default condicionalSlice.reducer;
