import React, { useState } from "react";
import {
  Divider,
  FixedField,
  ModalBase,
  Table,
} from "../../../../../../components";
import { Row } from "reactstrap";
import { formatNumber, formatValueFromAPI } from "../../../../../../coreUtils";
import { apiGetV2 } from "../../../../../../apiV2";

const columnsCompra = [
  {
    dataField: "nome_fornecedor",
    text: "Fornecedor",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_fornecedor),
  },
  { dataField: "numero_nf", text: "Nº NF", align: "center" },
  { dataField: "unidade", text: "UN", align: "center" },
  { dataField: "data_emi_nf", text: "Emissão", align: "center" },
  {
    dataField: "perc_margem_compra",
    text: "Marg Compra",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "quantidade",
    text: "Qtde",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_unit",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columnsVenda = [
  { dataField: "tipo", text: "Tipo", align: "center" },
  { dataField: "id_tipo", text: "# Tipo", align: "center" },
  { dataField: "nome_vendedor", text: "Vendedor", align: "left" },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  { dataField: "data", text: "Data", align: "center" },
  {
    dataField: "vlr_unit",
    text: "Unitário",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "perc_desc",
    text: "Desc",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true) + "%",
  },
  {
    dataField: "vlr_item",
    text: "Vlr Item",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "quantidade",
    text: "Qtde",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_tot_prod",
    text: "Tot Prod",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  //   {
  //     dataField: "vlr_icms_st",
  //     text: "ICMS ST",
  //     align: "right",
  //     formatter: (c) => formatNumber(c, true, 2),
  //   },
  //   {
  //     dataField: "vlr_icms_st_unit",
  //     text: "ST Unit",
  //     align: "right",
  //     formatter: (c) => formatNumber(c, true, 2),
  //   },
  {
    dataField: "vlr_tot_final",
    text: "Total",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const ComprasVendasProdutoModal = ({ isOpen, toggle, selected }) => {
  const [nome, setNome] = useState("");
  const [quantidade, setQuantidade] = useState(null);
  const [precoVenda, setPrecoVenda] = useState(null);
  const [compras, setCompras] = useState([]);
  const [vendas, setVendas] = useState([]);

  const limparDados = () => {
    setNome("");
    setQuantidade(null);
    setPrecoVenda(null);
    setCompras([]);
    setVendas([]);
  };

  const carregarDados = async () => {
    const [ok, ret] = await apiGetV2(
      `/nfe_entrada/listar_compras_vendas_prod/${selected}/`
    );
    if (ok) {
      setNome(ret.nome);
      setQuantidade(ret.quantidade);
      setPrecoVenda(ret.preco_venda);
      setCompras(ret.compras);
      setVendas(ret.vendas);
    } else {
      toggle();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Compras e Vendas do Produto"
      number="0101_11"
      onClosed={limparDados}
      onBeforeOpen={carregarDados}
      bodyStyle={{ paddingTop: "0.5rem" }}
    >
      <Row className="mb-2">
        <FixedField
          label="Nome"
          value={formatValueFromAPI(nome, selected)}
          horizontal
        />
        <FixedField
          label="Estoque"
          value={formatNumber(quantidade, true, 2, true)}
          horizontal
        />
        <FixedField
          label="Preço Venda"
          value={formatNumber(precoVenda, true, 2)}
          horizontal
        />
      </Row>
      <Divider>Compras</Divider>
      <Table
        data={compras}
        columns={columnsCompra}
        paginated={false}
        pageSize={10}
        showRegisterCount={false}
        growIntoPageSize
      />
      <Divider>Vendas</Divider>
      <Table
        data={vendas}
        columns={columnsVenda}
        paginated={false}
        pageSize={10}
        showRegisterCount={false}
        growIntoPageSize
      />
    </ModalBase>
  );
};
