import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FixedField,
  ModalBase,
  NumberInput,
} from "../../../../../../components";
import {
  MODAL_ACTIONS,
  formatNumber,
  roundFloat,
} from "../../../../../../coreUtils";
import UteisService from "../../../../../../services/uteis/UteisService";
import PesqProduto from "../../../../../../components/form/pesq_produto/PesqProduto";
import { v4 as uuidv4 } from "uuid";

export const IncluirAlterarItemNfFatModal = ({
  action,
  isOpen,
  toggle,
  idCliente,
  handleAdd,
  handleEdit,
  itens,
  utilizaRegraTributacao,
  uuidItemEdit,
}) => {
  const [idProduto, setIdProduto] = useState(null);
  const [nomeProduto, setNomeProduto] = useState("");
  const [referencia, setReferencia] = useState("");
  const [clasFiscal, setClasFiscal] = useState("");
  const [ncmValido, setNcmValido] = useState(true);
  const [unidade, setUnidade] = useState("");
  const [quantidade, setQuantidade] = useState(0);
  const [cfop, setCfop] = useState("");
  const [vlrUnit, setVlrUnit] = useState(0);
  const [vlrItem, setVlrItem] = useState(0);
  const [percDesc, setPercDesc] = useState(0);
  const [vlrDesc, setVlrDesc] = useState(0);
  const [vlrAcre, setVlrAcre] = useState(0);
  const [vlrTotProd, setVlrTotProd] = useState(0);
  const [vlrTotal, setVlrTotal] = useState(0);
  const [baseCalcIcms, setBaseCalcIcms] = useState(0);
  const [vlrIcms, setVlrIcms] = useState(0);
  const [baseCalcIcmsSt, setBaseCalcIcmsSt] = useState(0);
  const [vlrIcmsSt, setVlrIcmsSt] = useState(0);
  const [baseCalcIpi, setBaseCalcIpi] = useState(0);
  const [vlrIpi, setVlrIpi] = useState(0);

  const refPesqProd = useRef();
  const refQuantidade = useRef();
  const refCfop = useRef();

  const limparDados = () => {
    setIdProduto(null);
    setNomeProduto("");
    setReferencia("");
    setClasFiscal("");
    setUnidade("");
    setQuantidade(0);
    setCfop("");
    setVlrUnit(0);
    setVlrItem(0);
    setPercDesc(0);
    setVlrDesc(0);
    setVlrAcre(0);
    setVlrTotProd(0);
    setVlrTotal(0);
    setBaseCalcIcms(0);
    setVlrIcms(0);
    setBaseCalcIcmsSt(0);
    setVlrIcmsSt(0);
    setBaseCalcIpi(0);
    setVlrIpi(0);
    setNcmValido(true);
  };

  const carregarDados = (_uuid) => {
    const data = itens.find((e) => e.uuid === _uuid);
    setIdProduto(data.id_produto);
    setNomeProduto(data.nome_produto);
    setReferencia(data.referencia);
    setClasFiscal(data.clas_fiscal);
    setUnidade(data.unidade);
    setQuantidade(data.quantidade);
    setCfop(data.cfop);
    setVlrUnit(data.vlr_unit);
    setVlrItem(data.vlr_item);
    setPercDesc(data.perc_desc);
    setVlrDesc(data.vlr_desc);
    setVlrAcre(data.vlr_acre);
    setVlrTotProd(data.vlr_tot_prod);
    setVlrTotal(data.vlr_total);
    setBaseCalcIcms(data.base_calc_icms);
    setVlrIcms(data.vlr_icms);
    setBaseCalcIcmsSt(data.base_calc_icms_st);
    setVlrIcmsSt(data.vlr_icms_st);
    setBaseCalcIpi(data.base_calc_ipi);
    setVlrIpi(data.vlr_ipi);
    setNcmValido(data.ncm_valido);

    setTimeout(() => {
      if (refPesqProd.current) {
        refPesqProd.current.setDescricao(data.nome_produto);
        refPesqProd.current.setId(String(data.id_produto));
        refPesqProd.current.setReferencia(String(data.referencia));
      }
    }, 100);
  };

  const onBeforeOpen = () => {
    if (action === MODAL_ACTIONS.EDIT) {
      carregarDados(uuidItemEdit);
    }
  };

  const calcularImpostos = async (
    idProduto,
    quantidade,
    vlrItem,
    cfop = ""
  ) => {
    const _vlrTotProd = roundFloat(vlrItem * quantidade, 2);

    const trib = await UteisService.calcularImpostosProduto(
      idProduto,
      idCliente,
      "VENDA",
      55,
      true,
      cfop,
      quantidade,
      _vlrTotProd,
      0
    );

    if (trib && Object.keys(trib).length > 0) {
      const _vlrIcmsSt = parseFloat(trib.vlr_icms_st);
      const _vlrIpi = parseFloat(trib.vlr_ipi);
      const _baseCalcIcms = parseFloat(trib.base_calc_icms);
      const _vlrIcms = parseFloat(trib.vlr_icms);
      const _baseCalcIcmsSt = parseFloat(trib.base_calc_icms_st);
      const _baseCalcIpi = parseFloat(trib.base_calc_ipi);
      const _vlrTotalComImpostos = parseFloat(trib.vlr_total_com_impostos);

      setBaseCalcIcms(roundFloat(_baseCalcIcms, 2));
      setVlrIcms(roundFloat(_vlrIcms, 2));
      setBaseCalcIcmsSt(roundFloat(_baseCalcIcmsSt, 2));
      setBaseCalcIpi(roundFloat(_baseCalcIpi, 2));
      setVlrIcmsSt(roundFloat(_vlrIcmsSt, 2));
      setVlrIpi(roundFloat(_vlrIpi, 2));
      setVlrTotProd(_vlrTotProd);
      setVlrTotal(roundFloat(_vlrTotalComImpostos, 2));

      if (utilizaRegraTributacao) {
        setCfop((trib.cfop ?? "").trim());
      }
      return true;
    } else {
      return false;
    }
  };

  const handleSelectItemPesq = async ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    precoVenda,
    unidade,
    cfop,
    ncm,
  }) => {
    if (idProduto !== id) {
      if (await calcularImpostos(id, 1, precoVenda, cfop)) {
        setIdProduto(id);
        setNomeProduto(nome);
        setVlrUnit(precoVenda);
        setUnidade(unidade);
        setVlrItem(precoVenda);
        setReferencia(referencia);
        setQuantidade(1);
        setPercDesc(0);
        setVlrDesc(0);
        setVlrAcre(0);
        setClasFiscal(clasFiscal);

        if (!utilizaRegraTributacao) {
          setCfop((cfop ?? "").trim());
        }

        refPesqProd.current.setDescricao(nome);
        refPesqProd.current.setId(String(id));
        refPesqProd.current.setReferencia(String(referencia));
      }
    }
  };

  const handleSetQuantidade = async (v) => {
    if (await calcularImpostos(idProduto, v, vlrItem, cfop)) {
      setQuantidade(v);

      if (vlrItem <= vlrUnit) {
        setVlrDesc(roundFloat(vlrUnit * (percDesc / 100) * v, 2));
        setVlrAcre(0);
      } else {
        setPercDesc(0);
        setVlrDesc(0);
        setVlrAcre(roundFloat((vlrItem - vlrUnit) * v, 2));
      }
    }
  };

  const handleSetVlrItem = async (v) => {
    if (await calcularImpostos(idProduto, quantidade, v, cfop)) {
      setVlrItem(v);

      if (v <= vlrUnit) {
        const _vlrDesc = vlrUnit - v;
        setPercDesc(roundFloat((_vlrDesc / vlrUnit) * 100, 2));
        setVlrDesc(roundFloat(_vlrDesc * quantidade, 2));
        setVlrAcre(0);
      } else {
        setPercDesc(0);
        setVlrDesc(0);
        setVlrAcre(roundFloat((v - vlrUnit) * quantidade, 2));
      }
    }
  };

  const semProduto = [0, null, undefined].includes(idProduto);

  const handleSubmit = () => {
    if (semProduto) {
      return toastr.warning("Atenção", "Por favor, selecione um produto.");
    }

    if (quantidade <= 0) {
      return toastr.warning("Atenção", "A quantidade não pode ser zero.");
    }

    if (vlrItem < 0) {
      return toastr.warning("Atenção", "Por favor, verifique o valor do item.");
    }

    const payload = {
      uuid: action === MODAL_ACTIONS.EDIT ? uuidItemEdit : uuidv4(),
      id_produto: idProduto,
      nome_produto: nomeProduto,
      referencia: referencia,
      clas_fiscal: clasFiscal,
      unidade: unidade,
      quantidade: quantidade,
      cfop: cfop,
      vlr_unit: vlrUnit,
      vlr_item: vlrItem,
      perc_desc: percDesc,
      vlr_desc: vlrDesc,
      vlr_acre: vlrAcre,
      vlr_tot_prod: vlrTotProd,
      vlr_total: vlrTotal,
      base_calc_icms: baseCalcIcms,
      vlr_icms: vlrIcms,
      base_calc_icms_st: baseCalcIcmsSt,
      vlr_icms_st: vlrIcmsSt,
      base_calc_ipi: baseCalcIpi,
      vlr_ipi: vlrIpi,
      ncm_valido: ncmValido,
    };

    if (action === MODAL_ACTIONS.ADD) {
      handleAdd(payload);
      limparDados();

      if (refCfop.current) {
        refCfop.current.clearValue();
      }

      if (refPesqProd.current) {
        refPesqProd.current.clear();
        refPesqProd.current.focus();
      }
    } else {
      handleEdit(payload);
      toggle();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      onClosed={limparDados}
      size="xl"
      title={action === MODAL_ACTIONS.ADD ? "Adicionar Item" : "Alterar Item"}
      number="0036_11"
      footerActions
      onConfirm={handleSubmit}
      onBeforeOpen={onBeforeOpen}
    >
      <Row>
        <PesqProduto
          md={12}
          mdIdent={3}
          mdIdentAux={3}
          onConfirm={handleSelectItemPesq}
          ref={refPesqProd}
          onChangeDescricao={setNomeProduto}
          // selectNextField={selectNextField}
          autoFocus
          showShortcut
          selected={idProduto}
          idCliente={idCliente}
        />
      </Row>
      <Row className={utilizaRegraTributacao && "mt-3 mb-2"}>
        {utilizaRegraTributacao ? (
          <FixedField md="auto" label="CFOP" value={cfop} horizontal />
        ) : (
          <AsyncComboBox
            md={7}
            label="CFOP"
            isSearchable
            isConcatField
            concatModelName="cfop"
            defaultValue={cfop}
            onChange={(s) => setCfop(s?.value)}
            defaultOptions
            ref={refCfop}
          />
        )}
        <FixedField
          md={2}
          label="Vlr. Unitário"
          value={formatNumber(vlrUnit, true, 2)}
          horizontal
          divClassName={!utilizaRegraTributacao && "mt-4"}
        />
      </Row>
      <Row className="mb-2">
        <NumberInput
          md={2}
          label="Quantidade"
          value={quantidade}
          onChange={handleSetQuantidade}
          disabled={semProduto}
          decimalPlaces={4}
          ref={refQuantidade}
        />
        <NumberInput
          md={2}
          label="Vlr. Item"
          value={vlrItem}
          onChange={handleSetVlrItem}
          disabled={semProduto}
          decimalPlaces={10}
        />
      </Row>
      <Row className="mb-2">
        <FixedField
          md={2}
          label="Desconto"
          value={`${formatNumber(vlrDesc, true, 2)} | ${formatNumber(
            percDesc,
            true,
            2
          )}`}
          horizontal
        />
        <FixedField
          md={2}
          label="Vlr. Acréscimo"
          value={formatNumber(vlrAcre, true, 2)}
          horizontal
        />
        <FixedField
          md="auto"
          label="Vlr. Total Produtos"
          value={formatNumber(vlrTotProd, true, 2)}
          horizontal
          divClassName="ml-auto"
          textStyle={{ marginLeft: "auto" }}
          divStyle={{ minWidth: "16.66667%" }}
        />
      </Row>
      <Row>
        <FixedField
          md={2}
          label="Base Cálc. ICMS"
          value={formatNumber(baseCalcIcms, true, 2)}
          horizontal
        />
        <FixedField
          md={2}
          label="Vlr. ICMS"
          value={formatNumber(vlrIcms, true, 2)}
          horizontal
        />
        <FixedField
          md={2}
          label="Base Cálc. ICMS ST"
          value={formatNumber(baseCalcIcmsSt, true, 2)}
          horizontal
        />
        <FixedField
          md={2}
          label="Vlr. ICMS ST"
          value={formatNumber(vlrIcmsSt, true, 2)}
          horizontal
        />
        <FixedField
          md={2}
          label="Vlr. IPI"
          value={formatNumber(vlrIpi, true, 2)}
          horizontal
        />
        <FixedField
          md={2}
          label="Vlr. Total"
          value={formatNumber(vlrTotal, true, 2)}
          horizontal
          textStyle={{ marginLeft: "auto" }}
        />
      </Row>
    </ModalBase>
  );
};
