import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, TabPane } from "reactstrap";
import { FormCheckbox, NumberInput } from "../../../../../../../components";
import {
  setAliqCredIcms,
  setGeraCredIcms,
} from "../../../store/cadRegraTributacaoSlice";

export const TabCredICMS = () => {
  const store = useSelector((state) => state.cadRegraTributacao);
  const dispatch = useDispatch();

  return (
    <TabPane tabId="7" className="p-2">
      <Row className="mb-3">
        <FormCheckbox
          name="gera_cred_icms"
          label="Gera Crédito de ICMS"
          checked={store.geraCredIcms}
          onChange={() => dispatch(setGeraCredIcms(!store.geraCredIcms))}
          disabled={!store.camposAtivosIcms["credito_icms_simples_ativo"]}
        />
        <NumberInput
          label="Alíquota de Crédito de ICMS"
          md={3}
          onChange={(v) => dispatch(setAliqCredIcms(v))}
          value={store.aliqCredIcms}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          disabled={
            !store.camposAtivosIcms["credito_icms_simples_ativo"] ||
            !store.geraCredIcms
          }
        />
      </Row>
      <span>
        Os Campos acima somente serão habilitados quando o Regime Tributário do
        Emitente for SIMPLES NACIONAL.
      </span>
    </TabPane>
  );
};
