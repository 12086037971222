import { apiGet, apiPost, urlParams } from "../../../api";
import { apiGetV2 } from "../../../apiV2";

const ENDPOINT_BASE = `/relatorios/estoque_rota`;

const EstoqueRotaService = {
  buscarDados: async () => await apiGet(`${ENDPOINT_BASE}/rota_produto/`),
  buscarResumoEstoque: async (data, visao) => {
    const params = urlParams({ data: data, visao: visao });
    return await apiGetV2(`/relatorios/estoque_rota/resumo_estoque/${params}`);
  },
  lancamentoEstoque: async (payload) =>
    await apiPost(`jornada/estoque/lancamento_adm/`, payload),
};

export default EstoqueRotaService;
