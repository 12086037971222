import React from "react";
import { Card } from "reactstrap";
import { PageContainer } from "../../../components/PageContainer";

export const Geral = () => {
  return (
    <PageContainer title="Geral" number="conf_01">
      <Card body>
        <div style={{ display: "flex" }}>
          {/* <h6 style={{ marginBlock: "auto", marginInlineEnd: "7px" }}>
            Plano Atual:
          </h6> */}
          <h6 style={{ marginBlock: "auto", marginInlineEnd: "7px" }}>
            Licença: {localStorage.getItem("licenca")}
          </h6>
        </div>
      </Card>
    </PageContainer>
  );
};
