import React from "react";
import { Table } from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "id_produto",
    text: "# Produto",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "descricao",
    text: "Nome",
    align: "left",
    sortable: true,
  },
  {
    dataField: "qtd_prod",
    text: "Quantidade",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_prod",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const NfSaidaAgrupadoProdutoGrid = ({ dados }) => {
  return <Table columns={columns} data={dados} />;
};
