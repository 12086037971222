import React, { useState } from "react";
import {
  AsyncComboBox,
  FixedField,
  FormButton,
  LabelButton,
  ModalBase,
  ModalDetalhesIcone,
  Table,
} from "../../../../components";
import CredRotativoService from "../../../../services/financeiro/CredRotativoService";
import { formatNumber, sumDataField } from "../../../../coreUtils";
import { Row } from "reactstrap";
import { DetalheOSModal } from "./DetalheOSModal";
import { DetalheVendaModal } from "./DetalheVendaModal";
import { toastr } from "react-redux-toastr";

export const CreditoRotativoModal = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dados, setDados] = useState([]);
  const [idCliente, setIdCliente] = useState(null);
  const [totalPendente, setTotalPendente] = useState(0);
  const [loading, setLoading] = useState(false);
  const [idOrigemSel, setIdOrigemSel] = useState();
  const [detalheOSOpen, setDetalheOSOpen] = useState(false);
  const [detalheVendaOpen, setDetalheVendaOpen] = useState(false);

  const limparDados = () => {
    setIdCliente(null);
    setDados([]);
    setTotalPendente(0);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const listarMovsPendentesCliente = async (idCli) => {
    const ret = await CredRotativoService.listarMovsPendentes(idCli);
    if (ret.movs) {
      setTotalPendente(parseFloat(ret.total_pendente));
      setDados(
        ret.movs.map((e) => ({
          ...e,
          valor_pendente: parseFloat(e.valor_pendente),
          vlr_faturar: 0,
        }))
      );
    } else {
      setTotalPendente(0);
      setDados([]);
    }
  };

  const handleSelectCliente = async (v) => {
    setIdCliente(v);
    listarMovsPendentesCliente(v);
  };

  const setVlrFaturar = (coluna, novoValor, row) => {
    setDados(
      dados.map((e) => (e.id === row.id ? { ...e, vlr_faturar: novoValor } : e))
    );
  };

  const verificarVlrFaturar = (row) => {
    if (row.vlr_faturar > row.valor_pendente) {
      setDados(
        dados.map((e) =>
          e.id === row.id
            ? { ...e, vlr_faturar: Math.max(0, row.valor_pendente) }
            : e
        )
      );
      toastr.warning(
        "Atenção",
        "O Valor a Receber não pode ser superior ao Valor Pendente"
      );
    }
  };

  const faturarTudo = () => {
    setDados(dados.map((e) => ({ ...e, vlr_faturar: e.valor_pendente })));
  };

  const zerarTudo = () => {
    setDados(dados.map((e) => ({ ...e, vlr_faturar: 0 })));
  };

  const toggleDetalheOS = () => {
    setDetalheOSOpen(!detalheOSOpen);
  };

  const toggleDetalheVenda = () => {
    setDetalheVendaOpen(!detalheVendaOpen);
  };

  const abrirDetalhes = (idOrigem, tipo) => {
    setIdOrigemSel(idOrigem);
    if (tipo === "Ordem de Serviço") {
      toggleDetalheOS();
    } else if (tipo === "Venda") {
      toggleDetalheVenda();
    }
  };

  const handleSubmit = async () => {
    const payload = {
      movs_faturar: dados
        .filter((e) => e.vlr_faturar > 0)
        .map((e) => ({ id_mov: e.id, vlr_faturar: e.vlr_faturar })),
    };
    setLoading(true);
    if (await CredRotativoService.gerarFatura(payload)) {
      await listarMovsPendentesCliente(idCliente);
      notifyEvent();
    }
    setLoading(false);
  };

  const columns = [
    { dataField: "id", text: "#", align: "center", headerAlign: "center" },
    {
      dataField: "desc_origem",
      text: "Origem ",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_origem",
      text: "Nº Origem",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_caixa_loja",
      text: "Caixa",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_hora_mov",
      text: "Emissao",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "valor",
      text: "Valor Original",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "valor_pendente",
      text: "Valor Pendente",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_faturar",
      text: "Receber Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      editable: true,
      editorType: "number",
      onChange: setVlrFaturar,
      onBlur: verificarVlrFaturar,
      alwaysShowEditor: true,
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <ModalDetalhesIcone
          toggle={() => abrirDetalhes(row.id_origem, row.desc_origem)}
        />
      ),
    },
  ];

  return (
    <>
      <LabelButton md={3} onClick={toggle}>
        Crédito Rotativo
      </LabelButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Faturar Crédito Rotativo"
        number="0063_6"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onClosed={limparDados}
      >
        <Row className="mb-2">
          <AsyncComboBox
            md={10}
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            isSearchable
            onChange={(s) => handleSelectCliente(s?.value)}
          />
        </Row>
        <Table
          data={dados}
          columns={columns}
          paginated={false}
          showRegisterCount={false}
        />
        <Row>
          <FormButton
            md="auto"
            padded={false}
            color="light"
            onClick={faturarTudo}
          >
            Faturar Tudo
          </FormButton>
          <FormButton
            md="auto"
            padded={false}
            color="light"
            onClick={zerarTudo}
          >
            Zerar Tudo
          </FormButton>
          <FixedField
            divClassName="ml-auto"
            horizontal
            label="Total Pendente"
            value={formatNumber(totalPendente, true, 2)}
          />
          <FixedField
            divClassName="ml-3"
            horizontal
            label="Total a Faturar"
            value={formatNumber(sumDataField(dados, "vlr_faturar"), true, 2)}
          />
        </Row>
        <DetalheOSModal
          isOpen={detalheOSOpen}
          toggle={toggleDetalheOS}
          idOS={idOrigemSel}
        />
        <DetalheVendaModal
          isOpen={detalheVendaOpen}
          toggle={toggleDetalheVenda}
          idVenda={idOrigemSel}
        />
      </ModalBase>
    </>
  );
};
