import React from "react";
import { Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

const columns = [
  { dataField: "id_premiado", text: "Premiado", align: "center" },
  { dataField: "nome_premiado", text: "Nome", align: "left" },
  {
    dataField: "saldo_disp",
    text: "Pontos Disponíveis",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const GerenciamentoPremiacoesGrid = ({ dados, onSelect }) => {
  return (
    <Table
      keyField="id_premiado"
      data={dados}
      columns={columns}
      paginated={false}
      pageSize={10}
      onSelect={onSelect}
    />
  );
};
