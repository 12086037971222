import moment from "moment";
import unidecode from "unidecode";
import { docPost } from "../../pdf";
import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/nfe_servico";
const URL_BASE_NATUREZA_OPERACAO = `${URL_BASE}/natureza_operacao`;

const NotaFiscalServicoService = {
  alterar: (payload) => apiPutV2(`${URL_BASE}/alterar/`, payload),
  buscar: (idNFSe) => apiGetV2(`${URL_BASE}/buscar/${idNFSe}/`),
  buscarAlertas: (idNFSe) => apiGetV2(`${URL_BASE}/buscar_alertas/${idNFSe}/`),
  buscarDadosCliNfse: (idCliente) =>
    apiGetV2(`${URL_BASE}/buscar_dados_cli_nfse/${idCliente}/`),
  calcularImpostos: (vlrServico, idNaturezaOperacao) =>
    apiPostV2(
      `${URL_BASE}/calcular_impostos/`,
      {
        vlr_servico: vlrServico,
        id_natureza_operacao: idNaturezaOperacao,
      },
      { successMessage: false }
    ),
  cancelarNFSe: (id, observ) =>
    apiPutV2(`${URL_BASE}/cancelar/`, { id_nfse: id, observ: observ }),
  descartarDigitacao: (id) =>
    apiDeleteV2(`${URL_BASE}/descartar_digitacao/${id}/`),
  emitirNFSe: (id) => apiPostV2(`${URL_BASE}/emitir/`, { id_nfse: id }),
  enviarEmail: (idNfse) => apiPostV2(`${URL_BASE}/email/enviar/${idNfse}/`),
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  imprimirNFSe: async (id) => {
    const [ok, ret] = await apiGetV2(`${URL_BASE}/dados_impressao/${id}/`, {});

    if (!ok) return [false, false];

    if (ret.link_download_pdf) {
      window.open(ret.link_download_pdf, "_blank");
      return [false, false];
    }

    const arq = await docPost(
      "/danfse/",
      { ...ret, layout: 1 },
      {
        errorMessage: "Erro ao Gerar DANFSe",
      }
    );

    if (!arq) return [false, false];

    const fileName = `NFSe_${ret.numero_nfse}_${unidecode(
      ret.tomador.razao_social
    ).replaceAll(" ", "_")}_${moment(
      ret.data_hora_emissao.split(" ")[0],
      "DD/MM/YYYY"
    ).format("YYYYMMDD")}.pdf`;

    return [arq, fileName];
  },
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  naturezaOperacao: {
    urlBase: URL_BASE_NATUREZA_OPERACAO,
    listar: () => apiGetV2(`${URL_BASE_NATUREZA_OPERACAO}/listar/`),
    listarLogs: (idNaturOper) =>
      apiGetV2(`${URL_BASE_NATUREZA_OPERACAO}/listar_logs/${idNaturOper}/`),
  },
};

export default NotaFiscalServicoService;
