import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  IntegerFormInput,
  PageContainer,
  SearchInput,
} from "../../../../../components";
import OrcamentosService from "../../../../../services/orcamentos/OrcamentosService";
import { OrcRapidoOSGrid } from "./components/OrcRapidoOSGrid";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { incluirAlterarOrcamentoOSRoute } from "../../../../../routes/modules/ordem_servico";
import { useEffect } from "react";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../../../coreUtils";

const mostrarOptions = [
  { label: "Todos", value: "T" },
  { label: "Pendentes", value: "N" },
  { label: "Transformados em O.S", value: "J" },
];

export const OrcRapidoOS = ({ location }) => {
  const [pista, setPista] = useState("");
  const [idCliente, setIdCliente] = useState(null);
  const [idOrcamento, setIdOrcamento] = useState(null);
  const [mostrar, setMostrar] = useState(mostrarOptions[1].value);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loadingTransformar, setLoadingTransformar] = useState(false);

  const history = useHistory();

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async (p) => {
    const params = {
      id_cliente: idCliente,
      id_orcamento: idOrcamento,
      mostrar: mostrar,
      data_ini: dataIni,
      data_fim: dataFim,
      pista: p,
    };
    const [ok, ret] = await OrcamentosService.orcamentoRapido.listar(params);
    setDados(ok ? ret : []);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const alterarOrcamento = (_idOrcamento) => {
    history.push(incluirAlterarOrcamentoOSRoute.path, {
      idOrcamento: _idOrcamento,
      action: MODAL_ACTIONS.EDIT,
    });
  };

  const transformarOrcamentoEmOs = async () => {
    setLoadingTransformar(true);

    const [ok] = await OrcamentosService.ordServ.transformarEmOS(selected);

    if (ok) {
      carregarDados();
    }

    setLoadingTransformar(false);
  };

  useEffect(() => {
    if (location.state?.refresh) {
      carregarDados();
    }
  }, []);

  return (
    <PageContainer
      title="Gerenciamento de Orçamentos Rápidos"
      number="0077"
      canGoBack
    >
      <Card body>
        <Row>
          <SearchInput
            md={4}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome do Cliente, Nº Orçamento ou Placa"
          />
          <AsyncComboBox
            md={5}
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
            defaultValue={idCliente}
          />
          <IntegerFormInput
            md={2}
            label="Nº Orçamento"
            defaultValue={idOrcamento}
            onChange={setIdOrcamento}
          />
        </Row>
        <Row>
          <ComboBox
            md={3}
            label="Mostrar"
            options={mostrarOptions}
            onChange={(s) => setMostrar(s?.value)}
            defaultValue={mostrar}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <FormButton
            md="auto"
            color="info"
            onClick={() => history.push(incluirAlterarOrcamentoOSRoute.path)}
          >
            Incluir
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <OrcRapidoOSGrid
          dados={dados}
          setSelected={setSelected}
          alterar={alterarOrcamento}
        />
        <Row>
          <FormButton
            divClassName="ml-auto"
            md="auto"
            color="success"
            disabled={!selected}
            loading={loadingTransformar}
            onClick={transformarOrcamentoEmOs}
          >
            Confirmar e Transformar em O.S.
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
