import React from "react";
import { Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

const columns = [
  { dataField: "desc_moeda", text: "Moeda", align: "center" },
  { dataField: "detalhes", text: "Descrição do Recebimento", align: "left" },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const FormasRecOSGrid = ({ dados }) => {
  return (
    <Table
      data={dados}
      columns={columns}
      pageSize={5}
      paginated={false}
      showRegisterCount={false}
    />
  );
};
