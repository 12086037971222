import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imprimir: "P",
  layout_impressao: 1,
  transf_orc_vda_ped_mais_vez: false,
  limitar_impr_nome_prod: false,
  nro_carac_impr_nome_prod: 0,
  msg_fixa_1: "",
  msg_fixa_2: "",
  msg_fixa_3: "",
  msg_fixa_4: "",
};

export const orcamentoSlice = createSlice({
  name: "orcamento_slice",
  initialState: initialState,
  reducers: {
    setupOrcamento: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (
          ["msg_fixa_1", "msg_fixa_2", "msg_fixa_3", "msg_fixa_4"].includes(
            key
          ) &&
          value === null
        ) {
          value = "";
        }

        state[key] = value;
      });
      return state;
    },
    setImprimir: (state, action) => {
      state.imprimir = action.payload;
    },
    setLayoutImpressao: (state, action) => {
      state.layout_impressao = action.payload;
    },
    setTransfOrcVdaPedMaisVez: (state, action) => {
      state.transf_orc_vda_ped_mais_vez = action.payload;
    },
    setLimitarImprNomeProd: (state, action) => {
      state.limitar_impr_nome_prod = action.payload;
    },
    setNroCaracImprNomeProd: (state, action) => {
      state.nro_carac_impr_nome_prod = action.payload;
    },
    setMsgFixa1: (state, action) => {
      state.msg_fixa_1 = action.payload;
    },
    setMsgFixa2: (state, action) => {
      state.msg_fixa_2 = action.payload;
    },
    setMsgFixa3: (state, action) => {
      state.msg_fixa_3 = action.payload;
    },
    setMsgFixa4: (state, action) => {
      state.msg_fixa_4 = action.payload;
    },
  },
});

export const {
  setupOrcamento,
  setImprimir,
  setLayoutImpressao,
  setTransfOrcVdaPedMaisVez,
  setLimitarImprNomeProd,
  setNroCaracImprNomeProd,
  setMsgFixa1,
  setMsgFixa2,
  setMsgFixa3,
  setMsgFixa4,
} = orcamentoSlice.actions;

export default orcamentoSlice.reducer;
