import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { GiPayMoney } from "react-icons/gi";
import {
  estornoPagamentoRoute,
  gerenciamentoContasPagarRoute,
} from "../../../routes/modules/financeiro";
import { RiRefund2Line } from "react-icons/ri";

export const ContasPagar = () => {
  return (
    <PageContainer title="Contas a Pagar">
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento"
          icon={GiPayMoney}
          pathname={gerenciamentoContasPagarRoute.path}
          nroTela={gerenciamentoContasPagarRoute.nroTela}
        />
        <MenuGroupButton
          label="Estorno de Pagamento"
          icon={RiRefund2Line}
          pathname={estornoPagamentoRoute.path}
          nroTela={estornoPagamentoRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
