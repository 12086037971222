import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { FormCheckbox, NumberInput, RadioGroup } from "../../../../components";
import {
  setAliqIntIcmsUfEmitSimplesNac,
  setAmbiente,
  setPercAproxTributos,
  setTipoVlrAproxTributos,
  setUtilizaLibWinCrypt,
  setPermiteContingencia,
  setCadCliPermiteIdentifModNfe,
} from "../store/nf_eletronica_geral_slice";
import { RadioItem } from "../../../../components/RadioGroup";

export const PersonNfEletronicaGeral = () => {
  const store = useSelector((state) => state.nf_eletronica_geral);
  const dispatch = useDispatch();

  const onChangeTipoVlrAproxTributos = () => {
    if (store.tipo_vlr_aprox_tributos !== "FIXO") {
      dispatch(setPercAproxTributos(0));
    }
  };
  useEffect(onChangeTipoVlrAproxTributos, [store.tipo_vlr_aprox_tributos]);

  return (
    <>
      <Row>
        <RadioGroup
          label="Base de cálculo para definição de valores aproximados de tributos"
          value={store.tipo_vlr_aprox_tributos}
          onChange={(v) => dispatch(setTipoVlrAproxTributos(v))}
        >
          <RadioItem label="IBPT" value="IBPT" />
          <RadioItem label="Percentual Fixo" value="FIXO" />
        </RadioGroup>
        {store.tipo_vlr_aprox_tributos === "FIXO" && (
          <NumberInput
            md={2}
            label=" "
            value={store.perc_aprox_tributos}
            onChange={(v) => dispatch(setPercAproxTributos(v))}
            isPercentage
          />
        )}
      </Row>
      <Row>
        <RadioGroup
          label="Ambiente"
          value={store.ambiente}
          onChange={(v) => dispatch(setAmbiente(v))}
        >
          <RadioItem label="Produção" value="P" />
          <RadioItem label="Homologação" value="H" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar libWinCrypt"
          checked={store.utiliza_lib_win_crypt}
          onChange={() =>
            dispatch(setUtilizaLibWinCrypt(!store.utiliza_lib_win_crypt))
          }
        />
      </Row>
      <Row>
        <NumberInput
          md={3}
          label="Alíquota de ICMS da UF do Emitente"
          value={store.aliq_int_icms_uf_emit_simples_nac}
          onChange={(v) => dispatch(setAliqIntIcmsUfEmitSimplesNac(v))}
        />
        <span className="mt-4">
          Alíquota utilizada em empresas do regime tributário de SIMPLES
          NACIONAL, utilizado no cálculo de ICMS ST
        </span>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permite Contingência"
          checked={store.permite_contingencia}
          onChange={() =>
            dispatch(setPermiteContingencia(!store.permite_contingencia))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permite Identificar Modelo da NF no Cadastro de Cliente"
          checked={store.cad_cli_permite_identif_mod_nfe}
          onChange={() =>
            dispatch(
              setCadCliPermiteIdentifModNfe(
                !store.cad_cli_permite_identif_mod_nfe
              )
            )
          }
        />
      </Row>
    </>
  );
};
