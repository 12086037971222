import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taf: "",
  registro_estadual: "",
  ult_nro_cte_os: 0,
  ult_lote: 0,
  camin_salvar_xml: "",
};

export const cteOsSlice = createSlice({
  name: "cte_os_slice",
  initialState: initialState,
  reducers: {
    setupCteOs: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setTaf: (state, action) => {
      state.taf = action.payload;
    },
    setRegistroEstadual: (state, action) => {
      state.registro_estadual = action.payload;
    },
    setUltNroCteOs: (state, action) => {
      state.ult_nro_cte_os = action.payload;
    },
    setUltLote: (state, action) => {
      state.ult_lote = action.payload;
    },
    setCaminSalvarXml: (state, action) => {
      state.camin_salvar_xml = action.payload;
    },
  },
});

export const {
  setupCteOs,
  setTaf,
  setRegistroEstadual,
  setUltNroCteOs,
  setUltLote,
  setCaminSalvarXml,
} = cteOsSlice.actions;

export default cteOsSlice.reducer;
