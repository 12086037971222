import React from "react";
import { Card } from "reactstrap";
import { PageContainer } from "../../../components/PageContainer";

export const Usuarios = () => {
  return (
    <PageContainer title="Usuários" number="conf_04">
      <Card body></Card>
    </PageContainer>
  );
};
