import { api } from "../../../api";
import { toastr } from "react-redux-toastr";

const processaRetorno = (ret, returnType) => {
  if (ret.data.success || (ret.data.hasOwnProperty("err") && !ret.data.err)) {
    if (returnType) {
      return ret.data.res;
    } else {
      toastr.success("Sucesso", ret.data.msg);
      return true;
    }
  } else {
    toastr.info("", ret.data.msg);
    return returnType ?? false;
  }
};

const processaErro = (err, returnType) => {
  toastr.error("Erro", err.message);
  return returnType ?? false;
};

const ComissaoGerenciaService = {
  buscarGerentes: async () => {
    return await api
      .get("/comissao/regra_gerente/gerentes/?format_to_select=true")
      .then((ret) => processaRetorno(ret, []))
      .catch((err) => processaErro(err, []));
  },
  buscarComissao: async (filters) => {
    let gerente;
    filters.forEach((element) => {
      if (Object.keys(element)[0] === "gerente") {
        gerente = element[Object.keys(element)[0]];
      }
    });
    let url = `/relatorios/venda/comissao/gerente/${gerente}/?`;

    filters.forEach((element) => {
      let key = Object.keys(element)[0];
      if (key !== "gerente") {
        url = `${url}&${key}=${element[key]}`;
      }
    });

    return await api
      .get(url)
      .then((ret) => processaRetorno(ret, {}))
      .catch((err) => processaErro(err, {}));
  },
};

export default ComissaoGerenciaService;
