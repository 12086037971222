import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Row } from "reactstrap";
import { AsyncComboBox } from "../../../../components";
import { BotaoImprimir } from "../../../../components/BotaoImprimir";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import { formatDateISO } from "../../../../coreUtils";
import { ClientesNaoAtendRotaGrid } from "./components/ClientesNaoAtendRotaGrid";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";

export const ClientesNaoAtendRota = () => {
  const [dados, setDados] = useState([]);
  const [rota, setRota] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => {
    setDados([]);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
    limparDados();
  };

  const buscarDados = async () => {
    const params = {
      id_rota: rota,
      data_ini: dataIni,
      data_fim: dataFim,
    };

    return await apiGetV2(
      "/relatorios/venda_direta/clientes_nao_atendidos_por_rota/",
      params
    );
  };

  const verificaData = () => {
    if (!(dataIni instanceof Date)) {
      toastr.warning("Atenção", "Por favor, verifique o início do período");
      return false;
    } else if (!(dataFim instanceof Date)) {
      toastr.warning("Atenção", "Por favor, verifique o fim do período");
      return false;
    }
    return true;
  };

  const listarDados = async () => {
    if (!verificaData()) {
      return;
    }
    setLoadingPesquisar(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret : []);
    setLoadingPesquisar(false);
  };

  const imprimir = async () => {
    if (!verificaData()) {
      return;
    }
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      await docPrintReport(
        "/relatorios/pronta_entrega/clientes_nao_atend_rota/",
        {
          dados: ret,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
        },
        "5026_2"
      );
    }

    setLoadingImprimir(false);
  };

  return (
    <PageContainer
      title="Clientes Não Atendidos por Rota"
      number="5026"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            isSearchable
            concatModelName="rota_mob"
            defaultOptions
            md={2}
            label="Rota"
            onChange={(selected) => {
              setRota(selected?.value);
              limparDados();
            }}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={listarDados} loading={loadingPesquisar} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <ClientesNaoAtendRotaGrid dados={dados} />
    </PageContainer>
  );
};
