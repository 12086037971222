import React, { Component } from "react";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { NumberPage, ScreenTitle } from "../../../components";
import TableRecarga from "./TableRecarga";
class RecargaProdutos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rota_data: [],
    };
  }

  componentDidMount() {
    this.setState({ rota_data: this.props.location.state });
  }

  render() {
    const { rota_data } = this.state;
    return (
      <React.Fragment>
        <Container className="p-0 m-0 mb-3">
          <Link to="/frente_venda_mobile/jornada" className="mb-1">
            <ScreenTitle>
              <ArrowLeft size={15} style={{ marginBottom: "3.55px" }} /> Jornada
            </ScreenTitle>
          </Link>
        </Container>
        <h1 className="h3 mb-3">
          Recarga de Produtos - {`${rota_data.nome} (${rota_data.id})`}
        </h1>
        <TableRecarga />
        <NumberPage>0015</NumberPage>
      </React.Fragment>
    );
  }
}
export default RecargaProdutos;
