import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { handleFocus } from "../coreUtils";

const FormButton = ({
  md = "auto",
  onClick,
  color = "info",
  disabled = false,
  padded = true,
  className = "",
  divClassName = "",
  divStyle,
  style = {},
  loading = false,
  children,
  disabledHint = "Selecione um item da lista",
  hint,
  id,
  tabIndex,
  innerRef,
  block = true,
}) => {
  const [internalId] = useState(
    id ?? "bt-" + Math.floor(Math.random() * Date.now())
  );

  return (
    <Col md={md} className={divClassName} style={divStyle}>
      <FormGroup style={!padded ? { marginBottom: 0 } : {}}>
        {padded && <Label>&#8205;</Label>}
        <Button
          className={`align-self-center ${className}`}
          block={block}
          color={color}
          onClick={!loading ? onClick : () => {}}
          disabled={disabled}
          style={style}
          id={internalId}
          onKeyDown={(e) => {
            if (e.key !== "Enter") {
              handleFocus(e);
            }
          }}
          tabIndex={tabIndex}
          innerRef={innerRef}
        >
          {loading ? <Spinner size="sm" color="light" /> : children}
        </Button>
        {!disabled && !loading && hint && (
          <UncontrolledTooltip target={internalId}>{hint}</UncontrolledTooltip>
        )}
        {disabled && !loading && (
          <UncontrolledTooltip target={internalId}>
            {disabledHint}
          </UncontrolledTooltip>
        )}
      </FormGroup>
    </Col>
  );
};

export default FormButton;
