import React, { useState } from "react";
import SolicitacaoRecargaService from "../../../../../services/pronta_entrega/SolicitacaoRecargaService";
import { ModalBase, Table } from "../../../../../components";
import { formatValueFromAPI, naturalSortDate } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "data_hora_log",
    text: "Data/Hora",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  { dataField: "log", text: "Descrição", align: "left", sortable: true },
  {
    dataField: "nome_colaborador",
    text: "Responsável",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
    sortable: true,
  },
];

export const LogsSolicRecargaModal = ({ isOpen, toggle, selected }) => {
  const [logs, setLogs] = useState([]);

  const limparDados = () => {
    setLogs([]);
  };

  const carregarDados = async () => {
    const [ok, ret] = await SolicitacaoRecargaService.buscarLogs(selected);
    if (ok) {
      setLogs(ret);
    } else {
      toggle();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={`Logs da Solicitação de Recarga Nº ${selected}`}
      number="0104_2"
      onClosed={limparDados}
      onBeforeOpen={carregarDados}
    >
      <Table data={logs} columns={columns} paginated={false} pageSize={10} />
    </ModalBase>
  );
};
