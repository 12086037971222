import React from "react";
import { ModalDetalhes, Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const columnsDetalhes = [
  { dataField: "produto", text: "Produto", align: "center" },
  { dataField: "descricao", text: "Nome", align: "left" },
  {
    dataField: "qtd_liq",
    text: "",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_item",
    text: "Vlr. Item",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_desc",
    text: "Vlr. Desc",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_tot_prod",
    text: "Produtos",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_tot_liq",
    text: "Total Líq.",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columns = [
  { dataField: "id", text: "Venda", align: "center" },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  { dataField: "data_venda", text: "Data", align: "center" },
  { dataField: "hora_venda", text: "Hora", align: "center" },
  {
    dataField: "nome_colab_vendedor",
    text: "Vendedor",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colab_vendedor),
  },
  {
    dataField: "vlr_desc",
    text: "Vlr. Desc",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_tot_prod",
    text: "Produtos",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_frete",
    text: "Frete",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_frete_liq",
    text: "Frete Líq.",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_tot_liq",
    text: "Total Líq.",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "det",
    text: "",
    align: "center",
    formatter: (c, row) => (
      <ModalDetalhes
        title={`Venda ${row.id} - Detalhes`}
        data={row.itens}
        columns={columnsDetalhes}
      />
    ),
  },
];
export const VendasExcetoGruposGrid = ({ dados }) => {
  return <Table data={dados} columns={columns} showRegisterCount={false} />;
};
