import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import GerarNfVendaOsService from "../../../../../services/faturamento/GerarNfVendaOsService";
import { Row } from "reactstrap";
import { FormCheckbox } from "../../../../../components";

export const ReabrirGerarNfModal = forwardRef(({ notifyEvent }, ref) => {
  const [idSelect, setSelect] = useState();
  const [tipoSelect, setTipoSelect] = useState();
  const [reabrirNf, setReabrirNf] = useState(true);
  const [reabrirNfServ, setReabrirNfServ] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const internalToggle = () => {
    if (isOpen) {
      // Limpa os dados
      setSelect(null);
      setTipoSelect(null);
    }

    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      tipo: tipoSelect,
      id_tipo: idSelect,
      reabrir_nf: reabrirNf,
      reabrir_nf_serv: reabrirNfServ,
    };

    const ret = await GerarNfVendaOsService.reabrirGerarNF(payload);

    if (ret) {
      internalToggle();
      notifyEvent();
    }
    setIsLoading(false);
  };

  useImperativeHandle(ref, () => ({
    toggle: (id, tipo) => {
      if (!isOpen) {
        setSelect(id);
        setTipoSelect(tipo);
        setIsOpen(true);
      }
    },
  }));

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={internalToggle}
      size="md"
      title="Reabrir para geração de NF-e, NFS-e"
      number="0036_3"
      onConfirm={handleSubmit}
      loadingConfirm={isLoading}
      autoFocus
    >
      <Row style={{ justifyContent: "center" }}>
        <FormCheckbox
          label="Reabrir para Faturar NF-e"
          padded={false}
          checked={reabrirNf}
          onChange={() => setReabrirNf(!reabrirNf)}
        />
        <FormCheckbox
          label="Reabrir para Faturar NFS-e"
          padded={false}
          checked={reabrirNfServ}
          onChange={() => setReabrirNfServ(!reabrirNfServ)}
        />
      </Row>
    </ModalBase>
  );
});
