import { toastr } from "react-redux-toastr";
import { apiGetV2, apiV2 } from "../../apiV2";
import { showError } from "../../components/AlertaModal";

const URL_BASE = "/parametro";

const processaRetornoAtu = ({ data }) => {
  const success = data.success || (data.hasOwnProperty("err") && !data.err);

  if (success) {
    toastr.success("Sucesso", data.msg);
  } else {
    const msgs = data.msg.reduce((acc, e) => {
      if (typeof e === "string") {
        return [...acc, e];
      }
      return [
        ...acc,
        ...e[Object.keys(e)[0]].map((p) => `${p.d_prob} (${p.id_prob})`),
      ];
    }, []);

    showError(
      "Ocorreram problemas ao atualizar os parâmetros.",
      data.id_err,
      msgs
    );
  }
  return [success, success ? data.res : data];
};

const processaErroAtu = (err) => {
  showError(err.message, `SERVER_ERROR_${err.response.status}`);
  return [
    false,
    { msg: err.message, id_err: `SERVER_ERROR_${err.response.status}` },
  ];
};

const ParametroService = {
  buscar: async () => await apiGetV2(`${URL_BASE}/buscar_params/`),
  atualizarParams: async (payload) =>
    await apiV2
      .post(`${URL_BASE}/atualizar_params/`, payload)
      .then((ret) => processaRetornoAtu(ret))
      .catch((err) => processaErroAtu(err)),
};

export default ParametroService;
