import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroFlexRegraColaboradorModal } from "./components/CadastroFlexRegraColaboradorModal";
import {
  AsyncComboBox,
  BotaoPesquisar,
  PageContainer,
} from "../../../../components";
import {
  MODAL_ACTIONS,
} from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { FlexRegraColaboradorGrid } from "./components/FlexRegraColaboradorGrid";

export const modalTitleCadastroFlexRegraColaborador =
  "Regras de Flex por Colaborador";
export const routesBaseCadastroFlexRegraColaborador =
  "/cadastro/flex_regra_colab";

export const FlexRegraColaborador = () => {
  const [idColaborador, setIdColaborador] = useState(null);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const [action, setAction] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async () => {
    const [ok, ret] = await apiGetV2(
      `${routesBaseCadastroFlexRegraColaborador}/listar/`,
      {
        id_colaborador: idColaborador
      }
    );
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const toggle = (action) => {
    setAction(action);
    setIsModalOpen(!isModalOpen);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggle(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer
      title="Regras de Flex por Colaborador"
      canGoBack
      number="0116"
    >
      <Card body>
        <Row>
        <AsyncComboBox
          md={4}
          
          concatModelName="colaborador"
          defaultOptions
          label="Colaborador"
          

          onChange={(s) => setIdColaborador(s?.value)}
          defaultValue={idColaborador}
        />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggle} />
        </Row>
      </Card>
      <CadastroFlexRegraColaboradorModal
        isOpen={isModalOpen}
        toggle={toggle}
        action={action}
        selected={selected}
        notifyEvent={notifyEvent}
      />
      <ModalExcluirV2
        isOpen={excluirOpen}
        toggle={toggleExcluir}
        selected={selected}
        number="0116_2"
        notifyEvent={notifyEvent}
        routeBase={routesBaseCadastroFlexRegraColaborador}
      />
      <FlexRegraColaboradorGrid
        dados={dados}
        setSelected={setSelected}
        alterar={alterar}
        excluir={excluir}
      />
    </PageContainer>
  );
};
