import { apiGetV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/cadastro/senha";

const CadastroSenhaService = {
  buscar: async () => await apiGetV2(`${URL_BASE}/buscar/`),
  alterar: async (nomeUrl, payload) =>
    await apiPutV2(`${URL_BASE}/alterar/${nomeUrl}/`, payload),
};

export default CadastroSenhaService;
