import { apiGetV2, apiPutV2 } from "../../apiV2";
import { docPrintReport } from "../../pdf";

const URL_BASE = "/pedido";

const URL_BASE_CENTRAL = `${URL_BASE}/central`;

const PedidoInternoService = {
  central: {
    estornarRecebimento: (payload) =>
      apiPutV2(`${URL_BASE_CENTRAL}/estornar_recebimento/`, payload),
    listar: (params) => apiGetV2(`${URL_BASE_CENTRAL}/listar/`, params),
    imprimir: async (idsPedidos, viasImprimir) => {
      let pedidosImprimir = [];

      for (let i = 0; i < idsPedidos.length; i++) {
        const [ok, ret] = await apiGetV2(
          `${URL_BASE_CENTRAL}/buscar_dados_impressao/${idsPedidos[i]}/`
        );
        if (ok) {
          pedidosImprimir.push(ret);
        } else {
          return false;
        }
      }

      if (pedidosImprimir.length > 0) {
        return await docPrintReport(
          "/pedido/pedido_interno/",
          {
            layout: pedidosImprimir[0].layout_impressao,
            params: pedidosImprimir[0].params,
            pedidos: pedidosImprimir,
            vias_imprimir: viasImprimir,
          },
          "0100_1"
        );
      } else {
        return false;
      }
    },
  },
};

export default PedidoInternoService;
