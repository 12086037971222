import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  NumberInput,
  Table,
  ModalBase,
  TableDelete,
  FixedField,
  SearchInput,
  Divider,
} from "../../../../../components";
import {
  debounce,
  defaultDebounceTime,
  formatNumber,
  naturalSort,
} from "../../../../../coreUtils";
import RegrasComissaoService from "../../../../../services/cadastro/RegrasComissaoService";
import { showWarning } from "../../../../../components/AlertaModal";

export const DetalharRegraComissaoEscalonadaModal = ({
  isOpen,
  toggle,
  selected,
}) => {
  const [nome, setNome] = useState("");
  const [idProduto, setIdProduto] = useState(null);
  const [precoVenda, setPrecoVenda] = useState(null);
  const [vlrMaxEscalonada, setVlrMaxEscalonada] = useState(0);
  const [percComissao, setPercComissao] = useState(0);
  const [pista, setPista] = useState("");
  const [itens, setItens] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const selectRef = useRef();

  const limparDados = () => {
    setNome("");
    setIdProduto(null);
    setPrecoVenda(null);
    setVlrMaxEscalonada(0);
    setItens([]);
    setPercComissao(0);
    setPista("");
  };

  const onOpen = async () => {
    await buscarDados();
    await carregarItens();
  };

  const carregarItens = async (p) => {
    const params = { pista: p };
    const [ok, ret] = await RegrasComissaoService.movEscalonada.listar(
      selected,
      params
    );
    setItens(ok ? ret : []);
  };

  const buscarDados = async () => {
    const [ok, ret] = await RegrasComissaoService.buscar(selected);
    if (!ok) return;
    setNome(ret.nome);
  };

  const handleSetProduto = (s) => {
    setIdProduto(s?.value ?? null);
    setPrecoVenda(s?.preco_venda ?? null);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await carregarItens(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const handleAdd = async () => {
    if ([0, null, undefined].includes(idProduto)) {
      showWarning("Por favor, selecione um Produto");
      return;
    }

    if ([0, null, undefined].includes(vlrMaxEscalonada)) {
      showWarning(
        "Por favor, informe o valor máximo da escalonada e tente novamente"
      );
      return;
    }

    if ([0, null, undefined].includes(percComissao)) {
      showWarning(
        "Por favor, informe o percentual da comissão e tente novamente"
      );
      return;
    }

    const payload = {
      id_cab: selected,
      id_produto: idProduto,
      vlr_max_escalonada: vlrMaxEscalonada,
      perc_comissao: percComissao,
    };

    setLoadingAdd(true);
    const [ok] = await RegrasComissaoService.movEscalonada.incluir(payload);

    if (ok) {
      setPercComissao(0);
      setVlrMaxEscalonada(0);
      await carregarItens();
      if (selectRef.current) {
        selectRef.current.clearValue();
        selectRef.current.setFocus();
      }
    }
    setLoadingAdd(false);
  };

  const handleDelete = async (idMov) => {
    const [ok] = await RegrasComissaoService.movEscalonada.excluir(idMov);
    if (ok) {
      carregarItens();
    }
  };

  const columns = [
    {
      dataField: "id_produto",
      text: "Produto",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_produto",
      text: "Nome",
      align: "left",
      sortable: true,
    },

    {
      dataField: "vlr_min_escalonada",
      text: "Faixa de Valores",
      align: "center",
      formatter: (c, row) =>
        `De ${formatNumber(c, true, 2)} a ${formatNumber(
          row.vlr_max_escalonada,
          true,
          2
        )}`,
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "perc_comissao",
      text: "Perc. Comissão",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2) + " %",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "Ações",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={`${nome}: Detalhes`}
      number="0038_3"
      onBeforeOpen={onOpen}
      onClosed={limparDados}
      hideCancelButton
      bodyStyle={{ paddingTop: "0.5rem" }}
    >
      <Divider className="mt-0">Filtros</Divider>
      <Row>
        <SearchInput
          value={pista}
          md={10}
          onChange={handlePista}
          loading={loadingPista}
          placeholder="Nome do Produto"
          hideLabel
        />
      </Row>
      <Divider className="mt-3 mb-0">Faixas</Divider>
      <Row>
        <AsyncComboBox
          label="Produto"
          concatModelName="produto"
          className="pr-0"
          isSearchable
          md={8}
          onChange={handleSetProduto}
          ref={selectRef}
        />
        <FixedField
          divClassName="pt-4"
          label="Valor Unitário"
          value={formatNumber(precoVenda ?? 0, true, 2)}
          horizontal
        />
      </Row>
      <Row className="mb-2">
        <NumberInput
          label="Vlr Máx Escalonada"
          md={2}
          onChange={setVlrMaxEscalonada}
          value={vlrMaxEscalonada}
        />
        <NumberInput
          label="Perc. Comissão"
          md={2}
          onChange={setPercComissao}
          value={percComissao}
          isPercentage
        />
        <FormButton
          md="auto"
          color="info"
          onClick={handleAdd}
          disabled={[0, null, undefined].includes(idProduto)}
          disabledHint="Selecione um produto no campo à esquerda"
          loading={loadingAdd}
        >
          Incluir
        </FormButton>
      </Row>
      <Table columns={columns} data={itens} paginated={false} pageSize={10} />
    </ModalBase>
  );
};
