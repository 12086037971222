import React from "react";
import { Label, Row } from "reactstrap";
import { formatNumber } from "../../../../../coreUtils";
import { ModalBase } from "../../../../../components";

const labelStyle = { fontSize: "0.9rem" };
const rowClassName = "mx-0";

export const ConfirmarPagtoParcialModal = ({
  isOpen,
  toggle,
  vlrPagar,
  totalLancado,
  onConfirm,
}) => {
  const onConfirmInternal = () => {
    onConfirm();
    toggle();
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Pagar Títulos"
      number="0091_6"
      onConfirm={onConfirmInternal}
    >
      <Row className={rowClassName}>
        <Label style={{ ...labelStyle, fontWeight: "bold" }}>
          Deseja confirmar o pagamento PARCIAL do título?
        </Label>
      </Row>
      <Row className={rowClassName}>
        <Label style={labelStyle}>
          Valor do Título: <strong>{formatNumber(vlrPagar, true, 2)}</strong>
        </Label>
      </Row>
      <Row className={rowClassName}>
        <Label style={labelStyle}>
          Valor dos Recursos:{" "}
          <strong>{formatNumber(totalLancado, true, 2)}</strong>
        </Label>
      </Row>
    </ModalBase>
  );
};
