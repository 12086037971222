import React, { useState } from "react";
import { Row } from "reactstrap";
import { ModalBase } from "../../../../../components";
import NotaFiscalConsumidorService from "../../../../../services/docs_eletron/NotaFiscalConsumidorService";

export const DescartarNFCeModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const [ok] =
      await NotaFiscalConsumidorService.gerenciamento.descartarDigitacao(
        selected
      );
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Inutilização de Nota Fiscal Consumidor"
      number="0066_4"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      confirmButtonText="Sim"
      cancelButtonText="Não"
    >
      <Row className="justify-content-center">
        <p style={{ textAlign: "center" }}>
          Tem certeza de que deseja Descartar a Digitação da Nota Fiscal
          selecionada?
        </p>
      </Row>
    </ModalBase>
  );
};
