import React from "react";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { Table, TableCheck } from "../../../../../components";

export const AmbienteGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    { dataField: "nome", text: "Nome", align: "left" },
    { dataField: "abreviacao", text: "Abreviação", align: "center" },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
