import React from "react";
import { Table } from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "id_cliente",
    text: "# Cliente",
    align: "center",
    headerAlign: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "nome_cliente",
    text: "Nome",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "nome_fant_cliente",
    text: "Nome Fantasia",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "data_cadastro",
    text: "Data de Cadastro",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome_cidade",
    text: "Cidade",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "vlr_vdas_periodo",
    text: "Vlr. Vendas no Período",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_vdas_total",
    text: "Total Vendas",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const ClientesNovosGrid = ({ dados }) => {
  return <Table data={dados} columns={columns} paginated={false} />;
};
