import React from "react";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../../coreUtils";
import { Table } from "../../../../../components";
import ItensVendaDesempenhoVendasMetaLucroModal from "./ItensVendaDesempenhoVendasMetaLucroModal";

const columns = [
  {
    dataField: "id_venda_cab",
    text: "Venda",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    sortable: true,
  },
  {
    dataField: "data_venda",
    text: "Data",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "nome_vendedor",
    text: "Vendedor",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_vendedor),
    sortable: true,
  },
  {
    dataField: "nome_colab_aprovou",
    text: "Aprovada Por",
    align: "left",
    formatter: (c, row) =>
      row.id_colab_aprovou ? formatValueFromAPI(c, row.id_colab_aprovou) : "",
    sortable: true,
  },
  {
    dataField: "pml_perc_marg_lucro_aprovada",
    text: "Resultado (%)",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_total_venda",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "act",
    text: "",
    align: "center",
    isDummyField: true,
    formatter: (c, row) => (
      <ItensVendaDesempenhoVendasMetaLucroModal
        idVendaCab={row.id_venda_cab}
        itens={row.itens}
      />
    ),
  },
];

export const DesempenhoVendasMetaLucroGrid = ({ data }) => {
  return <Table data={data} columns={columns} />;
};
