import React, { useState } from "react";
import { FormButton, ModalBase } from "../../../../../components";
import OrdemServicoService from "../../../../../services/ordem_servico/OrdemServicoService";

export const ExcluirOSModal = ({
  isOpen,
  toggle,
  selected,
  nomeClienteOS,
  notifyEvent,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const [ok] = await OrdemServicoService.excluirOS(selected);
    if (ok) {
      if (notifyEvent) {
        notifyEvent();
      }
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Excluir Ordem de Serviço"
      number="0006_6"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      Você tem certeza que deseja excluir a O.S. <b># {selected}</b> do cliente{" "}
      <b>{nomeClienteOS}</b>?
    </ModalBase>
  );
};

export const ExcluirOSButton = ({ onClick, disabled, padded }) => (
  <FormButton
    md="auto"
    color="danger"
    onClick={onClick}
    disabled={disabled}
    padded={padded}
  >
    Excluir O.S.
  </FormButton>
);
