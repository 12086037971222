import React, { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import IntegerFormInput from "../../IntegerFormInput";
import TextInput from "../..//TextInput";
import BotaoPesq from "../../form/BotaoPesq";
import { useRef } from "react";
import { PesqPlanoCtaCentroCustoModal } from "./PesqPlanoCtaCentroCustoModal";
import { apiGetV2 } from "../../../apiV2";
import ComboBoxService from "../../../services/components/ComboBoxService";
import { ConcatShortcut } from "../../ConcatShortcut";

export const PesqPlanoCtaCentroCusto = ({
  onChange = () => {},
  md = 4,
  mode = "plano_contas",
  value,
  label,
  divClassName,
  disabled,
  hideShortcut = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [internalId, setInternalId] = useState(null);
  const [buscarValorOnBlur, setBuscarValorOnBlur] = useState(true);
  const [loadingPesq, setLoadingPesq] = useState(false);
  const [loadingDesc, setLoadingDesc] = useState(false);
  const [selected, setSelected] = useState();

  const refId = useRef();
  const refNome = useRef();

  const toggle = async () => {
    if (!isOpen) {
      setLoadingPesq(true);
      const ok = await loadOptions();
      setLoadingPesq(false);
      if (!ok) return;
    } else {
      setOptions([]);
    }
    setIsOpen(!isOpen);
  };

  const handleSelectPlanoConta = (v, s) => {
    onChange(s?.value ?? null, s);
    setInternalId(s?.value ?? null);
    setDescricao(s?.label ?? "");
  };

  const loadOptions = async () => {
    const [ok, ret] = await apiGetV2(
      mode === "plano_contas"
        ? "/plano_contas/pesquisa/"
        : "/cadastro/centro_custo/pesquisa/"
    );
    setOptions(ok ? ret : []);
    return ok;
  };

  useEffect(() => {
    if ([0, null, undefined].includes(value)) {
      setDescricao("");
    } else if (value !== internalId) {
      setInternalId(value);
      buscarDescricao(value);
    }
  }, [value]);

  const handleInputId = (id) => {
    setInternalId(id);
    setBuscarValorOnBlur(true);
  };

  const buscarDescricao = async (id) => {
    let desc = null;
    let nivel = null;
    setLoadingDesc(true);
    try {
      const concatModelName =
        mode === "plano_contas" ? "plano_conta" : "centro_custo";
      const ret = await ComboBoxService.fetchOptions(concatModelName, {
        pk: id,
      });
      if (ret.length > 0) {
        setDescricao(ret[0].descricao);
        desc = ret[0].descricao;
        nivel = ret[0].nivel - 1;
      } else {
        setDescricao("Não Encontrado");
      }
    } finally {
      setLoadingDesc(false);
    }
    return [desc, nivel];
  };

  const handleOnBlur = async () => {
    if (buscarValorOnBlur) {
      if (![0, null, undefined].includes(internalId)) {
        const [desc, nivel] = await buscarDescricao(internalId);
        onChange(internalId, {
          value: internalId,
          label: desc,
          treeDepth: nivel,
        });
      } else {
        onChange(null, null);
      }
      setBuscarValorOnBlur(false);
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "F3") {
      e.preventDefault();
      toggle();
    }
  };

  return (
    <>
      <Col md={md} className={divClassName}>
        <Label>{label}</Label>
        <Row>
          <IntegerFormInput
            md={4}
            label={null}
            colClassName="pr-0"
            onChange={handleInputId}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            ref={refId}
            defaultValue={value}
            disabled={disabled}
            additionalButton={
              <>
                <BotaoPesq
                  onClick={toggle}
                  style={{ marginLeft: "6px"}}
                  loading={loadingDesc || loadingPesq}
                  disabled={disabled}
                />
                {!hideShortcut && (
                  <ConcatShortcut
                    selected={selected}
                    concatModelName={mode === "plano_contas" ? "plano_conta" : "centro_custo"}
                    setSelected={setSelected}
                  />
                )}
              </>
            }
          />

          <TextInput
            md={null}
            label={null}
            disabled={true}
            ref={refNome}
            value={descricao}
            selectTextOnFocus
          />
        </Row>
      </Col>
      <PesqPlanoCtaCentroCustoModal
        isOpen={isOpen}
        toggle={toggle}
        options={options}
        mode={'plano_contas'}
        checked={![0, null, undefined].includes(value) ? [value] : []}
        onCheck={handleSelectPlanoConta}
      />
    </>
  );
};
