import React from "react";
import { Card, DropdownItem } from "reactstrap";
import { TableCheck } from "../../../../../components/TableCheck";
import { Table } from "../../../../../components/Table";
import { ClassifCli } from "../../../../../components/ClassifCli";
import { naturalSort } from "../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const ClientesGrid = ({
  data,
  onSelect,
  sistAutomotivo,
  alterar,
  excluir,
  imprimirFicha,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      sortable: true,
      formatter: (c, row) => (
        <div style={{ display: "flex" }}>
          <span className="mr-2">{c}</span>
          {![0, null, undefined].includes(row.classif_cli) && (
            <ClassifCli
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                marginBlock: "auto",
              }}
              value={row.classif_cli}
              table
            />
          )}
        </div>
      ),
    },
    {
      dataField: "endereco",
      text: "Endereço",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "bairro",
      text: "Bairro",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "cidade__nome",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "fone1",
      text: "Fone",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "whatsapp",
      text: "Whatsapp",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "status",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    iconeAlterar(alterar),
    dropdownAcoes({
      excluir: excluir,
      outrasAcoes: (id) => (
        <>
          <DropdownItem onClick={() => imprimirFicha(id)}>Imprimir Ficha</DropdownItem>
        </>
      ),
    }),
  ];

  return (
    <Card body>
      <Table
        columns={columns}
        data={data}
        paginated={false}
        pageSize={sistAutomotivo ? 10 : 25}
        onSelect={onSelect}
        clearSelectionOnUpdate
        onRowDoubleClick={alterar}
      />
    </Card>
  );
};
