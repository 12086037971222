import { configureStore } from "@reduxjs/toolkit";
import localInstalacaoModulosSlice from "./local_instalacao_modulos_slice";
import vistoriaTecnicaSlice from "./vistoria_slice";

export default configureStore({
  reducer: {
    vistoriaTecnica: vistoriaTecnicaSlice,
    localInstalacaoModulos: localInstalacaoModulosSlice,
  },
});
