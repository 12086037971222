import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Card, Col, ListGroup, Row } from "reactstrap";
import {
  AsyncComboBox,
  CardTotaisItem,
  FiltroPeriodoDatas,
  FormButton,
  NumberInput,
} from "../../../../components";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { Divider } from "../../../../components/Divider";
import { PageContainer } from "../../../../components/PageContainer";
import { RadioGroup, RadioItem } from "../../../../components/RadioGroup";
import ComissaoVendExtService from "../../../../services/relatorios/comissionamento/ComissaoVendExtService";
import CardValores from "./components/CardValores";
import { ComissVendExtGrafico } from "./components/ComissVendExtGrafico";
import LegendaBaseInformacoes from "./components/LegendaBaseInformacoes";
import { DetalhamentoVendasModal } from "./components/DetalhamentoVendasModal";

const formasCalc = { VDA: "EMIS_VENDA", LIQ: "LIQUIDEZ" };

export const ComissaoVendExt = () => {
  // Filtros
  const [tipo, setTipo] = useState(null);
  const [dataIni, setDataIni] = useState(new Date().getMonth() + 1);
  const [dataFim, setDataFim] = useState(new Date().getFullYear());
  const [idColab, setIdColab] = useState(null);
  const [nomeColab, setNomeColab] = useState(null);
  // Controle
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingCalcular, setLoadingCalcular] = useState(false);
  const [loadingConfirmar, setLoadingConfirmar] = useState(false);
  const [mostrarDados, setMostrarDados] = useState(false);
  // Valores da Previa
  const [percComissaoPrevia, setPercComissaoPrevia] = useState(0);
  const [previaResultado, setPreviaResultado] = useState(null);
  // Valores do Vendedor - Mês da Apuração
  const [vlrVdaMesApuracao, setVlrVdaMesApuracao] = useState();
  const [percParticipacao, setPercParticipacao] = useState();
  const [ticketMedio, setTicketMedio] = useState();
  const [vlrFreteMesApuracao, setVlrFreteMesApuracao] = useState();
  const [vlrDevolMesApuracao, setVlrDevolMesApuracao] = useState();
  const [vlrVdaCliSemComisMesApura, setVlrVdaCliSemComisMesApura] = useState();
  // Valores do Vendedor - Mês Anterior
  const [vlrVdaMesAnterior, setVlrVdaMesAnterior] = useState();
  const [vlrComissaoMesAnterior, setVlrComissaoMesAnterior] = useState();
  const [vlrFreteMesAnt, setVlrFreteMesAnt] = useState();
  const [vlrDevolMesAnt, setVlrDevolMesAnt] = useState();
  const [vlrVdaCliSemComisMesAnt, setVlrVdaCliSemComisMesAnt] = useState();
  // Valores da Empresa - Mês da Apuração
  const [vlrEmprVdaMesApuracao, setVlrEmprVdaMesApuracao] = useState();
  const [vlrEmprFreteMesApuracao, setVlrEmprFreteMesApuracao] = useState();
  const [vlrEmprDevolMesApuracao, setVlrEmprDevolMesApuracao] = useState();
  const [vlrVdaEmprCliSemComisMes, setVlrEmprVdaCliSemComisMes] = useState();
  // Valores da Empresa - Mês Anterior
  const [vlrEmprVdaMesAnterior, setVlrEmprVdaMesAnterior] = useState();
  const [vlrEmprFreteMesAnterior, setVlrEmprFreteMesAnterior] = useState();
  const [vlrEmprDevolMesAnterior, setVlrEmprDevolMesAnterior] = useState();
  const [
    vlrVdaEmprCliSemComisMesAnt,
    setVlrEmprVdaCliSemComisMesAnt,
  ] = useState();
  // Gráfico
  const [vlrUltSemestre, setVlrUltSemestre] = useState([]);
  // Liquidez
  const [dadosLiquidez, setDadosLiquidez] = useState(null);
  const [baseCalcComissao, setBaseCalcComissao] = useState(0);

  const percComisRef = useRef(null);

  const buscarDados = async () => {
    setLoadingPesquisar(true);

    const ret = await ComissaoVendExtService.listar({
      data_ini: dataIni,
      data_fim: dataFim,
      tipo_beneficiado: "VEND_EXT",
      id_beneficiado: idColab,
      tipo: tipo,
    });

    if (!ret.perc_participacao) {
      setLoadingPesquisar(false);
      return false;
    }

    // Valores do Vendedor - Mês da Apuração
    setPercParticipacao(ret["perc_participacao"]);
    setTicketMedio(ret["ticket_medio"]);
    setVlrVdaMesApuracao(ret["vlr_vda_mes_apuracao"]);
    setVlrFreteMesApuracao(ret["vlr_frete_mes"]);
    setVlrDevolMesApuracao(ret["vlr_devol_mes"]);
    setVlrVdaCliSemComisMesApura(ret["vlr_venda_cli_nao_gera_comis_mes"]);

    // Valores do Vendedor - Mês Anterior
    setVlrVdaMesAnterior(ret["vlr_vda_mes_anterior"]);
    setVlrComissaoMesAnterior(ret["vlr_comissao_mes_anterior"]);
    setVlrFreteMesAnt(ret["vlr_frete_mes_ant"]);
    setVlrDevolMesAnt(ret["vlr_devol_mes_ant"]);
    setVlrVdaCliSemComisMesAnt(ret["vlr_venda_cli_nao_gera_comis_mes_ant"]);

    // Valores da Empresa - Mês da Apuração
    setVlrEmprVdaMesApuracao(ret["venda_mes_empresa"]);
    setVlrEmprFreteMesApuracao(ret["frete_mes_empresa"]);
    setVlrEmprDevolMesApuracao(ret["devol_mes_empresa"]);
    setVlrEmprVdaCliSemComisMes(ret["vlr_mes_empr_cli_nao_gera_comis"]);

    // Valores da Empresa - Mês Anterior
    setVlrEmprVdaMesAnterior(ret["venda_mes_ant_empresa"]);
    setVlrEmprFreteMesAnterior(ret["frete_mes_ant_empresa"]);
    setVlrEmprDevolMesAnterior(ret["devol_mes_ant_empresa"]);
    setVlrEmprVdaCliSemComisMesAnt(ret["vlr_mes_ant_empr_cli_nao_gera_comis"]);

    // Gráfico
    setVlrUltSemestre(
      ret["valor_ult_semestre"].map((e) => {
        return {
          mesano: e.mesano,
          valor: parseFloat(e["valor"]),
        };
      })
    );

    setBaseCalcComissao(parseFloat(ret.base_calc_comissao));

    setDadosLiquidez(ret.dados_liquidez ?? null);

    setPreviaResultado(null);
    setMostrarDados(true);

    setLoadingPesquisar(false);
  };

  const handleCalcPrevia = async () => {
    setLoadingCalcular(true);
    const payload = {
      perc_comissao_previa: percComissaoPrevia,
      base_calc_comissao: baseCalcComissao,
    };

    const ret = await ComissaoVendExtService.previa(payload);
    setPreviaResultado(parseFloat(ret.vlr_previa_comissao));
    setLoadingCalcular(false);
  };

  const handleConfirmarPercComiss = async () => {
    setLoadingConfirmar(true);

    const payload = {
      tipo_beneficiado: "VEND_EXT",
      id_beneficiado: idColab,
      data_ini: dataIni,
      data_fim: dataFim,
      forma_calc: formasCalc[tipo],
      base_calc_comissao: baseCalcComissao,
      vlr_comissao: previaResultado,
    };

    await ComissaoVendExtService.confirmar(payload);

    setLoadingConfirmar(false);
  };

  const handleDates = (dataIni, dataFim) => {
    setDataIni(
      dataIni instanceof Date ? dataIni.toISOString().split("T")[0] : dataIni
    );
    setDataFim(
      dataFim instanceof Date ? dataFim.toISOString().split("T")[0] : dataFim
    );
    limparDados();
  };

  const limparDados = () => {
    // Valores do Vendedor - Mês da Apuração
    setPercParticipacao(0);
    setTicketMedio(0);
    setVlrVdaMesApuracao(0);
    setVlrFreteMesApuracao(0);
    setVlrDevolMesApuracao(0);
    setVlrVdaCliSemComisMesApura(0);
    // Valores do Vendedor - Mês Anterior
    setVlrVdaMesAnterior(0);
    setVlrComissaoMesAnterior(0);
    setVlrFreteMesAnt(0);
    setVlrDevolMesAnt(0);
    setVlrVdaCliSemComisMesAnt(0);
    // Valores da Empresa - Mês da Apuração
    setVlrEmprVdaMesApuracao(0);
    setVlrEmprFreteMesApuracao(0);
    setVlrEmprDevolMesApuracao(0);
    setVlrEmprVdaCliSemComisMes(0);
    // Valores da Empresa - Mês Anterior
    setVlrEmprVdaMesAnterior(0);
    setVlrEmprFreteMesAnterior(0);
    setVlrEmprDevolMesAnterior(0);
    setVlrEmprVdaCliSemComisMesAnt(0);
    setVlrUltSemestre([]);
    setDadosLiquidez(null);
    setBaseCalcComissao(0);
    setMostrarDados(false);
  };

  const handleSelectColab = (v) => {
    setIdColab(v?.value);
    setNomeColab(v?.label);
  };

  useEffect(() => {
    limparDados();
  }, [dataIni, idColab, dataFim, tipo]);

  return (
    <PageContainer
      title="Comissão por Vendedor Externo"
      number="5032"
      canGoBack
    >
      <Card body>
        <Row>
          <RadioGroup label="Tipo" value={tipo} onChange={setTipo}>
            <RadioItem label="Por Liquidez" value="LIQ" />
            <RadioItem label="Por Emissão de Venda" value="VDA" />
          </RadioGroup>
          <FiltroPeriodoDatas onChange={handleDates} maxEnd={new Date()} />
          <AsyncComboBox
            md={3}
            label="Colaborador"
            name="colaborador"
            onChange={handleSelectColab}
            isSearchable
            isConcatField
            defaultOptions
            concatModelName="colaborador"
          />
          <BotaoPesquisar
            onClick={buscarDados}
            loading={loadingPesquisar}
            disabled={!tipo || !idColab}
            disabledHint={
              !tipo
                ? "Informe o Tipo de Comissionamento"
                : "Selecione um colaborador"
            }
          />
        </Row>
      </Card>
      {mostrarDados && (
        <>
          <Card body>
            <Divider className="mb-1 mt-0">
              Desempenho por Emissão de Vendas
            </Divider>
            <CardValores
              vlrVdaMesApuracao={vlrVdaMesApuracao}
              vlrVdaMesAnterior={vlrVdaMesAnterior}
              vlrComissaoMesAnterior={vlrComissaoMesAnterior}
              percParticipacao={percParticipacao}
              ticketMedio={ticketMedio}
              vlrFreteMesApuracao={vlrFreteMesApuracao}
              vlrDevolMesApuracao={vlrDevolMesApuracao}
              vlrVdaCliSemComisMesApura={vlrVdaCliSemComisMesApura}
              vlrFreteMesAnt={vlrFreteMesAnt}
              vlrDevolMesAnt={vlrDevolMesAnt}
              vlrVdaCliSemComisMesAnt={vlrVdaCliSemComisMesAnt}
              vlrEmprVdaMesApuracao={vlrEmprVdaMesApuracao}
              vlrEmprVdaMesAnterior={vlrEmprVdaMesAnterior}
              vlrEmprFreteMesApuracao={vlrEmprFreteMesApuracao}
              vlrEmprDevolMesApuracao={vlrEmprDevolMesApuracao}
              vlrVdaEmprCliSemComisMes={vlrVdaEmprCliSemComisMes}
              vlrEmprFreteMesAnterior={vlrEmprFreteMesAnterior}
              vlrEmprDevolMesAnterior={vlrEmprDevolMesAnterior}
              vlrVdaEmprCliSemComisMesAnt={vlrVdaEmprCliSemComisMesAnt}
            />
            <ComissVendExtGrafico data={vlrUltSemestre} />
          </Card>
          {dadosLiquidez && (
            <Card body>
              <Divider className="mb-1 mt-0">
                Valores Recebidos pelo Vendedor no período
              </Divider>
              <Row>
                <Col>
                  <ListGroup horizontal>
                    <CardTotaisItem
                      label="Total em Boletos"
                      value={dadosLiquidez?.vlr_tot_boletos}
                    />
                    <CardTotaisItem
                      label="Total em Dinheiro Conferido"
                      value={dadosLiquidez?.vlr_tot_dinheiro}
                    />
                    <CardTotaisItem
                      label="Total em Transf. Bancária/PIX"
                      value={dadosLiquidez?.vlr_tot_transf_banc_pix}
                    />
                  </ListGroup>
                </Col>
              </Row>
            </Card>
          )}
          <Card body>
            <Row>
              <NumberInput
                label="Base Cálc. Comissão"
                md={2}
                value={baseCalcComissao}
                disabled
              />
              <NumberInput
                label="Perc. Comissão"
                md={2}
                onChange={setPercComissaoPrevia}
                value={percComissaoPrevia}
                isPercentage
                ref={percComisRef}
              />
              <FormButton
                md="auto"
                color="info"
                loading={loadingCalcular}
                onClick={handleCalcPrevia}
              >
                Calcular
              </FormButton>
              <NumberInput
                label="Resultado"
                md={2}
                disabled={true}
                value={previaResultado}
              />
              <DetalhamentoVendasModal
                idColab={idColab}
                nomeColab={nomeColab}
                dataIni={dataIni}
                dataFim={dataFim}
                tipo={tipo}
                percComis={percComissaoPrevia}
              />
              <FormButton
                md="auto"
                color="success"
                onClick={handleConfirmarPercComiss}
                loading={loadingConfirmar}
              >
                Confirmar
              </FormButton>
            </Row>
          </Card>
          <LegendaBaseInformacoes />
        </>
      )}
    </PageContainer>
  );
};
