import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../components";
import { NfSaidaAgrupadoProdutoGrid } from "./components/NfSaidaAgrupadoProdutoGrid";
import { formatDateISO } from "../../../../coreUtils";
import { docPrintReport } from "../../../../pdf";
import { apiGetV2 } from "../../../../apiV2";

export const NfSaidaAgrupadoProduto = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idCliente, setIdCliente] = useState(null);
  const [idTipoDoc, setIdTipoDoc] = useState([]);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: idCliente,
      id_tipo_doc: idTipoDoc,
    };

    return await apiGetV2(`/relatorios/nf_saida/agrupado_por_produto/`, params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      setDados(ret.produtos);
      setTotais(ret.totais);
    }
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      await docPrintReport(
        "/relatorios/nf_saida/agrupado_por_produto/",
        {
          dados: ret.produtos,
          totais: ret.totais,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
        },
        "5043"
      );
    }
    setLoadingImprimir(false);
  };

  useEffect(() => {
    limparDados();
  }, [dataIni, dataFim, idCliente, idTipoDoc]);

  return (
    <PageContainer
      title="NF Saída - Agrupado por Produto"
      number="5043"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            md={4}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
          />
          <AsyncComboBox
            onChange={(s) => setIdTipoDoc(s?.map((e) => e.value) ?? [])}
            isSearchable
            isClearable
            md={3}
            label="Tipo de Documento"
            isConcatField
            concatModelName="tipo_documento"
            isMulti
            hideShortcut
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <CardTotais md={5}>
        <CardTotaisItem
          label="Quantidade"
          value={totais?.quantidade}
          checkFloat
        />
        <CardTotaisItem label="Valor" value={totais?.valor} />
      </CardTotais>
      <Card body>
        <NfSaidaAgrupadoProdutoGrid dados={dados} totais={totais} />
      </Card>
    </PageContainer>
  );
};
