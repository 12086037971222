import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pista: "",
  mostrarInativos: false,
  idVeiculo: null,
  placa: null,
};

export const listagemClientesSlice = createSlice({
  name: "listagem_clientes_slice",
  initialState: initialState,
  reducers: {
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setPista: (state, action) => {
      state.pista = action.payload;
    },
    setMostrarInativos: (state, action) => {
      state.mostrarInativos = action.payload;
    },
    setIdVeiculo: (state, action) => {
      state.idVeiculo = action.payload;
    },
    setPlaca: (state, action) => {
      state.placa = action.payload;
    },
  },
});

export const { setPista, setMostrarInativos, setIdVeiculo, setPlaca } =
  listagemClientesSlice.actions;

export default listagemClientesSlice.reducer;
