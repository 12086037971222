import React, { useState } from "react";
import { Card, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { AsyncComboBox } from "../../../../components";
import { BotaoImprimir } from "../../../../components/BotaoImprimir";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import { formatDateISO, formatNumber } from "../../../../coreUtils";
import { TableVendasPorRota } from "./components/TableVendasPorRota";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";

export const VendasPorRota = () => {
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idCliente, setIdCliente] = useState(null);
  const [idRede, setIdRede] = useState(null);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
    limparDados();
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: idCliente,
      id_rede: idRede,
    };

    return await apiGetV2(
      "/relatorios/venda_direta/vendas_agrupadas_por_rota/",
      params
    );
  };

  const listarDados = async () => {
    setLoadingPesquisar(true);

    const [ok, ret] = await buscarDados();

    if (ok) {
      setDados(ret.rotas);
      setTotais(ret.totais);
    } else {
      setDados([]);
      setTotais({});
    }
    setLoadingPesquisar(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      await docPrintReport(
        "/relatorios/pronta_entrega/vendas_por_rota/",
        {
          dados: ret.rotas,
          totais: ret.totais,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
        },
        "5027_1"
      );
    }
    setLoadingImprimir(false);
  };

  return (
    <PageContainer title="Vendas Agrupadas por Rota" number="5027" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            md={4}
            onChange={(selected) => {
              setIdCliente(selected?.value);
              limparDados();
            }}
            isSearchable
            isClearable
          />
          <AsyncComboBox
            isConcatField
            concatModelName="rede_cliente"
            label="Rede"
            md={3}
            onChange={(selected) => {
              setIdRede(selected?.value);
              limparDados();
            }}
            isSearchable
            isClearable
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={listarDados} loading={loadingPesquisar} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <Card body>
        <Row>
          <Col md={12}>
            <ListGroup horizontal>
              <ListGroupItem>
                Qtd. Vendas:{" "}
                <strong>
                  <span>
                    {formatNumber(totais["total_qtde_vendas"] ?? 0, true, 0)}
                  </span>
                </strong>
              </ListGroupItem>
              <ListGroupItem>
                Troca:{" "}
                <strong>
                  <span>
                    {formatNumber(totais["total_troca"] ?? 0, true, 2)}
                  </span>
                </strong>
              </ListGroupItem>
              <ListGroupItem>
                Bonificação:{" "}
                <strong>
                  <span>
                    {formatNumber(totais["total_bonificacao"] ?? 0, true, 2)}
                  </span>
                </strong>
              </ListGroupItem>
              <ListGroupItem>
                Vendas com ST:{" "}
                <strong>
                  <span>
                    {formatNumber(totais["total_venda_com_st"] ?? 0, true, 2)}
                  </span>
                </strong>
              </ListGroupItem>
              <ListGroupItem>
                Vendas sem ST:{" "}
                <strong>
                  <span>
                    {formatNumber(totais["total_venda_sem_st"] ?? 0, true, 2)}
                  </span>
                </strong>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Card>
      <TableVendasPorRota dados={dados} />
      <Card body>
        <p>
          <b>BASE DE INFORMAÇÕES</b>
          <br />
          Vendas: Vendas realizadas por Aplicativos Mobile e Desktop
        </p>
      </Card>
    </PageContainer>
  );
};
