import React from "react";
import { Card } from "reactstrap";
import { DataTable } from "../../../../components/DataTable";
import { TableCheck } from "../../../../components/TableCheck";

export const LiberacaoClientesGrid = ({
  data,
  setClienteID,
  setNomeCliente,
}) => {
  const _columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "cpf_cnpj",
      text: "CPF/CNPJ",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "endereco",
      text: "Endereço",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "cidade__nome",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "cidade__uf",
      text: "UF",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "status",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => <TableCheck value={cell} />,
    },
    {
      dataField: "liberado_ecommerce",
      text: "Liberado",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => <TableCheck value={cell} />,
    },
  ];

  const handleSelect = (id, row) => {
    setClienteID(id);
    setNomeCliente(row?.nome);
  };

  return (
    <Card body>
      <DataTable
        columns={_columns}
        data={data}
        paginated
        onSelect={handleSelect}
      />
    </Card>
  );
};
