import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BsBox, BsFillFileEarmarkMedicalFill } from "react-icons/bs";
import {
  relnfSaidaProdNcmInvalidaRoute,
  relNfSaiAgrupadoProdutoRoute,
  relNfSaiDebitoLocacaoRoute,
  relNfSaidaGeralRoute,
  relNfSaiExclusaoIcmsStRoute,
} from "../../../routes/modules/relatorios";
import { MdOutlineCarRental } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";

export const NfSaida = () => {
  return (
    <PageContainer title="Relatórios de Nota Fiscal de Saída">
      <MenuGroup>
        <MenuGroupButton
          label="NF Saída Geral"
          icon={BsFillFileEarmarkMedicalFill}
          pathname={relNfSaidaGeralRoute.path}
          nroTela={relNfSaidaGeralRoute.nroTela}
        />
        <MenuGroupButton
          label="Agrupado por Produto"
          icon={BsBox}
          pathname={relNfSaiAgrupadoProdutoRoute.path}
          nroTela={relNfSaiAgrupadoProdutoRoute.nroTela}
        />
        <MenuGroupButton
          label="Exclusão de ICMS ST"
          icon={AiOutlineFileExcel}
          pathname={relNfSaiExclusaoIcmsStRoute.path}
          nroTela={relNfSaiExclusaoIcmsStRoute.nroTela}
        />
        <MenuGroupButton
          label="NCM's Inválidas"
          icon={AiOutlineFileExcel}
          pathname={relnfSaidaProdNcmInvalidaRoute.path}
          nroTela={relnfSaidaProdNcmInvalidaRoute.nroTela}
        />
        <MenuGroupButton
          label="NF Débito Locação"
          icon={MdOutlineCarRental}
          pathname={relNfSaiDebitoLocacaoRoute.path}
          nroTela={relNfSaiDebitoLocacaoRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
