import moment from "moment";
import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FixedTable,
  FormButton,
  IntegerFormInput,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { DatePicker } from "../../../../../components/DatePicker";
import { ModalBase } from "../../../../../components/ModalBase";
import {
  formatDateISO,
  formatNumber,
  roundFloat,
} from "../../../../../coreUtils";
import EmprestimoColaboradorService from "../../../../../services/frente_venda_mobile/EmprestimoColaboradorService";

const columns = [
  {
    dataField: "id",
    text: "Parcela",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const IncluirEmprestimoModal = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idColaborador, setIdColaborador] = useState(null);
  const [dataEmprestimo, setDataEmprestimo] = useState(new Date());
  const [valorTotal, setValorTotal] = useState(0);
  const [observ, setObserv] = useState("");
  const [numParcelas, setNumParcelas] = useState(0);
  const [parcelas, setParcelas] = useState([]);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdColaborador(null);
    setDataEmprestimo(new Date());
    setValorTotal(0);
    setObserv("");
    setNumParcelas(0);
    setParcelas([]);
  };

  const toggle = () => setIsOpen(!isOpen);

  const calcularParcelas = () => {
    let res = [];
    if (numParcelas > 0 && valorTotal > 0) {
      const parc = roundFloat(valorTotal / numParcelas, 2);
      const diff = roundFloat(valorTotal - parc * numParcelas, 2);
      for (let i = 1; i <= numParcelas; i++) {
        res.push({
          id: i,
          valor: parc,
          observ: "",
        });
      }

      res[0].valor = roundFloat(parc + diff, 2);
    }
    setParcelas(res);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      id_colaborador: idColaborador ?? 0,
      data_emprestimo: formatDateISO(dataEmprestimo),
      valor_total: valorTotal,
      observ: observ,
      parcelas: parcelas.map((e) => ({ valor: e.valor })),
    };

    if (await EmprestimoColaboradorService.incluir(payload)) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const recalcularParcelas = () => {
    calcularParcelas();
  };

  useEffect(recalcularParcelas, [numParcelas, valorTotal]);

  return (
    <>
      <FormButton color="info" md="auto" onClick={toggle}>
        Incluir
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        onClosed={limparDados}
        title="Inclusão de Empréstimo de Colaborador"
        number="0052_1"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            onChange={(s) => setIdColaborador(s?.value)}
            label="Colaborador"
            md={7}
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            isSearchable
          />
        </Row>
        <Row className="mb-3">
          <NumberInput
            md={4}
            label="Valor Total do Empréstimo"
            value={valorTotal}
            onChange={setValorTotal}
          />
          <IntegerFormInput
            md={4}
            label="Parcelas"
            defaultValue={numParcelas}
            onChange={setNumParcelas}
          />
          <DatePicker
            md={4}
            label="Data do Empréstimo"
            value={dataEmprestimo}
            onChange={(v) =>
              setDataEmprestimo(moment.isMoment(v) ? v.toDate() : v)
            }
          />
        </Row>
        <Row>
          <TextInput
            type="textarea"
            label="Observação"
            value={observ}
            rows={3}
            onChange={(e, v) => setObserv(v)}
          />
        </Row>
        <FixedTable
          data={parcelas}
          columns={columns}
          style={{ height: "300px" }}
        />
      </ModalBase>
    </>
  );
};
