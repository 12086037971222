import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { ModalDetalhes } from "../../../../../components/ModalDetalhes";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

export const DetalhesCliInadRotaModal = ({ row }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sort: true,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      sort: true,
      formatter: (cell, row) => (
        <>
          <span id={`nome_${row.id}`}>
            {formatValueFromAPI(cell, row.id_cliente)}
          </span>
          <UncontrolledTooltip target={`nome_${row.id}`}>
            {row.nome_fant_cliente}
          </UncontrolledTooltip>
        </>
      ),
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      formatter: (cell, row) => `${cell}-${row.parcela}`,
    },
    {
      dataField: "nosso_nro",
      text: "Nosso Nro.",
      align: "center",
    },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
    },
    {
      dataField: "vencimento",
      text: "Vencimento",
      align: "center",
    },
    {
      dataField: "vlr_orig",
      text: "Vlr. Original",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_receber",
      text: "Vlr. Receber",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_recebido",
      text: "Vlr. Recebido",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
  ];

  return (
    <ModalDetalhes
      title={`${row.nome} - Detalhamento de Títulos Em Aberto`}
      data={row.titulos}
      columns={columns}
    />
  );
};
