import { apiGetV2 } from "../../apiV2";

const URL_BASE = "/manifestacao_dfe";

const DownloadManifNfeService = {
  consultaChave: async (chaveAcesso) => {
    const [ok] = await apiGetV2(
      `${URL_BASE}/consulta_por_chave/${chaveAcesso}/`
    );

    return ok;
  },
};

export default DownloadManifNfeService;
