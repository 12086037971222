import { Zap } from "react-feather";
import { GerarNfVendaOs } from "../../pages/faturamento/venda_os/gerar_nf_venda_os/GerarNfVendaOs";
import { VendaOS } from "../../pages/faturamento/venda_os/VendaOs";
import { InfoNfFaturada } from "../../pages/faturamento/venda_os/gerar_nf_venda_os/info_nf_faturada/InfoNfFaturada";
import { TransferenciaVenda } from "../../pages/faturamento/transferencias/transferencia_venda/TransferenciaVenda";
import { Transferencias } from "../../pages/faturamento/transferencias/Transferencias";

export const gerarNfVendaOsRoute = {
  path: "/faturamento/venda_os/gerar_nota_fiscal",
  name: "Gerar NF de Venda ou O.S",
  id: "AcSkWeb0036",
  nroTela: "0036",
  component: GerarNfVendaOs,
};

export const transferenciaVendaRoute = {
  path: "/faturamento/transferencias/transferencia_venda",
  name: "Transferência de Vendas entre Empresas",
  id: "AcSkWeb0082",
  nroTela: "0082",
  component: TransferenciaVenda,
};

export const faturamentoVendaOsRoutes = [gerarNfVendaOsRoute];

export const transferenciasRoutes = [transferenciaVendaRoute];

export const infoNfFaturadaRoute = {
  path: "/faturamento/venda_os/gerar_nota_fiscal/info_nf_faturada",
  name: "Informações da Nota Fiscal Faturada",
  id: "AcSkWeb0036",
  nroTela: "0036",
  component: InfoNfFaturada,
};

const faturamentoRoutes = {
  path: "/faturamento",
  name: "Faturamento",
  icon: Zap,
  children: [
    {
      path: "/faturamento/venda_os",
      name: "Faturamento - Venda/O.S.",
      component: VendaOS,
      canGoBack: false,
    },
    {
      path: "/faturamento/transferencias",
      name: "Transferências",
      component: Transferencias,
      canGoBack: false,
    },
  ],
};

export default faturamentoRoutes;
