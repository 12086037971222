import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  BaseInfoTelaCard,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { apiGetV2 } from "../../../../apiV2";
import { ValorHorasTecnicoGrid } from "./components/ValorHorasTecnicoGrid";
import { docPrintReport } from "../../../../pdf";
import { formatDateISO } from "../../../../coreUtils";

const formatoOptions = [
  { label: "Sintético", value: "SIN" },
  { label: "Analítico", value: "ANA" },
];

export const ValorHorasTecnico = () => {
  const [idTecnico, setIdTecnico] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [formatoImpr, setFormatoImpr] = useState(formatoOptions[0].value);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [mostrarValoresServicosImpr, setMostrarValoresServicosImpr] =
    useState(true);
  const [considerarApenasOsFechRecCxa, setConsiderarApenasOsFechRecCxa] =
    useState(false);

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async (formato) => {
    const params = {
      id_tecnico: idTecnico,
      data_ini: dataIni,
      data_fim: dataFim,
      formato: formato,
      considerar_apenas_os_fech_rec_cxa: considerarApenasOsFechRecCxa,
    };

    return await apiGetV2(
      "/relatorios/ordem_servico/servicos_por_tecnico/",
      params
    );
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados("ANA");
    setDados(ok ? ret.tecnicos : []);
    setTotais(ok ? ret.totais : {});
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados(formatoImpr);
    if (ok) {
      const payload = {
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
        tecnicos: ret.tecnicos,
        totais: ret.totais,
        formato: formatoImpr,
        mostrar_valores_servicos: mostrarValoresServicosImpr,
      };
      await docPrintReport(
        "/relatorios/ordem_servico/valor_horas_tecnico/",
        payload,
        "5057"
      );
    }
    setLoadingImprimir(false);
  };

  useEffect(() => {
    limparDados();
  }, [dataIni, dataFim]);

  return (
    <PageContainer title="Valor de Horas por Técnico" number="5057">
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="tecnico"
            label="Técnico"
            isSearchable
            onChange={(s) => setIdTecnico(s?.value)}
            defaultValue={idTecnico}
            defaultOptions
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <FormCheckbox
            label="Considerar apenas O.S. Fechadas e Recebidas no Caixa Loja"
            checked={considerarApenasOsFechRecCxa}
            onChange={() =>
              setConsiderarApenasOsFechRecCxa(!considerarApenasOsFechRecCxa)
            }
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
        <Row>
          <ComboBox
            md={2}
            label="Formato"
            options={formatoOptions}
            defaultValue={formatoImpr}
            onChange={(s) => setFormatoImpr(s?.value)}
          />
          <FormCheckbox
            label="Mostrar Valores de Serviços"
            checked={mostrarValoresServicosImpr}
            onChange={() =>
              setMostrarValoresServicosImpr(!mostrarValoresServicosImpr)
            }
          />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Garantia"
          value={totais?.cnt_garantia}
          checkFloat
        />
        <CardTotaisItem
          label="Retrabalho"
          value={totais?.cnt_retrabalho}
          checkFloat
        />
        <CardTotaisItem
          label="Lançados Sem Técnico"
          value={totais?.cnt_sem_tecnico}
          checkFloat
        />
        <CardTotaisItem
          label="Total de Lançamentos"
          value={totais?.cnt_total}
          checkFloat
        />
        <CardTotaisItem
          label="Qtd. Total"
          value={totais?.qtd_total}
          checkFloat
        />
        <CardTotaisItem label="Valor Total" value={totais?.vlr_total} />
      </CardTotais>
      <Card body>
        <ValorHorasTecnicoGrid dados={dados} />
      </Card>
      <BaseInfoTelaCard nroTela="5057" />
    </PageContainer>
  );
};
