import React, { useState } from "react";
import {
  FixedField,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { toastr } from "react-redux-toastr";

export const UsarCredClienteModal = ({
  nomeCliente,
  vlrSaldoCredCliente,
  setVlrObservCredCliente,
  isOpen,
  toggle,
}) => {
  const [observ, setObserv] = useState("");
  const [vlrUtilizar, setVlrUtilizar] = useState(0);

  const limparDados = () => {
    setObserv("");
    setVlrUtilizar(0);
  };

  const onConfirm = () => {
    if (vlrUtilizar > vlrSaldoCredCliente) {
      toastr.warning(
        "Atenção",
        "O Valor a Utilizar não pode ser superior ao Saldo do Cliente"
      );
      return false;
    }
    setVlrObservCredCliente(vlrUtilizar, observ);
    toggle();
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Utilização do Crédito do Cliente"
      number="0063_16"
      onConfirm={onConfirm}
      onClosed={limparDados}
    >
      <Row>
        <FixedField label="Cliente" value={nomeCliente} horizontal />
      </Row>
      <Row>
        <NumberInput
          md={6}
          label="Saldo do Cliente"
          value={vlrSaldoCredCliente}
          disabled
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="Observação"
          value={observ}
          onChange={(e, v) => setObserv(v)}
        />
      </Row>
      <Row>
        <NumberInput
          md={6}
          label="Valor a Utilizar"
          value={vlrUtilizar}
          onChange={setVlrUtilizar}
        />
      </Row>
    </ModalBase>
  );
};
