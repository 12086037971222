import React from "react";
import { Table } from "../../../../../components";
import { formatValueFromAPI } from "../../../../../coreUtils";

export const columnsClientePorVendedor = [
  {
    dataField: "nome",
    text: "Vendedor",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id),
  },
  {
    dataField: "qtd_clientes",
    text: "Clientes",
    align: "center",
    headerAlign: "center",
  },
];

export const columnsDetailClientePorVendedor = [
  {
    dataField: "nome",
    text: "Cliente",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id),
  },
  {
    dataField: "nome_fant",
    text: "Nome Fantasia",
    align: "left",
    headerAlign: "left",
  },
  { dataField: "bairro", text: "Bairro", align: "left", headerAlign: "left" },
  {
    dataField: "cidade__nome",
    text: "Cidade",
    align: "left",
    headerAlign: "left",
  },
  { dataField: "fone1", text: "Fone", align: "center", headerAlign: "center" },
  {
    dataField: "repr_externo_atende",
    text: "Repr. Externo",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "rota_atende",
    text: "Rota",
    align: "left",
    headerAlign: "left",
  },
];

export const ClientesPorVendedorGrid = ({ dados }) => {
  return (
    <Table
      data={dados}
      columns={columnsClientePorVendedor}
      canExpand
      fixedSize={false}
      expandedAreaBuilder={(row) => (
        <Table
          data={row.clientes}
          columns={columnsDetailClientePorVendedor}
          paginated={false}
          pageSize={row.clientes.length}
          showRegisterCount={false}
        />
      )}
      paginated={false}
    />
  );
};
