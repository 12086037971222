import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import { TextInput } from "../../../../../components";
import { ModalCadastro } from "../../../../../components/ModalCadastro";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import VendedorTecnicoServicoService from "../../../../../services/cadastro/VendedorTecnicoServicoService";

const ModalCadastroVTS = ({ notify, idRegistro }) => {
  const [nome, setNome] = useState("");
  const [ativo, setAtivo] = useState(true);

  const buscarDados = async () => {
    if (idRegistro) {
      const ret = await VendedorTecnicoServicoService.buscar(idRegistro);
      if (ret !== {}) {
        setNome(ret["nome"]);
        setAtivo(ret["ativo"]);
      }
    }
  };

  const validar = () => {
    toastr.clean();

    if (["", null, undefined].includes(nome)) {
      toastr.warning("Nome inválido", "Verifique o nome e tente novamente.");
      return false;
    }

    return true;
  };

  const submitPayload = (action) => {
    let payload = { nome, ativo };

    if (action === MODAL_ACTIONS.EDIT) payload["id"] = idRegistro;

    return validar() ? payload : false;
  };

  const onOpen = (action) => action === MODAL_ACTIONS.EDIT && buscarDados();

  const onClose = () => {
    setNome("");
    setAtivo(true);
  };

  return (
    <ModalCadastro
      title="Vendedor Técnico de Serviços"
      onOpen={onOpen}
      onClose={onClose}
      size="md"
      submitPayload={(a) => submitPayload(a)}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routes={{
        post: `${VendedorTecnicoServicoService.urlBase}/cadastrar/`,
        put: `${VendedorTecnicoServicoService.urlBase}/alterar/`,
        delete: `${VendedorTecnicoServicoService.urlBase}/excluir/${idRegistro}/`,
      }}
      number="0020_1"
      deleteModalNumber="0020_2"
      selected={idRegistro}
      buttonsDivClassName="pr-0"
      notifyEvent={notify}
    >
      <Row>
        <TextInput
          required
          md={12}
          label="Nome"
          onChange={(e, val) => setNome(val)}
          value={nome}
          maxLength={80}
          autoFocus
        />
      </Row>
    </ModalCadastro>
  );
};

export default ModalCadastroVTS;
