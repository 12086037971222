import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  FixedField,
  FormButton,
  ModalBase,
  NumberInput,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
  Table,
  TableDelete,
  TextInput,
} from "../../../../../components";
import GerencContasPagarService from "../../../../../services/financeiro/GerencContasPagarService";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import moment from "moment";
import { RadioItem } from "../../../../../components/RadioGroup";
import { showWarning } from "../../../../../components/AlertaModal";

const columnsMovsPlanoConta = [
  { dataField: "id", text: "# ID", align: "center" },
  { dataField: "desc_operacao", text: "Tipo", align: "center" },
  { dataField: "emissao", text: "Emissão", align: "center" },
  { dataField: "pagamento", text: "Pagamento", align: "center" },
  {
    dataField: "nome_plano_conta",
    text: "Plano de Contas",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_plano_conta),
  },
  { dataField: "nome_centro_custo", text: "Centro de Custo", align: "left" },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const headerRowStyle = {
  justifyContent: "space-between",
  marginBottom: "0.5rem",
  marginRight: "-15px",
};

export const LancamentosTituloPagarModal = ({ isOpen, toggle, selected }) => {
  const [dadosTitulo, setDadosTitulo] = useState({});
  const [params, setParams] = useState({});
  const [natureza, setNatureza] = useState("ACRE");
  const [idPlanoContas, setIdPlanoContas] = useState("");
  const [idCentroCusto, setIdCentroCusto] = useState(null);
  const [historico, setHistorico] = useState("");
  const [valor, setValor] = useState(0);
  const [loadingAddLancamento, setLoadingAddLancamento] = useState(false);

  const utilizaOrganizacao = params?.utiliza_organizacao ?? false;
  const utilizaCentroCusto = params?.utiliza_centro_custo ?? false;

  const limparDadosLancamento = () => {
    setNatureza("ACRE");
    setIdPlanoContas("");
    setIdCentroCusto(null);
    setHistorico("");
    setValor(0);
  };

  const limparDados = () => {
    setDadosTitulo({});
    setParams({});
    limparDadosLancamento();
  };

  const carregarDados = async () => {
    const [ok, ret] = await GerencContasPagarService.buscar(selected);
    if (ok) {
      setDadosTitulo(ret);
    }
  };

  const onBeforeOpen = async (params) => {
    setParams(params);
    await carregarDados();
  };

  const handleAddLancamento = async () => {
    if ([0, null, undefined].includes(idPlanoContas)) {
      showWarning("Por favor, informe o Plano de Contas do Lançamento");
      return false;
    }
    if (utilizaCentroCusto && [0, null, undefined].includes(idCentroCusto)) {
      showWarning("Por favor, informe o Centro de Custo do Lançamento");
      return false;
    }
    if ([0, null, undefined].includes(valor)) {
      showWarning("Por favor, informe o Valor do Lançamento");
      return false;
    }

    const payload = {
      id_titulo_pagar: selected,
      natureza: natureza,
      id_plano_conta: idPlanoContas,
      id_centro_custo: idCentroCusto,
      historico: historico,
      valor: valor,
    };

    setLoadingAddLancamento(true);
    const [ok] = await GerencContasPagarService.lancamentos.incluir(payload);
    if (ok) {
      limparDadosLancamento();
      await carregarDados();
    }
    setLoadingAddLancamento(false);
  };

  const handleDeleteLancamento = async (id) => {
    const [ok] = await GerencContasPagarService.lancamentos.excluir(id);
    if (ok) {
      carregarDados();
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "F9") {
      handleAddLancamento();
    }
  };

  const columnsLancamentos = [
    { dataField: "id_lote_pag", text: "Lote", align: "center" },
    {
      dataField: "data_hora",
      text: "Data/Hora",
      align: "center",
      formatter: (c) => moment(c).format("DD/MM/YYYY HH:mm"),
    },
    {
      dataField: "nome_colaborador",
      text: "Responsável",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
    },
    {
      dataField: "histor",
      text: "Observação",
      align: "left",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "del",
      dummy: true,
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDeleteLancamento(row.id)} />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Lançamentos do Título a Pagar"
      number="0091_3"
      onBeforeOpen={onBeforeOpen}
      onClosed={limparDados}
      paramsName="inc_titulo_pagar"
      onKeyDown={onKeyDown}
    >
      <Row style={headerRowStyle}>
        <FixedField label="ID" value={selected} horizontal />
      </Row>
      {utilizaOrganizacao && (
        <Row style={headerRowStyle}>
          <FixedField
            label="Organização"
            value={
              dadosTitulo?.id_organizacao
                ? formatValueFromAPI(
                    dadosTitulo?.nome_organizacao,
                    dadosTitulo?.id_organizacao
                  )
                : "Não Informada"
            }
            horizontal
          />
        </Row>
      )}
      <Row style={headerRowStyle}>
        <FixedField
          label="Fornecedor"
          value={formatValueFromAPI(
            dadosTitulo?.nome_fornecedor,
            dadosTitulo?.id_fornecedor
          )}
          horizontal
        />
        <FixedField
          label="Número/Parcela"
          value={`${dadosTitulo?.numero}-${dadosTitulo?.sequenc}`}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField label="Emissão" value={dadosTitulo?.emissao} horizontal />
        <FixedField
          label="Vencimento"
          value={dadosTitulo?.vencimento}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField
          label="Multa (+)"
          value={formatNumber(dadosTitulo?.vlr_multa, true, 2)}
          horizontal
        />
        <FixedField
          label="Juros (+)"
          value={formatNumber(dadosTitulo?.vlr_juro, true, 2)}
          horizontal
        />
        <FixedField
          label="Outros (+)"
          value={formatNumber(dadosTitulo?.vlr_outr_ent, true, 2)}
          horizontal
        />
        <FixedField
          label="Desconto (-)"
          value={formatNumber(dadosTitulo?.vlr_desc, true, 2)}
          horizontal
        />
        <FixedField
          label="Outros (-)"
          value={formatNumber(dadosTitulo?.vlr_outr_sai, true, 2)}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField
          label="Valor Original"
          value={formatNumber(dadosTitulo?.vlr_orig, true, 2)}
          horizontal
        />
        <FixedField
          label="Valor Já Pago"
          value={formatNumber(dadosTitulo?.vlr_pago, true, 2)}
          horizontal
        />
        <FixedField
          label="Valor a Pagar"
          value={formatNumber(dadosTitulo?.vlr_pagar, true, 2)}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField label="Observação" value={dadosTitulo?.observ} horizontal />
      </Row>
      <Table
        data={dadosTitulo?.movs_plano_conta ?? []}
        columns={columnsMovsPlanoConta}
        showRegisterCount={false}
        pageSize={
          (dadosTitulo?.movs_plano_conta ?? []) > 5
            ? 5
            : (dadosTitulo?.movs_plano_conta ?? []).length
        }
        paginated={false}
      />
      <hr />
      <Row>
        <RadioGroup label="Natureza" value={natureza} onChange={setNatureza}>
          <RadioItem label="Acréscimo" value="ACRE" />
          <RadioItem label="Decréscimo" value="DECR" />
        </RadioGroup>
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          label="Plano Contas"
          mode="plano_contas"
          value={idPlanoContas}
          onChange={setIdPlanoContas}
        />
      </Row>
      {utilizaCentroCusto && (
        <Row>
          <PesqPlanoCtaCentroCusto
            md={8}
            label="Centro Custo"
            mode="centro_custo"
            value={idCentroCusto}
            onChange={setIdCentroCusto}
          />
        </Row>
      )}
      <Row>
        <TextInput
          md={12}
          label="Histórico"
          value={historico}
          onChange={(e, v) => setHistorico(v)}
        />
      </Row>
      <Row>
        <NumberInput md={2} label="Valor" value={valor} onChange={setValor} />
        <FormButton
          md="auto"
          color="success"
          onClick={handleAddLancamento}
          loading={loadingAddLancamento}
        >
          F9 - Confirmar
        </FormButton>
      </Row>
      <hr />
      <Table
        data={dadosTitulo?.transacoes ?? []}
        columns={columnsLancamentos}
        showRegisterCount={false}
        pageSize={
          (dadosTitulo?.transacoes ?? []) > 5
            ? 5
            : (dadosTitulo?.transacoes ?? []).length
        }
        paginated={false}
      />
    </ModalBase>
  );
};
