import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { ClienteExcecaoInadBoletoGrid } from "./components/ClienteExcecaoInadBoletoGrid";
import ClienteExcecaoInadBoletoService from "../../../../services/cadastro/ClienteExcecaoInadBoletoService";
import { IncluirExcecaoInadModal } from "./components/IncluirExcecaoInadModal";

export const ClienteExcecaoInadBoleto = () => {
  const [idCliente, setIdCliente] = useState(null);
  const [idRede, setIdRede] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);

  const carregarDados = async () => {
    const params = {
      id_cliente: idCliente,
      id_rede: idRede,
    };
    setLoading(true);
    const [ok, ret] = await ClienteExcecaoInadBoletoService.listar(params);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const excluir = async (idExcecao) => {
    const [ok] = await ClienteExcecaoInadBoletoService.excluir(idExcecao);
    if (ok) {
      carregarDados();
    }
  };

  const alterarExcecao = async (row, v) => {
    const payload = {
      id_excecao: row.id,
      num_dias_bloqueio: v,
    };
    await ClienteExcecaoInadBoletoService.alterar(payload);
    carregarDados();
  };

  const alterarInterno = (dataField, novoValor, row) => {
    setDados(
      dados.map((e) =>
        e.id === row.id ? { ...row, num_dias_bloqueio: novoValor } : e
      )
    );
  };

  return (
    <PageContainer title="Dias de Exceção de Inadimplência" number="0113">
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            label="Cliente"
            concatModelName="cliente"
            onChange={(s) => setIdCliente(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={3}
            label="Rede"
            concatModelName="rede_cliente"
            onChange={(s) => setIdRede(s?.value)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <IncluirExcecaoInadModal notifyEvent={carregarDados} />
        </Row>
      </Card>
      <Card body>
        <ClienteExcecaoInadBoletoGrid
          dados={dados}
          excluir={excluir}
          alterarExcecao={alterarExcecao}
          alterarInterno={alterarInterno}
        />
      </Card>
    </PageContainer>
  );
};
