import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import { TextInput } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { modalTitleListaPrecos, routesBaseListaPrecos } from "../ListaPrecos";
import { ModalCadastroV2 } from "../../../../../components/cadastro";

export const CadastroListaPrecosModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [descricao, setDescricao] = useState("");

  const limparDados = () => {
    setAtivo(true);
    setDescricao("");
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setAtivo(data.ativo);
  };

  const submitPayload = (action) => {
    if (descricao.length <= 0) {
      toastr.warning("Atenção", "Por favor, informe a descrição.");
      return false;
    }

    let data = {
      descricao: descricao,
    };

    if (action === MODAL_ACTIONS.EDIT) {
      data = {
        ...data,
        id_lista_preco: selected,
        ativo: ativo,
      };
    }
    return data;
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleListaPrecos}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseListaPrecos}
      number="0013_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row form>
        <TextInput
          label="Descrição"
          md={12}
          onChange={(e, v) => setDescricao(v)}
          value={descricao}
          maxLength={150}
          autoFocus
        />
      </Row>
    </ModalCadastroV2>
  );
};
