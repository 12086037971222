import React from "react";
import { Row } from "reactstrap";
import { ComboBox, FormCheckbox, RadioGroup } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  setMarcaModelo,
  setControleFluxo,
  setParidade,
  setBitsParada,
  setBitsDados,
  setVelocidadePorta,
  setPorta,
  setModuloBalancaAtivado,
  setTemBuffetLivre,
} from "../store/balanca_slice";
import { RadioItem } from "../../../../components/RadioGroup";

const marcaModeloOptions = [
  { label: "Urano", value: "URANO" },
  { label: "Urano POP-S", value: "URANO-POP" },
  { label: "Toledo Prix 8217", value: "PRIX-8217" },
];

const controleFluxoOptions = [
  { label: "Nenhum", value: "NENHUM" },
  { label: "XON-XOFF", value: "XON-XOFF" },
  { label: "RTS-CTS", value: "RTS-CTS" },
  { label: "DTR-DSR", value: "DTR-DSR" },
];

const paridadeOptions = [
  { label: "Nenhuma", value: "NENHUMA" },
  { label: "ODD", value: "ODD" },
  { label: "EVEN", value: "EVEN" },
  { label: "MARK", value: "MARK" },
  { label: "SPACE", value: "SPACE" },
];

const bitsDadosOptions = [
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
];

const velocidadePortaOptions = [
  { label: "110", value: 110 },
  { label: "300", value: 300 },
  { label: "600", value: 600 },
  { label: "1200", value: 1200 },
  { label: "2400", value: 2400 },
  { label: "4800", value: 4800 },
  { label: "9600", value: 9600 },
  { label: "14400", value: 14400 },
  { label: "19200", value: 19200 },
  { label: "38400", value: 38400 },
  { label: "56000", value: 56000 },
  { label: "57600", value: 57600 },
];

const portaOptions = [
  { label: "COM1", value: "COM1" },
  { label: "COM2", value: "COM2" },
  { label: "COM3", value: "COM3" },
  { label: "COM4", value: "COM4" },
  { label: "COM5", value: "COM5" },
  { label: "COM6", value: "COM6" },
  { label: "COM7", value: "COM7" },
  { label: "COM8", value: "COM8" },
  { label: "COM9", value: "COM9" },
  { label: "COM10", value: "COM10" },
  { label: "COM11", value: "COM11" },
  { label: "COM12", value: "COM12" },
  { label: "COM13", value: "COM13" },
  { label: "COM14", value: "COM14" },
  { label: "COM15", value: "COM15" },
  { label: "COM16", value: "COM16" },
  { label: "COM17", value: "COM17" },
  { label: "COM18", value: "COM18" },
  { label: "COM19", value: "COM19" },
  { label: "COM20", value: "COM20" },
];

export const PersonBalanca = () => {
  const store = useSelector((state) => state.balanca);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Módulo Ativado"
          checked={store.modulo_balanca_ativado}
          onChange={() =>
            dispatch(setModuloBalancaAtivado(!store.modulo_balanca_ativado))
          }
        />
        <span style={{ lineHeight: 1.7 }}>
          É necessário ativar este parâmetro para o código de barras da comanda
          ser reconhecido no PDV
        </span>
      </Row>
      <Row>
        <ComboBox
          md={2}
          label="Marca/Modelo"
          options={marcaModeloOptions}
          defaultValue={store.marca_modelo}
          onChange={(s) => dispatch(setMarcaModelo(s?.value))}
        />
      </Row>
      <Row>
        <ComboBox
          md={2}
          mdInput={8}
          label="Controle de Fluxo"
          options={controleFluxoOptions}
          defaultValue={store.controle_fluxo}
          onChange={(s) => dispatch(setControleFluxo(s?.value))}
        />
      </Row>
      <Row>
        <ComboBox
          md={2}
          mdInput={8}
          label="Paridade"
          options={paridadeOptions}
          defaultValue={store.paridade}
          onChange={(s) => dispatch(setParidade(s?.value))}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Bits de Parada"
          value={store.bits_parada}
          onChange={(v) => dispatch(setBitsParada(v))}
        >
          <RadioItem label="1 Segundo" value="s1" />
          <RadioItem label="1 Segundo e Meio" value="s1.5" />
          <RadioItem label="2 Segundos" value="s2" />
        </RadioGroup>
      </Row>
      <Row>
        <ComboBox
          md={2}
          mdInput={6}
          label="Bits de Dados"
          options={bitsDadosOptions}
          defaultValue={store.bits_dados}
          onChange={(s) => dispatch(setBitsDados(s?.value))}
        />
      </Row>
      <Row>
        <ComboBox
          md={2}
          mdInput={8}
          label="Velocidade da Porta"
          options={velocidadePortaOptions}
          defaultValue={store.velocidade_porta}
          onChange={(s) => dispatch(setVelocidadePorta(s?.value))}
        />
      </Row>
      <Row>
        <ComboBox
          md={2}
          mdInput={8}
          label="Porta"
          options={portaOptions}
          defaultValue={store.porta}
          onChange={(s) => dispatch(setPorta(s?.value))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Tem Buffet Livre"
          checked={store.tem_buffet_livre}
          onChange={() => dispatch(setTemBuffetLivre(!store.tem_buffet_livre))}
        />
      </Row>
    </>
  );
};
