import React from "react";
import { Card, Row } from "reactstrap";
import { FormButton, Table } from "../../../../../../components";
import { naturalSort } from "../../../../../../coreUtils";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "nome",
    text: "Nome",
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    dataField: "id_grupo__nome",
    text: "Grupo",
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    dataField: "id_fabricante__nome",
    text: "Fabricante",
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
];

export const ProdutosAddGrid = ({
  data,
  addProdutos,
  selected,
  onSelect,
  onSelectAll,
  loadingAdd,
}) => {
  return (
    <Card body>
      <Row className="mb-1">
        <strong className="mt-1 ml-3">ADICIONAR PRODUTOS</strong>
        <FormButton
          md="auto"
          color="success"
          onClick={addProdutos}
          padded={false}
          disabled={selected?.length === 0}
          disabledHint="Selecione um ou mais produtos da lista"
          divClassName="ml-auto"
          loading={loadingAdd}
        >
          Adicionar
        </FormButton>
      </Row>
      <Table
        data={data}
        columns={columns}
        multiselect
        selected={selected}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        growIntoPageSize
        pageSize={10}
      />
    </Card>
  );
};
