import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { naturalSort } from "../../../../../coreUtils";

export const ClasseGrid = ({ alterar, excluir, data, setSelected }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "diferenciar_pesq_prod",
      text: "Diferenciar Pesquisa Produto",
      align: "center",
      headerAlign: "center",
      sortable: true,
      formatter: (c) => <TableCheck value={c} />,
    },
    {
        dataField: "prioridade_pesq_impr",
        text: "Prioridade Pesquisa/Impressão",
        align: "center",
        headerAlign: "center",
        sortable: true,
      },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      sortable: true,
      formatter: (c) => <TableCheck value={c} />,
    },

    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];
  return <Table data={data} columns={columns} onSelect={setSelected} />;
};
