import "moment/locale/pt-br";
import React, { useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { HelpCircle } from "react-feather";
import {
  Card,
  Col,
  Fade,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import {
  FixedTable,
  FormButton,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { formatDateLocal, parseRound } from "../../../../coreUtils";
import FluxoDeCaixaService from "../../../../services/FluxoDeCaixaService";
import columns from "./columns";

export const FluxoDeCaixa = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date(Date.now() + 15 * 86400000)); // 15 dias para frente
  const [diasFluxo, setDiasFluxo] = useState([]);
  const [totaisFluxo, setTotaisFluxo] = useState([]);
  const [totaisFinais, setTotaisFinais] = useState({});
  const [considerar_saldo_ini, setConsiderar_saldo_ini] = useState(true);

  const loadData = async () => {
    setIsLoading(true);
    /* obtem os dados de receita e despesas da api */
    let rows = await FluxoDeCaixaService.fetch(
      dataInicio,
      dataFim,
      considerar_saldo_ini
    );

    if (rows) {
      let { dias, totais, totaisFinais } = rows;

      setDiasFluxo(dias);
      setTotaisFluxo(totais);
      setTotaisFinais(totaisFinais);

      if (totais) {
        columns.forEach((element) => {
          if (element.dataField !== "data") {
            element.footer = totais[element.dataField];
            if (totais[element.dataField] !== undefined) {
              element.footerStyle = {
                color:
                  parseRound(totais[element.dataField], 2) < 0
                    ? "#f44455"
                    : "#5fc27e",
                fontWeight: "bold",
              };
            }
          }
        });
      }

      if (dias.length > 0) {
        columns[1].headerFormatter = (column) => {
          const dIni = new Date(dataInicio);
          return (
            <>
              {column.text}
              {"  "}
              <HelpCircle size={15} id="HelpCircleCaixaADM" />
              <UncontrolledTooltip
                placement="right"
                target="HelpCircleCaixaADM"
              >
                O Saldo Inicial representa o saldo (entradas - saídas) em
                dinheiro do último Caixa ADM do dia {formatDateLocal(dIni)}
              </UncontrolledTooltip>
            </>
          );
        };
      }
    }
    setIsLoading(false);
  };

  return (
    <PageContainer title="Fluxo de Caixa" number="5010" canGoBack>
      <Card body>
        <Row>
          <Col md={2}>
            <FormGroup className="text-center">
              <Label>Data Inicial</Label>
              <DayPickerInput
                placeholder=""
                inputProps={{
                  name: "dataInicio",
                  autoComplete: "off",
                  className: "form-control text-center",
                  disabled: true,
                }}
                value={dataInicio}
                formatDate={formatDate}
                parseDate={parseDate}
                onDayChange={(v) => setDataInicio(v ?? null)}
                dayPickerProps={{
                  locale: "pt-BR",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </FormGroup>
          </Col>

          <Col md={2}>
            <FormGroup className="text-center">
              <Label>Data Final</Label>
              <DayPickerInput
                placeholder=""
                inputProps={{
                  name: "dataFim",
                  autoComplete: "off",
                  className: "form-control text-center",
                }}
                value={dataFim}
                formatDate={formatDate}
                parseDate={parseDate}
                onDayChange={(v) => setDataFim(v ?? null)}
                dayPickerProps={{
                  locale: "pt-BR",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </FormGroup>
          </Col>
          <FormCheckbox
            name="considerar_saldo_ini"
            checked={considerar_saldo_ini}
            onChange={() => setConsiderar_saldo_ini(!considerar_saldo_ini)}
            label="Considerar Saldo Inicial"
          />
          <FormButton
            color="secondary"
            disabled={!dataInicio || !dataFim}
            onClick={loadData}
            loading={isLoading}
            disabledHint="Informe a data final"
          >
            Carregar Valores
          </FormButton>
        </Row>
      </Card>
      {diasFluxo.length > 0 && (
        <Fade in={true}>
          <Row>
            <Col md="5">
              <Card body>
                <Row style={{ justifyContent: "end" }}>
                  <Col md="12" style={{ textAlign: "right" }}>
                    Saldo em Dinheiro dos Caixas Loja Abertos Agora:
                    <span className="ml-2" style={{ fontWeight: "bold" }}>
                      {totaisFluxo["caixa_loja"]}
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Card body>
            <FixedTable
              style={{ maxHeight: "330px" }}
              data={diasFluxo}
              keyField={"data"}
              columns={columns}
              striped
              rowStyle={(row) => {
                if (row.data === "Saldo Inicial") {
                  return { backgroundColor: "#ccc", fontWeight: "bold" };
                }
                return {};
              }}
            />
          </Card>

          <Row style={{ justifyContent: "end" }}>
            <Col md="5">
              <Card body>
                {totaisFinais.locacao !== "0,00" && (
                  <Row style={{ justifyContent: "end" }} className="mb-2">
                    <Col md="9" style={{ textAlign: "right" }}>
                      Locações sem dados financeiros lançados:
                    </Col>
                    <Col md="3" style={{ textAlign: "right" }}>
                      <span className="ml-2" style={{ fontWeight: "bold" }}>
                        {totaisFinais.locacao}
                      </span>
                    </Col>
                  </Row>
                )}
                <Row style={{ justifyContent: "end" }} className="mb-2">
                  <Col md="9" style={{ textAlign: "right" }}>
                    Resultado do período (desconsiderando saldo inicial):
                  </Col>
                  <Col md="3" style={{ textAlign: "right" }}>
                    <span className="ml-2" style={{ fontWeight: "bold" }}>
                      {totaisFinais.resultado}
                    </span>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "end" }}>
                  <Col md="9" style={{ textAlign: "right" }}>
                    Total geral:
                  </Col>
                  <Col md="3" style={{ textAlign: "right" }}>
                    <span className="ml-2" style={{ fontWeight: "bold" }}>
                      {totaisFinais.totalGeral}
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Fade>
      )}
    </PageContainer>
  );
};
