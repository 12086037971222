import React from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormButton,
  NumberInput,
  PageContainer,
  RadioGroup,
} from "../../../../../components";
import { Card, Row } from "reactstrap";
import { useState } from "react";
import CadastroRegraPromocaoService from "../../../../../services/cadastro/CadastroRegraPromocaoService";
import { useEffect } from "react";
import { ItensAddGrid } from "./components/ItensAddGrid";
import { roundFloat, useStateWithRef } from "../../../../../coreUtils";
import { ItensRegraGrid } from "./components/ItensRegraGrid";
import { RadioItem } from "../../../../../components/RadioGroup";

export const ItensRegraPromocao = ({ location }) => {
  const selected = location?.state?.selected;

  const [idProduto, setIdProduto] = useState(null);
  const [idGrupo, setIdGrupo] = useState(null);
  const [idFabricante, setIdFabricante] = useState(null);
  const [tipoDescAdd, setTipoDescAdd] = useState("PER");
  const [percDescPrecoVendaAdd, setPercDescPrecoVendaAdd] = useState(null);
  const [itensAdd, setitensAdd] = useState([]);
  const [itensRegra, setitensRegra] = useState([]);
  const [selectedAdd, setSelectedAdd, selectedAddRef] = useStateWithRef([]);
  const [loading, setLoading] = useState(true);
  const [loadingItensAdd, setLoadingItensAdd] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const carregarDados = async () => {
    const [ok, ret] = await CadastroRegraPromocaoService.buscarItens(selected);
    setitensRegra(ok ? ret : []);
  };

  const iniciarTela = async () => {
    await carregarDados();
    setLoading(false);
  };

  const handleSetTipoDescAdd = (v) => {
    setTipoDescAdd(v);
    setPercDescPrecoVendaAdd(null);
    setitensAdd(
      itensAdd.map((e) => ({
        ...e,
        preco_venda_novo: e.preco_venda,
        perc_desc: 0,
      }))
    );
  };

  const handleSetPercDescPrecoVendaGeral = (v) => {
    setPercDescPrecoVendaAdd(v);
    setitensAdd(
      itensAdd.map((row) => {
        if (tipoDescAdd === "PER") {
          let precoVendaNovo = roundFloat(
            row.preco_venda * ((100 - v) / 100),
            2
          );
          if (precoVendaNovo < 0) precoVendaNovo = 0;

          row.perc_desc = v;
          row.preco_venda_novo = precoVendaNovo;
        } else {
          if (v < row.preco_venda) {
            let percDescNovo = roundFloat(
              ((row.preco_venda - v) / row.preco_venda) * 100,
              2
            );

            row.perc_desc = percDescNovo;
          } else {
            row.perc_desc = 0;
          }

          row.preco_venda_novo = v;
        }
        return row;
      })
    );
  };

  const handleSelectItemAdd = (v, is) => {
    setSelectedAdd(
      is
        ? [...selectedAddRef.current, v]
        : selectedAddRef.current.filter((e) => e !== v)
    );
  };

  const onSelectAllAdd = (is) =>
    setSelectedAdd(is ? itensAdd.map((e) => e.id) : []);

  const selecionarItemAoAlterar = (id, condicao) => {
    if (condicao) {
      if (!selectedAddRef.current.includes(id)) {
        handleSelectItemAdd(id, true);
      }
    } else {
      if (selectedAddRef.current.includes(id)) {
        handleSelectItemAdd(id, false);
      }
    }
  };

  const recalcularValoresGrade = (coluna, novoValor, row) => {
    if (coluna === "perc_desc") {
      const percDescNovo = parseFloat(novoValor);
      selecionarItemAoAlterar(row.id, percDescNovo !== 0);

      let precoVendaNovo = roundFloat(
        row.preco_venda * ((100 - percDescNovo) / 100),
        2
      );
      if (precoVendaNovo < 0) precoVendaNovo = 0;

      row.perc_desc = percDescNovo;
      row.preco_venda_novo = precoVendaNovo;
    } else if (coluna === "preco_venda_novo") {
      const precoVendaNovo = parseFloat(novoValor);
      selecionarItemAoAlterar(row.id, precoVendaNovo !== row.preco_venda);

      if (precoVendaNovo < row.preco_venda) {
        let percDescNovo = roundFloat(
          ((row.preco_venda - precoVendaNovo) / row.preco_venda) * 100,
          2
        );
        row.perc_desc = percDescNovo;
      } else {
        row.perc_desc = 0;
      }

      row.preco_venda_novo = precoVendaNovo;
    }
    setitensAdd(itensAdd.map((e) => (e.id === row.id ? row : e)));
  };

  const carregarItensAdd = async () => {
    const params = {
      id_produto: idProduto,
      id_grupo: idGrupo,
      id_fabricante: idFabricante,
    };
    setSelectedAdd([]);
    setLoadingItensAdd(true);
    const [ok, ret] = await CadastroRegraPromocaoService.listarProdsSelecao(
      selected,
      params
    );
    setitensAdd(
      ok
        ? ret.map((e) => ({
            ...e,
            preco_venda: parseFloat(e.preco_venda),
            perc_desc: 0,
            preco_venda_novo: parseFloat(e.preco_venda),
          }))
        : []
    );
    setLoadingItensAdd(false);
  };

  const handleAddItens = async () => {
    const payload = {
      id_regra_cab: selected,
      ids_itens: itensAdd
        .filter((e) => selectedAdd.includes(e.id))
        .map((e) => ({
          id_item_regra: e.id,
          tipo_desconto: tipoDescAdd,
          perc_desconto: e.perc_desc,
          preco_venda: e.preco_venda_novo,
        })),
    };
    setLoadingAdd(true);
    const [ok] = await CadastroRegraPromocaoService.itens.incluir(payload);
    if (ok) {
      setPercDescPrecoVendaAdd(null);
      carregarItensAdd();
      carregarDados();
    }
    setLoadingAdd(false);
  };

  const handleDeleteItem = async (idItem) => {
    const [ok] = await CadastroRegraPromocaoService.itens.excluir(idItem);
    if (ok) {
      carregarDados();
    }
  };

  const onActivate = () => {
    iniciarTela();
  };
  useEffect(onActivate, []);

  return (
    <PageContainer
      title={`Itens da Regra de Promoção Nº ${selected}`}
      number="0078_3"
      canGoBack
      loading={loading}
    >
      <Card body>
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="produto"
            isSearchable
            md={4}
            label="Produto"
            defaultOptions
            onChange={(s) => setIdProduto(s?.value)}
          />
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="grupo"
            isSearchable
            md={3}
            label="Grupo"
            defaultOptions
            onChange={(s) => setIdGrupo(s?.value)}
          />
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="fabricante"
            isSearchable
            md={3}
            label="Fabricante"
            defaultOptions
            onChange={(s) => setIdFabricante(s?.value)}
          />
          <BotaoPesquisar
            loading={loadingItensAdd}
            onClick={carregarItensAdd}
          />
        </Row>
      </Card>
      <Card body>
        <Row>
          <strong className="mt-1 ml-3">ADICIONAR PRODUTOS À REGRA</strong>
          <RadioGroup
            label="Tipo de Desconto"
            value={tipoDescAdd}
            onChange={handleSetTipoDescAdd}
            divClassName="ml-auto"
          >
            <RadioItem label="Percentual" value="PER" />
            <RadioItem label="Valor" value="VAL" />
          </RadioGroup>
          <NumberInput
            md={2}
            label={tipoDescAdd === "PER" ? "% Desconto" : "Preço de Venda"}
            value={percDescPrecoVendaAdd}
            onChange={handleSetPercDescPrecoVendaGeral}
          />
          <FormButton
            md="auto"
            color="success"
            onClick={handleAddItens}
            disabled={selectedAdd?.length === 0}
            disabledHint="Selecione um ou mais produtos da lista"
            loading={loadingAdd}
          >
            Adicionar
          </FormButton>
        </Row>
        <ItensAddGrid
          dados={itensAdd}
          onSelectAllAdd={onSelectAllAdd}
          handleSelectItemAdd={handleSelectItemAdd}
          tipoDescAdd={tipoDescAdd}
          recalcularValoresGrade={recalcularValoresGrade}
          selected={selectedAdd}
        />
      </Card>
      <Card body>
        <Row>
          <strong className="mt-1 ml-3">ITENS DA REGRA</strong>
        </Row>
        <ItensRegraGrid
          dados={itensRegra}
          handleDeleteItem={handleDeleteItem}
        />
      </Card>
    </PageContainer>
  );
};
