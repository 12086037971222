import React, { useState } from "react";
import ManutencaoEstoqueService from "../../../../../services/cadastro/ManutencaoEstoqueService";
import {
  BotaoPesquisar,
  FiltroPeriodoDatas,
  ModalBase,
  Table,
} from "../../../../../components";
import { Row } from "reactstrap";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const columns = [
  { dataField: "data_hora_mov", text: "Data/Hora", align: "center" },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
  },
  {
    dataField: "desc_origem",
    text: "Origem",
    align: "left",
    formatter: (c, row) =>
      ![0, null, undefined].includes(row.id_origem)
        ? `${c} ${row.id_origem}`
        : c,
  },
  { dataField: "histor", text: "Histórico", align: "left" },
  {
    dataField: "quantidade_antes",
    text: "Qtd. Antes",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "quantidade_ajuste",
    text: "Ajuste",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "quantidade_depois",
    text: "Qtd. Depois",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
];

export const MovimentacoesProdutoModal = ({ isOpen, toggle, selected }) => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setDataIni(new Date());
    setDataFim(new Date());
    setDados([]);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
    };
    setLoading(true);
    const [ok, ret] = await ManutencaoEstoqueService.buscarMovimentacoes(
      selected,
      params
    );
    setDados(ok ? ret : []);
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Movimentações do Produto"
      number="0097_2"
      onClosed={limparDados}
      onBeforeOpen={carregarDados}
    >
      <Row className="mb-2">
        <FiltroPeriodoDatas onChange={handleDate} />
        <BotaoPesquisar onClick={carregarDados} loading={loading} />
      </Row>
      <Table data={dados} columns={columns} pageSize={10} paginated={false} />
    </ModalBase>
  );
};
