import React, { Component } from "react";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { AsyncComboBox, FixedTable, TextInput } from "../../../components";
import FiltersServiceV2 from "../../../services/FiltersServiceV2";

class TableRecarga extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_produto: null,
      quantidade: null,
    };
  }
  columns = [
    {
      dataField: "id",
      text: "#",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "nome",
      text: "Nome do Produto",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "qtd_atual",
      text: "Qtd Atual",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "qtd_carga",
      text: "Qtd Carga",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "saldo",
      text: "Saldo",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
  ];

  handleLoadProdutos = async (v) => {
    let filter = "";
    if (!isNaN(v)) {
      filter = `pk=${v.toUpperCase()}`;
    } else {
      filter = `${filter}nome__icontains=${v.toUpperCase()}`;
    }
    return await FiltersServiceV2.fetch("produto", filter);
  };

  handleSelectProduto = (selected) => {
    const value = selected?.value;
    this.setState({ id_produto: value });
  };

  handleInputQuantidade = (e) => {
    this.setState({ quantidade: e.target.value });
  };

  render() {
    const data = [];
    return (
      <React.Fragment>
        <Card>
          <CardBody className="pt-0">
            <Row form>
              <Col md={8}>
                <FormGroup className="d-flex p-0">
                  <Label style={{ marginTop: "1.7rem" }}>Produto:</Label>
                  <AsyncComboBox
                    onChange={this.handleSelectProduto}
                    loadOptions={this.handleLoadProdutos}
                    md={8}
                    isSearchable
                    isClearable
                  />
                  <Label className="ml-2" style={{ marginTop: "1.7rem" }}>
                    Quantidade:
                  </Label>
                  <TextInput md={2} onChange={this.handleInputQuantidade} />
                  <Button
                    color="success"
                    md={2}
                    className="ml-2"
                    style={{ height: "2rem", marginTop: "1.25rem" }}
                  >
                    Adicionar
                  </Button>
                </FormGroup>
              </Col>
            </Row>
            <FixedTable
              columns={this.columns}
              data={data}
              keyField="id"
              style={{ minHeight: "350px" }}
            />
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
export default TableRecarga;
