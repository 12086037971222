import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

export const OutrasOpcoes = () => {
  return (
    <UncontrolledDropdown direction="left">
      <DropdownToggle
        style={{
          background: "none",
          border: "none",
          margin: 0,
          padding: 0,
          cursor: "pointer",
        }}
      >
        <p
          style={{
            margin: 0,
            padding: 0,
            cursor: "pointer",
            opacity: 0.6,
            color: "black",
            fontSize: 11,
          }}
        >
          Outras Opções
        </p>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => {}}>Parâmetros</DropdownItem>
        <DropdownItem onClick={() => {}}>Status SEFAZ</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
