import { apiGetV2, apiPostV2 } from "../../../apiV2";

const URL_BASE = "/cadastro/desp_jornada";
const URL_BASE_VINCULACAO = `/jornada/vinc_despesa_rota_fornec`;

const DespesasJornadaService = {
  urlBase: URL_BASE,
  vincUrlBase: URL_BASE_VINCULACAO,
  listar: async () => await apiGetV2(`${URL_BASE}/listar/`),
  buscarVinculacoesDespesa: async (idDespesa) =>
    await apiGetV2(`${URL_BASE_VINCULACAO}/listar/${idDespesa}/`),
  incluirVinculacao: async (payload) =>
    await apiPostV2(`${URL_BASE_VINCULACAO}/incluir/`, payload),
};

export default DespesasJornadaService;
