import React from "react";
import { Table } from "../../../../../components";
import { iconeAlterar } from "../../../../../components/cadastro";

export const NumeracaoNfeGrid = ({ data, setSelected, alterar }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "serie",
      text: "Série",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "numero_ult_nfe",
      text: "Últ. Número de NFe",
      align: "center",
      headerAlign: "center",
    },
    iconeAlterar(alterar),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
