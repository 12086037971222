import React from "react";
import { ModalDetalhes } from "../../../../../components";
import { formatarValor } from "../../../../../coreUtils";

const ItensVendaDesempenhoVendasMetaLucroModal = ({ idVendaCab, itens }) => {
  const columns = [
    {
      dataField: "item",
      text: "It",
      align: "left",
    },
    {
      dataField: "produto",
      text: "# Produto",
      align: "left",
    },
    {
      dataField: "descricao",
      text: "Nome",
      align: "left",
    },
    {
      dataField: "quantidade",
      text: "Qtde",
      align: "center",
      formatter: (c) => formatarValor(c, 2, 0, true),
    },
    {
      dataField: "vlr_custo_unit",
      text: "Custo Unit",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "vlr_unit",
      text: "Vlr Unit",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "vlr_item",
      text: "Vlr Item",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "perc_desc",
      text: "% Desc",
      align: "center",
      formatter: (c) => `${formatarValor(c, 2, 0, true)}%`,
    },
    {
      dataField: "vlr_desc",
      text: "Desc",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "vlr_acre",
      text: "Acre",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "vlr_tot_prod",
      text: "Tot Prod",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "perc_lucro_gerado",
      text: "% Lucro",
      align: "center",
      formatter: (c) => `${formatarValor(c, 2, 0, true)}%`,
    },
    {
      dataField: "vlr_lucro_gerado",
      text: "Vlr Lucro",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
  ];
  return (
    <ModalDetalhes
      title={`Detalhamento da Venda ${idVendaCab}`}
      data={itens}
      columns={columns}
      size="xl"
    />
  );
};

export default ItensVendaDesempenhoVendasMetaLucroModal;
