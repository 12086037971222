import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { relOutrosVendasExcetoGruposRoute } from "../../../routes/modules/relatorios";
import { TbLayoutGridRemove } from "react-icons/tb";

export const RelOutros = () => {
  return (
    <PageContainer title="Outros Relatórios">
      <MenuGroup>
        <MenuGroupButton
          label="Vendas Exceto Grupos"
          icon={TbLayoutGridRemove}
          pathname={relOutrosVendasExcetoGruposRoute.path}
          nroTela={relOutrosVendasExcetoGruposRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
