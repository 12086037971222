import React, { useCallback, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";
import { api, updateAPI } from "../api";
import { useSession } from "../auth/session";
import ScrollToTop from "../components/ScrollToTop";
import { connect, useDispatch, useSelector } from "react-redux";
import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import routes, {
  checkRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes,
} from "./index";
import {
  SET_MENU_CURRENT_ROUTES,
  SET_MENU_ALLOWED_ROUTES,
  SET_MENUS,
  SET_AUTH_IS_LOADING,
  SET_AUTH_IS_AUTHENTICATED,
} from "../redux/constants";
import { updateAPIV2 } from "../apiV2";
import { configuracoesRoutes } from "./modules";
import { ContextMenu } from "../redux/reducers/menuReducer";
import settings from "../settings";
import { isDevEnv } from "../coreUtils";

const renderChildRoutes = (routes) => {
  return routes.map((route, index) => {
    if (route.children) {
      return renderChildRoutes(route.children);
    } else {
      return (
        <Route
          key={index}
          path={route.path}
          exact
          component={route.component}
        />
      );
    }
  });
};

const findUrls = (routes, permissions) => {
  routes.forEach((route) => {
    if (route.children) {
      findUrls(route.children, permissions);
    } else {
      const permIndex = permissions
        .map((e) => e.nroTela)
        .indexOf(route.nroTela);
      if (permIndex >= 0) {
        permissions[permIndex].path = route.path;
      }
    }
  });
};

const ChildRoutes = ({ layout: Layout, routes }) => (
  <Layout>
    <Switch>{renderChildRoutes(routes)}</Switch>
  </Layout>
);

const Routes = ({ allowedRoutes, menusDispatch }) => {
  const state = useSelector((state) => state.auth);
  const isLoading = state.isLoading;
  const isAuthenticated = state.isAuthenticated;
  const session = useSession();
  const dispatch = useDispatch();

  const _authenticate = useCallback(async () => {
    try {
      if (session.accessToken) {
        const response = await api({
          url: "buscar_configs_usuario/",
          baseURL: session.apiAddress,
          headers: {
            Authorization: `Bearer ${session.accessToken}`,
          },
        })
          .then((res) => {
            if (!res.data.success) toastr.error("Erro", res.data.msg);
            return res;
          })
          .catch((err) => {
            dispatch({ type: SET_AUTH_IS_AUTHENTICATED, data: false });
            if (!err.response) {
              toastr.error(
                "Erro de conexão",
                "Não foi possível conectar à API."
              );
            } else {
              toastr.error("Erro ao autenticar", err.message);
            }
          });
        dispatch({
          type: SET_AUTH_IS_AUTHENTICATED,
          data: response?.data.success ?? false,
        });
        if (response?.data.success) {
          const res = response.data.res;
          localStorage.setItem("licenca", res.licenca);
          localStorage.setItem("email_proprietario", res.email_proprietario);
          localStorage.setItem("id_colaborador", res.id_colaborador);
          localStorage.setItem("perfil", res.perfil);
          localStorage.setItem("sigla_empresa", res.sigla_empresa);
          localStorage.setItem("master", res.master);
          localStorage.setItem(
            "ver_totais_consultas",
            res.ver_totais_consultas
          );
          localStorage.setItem("id_empresa", res.id_empresa);
          localStorage.setItem("cnpj_empresa", res.cnpj_empresa);
          localStorage.setItem("nome_empresa", res.nome_empresa);
          localStorage.setItem(
            "nome_fant_empresa",
            res.nome_fant_empresa ?? response.data.nome_empresa
          );
          localStorage.setItem("ender_empresa", res.ender_empresa);
          localStorage.setItem("uf_empresa", res.uf_empresa);
          localStorage.setItem("cidade_empresa", res.cidade_empresa);
          localStorage.setItem("compl_empresa", res.compl_empresa);
          localStorage.setItem("fone_empresa", res.fone_empresa);
          localStorage.setItem("fone2_empresa", res.fone2_empresa);
          localStorage.setItem("email_empresa", res.email_empresa);
          localStorage.setItem("cod_ibge_empresa", res.cod_ibge_empresa);
          localStorage.setItem(
            "utiliza_negociacao_financ ",
            res.utiliza_negociacao_financ
          );
          localStorage.setItem(
            "forma_geracao_pdf_web",
            res.forma_geracao_pdf_web
          );
          localStorage.setItem(
            "vis_histor_venda_compra",
            res.vis_histor_venda_compra
          );
          localStorage.setItem("vis_custo_pesq_prod", res.vis_custo_pesq_prod);
          localStorage.setItem("user_admin_skill", res.user_admin_skill);
          localStorage.setItem(
            "lado_coluna_selecao_grades",
            res.lado_coluna_selecao_grades
          );

          let permissions = [];
          api({
            url: `/cadastro/acessos/`,
            baseURL: session.apiAddress,
            headers: {
              Authorization: `Bearer ${session.accessToken}`,
              ...(isDevEnv() ? { "database-name": settings.DB_NAME_DEV } : {}),
            },
          }).then((response2) => {
            if (!response2.data.err) {
              permissions = response2.data.res;
              permissions = permissions.map((element) => ({
                nroTela: element["nro_tela"],
                excecao: element["excecao"],
              }));

              findUrls(dashboardRoutes, permissions);
              menusDispatch({ type: SET_MENUS, data: permissions });

              const allowedRoutes = checkRoutes(dashboardRoutes, permissions);
              menusDispatch({
                type: SET_MENU_ALLOWED_ROUTES,
                data: [...allowedRoutes, ...configuracoesRoutes],
              });

              menusDispatch({
                type: SET_MENU_CURRENT_ROUTES,
                data: window.location.pathname.startsWith("/configuracoes")
                  ? configuracoesRoutes
                  : routes,
              });
            }
          });

          updateAPI(session.apiAddress, session.accessToken);
          updateAPIV2(session.apiAddress, session.accessToken);
        }
      }
    } finally {
      dispatch({ type: SET_AUTH_IS_LOADING, data: false });
    }
  }, [session.apiAddress, session.accessToken]);

  useEffect(() => {
    _authenticate();
  }, [_authenticate]);

  return (
    <Router>
      {isLoading ? (
        <Row className="justify-content-center h-100">
          <Col sm={1} md={1} lg={1} className="align-self-center">
            <Spinner color="secondary" className="mr-2" type="grow" />
          </Col>
        </Row>
      ) : isAuthenticated ? (
        <>
          <ScrollToTop>
            <ChildRoutes layout={DashboardLayout} routes={allowedRoutes} />
          </ScrollToTop>
          {window.location.pathname === "/acesso/login/" && (
            <Redirect push to="/" />
          )}
        </>
      ) : (
        <Switch>
          <Route
            path="/acesso/*"
            exact
            component={() => (
              <ChildRoutes layout={AuthLayout} routes={pageRoutes} />
            )}
          />
          <Redirect push to="/acesso/login" />
        </Switch>
      )}
    </Router>
  );
};

export default connect(
  (store) => ({ allowedRoutes: store.menuAllowedRoutes }),
  (dispatch) => ({ menusDispatch: dispatch }),
  null,
  {
    context: ContextMenu,
  }
)(Routes);
