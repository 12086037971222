import { apiGet, apiPut, urlParams } from "../../api";

const URL_BASE = "/venda/devolucao";

const DevolucaoVendaService = {
  listarVendas: async (params) =>
    await apiGet(`${URL_BASE}/listar_venda/${urlParams(params)}`),
  buscarVenda: async (id) => await apiGet(`${URL_BASE}/buscar_venda/${id}/`),
  buscarFormasRecVenda: async (id) =>
    await apiGet(`${URL_BASE}/buscar_formas_rec_venda/${id}/`),
  devolverVenda: async (payload) =>
    await apiPut(`${URL_BASE}/devolver_venda/`, payload),
};

export default DevolucaoVendaService;
