import React from "react";
import { useState } from "react";
import { Row } from "reactstrap";
import {
  FormCheckbox,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { CadastroUnidadeModal } from "../../../produtos/unidade/components/CadastroUnidadeModal";
import { modalTitleServico, routesBaseServico } from "../Servico";
import { ModalCadastroV2 } from "../../../../../components/cadastro";

export const CadastroServicoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [unidade, setUnidade] = useState("");
  const [valor, setValor] = useState(0);
  const [percDescMax, setPercDescMax] = useState(0);
  const [podeAltNome, setPodeAltNome] = useState(false);
  const [codCnae, setCodCnae] = useState("");
  const [codSped, setCodSped] = useState("");
  const [codigoTributMunic, setCodigoTributMunic] = useState("");

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setUnidade("");
    setValor(0);
    setPercDescMax(0);
    setPodeAltNome(false);
    setCodCnae("");
    setCodSped("");
    setCodigoTributMunic("");
  };

  const fetchData = (data) => {
    setAtivo(data.ativo);
    setNome(data.nome);
    setUnidade((data.unidade ?? "")?.trim());
    setValor(parseFloat(data.valor));
    setPercDescMax(parseFloat(data.perc_desc_max));
    setPodeAltNome(data.pode_alt_nome);
    setCodCnae(data.cod_cnae);
    setCodSped(data.cod_sped ?? "");
    setCodigoTributMunic(data.codigo_tribut_munic ?? "");
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(unidade)) {
      toastr.warning("Atenção", "Por favor, informe a unidade do serviço.");
      return false;
    }

    const payload = {
      nome: nome,
      unidade: unidade,
      valor: valor,
      perc_desc_max: percDescMax,
      pode_alt_nome: podeAltNome,
      cod_cnae: codCnae,
      cod_sped: codSped,
      codigo_tribut_munic: codigoTributMunic,
      ativo: ativo,
    };

    return action === MODAL_ACTIONS.ADD
      ? { importacao: false, id_cad_imp: 0, ...payload }
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleServico}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseServico}
      number="0062_1"
      selected={selected}
      notifyEvent={notifyEvent}
      concatShortcut={concatShortcut}
      concatModelName="servico"
    >
      <Row>
        <TextInput
          md={12}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={80}
        />
      </Row>
      <Row>
        <TextInput
          md={3}
          label="Unidade"
          value={unidade}
          onChange={(e, v) => setUnidade(v)}
          maxLength={3}
          additionalButton={
            <CadastroUnidadeModal
              selected={unidade}
              notifyEvent={(v) => setUnidade(v)}
              concatShortcut
            />
          }
        />
        <NumberInput md={3} label="Valor" value={valor} onChange={setValor} />
        <NumberInput
          md={3}
          label="Perc. Desc. Máx."
          value={percDescMax}
          onChange={setPercDescMax}
          isPercentage
        />
      </Row>
      <Row>
        <TextInput
          md={4}
          label="CNAE"
          value={codCnae}
          onChange={(e, v) => setCodCnae(v)}
          maxLength={15}
        />
        <TextInput
          md={4}
          label="SPED"
          value={codSped}
          onChange={(e, v) => setCodSped(v)}
          maxLength={10}
        />
        <TextInput
          md={4}
          label="Cód. de Tribut. no Município"
          value={codigoTributMunic}
          onChange={(e, v) => setCodigoTributMunic(v)}
          maxLength={15}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permite Alteração do Nome"
          checked={podeAltNome}
          onChange={() => setPodeAltNome(!podeAltNome)}
          name="pode-alt-nome"
        />
      </Row>
    </ModalCadastroV2>
  );
};
