import React from "react";
import { DataTable } from "../../../../../components/DataTable";

export const TabelaBaseVTS = ({ keyField, renderer, data, columns }) => {
  return (
    <DataTable
      data={data}
      columns={columns}
      readOnly
      keyField={keyField}
      hideCount
      striped={false}
      expandRow={
        renderer !== null
          ? {
              className: "selected-expanded-row",
              onlyOneExpanding: true,
              renderer: renderer,
            }
          : {}
      }
    />
  );
};
