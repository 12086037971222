import React from "react";
import { Table, TableCheck } from "../../../../../components";
import { naturalSort } from "../../../../../coreUtils";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  { dataField: "nome", text: "Produto", align: "left", sortable: true },
  {
    dataField: "id_grupo__nome",
    text: "Grupo",
    align: "left",
    sortable: true,
  },
  {
    dataField: "clas_fiscal",
    text: "NCM",
    align: "center",
    sortable: true,
  },
  {
    dataField: "ativo",
    text: "Ativo",
    align: "center",
    headerAlign: "center",
    formatter: (c) => <TableCheck value={c} />,
  },
];

export const ProdutosNcmInvalidaGrid = ({ dados }) => {
  return (
    <Table
      data={dados}
      columns={columns}
      showRegisterCount={false}
      paginated={false}
    />
  );
};
