import React from "react";
import { PageContainer } from "../../../../components/PageContainer";
import { Card, Row } from "reactstrap";
import { useState } from "react";
import {
  AsyncComboBox,
  BaseInfoCard,
  BotaoImprimir,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FormCheckbox,
  Table,
  TimeSeriesLineChart,
} from "../../../../components";
import {
  formatarValor,
  formatDateISO,
  userPodeVerTotaisRelVenda,
} from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";

const columnsDadosPorVendedor = [
  {
    dataField: "nome_vendedor",
    text: "Vendedor",
    align: "left",
  },
  {
    dataField: "vlr_custo",
    text: "Custo",
    align: "right",
    formatter: (c) => formatarValor(c, 2, 2, true),
  },
  {
    dataField: "vlr_desconto",
    text: "Desconto",
    align: "right",
    formatter: (c) => formatarValor(c, 2, 2, true),
  },
  {
    dataField: "vlr_acrescimo",
    text: "Acréscimo",
    align: "right",
    formatter: (c) => formatarValor(c, 2, 2, true),
  },
  {
    dataField: "vlr_venda",
    text: "Venda Sem Serv. Líquido",
    align: "right",
    formatter: (c) => formatarValor(c, 2, 2, true),
  },
  {
    dataField: "qtd_vendas",
    text: "Qtd Vendas",
    align: "center",
    formatter: (c) => formatarValor(c, 2, 0, true),
  },
  {
    dataField: "ticket_medio",
    text: "Ticket Médio",
    align: "right",
    formatter: (c) => formatarValor(c, 2, 2, true),
  },
  {
    dataField: "vlr_lucro",
    text: "Valor Lucro",
    align: "right",
    formatter: (c) => formatarValor(c, 2, 2, true),
  },
  {
    dataField: "perc_lucro",
    text: "Percentual Lucro",
    align: "center",
    formatter: (c) => `${formatarValor(c, 2, 2, true)}%`,
  },
];

export const ComissaoLucro = () => {
  const podeVerTotais = userPodeVerTotaisRelVenda();
  const idUsuario = parseInt(localStorage.getItem("id_colaborador"));
  const [vendedores, setVendedores] = useState([]);
  const [dadosGrafico, setDadosGrafico] = useState();
  const [dadosPorVendedor, setDadosPorVendedor] = useState();
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [incluirItens, setIncluirItens] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDados, setLoadingDados] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const handleSetVendedor = (selected) => setVendedores(selected ?? []);

  const buscarDados = async () => {
    setLoadingDados(true);
    try {
      const [ok, ret] = await apiGetV2(
        `/relatorios/comissao/por_lucro/dados_grafico/`,
        {
          data_ini: dataIni,
          data_fim: dataFim,
          id_vendedor: vendedores.map((e) => e.value),
        }
      );
      if (ok) {
        setDadosGrafico(ret);
      } else {
        setDadosGrafico({});
      }

      const [ok2, ret2] = await apiGetV2(
        `/relatorios/comissao/por_lucro/dados_por_vendedor/`,
        {
          data_ini: dataIni,
          data_fim: dataFim,
          id_vendedor: vendedores.map((e) => e.value),
        }
      );
      if (ok2) {
        setDadosPorVendedor(ret2);
      } else {
        setDadosPorVendedor([]);
      }
    } finally {
      setLoadingDados(false);
    }
  };

  const imprimir = async () => {
    setLoading(true);
    for (const vendedor of vendedores) {
      const params = {
        id_vendedor: vendedor.value,
        data_ini: dataIni,
        data_fim: dataFim,
      };

      const [ok, ret] = await apiGetV2(
        `/relatorios/comissao/por_lucro/listar/`,
        params
      );

      if (ok) {
        await docPrintReport(
          "/relatorios/comissao/por_lucro",
          {
            vendas: ret.vendas,
            data_ini: formatDateISO(dataIni),
            data_fim: formatDateISO(dataFim),
            totais: ret.totais,
            vendedor: vendedor.label,
            incluir_itens: incluirItens,
          },
          "5042"
        );
      }
    }
    setLoading(false);
  };

  const nenhumVendedorSelecionado = vendedores.length === 0;

  return (
    <PageContainer title="Comissão por Lucro" number="5042" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Vendedor"
            isConcatField
            concatModelName="colaborador"
            isSearchable
            isClearable
            onChange={handleSetVendedor}
            defaultOptions
            defaultValue={!podeVerTotais ? idUsuario : null}
            disabled={!podeVerTotais}
            isMulti={podeVerTotais}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <FormCheckbox
            label="Incluir Itens das Vendas"
            name="incluir_itens"
            checked={incluirItens}
            onChange={() => setIncluirItens(!incluirItens)}
          />
        </Row>
        <Row>
          <BotaoPesquisar
            onClick={buscarDados}
            loading={loadingDados}
            disabled={nenhumVendedorSelecionado}
            disabledHint={nenhumVendedorSelecionado && "Selecione o Vendedor"}
          />
          <BotaoImprimir
            onClick={imprimir}
            hint="Será gerado um arquivo .PDF para cada vendedor selecionado"
            loading={loading}
            disabled={nenhumVendedorSelecionado}
            disabledHint={nenhumVendedorSelecionado && "Selecione o Vendedor"}
          />
        </Row>
      </Card>
      {dadosGrafico && (
        <>
          <TimeSeriesLineChart
            data={dadosGrafico.dados}
            title="Comparativo de Lucro Gerado por Mês"
            dataKey={"Mes/Ano"}
            lineDataKeys={dadosGrafico.vendedores}
            hideTotal={localStorage.getItem("ver_totais_consultas") === "false"}
            total={parseFloat(dadosGrafico?.total_periodo ?? "0")}
            isRank
          />
          <Card body>
            <Table columns={columnsDadosPorVendedor} data={dadosPorVendedor} />
          </Card>
          <BaseInfoCard baseInfo={["Vendas: Vendas do Sistema Desktop"]} />
        </>
      )}
    </PageContainer>
  );
};
