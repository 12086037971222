import React from "react";
import { ModalBase } from "../../../../../components";
import ConferenciaValoresJornadaService from "../../../../../services/frente_venda_mobile/ConferenciaValoresJornadaService";

const ConfirmarReaberturaConferenciaModal = ({
  isOpen,
  toggle,
  idJornada,
  notifyEvent,
}) => {
  const reabrirConferencia = async () => {
    toggle();
    const [ok, _] = await ConferenciaValoresJornadaService.reabrirConferencia({
      id_jorn_dia: idJornada,
    });
    if (ok) {
      notifyEvent();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title={
        <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          Confirmação de Reabertura de Conferência
        </h5>
      }
      autoFocus
      onConfirm={reabrirConferencia}
    >
      Você tem certeza que deseja reabrir a conferência de valores do dia
      informado?
      <br />
      Essa operação não pode ser desfeita e os valores precisarão ser conferidos
      novamente.
    </ModalBase>
  );
};

export default ConfirmarReaberturaConferenciaModal;
