import React from "react";
import { useState } from "react";
import { AsyncComboBox, TextInput } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleVeicCliente, routesBaseVeicCliente } from "../VeicCliente";
import { Row } from "reactstrap";

export const CadastroVeicClienteModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [idMontadora, setIdMontadora] = useState(0);
  const [nome, setNome] = useState("");

  const limparDados = () => {
    setIdMontadora(0);
    setNome("");
  };

  const fetchData = (data) => {
    setIdMontadora(data.id_montadora ?? 0);
    setNome(data.nome);
  };

  const submitPayload = (action) => {
    const payload = {
      id_montadora: idMontadora,
      nome: nome,
    };

    return action === MODAL_ACTIONS.ADD
      ? { ...payload, importacao: false, id_cad_imp: 0 }
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleVeicCliente}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseVeicCliente}
      number="0061_1"
      selected={selected}
      notifyEvent={notifyEvent}
      concatShortcut={concatShortcut}
      concatModelName="veiculo"
    >
      <Row>
        <TextInput
          label="Nome"
          md={12}
          onChange={(e, v) => setNome(v)}
          value={nome}
          maxLength={30}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={8}
          isConcatField
          concatModelName="montadora"
          defaultOptions
          label="Montadora"
          isSearchable
          onChange={(s) => setIdMontadora(s?.value)}
          defaultValue={idMontadora}
        />
      </Row>
    </ModalCadastroV2>
  );
};
