import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  idOS: null,
  idVistoria: null,
  dataVisita: null,
  horaVisita: null,
  UC: null,
  idCliente: null,
  tipoCliente: "F",
  nomeCliente: "",
  enderecoCliente: "",
  numEndCliente: "",
  complementoCliente: "",
  bairroCliente: "",
  cidadeCliente: "",
  cidadeUfCliente: "",
  cepCliente: "",
  referenciaCliente: "",
  foneCliente: "",
  responsavelCliente: "",
  foneResponsavel: "",
  solicitanteCliente: "",
  foneSolicitante: "",
  UCCompensacao: "",
  demandaContratada: "NAO",
  tipoDemanda: "OEM",
  responsavel1Vistoria: null,
  responsavel2Vistoria: null,

  // Inversor e String Box
  localFixacaoInversor: "",
  localStringBox: "",
  distanciaModuloStringBox: null,
  distanciaStringBoxInversor: null,
  distanciaInversorMedidor: null,
  pontoInternet: "NAO",
  tipoComunicacaoInternet: "NAO",
  observInversorStringBox: "",

  // Padrão Entrada
  concessionaria: null,
  tipoLigacao: null,
  tensaoNominal: null,
  amperagemDisjuntorMedicao: null,
  amperagemDisjuntorGeral: null,
  condicaoEntrada: null,
  bitolaCabo: null,
  tipoRamal: null,
  numeroTransformador: null,

  // Proposta
  quantidadeModulos: 0,
  potenciaModulo: 0,
  larguraModulo: 0,
  comprimentoModulo: 0,
  alturaModulo: 0,
  quantidadeInversor: 0,
  potenciaInversor: 0,
  modeloInversor: null,

  // Fotos
  fotos: [],
};

export const vistoriaTecnicaSlice = createSlice({
  name: "vistoria_tecnica",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    reset: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setIdVistoria: (state, action) => {
      state["idVistoria"] = action.payload;
    },
    setIdOS: (state, action) => {
      state["idOS"] = action.payload;
    },
    setDataVisita: (state, action) => {
      state["dataVisita"] = action.payload;
    },
    setHoraVisita: (state, action) => {
      state["horaVisita"] = action.payload;
    },
    setUC: (state, action) => {
      state["UC"] = action.payload;
    },
    setIdCliente: (state, action) => {
      state["idCliente"] = action.payload;
    },
    setTipoCliente: (state, action) => {
      state["tipoCliente"] = action.payload;
    },
    setNomeCliente: (state, action) => {
      state["nomeCliente"] = action.payload;
    },
    setEnderecoCliente: (state, action) => {
      state["enderecoCliente"] = action.payload;
    },
    setNumEndCliente: (state, action) => {
      state["numEndCliente"] = action.payload;
    },
    setComplementoCliente: (state, action) => {
      state["complementoCliente"] = action.payload;
    },
    setBairroCliente: (state, action) => {
      state["bairroCliente"] = action.payload;
    },
    setCidadeCliente: (state, action) => {
      state["cidadeCliente"] = action.payload;
    },
    setCidadeUfCliente: (state, action) => {
      state["cidadeUfCliente"] = action.payload;
    },
    setCepCliente: (state, action) => {
      state["cepCliente"] = action.payload;
    },
    setReferenciaCliente: (state, action) => {
      state["referenciaCliente"] = action.payload;
    },
    setFoneCliente: (state, action) => {
      state["foneCliente"] = action.payload;
    },
    setResponsavelCliente: (state, action) => {
      state["responsavelCliente"] = action.payload;
    },
    setFoneResponsavel: (state, action) => {
      state["foneResponsavel"] = action.payload;
    },
    setSolicitanteCliente: (state, action) => {
      state["solicitanteCliente"] = action.payload;
    },
    setFoneSolicitante: (state, action) => {
      state["foneSolicitante"] = action.payload;
    },
    setUCCompensacao: (state, action) => {
      state["UCCompensacao"] = action.payload;
    },
    setDemandaContratada: (state, action) => {
      state["demandaContratada"] = action.payload;
    },
    setTipoDemanda: (state, action) => {
      state["tipoDemanda"] = action.payload;
    },
    setResponsavel1Vistoria: (state, action) => {
      state["responsavel1Vistoria"] = action.payload;
    },
    setResponsavel2Vistoria: (state, action) => {
      state["responsavel2Vistoria"] = action.payload;
    },
    setLocalFixacaoInversor: (state, action) => {
      state["localFixacaoInversor"] = action.payload;
    },
    setLocalStringBox: (state, action) => {
      state["localStringBox"] = action.payload;
    },
    setDistanciaModuloStringBox: (state, action) => {
      state["distanciaModuloStringBox"] = action.payload;
    },
    setDistanciaStringBoxInversor: (state, action) => {
      state["distanciaStringBoxInversor"] = action.payload;
    },
    setDistanciaInversorMedidor: (state, action) => {
      state["distanciaInversorMedidor"] = action.payload;
    },
    setPontoInternet: (state, action) => {
      state["pontoInternet"] = action.payload;
      if (action.payload !== "SIM") {
        state["tipoComunicacaoInternet"] = "NAO";
      }
      return state;
    },
    setTipoComunicacaoInternet: (state, action) => {
      state["tipoComunicacaoInternet"] = action.payload;
    },
    setObservInversorStringBox: (state, action) => {
      state["observInversorStringBox"] = action.payload;
    },
    setConcessionaria: (state, action) => {
      state["concessionaria"] = action.payload;
    },
    setTipoLigacao: (state, action) => {
      state["tipoLigacao"] = action.payload;
    },
    setTensaoNominal: (state, action) => {
      state["tensaoNominal"] = action.payload;
    },
    setAmperagemDisjuntorMedicao: (state, action) => {
      state["amperagemDisjuntorMedicao"] = action.payload;
    },
    setAmperagemDisjuntorGeral: (state, action) => {
      state["amperagemDisjuntorGeral"] = action.payload;
    },
    setCondicaoEntrada: (state, action) => {
      state["condicaoEntrada"] = action.payload;
    },
    setBitolaCabo: (state, action) => {
      state["bitolaCabo"] = action.payload;
    },
    setTipoRamal: (state, action) => {
      state["tipoRamal"] = action.payload;
    },
    setNumeroTransformador: (state, action) => {
      state["numeroTransformador"] = action.payload;
    },
    setQuantidadeModulos: (state, action) => {
      state["quantidadeModulos"] = action.payload;
    },
    setPotenciaModulo: (state, action) => {
      state["potenciaModulo"] = action.payload;
    },
    setAlturaModulo: (state, action) => {
      state["alturaModulo"] = action.payload;
    },
    setComprimentoModulo: (state, action) => {
      state["comprimentoModulo"] = action.payload;
    },
    setLarguraModulo: (state, action) => {
      state["larguraModulo"] = action.payload;
    },
    setQuantidadeInversor: (state, action) => {
      state["quantidadeInversor"] = action.payload;
    },
    setPotenciaInversor: (state, action) => {
      state["potenciaInversor"] = action.payload;
    },
    setModeloInversor: (state, action) => {
      state["modeloInversor"] = action.payload;
    },
    addFoto: (state, action) => {
      state["fotos"].push(action.payload);
    },
    removerFoto: (state, action) => {
      state["fotos"] = state["fotos"].filter(
        (element) => element.nome !== action.payload
      );
    },
  },
});

export const {
  init,
  reset,
  setup,
  setIdVistoria,
  setDataVisita,
  setHoraVisita,
  setUC,
  setIdCliente,
  setTipoCliente,
  setNomeCliente,
  setEnderecoCliente,
  setNumEndCliente,
  setComplementoCliente,
  setBairroCliente,
  setCidadeCliente,
  setCidadeUfCliente,
  setCepCliente,
  setReferenciaCliente,
  setFoneCliente,
  setResponsavelCliente,
  setFoneResponsavel,
  setSolicitanteCliente,
  setFoneSolicitante,
  setUCCompensacao,
  setDemandaContratada,
  setTipoDemanda,
  setResponsavel1Vistoria,
  setResponsavel2Vistoria,
  setLocalFixacaoInversor,
  setLocalStringBox,
  setDistanciaModuloStringBox,
  setDistanciaStringBoxInversor,
  setDistanciaInversorMedidor,
  setPontoInternet,
  setTipoComunicacaoInternet,
  setObservInversorStringBox,
  setConcessionaria,
  setTipoLigacao,
  setTensaoNominal,
  setAmperagemDisjuntorGeral,
  setAmperagemDisjuntorMedicao,
  setCondicaoEntrada,
  setBitolaCabo,
  setTipoRamal,
  setNumeroTransformador,
  setQuantidadeModulos,
  setPotenciaModulo,
  setAlturaModulo,
  setLarguraModulo,
  setComprimentoModulo,
  setQuantidadeInversor,
  setPotenciaInversor,
  setModeloInversor,
  setIdOS,
  addFoto,
  removerFoto,
} = vistoriaTecnicaSlice.actions;

export default vistoriaTecnicaSlice.reducer;

export const vistoriaAsPayload = (state) => {
  const res = {};

  const invalidArray = [
    0,
    "",
    null,
    undefined,
    "NAO",
    "NAO DEFINIDO",
    false,
    "0",
  ];

  res["id_os"] = state.idOS;
  res["nome_cli_resp"] = state.responsavelCliente?.toUpperCase() ?? "";
  res["fone_cli_rest"] = state.foneResponsavel?.toUpperCase() ?? "";
  res["nome_cli_solic"] = state.solicitanteCliente?.toUpperCase() ?? "";
  res["fone_cli_solic"] = state.foneSolicitante?.toUpperCase() ?? "";
  res["n_uc"] = state.UC?.toUpperCase() ?? "";
  res["uc_comp"] = state.UCCompensacao?.toUpperCase() ?? "";
  res["demand_contr"] = state.demandaContratada === "SIM";
  res["tipo_demanda"] = state.tipoDemanda?.toUpperCase() ?? "";
  res["valor"] = state.valorDemanda ?? 0.0;
  res["visita"] = [];

  const checkVazio = (obj) => {
    let countDados = 0;
    obj.forEach(function(i) {
      Object.keys(i).forEach(function(j) {
        if (!invalidArray.includes(i[j])) {
          countDados++;
        }
      });
    });
    if (countDados > 0) {
      return obj;
    } else {
      return [];
    }
  };

  // Visita
  if (state.responsavel1Vistoria) {
    res["visita"].push({
      tipo: "",
      id_tecnico: state.responsavel1Vistoria ?? 0,
      data: (state.dataVisita?.toISOString()?.split("T") ?? [""])[0],
      hora: state.horaVisita?.toUpperCase() ?? "",
    });
  }
  if (state.responsavel2Vistoria) {
    res["visita"].push({
      tipo: "",
      id_tecnico: state.responsavel2Vistoria ?? 0,
      data: (state.dataVisita?.toISOString()?.split("T") ?? [""])[0],
      hora: state.horaVisita?.toUpperCase() ?? "",
    });
  }

  // Padrão de Entrada
  res["pad_entrada"] = [
    {
      concessio: state.concessionaria?.toUpperCase() ?? "",
      sigla_tipo_lig: state.tipoLigacao?.toUpperCase() ?? "",
      tensao_nominal: state.tensaoNominal?.toUpperCase() ?? "",
      disjuntor_entr_medicao: String(
        state.amperagemDisjuntorMedicao ?? ""
      )?.toUpperCase(),
      disjuntor_entr_geral: String(
        state.amperagemDisjuntorGeral ?? ""
      )?.toUpperCase(),
      sigla_cond_entr: state.condicaoEntrada?.toUpperCase() ?? "",
      bitola_cabo_ramal: String(state.bitolaCabo ?? "")?.toUpperCase(),
      sigla_tipo_ramal: state.tipoRamal?.toUpperCase() ?? "",
      nro_transfo: state.numeroTransformador?.toUpperCase() ?? "",
    },
  ];

  res["pad_entrada"] = checkVazio(res["pad_entrada"]);

  // Inversor e String Box
  res["inversor"] = [
    {
      local_fixa_inversor: state.localFixacaoInversor?.toUpperCase() ?? "",
      local_fixa_stringbox: state.localStringBox?.toUpperCase() ?? "",
      dist_modulos_stringbox: state.distanciaModuloStringBox ?? 0.0,
      dist_stringbox_inversor: state.distanciaStringBoxInversor ?? 0.0,
      dist_inversor_medidor: state.distanciaInversorMedidor ?? 0.0,
      tem_ponto_net: state.pontoInternet === "SIM",
      sigla_tipo_conexao_net: state.tipoComunicacaoInternet ?? "NAO",
      observacao: state.observInversorStringBox ?? "",
    },
  ];

  res["inversor"] = checkVazio(res["inversor"]);

  //Sistema proposto
  res["sist_proposto"] = [
    {
      qtd_modulo: state.quantidadeModulos ?? 0.0,
      potencia_mod: String(state.potenciaModulo ?? 0.0),
      larg_modulo: state.larguraModulo ?? 0.0,
      comp_modulo: state.comprimentoModulo ?? 0.0,
      alt_modulo: state.alturaModulo ?? 0.0,
      qtd_inversor: state.quantidadeInversor ?? 0.0,
      potencia_inversor: String(state.potenciaInversor ?? 0.0),
      modelo_inversor: state.modeloInversor?.toUpperCase() ?? "",
    },
  ];

  res["sist_proposto"] = checkVazio(res["sist_proposto"]);

  if (!state.idVistoria) {
    res["fotos"] = [];
    state.fotos.forEach((foto) =>
      res["fotos"].push({
        nome_arquivo: foto.nome?.toUpperCase(),
        obs: foto.obs?.toUpperCase(),
        base64: foto.base64,
      })
    );
  }

  return res;
};
