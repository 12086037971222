import React from "react";
import { TableCheck } from "../../../../../components/TableCheck";
import { formatValueFromAPI, naturalSort } from "../../../../../coreUtils";
import { Table } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const CadastroRotaGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome da Rota",
      align: "left",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "id_colaborador__nome",
      text: "Vendedor",
      align: "left",
      sortable: true,
      formatter: (_, row) =>
        formatValueFromAPI(row.id_colaborador__nome, row.id_colaborador),
    },
    {
      dataField: "veiculos",
      text: "Veículo",
      align: "left",
      sortable: true,
    },
    {
      dataField: "serie",
      text: "Série NFe",
      align: "center",
      sortable: true,
    },
    {
      dataField: "ult_nro_nfe_emitido",
      text: "Última NFe Emitida",
      align: "center",
      sortable: true,
    },
    {
      dataField: "ativo",
      text: "Ativa",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
