import { apiGet } from "../api";

const VendasPorPeriodoService = {
  fetch: async (dataIni, dataFim, grouping, agruparPor, vendedor) => {
    const params_ = {
      data_ini: dataIni,
      data_fim: dataFim,
      grouping: grouping,
      agrupar_por: agruparPor,
    };

    if (vendedor?.length > 0) params_["vendedor"] = `[${vendedor}]`;

    const searchParams = new URLSearchParams(params_).toString();

    return await apiGet(
      `/relatorios/venda/vendas_por_vendedor/?${searchParams}`
    );
  },
};

export default VendasPorPeriodoService;
