import React from "react";
import { Card } from "reactstrap";
import { TableCheck } from "../../../../../components/TableCheck";
import { Table } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const ContaBancGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "banco__nome",
      text: "Banco",
      align: "left",
    },
    {
      dataField: "agencia",
      text: "Agência",
      align: "center",
    },
    {
      dataField: "nro_conta",
      text: "Nro. Conta",
      align: "center",
    },
    {
      dataField: "layout_banco",
      text: "Layout",
      align: "left",
    },
    {
      dataField: "padrao_cnab",
      text: "Padrão CNAB",
      align: "center",
    },
    {
      dataField: "status",
      text: "Status",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Card body>
      <Table
        columns={columns}
        data={data}
        onSelect={setSelected}
        paginated={false}
        onRowDoubleClick={alterar}
      />
    </Card>
  );
};
