import React, { useRef, useState } from "react";
import {
  AsyncComboBox,
  FormButton,
  ModalBase,
  Table,
  TableDelete,
} from "../../../../../components";
import AvisoVincColaboradorService from "../../../../../services/aviso/AvisoVincColaboradorService";
import { toastr } from "react-redux-toastr";
import { Label, Row } from "reactstrap";
import { formatValueFromAPI } from "../../../../../coreUtils";

export const ColaboradoresAvisoModal = ({ selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dados, setDados] = useState([]);
  const [idColaborador, setIdColaborador] = useState(null);
  const [loadingIncluir, setLoadingIncluir] = useState(false);
  const idColaboradorRef = useRef();

  const carregarDados = async () => {
    const [ok, ret] = await AvisoVincColaboradorService.buscar(selected);
    setDados(ok ? ret : []);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const incluir = async () => {
    if ([null, undefined].includes(idColaborador)) {
      toastr.warning("Atenção", "Por favor, informe o Colaborador");
      return;
    }
    const payload = { id_colaborador: idColaborador, id_aviso: selected };
    const [ok] = await AvisoVincColaboradorService.incluir(payload);
    if (ok) {
      setIdColaborador(null);
      if (idColaboradorRef.current) {
        idColaboradorRef.current.clearValue();
        idColaboradorRef.current.setFocus();
      }
      await carregarDados();
    }
  };

  const excluir = async (idVinc) => {
    const [ok] = await AvisoVincColaboradorService.excluir(idVinc);
    if (ok) {
      carregarDados();
    }
  };

  const columns = [
    {
      dataField: "nome_colaborador",
      text: "Colaborador",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
    },
    {
      dataField: "del",
      isDummyField: true,
      text: "Ações",
      align: "center",
      formatter: (cell, row) => <TableDelete onClick={() => excluir(row.id)} />,
    },
  ];

  return (
    <>
      <FormButton
        md="auto"
        color="primary"
        onClick={toggle}
        disabled={[0, null, undefined].includes(selected)}
        padded={false}
      >
        Atribuições do Aviso
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Colaboradores Vinculados ao Aviso"
        number="0090_1"
        onBeforeOpen={carregarDados}
      >
        <h6
          className="mb-2"
          style={{
            fontSize: "0.82rem",
            fontWeight: "normal",
            color: "inherit",
          }}
        >
          Informe os colaboradores a serem atribuídos ao Aviso
        </h6>
        <Row>
          <AsyncComboBox
            md={8}
            isConcatField
            concatModelName="colaborador"
            label="Colaborador"
            isSearchable
            isClearable
            onChange={(s) => setIdColaborador(s?.value)}
            defaultValue={idColaborador}
            ref={idColaboradorRef}
          />
          <FormButton
            md="auto"
            color="info"
            onClick={incluir}
            loading={loadingIncluir}
            disabled={[null, undefined].includes(idColaborador)}
            disabledHint="Informe o Colaborador"
          >
            Atribuir
          </FormButton>
        </Row>
        <Table data={dados} columns={columns} paginated={false} pageSize={10} />
      </ModalBase>
    </>
  );
};
