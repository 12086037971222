import React from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  DragAndDropArquivos,
  FormButton,
  PageContainer,
  TextInput,
} from "../../../../components";
import { apiPostV2 } from "../../../../apiV2";

export const ImportacaoPlanilhaBroker = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const [arquivo, setArquivo] = useState(null);
  const [nomeArquivo, setNomeArquivo] = useState(null);

  const onDrop = (file) => {
    setArquivo(file);
    setNomeArquivo(file.name);
  };

  const importar = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("file", arquivo, nomeArquivo);

      const [ok] = await apiPostV2(
        `/venda/broker/importar_planilha/`,
        formData
      );

      if (ok) {
        setArquivo(null);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Importação de Vendas Broker" number="0102" canGoBack>
      <Card body>
        <Row>
          <DragAndDropArquivos
            multiple={true}
            accept="*"
            md={6}
            text="Arraste e solte o Arquivo XLS/XLSX com a informação das Vendas ou clique para selecioná-lo"
            onDrop={onDrop}
          />
        </Row>
        {arquivo && (
          <Row className="mt-2">
            <TextInput
              md={6}
              label="Arquivo Indicado"
              value={nomeArquivo}
              disabled
            />
            <FormButton color="success" loading={loading} onClick={importar}>
              Importar
            </FormButton>
          </Row>
        )}
      </Card>
    </PageContainer>
  );
};
