import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../../coreUtils";
import {
  BotaoConfirmarVoltar,
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../../components";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { CadastroBancoModal } from "./components/CadastroBancoModal";
import { CadastroBancoGrid } from "./components/CadastroBancoGrid";

export const modalTitleBanco = "Cadastro Banco";
export const routesBaseBanco = "/cadastro/banco";

export const CadBanco = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [somenteAtivos, setSomenteAtivos] = useState(true);

  const buscarDados = async (p) => {
    const [ok, ret] = await apiGetV2(`${routesBaseBanco}/listar/`, {
      pista: p,
      somente_ativos: somenteAtivos,
    });
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
        toggleExcluir()
      ;
    }, 1);
  };

  return (
    <PageContainer
      title="Cadastro de Banco"
      number="0121"
      canGoBack
    >
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <FormCheckbox
            label="Somente Ativos"
            checked={somenteAtivos}
            onChange={() => setSomenteAtivos(!somenteAtivos)}
          />
          <CadastroBancoModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleBanco}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0121_2"
            selected={selected}
            routeBase={routesBaseBanco}
            notifyEvent={notifyEvent}
          />
          <BotaoConfirmarVoltar
            selected={selected}
            carregarDados={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <CadastroBancoGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
