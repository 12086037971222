import React from "react";
import { IconButton, Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { BsPrinter } from "react-icons/bs";
import CaixaLojaService from "../../../../../services/financeiro/CaixaLojaService";

const columns = [
  { dataField: "id", text: "#", align: "center", headerAlign: "center" },
  {
    dataField: "nome_usuario",
    text: "Operador",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.usuario),
  },
  {
    dataField: "abertura",
    text: "Abertura",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "fechamento",
    text: "Fechamento",
    align: "center",
    headerAlign: "center",
    formatter: (c) => c ?? "Aberto",
  },
  {
    dataField: "saldo_abe",
    text: "Saldo Abert.",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "saldo_fec",
    text: "Saldo Fec.",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_cartao",
    text: "Cartao",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_cheque",
    text: "Cheque",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_crediario",
    text: "Crediario",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_rotativo",
    text: "Rotativo",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_dinheiro",
    text: "Dinheiro",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_troco",
    text: "Troco",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_retirada",
    text: "Retirada",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_sangria",
    text: "Sangria",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "print",
    text: "",
    align: "center",
    headerAlign: "center",
    formatter: (c, row) => (
      <IconButton
        icon={BsPrinter}
        onClick={() => CaixaLojaService.imprimirFechamento(row.id)}
        hint="Imprimir"
      />
    ),
  },
];

export const GerencCaixaLojaGrid = ({ dados, onSelect }) => {
  return (
    <Table
      data={dados}
      columns={columns}
      onSelect={onSelect}
      pageSize={5}
      paginated={false}
      showRegisterCount={false}
    />
  );
};
