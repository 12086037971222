import React, { useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import {
  TextInput,
  FormButton,
  ModalBase,
  Table,
  TableDelete,
} from "../../../../../../components";
import NotaFiscalService from "../../../../../../services/docs_eletron/NotaFiscalService";

export const ChavesReferenciadasNfModal = ({ idNFe }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [chaveAcesso, setChaveAcesso] = useState("");
  const [chavesRefer, setChavesRefer] = useState([]);
  const [loadingIncluirChave, setLoadingIncluirChave] = useState(false);

  const refChaveAcesso = useRef();

  const columns = [
    {
      dataField: "chave_devol",
      text: "Chave Acesso",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => removerChave(row.id)} />
      ),
    },
  ];

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const carregarDados = async () => {
    const [ok, ret] = await NotaFiscalService.inclusao.buscarChavesRefer(idNFe);
    if (ok) {
      setChavesRefer(ret.chaves_ref);
    }
  };

  const removerChave = async (idChaveRefer) => {
    const [ok] = await NotaFiscalService.inclusao.excluirChaveRefer(
      idChaveRefer
    );
    if (ok) {
      carregarDados();
    }
  };

  const limparDados = () => {
    setChaveAcesso("");
    refChaveAcesso.current.focus();
  };

  const incluirChaveRefer = async () => {
    setLoadingIncluirChave(true);
    const payload = {
      id_cab: idNFe,
      chave_acesso: chaveAcesso ?? "",
    };
    const [ok] = await NotaFiscalService.inclusao.incluirChaveRefer(payload);
    if (ok) {
      carregarDados();
      limparDados();
    }
    setLoadingIncluirChave(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        onClick={toggle}
        disabled={!idNFe}
        disabledHint="A NF não foi salva. Inclua um item ou salve-a primeiro."
      >
        Chaves Referenciadas
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Chaves Referenciadas da NF-e"
        onClosed={limparDados}
        onBeforeOpen={carregarDados}
      >
        <Row>
          <TextInput
            colClassName="ml-auto mr-auto"
            md={10}
            label="Chave de Acesso"
            value={chaveAcesso}
            onChange={(e, v) => setChaveAcesso(v)}
            autoFocus
            ref={refChaveAcesso}
          />
          <FormButton
            md={2}
            color="primary"
            onClick={incluirChaveRefer}
            loading={loadingIncluirChave}
          >
            Incluir
          </FormButton>
        </Row>
        <Row>
          <Col md={12}>
            <Table
              data={chavesRefer}
              columns={columns}
              paginated={false}
              pageSize={7}
            />
          </Col>
        </Row>
      </ModalBase>
    </>
  );
};
