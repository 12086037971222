import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  FormButton,
  IntegerFormInput,
  ModalBase,
  NumberInput,
  RadioGroup,
  TextInput,
  UnlockToEdit,
} from "../../../../../components";
import { apiGetV2, apiPostV2 } from "../../../../../apiV2";
import { RadioItem } from "../../../../../components/RadioGroup";
import { toastr } from "react-redux-toastr";

const configApiGateway = {
  url_login:
    "https://bk07exvx19.execute-api.us-east-1.amazonaws.com/dev-stage/oauth/login",
  url_proc:
    "https://v4kugeekeb.execute-api.us-east-1.amazonaws.com/prod-stage/cloud-notification/create",
  client_id: "3veb9e18d50ceqes38o1i8mlph",
};

const configLocalhost = {
  url_login: "http://localhost:6060/Client/login/",
  url_proc: "http://localhost:6060/Client/",
  client_id: "",
};

export const PersonTefPayerModal = () => {
  const userSkill =
    localStorage.getItem("user_admin_skill").toLowerCase() === "true";
  const [metodo, setMetodo] = useState("");
  const [urlLogin, setUrlLogin] = useState("");
  const [urlProc, setUrlProc] = useState("");
  const [clientId, setClientId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [ultCorrelationId, setUltCorrelationId] = useState("");
  const [delayEsperaDepoisRec, setDelayEsperaDepoisRec] = useState(7);
  const [delayVerificaStatus, setDelayVerificaStatus] = useState(3);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const limparDados = () => {
    setMetodo("");
    setUrlLogin("");
    setUrlProc("");
    setClientId("");
    setUsername("");
    setPassword("");
    setCompanyId("");
    setStoreId("");
    setUltCorrelationId("");
    setDelayEsperaDepoisRec(3);
    setDelayVerificaStatus(7);
  };

  const carregarDados = async () => {
    setLoadingFetch(true);
    const [ok, ret] = await apiGetV2("/tef/payer/buscar_config/", null, {
      errorMessage: false,
    });

    if (ok) {
      setMetodo(ret.metodo);
      setUrlLogin(ret.url_login);
      setUrlProc(ret.url_proc);
      setClientId(ret.client_id);
      setUsername(ret.username);
      setPassword(ret.password);
      setCompanyId(ret.company_id);
      setStoreId(ret.store_id);
      setUltCorrelationId(ret.ult_correlation_id);
      setDelayEsperaDepoisRec(parseFloat(ret.delay_espera_depois_rec));
      setDelayVerificaStatus(parseFloat(ret.delay_verifica_status));
    }
    setLoadingFetch(false);
  };

  const toggle = async () => {
    if (!isOpen) {
      await carregarDados();
    }
    setIsOpen(!isOpen);
  };

  const handleSetMetodo = (v) => {
    setMetodo(v);
    if (v === "API_GATEWAY") {
      setUrlLogin(configApiGateway.url_login);
      setUrlProc(configApiGateway.url_proc);
      setClientId(configApiGateway.client_id);
    } else if (v === "LOCALHOST") {
      setUrlLogin(configLocalhost.url_login);
      setUrlProc(configLocalhost.url_proc);
      setClientId(configLocalhost.client_id);
    }
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(metodo)) {
      toastr.warning("Atenção", "Por favor, informe o Método");
      return false;
    }

    setLoadingSubmit(true);
    const payload = {
      metodo: metodo,
      url_login: urlLogin,
      url_proc: urlProc,
      client_id: clientId,
      username: username,
      password: password,
      company_id: companyId,
      store_id: storeId,
      delay_espera_depois_rec: delayEsperaDepoisRec,
      delay_verifica_status: delayVerificaStatus,
    };

    const [ok] = await apiPostV2("/tef/payer/atualizar_config/", payload);

    if (ok) {
      toggle();
    }

    setLoadingSubmit(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        onClick={toggle}
        divClassName="px-0"
        loading={loadingFetch}
        disabled={!userSkill}
        disabledHint="Acesso restrito à equipe de suporte da Skillsoft"
      >
        Configurações
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Parâmetros TEF Payer"
        onConfirm={handleSubmit}
        loadingConfirm={loadingSubmit}
        onClosed={limparDados}
        number="conf_02_payer"
      >
        <Row>
          <RadioGroup label="Método" value={metodo} onChange={handleSetMetodo}>
            <RadioItem label="API Gateway" value="API_GATEWAY" />
            <RadioItem label="Localhost" value="LOCALHOST" />
          </RadioGroup>
        </Row>
        <Row>
          <UnlockToEdit>
            <TextInput
              md={12}
              label="URL Login"
              value={urlLogin}
              forceUppercase={false}
              maxLength={255}
              onChange={(e, v) => setUrlLogin(v)}
            />
          </UnlockToEdit>
        </Row>
        <Row>
          <UnlockToEdit>
            <TextInput
              md={12}
              label="URL Proc"
              value={urlProc}
              forceUppercase={false}
              maxLength={255}
              onChange={(e, v) => setUrlProc(v)}
            />
          </UnlockToEdit>
        </Row>
        <Row>
          <UnlockToEdit>
            <TextInput
              md={4}
              label="Client ID"
              value={clientId}
              forceUppercase={false}
              maxLength={100}
              onChange={(e, v) => setClientId(v)}
            />
          </UnlockToEdit>
          <TextInput
            md={4}
            label="Username"
            value={username}
            forceUppercase={false}
            maxLength={100}
            onChange={(e, v) => setUsername(v)}
          />
          <TextInput
            md={4}
            label="Password"
            value={password}
            forceUppercase={false}
            maxLength={32}
            onChange={(e, v) => setPassword(v)}
          />
        </Row>
        <Row>
          <TextInput
            md={4}
            label="Company ID"
            value={companyId}
            forceUppercase={false}
            maxLength={100}
            onChange={(e, v) => setCompanyId(v)}
          />
          <TextInput
            md={4}
            label="Store ID"
            value={storeId}
            forceUppercase={false}
            maxLength={32}
            onChange={(e, v) => setStoreId(v)}
          />
          <IntegerFormInput
            md={4}
            label="Ult. Correlation ID"
            defaultValue={ultCorrelationId}
            disabled
          />
        </Row>
        <Row>
          <UnlockToEdit>
            <NumberInput
              md={4}
              label="Delay Espera Depois Rec"
              value={delayEsperaDepoisRec}
              onChange={setDelayEsperaDepoisRec}
            />
          </UnlockToEdit>
          <UnlockToEdit>
            <NumberInput
              md={4}
              label="Delay Verifica Status"
              value={delayVerificaStatus}
              onChange={setDelayVerificaStatus}
            />
          </UnlockToEdit>
        </Row>
      </ModalBase>
    </>
  );
};
