import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { apiGetV2 } from "../../../../apiV2";
import { ProdutosNcmInvalidaGrid } from "./components/ProdutosNcmInvalidaGrid";
import { docPrintReport } from "../../../../pdf";

export const ProdutosNcmInvalida = () => {
  const [idGrupo, setIdGrupo] = useState(null);
  const [somenteAtivos, setSomenteAtivos] = useState(false);
  const [dados, setDados] = useState([]);
  const [qtdProdutos, setQtdProdutos] = useState(0);
  const [qtdNcms, setQtdNcms] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const buscarDados = async () =>
    await apiGetV2("/relatorios/cadastrais/produtos/ncms_invalidos/", {
      id_grupo: idGrupo,
      somente_ativos: somenteAtivos,
    });

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      setDados(ret.produtos);
      setQtdProdutos(ret.qtd_produtos);
      setQtdNcms(ret.qtd_ncms);
    } else {
      setDados([]);
      setQtdProdutos(0);
      setQtdNcms(0);
    }
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      await docPrintReport(
        "/relatorios/cadastrais/produtos_ncm_invalida/",
        { dados: ret },
        "5049"
      );
    }
    setLoadingImprimir(false);
  };

  return (
    <PageContainer title="Produtos com NCM Inválida" number="5049" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            isConcatField
            concatModelName="grupo"
            isSearchable
            isClearable
            md={6}
            label="Grupo"
            onChange={(s) => setIdGrupo(s?.value)}
            defaultValue={idGrupo}
          />
          <FormCheckbox
            label="Somente Ativos"
            checked={somenteAtivos}
            onChange={() => setSomenteAtivos(!somenteAtivos)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <CardTotais md={5}>
        <CardTotaisItem label="Produtos" value={qtdProdutos} checkFloat />
        <CardTotaisItem label="NCM's" value={qtdNcms} checkFloat />
      </CardTotais>
      <Card body>
        <ProdutosNcmInvalidaGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
