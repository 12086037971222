import React from "react";
import { Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

const columns = [
  { dataField: "desc_origem", text: "Origem", align: "center" },
  { dataField: "nro_origem", text: "Nº Origem", align: "center" },
  {
    dataField: "pontos_ganho",
    text: "Pontos Ganhos",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "pontos_saldo",
    text: "Pontos Saldo",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const MovimentosPremiadoGrid = ({ dados }) => {
  return (
    <Table data={dados} columns={columns} paginated={false} pageSize={10} />
  );
};
