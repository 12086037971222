import {
  Label,
  PrintDensity,
  PrintDensityName,
  Text,
  FontFamily,
  FontFamilyName,
  Spacing,
  Alignment,
  Grid,
  Size,
  SizeType,
  Barcode,
  BarcodeType,
  BarcodeTypeName,
} from "jszpl";
import { sliceIntoChunks, wrapText } from "../../../../../coreUtils";
import unidecode from "unidecode";
import alignmentValue from "jszpl/src/enums/alignment-value";

export const imprimirEtqL32 = (etiquetas) => {
  try {
    let etq = etiquetas.reduce(
      (acc, e) => [...acc, ...Array(e.qtd_etiquetas).fill(e)],
      []
    );

    etq = sliceIntoChunks(etq, 2);

    const resZpl = etq.reduce((acc, row) => {
      const label = new Label();
      label.printDensity = new PrintDensity(PrintDensityName["8dpmm"]);
      label.width = 104;
      label.height = 25;

      const grid = new Grid();
      label.content.push(grid);
      grid.columns.push(new Size(1, SizeType.Relative));
      grid.columns.push(new Size(1, SizeType.Relative));
      grid.rows.push(new Size(1, SizeType.Relative));
      grid.columnSpacing = 5;
      grid.rowSpacing = 1;
      grid.padding = new Spacing(30);

      row.forEach((col, colIndex) => {
        const nomeWrap = wrapText(unidecode(col.nome), 20).split("\n");
        if (nomeWrap?.length > 0) {
          const nomeProd = new Text();
          grid.content.push(nomeProd);
          nomeProd.text = nomeWrap[0];
          nomeProd.fontFamily = new FontFamily(FontFamilyName.A);
          nomeProd.characterHeight = new Size(30, SizeType.Absolute);
          nomeProd.grid.column = colIndex;
          nomeProd.top = new Size(0, SizeType.Absolute);
        }

        if (nomeWrap?.length > 1) {
          const nomeProd2 = new Text();
          grid.content.push(nomeProd2);
          nomeProd2.text = nomeWrap.slice(1).join(" ").substring(0, 20);
          nomeProd2.fontFamily = new FontFamily(FontFamilyName.A);
          nomeProd2.characterHeight = new Size(30, SizeType.Absolute);
          nomeProd2.grid.column = colIndex;
          nomeProd2.top = new Size(25, SizeType.Absolute);
        }

        if (!["", "SEM GTIN"].includes(col.codigo_barras ?? "")) {
          const codBar = new Barcode();
          grid.content.push(codBar);
          codBar.data = col.codigo_barras;
          codBar.type = new BarcodeType(BarcodeTypeName.EAN13);
          codBar.left = new Size(75, SizeType.Absolute);
          codBar.height = new Size(50, SizeType.Absolute);
          codBar.grid.column = colIndex;
          codBar.top = new Size(54, SizeType.Absolute);
        }

        const idProd = new Text();
        grid.content.push(idProd);
        idProd.text = col.id_produto.toString();
        idProd.fontFamily = new FontFamily(FontFamilyName.A);
        idProd.characterHeight = new Size(25, SizeType.Absolute);
        idProd.grid.column = colIndex;
        idProd.top = new Size(135, SizeType.Absolute);

        const referencia = new Text();
        grid.content.push(referencia);
        referencia.text = col.referencia.toString();
        referencia.fontFamily = new FontFamily(FontFamilyName.A);
        referencia.characterHeight = new Size(25, SizeType.Absolute);
        referencia.grid.column = colIndex;
        referencia.horizontalAlignment = new Alignment(alignmentValue.End);
        referencia.top = new Size(138, SizeType.Absolute);
      });

      return [...acc, ...label.generateZPL().split("\n")];
    }, []);

    return [true, resZpl];
  } catch (err) {
    return [false, err.message];
  }
};
