import React, { useState } from "react";
import {
  debounce,
  defaultDebounceTime,
  MODAL_ACTIONS,
} from "../../../../coreUtils";
import CadastroCentroCustoService from "../../../../services/cadastro/CadastroCentroCustosService";
import {
  BotaoPesquisar,
  ComboBox,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { BotaoIncluir } from "../../../../components/cadastro";
import { CentroCustosModal } from "./components/CentroCustoModal";
import { CentroCustoGrid } from "./components/CentroCustoGrid";

export const modalTitleCentroCustos = "Centro de Custos";
export const routesBaseCentroCustos = "/cadastro/centro_custo";

export const CentroCustos = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mostrarInativos, setMostrarInativos] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExcluirOpen, setIsExcluirOpen] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [selected, setSelected] = useState([]);
  const [pista, setPista] = useState("");

  const [filtroNatureza, setFiltroNatureza] = useState();

  const tiposNatureza = [
    {
      label: "Receitas",
      value: "+",
    },
    {
      label: "Despesas",
      value: "-",
    },
  ];

  const buscarDados = async (p) => {
    setLoading(true);
    try {
      const [ok, ret] = await CadastroCentroCustoService.listar({
        pista: p,
        mostrar_inativos: mostrarInativos,
        natureza: filtroNatureza,
      });
      setDados(ok ? ret : []);
    } finally {
      setLoading(false);
    }
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const toggle = (action) => {
    setAction(action);
    setIsModalOpen(!isModalOpen);
  };

  const toggleExcluir = () => setIsExcluirOpen(!isExcluirOpen);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggle(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Centro de Custos" number="0128">
      <Card body>
        <Row>
          <SearchInput md={4} onChange={handlePista} loading={loading} />
          <ComboBox
            md={2}
            options={tiposNatureza}
            label="Natureza"
            onChange={(s) => setFiltroNatureza(s?.value)}
            defaultValue={filtroNatureza}
            isClearable
            isSearchable={false}
          />
          <FormCheckbox
            label={"Mostrar Inativos"}
            onChange={(e) => setMostrarInativos(!mostrarInativos)}
            checked={mostrarInativos}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />

          <BotaoIncluir toggle={toggle} />

          <CentroCustosModal
            isOpen={isModalOpen}
            toggle={toggle}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <CentroCustoGrid
          dados={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
