import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  UncontrolledAlert,
} from "reactstrap";
import { kClientId, kClientSecret } from "../../api";
import { login } from "../../auth/session";
import { isDevEnv } from "../../coreUtils";
import settings from "../../settings";
import { SET_AUTH_IS_LOADING } from "../../redux/constants";
import { useDispatch } from "react-redux";

const SignIn = () => {
  const [user, setUser] = useState(Cookies.get("user"));
  const [password, setPassword] = useState("");
  const [loginErrMsg, setLoginErrMsg] = useState("");
  const [loginErrAttempt, setLoginErrAttempt] = useState(0);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [deveRedefinirSenha, setDeveRedefinirSenha] = useState(false);
  const [temAcessoNovosApps, setTemAcessoNovosApps] = useState(true);
  const dispatch = useDispatch();

  const nickname = Cookies.get("nickname");

  const fazerLogin = async () => {
    // É gerado um URLSearchParams pois o content-type do POST precisa ser
    // x-www-form-urlencoded
    const data = new URLSearchParams({
      grant_type: "password",
      username: user,
      password: password,
      application: "WEB",
    });

    Cookies.remove("access_token");

    await axios
      .post(`${settings.AUTENTICADOR}/o/gerar_token/`, data, {
        timeout: 5000,
        auth: {
          username: kClientId,
          password: kClientSecret,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //Verifica se está no ambiente de desenvolvimento/teste
          if (isDevEnv()) {
            login(
              response.data.access_token,
              response.data.refresh_token,
              settings.API_ADDRESS
            );
          } else {
            login(
              response.data.access_token,
              response.data.refresh_token,
              response.data.acessos.api_address
            );
          }
          dispatch({ type: SET_AUTH_IS_LOADING, data: true });
          Cookies.set("nickname", prepareNickname(user));
          setLoginSuccess(true);
        } else {
          loginAttempt(response.data.msg);
        }
      })
      .catch((err) => {
        if (err.response.data?.msg?.msg) {
          loginAttempt(err.response.data.msg.msg);
        } else {
          loginAttempt(
            err.response?.data?.error === "invalid_grant"
              ? "Usuário ou senha incorretos."
              : err.response?.data?.msg ??
                  "Falha ao tentar se conectar com o serviço de autenticação."
          );
        }
      });
  };

  const handleClickLogin = async (e) => {
    e.preventDefault();

    setLoginErrMsg("");

    if (user && user.length >= 5 && password) {
      await axios
        .get(
          `${settings.AUTENTICADOR}/verifica_redefine_senha/?nickname=${user}`,
          {
            timeout: 5000,
            auth: {
              username: kClientId,
              password: kClientSecret,
            },
            validateStatus: false,
          }
        )
        .then((response) => {
          if (response.status === 200 && response.data.resetar_senha) {
            setTemAcessoNovosApps(response.data.tem_acesso_novos_apps ?? true);
            setDeveRedefinirSenha(true);
          } else {
            fazerLogin();
          }
        })
        .catch(() => {
          loginAttempt(
            "Falha ao tentar se conectar com o serviço de autenticação."
          );
        });
    } else {
      loginAttempt("Revise o formulário e tente novamente.");
    }
  };

  /* transforma o username em nickname */
  const prepareNickname = (user) => {
    user = user.split("@")[0];
    const nickname = user.charAt(0).toUpperCase() + user.slice(1);
    return nickname;
  };

  /* quando existe uma falha no login. */
  const loginAttempt = (msg) => {
    const _loginErrAttempt = loginErrAttempt + 1;
    setLoginErrMsg(msg);
    setLoginErrAttempt(_loginErrAttempt);
  };

  if (deveRedefinirSenha) {
    return (
      <Redirect
        to={{
          pathname: "/acesso/redefinir-senha",
          state: { nickname: user, tem_acesso_novos_apps: temAcessoNovosApps },
        }}
      />
    );
  }

  if (loginSuccess) {
    return <Redirect push to="/inicio" />;
  }

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>Bem vindo {nickname && "de volta, " + nickname}</h2>
        <p className="lead">Faça login na sua conta para continuar</p>
        {isDevEnv() && (
          <>
            <span
              className="lead"
              style={{ color: "red", textTransform: "uppercase" }}
            >
              Você está em Ambiente de Desenvolvimento
            </span>
            <br />
            <p style={{ color: "red" }}>{settings.DB_NAME_DEV}</p>
          </>
        )}
      </div>

      {loginErrMsg.length > 0 && (
        <UncontrolledAlert color="danger">
          <div className="alert-message">{loginErrMsg}</div>
        </UncontrolledAlert>
      )}

      <Card className={isDevEnv() ? "dev-env" : undefined} body>
        <div className="m-sm-4">
          <Form>
            <FormGroup>
              <Label className="label-auth-login">Usuário</Label>
              <Input
                bsSize="lg"
                type="email"
                name="user"
                placeholder="usuario@empresa"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                required
                className="input-auth-login"
                autoFocus
              />
            </FormGroup>
            <FormGroup>
              <Label className="label-auth-login">Senha</Label>
              <InputGroup>
                <Input
                  bsSize="lg"
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  placeholder="minha senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="input-auth-login"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    style={{ cursor: "pointer", backgroundColor: "white" }}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? <Eye size={15} /> : <EyeOff size={15} />}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <small>
                <Link to="/acesso/reset-password">Esqueci minha senha</Link>
              </small>
            </FormGroup>

            <div>
              <CustomInput
                type="checkbox"
                id="rememberMe"
                label="Lembrar de mim?"
                defaultChecked
              />
            </div>

            <div className="text-center mt-3">
              <Button
                type="submit  "
                color="primary"
                size="lg"
                onClick={handleClickLogin}
              >
                Login
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default SignIn;
