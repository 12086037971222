import { apiPut } from "../../api";
import { apiGetV2 } from "../../apiV2";

const URL_BASE = "/cadastro/colaborador";

const CadastroColaboradorService = {
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  preferencias: {
    visaoPadraoCentralOs: async (novaVisaoPadrao) =>
      await apiPut(
        `${URL_BASE}/preferencias/visao_padrao_central_os/`,
        {
          nova_visao_padrao: novaVisaoPadrao,
        },
        {}
      ),
  },
};

export default CadastroColaboradorService;
