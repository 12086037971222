import React, { useState } from "react";
import {
  BotaoImprimir,
  ModalBase,
  ModalDetalhesIcone,
  Table,
} from "../../../../../components";
import { formatValueFromAPI, naturalSortDate } from "../../../../../coreUtils";
import { Row } from "reactstrap";

const columns = [
  {
    dataField: "nome",
    text: "Cliente",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id),
    sortable: true,
  },
  {
    dataField: "nome_fant",
    text: "Nome Fantasia",
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    dataField: "ultima_venda",
    text: "Última Venda",
    align: "center",
    headerAlign: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "bairro",
    text: "Bairro",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "cidade__nome",
    text: "Cidade",
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    dataField: "fone1",
    text: "Fone",
    align: "center",
    headerAlign: "center",
  },
];
export const DetalhesClientesNaoVendidosModal = ({
  nomeItem,
  data,
  imprimir,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const imprimirInternal = async () => {
    setLoadingImprimir(true);
    await imprimir();
    setLoadingImprimir(false);
  };

  return (
    <>
      <ModalDetalhesIcone toggle={toggle} />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title={
          <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            {nomeItem} - Clientes Não Vendidos
          </h5>
        }
        number="5045_1"
        autoFocus
        hideCancelButton
      >
        <Row>
          <BotaoImprimir
            padded={false}
            onClick={imprimirInternal}
            loading={loadingImprimir}
          />
        </Row>
        <Table
          columns={columns}
          data={data ?? []}
          keyField="id"
          paginated={false}
        />
      </ModalBase>
    </>
  );
};
