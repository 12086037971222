import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Col, FormFeedback, FormGroup, Input } from "reactstrap";
import { handleFocus } from "../coreUtils";
import { HintLabel } from "./HintLabel";

const IntegerFormInput = forwardRef(
  (
    {
      onChange,
      onBlur,
      onKeyDown,
      label,
      md,
      required,
      style,
      name,
      defaultValue,
      disabled,
      clearOnDisable,
      valueOnDisableClear = 0,
      tabOrder,
      colClassName,
      additionalButton,
      mdInput = 12,
      autoFocus,
      maxLength,
      hint,
      hiddenLabel,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState("");
    const [invalidMessage, setInvalidMessage] = useState("");
    const [invalid, setInvalid] = useState(false);
    const inputRef = useRef();
    const [internalId] = useState(
      "text-input-" + Math.floor(Math.random() * Date.now())
    );

    const inputIsValid = (value) => {
      const regex = RegExp(/^([0-9]+)$/);
      if (value) {
        if (!regex.test(value)) {
          setInvalidMessage("Valor inválido");
          return false;
        }
      } else if (required && !value) {
        setInvalidMessage("Esse campo é obrigatório");
        return false;
      }
      setInvalidMessage("");
      return true; // Vazio é válido
    };

    const handleOnChange = (e) => {
      e.preventDefault();
      if (
        e.target.value.length > 0 &&
        e.target.value.replace(/\D/g, "").length === 0
      ) {
        return false;
      }
      const value = (e.target.value || "").trim().replace(/\D/g, "");

      if (inputIsValid(value)) {
        setInvalid(false);
        setInputValue(value);
        if (onChange) {
          if (value !== "") {
            onChange(Number.parseFloat(value.replace(",", ".")), e);
          } else {
            onChange(null, e);
          }
        }
      } else {
        setInvalid(true);
        setInputValue("");
        if (onChange) {
          onChange(0.0, e);
        }
      }
    };

    const onBlurInternal = (e) => {
      if (onBlur) {
        const inputValue = e.target.value || "";
        onBlur(e, inputValue?.toUpperCase());
      }

      const { value } = e.target;
      inputIsValid(value);
    };

    useImperativeHandle(ref, () => ({
      floatValue: () => {
        return inputIsValid(inputValue)
          ? Number.parseFloat(String(inputValue || "0.0").replace(",", "."))
          : 0.0;
      },
      isValid: () => inputIsValid(inputValue),
      clear: () => {
        setInputValue("");
      },
      setValue: (value) => {
        if (inputIsValid(value)) {
          setInputValue(value);
        }
      },
      focus: () => inputRef.current.focus(),
      value: inputValue,
    }));

    useEffect(() => {
      if (inputIsValid(defaultValue)) {
        setInputValue(defaultValue ?? "");
      }
    }, [defaultValue]);

    useEffect(() => {
      if (disabled && clearOnDisable) {
        setInputValue(valueOnDisableClear);
        if (onChange) {
          onChange(valueOnDisableClear);
        }
      }
    }, [disabled, clearOnDisable]);

    const onKeyDownInternal = (e) => {
      handleFocus(e);
      if (onKeyDown) {
        onKeyDown(e);
      }
    };

    return (
      <Col md={md} className={colClassName}>
        <FormGroup>
          {label && (
            <HintLabel
              for={internalId}
              hintText={hint}
              label={label}
              hidden={hiddenLabel}
              required={required}
            />
          )}
          <Col md={mdInput} className="no-gutters">
            <div style={{ display: "flex", width: "100%" }}>
              <Input
                id={internalId}
                type="text"
                style={{ textAlign: "center", ...style }}
                onChange={handleOnChange}
                onBlur={onBlurInternal}
                name={name}
                value={inputValue}
                invalid={invalid}
                required={required}
                onKeyDown={onKeyDownInternal}
                disabled={disabled}
                tabOrder={tabOrder}
                onFocus={(e) => e.target.select()}
                autoFocus={autoFocus}
                innerRef={inputRef}
                maxLength={maxLength}
              />
              <FormFeedback valid={false}>{invalidMessage}</FormFeedback>
              {additionalButton && additionalButton}
            </div>
          </Col>
        </FormGroup>
      </Col>
    );
  }
);

export default IntegerFormInput;
