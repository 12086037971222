import React, { useState } from "react";
import imagem1 from "../../../../assets/img/layouts_os_fec/1.png";
import imagem2 from "../../../../assets/img/layouts_os_fec/2.png";
import imagem3 from "../../../../assets/img/layouts_os_fec/3.png";
import imagem4 from "../../../../assets/img/layouts_os_fec/4.png";
import imagem5 from "../../../../assets/img/layouts_os_fec/5.png";
import ImageViewer from "react-simple-image-viewer";
import { Divider } from "../../../../components/Divider";

const layouts = [
  {
    id: 1,
    imagem: imagem1,
    descricao: (
      <>
        <ul>
          <li>Impressão padrão</li>
          <li>Impressão da Referência conforme parâmetro</li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    imagem: imagem2,
    descricao: (
      <>
        <ul>
          <li>Impressão Padrão</li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    imagem: imagem3,
    descricao: (
      <>
        <ul>
          <li>Não imprime Referência</li>
          <li>Espaço para assinatura do Cliente</li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    imagem: imagem4,
    descricao: (
      <>
        <ul>
          <li>Ordem de serviço automotiva</li>
        </ul>
      </>
    ),
  },
  {
    id: 5,
    imagem: imagem5,
    descricao: (
      <>
        <ul>
          <li>Similar ao layout 1, com desconto dos serviços</li>
          <li>Impressão da Referência conforme parâmetro</li>
        </ul>
      </>
    ),
  },
];

const PrintDisplay = ({ layoutsList, openImageViewer }) => (
  <div className="print-display">
    {layoutsList.map((layout) => (
      <div className="print-item">
        <img
          src={layout.imagem}
          className="print-thumb"
          onClick={() => openImageViewer(layout.id)}
          alt=""
        />
        <div style={{ height: "min-content", marginLeft: "0.75rem" }}>
          <h5 className="mb-1">Layout {layout.id}</h5>
          <span>{layout.descricao}</span>
        </div>
      </div>
    ))}
  </div>
);

export const ImpressaoFechOS = () => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [idImagem, setIndexImagem] = useState(0);

  const openImageViewer = (index) => {
    setIndexImagem(index);
    setIsViewerOpen(true);
  };

  const onCloseImageViewer = () => {
    setIndexImagem(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Divider className="mt-2">Folha A4</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [1, 4, 5].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Bobina 80mm</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [3].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Matricial - Folha A4</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [2].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      {isViewerOpen && (
        <ImageViewer
          src={[layouts.find((layout) => layout.id === idImagem)?.imagem]}
          currentIndex={0}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={onCloseImageViewer}
          backgroundStyle={{ backgroundColor: "#00000085" }}
        />
      )}
    </>
  );
};
