import React from "react";
import {
  TabBody,
  TabController,
} from "../../../../../../components/TabController";
import { TabDesonerICMS } from "./components/TabDesonerICMS";
import { TabDiferICMS } from "./components/TabDiferICMS";
import { TabICMS } from "./components/TabICMS";
import { TabICMSST } from "./components/TabICMSST";
import { TabIPI } from "./components/TabIPI";
import { TabPisCofins } from "./components/TabPisCofins";
import { TabCredICMS } from "./components/TabCredICMS";

export const TabsTributacao = () => {
  return (
    <TabController
      tabClassName="p-0"
      autoFocusFirst={false}
      contentStyle={{ minHeight: "223px" }}
    >
      <TabBody title="ICMS">
        <TabICMS />
      </TabBody>
      <TabBody title="ICMS ST">
        <TabICMSST />
      </TabBody>
      <TabBody title="IPI">
        <TabIPI />
      </TabBody>
      <TabBody title="PIS/COFINS">
        <TabPisCofins />
      </TabBody>
      <TabBody title="Desoneração ICMS">
        <TabDesonerICMS />
      </TabBody>
      <TabBody title="Diferimento">
        <TabDiferICMS />
      </TabBody>
      <TabBody title="Crédito de ICMS">
        <TabCredICMS />
      </TabBody>
    </TabController>
  );
};
