import { apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/nfe_entrada/fracionamento";

const FracionamentoNfEntradaService = {
  buscarItemFracionar: (idItem) =>
    apiGetV2(`${URL_BASE}/buscar_item_fracionar/${idItem}/`),
  buscarItensFracionados: (idItem) =>
    apiGetV2(`${URL_BASE}/buscar_itens_fracionados/${idItem}/`),
  fracionarItem: (payload) => apiPostV2(`${URL_BASE}/fracionar_item/`, payload),
  listarNfs: (params) => apiGetV2(`${URL_BASE}/listar_nfs/`, params),
};

export default FracionamentoNfEntradaService;
