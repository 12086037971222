import React from "react";
import { useState } from "react";
import { modalTitleMotivo, routesBaseMotivo } from "../Motivo";
import { Row } from "reactstrap";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { ComboBox, TextInput } from "../../../../../components";
import { showWarning } from "../../../../../components/AlertaModal";

export const tipoMotivoOptions = [
  { label: "Todos os Módulos", value: "ALL" },
  { label: "Pedidos de Venda", value: "PED" },
  { label: "Contas a Pagar", value: "PAG" },
  { label: "Contas a Receber", value: "REC" },
  { label: "Agendamentos", value: "AGE" },
  { label: "Cadastros em Geral", value: "CAD" },
  { label: "Nota Fiscal Eletrônica", value: "NFE" },
  { label: "Venda", value: "VDA" },
  { label: "Ordem de Serviço", value: "OSV" },
  { label: "Venda pelo Mobile", value: "VPM" },
];

export const CadastroMotivoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [tipo, setTipo] = useState("ALL");
  const [descricao, setDescricao] = useState("");

  const limparDados = () => {
    setAtivo(true);
    setTipo("ALL");
    setDescricao("");
  };

  const fetchData = (data) => {
    setAtivo(data.ativo);
    setTipo(data.tipo);
    setDescricao(data.descricao);
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(tipo)) {
      showWarning("Por favor, informe o Tipo do Motivo");
      return false;
    }

    if (["", null, undefined].includes(descricao)) {
      showWarning("Por favor, informe a Descrição do Motivo");
      return false;
    }

    const payload = {
      tipo: tipo,
      descricao: descricao,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_motivo: selected, ...payload, ativo: ativo };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleMotivo}
      size="sm"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseMotivo}
      number="0126_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <ComboBox
          md={10}
          label="Aplicar Em"
          options={tipoMotivoOptions}
          onChange={(s) => setTipo(s?.value)}
          defaultValue={tipo}
        />
      </Row>
      <Row>
        <TextInput
          label="Descrição"
          value={descricao}
          onChange={(e, v) => setDescricao(v)}
          maxLength={60}
        />
      </Row>
    </ModalCadastroV2>
  );
};
