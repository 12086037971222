import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { transferenciaVendaRoute } from "../../../routes/modules/faturamento";
import { TbTransfer } from "react-icons/tb";

export const Transferencias = () => {
  return (
    <PageContainer title="Transferências">
      <MenuGroup>
        <MenuGroupButton
          label="Transferência de Venda"
          icon={TbTransfer}
          pathname={transferenciaVendaRoute.path}
          nroTela={transferenciaVendaRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
